import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AnexoField from "./AnexoField";

export default function AnexosProtocoloPanel(props) {
    const { protocolo, setWait, setProtocolo, setMensagem, disabled } = props;

    return (
        <Grid container>
            {protocolo && protocolo.anexos && protocolo.anexos.map((row, i) => (
                <Grid key={i} item >
                    <AnexoField disabled={disabled}
                        anexo={row} protocolo={protocolo} setWait={setWait} setProtocolo={setProtocolo} setMensagem={setMensagem} />
                </Grid>
            ))}
        </Grid>
    );
}
