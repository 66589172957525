import NotificacaoService from '../../../services/NotificacaoService';
import ProcessoService from '../../../services/ProcessoService';
import React, { useState, useEffect } from 'react';
import {
    Button, Grid, Paper, TextField, Container, Tooltip, IconButton, Typography, makeStyles,
} from '@material-ui/core';
import ConfirmDialog from "../../../util/ConfirmDialog";
import BuscaDadoDialog from "../../../util/BuscaDadoDialog";

import { useStyles, getQueryParams } from "../../../constants";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation, useParams } from "react-router-dom";
import SearchIcon from '@material-ui/icons/Search';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

export const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '100px',
        overflow: 'auto'
    }
}), { index: 1 });

export default function ManterComplementoINformacaoPanel(props) {
    const classes = useStyles();
    const { setMensagem, setWait, setTitulo, desable } = props;
    let { id } = useParams();

    const [complemento, setComplemento] = useState({});
    const classesLocal = useLocalStyle();

    const history = useHistory();
    const [profile, carregandoProfile] = useCurrentUser();
    const location = useLocation();
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmResponder, setShowConfirmResponder] = useState(false);

    const [processosParaSelecao, setProcessosParaSelecao] = useState([]);
    const [descricao, setDescricao] = useState('');

    const [desabilitado, setDesabilitado] = useState(false);
    const param = getQueryParams(useLocation().search);

    const isPerfilEmpresario = () => {
        return profile && profile.regras ? profile.regras.find(r => r === '012') !== undefined : false;
    }

    useEffect(() => {
        setTitulo('Solicitar Complemento de Informação');
        //const id = param.get("id");
        async function fetchData() {
            setWait(true);
            buscaComplemento(id);
        }
        fetchData();
        if( isView()){
            setDesabilitado(true);
        }
        return () => { }
    }, []);

    useEffect(() => {
        if( podeResponder() ){
            setTitulo('Responder Complemento de Informação');
        }else{
            if ( id) {
                setTitulo('Complemento de Informação');
            } else {
                setTitulo('Solicitação Complemento de Informação');
            }
        }   
    }, [complemento && complemento.status])

    function isView(){
        return location.pathname.includes("formView");
    }
    function confirmaResposta() {
        let dado = { ...complemento };
        dado.descricao = descricao;
        NotificacaoService.alterar(dado).then((n) => {
            // idProcesso = n.processo;
            handleSair();
            setMensagem({ mensagem: `Notificação respondida com sucesso. Número do protocolo: ${n.protocolo}`, duracao: 3000 })
        }).catch((e) => {
            setMensagem({ tipo: 'error', mensagem: e.message });
        }).finally(() => {
            setWait(false);
        });
    }

    function salvaRascunho() {
        let dado = { ...complemento };
        dado.assunto = 'Complemento';
        dado.status = 'RASCUNHO_ACESSOR';
        dado.descricao = descricao;
        setWait(true);
        dado.historico = dado.historico ? dado.historico : [];
        dado.historico.push({
            tipo: dado.status,
            data: new Date(),
            responsavel: {
                nome: profile.name,
                email: profile.email,
                userId: profile.id
            }
        });
        
        NotificacaoService.criar(dado).then((n) => {

            // setProtocolo( n.protocolo );
            //setComplemento(n);
            // setId(n.id);
            // setHistorico( n.historico);
            setMensagem({ mensagem: `Rascunho criado com sucesso.`, duracao: 3000 });
            handleSair(n);
        }).catch((e) => {
            setMensagem({ tipo: 'error', mensagem: e.message });
        }).finally(() => {
            setWait(false);
        });
    }

    function confirmaAprovacao() {
        let dado = { ...complemento };
        dado.assunto = 'Complemento';
        dado.tipo = 'Complemento'
        dado.status = "EM_ANALISE";
        dado.descricao = descricao;
        dado.solicitante = {
            nome: profile.name,
            email: profile.email,
            id: profile.id  
        };

        setWait(true);
        dado.historico = dado.historico ? dado.historico : [];
        dado.historico.push({
            tipo: dado.status,
            data: new Date(),
            responsavel: {
                nome: profile.name,
                email: profile.email,
                userId: profile.id
            }
        });
        NotificacaoService.criar(dado).then((n) => {
            handleSair(n);
            setMensagem({ mensagem: `Solicitação criada com sucesso. Número do protocolo: ${n.protocolo}`, duracao: 3000 })
        }).catch((e) => {
            setMensagem({ tipo: 'error', mensagem: e.message });
        }).finally(() => {
            setWait(false);
        });
    }

    function handleSair(complemento) {
        history.replace(`/gerenciaProcesso/complementoinformacao/lista`);
    }

    function handleVisualizarPdf() {
        let dado = { ...complemento };
        dado.descricao = descricao;
        fetch("/api/processoProdf/requerimento/relatorio", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }

    function handleLocalizarProcesso() {
        setWait(true);
        ProcessoService.obterPorCnpjs(null).then((r) => {
            setProcessosParaSelecao(r);
            setShowBuscaDadoDialog(true);
        }).catch((e) => {
            setMensagem({ tipo: 'error', mensagem: e.message });
        }).finally(() => {
            setWait(false);
        });
    }

    function handleSelecionarProcesso(p) {
        
        setComplemento(
            {
                ...complemento,
                processo:{
                     id: p.idProcesso,
                     nProcesso: p.nProcesso,
                     cnpj: p.cnpj,
                     razaoSocial: p.razaoSocial,
                     programa: p.programa
                }
            }
        );
    }
    const abrirResponder = () => {
        let identificador = id?id:param.get("id");
        history.replace(`/gerenciaProcesso/notificacao/respostaForm?id=${identificador}&back=/gerenciaProcesso/complementoinformacao/lista`);
    }

    function buscaComplemento(id) {
        let identificador = id?id:param.get("id");
        if (!identificador || identificador === 0 || identificador === '0') {
            setWait(false);
            return;
        }
        fetch('/api/processoProdf/notificacaoComplemento/' + identificador, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    setComplemento(json);
                    setDescricao(json.descricao?json.descricao:'');
                    setWait(false);                           
                    if( desable)setDesabilitado(true);
                    let pasaResposta = '';
                    for (let x in json.historico) {
                        const d = json.historico[x];
                        if (d.tipo === "responder") {
                            pasaResposta = d.texto;
                            //setResposta(d.texto);
                        }
                    }
                    setComplemento({
                        ...json,
                        resposta: pasaResposta
                    });
                    // else setDesabilitado(
                    //     json.status === 'DEFERIDO' ||
                    //     json.status === 'EM_ANALISE' ||
                    //     json.status === 'NAO_RESPONDIDO' ||
                    //     json.status === 'RASCUNHO' ||
                    //     json.status === 'PENDENTE')
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function podeResponder() {
        if( desable) return false;
        if( isView() )return false;
        if( isPerfilEmpresario() ){ 
            return true;
        }
        const status = complemento.status;
        return ((status === 'NAO_RESPONDIDO' || status === 'RASCUNHO_ACESSOR' || status === 'RASCUNHO' || status === 'PENDENTE') && isPerfilEmpresario());
    }
    function podeNotificar() {
        if( isView() )return false;
        if( isPerfilEmpresario() ) return false;
        if( podeResponder()) return false;
        return !desabilitado;
    }
    function podeRascunho() {
        if( isPerfilEmpresario() ) return false;
        if( isView() )return false;
        if( podeResponder()) return false;
        return !desabilitado;
    }
    const sair = ()=>{
        let urlRet  = "/gerenciaProcesso/complementoinformacao/lista";
        if( param.get('back') ){
            urlRet = param.get('back');
            if( param.get("open")){
                urlRet += "?open="+param.get("open")
            }
        }
        history.push(urlRet);    
    }
    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                <Grid item xs={ (desabilitado || isPerfilEmpresario())? 4 : 3}>
                        <TextField disabled={true}
                            value={complemento.processo && complemento.processo.nProcesso || ''}
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            variant="outlined"
                        />
                    </Grid>
                    {!(desabilitado || isPerfilEmpresario()) &&
                        <Grid item xs={1} style={{ textAlign: 'center' }}>
                            <Tooltip title='Buscar Processo'>
                                <IconButton onClick={handleLocalizarProcesso} >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }

                    <Grid item xs={2}>
                        <TextField disabled={true}
                            value={complemento.processo && complemento.processo.programa || ''}
                            fullWidth
                            label="Programa"
                            id="programa"
                            name="programa"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField disabled={true}
                            value={complemento.protocolo || ''}
                            fullWidth
                            label="Protocolo"
                            id="protocolo"
                            name="protocolo"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField disabled={true}
                            value={complemento.dataCriacao || ''}
                            fullWidth
                            label="Data solicitação"
                            id="dataSolicitacao"
                            name="dataSolicitacao"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField disabled={true}
                            value={complemento.processo && complemento.processo.cnpj || ''}
                            fullWidth
                            label="CNPJ"
                            id="cnpj"
                            name="cnpj"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled={true}
                            value={complemento.processo && complemento.processo.razaoSocial || ''}
                            fullWidth
                            label="Razão Social"
                            id="razaoSocial"
                            name="razaoSocial"
                            variant="outlined"
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <CKEditor
                            editor={ClassicEditor} disabled={desabilitado || param.get("acao") === "view"}
                            config={{
                                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                    '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                            }}
                            data={descricao}
                            onReady={editor => {
                                editor.editing.view.change(writer => {
                                    writer.setStyle(
                                        "height",
                                        "300px",
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                            onChange={(event, editor) => {
                                setDescricao(editor.getData());
                            }}
                            onBlur={(event, editor) => {

                            }}
                            onFocus={(event, editor) => {

                            }}
                        />
                    </Grid>
                    {complemento.resposta &&
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>Resposta Notificação:</Typography>
                            <Paper className={classesLocal.paper}>
                                <span dangerouslySetInnerHTML={{
                                    __html: complemento.resposta ? complemento.resposta : ''
                                }}></span>
                            </Paper>
                        </Grid>
                    }  
                    
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleVisualizarPdf()}>
                            Visualizar
                        </Button>
                        {podeRascunho() &&
                            <Button variant="contained" color="primary" onClick={salvaRascunho} disabled={descricao.length === 0 || !complemento.processo }>
                                Salvar rascunho
                        </Button>}
                        {podeNotificar() && 
                            <Button variant="contained" color="primary" onClick={() => { setShowConfirm(true) }} disabled={descricao.length === 0 || !complemento.processo}>
                                Solicitar
                            </Button>
                        }
                        {podeResponder() &&
                            <Button variant="contained" color="primary" onClick={abrirResponder}>
                                Responder
                        </Button>}
                    </Grid>
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirmar solicitação.'
                        exec={confirmaAprovacao}
                    />
                    <ConfirmDialog
                        open={showConfirmResponder}
                        setOpen={setShowConfirmResponder}
                        titulo={'Confirmação'}
                        msg='Confirma cadastro da resposta?'
                        exec={confirmaResposta}
                    />
                    <BuscaDadoDialog
                        open={showBuscaDadoDialog}
                        setOpen={setShowBuscaDadoDialog}
                        acao={(p) => handleSelecionarProcesso(p)}
                        colunas={[
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Nome', campo: 'razaoSocial' },
                            { nome: 'Processo', campo: 'nProcesso' },
                        ]}
                        dados={processosParaSelecao} />
                </Grid>
            </Paper >
        </Container >
    );
}
