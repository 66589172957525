import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import { Meses, FormataMoeda } from "../../../constants";
import TableMoedaCell from "./TableMoedaCell";

const useStylesLocal = makeStyles(theme => ({
    input: {
        width: 80,
        height: 30
    },
    table: {
        minWidth: 700
    },
    tablecell: {
        fontSize: 14,
        fontWeight: 700
    }
}));


export default function OperacaoEntradaTable(props) {
    const classes = useStylesLocal();
    const [componenteAtual, setComponenteAtual] = useState();
    const { dados, setDados, calcula, disabled, dadoApoio, perDescontoBeneficio } = props;
    const [total, setTotal] = useState({});

    useEffect(() => {
        // let teste = (dadoApoio.txIcms/100);
        
        // if (dados) {
        //     let arr = [...dados];
        //     for (let x in dados) {
        //         if (!dados[x]) continue;
        //         if (dadoApoio) {
        //             if (arr[x].recolhimentoFundoProd) {
        //                 arr[x].ICMSProprioEmpreendidoS = arr[x].recolhimentoFundoProd / teste;
        //             }
        //         }
        //         if (perDescontoBeneficio) {
        //             if (!dados[x]) continue;
        //             if (arr[x].ICMSProprioEmpreendidoS) {
        //                 arr[x].ICMSProprioEmpreendidoC = arr[x].ICMSProprioEmpreendidoS * (1 - (perDescontoBeneficio/100));
        //                 arr[x].creditoPresumidoICMS = arr[x].ICMSProprioEmpreendidoS * (perDescontoBeneficio/100);
        //             }
        //         }
        //         arr[x].recolhimentoTotalFundos = arr[x].recolhimentoFundoProd + arr[x].recolhimentoFundoDesenv;
        //     }
        //     setDados(arr);
        // }
    }, [perDescontoBeneficio])

    useEffect(() => {
        if (dados && dados.length > 0) {
            let totvalorDeclarado = 0;
            let totimpostoImportacao = 0;
            let totimpostoProdutoIndustriazados = 0;
            let totimpostoOperacaoFinanceira = 0;
            let totcontribuicaoPis = 0;
            let totcontribuicaoCofis = 0;
            let totdespensaSiscomex = 0;
            let totoutrasDespesas = 0;
            let toticmsRecolhido = 0;
            for (let x in dados) {
                if (!dados[x]) continue;
                totvalorDeclarado               += dados[x].valorDeclarado               ? dados[x].valorDeclarado              :0;
                totimpostoImportacao            += dados[x].impostoImportacao            ? dados[x].impostoImportacao           :0;
                totimpostoProdutoIndustriazados += dados[x].impostoProdutoIndustriazados ? dados[x].impostoProdutoIndustriazados:0;
                totimpostoOperacaoFinanceira    += dados[x].impostoOperacaoFinanceira    ? dados[x].impostoOperacaoFinanceira   :0;
                totcontribuicaoPis              += dados[x].contribuicaoPis              ? dados[x].contribuicaoPis             :0;
                totcontribuicaoCofis            += dados[x].contribuicaoCofis            ? dados[x].contribuicaoCofis           :0;

                totdespensaSiscomex             += dados[x].despensaSiscomex             ? dados[x].despensaSiscomex            :0;
                totoutrasDespesas               += dados[x].outrasDespesas               ? dados[x].outrasDespesas              :0;
                toticmsRecolhido                += dados[x].icmsRecolhido                ? dados[x].icmsRecolhido               :0;
    
            }
            // let saldoInicial = (dados[11].saldoInicial?dados[11].saldoInicial:0) + (dados[11].parcelaLiberacao?dados[11].parcelaLiberacao:0) - (dados[11].parcelaLeiloada?dados[11].parcelaLeiloada:0) 
            //     + (dados[11].rendimentoContrato?dados[11].rendimentoContrato:0) - (dados[11].jurosPago?dados[11].jurosPago:0);

            setTotal({
                valorDeclarado               : totvalorDeclarado               ,
                impostoImportacao            : totimpostoImportacao            ,
                impostoProdutoIndustriazados : totimpostoProdutoIndustriazados ,
                impostoOperacaoFinanceira    : totimpostoOperacaoFinanceira    ,
                contribuicaoPis              : totcontribuicaoPis              ,
                contribuicaoCofis            : totcontribuicaoCofis            ,
                despensaSiscomex             : totdespensaSiscomex             ,
                outrasDespesas               : totoutrasDespesas               ,
                icmsRecolhido                : toticmsRecolhido                ,
                // icmsSemDesconto              : toticmsSemDesconto              ,
                // creditoPresumido             : totcreditoPresumido             
            });
        }
    }, [dados])

    const onChange = (e, row, index) => {
        if (!/^[0-9]*\,?[0-9]*$/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^0-9]*\,?[0-9]*$/g, "");
            return;
        }
        let valor = e.target.value;
        // let teste = (dadoApoio.txIcms/100);
        let arr = [...dados];
        let val = { ...arr[index] };
        // 
        valor = valor.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '');
        valor = valor.replace(",", ".");
        val[e.target.name] = parseFloat(valor);

        //Soma das colunas informadas * (Desconto ICMS Emprega DF/(1-Desconto ICMS Emprega DF)  
        //=(SOMA(B2:I2))*0,12/0,88
        //=K2*50%



        if(dadoApoio){

            if( dadoApoio.descontoICMS ){
                val.icmsSemDesconto = (
                  (val.valorDeclarado    ?          parseFloat(val.valorDeclarado              ):0)
                + (val.impostoImportacao ?          parseFloat(val.impostoImportacao           ):0)
                + (val.impostoProdutoIndustriazados?parseFloat(val.impostoProdutoIndustriazados):0)
                + (val.impostoOperacaoFinanceira?   parseFloat(val.impostoOperacaoFinanceira   ):0)
                + (val.contribuicaoPis?             parseFloat(val.contribuicaoPis             ):0)
                + (val.contribuicaoCofis?           parseFloat(val.contribuicaoCofis           ):0)
                + (val.despensaSiscomex?            parseFloat(val.despensaSiscomex            ):0)
                + (val.outrasDespesas?              parseFloat(val.outrasDespesas              ):0)
                ) * ( dadoApoio.descontoICMS/100 ) /0.88; 
                val.creditoPresumido = val.icmsSemDesconto* 0.5;
            }
        }
        arr[index] = val; 
        setDados(arr);
    };

    function FormataPerc(val){
        const opt = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
        if( val === 0 || val === '0')return val.toLocaleString('pt-br', opt);
        if( !val) return '';
        var f = val.toLocaleString('pt-br',opt);
        return f;
    }


    function proximo(nome) {
        let arr = nome.split("nm_");
        let col = parseInt(arr[0]);
        let lin = parseInt(arr[1]);
        if (col >= 9) {
            lin++;
            col = 1;
        } else {
            col++;
        }
        setComponenteAtual(col + "nm_" + lin);
    }
    function apresentaValorPer(index, nome) {
        if (dados && dados.length > 0) {
            if (dados[index]) {
                return FormataPerc(dados[index][nome]);
            }
            return '';
        }
        return '';
    }
    function apresentaValor(index, nome) {
        if (dados && dados.length > 0) {
            if (dados[index]) {
                return FormataMoeda(dados[index][nome]);
            }
            return '';
        }
        return '';
    }
    return (
        <TableContainer component={Paper} className={classes.root}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead} >REFERÊNCIA</TableCell>
                        <TableCell className={classes.tableHead} align="right">VALOR DECLARADO MERCADORIA EM DI</TableCell>
                        <TableCell className={classes.tableHead} align="right">IMPOSTO IMPORTAÇÃO</TableCell>
                        <TableCell className={classes.tableHead} align="right">IMPOSTO PRODUTOS INDUSTRIALIZADOS</TableCell>
                        <TableCell className={classes.tableHead} align="right">IMPOSTO SOBRE OPERAÇÕES FINANCEIRAS</TableCell>
                        <TableCell className={classes.tableHead} align="right">CONTRIBUIÇÃO PIS</TableCell>
                        <TableCell className={classes.tableHead} align="right">CONTRIBUIÇÃO COFIS</TableCell>

                        <TableCell className={classes.tableHead} align="right">DESPESA SISCOMEX</TableCell>
                        <TableCell className={classes.tableHead} align="right">OUTRAS DESPESAS</TableCell>
                        <TableCell className={classes.tableHead} align="right">ICMS RECOLHIDO</TableCell>
                        {/* <TableCell className={classes.tableHead} align="right">ICMS SEM DESCONTO</TableCell>
                        <TableCell className={classes.tableHead} align="right">CRÉDITO PRESUMIDO ICMS</TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Meses.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{row}</TableCell>
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"1nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="valorDeclarado" onChange={onChange} valor={apresentaValor(index, 'valorDeclarado')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"2nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="impostoImportacao" onChange={onChange} valor={apresentaValor(index, 'impostoImportacao')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"3nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="impostoProdutoIndustriazados" onChange={onChange} valor={apresentaValor(index, 'impostoProdutoIndustriazados')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"4nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="impostoOperacaoFinanceira"  onChange={onChange} valor={apresentaValor(index, 'impostoOperacaoFinanceira')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"5nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="contribuicaoPis"  onChange={onChange} valor={apresentaValor(index, 'contribuicaoPis')} />

                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"6nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="contribuicaoCofis"  onChange={onChange} valor={apresentaValor(index, 'contribuicaoCofis')} />

                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"7nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="despensaSiscomex"  onChange={onChange} valor={apresentaValor(index, 'despensaSiscomex')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"8nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="outrasDespesas"    onChange={onChange}  valor={apresentaValor(index, 'outrasDespesas')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"9nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="icmsRecolhido"     onChange={onChange}  valor={apresentaValorPer(index, 'icmsRecolhido')} />

                            {/* <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"9nm_" + index} index={index} disabled={true}     row={row} calcula={calcula} name="icmsSemDesconto"  onChange={onChange}    valor={apresentaValorPer(index, 'icmsSemDesconto')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"10nm_" + index} index={index} disabled={true}    row={row} calcula={calcula} name="creditoPresumido"  onChange={onChange}  valor={apresentaValorPer(index, 'creditoPresumido')} /> */}
                            
                        </TableRow>
                    ))}
                    <TableRow style={{ background: '#fafafa' }} className={classes.tablecell}
                    >
                        <TableCell className={classes.tablecell} component="th" scope="row" >Total</TableCell>
                        {/* <TableCell className={classes.tablecell} align="right" style={{ color: (total.recolhimentoFundoProd < 0) ? '#ff5c33' : 'black' }} >{FormataMoeda(total.recolhimentoFundoProd)}</TableCell> */}
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.valorDeclarado)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.impostoImportacao)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.impostoProdutoIndustriazados)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.impostoOperacaoFinanceira)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.contribuicaoPis)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.contribuicaoCofis)}</TableCell>

                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.despensaSiscomex)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.outrasDespesas)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataPerc (total.icmsRecolhido)}</TableCell>
                            
                        {/* <TableCell className={classes.tablecell} align="right">{FormataPerc(total.icmsSemDesconto)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataPerc(total.creditoPresumido)}</TableCell> */}

                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
