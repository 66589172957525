import React, { useEffect, useState } from 'react';
import {
    Grid, Button,
} from '@material-ui/core';
import GenericTable from "../../template/GenericTable";
import { useHistory, useLocation, useParams } from 'react-router';
import { useStyles, FormataMoeda } from '../../../constants';
import ConfirmDialog from '../../../util/ConfirmDialog';
import { useCurrentUser } from "../../../server/UseCurrentUser";

let idLeilao = 0;
export default function RenunciaFundadeTable(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait } = props;
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const history = useHistory();
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    let [profile] = useCurrentUser();
    const [options, setOptions] = useState(['Visualizar', 'Alterar', 'Excluir']);

    let { programa } = useParams();

    useEffect(() => {
        setTitulo("Renucia FUNDEFE - " + programa);
        buscarRenuncia();
    }, [programa]);

    useEffect(() => {
        if (profile){
            if( profile.regras.find(r => r === "047")){
                setOptions(['Visualizar']);
            }
        }
    }, [profile]);

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === "Visualizar") {
            history.replace("/fundefe/renunciaView/"+ programa+"/"+ dado.id);
        } else if (acao === "Alterar") {
            history.replace("/fundefe/renunciaAlterar/"+programa+"/"+ dado.id );
        } else if (acao === "Excluir") {
            excluir(dado.id);
        }
    }
    function excluir(id) {
        setShowConfirmExclusao(true);
        idLeilao = id;
    }

    function buscarRenuncia() {
        fetch("/api/fundefeRenuncia")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    const itens = result.filter(f => f.previsao.programa === programa);
                    let value = [];
                    for (let i = 0; i < itens.length; i++) {
                        let fd = {...itens[i]};
                        fd.novoProjeto = FormatanovoProjeto(fd.novoProjeto);
                        value.push(fd);
                    }
                    setFonteDeDados(value);
                }
            });
    }

    function confirmaExclusao() {
        setWait(true);
        fetch('/api/fundefeRenuncia/' + idLeilao, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(response => {
            if (response.ok) {
                setWait(false);
                let value = [];
                
                if (fonteDeDados && (fonteDeDados.length > 0)) {
                    for (let i = 0; i < fonteDeDados.length; i++) {
                        if (fonteDeDados[i].id !== idLeilao) {
                            let fd = {...fonteDeDados[i]};
                            fd.novoProjeto = FormatanovoProjeto(fd.novoProjeto);
                            value.push(fd);
                        }
                    }
                }
                setFonteDeDados(value);
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function FormatanovoProjeto(val) {
        return val? "Sim": "Não";
    }
    function isSomenteConsulta(){
        if( profile && profile.regras.find(r => r === "047")){
            return true;
        }
        return false;
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            {fonteDeDados &&
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={options}
                dados={fonteDeDados}
                colunas={[
                    { nome: 'Exercicio', campo: 'previsao.exercicio' },
                    { nome: 'CNPJ', campo: 'previsao.cnpj' },
                    { nome: 'Razão Social', campo: 'previsao.razaoSocial' },
                    { nome: 'RA', campo: 'previsao.ra' },
                    { nome: 'Novo Projeto', campo: 'novoProjeto' },
                    { nome: 'Setor', campo: 'previsao.setor' },
                    { nome: 'Saldo Exercicio Anterior', campo: 'saldoExercicioAnterior', transformacao: FormataMoeda, align: "right"},
                    { nome: 'Ação', campo: 'action' }
                ]} />
            }

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
                {!isSomenteConsulta() &&
                <Button variant="contained" color="primary" href={'/fundefe/novaRenuncia/' + programa} >
                    Novo
                </Button>}
            </Grid>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />
        </Grid >


    );
}
