import React, { useState, useEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SolicitarAcessoDocTable from './SolicitarAcessoDocTable';
import Button from '@material-ui/core/Button';
import MotivoDialog from '../../util/MotivoDialog';
import ConfirmDialog from '../../util/ConfirmDialog';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";
import MaskedInput from 'react-text-mask'
import TextField from '@material-ui/core/TextField';
import { useParams } from "react-router-dom";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
    heading: {
      fontWeight: 'bold',
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      width: '50%',
      justifyContent: 'center',
      alignItems: 'center'

    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    btns: {
      '& > *': {
        margin: theme.spacing(1)
      },
      display: 'flex'
    }
  }),
);

function CPFMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
let primeiro = true;
export default function SolicitarAcessoAcompanhar() {
  const classes = useStyles();
  let { id } = useParams();

  const [showMotivoDialog, setShowMotivoDialog] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [dados, setDados] = React.useState({
    solicitante: {
      cpf: '',
      nome: '',
      nomeMae: '',
      dataNascimento: new Date(),
      telefone: '',
      email: ''
    },
    empresa: {
      cnpj: '',
      nomeEmpresa: '',
    }
  });
  const [erro, setErro] = useState();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const history = useHistory();

  const [expanded, setExpanded] = React.useState({
    pn1: true,
    pn2: true
  });

  const [documentos, setDocumentos] = useState([]);


  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/api/acesso/solicitar/' + id);
      const data = await response.json();
      if (data.status > 300) {
        setErro(data);
        return;
      } else {
        setDados(data);
        setDocumentos(data.documentos);
      }
    }

    fetchData();

  }, []);

  function confirmaAprovacao() {
    setOpenBackdrop(true);
    const body = {
      idSolicitacao: dados.id,
      cpf: dados.solicitante.cpf
    }
    fetch('/api/acesso/aprovarSolicitacao', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      credentials: 'include'
    }).then(response => {
      setOpenBackdrop(false);
      if (response.status <= 300) {
        history.push("/autorizaAcesso");

      } else {
        setErro(response);
      }
    })
      .catch(error => {
        setOpenBackdrop(false);
      });
  }

  const revogar = (txt) => {
    setShowMotivoDialog(false);

    setOpenBackdrop(true);
    const body = {
      idSolicitacao: dados.id,
      motivo: txt
    }

    fetch('/api/acesso/revogarSolicitacao', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      credentials: 'include'
    }).then(response => {
      setOpenBackdrop(false);
      if (response.status <= 300) {
        // return response.json();
        history.push("/autorizaAcesso");
      } else {
        setErro(response);
      }
    })
      .catch(error => {
        setOpenBackdrop(false);
      });
  }
  const handleChange = (panel) => (event, isExpanded) => {
    let pn = { ...expanded };
    pn[panel] = isExpanded;
    setExpanded(pn);
  };

  const handleReprovar = (e) => {

    setShowMotivoDialog(true);  
  }

  function sair() {
    history.push("/autorizaAcesso");

  }

  return (
    <Container maxWidth="md">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <Grid container spacing={3} >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              {
                erro && <Alert severity="error">{erro.statusText || erro.error}</Alert>
              }
              <Grid container spacing={3} >
                <Grid item xs>
                  <Typography variant="h6" gutterBottom>Análise de solicitação de acesso</Typography>
                </Grid>
              </Grid>
              <Accordion expanded={expanded.pn1} onChange={handleChange('pn1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className={classes.heading}>Responsável pelas informações</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <TextField
                        disabled
                        autoFocus
                        label="CPF"
                        id="cpf"
                        fullWidth
                        InputProps={{
                          inputComponent: CPFMask,
                        }}
                        value={dados.solicitante.cpf}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label="Nome"
                        name="nome"
                        id="nome"
                        value={dados.solicitante.nome}
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        id="dataNascimento"
                        label="Data Nascimento"
                        format="dd/MM/yyyy"
                        fullWidth
                        value={dados.solicitante.dataNascimento}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        inputVariant="outlined"
                        disabled
                        invalidDateMessage="Formato inválido"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label="Nome"
                        value={dados.solicitante.nomeMae}
                        disabled
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        label="Telefone"
                        value={dados.solicitante.telefone}
                        disabled
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label="Email"
                        value={dados.solicitante.email}
                        disabled
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion expanded={expanded.pn2} onChange={handleChange('pn2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography className={classes.heading}>Empresa</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        label="CNPJ"
                        value={dados.empresa.cnpj}
                        disabled
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label="Razão social"
                        value={dados.empresa.nomeEmpresa}
                        disabled
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <SolicitarAcessoDocTable rows={documentos} setRows={setDocumentos} erro={erro} setErro={setErro} editavel={false} exec={confirmaAprovacao} />

            </Paper>
          </Grid>
          <MotivoDialog open={showMotivoDialog} setOpen={setShowMotivoDialog} acao={revogar} />
          <ConfirmDialog
            open={showConfirm}
            setOpen={setShowConfirm}
            titulo={'Confirmação'}
            msg='Após a aprovação da solicitação, o cliente receberá o e-mail com as informações e os dados de acesso para prosseguir. Confirma a aprovação ?'
            exec={confirmaAprovacao}
          />
          <Backdrop className={classes.backdrop} open={openBackdrop} >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Grid container justify="flex-end" className={classes.btns}>
            <Button variant="contained" color="secondary"  onClick={() => sair()} rel="noopener noreferrer">
              Sair
            </Button>
            {
              (dados && (dados.status === 'ABERTO' || dados.status === 'EM_ANALISE')) &&
              <Button variant="contained" color="secondary" onClick={handleReprovar}>
                Reprovar
              </Button>
            }
            {
              (dados && (dados.status === 'ABERTO' || dados.status === 'EM_ANALISE')) &&
              <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)}>
                Aprovar
              </Button>
            }
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </Container>
  )

}