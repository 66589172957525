

import React, {useState, useEffect} from 'react';
import {
    Grid, Button, TextField,
} from '@material-ui/core';
import { useStyles } from "../../../constants";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ProtocoloField from "../../../util/ProtocoloField";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

export default function HistoricoDialog(props) {
    const { open, setOpen, titulo, cnpj, exec, historico, tipoInstrumento, setWait, acao } = props;
    const classes = useStyles();
    const [values, setValues] = useState(historico);
    const [dataPublicacao, setDataPublicacao] = useState();

    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };
    useEffect(()=>{

        setValues( historico );
        if( historico.dataPublicacao ) setDataPublicacao( new Date(historico.dataPublicacao) );
    }, [historico])
    const handleSelecionarProtocolo = (requerimento)=>{
        setValues({...values, 
            protocolo:requerimento.protocolo
        });
    }
    const handleSalvarClick=()=>{
        setOpen(false);
        if( acao){
            values.dataPublicacao = dataPublicacao;
            acao( values );
        }
    }
    const handleDateChangeIndicacao = (date) => {
        setDataPublicacao(date);
        setValues({
          ...values,
          'dataPublicacao': date
        });
      }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Istrumento de modificação</DialogTitle>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <ProtocoloField
                        valor={values.protocolo || ''}
                        setWait={setWait}
                        cnpj={cnpj}
                        acao={handleSelecionarProtocolo}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={values.instrumento || ''}
                        fullWidth
                        label="Instrumento"
                        id="instrumento"
                        name="instrumento"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={values.dodf || ''}
                        fullWidth
                        label="Número DODF"
                        id="dodf"
                        name="dodf"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        id="dataIndicacao"
                        label="Data Indicação"
                        format="dd/MM/yyyy"
                        value={dataPublicacao || null} onChange={handleDateChangeIndicacao}
                        fullWidth
                        KeyboardButtonProps={true}
                        inputVariant="outlined"
                        invalidDateMessage="Formato inválido"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        value={values.descricao || ''}
                        fullWidth
                        multiline
                        rows={4} fullWidth
                        label="Observação"
                        id="descricao"
                        name="descricao"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"

                    />
                </Grid>
            </Grid>
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" onClick={() => setOpen(false)} rel="noopener noreferrer">
                    Sair
                    </Button>
                <Button variant="contained" color="primary" onClick={handleSalvarClick} rel="noopener noreferrer">
                    {tipoInstrumento}
                    </Button>
            </Grid>
        </Dialog>
        </MuiPickersUtilsProvider>
    );
}
