import React, { useEffect, useState } from 'react';
import {
  Grid, IconButton, Button, Menu, MenuItem, Tooltip, Typography, TextField,
  Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { useHistory, useLocation } from "react-router-dom";
import { useStyles, ValidarCNPJ, getQueryParams, FormataDataComHora, FormataDataSemHora } from "../../constants";
import { makeStyles } from '@material-ui/core/styles';
import MaskedInput from 'react-text-mask'
import ConfirmDialog from "../../util/ConfirmDialog";
import MapaDialog from "../../util/MapaDialog";
import GenericTable from "../../components/template/GenericTable";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useCurrentUser } from "../../server/UseCurrentUser";
import ProcessoField from "../../util/ProcessoField";
import NumberFormat from 'react-number-format';
import Service from '../../services/Service';
import { ptBR } from 'date-fns/locale';


const options = [
  'Atualizar',
];
const programas = ['PROIN', 'PRODECON', 'PRO-DFI', 'PRO-DFII', 'DESENVOLVEDF', 'Pades'];

let msgConfirmacao = "";
let url = "/api/processoProdf/lote";

function CNPJMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function propostaMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

const useStylesLocal = makeStyles((theme) => ({
  formControl: {
    minWidth: 220
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const myField = (props) => {
  const { inputRef, onChange, ...other } = props;

  return <NumberFormat
    {...other}
    getInputRef={inputRef}
    thousandSeparator={'.'}
    decimalSeparator={','}
    decimalScale={2}
    fixedDecimalScale={true}
    onChange={onChange}
  />
}
let idVistoria;
export default function LoteTabelForm(props) {
  const classes = useStyles();
  const classesLocal = useStylesLocal();
  const [profile] = useCurrentUser();
  const { setErro, setTitulo, setWait, setMensagem, disable } = props;
  const [bloco, setBloco] = useState(0);
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const history = useHistory();
  const [raAde, setRaAde] = useState();
  const [adeFiltro, setAdeFiltro] = useState();
  const [values, setValues] = useState({});
  const [error, setError] = React.useState(resetErro());
  const [empresa, setEmpresa] = useState({});
  const [atividadePrincipal, setAtividadePrincipal] = useState({});
  const [atividadesSecundaria, setAtividadesSecundaria] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const param = getQueryParams(useLocation().search);
  const [situacao, setSituacao] = useState();
  const [situacaoSigafe, setSituacaoSigafe] = useState();
  const [showMapa, setShowMapa] = useState(false);
  const [uos, setUos] = useState();
  const [processos, setProcessos] = useState([]);
  const [dataLicitacao, setDataLicitacao] = useState();
  const [dataIndicacao, setDataIndicacao] = useState();

  const [vistoria, setVistoria] = useState([]);


  const [ordem, setOrdem] = useState({
    campo: '',
    ordem: 'asc'
  });

  useEffect(() => {


    if (param.get("id")) {
      setTitulo(disable ? "Visualização do lote" : "Alterar Lote");
      msgConfirmacao = "Confirma alteração do lote?"
    } else {
      setTitulo("Cadastro de Lote");
      msgConfirmacao = "Confirma cadastro do lote?"
    }

    buscaRaAde();
    buscarSituacao();
    buscarSituacaoSigafe();

    if (location.pathname.includes("estoqueLoteView")) {
      setTitulo("Visualização do Lote");
    }
    url = "/api/processoProdf/lote";
  }, []);

  // useEffect(() => {
  //   montaDescUos();
  // }, [values, uos]);
  function resetErro() {
    return {
      cnpj: {
        erro: false,
        msg: ''
      }
    }
  }


  const handleChange = (panel) => (event, isExpanded) => {
    if (panel === 'vistoria') {
      buscaVistoria();
    }
  };

  const handleActionSolicitarClick = (index, acao, dado) => {
    idVistoria = dado.id;
    if (acao = 'Visualizar') {
      history.replace("/vistoria/solicitarView?id=" + idVistoria)
    }
  }

  const buscaVistoria = () => {
    const dado = {
      nProcesso: values.processo,
      ra: values.ra,
      ade: values.ade,
      endereco: values.endereco
    };
    setWait(true);
    fetch(`/api/processoProdf/vistoria/buscaByLote/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(dado),
    }).then((r) => {
      setWait(false);
      if (!r.ok) {
        r.json().then(j => setErro(j));
      } else {
        r.json().then(result => setVistoria(result));
      }
    });
  }


  function formataData(dt) {
    if (dt) {
      return FormataDataComHora(new Date(dt));
    }
    return '';
  }

  function buscaLote(id, ra, uos) {
    setWait(true);
    fetch('/api/processoProdf/lote/' + id).then(response => {
      if (response.ok) {
        response.json().then((json) => {
          setWait(false);
          if (json.latitude) {
            json.coordenadas = (json.latitude && json.longitude) ? json.latitude + ", " + json.longitude : null;
          }

          if (json.area) {
            json.area = parseFloat(json.area);
          }
          setValues(json);
          setDataIndicacao(json.dataIndicacao ? new Date(json.dataIndicacao) : null);
          setDataLicitacao(json.dataLicitacao ? new Date(json.dataLicitacao) : null);

          let arr = ra.find(item => item.nome === json.ra);
          arr = arr ? arr.ade : null;
          setAdeFiltro(arr);

          buscaEmpresa(json);

          montaDescUos(json, uos);

        });
      } else {
        response.json().then((error) => {
          setWait(false);
          setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
      }
    }).catch(error => {
      setWait(false);
      setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }
  function buscaRaAde() {
    setWait(true);
    fetch('/api/info/ra_ade').then(response => {
      if (response.ok) {
        response.json().then((json) => {
          setRaAde(json);
          buscarUos(json);

        });
      } else {
        response.json().then((error) => {
          setWait(false);
          setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
      }
    }).catch(error => {
      setWait(false);
      setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }
  function buscarUos(ra) {
    setWait(true);
    fetch('/api/info/uos').then(response => {
      if (response.ok) {
        response.json().then((json) => {
          setWait(false);
          setUos(json);
          if (param.get("id")) {
            buscaLote(param.get("id"), ra, json);
          }
        })
      } else {
        response.json().then((error) => {
          setWait(false);
          setError((error && error.message) || 'Oops! Something went wrong with UOS. Please try again!')
        })
      }
    }).catch(error => {
      setWait(false);
      setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }

  function buscarSituacao() {
    setWait(true);
    fetch('/api/processoProdf/situacaoLote').then(response => {
      if (response.ok) {
        response.json().then((json) => {
          setWait(false);
          setSituacao(json);
        });
      } else {
        response.json().then((error) => {
          setWait(false);
          setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
      }

    }).catch(error => {
      setWait(false);
      setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }
  function buscarSituacaoSigafe() {
    setWait(true);
    fetch('/api/info/situacaoSigafe').then(response => {
      if (response.ok) {
        response.json().then((json) => {
          setWait(false);
          setSituacaoSigafe(json);
        });
      } else {
        response.json().then((error) => {
          setWait(false);
          setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
      }

    }).catch(error => {
      setWait(false);
      setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }
  function buscaEmpresa(lote) {
    if (lote.cnpj) {
      const cnpj = lote.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
      if (cnpj.length === 0) {
        return;
      }
      fetch("/api/v1/cnpj/" + cnpj)
        .then(response => {
          setWait(false);
          if (response.ok) {
            setWait(false);
            response.json().then(json => {

              incluirAtividadePrincipal(json);
              if (!json) return;
              if (!json.razaoSocial) {
                json.razaoSocial = json.nome;
              }
              if (!lote.razaoSocial) {
                lote.razaoSocial = json.razaoSocial;
                setValues(lote);
              }
              setEmpresa(json);
            });
          } else {
            response.json() // return the result of the inner promise, which is an error
              .then((error) => {
                const item = {
                  tipo: 'error',
                  duracao: 15000,
                  visibilidade: true,
                  mensagem: (error && error.message) || 'Oops! Something went wrong. Please try again!'
                }
                setMensagem(item);
              });

          }
        }).catch(error => {
          topFunction();
          // console.error(">>>erro<<<", error )
          setWait(false);
          setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
  }
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  function incluirAtividadePrincipal(empresa) {
    if (!empresa.atividade) {
      setWait(true);
      fetch("/api/empresa/atualizaAtividade/" + empresa.id
      ).then(res => res.json()).then((result) => {
        setWait(false);
        setEmpresa(result)
      });
    }
  }

  function handleSelecionarProcesso(p) {
    if (!p || !p.idProcesso) return;
    fetch("/api/processoProdf/" + p.idProcesso)
      .then(res => res.json()).then((result) => {
        setWait(false);
        setValues({
          ...values,
          processo: result.dadosEmpresa.nProcesso,
        });
      });

  }
  function fechaMapa(ponto) {
    if (ponto && ponto.lat) {
      setValues({ ...values, coordenadas: ponto.lat + ", " + ponto.lng })
    }
    setShowMapa(false);
  }
  const handleLostFocusCNPJ = (e) => {

    setError({
      ...error,
      cnpj: {
        erro: false,
        msg: ''
      }
    });
    const cnpj = values.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
    if (cnpj.length === 0) {
      let v = { ...values };
      v.razaoSocial = null;

      setValues(v);
      setEmpresa({});
      return;
    }
    if (ValidarCNPJ(cnpj)) {
      setWait(true);

      fetch("/api/v1/cnpj/" + cnpj)
        .then(res => (res.status <= 300) ? res.json() : setErro(res))
        .then(
          (result) => {
            setWait(false);
            if (!result) return;
            if (!result.razaoSocial) {
              result.razaoSocial = result.nome;

            }
            setValues({ ...values, razaoSocial: result.razaoSocial });
            setEmpresa(result);
            if (result.atividade) {
              setAtividadePrincipal(result.atividade.atividadePrincipal ? result.atividade.atividadePrincipal[0] : {});
              setAtividadesSecundaria(result.atividade.atividadeSecundaria);
            } else {
              setAtividadePrincipal(result.atividade_principal ? result.atividade_principal[0] : {});
              setAtividadesSecundaria(result.atividades_secundarias);
            }
          },
          (error) => {
            setWait(false);
          }
        )
    } else {
      setError({
        ...error,
        cnpj: {
          erro: true,
          msg: 'CNPJ inválido'
        }
      });
    }
  }

  const handleChangeInputForm = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    if (event.target.name === 'ra') {
      let arr = raAde.find(item => item.nome === event.target.value);
      arr = arr ? arr.ade : [];
      setAdeFiltro(arr);
      if (arr && arr.length === 1) {
        setValues({
          ...values,
          'ade': arr[0],
          [event.target.name]: event.target.value,
        });
      }
    }
    if (event.target.name === 'uos') {
      let item = uos.find(item => item.codigo === event.target.value);
      if (item) {
        setValues({
          ...values,
          uos: event.target.value,
          descricao: item.descricao
        }
        );
      }
    }
  };


  const handleChangePrograma = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  function montaDescUos(lote, uos) {
    if (!uos) return;
    if (!lote.descricao || lote.descricao.length === 0) {
      const item = uos.find(item => item.codigo === lote.uos);
      if (item) setValues({ ...lote, descricao: item.descricao });
    }
  }
  const handleActionClick = (action) => {
    let acao = options[action];

    setAnchorEl(null);

    if (action === 0) {
      history.push("/operacao/EmpresaLoteView")
    }
  };
  const submitForm = (e) => {
    if (values.coordenadas && values.coordenadas.length > 0) {
      const arr = values.coordenadas.split(",");
      values.latitude = arr[0].trim();
      values.longitude = arr[1].trim();
    }

    if (values.area && typeof values.area === 'string') {
      let area = values.area.replace(/[.*+\-?^${}()|[\]\\]/g, '');
      values.area = area.replace(",", ".");
    }

    Service.POST(url, values).then(json => {
      setWait(false);
      if (param.get("id")) {
        setMensagem("Lote alterado com sucesso");
      } else {
        setMensagem("Lote cadastrado com sucesso");
      }
      sair();
    }).catch(error => {
      setWait(false);
      topFunction();
      if( error && error.message && error.message.includes("não cadastrado")){
        setMensagem(error.message);
      }else if( error.error === "Conflict") {
        url = "/api/processoProdf/lote/semVerificacao";
        msgConfirmacao = "A alteração que está sendo feita está diferente do cadastro do processo (" + error.message + "). Caso confirme a alteração, o sistema irá alterar os dados do processo para ficar conforme a alteração que está sendo feita. Deseja continuar?"
        setShowConfirm(true);
        setMensagem(error ? error.message : 'Oops! Something went wrong. Please try again!');
      }else{
        setMensagem(error.message? error.message:error);
      }
    });
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }
  const sair = () => {
    history.replace("/operacao/lotes");
  }
  function podeEnviar() {
    if (!values.ra || !values.ade || !values.endereco || !values.area) {
      return false;
    }
    return true;
  }
  function salvar() {

    setShowConfirm(true);
  }
  const handleDateChangeLicitacao = (date) => {
    setDataLicitacao(date);
    setValues({
      ...values,
      'dataLicitacao': date
    });
  }
  const handleDateChangeIndicacao = (date) => {
    setDataIndicacao(date);
    setValues({
      ...values,
      'dataIndicacao': date
    });
  }
  function relatorio() {

    const dado = values;
    //fetch("/api/processoProdf/requerimento/relatorio", {
    fetch("/api/processoProdf/lote/fichaImovel", {
      method: "POST",
      body: JSON.stringify(dado),
      headers: { "Content-Type": "application/json; charset=utf-8" }
    }).then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        response.json().then((error) => {
          setWait(false);
          setMensagem("Problema ao gerar PDF");
        });
        return;
      }
    }).then(body => {
      if (!body) return;
      var file = window.URL.createObjectURL(body);
      let tab = window.open();
      tab.location.assign(file);
    });
  }


  function createSortHandler(coluna) {
    let or = { ...ordem };
    if (or.campo === coluna) {
      or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
    } else {
      or.campo = coluna;
      or.ordem = 'asc';
    }
    setOrdem(or);

    setBloco(0);
  }
  function transformaDt(valor) {
    if (valor) {
      return FormataDataComHora(new Date(valor));
    }
    return "";
  }

  function isReadOnly() {
    return (disable && disable === true) ? true : false;
  }
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography variant="h5" gutterBottom>Dados Imovel</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={3} className={classes.root}>
                <Grid item xs={3}>
                  <TextField
                    autoFocus
                    label="Numero da Proposta"
                    value={values.proposta}
                    onChange={handleChangeInputForm}
                    name="proposta"
                    id="proposta-mask-input"
                    fullWidth
                    InputProps={{
                      inputComponent: propostaMask,
                      readOnly: (disable && disable === true)
                    }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <ProcessoField
                    handleChangeInputForm={handleChangeInputForm}
                    valor={values.processo}
                    nome="Processo Vinculado"
                    acao={handleSelecionarProcesso}
                    processos={processos}
                    profile={profile}
                    setWait={setWait}
                    fieldReadOnly={disable && disable === true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    id="dataIndicacao"
                    label="Data Indicação"
                    format="dd/MM/yyyy"
                    value={dataIndicacao || null} onChange={handleDateChangeIndicacao}
                    fullWidth
                    KeyboardButtonProps={true}
                    inputVariant="outlined"
                    disabled={(disable && disable === true)}
                    invalidDateMessage="Formato inválido"
                  />
                </Grid>
                <Grid item xs={3} >
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    id="dataLicitacao"
                    label="Data Licitação"
                    format="dd/MM/yyyy"
                    fullWidth
                    value={dataLicitacao || null} onChange={handleDateChangeLicitacao}
                    KeyboardButtonProps={true}
                    inputVariant="outlined"
                    disabled={(disable && disable === true)}
                    invalidDateMessage="Formato inválido"
                  />
                </Grid>
                <Grid item xs={4} >
                  <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">RA</InputLabel>
                    <Select
                      autoWidth={false}
                      fullWidth
                      labelId="demo-simple-select-outlined-label"
                      id="ra"
                      name="ra"
                      value={values.ra || ''}
                      onChange={handleChangeInputForm}
                      label="RA"
                      inputProps={{ readOnly: (disable && disable === true) }}
                    >
                      {
                        raAde && raAde.map((item, index) => (
                          <MenuItem key={index} value={item.nome}>{item.nome}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>

                {/* trazer ade */}
                <Grid item xs={4} >
                  <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label-ade">ADE</InputLabel>
                    {adeFiltro &&
                      <Select
                        labelId="demo-simple-select-outlined-label-ade"
                        id="ade"
                        name="ade"
                        value={values.ade || ''}
                        onChange={handleChangeInputForm}
                        label="ADE"
                        inputProps={{ readOnly: (disable && disable === true) }}
                      >
                        {
                          adeFiltro && adeFiltro.map((item, index) => (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                          ))
                        }
                      </Select>
                    }
                  </FormControl>

                </Grid>
                <Grid item xs={4}>
                  <FormControl variant="outlined" fullWidth disabled={false}>
                    <InputLabel id="programa" >Programa</InputLabel>
                    <Select
                      id="programa"
                      name='programa'
                      label="Programa"
                      placceholder="programa"
                      onChange={handleChangeInputForm}
                      value={values.programa || ''}
                      inputProps={{ readOnly: (disable && disable === true) }}
                    >
                      {
                        programas && programas.map((tp, i) => (
                          <MenuItem key={i} value={tp} >{tp}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="endereco"
                    name="endereco"
                    label="Endereço"
                    value={values.endereco || ''} onChange={handleChangeInputForm}
                    variant="outlined"
                    inputProps={{ readOnly: (disable && disable === true) }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Situação Lote</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="situacao"
                      name="situacao"
                      value={values.situacao || ''}
                      onChange={handleChangeInputForm}
                      label="Situaçao Lote"
                      inputProps={{ readOnly: (disable && disable === true) }}
                    >
                      {
                        situacao && situacao.map((item, index) => (
                          <MenuItem key={index} value={item.situacao}>{item.situacao}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                {/* situacao sigafe */}

                <Grid item xs={6}>
                  <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                    <InputLabel id="demo-simple-select-outlined-label">Situação Sigafe</InputLabel>
                    {situacaoSigafe &&
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="situacaoSigafe"
                        name="situacaoSigafe"
                        value={values.situacaoSigafe || ''}
                        onChange={handleChangeInputForm}
                        label="Situaçao Sigafe"
                        inputProps={{ readOnly: (disable && disable === true) }}>
                        {
                          situacaoSigafe && situacaoSigafe.map((item, index) => (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                          ))
                        }
                      </Select>
                    }
                  </FormControl>
                </Grid>
                <Grid item xs={6} >
                  {/* <TextField
                    fullWidth
                    id="area"
                    name="area"
                    label="Area (m²)"
                    value={values.area || ''} onChange={handleChangeInputForm}
                    variant="outlined"
                    inputProps={{ readOnly: (disable && disable === true) }}
                    type="number" step="any"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /> */}
                  <TextField
                    fullWidth
                    id="area"
                    name="area"
                    label="Area (m²)"
                    value={values.area || ''}
                    onChange={handleChangeInputForm}
                    variant="outlined"
                    InputProps={{
                      inputComponent: myField
                    }}
                  />

                </Grid>
                <Grid item xs={disable ? 6 : 5} >
                  <TextField
                    fullWidth
                    id="coordenadas"
                    name="coordenadas"
                    label="Coordenadas: Lat, Lng"
                    value={values.coordenadas || ''} onChange={handleChangeInputForm}
                    inputProps={{ readOnly: (disable && disable === true) }}
                    variant="outlined"
                  />
                </Grid>
                {!disable &&
                  <Grid item xs={1}>
                    <Tooltip title='Buscar Localidade'>
                      <IconButton onClick={() => setShowMapa(true)} >
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                }
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br /><br />
        <Grid item xs={12}>

          <Accordion defaultExpanded={location.pathname.includes("estoqueLoteView") ? false : true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography align="left" variant="h5">Destinação</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12} >
                  <FormControl variant="outlined" className={classesLocal.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">UOS</InputLabel>
                    {uos &&
                      <Select
                        style={{ maxWidth: 229, minWidth: 50 }}
                        autoWidth={false}
                        labelId="demo-simple-select-outlined-label"
                        id="uos"
                        name="uos"
                        value={values.uos || ''}
                        // onBlur={buscarUos}
                        onChange={handleChangeInputForm}
                        label="UOS"
                        inputProps={{ readOnly: (disable && disable === true) }}
                      >
                        {
                          uos && uos.map((item, index) => (
                            <MenuItem key={index} value={item.codigo}>{item.codigo}</MenuItem>
                          ))
                        }
                      </Select>
                    }
                  </FormControl>

                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="descricao"
                    name="descricao"
                    label="Descrição"
                    multiline
                    rows={6}
                    value={values.descricao || ''}
                    onChange={handleChangeInputForm}
                    inputProps={{ readOnly: (disable && disable === true) }}
                    variant="outlined"
                  />
                </Grid>

              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        <Grid item xs={12}>
          <Accordion defaultExpanded={location.pathname.includes("estoqueLoteView") ? false : true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography align="left" variant="h5">Dados do Interessado</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} className={classes.root}>
                <Grid item xs={4}>
                  <TextField
                    required fullWidth
                    error={error.cnpj.erro}
                    helperText={error.cnpj.msg}
                    label="CNPJ"
                    value={values.cnpj}
                    onChange={handleChangeInputForm}
                    onBlur={handleLostFocusCNPJ}
                    name="cnpj"
                    id="cnpj-mask-input"
                    InputProps={{
                      inputComponent: CNPJMask
                    }}
                    variant="outlined"
                    inputProps={{ readOnly: (disable && disable === true) }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField fullWidth
                    id="razaoSocial"
                    label="Razão Social"
                    value={values.razaoSocial || ''}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField

                    value={empresa.atividade && empresa.atividade.atividadePrincipal[0].code || ""}
                    fullWidth
                    label="CNAE Atividade principal"
                    id="cneaAtividadePrincipal"
                    name="cneaAtividadePrincipal"
                    onChange={handleChangeInputForm}
                    aria-describedby="nova-helper-text"
                    variant="outlined"
                    InputProps={{
                      readOnly: isReadOnly(),
                    }}
                  />
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    value={empresa.atividade && empresa.atividade.atividadePrincipal[0].text || ""}
                    fullWidth
                    label="Descrição atividade principal"
                    id="descricaoAtividadePrincipal"
                    name="descricaoAtividadePrincipal"

                    onChange={handleChangeInputForm}
                    aria-describedby="nova-helper-text"
                    variant="outlined"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>
                <Typography variant="body1" gutterBottom>CNAE Atividades secundarias:</Typography>
                {empresa.atividade && empresa.atividade.atividadeSecundaria &&
                  <GenericTable disableSearch={true}
                    dados={empresa.atividade.atividadeSecundaria} colunas={[
                      { nome: 'CNAe', campo: 'code' },
                      { nome: 'Descrição', campo: 'text' },
                    ]} />
                }

              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <br />
        {param.get("id") &&
          <Grid item xs={12}>
            <Accordion defaultExpanded={location.pathname.includes("estoqueLoteView") ? false : true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography align="left" variant="h5">Observações (Importadas)</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} className={classes.root}>
                  <Grid item xs={12}>
                    <TextField
                      id="historicoVistoria"
                      name="historicoVistoria"
                      label="Historico de Vistoria"
                      multiline
                      rows={4} fullWidth
                      value={values.historicoVistoria || ''}
                      onChange={handleChangeInputForm}
                      inputProps={{ readOnly: (param.get("id") ? true : false) }}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField multiline rows={4} fullWidth
                      id="vistoriaTerracap"         // observacao_terracap
                      name="vistoriaTerracap"       // observacao_terracap
                      label="Historico do Imovel"   // observacao_terracap
                      value={values.observacao || ''}  // observacao_terracap
                      onChange={handleChangeInputForm}
                      inputProps={{ readOnly: (param.get("id") ? true : false) }}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField multiline rows={4} fullWidth
                      id="outrasObservacoes" name="outrasObservacoes"
                      label="Andamento do Processo"
                      value={values.outrasObservacoes || ''}
                      onChange={handleChangeInputForm}
                      inputProps={{ readOnly: (param.get("id") ? true : false) }}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        }
        <Grid item xs={12}>
          <TextField multiline rows={4} fullWidth
            id="observacoes" name="observacoes"
            label="Observações"
            value={values.observacoes || ''}
            onChange={handleChangeInputForm}
            inputProps={{ readOnly: disable }}
            variant="outlined"
          />
        </Grid>
        {values.id &&
          <Grid item xs={12}>
            <Accordion defaultExpanded={false} onChange={handleChange('vistoria')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography align="left" variant="h5">Historico de Vistorias</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} className={classes.root}>
                  <GenericTable
                    dados={vistoria}
                    actionClick={handleActionSolicitarClick}
                    options={['Visualizar']}
                    colunas={[
                      { nome: 'Protocolo', campo: 'protocolo' },
                      { nome: 'Data solicitação', campo: 'dataSolicitacao', transformacao: formataData },
                      { nome: 'Assunto', campo: 'assunto' },
                      { nome: 'Ação', campo: 'action' },
                    ]} />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        }
        {values.id &&
          <Grid item xs={12}>
            <Accordion defaultExpanded={false}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography align="left" variant="h5">Historico de Alterações</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3} className={classes.root}>

                  <GenericTable dados={values.historico} colunas={[
                    { nome: 'Data', campo: 'data', 'transformacao': transformaDt },
                    { nome: 'Responsável', campo: 'responsavel.nome' },
                    { nome: 'Ação', campo: 'acao' },
                    { nome: 'Observacao', campo: 'texto' },
                  ]} />
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        }

        <Grid container justify="flex-end" className={classes.btns}>
          <Button variant="contained" color="secondary" href={location.pathname.includes("estoqueLoteExcluidoView") ? "/operacao/manutencaoLotes" : "/operacao/lotes"} rel="noopener noreferrer">
            Sair
          </Button>
          {location.pathname.includes("estoqueLoteView") &&
            <Button variant="contained" color="primary" href={"/operacao/estoqueLoteForm?id=" + param.get("id")} rel="noopener noreferrer">
              Alterar
            </Button>
          }
          {!location.pathname.includes("estoqueLoteExcluidoView") &&
            <Button variant="contained" color="primary" onClick={() => relatorio()} >
              Imprimir ficha do imóvel
            </Button>
          }

          {!disable &&
            <Button variant="contained" color="primary" onClick={() => salvar()} disabled={!podeEnviar()}>
              Salvar
            </Button>
          }
        </Grid>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={openAction}
          onClose={handleClose}
        >
          {options.map((option, index) => (
            <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleActionClick(index)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
        <ConfirmDialog
          open={showConfirm}
          setOpen={setShowConfirm}
          titulo={'Confirmação'}
          msg={msgConfirmacao}
          exec={submitForm}
        />
        <MapaDialog onClose={fechaMapa} open={showMapa} empresa={empresa} />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
