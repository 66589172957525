
import React, { useState, useEffect, } from 'react';
import {
     Paper, TextField, Typography,
    Accordion, AccordionSummary, AccordionDetails, 
} from '@material-ui/core';
import { useStyles, getQueryParams, API_BASE_URL } from "../../../constants";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InformacaoBasicaForm from "./InformacaoBasicaForm";
import EnderecoForm from "./EnderecoForm";
import InformacoesForm from "./InformacoesForm";
import FiliaisForm from "./FiliaisForm";
import SocioDiretoresForm from "./SocioDiretoresForm";

export default function DadosCadastraisEmpresaForm(props) {
    const classes = useStyles();
    const {value, setValue , setWait, setMensagem, disable} = props;

    return(
        // <Container maxWidth='md'>
            <Paper >
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Informações Básicas</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InformacaoBasicaForm value={value} setValue={setValue} disable={disable}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Sede</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <EnderecoForm value={value} setValue={setValue} setMensagem={setMensagem} setWait={setWait} disable={disable}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Informações</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <InformacoesForm value={value} setValue={setValue} disable={disable}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Filiais da Empresa</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FiliaisForm value={value} setValue={setValue} setWait={setWait} disable={disable}/>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Sócios/Diretores</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SocioDiretoresForm value={value} setValue={setValue} setWait={setWait} disable={disable}/>
                    </AccordionDetails>
                </Accordion>
            </Paper>
        // </Container>
    )

}