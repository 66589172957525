import React, { useEffect, useState } from 'react';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import {
    Box, Grid, IconButton, Button, Paper, Menu, MenuItem,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip, Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CampoBusca from "../../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useHistory } from "react-router-dom";
import { useStyles, filtroDadoGenerico } from "../../../constants";
import Service from '../../../services/Service';

const options = [
    'Visualizar',
    'Atualizar',
    'Excluir'
];

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;
let id;



export default function SituacaoLoteTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, titulo, setWait } = props;

    const [showConfirmExclusao, setShowConfirmExclusao]= useState(false);
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);
    const history = useHistory();

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    const handleAcao = (e, index) => {
        indexProcesso = index;
        setAnchorEl(e.currentTarget);
    }

    const handleActionClick = (action) => {
        let processo = fonteDeDados[indexProcesso];
        const id = dadosFiltrado[indexProcesso].id;
        let acao = options[action];

        setAnchorEl(null);

        if(action === 0){
            history.push("/operacao/situacaoLotesView?id="+id)

        }
        if(action === 1){
            history.push("/operacao/situacaoLotesForm?id="+id)
        }
        if(action === 2){
            setShowConfirmExclusao(true);
        }        
    };
    

  
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
    }

    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
            } else {
                disparaSearch();
            }
        }, tempo);
    }

    function getTituloSituacao(row) {

        return "Visualizar";
    }

    useEffect(() => {
        setTitulo("Gerenciar situação do lote")
        // setWait(true);
        Service.GET("/api/processoProdf/situacaoLote").then(result => {
            // setWait(false);
            fonteDeDados = [];
                    for(let x in result){
                        const d = result[x];
                        fonteDeDados.push(
                            {
                                id: d.id,
                                situacao: d.situacao,
                                descricao: d.descricao,
                                
                            }
                        );
                    }
                    filtraDado();
        }).catch(error => {
            // setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
        
        // fetch("/api/processoProdf/situacaoLote",
        //     {
        //         method: 'GET',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Accept: 'application/json',
        //             'Authorization': `Bearer ${localStorage.accessToken}`
        //         },
              
        //     }).then(res => res.json()).then((result) => {
             
        //         if (result.status && result.status > 300) {
        //             setErro(result);
        //         } else {
        //             fonteDeDados = [];
        //             for(let x in result){
        //                 const d = result[x];
        //                 fonteDeDados.push(
        //                     {
        //                         id: d.id,
        //                         situacao: d.situacao,
        //                         descricao: d.descricao,
                                
        //                     }
        //                 );
        //             }
        //             filtraDado();
        //         }
        //     });
    }, [ordem, pagina, bloco]);

    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];
        let arrOrdenado = ordenar(fonteDeDados);

        filtroDadoGenerico(arrOrdenado, arr, filtroCampo);

        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (++index >= pagina) break;
        }
        setDadosFiltrado(arrBloco);
    }

    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);

        setBloco(0);
    }

    function confirmaExclusao(){
        const id = dadosFiltrado[indexProcesso].id;
        fetch("/api/processoProdf/situacaoLote/"+id,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then((result) => {
                if (result.status && result.status > 300) {
                    setErro("Situação em uso e não pode ser excluida");
                } else {
                    let value = [];
                    if (fonteDeDados && (fonteDeDados.length > 0)) {
                        for (let i = 0; i < fonteDeDados.length; i++) {
                            if (fonteDeDados[i].id !== id) {
                            value.push(fonteDeDados[i]);
                            }
                        }
                    }
                    fonteDeDados = value
                    filtraDado();
                }
            });
    }

  
    return (
        <Grid container spacing={3} className={classes.root}>
            
            <Grid item xs={12} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>                               

                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'proces'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('proces')}
                                    >
                                        Situação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'ra'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('ra')}
                                    >
                                        Descriação
                                    </TableSortLabel>
                                </TableCell>
                        

                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={i}>
                                    
                                    <TableCell>{row.situacao}</TableCell>
                                    <TableCell>{row.descricao}</TableCell>
                                    <TableCell align='center'>
                                       <Box>
                                            
                                            <Tooltip title={getTituloSituacao(row)}>
                                                <IconButton  onClick={(e) => handleAcao(e, i)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                
                {
                    fonteDeDados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ qtd }
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }
                

            </Grid>
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>

                <Button variant="contained" color="primary" href={"/operacao/situacaoLotesForm"} >
                    Cadastrar
                </Button>
                
            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                    // style: {
                    //     maxHeight: ITEM_HEIGHT * 4.5,
                    //     width: '20ch',
                    // },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleActionClick(index)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>

            <ConfirmDialog
                    open={showConfirmExclusao}
                    setOpen={setShowConfirmExclusao}
                    titulo={'Confirmação'}
                    msg='Confirma exclusão do item selecionado?.'
                    exec={confirmaExclusao}
                />
        </Grid>


    );
}
