
import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
} from '@material-ui/core';
import MaskedInput from 'react-text-mask'
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useStyles, getQueryParams, API_BASE_URL } from "../../../constants";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Download from "../../../util/Download";
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import SearchIcon from '@material-ui/icons/Search';
import BuscaDadoDialog from "../../../util/BuscaDadoDialog";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Remove';

function TelMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TelMask2(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

let tipoAnexo = null;
let currentRow = null;
//let id = null;
let acaoSubmit = null;

export const useStylesLocal = makeStyles((theme) => ({
    btns: {
        marginTop: '10px',
        '& > *': {
            margin: '4px'
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    paper: {
        width: '100%',
        height: '180px',
        overflow: 'auto'
    }
}));

export default function RequerimentoForm(props) {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    let { id } = useParams();
    const [processo, setProcesso] = useState();
    const { setTitulo, setMensagem, setWait, disable ,setSuccess} = props;
    const [processos, setProcessos] = useState([]);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const location = useLocation();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showTel1, setShowTel1] = useState(false);
    const [assuntos, setAssunto] = useState(['']);
    const [txt, setTxt] = useState('');

    const [value, setValue] = useState({
        assunto: '',
        processo: {
            nProcesso: ''
        },
        cpf: '',
        nome: '',
        telefone: '',
        email: '',
        protocolo: '',
        tipo: '',
        declaracao: false,
    });
    const [arquivo, setArquivo] = useState();
    const [anexos, setAnexos] = useState([{ tipo: -1 }]);
    const history = useHistory();
    const param = getQueryParams(useLocation().search);
    let [profile] = useCurrentUser();


    const [error, setError] = useState(resetErro());
    const [tipoDocumento, setTipoDocumento] = useState('');


    useEffect(() => {

        if (profile) {
            const identificador = param.get('id');
            if (identificador) return;
            setValue({
                ...value,
                nome: profile.name,
                cpf: profile.CPF,
                email: profile.email,
                telefone: profile.telefone
            });
            if (param.get('IdProcesso')) {
                buscaProcessoPorId(param.get('IdProcesso'));
            }
        }
    }, [profile]);

    useEffect(() => {
        setTitulo(location.pathname.includes("solicitarRequerimentoView") ? "Visualizar Requerimento" : "Solicitar Requerimento");
        buscaTipoDocumento();


    }, [])
    function buscaDados() {
        // id = param.get('id');
        const identificador = id ? id : param.get('id');
        if (identificador) {
            setWait(true);
            fetch('/api/processoProdf/requerimento/' + identificador, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        setValue({
                            ...json,
                            nome: json.requerente.nome,
                            cpf: json.requerente.cpf ? json.requerente.cpf : '',
                            email: json.requerente.email,
                            telefone: json.requerente.telefone ? json.requerente.telefone : ''
                        });
                        let arr = []
                        if (json.documentos) {
                            arr = json.documentos;
                        }

                        setAnexos(arr);

                        setTxt(json.descricao ? json.descricao : '');
                        setProcesso(json.processo);
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        }
    }
    function buscaProcessoPorId(idProcesso) {
        setWait(true);
        fetch('/api/gerenciarProcesso/byIdProcesso/' + idProcesso, {
            method: 'GET'
        }).then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    setWait(false);

                    setValue({
                        ...value,
                        processo: {
                            id: json.idProcesso,
                            nProcesso: json.nProcesso,
                            cnpj: json.cnpj,
                            razaoSocial: json.razaoSocial,
                            programa: json.programa
                        },
                        nome: profile.name,
                        cpf: profile.CPF,
                        email: profile.email,
                        telefone: profile.telefone
                    });

                    setProcesso({
                        id: json.idProcesso,
                        nProcesso: json.nProcesso,
                        cnpj: json.cnpj,
                        razaoSocial: json.razaoSocial,
                        programa: json.programa
                    })
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function atualizaProcesso(pro) {
        setProcesso(pro);
        setValue({
            ...value,
            processo: {
                id: pro.idProcesso,
                nProcesso: pro.nProcesso,
                cnpj: pro.cnpj,
                razaoSocial: pro.razaoSocial,
                programa: pro.programa
            }
        });
    }
    const handleCheck = (e) => {
        setValue({
            ...value,
            declaracao: !value.declaracao
        });
    }

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };
    function buscaTipoDocumento() {
        fetch('/api/info/tipoDocumento')
            .then(response => response.json())
            .then(json => {
                tipoAnexo = json;
                let arr = [''];
                json.forEach(item => arr.push(item.tipo));
                setAssunto(arr);
                buscaDados();
            });

    }

    function confirmaRequerimento() {
        setError(resetErro());
        let dado = { ...value };
        dado.requerente = {
            nome: profile.name,
            email: profile.email,
            id: profile.id,
            cpf: profile.CPF,
            telefone: profile.telefone,
        };
        if (value.status === 'PENDENTE') {
            dado.status = "EM_ANALISE";
            dado.historico = [{
                tipo: "Reenviado",
                data: new Date(),
                texto: "Solicitação reenviada pelo empresario",
                responsavel: {
                    id: profile.id,
                    nome: profile.name,
                    email: profile.email
                }
            }];

        } else {
            dado.status = "EM_ANALISE";
            dado.historico = [{
                tipo: "EM_ANALISE",
                data: new Date(),
                texto: "Solicitação do empresario",
                responsavel: {
                    id: profile.id,
                    nome: profile.name,
                    email: profile.email
                }
            }];
        }

        // dado.documentos = anexos;
        dado.documentos = [];//anexos;
        anexos.forEach(a => {
            if (a.tipo !== -1) dado.documentos.push(a);
        })
        dado.descricao = txt;
        acaoSubmit = "tabela";
        if (valida(dado)) {
            submitForm(dado);
        } else {
            setTimeout(function () {
                document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
            }, 300);
        }
        delete dado.cpf;
        delete dado.telefone;
        delete dado.email;
    }

    const enviarRascunho = () => {
        let e = resetErro();
        setError(e);
        let dado = { ...value };

        dado.requerente = {
            nome: profile.name,
            email: profile.email,
            id: profile.id,
            cpf: profile.CPF,
            telefone: profile.telefone,
        };
        // dado.documentos = anexos;
        if (value.status === 'PENDENTE') {
            dado.status = "PENDENTE";
        } else {
            dado.status = "RASCUNHO";
        }
        dado.descricao = txt;
        acaoSubmit = null;
        dado.documentos = [];//anexos;
        anexos.forEach(a => {
            if (a.tipo !== -1) dado.documentos.push(a);
        })

        submitForm(dado);
    }

    function submitForm(dado) {
        setWait(true);
        if (isAssuntoDiversos()) {
            dado.processo = {
                nProcesso: ''
            };
        }
        fetch('/api/processoProdf/requerimento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                if (!acaoSubmit) {

                    setSuccess("Rascunho salvo com sucesso.")

                    history.replace("/gerenciaProcesso/solicitarRequerimentoTabela");

                } else if (acaoSubmit === 'tabela') {
                    response.json().then(json => {
                        if (json.historico.length === 1) {
                            setSuccess("Requerimento criado com sucesso. protocolo:" + json.protocolo);
                        } else {
                            setSuccess("Requerimento salvo com sucesso.");
                        }
                        history.replace("/gerenciaProcesso/solicitarRequerimentoTabela");


                    });

                }
                else {
                    history.goBack();
                }
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function relatorio() {
        var a = document.createElement("a");
        a.href = API_BASE_URL + "/api/processoProdf/requerimento/relatorio/" + id;
        a.setAttribute("target", "_blank");
        a.click();
    }
    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: { erro: false, msg: '' },
            telefone: { erro: false, msg: '' },
            email: { erro: false, msg: '' }
        }
    }
    function valida(dado) {
        if (!dado.declaracao) {
            setMensagem("Para prosseguir a analise é necessario declararar a entrega total da documentação");

            return false
        }

        return true
    }
    const handleChangeCampo = (event) => {
        const tipo = event.target.value;
        setValue({
            ...value,
            assunto: tipo
        }
        );

        const tps = tipoAnexo.find(i => i.tipo === tipo);
        if (tps) {
            const arr = [];
            tps.lst.forEach(item => {
                arr.push({
                    tipo: item
                })
            })
            if (arr.length === 0) {
                arr.push({ tipo: -1 });
            }
            setAnexos(arr);
        } else {
            setAnexos([{ tipo: -1 }]);
        }

    }
    const handleEscolheFile = () => {
        let file = document.getElementById("file");
        file.click();
    }

    const handleAnexo = (row) => {
        currentRow = row;

        fileClick();
    }
    function fileClick() {
        let file = document.getElementById("file");
        file.click();
    }
    function isAssuntoDiversos() {
        return value.assunto === 'Diversos';
    }
    function visivelEnviar() {
        if (disable) return false;
        if (value && value.status && value.status === 'DEFERIDO') return false;
        if (value && value.status && value.status === 'EM_ANALISE') return false;
        return true;

    }
    function desabilitado() {
        if (disable) return true;
        if (value && value.status && value.status === 'DEFERIDO') return true;
        if (value && value.status && value.status === 'EM_ANALISE') return true;
        return false;
    }
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();
        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();

                    if (currentRow) {
                        let arr = [...anexos];
                        arr.forEach(i => {
                            if (i === currentRow) {
                                i.nome = nm;
                                i.uuid = result.uuid;
                            }
                        });
                        setAnexos(arr);
                        currentRow = null;
                    } else {
                        let arr = [];
                        arr.push({
                            tipo: tipoDocumento,
                            nome: nm,
                            uuid: result.uuid
                        });
                        anexos.forEach(a => {
                            if (a.tipo !== -1) arr.push(a)
                        });
                        arr.push({ tipo: -1 });
                        setAnexos(arr);
                        // setArquivo({
                        //     nome: nm,
                        //     uuid: result.uuid
                        // });
                    }
                    // document.getElementById("arquivo").value= nm;
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }

    function podeEnviarRascunho() {
        // if (!txt || txt.length === 0) return true;
        if (!isAssuntoDiversos()) {
            if (value.processo.nProcesso.length === 0) return true;
        }
        return false;
    }

    function podeEnviar() {
        if (!value.assunto || value.assunto.length == 0) return true;
        if (!txt || txt.length === 0) return true;
        if (!isAssuntoDiversos()) {
            if (value.processo.nProcesso.length === 0) return true;
        }
        return false;
    }
    const handleTelLostFocus = () => {
        setShowTel1(value.telefone.trim().length !== 16);
    }
    const handleTelFocus = () => {
        setShowTel1(false);
    }
    function removeArquivo(item, index) {
        if (item.tipo && item.tipo.length > 0) {
            let itens = [...anexos];
            itens[index].nome = null;
            itens[index].uuid = null;

            setAnexos(itens);
        } else {
            let arr = [];
            anexos.forEach(it => {
                if (it.uuid !== item.uuid) {
                    arr.push(it);
                }
            });

            setAnexos(arr);
        }
    }
    function isShowPendencia() {
        return value && (value.status === 'PENDENTE');
    }
    function showPendencia() {
        if (value && value.historico) {
            const h = value.historico.filter(h => h.tipo === 'PENDENTE');
            return h && h.length > 0 ? h[h.length - 1].texto : ''
        }
        return '';
    }
    function isShowReprovado() {
        return value && (value.status === 'REPROVADO');
    }
    function showReprovado() {
        if (value && value.historico) {
            const h = value.historico.filter(h => h.tipo === 'REPROVADO');
            return h && h.length > 0 ? h[h.length - 1].texto : ''
        }
        return '';
    }
    function isShowParecerSolicitacao() {
        const lastFluxo = (value && value.fluxoPath) ? value.fluxoPath[value.fluxoPath.length - 1] : '';
        return lastFluxo === 'deferido' || lastFluxo === 'indeferido';
    }
    function showParecer() {
        if (value && value.historico) {
            const h = value.historico.find(h => h.tipo === 'INDEFERIDO' || h.tipo === 'DEFERIDO');
            return h ? h.texto : ''
        }
        return '';
    }

    const handleAnexar = (e) => {
        let arr = [...anexos];
        arr.push({
            tipo: tipoDocumento,
            nome: arquivo.nome,
            uuid: arquivo.uuid
        });
        setAnexos(arr);
        setArquivo();
        setTipoDocumento('');
    }
    const buscaProcessoFiltrado = () => {
        setWait(true);
        fetch("/api/gerenciarProcesso/filtro",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify({
                    cnpjs: profile.empresas
                }),
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setMensagem(result);
                } else {
                    setProcessos(result);

                    setShowBuscaDadoDialog(true);
                }
            });
    }
    const sair = () => {
        let urlRet = "/gerenciaProcesso/solicitarRequerimentoTabela";
        if (param.get('back')) {
            urlRet = param.get('back');
            if (param.get("open")) {
                urlRet += "?open=" + param.get("open")
            }
        }
        history.replace(urlRet);
    }


    function docEmpresa(){
        if(value.assunto === "Requerimento transferência de incentivo – PRO-DF II"
        || value.assunto === "Requerimento transferência de incentivo – DESENVOLVE-DF"
        || value.assunto === "Requerimento revogação + transferência"
        || value.assunto === "Requerimento migração + transferência – para análise da admissibilidade"
        || value.assunto === "Requerimento migração + transferência – após deferida admissibilidade"
        || value.assunto === "Requerimento migração + revogação + transferência – para análise da admissibilidade"
        || value.assunto === "Requerimento migração + revogação + transferência – após deferida admissibilidade"
        || value.assunto === "Requerimento convalidação + transferência"
        || value.assunto === "Requerimento convalidação + revogação + transferência"){
            return true;
        }
    }

    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={10}>
                        <FormControl variant="outlined" fullWidth disabled={desabilitado()}>
                            <InputLabel id="assutno-label">Assunto</InputLabel>
                            <Select id="assunto" label="Assunto" placceholder="Assutno" onChange={handleChangeCampo} value={value.assunto} >
                                {assuntos && assuntos.map((assunto, i) => (
                                    <MenuItem key={i} value={assunto}>{assunto}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={value.protocolo || ''}
                            disabled
                            fullWidth
                            label="Protocolo"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    {!isAssuntoDiversos() &&
                        <>
                            <Grid item xs={visivelEnviar() ? 3 : 4}>
                                <TextField
                                    value={processo && processo.nProcesso || ''}
                                    disabled
                                    fullWidth
                                    label="Processo"
                                    id="processo"
                                    name="processo"
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            {visivelEnviar() &&
                                <Grid item xs={1}>
                                    <Tooltip title='Buscar Processo'>
                                        <IconButton onClick={buscaProcessoFiltrado} >
                                            <SearchIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            }
                            <Grid item xs={3}>
                                <TextField
                                    value={processo && processo.cnpj || ''} disabled
                                    fullWidth
                                    label="CNPJ"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    value={value.processo.programa || ''} disabled
                                    fullWidth
                                    label="Programa"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField disabled
                                    value={processo && processo.razaoSocial || ''}
                                    fullWidth
                                    label="Razão Social"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={4}>
                        <TextField
                            disabled
                            label="CPF"
                            value={value.cpf || ''}
                            error={error.cpf.erro}
                            helperText={error.cpf.msg}
                            name="cpf"
                            id="cpf-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: CPFMask,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled
                            value={value.nome || ''}
                            fullWidth
                            label="Requerente"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            disabled
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleTelLostFocus}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'none' : 'block' }}
                            name="telefone"
                            id="tel-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask,
                            }}
                            variant="outlined"
                        />
                        <TextField
                            disabled
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'block' : 'none' }}
                            name="telefone"
                            id="tel-mask-input1"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask2,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            disabled
                            error={error.email.erro}
                            helperText={error.email.msg}
                            fullWidth
                            label="Email"
                            value={value.email || ''}
                            onChange={handleChangeInputForm}
                            InputProps={{
                                readOnly: false,
                            }}
                            name="email"
                            id="email-input"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12} >
                        <CKEditor
                            editor={ClassicEditor} disabled={desabilitado()}
                            config={{
                                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                    '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                            }}
                            // config={{
                            //     toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                            //         '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                            // }}
                            data={txt}
                            onReady={editor => {
                                editor.editing.view.change(writer => {
                                    writer.setStyle(
                                        "height",
                                        "250px",
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                            onChange={(event, editor) => {
                                setTxt(editor.getData());
                            }}
                            onBlur={(event, editor) => {

                            }}
                            onFocus={(event, editor) => {

                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <TableContainer component={Paper} >
                                    <Table aria-label="customized table" size={'small'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Tipo Documento
                                                </TableCell>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Documento
                                                </TableCell>
                                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                                    Ação
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {anexos && anexos.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{row.tipo === -1 ? "" : row.tipo}</TableCell>
                                                    <TableCell>{row.nome}</TableCell>
                                                    <TableCell>
                                                        {row.tipo === -1 && visivelEnviar() &&
                                                            <Tooltip title="Incluir documento">
                                                                <IconButton onClick={(e) => handleEscolheFile()}>
                                                                    <AttachFileIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {row.tipo !== -1 &&
                                                            <center>
                                                                {
                                                                    !desabilitado() ? (
                                                                        !row.nome ?
                                                                            <Box>
                                                                                {

                                                                                    (row.arquivo || row.doc) ?
                                                                                        <Tooltip title="Remover documento">
                                                                                            <IconButton onClick={(e) => removeArquivo(row, i)}>
                                                                                                <DeleteIcon />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                        :
                                                                                        <Tooltip title="Incluir documento">
                                                                                            <IconButton onClick={(e) => handleAnexo(row)}>
                                                                                                <AttachFileIcon />
                                                                                            </IconButton>
                                                                                        </Tooltip>

                                                                                }
                                                                            </Box> :
                                                                            // <Download doc={row}/>
                                                                            <Tooltip title="Remover documento">
                                                                                <IconButton onClick={(e) => removeArquivo(row, i)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        // <Tooltip title='Remover'>
                                                                        //     <IconButton onClick={(e)=>removeArquivo(row)} >
                                                                        //         <RemoveIcon />
                                                                        //     </IconButton>
                                                                        // </Tooltip>

                                                                    )
                                                                        :
                                                                        <Box>
                                                                            {row.nome && <Download doc={row} />}
                                                                        </Box>

                                                                }
                                                            </center>
                                                        }
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>
                                    Decreto nº 41.015, de 22 de julho de 2020:
                                </Typography>
                                <br />

                                {docEmpresa() &&
                                    <Typography variant="body2" gutterBottom>
                                        Toda a documentação apresentada é referente à empresa recebente
                                    </Typography>
                                }
                                {docEmpresa() &&
                                    <br />
                                }
                                <Typography variant="body2" gutterBottom>
                                    Art. 84. Nos casos de documentação incompleta, ainda que apresentada na data limite, será concedido à empresa o prazo de 30 dias corridos para saneamento, após o que, caso permaneça a omissão, o requerimento será inadmitido pela SEDET, e arquivado sem análise ou encaminhado para abertura de procedimento de cancelamento, conforme a situação.
                                    Parágrafo único. Não será realizada a análise técnica acerca do pedido enquanto não finalizada a juntada da documentação faltante.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={value.declaracao}
                                            disabled={desabilitado()}
                                            onChange={handleCheck}
                                            name="declaracao"
                                            color="primary"
                                        />
                                    }
                                    label="DECLARO A ENTREGA DA DOCUMENTAÇÃO"
                                />
                            </Grid>

                            {isShowParecerSolicitacao() &&
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classesLocal.heading}>Parecer Técnico</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <span className={classesLocal.paper} dangerouslySetInnerHTML={{
                                                __html: showParecer()
                                            }}></span>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }
                            {isShowPendencia() &&
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classesLocal.heading}>Pendência</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <span className={classesLocal.paper} dangerouslySetInnerHTML={{
                                                __html: showPendencia()
                                            }}></span>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }
                            {isShowReprovado() &&
                                <Grid item xs={12}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography className={classesLocal.heading}>Reprovado</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                            <span className={classesLocal.paper} dangerouslySetInnerHTML={{
                                                __html: showReprovado()
                                            }}></span>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }

                        </Grid>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                            Sair
                        </Button>

                        {
                            value.id &&
                            <Button variant="contained" color="primary" onClick={() => relatorio()}>
                                Visualizar
                            </Button>
                        }

                        {visivelEnviar() &&
                            <Button variant="contained" color="primary" onClick={enviarRascunho} disabled={podeEnviarRascunho()}>
                                Salvar rascunho
                            </Button>
                        }
                        {
                            visivelEnviar() &&
                            <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} disabled={podeEnviar() || !value.declaracao}>
                                Solicitar
                            </Button>
                        }

                    </Grid>
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirma solicitação de requerimento?'
                        exec={confirmaRequerimento}
                    />
                </Grid>
                <BuscaDadoDialog
                    open={showBuscaDadoDialog}
                    setOpen={setShowBuscaDadoDialog}
                    acao={atualizaProcesso}
                    colunas={[
                        { nome: 'CNPJ', campo: 'cnpj' },
                        { nome: 'Nome', campo: 'razaoSocial' },
                        { nome: 'Processo', campo: 'nProcesso' },
                    ]}
                    dados={processos} />
            </Paper>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />

        </Container>
    );
}
