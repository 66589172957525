import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import {TextField} from '@material-ui/core';

const useStylesLocal = makeStyles(theme => ({
    input: {
      width: 80,
      height: 30
    },
    table: {
        minWidth: 700
    },
    tablecell: {
      fontSize: 14,
      fontWeight: 700
    }
  }));


  export default function TableMoedaCell(props) {
    const classes = useStylesLocal();
    const { index, row, name, onChange, valor, calcula, disabled, proximo, nome, componenteAtual, clikeConsoeLog } = props

    const [edit, setEdit] = useState(false);

    useEffect(()=>{
      if(componenteAtual && componenteAtual === nome){
        setEdit(true);
      }
    },[componenteAtual])
    const handleClick=(e)=>{
       if (clikeConsoeLog) clikeConsoeLog(index);
        if( !disabled) setEdit( true );
    }
    const handleKeyPress = (e)=>{
        if (e.keyCode === 13) {
            e.preventDefault();
            handleFocus();
            if( proximo) proximo(nome);
        }else if (e.keyCode === 27) {
            e.preventDefault();
            setEdit( false );
        }
    }
    const handleFocus = (e)=> {
        if( calcula ){
            calcula();
        }
       
        setEdit( false )
    }
    return (
      <TableCell align="right" onClick={ handleClick } >
        {edit ? (
          <TextField autoFocus
            value={row[name]}
            name={name}
            onChange={e =>onChange(e, row, index)}
            onBlur={handleFocus}
            onKeyDown={handleKeyPress}
            className={classes.input}
            focused
          />
        ) : (
            valor
        )}
      </TableCell>
    );
  };
