import React, { useState, useEffect } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Typography,
    Accordion, AccordionDetails, AccordionSummary, Tooltip, IconButton,
    RadioGroup, Radio, FormControlLabel, FormGroup, Checkbox
} from '@material-ui/core';
import { useStyles, Meses, UseFocus, FormataDataComHora, ValidarCNPJ } from "../../../constants";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useParams, useLocation, useHistory } from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BuscaDadoDialog from "../../../util/BuscaDadoDialog";
import SearchIcon from '@material-ui/icons/Search';
import FinanciamentoTable from "./FinanciamentoTable";
import AplicacaoTable from "./AplicacaoTable";
import Icone from '@material-ui/icons/SaveAltSharp';
import GenericTable from "../../../components/template/GenericTable";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ptBR } from 'date-fns/locale';

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}
function CNPJMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
const setor = ["Atacadista", "Industrial", "Outros"]
let disable = false;
export default function RenunciaForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait } = props;
    let { programa, id } = useParams();
    const [value, setValue] = useState();
    const [dadoApoio, setDadoApoio] = useState();
    const [lstExercicio, setLstExercicio] = useState([]);
    const [parcela, setParcela] = useState();
    const [escolhaPrevisao, setEscolhaPrevisao] = useState([]);
    const location = useLocation();
    const [previsao, setPrevisao] = useState();
    const [leiloes, setLeiloes] = useState();
    const history = useHistory();
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [demaisEmpresa, setDemaisEmpresa] = useState(false);
    const [novoProjeto, setNovoProjeto] = useState(false);
    const [error, setError] = React.useState(resetErro());
    const [financiamento, setFinanciamento] = useState([]);
    const [aplicacao, setAplicacao] = useState([]);
    const [exercicio, setExercicio] = useState();
    const [raAde, setRaAde] = useState();

    const [input1Ref, setInput1Focus] = UseFocus();

    useEffect(() => {
        if (location.pathname.includes("renunciaAlterar")) {
            setTitulo("Alterar Renuncia FUNDEFE - " + programa);
        } else if (location.pathname.includes("renunciaView")) {
            setTitulo("Visulizar Renuncia FUNDEFE - " + programa);
        } else {
            setTitulo("Cadastro Renuncia FUNDEFE - " + programa);
        }

        buscaPrevisao();

        if (financiamento.length === 0) {
            let arr = [];
            let arrA = [];
            for (let m in Meses) {
                arr.push({ mes: parseInt(m) });
                arrA.push({ mes: parseInt(m) });
            }
            setFinanciamento(arr);
            setAplicacao(arrA);
        }
        disable = location.pathname.includes("renunciaView");
    }, []);
    useEffect(() => {
        if (lstExercicio && lstExercicio.length > 0) {
            if (id) {
                buscaRenuncia();
            }
        }
    }, [lstExercicio])

    useEffect(() => {
        if (value && value.previsao && value.previsao.exercicio && !dadoApoio) {
            buscaDadoApoio({ ...value.previsao });
        }
        function buscaRaAde() {
            fetch('/api/info/ra_ade').then(response => {
                if (response.ok) {
                    response.json().then((json) => {
                        setRaAde(json);
                    });
                } else {
                    response.json().then((error) => {
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });

        }
        if (novoProjeto) {
            if (!raAde) {
                buscaRaAde();
            }
        }
    }, [novoProjeto, value])

    function resetErro() {
        return {
            cnpj: {
                erro: false,
                msg: ''
            },
        }
    }
    const hanleLostFocusExercicio = () => {
        let dado = {
            ...value, previsao: {
                exercicio: exercicio
            }
        };
        setValue(dado);
        setDadoApoio();
    }
    const handleChangedemaisEmpresa = (e) => {
        setDemaisEmpresa(e.target.checked);
    }
    const handleChangeNovoProjeto = (e) => {
        setNovoProjeto(e.target.checked);
    }
    const handleChangeInputForm = (e) => {
        let dado = { ...value };
        if (!dado.previsao) dado.previsao = {}
        dado.previsao[e.target.name] = e.target.value;
        setValue(dado);
    }

    const handleLostFocusCNPJ = (e) => {
        setError({
            ...error,
            cnpj: {
                erro: false,
                msg: ''
            }
        });
        if (!value || !value.previsao || !value.previsao.cnpj) return;
        const cnpj = value.previsao.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (cnpj.length === 0) return;
        if (ValidarCNPJ(cnpj)) {
            fetch("/api/v1/cnpj/" + cnpj)
                .then(res => (res.status <= 300) ? res.json() : setMensagem(res))
                .then(
                    (result) => {
                        if (!result) return;
                        let dado = { ...value };
                        dado.previsao.razaoSocial = result.razaoSocial
                        setValue(dado);
                    },
                )
        } else {
            setError({
                ...error,
                cnpj: {
                    erro: true,
                    msg: 'CNPJ inválido'
                }
            });
        }
    }
    function buscaRenuncia() {
        fetch('/api/fundefeRenuncia/' + id)
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            if (json.previsao) {
                                json.previsao.matriz = (json.previsao.isMatriz === true) ? 'matriz' : 'filial'
                            }

                            setValue(json);
                            setFinanciamento(json.financiamento);
                            setAplicacao(json.aplicacao);
                            setNovoProjeto(json.novoProjeto === true);

                            setExercicio( json.previsao.exercicio );
                            setDemaisEmpresa( json.demaisEmpresa );

                            buscaLeilaoSeJaExixte(json.previsao);
                            buscaParcelasSeJaExixte(json.previsao);
                            buscaDadoApoio(json.previsao);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function convertStringToDouble(val) {
        let d = 0;
        if (val && typeof val === 'string') {
            let s = val.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '');
            // s = s.replace("R", '').replace('$', '');
            d = s.replace(",", ".");
        } else if (val && typeof val === 'number') {
            return val;
        }
        return parseFloat(d);
    }
    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            handleSaldExercicioAnteriorFocus();
        }
    }

    function buscaPrevisao() {
        fetch('/api/fundefePrevisao')
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            setPrevisao(json);
                            let arr = [];
                            for (let x in json) {
                                const item = arr.find(l => l.exercicio === json[x].exercicio)
                                if (!item) arr.push(json[x]);
                            }
                            setLstExercicio(arr);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function desabilitarBusca() {
        if (!value || !value.previsao) return true;
        return false;
    }
    const incluir = (e) => {
        let dado = { ...value };
        delete dado.previsao.historico;

        dado.financiamento = financiamento;
        dado.aplicacao = aplicacao;
        dado.demaisEmpresa = demaisEmpresa;
        dado.novoProjeto = novoProjeto;

        if (novoProjeto) {
            dado.previsao.programa = programa;
            dado.previsao.isMatriz = dado.previsao.isMatriz === 'matriz';
            dado.previsao.setor = programa === 'FIDE' ? 'Atacadista' : 'Industrial'
        }
        if (demaisEmpresa) {
            dado.previsao.programa = programa;
            dado.previsao.setor = programa === 'FIDE' ? 'Atacadista' : 'Industrial'
        }
        if (dado.saldoExercicioAnterior && typeof dado.saldoExercicioAnterior === 'string') {
            let val = dado.saldoExercicioAnterior.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            dado.saldoExercicioAnterior = val.replace(",", ".");
        }
        if (!dado.historico) dado.historico = [];

        submitForm(dado);
    }
    function submitForm(dado) {
        setWait(true);
        fetch('/api/fundefeRenuncia', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                response.json().then((json) => {
                    setMensagem("Renúncia " + (location.pathname.includes("renunciaAlterar") ? "alterada" : "incluída") + " com sucesso");
                    history.replace("/fundefe/renunciaLista/" + programa);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function transformaDt(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }
    const handleChangeExercicio = (event) => {
        const val = event.target.value;
        let dado = {
            ...value, previsao: {
                exercicio: val
            }
        };
        if (novoProjeto) {
            dado.previsao.setor = programa === 'FIDE' ? 'Atacadista' : 'Industrial'
        }
        setValue(dado);
    };

    function disableIncluir() {
        if (!value || !value.saldoExercicioAnterior) return true;

        return false;
    }
    const handleSaldExercicioAnterior = (e) => {
        setValue({ ...value, saldoExercicioAnterior: e.target.value })
    }
    const handleSaldExercicioAnteriorFocus = () => {
        if (!value.saldoExercicioAnterior) return;
        let arrFin = [...financiamento];
        let arrApl = [...aplicacao];
        for (let x in arrFin) {
            const f = arrFin[x];
            let a = arrApl[x];
            a.parcelaLiberacao = f.parcelaLiberacao;
            a.diaLiberacao = f.diaLiberacao;
            a.parcelaLeiloada = f.parcelaLeiloada;
            a.valorArrematado = f.valorArrematado;
            a.diaLeilao = f.diaLeilao;
            a.jurosPago = f.jurosPago;
        }

        calcula(arrFin);
        calculaAplicacao(arrApl);
        setFinanciamento(arrFin);
        setAplicacao(arrApl);
    }
    function calculaAplicacao(arr) {
        let saldoInicial = convertStringToDouble(value.saldoExercicioAnterior);
        let parcelaLiberacao = getParcelaLIberada(arr[0]);
        let parcelaLeiloada = getParcelaLeiloada(arr[0]);
        let jurosPago = arr[0].jurosPago ? arr[0].jurosPago : 0;
        let rendimentoAplicacao = calcRendimentoAplicacao(arr[0]);
        for (let i = 0; i < arr.length; i++) {
            arr[i].jurosContrato = dadoApoio ? dadoApoio.juros : 0;
            if (i === 0) {
                arr[0].saldoInicial = saldoInicial;
            } else {
                jurosPago = arr[i - 1].jurosPago ? arr[i - 1].jurosPago : 0;
                parcelaLiberacao = getParcelaLIberada(arr[i - 1]);
                parcelaLeiloada = getParcelaLeiloada(arr[i - 1]);

                arr[i].saldoInicial = saldoInicial + parcelaLiberacao - parcelaLeiloada + rendimentoAplicacao - jurosPago;
            }
            getParcelaLIberada(arr[i]);// do mes corrente
            parcelaLeiloada = getParcelaLeiloada(arr[i]); //do mes corrente
            saldoInicial = arr[i].saldoInicial;
            arr[i].remuneracaoContaUnida = calcCotaUnica(arr[i]);

            rendimentoAplicacao = calcRendimentoAplicacao(arr[i]);
            arr[i].rendimentoAplicacao = rendimentoAplicacao;

            arr[i].renunciaMensal = calcrenunciaMensal(arr[i], i);
        }
    }
    function calcula(arr) {
        //=C9+D9-F9+J9-K9
        let saldoInicial = convertStringToDouble(value.saldoExercicioAnterior);
        let parcelaLiberacao = getParcelaLIberada(arr[0]);
        let parcelaLeiloada = getParcelaLeiloada(arr[0]);
        let jurosPago = arr[0].jurosPago ? arr[0].jurosPago : 0;
        let rendimentoContrato = calcRendimentoContrato(arr[0]);
        for (let i = 0; i < arr.length; i++) {

            arr[i].jurosContrato = dadoApoio ? dadoApoio.juros : 0;
            if (i === 0) {
                arr[0].saldoInicial = saldoInicial;
            } else {
                jurosPago = arr[i - 1].jurosPago ? arr[i - 1].jurosPago : 0;
                parcelaLiberacao = getParcelaLIberada(arr[i - 1]);
                parcelaLeiloada = getParcelaLeiloada(arr[i - 1]);
                arr[i].saldoInicial = saldoInicial + parcelaLiberacao - parcelaLeiloada + rendimentoContrato - jurosPago;

            }
            getParcelaLIberada(arr[i]);// do mes corrente
            parcelaLeiloada = getParcelaLeiloada(arr[i]); //do mes corrente     
            saldoInicial = arr[i].saldoInicial;
            rendimentoContrato = calcRendimentoContrato(arr[i]);
            arr[i].rendimentoContrato = rendimentoContrato;
        }
    }
    function getParcelaLeiloada(fin) {
        let result = 0;

        if (fin.parcelaLeiloada || fin.parcelaLeiloada === 0) {
            return convertStringToDouble(fin.parcelaLeiloada)
        } else if (leiloes) {

            const item = leiloes.find(l => {
                const dt = new Date(l.dataLeilao);
                const ano = dt.getFullYear();
                const mes = dt.getMonth();

                return ano === value.previsao.exercicio && mes === fin.mes;
            });
            if (item) {
                fin.parcelaLeiloada = (item.totalLiberado);
                fin.valorArrematado = (item.valorArrematado);
                fin.diaLeilao = new Date(item.dataLeilao).getDate();
                result = item.totalLiberado
            }
        }
        return result;
    }
    function calcrenunciaMensal(apl, index) {
        //=SE(J26=0;0;J26-J9+(F26-G26)-I26)
        let result = 0;
        let J26 = apl.rendimentoAplicacao ? apl.rendimentoAplicacao : 0;
        if (J26 > 0) {
            // J26 = Number((J26).toFixed(2));
            let J9 = financiamento[index].rendimentoContrato ? financiamento[index].rendimentoContrato : 0;
            let F26 = apl.parcelaLeiloada ? apl.parcelaLeiloada : 0;
            let G26 = apl.valorArrematado ? apl.valorArrematado : 0;
            let I26 = apl.emolumentos ? apl.emolumentos : 0;

            //J26-J9+(F26-G26)-I26)
            result = J26 - J9 + (F26 - G26) - I26;
        }
        return result;
    }
    function calcCotaUnica(apl) {
        //=Q26*0,95
        let result = 0;
        if (dadoApoio) {
            const item = dadoApoio.dadosCDI.find(cdi => {
                return apl.mes === Meses.indexOf(cdi.mes);
            });
            if (item) {
                result = item.cdi * 0.95;
            }
        }
        return Number((result));
    }
    function getParcelaLIberada(fin) {
        let soma = 0;
        if (fin.parcelaLiberacao || fin.parcelaLiberacao === 0) {
            return convertStringToDouble(fin.parcelaLiberacao)
        } else if (parcela && parcela.parcelas) {
            let arr = parcela.parcelas.sort((a, b) => {
                let dt1 = new Date(a.dtLiberacao);
                let dt2 = new Date(b.dtLiberacao);
                return dt1 - dt2;
            })
            for (let x in arr) {
                const p = arr[x];
                const i = Meses.indexOf(p.parcela);

                if (i === fin.mes) {
                    fin.diaLiberacao = new Date(p.dtLiberacao).getDate();//ultima data
                    soma += p.valorParcela;
                }
            }
        }
        fin.parcelaLiberacao = soma
        fin.emolumentos = soma * 0.005;
        return soma;
    }
    const desabilitarSaldo = () => {
        if (!value || !value.previsao) return true;
        return false;
    }
    function calcRendimentoAplicacao(fin) {
        //=(C26*(1+L26)+D26*(1+L26*((31-E26)/30))-F26*(1+L26*((31-H26)/30)))-C26-D26+F26
        let C26 = fin.saldoInicial ? fin.saldoInicial : 0;             //C
        let L26 = fin.remuneracaoContaUnida ? fin.remuneracaoContaUnida : 0; //L
        let D26 = fin.parcelaLiberacao ? fin.parcelaLiberacao : 0; //D
        let F26 = fin.parcelaLeiloada ? fin.parcelaLeiloada : 0;    //F
        let E26 = fin.diaLiberacao ? fin.diaLiberacao : 0;             //E
        let H26 = fin.diaLeilao ? fin.diaLeilao : 0;                      //H

        let rend = (C26 * (1 + L26) + D26 * (1 + L26 * ((31 - E26) / 30)) - F26 * (1 + L26 * ((31 - H26) / 30))) - C26 - D26 + F26;

        return rend / 100;
    }
    function calcRendimentoContrato(fin) {

        //=(C9*(1+L9)+D9*(1+L9*((31-E9)/30))-F9*(1+L9*((31-H9)/30)))-C9-D9+F9
        let saldoInicial = fin.saldoInicial ? fin.saldoInicial : 0;             //C
        let jurosContrato = fin.jurosContrato ? fin.jurosContrato : 0;          //L
        let parcelaLiberacao = fin.parcelaLiberacao ? fin.parcelaLiberacao : 0; //D
        let parcelaLeiloada = fin.parcelaLeiloada ? fin.parcelaLeiloada : 0;    //F
        let diaLiberacao = fin.diaLiberacao ? fin.diaLiberacao : 0;             //E
        let diaLeilao = fin.diaLeilao ? fin.diaLeilao : 0;                      //H
        let rend = (saldoInicial * (1 + jurosContrato) +
            parcelaLiberacao * (1 + jurosContrato * ((31 - diaLiberacao) / 30)) -
            parcelaLeiloada * (1 + jurosContrato * ((31 - diaLeilao) / 30))) -
            saldoInicial - parcelaLiberacao + parcelaLeiloada;

        return rend / 100;
    }
    function buscaDado() {
        const exercicio = value.previsao.exercicio;
        const item = previsao.filter(l => {
            return l.exercicio === exercicio && l.programa === programa;
        })
        if (item) {
            setEscolhaPrevisao([...item]);
        }

        setShowBuscaDadoDialog(true);
    }
    function handleEscolhaPrevisao(dado) {
        if (dado) {
            dado.matriz = (dado.isMatriz === true) ? 'matriz' : 'filial'
        }
        buscaRenunciaSeJaExixte(dado);
        buscaParcelasSeJaExixte(dado);
        buscaLeilaoSeJaExixte(dado);
        buscaDadoApoio(dado);
    }
    function buscaDadoApoio(dado) {
        setWait(true);
        fetch('/api/fundefeDadoApoio/byExercicio/' + dado.exercicio)
            .then(response => {
                if (response.ok) {
                    setWait(false);
                    response.json().then((json) => {
                        setDadoApoio(json);
                    });
                } else {
                    setWait(false);
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function buscaLeilaoSeJaExixte(dado) {
        if (!dado.id) return;
        setWait(true);
        fetch('/api/fundefeLeilao/byIdPrevisao/' + dado.id)
            .then(response => {
                if (response.ok && (response.status < 300)) {
                    setWait(false);
                    response.json().then((json) => {
                        setLeiloes(json);
                    });
                } else {
                    setWait(false);
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function buscaParcelasSeJaExixte(dado) {
        if (!dado.id) return;
        setWait(true);
        fetch('/api/fundefeParcelas/byIdPrevisao/' + dado.id)
            .then(response => {
                if (response.ok && (response.status < 300)) {
                    setWait(false);
                    response.json().then((json) => {
                        setParcela(json);
                    });
                } else {
                    setWait(false);
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function buscaRenunciaSeJaExixte(dado) {
        setWait(true);
        fetch('/api/fundefeRenuncia/byIdPrevisao/' + dado.id)
            .then(response => {
                if (response.ok && (response.status < 300)) {
                    setWait(false);
                    response.json().then((json) => {
                        setMensagem("Renuncia já cadastrada, abrindo para edição");
                        if (json.previsao) {
                            json.previsao.matriz = (json.previsao.isMatriz === true) ? 'matriz' : 'filial'
                        }
                        setValue(json);
                        setFinanciamento(json.financiamento);
                        setAplicacao(json.aplicacao);
                        // saldoExercicioAnteriorOld = json.saldoExercicioAnterior;
                    });
                } else {
                    setInput1Focus();
                    let valores = {
                        ...value,
                        previsao: dado
                    };
                    valores.historico = null;
                    setValue(valores);
                    setWait(false);
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }

    const handleCalc = (e) => {

        if (!dadoApoio) {
            setMensagem("Dado apoio nao encontrado");

        } else {
            handleSaldExercicioAnteriorFocus();
        }
        // 
    }
    function sair() {
        history.replace("/fundefe/renunciaLista/" + programa);
    }
    return (
        <Container maxWidth='xl'>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Paper >
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Informações</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={demaisEmpresa}
                                                onChange={handleChangedemaisEmpresa} disabled={disable} />}
                                            label="Demais empresas"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={novoProjeto}
                                                    onChange={handleChangeNovoProjeto}
                                                    color="primary" disabled={disable}
                                                />
                                            }
                                            label="Novos projetos"
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    {novoProjeto &&
                                        <TextField
                                            fullWidth
                                            label="Exercício"
                                            value={exercicio || ''}
                                            disabled={disable}
                                            name="exercicio"
                                            variant="outlined"
                                            onChange={(e) => setExercicio(e.target.value)}
                                            onBlur={hanleLostFocusExercicio}
                                            variant="outlined"
                                        />
                                    }
                                    {!novoProjeto &&
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="lbExercicio">Excercício</InputLabel>
                                            <Select
                                                labelId="lbExercicio"
                                                id="exercicio"
                                                value={value && value.previsao && value.previsao.exercicio || ''}
                                                onChange={handleChangeExercicio}
                                                disabled={disable}
                                                label="Exercício"
                                            >
                                                <MenuItem value="">Nenhum</MenuItem>
                                                {lstExercicio && lstExercicio.map((row, i) => (
                                                    <MenuItem key={i} value={row.exercicio}>{row.exercicio}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Programa"
                                        value={programa}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                {!demaisEmpresa && <>
                                    {!novoProjeto && <>
                                        <Grid item xs={disable ? 4 : 3}>
                                            <TextField
                                                fullWidth
                                                label="CNPJ"
                                                value={value && value.previsao && value.previsao.cnpj || ''}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        {!disable &&
                                            <Grid item xs={1}>
                                                <Tooltip title='Buscar Previsão Orçamentárea' >
                                                    <div>
                                                        <IconButton onClick={buscaDado} disabled={desabilitarBusca()}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </Grid>}

                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Beneficiário"
                                                value={value && value.previsao && value.previsao.razaoSocial || ''}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl component="fieldset">
                                                <RadioGroup row aria-label="isMatriz" name="isMatriz" value={value && value.previsao && value.previsao.matriz || ''}>
                                                    <FormControlLabel value="matriz" control={<Radio color="primary" />} label="Matriz" />
                                                    <FormControlLabel value="filial" control={<Radio color="primary" />} label="Filial" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </>}

                                    {novoProjeto &&
                                        <>
                                            <Grid item xs={3}>
                                                <TextField
                                                    fullWidth
                                                    label="CNPJ"
                                                    // disabled={disable}
                                                    error={error.cnpj.erro}
                                                    helperText={error.cnpj.msg}
                                                    value={value && value.previsao && value.previsao.cnpj || ''}
                                                    onChange={handleChangeInputForm}
                                                    onBlur={handleLostFocusCNPJ}
                                                    name="cnpj"
                                                    variant="outlined"
                                                    InputProps={{
                                                        inputComponent: CNPJMask,
                                                        readOnly: disable,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    // disabled={true}
                                                    label="Razão social"
                                                    value={value && value.previsao && value.previsao.razaoSocial || ''}
                                                    //onChange={handleChangeInputForm}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <FormControl component="fieldset">
                                                    <RadioGroup row value={value && value.previsao && value.previsao.isMatriz || ''} aria-label="isMatriz"
                                                        onChange={handleChangeInputForm} name="isMatriz" defaultValue="top">
                                                        <FormControlLabel
                                                            value="matriz"
                                                            control={<Radio disabled={disable} color="primary" />}
                                                            label="Matriz"
                                                            labelPlacement="end"
                                                        />
                                                        <FormControlLabel
                                                            value="filial"
                                                            control={<Radio disabled={disable} color="primary" />}
                                                            label="Filial"
                                                            labelPlacement="end"
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            {programa == "PRODF" &&
                                                <Grid item xs={3}>
                                                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                                        <InputLabel id="demo-simple-select-outlined-label">RA</InputLabel>
                                                        <Select
                                                            autoWidth={false}
                                                            fullWidth
                                                            labelId="demo-simple-select-outlined-label"
                                                            name="ra"
                                                            value={value && value.previsao && value.previsao.ra || ''}
                                                            onChange={handleChangeInputForm}
                                                            label="RA"
                                                            disabled={disable}
                                                            inputProps={{ readOnly: (disable && disable === true) }}
                                                        >
                                                            {
                                                                raAde && raAde.map((item, index) => (
                                                                    <MenuItem key={index} value={item.nome}>{item.nome}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            }

                                        </>}
                                </>}
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Processo"
                                        value={value && value.previsao && value.previsao.processo || ''}
                                        onChange={handleChangeInputForm}
                                        name="processo"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: !novoProjeto,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                            {!novoProjeto&&
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Setor"
                                        value={value && value.previsao && value.previsao.setor || ''}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                }
                                {novoProjeto&&
                                <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="tipo" >Setor</InputLabel>
                                    <Select
                                        id="setor"
                                        name='setor'
                                        disabled={disable}
                                        label="setor"
                                        placceholder="setor"
                                        onChange={handleChangeInputForm}
                                        value={value && value.previsao && value.previsao.setor || ''} >
                                        {
                                            setor && setor.map((tp, i) => (
                                                <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>}
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Saldo do Exercício Anterior"
                                        value={value && value.saldoExercicioAnterior || ''}
                                        onChange={handleSaldExercicioAnterior}
                                        // onBlur={handleSaldExercicioAnteriorFocus}
                                        onKeyDown={handleKeyPress}
                                        InputProps={{
                                            inputComponent: MonetarioField
                                        }}
                                        variant="outlined"
                                        inputRef={input1Ref}
                                        disabled={disable || desabilitarSaldo()}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleCalc}
                                        disabled={disable}
                                        className={classes.button}
                                        startIcon={<Icone />}
                                    > Cal
                                    </Button>
                                </Grid>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Financiamento</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <FinanciamentoTable dados={financiamento} setDados={setFinanciamento} calcula={handleSaldExercicioAnteriorFocus} disabled={disable} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>


                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Aplicação</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <AplicacaoTable dados={aplicacao} setDados={setAplicacao} calcula={handleSaldExercicioAnteriorFocus} disabled={disable} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Histórico</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <GenericTable
                                    dados={value ? value.historico : []}
                                    colunas={[
                                        { nome: 'Data', campo: 'data', transformacao: transformaDt },
                                        { nome: 'Responsavel', campo: 'responsavel.nome' },
                                        { nome: 'Ação', campo: 'tipo' },
                                        { nome: 'Observação', campo: 'texto' }
                                    ]} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Grid container justify="flex-end" className={classes.btns}>
                        {!disable &&
                            <Button variant="contained" color="primary"
                                onClick={incluir} rel="noopener noreferrer"
                                disabled={disableIncluir()}
                            >
                                {location.pathname.includes("renunciaAlterar") ? "Alterar" : "Incluir"}
                            </Button>}
                        <Button variant="contained"
                            color="secondary"
                            onClick={sair} rel="noopener noreferrer"
                        >
                            Sair
                        </Button>
                    </Grid>
                </Paper>
            </MuiPickersUtilsProvider>
            
            <BuscaDadoDialog
                open={showBuscaDadoDialog}
                setOpen={setShowBuscaDadoDialog}
                acao={handleEscolhaPrevisao}
                colunas={[
                    { nome: 'CNPJ', campo: 'cnpj' },
                    { nome: 'Nome', campo: 'razaoSocial' },
                    { nome: 'Programa', campo: 'programa' },
                ]}
                dados={escolhaPrevisao} />
            
        </Container>
    );
}
