import React, { useState, useEffect } from 'react';
import {
    Button, Grid, Paper, TextField,
    Accordion, AccordionSummary, AccordionDetails,
    FormControl, InputLabel, Select, MenuItem, Typography
} from '@material-ui/core';
import { useStyles } from "../../../constants";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GenericTable from '../../template/GenericTable';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

export default function MetasEmprego(props) {
    const classes = useStyles();
    const { value, setValue, disable } = props;
    const [txt, setTxt] = useState('');
    const [values, setValues] = useState({});
    const [nomeFiliao1, setNomeFiliao1] = useState();

    useEffect(() => {
        buscarFiliais()
    }, []);
    function calculaTotalEmpregoManter(dado, event) {
        if (event.target.name === 'empregosGerar') {
            let val = parseInt(values.empregosExistentes);
            if (val) {
                val += parseInt(event.target.value)
                dado.totalEmpregoManter = val
            }
        } else if (event.target.name === 'empregosExistentes') {
            let val = parseInt(values.empregosGerar);
            if (val) {
                val += parseInt(event.target.value)
                dado.totalEmpregoManter = val;
            }
        }
        return dado;
    }
    const handleChangeInputForm = (event) => {
        let dado = { ...values };
        dado = calculaTotalEmpregoManter(dado, event);

        setValues({
            ...dado,
            [event.target.name]: event.target.value,
        });

    };

    const handleActionSolicitarClick = (index, acao, dado) => {
        let arr = [...value.metasDeEmprego.valoresTable];
        arr.splice(index, 1);
        const pasaDado = {
            ...value.metasDeEmprego,
            valoresTable: arr
        };
        setValue({
            ...value,
            metasDeEmprego: pasaDado,
        });
    }

    function incluir() {

        let arr = []
        let ok = true
        const dadosTab = {
            ...values,
        };
        if (value.metasDeEmprego) {
            for (let x in value.metasDeEmprego.valoresTable) {
                const d = value.metasDeEmprego.valoresTable[x]
                arr.push(d)
            }
        }
        if (ok) arr.push(dadosTab);
        const pasaDado = {
            ...value.metasDeEmprego,
            valoresTable: arr
        };
        setValue({
            ...value,
            metasDeEmprego: pasaDado
        })
        setValues({});
    }
    function disableIncluir() {
        if (values.empregosExistentes && values.empregosGerar && values.nomeFiliao && values.totalEmpregoManter) return false
        return true
    }
    function buscarFiliais() {
        let arr = [];
        arr.push(value.empresa.razaoSocial)
        for (let x in value.filiais) {
            const d = value.filiais[x].razaoSocial
            arr[x] = d
            arr.push(value.empresa.razaoSocial)
        }
        setNomeFiliao1(arr);
    }
    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>METAS DE EMPREGO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} >
                        {!disable && <>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="tipo" >Nome da filial</InputLabel>
                                    <Select
                                        id="nomeFiliao"
                                        name='nomeFiliao'
                                        label="Nome da filial"
                                        placceholder="Nome da filial"
                                        onChange={handleChangeInputForm}
                                        value={values.nomeFiliao || ''} >
                                        {
                                            nomeFiliao1 && nomeFiliao1.map((tp, i) => (
                                                <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    value={values.empregosExistentes || ''}
                                    // disabled
                                    fullWidth
                                    type="number"
                                    label="Empregos existentes"
                                    id="empregosExistentes"
                                    name="empregosExistentes"
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    value={values.empregosGerar || ''}
                                    // disabled
                                    fullWidth
                                    type="number"
                                    label="Empregos a gerar"
                                    id="empregosGerar"
                                    name="empregosGerar"
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    value={values.totalEmpregoManter = parseInt(values.empregosGerar) + parseInt(values.empregosExistentes) || ''}
                                    // disabled
                                    fullWidth
                                    type="number"
                                    label="Total de empregos a manter"
                                    id="totalEmpregoManter"
                                    name="totalEmpregoManter"
                                    // onChange={handleChangeInputForm}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: disable,
                                    }}
                                />
                            </Grid>
                            <Grid container justify="flex-end" className={classes.btns}>
                                <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={incluir} disabled={disableIncluir()}>
                                    incluir/Alterar
                                </Button>
                            </Grid>
                        </>}

                        <GenericTable
                            actionClick={handleActionSolicitarClick}
                            options={['Excluir']}
                            dados={value.metasDeEmprego ? (value.metasDeEmprego.valoresTable || []) : []}
                            colunas={[
                                { nome: 'Local', campo: 'nomeFiliao' },
                                { nome: 'Empregos existentes', campo: 'empregosExistentes' },
                                { nome: 'Empregos a gerar', campo: 'empregosGerar' },
                                { nome: 'Total de empregos a manter', campo: 'totalEmpregoManter' },
                                { nome: 'Ação', campo: !(disable) ? 'action' : 'null' }
                            ]} />



                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>O projeto proporcionar á melhoria aferível de qualificação de mão de obra? Quais?</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={value.metasDeEmprego ? (value.metasDeEmprego.proporcionar || "") : ''}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    setValue({
                                        ...value,
                                        metasDeEmprego: {
                                            ...value.metasDeEmprego,
                                            proporcionar: editor.getData()
                                        }
                                    });
                                }}
                                disabled={disable}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )
}