import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Paper, Menu, MenuItem, Button, TextField, Container,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RemoveIcon from '@material-ui/icons/Remove';
import CampoBusca from "../../../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import { useStyles, getQueryParams, calculaDiaLimite } from "../../../../constants";
import { FormataDataComHora } from '../../../../constants/';
import ConfirmDialog from "../../../../util/ConfirmDialog";
import { useHistory, useLocation  } from "react-router-dom";

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let qtd = 0;
let primeiro = true;
let idProcesso = 0;
const dataAtual = new Date();

export default function LitarRecursosPanel(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait } = props;
    const [processo, setProcesso] = useState();
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const param = getQueryParams(useLocation().search);
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const history = useHistory();
    const [value, setValue] = useState({
        processo: '',
        CNPJ: '',
        razaoS: '',
    });

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });
    
    useEffect(() => {
        setTitulo("Solicitação de Recursos");
        if (primeiro) {
            setWait(true);
            fetch("/api/processoProdf/"+param.get("id"),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'Authorization': `Bearer ${localStorage.accessToken}`
                    }
                }).then(res => res.json()).then((result) => {
                    //   const lst = result.sort((a, b) => ((a.perfil.name < b.perfil.name) ? -1 : 1));
                    setWait(false);
                    if (result.status && result.status > 300) {
                        setErro(result);
                    } else {                        
                        setProcesso(result);
                        salvaProcessoTrabalho(result);
                        buscaRecurso(result);
                        // fonteDeDados = result.sort((a, b) => ((a.dataCriacao < b.dataCriacao) ? 1 : -1));
                        // filtraDado();
                    }
                });
            primeiro = false;
        }
    });

    function buscaRecurso(pro){
        setWait(true);
        fetch("/api/processoProdf/recurso/findByProcesso/",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify(
                    {
                        processo: pro.dadosEmpresa.nProcesso
                    }
                ),
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {                    
                    fonteDeDados = result.sort((a, b) => ((a.dataCriacao < b.dataCriacao) ? 1 : -1));
                    filtraDado();
                }
            });
    }


    function salvaProcessoTrabalho(dado){
        localStorage.setItem("workingProcess", JSON.stringify(dado) );
    }
    
    const handleAcao = (e, item, acao) => {
        if (acao === 'editar') {
            history.replace("/gerenciaProcesso/unate/solicitarRecursoForm?id=" + item.id);
        }
    }

    function confirmaExclusao() {
        setWait(true);
        fetch("/api/processoProdf/prorrogacao/" + idProcesso, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Bearer ${localStorage.accessToken}`
            }
        }).then((result) => {
            setWait(false);
            if (result.status && result.status > 300) {
                setErro(result);
            } else {
                let value = [];
                if (fonteDeDados && (fonteDeDados.length > 0)) {
                    for (let i = 0; i < fonteDeDados.length; i++) {
                        if (fonteDeDados[i].id !== idProcesso) {
                            value.push(fonteDeDados[i]);
                        }
                    }
                }
                fonteDeDados = value
                filtraDado();
            }
        });
    }
    function excluir(id) {
        setShowConfirmExclusao(true);
        idProcesso = id;
    }

    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
    }

    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
            } else {
                disparaSearch();
            }
        }, tempo);
    }

    useEffect(() => {
        filtraDado();
    }, [ordem, pagina, bloco]);

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };

    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];

        let arrOrdenado = ordenar(fonteDeDados);


        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                let campos = filtroCampo.split(' ');
                for (let x in d) {
                    for (var y in campos) {
                        if (x.includes("data")) {
                            let val = FormataDataComHora(new Date(d[x]));
                            if (val.includes(campos[y])) {
                                arr.push(d);
                                break;
                            }
                        } else if (typeof d[x] === 'string') {
                            if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                arr.push(d);
                                break;
                            }
                        } else if (d[x] == campos[y]) {
                            arr.push(d);
                            break;
                        }
                    }
                }
            } else {
                arr.push(d);
            }
        });
        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (index++ > pagina) break;
        }

        setDadosFiltrado(arrBloco);
    }
    function ordenar(arr) {
        let ordenado = arr;
        if( ordem.campo === 'processo'){
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a.processo.nProcesso< b.processo.nProcesso) ? 1 * asc : -1 * asc));
          }
          else if(ordem.campo === 'programa'){              
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a.processo.nProcesso< b.processo.nProcesso) ? 1 * asc : -1 * asc));
          }
          else {
            if (ordem.campo.length > 0) {
              const asc = (ordem.ordem === 'asc') ? 1 : -1;
              ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
            }
          }
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);

        setBloco(0);
    }

    function showStatus(st){
        if( st === 'RASCUNHO'){
            return "Rascunho"
        }else if ( st === 'EM_ANALISE'){
            return "Em validação UNATE";
        }else if ( st === 'PENDENTE'){
            return "Pendente";
        }else if ( st === 'REPROVADO'){
            return "Reprovado";
        }else if ( st === 'DEFERIDO'){
            return "Em análise";
        }else if ( st === 'INDEFERIDO'){
            return "Reprovado";
        }else{
            return "-"
        }
    }
    
    return (
        <Grid container spacing={3} className={classes.root}>
            <Container maxWidth='md'>
                <Paper>
                    <Grid container spacing={3} className={classes.root}>

                        <Grid item xs={6}>
                            <TextField
                                value={processo && processo.dadosEmpresa && processo.dadosEmpresa.nProcesso || ''}
                                disabled
                                fullWidth
                                label="Processo"
                                id="processo"
                                name="processo"
                                // onChange={handleChangeInputForm}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo && processo.dadosEmpresa && processo.dadosEmpresa.cnpj || ''}
                                disabled
                                fullWidth
                                label="CNPJ"
                                id="cnpj"
                                name="cnpj"
                                // onChange={handleChangeInputForm}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={processo && processo.dadosEmpresa && processo.dadosEmpresa.razaoSocial || ''}
                                disabled
                                fullWidth
                                label="Razão Social"
                                id="nome"
                                name="nome"
                                // onChange={handleChangeInputForm}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
            <Grid item xs={12} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'processo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('processo')}
                                    >
                                        Numero Processo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'protocolo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('protocolo')}
                                    >
                                        Protocolo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'dataCriacao'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataCriacao')}
                                    >
                                        Data Solicitação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'dataAnalise'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataAnalise')}
                                    >
                                        Data validação UNATE
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'limite'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('limite')}
                                    >
                                        Dias limite resposta
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'status'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('status')}
                                    >
                                        Situação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'programa'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('programa')}
                                    >
                                        Programa
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'tpRecurso'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('tpRecurso')}
                                    >
                                        Tipo recurso
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell >{row.processo.nProcesso}</TableCell>
                                    <TableCell>{row.protocolo}</TableCell>
                                    <TableCell>{FormataDataComHora(new Date(row.dataCriacao))}</TableCell>
                                    <TableCell>{(row.dataAnalise) ? FormataDataComHora(new Date(row.dataAnalise)) : ''}</TableCell>
                                    <TableCell> {row.status === 'PENDENTE'?calculaDiaLimite(row.dataAnalise):''}</TableCell>
                                    <TableCell>{showStatus(row.status)}</TableCell>
                                    <TableCell>{row.processo.programa}</TableCell>
                                    <TableCell>{row.tipo}</TableCell>
                                    <TableCell>
                                        <Box >
                                        {
                                                    row.status !== 'RASCUNHO' &&
                                                    <Tooltip title="Visualizar">
                                                        <IconButton href={"/gerenciaProcesso/unate/solicitarRecursoView?id=" + row.id+"&edit=false"} >
                                                            <VisibilityIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            {
                                                row.status === 'RASCUNHO' &&
                                                <Tooltip title="Editar">
                                                    <IconButton onClick={(e) => handleAcao(e, row, 'editar')}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                row.status === 'RASCUNHO' &&
                                                <Tooltip title="Excluir">
                                                    <IconButton onClick={() => (excluir(row.id))} >
                                                        <RemoveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                row.status === 'PENDENTE' &&
                                                <Tooltip title="Reenviar">
                                                    <IconButton onClick={(e) => handleAcao(e, row, 'editar')}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }

                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    fonteDeDados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={qtd}
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }
            </Grid>
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/gerenciaProcesso"} rel="noopener noreferrer">
                    Sair
                </Button>

                <Button variant="contained" color="primary" href={"/gerenciaProcesso/unate/solicitarRecursoForm"} >
                    Cadastrar
                </Button>
            </Grid>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />
        </Grid>
    );
}
