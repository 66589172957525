import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography,
    Accordion, AccordionSummary, AccordionDetails, ButtonGroup, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, DialogActions
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import MaskedInput from 'react-text-mask'
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useStyles, getQueryParams, FormataDataComHora } from "../../../constants";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import Download from "../../../util/Download";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import HistoricoTable from "../unate/HistoricoTable";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FluxoPanel from "../../../util/FluxoPanelBPM";
import { makeStyles, } from '@material-ui/core/styles';
import UnidadeDialog from "../../user/unidade/UnidadeDialog";
import TextoPadraoDialog from "../../../util/TextoPadraoDialog";
import { useUnidades } from "../../../server/UseUnidades";
import AnexoUploadComponent from "../../../components/common/fileUpload/AnexoUploadComponent";
import Service from "../../../services/Service";
import AnexosProtocoloPanel from "./AnexosProtocoloPanel";

function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '160px',
        overflow: 'auto'
    },
    deslocar: {
        position: 'relative',
        display: 'flex',
        marginLeft: 'auto',
        top: '37px',
        right: '10px',
        zIndex: 999
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        marginLeft: "80px"
    },
}), { index: 1 });
let acao = '';
export default function DespachoRequerimentoForm(props) {
    const classes = useStyles();
    const classesLocal = useLocalStyle();
    const [processo, setProcesso] = useState();
    const { setTitulo, setErro, setWait, disableDespacho, disableParecerTecnico, showEncaminhar, assunto, disable, setMensagem } = props;
    let { id } = useParams();
    //let { assunto } = useParams();
    const [showConfirm, setShowConfirm] = useState(false);
    const [anexos, setAnexos] = useState([]);
    const [msgConfirmacao, setMsgConfirmacao] = useState('');
    const [fluxo, setFluxo] = useState();
    const [protocolo, setProtocolo] = useState();
    const [showUnidadeDialogProtocolo, setShowUnidadeDialogProtocolo] = useState(false);



    const [value, setValue] = useState({
        assunto: '',
        processo: '',
        cpf: '',
        nome: '',
        telefone: '',
        email: '',
        protocolo: '',
        tipo: '',
        fluxoPath: [],
        declaracao: false,
    });

    const history = useHistory();
    let [profile] = useCurrentUser();
    const param = getQueryParams(useLocation().search);
    const location = useLocation();
    const [txtDespacho, setTxtDespacho] = useState('');
    const [txtParecer, setTxtParecer] = useState('');
    const [error, setError] = useState(resetErro());
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialogArecer, setOpenDialogParecer] = React.useState(false);
    const [assuntoTexto, setAssuntoTexto] = useState([]);
    const [unidades] = useUnidades();
    const [showUnidadeDialog, setShowUnidadeDialog] = useState(false);
    const [unidade, setUnidade] = React.useState();
    const [showConfirmMudarUnidade, setShowConfirmMudarUnidade] = useState(false);
    const [showConfirmEnviarUnidade, setShowConfirmEnviarUnidade] = useState(false);

    useEffect(() => {

        if (profile) {
            id = param.get('id');
            if (id) return;
            setValue({
                ...value,
                nome: profile.name,
                cpf: profile.CPF,
                email: profile.email
            });
        }
    }, [profile]);


    useEffect(() => {
        if (showEncaminhar) {
            setTitulo && setTitulo("Encaminhar");
        } else {
            if (location.pathname.includes("view")) {
                setTitulo && setTitulo("Visualizar Notificação");
            } else if (location.pathname.includes("parecerTecnicoRequerimentoForm")) {
                setTitulo && setTitulo("Parecer Técnico");
            } else if (!showEncaminhar && (!disableDespacho || disableDespacho) && !desabilitado()) {
                setTitulo && setTitulo("Analisar Requerimento");
            } else if (param.get('assunto') === "Complemento") {
                setTitulo && setTitulo("Visualizar Complemento de Informação");
            } else {
                setTitulo && setTitulo("Visualizar Notificação");
            }
            // else {
            //     setTitulo && setTitulo("Analisar Requerimento");
            // }
        }
        const process = localStorage.getItem("workingProcess");
        setProcesso(JSON.parse(process));
        buscaDados();
        buscarResponseveis()
    }, [profile])
    function montaUrl() {
        let ass = assunto;
        if (!ass) {
            ass = (param.get("assunto")) ? param.get("assunto") : '';
        }
        // console.log("ass ", ass)
        if (ass === 'Recurso' ||ass === 'Recurso - Contra Cancelamento' || ass === 'Recurso - Contra Indeferimento' || ass === 'Recurso - outros' || ass === 'Recurso - Outros') {
            return '/api/processoProdf/recurso/'
        } else if (ass === 'Prorrogacao' || ass === 'Prorrogacao - Contra Recurso' || ass === 'Prorrogacao - Processo de Implantação' || ass === 'Prorrogacao - Análise de Documento' || ass === 'Prorrogacao - Outros') {
            return '/api/processoProdf/prorrogacao/'
        } else if (ass === 'Cancelamento') {
            return '/api/processoProdf/cancelamento/'
        } else if (ass === 'Notificacao') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else if (ass === 'Complemento') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else {
            return "/api/processoProdf/requerimento/";
        }
    }
    function montaTxtAnexos() {
        let ret = "";
        if (protocolo) {
            for (let x in protocolo.anexos) {
                const anexo = protocolo.anexos[x];
                ret += anexo.nomeDocumento + ", "
            }
            if (ret.length > 100) {
                ret = ret.substring(0, 100) + "...";
            } else ret = ret.substring(0, ret.length - 2);
        }
        return ret;
    }
    function showVisualizar() {
        return !location.pathname.includes("view");
    }
    function buscaProtocolo(numeroProtocolo) {
        Service.GET("/api/processoProdf/requerimento/protocolo/" + numeroProtocolo).then(result => {
            setWait(false);
            setProtocolo(result);
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
    }
    function buscaDados() {
        id = param.get('id');

        const process = JSON.parse(localStorage.getItem("workingProcess"));
        setProcesso(process);
        const url = montaUrl();

        if (id) {
            setWait(true);
            fetch(url + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        montaValor(json);
                        if (location.pathname.includes("view")) {
                            if (json.assunto === 'Complemento') setTitulo && setTitulo("Visualizar Complemento de Informação");
                            if (json.assunto === 'Notificacao') setTitulo && setTitulo("Visualiar Notificação");
                        } else {
                            if (json.assunto === "Diversos") {
                                setTitulo("Analisar Requerimento Diversos");
                            }
                        }
                        buscaProtocolo(json.protocolo)
                        if (json.documentos) setAnexos(json.documentos);
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        } else {
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarrequerimentoForm");
            }
        }
    }
    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };
    const handleChangeTecnico = (event) => {
        let arr = [...anexos];
        let item = arr.find(item => (item.nome ? item.nome : item.tipo) === event.target.name);
        item.checkTecnico = event.target.checked;
        setAnexos(arr);
    }
    function unidadeSelecionadaProtocolo() {
        setWait(true);
        const item = {
            protocolo: value.protocolo,
            unidade: unidade.id,
            assunto: value.assunto,
            responsavel: {
                idUser: profile.id,
                nome: profile.name,
                email: profile.email
            }
        }

        Service.POST('/api/processoProdf/requerimento/unidadeProtocolo', item).then(resp => {
            setWait(false);
            sair();
        }).catch(error => {
            setWait(false);
            setErro(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    function mudarUnidade(un){
        setUnidade(un)
        setShowConfirmEnviarUnidade(true)
    }
    function unidadeSelecionada(un) {
        setUnidade(un);
        setShowConfirmMudarUnidade(true);
    }
    function montaValor(json) {
        let valor = null;
        if (!json.assunto) json.assunto = assunto ? assunto : param.get("assunto");
        if (json.requerente) {
            valor = {
                ...json,
                nome: json.requerente.nome,
                cpf: json.requerente.cpf ? json.requerente.cpf : '',
                email: json.requerente.email,
                telefone: json.requerente.telefone ? json.requerente.telefone : '',
            };
            if (json.historico.length > 0 && json.historico[0].tipo === 'EM_ANALISE') {
                valor.respCadastro = json.historico[0].responsavel
            } else {
                valor.respCadastro = json.requerente;
            }


        } else if (json.solicitante) {
            if (typeof json.processo === 'string') { // para recurso, cancelamento e prorrogacao
                //TODO NECESSARIO REFATORAR
                if (processo) {
                    json.processo = {
                        id: processo.id,
                        nProcesso: processo.dadosEmpresa.nProcesso,
                        cnpj: processo.dadosEmpresa.cnpj,
                        razaoSocial: processo.dadosEmpresa.razaoSocial
                    }
                }

            }
            valor = {
                ...json,
                nome: json.solicitante.nome,
                cpf: json.solicitante.cpf ? json.solicitante.cpf : '',
                email: json.solicitante.email,
                telefone: json.solicitante.telefone ? json.solicitante.telefone : ''
            }
            if (json.historico.length > 0 && json.historico[0].tipo === 'EM_ANALISE') {
                valor.respCadastro = json.historico[0].responsavel
            } else {
                valor.respCadastro = json.solicitante;
            }
        }
        if (valor.assunto === 'Diversos' && valor.status !== 'PARECER_TECNICO') {
            const itens = valor.historico.filter(h => h.tipo === "DESPACHO");
            if (itens && itens.length > 0) {
                setTxtParecer(itens[itens.length - 1].texto);
            }
        } else {
            valor.historico.forEach(h => {
                if (h.tipo === 'DEFERIDO' || h.tipo === 'INDEFERIDO' || h.tipo === 'RASCUNHO_ACESSOR') {
                    setTxtParecer(h.texto ? h.texto : '');
                }
            })
        }
        montaPath(valor);

        setValue(valor);
    }

    function vizualizarResposta() {
        const fluxo = value.fluxoPath[value.fluxoPath.length - 1];
        if (fluxo === 'responder' && value && value.assunto && value.assunto === 'Complemento') return true;
        if (fluxo === 'responder' && value && value.assunto && value.assunto === 'Notificacao') return true;
        return false;
    }

    function getResposta() {
        if (value && value.historico) {
            const h = value.historico.find(x => x.tipo === "responder");
            return (h && h.texto) ? h.texto : '';
        }
        return '';
    }
    function showParecerTecnico() {
        if (value.assunto === 'Notificacao') return false;
        if (value.assunto === 'Complemento') return false;
        return true;
    }
    function montaPath(solicitacao) {
        if (!solicitacao.fluxoPath) {
            if (solicitacao.status === 'RASCUNHO') {
                solicitacao.fluxoPath = ["emRascunho"];
            } else {
                solicitacao.fluxoPath = ["emRascunho", "valUnate"];
            }
        }
    }

    function confirmaRequerimento() {

        setError(resetErro());
        let dado = {
            id: value.id,
            assunto: value.assunto,
            protocolo: value.protocolo,
            documentos: anexos
        }
        const txt = txtDespacho.length > 0 ? txtDespacho : txtParecer;

        // dado.documentos = anexos;
        if (acao === 'despachar') {
            dado.status = "DESPACHO";
        } else if (acao === 'devolver') {
            dado.status = 'DEVOLVIDO';
        } else if (acao === 'deferir') {
            dado.status = 'DEFERIDO';
        } else if (acao === 'indeferir') {
            dado.status = 'INDEFERIDO';
        } else if (acao === 'rascunho') {
            dado.status = 'RASCUNHO_ACESSOR';
        } else if (acao === 'encaminhar') {
            dado.status = 'ENCAMINHADO';
        }

        dado.historico = [{
            tipo: dado.status,
            data: new Date(),
            texto: txt,
            responsavel: {
                idUser: profile.id,
                nome: profile.name,
                email: profile.email
            }
        }];
        submitForm(dado);
    }

    function submitForm(dado) {
        setWait(true);
        let url = '/api/processoProdf/requerimento/posCreate';
        if (dado.assunto === 'Complemento' || dado.assunto === 'Notificacao') {
            url = '/api/processoProdf/notificacaoComplemento/posCreate';
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                if (acao === 'rascunho') {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                } else {
                    if (showEncaminhar) {
                        history.push("/gerenciaProcesso/unate/encaminharEmpresario");
                    } else {
                        if ((disableDespacho && disableDespacho === true)) {
                            history.push("/gerenciaProcesso/semp/avaliaRequerimento");
                        } else {
                            history.push("/gerenciaProcesso/semp/despacho");
                        }
                    }
                    window.location.reload(false);
                }
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: { erro: false, msg: '' },
            telefone: { erro: false, msg: '' },
            email: { erro: false, msg: '' }
        }
    }

    function desabilitado() {
        if (disable === true) {
            return true;
        }
        if (!param.get('editable') || param.get('editable') === 'true') {
            return false;
        }
        return true;
    }

    function podeEnviar() {
        return false;
    }
    function handleAcao(cmd) {
        acao = cmd;
        if (cmd === 'rascunho') {

        } else if (acao === 'devolver') {
            setMsgConfirmacao("Confirma devolver a solicitação?");
            setShowConfirm(true);
        } else if (acao === 'despachar') {
            setMsgConfirmacao("Confirma despachar a solicitação?");
            setShowConfirm(true);
        } else if (acao === 'deferir') {
            setMsgConfirmacao("Confirma deferir a solicitação?");
            setShowConfirm(true);
        } else if (acao === 'indeferir') {
            setMsgConfirmacao("Confirma indeferir a solicitação?");
            setShowConfirm(true);
        } else if (acao === 'encaminhar') {
            setMsgConfirmacao("Confirma encaminhar a solicitação para o empresário?");
            setShowConfirm(true);
        }
    }
    function handleAcaoSalvarRascunho() {
        acao = 'rascunho';
        confirmaRequerimento()
    }

    function getassunt() {
        if (value.assunto === 'Complemento') {
            return 'Complemento de Informação  '
        }
        if (isAssuntoDiversos()) {
            return value.assunto;
        } if (value.assunto === 'Cancelamento' || value.assunto === 'Complemento') {
            return value && value.assunto || '';
        }
        else {
            return value && value.assunto + (value.tipo?(" - " + value.tipo):'');
        }
    }
    function isAssuntoDiversos() {
        return value.assunto === 'Diversos';
    }

    const relatorio = () => {
        let dado = { ...value };
        fetch("/api/processoProdf/requerimento/relatorio", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                return;
            }
        }).then(body => {
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    const handleFluxoChange = () => {
        if (!fluxo) {
            let url = '/api/info/fluxoComplexo';
            if (value.assunto === 'Diversos') {
                url = '/api/info/fluxoDiversos';
            } else if (value.assunto === 'Notificacao') {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
                //     url = '/api/info/fluxoNotificacaoComplementoSimples';
                // if (value.tipo === 'Pendência de vistoria' || value.tipo === 'Prazo vencido') {
                //     url = '/api/info/fluxoNotificacaoComplementoComplexo';
                // }
            } else if (value.assunto && value.assunto === 'Complemento') {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
            }
            fetch(url)
                .then(response => response.json())
                .then(json => setFluxo(json)).catch(erro => setErro(erro));
        }
    }
    const sair = () => {
        if (param.get("return")) {
            history.push(param.get("return"));
        } else {

            if (location.pathname.includes("view")) {
                if (value.assunto === 'Complemento') {
                    history.push("/gerenciaProcesso/complementoinformacao/lista");
                } else {
                    history.push("/gerenciaProcesso/notificacao/lista");
                }
            } else if (history.location.pathname.includes("requerimento")) {
                history.push("/visualizarRequerimento");
            } else if (history.location.pathname.includes("encaminharEmpresarioForm")) {
                history.push("/gerenciaProcesso/unate/encaminharEmpresario");
            } else if (history.location.pathname.includes("despachoRequerimentoForm")) {
                history.push("/gerenciaProcesso/semp/despacho");
            } else if (history.location.pathname.includes("parecerTecnicoRequerimentoForm")) {
                history.push("/gerenciaProcesso/semp/avaliaRequerimento");
            } else {
                if (!disableDespacho || disableDespacho === false) {
                    history.push("/gerenciaProcesso/semp/avaliaRequerimento");
                } else {
                    history.push("/gerenciaProcesso/semp/despacho");
                }
            }
        }
    }

    function handleClickComfimarDialog(assuntoDado) {
        setTxtDespacho(assuntoDado.txt)
    }
    function handleClickComfimarDialogParecer(assuntoDado) {
        setTxtParecer(assuntoDado.txt)
    }

    function buscarResponseveis() {
        setWait(true);
        fetch('/api/textoPadrao/buscarAssunto', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then((json) => {
                    let arr = [];
                    for (let x in json) {
                        let v = json[x]
                        arr.push(v)
                    }
                    setAssuntoTexto(arr);
                })
            } else {
                response.json().then((error) => {
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    const mudaUnidadeDoProcesso = () => {
        setWait(true);
        const dado = {
            idRequerimento: value.id,
            idUnidade: unidade.id,
            motivo: txtParecer
        }
        fetch('/api/processoProdf/requerimento/mudarUnidade', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                response.json()
                    .then(ret => {
                        setWait(false);
                        sair();
                    })
            } else {
                response.json().then(erro => {
                    setErro(erro.message);
                })
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const anexar = (dado) => {
        dado.protocolo = value.protocolo;
        dado.email = profile.email;

        setWait(true);
        let url = '/api/processoProdf/requerimento/anexoParecerTecnico';
        Service.POST(url, dado).then(resp => {
            setWait(false);
            setProtocolo(resp);

        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={4}>
                        <TextField
                            value={value.processo && value.processo.programa || ''}
                            disabled
                            fullWidth
                            label="Programa"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={value.processo && value.processo.nProcesso || ''}
                            disabled
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={value && value.protocolo || ''}
                            disabled
                            fullWidth
                            label="Protocolo"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={value.dataCriacao ? FormataDataComHora(new Date(value.dataCriacao)) : ''}
                            disabled
                            fullWidth
                            label="Data Solicitação"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            value={value.processo && value.processo.cnpj || ''} disabled
                            fullWidth
                            label="CNPJ"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField disabled
                            value={value.processo && value.processo.razaoSocial || ''}
                            fullWidth
                            label="Razão Social"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    {showParecerTecnico() &&
                        <Grid item xs={4}>
                            <TextField
                                disabled
                                label="CPF"
                                value={value.cpf}
                                error={error.cpf.erro}
                                helperText={error.cpf.msg}
                                name="cpf"
                                id="cpf-mask-input"
                                fullWidth
                                InputProps={{
                                    inputComponent: CPFMask,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    }
                    {showParecerTecnico() &&
                        <Grid item xs={8}>
                            <TextField disabled
                                value={value.nome}
                                fullWidth
                                label="Requerente"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            value={getassunt()}
                            disabled
                            fullWidth
                            label="Assunto"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    {value && value.descricao &&
                        <Grid item xs={12}>
                            <Paper className={classesLocal.paper}>
                                <span dangerouslySetInnerHTML={{
                                    __html: value.descricao ? value.descricao : ''
                                }}></span>
                            </Paper>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <TableContainer component={Paper} >
                                    <Table aria-label="customized table" size={'small'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Documento
                                                </TableCell>
                                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                                    Ação
                                                </TableCell>
                                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                                    Ação Técnico
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {anexos && anexos.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{row.tipo}</TableCell>
                                                    <TableCell>
                                                        <center>
                                                            <Box>
                                                                {row.nome && <Download doc={row} />}
                                                                {row.check && <CheckIcon color="primary" />}

                                                            </Box>
                                                        </center>
                                                    </TableCell>
                                                    <TableCell>
                                                        <center>
                                                            <Checkbox
                                                                disabled={desabilitado()}
                                                                checked={row.checkTecnico}
                                                                onChange={handleChangeTecnico}
                                                                name={row.nome ? row.nome : row.tipo}
                                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            />
                                                        </center>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {!anexos || anexos.length === 0 &&
                                                <TableRow>
                                                    <TableCell colSpan={2} width='100%' align="center">Nenhum registro encontrado.</TableCell>
                                                </TableRow>

                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        {
                            vizualizarResposta() &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant="button" display="block" gutterBottom>Resposta</Typography>
                                </AccordionSummary>

                                <Paper className={classesLocal.paper}>
                                    <span dangerouslySetInnerHTML={{
                                        __html: getResposta()
                                    }}></span>
                                </Paper>
                            </Accordion>
                        }
                        {showParecerTecnico() &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant="button" display="block" gutterBottom>Relatório Técnico</Typography>
                                    <Typography className={classesLocal.secondaryHeading}>{montaTxtAnexos()}</Typography>

                                </AccordionSummary>
                                <div>
                                    <AnexosProtocoloPanel
                                        disabled={desabilitado()}
                                        protocolo={protocolo} setWait={setWait} setMensagem={setMensagem} setProtocolo={setProtocolo} />
                                    <div className={classesLocal.deslocar} >
                                        <AnexoUploadComponent
                                            disabled={desabilitado()}
                                            setWait={setWait} setMensagem={setMensagem}
                                            setOpenDialogParecer={setOpenDialogParecer}
                                            anexar={anexar}
                                        />
                                        {/* <ButtonGroup style={{ marginLeft: "auto" }} variant="text" size="small" aria-label="small button group" 
                                            color="secondary" 
                                            disabled={(disableParecerTecnico && disableParecerTecnico === true)}>
                                        <Button onClick={addAnexo}>Anexar</Button>
                                        <Button onClick={() => { setOpenDialogParecer(true); }}>Texto Padrão</Button>
                                    </ButtonGroup> */}
                                    </div>

                                    <CKEditor
                                        editor={ClassicEditor} disabled={desabilitado() || (disableParecerTecnico && disableParecerTecnico === true)}
                                        config={{
                                            toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                                '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                        }}
                                        data={txtParecer}
                                        onReady={editor => {
                                            editor.editing.view.change(writer => {
                                                writer.setStyle(
                                                    "height",
                                                    "200px",
                                                    editor.editing.view.document.getRoot()
                                                );
                                            });
                                        }}
                                        onChange={(event, editor) => {
                                            setTxtParecer(editor.getData());
                                        }}
                                        onBlur={(event, editor) => {
                                        }}
                                        onFocus={(event, editor) => {
                                        }}
                                    />
                                </div>
                            </Accordion>
                        }
                        {(!disableDespacho || disableDespacho === false) &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant="button" display="block" gutterBottom>Despacho</Typography>
                                </AccordionSummary>
                                <Button className={classesLocal.deslocar}
                                    color="primary" onClick={() => { setOpenDialog(true); }}>
                                    Texto Padrão
                                </Button>
                                <CKEditor
                                    editor={ClassicEditor} disabled={desabilitado()}
                                    config={{
                                        toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                            '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                    }}
                                    data={txtDespacho}
                                    onReady={editor => {
                                        editor.editing.view.change(writer => {
                                            writer.setStyle(
                                                "height",
                                                "100px",
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    onChange={(event, editor) => {
                                        setTxtDespacho(editor.getData());
                                    }}
                                    onBlur={(event, editor) => {
                                    }}
                                    onFocus={(event, editor) => {
                                    }}
                                />

                            </Accordion>
                        }

                        {showEncaminhar &&

                            <Accordion>
                                <Grid item xs={12}>
                                    <TextField
                                        id="outlined-multiline-static"
                                        disabled={desabilitado()}
                                        label="Observação"
                                        value={txtDespacho}
                                        onChange={(e) => setTxtDespacho(e.target.value)}
                                        multiline
                                        rows={4}
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>
                            </Accordion>
                        }

                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="button" display="block" gutterBottom>Histórico</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {value && value.historico &&
                                    <HistoricoTable dados={value.historico}  protocolo={value.protocolo} setWait={setWait} setMensagem={setMensagem} />
                                }
                            </AccordionDetails>
                        </Accordion>

                        <Accordion onChange={handleFluxoChange}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="button" display="block" gutterBottom>Fluxo</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                {fluxo &&
                                    <FluxoPanel fluxo={fluxo} path={value.fluxoPath} />
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                    <Grid container justify="flex-end" className={classes.btns}>

                        <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                            Sair
                        </Button>
                        {!showEncaminhar && (disableDespacho && disableDespacho === true) && !desabilitado() &&
                            <Button variant="contained" color="primary" onClick={() => handleAcaoSalvarRascunho('rascunho')} disabled={podeEnviar() || txtParecer.length === 0}>
                                Salvar Rascunho
                            </Button>
                        }
                        {showVisualizar() &&
                            <Button variant="contained" color="primary" onClick={relatorio} >
                                Visualizar
                            </Button>
                        }
                        {
                            showEncaminhar && !desabilitado() &&
                            <Button variant="contained" color="primary" onClick={() => handleAcao('encaminhar')} disabled={podeEnviar() || txtDespacho.length === 0}>
                                Encaminhar
                            </Button>
                        }
                        {!showEncaminhar && (!disableDespacho || disableDespacho === false) && !desabilitado() &&
                            <Button variant="contained" color="primary" onClick={() => handleAcao('devolver')} disabled={podeEnviar() || txtDespacho.length === 0}>
                                Devolver
                            </Button>}
                        {!isAssuntoDiversos() && !showEncaminhar && (disableDespacho && disableDespacho === true) && !desabilitado() &&
                            <Button variant="contained" color="primary" onClick={() => handleAcao('indeferir')} disabled={podeEnviar() || txtParecer.length === 0}>
                                Invalidar
                            </Button>
                        }
                        {!isAssuntoDiversos() && !showEncaminhar && (disableDespacho && disableDespacho === true) && !desabilitado() &&
                            <>
                                <Button variant="contained" color="primary" onClick={() => handleAcao('deferir')} disabled={podeEnviar() || txtParecer.length === 0}>
                                Validar
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => setShowUnidadeDialogProtocolo(true)} >
                                    Enviar para a unidade
                                </Button>
                            </>
                        }
                        {isAssuntoDiversos() && !showEncaminhar && (disableDespacho && disableDespacho === true) && !desabilitado() &&
                            <>
                                <Button variant="contained" color="primary" onClick={() => handleAcao('despachar')} disabled={podeEnviar() || txtParecer.length === 0}>
                                    Parecer Técnico
                                </Button>
                                <Button variant="contained" color="primary" onClick={() => setShowUnidadeDialog(true)} disabled={podeEnviar() || txtParecer.length === 0}>
                                    Mudar Unidade
                                </Button>
                            </>
                        }
                        {!showEncaminhar && (!disableDespacho || disableDespacho === false) && !desabilitado() &&
                            <Button variant="contained" color="primary" onClick={() => handleAcao('despachar')} disabled={podeEnviar() || txtDespacho.length === 0}>
                                Despachar
                            </Button>
                        }
                    </Grid>

                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg={msgConfirmacao}
                        exec={confirmaRequerimento}
                    />
                    <TextoPadraoDialog open={openDialog} setOpen={setOpenDialog} exec={handleClickComfimarDialog} assuntoTexto={assuntoTexto}></TextoPadraoDialog>
                    <TextoPadraoDialog open={openDialogArecer} setOpen={setOpenDialogParecer} exec={handleClickComfimarDialogParecer} assuntoTexto={assuntoTexto}></TextoPadraoDialog>

                </Grid>

            </Paper>
            <UnidadeDialog open={showUnidadeDialogProtocolo} setOpen={setShowUnidadeDialogProtocolo} acao={mudarUnidade} unidades={unidades} />
            <UnidadeDialog open={showUnidadeDialog} setOpen={setShowUnidadeDialog} acao={unidadeSelecionada} unidades={unidades} />
            <ConfirmDialog
                open={showConfirmMudarUnidade}
                setOpen={setShowConfirmMudarUnidade}
                titulo={'Confirmação'}
                msg='Confirma troca da unidade?'
                exec={mudaUnidadeDoProcesso}
            />
            <ConfirmDialog
                open={showConfirmEnviarUnidade}
                setOpen={setShowConfirmEnviarUnidade}
                titulo={'Confirmação'}
                msg='Confirma enviar para a unidade?'
                exec={unidadeSelecionadaProtocolo}
            />

        </Container>
    );
}
