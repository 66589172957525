
import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
} from '@material-ui/core';
import MaskedInput from 'react-text-mask'
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useStyles, getQueryParams, API_BASE_URL } from "../../../constants";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Download from "../../../util/Download";
import { makeStyles } from '@material-ui/core/styles';
import { ValidarCPF } from '../../../constants/';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import SearchIcon from '@material-ui/icons/Search';
import BuscaDadoDialog from "../../../util/BuscaDadoDialog";
import { useUnidades } from "../../../server/UseUnidades";

function TelMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TelMask2(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

let tipoAnexo = null;
let currentRow = null;
let id = null;
let acaoSubmit = null;

export const useStylesLocal = makeStyles((theme) => ({
    btns: {
        marginTop: '10px',
        '& > *': {
            margin: '4px'
        },
    },
}));

export default function RequerimentoUnateForm(props) {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    const [processo, setProcesso] = useState();
    const { setTitulo, setMensagem, setWait, editavel } = props;
    const [processos, setProcessos] = useState([]);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [showConfirmConclusao, setShowConfirmConclusao] = useState(false);
    const [podeEditarRequerente, setPodeEditarRequerente] = useState(editavel);
    const [txt, setTxt] = useState('');
    const [showConfirm, setShowConfirm] = useState(false);
    const [showTel1, setShowTel1] = useState(false);
    const [assuntos, setAssunto] = useState([]);
    const [value, setValue] = useState({
        assunto: '',
        processo: {
            nProcesso: ''
        },
        cpf: '',
        nome: '',
        telefone: '',
        email: '',
        protocolo: '',
        tipo: '',
        declaracao: false,
    });
    const [arquivo, setArquivo] = useState();
    const [anexos, setAnexos] = useState([]);
    const history = useHistory();
    let [profile] = useCurrentUser();
    const param = getQueryParams(useLocation().search);

    const [error, setError] = useState(resetErro());
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [unidades] = useUnidades();

    useEffect(() => {

        if (profile) {
            id = param.get('id');
            if (id) return;

        }
    }, [profile]);

    useEffect(() => {
        setTitulo("Cadastrar Requerimento");
        buscaTipoDocumento();
    }, [])
    function buscaDados() {
        id = param.get('id');

        if (id) {
            setWait(true);
            fetch('/api/processoProdf/requerimento/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        setValue({
                            ...json,
                            nome: json.requerente.nome,
                            cpf: json.requerente.cpf ? json.requerente.cpf : '',
                            email: json.requerente.email,
                            telefone: json.requerente.telefone ? json.requerente.telefone : ''
                        });
                        if (json.documentos) setAnexos(json.documentos);
                        setProcesso(json.processo);
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        } else {
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarrequerimentoForm");
            }
        }
    }
    function desabilitado() {

        if (editavel === true) return true;
        if (value && value.status && value.status === 'DEFERIDO') return true;
        if (value && value.status && value.status === 'EM_ANALISE') return true;
        return false;
    }
    function atualizaProcesso(pro) {
        setProcesso(pro);
        setValue({
            ...value,
            processo: {
                id: pro.idProcesso,
                nProcesso: pro.nProcesso,
                cnpj: pro.cnpj,
                razaoSocial: pro.razaoSocial,
                programa: pro.programa
            }
        });
    }
    const handleCheck = (e) => {
        setValue({
            ...value,
            declaracao: !value.declaracao
        });
    }

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };
    function buscaTipoDocumento() {
        if (!tipoAnexo) {
            fetch('/api/info/tipoDocumento')
                .then(response => response.json())
                .then(json => {
                    tipoAnexo = json;
                    let arr = [''];
                    json.forEach(item => arr.push(item.tipo));
                    setAssunto(arr);
                    buscaDados();
                });
        } else if (assuntos.length === 0) {
            let arr = [''];
            tipoAnexo.forEach(item => arr.push(item.tipo));
            setAssunto(arr);
        }
    }

    function confirmaRequerimento() {
        setError(resetErro());
        let dado = { ...value };
        dado.requerente = {
            id: value.idUser,
            nome: value.nome,
            cpf: value.cpf,
            email: value.email,
            telefone: value.telefone,
        };

        dado.documentos = anexos;
        dado.status = "EM_ANALISE";
        acaoSubmit = "tabela";
        dado.historico = [{
            tipo: dado.status,
            data: new Date(),
            texto: "Cadastro via UNATE",
            responsavel: {
                id: profile.id,
                nome: profile.name,
                email: profile.email
            }
        }];
        if (isAssuntoDiversos()) {
            dado.descricao = txt;
        }
        if (valida(dado)) {
            submitForm(dado);
        }

        delete dado.cpf;
        delete dado.telefone;
        delete dado.email;
    }

    function submitForm(dado) {
        setWait(true);

        fetch('/api/processoProdf/requerimento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);

                if (!acaoSubmit) {
                    setMensagem("Rascunho salvo com sucesso.")
                    history.replace("/gerenciaProcesso/unate/solicitarRequerimentoOutrosTable");
                } else {
                    response.json().then(json => setValue({
                        ...value,
                        id: json.id,
                        protocolo: json.protocolo
                    }));
                    setShowConfirmConclusao(true);
                }
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function sair() {
        if (acaoSubmit === 'tabela') {
            history.replace("/home");
        }
        else {
            // history.goBack();
            history.replace("/");

        }
    }
    function relatorio() {
        var a = document.createElement("a");
        a.href = API_BASE_URL + "/api/processoProdf/requerimento/relatorio/" + id;
        a.setAttribute("target", "_blank");
        a.click();
    }
    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: { erro: false, msg: '' },
            telefone: { erro: false, msg: '' },
            email: { erro: false, msg: '' }
        }
    }
    function valida(dado) {
        if (dado.documentos.length === 0) {
            setMensagem("Obrigatório informar os documentos para analise");
            return false;
        }
        return true
    }
    const handleChangeCampo = (event) => {
        const tipo = event.target.value;
        setValue({
            ...value,
            assunto: tipo
        }
        );
        const tps = tipoAnexo.find(i => i.tipo === tipo);
        if (tps) {
            const arr = [];
            tps.lst.forEach(item => {
                arr.push({
                    tipo: item
                })
            })
            setAnexos(arr);
        } else {
            setAnexos([]);
        }

    }
    const handleEscolheFile = () => {
        let file = document.getElementById("file");
        file.click();
    }

    const handleAnexo = (row) => {
        currentRow = row;

        fileClick();
    }
    function fileClick() {
        let file = document.getElementById("file");
        file.click();
    }
    const handleAnexar = (e) => {
        let arr = [...anexos];
        arr.push({
            tipo: tipoDocumento,
            nome: arquivo.nome,
            uuid: arquivo.uuid
        });
        setAnexos(arr);
        setArquivo();
        setTipoDocumento('');
    }
    function visivelEnviar() {

        return !(value && value.status && value.status === 'EM_ANALISE');
    }
    function disable() {
        return (value && value.status && value.status === 'EM_ANALISE');
    }
    const upload = (e) => {

        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();

                    if (currentRow) {
                        let arr = [...anexos];
                        arr.forEach(i => {
                            if (i === currentRow) {
                                i.nome = nm;
                                i.uuid = result.uuid;
                            }
                        });
                        setAnexos(arr);
                        currentRow = null;
                    } else {
                        setArquivo({
                            nome: nm,
                            uuid: result.uuid
                        });
                    }
                    // document.getElementById("arquivo").value= nm;
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }

    function podeEnviar() {
        const itens = anexos.find(a => {
            if (a.uuid && a.uuid.length > 0) return true;
        });
        if (!itens) return true;
        if (!value.nome) return true;
        if (!value.telefone) return true;
        if (!value.email) return true;
        if (value && value.cpf) {
            const cpf = value.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
            if (cpf.length === 0) return true;
        }
        if (value && value.telefone) {
            const telefone = value.telefone.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
            if (telefone.length === 0) return true;
        }

        if (!value.declaracao) return true;
        if (!isAssuntoDiversos()) {
            if (value.processo.nProcesso.length === 0) return true;
            if (!value.assunto || value.assunto.length == 0) return true;
        }
        return false;
    }
    const handleTelLostFocus = () => {
        setShowTel1(value.telefone.trim().length !== 16);
    }
    const handleTelFocus = () => {
        setShowTel1(false);
    }
    function removeArquivo(item, index) {
        if (item.tipo && item.tipo.length > 0) {
            let itens = [...anexos];
            itens[index].nome = null;
            itens[index].uuid = null;

            setAnexos(itens);
        } else {
            let arr = [];
            anexos.forEach(it => {
                if (it.uuid !== item.uuid) {
                    arr.push(it);
                }
            });
            setAnexos(arr);
        }
    }

    function isAssuntoRequerimentoGeral() {
        return value.assunto === 'Requerimento geral';
    }
    function isAssuntoDiversos() {
        return value.assunto === 'Diversos';
    }
    const buscaProcessoFiltrado = () => {
        setWait(true);
        fetch("/api/gerenciarProcesso/filtro",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify({
                    cnpjs: profile.empresas
                }),
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setMensagem(result);
                } else {
                    let arr = result;

                    for (let x in arr) {
                        const item = unidades.find(u => u.id === arr[x].idUnidade);
                        if (item) {
                            arr[x].unidade = item.nome;
                        }
                    }
                    setProcessos(arr);
                    setShowBuscaDadoDialog(true);
                }
            });
    }
    const handleLostFocusCPF = (e) => {
        setError({
            ...error,
            cpf: {
                erro: false,
                msg: ''
            }
        });
        if (!value.cpf) return;
        const cpf = value.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();

        if (cpf.length === 0) return;

        if (ValidarCPF(cpf)) {
            // TODO buscar ou não CPF
            setWait(true);
            fetch("/api/processoProdf/requerimento/buscaSolicitante/" + cpf)
                .then(res => {
                    if (res.status <= 300) return res.json()
                })
                .then(
                    (result) => {
                        setWait(false);
                        setError(resetErro);
                        if (!result) {
                            setPodeEditarRequerente(false);
                            setValue({
                                ...value, nome: '', telefone: '', email: ''
                            });
                        } else {
                            setValue({
                                ...value,
                                idUser: result.id,
                                nome: result.name,
                                email: result.email,
                                telefone: result.telefone
                            });
                            setPodeEditarRequerente(true);
                        }
                    },
                    (error) => {
                        setWait(false);
                    }
                )
        } else {
            setError({
                ...error,
                cpf: {
                    erro: true,
                    msg: 'CPF inválido'
                }
            });
        }
    }

    function teste() {
        if (value.assunto === 'Requerimento transferência de incentivo – PRO-DF II' 
        || value.assunto === 'Requerimento revogação + transferência'
        || value.assunto === 'Requerimento transferência de incentivo – DESENVOLVE-DF'
        || value.assunto === 'Requerimento revogação + transferência'
        || value.assunto === 'Requerimento migração + revogação – após deferida admissibilidade'
        || value.assunto === 'Requerimento migração + transferência – após deferida admissibilidade'
        || value.assunto === 'Requerimento migração + revogação + transferência – para análise da admissibilidade'
        || value.assunto === 'Requerimento migração + revogação + transferência – após deferida admissibilidade' 
        || value.assunto === 'Requerimento convalidação + transferência'
        || value.assunto === 'Requerimento convalidação + revogação + transferência'
        ) {
           return true;
        }
        
        
    };

    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>

                    <Grid item xs={12}>
                        <TextField
                            value={profile && profile.name || ''}
                            disabled
                            fullWidth
                            label="Responável pelo preenchimento"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <FormControl variant="outlined" fullWidth disabled={disable()}>
                            <InputLabel id="assutno-label">Assunto</InputLabel>
                            <Select id="assunto" label="Assunto" placceholder="Assutno" onChange={handleChangeCampo} value={value.assunto} >
                                {assuntos && assuntos.map((assunto, i) => (
                                    <MenuItem key={i} value={assunto}>{assunto}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {!isAssuntoDiversos() &&
                        <>
                            <Grid item xs={3}>
                                <TextField
                                    value={processo && processo.nProcesso || ''}
                                    disabled
                                    fullWidth
                                    label="Processo"
                                    id="processo"
                                    name="processo"
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title='Buscar Processo'>
                                    <IconButton onClick={buscaProcessoFiltrado} >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={processo && processo.cnpj || ''} disabled
                                    fullWidth
                                    label="CNPJ"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField disabled
                                    value={processo && processo.razaoSocial || ''}
                                    fullWidth
                                    label="Razão Social"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                        </>
                    }
                    <Grid item xs={4}>
                        <TextField
                            disabled={editavel}
                            label="CPF"
                            value={value.cpf || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleLostFocusCPF}
                            error={error.cpf.erro}
                            helperText={error.cpf.msg}
                            name="cpf"
                            id="cpf-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: CPFMask,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled={podeEditarRequerente}
                            value={value.nome || ''}
                            onChange={handleChangeInputForm}
                            name="nome"
                            fullWidth
                            label="Requerente"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleTelLostFocus}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'none' : 'block' }}
                            name="telefone"
                            id="tel-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask,
                            }}
                            variant="outlined"
                        />
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'block' : 'none' }}
                            name="telefone"
                            id="tel-mask-input1"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask2,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.email.erro}
                            helperText={error.email.msg}
                            fullWidth
                            label="Email"
                            value={value.email || ''}
                            onChange={handleChangeInputForm}
                            name="email"
                            id="email-input"
                            variant="outlined"
                        />
                    </Grid>

                    {isAssuntoDiversos() &&
                        <Grid item xs={12} >
                            <CKEditor
                                editor={ClassicEditor} disabled={desabilitado()}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={txt}
                                onReady={editor => {
                                    // You can store the "editor" and use when it is needed.

                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "250px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setTxt(data);
                                }}
                                onBlur={(event, editor) => {

                                }}
                                onFocus={(event, editor) => {

                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={1} >
                            {visivelEnviar() && isAssuntoRequerimentoGeral() &&
                                <Grid item xs={12}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={8}>
                                            <TextField
                                                value={(arquivo) ? arquivo.nome : ''}
                                                fullWidth
                                                disabled
                                                // defaultValue={arquivo && arquivo.nome}
                                                display="inline"
                                                label="arquivo"
                                                id="arquivo"
                                                name="arquivo"
                                                aria-describedby="nova-helper-text"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={4} className={classesLocal.btns}>
                                            <Button variant="contained" color="primary" onClick={handleEscolheFile}>Procura anexo</Button>
                                            <Button variant="contained" color="primary" onClick={handleAnexar} disabled={!arquivo}>Anexar</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            {isAssuntoDiversos() &&
                                <Grid item xs={12}>
                                    <Grid container spacing={1} >
                                        <Grid item xs={8}>
                                            <TextField
                                                value={(arquivo) ? arquivo.nome : ''}
                                                fullWidth
                                                disabled
                                                // defaultValue={arquivo && arquivo.nome}
                                                display="inline"
                                                label="arquivo"
                                                id="arquivo"
                                                name="arquivo"
                                                aria-describedby="nova-helper-text"
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={4} className={classesLocal.btns}>
                                            <Button variant="contained" color="primary" onClick={handleEscolheFile}>Procura anexo</Button>
                                            <Button variant="contained" color="primary" onClick={handleAnexar} disabled={!arquivo}>Anexar</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <TableContainer component={Paper} >
                                    <Table aria-label="customized table" size={'small'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Tipo Documento
                                                </TableCell>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Documento
                                                </TableCell>
                                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                                    Ação
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {anexos && anexos.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{row.tipo}</TableCell>
                                                    <TableCell>{row.nome}</TableCell>
                                                    <TableCell>
                                                        <center>
                                                            {
                                                                !disable() ? (
                                                                    !row.nome ?
                                                                        <Box>
                                                                            {

                                                                                (row.arquivo || row.doc) ?
                                                                                    <Tooltip title="Remover documento">
                                                                                        <IconButton onClick={(e) => removeArquivo(row, i)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip title="Incluir documento">
                                                                                        <IconButton onClick={(e) => handleAnexo(row)}>
                                                                                            <AttachFileIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>

                                                                            }
                                                                        </Box> :
                                                                        // <Download doc={row}/>
                                                                        <Tooltip title="Remover documento">
                                                                            <IconButton onClick={(e) => removeArquivo(row, i)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    // <Tooltip title='Remover'>
                                                                    //     <IconButton onClick={(e)=>removeArquivo(row)} >
                                                                    //         <RemoveIcon />
                                                                    //     </IconButton>
                                                                    // </Tooltip>

                                                                )
                                                                    :
                                                                    <Box>
                                                                        {row.nome && <Download doc={row} />}
                                                                    </Box>

                                                            }
                                                        </center>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body2" gutterBottom>
                                    Decreto nº 41.015, de 22 de julho de 2020:
                                </Typography>
                                <br />


                                {teste() &&
                                    <Typography variant="body2" gutterBottom>
                                        TODA A DOCUMENTAÇÃO APRESENTADA É REFERENTE À EMPRESA RECEBENTE
                                    </Typography>

                                }
                                {teste() &&
                                    <br />
                                }
                                <Typography variant="body2" gutterBottom>
                                    Art. 84. Nos casos de documentação incompleta, ainda que apresentada na data limite, será concedido à empresa o prazo de 30 dias corridos para saneamento, após o que, caso permaneça a omissão, o requerimento será inadmitido pela SEDET, e arquivado sem análise ou encaminhado para abertura de procedimento de cancelamento, conforme a situação.
                                    Parágrafo único. Não será realizada a análise técnica acerca do pedido enquanto não finalizada a juntada da documentação faltante.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={value.declaracao}
                                            onChange={handleCheck}
                                            name="declaracao"
                                            color="primary"
                                        />
                                    }
                                    label="DECLARO A ENTREGA TOTAL DA DOCUMENTAÇÃO"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                            Sair
                        </Button>

                        {
                            value.id &&
                            <Button variant="contained" color="primary" onClick={() => relatorio()}>
                                Visualizar
                            </Button>
                        }

                        {
                            visivelEnviar() &&
                            <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} disabled={podeEnviar()}>
                                Cadastrar
                            </Button>
                        }

                    </Grid>
                    <ConfirmDialog
                        open={showConfirmConclusao}
                        setOpen={setShowConfirmConclusao}
                        disableCancelar={true}
                        titulo={'cadastramento de requerimento criado com sucesso'}
                        msg={'numero do protocolo gerado: ' + value.protocolo}
                        btn={"OK"}
                        exec={sair}
                    />
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirma Cadastro de Requerimento'
                        exec={confirmaRequerimento}
                    />
                </Grid>
                <BuscaDadoDialog
                    open={showBuscaDadoDialog}
                    setOpen={setShowBuscaDadoDialog}
                    acao={atualizaProcesso}
                    colunas={[
                        { nome: 'CNPJ', campo: 'cnpj' },
                        { nome: 'Nome', campo: 'razaoSocial' },
                        { nome: 'Processo', campo: 'nProcesso' },
                        { nome: 'Unidade', campo: 'unidade' }
                    ]}
                    dados={processos} />
            </Paper>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />

        </Container>
    );
}
