import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { useStyles } from '../../../constants';
import { useHistory, useLocation } from "react-router-dom";
import GenericTable from "../../../components/template/GenericTable";
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";

export default function DetalharDadosImovelProcessoPanel(props) {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();

    const { processo, gestaoProcesso } = props;

    function formataDigito(valor) {
        if (valor.substring(1, 2) == '.') {
            valor.replace(".", "");
            return valor;
        }
        valor = parseInt(valor)

        if (valor == null) return '';
        return valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    }

    return (
        <Container>
            <GenericTable
                // actionClick={handleActionSolicitarClick}
                // handleDisableAction={desabilitaAcaoExcluirTable}
                // options={['Visualizar', 'Alterar', 'Excluir']}
                dados={processo.dadosImovel}
                disableSearch={true}
                readOnly={true}
                colunas={[
                    { nome: 'Número da Proposta', campo: 'proposta' },
                    { nome: 'RA', campo: 'ra' },
                    { nome: 'ADE', campo: 'ade' },
                    { nome: 'Endereço', campo: 'endereco' },
                    { nome: 'Área', campo: 'area', transformacao: formataDigito }
                ]} />
            {/* <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="imovel"/> */}
        </Container>
    );
}
