import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Typography
} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles } from "../../constants";
import FormControl from '@material-ui/core/FormControl';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import GenericTable from '../template/GenericTable';
import ConfirmDialog from '../../util/ConfirmDialog';
import { ptBR } from 'date-fns/locale';


const turno = ['MANHA', 'TARDE'];
const dia = ['SEGUNDA', 'TERÇA', 'QUARTA', 'QUINTA', 'SEXTA', 'SABADO', 'DOMINGO'];
let idVistoria;

export default function CadastrarCapacidadeForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, disable, setErro } = props;
    const [value, setValue] = useState();
    const history = useHistory();
    const [showConfirm, setShowConfirm] = useState(false);
    const [error, setError] = useState(resetErro());
    const [erroCamp, setErroCamp] = useState(false);
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);

    useEffect(() => {
        setTitulo("Cadastrar Capacidade Vistoria");
        buscaCapacidade();
    }, [])

    function buscaCapacidade() {
        setWait(true);
        fetch("/api/processoProdf/capacidade")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    let arr = [];
                    let arr2 = [];

                    for (let m in dia) {
                        let itens = result.filter(r => r.dia === dia[m])
                        itens = itens.sort((a, b) => ((a.turno < b.turno) ? -1 : 1));
                        for (let aa in itens) {
                            arr2.push(itens[aa]);
                        }
                    }

                    for (let x in arr2) {
                        const capacidade = arr2[x];
                        arr.push({
                            id: capacidade.id,
                            dia: capacidade.dia,
                            turno: capacidade.turno,
                            quantidade: capacidade.quantidade
                        })
                    }
                    setFonteDeDados(arr);
                }
            });
    }

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };

    function sair() {
        history.replace("/home");
    }

    function isReadOnly() {
        return false;
    }

    function resetErro() {
        return {
            processo: { erro: false, msg: '' },

            campo3: { erro: false, msg: '' },
        }
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        idVistoria = dado.id
        if (acao && acao === 'Excluir') { 
            setShowConfirmExclusao(true)
        }
    }

    function confirmaExclusao() {
        const dado = {
            id: idVistoria,
        }
        setWait(true);
        fetch("/api/processoProdf/capacidade/" + dado.id, {
            method: 'DELETE'
        }).then((result) => {
            setWait(false);
            if (!result.ok) {
                setErro(result && result.message ? result.message : result.statusText);
            } else {
                buscaCapacidade();
                setMensagem("Registro excluido!")
            }
        }).catch(error => {
            setWait(false);
            setErro((setErro && setErro.statusText) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function confirmaAprovacao() {
        setError(resetErro());
        
        let dado = {
            dia: value.dia,
            turno: value.turno,
            quantidade: (value.quantidade != null ? (value.quantidade.replace(/^0+/, '') == '' ? '0' : value.quantidade.replace(/^0+/, '')) : "0"),
        };

        submitForm(dado);
    }
    function disableBtn() {
        if (value == null || value.dia == null || value.turno == null) {
            return true;
        }
        if (value.quantidade <= 0) {
            return true;
        }
        return false;
    }


    function submitForm(dado) {
        fetch('/api/processoProdf/capacidade', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                setMensagem("Capacidade cadastrada com sucesso");
                buscaCapacidade();
                setValue({});
            } else {
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            }
        }).catch(error => {
            topFunction();
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function teste(st) {
        if (st === 'SEGUNDA') {
            return "1234"
        }
    }



    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='md'>
                <Paper >
                    <Grid container spacing={1} className={classes.root}>
                        <Typography variant="h5" gutterBottom>Capacidade</Typography>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth disabled={disable && disable === true} error={erroCamp} >
                                <InputLabel id="tipo" >Dia</InputLabel>
                                <Select id="dia" name='dia' label="Dia" placceholder="Dia" onChange={handleChangeInputForm} value={value && value.dia || ""} >
                                    {dia && dia.map((tp, i) => (
                                        <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth disabled={disable && disable === true} error={erroCamp} >
                                <InputLabel id="tipo" >Turno</InputLabel>
                                <Select id="turno" name='turno' label="turno" placceholder="turno" onChange={handleChangeInputForm} value={value && value.turno || ""} >
                                    {turno && turno.map((tp, i) => (
                                        <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                disabled={disable && disable === true}
                                value={value && value.quantidade || 0}
                                fullWidth
                                label="Quantidade"
                                id="quantidade"
                                name="quantidade"
                                onChange={handleChangeInputForm}
                                type="number"
                                aria-describedby="nova-helper-text"
                                variant="outlined"

                            />
                        </Grid>
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" color="primary"
                                rel="noopener noreferrer"
                                onClick={() => setShowConfirm(true)}
                                disabled={disableBtn()}
                            >
                                Cadastrar / Alterar
                            </Button>
                        </Grid>

                        <GenericTable
                            actionClick={handleActionSolicitarClick}
                            disableSearch={true}
                            dados={fonteDeDados}
                            readOnly={isReadOnly()}
                            options={['Excluir']}
                            colunas={[
                                { nome: 'Dia', campo: 'dia' },
                                { nome: 'Turno', campo: 'turno' },
                                { nome: 'Quantidade', campo: 'quantidade' },
                                { nome: 'Ação', campo: 'action' }
                            ]} />
                    </Grid>
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg={"Confirma cadastro de capacidade vistoria?"}
                        exec={confirmaAprovacao}
                    />

                    <ConfirmDialog
                        open={showConfirmExclusao}
                        setOpen={setShowConfirmExclusao}
                        titulo={'Confirmação'}
                        msg='Confirma exclusão do item selecionado?.'
                        exec={confirmaExclusao}
                    />

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                            Sair
                        </Button>

                    </Grid>


                </Paper>
            </Container>
        </MuiPickersUtilsProvider>

    );
}