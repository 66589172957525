import React, { useEffect, useState} from 'react';
import {
    Grid, IconButton, Paper, Menu, MenuItem,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip
} from '@material-ui/core';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import CampoBusca from "../../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import {useStyles, FormataDataComHora, filtroDadoGenerico, fluxoDespacho} from "../../../constants";
import { useHistory, useLocation } from "react-router-dom";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useUnidades } from "../../../server/UseUnidades";
import {useFluxo, useFluxoDiversos} from "../../../server/UseFluxo";
import Service  from "../../../services/Service";

let options = [
    'Visualizar',
    'Analisar'
];
let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;
let acao;
let requerimento;

export default function SempAvaliarRequerimentoTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait } = props;
    let [profile] = useCurrentUser();
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);
    const [value, setValue] = React.useState('Todos Requerimento');
    const history = useHistory();
    const [update, setUpdate]= useState(false);
    const [protocoloList, setProtocoloList] = useState([]);
    
    const [unidades] = useUnidades();
    const location = useLocation();
    const [fluxo] = useFluxo();
    const [fluxoDiverso] = useFluxoDiversos();

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    useEffect(() => {
        if( profile && fluxo && fluxoDiverso ){
            buscaTodosRequerimento();
        }
    },[profile, unidades, fluxo, fluxoDiverso]);
    
    useEffect(() => {
        setTitulo("Analisar Requerimento");
        
    },[]);
    
    useEffect(() => {
        if( update){
            buscaTodosRequerimento();
            setUpdate(false);
        }
    });

    useEffect(() => {
        // fonteDeDados = dados;
        filtraDado();
    }, [ordem, pagina, bloco, value]);
    
    function buscaTodosRequerimento(){
        if( !profile || !unidades || !fluxo) return;
        setWait(true);
        fetch("/api/processoProdf/requerimento/status/PARECER_TECNICO",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    result.sort((a, b) => {
                        const protocoloA = a.protocolo.split("-");
                        const protocoloB = b.protocolo.split("-");
                        const sA = parseInt(protocoloA[1]+protocoloA[0]);
                        const sB = parseInt(protocoloB[1]+protocoloB[0]);
                        return sA < sB?1:-1;
                    });

                    fonteDeDados = [];
                    for(let x in result){
                        const d = result[x];
                        
                        if( dadoNaoDeveAparecerTecnico(d) ) continue;
                        fonteDeDados.push(
                            {
                                id: d.id,
                                nProcesso: getNumeroProcesso(d),
                                protocolo: d.protocolo,
                                dataCriacao: d.dataCriacao,
                                dataAnalise: d.dataAnalise,
                                status: d.status,
                                programa: d.processo.programa,
                                responsavel: showResponsavel(d),
                                responsaveis: d.responsaveis,
                                fluxoPath: d.fluxoPath,
                                assunto: d.assunto,
                                historico: d.historico
                            }
                        );
                    }
                    filtraDado();
                }
            });        
    }
    const handleAcao = (e, index) => {
        indexProcesso = index;
        requerimento = dadosFiltrado[indexProcesso];
        let item = protocoloList.find(i => i.protocolo === requerimento.protocolo);
        if( !item ){
            setWait(true);
            Service.GET("/api/processoProdf/protocolo/byNumProtocolo/" + requerimento.protocolo).then(result =>{
                setWait(false);
                let arr = [...protocoloList];
                arr.push( result);
                setProtocoloList(arr);
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        }
        setAnchorEl(e.currentTarget);
    }
    function dadoNaoDeveAparecerTecnico(d){
        if( d.assunto === 'Notificacao' || d.assunto === 'Complemento') return true;
        
        if( d.assunto === 'Diversos' || !location.pathname.includes("despacho")){
            // if( profile && profile.unidade){
                // if( !hasUnidade(d) ) return true;
            // }
        }
        // else{
        //     if( !hasUnidadeFluxo(d) ) return true;
        // }
        return false;
    }
    function hasUnidadeFluxo(r){        
    
        const id = parseInt( profile.unidade.id ); // usuario
        const idUnProc = r.processo.idUnidade;
        
        let filhosDoLogado = [];
        buscaParentes(id, filhosDoLogado); //unidades.filter(u => u.pai === id);

        let lastFluxo = r.fluxoPath[r.fluxoPath.length - 1];
        const unidadesExecutoras = fluxo.nodes.find(no => no.node === lastFluxo);

        if(  idUnProc === id){
            const itens  = unidadesExecutoras.unidadesExecutoras.filter( u => u === id)
            if( itens && itens.length > 0 ) return true
            return false;
        }else{
            for( let x in filhosDoLogado ){
                const idUniFilho = filhosDoLogado[x].id;
                if( idUniFilho === idUnProc ){
                    for( let y in unidadesExecutoras.unidadesExecutoras){
                        const idUnidExecutora = unidadesExecutoras.unidadesExecutoras[y];
                        if( idUnidExecutora === id ) return true;
                    }
                }
            }
        }
    }
    function buscaParentes(id, arr){
        const itens = unidades.filter(u => u.pai === id);
        if( itens && itens.length > 0 ){
            for(let x in itens){
                arr.push( itens[x]);
                buscaParentes(itens[x].id, arr);
            }
        }
    }
    function hasUnidadeExecutora(r){
        const idUnidade = parseInt( profile.unidade.id );//;
        let lastFluxo = r.fluxoPath[r.fluxoPath.length - 1];
        const item = fluxo.nodes.find(no => no.node === lastFluxo);
        if( item && item.unidadesExecutoras){
            for(let x in item.unidadesExecutoras){
                const unidadeExec = item.unidadesExecutoras[x];
                if( unidadeExec === idUnidade)  return true;
                
                const filhos = unidades.filter(u => u.pai === idUnidade)
                const itens = filhos.filter( f => f.id === unidadeExec);
                if( itens && itens.length > 0 ){
                    return true;
                }
            
            }
        }
        return false;
    }
    function hasUnidade(r){
        if( r.assunto === 'Diversos' ){
            const arr = r.historico.filter(h => h.tipo === 'unidade');
            if( arr && arr.length > 0 ){
                const idUnidade = parseInt( arr[arr.length-1].texto);
                const id = parseInt( profile.unidade.id );
                if( idUnidade === id) return true;

                const paiRequerimento = unidades.find(u => u.id === idUnidade);
                if( paiRequerimento.pai === id) return true;         

            }
        }else{
            const id = parseInt( profile.unidade.id );
            let has = r.processo.idUnidade === id;
            if( !has && unidades){
                const filhos = unidades.filter(u => u.pai === id)
                const itens = filhos.filter( f => f.id === r.processo.idUnidade);
                has = itens && itens.length > 0 ;
            }
            return has;
        }
        return false;
    }

    const handleActionClick = (action) => {
        requerimento = dadosFiltrado[indexProcesso];
        acao = options[action];
        let assunto = requerimento.assunto.split('-')[0].trim();
        
        setAnchorEl(null);
        if( acao === 'Analisar'){
            history.replace("/gerenciaProcesso/semp/parecerTecnicoRequerimentoForm?id="+requerimento.id+"&assunto="+assunto);
        }else{
            history.replace("/gerenciaProcesso/semp/parecerTecnicoRequerimentoForm?id="+requerimento.id+"&editable=false&assunto="+assunto);
        }
      };
    
    function disabledMenuItem(option, index){
        
        if( !profile) return;
        
        if( option === 'Visualizar'){
            return false;
        }
        
        if( requerimento && requerimento.status === "EM_ANALISE"){
            return true;
        }
        if( requerimento && requerimento.status === "PARECER_TECNICO"){
            let item = protocoloList.find(i => i.protocolo === requerimento.protocolo);
            if( item && item.unidade){
                if(profile.unidade){
                    if( item.unidade !== profile.unidade.id){
                        return true;
                    }
                }
            }
            return false;
        }
        return true;
    }
    function isDespachosOk(){
        //TODO entender o que seria isso
        return false;
    }
    const handleClose=()=>{
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
          disparaSearch();
        }
        elapseTime = performance.now();
      }
    
      function disparaSearch() {
        setTimeout(function () {
          if (performance.now() - elapseTime > tempo) {
            setBloco(0);
            filtraDado(filtroCampo);
          } else {
            disparaSearch();
          }
        }, tempo);
      }

    function getTituloSituacao(row) {
        return "Visualizar";
    }

    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];
        let arrOrdenado = ordenar(fonteDeDados);
        filtroDadoGenerico(arrOrdenado, arr, filtroCampo);
        let arrBloco = [];
        qtd = arr.length;
        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (++index >= pagina) break;
        }
        setDadosFiltrado(arrBloco);
    }
    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            if( ordem.campo === 'protocolo'){
                ordenado = ordenado.sort((a, b) => {
                    const protocoloA = a.protocolo.split("-");
                    const protocoloB = b.protocolo.split("-");
                    const sA = parseInt(protocoloA[1]+protocoloA[0]);
                    const sB = parseInt(protocoloB[1]+protocoloB[0]);
                    return ((sA < sB) ? 1 * asc : -1 * asc)
                });
            }else{
                ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
            }
        }
        return ordenado;
    }
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
        setBloco(0);
    }
    
    function getNumeroProcesso(row){
        if( typeof row.processo === 'string'){
            return row.processo;
        }else{
            return row.processo.nProcesso;
        }
    }
    function showResponsavel(row){
        if( row.responsaveis ){
            let ret = '';
            row.responsaveis.forEach(r => ret += r.nome+' ');
            return ret.trim();
        }
        return "";
    }
    function showStatus(row){
        return fluxoDespacho(row, fluxo, fluxoDiverso);
    }
    function isAcessor(){
        if( profile){
            return profile.regras.find(item => item === '013')
        }
        return false;
    }
    function dataValidacaoUnate(row){
        const item = row.historico.find(h => h.tipo === 'valGestaoUnate' );
        if( item ){
            const dt = new Date(item.data);
            if( dt ){
                return FormataDataComHora(dt);
            }
        }
        return '';
    }
    return (
        <Grid container spacing={3} className={classes.root}>
           
            <Grid item xs={6} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'protocolo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('protocolo')}
                                    >
                                        Protocolo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'processo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('processo')}
                                    >
                                        Processo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'assunto'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('assunto')}
                                    >
                                        Assunto
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'dataCriacao'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataCriacao')}
                                    >
                                        Data Solicitação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'dataAnalise'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataAnalise')}
                                    >
                                        Data Validação Unate
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'status'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('status')}
                                    >
                                        Situação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'programa'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('programa')}
                                    >
                                        Programa
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'responsavel'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('responsavel')}
                                    >
                                        Responsável Unate
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell >{row.protocolo}</TableCell>
                                    <TableCell>{ row.nProcesso }</TableCell>
                                    <TableCell>{row.assunto}</TableCell>
                                    <TableCell>{ FormataDataComHora( new Date(row.dataCriacao)) }</TableCell>
                                    <TableCell>{ dataValidacaoUnate(row) }</TableCell>
                                    <TableCell>{ showStatus(row) }</TableCell>
                                    <TableCell>{ row.programa }</TableCell>
                                    <TableCell>{row.responsavel}</TableCell>
                                    <TableCell width='100px' align="center">
                                        {/* <Box > */}
                                            {/* <Tooltip title="Visualizar">
                                                <IconButton href={"/template/templateForm/"+i} >
                                                <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip> */}
                                            <Tooltip title={getTituloSituacao(row)}>
                                                <IconButton onClick={(e) => handleAcao(e, i)}>
                                                    <MoreVertIcon/>
                                                </IconButton>
                                            </Tooltip>

                                        {/* </Box> */}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    fonteDeDados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ qtd }
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                // style: {
                //     maxHeight: ITEM_HEIGHT * 4.5,
                //     width: '20ch',
                // },
                }}
            >
                {options.map((option, index) => (
                        <MenuItem disabled={ disabledMenuItem(option, index)}
                            key={option} 
                            selected={option === 'Pyxis'} 
                            onClick={()=>handleActionClick(index)}>
                            {option}
                        </MenuItem>
                ))}
            </Menu>
        </Grid>

    );
}
