import React, {useEffect, useState} from "react";
import { createStyles, makeStyles} from '@material-ui/core/styles';
import {Container, Grid, InputBase, Paper, Typography} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import UsersTable from './UsersTable';
import Alert from '@material-ui/lab/Alert';
import TrocarPerfilForm from './TrocarPerfilForm';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: '60px'
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

let elapseTime = 0;
let tempo = 600;
let filtroCampo=null;
let acessoFonte = null;

let primeiro = true;

export default function UsersPanel() {
  const classes = useStyles();
  const [dados, setDados] = React.useState([]);
  const [erro, setErro] = useState();
  const [trocarPerfil, setTrocarPerfil] = useState();
  const [pagina, setPagina] = useState(5);
  const [bloco, setBloco] = useState(0);
  const [titulo, setTitulo] = useState("Usuários do Sistema");
  const [ordem, setOrdem] = useState({
    campo: 'name',
    ordem: 'desc'
  });

  useEffect(() => {
    async function fetchData(){
      const response = await fetch('/api/usuariosSistema', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });

      const data = await response.json();
      if( data.status > 300){
        
        return;
      } else {
        let arr= [];
        data.forEach(d =>{
          if( d.situacao){
            arr.push(d)
          }
        })
        acessoFonte = arr;
        filtraDado();
      }
    }
    if( primeiro ){
        fetchData();
        primeiro = false;   
    }
  },[dados] );

  useEffect(() => {
    
    filtraDado();
  }, [ordem, pagina, bloco]);


  function ordenar(arr) {     
    let ordenado = arr;    
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }   
    
    return ordenado;
  }

  function filtraDado() {   
    if (!acessoFonte) return;
    let arr = [];
    let users = ordenar(acessoFonte);

    users.forEach(d => {
      if( filtroCampo ) {
        let campos = filtroCampo.split(' ');
        root: for( var x in d) {
          for( var y in campos) {
            if( typeof d[x] === 'string' ) {
              if( (d[x] && campos[y]) && (d[x].toUpperCase().includes(campos[y].toUpperCase())) ) {
                arr.push( d );
                break root;
              }
            } else if( d[x] === campos[y]) {
              arr.push( d );
              break root;
            }
          }
        }
      } else {
        arr.push( d );
      }
    });
    setDados(arr);
  }

  const handleSearch =(e) =>{
    filtroCampo = e.target.value;

    if( performance.now() - elapseTime > tempo ){
      disparaSearch();
    }

    elapseTime = performance.now();
  }

  function disparaSearch(){
    setTimeout(function () {
      if( performance.now() - elapseTime > tempo ) {
        filtraDado(filtroCampo);
      } else {
        disparaSearch();
      }
    }, tempo);        
  }

  return (
    <Container maxWidth="xl" className={classes.root}>

      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
          </Paper>
        </Grid>
      </Grid>

    { !trocarPerfil &&
      <Paper className={classes.paper}>
      {
        erro && <Alert severity="error">{erro.statusText || erro.error}</Alert>
      }
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Buscar…"
                onChange={ handleSearch}
                fullWidth
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <UsersTable rows={ dados } setOrdem={setOrdem} ordem={ordem} setRows={setDados}
             setErro={setErro} setTrocarPerfil={setTrocarPerfil} setBloco={setBloco} bloco={bloco} setPagina={setPagina} pagina={pagina} />
          </Grid>
        </Grid>
      </Paper>
    }
    { trocarPerfil &&
      <TrocarPerfilForm setTrocarPerfil={setTrocarPerfil} usuario={trocarPerfil} setTitulo={setTitulo}/>
    }
    </Container>
  )
};
