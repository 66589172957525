import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Grid, Paper, Typography } from '@material-ui/core';
import { FormataMoeda } from "../../../constants";
import TableMoedaCell from "./TableMoedaCell";
import FruicaoGraficos from './FruicaoGraficos';


const useStylesLocal = makeStyles(theme => ({
    input: {
        width: 80,
        height: 30
    },
    table: {
        minWidth: 700
    },
    tablecell: {
        fontSize: 14,
        fontWeight: 700
    },
    tableHead: {
        backgroundColor: '#45b97c',
        color: theme.palette.common.white,
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold, //.fontWeightRegular,
    },

}));

export default function AnaliseExecucaoTable(props) {
    const classes = useStylesLocal();
    // const classes = useStyles();
    const { dados, setDados, salvarDados, disabled, dadoApoio } = props;
    const [dadolocal, setDadolocal] = useState([]);
    const [componenteAtual, setComponenteAtual] = useState();
    const [fruicao, setFruicao] = useState([]);
    const [total, setTotal] = useState([]);
    const [editado, setEditado] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [dadoOrdenavel, setDadoOrdenavel] = useState([]);
    const [operacao, setOperacao] = useState([0,0]);
    
    useEffect(() => {

        if (dados && dados.execucaoOperacaoFruicao && dados.execucaoOperacaoFruicao.length > 0) {
            let arr = [];
            let arrOr = [...dados.execucaoOperacaoFruicao];
            arrOr = arrOr.sort( (a,b) => a.ano - b.ano);
            for (let y in arrOr) {
                const d = arrOr[y];

                // const apoio = dadoApoio ? dadoApoio.find(e => e.exercicio === d.ano) : null;
                
                // const apoioSeguinte = dadoApoio ? dadoApoio.find(e => e.exercicio === (d.ano + 1)) : null;

                let operacoesRealizadas = 0;
                let arrecadacaoICMS = 0;
                let arrecadacaoICMSSeguinte = 0;
                for (let x = 0; x < 12; x++) {
                    if (d.execucoes[x]) operacoesRealizadas += d.execucoes[x].valorContabilICMSSIGES;
                    if (d.execucoes[x]) arrecadacaoICMS += d.execucoes[x].ICMSRecolherSIGES;
                    if (d.execucoes[x + 1]) arrecadacaoICMSSeguinte += d.execucoes[x + 1].ICMSRecolherSIGES;
                }
                const arrecadacaoRealICMS = calculaArrecadacaoReal(d.ano)* arrecadacaoICMS;
                arr.push({
                    ano: d.ano,
                    operacoesRealizadas: operacoesRealizadas,
                    arrecadacaoICMS: arrecadacaoICMS,
                    arrecadacaoRealICMS: arrecadacaoRealICMS
                    // crescimentoRealArrecadado: calculaCrescimentoRealArrecadado(arrecadacaoRealICMS) //arrecadacaoICMS && arrecadacaoICMSSeguinte ? arrecadacaoICMS / arrecadacaoICMSSeguinte : "-"
                });
            }
            calculaCrescimentoRealArrecadado(arr);
            setDadolocal(arr);
            
            setDadoOrdenavel( arrOr );
            
            // let projecaoArrecadacaoICMSTot = 0 ;
            // for( let x in dados.execucaoOperacaoFruicao){
            //     const v = dados.execucaoOperacaoFruicao[x];
            //     projecaoArrecadacaoICMSTot += v.projecaoArrecadacaoICMS;
            // }
            // setTotal([projecaoArrecadacaoICMSTot, 100000]);
        }

        

    }, [dados, dadoApoio]);

    function calculaCrescimentoRealArrecadado(arr){
        for( let i in arr){
            const arrecadacaoRealICMS = arr[i].arrecadacaoRealICMS;
            let calc = 0;
            for( let ii in arr){
                if( arr[ii].ano === arr[i].ano-1){
                    calc = (arrecadacaoRealICMS - arr[ii].arrecadacaoRealICMS )/arr[ii].arrecadacaoRealICMS;
                    break;
                }
            }
            arr[i].crescimentoRealArrecadado = FormataPerc(calc*100);
        }
    }
    function calculaArrecadacaoReal(anoRef){
        //T28*(1+Q23)*(1+Q24)
        let retorno = 1;
        for(let x in dadoApoio){
            const da = dadoApoio[x];
            if( anoRef < da.exercicio){
                const indiceAnaliseExec = da.indiceAnaliseExec?da.indiceAnaliseExec/100:0;
                // const apoioSeguinte = dadoApoio ? dadoApoio.find(e => e.exercicio === (d.ano + 1)) : null;

                retorno *= (1+indiceAnaliseExec);//dadoApoio && apoio && apoioSeguinte ? arrecadacaoICMS * apoioSeguinte.indiceAnaliseExec / apoio.indiceAnaliseExec;
            }
        }
        return retorno;// > 1? retorno:0;
        // return 100;
    }

    const onChange = (e, row, index) => {
        if (!/^[0-9]*\,?[0-9]*$/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^0-9]*\,?[0-9]*$/g, "");
            return;
        }
        let arr = {...dados};
        let val = { ...arr.execucaoOperacaoFruicao[index] };
        let valor = e.target.value;
        valor = valor.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '');
        valor = valor.replace(",", ".");
        val[e.target.name] = parseFloat(valor);

        arr.execucaoOperacaoFruicao[index] = val;
        setDados(arr);
        setEditado(true)
    };
    function apresentaValor(index, nome) {
        if (dados && dados.execucaoOperacaoFruicao && dados.execucaoOperacaoFruicao.length > 0) {
            if (dados.execucaoOperacaoFruicao[index]) {
                if (dadolocal[index] && (nome === "arrecadacaoICMS" || nome === "arrecadacaoRealICMS")) {
                    return FormataMoeda(dadolocal[index][nome]);
                }
                return FormataMoeda(dados.execucaoOperacaoFruicao[index][nome]);
            }
            return '';
        }
        return '';
    }
    function apresentaValorEmprego(index, nome) {
        if (dadoOrdenavel.length > 0) {
            if (dadoOrdenavel[index]) {
                if (dadolocal[index] && (nome === "operacoesRealizadas")) {
                    return FormataMoeda(dadolocal[index][nome]);
                    // return FormataMoeda(dadoOrdenavel[index][nome] );
                }
                return dadoOrdenavel[index][nome];
            }
            return '';
        }
        return '';
    }
    function apresentaValorPorcento(index, nome) {
        if (dados && dados.execucaoOperacaoFruicao && dados.execucaoOperacaoFruicao.length > 0) {
            if (dados.execucaoOperacaoFruicao[index]) {
                if (dadolocal[index] && (nome === "crescimentoRealArrecadado")) {
                    // return dadolocal[index][nome];
                    if(dadolocal[index][nome] === "-") return "-";
                    let x = dadolocal[index][nome] + '%';
                    return x;
                    // return x.replace(".", ",");
                }
                let x = Number(dados.execucaoOperacaoFruicao[index][nome]).toFixed(4) + '%';
                return x.replace(".", ",");
            }
            return '';
        }
        return '';
    }

    function proximo(nome) {
        let arr = nome.split("nm_");
        let col = parseInt(arr[0]);
        let lin = parseInt(arr[1]);
        if( col >= 2){
            lin++;
            col=1;
        }else{
            col++;
        }
        setComponenteAtual(col + "nm_" + lin);
    }

    const buscaGrafico = (index) => {
        let dado = {...dados.execucaoOperacaoFruicao[index]}

        let operacao = dado.operacoes;
        let execusao = dado.execucoes;
        let geral = dado.geral;
        let especial = dado.especial;
        let relevante = dado.revelanteInteres;
        let renda = dado.renda;
        let terceirizado = dado.terceirizados

        dado.operacoes = montaTabela([...operacao]);
        dado.execucoes = montaTabela([...execusao])
        dado.geral = montaTabela([...geral])
        dado.especial = montaTabela([...especial])
        dado.relevante = montaTabela([...relevante])
        dado.renda = montaTabela([...renda])
        dado.terceirizado = montaTabela([...terceirizado])
       
        setFruicao(dado);
        setOperacao([dado.faturamentoPrevisao, dadolocal[index].operacoesRealizadas]);

        setTotal([dado.projecaoArrecadacaoICMS, dadolocal[index].arrecadacaoRealICMS ]);
    }

    function montaTabela(arr) {
        for (let x in arr) {
            let val = arr[x] ? arr[x] : {};
            val.mes = parseInt(x);
            arr[x] = val;
        }
        return arr;
    }
    function calcula(){
        if (editado) salvarDados();
        setEditado(false)
    }
    function buscaIndice(index, row){
        const item = dadoApoio.find( da => da.exercicio === row.ano)
        return (item && item.indiceAnaliseExec)?item.indiceAnaliseExec:0
    }
    function FormataPerc(val){
        const opt = {
            maximumFractionDigits:2,
            minimumFractionDigits:2,
          };
        if( val === 0 || val === '0')return val.toLocaleString('pt-br', opt);
        if( !val) return '';
        var f = val.toLocaleString('pt-br',opt);
        return f;
    }
    const handleSelect = (index) =>{
        setSelectedIndex(index);
        buscaGrafico(index);
    }
   
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>

                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead} align="right" sortDirection={false}>
                                    Ano Referência
                                </TableCell>
                                <TableCell className={classes.tableHead} align="right">Faturamento Previsão</TableCell>
                                <TableCell className={classes.tableHead} align="right">Operações realizadas</TableCell>
                                <TableCell className={classes.tableHead} align="right">Projeção de arrecadação ICMS</TableCell>
                                <TableCell className={classes.tableHead} align="right">Arrecadação ICMS</TableCell>
                                <TableCell className={classes.tableHead} align="right">INPC</TableCell>
                                <TableCell className={classes.tableHead} align="right">Arrecadação real ICMS</TableCell>
                                <TableCell className={classes.tableHead} align="right">Crescimento Real Arrecadado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadoOrdenavel.map((row, index) => (
                                <TableRow hover key={index} onClick={ () => handleSelect(index)} selected={index === selectedIndex}>
                                    <TableMoedaCell index={index} disabled={true} row={row.ano} calcula={calcula} name="ano" valor={apresentaValorEmprego(index, 'ano')} />
                                    <TableMoedaCell clikeConsoeLog={buscaGrafico} componenteAtual={componenteAtual} proximo={proximo} nome={"1nm_"+index} index={index} disabled={disabled} row={row.ano} calcula={calcula} name="faturamentoPrevisao" onChange={onChange} valor={apresentaValor(index, 'faturamentoPrevisao')} />
                                    <TableMoedaCell index={index} disabled={true} row={row.ano} calcula={calcula} name="operacoesRealizadas" valor={apresentaValorEmprego(index, 'operacoesRealizadas')} />
                                    <TableMoedaCell focus={buscaGrafico} componenteAtual={componenteAtual} proximo={proximo} nome={"2nm_"+index} index={index} disabled={disabled} row={row.ano} calcula={calcula} name="projecaoArrecadacaoICMS" onChange={onChange} valor={apresentaValor(index, 'projecaoArrecadacaoICMS')} />
                                    <TableMoedaCell index={index} disabled={true} row={row.ano} calcula={calcula} name="arrecadacaoICMS" valor={apresentaValor(index, 'arrecadacaoICMS')} />

                                    <TableMoedaCell index={index} disabled={true} row={row.ano} calcula={calcula} name="arrecadacaoRealICMS" valor={FormataPerc(buscaIndice(index, row)) } />

                                    <TableMoedaCell index={index} disabled={true} row={row.ano} calcula={calcula} name="arrecadacaoRealICMS" valor={apresentaValor(index, 'arrecadacaoRealICMS')} />
                                    <TableMoedaCell index={index} disabled={true} row={row.ano} calcula={calcula} name="crescimentoRealArrecadado" valor={apresentaValorPorcento(index, 'crescimentoRealArrecadado')} />
                                </TableRow>
                            ))}
                            {/* <TableRow style={{ background: '#fafafa' }} className={classes.tablecell}
                            >
                                <TableCell className={classes.tablecell} component="th" scope="row" >Total</TableCell>
                                <TableCell className={classes.tablecell} align="right" >{total.empregosDiretos}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{total.totalEmpregoIndiretos}</TableCell>
                                <TableCell className={classes.tablecell} align="right">{total.totalPostoTrabalhoGerado}</TableCell>
                            </TableRow> */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12}>
             <Typography className={classes.heading} ></Typography>
                <FruicaoGraficos graficoPiz={false} dado={fruicao} operacao={operacao} dadoCalculado={dadolocal} total={total} />    
            </Grid>
        </Grid>
    );
}