import React, { useEffect,useState } from 'react';
import {
    Button, Grid, Paper, Container,TextField
}
    from '@material-ui/core';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, FormataData } from "../../../constants"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Service from "../../../services/Service";
import DateFnsUtils from '@date-io/date-fns';
import NumberFormat from 'react-number-format';
import { ptBR } from 'date-fns/locale';
import EtapaProcessoComp from './EtapaProcessoComp';

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={4}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}
export default function CadastroAtestadoForm(props) {
    const classes = useStyles();
    const { setTitulo,setMensagem, setWait,setErro } = props;
    const [id, setId] = useState();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [dataAip ,setDataAip] = useState();
    const [dataAid ,setDataAid] = useState();
    const [dataAiddf ,setDataAiddf] = useState();
    const [value, setValue] = useState({ atestadoImplantacao: {} });
    useEffect(() => {
        setTitulo("Cadastrar Atestado");
        if (param.get("id")) {
            setId(param.get("id"));
            fetch(`/api/processoProdf/${param.get("id")}`).then((r) => {
                setWait(false);
                if (!r.ok) {
                    r.json().then(j => setErro(j));

                } else {
                    r.json().then(result => {
                        setValue(result);
                        if(result.atestadoImplantacao){
                            setDataAip(formatarDataString(result.atestadoImplantacao.dataAip));
                            setDataAid(formatarDataString(result.atestadoImplantacao.dataAid));
                            setDataAiddf(formatarDataString(result.atestadoImplantacao.dataAiddf));
                        }
                    });
                }
            });
        }
    }, []);


    function formatarDataString(d) {
        if(!d) return '';
        const data = d.split("/");
        if(data.length === 3){
            return new Date(data[2],(data[1]-1),data[0]);
        }
        return '';
    }

    function sair() {
        //history.replace("/gerenciaProcesso/cadastrarProcesso?id=123456789abcd&panel=cartaConsulta");
        if (id) history.replace("/gerenciaProcesso/processo/" + id + "?panel=atestado");
        else history.replace("/gerenciaProcesso/cadastrarProcesso/?panel=atestado");
    }

    function salvar(){
        let dado = {
            processo: {
                ...value,
                atestadoImplantacao: {
                    ...value.atestadoImplantacao,
                    dataAip: dataAip ? FormataData(dataAip): "",
                    dataAid: dataAid ? FormataData(dataAid): "",
                    dataAiddf: dataAiddf ? FormataData(dataAiddf): ""
                }
            }
        };
        submitForm(dado);
    }

    function submitForm(dado) {
        
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dado).then(json => {
            setWait(false);
            history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=atestado");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            atestadoImplantacao: {
                ...value.atestadoImplantacao,
                [event.target.name]: event.target.value
            }
        });
    };

    const handleChangeInputFormDataAip = (date) => {
        setDataAip(date)
    }

    const handleChangeInputFormDataAid = (date) => {
        setDataAid(date)
    }
    const handleChangeInputFormDataAiddf = (date) => {
        setDataAiddf(date)
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
        <Container maxWidth='md'>
            <Paper >
                <Grid>

                    <Grid container spacing={1} className={classes.root}>
                        <Grid item xs={4}>
                            <TextField
                                value={value && value.atestadoImplantacao && value.atestadoImplantacao.nProvisorio   || ''}                                
                                fullWidth
                                label="Nº Provisório"
                                id="nProvisorio"
                                name="nProvisorio"
                                onChange={handleChangeInputForm}
                                aria-describedby="nova-helper-text"
                                InputProps={{
                                    readOnly: false,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataAip"
                                    label="Data AIP"
                                    format="dd/MM/yyyy"
                                    name="dataAip"                                    
                                    value={dataAip || null}
                                    onChange={handleChangeInputFormDataAip}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                // disabled={(disable && disable === true)}
                                />
                        </Grid>        
                        <Grid item xs={4}>
                                {value && value.dadosEmpresa && value.dadosEmpresa.nProcesso &&
                                    <EtapaProcessoComp nProcesso={value.dadosEmpresa.nProcesso} indice={9} />}
                            </Grid>               
                        
                        <Grid item xs={4}>
                            <TextField
                                value={value && value.atestadoImplantacao && value.atestadoImplantacao.nDefinitivo || ''}                                
                                fullWidth
                                label="Nº Definitivo"
                                id="nDefinitivo"
                                onChange={handleChangeInputForm}
                                name="nDefinitivo"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                       
                        <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataAid"
                                    label="Data AID"
                                    format="dd/MM/yyyy"
                                    name="dataAid"                                    
                                    value={dataAid || null}
                                    onChange={handleChangeInputFormDataAid}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                // disabled={(disable && disable === true)}
                                />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={value && value.atestadoImplantacao && value.atestadoImplantacao.descontoConcedido || ''}                                
                                fullWidth
                                label="Desconto Concedito(%)"
                                id="descontoConcedido"
                                onChange={handleChangeInputForm}
                                name="descontoConcedido"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                InputProps={{                                    
                                    inputComponent: MonetarioField
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={value && value.atestadoImplantacao && value.atestadoImplantacao.nDefinitivoDesenvolveDf   || ''}                                
                                fullWidth
                                label="Nº Definitivo Desenvolve-DF"
                                id="nDefinitivoDesenvolveDf"
                                name="nDefinitivoDesenvolveDf"
                                onChange={handleChangeInputForm}
                                aria-describedby="nova-helper-text"
                                InputProps={{
                                    readOnly: false,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataAiddf"
                                    label="Data AIP"
                                    format="dd/MM/yyyy"
                                    name="dataAiddf"                                    
                                    value={dataAiddf || null}
                                    onChange={handleChangeInputFormDataAiddf}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                // disabled={(disable && disable === true)}
                                />
                        </Grid>
                    </Grid>


                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" onClick={() => salvar()} color="primary">
                        Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
        </MuiPickersUtilsProvider>
    );
}