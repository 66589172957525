import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Collapse, Container, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, IconButton, 
    Paper, Typography, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from "react-router-dom";
import MotivoDialog from '../../../util/MotivoDialog';
import ConfirmDialog from '../../../util/ConfirmDialog';
import { ptBR } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
  },
  btns: {
    '& > *': {
      margin: theme.spacing(1)
    },
    display: 'flex'
  },
  heading: {
    fontWeight: 'bold',
  },
}));
let primeiro = true;
export default function Form(props) {
  const [solicitacao, setSolicitacao] = React.useState({
    solicitante: {
      name: '',
      login:'',
      cpf: '',
      perfis:''
    },
    perfil:''
  });
  const [disabled, setDisabled] = React.useState(false);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState({
    pn1: true,
    pn2: true
  });
  const [showMotivoDialog, setShowMotivoDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');
  const history = useHistory();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    let sol = {...props.solicitacao};

    if( primeiro){
      sol.solicitante.cpf =  (sol.solicitante.cpf)?sol.solicitante.cpf:'';
      sol.solicitante.perfis =  (sol.solicitante.perfis)?sol.solicitante.perfis:'-';
    
      setSolicitacao(sol);
      setDisabled(props.disabled);
  
      primeiro = false;
    }

  }, [solicitacao, disabled]);

  const handleChange = (panel) => (event, isExpanded) => {
    let pn = { ...expanded };
    pn[panel] = isExpanded;
    setExpanded(pn);
  }

  const handleSair = () => {
    window.location.reload();
  }

  const handleReprovar = () => {
    setShowMotivoDialog(true);
  }

  const handleAprovar = () => {
    setShowConfirm(true);
  }

  function confirmaSolicitacao(){
    const body = { id: solicitacao.id };

    fetch('/api/perfil/solicitar/aprovarSolicitacao', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(body),
      credentials: 'include'
    }).then(response => {
      // if( response.status === 202){
      if( response.ok || response.status < 300  ){
        // history.replace("/home");
        window.location.reload();
        
      } else {
        response.json().then(error => {
          setOpen(true);
          setMsgErro(error.message);
        }).catch(error => {
          setOpen(true);
          setMsgErro("Erro ao comunicar com o servidor.");

        });
      }
    }).catch(error => {
      setOpen(true);
      setMsgErro("Erro ao comunicar com o servidor.");
    });
  }

  const revogar=(txt) => {
    setShowMotivoDialog(false);
    const body = {
        id: solicitacao.id,
        motivo: txt 
      }

    fetch('/api/perfil/solicitar/revogarSolicitacao', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Authorization': `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(body),
      credentials: 'include'
    }).then(response => {
      if( response.status.ok || response.status < 300 ){
        // history.replace("/home");
        window.location.reload();
      } else {
        response.json().then(error => {
          setOpen(true);
          setMsgErro(error.message);
        }).catch(error => {
          setOpen(true);
          setMsgErro("Erro ao comunicar com o servidor.");
        });
      }
    }).catch(error => {
      setOpen(true);
      setMsgErro("Erro ao comunicar com o servidor.");
    });
  }

  return (
    <Container className={classes.root} maxWidth="md">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
          <div className={classes.root}>
        <Collapse in={open}>
          <Alert action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
            {msgErro}
          </Alert>
        </Collapse>
      </div>
    
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <ExpansionPanel expanded={expanded.pn1} onChange={handleChange('pn1')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" >
                  <Typography className={classes.heading}>Dados pessoais</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                          value={solicitacao && solicitacao.solicitante.name}
                          disabled
                          fullWidth
                          label="Nome"
                          variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                          value={solicitacao && solicitacao.solicitante.login}
                          disabled
                          fullWidth
                          label="Matrícula"
                          variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                          value={solicitacao && solicitacao.solicitante.cpf}
                          disabled
                          fullWidth
                          label="CPF"
                          variant="outlined"
                        />
                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
              <ExpansionPanel expanded={expanded.pn2} onChange={handleChange('pn2')}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2bh-content" id="panel2bh-header" >
                  <Typography className={classes.heading}>Dados do perfil</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                          value={solicitacao && solicitacao.solicitante.perfis}
                          disabled
                          fullWidth
                          label="Perfil ativo"
                          variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                          value={solicitacao && solicitacao.perfil}
                          disabled
                          fullWidth
                          label="Perfil solicitado"
                          variant="outlined"
                        />

                    </Grid>
                  </Grid>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Paper>
          </Grid>
        </Grid>

        <Grid container justify="flex-end" className={classes.btns} >
        <Button variant="contained" color="secondary" onClick={() => handleSair()}>
          Sair
        </Button>
        {(!disabled && <Button variant="contained" color="secondary" onClick={() => handleReprovar()}>
          Reprovar
        </Button>)}
        {(!disabled && <Button variant="contained" color="primary" onClick={() => handleAprovar()}>
          Aprovar
        </Button>)}
      </Grid>
      <MotivoDialog open={showMotivoDialog} setOpen={setShowMotivoDialog} acao={revogar} titulo="Analise da solicitação de vinculo"/>

      <ConfirmDialog 
                open={showConfirm} 
                setOpen={setShowConfirm} 
                titulo={'Confirmação'} 
                msg='Confirma solicitação de vinculo ao perfil selecionado?' 
                exec={confirmaSolicitacao}
                />
    

      </MuiPickersUtilsProvider>
      </Container>    
  );
}
