import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, TextField, Typography, Tooltip, RadioGroup, FormControlLabel, Radio, FormControl, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, ValidarCNPJ, FormataDataComHora, FormataDataSemHora } from '../../../constants'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import GenericTable from '../../../components/template/GenericTable'
import MaskedInput from 'react-text-mask';
import { useHistory, useParams, useLocation} from 'react-router';
import AnaliseExecucaoTable from "./AnaliseExecucaoTable";
import ConfirmDialog from "../../../util/ConfirmDialog";
import { ptBR } from 'date-fns/locale';

function CNPJMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
let anoFruicao=null;
let oldCnpj=null;
export default function CadastroBeneficiarioForm(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setMensagem, disable } = props;
    const [selectedDate, setSelectedDate] = React.useState(null);
    const location = useLocation();
    const [value, setValue] = useState();
    const [dadoApoio, setDadoApoio] = useState([]);
    const [showFruicao, setShowFruica] = useState(false);
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const [error, setError] = React.useState(resetErro());
    const history = useHistory();
    let { id } = useParams();
    


    useEffect(() => {
        if( location.pathname.includes("DadosFinanceirosView")){
            setTitulo("Visualiza Acompanhamento de Beneficiário");
        }else{
            setTitulo("Cadastro e Acompanhamento de Beneficiário");
        }
        if (id) {
            buscaBeneficiario();
        }
        buscarDadoApoio();
    }, []);
    function buscaBeneficiario() {
        fetch('/api/empregaDF/' + id)
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            if (json.isMatriz) {
                                json.matriz = (json.isMatriz === true) ? 'matriz' : 'filial'
                            }
                            if (json.execucaoOperacaoFruicao) {
                                json.execucaoOperacaoFruicao.sort((a, b) => {
                                    return b.ano - a.ano;
                                })
                            }
                            setValue(json);
                            oldCnpj = json.cnpj;
                            setValue({
                                ...json,
                                isMatriz: json.isMatriz === true ? "matriz" : "filial"
                            });
                            setSelectedDate(new Date(json.dataPublicacao));
                            setShowFruica(true);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function buscarDadoApoio() {
        fetch("/api/fundefeDadoApoio")
            .then(res => res.json()).then((result) => {
                if (result.status && result.status > 300) {
                } else {
                    // const dadoReferencia = result.find(a => a.exercicio == fruicao.ano);
                    setDadoApoio(result);
                }
            });
    }
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const handleActionSolicitarClick = (index, acao, row) => {
        
        if (acao === 'Alterar') {
            let beneficiario = { ...value };
            beneficiario.execucaoOperacaoFruicao = [];
            beneficiario.execucaoOperacaoFruicao.push(row);

            localStorage.setItem("BENEFICIARIO", JSON.stringify(beneficiario));
            history.replace("/fundefe/CadastroFruicaoForm");
        } else if (acao === 'Visualizar') {
            let beneficiario = { ...value };
            beneficiario.execucaoOperacaoFruicao = [];
            beneficiario.execucaoOperacaoFruicao.push(row);

            localStorage.setItem("BENEFICIARIO", JSON.stringify(beneficiario));
            history.replace("/fundefe/ViewFruicaoForm");
        }else if ( acao === 'Excluir'){
            anoFruicao = row.ano;
            setShowConfirmExclusao(true);
        }
    }
    function resetErro() {
        return {
            cnpj: {
                erro: false,
                msg: ''
            },
        }
    }
    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    }
    const handleLostFocusCNPJ = (e) => {
        setError({
            ...error,
            cnpj: {
                erro: false,
                msg: ''
            }
        });
        if (!value || !value.cnpj) return;
        
        if( oldCnpj === value.cnpj) return;

        const cnpj = value.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (cnpj.length === 0) return;
        setWait(true);
        if (ValidarCNPJ(cnpj)) {
            fetch("/api/v1/cnpj/" + cnpj)
                .then(res => (res.status <= 300) ? res.json() : setMensagem(res))
                .then(
                    (result) => {
                        setWait(false);
                        if (!result) return;
                        setValue({
                            ...value, razaoSocial: result.razaoSocial,
                            nomeFantasia: result.nomeFantasia,
                        

                        });
                    },
                )
        } else {
            setWait(false);
            setError({
                ...error,
                cnpj: {
                    erro: true,
                    msg: 'CNPJ inválido'
                }
            });
        }
    }
    function novaFruiacao() {
        let dado = { ...value };
        if (dado.isMatriz === "matriz") {
            dado.isMatriz = true;
        } else {
            dado.isMatriz = false;
        };
        // if (dado.id) {
            dado.execucaoOperacaoFruicao = [];
            localStorage.setItem("BENEFICIARIO", JSON.stringify(dado));
            history.push('/fundefe/CadastroFruicaoForm');
        // } else {
            // submitForm('/fundefe/CadastroFruicaoForm', dado);
        // }
    }
    function confirmaAprovacao() {
        setError(resetErro());

        let dado = { ...value };
        if (dado.isMatriz === "matriz") {
            dado.isMatriz = true;
        } else {
            dado.isMatriz = false;
        };
        // if (dado.id) {
        //     dado.execucaoOperacaoFruicao = [];
        //     localStorage.setItem("BENEFICIARIO", JSON.stringify(dado));
        //     history.replace('/fundefe/CadastroFruicaoForm');
        // } else {
            
            submitForm( dado);
        // }

        // let dado = { ...value };
        // dado = dado?dado:{}
        // if (dado.isMatriz === "matriz") {
        //     dado.isMatriz = true;
        // } else {
        //     dado.isMatriz = false;
        // };
        // dado.dataPublicacao = selectedDate;
        dado.dataPublicacao = selectedDate;

    }


    function submitForm(dado) {
        setWait(true);
        dado.dataPublicacao = (selectedDate|| "");

        fetch('/api/empregaDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                response.json().then(json => {
                    // if (url === '/fundefe/CadastroFruicaoForm') {
                    //     localStorage.setItem("BENEFICIARIO", JSON.stringify(json));
                    // }
                    setValue( json );
                    setShowFruica(true)
                    if(id ){
                        setMensagem("Alterado com sucesso!")
                    }else{
                        setMensagem("Criado com sucesso!")
                    }
                    history.push('/fundefe/DadosFinanceiros');
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function formataData(dt) {
        if (dt) {
            return FormataDataComHora(new Date(dt));
        }
        return '';
    }
    function formataDataSemHora(dt) {
        if (dt) {
            return FormataDataSemHora(new Date(dt));
        }
        return '';
    }
    function confirmaExclusao(){
        setWait(true);
        fetch("/api/empregaDF/fruicao/"+id+"/"+anoFruicao,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then((result) => {
                setWait(false);
                if (!result.ok) {
                    setMensagem(result);
                } else {
                    let arr = [...value.execucaoOperacaoFruicao];
                    let newArr=[];
                    for(let x in arr){
                        if( arr[x].ano !== anoFruicao){
                            newArr.push( arr[x])
                        }
                    }
                    setValue({
                        ...value,
                        execucaoOperacaoFruicao: newArr
                    });
                    // let value = [];
                    // if (fonteDeDados && (fonteDeDados.length > 0)) {
                    //     for (let i = 0; i < fonteDeDados.length; i++) {
                    //         if (fonteDeDados[i].id !== idParcela) {
                    //         value.push(fonteDeDados[i]);
                    //         }
                    //     }
                    // }
                    // setFonteDeDados(value);
                }
            });
    }

    function salvarDados() {
        setError(resetErro());
        let dado = { ...value };
        if (dado.isMatriz === "matriz") {
            dado.isMatriz = true;
        } else {
            dado.isMatriz = false;
        };
        dado.dataPublicacao = (selectedDate || "");
        fetch('/api/empregaDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                response.json().then(json => {
                    setValue(json);
                    setShowFruica(true)
                    if (id) {
                        setMensagem("Alterado com sucesso!")
                    } else {
                        setMensagem("Criado com sucesso!")
                    }
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    const desabilitaView = (acao, dado) =>{
        if (location.pathname.includes("DadosFinanceirosView")) {
            if (acao === 'Alterar') {
                return true;
            }
            if (acao === 'Emitir Parecer') {
                return true;
            }
            if (acao === 'Parecer') {
                return true;
            }
            if (acao === 'Excluir') {
                return true;
            }
            return false;
        }
        return false;
    }

    const sair = () => {
        history.push("/fundefe/DadosFinanceiros");

    }
    return (
        <Container maxWidth='xl'>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Informações do Beneficiário</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="CNPJ"
                                    disabled={disable}
                                    error={error.cnpj.erro}
                                    helperText={error.cnpj.msg}
                                    value={value && value.cnpj || ''}
                                    onChange={handleChangeInputForm}
                                    onBlur={handleLostFocusCNPJ}
                                    autoFocus={true}
                                    name="cnpj"
                                    id="cnpj-mask-input"
                                    variant="outlined"
                                    autoFocus={true}
                                    InputProps={{
                                        inputComponent: CNPJMask
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={disable}
                                    fullWidth
                                    label="Razão Social"
                                    value={value && value.razaoSocial || ''}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="Nome Fantasia"
                                    value={value && value.nomeFantasia || ''}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControl component="fieldset">
                                    <RadioGroup row value={value && value.isMatriz || ''} aria-label="isMatriz" onChange={handleChangeInputForm} name="isMatriz" defaultValue="top">
                                        <FormControlLabel
                                            value="matriz"
                                            control={<Radio disabled={disable} color="primary" />}
                                            label="Matriz"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="filial"
                                            control={<Radio disabled={disable} color="primary" />}
                                            label="Filial"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    disabled={disable}
                                    fullWidth
                                    label="Processo SEI*"
                                    value={value && value.processoSEI || ''}
                                    onChange={handleChangeInputForm}
                                    name="processoSEI"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    disabled={disable}
                                    fullWidth
                                    label="Diário Oficial"
                                    value={value && value.diarioOficial || ''}
                                    onChange={handleChangeInputForm}
                                    name="diarioOficial"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <KeyboardDatePicker
                                    disabled={disable}
                                    disableToolbar
                                    variant="inline"
                                    label="Data Publicação"
                                    format="dd/MM/yyyy"
                                    fullWidth
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    disabled={disable}
                                    fullWidth
                                    label="Pontuação análise inicial"
                                    value={value && value.pontuacaoAnalise || ''}
                                    onChange={handleChangeInputForm}
                                    name="pontuacaoAnalise"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    disabled={disable}
                                    fullWidth
                                    label="Termo de acordo"
                                    value={value && value.termoAcordo || ''}
                                    onChange={handleChangeInputForm}
                                    name="termoAcordo"
                                    variant="outlined"
                                />
                            </Grid>


                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={showFruicao} onChange={()=>{setShowFruica(!showFruicao)} }>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} >Execução, Operação e Fruição</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GenericTable disableSearch={true}
                            actionClick={handleActionSolicitarClick}
                            handleDisableAction={desabilitaView}
                            options={location.pathname.includes("DadosFinanceirosView")?['Visualizar']:['Visualizar', 'Alterar', 'Emitir Parecer', 'Excluir']}
                            dados={value && value.execucaoOperacaoFruicao}
                            colunas={[
                                { nome: 'Ano Referência', campo: 'ano' },
                                { nome: 'Data última atualização', campo: 'data', transformacao: formataData },
                                { nome: 'Responsavel', campo: 'responsavel.nome' },
                                { nome: 'Ação', campo: 'action' }
                            ]} />
                    </AccordionDetails>
                    <Grid container justify="flex-end" className={classes.btns}>
                    {!disable &&
                        <Button variant="contained" color="primary" rel="noopener noreferrer"
                            onClick={novaFruiacao}  disabled={disable || !value} >
                            Adicionar
                        </Button>
                    }
                    </Grid>
                </Accordion>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} >Análise e Acompanhamento</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AnaliseExecucaoTable dados={value} setDados={setValue} dadoApoio={dadoApoio} disabled={false} salvarDados={salvarDados} />
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} >Histórico</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GenericTable disableSearch={true}
                            actionClick={handleActionSolicitarClick}
                            dados={value ? value.historico : []}
                            colunas={[
                                { nome: 'Data', campo: 'data', transformacao: formataDataSemHora },
                                { nome: 'Reponsavel', campo: 'responsavel.nome' },
                                { nome: 'Ação', campo: 'tipo' },
                                { nome: 'Observação', campo: 'texto' },
                            ]} />
                    </AccordionDetails>
                </Accordion>
                <Grid container justify="flex-end" className={classes.btns}>
                    {!disable &&
                        <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={confirmaAprovacao}   >
                            Salvar
                        </Button>
                    }
                    <Button variant="contained" color="secondary" rel="noopener noreferrer" onClick={sair} >
                        Sair
                    </Button>
                </Grid>
            </MuiPickersUtilsProvider>

            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />

        </Container>
    )
}