import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {Paper} from '@material-ui/core';
import {Meses, FormataMoeda } from "../../../constants";

const useStylesLocal = makeStyles(theme => ({
    input: {
      width: 80,
      height: 30
    },
    table: {
        minWidth: 700
    },
    tablecell: {
      fontSize: 14,
      fontWeight: 700
    }
  }));


const CustomTableCell = (props) => {
    const { valor } = props
    return (
      <TableCell align="right"  >
        {valor}
      </TableCell>
    );
  };

export default function AplicacaoTable(props) {
    const classes = useStylesLocal();

    const {dados, setDados, calcula, disabled} = props;
    const [total, setTotal] = useState({});
    
    useEffect(()=>{
        if( dados && dados.length > 0 ){
            let totparcelaLiberacao = 0;
            let totparcelaLeiloada = 0;
            let totvalorArrematado = 0;
            let totemolumentos = 0;
            let totrendimentoAplicacao = 0;
            let totrenunciaMensal = 0;
            let totjurosPago = 0;

            for(let x in dados){
                totparcelaLiberacao += dados[x].parcelaLiberacao?dados[x].parcelaLiberacao:0;
                totparcelaLeiloada += dados[x].parcelaLeiloada?dados[x].parcelaLeiloada:0;
                totvalorArrematado += dados[x].valorArrematado?dados[x].valorArrematado:0;
                totemolumentos += dados[x].emolumentos?dados[x].emolumentos:0;
                totrendimentoAplicacao += dados[x].rendimentoAplicacao?dados[x].rendimentoAplicacao:0;
                totrenunciaMensal += dados[x].renunciaMensal?dados[x].renunciaMensal:0;
                totjurosPago += dados[x].jurosPago?dados[x].jurosPago:0;
            }
            //=C20+D20-F20+J20-K20

            let saldoInicial = 
                (dados[11].saldoInicial?dados[11].saldoInicial:0) + 
                (dados[11].parcelaLiberacao?dados[11].parcelaLiberacao:0) - 
                (dados[11].parcelaLeiloada?dados[11].parcelaLeiloada:0) + 
                (dados[11].rendimentoAplicacao?dados[11].rendimentoAplicacao:0) - 
                (dados[11].jurosPago?dados[11].jurosPago:0);

            setTotal({
                saldoInicial: saldoInicial,
                parcelaLiberacao: totparcelaLiberacao,
                parcelaLeiloada: totparcelaLeiloada,
                valorArrematado: totvalorArrematado,
                emolumentos: totemolumentos,
                rendimentoAplicacao: totrendimentoAplicacao,
                renunciaMensal: totrenunciaMensal,
                jurosPago: totjurosPago
            });
        }
    }, [dados])
    function formataTaxa(val) {
        if ( val && typeof val === 'number'){
            let s = val.toFixed(4);
            s = s.endsWith('000')?s.substr(0, s.length-3):s;
            return s.replace(".", ",");
        }
        return val;
    }
    const onChange = (e, row, index) => {
        let arr = [...dados];
        let val = {...arr[index]};
        let valor = e.target.value;
        valor = valor.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '');
        valor = valor.replace(",", ".");
        val[e.target.name]= parseFloat(valor) ;
        if( !val.editado)val.editado = [];
        if( !val.editado.find(v => v === e.target.name)) val.editado.push(e.target.name);

        arr[index] = val;
        setDados(arr);
    };

    return (
        <TableContainer component={Paper} className={classes.root}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead} >MES</TableCell>
                        <TableCell className={classes.tableHead} align="right">Saldo Inicial</TableCell>
                        <TableCell className={classes.tableHead} align="right">Parcela Liberada</TableCell>
                        <TableCell className={classes.tableHead} align="right">Data Lieração</TableCell>
                        <TableCell className={classes.tableHead} align="right">Parcela Leiloada</TableCell>
                        <TableCell className={classes.tableHead} align="right">Valor Arrematado</TableCell>
                        <TableCell className={classes.tableHead} align="right">Dia Leilão</TableCell>
                        <TableCell className={classes.tableHead} align="right">Emolumentos</TableCell>
                        <TableCell className={classes.tableHead} align="right">Rendimento Aplicação</TableCell>
                        <TableCell className={classes.tableHead} align="right">Juros Pagos</TableCell>
                        <TableCell className={classes.tableHead} align="right">Remuneração Conta Unica</TableCell>
                        <TableCell className={classes.tableHead} align="right">Renúncia Mensal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Meses.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{row}</TableCell>
                            <TableCell align="right" style={{color:(dados && dados.length > 0 && dados[index].saldoInicial <0)?'#ff5c33':'black'}}>{ (dados && dados.length > 0 )? FormataMoeda(dados[index].saldoInicial):""}</TableCell>
                            <CustomTableCell index={index} disabled={disabled} row={row} calcula={calcula} name="parcelaLiberacao" onChange={onChange} valor={ FormataMoeda( (dados && dados.length > 0)? dados[index]['parcelaLiberacao']:0 )} />
                            <CustomTableCell index={index} disabled={disabled} row={row} calcula={calcula} name="diaLiberacao"     onChange={onChange} valor={ ( (dados && dados.length > 0)? dados[index]['diaLiberacao']:0 )}     />
                            <CustomTableCell index={index} disabled={disabled} row={row} calcula={calcula} name="parcelaLeiloada"  onChange={onChange} valor={ FormataMoeda( (dados && dados.length > 0)? dados[index]['parcelaLeiloada']:0 )}  />
                            <CustomTableCell index={index} disabled={disabled} row={row} calcula={calcula} name="valorArrematado"  onChange={onChange} valor={ FormataMoeda( (dados && dados.length > 0)? dados[index]['valorArrematado']:0 )}  />
                            <CustomTableCell index={index} disabled={disabled} row={row} calcula={calcula} name="diaLeilao"        onChange={onChange} valor={ ( (dados && dados.length > 0)? dados[index]['diaLeilao']:0 )}  />
                            <TableCell align="right">{(dados && dados.length)?FormataMoeda(dados[index].emolumentos):''}</TableCell>
                            <TableCell align="right" style={{color:(dados && dados.length > 0 && dados[index].rendimentoAplicacao <0)?'#ff5c33':'black'}}>
                                { FormataMoeda((dados && dados.length)?dados[index].rendimentoAplicacao:0) }</TableCell>
                            
                            <CustomTableCell valor={ ( (dados && dados.length > 0)? FormataMoeda(dados[index]['jurosPago']):0 )}  />

                            <TableCell align="right">{ (dados && dados.length >0 && dados[index].remuneracaoContaUnida)? formataTaxa(dados[index].remuneracaoContaUnida)+'%':''}</TableCell>
                            <TableCell align="right">{(dados && dados.length)?FormataMoeda(dados[index].renunciaMensal):''}</TableCell>
                        </TableRow>
                    ))}
                    <TableRow style={{background:'#fafafa'}}
                     >
                            <TableCell className={classes.tablecell} component="th" scope="row">Final</TableCell>
                            <TableCell className={classes.tablecell} align="right" style={{color:(total.saldoInicial <0)?'#ff5c33':'black'}} >{ FormataMoeda(total.saldoInicial) }</TableCell>
                            <TableCell className={classes.tablecell} align="right">{ FormataMoeda(total.parcelaLiberacao) }</TableCell>
                            <TableCell className={classes.tablecell} align="right"></TableCell>
                            <TableCell className={classes.tablecell} align="right">{ FormataMoeda(total.parcelaLeiloada) }</TableCell>
                            <TableCell className={classes.tablecell} align="right">{ FormataMoeda(total.valorArrematado) }</TableCell>
                            <TableCell className={classes.tablecell} align="right"></TableCell>
                            <TableCell className={classes.tablecell} align="right">{ FormataMoeda(total.emolumentos) }</TableCell>
                            <TableCell className={classes.tablecell} align="right">{ FormataMoeda(total.rendimentoAplicacao) }</TableCell>
                            <TableCell className={classes.tablecell} align="right"> { FormataMoeda(total.jurosPago)} </TableCell>
                            <TableCell className={classes.tablecell} align="right"> </TableCell>
                            <TableCell className={classes.tablecell} align="right">{ FormataMoeda(total.renunciaMensal) } </TableCell>
                        </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
