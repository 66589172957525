import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import { Menu, Divider, ListItemIcon } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import LeftMenu from './LeftMenu';
import { useCurrentUser } from "../server/UseCurrentUser";
import LoginModal from "./LoginModal";
import { ACCESS_TOKEN, API_BASE_URL } from '../constants';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useHistory, useLocation } from "react-router-dom";
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  head: {
    backgroundColor: '#4079bc',
    color: theme.palette.common.white,
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}
export default function Header() {
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElNotif, setAnchorElNotif] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [openLogin, setOpenLogin] = React.useState(false);
  const [isSSEStarted, setIsSSEStarted] = React.useState(false);
  const [isLoadUserPost, setIsLoadUserPost] = React.useState(false);
  const [stateDrawer, setStateDrawer] = React.useState(false);

  const [userPost, setUserPost] = React.useState([]);

  let [profile, loading] = useCurrentUser();

  const [state, setState] = React.useState({
    open: location.pathname === '/home'
  });

  const isMenuOpen = Boolean(anchorEl);
  const isMenuNotifiOpen = Boolean(anchorElNotif);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const history = useHistory();

  function getTitulo() {
    var getUrl = window.location;
    if (getUrl.origin === 'http://sga.sde.df.gov.br') {
      return 'Sistema SGA/SEDET';
    } else {
      return 'SIGEPE - Sistema de Gestão de Programas de Incentivos Econômicos';
    }
    // switch( location.pathname ){
    //   case '/home': return "";break;
    //   case '/criarPerfil': return "- Perfis";break;
    //   case '/autorizaAcesso': return "- Autorizar Acesso";
    //   case '/usuariosSistema': return "- Trocar perfil do usuário";
    //   case '/solicitarVinculoPerfis': return ' - Solicitação De Vinculo';
    //   case '/analisarSolicitacaoVinculo': return ' - Analisar solicitação vínculo'
    //   default: return " - "+location.pathname.split('/')[1].toUpperCase();
    // }
    // return '';
  }
  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/api/post/user/' + profile.id);
      const data = await response.json();
      if (data && data.length > 0) {
        let arr = []; //[...userPost];

        for (let x in data) {
          let item = arr.find(el => el.titulo === data[x].titulo);
          //if(data[x].status === 1) continue;
          if (!item) {
            item = {
              titulo: data[x].titulo,
              lst: [data[x]]
            };
            arr.push(item);
          } else {
            item.lst.push(data[x]);
          }

        }
        // data.forEach(d => {
        //   let item = arr.find(el => el.titulo === d.titulo);
        //   console.log(d.status)


        //   if (!item) {
        //     console.log(item)
        //     item = {
        //       titulo: d.titulo,
        //       lst: [d]
        //     };
        //     arr.push(item);
        //   } else {
        //     item.lst.push(d);
        //   }

        // });
        setUserPost(arr);
        // setUserPost( data );
      }
    }

    if (!isLoadUserPost && !!profile) {
      setIsLoadUserPost(true);
      if (profile.perfis) {
        fetchData();
      }
      //TODO descomentar
      // initEventSource();
    }
  });

  const toggleStateDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setStateDrawer(open);
  };
  const listPdfs = (anchor) => (

    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleStateDrawer(anchor, false)}
      onKeyDown={toggleStateDrawer(anchor, false)}
    >
      <List>
        <ListItemLink button  onClick={()=>history.push("/manual/acesso")}  target="_blank">
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary={"Manual de Acesso"} />
        </ListItemLink>
        <Divider />
        <ListItemLink button onClick={()=>history.push("/manual/sistema")} target="_blank">
          <ListItemIcon><InboxIcon /></ListItemIcon>
          <ListItemText primary={"Manual do Sistema"} />
        </ListItemLink>
        <Divider />
        {/* {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['All mail', 'Trash', 'Spam'].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))} */}
      </List>
    </div>
  );
  const handleShowDocumentacao = () => {
    setStateDrawer(true);
  }

  // const initEventSource = () => {
  //   if (isSSEStarted) return;

  //   setIsSSEStarted(true);

  //   const eventSource = new EventSource('/api/post/user/' + profile.id);
  //   window.sseErro = 0;
  //   eventSource.onopen = (event) => {
  //     window.sseErro = 0;

  //     setUserPost([]);
  //   }
  //   eventSource.onmessage = (event) => {

  //     const data = JSON.parse(event.data);
  //     let arr = [...userPost];

  //     if (!(arr.find(p => p.id === data.id))) {
  //       setUserPost(a => [...a, data]);
  //     }
  //   };
  //   eventSource.onerror = (event) => {

  //     if (window.sseErro > 3) eventSource.close();
  //     window.sseErro += 1;

  //   };
  // }

  const handleLimparNotif = event => {
    setUserPost([]);
    if (!state.open) handleMenuClose();
  }

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotifMenuOpen = event => {
    setAnchorElNotif(event.currentTarget);
  }

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleLogin = () => {
    setOpenLogin(!openLogin);
    if (!state.open) handleMenuClose();
  };

  const handleCriarUmaConta = () => {
    history.replace("/minhaConta");

    if (!state.open) handleMenuClose();
  }
  const handlePerfil = () => {
    history.replace("/Perfil");
    if (!state.open) handleMenuClose();
  }

  const handleTrocaSenha = () => {
    handleMenuClose();
    history.replace("/trocarSenha");
  }
  const handleFaleConosco = () => {
    handleMenuClose();
    history.replace("/suporte/registrarChamado");
  }

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.clear();
    profile = {};

    history.push("/login");
    window.location.reload();

    if (!state.open) handleMenuClose();
  }
  const handleMenuClose = () => {
    setAnchorEl(null);
    setAnchorElNotif(null);
    handleMobileMenuClose();
  };
  const handleOpenNotificacao = (e, post) => {
    let ids = '';
    post.lst.forEach(p => ids += p.idPost + ",");
    history.push("/post/" + ids);
    handleMenuClose();
  }

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const toggleDrawer = () => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, open: !state.open });
  };

  const menuId = 'primary-search-account-menu';
  const menuNotificacaoId = 'menu-notificacao-id';

  const menuItem = (profile) => {
    // if (profile && profile.usuarioExterno) {
    if (profile && profile.provedor === 'local') {
      return (
        <div>
          {/* <MenuItem onClick={handlePerfil}>Perfil</MenuItem> */}
          <MenuItem onClick={handleCriarUmaConta}>Meu Perfil</MenuItem>
          <MenuItem onClick={handleTrocaSenha}>Trocar senha</MenuItem>
          {/* <MenuItem onClick={handleFaleConosco}>
            <ListItemIcon>
              <HelpOutlineIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem> */}
          <MenuItem onClick={handleLogout}>Sair</MenuItem>
        </div>
      )
    } else {
      return (
        <div>
          <MenuItem onClick={handleCriarUmaConta}>Meu Perfil</MenuItem>
          <MenuItem onClick={handleLogout}>Sair</MenuItem>
        </div>
      )
    }
  };
  function sizeUserPost(up) {
    let qtd = 0;
    if (up) {
      up.forEach(u => {

        qtd += u.lst.length;
      });
    }
    return qtd;
  }
  function temFoto() {
    let tem = false;
    if (profile && profile.foto) {
      tem = true;
    }

    return tem;
  }
  const renderMenuNotificacao = (

    <Menu
      anchorEl={anchorElNotif}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuNotificacaoId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuNotifiOpen}
      onClose={handleMenuClose}
      sx={{
        display:'flex',
      }}
    >
      {userPost && sizeUserPost(userPost) > 0 ?
        <div>
          {
            userPost.map((p, index) => (
              <MenuItem key={index} onClick={(e) => handleOpenNotificacao(e, p)} >{p.titulo + " (" + p.lst.length + ")"}</MenuItem>
            ))
          }
          {/* <Divider /> */}
          {/* <MenuItem onClick={handleLimparNotif}>Limpar todas notificações</MenuItem> */}
        </div> :<div><MenuItem disabled>{"Sem notificação"}</MenuItem></div>
      }
    </Menu>
  )
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {
        loading ? ("carregando...") : menuItem(profile)
      }

    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={sizeUserPost(userPost)} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const conteudo = (
    <div className={classes.grow}>
      <AppBar position="static">
        <Toolbar className={classes.head}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            onClick={toggleDrawer()}
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {getTitulo()}
          </Typography>

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {/* <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={8} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton> */}
            <IconButton aria-label="show 17 new notifications"
              color="inherit"
              aria-controls={menuNotificacaoId}
              aria-haspopup="true"
              onClick={handleNotifMenuOpen}
            >
              <Badge badgeContent={sizeUserPost(userPost)} color="secondary" >
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleShowDocumentacao}
              color="inherit"
            >
              <LibraryBooksIcon />

            </IconButton>
            <IconButton
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleFaleConosco}
              color="inherit"
            >
              <HelpOutlineIcon />

            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              {
                temFoto() && <Avatar alt="Remy Sharp" src={API_BASE_URL + "/api/dowload/gridfs/" + profile.foto.id} />
              }
              {
                !temFoto() &&
                <AccountCircle />
              }
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      <LeftMenu open={state.open} onchange={toggleDrawer()} profile={profile} />

      {renderMobileMenu}
      {renderMenu}
      {renderMenuNotificacao}
      <LoginModal open={openLogin} onchange={handleLogin} />

      <Drawer anchor={'right'} open={stateDrawer} onClose={toggleStateDrawer('right', false)}>
        {listPdfs('right')}
      </Drawer>
    </div>
  )
  return (
    <div>
      {
        profile && conteudo
      }
    </div>

  );
}
