import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';

export default function AcaoTabelaComponent(props) {
    const { visibilidade, titulo, onClick, objeto, children } = props;
    if (!visibilidade || visibilidade(objeto)) {
        return (
            <Tooltip title={titulo}>
                <IconButton onClick={() => onClick(objeto)} >
                    {children}
                </IconButton>
            </Tooltip>
        );
    } else {
        return null;
    }
}
