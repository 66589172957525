import React, { useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import CalendarioPanel from "../../../util/calendario/CalendarioPanel";
import { useLocation, useHistory } from "react-router-dom";
import { useStyles, getQueryParams } from "../../../constants";
import AgendarVistoriaDialog from "./AgendarVistoriaDialog";
import AgendaDetalheDiaDialog from "./AgendaDetalheDiaDialog";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { grey, blue, red, yellow, green } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const diaSemana = ["DOMINGO", "SEGUNDA", "TERÇA", "QUARTA", "QUINTA", "SEXTA", "SABADO"];

const useStylesLocal = makeStyles(theme => ({
  root: {
    // maxWidth: 300,
    // width: "100%"
    // width:'100%',   
    // height:'100%'
    // display: 'block',
    // width: '30vw',
    // transitionDuration: '0.3s',
    // height: '50px' 
  },
  removeBottomPadding: {
    paddingBottom: "0px",
    paddingTop: "0px"
  },
  hoje: {
    // maxWidth: 300,
    // height: '150px',
    border: 1, borderColor: 'blue', borderStyle: 'solid',
    background: "#D3D3D3",
  },
  mesNaoCorrete: {
    backgroundColor: grey[500],
  },
  mesCorrete: {
    backgroundColor: blue[500],
  },
  cellRed: {
    backgroundColor: red[500],
  },
  cellYellow: {
    backgroundColor: yellow[500],
  },
  cellGreen: {
    backgroundColor: green[500],
  },
  cellBlue: {
    backgroundColor: blue[500],
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  btn:{
    width: "100px",
  }
}));

let x = true;
export default function AgendarVistoriaPanel(props) {
  const classes = useStyles();
  const classesLocal = useStylesLocal();
  const { setTitulo, setWait, setErro, setMensagem, reagendarVistoria } = props;
  const param = getQueryParams(useLocation().search);
  const [vistoria, setVistoria] = useState();
  const [showAgendar, setShowAgendar] = useState(false);
  const [dataIndicada, setDataIndicada] = useState();
  const [dado, setDado] = useState();
  const [capacidade, setCapacidade] = useState();
  const [open, setOpen] = useState(false);
  const [dadoDia, setDadoDia] = useState();

  const history = useHistory();
  useEffect(() => {
    if (reagendarVistoria) {
      setTitulo("REAGENDAR VISTORIA");
    } else {
      setTitulo("AGENDAR VISTORIA");
    }
    buscaVistoria(param.get("id"));
    buscaVistoriasAgendadas();
    buscarCapacidade();
  }, []);

  function buscarCapacidade() {
    setWait(true);
    fetch("/api/processoProdf/capacidade")
      .then(res => res.json()).then((result) => {
        setWait(false);
        if (result.status && result.status > 300) {
          setErro(result.error ? result.error : "Problema inesperado!");
        } else {
          setCapacidade(result);
        }
      });
  }
  function buscaVistoria(id) {
    setWait(true);
    fetch("/api/processoProdf/vistoria/" + id)
      .then(res => res.json()).then((result) => {
        setWait(false);
        if (result.status && result.status > 300) {
          setErro(result.error ? result.error : "Problema inesperado!");
        } else {
          setVistoria(result);
          if (reagendarVistoria) {
            setTitulo("Reagendar Vistoria - " + result.protocolo + " " + (result.processo ? result.processo.razaoSocial : result.lote[0].ade));
          } else {
            setTitulo("Agendar Vistoria - " + result.protocolo + " " + (result.processo ? result.processo.razaoSocial : result.lote[0].ade));
          }
        }
      });
  }
  function buscaVistoriasAgendadas() {
    setWait(true);
    fetch('/api/processoProdf/vistoria/agendadas')
      .then(response => {
        setWait(false);
        if (response.ok) {
          response.json()
            .then(json => {
              let arr = [];
              for (let x in json) {
                if (json[x].situacao !== "SUSPENSO") {
                  arr.push({
                    id: json[x].id,
                    nome: json[x].lote[0].ra,
                    data: new Date(json[x].agenda.data),
                    agenda: json[x].agenda,
                    turno: json[x].agenda.turno
                  })
                }
              }
              setDado(arr);
            })
        }
      })
  }
  const handleClick = (dia, data) => {
    if (getCollor(dia) === 'aa') {
      setMensagem("Dia indisponível, capacidade não cadastrada");
      return;
    } else if (getCollor(dia) === 'red') {
      setMensagem("Dia indisponível, sem capacidade disponível");
      return;
    }

    const dt = new Date(dia.ano, dia.mes, dia.dia, 12, 0, 0);
    // if (dt < new Date()) {
    //   setMensagem({
    //     tipo: "error",
    //     mensagem: "Data não permitida! Escolha uma data posterior ao dia de hoje"
    //   });
    //   return;
    // }
    setDataIndicada(new Date(data.getFullYear(), dia.mes, dia.dia));
    setShowAgendar(true);
  }
  function feito() {
    if (reagendarVistoria) {
      history.replace("/vistoria/gerenciar");
    } else {
      history.replace("/vistoria/agendar");
    }
  }

  function getCollor(d) {
    if (!capacidade || !dado) return '';
    const dt = new Date(d.ano, d.mes, d.dia);
    const s = diaSemana[dt.getDay()];
    let qtdVistoriasManha = 0;
    let qtdVistoriasTarde = 0;
    let qtdDisponivelManha = 0;
    let qtdDisponivelTarde = 0;
    let diaDaSemana;
    for (let y in dado) {
      if (d.dia === dado[y].data.getDate() && d.mes === dado[y].data.getMonth() && d.ano === dado[y].data.getFullYear()) {

        if (dado[y].turno === "MANHA") qtdVistoriasManha++;
        else if (dado[y].turno === "TARDE") qtdVistoriasTarde++;

        diaDaSemana = diaSemana[dado[y].data.getDay()];
      }
    }

    for (let x in capacidade) {
      if (s === capacidade[x].dia) {
        //if (diaDaSemana === s) {
        if (capacidade[x].turno === "TARDE") qtdDisponivelTarde = parseInt(capacidade[x].quantidade);
        else if (capacidade[x].turno === "MANHA") qtdDisponivelManha = parseInt(capacidade[x].quantidade);
        //}
      }
    }

    for (let x in capacidade) {
      if (s === capacidade[x].dia) {
        if (diaDaSemana === s) {
          if (qtdVistoriasManha >= qtdDisponivelManha && qtdVistoriasTarde >= qtdDisponivelTarde) return 'red';
          else if (qtdVistoriasTarde >= qtdDisponivelTarde) return 'yellow';
          else if (qtdVistoriasManha >= qtdDisponivelManha) return 'yellow';
        }
        if (qtdVistoriasManha >= qtdDisponivelManha && qtdVistoriasTarde >= qtdDisponivelTarde) return 'red';
        if (qtdVistoriasManha >= qtdDisponivelManha || qtdVistoriasTarde >= qtdDisponivelTarde) return 'yellow';
        return 'green';
      }
    }
    return 'aa';
  }
  function sair() {
    if (reagendarVistoria) {
      history.replace("/vistoria/gerenciar");
    } else {
      history.replace("/vistoria/agendar");
    }
  }
  function diaClick(dia, mes, ano, atividades) {
    let arr = [];
    for (let a in atividades) {
      let at = atividades[a];
      let d = at.data.getDate();
      let m = at.data.getMonth();
      if (dia === d && mes === m) {
        arr.push(at);
      }
    }
    setDadoDia({
      dia: dia,
      mes: mes,
      ano: ano,
      atividades: arr
    });
    setOpen(true);
  }

  return (
    <Grid container spacing={3} className={classes.root}>
      <CalendarioPanel
        atividades={dado}
        onCellClick={handleClick}
        cellColorAction={getCollor}
        diaClick={diaClick}
      />
      { vistoria &&
        <AgendarVistoriaDialog
          open={showAgendar}
          setOpen={setShowAgendar}
          data={dataIndicada}
          vistoria={vistoria}
          lstVistoria={dado}
          setWait={setWait}
          setMensagem={setMensagem}
          actionFeito={feito}
          reagendarVistoria={reagendarVistoria}
        />
      }
      <Grid item xs={12}>
        <Grid container justify="flex-end" className={classes.btns}>
          <Card className={classesLocal.hoje} variant="outlined" >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classesLocal.mesNaoCorrete}>
                  dia
                </Avatar>
              }
              subheader={"Hoje"}
            />
          </Card>
          <Card className={classesLocal.root} variant="outlined" >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classesLocal.mesNaoCorrete}>
                  dia
                    </Avatar>
              }
              subheader={"Mês não corrente"}
            />
          </Card>
          <Card className={classesLocal.root} variant="outlined" >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classesLocal.cellRed}>
                  dia
                    </Avatar>
              }
              subheader={"Indisponível"}
            />
          </Card>
          <Card className={classesLocal.root} variant="outlined" >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classesLocal.cellYellow}>
                  dia
                    </Avatar>
              }
              subheader={"Apenas um turno disponível"}
            />
          </Card>
          <Card className={classesLocal.root} variant="outlined" >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classesLocal.cellGreen}>
                  dia
                    </Avatar>
              }
              subheader={"Disponível"}
            />
          </Card>
          <Card className={classesLocal.root} variant="outlined" >
            <CardHeader
              avatar={
                <Avatar aria-label="recipe" className={classesLocal.cellBlue}>
                  dia
                    </Avatar>
              }
              subheader={"Sem capacidade cadastrada"}
            />
          </Card>

          <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer"className={classesLocal.btn} >
            Sair
          </Button>
        </Grid>
      </Grid>

      <AgendaDetalheDiaDialog open={open} setOpen={setOpen} dadoDia={dadoDia} />

    </Grid>
  );
}
