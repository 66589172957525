import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Collapse, Container, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Grid, IconButton,
  MenuItem, Paper, Select, Typography, TextField
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ConfirmDialog from '../../util/ConfirmDialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
  },
  btns: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

let pageLoaded = false;

export default function TrocarPerfilForm(props) {
  const { setTrocarPerfil, setTitulo } = props;
  const [usuario, setUsuario] = React.useState({
    name: '',
    login: '',
    email: '',
    perfis: '',
  });
  const [perfis, setPerfis] = React.useState([]);
  const [perfilSolicitado, setPerfilSolicitado] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [expanded, setExpanded] = React.useState({
    pn1: true,
    pn2: true
  });
  const classes = useStyles();

  useEffect(() => {
    if (!pageLoaded) {
      setTitulo("Trocar perfil do usuário");
      setUsuario(props.usuario);
      setPerfilSolicitado(props.usuario && (props.usuario.perfis.length > 0) && props.usuario.perfis[0]);
      pageLoaded = true;
      fetch("/api/perfil",
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Bearer ${localStorage.accessToken}`
          }
        }).then(res => {
          if (res.ok && (res.status === 200)) {
            res.json().then((result) => {
              const lst = result.sort((a, b) => ((a.name < b.name) ? -1 : 1));
              setPerfis(lst);
            });
          } else {
            res.json().then(error => {
              setMsgErro(error.message);
              setOpen(true);
            }).catch(error => {
              setMsgErro("Erro com a comunicação ao servidor.");
              setOpen(true);
            });
          }
        });
    }
  });

  const handleChangeCampo = (event) => {
    setPerfilSolicitado(event.target.value);
  }

  const handleSair = () => {
    window.location.reload();
  }

  const handleTrocarPerfil = () => {
    fetch('/api/perfil/perfilUsuario/' + usuario.id + '/' + perfilSolicitado, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify({}),
      credentials: 'include'
    }).then(response => {
      if (response.ok && (response.status === 202)) {
        setOpen(true);
        setMsgErro("Perfil Alterado com sucesso!")
        window.location.reload();
      } else {
        response.json().then((error) => {
          setOpen(true);
          setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
      }
    }).catch(error => {
      setOpen(true);
      setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }

  function confirmaAprovacao(){

  }

  return (
    <Container maxWidth="xl">
      <div className={classes.root}>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msgErro}
          </Alert>
        </Collapse>
      </div>

      <Grid container spacing={3} className={classes.paper}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nome"
            value={usuario.name}
            disabled
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Usuário"
            value={usuario.login}
            disabled
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="E-mail"
            value={usuario.email}
            disabled
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Perfil ativo"
            value={usuario.perfis}
            disabled
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Perfil ativo"
            value={usuario.perfis}
            disabled
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          {perfis && perfis.length > 0 &&
            <FormControl variant="outlined" className={classes.formControl} fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Perfil Solicitado</InputLabel>
              <Select id="perfil" label="Novo Perfil" placceholder="Perfil" onChange={handleChangeCampo} value={perfilSolicitado} >
                {perfis && perfis.map((perfil, i) => (
                  <MenuItem key={i} value={perfil.nome} >{perfil.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </Grid>
      </Grid>


      <Grid container justify="flex-end" className={classes.btns} >
        <Button variant="contained" color="secondary" onClick={handleSair}>
          Sair
        </Button>
        <Button variant="contained" color="primary"  onClick={() => setShowConfirm(true)}>
          Alterar perfil
        </Button>
      </Grid>

      <ConfirmDialog
        open={showConfirm}
        setOpen={setShowConfirm}
        titulo={'Confirmação'}
        msg='Confirma a alteração do perfil ?'
        exec={handleTrocarPerfil}
      />
    </Container>
  );
}
