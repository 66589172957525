import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from '@material-ui/core/colors';
import GenericTable from '../../template/GenericTable';
import { FormataDataComHora } from '../../../constants';
let arr = [];
export default function DetalharDadosHistoricoMovimentacaoPanel(props) {
    const { gestaoProcesso } = props;
    useEffect(() => {
        if (gestaoProcesso) {
            const his = gestaoProcesso;
            if (his != null) {
                for (let x in his.historico) {
                    const hist = his.historico[x];
                    arr.push({
                        data: hist.data,
                        texto: hist.texto,
                        tipo: hist.tipo ? hist.tipo :"Mudança da unidade",
                        responsavel: hist.responsavel.nome
                    })
                }
            }
        }
    }, [gestaoProcesso])
    return (
        <Grid item xs={12}>
            <TabelaComponent dados={arr} >
                <CelulaCabecalhoTabelaComponent identificador='data' titulo="Data" />
                <CelulaCabecalhoTabelaComponent identificador='texto' titulo="Texto" />
                <CelulaCabecalhoTabelaComponent identificador='tipo' titulo="Tipo" />
                <CelulaCabecalhoTabelaComponent identificador='responsavel' titulo="Responsável" />
            </TabelaComponent>
        </Grid>
    );
}
