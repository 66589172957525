import React from "react";
import { Container, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AssociarPerfilLoteTable from './Table';
import AssociarPerfilLoteForm from './Form';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '60px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },
}));

export default function Panel() {
    const [editarPerfilLote, setEditarPerfilLote] = React.useState();
    const classes = useStyles();
    const [titulo, setTitulo] = React.useState("Associação de usuários aos perfis")
    return (
      <Container maxWidth="xl" className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography component="h1" variant="h5" gutterBottom>{titulo}</Typography>
            </Paper>
          </Grid>
        
        {
          editarPerfilLote && <AssociarPerfilLoteForm setEdit={setEditarPerfilLote} userProfile={editarPerfilLote.perfil} 
              disabled={editarPerfilLote.disabled} setTitulo={setTitulo} />
        }
        {
          !editarPerfilLote && <AssociarPerfilLoteTable setEdit={setEditarPerfilLote} />
        }
        </Grid>
      </Container>
    )
}
