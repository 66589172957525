import React, { useState, useEffect } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, TextField, Typography, Tooltip, IconButton }
    from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormataDataComHora, useStyles } from '../../../constants';
import GenericTable from '../../template/GenericTable';
import { useHistory, useLocation, useParams } from 'react-router';
import { useCurrentUser } from '../../../server/UseCurrentUser';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SearchIcon from '@material-ui/icons/Search';
import BuscaDadoDialog from '../../../util/BuscaDadoDialog';
import NumberFormat from 'react-number-format';
import { ptBR } from 'date-fns/locale';

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}
const TaxaField = (props) => {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={4}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

let listLeilao = [];
let procureLeilao = true;
let leilaoPreSelecionado = true;
let dadoApoio;
export default function ResultadoLeilaoForm(props) {
    const { setTitulo, setMensagem, setWait, setErro } = props;
    const classes = useStyles();
    const [txt, setTxt] = useState('');
    let { id } = useParams();
    const location = useLocation();
    const history = useHistory();
    const [lstExercicio, setLstExercicio] = useState([]);
    const [previsao, setPrevisao] = useState();
    const [lstPrevisao, setLstPrevisao] = useState([]);
    const [escolhaPrevisao, setEscolhaPrevisao] = useState([]);
    const [parcelas, setParcelas] = useState([]);
    let [profile] = useCurrentUser();
    const [value, setValue] = useState({
        dataLeilao: null
    });
    const [error, setError] = React.useState(resetErro());
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);

    useEffect(() => {
        if (location.pathname.includes("editarLeilao")) {
            setTitulo("Editar cadastro consolidado resultado leilão");
        } else if (location.pathname.includes("viewLeilao")) {
            setTitulo("Visualizar cadastro consolidado resultado leilão")
        } else {
            setTitulo("Cadastro consolidado resultado leilão");
        }
        buscaPrevisao();
        buscarLailoes();
        buscarDadosApoio();
        if (id) {
            buscarLeilao();
        }
    }, []);

    function buscarLeilao() {
        setWait(true);
        fetch('/api/fundefeLeilao/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then(json => {
                    let liberado = json.totalLiberado;
                    let arrematado = json.valorArrematado;
                    let desagio = (liberado) - (arrematado);
                    let porDesagio = ((1 - ((arrematado /liberado)) )* 100);
                    leilaoPreSelecionado = json.leilao;
                    json.previsao.matriz = (json.previsao.isMatriz === true) ? 'matriz' : 'filial';
                    const apoioDado = dadoApoio.filter(l => l.exercicio === json.previsao.exercicio);
                    setLstPrevisao([json.previsao.programa]);

                    setValue({
                        ...json,
                        valorDesagio: desagio,
                        poecentoDesagio: porDesagio,
                        cdiAtual: parseFloat( json.cdiAtual),
                        capAnual: apoioDado.length > 0 ? ((Math.pow((1 + apoioDado[0].juros), 12)) - 1) : ''
                    });
                    //((1+xpto)^12)-1
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function buscaPrevisao() {
        fetch('/api/fundefePrevisao')
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            setPrevisao(json);
                            let arr = [];
                            for (let x in json) {
                                const item = arr.find(l => l.exercicio === json[x].exercicio)
                                if (!item) arr.push(json[x]);
                            }
                            setLstExercicio(arr);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function buscarLailoes() {
        fetch("/api/fundefeLeilao")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    //listLeilao = [];
                    if (procureLeilao) {
                        for (let x in result) {
                            listLeilao.push(result[x].leilao);
                        }
                        procureLeilao = false;
                    }
                }
            });
    }
    function buscarDadosApoio() {
        fetch("/api/fundefeDadoApoio")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    dadoApoio = result;
                }
            });
    }

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });

        if (event.target.name === 'valorArrematado' || event.target.name === 'totalLiberado') {
            const libe = event.target.name === 'totalLiberado' ? event.target.value : value.totalLiberado;
            let liberado = 0;
            if(libe && typeof libe === "string" ) liberado = libe.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            else liberado = libe;
            const arrema = event.target.name === 'valorArrematado' ? event.target.value : value.valorArrematado;
            let arrematado = 0;
            if (arrema && typeof arrema === "string" )  arrematado = arrema.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            else arrematado = arrema;
            let desagio = parseFloat(liberado) - parseFloat(arrematado);
            let porDesagio = ((1 - (parseFloat(arrematado) / parseFloat(liberado))) * 100)
            setValue({
                ...value,
                [event.target.name]: event.target.value,
                valorDesagio: desagio,
                poecentoDesagio: porDesagio
            });
        }
        // if (event.target.name === 'leilao') verifiqueLeilao(event.target.value);
    };
    // function verifiqueLeilao(d) {
    //     if (listLeilao.includes(d) && leilaoPreSelecionado != d) {
    //         setError({
    //             leilao: {
    //                 erro: true,
    //                 msg: "*Lailão ja cadastrado"
    //             },
    //         });
    //     } else if (error.leilao.erro) {
    //         setError({
    //             leilao: {
    //                 erro: false,
    //                 msg: ''
    //             },
    //         });
    //     }
    // }
    const handleChange = (event) => {
        const val = event.target.value;
        const exercicio = value.previsao.exercicio;

        const item = previsao.find(l => {
            return l.exercicio === exercicio && l.programa === val;
        })
        if (item) setValue({
            ...value, previsao: {
                exercicio: exercicio,
                programa: val
            }
        });
    };

    function confirmaAprovacao() {
        setError(resetErro());
        let dado = { 
            ...value,
            totalLiberado: typeof value.totalLiberado == "string" ? value.totalLiberado.replace(/[.*+\-?^${}()|[\]\\]/g, '').replace(",", ".") : value.totalLiberado,
            valorArrematado: typeof value.valorArrematado == "string" ? value.valorArrematado.replace(/[.*+\-?^${}()|[\]\\]/g, '').replace(",", ".") : value.valorArrematado,
            cdiAtual: typeof value.cdiAtual == "string" ? value.cdiAtual.replace(/[.*+\-?^${}()|[\]\\]/g, '').replace(",", ".") : value.cdiAtual,
         };
        if (dado.previsao) delete dado.previsao.historico;
        submitForm(dado);
    }

    function submitForm(dado) {
        dado.requerente = {
            id: profile.id,
            cpf: profile.CPF,
            nome: profile.name,
            telefone: profile.telefone,
            email: profile.email
        }
        setWait(true);
        fetch('/api/fundefeLeilao', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                if (location.pathname.includes("editarLeilao")) {
                    setMensagem("Rascunho salvo com sucesso.")
                    response.json().then(json => setValue(json));
                    buscarLeilao();
                }
                listLeilao = [];
                history.replace("/fundefe/leiloes");
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function resetErro() {
        return {
            leilao: {
                erro: false,
                msg: ''
            },

        }
    }

    function transformaDt(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }

    function disableView() {
        if (location.pathname === "/fundefe/viewLeilao/" + id) return true;

    }
    const handleDateChange = (date) => {
        setValue({
            ...value,
            dataLeilao: date
        });
    };

    const handleChangeExercicio = (event) => {
        const val = event.target.value;
        const itens = previsao.filter(l => l.exercicio === val);
        if (itens) {
            let arr = []
            for (let x in itens) {
                if (!arr.find(p => p === itens[x].programa)) {
                    arr.push(itens[x].programa);
                }
            }
            setLstPrevisao(arr);
        }
        const apoioDado = dadoApoio.filter(l => l.exercicio === val);
        setValue({
            ...value,
            previsao: {
                exercicio: val
            },
            capAnual: apoioDado.length > 0 ? ((Math.pow((1 + apoioDado[0].juros), 12)) - 1) : ''
        });
    };

    function buscaDado() {
        const programa = value.previsao.programa;
        const exercicio = value.previsao.exercicio;
        const item = previsao.filter(l => {
            return l.exercicio === exercicio && l.programa === programa;
        })
        if (item) {
            setEscolhaPrevisao([...item]);
        }

        setShowBuscaDadoDialog(true);
    }
    function handleEscolhaPrevisao(dado) {
        if (dado) {
            dado.matriz = (dado.isMatriz === true) ? 'matriz' : 'filial'
        }
        setParcelas([]);
        let valores = {
            ...value,
            previsao: dado
        };
        //valores.historico = null;
        setValue(valores);
        //buscaParcelaSeJaExixte(dado);
    }

    function desabilitarBusca() {
        if (!value || !value.previsao) return true;
        return false;
    }

    function disabledIncluir() {
        if (error.leilao.erro) return true;
        
        if(value.previsao && 
            value.previsao.cnpj &&
            value.leilao &&
            value.dataLeilao &&
            value.valorArrematado &&
            value.totalLiberado &&
            value.cdiAtual //&&
            // value.dataLeilao.getTime()
            ){
                if(typeof value.dataLeilao == 'object' && !value.dataLeilao.getTime()) return true;
            return false;
        }
        return true;
    }

    return (
        <Container maxWidth='lg'>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Paper >
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>INFORMAÇÕES</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1} >
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="lbExercicio">Excercício</InputLabel>
                                        <Select
                                            labelId="lbExercicio"
                                            id="exercicio"
                                            value={value && value.previsao && value.previsao.exercicio || ''}
                                            onChange={handleChangeExercicio}
                                            disabled={disableView()}
                                            label="Exercício"
                                        >
                                            <MenuItem value="">Nenhum</MenuItem>
                                            {lstExercicio && lstExercicio.map((row, i) => (
                                                <MenuItem key={i} value={row.exercicio}>{row.exercicio}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="tipo" >Programa</InputLabel>
                                        <Select
                                            labelId="lbPrevisao"
                                            id="previsao"
                                            value={value && value.previsao && value.previsao.programa || ''}
                                            onChange={handleChange}
                                            disabled={disableView()}
                                            label="Exercício"
                                        >
                                            <MenuItem value="">
                                                <em>Nenhum</em>
                                            </MenuItem>
                                            {
                                                lstPrevisao && lstPrevisao.map((row, i) => (
                                                    <MenuItem key={i} value={row}>{row}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        // disabled={disableView()}
                                        label="Leilão"
                                        value={value && value.leilao || ''}
                                        onChange={handleChangeInputForm}
                                        name="leilao"
                                        id="leilao"
                                        variant="outlined"
                                        error={error.leilao.erro}
                                        helperText={error.leilao.msg}
                                        InputProps={{
                                            readOnly: disableView(),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        label="Data Leilão"
                                        format="dd/MM/yyyy"
                                        fullWidth
                                        value={value && value.dataLeilao}
                                        // disabled={disableView()}
                                        InputProps={{
                                            readOnly: disableView(),
                                        }}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        inputVariant="outlined"
                                        invalidDateMessage="Formato inválido"
                                    />
                                </Grid>
                                {!disableView() &&
                                    <>
                                        <Grid item xs={2}>
                                            <TextField
                                                fullWidth
                                                label="CNPJ"
                                                value={value && value.previsao && value.previsao.cnpj || ''}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Tooltip title='Buscar Previsão Orçamentárea' >
                                                <div>
                                                    <IconButton onClick={buscaDado} disabled={desabilitarBusca()}>
                                                        <SearchIcon />
                                                    </IconButton>
                                                </div>
                                            </Tooltip>
                                        </Grid>
                                    </>
                                } {disableView() &&
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            label="CNPJ"
                                            value={value && value.previsao && value.previsao.cnpj || ''}
                                            // disabled={disableView()}
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        // disabled={true}
                                        label="Beneficiário"
                                        value={value && value.previsao && value.previsao.razaoSocial || ''}
                                        onChange={handleChangeInputForm}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        name="razaoSocial"
                                        id="nome-empresa-input"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Processo"
                                        value={value && value.previsao && value.previsao.processo || ''}
                                        // disabled={disableView()}
                                        InputProps={{
                                            readOnly: disableView(),
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row aria-label="isMatriz" name="isMatriz" value={value && value.previsao && value.previsao.matriz || ''}>
                                            <FormControlLabel value="matriz" control={<Radio color="primary" />} label="Matriz" />
                                            <FormControlLabel value="filial" control={<Radio color="primary" />} label="Filial" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        value={value && value.capAnual || ''}
                                        // disabled={true}
                                        fullWidth
                                        label="Tx Cap anual"
                                        id="capAnual"
                                        name="capAnual"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            inputComponent: TaxaField,
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        value={value && value.cdiAtual || ''}
                                        // disabled={true}
                                        fullWidth
                                        label="Tx CDI Dt Atual"
                                        id="cdiAtual"
                                        name="cdiAtual"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        onChange={handleChangeInputForm}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            inputComponent: TaxaField,
                                            readOnly: disableView(),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        // disabled={disableView()}
                                        value={value && value.totalLiberado || ''}
                                        fullWidth
                                        label="Total Liberado"
                                        id="totalLiberado"
                                        name="totalLiberado"
                                        onChange={handleChangeInputForm}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                            inputComponent: MonetarioField,
                                            readOnly: disableView(),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        // disabled={disableView()}
                                        value={value && value.valorArrematado || ''}
                                        fullWidth
                                        label="Valor arrematado"
                                        id="valorArrematado"
                                        name="valorArrematado"
                                        onChange={handleChangeInputForm}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                            inputComponent: MonetarioField,
                                            readOnly: disableView(),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        // type="number"
                                        // disabled={true}
                                        value={value && value.poecentoDesagio || ''}
                                        fullWidth
                                        label="% Deságio"
                                        id="poecentoDesagio"
                                        name="poecentoDesagio"
                                        onChange={handleChangeInputForm}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            inputComponent: TaxaField
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        value={value && value.valorDesagio || ''}
                                        fullWidth
                                        label="Total Deságio"
                                        id="totalDesagio"
                                        name="totalDesagio"
                                        onChange={handleChangeInputForm}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                            inputComponent: MonetarioField,
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Historico</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <GenericTable
                                    disableSearch
                                    options={['Visualizar', 'Alterar', 'Excluir']}
                                    dados={value && value.historico || []}
                                    colunas={[
                                        { nome: 'Data', campo: 'data', transformacao: transformaDt },
                                        { nome: 'Responsavel', campo: 'responsavel.nome' },
                                        { nome: 'Ação', campo: 'acao' },
                                        { nome: 'Observação', campo: 'texto' }
                                    ]} />
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Grid container spacing={1} >
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" color="secondary" href={"/fundefe/leiloes"} rel="noopener noreferrer">
                                Sair
                    </Button>
                            {!disableView() &&
                                <Button variant="contained" color="primary" onClick={() => confirmaAprovacao()} disabled={disabledIncluir()} >
                                    {location.pathname.includes("editarLeilao") ? "Alterar" : "Salvar"}
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Paper>
            </MuiPickersUtilsProvider>
            <BuscaDadoDialog
                open={showBuscaDadoDialog}
                setOpen={setShowBuscaDadoDialog}
                acao={handleEscolhaPrevisao}
                colunas={[
                    { nome: 'CNPJ', campo: 'cnpj' },
                    { nome: 'Nome', campo: 'razaoSocial' },
                    { nome: 'Programa', campo: 'programa' },
                ]}
                dados={escolhaPrevisao} />
        </Container>
    );
}
