import React, { useState } from 'react';
import { Button, Grid, TextField }
    from '@material-ui/core';
import { useStyles } from "../../../constants";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ValidarCNPJ } from '../../../constants/';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import GenericTable from "../../../components/template/GenericTable";
import MaskedInput from 'react-text-mask';

function CNPJMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            // showMask
        />
    );
}

export default function FiliaisForm(props) {
    const classes = useStyles();
    const { value, setValue, setWait, disable } = props;
    const [erro, setErro] = useState();
    const [error, setError] = React.useState(resetErro());
    const [values, setValues] = useState({});

    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };


    const handleLostFocusCNPJ = (e) => {
        setError({
            ...error,
            cnpj: {
                erro: false,
                msg: ''
            }
        });
        if (!values || !values.cnpj) return;
        const cnpj = values.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (cnpj.length === 0) return;

        if (ValidarCNPJ(cnpj)) {
            setWait(true);

            fetch("/api/v1/cnpj/" + cnpj)
                .then(res => (res.status <= 300) ? res.json() : setErro(res))
                .then(
                    (result) => {
                        setWait(false);
                        if (!result) return;
                        setValues({
                            ...values, razaoSocial: result.razaoSocial,
                            endereco: result.endereco
                        });
                    },
                    (error) => {
                        setWait(false);
                    }
                )
        } else {
            setError({
                ...error,
                cnpj: {
                    erro: true,
                    msg: 'CNPJ inválido'
                }
            });
        }
    }

    function incluir() {
        let arr = []
        let ok = true
        const dadosTab = {
            ...values,
        };
        if (value.filiais) {
            for (let x in value.filiais) {
                const d = value.filiais[x]
                arr.push(d)
            }
        }
        if (ok) arr.push(dadosTab);

        setValue({
            ...value,
            filiais: arr
        })
        setValues({});
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        let arr = [...value.filiais];
        arr.splice(index, 1);

        setValue({
            ...value,
            filiais: arr,
        });
    }

    function resetErro() {
        return {

            cnpj: {
                erro: false,
                msg: ''
            }
        }
    }

    const desabilitaAcaoAtenderTable = (acao, dado) => {
        if(acao === "Excluir")
       if(disable)return true;
    }

    const handleChangeRadioGroup = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    return (
        <Grid container spacing={1} className={classes.root}>
            {!disable &&
                <>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Possui filial:</FormLabel>
                            <RadioGroup row name="filial" value={values.filial || ""} onChange={handleChangeRadioGroup} >
                                <FormControlLabel
                                    value="sim"
                                    control={<Radio color="primary" />}
                                    label="Sim"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="nao"
                                    control={<Radio color="primary" />}
                                    label="Não"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            required
                            label="CNPJ"
                            value={values && values.cnpj || ''}
                            error={error.cnpj.erro}
                            helperText={error.cnpj.msg}
                            name="cnpj"
                            onChange={handleChangeInputForm}
                            onBlur={handleLostFocusCNPJ}
                            id="cnpj-mask-input"
                            variant="outlined"
                            InputProps={{
                                inputComponent: CNPJMask
                            }}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            value={values && values.razaoSocial || ''}
                            fullWidth
                            label="Razão Social"
                            id="razaoSocial"
                            name="razaoSocial"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={values && values.endereco || ''}
                            fullWidth
                            label="Endereco"
                            id="endereco"
                            name="endereco"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="primary" onClick={incluir} >
                            Incluir
                        </Button>
                    </Grid>
                </>
            }

            <Grid item xs={12}>
                <GenericTable
                    actionClick={handleActionSolicitarClick}
                    options={['Excluir']}
                    handleDisableAction={desabilitaAcaoAtenderTable}
                    dados={value ? (value.filiais || []) : []}
                    colunas={[
                        { nome: 'CNPJ', campo: 'cnpj' },
                        { nome: 'Razão Social', campo: 'razaoSocial' },
                        { nome: 'Endereço', campo: 'endereco' },
                        { nome: 'Filial', campo: 'filial' },
                        { nome: 'Ação', campo: !(disable) ? 'action' : 'null'  }
                    ]} />
            </Grid>

        </Grid>
    );
}
