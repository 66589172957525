import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box, Container, Collapse, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, MenuItem, Paper,
  Select, Typography, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import GroupIcon from '@material-ui/icons/Group';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import CampoBusca from "../../../util/CampoBusca";
import { useStyles } from "../../../constants";



const StyledTableCell = withStyles((theme) => createStyles({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => createStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
  }
}))(TableRow);

let dados = null;
let acessoFonte = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let qtd = 0;
let pageLoaded = false;

export default function AssociarPerfilTabela(props) {
  const { setEdit } = props;
  const classes = useStyles();
  const [perfis, setPerfis] = React.useState([]);
  const [campo, setCampo] = React.useState('');
  const [pagina, setPagina] = useState(5);
  const [bloco, setBloco] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');

  const [ordem, setOrdem] = useState({
    campo: 'nome',
    ordem: 'desc'
  });

  const handleChangePage = (event, newPage) => {
    setBloco(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPagina(event.target.value);
  };


  const handleChangeCampo = event => {
    setCampo(event.target.value);
  }

  useEffect(() => {

    if (!pageLoaded || perfis.length === 0) {
      fetch("/api/perfil/perfilUsuario",
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Bearer ${localStorage.accessToken}`
          }
        }).then(res => res.json()).then((result) => {
          const lst = result.sort((a, b) => ((a.perfil.name < b.perfil.name) ? -1 : 1));
          dados = lst;
          acessoFonte = lst;
          filtraDado();
        });
      pageLoaded = true;
    }
  }, [perfis]);

  useEffect(() => {
    
    filtraDado();
  }, [ordem, pagina, bloco]);

  function ordenar(arr) {     
    let ordenado = arr;  
    if ( ordem.campo === 'nome') {
      const asc = (ordem.ordem === 'asc') ? 1 : -1;
      ordenado = ordenado.sort((a, b) => ((a.perfil.nome < b.perfil.nome) ? 1 * asc : -1 * asc));
    } else {
      if (ordem.campo.length > 0) {
        const asc = (ordem.ordem === 'asc') ? 1 : -1;
        ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
      }
    }

     
    
    return ordenado;
  }

  function filtraDado() {
    if (!acessoFonte) return;
    let arr = [];
    let dados = ordenar(acessoFonte);    

    dados.forEach(d => {
      if (filtroCampo) {
        let campos = filtroCampo.split(' ');
        for (var y in campos) {
          if (d.perfil.nome.toUpperCase().includes(campos[y].toUpperCase())) {
            arr.push(d);
            break;
          }
        }

      } else {
        arr.push(d);
      }
    });
    setPerfis(arr);
  }

  const handleSearch = (e) => {
    filtroCampo = e.target.value;
    if (performance.now() - elapseTime > tempo) {
      disparaSearch();
    }

    elapseTime = performance.now();
  }

  function createSortHandler(coluna) {
    let or = { ...ordem };
    if (or.campo === coluna) {
      or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
    } else {
      or.campo = coluna;
      or.ordem = 'asc';
    }
    setOrdem(or);

    setBloco(0);
  }

  function disparaSearch() {
    setTimeout(function () {
      if (performance.now() - elapseTime > tempo) {
        filtraDado(filtroCampo);
      } else {
        disparaSearch();
      }
    }, tempo);
  }

  const handleAbrirAssociarPerfil = (disabled, perfil) => {
    const props = {
      disabled: disabled,
      perfil: perfil
    }
    setEdit(props);
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <div>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {msgErro}
          </Alert>
        </Collapse>
      </div>
      <form noValidate autoComplete="off" actuion="#" method="post" >
        <Grid container spacing={3}>
          <Grid item xs={12} >
            <CampoBusca handleSearch={handleSearch} />
          </Grid>

          <Grid item xs={12} >
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell sortDirection={false} className={classes.tableHead}>
                      <TableSortLabel
                        active={ordem.campo === 'nome'}
                        direction={ordem.ordem}
                        onClick={(e) => createSortHandler('nome')}
                      >
                        Nome do perfil
                       </TableSortLabel>
                    </TableCell>
                    <TableCell sortDirection={false} className={classes.tableHead}>
                      <TableSortLabel
                        active={ordem.campo === 'users'}
                        direction={ordem.ordem}
                        onClick={(e) => createSortHandler('users')}
                      >
                        Qtde de usuários
                       </TableSortLabel>
                    </TableCell>
                    <TableCell width='100px' align="center" className={classes.tableHead}>
                      Ação
                       </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {((perfis && (perfis.length > 0)) ? perfis.map((row, i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="td" scope="col">{row.perfil.nome}</StyledTableCell>
                      <StyledTableCell component="td" scope="col">{(row.users && (row.users.length > 0)) ? row.users.length : 0}
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="col">
                        <Box>
                          <Tooltip title="Associar Usuários">
                            <IconButton onClick={() => handleAbrirAssociarPerfil(false, row)}>
                              <GroupIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Visualizar">
                            <IconButton onClick={() => handleAbrirAssociarPerfil(true, row)}>
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </StyledTableCell>
                    </StyledTableRow>
                  )) :
                    (<StyledTableRow><TableCell colSpan={3}><center>Nenhum perfil cadastrado</center></TableCell></StyledTableRow>))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            {
              dados &&
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={qtd}
                rowsPerPage={pagina}
                page={bloco}
                labelRowsPerPage="Registros por página"
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            }

          </Grid>

        </Grid>
      </form>
    </Container>
  )
}
