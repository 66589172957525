import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, Paper, Typography, Button,
    Accordion, AccordionSummary, AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "../../constants";
import { useLocation } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Service from '../../services/Service';
import { ptBR } from 'date-fns/locale';

const useStylesPagina = makeStyles((theme) => ({
    paper: {
        // width: 200,
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));
let fonteDeDados = null;
let assuntoPrint = false;
let responsavelPrint = false;
let tamanhoGrid = 6;
let disableButton;

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}
let oldPath = null;
export default function GenericGestapForm(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setMensagem, setErro, disable } = props;
    const location = useLocation();
    const classesPagina = useStylesPagina();
    const [checkedAssunto, setCheckedAssunto] = React.useState([]);
    const [checkedResponsavel, setCheckedResponsavel] = React.useState([]); // Responsavel
    const [assuntoDisponiveis, setAssuntoDisponiveis] = React.useState([]);
    const [assuntoSelecionadas, setAssuntoSelecionadas] = React.useState([]);
    const [responsavelDisponiveis, setResponsavelDisponiveis] = React.useState([]); // responsavel
    const [responsavelSelecionadas, setResponsavelSelecionadas] = React.useState([]);
    const assuntoDisponiveisChecked = intersection(checkedAssunto, assuntoDisponiveis);
    const assuntoSelecionadasChecked = intersection(checkedAssunto, assuntoSelecionadas);
    const responsavelDisponiveisChecked = intersection(checkedResponsavel, responsavelDisponiveis);
    const responsavelSelecionadasChecked = intersection(checkedResponsavel, responsavelSelecionadas);
    const [error, setError] = useState(resetErro());
    const [dataIndicacao, setDataIndicacao] = useState();
    const [dataIndicacaoA, setDataIndicacaoA] = useState();

    useEffect(() => {
        if (location.pathname !== oldPath) {
            atualizaStatus()
        }
    });
    function atualizaStatus() {
        setResponsavelSelecionadas([]);
        setCheckedResponsavel([]);
        setCheckedAssunto([]);
        setAssuntoSelecionadas([]);
        setDataIndicacao();
        setDataIndicacaoA();
        oldPath = location.pathname;
        if (location.pathname.includes("listagemAtendimentoForm")) {
            setTitulo("Listagem de atendimento");
            assuntoPrint = true;
            responsavelPrint = true;
            tamanhoGrid = 6;
        } else if (location.pathname.includes("listagemAcoesForm")) {
            setTitulo("Listagem de Ações");
            assuntoPrint = true;
            responsavelPrint = true;
            tamanhoGrid = 6;
        } else if (location.pathname.includes("listagemRequerimentoForm")) {
            setTitulo("Listagem de Requerimento");
            assuntoPrint = true;
            responsavelPrint = false;
            tamanhoGrid = 12;
        } else if (location.pathname.includes("quantitativoRequerimentoForm")) {
            setTitulo("Quantitativo Requerimento");
            assuntoPrint = true;
            responsavelPrint = false;
            tamanhoGrid = 12;
        } else if (location.pathname.includes("tiposRequerimentoForm")) {
            setTitulo("Tipos de Requerimento");
            assuntoPrint = true;
            responsavelPrint = false;
            tamanhoGrid = 12;
        } else if (location.pathname.includes("quantitativoAtendimentoForm")) {
            setTitulo("Quantitativo de atendimento");
            assuntoPrint = false;
            responsavelPrint = true;
            tamanhoGrid = 12;
        } else if (location.pathname.includes("tempoMedioAtendimentoForm")) {
            setTitulo("Tempo médio de atendimento");
            assuntoPrint = true;
            responsavelPrint = false;
            tamanhoGrid = 12;
        } else {
            setTitulo("Erro 404.");
            assuntoPrint = false;
            responsavelPrint = false;
        }
        montaGrupoDados();
    }
    useEffect(() => {
        buscaTodosRequerimento();
    }, []);

    function resetErro() {
        return {
            data: { erro: false, msg: '' },
        }
    }

    function buscaTodosRequerimento() {
        setWait(true);
        Service.GET("/api/processoProdf/requerimento/todos").then(result =>{
            setWait(false);
            fonteDeDados = result;
            montaGrupoDados();
        }).catch(error => {
            setWait(false);
            setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
        });
        // fetch("/api/processoProdf/requerimento/todos",
        //     {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Accept: 'application/json',
        //             'Authorization': `Bearer ${localStorage.accessToken}`
        //         }
        //     }).then(res => res.json()).then((result) => {
        //         setWait(false);
        //         if (result.status && result.status > 300) {
        //             setErro(result);
        //         } else {
        //             fonteDeDados = result;
        //             montaGrupoDados();
        //         }
        //     });
    }
    function montaGrupoDados(dtInicio, dtFim) {
        let arrAssunto = [];
        let arrRessponsavel = [];
        for (let x in fonteDeDados) {
            const d = fonteDeDados[x];
            const dt = new Date(d.dataCriacao);

            if (dtInicio && dtInicio > dt) {
                continue;
            }
            if (dtFim && dtFim < dt) {
                continue;
            }
            const item = arrAssunto.find(a => d.assunto === a);
            if (!item) arrAssunto.push(d.assunto);
            if (d.responsaveis && d.responsaveis.length > 0) {
                const r = arrRessponsavel.find(r => r.idUser === d.responsaveis[0].idUser);
                if (!r) arrRessponsavel.push(d.responsaveis[0]);
            }

        }
        setAssuntoDisponiveis(arrAssunto);
        setAssuntoSelecionadas([]);
        setResponsavelDisponiveis(arrRessponsavel);
        setResponsavelSelecionadas([]);
    }

    const handleToggleAssunto = (value) => () => {
        const currentIndex = checkedAssunto.indexOf(value);
        const newChecked = [...checkedAssunto];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedAssunto(newChecked);
    };
    const handleToggleResponsavel = (value) => () => {
        const currentIndex = checkedResponsavel.indexOf(value);
        const newChecked = [...checkedResponsavel];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedResponsavel(newChecked);
    };
    function montaDadoFiltrado() {
        let dado = []
        for (let x in fonteDeDados) {
            const d = fonteDeDados[x];
            const dt = new Date(d.dataCriacao);

            if (dataIndicacao && dataIndicacao > dt) {
                continue;
            }
            if (dataIndicacaoA && dataIndicacaoA < dt) {
                continue;
            }

            if (assuntoSelecionadas && assuntoSelecionadas.length > 0) {
                const item = assuntoSelecionadas.find(a => a === d.assunto)
                if (!item) continue;
            }
            if (responsavelSelecionadas && responsavelSelecionadas.length > 0) {
                if (d.responsaveis && d.responsaveis.length > 0) {
                    const item = responsavelSelecionadas.find(r => r.idUser === d.responsaveis[0].idUser)
                    if (!item) continue;
                } else {
                    continue;
                }
            }
            dado.push(d);
        }
        return dado;
    }
    const handleClickGerarRelatorio = () => {
        if (error.data.erro) {
            setMensagem("Verifique a data de solicitação");
            return;
        }
        setWait(true);
        const dado = montaDadoFiltrado();
        dado.sort((a, b) => {
            const protocoloA = a.protocolo.split("-");
            const protocoloB = b.protocolo.split("-");
            const sA = parseInt(protocoloA[1]+protocoloA[0]);
            const sB = parseInt(protocoloB[1]+protocoloB[0]);
            return sA < sB?1:-1;
        });
        let uri = '';
        if (location.pathname.includes("listagemAtendimentoForm")) {
            uri = "/api/gestao/relatorioListagemAtendimento.pdf";
        } else if (location.pathname.includes("listagemAcoesForm")) {
            uri = "/api/gestao/relatorioListagemAcoes.pdf";
        } else if (location.pathname.includes("listagemRequerimentoForm")) {
            uri = "/api/gestao/relatorioListagemRequerimento.pdf";
        } else if (location.pathname.includes("quantitativoRequerimentoForm")) {
            uri = "/api/gestao/relatorioQuantitativoRequerimento.pdf";
        }
        else if (location.pathname.includes("quantitativoAtendimentoForm")) {
            uri = "/api/gestao/relatorioQuantitativoAtendimento.pdf";
        }
        else if (location.pathname.includes("tempoMedioAtendimentoForm")) {
            uri = "/api/gestao/relatorioTempoMedioAtendimento.pdf";
        }
        else if (location.pathname.includes("tiposRequerimentoForm")) {
            if(assuntoSelecionadas.length == 0) {
                setMensagem("Selecione um assunto!")
                return;
            }
            uri = "/api/gestao/relatorioTiposRequerimento.pdf";
        }


        //TODO colocar as outras uris
        fetch(uri, {
            method: "POST",
            body: JSON.stringify({
                filtro: {
                    dtInit: dataIndicacao,
                    dtFim: dataIndicacaoA,
                    assuntoSelecionadas,
                    responsavelSelecionadas
                },
                lista: dado
            }),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                setWait(false);
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    const handleAllRightAssunto = () => {
        setAssuntoSelecionadas(assuntoSelecionadas.concat(assuntoDisponiveis));
        setAssuntoDisponiveis([]);
    };
    const handleCheckedRightAssunto = () => {
        const arr = assuntoSelecionadas.concat(assuntoDisponiveisChecked);
        setAssuntoSelecionadas(arr);
        setAssuntoDisponiveis(not(assuntoDisponiveis, assuntoDisponiveisChecked));
        setCheckedAssunto(not(checkedAssunto, assuntoDisponiveisChecked));
    };

    const handleCheckedLeftAssunto = () => {
        setAssuntoDisponiveis(assuntoDisponiveis.concat(assuntoSelecionadasChecked));
        setAssuntoSelecionadas(not(assuntoSelecionadas, assuntoSelecionadasChecked));
        setCheckedAssunto(not(checkedAssunto, assuntoSelecionadasChecked));
    };
    const handleAllLeftAssunto = () => {
        setAssuntoDisponiveis(assuntoDisponiveis.concat(assuntoSelecionadas));
        setAssuntoSelecionadas([]);
    };

    const handleAllRightResponsavel = () => {
        setResponsavelSelecionadas(responsavelSelecionadas.concat(responsavelDisponiveis));
        setResponsavelDisponiveis([]);
    };
    const handleCheckedRightResponsavel = () => {
        setResponsavelSelecionadas(responsavelSelecionadas.concat(responsavelDisponiveisChecked));
        setResponsavelDisponiveis(not(responsavelDisponiveis, responsavelDisponiveisChecked));
        setCheckedResponsavel(not(checkedResponsavel, responsavelDisponiveisChecked));
    };
    const handleCheckedLeftResponsavel = () => {
        setResponsavelDisponiveis(responsavelDisponiveis.concat(responsavelSelecionadasChecked));
        setResponsavelSelecionadas(not(responsavelSelecionadas, responsavelSelecionadasChecked));
        setCheckedResponsavel(not(checkedResponsavel, responsavelSelecionadasChecked));
    };
    const handleAllLeftResponsavel = () => {
        setResponsavelDisponiveis(responsavelDisponiveis.concat(responsavelSelecionadas));
        setResponsavelSelecionadas([]);
    };


    const handleDateChangeIndicacao = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (date > dataIndicacaoA) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        if (date) date.setHours(0);
        
        setDataIndicacao(date);
        montaGrupoDados(date, dataIndicacaoA);
    }
    const handleDateChangeIndicacaoA = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (dataIndicacao == null) {
            setError({
                data: { erro: true, msg: 'Informe a data inicial' }
            });
        }
        if (dataIndicacao > date) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        date.setHours(23);
        setDataIndicacaoA(date);
        montaGrupoDados(dataIndicacao, date);
    }
    const customListAssunto = (items) => (
        <Paper className={classesPagina.paper}>
            <List dense component="div" role="list">
                {items && items.length > 0 && items.map((value) => {
                    const labelId = `transfer-list-itemAssunto-${value}-label`;
                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggleAssunto(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checkedAssunto.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>

                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const customListResponsavel = (items) => (
        <Paper className={classesPagina.paper}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-itemResponsavel-${value.idUser}-label`;
                    return (
                        <ListItem key={value.idUser} role="listitem" button onClick={handleToggleResponsavel(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checkedResponsavel.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.nome} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <Accordion defaultExpanded={true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="h6" gutterBottom>

                                {
                                location.pathname.includes("listagemRequerimentoForm") 
                                ||
                                location.pathname.includes("tempoMedioAtendimentoForm") 
                                ? "Data de Atendimento" : "Período (Data atendimento)"}
         
                                {/* Período (Data atendimento) */}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} className={classesPagina.root}>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        id="dataLicitacao"
                                        label="De:"
                                        format="dd/MM/yyyy"
                                        fullWidth
                                        value={dataIndicacao || null}
                                        onChange={handleDateChangeIndicacao}
                                        fullWidth
                                        KeyboardButtonProps={true}
                                        inputVariant="outlined"
                                        disabled={(disable && disable === true)}
                                        invalidDateMessage="Formato inválido"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        error={error.data.erro}
                                        helperText={error.data.msg}
                                        disableToolbar
                                        variant="inline"
                                        id="dataLicitacao"
                                        label="Até:"
                                        format="dd/MM/yyyy"
                                        fullWidth
                                        value={dataIndicacaoA || null}
                                        onChange={handleDateChangeIndicacaoA}
                                        fullWidth
                                        KeyboardButtonProps={true}
                                        inputVariant="outlined"
                                        disabled={(disable && disable === true)}
                                        invalidDateMessage="Formato inválido"
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </MuiPickersUtilsProvider>
            </Grid>
            {assuntoPrint &&
                <Grid item xs={tamanhoGrid}>
                    <Accordion defaultExpanded={true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="h6" gutterBottom>Assunto</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} className={classesPagina.root}>
                                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                                    <Grid item xs={5}>
                                        <Typography variant="subtitle1">Assunto disponível:</Typography>
                                        {assuntoDisponiveis && customListAssunto(assuntoDisponiveis)}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid container direction="column" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classesPagina.button}
                                                onClick={handleAllRightAssunto}
                                                disabled={assuntoDisponiveis.length === 0}
                                                aria-label="move all right"
                                            > ≫</Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classesPagina.button}
                                                onClick={handleCheckedRightAssunto}
                                                disabled={assuntoDisponiveisChecked.length === 0}
                                                aria-label="move selected right"
                                            > &gt; </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classesPagina.button}
                                                onClick={handleCheckedLeftAssunto}
                                                disabled={assuntoSelecionadasChecked.length === 0}
                                                aria-label="move selected left"
                                            > &lt;</Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classesPagina.button}
                                                onClick={handleAllLeftAssunto}
                                                disabled={assuntoSelecionadas && assuntoSelecionadas.length === 0}
                                                aria-label="move all left"
                                            > ≪ </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant="subtitle1">Assunto selecionado:</Typography>
                                        {customListAssunto(assuntoSelecionadas)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
            {responsavelPrint &&
                <Grid item xs={tamanhoGrid}>
                    <Accordion defaultExpanded={true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography variant="h6" gutterBottom>Responsável UNATE</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} className={classesPagina.root}>
                                <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                                    <Grid item xs={5}>
                                        <Typography variant="subtitle1">Responsável disponível:</Typography>
                                        {customListResponsavel(responsavelDisponiveis)}
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid container direction="column" alignItems="center">
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classesPagina.button}
                                                onClick={handleAllRightResponsavel}
                                                disabled={responsavelDisponiveis.length === 0}
                                                aria-label="move all right"
                                            > ≫</Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classesPagina.button}
                                                onClick={handleCheckedRightResponsavel}
                                                disabled={responsavelDisponiveisChecked.length === 0}
                                                aria-label="move selected right"
                                            > &gt; </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classesPagina.button}
                                                onClick={handleCheckedLeftResponsavel}
                                                disabled={responsavelSelecionadasChecked.length === 0}
                                                aria-label="move selected left"
                                            > &lt;</Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classesPagina.button}
                                                onClick={handleAllLeftResponsavel}
                                                disabled={responsavelSelecionadas.length === 0}
                                                aria-label="move all left"
                                            > ≪ </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography variant="subtitle1">Responsável selecionado:</Typography>
                                        {customListResponsavel(responsavelSelecionadas)}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="primary" onClick={handleClickGerarRelatorio} disabled={disableButton}>
                    Gerar Relatório
                </Button>

            </Grid>

        </Grid>
    );
}
