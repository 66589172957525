import React, { useState } from "react";
import { Container } from '@material-ui/core';
import { Backdrop, Grid, Paper, Typography, Collapse, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useStyles } from "../../constants";
import CloseIcon from '@material-ui/icons/Close';
import LiberacoesTable from './LiberacoesTable';
import LeiloesTable from "./leilao/LeiloesTable";
import RenunciaFundadeTable from "./leilao/RenunciaFundadeTable";
import ResultadoLeilaoForm from "./leilao/ResultadoLeilaoForm";
import LiberacoesForm from "./LiberacoesForm";
import PlanejamentoForm from "./planejamento/PlanejamentoForm"
import PrevisaoOrcamentariaTable from "./PrevisaoOrcamentariaTable";
import DadosApoioTable from "./dadosApoio/DadosApoioTable";
import ConsolidadoLiberacaoTable from "./orcamentaria/ConsolidadoLiberacaoTable";
import ConsolidadoPevisaoTable from "./orcamentaria/ConsolidadoPevisaoTable";
import DadosApoioForm from "./dadosApoio/DadosApoioForm"
import RenunciaForm from "./leilao/RenunciaForm";
import BeneficiariosTable from "./empregaDf/BeneficiariosTable";
import CadastroBeneficiarioForm from "./empregaDf/CadastroBeneficiarioForm"
import ConsolidadoRenunciaTable from "./leilao/ConsolidadoRenunciaTable";
import CadastroFruicaoForm from "./empregaDf/CadastroFruicaoForm";
import NovosProjetosForm from "./orcamentaria/NovosProjetosForm"
import PrevisaoNovoProjetoTable from "./orcamentaria/PrevisaoNovoProjetoTable";
import RelatorioEmpregaDfDemontrativo from "./RelatorioEmpregaDfDemontrativo";

export default function Fundefe() {
  const classes = useStyles();
  const match = useRouteMatch();
  const [erro, setErro] = useState();
  const [wait, setWait] = useState(false);
  const [titulo, setTitulo] = useState("FUNDEFE");
  const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });

  function setMensagem(alerta) {
    let a;
    if (typeof alerta === 'object') {
      a = { ...alerta };
      if (!a.tipo) a.tipo = 'success';
      if (!a.duracao) a.duracao = 5000;
      a.visibilidade = true;
    } else {
      a = {
        tipo: 'info',
        duracao: 5000,
        visibilidade: true,
        mensagem: alerta
      }
    }

    setAlerta(a);
    if (!!a.duracao && a.duracao > 0) {
      window.setTimeout(() => {
        setAlerta({ tipo: a.tipo, visibilidade: false });
      }, a.duracao)
    }
  }

  return (
    <Container maxWidth="xl">

      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
            <Collapse in={alerta.visibilidade}>
              <Alert severity={alerta.tipo} action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlerta({ visibilidade: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>{alerta.mensagem}</Alert>
            </Collapse>
          </Paper>
          {
            erro && <Alert severity="error">{erro}</Alert>
          }
        </Grid>
        <Grid item sm={12}>
          <Switch>

            <Route path={`${match.path}/empregaDF`}>
              <h2>empregaDF</h2>
            </Route>
            <Route path={`${match.path}/ideas`}>
              <h2>ideas</h2>
            </Route>
            <Route path={`${match.path}/PRODF`}>
              <h2>PRODF</h2>
            </Route>
            <Route path={`${match.path}/consolidado`}>
              <h2>consolidado</h2>
            </Route>
            <Route path={`${match.path}/cadLiberacoes`}>
              <LiberacoesTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/consolidadeLiberacoes`}>
              <h2>consolidadeLiberacoes</h2>
            </Route>
            <Route path={`${match.path}/relatorioFundefeDemonstrativo`}>
              <RelatorioEmpregaDfDemontrativo setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
            </Route>
            <Route path={`${match.path}/relatorioFundefeAvaliacao`}>
              <h2>Emprega DF - Avaliação</h2>
            </Route>
            <Route path={`${match.path}/leiloes`}>
              < LeiloesTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/cadastrarResultadoLeilao`}>
              < ResultadoLeilaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/liberacoesNovo`}>
              <LiberacoesForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/editarLeilao/:id`}>
              < ResultadoLeilaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/viewLeilao/:id`}>
              < ResultadoLeilaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/liberacoesAlterar/:id`}>
              < LiberacoesForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/liberacoesView/:id`}>
              < LiberacoesForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/PlanejamentoView/:id`}>
              < PlanejamentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} disable={true}/>
            </Route>
            <Route path={`${match.path}/PlanejamentoAlter/:id`}>
              < PlanejamentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/PlanejamentoForm/:projeto`}>
              < PlanejamentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/previsaoLista/:projeto`}>
              < PrevisaoOrcamentariaTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/previsaoNovoProjeto`}>
              < PrevisaoNovoProjetoTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            
            <Route path={`${match.path}/NovosProjetosForm`}>
              <NovosProjetosForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait}/>
            </Route>

            <Route path={`${match.path}/NovosProjetosView/:id`}>
              <NovosProjetosForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true} />
            </Route>

            <Route path={`${match.path}/NovosProjetosAlterar/:id`}>
              <NovosProjetosForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait}/>
            </Route>

            <Route path={`${match.path}/novaRenuncia/:programa`}>
              <RenunciaForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/renunciaView/:programa/:id`}>
              <RenunciaForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} disable={true}/>
            </Route>
            <Route path={`${match.path}/renunciaAlterar/:programa/:id`}>
              <RenunciaForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
           
            <Route path={`${match.path}/renunciaLista/:programa`}>
              {/* <ConsolidadaRenunciaTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} /> */}
              <RenunciaFundadeTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/previsao/consolidado`}>
              < ConsolidadoPevisaoTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/liberacao/consolidado`}>
              <ConsolidadoLiberacaoTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/dadosApoioTable`}>
              < DadosApoioTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/dadosApoioForm/:id`}>
              < DadosApoioForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/dadosApoioForm`}>
              < DadosApoioForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/dadosApoioView/:id`}>
              < DadosApoioForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} disable={true}/>
            </Route>

            <Route path={`${match.path}/renunciaConsolidado`}>
              <ConsolidadoRenunciaTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/DadosFinanceirosForm/:id`}>
              <CadastroBeneficiarioForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait}/>
            </Route>
            <Route path={`${match.path}/DadosFinanceirosView/:id`}>
              <CadastroBeneficiarioForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true}/>
            </Route>
            <Route path={`${match.path}/DadosFinanceirosForm`}>
              <CadastroBeneficiarioForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait}/>
            </Route>
            
            <Route path={`${match.path}/DadosFinanceiros`}>
              <BeneficiariosTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/CadastroFruicaoForm`}>
              <CadastroFruicaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/ViewFruicaoForm`}>
              <CadastroFruicaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true} />
            </Route>
          

            <Route path={match.path}>
              <h2>nao implementado ainda</h2>
            </Route>
          </Switch>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={wait}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Container>
  )
}