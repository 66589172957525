import React, { useEffect, useRef, useState } from 'react';
import {
    Grid, Button, Dialog, DialogTitle, TextField, DialogContent, DialogActions, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import ConfirmDialog from "../../util/ConfirmDialog";
import { useStyles, fluxoDespacho, FormataDataComHora } from "../../constants";
import GenericTable from "../../components/template/GenericTable";
import { useHistory } from "react-router-dom";
import Service from '../../services/Service';


let fonteDeDados = null;
let requerimento;

export default function DistribuirRequerimentoTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait, setMensagem } = props;
    const [openDialog, setOpenDialog] = React.useState(false);
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const [dadoRequerimentos, setDadoRequerimentos] = useState([]);
    const [responsavel, setResponsavel] = useState([]);
    const [responsavelDado, setResponsavelDado] = React.useState('');
    const [tituloDialog, setTituloDialog] = useState('Erro');
    const history = useHistory();

    useEffect(() => {
        setTitulo("Distribuir Requerimento");
        buscaTodosRequerimento();
        buscarResponseveis();
    }, []);

    function buscaTodosRequerimento() {
        setWait(true);
        Service.GET("/api/processoProdf/requerimento/status/EM_ANALISE").then(result =>{
            setWait(false);
            fonteDeDados = result;
            result.sort((a, b) => ((a.protocolo < b.protocolo) ? 1 : -1));
            filtrarDados()
        }).catch(error => {
            setWait(false);
            setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
        });
        // fetch("/api/processoProdf/requerimento/status/EM_ANALISE",
        //     {
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Accept: 'application/json',
        //             'Authorization': `Bearer ${localStorage.accessToken}`
        //         }
        //     }).then(res => res.json()).then((result) => {
        //         setWait(false);
        //         if (result.status && result.status > 300) {
        //             setErro(result);
        //         } else {
        //             fonteDeDados = result;
        //             result.sort((a, b) => ((a.protocolo < b.protocolo) ? 1 : -1));
        //             filtrarDados()
        //         }
        //     });
    }
    function filtrarDados() {
        let arrTest = []
        for (let x in fonteDeDados) {
            const d = fonteDeDados[x];
            if (d.responsaveis.length > 0) continue;
            arrTest.push({
                id: d.id,
                nProcesso: getNumeroProcesso(d),
                protocolo: d.protocolo,
                dataCriacao: d.dataCriacao,
                dataAnalise: d.dataAnalise,
                status: showStatus(d),
                programa: d.processo.programa,
                responsavel: showResponsavel(d),
                assunto: d.assunto,
            });
        }
        setDadoRequerimentos(arrTest);
    }

    function getNumeroProcesso(row) {
        if (typeof row.processo === 'string') {
            return row.processo;
        } else {
            return row.processo.nProcesso;
        }
    }
    function showStatus(row) {
        const st = row.status
        if (st === 'RASCUNHO') {
            return "Rascunho"
        } else if (st === 'EM_ANALISE') {
            return 'Em analise';
        } else if (st === 'EM_ANALISE') {
            return fluxoDespacho(row, "Em analise");
        } else if (st === 'PENDENTE') {
            return "Pendente";
        } else if (st === 'REPROVADO') {
            return "Reprovado";
        } else if (st === 'DEFERIDO') {
            return fluxoDespacho(row, "Deferido");
        } else if (st === 'INDEFERIDO') {
            return fluxoDespacho(row, "Indeferido");
        } else if (st === 'DESPACHO') {
            return fluxoDespacho(row, "Aguardando Despacho");
        } else if (st === 'DEVOLVIDO') {
            return "Devolvido";
        } else if (st === 'PARECER_TECNICO') {
            return "Parecer Técnico";
        }
        else {
            return "Em Análise"
        }
    }

    function showResponsavel(row) {
        if (row.responsaveis) {
            let ret = '';
            row.responsaveis.forEach(r => ret += r.nome + ' ');
            return ret.trim();
        }
        return "";
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === "Vincular Responsável") {
            setOpenDialog(true);
            setTituloDialog("Vincular Responsável:")
        } else if (acao === "Visualizar") {
            let assunto = dado.assunto.split('-')[0].trim();
            history.replace("./distribuirRequerimentoView?id=" + dado.id + "&assunto=" + assunto);
        } else if (acao === "Distribuir") {
            let assunto = dado.assunto.split('-')[0].trim();
            history.replace("./distribuirRequerimentoForm?id=" + dado.id + "&assunto=" + assunto);

        }
        requerimento = dado;
    }

    function buscarResponseveis() {
        setWait(true);
        Service.GET('/api/usuariosDaUnidade/2').then(json =>{
            setWait(false);
            let arr = [];
            for (let x in json) {
                let v = json[x];
                if (v.perfis.find(p => p === 'Empresário')) continue;
                arr.push(v);
            }
            setResponsavel(arr);
        }).catch(error => {
            setWait(false);
            setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
        });
        // fetch('/api/usuariosDaUnidade/2', {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok) {
        //         setWait(false);
        //         response.json().then((json) => {
        //             let arr = [];
        //             for (let x in json) {
        //                 let v = json[x];
        //                 if (v.perfis.find(p => p === 'Empresário')) continue;
        //                 arr.push(v);
        //             }
        //             setResponsavel(arr);
        //         })
        //     } else {
        //         response.json().then((error) => {
        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    function isReadOnly() {
        return false;
    }
    function handleClickComfimarDialog() {
        let assunto = requerimento.assunto.split('-')[0].trim();
        let dado = {
            id: requerimento.id,
            assunto: assunto,
        };
        dado.responsavel = {
            idUser: responsavelDado.id,
            nome: responsavelDado.name,
            email: responsavelDado.email
        };
        Service.POST('/api/processoProdf/requerimento/vincular', dado).then(resp =>{
            setWait(false);
            setOpenDialog(false);
            buscaTodosRequerimento();
        }).catch(error =>{
            setWait(false);
            setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
        })
        // fetch('/api/processoProdf/requerimento/vincular', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok && (response.status < 300)) {
        //         setWait(false);
        //         response.json().then((json) => {
        //             setOpenDialog(false);
        //             buscaTodosRequerimento();
        //         });
        //     } else {
        //         response.json().then((error) => {
        //             setWait(false);
        //             setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    function confirmaExclusao() {
    }

    function transformaDt(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }

    const handleChangeResponsavel = (event) => {
        setResponsavelDado(event.target.value);
    };

    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={['Vincular Responsável', 'Distribuir', 'Visualizar']}
                dados={dadoRequerimentos}
                readOnly={isReadOnly()}
                colunas={[
                    { nome: 'Protocolo', campo: 'protocolo' },
                    { nome: 'Processo', campo: 'nProcesso' },
                    { nome: 'Assunto', campo: 'assunto' },
                    { nome: 'Data Criação', campo: 'dataCriacao', transformacao: transformaDt },
                    // { nome: 'Data validação UNATE', campo: 'aaaa' },
                    { nome: 'Situação', campo: 'status' },
                    { nome: 'Programa', campo: 'programa' },
                    // { nome: 'Responsável UNATE', campo: 'responsavel' },
                    { nome: 'Ação', campo: 'action' }
                ]} />

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
            </Grid>
            <div>
                <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth >
                    <DialogTitle id="form-dialog-title">{tituloDialog}</DialogTitle>
                    <DialogContent>
                        {tituloDialog == "Distribuir:" &&
                            <Grid container spacing={3} className={classes.root}>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="protocolo"
                                        value={requerimento.protocolo || ''}
                                        //onChange={handleChangeInputForm}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        name="protocolo"
                                        id="nome-protocolo-input"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Processo"
                                        value={requerimento.nProcesso || ''}
                                        //onChange={handleChangeInputForm}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        name="nProcesso"
                                        id="nome-nProcesso-input"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Assunto"
                                        value={requerimento.assunto || ''}
                                        //onChange={handleChangeInputForm}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        name="assunto"
                                        id="nome-assunto-input"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Data Criação"
                                        value={requerimento.dataCriacao || ''}
                                        //onChange={handleChangeInputForm}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        name="dataCriacao"
                                        id="nome-dataCriacao-input"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Situação"
                                        value={requerimento.status || ''}
                                        //onChange={handleChangeInputForm}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        name="status"
                                        id="nome-status-input"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Programa"
                                        value={requerimento.programa || ''}
                                        //onChange={handleChangeInputForm}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        name="programa"
                                        id="nome-programa-input"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        }
                        <FormControl variant="outlined" fullWidth >
                            <InputLabel id="demo-simple-select-outlined-label">Responsável</InputLabel>
                            <Select
                                labelId="lb-idresponsavel"
                                id="idresponsavel"
                                value={responsavelDado}
                                onChange={handleChangeResponsavel}
                                label="Responsável"
                            >
                                {responsavel.map(v =>
                                    <MenuItem key={v.id} value={v}>
                                        <em>{v.name}</em>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleClickComfimarDialog()} >
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />
        </Grid >


    );
}
