import React, { useState, useEffect } from 'react';
import { useStyles, getQueryParams } from '../../../constants';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Container, Grid, Button } from '@material-ui/core';
import DetalharDadosAtestadoProcessoPanel from './DetalharDadosAtestadoProcessoPanel'
import DetalharDadosCartaConsultaProcessoPanel from './DetalharDadosCartaConsultaProcessoPanel';
import DetalharDadosComplementoInformacaoProcessoPanel from './DetalharDadosComplementoInformacaoProcessoPanel';
import DetalharDadosContratoTerracapProcessoPanel from './DetalharDadosContratoTerracapProcessoPanel';
import DetalharDadosEscrituracaoProcessoPanel from './DetalharDadosEscrituracaoProcessoPanel';
import DetalharDadosGeraisProcessoPanel from './DetalharDadosGeraisProcessoPanel';
import DetalharDadosImovelProcessoPanel from './DetalharDadosImovelProcessoPanel';
import DetalharDadosImplantacaoProcessoPanel from './DetalharDadosImplantacaoProcessoPanel';
import DetalharDadosIndicacaoAreaProcessoPanel from './DetalharDadosIndicacaoAreaProcessoPanel';
import DetalharDadosMetasProcessoPanel from './DetalharDadosMetasProcessoPanel';
import DetalharDadosNotificacaoProcessoPanel from './DetalharDadosNotificacaoProcessoPanel';
import DetalharDadosProjetoProcessoPanel from './DetalharDadosProjetoProcessoPanel';
import DetalharDadosProrrogacaoPrazoProcessoPanel from './DetalharDadosProrrogacaoPrazoProcessoPanel';
import DetalharDadosRecursoProcessoPanel from './DetalharDadosRecursoProcessoPanel';
import DetalharDadosRequerimentoProcessoPanel from './DetalharDadosRequerimentoProcessoPanel';
import DetalharDadosVistoriaProcessoPanel from './DetalharDadosVistoriaProcessoPanel';
import DetalharDadosHistoricoMovimentacaoPanel from './DetalharDadosHistoricoMovimentacaoPanel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import { useParams, useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useUnidades } from "../../../server/UseUnidades";
import DetalharDadosProrrogacaoContratoProcessoPanel from './DetalharDadosProrrogacaoContratoProcessoPanel';
import Service from '../../../services/Service';
import {
  Done, Error, NotInterested
} from '@material-ui/icons';

const useStylesLocal = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 500,
    position: 'relative',
    minHeight: 200,
  },
  fab: {
    position: 'absolute',
    backgroundColor: green[500],
    top: theme.spacing(2),
    right: theme.spacing(6),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

export default function DetalharProcessoPanel(props) {
  const classes = useStyles();
  const classesLocal = useStylesLocal();
  let { id } = useParams();
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const { setErro, setWait, setTitulo, setMensagem } = props;
  const location = useLocation();
  const [processo, setProcesso] = useState();
  let [profile, carregandoProfile] = useCurrentUser();
  const [unidades] = useUnidades();
  const [vistoria, setVistoria] = useState();

  const param = getQueryParams(useLocation().search);

  const [gestaoProcesso, getGestaoProcesso] = React.useState();



  useEffect(() => {

    setTitulo("Cadastro de Processo");

    if (id) {
      setWait(true);
      Service.GET("/api/gerenciarProcesso/" + id).then(result => {
        setWait(false);
        setProcesso({
          ...result.processo,
          // unidade: result.IdUnidade,
          programa: result.programa
        });
      }).catch(error => {
        setWait(false);
        setErro(error ? error : 'Oops! Something went wrong. Please try again!');
      });
      // fetch(`/api/processoProdf/${id}`).then((r) => {
      //   setWait(false);

      //   if (!r.ok) {
      //     r.json().then(j => setErro(j));

      //   } else {
      //     r.json().then(result => setProcesso(result));
      //   }
      // });
    } else {
      setProcesso({
        dadosEmpresa: {}, cartaConsulta: {}, indicacaoArea: {}, projetoViabilidadeTecnicaEconomicoFinanceiraPvtef: {}, contratoTerracap: {}, inicioImplantacao: {},
        atestadoImplantacao: {}, escritura: {}, dcm: {}
      });
    }
    buscaGestao();
    if (location.pathname.includes("processoImovel")) {
      setExpanded("imovel")

    }
    if (param.get('panel')) {
      setExpanded(param.get("panel"));
    }
  }, [id])




  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (panel === 'vistoria') {
      buscaVistoria();
    }
  };

  const handleSair = () => {
    if (location.pathname.includes("processoConsultar")) {
      history.replace(`/consultaProcesso`);
    } else {
      history.replace(`/gerenciaProcesso`);
    }
  }
  const buscaVistoria = () => {
    const dado = {
      nProcesso: processo.dadosEmpresa.nProcesso
    };
    setWait(true);
    
    fetch(`/api/processoProdf/vistoria/buscaByProcesso/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(dado),
    }).then((r) => {
      setWait(false);

      if (!r.ok) {
        r.json().then(j => setErro(j));

      } else {
        r.json().then(result => setVistoria(result));
      }
    });
  }


  function buscaGestao() {
    fetch(`/api/gerenciarProcesso/byIdProcesso/${id}`)
      .then(response => response.json())
      .then(json => getGestaoProcesso(json))
      .catch(erro => console.error(erro));
  }
  function isEmpresario() {
    if (location.pathname.includes("cadastrarProcesso")) return true;
    if (profile) {
      return profile.regras.find(r => r === '012') && true;
    }
    return false;
  }
  const hasRegraCadastrarProcesso = () => {
    if (profile) {
      return profile.regras.filter(r => r === "051").length > 0;
    }
    return false;
  };

  function marcaEtapa(dado) {
    if (isEmpresario()) return;
    if (dado === "concluido") {
      return <Done fontSize="inherit" color='primary' />
    } else if (dado === "pendente") {
      return <Error fontSize="inherit" color='secondary' />
    } else if (dado === "NA") {
      return <NotInterested fontSize="inherit" />
    }
  }
  if (!processo) {
    return <div />
  } else {

    return (
      <Container maxWidth="lg" style={{ marginBottom: '20px' }}>
        <h3>PROCESSOS &gt; DETALHAR PROCESSO</h3>
        <Accordion expanded={expanded === 'processo' || expanded === false} onChange={handleChange('processo')} style={{ width: '100%' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="processo-content"
            id="processo-header" >
            <Typography className={classes.heading}>
              <span>01. Processo {marcaEtapa(gestaoProcesso?.etapa?.processo)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/alterarProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            <DetalharDadosGeraisProcessoPanel processo={processo} setProcesso={setProcesso} profile={profile} unidades={unidades} setWait={setWait} setErro={setErro} gestaoProcesso={gestaoProcesso} setMensagem={setMensagem} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'imovel'} onChange={handleChange('imovel')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="imovel-content"
            id="imovel-header" >
            <Typography className={classes.heading}>
              <span>02. Imóvel {marcaEtapa(gestaoProcesso?.etapa?.imovel)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/imovelProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            <DetalharDadosImovelProcessoPanel processo={processo} gestaoProcesso={gestaoProcesso} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'cartaConsulta'} onChange={handleChange('cartaConsulta')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="cartaConsulta-content"
            id="cartaConsulta-header" >
            <Typography className={classes.heading}>
              <span>03. Carta Consulta {marcaEtapa(gestaoProcesso?.etapa?.carta)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/cartaConsultaProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            {processo.cartaConsulta && <DetalharDadosCartaConsultaProcessoPanel processo={processo} setProcesso={setProcesso} gestaoProcesso={gestaoProcesso} setWait={setWait} setErro={setErro} setMensagem={setMensagem} profile={profile} />}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'indicacaoArea'} onChange={handleChange('indicacaoArea')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="indicacaoArea-content"
            id="indicacaoArea-header" >
            <Typography className={classes.heading}>
              <span>04. Indicação de Área {marcaEtapa(gestaoProcesso?.etapa?.area)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/cartaIndicarAreaProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            <DetalharDadosIndicacaoAreaProcessoPanel processo={processo} setProcesso={setProcesso} gestaoProcesso={gestaoProcesso} setWait={setWait} setErro={setErro} setMensagem={setMensagem} profile={profile} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'projeto'} onChange={handleChange('projeto')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="projeto-content"
            id="projeto-header" >
            <Typography className={classes.heading}>
              <span>05. Projeto {marcaEtapa(gestaoProcesso?.etapa?.projeto)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/projetoProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            <DetalharDadosProjetoProcessoPanel processo={processo} setProcesso={setProcesso} gestaoProcesso={gestaoProcesso} setWait={setWait} setErro={setErro} setMensagem={setMensagem} profile={profile} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'contratoTerracap'} onChange={handleChange('contratoTerracap')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="contratoTerracap-content"
            id="contratoTerracap-header" >
            <Typography className={classes.heading}>
              <span>06. Contrato Terracap {marcaEtapa(gestaoProcesso?.etapa?.terracap)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/contratoTerracapProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            <DetalharDadosContratoTerracapProcessoPanel processo={processo} setProcesso={setProcesso} gestaoProcesso={gestaoProcesso} setWait={setWait} setErro={setErro} setMensagem={setMensagem} profile={profile} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'implantacao'} onChange={handleChange('implantacao')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="implantacao-content"
            id="implantacao-header" >
            <Typography className={classes.heading}>
              <span>07. Início de implantação {marcaEtapa(gestaoProcesso?.etapa?.implantacao)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/implantacaoProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            <DetalharDadosImplantacaoProcessoPanel processo={processo} setProcesso={setProcesso} gestaoProcesso={gestaoProcesso} setWait={setWait} setErro={setErro} setMensagem={setMensagem} profile={profile} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'prorrogacaoContrato'} onChange={handleChange('prorrogacaoContrato')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="prorrogacaoContrato-content"
            id="prorrogacaoContrato-header" >
            <Typography className={classes.heading}>
              <span>08. Prorrogação Contrato {marcaEtapa(gestaoProcesso?.etapa?.prorrogacao)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/prorrogacaoContratoProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            <DetalharDadosProrrogacaoContratoProcessoPanel processo={processo} gestaoProcesso={gestaoProcesso} />
          </AccordionDetails>
        </Accordion>
        {/* <Accordion expanded={expanded === 'prorrogacaoPrazo'} onChange={handleChange('prorrogacaoPrazo')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="prorrogacaoPrazo-content"
            id="prorrogacaoPrazo-header" >
            <Typography className={classes.heading}>
              <span>09. Prorrogação Prazo</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DetalharDadosProrrogacaoPrazoProcessoPanel processo={processo} />
          </AccordionDetails>
        </Accordion> */}
        <Accordion expanded={expanded === 'atestado'} onChange={handleChange('atestado')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="atestado-content"
            id="atestado-header" >
            <Typography className={classes.heading}>
              <span>09. Atestado {marcaEtapa(gestaoProcesso?.etapa?.atestado)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/atestadoProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            <DetalharDadosAtestadoProcessoPanel processo={processo} setProcesso={setProcesso} gestaoProcesso={gestaoProcesso} setWait={setWait} setErro={setErro} setMensagem={setMensagem} profile={profile} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'escrituracao'} onChange={handleChange('escrituracao')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="escrituracao-content"
            id="escrituracao-header" >
            <Typography className={classes.heading}>
              <span>10. Escrituração {marcaEtapa(gestaoProcesso?.etapa?.escrituracao)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/escrituracaoProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            <DetalharDadosEscrituracaoProcessoPanel processo={processo} setProcesso={setProcesso} gestaoProcesso={gestaoProcesso} setWait={setWait} setErro={setErro} setMensagem={setMensagem} profile={profile} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'metas'} onChange={handleChange('metas')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="metas-content"
            id="metas-header" >
            <Typography className={classes.heading}>
              <span>11.  Declaração de cumprimento de metas. {marcaEtapa(gestaoProcesso?.etapa?.metas)}</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {hasRegraCadastrarProcesso() &&
              <Fab aria-label="delete" className={classesLocal.fab} size="small" href={"/gerenciaProcesso/metasProcesso" + (id ? ("?id=" + id) : "")} >
                <EditIcon fontSize="inherit" />
              </Fab>
            }
            {processo.dcm && <DetalharDadosMetasProcessoPanel processo={processo} setProcesso={setProcesso} gestaoProcesso={gestaoProcesso} setWait={setWait} setErro={setErro} setMensagem={setMensagem} profile={profile} />}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'vistoria'} onChange={handleChange('vistoria')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="vistoria-content"
            id="vistoria-header" >
            <Typography className={classes.heading}>
              <span>12. Vistoria</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {vistoria &&
              <DetalharDadosVistoriaProcessoPanel vistoria={vistoria} />}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'requerimento'} onChange={handleChange('requerimento')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="requerimento-content"
            id="requerimento-header" >
            <Typography className={classes.heading}>
              <span>13. Requerimento</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DetalharDadosRequerimentoProcessoPanel processo={processo} />
          </AccordionDetails>
        </Accordion>
        {/* <Accordion expanded={expanded === 'recurso'} onChange={handleChange('recurso')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="recurso-content"
            id="recurso-header" >
            <Typography className={classes.heading}>
              <span>14. Recurso</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DetalharDadosRecursoProcessoPanel processo={processo} />
          </AccordionDetails>
        </Accordion> */}
        <Accordion expanded={expanded === 'notificacao'} onChange={handleChange('notificacao')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="notificacao-content"
            id="notificacao-header" >
            <Typography className={classes.heading}>
              <span>14. Notificação</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DetalharDadosNotificacaoProcessoPanel processo={processo} />
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'complementoInformacao'} onChange={handleChange('complementoInformacao')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="complementoInformacao-content"
            id="complementoInformacao-header" >
            <Typography className={classes.heading}>
              <span>15. Complemento de Informação</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DetalharDadosComplementoInformacaoProcessoPanel processo={processo} />
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'prorrogacaoPrazo'} onChange={handleChange('prorrogacaoPrazo')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="prorrogacaoPrazo-content"
            id="prorrogacaoPrazo-header" >
            <Typography className={classes.heading}>
              <span>16. Prorrogação Prazo</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DetalharDadosProrrogacaoPrazoProcessoPanel processo={processo} />
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'historico'} onChange={handleChange('historico')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="historico-content"
            id="historico-header" >
            <Typography className={classes.heading}>
              <span>17. Histórico de Movimentação</span>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DetalharDadosHistoricoMovimentacaoPanel gestaoProcesso={gestaoProcesso} processo={processo} />
          </AccordionDetails>
        </Accordion>

        <Grid container justify="flex-end" className={classes.btns}>
          <Button variant="contained" color="secondary" onClick={() => handleSair()} rel="noopener noreferrer">
            Sair
          </Button>
        </Grid>
      </Container>
    );
  }
}
