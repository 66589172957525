import NotificacaoService from '../../../services/NotificacaoService';
import ProcessoService from '../../../services/ProcessoService';
import React, { useState, useEffect } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, makeStyles, Typography
} from '@material-ui/core';
import ConfirmDialog from "../../../util/ConfirmDialog";
import BuscaDadoDialog from "../../../util/BuscaDadoDialog";
import FormControl from '@material-ui/core/FormControl';
import RemoveIcon from '@material-ui/icons/Remove';
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles, getQueryParams, FormataDataComHora } from "../../../constants";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Download from "../../../util/Download";
import FileUploadComponent from '../../common/fileUpload/FileUploadComponent';
import SearchIcon from '@material-ui/icons/Search';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

const tipos = ['Pendência de vistoria', 'Prazo vencido', 'Outros'];

export const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '100px',
        overflow: 'auto'
    }
}), { index: 1 });

export default function ManterNotificacaoPanel(props) {
    const classes = useStyles();
    const classesLocal = useLocalStyle();
    const { setMensagem, setWait, setTitulo, disable } = props;
    let { id } = useParams();
    const [tipoNotificacao, setTipoNotificacao] = useState('');
    const [anexos, setAnexos] = useState([]);
    const [razaoSocial, setRazaoSocial] = useState("");
    const [dataSolicitacao, setDataSolicitacao] = useState("");
    const [cnpj, setCnpj] = useState("");
    const [programa, setPrograma] = useState("");
    const [resposta, setResposta] = useState("");
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmResponder, setShowConfirmResponder] = useState(false);
    let { idProcesso, idNotificacao, acao } = useParams();
    const [notificacao, setNotificacao] = useState({});
    const history = useHistory();
    const [profile, carregandoProfile] = useCurrentUser();
    const [protocolo, setProtocolo] = useState();
    const location = useLocation();
    const [processosParaSelecao, setProcessosParaSelecao] = useState([]);
    const [numeroProcesso, setNumeroProcesso] = useState('');
    const [status, setStatus] = useState('');
    const [descricao, setDescricao] = useState('');
    const [solicitante, setSolicitante] = useState();
    const [processo, setProcesso] = useState();
    const [idProcesso2, setIdProcesso2] = useState('');
    const [id2, setId] = useState();
    const [desabilitado, setDesabilitado] = useState(false);
    const param = getQueryParams(useLocation().search);

    const isPerfilEmpresario = () => {
        return profile && profile.regras ? profile.regras.find(r => r === '012') !== undefined : false;
    }

    const isPerfilUnate = () => {
        return profile && profile.regras ? profile.regras.find(r => r === '011') !== undefined : false;
    }

    function isAcaoCadastrar() {
        return acao === 'cadastrar';
    }

    useEffect(() => {

        if (isPerfilEmpresario) {
            setTitulo('Responder Notificação');
        } else if (id) {
            setTitulo('Notificação');
        } else {
            setTitulo('Solicitação Notificação');
        }


        const identificador = param.get("id");
        async function fetchData() {
            setWait(true);
            if (id) buscaNotificacao(id);
            else buscaNotificacao(identificador);
        }
        fetchData();
        if (isView()) {
            setDesabilitado(true);
        }
        return () => { }
    }, [])

    useEffect(() => {

        if (podeResponder()) {
            setTitulo('Responder Notificação');
        } else {
            if (id) {
                setTitulo('Notificação');
            } else {
                setTitulo('Solicitação Notificação');
            }
        }


    }, [status])

    function montaDado() {
        let dado = { ...notificacao };
        dado.documentos = anexos;

        if (!dado.solicitante) {
            dado.solicitante = {
                nome: profile.name,
                email: profile.email,
                id: profile.id
            };
        } else {
            dado.solicitante = solicitante;
            dado.processo = processo;
        }
        dado.processo = {
            id: (!!idProcesso && idProcesso !== '0' ? idProcesso : idProcesso2),
            nProcesso: numeroProcesso,
            razaoSocial: razaoSocial,
            cnpj: cnpj,
            programa: programa
        }
        dado.assunto = 'Notificacao';
        dado.documentos = anexos;
        dado.tipo = tipoNotificacao;
        dado.descricao = descricao;
        dado.protocolo = protocolo;
        dado.id = id;

        return dado;
    }
    function isView() {
        return location.pathname.includes("formView");
    }
    function confirmaResposta() {
        const dado = montaDado();
        NotificacaoService.alterar(dado).then((n) => {
            idProcesso = n.processo;
            handleSair();
            setMensagem({ mensagem: `Notificação respondida com sucesso. Número do protocolo: ${n.protocolo}`, duracao: 3000 })
        }).catch((e) => {
            setMensagem({ tipo: 'error', mensagem: e.message });
        }).finally(() => {
            setWait(false);
        });
    }

    function salvaRascunho() {
        let dado = montaDado();

        dado.status = 'RASCUNHO_ACESSOR';

        if (isNotificacaoValido(dado)) {
            setWait(true);
            dado.historico = dado.historico ? dado.historico : [];
            dado.historico.push({
                tipo: dado.status,
                data: new Date(),
                responsavel: {
                    nome: profile.name,
                    email: profile.email,
                    userId: profile.id
                }
            });

            NotificacaoService.criar(dado).then((n) => {
                setProtocolo(n.protocolo);
                setId(n.id);
                setMensagem({ mensagem: `Rascunho criado com sucesso.`, duracao: 3000 })
                history.replace("/gerenciaProcesso/notificacao/lista");

            }).catch((e) => {
                setMensagem({ tipo: 'error', mensagem: e.message });
            }).finally(() => {
                setWait(false);
            });
        }
    }
    function confirmaAprovacao() {
        let dado = montaDado();
        dado.status = "EM_ANALISE";

        if (isNotificacaoValido(dado)) {
            setWait(true);
            dado.historico = dado.historico ? dado.historico : [];
            dado.historico.push({
                tipo: dado.status,
                data: new Date(),
                responsavel: {
                    nome: profile.name,
                    email: profile.email,
                    userId: profile.id
                }
            });
            NotificacaoService.criar(dado).then((n) => {
                handleSair(n);
                setMensagem({ mensagem: `Notificação criada com sucesso. Número do protocolo: ${n.protocolo}`, duracao: 3000 })
            }).catch((e) => {
                setMensagem({ tipo: 'error', mensagem: e.message });
            }).finally(() => {
                setWait(false);
            });
        }
    }

    function isNotificacaoValido(dado) {
        // if (!anexos || anexos.length === 0) {
        //     setMensagem({ tipo: 'error', mensagem: "Obrigatório informar os documentos para analise" });
        //     return false;
        // }
        // if (!documentacaoEntregue) {
        //     setMensagem({ tipo: 'error', mensagem: "Para prosseguir a analise é necessario declararar a entrega total da documentação" });
        //     return false
        // }
        return true
    }



    function removeArquivo(item) {
        let arr = [];
        anexos.forEach(it => {
            if (it.uuid !== item.uuid) {
                arr.push(it);
            }
        });
        setAnexos(arr);
    }

    function handleSair(notificacao) {
        // if (!idProcesso) idProcesso = 0;
        // if( notificacao) history.replace(`/gerenciaProcesso/notificacoes/${notificacao.processo.id}`);
        // else history.replace(`/gerenciaProcesso/notificacoes`);
        history.replace(`/gerenciaProcesso/notificacao/lista`);
    }

    function handleVisualizarPdf() {
        const dado = montaDado();
        fetch("/api/processoProdf/requerimento/relatorio", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }

    function handleLocalizarProcesso() {
        setWait(true);
        ProcessoService.obterPorCnpjs(null).then((r) => {
            setProcessosParaSelecao(r);
            setShowBuscaDadoDialog(true);
        }).catch((e) => {
            setMensagem({ tipo: 'error', mensagem: e.message });
        }).finally(() => {
            setWait(false);
        });
    }

    function handleSelecionarProcesso(p) {
        setIdProcesso2(p.idProcesso);
        setNumeroProcesso(p.nProcesso);
        setPrograma(p.programa);
        setRazaoSocial(p.razaoSocial);
        setCnpj(p.cnpj);
    }
    const abrirResponder = () => {
        history.replace(`/gerenciaProcesso/notificacao/respostaForm?id=${param.get("id")}&back=/gerenciaProcesso/notificacao/lista`);
    }

    function buscaNotificacao(id) {
        if (!id || id === 0 || id === '0') {
            setWait(false);
            return;
        }
        fetch('/api/processoProdf/notificacaoComplemento/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    setId(id);
                    setWait(false);
                    setCnpj(json.processo.cnpj);
                    setRazaoSocial(json.processo.razaoSocial);
                    setNumeroProcesso(json.processo.nProcesso);
                    setProtocolo(json.protocolo);
                    setPrograma(json.processo.programa);
                    setDataSolicitacao(json.dataCriacao ? new Date(json.dataCriacao) : '');
                    setDescricao(json.descricao);
                    setSolicitante(json.solicitante);
                    setProcesso(json.processo);
                    setTipoNotificacao(json.tipo);
                    setStatus(json.status);
                    if (disable) {
                        setDesabilitado(true);
                    }
                    for (let x in json.historico) {
                        const d = json.historico[x];
                        if (d.tipo === "responder") {
                            setResposta(d.texto);
                        }
                    }
                    // else{
                    //     setDesabilitado( 
                    //         json.status === 'DEFERIDO' || 
                    //         json.status === 'EM_ANALISE' || 
                    //         json.status === 'NAO_RESPONDIDO' || 
                    //         json.status === 'RASCUNHO' ||
                    //         json.status === 'PENDENTE')
                    // }
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function podeNotificar() {
        if (isView()) return false;
        if (disable) return false;
        if (podeResponder()) return false;
        if (status === 'RASCUNHO_ACESSOR') return true;
        return true;
    }

    function podeResponder() {
        if (isView()) return false;
        if (disable) return false;
        return ((status === 'NAO_RESPONDIDO' || status === 'RASCUNHO_ACESSOR' || status === 'RASCUNHO' || status === 'PENDENTE') && isPerfilEmpresario());
    }


    function podeRascunho() {
        if (disable) return false;
        if (isView()) return false;
        if (podeResponder()) return false;
        if (status === 'RASCUNHO_ACESSOR') return true;
        return true;
    }

    const sair = () => {
        let urlRet = "/gerenciaProcesso/notificacao/lista";
        if (param.get('back')) {
            urlRet = param.get('back');
            if (param.get("open")) {
                urlRet += "?open=" + param.get("open")
            }
        }
        history.replace(urlRet);
    }

    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={(desabilitado || isPerfilEmpresario()) ? 4 : 3}>
                        <TextField disabled={desabilitado}
                            value={numeroProcesso || ''}
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            variant="outlined"
                        />
                    </Grid>
                    {!(desabilitado || isPerfilEmpresario()) &&
                        <Grid item xs={1} style={{ textAlign: 'center' }}>
                            {((!idProcesso || idProcesso === '0')) && <Tooltip title='Buscar Processo'>
                                <IconButton onClick={handleLocalizarProcesso} >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>}
                        </Grid>
                    }
                    <Grid item xs={4}>
                        <TextField disabled={desabilitado}
                            value={programa || ''}
                            fullWidth
                            label="Programa"
                            id="programa"
                            name="programa"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField disabled={true}
                            value={protocolo || ''}
                            fullWidth
                            label="Protocolo"
                            id="protocolo"
                            name="protocolo"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField disabled={true}
                            value={(!!cnpj && cnpj) || ''}
                            fullWidth
                            label="CNPJ"
                            id="cnpj"
                            name="cnpj"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled={true}
                            value={(!!razaoSocial && razaoSocial) || ''}
                            fullWidth
                            label="Razão Social"
                            id="razaoSocial"
                            name="razaoSocial"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField disabled={desabilitado}
                            value={(dataSolicitacao && FormataDataComHora(new Date(dataSolicitacao))) || ''}
                            fullWidth
                            label="Data solicitação"
                            id="dataSolicitacao"
                            name="dataSolicitacao"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {desabilitado &&
                            <TextField disabled={desabilitado}
                                value={tipoNotificacao || ''}
                                fullWidth
                                label="Notificacão"
                                id="tipoNotificacao"
                                name="tipoNotificacao"
                                variant="outlined"
                            />}
                        {!desabilitado &&
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="tipo" >Notificacao</InputLabel>
                                <Select id="tipo" name='tipo' label="Notificacao" placceholder="Notificacao" value={tipoNotificacao} onChange={e => setTipoNotificacao(e.target.value)}>
                                    {tipos && tipos.map((tp, i) => (
                                        <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>}
                    </Grid>


                    <Grid item xs={12}>

                        <CKEditor
                            editor={ClassicEditor} disabled={desabilitado}
                            config={{
                                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                    '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                            }}
                            data={descricao}
                            onReady={editor => {
                                editor.editing.view.change(writer => {
                                    writer.setStyle(
                                        "height",
                                        "250px",
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                            onChange={(event, editor) => {
                                setDescricao(editor.getData());
                            }}
                            onBlur={(event, editor) => {

                            }}
                            onFocus={(event, editor) => {

                            }}
                        />

                    </Grid>
                    {resposta &&
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>Resposta Notificação:</Typography>
                            <Paper className={classesLocal.paper}>
                                <span dangerouslySetInnerHTML={{
                                    __html: resposta ? resposta : ''
                                }}></span>
                            </Paper>
                        </Grid>
                    }
                    {isPerfilUnate() && !isAcaoCadastrar() && <Grid item xs={12}>
                        <Grid container spacing={1} className={classes.root}>
                            <FileUploadComponent setErro={setMensagem} anexos={anexos} setAnexos={setAnexos} setWait={setWait} tipoDocumento="" />
                            <Grid item xs={12}>
                                <TableContainer component={Paper} className={classes.root}>
                                    <Table aria-label="customized table" size={'small'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Documento
                                                </TableCell>
                                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                                    Ação
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {anexos && anexos.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{row.nome}</TableCell>
                                                    <TableCell>
                                                        <center>
                                                            <Download doc={row} />
                                                            <Tooltip title='Remover'>
                                                                <IconButton onClick={(e) => removeArquivo(row)} >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </center>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>}

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleVisualizarPdf()}>
                            Visualizar
                        </Button>
                        {podeRascunho() &&
                            <Button variant="contained" color="primary" onClick={salvaRascunho} disabled={!descricao || !numeroProcesso}>
                                Salvar rascunho
                        </Button>}
                        {podeNotificar() && <Button variant="contained" color="primary" onClick={() => { setShowConfirm(true) }} disabled={!descricao || !numeroProcesso || !tipoNotificacao}>
                            Notificar
                        </Button>}
                        {podeResponder() &&
                            <Button variant="contained" color="primary" onClick={abrirResponder}>
                                Responder
                        </Button>}
                    </Grid>
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirmar solicitação.'
                        exec={confirmaAprovacao}
                    />
                    <ConfirmDialog
                        open={showConfirmResponder}
                        setOpen={setShowConfirmResponder}
                        titulo={'Confirmação'}
                        msg='Confirma cadastro da resposta?'
                        exec={confirmaResposta}
                    />
                    <BuscaDadoDialog
                        open={showBuscaDadoDialog}
                        setOpen={setShowBuscaDadoDialog}
                        acao={(p) => handleSelecionarProcesso(p)}
                        colunas={[
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Nome', campo: 'razaoSocial' },
                            { nome: 'Processo', campo: 'nProcesso' },
                        ]}
                        dados={processosParaSelecao} />
                </Grid>
            </Paper >
        </Container >
    );
}
