
import React, { useEffect, useState } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import Service from '../../../services/Service';


export default function EtapaProcessoComp(props) {
    const { nProcesso, indice} = props;

    const [situacao, setSituacao] = React.useState('');

    React.useEffect(() => {
        const dado = {
            nProcesso: nProcesso
        }

        Service.POST('/api/processoProdf/etapa/nProcesso', dado).then(json => {
            if( json && json.listaEtapa){
                const item = json.listaEtapa.find( e => e.indice === indice);
                if( item ){
                    setSituacao( item.situacao )
                }
            }
        }).catch(error => {
           console.error( error);
        });
    }, [])
    const handleChange = (event) => {
        setSituacao(event.target.value);
        const dado = {
            nProcesso: nProcesso,
            indice: indice,
            situacao: event.target.value
        }

        Service.POST('/api/processoProdf/etapa', dado).then(json => {

        }).catch(error => {
           console.error( error);
        });
    };

    return (
        <FormControl variant="outlined"
            //className={classes.formControl}
            fullWidth
            >
            <InputLabel htmlFor="outlined-age-native-simple">Etapa</InputLabel>
            <Select native
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="situacao"
                value={situacao}
                inputProps={{
                    name: 'situacao',
                    id: 'outlined-age-native-simple',
                  }}
                onChange={handleChange}
            >
                <option value={''}> </option>
                <option value={'concluido'}>Concluido</option>
                <option value={'nsa'}>Não se aplica</option>
            </Select>
        </FormControl>
    )
}