import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import { Meses, FormataMoeda } from "../../../constants";
import TableMoedaCell from "./TableMoedaCell";
const useStylesLocal = makeStyles(theme => ({
    input: {
      width: 80,
      height: 30
    },
    table: {
        minWidth: 700
    },
    tablecell: {
      fontSize: 14,
      fontWeight: 700
    }
  }));


export default function OperacaoTable(props) {
    const classes = useStylesLocal();
    const { dados, setDados, calcula, disabled } = props;
    const [total, setTotal] = useState({});
    const [componenteAtual, setComponenteAtual] = useState();

    useEffect(() => {
        if(dados) {
            let arr = [...dados];
            for (let x in dados){
                if (!dados[x]) continue;
                /*
                if ((dados[x].saidaTributadas || dados[x].saidaTributadas === 0 ) && (dados[x].saidaNaoTributadas || dados[x].saidaNaoTributadas === 0) 
                    && (dados[x].operacaoVenda || dados[x].operacaoVenda === 0) && (dados[x].transferencia || dados[x].transferencia === 0)) 
                    arr[x].remessa =  dados[x].saidaTributadas + dados[x].saidaNaoTributadas - dados[x].operacaoVenda - dados[x].transferencia;
                    */
                arr[x].remessa =  dados[x].saidaTributadas? dados[x].saidaTributadas:0;
                arr[x].remessa += dados[x].saidaNaoTributadas?dados[x].saidaNaoTributadas:0;
                arr[x].remessa -= dados[x].operacaoVenda?dados[x].operacaoVenda:0;
                arr[x].remessa -= dados[x].transferencia?dados[x].transferencia:0;


                if (dados[x].operacaoVenda && dados[x].custoProdutosVendido) arr[x].porCustoProdutodsVendido = (dados[x].operacaoVenda / dados[x].custoProdutosVendido) * 100;
            }
            setDados(arr);
        }
    }, [])
    useEffect(() => {
        if (dados && dados.length > 0) {

            let totSaidaTributadas = 0;
            let totSaidaNaoTributadas = 0;
            let totOperacaoVenda = 0;
            let totTransferencia = 0;
            let totRemessa = 0;
            let totCustoProdutosVendido = 0;
            let totPorCustoProdutodsVendido = 0;
            for (let x in dados) {
                if (!dados[x]) continue;



                // val["porCustoProdutodsVendido"] = (val["operacaoVenda"] / val["custoProdutosVendido"] ) * 100;


                totSaidaTributadas += dados[x].saidaTributadas ? dados[x].saidaTributadas : 0;
                totSaidaNaoTributadas += dados[x].saidaNaoTributadas ? dados[x].saidaNaoTributadas : 0;
                totOperacaoVenda += dados[x].operacaoVenda ? dados[x].operacaoVenda : 0;
                totTransferencia += dados[x].transferencia ? dados[x].transferencia : 0;
                totRemessa += dados[x].remessa ? dados[x].remessa : 0;
                totCustoProdutosVendido += dados[x].custoProdutosVendido ? dados[x].custoProdutosVendido : 0;
            }

            totPorCustoProdutodsVendido = (totCustoProdutosVendido && totCustoProdutosVendido>0)?((totOperacaoVenda/totCustoProdutosVendido)*100):0; //dados[x].porCustoProdutodsVendido ? dados[x].porCustoProdutodsVendido : 0;

            setTotal({
                saidaTributadas: totSaidaTributadas,
                saidaNaoTributadas: totSaidaNaoTributadas,
                operacaoVenda: totOperacaoVenda,
                transferencia: totTransferencia,
                remessa: totRemessa,
                custoProdutosVendido: totCustoProdutosVendido,
                porCustoProdutodsVendido: totPorCustoProdutodsVendido
            });
        }
    }, [dados])

    function FormataMilhar(val){
        if( val === 0 || val === '0')return val.toLocaleString('pt-br');
        if( !val) return '';
        var f = val.toLocaleString('pt-br');
        return f;
    }
    const onChange = (e, row, index) => {
        if(!/^[0-9]*\,?[0-9]*$/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^0-9]*\,?[0-9]*$/g, "");
            return;
        }
        let arr = [...dados];
        let val = { ...arr[index] };
        let valor = e.target.value;
        valor = valor.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '');
        valor = valor.replace(",", ".");
        val[e.target.name] = parseFloat(valor);

        // if( !val.editado)val.editado = [];
        // if( !val.editado.find(v => v === e.target.name)) val.editado.push(e.target.name);
        if ((e.target.name === "saidaTributadas" || e.target.name === "saidaNaoTributadas" || e.target.name === "operacaoVenda" || e.target.name === "transferencia") && (val["saidaTributadas"] || val["saidaTributadas"] === 0 || val["saidaTributadas"] === "0" && val["saidaNaoTributadas"] || val["saidaNaoTributadas"] === 0 || val["saidaNaoTributadas"] === "0" && val["operacaoVenda"] || val["operacaoVenda"] === 0 || val["operacaoVenda"] === "0" && val["transferencia"] || val["transferencia"] === 0 || val["transferencia"] === "0")) {
            val["remessa"] = val["saidaTributadas"] + val["saidaNaoTributadas"] - val["operacaoVenda"] - val["transferencia"];
        }


        val.remessa =  val.saidaTributadas?   val.saidaTributadas:0;
        val.remessa += val.saidaNaoTributadas?val.saidaNaoTributadas:0;
        val.remessa -= val.operacaoVenda?     val.operacaoVenda:0;
        val.remessa -= val.transferencia?     val.transferencia:0;

        if (e.target.name === "operacaoVenda" || e.target.name === "custoProdutosVendido"){
            if(val["operacaoVenda"] && (val["custoProdutosVendido"])){
                val["porCustoProdutodsVendido"] = (val["operacaoVenda"] / val["custoProdutosVendido"] ) * 100;
            }else{
                val["porCustoProdutodsVendido"] = null;
            }
        }

        arr[index] = val;
        setDados(arr);
    };
    function apresentaValor(index, nome) {
        if (dados && dados.length > 0) {
            if (dados[index] || dados[index] === '0' || dados[index] === 0) {
                return FormataMoeda(dados[index][nome]);
            }
            return '';
        }
        return '';
    }
    function apresentaValorPorcento(index, nome) {
        if (dados && dados.length > 0) {
            if (dados[index] && dados[index][nome]) {
                // if (dados[index][nome] === Infinity) return '-';
                return FormataMilhar( parseFloat(dados[index][nome].toFixed(2)) ) + '%';
                // return x.replace(".", ",");
            }
            return '';
        }
        return '';
    }
    function proximo(nome){
        let arr = nome.split("nm_");
        let col = parseInt(arr[0]);
        let lin = parseInt(arr[1]);
        if( col >= 5){
            lin++;
            col=1;
        }else{
            col++;
        }
        setComponenteAtual(col+"nm_"+lin);
    }
    return (
        <TableContainer component={Paper} className={classes.root}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead} >Mês Referência </TableCell>
                        <TableCell className={classes.tableHead} align="right">SAÍDAS TRIBUTADAS(R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">SAÍDAS NÃO TRIBUTADAS(R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">OPERAÇÕES DE VENDA(R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">TRANSFERÊNCIAS(R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">REMESSAS(R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">CUSTOS DOS PRODUTOS VENDIDOS(R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">% DE VENDAS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Meses.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{row}</TableCell>
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"1nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="saidaTributadas" onChange={onChange} valor={apresentaValor(index, 'saidaTributadas')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"2nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="saidaNaoTributadas" onChange={onChange} valor={apresentaValor(index, 'saidaNaoTributadas')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"3nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="operacaoVenda" onChange={onChange} valor={apresentaValor(index, 'operacaoVenda')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"4nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="transferencia" onChange={onChange} valor={apresentaValor(index, 'transferencia')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} index={index} disabled={true} row={row} calcula={calcula} name="remessa" onChange={onChange} valor={apresentaValor(index, 'remessa')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"5nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="custoProdutosVendido" onChange={onChange} valor={apresentaValor(index, 'custoProdutosVendido')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} index={index} disabled={true} row={row} calcula={calcula} name="porCustoProdutodsVendido" onChange={onChange} valor={apresentaValorPorcento(index, 'porCustoProdutodsVendido')} />
                        </TableRow>
                    ))}
                    <TableRow style={{ background: '#fafafa' }} className={classes.tablecell}
                    >
                        <TableCell className={classes.tablecell} component="th" scope="row">Total</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.saidaTributadas)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.saidaNaoTributadas)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.operacaoVenda)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.transferencia)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.remessa)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.custoProdutosVendido)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{total.porCustoProdutodsVendido ? 
                            (FormataMilhar( parseFloat(total.porCustoProdutodsVendido.toFixed(2)) ))+ "%":""}</TableCell>
                        {/* style={{color:(total.recolhimentoFundoProd <0)?'#ff5c33':'black'}} */}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
