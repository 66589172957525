import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, TextField, Typography, RadioGroup, FormControlLabel, Radio, FormControl, Button, FormLabel, FormGroup, Checkbox, Paper } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../../../constants'
import FruicaoTable from "./FruicaoTable";
import OperacaoEntradaTable from "./OperacaoEntradaTable";
import FruicaoRendaTable from "./FruicaoRendaTable";
import FruicaoExecusaoTable from "./FruicaoExecusaoTable";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from 'react-router';
import OperacaoTable from './OperacaoTable';
import FruicaoGraficos from "./FruicaoGraficos";
import FruicaoTerceirizadosTable from './FruicaoTerceirizadosTable';
import IconButton from '@material-ui/core/IconButton';
import UpdateIcon from '@material-ui/icons/Update';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import NumberFormat from 'react-number-format';

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={4}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStylesLocal = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function CadastroFruicaoForm(props) {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    const { setTitulo, setWait, setMensagem, disable } = props;
    const [fruicao, setFruicao] = useState();
    const [operacao, setOperacao] = useState([]);
    const [dadoApoio, setDadoApoio] = useState();
    const [isInclusao, setIsInclusao] = useState(true);
    const [value, setValue] = React.useState(0);
    const [taxaBeneficios, setTaxaBeneficios] = useState([]);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [beneficiario, setBeneficiario] = useState();
    let [profile] = useCurrentUser();
    const [fruicaoGeral, setFruicaoGeral] = useState([]);
    const [fruicaoRenda, setFruicaoRenda] = useState([]);
    const [fruicaoTerceirizados, setFruicaoTerceirizados] = useState([]);
    const [fruicaoEspecial, setFruicaoEspecial] = useState([]);
    const [operacoesSaida, setOperacoesSaida] = useState([]);
    const [fruicaoRelevante, setFruicaoRelevante] = useState([]);
    const [total, setTotal] = useState([]);
    const [fruicaoExecusao, setFruicaoExecusao] = useState([]);
    const history = useHistory();
    const location = useLocation();


    const [state, setState] = React.useState({
        geral: false,
        especial: false,
        relevante: false,
    });
    const { geral, especial, relevante } = state;

    useEffect(() => {
        let tot = [];
        function totaliza(tot, arr) {
            if (arr && arr.length > 0) {

                for (let x in arr) {
                    let f = arr[x];
                    let t = tot[x] ? tot[x] : [];
                    for (let y in f) {
                        if (y === 'mes') {
                            t[y] = f[y];
                        }
                        let qtd = t[y] ? t[y] : 0;
                        qtd += f[y];
                        t[y] = qtd;
                    }
                    tot[x] = t;
                }
            }
        }
        if (state.geral) totaliza(tot, fruicaoGeral);
        if (state.especial) totaliza(tot, fruicaoEspecial);
        if (state.relevante) totaliza(tot, fruicaoRelevante);
        setTotal(tot);


    }, [fruicaoGeral, fruicaoEspecial, fruicaoRelevante, state]);

    useEffect(() => {

        const json = localStorage.getItem("BENEFICIARIO");
        let val = JSON.parse(json);
        val.matriz = (val.isMatriz) ? "matriz" : "filial";

        setBeneficiario(val);

        if (val.execucaoOperacaoFruicao && val.execucaoOperacaoFruicao.length > 0) {
            let frui = { ...val.execucaoOperacaoFruicao[0] };
            let s = {
                geral: false,
                especial: false,
                relevante: false,
            };
            for (let x in frui.beneficios) {
                const b = frui.beneficios[x];
                if (b === 'geral') s.geral = true;
                if (b === 'especial') s.especial = true;
                if (b === 'relevante') s.relevante = true;
            }
            let rendaF = [];
            if (frui.execucoes) setFruicaoExecusao(frui.execucoes)
            if (frui.operacoes) setOperacao(frui.operacoes);
            if (frui.geral) setFruicaoGeral(frui.geral);
            if (frui.especial) setFruicaoEspecial(frui.especial);

            if (frui.operacoesSaida) setOperacoesSaida(frui.operacoesSaida);

            if (frui.revelanteInteres) setFruicaoRelevante(frui.revelanteInteres);
            if (frui.renda) rendaF = frui.renda;//setFruicaoRenda(frui.renda);
            if (frui.terceirizados) setFruicaoTerceirizados(frui.terceirizados);
            if (frui.taxaBeneficios) setTaxaBeneficios(frui.taxaBeneficios);

            // let arr = rendaF;
            
            for (let x in rendaF) {
                if (!rendaF[x]) continue;
                if (rendaF[x].totalSalario && rendaF[x].totalEmpregoDiretos) rendaF[x].valorMedioSalario = rendaF[x].totalSalario / rendaF[x].totalEmpregoDiretos;
            }
            setFruicaoRenda(rendaF);///

            setState(s);
            setFruicao(frui);
            setTitulo("Altera fruição e acompanhamento");
            setIsInclusao(false);
        } else {
            setTitulo("Cadastro de fruição e acompanhamento");
        }

    }, []);

    useEffect(() => {
        if (fruicao && fruicao.ano) buscarDadoApoio();
    }, [fruicao && fruicao.ano]);

    const salvaBeneficiario = () => {
        setWait(true);
        const json = localStorage.getItem("BENEFICIARIO");
        let dado = JSON.parse(json);
        fetch('/api/empregaDF', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    setBeneficiario(json);
                    concluir(json);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function buscarDadoApoio() {
        fetch("/api/fundefeDadoApoio")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    const dadoReferencia = result.find(a => a.exercicio == fruicao.ano);
                    setDadoApoio(dadoReferencia);
                }
            });
    }

    const handleChangeCheckbox = (event) => {
        if (disable) return;
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const atualiza = (e) => {

        let dados = { ...fruicao };
        // dados.beneficios = beneficios;
        dados.operacoes = montaTabela([...operacao]);
        dados.execucoes = montaTabela([...fruicaoExecusao])
        dados.geral = montaTabela([...fruicaoGeral]);
        dados.especial = montaTabela([...fruicaoEspecial]);

        
        dados.operacoesSaida = montaTabela([...operacoesSaida]);

        dados.revelanteInteres = montaTabela([...fruicaoRelevante]);
        dados.renda = montaTabela([...fruicaoRenda])
        dados.terceirizados = montaTabela([...fruicaoTerceirizados])
        dados.responsavel = {
            id: profile.id,
            nome: profile.name,
            email: profile.email
        }
        setFruicao(dados);
        e.stopPropagation();
    }

    function montaTabela(arr) {
        for (let x in arr) {
            let val = arr[x] ? arr[x] : {};
            val.mes = parseInt(x);
            arr[x] = val;
        }
        return arr;
    }
    const sair = () => {
        const json = localStorage.getItem("BENEFICIARIO");
        let val = JSON.parse(json);
        
        if( location.pathname.includes("ViewFruicaoForm")){
            history.replace("/fundefe/DadosFinanceirosView/" + val.id);
        }else{
            history.replace("/fundefe/DadosFinanceirosForm/" + val.id);
        }
        
    }
    const concluir = (json) => {
        if (disable) {
            history.replace("/fundefe/DadosFinanceirosForm/" + beneficiario.id);
            return;
        }
        if (!json || !json.id) {
            if (!beneficiario.id) {
                salvaBeneficiario();
                return;
            }
        }
        let beneficios = []
        if (state.geral) beneficios.push("geral");
        if (state.especial) beneficios.push("especial");
        if (state.relevante) beneficios.push("relevante");
        let dados = { ...fruicao };
        dados.beneficios = beneficios;
        dados.taxaBeneficios = {
            geral: taxaBeneficios.geral ?(typeof taxaBeneficios.geral === "number" ? taxaBeneficios.geral : taxaBeneficios.geral.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '').replace(",",".")) : 0.0,
            especial: taxaBeneficios.especial ?(typeof taxaBeneficios.especial === "number" ? taxaBeneficios.especial : taxaBeneficios.especial.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '').replace(",",".")) : 0.0,
            relevante: taxaBeneficios.relevante ?(typeof taxaBeneficios.relevante === "number" ? taxaBeneficios.relevante : taxaBeneficios.relevante.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '').replace(",",".")) : 0.0
        };
        dados.operacoes = montaTabela([...operacao]);
        dados.execucoes = montaTabela([...fruicaoExecusao])
        dados.geral = montaTabela([...fruicaoGeral]);
        dados.especial = montaTabela([...fruicaoEspecial]);

        dados.operacoesSaida = montaTabela([...operacoesSaida]);
        

        dados.revelanteInteres = montaTabela([...fruicaoRelevante]);
        dados.renda = montaTabela([...fruicaoRenda])
        dados.terceirizados = montaTabela([...fruicaoTerceirizados])
        dados.responsavel = {
            id: profile.id,
            nome: profile.name,
            email: profile.email
        }

        let val = {
            id: (json && json.id) ? json.id : beneficiario.id,
            execucaoOperacaoFruicao: [
                dados
            ],
            historico: [
                {
                    responsavel: {
                        id: profile.id,
                        nome: profile.name,
                        email: profile.email
                    },
                    tipo: "Inclusão Fruição",
                    data: new Date(),
                    texto: "Inclusão/ Alteração"
                }
            ]
        }
        submitForm(val);
    }

    function submitForm(dado) {
        setWait(true);

        fetch('/api/empregaDF/fruicao', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then(json => {
                    // const json = localStorage.getItem("BENEFICIARIO");
                    localStorage.setItem("BENEFICIARIO", JSON.stringify(json));
                    // history.replace("/fundefe/DadosFinanceirosForm/" + json.id);
                    setMensagem((isInclusao) ? "Registro incluído" : "Registro alterado");
                    topFunction();
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function topFunction() {
        setTimeout(function () {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }, 30);
    }

    const handleChangeInputFormInt = (event) => {
        const valInt = event.target.value.replace(/\D/g, "");
        if (event.target.value.replace(/\d/g, "")) {
            return;
        }
        setFruicao({
            ...fruicao,
            [event.target.name]: valInt,
        });
    }
    const handleChangeInputFormTaxa = (event) => {
        setTaxaBeneficios({
            ...taxaBeneficios,
            [event.target.name]: event.target.value,
        });
    }


    return (
        <Container maxWidth='xl'>
            <Paper>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Informações do Beneficiário</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={3}>
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    label="CNPJ"
                                    value={beneficiario && beneficiario.cnpj || ''}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    label="Razão Social"
                                    value={beneficiario && beneficiario.razaoSocial || ''}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <FormControl disabled={true} component="fieldset">
                                    <RadioGroup disabled={true} row aria-label="isMatriz" name="isMatriz" value={beneficiario && beneficiario.matriz || ''}>
                                        <FormControlLabel value="matriz" control={<Radio color="primary" />} label="Matriz" />
                                        <FormControlLabel value="filial" control={<Radio color="primary" />} label="Filial" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    disabled={true}
                                    fullWidth
                                    label="Processo SEI*"
                                    value={beneficiario && beneficiario.processoSEI || ''}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>

                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Fruição e acompanhamento</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1}>

                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Ano refência"
                                    value={fruicao && fruicao.ano || ''}
                                    onChange={handleChangeInputFormInt}
                                    name="ano"
                                    autoFocus={true}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: disable,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="Pontuação do ano de referência"
                                    value={fruicao && fruicao.pontuacao || ''}
                                    onChange={handleChangeInputFormInt}
                                    name="pontuacao"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: disable,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend">Beneficios</FormLabel>
                                    <FormGroup aria-label="position" row >
                                    <Grid container spacing={1}>
                                            <Grid item xs={4}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={geral || false} onChange={handleChangeCheckbox} name="geral" />}
                                                    label="Geral"
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Taxa de desconto(%)"
                                                    value={taxaBeneficios && taxaBeneficios.geral || ''}
                                                    onChange={handleChangeInputFormTaxa}
                                                    name="geral"
                                                    variant="outlined"
                                                    InputProps={{
                                                        readOnly: disable,
                                                        inputComponent: MonetarioField
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={especial || false} onChange={handleChangeCheckbox} name="especial" />}
                                                    label="Importação"
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Taxa de desconto(%)"
                                                    value={taxaBeneficios && taxaBeneficios.especial || ''}
                                                    onChange={handleChangeInputFormTaxa}
                                                    name="especial"
                                                    variant="outlined"
                                                    InputProps={{
                                                        readOnly: disable,
                                                        inputComponent: MonetarioField
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControlLabel
                                                    control={<Checkbox checked={relevante || false} onChange={handleChangeCheckbox} name="relevante" />}
                                                    label="Relevante Interesse"
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Taxa de desconto(%)"
                                                    value={taxaBeneficios && taxaBeneficios.relevante || ''}
                                                    onChange={handleChangeInputFormTaxa}
                                                    name="relevante"
                                                    variant="outlined"
                                                    InputProps={{
                                                        readOnly: disable,
                                                        inputComponent: MonetarioField
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} >
                                <AppBar position="static">
                                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                                        <Tab label="Renda" {...a11yProps(0)} />
                                        <Tab label="Empregos" {...a11yProps(1)} />
                                        <Tab label="Execução" {...a11yProps(2)} />  
                                        <Tab label="Operação" {...a11yProps(3)} />
                                        <Tab label="Fruição" {...a11yProps(4)} />
                                        <Tab label="Gráfico" {...a11yProps(5)} />
                                    </Tabs>
                                </AppBar>
                                <TabPanel value={value} index={2}>
                                    <FruicaoExecusaoTable dados={fruicaoExecusao} setDados={setFruicaoExecusao} dadosTerceirizados={fruicaoTerceirizados} disabled={disable} dadosRenda={fruicaoRenda} />
                                </TabPanel>
                                <TabPanel value={value} index={3}>
                                    <Accordion defaultExpanded={true}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <Typography className={classes.heading}>Operações de saída</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <OperacaoTable dados={operacao} setDados={setOperacao} disabled={disable} />
                                        </AccordionDetails>
                                    </Accordion>

                                    
                                    {state.especial &&
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography className={classes.heading}>Operações de Entrada</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <OperacaoEntradaTable dados={operacoesSaida} setDados={setOperacoesSaida} dadoApoio={dadoApoio} perDescontoBeneficio={taxaBeneficios.especial ?(typeof taxaBeneficios.especial === "number" ? taxaBeneficios.especial : taxaBeneficios.especial.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '').replace(",",".")) : 0.0} disabled={disable} />
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                </TabPanel>
                                <TabPanel value={value} index={4}>
                                    {state.geral &&
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography className={classes.heading}>Geral</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <FruicaoTable dados={fruicaoGeral} setDados={setFruicaoGeral} dadoApoio={dadoApoio} perDescontoBeneficio={taxaBeneficios.geral ?(typeof taxaBeneficios.geral === "number" ? taxaBeneficios.geral : taxaBeneficios.geral.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '').replace(",",".")) : 0.0} disabled={disable} />
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    {state.especial &&
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography className={classes.heading}>Importação</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <FruicaoTable dados={fruicaoEspecial} setDados={setFruicaoEspecial} dadoApoio={dadoApoio} perDescontoBeneficio={taxaBeneficios.especial ?(typeof taxaBeneficios.especial === "number" ? taxaBeneficios.especial : taxaBeneficios.especial.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '').replace(",",".")) : 0.0} disabled={disable} />
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    {state.relevante &&
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography className={classes.heading}>Relevante Interresante</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <FruicaoTable dados={fruicaoRelevante} setDados={setFruicaoRelevante} dadoApoio={dadoApoio} perDescontoBeneficio={taxaBeneficios.relevante ?(typeof taxaBeneficios.relevante === "number" ? taxaBeneficios.relevante : taxaBeneficios.relevante.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '').replace(",",".")) : 0.0} disabled={disable} />
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                    {(state.especial || state.relevante || state.geral) &&
                                        <Accordion defaultExpanded={true}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header"
                                            >
                                                <Typography className={classes.heading}>Total</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <FruicaoTable dados={total} setDados={setTotal} dadoApoio={dadoApoio} perDescontoBeneficio={dadoApoio ? dadoApoio.perDescontoBeneficioInte : null} disabled={true} />
                                            </AccordionDetails>
                                        </Accordion>
                                    }
                                </TabPanel>
                                <TabPanel value={value} index={0}>
                                    <FruicaoRendaTable dados={fruicaoRenda} setDados={setFruicaoRenda} disabled={disable} />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    <FruicaoTerceirizadosTable dados={fruicaoTerceirizados} setDados={setFruicaoTerceirizados} dadosRenda={fruicaoRenda} disabled={disable} />
                                </TabPanel>
                                <TabPanel value={value} index={5} 
                                   style={{
                                    marginLeft : '35%',
                                }} >
                                    <Typography className={classes.heading} >Gráficos</Typography>
                                        <IconButton aria-label="atualiza Grafico" color="primary"
                                            onClick={atualiza}
                                        >
                                            <UpdateIcon />
                                        </IconButton>

                                        {(fruicao && total && total.length > 0) &&
                                            <FruicaoGraficos graficoPiz={true} dado={fruicao} total={total} />
                                            }
                                     
                                </TabPanel>
                            </Grid>
                        </Grid>

                    </AccordionDetails>

                </Accordion>
                <Grid container justify="flex-end" className={classes.btns}>
                    {!disable && (fruicao && fruicao.ano) &&
                        <Button variant="contained" color="primary" rel="noopener noreferrer"
                            onClick={concluir}
                        >
                            {isInclusao ? "Incluir" : "Alterar"}
                        </Button>
                    }
                    <Button variant="contained" color="secondary" rel="noopener noreferrer"
                        onClick={sair}
                    >
                        Voltar
                </Button>

                </Grid>
            </Paper>
        </Container>
    );

}