import React, { useEffect, useState } from 'react';
import {
    Grid, Button,
} from '@material-ui/core';
import GenericTable from "../../components/template/GenericTable";
import { FormataDataSemHora, useStyles } from "../../constants";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../util/ConfirmDialog";
import Service from '../../services/Service';
import { useCurrentUser } from "../../server/UseCurrentUser";

let idProjeto = 0;
let bucouProjeto = false;

export default function ProjetoTable(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const history = useHistory();
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    let [profile] = useCurrentUser();

    useEffect(() => {
        setTitulo("Projetos Solicitados");
        buscaProjetos();
    }, []);

    useEffect(() => {
        if (profile && fonteDeDados.length > 0 && !bucouProjeto) buscarRequerimento();
    }, [profile, fonteDeDados]);

    function buscaProjetos() {
        setWait(true);
        Service.GET("/api/projeto/userLogado").then(json => {
            bucouProjeto = false;
            setWait(false);
            setFonteDeDados(json);
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function buscarRequerimento() {
        Service.POST('/api/processoProdf/requerimento/filtro', {
            cnpjs: profile.empresas,
            cpf: profile.CPF
        }).then(resp => {
            setWait(false);
            ajustarSituacao(resp);
            bucouProjeto = true;
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })

    }
    function ajustarSituacao(dado) {
        let d = []
        for (let x in fonteDeDados) {
            const projeto = fonteDeDados[x];
            let temRequerimento = false;
            for (let y in dado) {
                const requerimento = dado[y];
                if (projeto.protocolo == requerimento.protocolo) {
                    temRequerimento = true;
                    d.push({
                        ...projeto,
                        status: requerimento.status,
                        idRequerimento: requerimento.id
                    });
                }
            }
            if (!temRequerimento) {
                d.push({
                    ...projeto,
                });
            }
        }
        // console.log(d)
        setFonteDeDados(d);
    }

    function excluir(id) {
        setShowConfirmExclusao(true);
        idProjeto = id;
    }
    function confirmaExclusao() {
        setWait(true);
        fetch('/api/projeto/' + idProjeto, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(response => {
            if (response.ok) {
                setWait(false);

                let value = [];
                if (fonteDeDados && (fonteDeDados.length > 0)) {
                    for (let i = 0; i < fonteDeDados.length; i++) {
                        if (fonteDeDados[i].id !== idProjeto) {
                            value.push(fonteDeDados[i]);
                        }
                    }
                }
                // fonteDeDados = value
                setFonteDeDados(value);

            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === "Visualizar") {
            history.replace("/projeto/viewProjeto/" + dado.id);
        } else if (acao === "Alterar") {
            history.replace("/projeto/editarProjeto/" + dado.id);
        } else if (acao === "Excluir") {
            excluir(dado.id);
        } else if (acao === 'Visualizar Requerimento') {
            history.replace("/gerenciaProcesso/solicitarRequerimentoView/" + dado.idRequerimento);
        }
    }

    const desabilitaAcaoAtenderTable = (acao, dado) => {
        if (dado) {
            if (acao === 'Reenviar' && dado.status !== "PENDENTE") {
                return true;
            }
            if (acao === 'Excluir' && dado.status !== "RASCUNHO") {
                return true;
            }
            if (acao === 'Alterar' && dado.status !== "RASCUNHO") {
                return true;
            }
            if (acao === 'Visualizar Requerimento' && !dado.idRequerimento) {
                return true;
            }
            return false;
        }
        return true;
    }

    function transformaStatus(valor) {
        if (valor) {
            if (valor === "RASCUNHO") return "Rascunho";
            else if (valor === "EM_ANALISE") return "Em análise";
            else if (valor === "PENDENTE") return "Pendete";
            else if (valor === "REPROVADO") return "Reprovado";
            else if (valor === "INDEFERIDO") return "Indeferido";
            else if (valor === "DEFERIDO") return "Deferido";
            return (valor);
        }
        return "";
    }

    function getRa(dado) {
        if (dado && dado.dadosImovel) {
            return dado.dadosImovel[0].ra
        }
        return "";
    }
    function getAde(dado) {
        if (dado && dado.dadosImovel) {
            return dado.dadosImovel[0].ade
        }
        return "";
    }
    function getEndereco(dado) {
        if (dado && dado.dadosImovel) {
            return dado.dadosImovel[0].ade
        }
        return "";
    }
    function transformaDt(valor) {
        if (valor) {
            return FormataDataSemHora(new Date(valor));
        }
        return "";
    }

    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                handleDisableAction={desabilitaAcaoAtenderTable}
                options={['Visualizar', 'Alterar', 'Excluir', 'Reenviar', 'Visualizar Requerimento']}
                dados={fonteDeDados}
                colunas={[
                    { nome: 'Protocolo', campo: 'protocolo' },
                    { nome: 'Processo', campo: 'lotes.processo' },
                    { nome: 'CNPJ', campo: 'empresa.cnpj' },
                    { nome: 'Razão Social', campo: 'empresa.razaoSocial' },
                    { nome: 'RA', campo: 'lotes', transformacao: getRa },
                    { nome: 'ADE', campo: 'lotes', transformacao: getAde },
                    { nome: 'Endereço', campo: 'lotes', transformacao: getEndereco },
                    { nome: 'Assunto', campo: 'assunto' },
                    { nome: 'Data Solicitação', campo: 'dataEnvio', transformacao: transformaDt },
                    { nome: 'Situação', campo: 'status', transformacao: transformaStatus },
                    { nome: 'Requerente', campo: 'Requerente' },
                    { nome: 'Ação', campo: 'action' }
                ]} />

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>

                <Button variant="contained" color="primary" href={"/projeto/novoProjeto"} >
                    Cadastrar
                </Button>
            </Grid>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />
        </Grid >


    );
}
