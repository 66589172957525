import React, { useState } from "react";
import { Container } from '@material-ui/core';
import { Backdrop, Grid, Paper, Typography, Collapse, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useStyles } from "../../constants";
import CloseIcon from '@material-ui/icons/Close';
import ChamadosTable from "./ChamadosTable";
import ChamadosForm from './ChamadosForm';
import ChamadosIndicadoresPanel from "./ChamadosIndicadoresPanel";
import SuporteTab from "./SuporteTab";
import FiltroRelatorioChamado from "./FiltroRelatorioChamado";

export default function SuportePanel() {
  const classes = useStyles();
  const match = useRouteMatch();
  const [erro, setErro] = useState();
  const [wait, setWait] = useState(false);
  const [titulo, setTitulo] = useState("???");
  const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });

  function setMensagem(alerta) {
    let a;
    if (typeof alerta === 'object') {
      a = { ...alerta };
      if (!a.tipo) a.tipo = 'success';
      if (!a.duracao) a.duracao = 5000;
      a.visibilidade = true;
    } else {
      a = {
        tipo: 'info',
        duracao: 5000,
        visibilidade: true,
        mensagem: alerta
      }
    }

    setAlerta(a);
    if (!!a.duracao && a.duracao > 0) {
      window.setTimeout(() => {
        setAlerta({ tipo: a.tipo, visibilidade: false });
      }, a.duracao)
    }
  }

  return (
    <Container maxWidth="xl">

      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
            <Collapse in={alerta.visibilidade}>
              <Alert severity={alerta.tipo} action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlerta({ visibilidade: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>{alerta.mensagem}</Alert>
            </Collapse>
          </Paper>
          {
            erro && <Alert severity="error">{erro}</Alert>
          }
        </Grid>
        <Grid item sm={12}>
          <Switch>

            <Route path={`${match.path}/listaChamados`}>
              <ChamadosTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/registrarChamado`}>
              {/* <RegistrarChamadaForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} /> */}
              <SuporteTab setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/chamadoSuporteForm`}>
              <ChamadosForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/chamadoSuporteView`}>
              <ChamadosForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} view={true} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/analiseIndicadores`}>
              <ChamadosIndicadoresPanel setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} view={true} />
            </Route>

            <Route path={`${match.path}/gerarRelatorio`}>
              <FiltroRelatorioChamado setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
            </Route>

            <Route path={match.path}>
              <h2>nao implementado ainda</h2>
            </Route>
          </Switch>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={wait}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Container>
  )
}