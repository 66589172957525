import React, { useEffect, useState } from 'react';
import GenericTable from "../../template/GenericTable";
import { useStyles } from "../../../constants";
import {
    Grid, Button, TextField,
} from '@material-ui/core';
import { useHistory, useLocation, useParams } from "react-router-dom";
import ConfirmDialog from '../../../util/ConfirmDialog';

let idParcela = null;
export default function DadosApoioTable(props) {
    const { setTitulo, setMensagem, setWait, setErro } = props;
    const classes = useStyles();
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const [showConfirmExclusao, setShowConfirmExclusao]= useState(false);
    const history = useHistory();
    const location = useLocation();
    let { id } = useParams();
    useEffect(() => {
        setTitulo("Dados Apoio");
        buscarDadoApoio()
    }, []);

    function buscarDadoApoio(){
        fetch("/api/fundefeDadoApoio")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    setFonteDeDados(result)
                    // filialMatriz(result)
                }
            });
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === "Visualizar") {
            history.replace("/fundefe/dadosApoioView/"+dado.id);
        } else if (acao === "Alterar") {
            history.replace("/fundefe/dadosApoioForm/" + dado.id);
        } else if (acao === "Excluir") {
            setShowConfirmExclusao(true);
            idParcela = dado.id;
        }
    }

    function confirmaExclusao(){
        setWait(true);
        fetch("/api/fundefeDadoApoio/"+idParcela,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    let value = [];
                    if (fonteDeDados && (fonteDeDados.length > 0)) {
                        for (let i = 0; i < fonteDeDados.length; i++) {
                            if (fonteDeDados[i].id !== idParcela) {
                            value.push(fonteDeDados[i]);
                            }
                        }
                    }
                    setFonteDeDados(value);
                }
            });
    }

    return (
        <Grid container spacing={3} className={classes.root} >
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={['Visualizar', 'Alterar', 'Excluir']}
                dados={fonteDeDados}
                colunas={[
                    { nome: 'Exercicio', campo: 'exercicio' },
                    { nome: 'Ação', campo: 'action' }
                ]}
            />
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
                <Button variant="contained" color="primary" href={"/fundefe/dadosApoioForm"}>
                    Novo
                </Button>
            </Grid>
            <ConfirmDialog
                    open={showConfirmExclusao}
                    setOpen={setShowConfirmExclusao}
                    titulo={'Confirmação'}
                    msg='Confirma exclusão do item selecionado?.'
                    exec={confirmaExclusao}
                />
        </Grid >


    );
}
