import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Container, Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useStyles } from '../../constants';
import UnidadeSeiForm from './form/UnidadeSeiForm';
import { useHistory } from 'react-router-dom';
import TiposProcessoForm from './form/TiposProcessoForm';
import HipoteseLegalForm from './form/HipoteseLegalForm';
import TipoDocumentosForm from './form/TipoDocumentosForm';
import RelatorioTecnicoForm from './form/RelatorioTecnicoForm';
import LaudoVistoriaForm from './form/LaudoVistoriaForm';
import Service from '../../services/Service';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

export default function ParametroSeiTabPanel(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setMensagem, setErro, setSuccess } = props;
    const [value, setValue] = useState(0);
    const [parametro, setParametro] = useState();
    const [salvarDisable, setSalvarDisable]= useState(false);
    const history = useHistory();

    useEffect(() => {
        setWait(true);
        Service.GET('/api/sei/parametro').then(json => {
            setWait(false);
            setParametro(json);
        }).catch(error => {
            setWait(false);
            if (error && error.message == "ParametroSei not found with id : '0'") {
                setMensagem("Parâmetro SEI ainda não cadastrado")
            } else {
                setErro(error ? error : 'Oops! Something went wrong. Please try again!');
            }

        });
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const proximaPage = () => {
        const x = value;
        setValue(x + 1);
    }
    const anteriorPage = () => {
        const x = value;
        setValue(x - 1);
    }

    function salvar() {
        setErro(false);
        setWait(true);
        Service.POST('/api/sei/parametro', parametro).then(resp => {
            setParametro(resp);
            setWait(false);
            setSalvarDisable(false);
            setSuccess("Parametro salvo com sucesso")
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })

    }

    return (
        <Container maxWidth='lg'>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab value={0} label="Unidade" wrapped {...a11yProps(0)} />
                    <Tab value={1} label="Tipos de Processo" {...a11yProps(1)} />
                    {/* <Tab value={2} label="Hipótese Legal" {...a11yProps(2)} /> */}
                    {/* <Tab value={2} label="Tipo de Documentos" {...a11yProps(3)} />*/}
                    {/* <Tab value={2} label="Relatorio Técnico"         {...a11yProps(4)} /> */}
                    <Tab value={2} label="Laudo de Vistoria" {...a11yProps(5)} />

                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <UnidadeSeiForm setWait={setWait} setMensagem={setMensagem} parametro={parametro} setParametro={setParametro} salvar={salvar} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TiposProcessoForm setWait={setWait} setMensagem={setMensagem} parametro={parametro} setParametro={setParametro} salvar={salvar} />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                <HipoteseLegalForm setWait={setWait} setMensagem={setMensagem} parametro={parametro} setParametro={setParametro} salvar={salvar} />
            </TabPanel> */}
            {/* <TabPanel value={value} index={2}>
                <TipoDocumentosForm />
            </TabPanel>  */}
            {/* <TabPanel value={value} index={2}>
                <RelatorioTecnicoForm setWait={setWait} setMensagem={setMensagem} parametro={parametro} setParametro={setParametro} setSalvarDisable={setSalvarDisable} />
            </TabPanel> */}
            <TabPanel value={value} index={2}>
                <LaudoVistoriaForm setWait={setWait} setMensagem={setMensagem} parametro={parametro} setParametro={setParametro} setSalvarDisable={setSalvarDisable} />
            </TabPanel>

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" onClick={() => history.push("/home")} rel="noopener noreferrer">
                    Sair
                </Button>
                {value != 0 && <Button variant="contained" color="primary" onClick={anteriorPage} >
                    Anterior
                </Button>}
                {value < 2 && <Button variant="contained" color="primary" onClick={proximaPage} >
                    Proximo
                </Button>}
                {salvarDisable && <Button variant="contained" color="primary" onClick={salvar} >
                    Salvar
                </Button>}

            </Grid>
        </Container>
    );
}
