import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { List, Typography, Box, IconButton, TextField, Container } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useStyles } from "../../constants";
import CampoBusca from "../../util/CampoBusca";
import ConfirmDialog from "../../util/ConfirmDialog";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import UnidadeDialog from "../user/unidade/UnidadeDialog";
import { useUnidades } from "../../server/UseUnidades";

const useStylesPagina = makeStyles(() => ({
  paper: {
    width: 350,
    height: 500,
    overflow: 'auto',
  },
  margem: {
    marginLeft: '-40px'
  }
}));

function intersection(a, b) {
  return a.filter((value) => b.find(item => item.regra === value.regra));
}

let pageLoaded = false;
let fonteDisponiveis;
let fonteSelecioadas;
let elapseTime = 0;
let tempo = 600;
let filtroCampoDisponivel = null;
let filtroCampoSelecionado = null;

const useStylesLocal = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    //   width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  txt: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: 400,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CadastrarPerfilPanel(props) {
  const classes = useStyles();
  const classesLocal = useStylesLocal();
  const { perfil, disabled } = props;
  const classesPagina = useStylesPagina();
  const [checked, setChecked] = React.useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showUnidadeDialog, setShowUnidadeDialog] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');
  const [nome, setNome] = React.useState();
  const [unidade, setUnidade] = React.useState();

  const [unidades] = useUnidades();

  const [painelDisponiveis, setPainelDisponiveis] = React.useState([]);
  const [painelSelecionadas, setPainelSelecionadas] = React.useState([]);

  const leftChecked = intersection(checked, painelDisponiveis);
  const rightChecked = intersection(checked, painelSelecionadas);

  useEffect(() => {

    fetch("/api/funcionalidade",
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': `Bearer ${localStorage.accessToken}`
        }
      }).then(res => res.json()).then((result) => {
        if (!pageLoaded) {
          let lst = result;
          if (props && props.perfil) {
            lst = [];
            let funcMap = [];
            let lstUser = [];
            if (props.perfil.regras) {
              props.perfil.regras.forEach(f => {
                funcMap.push(f);
              });
            }
            result.forEach(f => {
              if (props.perfil.regras && props.perfil.regras.find(e => e === f.regra)) {
                lstUser.push(f);
              } else {
                lst.push(f);
              }
            });
            fonteSelecioadas = [...lstUser];
            setPainelSelecionadas(lstUser);
          }
          lst.sort((a, b) => ((a.regra < b.regra) ? -1 : 1));
          setPainelDisponiveis(lst);
          fonteDisponiveis = [...lst];
          pageLoaded = true;
        }
      });
  }, []);
  useEffect(() => {
    if (unidades && perfil && perfil.idUnidade) {
      const u = unidades.find(un => un.id === perfil.idUnidade);
      setUnidade(u)
    }
  }, [unidades])
  function unidadeSelecionada(un) {
    setUnidade(un);
  }
  // const handleCancel = () => {
  //   window.location.reload();
  // }

  const handleSubmit = () => {
    if (filtroCampoSelecionado && filtroCampoSelecionado.length > 0) {
      setMsgErro("Retire o filtro dos usuários selecionados para poder aplicar a alteração.");
      return;
    }

    let regras = [];
    painelSelecionadas.forEach(el => regras.push(el.regra));

    let id = (props && props.perfil) ? props.perfil.id : null;
    let path = (props && props.perfil) ? 'atualizar' : 'cadastrar';
    let dado = {
      id: id,
      regras: regras,
      idUnidade: unidade.id
    };
    dado.nome = (perfil && perfil.nome) ? props.perfil.nome : document.getElementById("nome").value;

    fetch('/api/perfil/' + path, {
      method: (props && props.perfil) ? 'PUT' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(dado),
      credentials: 'include'
    }).then(response => {
      if (response.ok && (response.status < 300)) {
        window.location.reload();
      } else {
        response.json().then((error) => {
          setOpen(true);
          setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
      }
    }).catch(error => {
      setOpen(true);
      setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });

  }

  const handleToggle = (value) => () => {
    let newChecked = [...checked];
    if (checked.find(item => value.regra === item.regra)) {
      let arr = [];
      newChecked.forEach(item => {
        if (item.regra !== value.regra) {
          arr.push(item);
        }
      })
      newChecked = arr;
    } else {
      newChecked.push(value)
    }
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    let disponiveis = [...painelDisponiveis];
    let selecionadas = [...painelSelecionadas];
    disponiveis.forEach(item => selecionadas.push(item));

    selecionadas.sort((a, b) => a.name > b.name);
    setPainelSelecionadas(selecionadas);
    setPainelDisponiveis([]);

    fonteSelecioadas = selecionadas;
  };

  const handleCheckedRight = () => {
    let disponiveis = [...painelDisponiveis];
    let selecionadas = [...painelSelecionadas];
    leftChecked.forEach(item => {
      selecionadas.push(item)
    });
    let arr = [];
    for (let x in disponiveis) {
      let item = disponiveis[x];
      if (selecionadas.find(i => i.regra === item.regra)) {
        continue;
      }
      arr.push(item);
    }
    arr.sort((a, b) => a.name > b.name);
    selecionadas.sort((a, b) => a.name > b.name);
    setPainelDisponiveis(arr);
    setPainelSelecionadas(selecionadas);

    fonteSelecioadas = selecionadas;
  };

  const handleCheckedLeft = () => {

    let disponiveis = [...painelDisponiveis];
    let selecionadas = [...painelSelecionadas];
    rightChecked.forEach(item => {
      disponiveis.push(item)
    });
    let arr = [];
    for (let x in selecionadas) {
      let item = selecionadas[x];
      if (disponiveis.find(i => i.regra === item.regra)) {
        continue;
      }
      arr.push(item);
    }
    arr.sort((a, b) => a.name > b.name);
    setPainelSelecionadas(arr);
    setPainelDisponiveis(disponiveis);

  };

  const handleAllLeft = () => {
    let disponiveis = [...painelDisponiveis];
    let selecionadas = [...painelSelecionadas];
    selecionadas.forEach(item => disponiveis.push(item));

    selecionadas.sort((a, b) => a.name > b.name);
    setPainelSelecionadas([]);
    setPainelDisponiveis(disponiveis);
  };

  const handleSearchDisponivel = (e) => {
    filtroCampoDisponivel = e.target.value;
    if (performance.now() - elapseTime > tempo) {
      disparaSearchDisponivel();
    }
    elapseTime = performance.now();
  }
  const handleSearchSelecionado = (e) => {
    setMsgErro();
    filtroCampoSelecionado = e.target.value;
    if (performance.now() - elapseTime > tempo) {
      disparaSearchSelecionado();
    }
    elapseTime = performance.now();
  }
  function disparaSearchSelecionado() {
    setTimeout(function () {
      if (performance.now() - elapseTime > tempo) {
        filtraDadoSelecionado(filtroCampoSelecionado);
      } else {
        disparaSearchSelecionado();
      }
    }, tempo);
  }
  function disparaSearchDisponivel() {
    setTimeout(function () {
      if (performance.now() - elapseTime > tempo) {
        filtraDadoDisponivel(filtroCampoDisponivel);
      } else {
        disparaSearchDisponivel();
      }
    }, tempo);
  }
  function filtraDadoSelecionado() {
    if (!fonteSelecioadas) return;
    let arr = [];
    let arrOrdenado = ordenar(fonteSelecioadas);
    arrOrdenado.forEach(d => {
      if (filtroCampoSelecionado) {
        let campos = filtroCampoSelecionado.split(' ');
        for (var y in campos) {
          if (d.descricao.toUpperCase().includes(campos[y].toUpperCase())) {
            arr.push(d);
            break;
          }
        }
      } else {
        arr.push(d);
      }
    });

    let arrSemDisponivel = [];
    for (let x in arr) {
      let item = arr[x]
      if (painelDisponiveis.find(sel => sel.regra === item.regra)) {
        continue;
      }
      arrSemDisponivel.push(item);
    }

    setPainelSelecionadas(arrSemDisponivel);

  }
  function filtraDadoDisponivel() {
    if (!fonteDisponiveis) return;
    let arr = [];
    let arrOrdenado = ordenar(fonteDisponiveis);
    arrOrdenado.forEach(d => {
      if (filtroCampoDisponivel) {
        let campos = filtroCampoDisponivel.split(' ');
        for (var y in campos) {
          if (d.descricao.toUpperCase().includes(campos[y].toUpperCase())) {
            arr.push(d);
            break;
          }
        }
      } else {
        arr.push(d);
      }

    });
    let arrSemSelecionado = [];
    for (let x in arr) {
      let item = arr[x]
      if (painelSelecionadas.find(sel => sel.regra === item.regra)) {
        continue;
      }
      arrSemSelecionado.push(item);
    }
    setPainelDisponiveis(arrSemSelecionado);

  }
  function ordenar(arr) {
    const finalList = arr.sort((a, b) => ((a.nome < b.nome) ? -1 : 1));
    return finalList;
  }
  const handleEscolheUnidade = () => {
    setShowUnidadeDialog(true);
  }

  const customList = (items) => (
    <Paper className={classesPagina.paper}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value.regra} role="listitem" button onClick={handleToggle(value)}>
              {(!disabled) &&<ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>}
              <ListItemText id={labelId} primary={value.descricao} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Container maxWidth='lg'>
      <Grid container justify="center" alignItems="center" className={classes.root}>
        {msgErro &&
          <Grid item xs={12}>
            <Alert
              severity="warning"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setMsgErro();
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {msgErro}
            </Alert>
          </Grid>

        }
        {(perfil && perfil.id) &&
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography component="h1" variant="h5" gutterBottom>{'Perfil ' + ((props && props.perfil) ? props.perfil.nome : '')} </Typography>
            </Paper>
          </Grid>
        }
        {
          (!perfil || !perfil.id)
          &&
          <Grid item xs={12}>
            <Paper className={classesLocal.root}>
              <TextField
                fullWidth
                value={nome || ''}
                onChange={(e) => { setNome(e.target.value) }}
                label="Perfil"
                id="nome"
                name="nome"
                aria-describedby="nova-helper-text"
                variant="outlined"
              />
            </Paper>
          </Grid>
        }
        <Grid item xs={5}>
          <Typography variant="subtitle1">Funcionalidades disponíveis:</Typography>
          <Box className={classesPagina.margem}>
            <CampoBusca handleSearch={handleSearchDisponivel} />
          </Box>
          {customList(painelDisponiveis)}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllRight}
              disabled={(!disabled) ? painelDisponiveis.length === 0 : true}
              aria-label="move all right"
            >
              ≫
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={(!disabled) ? leftChecked.length === 0 : true}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={(!disabled) ? rightChecked.length === 0 : true}
              aria-label="move selected left"
            >
              &lt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              className={classes.button}
              onClick={handleAllLeft}
              disabled={(!disabled) ? painelSelecionadas.length === 0 : true}
              aria-label="move all left"
            >
              ≪
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle1">Funcionalidades selecionadas:</Typography>
          <Box className={classesPagina.margem}>
            <CampoBusca handleSearch={handleSearchSelecionado} />
          </Box>
          {customList(painelSelecionadas)}
        </Grid>

      </Grid>
      <Grid item xs={12}>
        <Paper className={classesLocal.root}>
          <TextField
            className={classesLocal.input}
            placeholder="Unidade"
            id="nomeUnidade"
            label="Unidade Organizacional Vinculada"
            value={unidade && unidade.nome || ''}
            inputProps={{ readOnly: true }}
            variant="outlined"
          />
          {(!disabled) &&<Tooltip title="Buscar que esse perfil esta associado" aria-label="add">
            <IconButton type="submit" className={classesLocal.iconButton}
              aria-label="search"
              onClick={handleEscolheUnidade}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>}
        </Paper>
      </Grid>
      <Grid container justify="flex-end" className={classes.btns} >
        {/* <ThemeProvider theme={theme}> */}
        <Button variant="contained" color="secondary" href={"/criarPerfil"} >
          Sair
        </Button>
        <Button variant="contained" color="primary"
          onClick={() => setShowConfirm(true)}
          type="submit"
          disabled={(props && props.disabled) || !(unidade && unidade.nome)}>
          Associar
        </Button>
        {/* </ThemeProvider> */}
      </Grid>
      <ConfirmDialog
        open={showConfirm}
        setOpen={setShowConfirm}
        titulo={'Confirmação'}
        msg={'Deseja confirmar a alteração das funcionalidades ?'}
        exec={handleSubmit}
      />
      <UnidadeDialog open={showUnidadeDialog} setOpen={setShowUnidadeDialog} acao={unidadeSelecionada} unidades={unidades} />
    </Container>
  );
}
