import React, { useEffect, useState } from 'react';
import {
    Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../../../../constants';
import Service from '../../../../services/Service';

export default function EmpresaTrasferenteForm(props) {
    const classes = useStyles();
    const [dadoEmpresa, setDadoEmpresa] = useState(false);
    const { value, setValue, setWait, setMensagem, setAbilitaSalvar, disabled } = props;

    useEffect(() => {
        if (value && value.processo.cnpj) {
            // console.log("value:", value);
            if (!dadoEmpresa) {
                const cnpj = value.processo.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
                // console.log("cnpj:", cnpj);
                setWait(true);
                Service.GET("/api/v1/cnpj/" + cnpj).then(result => {
                    setWait(false);
                    // setProtocolo(result);
                    // console.log("<<<<<<<<<<<<<<<result>>>>>>>>>>>>>>>");
                    setDadoEmpresa(result)
                }).catch(error => {
                    setWait(false);
                    setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
                });
            }
        }

    }, [value]);

    const handleChangeProcesso = (event) => {
        setAbilitaSalvar(true);
        setValue({
            ...value,
            processo: {
                ...value.processo,
                [event.target.name]: event.target.value
            }
        });
    };

    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Relatório Tecnico para Trasferência de Incentivos</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <h4>1. Dados Gerais da Empresa Trasferente</h4>
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                value={value.processo && value.processo.razaoSocial || ''}
                                // disabled
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Interessado"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="interessado"
                                name="interessado"
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                value={value.processo && value.processo.nProcesso || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="N° Processo"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="processo"
                                name="processo"
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                value={value.processo && value.processo.cnpj || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="CNPJ"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="cnpj"
                                name="cnpj"
                            />
                        </Grid>
                        <Grid item xs={6} >
                            <TextField
                                // value={value.processo && value.processo.cnpj || ''}
                                value={value.protocolo || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="CF/DF"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="cfdf"
                                name="cfdf"
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                value={dadoEmpresa && dadoEmpresa.atividade && dadoEmpresa.atividade.atividadePrincipal[0].text || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Atividade Economica Desenvolvida"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="atividadeEconomica"
                                name="atividadeEconomica"
                                multiline
                            // rows={4}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                value={dadoEmpresa && dadoEmpresa.endereco || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Endereço Atual"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="enderecoAtual"
                                name="enderecoAtual"
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <TextField
                                value={dadoEmpresa && dadoEmpresa.telefone || ''}
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Telefone"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="telefone"
                                name="telefone"
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <TextField
                                value={value.processo && value.processo.site || ''}
                                InputProps={{
                                    readOnly: disabled,
                                }}
                                fullWidth
                                label="Site"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="site"
                                name="site"
                                onChange={handleChangeProcesso}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <TextField
                                value={value.processo && value.processo.email || ''}
                                InputProps={{
                                    readOnly: disabled,
                                }}
                                fullWidth
                                label="Email"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="email"
                                name="email"
                                onChange={handleChangeProcesso}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    )

}