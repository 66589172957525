import React, { useState } from 'react';
import { Grid, TextField, InputAdornment } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { KeyboardDatePicker } from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';

import NumberFormat from 'react-number-format';


const FormataCampo = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

export default function InformacoesForm(props) {
    const { value, setValue, disable } = props;
    const [selectedDateInicio, setSelectedDateInicio] = React.useState(
        (value.informacao && value.informacao.dtInicioContrato) ? value.informacao.dtInicioContrato : null
    );
    const [selectedDateFim, setSelectedDateFim] = React.useState(
        (value.informacao && value.informacao.dtFimContrato) ? value.informacao.dtFimContrato : null
    );

    const handleChangeInputForm = (event) => {
        let informacao = value.informacao ? value.informacao : {};
        informacao[event.target.name] = event.target.value
        setValue({
            ...value,
            informacao: informacao,
        });
    };

    const handleDateFimChange = (date) => {
        setSelectedDateFim(date);
        let informacao = value.informacao ? value.informacao : {};
        informacao["dtInicioContrato"] = date;
        setValue({
            ...value,
            informacao: informacao,
        });
    };
    const handleDateInicioChange = (date) => {
        setSelectedDateInicio(date);
        let informacao = value.informacao ? value.informacao : {};
        informacao["dtFimContrato"] = date;
        setValue({
            ...value,
            informacao: informacao,
        });
    };

    const handleChangeRadioGroup = (event) => {
        setValue({
            ...value,
            informacao: {
                ...value.informacao,
                [event.target.name]: event.target.value
            }
        });
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Grid container spacing={1} >
                <Grid item xs={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Estabelecimento</FormLabel>
                        <RadioGroup row name="estabelecimento" defaultValue="top" value={value.informacao && value.informacao.estabelecimento || ""} onChange={handleChangeRadioGroup} >
                            <FormControlLabel
                                value="matriz"
                                control={<Radio color="primary" />}
                                label="Matriz"
                                disabled={disable}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="filial"
                                control={<Radio color="primary" />}
                                label="Filial"
                                disabled={disable}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Condição da ocupação Atual</FormLabel>
                        <RadioGroup row name="condOcupacaoAtual" value={value.informacao && value.informacao.condOcupacaoAtual || ""} onChange={handleChangeRadioGroup} >
                            <FormControlLabel
                                value="proprio"
                                control={<Radio color="primary" />}
                                label="Proprio"
                                disabled={disable}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="cedido"
                                control={<Radio color="primary" />}
                                label="Cedido"
                                disabled={disable}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="alugado"
                                control={<Radio color="primary" />}
                                label="Alugado"
                                disabled={disable}
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="comodato"
                                control={<Radio color="primary" />}
                                label="Comodato"
                                disabled={disable}
                                labelPlacement="end"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Espeficicação do Imovel</FormLabel>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        required
                        label="Área do Terreno"
                        value={value.informacao && value.informacao.areaTerreno || ''}
                        name="areaTerreno"
                        onChange={handleChangeInputForm}
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">m²</InputAdornment>,
                            inputComponent: FormataCampo
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        required
                        label="Área do Edificada"
                        value={value.informacao && value.informacao.areaEdificada || ''}
                        name="areaEdificada"
                        id="cnpj-mask-input"
                        onChange={handleChangeInputForm}
                        disabled={disable}
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="start">m²</InputAdornment>,
                            inputComponent: FormataCampo
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        label="Se alugada, informar o valor mensal"
                        value={value.informacao && value.informacao.valorAluguel || ''}
                        name="valorAluguel"
                        id="valorAluguel"
                        onChange={handleChangeInputForm}
                        variant="outlined"
                        disabled={disable}
                        fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="start">R$</InputAdornment>,

                            inputComponent: FormataCampo
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <KeyboardDatePicker

                    disableToolbar
                    variant="inline"
                    id="dataInicioContrato"
                    label="Data Inicio do Contrato"
                    format="dd/MM/yyyy"
                    fullWidth
                    InputProps={{
                        readOnly: false,
                    }}
                    value={selectedDateInicio}
                    onChange={handleDateInicioChange}
                    disabled={disable}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    inputVariant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    id="dataFimContrato"
                    label="Data Fim do Contrato"
                    format="dd/MM/yyyy"
                    fullWidth
                    InputProps={{
                        readOnly: false,
                    }}
                    value={selectedDateFim}
                    onChange={handleDateFimChange}
                    disabled={disable}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    inputVariant="outlined"
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    );
}
