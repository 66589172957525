import React, { useState, useEffect, } from 'react';
import {
    Button, TextField, Paper, ListItem, List, ListItemText,
    ListItemAvatar, Avatar, Divider, Typography,
    ButtonGroup, Popover, IconButton, Toolbar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ArquivoService from '../../../services/ArquivoService';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import Service from '../../../services/Service';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        // maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },
}));
export default function AnexoUploadComponent(props) {
    const { disabled, setWait, setMensagem, setOpenDialogParecer, anexar } = props;
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [nome, setNome] = useState('');
    const [link, setLink] = useState('');
    const [anexo, setAnexo] = useState();
    const [ambienteInterno, setAmbienteInterno] = useState(false);
    const [documentos, setDocumentos] = useState();
    const [documentosOriginal, setDocumentosOriginal] = useState();

    useEffect(() => {
        if (ambienteInterno && !documentos) {
            Service.GET("/api/processoProdf/requerimento/getAllDocumentosTecnicos").then(result => {
                setWait(false);
                setDocumentos(result);
                setDocumentosOriginal(result);
            }).catch(error => {
                setWait(false);
                setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
            });
        }
    }, [ambienteInterno])
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleFiltro=(filtro)=>{
        let arr = [];
        for(let x in documentosOriginal){
            const doc = documentosOriginal[x];
            let has = false;
            for(let xx in doc){
                const conteudo = doc[xx];
                if( conteudo.includes(filtro)){
                    has = true;
                    break;
                }
            }
            if( has){
                arr.push(doc);
            }
        }
        setDocumentos(arr);
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const addAnexo = () => {
        let file = document.getElementById("file");
        file.click();
    }

    const upload = () => {
        let file = document.getElementById("file");
        let dados = new FormData();
        setWait(true);
        dados.append('file', file.files[0]);
        ArquivoService.upload(dados).then((result) => {
            setWait(false);
            var nm = file.value.split(/(\\|\/)/g).pop();

            let anexo = {
                nome: nm,
                uuid: result.uuid
            };
            setAnexo(anexo);
            file.value = null;
        }).catch((e) => {
            setWait(false);
            setMensagem({ tipo: 'error', mensagem: e.message });
        });
    }
    const handleClickItemEscolhido=(item)=>{
        const dado = {
            id: item.id,
            nomeDocumento: item.nomeDocumento,
            nomeArquivo: item.filename
        }
        if (anexar) anexar(dado);
        handleClose();
    }
    const handleRemoverClick = () => {
        setNome("");
        setLink("");
        setAnexo();
    }
    const handleAnexarClick = () => {
        const dado = {
            nome: nome,
            link: link,
            anexo: anexo
        }
        if (anexar) anexar(dado);
        handleClose();
    }
    const trocaAmbienteInterno = () => {
        setAmbienteInterno(true);
    }
    function EscolhaAmbienteSde() {
        return (
            <List className={classes.root}>
                {documentos && documentos.map((row, i) => (
                    <ListItem key={i} button onClick={()=> handleClickItemEscolhido(row)}>

                        <ListItemText
                            primary={row.nomeDocumento + " - " + row.data}
                            secondary={
                                <React.Fragment>
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        className={classes.inline}
                                        color="textPrimary"
                                    >
                                        {row.user}
                                    </Typography>
                                    {" - " + row.filename}
                                </React.Fragment>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        )
    }

    return (
        <>
            <ButtonGroup style={{ marginLeft: "auto" }} variant="text" size="small" aria-label="small button group"
                color="secondary"
                disabled={disabled}>
                <Button onClick={handleClick}>Anexar</Button>
                <Button onClick={() => { setOpenDialogParecer(true); }}>Texto Padrão</Button>
            </ButtonGroup>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper variant="outlined" style={{ width: "400px", maxHeight:"500px", minHeight:"350px" }}>
                    <Toolbar>
                        {ambienteInterno &&
                            <IconButton edge="start" style={{ marginRight: 5 }} color="inherit" aria-label="menu" onClick={() => setAmbienteInterno(false)}>
                                <ArrowBackIosIcon />
                            </IconButton>}
                        <Typography style={{ flexGrow: 1 }}>{
                            ambienteInterno ? "Escolha um documento já cadastrado" : "Anexar a partir do ..."
                        }</Typography>
                        <IconButton edge="start" style={{ marginRight: 5 }} color="inherit" aria-label="menu" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                    {!ambienteInterno &&
                        <List component="nav" aria-label="main mailbox folders">

                            <Divider />
                            <ListItem>
                                <Button size="small" variant="contained" onClick={addAnexo}>Computador</Button>
                            </ListItem>
                            <ListItem>
                                <Button size="small" variant="contained" onClick={trocaAmbienteInterno}>Ambiente SEDET</Button>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <Typography sx={{ p: 2 }}>Um link</Typography>
                            </ListItem>
                            <ListItem>
                                <TextField fullWidth id="id-nomeLink" value={nome} onChange={(e) => setNome(e.target.value)} label="Nome:" />
                            </ListItem>
                            <ListItem>
                                {!anexo &&
                                    <TextField fullWidth id="id-link" value={link} onChange={(e) => setLink(e.target.value)} label="Link:" />
                                }
                                {anexo &&
                                    <TextField fullWidth value={anexo.nome} label="Arquivo:" disabled />
                                }
                            </ListItem>
                            <Divider />
                            <ListItem>

                                <ButtonGroup color="primary" aria-label="outlined primary button group">
                                    <Button onClick={handleAnexarClick}>Anexar</Button>
                                    <Button onClick={handleRemoverClick}>Remover</Button>
                                </ButtonGroup>


                            </ListItem>
                        </List>
                    }
                    {ambienteInterno &&
                    <React.Fragment>
                        <TextField id="id-filtro-sde" label="Filtro" fullWidth onChange={(e)=>handleFiltro(e.target.value)}/>
                            <EscolhaAmbienteSde />
                        </React.Fragment>
                    }
                </Paper>
            </Popover>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />
        </>
    )
}