import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import { Button, TextField, Paper, Grid, Typography } from "@material-ui/core";
import RemoveIcon from '@material-ui/icons/Remove';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import SaveIcon from '@material-ui/icons/Save';
import { API_BASE_URL } from "../../constants";

const useStyles = makeStyles((theme) => ({
    root: {
        //   width: '100%',
    },
    media: {
        // height: 200,
        // paddingTop: '56.25%', // 16:9
        // paddingTop: '100.100%'
        // width: 300,
        // objectFit: 'cover'
        // marginRight: '5',
        // width: '90%',
        height: 300,
        objectFit: 'fill',
    }
}));

export default function LaudoAnexoGrid(props) {
    const { anexos, onClick, anexoTarget, trocaOrdem, disabled} = props;


    return (
        <Paper>
            <Grid container spacing={2} >
                {anexos && anexos.map((anexo, index) => (
                    <Grid item xs={6} key={index}>
                        <LaudoAnexoCard anexo={anexo} onClick={onClick} anexoTarget={anexoTarget} trocaOrdem={trocaOrdem} disabled={disabled}/>
                    </Grid>
                ))}
                {!disabled && anexos && anexos.length % 2 === 0 &&
                    <>
                        <Grid item xs={6}>
                            <LaudoAnexoCard onClick={onClick} anexoTarget={anexoTarget} />
                        </Grid>
                        {/* <Grid item xs={6}>
                        <LaudoAnexoCard onClick={onClick} anexoTarget={anexoTarget}/>
                    </Grid> */}
                    </>
                }
                {!disabled && anexos && anexos.length % 2 === 1 &&
                    <Grid item xs={6}>
                        <LaudoAnexoCard onClick={onClick} anexoTarget={anexoTarget} />
                    </Grid>
                }
            </Grid>
        </Paper>
    )
}

let source;
const LaudoAnexoCard = (props) => {
    const { anexo, onClick, anexoTarget, trocaOrdem, disabled } = props;
    const classes = useStyles();
    const [legenda, setLegenda] = useState();
    const [update, setUpdate] = useState(false);
    useEffect(()=>{
        if( anexo && anexo.legenda){
            setLegenda(anexo.legenda);
        }
    },[anexo])

    const handleClick = () => {
        if( disabled) return;
        if (onClick) {
            let item = {
                acao: (anexo && anexo.id) ? 'remove' : 'add',
                anexo: anexo,
                legenda: legenda
            }
            if (anexoTarget) {
                item.acao = 'save';
            }
            if (update) {
                item.acao = 'update';
                item.uuid = anexo.uuid;
                setUpdate(false);
            }
            onClick(item);
        }
    }
    const handleChange = (e) => {
        setLegenda(e.target.value);
        if (onClick && anexo) {
            setUpdate(true);
            // const item = {
            //     acao: 'update',
            //     legenda: e.target.value
            // }
            // onClick(item);
        }
    }
    function getIcone() {
        if (disabled) return <div/>
        if (update) return <SaveIcon />
        if (anexo && anexo.id) return <RemoveIcon />;
        if (anexoTarget) return <SaveIcon />
        else return <AttachFileIcon />
    }
    const handleDragEnd = (e)=>{
        if( disabled) return;
        // target=e.target.id;
        if( trocaOrdem){
            trocaOrdem(e.target.id, source)
        }
    }
    const handleDragOver=(e)=>{
        if( disabled) return;
        source = e.target.id;
        e.preventDefault();
    }
    return (
        <Card className={classes.root} draggable 
        onDragEnd={handleDragEnd} 
        
        id={anexo?anexo.uuid:'0'}>
            
            <CardHeader
                action={
                    <IconButton aria-label="settings" onClick={handleClick}>
                        {getIcone()}
                    </IconButton>
                }
                subheader={anexo ? anexo.nome : 'Informe um arquivo'}
            />
            {anexo &&
                // <DndProvider backend={HTML5Backend}>
                    <CardMedia
                        onDragOver={handleDragOver}
                        id={anexo.uuid}
                        className={classes.media}
                        image={anexo.id ? (API_BASE_URL + "/api/dowload/gridfs/" + anexo.id) : (API_BASE_URL + "/api/dowload/fileSystem/" + anexo.uuid + "-" + anexo.nome)}
                        title="Anexo"
                        component="img"
                    />
                // </DndProvider>
            }
            {!anexo && anexoTarget &&
                <CardMedia role="Handle"
                    className={classes.media}
                    image={API_BASE_URL + "/api/dowload/fileSystem/" + anexoTarget.uuid + "-" + anexoTarget.doc}
                    title="Anexo"
                    component="img"
                />}
            {!anexo && !anexoTarget &&
                <div
                    className={classes.media}

                />
            }
            <CardContent>
                <TextField value={legenda || ''} label="Legenda"
                    inputProps={{ style: { textAlign: 'center' } }}
                    fullWidth onChange={handleChange} disabled={disabled}/>

            </CardContent>
        </Card>
    );
}
