
import React, { useEffect, useState } from 'react';
import {
    Button, Grid, Container, Card, CardHeader, CardContent, TextField, FormControl, Select, MenuItem, IconButton, Tooltip
} from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, ValidarCNPJ, ValidarCPF, FormataCnpj } from "../../../constants";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import MaskedInput from 'react-text-mask'
import InputLabel from '@material-ui/core/InputLabel';
import { useUnidades } from '../../../server/UseUnidades';
import Service from '../../../services/Service';
import SearchIcon from '@material-ui/icons/Search';
import BuscaDadoDialog from '../../../util/BuscaDadoDialog'
import { ptBR } from 'date-fns/locale';
import EtapaProcessoComp from './EtapaProcessoComp';

function processoMask(props) {
    const { inputRef, ...other } = props;
    function functMask(a) {
        let nume = a.replace(/\D/g, '');
        if (nume.length <= 13) return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
        return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]; // 01234-000 000 01/2021-24.
    }
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={functMask}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
const status = ['Análise de Alteração Contratual', 'Análise de Alteração de Metas de Emprego', 'Análise de Alteração de Área', 'Assinatura de Contrato',
    'Cancelado', 'Em Acompanhamento Desenvolve-DF', 'Em Análise de Convalidação', 'Em Análise de Migração', 'Em Análise de Projeto', 'Em Análise de Revogação',
    'Em Análise de Transferência', 'Em Implantação Desenvolve-DF', 'Em Implantação PRODF', 'Escriturado', 'Implantado PRO-DF com AID'];
const programas = ['DESENVOLVE-DF', 'PADES', 'PRO-DF', 'PRO-DF II', 'PRODECON', 'PROIN'];

export default function CadastroProcessoForm(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setErro, setMensagem } = props;
    const [id, setId] = useState();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [processo, setProcesso] = useState({ dadosEmpresa: {} });
    const [error, setError] = React.useState(resetErro());
    const [unidades] = useUnidades();
    const [processoCadastrado, setProcessoCadastrado] = useState(false);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [listEmpresa, setListEmpresa] = useState([]);

    useEffect(() => {
        setTitulo("Cadastrar Processo");

        if (param.get("id")) {
            setId(param.get("id"));
            Service.GET("/api/gerenciarProcesso/" + param.get("id")).then(result => {
                setWait(false);
                // console.log("aqui",result)
                setProcesso({
                    ...result.processo,
                    unidade: result.IdUnidade,
                    programa: result.programa
                });
                if (result.processo && result.processo.dadosEmpresa && result.processo.dadosEmpresa.nProcesso != null) {
                    setProcessoCadastrado(true)
                }
            }).catch(error => {
                setWait(false);
                setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
            });
        }
        var data = new Date();
        processo.dataCriacao = data;
        console.log(processo)

    }, []);

    function resetErro() {
        return {
            cpf: { erro: false, msg: '' },
            cnpj: { erro: false, msg: '' },
            processo: { erro: false, msg: '' }
        }
    }
    function sair() {
        if (id) history.replace("/gerenciaProcesso/processo/" + id + "?panel=processo");
        else history.replace("/gerenciaProcesso/cadastrarProcesso/?panel=processo");
    }
    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade,
            programa: processo.programa

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }
    const handleChangeInputForm = (e) => {
        setProcesso({
            ...processo,
            [e.target.name]: e.target.value
        })
    }
    const handleChangeInputFormSituacao = (e) => {
        setProcesso({
            ...processo,
            situacao: {
                ...processo.situacao,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleChangeInputFormDadosEmpr = (e) => {
        setProcesso({
            ...processo,
            dadosEmpresa: {
                ...processo.dadosEmpresa,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleChangeInputFormDt = (date) => {
        setProcesso({
            ...processo,
            dataCriacao: date
        })
    }
    const disableSalvarBtn = () => {
        if (!processo || !processo.dadosEmpresa || !processo.dadosEmpresa.nProcesso) return true;
        if (processo.dadosEmpresa.nProcesso.trim().length != 22 && processo.dadosEmpresa.nProcesso.trim().length != 16) return true;
        return false;
    }

    const handleLostFocusProcesso = (e) => {
        setError({
            ...error,
            processo: {
                erro: false,
                msg: ''
            }
        });
        if (!processo || !processo.dadosEmpresa || !processo.dadosEmpresa.nProcesso) return;
        const proces = processo.dadosEmpresa.nProcesso.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (proces.length === 0) return;

        Service.POST('/api/gerenciarProcesso/findProcesso', processo.dadosEmpresa.nProcesso).then(json => {
            setWait(false);
            if (json.idProcesso !== id) {
                setError({
                    ...error,
                    processo: {
                        erro: true,
                        msg: 'Processo ja cadastrado'
                    }
                });
            }
        }).catch(error => {
            setWait(false);
            // setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
    }
    const buscaDadoEmpresa = () => {
        setShowBuscaDadoDialog(true);
        setWait(true);
        Service.GET("/api/empresa/").then(result => {
            setWait(false);
            let arr = [];
            for (let x in result) {
                const r = result[x];
                if (r.cnpj === "" || r.razaoSocial === null) continue;
                arr.push({
                    id: r.id,
                    cnpj: FormataCnpj(r.cnpj),
                    cnpjSemForma: r.cnpj,
                    razaoSocial: r.razaoSocial,
                    cpfRepr: r.cpfRepr,
                    cfdf: r.cfdf,
                    representante: r.representante,
                })
            }
            setListEmpresa(arr);
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function atualizaProcesso(emp) {
        setProcesso({
            ...processo,
            dadosEmpresa: {
                ...processo.dadosEmpresa,
                cnpj: emp.cnpj,
                razaoSocial: emp.razaoSocial,
                cpfResponsavel: emp.cpfRepr ? emp.cpfRepr : "",
                nomeResponsavel: emp.representante ? emp.representante : "",
                cfdf: emp.cfdf ? emp.cfdf : ""
            }
        });
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container>
                <Card style={{ marginTop: '5px' }}>
                    <CardHeader title="Dados Processo" />
                    <CardContent>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.nProcesso || ''}
                                    onChange={handleChangeInputFormDadosEmpr}
                                    onBlur={handleLostFocusProcesso}
                                    error={error.processo.erro}
                                    helperText={error.processo.msg}
                                    fullWidth
                                    label="Processo"
                                    id="processo"
                                    name="nProcesso"
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: processoMask,
                                    }}
                                    disabled={processoCadastrado}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataIndicacao"
                                    disabled
                                    label="Data criação"
                                    format="dd/MM/yyyy"
                                    value={processo.dataCriacao || null}
                                    onChange={handleChangeInputFormDt}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth disabled={false}>
                                    <InputLabel id="unidade" >Situação Status</InputLabel>
                                    <Select
                                        id="status"
                                        name='status'
                                        label="status"
                                        placceholder="status"
                                        onChange={handleChangeInputFormSituacao}
                                        value={processo && processo.situacao && processo.situacao.status || ''}
                                    >
                                        {
                                            status && status.map((tp, i) => (
                                                <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth disabled={false}>
                                    <InputLabel id="unidade" >Unidade</InputLabel>
                                    <Select
                                        id="unidade"
                                        name='unidade'
                                        label="Unidade"
                                        placceholder="Unidade"
                                        onChange={handleChangeInputForm}
                                        value={processo && processo.unidade || ''}
                                    >
                                        {unidades && unidades.map((tp, i) => (
                                            <MenuItem key={i} value={tp.id} >{tp.nome}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth disabled={false}>
                                    <InputLabel id="unidade" >Programa</InputLabel>
                                    <Select
                                        id="programa"
                                        name='programa'
                                        label="programa"
                                        placceholder="programa"
                                        onChange={handleChangeInputForm}
                                        value={processo && processo.programa || ''}
                                    >
                                        {
                                            programas && programas.map((tp, i) => (
                                                <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card style={{ marginTop: '5px' }}>
                    <CardHeader title="Dados empresa" />
                    <CardContent>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={3}>
                                <TextField
                                    fullWidth
                                    label="CNPJ"
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.cnpj || ''}
                                    name="cnpj"
                                    id="cnpj-mask-input"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title='Buscar Empresa'>
                                    <IconButton onClick={buscaDadoEmpresa} >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.razaoSocial || ''}
                                    fullWidth
                                    label="Razão Social"
                                    id="razaoSocial"
                                    name="razaoSocial"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.cpfResponsavel || ''}
                                    label="CPF Responsável"
                                    id="cpfResponsavel"
                                    name="cpfResponsavel"
                                    // id="cpf-mask-input"
                                    fullWidth
                                    InputProps={{
                                        readOnly: true
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.nomeResponsavel || ''}
                                    fullWidth
                                    label="Nome Responsável"
                                    id="nomeResponsavel"
                                    name="nomeResponsavel"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.cfdf || ''}
                                    fullWidth
                                    label="CF/DF"
                                    id="cfdf"
                                    name="cfdf"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {processo && processo.dadosEmpresa.nProcesso && 
                                <EtapaProcessoComp nProcesso={processo.dadosEmpresa.nProcesso} indice={1}/> }
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                        Sair
                    </Button>
                    <Button variant="contained" onClick={() => salvar()} color="primary" disabled={disableSalvarBtn()}>
                        Salvar
                    </Button>
                </Grid>
                <BuscaDadoDialog
                    open={showBuscaDadoDialog}
                    setOpen={setShowBuscaDadoDialog}
                    acao={atualizaProcesso}
                    colunas={[
                        { nome: 'CNPJ', campo: 'cnpj' },
                        { nome: 'Razão Social', campo: 'razaoSocial' },
                        { nome: 'CPF Representante', campo: 'cpfRepr' },
                        { nome: 'Representante', campo: 'representante' },
                    ]}
                    dados={listEmpresa} />
            </Container>
        </MuiPickersUtilsProvider>
    );
}
