import React, {useEffect} from 'react'
import {Grid} from '@material-ui/core'
import { Bar, Chart } from 'react-chartjs-2';
import { useStyles, FormataMoeda } from '../../../constants'

export default function VerticalIcms(props){
    const { titulo, data, moeda, proporcao } = props;
    const classes = useStyles();

    useEffect(()=>{
        Chart.pluginService.register({
            afterDraw: function (chartInstance, easing) {

                if (chartInstance.config.options.showDatapoints) {
                    var helpers = Chart.helpers;
                    var ctx = chartInstance.chart.ctx;
                    var fontColor = helpers.getValueOrDefault(chartInstance.config.options.showDatapoints.fontColor, chartInstance.config.options.defaultFontColor);
              
                    // render the value of the chart above the bar
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'normal', Chart.defaults.global.defaultFontFamily);
                    ctx.textAlign = 'center';
                    ctx.textBaseline = 'bottom';
                    ctx.fillStyle = fontColor;
              
                    chartInstance.data.datasets.forEach(function (dataset) {
                      for (var i = 0; i < dataset.data.length; i++) {
                        var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                        var scaleMax = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._yScale.maxHeight;
                        var yPos = (scaleMax - model.y) / scaleMax >= 0.93 ? model.y + 20 : model.y - 5;
                        ctx.fillText( FormataMoedaLocal(dataset.data[i]), model.x, yPos);
                      }
                    });
                  }
            }
          });
    }, [])

    function FormataMoedaLocal(a){
        if(moeda === false){
            return a;
        }
        return FormataMoeda(a);
    }

    const options = {
        responsive: true,
        maintainAspectRatio: proporcao? proporcao : false,
        showDatapoints:{
            defaultFontColor: Chart.defaults.global.defaultFontColor,
            fontColor: Chart.defaults.global.defaultFontColor
        },
        legend: {
            display: false
         },
         scales: {
            xAxes: [{
               gridLines: {
                  display: false
               }
            }],
            yAxes: [{
               gridLines: {
                  display: false
               },
               ticks: {
                    max: proporcao ? (Math.max(...data.datasets[0].data) <= 10) ? 10 : Math.max(...data.datasets[0].data) : (Math.max(...data.datasets[0].data) + 1) ,
                    display: true,
                    beginAtZero: true
                }
            }]
         },
         tooltips: {
            enabled: false
         }
    }


    return (
        <Grid container >
            <Grid item xs={12}>
                <Bar
                    data={data}
                    // width={100}
                    // height={200}
                    options={options}
                     />
            </Grid>
            <Grid item xs={12}>
                <h4 className={classes.paper}>{titulo}</h4>
            </Grid>
        </Grid>
    );
}