import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Collapse, Container, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, Typography, Paper, TextField }
  from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../server/UseCurrentUser";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  btns: {
    '& > *': {
      margin: theme.spacing(1)
    },
    display: 'flex'
  }
}));

let senhaForte = false;
let valorCampo = '';

export default function TrocarSenhaPanel() {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');
  let [user] = useCurrentUser();

  const handleAlteraNovaSenha = (event) => {
    valorCampo = event.target.value;
    validaDados();
  }

  function validaDados() {
    let retorno = true;
    senhaForte = false;
    if (!document.getElementById('nova').value || !document.getElementById('confirmar').value) {
      setOpen(false);
      setMsgErro("");
      retorno = false;
    } else {
      if (valorCampo !== document.getElementById('confirmar').value) {
        setOpen(true);
        setMsgErro("As senhas novas não coincidem.");
        retorno = false;
      } else if (!valorCampo.match(/^(?=.*\d)(?=.*[A-Za-z]).{8,200}$/)) {
        setOpen(true);
        setMsgErro("A senha precisa conter pelo menos 8 caracteres entre letras e números.");
        retorno = false;
      } else {
        senhaForte = true;
        setOpen(false);
        setMsgErro("");
      }
    }
    return retorno;
  }
  const handleAlteraConfirmacao = (event) => {
    if (!document.getElementById('nova').value || !document.getElementById('confirmar').value) {
      setOpen(false);
      setMsgErro("");
    } else {
      if (event.target.value !== document.getElementById('nova').value) {
        setOpen(true);
        setMsgErro("As senhas novas não coincidem.");
      } else if (!event.target.value.match(/^(?=.*\d)(?=.*[A-Za-z]).{8,200}$/)) {
        setOpen(true);
        setMsgErro("A senha precisa conter pelo menos 8 caracteres entre letras e números.");
      } else {
        setOpen(false);
        setMsgErro("");
      }
    }
  }

  const handleSubmit = (event) => {

    event.preventDefault();
    valorCampo = document.getElementById('nova').value;
    if (validaDados()) {
      fetch('/api/trocarsenha', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.accessToken}`
        },
        body: JSON.stringify({
          id: user.id,
          antiga: document.getElementById('atual').value,
          nova: document.getElementById('nova').value
        }),
        credentials: 'include'
      }).then(response => {
        if (response.ok && (response.status === 202)) {
          history.push('/home');
        } else {
          response.json().then((error) => {
            setOpen(true);
            setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
          });
        }
      }).catch(error => {
        setOpen(true);
        setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
      });
    } else {
      setMsgErro("Todos os campos são obrigatórios, a senha precisa ter no mínimo 8 caracteres entre letras e números e a nova senha deve coincidir com sua confirmação");
      setOpen(true);
    }
  }

  return (
    <Container maxWidth="sm">

      <div className={classes.root}>
        {/* {msgErro &&
          <Collapse in={open}>
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {msgErro}
            </Alert>
          </Collapse>} */}
        {
          msgErro && <Alert severity="error">{msgErro}</Alert>
        }
      </div>

      <form className={classes.root} noValidate autoComplete="off" action="/api/trocarsenha" method="post" onSubmit={handleSubmit} >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="body1" gutterBottom>
                    <center>Troca de senha</center>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Senha atual"
                    id="atual"
                    name="atual"
                    type="password"
                    aria-describedby="atual-helper-text"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nova senha"
                    id="nova"
                    name="nova"
                    type="password"
                    onChange={handleAlteraNovaSenha}
                    aria-describedby="nova-helper-text"
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Confirmar nova senha"
                    id="confirmar"
                    name="confirmar"
                    type="password"
                    onChange={handleAlteraConfirmacao}
                    aria-describedby="nova-helper-text"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.paper} >
                <Grid container justify="flex-end" className={classes.btns}>
                  <Button href="/home" variant="contained" color="secondary">
                    Sair
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleSubmit} type="submit">
                    Trocar
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
