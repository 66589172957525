import React, { useState } from 'react';
import { Container, Card, CardContent, Grid, TextField, Button } from '@material-ui/core';
import { useStyles } from '../../../constants';
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";
import Service from '../../../services/Service';
import { useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={4}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

export default function DetalharDadosAtestadoProcessoPanel(props) {
    const classes = useStyles();
    const { processo, setProcesso, gestaoProcesso, profile, setWait, setMensagem } = props;
    const [alteracao, setAlteracao] = useState(false);
    const location = useLocation();

    function isEmpresario() {
        if (location.pathname.includes("cadastrarProcesso")) return true;
        if (profile) {
            return profile.regras.find(r => r === '012') && true;
        }
        return false;
    }

    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            setAlteracao(false);
            // history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeAtestadoImplantacao = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            atestadoImplantacao: {
                ...processo.atestadoImplantacao,
                [e.target.name]: e.target.value
            }
        })
    }
    return (
        <Container>
            <Card style={{ marginTop: '5px' }}>
                <CardContent>
                    <Grid container spacing={3} className={classes.root}>
                        <Grid item xs={4}>
                            <TextField
                                value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.nProvisorio || ''}
                                fullWidth
                                label="Nº Provisório"
                                id="nProvisorio"
                                name="nProvisorio"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={!isEmpresario() ? 4 : 8}>
                            <TextField
                                value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.dataAip || ''}
                                fullWidth
                                label="Data AIP"
                                id="dataAip"
                                name="dataAip"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        {!isEmpresario() &&
                            <Grid item xs={4}>
                                <TextField
                                    value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.linkFolhaAip || ''}
                                    onChange={handleChangeAtestadoImplantacao}
                                    fullWidth
                                    label="Data AIP (Link/Folha)"
                                    id="linkFolhaAip"
                                    name="linkFolhaAip"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                        <Grid item xs={!isEmpresario() ? 3 : 4}>
                            <TextField
                                value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.nDefinitivo || ''}
                                fullWidth
                                label="Nº Definitivo"
                                id="nDefinitivo"
                                name="nDefinitivo"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={!isEmpresario() ? 2 : 5}>
                            <TextField
                                value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.dataAid || ''}
                                fullWidth
                                label="Data AID"
                                id="dataAid"
                                name="dataAid"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.descontoConcedido || ''}
                                fullWidth
                                label="Desconto Concedido (%)"
                                id="descontoConcedido"
                                name="descontoConcedido"
                                variant="outlined"
                                InputProps={{                                    
                                    inputComponent: MonetarioField
                                }}
                                disabled
                            />
                        </Grid>
                        {!isEmpresario() &&
                            <Grid item xs={4}>
                                <TextField
                                    value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.linkFolhaAid || ''}
                                    onChange={handleChangeAtestadoImplantacao}
                                    fullWidth
                                    label="Desconto concedido (Link/Folha)"
                                    id="linkFolhaAid"
                                    name="linkFolhaAid"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                         <Grid item xs={4}>
                            <TextField
                                value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.nDefinitivoDesenvolveDf || ''}
                                fullWidth
                                label="Nº Definitivo Desenvolve-DF"
                                id="nDefinitivoDesenvolveDf"
                                name="nDefinitivoDesenvolveDf"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={!isEmpresario() ? 4 : 8}>
                            <TextField
                                value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.dataAiddf || ''}
                                fullWidth
                                label="Data AIDDF"
                                id="dataAiddf"
                                name="dataAiddf"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        {!isEmpresario() &&
                            <Grid item xs={4}>
                                <TextField
                                    value={processo && processo.atestadoImplantacao && processo.atestadoImplantacao.linkFolhaAiddf || ''}
                                    onChange={handleChangeAtestadoImplantacao}
                                    fullWidth
                                    label="Data AIDDF (Link/Folha)"
                                    id="linkFolhaAiddf"
                                    name="linkFolhaAiddf"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                        {/* <Grid item xs={12}>
                            <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="atestado" />
                        </Grid> */}
                    </Grid>
                    {alteracao &&
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" onClick={() => salvar()} color="primary" >
                                Salvar
                            </Button>
                        </Grid>
                    }
                </CardContent>
            </Card>
        </Container>
    );
}
