export const StatusRequerimento=[
    {
        status:"EM_ANALISE",
        nome:"Em Analise"
    },
    {
        status:"RASCUNHO",
        nome: "Rascunho"
    },
    {
        status:"PENDENTE",
        nome: "Pendente"
    },
    {
        status:"REPROVADO",
        nome: "Reprovado"
    },
    {
        status:"DEFERIDO",
        nome: "Deferido"
    },
    {
        status:"INDEFERIDO",
        nome: "Indeferido"
    },
    {
        status:"ERRRO",
        nome: "Erro"
    },
]