import React, { useEffect, useState } from "react";
import { Container } from '@material-ui/core';
import { Backdrop, Grid, Paper, Typography, Collapse, IconButton } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
// import Alert from '@material-ui/lab/Alert';
import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import { useStyles } from "../constants";
// import PdfView from "../util/PdfView";

export default function ManualPanel() {
  const classes = useStyles();
  const match = useRouteMatch();
  // const [erro, setErro] = useState();
  // const [wait, setWait] = useState(false);
  const [titulo, setTitulo] = useState("Manual");
  const frame = document.getElementById("frame");
  const location = useLocation();
  // const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });


  useEffect(() => {
    if(location.pathname.includes("acesso")){
      setTitulo("Manual de Acesso");
    }else if(location.pathname.includes("sistema")){
      setTitulo("Manual do Sistema");
    }

  });

  // function setMensagem(alerta) {
  //   let a;
  //   if (typeof alerta === 'object') {
  //     a = { ...alerta };
  //     if (!a.tipo) a.tipo = 'success';
  //     if (!a.duracao) a.duracao = 5000;
  //     a.visibilidade = true;
  //   } else {
  //     a = {
  //       tipo: 'info',
  //       duracao: 5000,
  //       visibilidade: true,
  //       mensagem: alerta
  //     }
  //   }

  //   setAlerta(a);
  //   if (!!a.duracao && a.duracao > 0) {
  //     window.setTimeout(() => {
  //       setAlerta({ tipo: a.tipo, visibilidade: false });
  //     }, a.duracao)
  //   }
  // }

  return (
    <Container maxWidth="xl">

      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
            {/* <Collapse in={alerta.visibilidade}>
              <Alert severity={alerta.tipo} action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlerta({ visibilidade: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>{alerta.mensagem}</Alert>
            </Collapse> */}
          </Paper>

          {
            // erro && <Alert severity="error">{erro}</Alert>
          }
        </Grid>

        <Grid item sm={12}>
          <Switch>
            <Route path={`${match.path}/acesso`}>
            <iframe id="frame"  width= "100%" height= "600px" src="/Manual_de_Acesso.pdf" />
              {/* <PdfView setTitulo={setTitulo} setWait={setWait} titulo={"Manual de Acesso"}
                url={"/Manual_de_Acesso.pdf"}
              /> */}
            </Route>
            <Route path={`${match.path}/sistema`}>
            <iframe id="frame"  width= "100%" height= "600px" src="/Manual_Do_Sistema.pdf" />
              {/* <PdfView setTitulo={setTitulo} setWait={setWait} titulo={"Manual do Sistema"}
                url={"/Manual_Do_Sistema.pdf"}
              /> */}
            </Route>
            <Route path={match.path}>
              <h2>nao implementado</h2>
            </Route>
          </Switch>
        </Grid>
      </Grid>
      {/* <Backdrop className={classes.backdrop} open={wait}>
        <CircularProgress color="inherit" />
      </Backdrop> */}

    </Container>
  )
}