import React, { useEffect, useState } from 'react';
import {
  Box, Grid, IconButton, Button, Paper, Menu, MenuItem,
  Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip, Typography
} from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { useStyles, getQueryParams } from "../../constants";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";

import icon from 'leaflet/dist/images/marker-icon.png';
 import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconAnchor:   [19, 46],
  popupAnchor:  [-6, -47]

});

L.Marker.prototype.options.icon = DefaultIcon;

export default function LoteMapaView(props) {
  const classes = useStyles();
  const { setErro, setTitulo, setWait } = props;
  const [localidade, setLocalidade] = useState();

  const param = getQueryParams(useLocation().search);

  useEffect(() => {
    setTitulo("Mapa Lote")
    const id = param.get("id");
    setWait(true);
    fetch('/api/processoProdf/lote/' + id).then(response => {
      if (response.ok) {
        response.json().then((json) => {
          setWait(false);

          if (json.cnpj && json.cnpj.length > 0) {
            buscaEmpresa(json.cnpj).then(e => {
              setLocalidade({
                lat: json.latitude ? json.latitude : -15.791216,
                lng: json.longitude ? json.longitude : -47.881952,
                area: json.area,
                situacao: json.situacao,
                proposta: json.proposta,
                cnpj: json.cnpj,
                razaoSocial: e ? e.razaoSocial : "",
                ra: json.ra,
                ade: json.ade,
                endereco: json.endereco

              });
            });
          }

          setLocalidade({
            lat: json.latitude ? json.latitude : -15.791216,
            lng: json.longitude ? json.longitude : -47.881952,
            area: json.area,
            situacao: json.situacao,
            proposta: json.proposta,
            cnpj: json.cnpj,
            razaoSocial: "",
            ra: json.ra,
            ade: json.ade,
            endereco: json.endereco
          })

        });
      } else {
        response.json().then((error) => {
          setWait(false);
          setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
      }
    }).catch(error => {
      setWait(false);
      setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });


  }, []);


  async function buscaEmpresa(cnpjParm) {
    const cnpj = cnpjParm.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();

    const res = await fetch("/api/v1/cnpj/" + cnpj);
    if (res.ok) {
      const ret = await res.json();
      return ret;
    }
  }

  function openPopup(marker) {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup()
      })
    }
  }

  function formataDigito(valor){
    if( valor == null ) return '';
    return valor.toLocaleString('pt-br',{minimumFractionDigits: 2});
}
  return (
    <Grid>
      {localidade &&
        <Map style={{
          height: '60vh',
          position: 'center',
          borderRadius: '15px',
        }} center={[localidade.lat, localidade.lng]} zoom={13}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={[localidade.lat, localidade.lng]} ref={openPopup} icon={DefaultIcon}>
            <Popup>
              <p>
                <b>Área:</b> {formataDigito(localidade.area)}<br />
                <b>Situação:</b> {localidade.situacao}<br />
                <b>Proposta:</b> {localidade.proposta} <br />
                <b>CNPJ:</b> {localidade.cnpj}<br />
                <b>Empresa:</b> {localidade.razaoSocial}<br />
                <b>RA:</b> {localidade.ra}<br />
                <b>ADE:</b> {localidade.ade}<br />
                <b>Endereço:</b> {localidade.endereco}<br />
              </p>
            </Popup>
          </Marker>
        </Map>
      }
      <Grid container justify="flex-end" className={classes.btns}>
        <Button variant="contained" color="secondary" href={"/operacao/lotes"} rel="noopener noreferrer">
          Sair
        </Button>


      </Grid>

    </Grid>


  );
}

