import React, { useEffect, useState, useRef } from 'react';
import {
    Grid, Button, Paper, Accordion, AccordionSummary, Typography, AccordionDetails, TextField, FormControl, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, InputAdornment,
} from '@material-ui/core';
import GenericTable from "../../template/GenericTable";
import { FormataDataComHora, useStyles } from "../../../constants";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router';
import { ValidarCNPJ } from '../../../constants';
import MaskedInput from 'react-text-mask';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useParams, useLocation } from "react-router-dom";
import NumberFormat from 'react-number-format';
import { ptBR } from 'date-fns/locale';

function CNPJMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

const setor = ["Atacadista", "Industrial", "Outros"]
const ra = ["ÁGUAS CLARAS", "BRASÍLIA", "BRAZLÂNDIA", "CANDANGOLÂNDIA", "CEILÂNDIA", "CRUZEIRO", "GAMA", "GUARÁ", "NÚCLEO BANDEIRANTE", "PLANALTINA", "RECANTO DAS EMAS", "RIACHO FUNDO", "S I A", "SAMAMBAIA", "SANTA MARIA", "SÃO SEBASTIÃO", "SCIA", "SOBRADINHO", "TAGUATINGA"]

export default function PlanejamentoForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro, disable } = props;
    const location = useLocation();
    const history = useHistory();
    const [error, setError] = React.useState(resetErro());
    let { projeto } = useParams();
    let { id } = useParams();
    const [values, setValues] = React.useState({    
        exercicio: '',
        processo: '',
        programa: '',
        cnpj: '',
        razaoSocial: '',
        isMatriz: '',
        ra: '',
        setor: '',
        valor: '',
    });

    useEffect(() => {
        if (location.pathname === '/fundefe/PlanejamentoForm/PRODF') {
            setTitulo("Cadastro de previsões Orçamentaria - FUNDEFE - PRODF");
        } else if (location.pathname === '/fundefe/PlanejamentoForm/FIDE') {
            setTitulo("Cadastro de previsões Orçamentaria - FUNDEFE - FIDE");
        } else if (location.pathname === '/fundefe/PlanejamentoForm/IDEAS') {
            setTitulo("Cadastro de previsões Orçamentaria - FUNDEFE - IDEAS");
        }else{
            setTitulo("Editar previsão Orçamentaria - FUNDADE")
        }
        buscaPlanejamento()
    }, [location]);

    function buscaPlanejamento() {
        fetch('/api/fundefePrevisao/' + id)
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            setValues({
                                ...json,
                                isMatriz: json.isMatriz === true ? "matriz": "filial"
                            });
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    

    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    }
    
    function resetErro() {
        return {
            cnpj: {
                erro: false,
                msg: ''
            },
        }
    }

    const handleLostFocusCNPJ = (e) => {
        setError({
            ...error,
            cnpj: {
                erro: false,
                msg: ''
            }
        });
        const cnpj = values.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (cnpj.length === 0) return;
        if (ValidarCNPJ(cnpj)) {
            fetch("/api/v1/cnpj/" + cnpj)
                .then(res => (res.status <= 300) ? res.json() : setErro(res))
                .then(
                    (result) => {
                        if (!result) return;
                        setValues({
                            ...values, razaoSocial: result.razaoSocial
                        });
                    },
                )
        } else {
            setError({
                ...error,
                cnpj: {
                    erro: true,
                    msg: 'CNPJ inválido'
                }
            });
        }
    }

    function transformaDt(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }

    function chamarPlanegamentoForm() {
        if (location.pathname === '/fundefe/PlanejamentoForm/FIDE') {
            history.push('/fundefe/previsaoLista/FIDE')
        } else if (location.pathname === '/fundefe/PlanejamentoForm/PRODF') {
            history.push('/fundefe/previsaoLista/PRODF')
        } else if (location.pathname === '/fundefe/PlanejamentoForm/IDEAS') {
            history.push('/fundefe/previsaoLista/IDEAS')
        }else if(values.programa === "FIDE"){
            history.push('/fundefe/previsaoLista/FIDE')
        }else if(values.programa === "PRODF"){
            history.push('/fundefe/previsaoLista/PRODF')
        }else if(values.programa === "IDEAS"){
            history.push('/fundefe/previsaoLista/IDEAS')
        }

    }

    function confirmaAprovacao() {
        setError(resetErro());
        let dado = { ...values };
        if (dado.isMatriz === "matriz") {
            dado.isMatriz = true;
        } else {
            dado.isMatriz = false;
        };
        dado.programa = projeto || values.programa;
        if(dado.valor && typeof dado.valor==="string"){
            let val = dado.valor.replace(/[.*+\-?^${}()|[\]\\]/g, '');
        dado.valor = val.replace(",", ".");
        }
        submitForm(dado);
    }

    function submitForm(dado) {
        setWait(true);
        fetch('/api/fundefePrevisao', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                response.json().then(json => {
                    chamarPlanegamentoForm();
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function podeEnviar() {
        if (values.cnpj.length === 0) return true;
        if (values.exercicio.length === 0) return true;
        if (values.processo.length === 0) return true;
        if (values.valor.length === 0) return true;
        if (values.isMatriz.length === 0) return true;
        if (values.ra.length === 0) return true;
        if (values.setor.length === 0) return true;
        return false;
    }

   


    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Paper >
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Informações Beneficiários</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} >
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    disabled={disable}
                                    type={"number"}
                                    label="Exercícios"
                                    value={values.exercicio || ''}
                                    onChange={handleChangeInputForm}
                                    name="exercicio"
                                    id="exercicio"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    disabled={disable}
                                    label="Processo"
                                    value={values.processo || ''}
                                    onChange={handleChangeInputForm}
                                    name="processo"
                                    id="processo"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    disabled={disable}
                                    label="programa"
                                    disabled
                                    value={projeto || values.programa   }
                                    onChange={handleChangeInputForm}
                                    name="programa"
                                    id="programa"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    label="CNPJ"
                                    disabled={disable}
                                    error={error.cnpj.erro}
                                    helperText={error.cnpj.msg}
                                    value={values.cnpj}
                                    onChange={handleChangeInputForm}
                                    onBlur={handleLostFocusCNPJ}
                                    name="cnpj"
                                    id="cnpj-mask-input"
                                    variant="outlined"
                                    InputProps={{
                                        inputComponent: CNPJMask
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    disabled={true}
                                    label="Razão social"
                                    value={values.razaoSocial || ''}
                                    onChange={handleChangeInputForm}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    name="razaoSocial"
                                    id="nome-empresa-input"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl component="fieldset">
                                    <RadioGroup row value={values.isMatriz || ''} aria-label="isMatriz"  onChange={handleChangeInputForm}  name="isMatriz" defaultValue="top">
                                        <FormControlLabel
                                            value="matriz"
                                            control={<Radio disabled={disable} color="primary" />}
                                            label="Matriz"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="filial"
                                            control={<Radio disabled={disable} color="primary" />}
                                            label="Filial"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="tipo" >RA</InputLabel>
                                    <Select
                                        id="ra"
                                        name='ra'
                                        disabled={disable}
                                        label="ra"
                                        placceholder="ra"
                                        onChange={handleChangeInputForm}
                                        value={values.ra || ''} >
                                        {
                                            ra && ra.map((tp, i) => (
                                                <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="tipo" >Setor</InputLabel>
                                    <Select
                                        id="setor"
                                        name='setor'
                                        disabled={disable}
                                        label="setor"
                                        placceholder="setor"
                                        onChange={handleChangeInputForm}
                                        value={values.setor || ''} >
                                        {
                                            setor && setor.map((tp, i) => (
                                                <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Valores orçamentários planejados</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} >
                            <TextField
                                fullWidth
                                id="valor"
                                disabled={disable}
                                name="valor"
                                label="Valor Total(R$):"
                                value={values.valor || ''}
                                onChange={handleChangeInputForm}
                                variant="outlined"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                    inputComponent: MonetarioField
                                }}
                            />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Historico</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} >
                            <GenericTable
                                disableSearch
                                options={['Visualizar', 'Alterar', 'Excluir']}
                                dados={values.historico}
                                colunas={[
                                    { nome: 'Data', campo: 'data', transformacao: transformaDt },
                                    { nome: 'Responsavel', campo: 'responsavel.nome' },
                                    { nome: 'Ação', campo: 'acao', },
                                    { nome: 'Observação', campo: 'texto' }
                                ]} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>

                <Grid container spacing={1} >
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={chamarPlanegamentoForm} rel="noopener noreferrer">
                            Sair
                    </Button>
                        {!disable && 
                            <Button variant="contained" color="primary" onClick={() => confirmaAprovacao()} disabled={podeEnviar()} >
                                Salvar
                        </Button>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </MuiPickersUtilsProvider>

    );
}
