import React, { useEffect, useState } from 'react';
import {
    Grid, Button, TextField,
} from '@material-ui/core';
import { useStyles, FormataDataComHora  } from "../../../constants";
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';


export default function HistoricoView(props) {
    const { open, setOpen, cnpj, historico } = props;
    const classes = useStyles();
    const [values, setValues] = useState({});

  
    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };
    useEffect(()=>{
        setValues( historico );
    }, [historico])
  
   

    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Istrumento de modificação</DialogTitle>
            <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
                    <TextField
                        disabled
                        valor={values.responsavel|| ''}
                        fullWidth
                        label="Responsável"
                        id="responsavel"
                        name="responsavel"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        valor={values.protocolo|| ''}
                        fullWidth
                        label="Protocolo"
                        id="protocolo"
                        name="protocolo"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        value={values.assunto || ''}
                        fullWidth
                        label="Assunto"
                        id="assunto"
                        name="assunto"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        value={values.instrumento || ''}
                        fullWidth
                        label="Instrumento"
                        id="instrumento"
                        name="instrumento"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        value={values.dodf || ''}
                        fullWidth
                        label="Número DODF"
                        id="dodf"
                        name="dodf"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        value={values.data ? FormataDataComHora(new Date(values.data)) : ''}
                        fullWidth
                        label="Data"
                        id="data"
                        name="data"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        
                        value={values.dataPublicacao ? FormataDataComHora(new Date(values.dataPublicacao)) : ''}
                        fullWidth
                        label="Data publicação"
                        id="dataPublicação"
                        name="dataPublicação"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        value={values.texto || ''}
                        fullWidth
                        label="Observação"
                        id="observacao"
                        name="observacao"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        disabled
                        value={values.descricao || ''}
                        fullWidth
                        label="Descrição"
                        id="descricao"
                        name="descricao"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"

                    />
                </Grid>
            </Grid>
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" onClick={() => setOpen(false)} rel="noopener noreferrer">
                    Sair
                    </Button>
                
            </Grid>
        </Dialog>
    );
}