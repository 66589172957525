import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Paper, Menu, MenuItem,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CampoBusca from "../../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import {useStyles, FormataDataComHora,deParaTipoHitorico} from "../../../constants";
import { useUnidades } from "../../../server/UseUnidades";
import Service from '../../../services/Service';

const options = [
    'AcaoA',
    'AcaoB'
  ];

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;

export default function HistoricoTable(props) {
    const classes = useStyles();
    const { dados, protocolo, setWait, setMensagem } = props;

    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);
    const [unidades] = useUnidades();
    const [dadosNovo, setDadosNovo] = useState();
    const [integracaoSei, setIntegracaoSei] = useState();

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    useEffect(() => {
        Service.GET("/api/processoProdf/requerimento/buscaIntegracaoSei/" + protocolo).then(result => {
            setWait(false);
            if (result[0].sucesso === true){
                setIntegracaoSei(result[0]);
            }
            // setProtocolo(result);
        }).catch(error => {
            setWait(false);
            if(error.message == 'Cannot read properties of undefined (reading \'sucesso\')') {
                console.log(  error, "test: ",error.message )
            }else{
                setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
            }
            
        });
    }, []);

    useEffect(() => {
        let arr = dados;
        if(integracaoSei){
            arr.push({
                data: integracaoSei.dataIntegracao,
                responsavel:{nome: "Sistema"},
                tipo: "Requerimento vinculado ao SEI",
                texto: integracaoSei.retornoSei ? integracaoSei.retornoSei.procedimentoFormatado : ""
            })
        }
        setDadosNovo(arr)
        fonteDeDados = arr;
        setDadosFiltrado(arr);
    }, [dados, integracaoSei]);

    const handleAcao = (e, index) => {
        indexProcesso = index;
        setAnchorEl(e.currentTarget);
    }
    
    const handleActionClick = (action) => {
        let processo = fonteDeDados[indexProcesso];
        let acao = options[action];

        setAnchorEl(null);
      };
    const handleClose=()=>{
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
          disparaSearch();
        }
        elapseTime = performance.now();
      }
    
      function disparaSearch() {
        setTimeout(function () {
          if (performance.now() - elapseTime > tempo) {
            setBloco(0);
            filtraDado(filtroCampo);
          } else {
            disparaSearch();
          }
        }, tempo);
      }

    useEffect(() => {
        fonteDeDados = dadosNovo;
        filtraDado();
    }, [ordem, pagina, bloco]);

    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];

        let arrOrdenado = ordenar(fonteDeDados);
        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                let campos = filtroCampo.split(' ');
                for( let x in d ){
                    for (var y in campos) {
                        if( typeof d[x] === 'string'){
                            if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                arr.push(d);
                                break;
                            }    
                        }else if( d[x] == campos[y]){
                            arr.push(d);
                                break;
                        }
                    }
                }
            } else {
                arr.push(d);
            }
        });
        setDadosFiltrado(arr);
    }
    
    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }
        return ordenado;
    }
    function showResponsavel(row){
        if( row.responsavel ){
            return row.responsavel.nome
        }else{
            return "";
        }
    }
    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
        
        setBloco(0);
    }
    function formataTipo(row){
        return deParaTipoHitorico(row.tipo);
    }
    function montaTxt(row){
        if( row.tipo === 'unidade' && unidades){
            const id = parseInt( row.texto);
            let u = unidades.find(un => un.id === id);
            return "Vinculado a unidade: "+u.nome;
        }
        return row.texto?row.texto:''
    }

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'data'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('data')}
                                    >
                                        Data
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'responsavel'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('responsavel')}
                                    >
                                        Responsável
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'tipo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('tipo')}
                                    >
                                        Ação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'terceiro'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('terceiro')}
                                    >
                                        Observação
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell >{FormataDataComHora( new Date(row.data))}</TableCell>
                                    <TableCell>{ showResponsavel(row) }</TableCell>
                                    <TableCell>{ formataTipo(row) }</TableCell>
                                    <TableCell>
                                        <span dangerouslySetInnerHTML={{
                                            __html: montaTxt(row)
                                        }}></span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openAction}
                onClose={handleClose}
            >
                {options.map((option, index) => (
                <MenuItem key={option} selected={option === 'Pyxis'} onClick={()=>handleActionClick(index)}>
                    {option}
                </MenuItem>
                ))}
            </Menu>
        </Grid>


    );
}
