import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { FormataDataComHora } from '../../constants/';
import GenericTable from "../template/GenericTable";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(2),
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,

      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
  }),
);
let acessoFonte = null;

export default function AutorizarAcessoPanel() {
  const classes = useStyles();
  const [dados, setDados] = React.useState();
  const [erro, setErro] = useState();
  const [fonteDeDados, setFonteDeDados] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/api/solicitarAcesso/all', {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      });
      const data = await response.json();
      if (data.status > 300) {
        setErro(data);
        return;
      } else {
        acessoFonte = data.sort((a, b) => ((a.historico && b.historico && a.historico[0].data < b.historico[0].data) ? 1 : -1));
        let fonteDeDados = [];
        for (let x in data) {
          const d = data[x];
          fonteDeDados.push(
            {
              id: d.id,
              dataSolicitacao: dataSolicitacao(d),
              cpf: d.solicitante.cpf,
              nome: d.solicitante.nome,
              cnpj: d.empresa.cnpj,
              nomeEmpresa: d.empresa.nomeEmpresa,
              dataValidacaoUNATE: historico(d),
              situacao: showStatus(d.status)
            }
          );
        }
        setFonteDeDados(fonteDeDados);
      }
    }
    if (!dados) {
      fetchData();
    }
  }, [dados]);

  function dataSolicitacao(solicitacao) {
    if (solicitacao.historico) {
      const dado = solicitacao.historico[0].data;
      if (typeof dado === 'string') {
        return FormataDataComHora(new Date(dado))
      }
      return FormataDataComHora(dado);
    }
    return "pendente de envio";
  }
  function showStatus(status) {
    if (status === 'APROVADO') {
      return "Aprovado";
    } else if (status === 'USER_NOTIFICADO_APROVADO') {
      return "Aprovado";
    } else if (status === 'REPROVADO') {
      return "Reprovado";
    } else if (status === 'USER_NOTIFICADO_REPROVACAO') {
      return "Reprovado";
    }
    return "Em Análise"
  }
  function historico(solicitacao) {
    if (solicitacao.historico && solicitacao.status !== 'ABERTO') {
      const dado = solicitacao.historico[solicitacao.historico.length - 1].data;
      if (typeof dado === 'string') {
        return FormataDataComHora(new Date(dado))
      }
      return FormataDataComHora(dado);
    }
    return "";
  }

  function isReadOnly() {
    return false;
  }
  const handleActionSolicitarClick = (index, acao, dado) => {
    if(acao === 'Analisar'){
      history.replace("/home/solicitarAcesso/"+ dado.id);
    }else if(acao === 'Visualizar'){
      history.replace("/home/solicitarAcesso/"+ dado.id);
    }else{
      console.error(" acao :", acao)
    }
  }
  const desabilitaAcaoAtenderTable = (acao, dado) => {
    if (dado && acao === 'Analisar') {
      return (dado.situacao === "Aprovado" || dado.situacao === "Reprovado") ? true : false;
    }else if (dado && acao === 'Visualizar') {
      return dado.situacao === "Em Análise" ? true : false;
    }
    return false;
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      {
        erro && <Alert severity="error">{erro.statusText || erro.error}</Alert>
      }
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom><center>Solicitações de acesso</center></Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <GenericTable
            actionClick={handleActionSolicitarClick}
            options={['Analisar', 'Visualizar']}
            handleDisableAction={desabilitaAcaoAtenderTable}
            dados={fonteDeDados}
            readOnly={isReadOnly()}
            colunas={[
              { nome: 'Data solicitação', campo: 'dataSolicitacao' },
              { nome: 'CPF', campo: 'cpf' },
              { nome: 'Nome', campo: 'nome' },
              { nome: 'CNPJ', campo: 'cnpj' },
              { nome: 'Razão social', campo: 'nomeEmpresa' },
              { nome: 'Data validação UNATE', campo: 'dataValidacaoUNATE' },
              { nome: 'Situação', campo: 'situacao' },
              { nome: 'Ação', campo: 'action' }
            ]} />
        </Grid>
      </Grid>
    </Container>
  )
};