import React, { useEffect, useState } from "react";
import { Container } from '@material-ui/core';
import { Backdrop, Grid, Paper, Typography, Collapse, IconButton, Button, ButtonGroup } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { useStyles, getQueryParams } from "../../../constants";
import CloseIcon from '@material-ui/icons/Close';
import UnidadeDiagram from "./UnidadeDiagram";
import UnidadeForm from "./UnidadeForm";
import { Switch, Route, useRouteMatch, useLocation, useHistory } from "react-router-dom";


export default function UnidadePanel() {
    const classes = useStyles();
    const match = useRouteMatch();
    const [wait, setWait] = useState(false);
    const param = getQueryParams(useLocation().search);
    const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });
    const [unidade, setUnidade] = useState();
    const [titulo, setTitulo] = useState("Manutenção de Unidade");
    const history = useHistory();
    
    useEffect(()=>{
      buscaUnidades();
    },[])

    function buscaUnidades(){
      fetch('/api/unidade')
      .then(response => response.json())
      .then(json => setUnidade(json))
      .catch(erro => setMensagem(erro));
    }

    function setMensagem(alerta) {
      let a;
      if (typeof alerta === 'object') {
        a = { ...alerta };
        if (!a.tipo) a.tipo = 'success';
        if (!a.duracao) a.duracao = 5000;
        a.visibilidade = true;
      } else {
        a = {
          tipo: 'info',
          duracao: 5000,
          visibilidade: true,
          mensagem: alerta
        }
      }
  
      setAlerta(a);
      if (!!a.duracao && a.duracao > 0) {
        window.setTimeout(() => {
          setAlerta({ tipo: a.tipo, visibilidade: false });
        }, a.duracao)
      }
    }
    return (
        <Container maxWidth="xl">
    
          <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
                <Collapse in={alerta.visibilidade}>
                  <Alert severity={alerta.tipo} action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlerta({ visibilidade: false });
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }>{alerta.mensagem}</Alert>
                </Collapse>
              </Paper>
            </Grid>
            
            
            <Grid item sm={12}>
              <Switch>
                <Route path={`${match.path}/view`}>
                  {unidade && 
                    <UnidadeDiagram dado={unidade} setMensagem={setMensagem}/>
                  }
                </Route>
              </Switch>
              <Switch>
                <Route path={`${match.path}/novaUnidade`}>
                  <UnidadeForm setTitulo={setTitulo} unidades={unidade} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/editarUnidade`}>
                  <UnidadeForm setTitulo={setTitulo} unidades={unidade} setMensagem={setMensagem} setWait={setWait}/>
                </Route>
                <Route path={`${match.path}/excluirUnidade`}>
                  <UnidadeForm setTitulo={setTitulo} unidades={unidade} setMensagem={setMensagem} setWait={setWait} disable={true}/>
                </Route>
              </Switch> 
            </Grid>
          </Grid>
          <Backdrop className={classes.backdrop} open={wait}>
            <CircularProgress color="inherit" />
          </Backdrop>
    
        </Container>
      )
    }