import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, Paper, Typography, Button, TextField,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell,
    Accordion, AccordionSummary, AccordionDetails, FormControl, Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import { useStyles } from "../../../constants";
import { useLocation } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import CampoBusca from '../../../util/CampoBusca';

const useStylesPagina = makeStyles((theme) => ({
    paper: {
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

let fonteDeDados = null;
let qtd = 0;
let elapseTime = 0;
let tempo = 600;
let menor = 0;
let maior = 0;
let oldPath = null;
let filtroCampo = null
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function EmitirRelatorioForm(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setMensagem, setErro } = props;
    const location = useLocation();
    const classesPagina = useStylesPagina();
    const [bloco, setBloco] = useState(0);
    const [situacaoSigafe, setSituacaoSigafe] = useState();
    const [uos, setUos] = useState();
    const [programa, setPrograma] = useState();
    const [raAde, setRaAde] = useState();
    const [selected, setSelected] = React.useState([]);

    const [values, setValues] = useState({
        areaMenor: 0,
        areaMaior: 0,
    });
    const [error, setError] = useState({
        areaMenor: { erro: false, msg: '' },
        areaMaior: { erro: false, msg: '' },
    })

    const [checkedRA, setCheckedRA] = React.useState([]);
    const [checkedADE, setCheckedADE] = React.useState([]);
    const [checkedSigafe, setCheckedSigafe] = React.useState([]);
    const [checkedSituacao, setCheckedSituacao] = React.useState([]);
    const [checkedPrograma, setCheckedPrograma] = React.useState([]);

    const [valida, setValida] = React.useState(true);

    const [raDisponiveis, serRaDisponiveis] = React.useState([]);
    const [raSelecionadas, setRaSelecionadas] = React.useState([]);
    const [adeDisponiveis, serAdeDisponiveis] = React.useState([]);
    const [adeSelecionadas, setAdeSelecionadas] = React.useState([]);
    const [sigafeDisponiveis, serSigafeDisponiveis] = React.useState(situacaoSigafe);
    const [sigafeSelecionadas, setSigafeSelecionadas] = React.useState([]);
    const [situacaoDisponiveis, setSituacaoDisponiveis] = React.useState([]);
    const [situacaoSelecionadas, setSituacaoSelecionadas] = React.useState([]);
    const [programaDisponiveis, setProgramaDisponiveis] = React.useState([]);
    const [programaSelecionadas, setProgramaSelecionadas] = React.useState([]);
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);

    const raDisponiveisChecked = intersection(checkedRA, raDisponiveis);
    const raSelecionadasChecked = intersection(checkedRA, raSelecionadas);
    const adeDisponiveisChecked = intersection(checkedADE, adeDisponiveis);
    const adeSelecionadasChecked = intersection(checkedADE, adeSelecionadas);
    const sigafeDisponiveisChecked = intersection(checkedSigafe, sigafeDisponiveis);
    const sigafeSelecionadasChecked = intersection(checkedSigafe, sigafeSelecionadas);
    const situacaoDisponiveisChecked = intersection(checkedSituacao, situacaoDisponiveis);
    const situacaoSelecionadasChecked = intersection(checkedSituacao, situacaoSelecionadas);
    const programaDisponiveisChecked = intersection(checkedPrograma, programaDisponiveis);
    const programaSelecionadasChecked = intersection(checkedPrograma, programaSelecionadas);

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    const [valueRadioGroup, setValueRadioGroup] = React.useState('grupoArea');
    const handleChangeRadioGroup = (event) => {
        setValueRadioGroup(event.target.value);
    };

    useEffect(() => {
        atualizaStatus();
    }, []);
    useEffect(() => {
        if (location.pathname != oldPath) {
            atualizaStatus();
        }
    });
    useEffect(() => {
        handleSearch();
    }, [raSelecionadas, adeSelecionadas, situacaoSelecionadas]);

    useEffect(() => {
        filtraDado();
    }, [ordem, pagina, bloco]);
    function formataArea(val) {
        if (!val) return '';
        try {
            const num = parseFloat(val);
            return num.toLocaleString('pt-br', { minimumFractionDigits: 2 });
        } catch (e) {
            console.error(e);
            return "";
        }
    }
    function validaSituacao() {
        let dado = {
            grupo: {
                ade: document.getElementById("grupoADE").checked,
                ra: document.getElementById("grupoRA").checked,
                df: document.getElementById("grupoDF").checked
            },
        }
        if (dado.grupo.ade === false && dado.grupo.ra === false && dado.grupo.df === false) {
            setValida(true)
        } else {
            setValida(false)
        }
    }

    function validarArea() {
        if (values.areaMaior > 0) {
            return false
        } else {
            return true
        }
    }

    function atualizaStatus() {
        menor = 0;
        maior = 0;
        setWait(true);
        fonteDeDados = [];
        oldPath = location.pathname;
        setAdeSelecionadas([]);
        setRaSelecionadas([]);
        setSigafeSelecionadas([]);
        setSituacaoSelecionadas([]);
        serAdeDisponiveis([]);
        setValues({
            areaMenor: menor,
            areaMaior: maior,
        });
        if (location.pathname.includes("relatorioEstoqueFrom")) {
            setTitulo("Estoque Lote");
        } else if (location.pathname.includes("relatorioListagemFrom")) {
            setTitulo("Listagem de Lotes");
        } else if (location.pathname.includes("relatorioFichaFrom")) {
            setTitulo("Ficha de Imóvel");
        } else {
            setTitulo("Erro");
        }
        buscaRaAde();
        buscarSituacao();
        buscarSituacaoSigafe();
        buscarPrograma();
        buscarUos();
        buscaLotes();

    }
    function buscaLotes() {

        fetch("/api/processoProdf/lote"
        ).then(res => res.json()).then((result) => {
            setWait(false);
            if (result.status && result.status > 300) {
                setErro(result);
            } else {
                fonteDeDados = result;
                filtraDado();
            }
        });
    }
    const handleSearch = () => {
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
    }
    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
                setSelected([]);
            } else {
                disparaSearch();
            }
        }, tempo);
    }

    const handleBuscar = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
    }


    function isInArea(row) {
        let ret = true;
        try {
            if (menor > 0) {
                if (parseInt(menor) > parseInt(row.area)) {
                    ret = false;
                }
            }
            if (maior > 0) {
                if (parseInt(maior) < parseInt(row.area)) {
                    ret = false;
                }
            }
        } catch (e) {
            console.error(e);
            return false;
        }
        return ret;
    }
    function montaArrFiltro() {
        if (!fonteDeDados) return;
        let arr = [];
        let arrOrdenado = ordenar(fonteDeDados);

        for (let x in arrOrdenado) {
            const d = arrOrdenado[x];
            if (!isInArea(d)) continue;

            if (raSelecionadas && raSelecionadas.length > 0) {
                const item = raSelecionadas.find(ra => ra === d.ra)
                if (!item) {
                    continue;
                }
            }
            if (adeSelecionadas && adeSelecionadas.length > 0) {
                const item = adeSelecionadas.find(ade => ade === d.ade);
                if (!item) continue;
            }
            if (situacaoSelecionadas && situacaoSelecionadas.length > 0) {
                const item = situacaoSelecionadas.find(st => st === d.situacao);
                if (!item) continue;
            }
            if (sigafeSelecionadas && sigafeSelecionadas.length > 0) {
                const item = sigafeSelecionadas.find(st => st === d.situacaoSigafe);
                if (!item) continue;
            }
            if (programaSelecionadas && programaSelecionadas.length > 0) {
                const item = programaSelecionadas.find(st => st === d.programa);
                if (!item) continue;
            }
            arr.push(d);
        }
        return arr;
    }

    // function filtraDado() {
    //     let arr = montaArrFiltro();
    //     if (!arr) return;
    //     let arrBloco = [];
    //     qtd = arr.length;

    //     let x = 0;
    //     let index = 0;
    //     for (x = (bloco * pagina); x < arr.length; x++) {
    //         let d = arr[x];
    //         arrBloco.push(d);
    //         if (index++ > (pagina - 2)) break;
    //     }
    //     setDadosFiltrado(arrBloco);
    // }

    function filtraDado() {
        if (!fonteDeDados) return;

        let arr = [];
        let arrPreFiltro = montaArrFiltro();

        let arrOrdenado = ordenar(arrPreFiltro);


        arrOrdenado.forEach(d => {
            if (filtroCampo) {

                let campos = [];
                if (filtroCampo.split('"').length === 1 || filtroCampo.split('"').length > 2) {
                    if (filtroCampo.includes('"')) {
                        campos.push(filtroCampo.split('"')[1])
                    } else {
                        campos = filtroCampo.split(' ');
                    }
                    root: for (let x in d) {
                        for (var y in campos) {
                            if (x === 'processo') {
                                let string1 = d[x];
                                string1 = string1.replace(/[\W_]/g, "");
                                let string2 = campos[y];
                                string2 = string2.replace(/[\W_]/g, "");

                                if (string1.includes(string2)) {
                                    arr.push(d);
                                    break root;
                                }
                            } else if (typeof d[x] === 'string') {
                                if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                    arr.push(d);
                                    break root;
                                }
                            } else if (d[x] == campos[y]) {
                                arr.push(d);
                                break root;
                            }
                        }
                    }
                } else {
                    arr.push(d);
                }
            } else {
                arr.push(d);
            }
        });


        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (index++ > (pagina - 2)) break;
        }
        setDadosFiltrado(arrBloco);
        return arr;
    }
    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
    };
    function buscaRaAde() {
        setWait(true);
        fetch('/api/info/ra_ade').then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    setRaAde(json);
                    let arr = [];
                    for (let x in json) {
                        let ra = json[x];
                        arr.push(ra.nome);
                    }
                    serRaDisponiveis(arr);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function buscarUos() {
        setWait(true);
        fetch('/api/info/uos').then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    setWait(false);
                    setUos(json);
                })
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setError((error && error.message) || 'Oops! Something went wrong with UOS. Please try again!')
                })
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function buscarSituacao() {
        setWait(true);
        fetch('/api/processoProdf/situacaoLote').then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    setWait(false);
                    // setSituacao(json);
                    let arr = [];
                    for (let i in json) {
                        arr.push(json[i].situacao);
                    }
                    setSituacaoDisponiveis(arr);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }

        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function buscarPrograma() {
        setWait(true);
        fetch('/api/info/programa').then(response => {
            if (response.ok) {
                response.json().then((json) => {

                    setWait(false);

                    setProgramaDisponiveis(json);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }

        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });

    }
    function buscarSituacaoSigafe() {
        setWait(true);
        fetch('/api/info/situacaoSigafe').then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    setWait(false);
                    setSituacaoSigafe(json);
                    serSigafeDisponiveis(json);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }

        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const handleChangeInputFormMenor = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
        menor = event.target.value;
        handleSearch();
    };
    const handleChangeInputFormMaior = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
            areaMaior: event.target.value
        });
        maior = event.target.value;
    };

    // const handleChange = (event) => {
    //     setState({ ...state, [event.target.name]: event.target.checked });
    // };

    const handleToggleRA = (value) => () => {
        const currentIndex = checkedRA.indexOf(value);
        const newChecked = [...checkedRA];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedRA(newChecked);
    };
    const handleToggleADE = (value) => () => {
        const currentIndex = checkedADE.indexOf(value);
        const newChecked = [...checkedADE];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedADE(newChecked);
    };
    const handleToggleSigafe = (value) => () => {
        const currentIndex = checkedSigafe.indexOf(value);
        const newChecked = [...checkedSigafe];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedSigafe(newChecked);
    };
    const handleToggleSituação = (value) => () => {
        const currentIndex = checkedSituacao.indexOf(value);
        const newChecked = [...checkedSituacao];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedSituacao(newChecked);
    };

    const handleTogglePrograma = (value) => () => {
        const currentIndex = checkedPrograma.indexOf(value);
        const newChecked = [...checkedPrograma];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedPrograma(newChecked);
    };

    const handleAllRightRA = () => {
        const arr = raSelecionadas.concat(raDisponiveis);
        setRaSelecionadas(arr);
        serRaDisponiveis([]);
        montaFiltroAde(arr);
        setAdeSelecionadas([]);
    };
    const handleCheckedRightRA = () => {
        const arr = raSelecionadas.concat(raDisponiveisChecked);
        setRaSelecionadas(arr);
        serRaDisponiveis(not(raDisponiveis, raDisponiveisChecked));
        setCheckedRA(not(checkedRA, raDisponiveisChecked));
        montaFiltroAde(arr);
        setAdeSelecionadas([]);
    };

    const handleAllRightPrograma = () => {

        setProgramaSelecionadas(programaSelecionadas.concat(programaDisponiveis));
        setProgramaDisponiveis([]);
    };

    const handleCheckedRightPrograma = () => {
        setProgramaSelecionadas(programaSelecionadas.concat(programaDisponiveisChecked));
        setProgramaDisponiveis(not(programaDisponiveis, programaDisponiveisChecked));
        setCheckedPrograma(not(checkedPrograma, programaDisponiveisChecked));

    };

    const handleCheckedLeftPrograma = () => {
        setProgramaDisponiveis(programaDisponiveis.concat(programaSelecionadasChecked));
        setProgramaSelecionadas(not(programaSelecionadas, programaSelecionadasChecked));
        setCheckedPrograma(not(checkedPrograma, programaSelecionadasChecked));
    };
    const handleAllLeftPrograma = () => {
        setProgramaDisponiveis(programaDisponiveis.concat(programaSelecionadas));
        setProgramaSelecionadas([]);
    };

    function montaFiltroAde(ras) {
        let arr = [];
        for (let x in ras) {
            let ra = ras[x];
            let itens = raAde.find(i => i.nome === ra);
            arr = arr.concat(itens.ade);
        }
        serAdeDisponiveis(arr);
    }

    const handleCheckedLeftRA = () => {
        const arr = not(raSelecionadas, raSelecionadasChecked);
        serRaDisponiveis(raDisponiveis.concat(raSelecionadasChecked));
        setRaSelecionadas(arr);
        setCheckedRA(not(checkedRA, raSelecionadasChecked));
        montaFiltroAde(arr);
        setAdeSelecionadas([]);
    };

    const handleAllLeftRA = () => {
        serRaDisponiveis(raDisponiveis.concat(raSelecionadas));
        setRaSelecionadas([]);
        montaFiltroAde([]);
        setAdeSelecionadas([]);
    };

    const handleAllRightADE = () => {
        setAdeSelecionadas(adeSelecionadas.concat(adeDisponiveis));
        serAdeDisponiveis([]);
    };
    const handleCheckedRightADE = () => {
        setAdeSelecionadas(adeSelecionadas.concat(adeDisponiveisChecked));
        serAdeDisponiveis(not(adeDisponiveis, adeDisponiveisChecked));
        setCheckedADE(not(checkedADE, adeDisponiveisChecked));
    };
    const handleCheckedLeftADE = () => {
        serAdeDisponiveis(adeDisponiveis.concat(adeSelecionadasChecked));
        setAdeSelecionadas(not(adeSelecionadas, adeSelecionadasChecked));
        setCheckedADE(not(checkedADE, adeSelecionadasChecked));
    };
    const handleAllLeftADE = () => {
        serAdeDisponiveis(adeDisponiveis.concat(adeSelecionadas));
        setAdeSelecionadas([]);
    };

    const handleAllRightSigafe = () => {
        setSigafeSelecionadas(sigafeSelecionadas.concat(sigafeDisponiveis));
        serSigafeDisponiveis([]);
    };
    const handleCheckedRightSigafe = () => {
        setSigafeSelecionadas(sigafeSelecionadas.concat(sigafeDisponiveisChecked));
        serSigafeDisponiveis(not(sigafeDisponiveis, sigafeDisponiveisChecked));
        setCheckedSigafe(not(checkedSigafe, sigafeDisponiveisChecked));
    };
    const handleCheckedLeftSigafe = () => {
        serSigafeDisponiveis(sigafeDisponiveis.concat(sigafeSelecionadasChecked));
        setSigafeSelecionadas(not(sigafeSelecionadas, sigafeSelecionadasChecked));
        setCheckedSigafe(not(checkedSigafe, sigafeSelecionadasChecked));
    };
    const handleAllLeftSigafe = () => {
        serSigafeDisponiveis(sigafeDisponiveis.concat(sigafeSelecionadas));
        setSigafeSelecionadas([]);
    };

    const handleAllRightSituacao = () => {
        setSituacaoSelecionadas(situacaoSelecionadas.concat(situacaoDisponiveis));
        setSituacaoDisponiveis([]);
    };
    const handleCheckedRightSituacao = () => {
        setSituacaoSelecionadas(situacaoSelecionadas.concat(situacaoDisponiveisChecked));
        setSituacaoDisponiveis(not(situacaoDisponiveis, situacaoDisponiveisChecked));
        setCheckedSituacao(not(checkedSituacao, situacaoDisponiveisChecked));
    };
    const handleCheckedLeftSituacao = () => {
        setSituacaoDisponiveis(situacaoDisponiveis.concat(situacaoSelecionadasChecked));
        setSituacaoSelecionadas(not(situacaoSelecionadas, situacaoSelecionadasChecked));
        setCheckedSituacao(not(checkedSituacao, situacaoSelecionadasChecked));
    };
    const handleAllLeftSituacao = () => {
        setSituacaoDisponiveis(situacaoDisponiveis.concat(situacaoSelecionadas));
        setSituacaoSelecionadas([]);
    };

    const customListRA = (items) => (
        <Paper className={classesPagina.paper}>

            <List dense component="div" role="list">
                {items && items.length > 0 && items.map((value) => {
                    const labelId = `transfer-list-itemRA-${value}-label`;
                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggleRA(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checkedRA.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>

        </Paper>
    );


    const customListPrograma = (items) => (
        <Paper className={classesPagina.paper}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem key={value} role="listitem" button onClick={handleTogglePrograma(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checkedPrograma.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const customListADE = (items) => (
        <Paper className={classesPagina.paper}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-itemADE-${value}-label`;
                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggleADE(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checkedADE.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const customListSigafe = (items) => (
        <Paper className={classesPagina.paper}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-itemADE-${value}-label`;
                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggleSigafe(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checkedSigafe.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const customListSituacao = (items) => (
        <Paper className={classesPagina.paper}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggleSituação(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checkedSituacao.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        } else {
            ordenado = ordenado.sort((a, b) => {
                if (a.ra === b.ra) {
                    if (a.ade === b.ade) {
                        return (a.endereco < b.endereco) ? -1 : 1;
                    }
                    return (a.ade < b.ade) ? -1 : 1;
                } else {
                    return (a.ra < b.ra) ? 1 : -1
                }
                return 0;
                // return ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc)
            });
        }
        return ordenado;
    }

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
        setBloco(0);
    }
    const handleChangeSelecionarTodos = (e) => {
        if (e.target.checked) {
            let arr = filtraDado();
            if (arr) {
                let a = [];
                for (let i in arr) {
                    a.push(arr[i].id);
                }
                setSelected(a);
            }
        } else {
            setSelected([]);
        }
    }
    const handleCheckCange = (e, row) => {
        const id = row.id;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    }
    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleCliciGerarRelatorioEstoqueSituacao = () => {
        let lst = montaArrFiltro();
        if (!lst || lst.length === 0) {
            setMensagem("Nenhum registro atende ao critério de pesquisa");
            topFunction();
            return;
        }
        setWait(true);
        let arr = [];
        for (let i in lst) {
            arr.push(lst[i].id);
        }
        let dado = {
            grupo: {
                ade: document.getElementById("grupoADE").checked,
                ra: document.getElementById("grupoRA").checked,
                df: document.getElementById("grupoDF").checked
            },
            ids: arr
        }

        fetch("/api/processoProdf/lote/gerarRelatorioEstoqueSituacao.pdf", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }

    const handleCliciGerarRelatorioEstoqueArea = () => {
        setWait(true);
        let lst = montaArrFiltro();
        let arr = [];
        for (let i in lst) {
            arr.push(lst[i].id);
        }
        let dado = {
            grupo: {
                ade: document.getElementById("grupoADE").checked,
                ra: document.getElementById("grupoRA").checked,
                df: document.getElementById("grupoDF").checked
            },
            ids: arr
        }

        fetch("/api/processoProdf/lote/gerarRelatorioEstoque.pdf", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    function topFunction() {
        setTimeout(function () {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }, 30);
    }
    const handleCliciGerarRelatorio = () => { //  relatorioListagemFrom relatorioListagemFrom

        let url = "/api/processoProdf/lote/gerarRelatorioEstoque.pdf";
        if (valueRadioGroup === "grupoArea") {
            url = "/api/processoProdf/lote/gerarRelatorioEstoque.pdf";
        } else if (valueRadioGroup === "grupoProcesso") {
            url = "/api/processoProdf/lote/gerarRelatorioEstoqueProcesso.pdf";
        } else if (valueRadioGroup === "grupoEmpresa") {
            url = "/api/processoProdf/lote/gerarRelatorioEstoqueEmpresa.pdf";
        }

        let lst = montaArrFiltro();

        if (!lst || lst.length === 0) {
            setMensagem("Nenhum registro atende ao critério de pesquisa");
            topFunction();
            return;
        }
        setWait(true);
        let arr = [];
        for (let i in lst) {
            arr.push(lst[i].id);
        }
        let dado = {
            grupo: {
                ade: false,
                ra: false,
                df: false
            },
            ids: arr
        }

        fetch(url, {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            setWait(false);
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    const gerarRelatorioFicha = () => {

        setWait(true);
        fetch("/api/processoProdf/lote/fichaImovelLista", {
            method: "POST",
            body: JSON.stringify(selected),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {

            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {

            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }

    return (
        <Grid container spacing={2} className={classes.root} >
            {location.pathname.includes("relatorioEstoqueFrom") &&
                <Grid container>
                    <Grid item xs={6}>
                        <Typography className={classes.heading} gutterBottom>Agrupadores:</Typography>
                        <FormControlLabel
                            onChange={validaSituacao}
                            component="fieldset"
                            control={
                                <Switch
                                    id="grupoADE"
                                    color="primary"
                                />
                            }
                            label="Agrupar por ADE"
                        />
                        <FormControlLabel
                            onChange={validaSituacao}
                            control={
                                <Switch
                                    id="grupoRA"
                                    color="primary"
                                />
                            }
                            label="Agrupar por RA"
                        />
                        <FormControlLabel
                            onChange={validaSituacao}
                            control={
                                <Switch
                                    id="grupoDF"
                                    color="primary"
                                />
                            }
                            label="Agrupar por DF"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography className={classes.heading} gutterBottom>Filtro Área:</Typography><br />
                        <FormControlLabel component="fieldset" labelPlacement='start'
                            control={
                                <div>
                                    <TextField
                                        id="areaMenor"
                                        name="areaMenor"
                                        label="mínimo"
                                        type="number"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        value={values.areaMenor}
                                        error={error.areaMenor.erro}
                                        helperText={error.areaMenor.msg}
                                        onChange={handleChangeInputFormMenor}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        id="areaMaior"
                                        name="areaMaior"
                                        label="máximo"
                                        type="number"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        value={values.areaMaior}
                                        error={error.areaMaior.erro}
                                        helperText={error.areaMaior.msg}
                                        onChange={handleChangeInputFormMaior}
                                        onBlur={handleSearch}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </div>
                            }
                            label="Área entre: "
                        />
                    </Grid>
                </Grid>
            }
            <Grid item xs={6}>
                <Accordion defaultExpanded={true} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} gutterBottom>Filtro RA</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid className={classesPagina.root}>

                            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                                <Grid item xs={5}>
                                    <Typography variant="subtitle1">RAs disponíveis:</Typography>
                                    {raDisponiveis && customListRA(raDisponiveis)}
                                </Grid>
                                <Grid item xs={2}  >
                                    <Grid container direction="column" alignItems="center">
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleAllRightRA}
                                            disabled={raDisponiveis.length === 0}
                                            aria-label="move all right"
                                        > ≫</Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleCheckedRightRA}
                                            disabled={raDisponiveisChecked.length === 0}
                                            aria-label="move selected right"
                                        > &gt; </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleCheckedLeftRA}
                                            disabled={raSelecionadasChecked.length === 0}
                                            aria-label="move selected left"
                                        > &lt;</Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleAllLeftRA}
                                            disabled={raSelecionadas && raSelecionadas.length === 0}
                                            aria-label="move all left"
                                        > ≪ </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="subtitle1">RAs selecionadas:</Typography>
                                    {customListRA(raSelecionadas)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={6}>
                <Accordion defaultExpanded={true} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} gutterBottom>Filtro ADE</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                            <Grid item xs={5}>
                                <Typography variant="subtitle1">ADEs disponíveis:</Typography>
                                {customListADE(adeDisponiveis)}
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container direction="column" alignItems="center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classesPagina.button}
                                        onClick={handleAllRightADE}
                                        disabled={adeDisponiveis.length === 0}
                                        aria-label="move all right"
                                    > ≫</Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classesPagina.button}
                                        onClick={handleCheckedRightADE}
                                        disabled={adeDisponiveisChecked.length === 0}
                                        aria-label="move selected right"
                                    > &gt; </Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classesPagina.button}
                                        onClick={handleCheckedLeftADE}
                                        disabled={adeSelecionadasChecked.length === 0}
                                        aria-label="move selected left"
                                    > &lt;</Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        className={classesPagina.button}
                                        onClick={handleAllLeftADE}
                                        disabled={adeSelecionadas.length === 0}
                                        aria-label="move all left"
                                    > ≪ </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="subtitle1">ADEs selecionadas:</Typography>
                                {customListADE(adeSelecionadas)}
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={6}>
                <Accordion defaultExpanded={true} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} gutterBottom>Filtro Situação SIGAFE</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} className={classesPagina.root}>
                            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                                <Grid item xs={5}>
                                    <Typography variant="subtitle1">Situações disponíveis:</Typography>
                                    {sigafeDisponiveis &&
                                        customListSigafe(sigafeDisponiveis)
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container direction="column" alignItems="center">
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleAllRightSigafe}
                                            disabled={sigafeDisponiveis && sigafeDisponiveis.length === 0}
                                            aria-label="move all right"
                                        > ≫</Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleCheckedRightSigafe}
                                            disabled={sigafeDisponiveisChecked.length === 0}
                                            aria-label="move selected right"
                                        > &gt; </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleCheckedLeftSigafe}
                                            disabled={sigafeSelecionadasChecked.length === 0}
                                            aria-label="move selected left"
                                        > &lt;</Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleAllLeftSigafe}
                                            disabled={sigafeSelecionadas.length === 0}
                                            aria-label="move all left"
                                        > ≪ </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="subtitle1">Situações selecionadas:</Typography>
                                    {customListSigafe(sigafeSelecionadas)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={6}>
                <Accordion defaultExpanded={true} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} gutterBottom>Filtro Situação</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} className={classesPagina.root}>
                            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                                <Grid item xs={5}>
                                    <Typography variant="subtitle1">Situações disponíveis:</Typography>
                                    {situacaoDisponiveis &&
                                        customListSituacao(situacaoDisponiveis)
                                    }
                                </Grid>
                                <Grid item xs={2}>
                                    <Grid container direction="column" alignItems="center">
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleAllRightSituacao}
                                            disabled={situacaoDisponiveis && situacaoDisponiveis.length === 0}
                                            aria-label="move all right"
                                        > ≫</Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleCheckedRightSituacao}
                                            disabled={situacaoDisponiveisChecked.length === 0}
                                            aria-label="move selected right"
                                        > &gt; </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleCheckedLeftSituacao}
                                            disabled={situacaoSelecionadasChecked.length === 0}
                                            aria-label="move selected left"
                                        > &lt;</Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleAllLeftSituacao}
                                            disabled={situacaoSelecionadas.length === 0}
                                            aria-label="move all left"
                                        > ≪ </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="subtitle1">Situações selecionadas:</Typography>
                                    {customListSituacao(situacaoSelecionadas)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={6}>
                <Accordion defaultExpanded={true} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} gutterBottom>Filtro Programa</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid className={classesPagina.root}>

                            <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                                <Grid item xs={5}>
                                    <Typography variant="subtitle1">Programas disponíveis:</Typography>
                                    {programaDisponiveis && customListPrograma(programaDisponiveis)}
                                </Grid>
                                <Grid item xs={2}  >
                                    <Grid container direction="column" alignItems="center">
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleAllRightPrograma}
                                            disabled={programaDisponiveis && programaDisponiveis.length === 0}
                                            aria-label="move all right"
                                        > ≫</Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleCheckedRightPrograma}
                                            disabled={programaDisponiveisChecked.length === 0}
                                            aria-label="move selected right"
                                        > &gt; </Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleCheckedLeftPrograma}
                                            disabled={programaSelecionadasChecked.length === 0}
                                            aria-label="move selected left"
                                        > &lt;</Button>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            className={classesPagina.button}
                                            onClick={handleAllLeftPrograma}
                                            disabled={programaSelecionadas && programaSelecionadas.length === 0}
                                            aria-label="move all left"
                                        > ≪ </Button>
                                    </Grid>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="subtitle1">Programas selecionadas:</Typography>
                                    {customListPrograma(programaSelecionadas)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>




            {!location.pathname.includes("relatorioEstoqueFrom") &&
                <Grid item xs={6}>
                    <Accordion defaultExpanded={true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading} gutterBottom>Filtro Área</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography className={classes.heading} gutterBottom>Área entre: </Typography>
                            <TextField
                                id="areaMenor"
                                name="areaMenor"
                                label="mínimo"
                                type="number"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                value={values.areaMenor}
                                error={error.areaMenor.erro}
                                helperText={error.areaMenor.msg}
                                onChange={handleChangeInputFormMenor}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                id="areaMaior"
                                name="areaMaior"
                                label="máximo"
                                type="number"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                value={values.areaMaior}
                                error={error.areaMaior.erro}
                                helperText={error.areaMaior.msg}
                                onChange={handleChangeInputFormMaior}
                                onBlur={handleSearch}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </AccordionDetails>
                    </Accordion>
                    {/* {location.pathname.includes("relatorioFichaFrom") &&
                        <Grid item xs={6} >
                            <Grid container justify="flex-end" className={classes.btns}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    rel="noopener noreferrer"
                                > Filtrar </Button>
                            </Grid>
                        </Grid>
                    } */}
                </Grid>
            }
            {location.pathname.includes("relatorioListagemFrom") &&
                <Grid item xs={6}>
                    {/* <Typography variant="h6" gutterBottom>Área entre </Typography> */}
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading} gutterBottom>Tipo Relatório</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <RadioGroup row aria-label="gender" name="gender1" value={valueRadioGroup} onChange={handleChangeRadioGroup}>
                                <FormControlLabel value="grupoArea" control={<Radio />} label="Agrupar por  Área" />
                                <FormControlLabel value="grupoProcesso" control={<Radio />} label="Agrupar por Processo" />
                                <FormControlLabel value="grupoEmpresa" control={<Radio />} label="Agrupar por Empresa" />
                            </RadioGroup>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            }
            {/* {location.pathname.includes("relatorioFichaFrom") &&
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="primary" rel="noopener noreferrer">
                        Gerar Relatório Ficha Lote
                    </Button>
                </Grid>
            } */}
            {location.pathname.includes("relatorioEstoqueFrom") &&
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={handleCliciGerarRelatorioEstoqueSituacao} disabled={valida}>
                        Gerar Relatório - Situação
                    </Button>
                    <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={handleCliciGerarRelatorioEstoqueArea} disabled={validarArea()}>
                        Gerar Relatório - Área
                    </Button>
                </Grid>
            }
            {location.pathname.includes("relatorioListagemFrom") &&
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={handleCliciGerarRelatorio} disabled={validarArea()}>
                        Gerar Relatório
                    </Button>
                </Grid>
            }

            {location.pathname.includes("relatorioFichaFrom") &&
                <Grid container spacing={3} alignItems="flex-end">
                    <br />
                    <Grid item xs={12}>
                        <Accordion defaultExpanded={true} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography className={classes.heading} gutterBottom>Imóveis Listados</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3} className={classes.root}>
                                    <Grid item xs={12} >
                                        <CampoBusca handleSearch={handleBuscar} />
                                    </Grid>
                                    <TableContainer component={Paper} className={classes.root}>
                                        <Table aria-label="customized table" size={'small'}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                                        <TableSortLabel
                                                            active={ordem.campo === 'selecionar'}
                                                            direction={ordem.ordem}
                                                            onClick={(e) => createSortHandler('selecionar')}
                                                        > Selecionar </TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                                        <TableSortLabel
                                                            active={ordem.campo === 'proposta'}
                                                            direction={ordem.ordem}
                                                            onClick={(e) => createSortHandler('proposta')}
                                                        > Proposta</TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                                        <TableSortLabel
                                                            active={ordem.campo === 'processo'}
                                                            direction={ordem.ordem}
                                                            onClick={(e) => createSortHandler('processo')}
                                                        > Processo</TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                                        <TableSortLabel
                                                            active={ordem.campo === 'ra'}
                                                            direction={ordem.ordem}
                                                            onClick={(e) => createSortHandler('ra')}
                                                        > RA</TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                                        <TableSortLabel
                                                            active={ordem.campo === 'ade'}
                                                            direction={ordem.ordem}
                                                            onClick={(e) => createSortHandler('ade')}
                                                        > ADE</TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                                        <TableSortLabel
                                                            active={ordem.campo === 'endereco'}
                                                            direction={ordem.ordem}
                                                            onClick={(e) => createSortHandler('endereco')}
                                                        > Endereço</TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                                        <TableSortLabel
                                                            active={ordem.campo === 'area'}
                                                            direction={ordem.ordem}
                                                            onClick={(e) => createSortHandler('area')}
                                                        > Área</TableSortLabel>
                                                    </TableCell>
                                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                                        <TableSortLabel
                                                            active={ordem.campo === 'situacao'}
                                                            direction={ordem.ordem}
                                                            onClick={(e) => createSortHandler('situacao')}
                                                        > Situação</TableSortLabel>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {dadosFiltrado && dadosFiltrado.map((row, i) => {
                                                    const isItemSelected = isSelected(row.id);
                                                    const labelId = `enhanced-table-checkbox-${i}`;
                                                    return (
                                                        <TableRow key={i}>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    checked={isItemSelected}
                                                                    onChange={(e) => handleCheckCange(e, row)}
                                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                                />
                                                            </TableCell>
                                                            <TableCell
                                                                id={labelId} scope="row" padding="none"
                                                            >{row.proposta}</TableCell>
                                                            <TableCell>{row.processo}</TableCell>
                                                            <TableCell>{row.ra}</TableCell>
                                                            <TableCell>{row.ade}</TableCell>
                                                            <TableCell>{row.endereco}</TableCell>
                                                            <TableCell>{formataArea(row.area)}</TableCell>
                                                            <TableCell>{row.situacao}</TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                        {dadosFiltrado &&
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25]}
                                                component="div"
                                                count={qtd}
                                                rowsPerPage={pagina}
                                                page={bloco}
                                                labelRowsPerPage="Registros por página"
                                                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                            />
                                        }
                                    </TableContainer>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selected && selected.length > 0}
                                                onChange={handleChangeSelecionarTodos}
                                                name="checkedB"

                                            />
                                        }
                                        label="Selecionar Todos"
                                    />
                                    <Grid container justify="flex-end" className={classes.btns}>
                                        {location.pathname.includes("relatorioFichaFrom") &&
                                            <Button variant="contained" disabled={!selected || selected.length === 0}
                                                color="primary"
                                                onClick={gerarRelatorioFicha}
                                                rel="noopener noreferrer">
                                                Gerar Relatório Ficha do Imovel
                                            </Button>
                                        }

                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
}
