import React from 'react';
import {
    Grid, Paper, Typography,
    Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "../../../constants";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

export default function ResponseSocioAmbientalForm(props) {
    const classes = useStyles();
    const { value, setValue, disable} = props;

    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>RESPONSABILIDADE SOCIAL E/OU AMBIENTAL</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12} >
                            <p>Existem projetos de empreendimentos que proponham investimentos em Responsabilidade <br />
                            Social elou Ambiental? Em coso de resposto afirmativo, o empresa dever á descrever os <br />
                            Projetos abaixo, bem como apresentar provas documentais de suas realizações.</p>
                            <Typography variant="h6" gutterBottom>a) Projetos educacionais? (Descreva)</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}

                                data={value.socioAmbiental ? value.socioAmbiental.projetoEducacional || '' : ''}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    const pasaDado = {
                                        ...value.socioAmbiental,
                                        projetoEducacional: editor.getData()
                                    };
                                    setValue({
                                        ...value,
                                        socioAmbiental: pasaDado
                                    });
                                }}
                                disabled={disable}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>b) Projetos culturais e esportivos? (Descreva)</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={value.socioAmbiental ? value.socioAmbiental.projetoCultural || '' : ''}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    const pasaDado = {
                                        ...value.socioAmbiental,
                                        projetoCultural: editor.getData()
                                    };
                                    setValue({
                                        ...value,
                                        socioAmbiental: pasaDado
                                    });
                                }}
                                disabled={disable}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>c) Minimização de resíduos (reciclagem)? (Descreva)</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={value.socioAmbiental ? value.socioAmbiental.minimizacaoResiduos || '' : ''}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    const pasaDado = {
                                        ...value.socioAmbiental,
                                        minimizacaoResiduos: editor.getData()
                                    };
                                    setValue({
                                        ...value,
                                        socioAmbiental: pasaDado
                                    });
                                }}
                                disabled={disable}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>d) Existe reutilização de recursos naturais (água)? (Descreva)</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={value.socioAmbiental ? value.socioAmbiental.reutilizacaoRecursos || '' : ''}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    const pasaDado = {
                                        ...value.socioAmbiental,
                                        reutilizacaoRecursos: editor.getData()
                                    };
                                    setValue({
                                        ...value,
                                        socioAmbiental: pasaDado
                                    });
                                }}
                                disabled={disable}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
}
