
import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
} from '@material-ui/core';
import MaskedInput from 'react-text-mask'
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useStyles, getQueryParams } from "../../../constants";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Download from "../../../util/Download";
import { makeStyles } from '@material-ui/core/styles';
import { ValidarCPF } from '../../../constants/';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

function TelMask(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  function TelMask2(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  

let tipoAnexo=null;
let primeiro = true;
let currentRow=null;

export const useStylesLocal = makeStyles((theme) => ({
    btns: {
        marginTop:'10px',
        '& > *': {
            margin: '4px'
        },
    },
}));

export default function RequerimentoOutrosUnateForm(props) {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    const [processo, setProcesso] = useState();
    const { setTitulo, setErro, setWait } = props;
    let { id } = useParams();
    const [showConfirm, setShowConfirm] = useState(false);
    const [editavel, setEditavel] = useState(true);
    const [showTel1, setShowTel1] = useState(false);
    const [assuntos, setAssunto] = useState(['']);
    const [showConfirmConclusao, setShowConfirmConclusao] = useState(false);
    const [podeEditarRequerente, setPodeEditarRequerente] = useState(editavel);
    

    const [value, setValue] = useState({
        assunto: '',
        processo: {
            nProcesso:''
        },
        cpf: '',
        nome:'',
        telefone: '',
        email:'',
        protocolo: '',
        tipo: '',
        declaracao: false,
    });
    const [arquivo, setArquivo] = useState();
    const [anexos, setAnexos] = useState([]);
    const history = useHistory();
    let [profile] = useCurrentUser();
    const param = getQueryParams(useLocation().search);

    const [error, setError] = useState(resetErro());
    const [tipoDocumento, setTipoDocumento] = useState('');

    useEffect(() => {
        if( profile){

            const process = JSON.parse(localStorage.getItem("workingProcess"));
            if( process){
                setProcesso( process );
                setValue({...value, 
                    processo:{
                        id: process.id,
                        nProcesso: process.dadosEmpresa.nProcesso,
                        cnpj: process.dadosEmpresa.cnpj,
                        razaoSocial: process.razaoSocial,
                        programa: process.dadosEmpresa.programa
                    }
                });

            }
            buscaTipoDocumento();

        }
    },[profile]);

    useEffect(() => {
        setTitulo("CADASTRAR REQUERIMENTO ");
        setEditavel( param.get('editavel') === 'true')
    },[])
    function buscaDados(){
        id = param.get('id');
        setTitulo("CADASTRAR REQUERIMENTO ");
        const process = localStorage.getItem("workingProcess");
        setProcesso( JSON.parse(process) );

        if( id ){
            setWait(true);
            fetch('/api/processoProdf/requerimento/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false); 
                        setValue({...json,
                            nome: json.requerente.nome,
                            cpf: json.requerente.cpf?json.requerente.cpf:'',
                            email:json.requerente.email,
                            telefone: json.requerente.telefone?json.requerente.telefone:''
                        });
                        if( json.documentos ) setAnexos( json.documentos );
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        }else{
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarrequerimentoForm");
            }
        }
    }
    const handleCheck = (e) =>{
        setValue({
            ...value,
            declaracao: !value.declaracao
        });
    }

    const handleLostFocusCPF = (e) => {
        setError({
          ...error,
          cpf: {
            erro: false,
            msg: ''
          }
        });
        if( !value.cpf ) return;
        const cpf = value.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
    
        if (cpf.length === 0) return;
    
        if (ValidarCPF(cpf)) {
          // TODO buscar ou não CPF
          setWait(true);
          fetch("/api/v1/cpf/" + cpf)
            .then(res => {
              if (res.status <= 300) return res.json()
            })
            .then(
              (result) => {
                setWait(false);
                setError(resetErro);
                if (!result) {
                    setPodeEditarRequerente(false);
                  setValue({
                    ...value, nome: '', telefone:'', email:''
                  });
                } else {
                  setValue({
                    ...value,
                    idUser: result.id,
                    nome: result.name,
                    email: result.email,
                    telefone: result.telefone
                  });
                  setPodeEditarRequerente(true);
                }
              },
              (error) => {
                setWait(false);
              }
            )
        } else {
          setError({
            ...error,
            cpf: {
              erro: true,
              msg: 'CPF inválido'
            }
          });
        }
      }
    
    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };
    function buscaTipoDocumento(){
        if( !tipoAnexo){
            fetch('/api/info/tipoDocumento')
            .then( response => response.json() )
            .then(json => {
                tipoAnexo = json;
                let arr = [''];
                json.forEach(item => arr.push( item.tipo ));
                setAssunto(arr);
                buscaDados();
            });
        }
    }
    
    function confirmaRequerimento() {
        setError(resetErro());

        let dado = { ...value };

        dado.requerente = {
            id: value.idUser,
            nome: value.nome,
            email: value.email,
            cpf: value.cpf,
            telefone: value.telefone
        };
        dado.processo = {
            id: processo.id,
            processo: processo.dadosEmpresa.nProcesso,
            cnpj: processo.dadosEmpresa.cnpj,
            razaoSocial: processo.dadosEmpresa.razaoSocial
        };
        dado.documentos = anexos;
        dado.status = "EM_ANALISE";
        dado.historico=[{
            tipo: dado.status,
            data: new Date(),
            texto: "Cadastro via UNATE",
            responsavel:{
                id: profile.id,
                nome: profile.name,
                email: profile.email
            }
        }];
        if( valida(dado) ){
            submitForm(dado);
        }
        
        delete dado.cpf;
        delete dado.telefone;
        delete dado.email;
    }
    
    function sair(){

        if (param.get("editavel")) {
            history.goBack();//TODO tem que pensar uma forma melhor de ficar isso
        } else {
            history.replace("/home");
        }
        
    }

    function submitForm(dado) {
        setWait(true);
        fetch('/api/processoProdf/requerimento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                primeiro=true;
                setWait(false);
                response.json().then( json =>setValue( {...value,
                    id:json.id,
                    protocolo: json.protocolo
                } ));
                setShowConfirmConclusao(true);
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: {erro: false, msg:''},
            telefone: {erro:false, msg:''},
            email: {erro:false, msg:''}
        }
    }
    function valida(dado){
        if( !dado.documentos || dado.documentos.length === 0 ){
            setErro("Obrigatório informar os documentos para analise");
            return false;
        }
        return true
    }
    const handleChangeCampo = (event) => {
        
        const tipo = event.target.value;
        setValue({
            ...value,
            assunto: tipo
        }
        );

        const tps = tipoAnexo.find(i => i.tipo === tipo);
        if( tps ){
            const arr = [];
            tps.lst.forEach(item => {
                arr.push({
                    tipo: item
                })
            })
            setAnexos( arr);
        }else{
            setAnexos( [] );
        }

    }
    const handleEscolheFile = () => {
        let file = document.getElementById("file");
        file.click();
    }

    const handleAnexo=(row) =>{
        currentRow=row;

        fileClick();
    }
    function fileClick(){
        let file = document.getElementById("file");
        file.click();
    }
    const handleAnexar = (e) => {        
        let arr = [...anexos];
        arr.push({
            tipo: tipoDocumento,
            nome: arquivo.nome,
            uuid: arquivo.uuid
        });
        setAnexos(arr);
        setArquivo();
        setTipoDocumento('');
    }

    function visivelEnviar(){     
        if(param.get("editavel")==='false')return true;   
        if (value && value.status && value.status === 'EM_ANALISE')return false;
        if (value && value.status && value.status === 'PENDENTE')return false;
        return true;
        
    }

    function disable(){
        if(param.get("editavel")==='false')return false;   
        if (value && value.status && value.status === 'EM_ANALISE')return true;
        if (value && value.status && value.status === 'PENDENTE')return true;
        return false;
    }

   
    
    const upload = (e) => {
        
        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setErro(res))
            .then(
                (result) => {
                    setWait(false);
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();
                    
                    if( currentRow ){
                        let arr = [...anexos];
                        arr.forEach(i =>{
                            if( i === currentRow ){
                                i.nome = nm;
                                i.uuid = result.uuid;
                            }
                        });
                        setAnexos( arr );
                        currentRow = null;
                    }else{
                        setArquivo({
                            nome: nm,
                            uuid: result.uuid
                        });
                    }
                    // document.getElementById("arquivo").value= nm;
                },
                (error) => {
                    setWait(false);
                    setErro(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setErro("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setErro("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }
    
    function naoPodeEnviar() {
        if (!value.assunto || value.assunto.length == 0) return true;
        if (!value.nome) return true;
        if( !value.declaracao) return true;
        return false;
    }
    const handleTelLostFocus = () => {
        setShowTel1(value.telefone.trim().length !== 16);
      }
      const handleTelFocus = () => {
        setShowTel1(false);
      }
    function removeArquivo(item, index){
        if( item.tipo && item.tipo.length > 0 ){
            let itens = [...anexos];
            itens[index].nome=null;
            itens[index].uuid=null;
    
            setAnexos(itens);
        }else{
            let arr = [];
            anexos.forEach(it => {
                if (it.uuid !== item.uuid) {
                    arr.push(it);
                }
            });
        
            setAnexos(arr);
        }
    }

    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={8}>
                        <TextField
                            value={profile && profile.name || ''}
                            disabled
                            fullWidth
                            label="Responável pelo preenchimento"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={value.processo && value.processo.programa || ''}
                            disabled
                            fullWidth
                            label="Programa"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={value.protocolo || ''}
                            disabled
                            fullWidth
                            label="Protocolo"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth disabled={disable()}>
                            <InputLabel id="assutno-label">Assunto</InputLabel>
                            <Select id="assunto" label="Assunto" placceholder="Assutno" onChange={handleChangeCampo} value={ value.assunto } >
                                {assuntos && assuntos.map((assunto, i) => (
                                    <MenuItem key={i} value={assunto}>{assunto}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={processo && processo.dadosEmpresa && processo.dadosEmpresa.nProcesso || ''}
                            disabled
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={processo && processo.dadosEmpresa && processo.dadosEmpresa.cnpj || ''} disabled
                            fullWidth
                            label="CNPJ"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField disabled
                            value={processo && processo.dadosEmpresa && processo.dadosEmpresa.razaoSocial || ''}
                            fullWidth
                            label="Interessado"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                        disabled={disable()}
                        label="CPF"
                        value={value.cpf || ''}
                        onChange={handleChangeInputForm}
                        onBlur={handleLostFocusCPF}
                        error={error.cpf.erro}
                        helperText={error.cpf.msg}
                        name="cpf"
                        id="cpf-mask-input"
                        fullWidth
                        InputProps={{
                            inputComponent: CPFMask,
                        }}
                        variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled={podeEditarRequerente}
                            value={ value.nome || ''}
                            onChange={handleChangeInputForm}
                            name='nome'
                            id='nome'
                            fullWidth
                            label="Requerente"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                              <TextField
                                disabled={podeEditarRequerente}
                                error={error.telefone.erro}
                                helperText={error.telefone.msg}
                                label="Telefone"
                                value={value.telefone || ''}
                                onChange={handleChangeInputForm}
                                onBlur={handleTelLostFocus}
                                onFocus={handleTelFocus}
                                style={{ display: showTel1 ? 'none' : 'block' }}
                                name="telefone"
                                id="tel-mask-input"
                                fullWidth
                                InputProps={{
                                  inputComponent: TelMask,
                                }}
                                variant="outlined"
                              />
                              <TextField
                                disabled={podeEditarRequerente}
                                error={error.telefone.erro}
                                helperText={error.telefone.msg}
                                label="Telefone"
                                value={value.telefone || ''}
                                onChange={handleChangeInputForm}
                                onFocus={handleTelFocus}
                                style={{ display: showTel1 ? 'block' : 'none' }}
                                name="telefone"
                                id="tel-mask-input1"
                                fullWidth
                                InputProps={{
                                  inputComponent: TelMask2,
                                }}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                disabled={podeEditarRequerente}
                                error={error.email.erro}
                                helperText={error.email.msg}
                                fullWidth
                                label="Email"
                                value={value.email || ''}
                                onChange={handleChangeInputForm}
                                InputProps={{
                                  readOnly: !editavel,
                                }}
                                name="email"
                                id="email-input"
                                variant="outlined"
                              />
                            </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} >                            
                            <Grid item xs={12}>
                                <TableContainer component={Paper} >
                                    <Table aria-label="customized table" size={'small'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Tipo Documento
                                                </TableCell>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Documento
                                                </TableCell>
                                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                                    Ação
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {anexos && anexos.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{row.tipo}</TableCell>
                                                    <TableCell>{row.nome}</TableCell>
                                                    <TableCell>
                                                        <center>
                                                           {
                                                               !disable() ?(
                                                                 !row.nome ?
                                                                    <Box>    
                                                                    {
                                                                        
                                                                        (row.arquivo || row.doc) ?
                                                                        <Tooltip title="Remover documento">
                                                                            <IconButton onClick={ (e) => removeArquivo(row, i) }>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        :
                                                                        <Tooltip title="Incluir documento">
                                                                            <IconButton onClick={ (e) => handleAnexo(row) }>
                                                                                <AttachFileIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        
                                                                    }
                                                                </Box>:
                                                                    // <Download doc={row}/>
                                                                        <Tooltip title="Remover documento">
                                                                            <IconButton onClick={ (e) => removeArquivo(row, i) }>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    // <Tooltip title='Remover'>
                                                                    //     <IconButton onClick={(e)=>removeArquivo(row)} >
                                                                    //         <RemoveIcon />
                                                                    //     </IconButton>
                                                                    // </Tooltip>
                                                                
                                                            )
                                                               :
                                                               <Box>
                                                                {  row.nome  && <Download doc={row}/>}
                                                               </Box>
                                                               
                                                            }
                                                        </center>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                            <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom>
                                Decreto nº 41.015, de 22 de julho de 2020:	 
                                </Typography>
                                <br/>
                                <Typography variant="body2" gutterBottom>
 Art. 84. Nos casos de documentação incompleta, ainda que apresentada na data limite, será concedido à empresa o prazo de 30 dias corridos para saneamento, após o que, caso permaneça a omissão, o requerimento será inadmitido pela SEDET, e arquivado sem análise ou encaminhado para abertura de procedimento de cancelamento, conforme a situação. 
 Parágrafo único. Não será realizada a análise técnica acerca do pedido enquanto não finalizada a juntada da documentação faltante.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox disabled={disable()}
                                            checked={value.declaracao}
                                            onChange={handleCheck}
                                            name="declaracao"
                                            color="primary"
                                        />
                                    }
                                    label="DECLARO A ENTREGA TOTAL DA DOCUMENTAÇÃO"
                                />
                            </Grid>

                        </Grid>

                    </Grid>

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={() => sair() } rel="noopener noreferrer">
                            Sair
                        </Button>
                        {
                            visivelEnviar() &&    
                            <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} disabled={naoPodeEnviar()}>
                                Cadastrar
                            </Button>
                        }
                    
                    </Grid>
                    <ConfirmDialog
                        open={showConfirmConclusao}
                        btn={'OK'}
                        setOpen={setShowConfirmConclusao}
                        titulo={'cadastramento de requerimento criado com sucesso'}
                        msg={'numero do protocolo gerado: '+value.protocolo}
                        exec={sair}
                    />
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirma cadastro de requerimento?'
                        exec={confirmaRequerimento}
                    />
                </Grid>

            </Paper>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />

        </Container>
    );
}
