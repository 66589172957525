import React, { useEffect, useState } from 'react';
import {
    Grid, Button, 
} from '@material-ui/core';

import {useStyles, FormataData, fluxoDespacho} from "../../../constants";

import GenericTable from "../../template/GenericTable";
import { useHistory } from "react-router-dom";

let dadoOriginal=[];

export default function ValidarGestaoUNATETable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait } = props;
    const history = useHistory();
    const [fonteDeDados, setFonteDeDados] = useState([]);

    useEffect(() => {
        setTitulo("Validar Gestão UNATE");
        buscaRequerimento();

        return () => {
            setFonteDeDados({});
        };
    }, []);

    function isReadOnly() {
        return false;
    }
    function showStatus(row){
        const st = row.status
        if( st === 'RASCUNHO'){
            return "Rascunho"
        }else if ( st === 'EM_ANALISE'){
            return fluxoDespacho(row, "Em análise");
        }else if ( st === 'PENDENTE'){
            return "Pendente";
        }else if ( st === 'REPROVADO'){
            return "Reprovado";
        }else if ( st === 'DEFERIDO'){
            return fluxoDespacho(row, "Deferido");
        }else if ( st === 'INDEFERIDO'){
            return fluxoDespacho(row, "Indeferido");
        }else if ( st === 'DESPACHO'){
            const fluxo = row.fluxoPath[row.fluxoPath.length-1];
            if( fluxo === 'despachoSubSecretaria'){
                return "Aguardando despacho Subsecretaria"
            }else if( fluxo === 'despachoDiretoria'){
                return "Aguardando despacho Diretoria"
            }else if( fluxo === 'despachoGerencia'){
                return "Aguardando despacho Gerencia"
            }else if ( fluxo === 'analiseGerencia'){
                return "Aguardando despacho analise Gerencia";
            }else if ( fluxo === 'analiseDiretoria'){
                return "Aguardando despacho analise Diretoria";
            }else if ( fluxo === 'analiseSubSecretaria'){
                return "Aguardando despacho analise Subsecretaria";
            }
            return "Aguardando validação gestão UNATE";

        }else if ( st === 'DEVOLVIDO'){
            return "Devolvido";
        }
        else{
            return "Em Analise"
        }
    }
    
    function buscaRequerimento(){
        setWait(true);
        fetch("/api/processoProdf/requerimento/status/DESPACHO",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    dadoOriginal = result;
                    dadoOriginal = result.sort((a, b) => {
                        const protocoloA = a.protocolo.split("-");
                        const protocoloB = b.protocolo.split("-");
                        const sA = parseInt(protocoloA[1]+protocoloA[0]);
                        const sB = parseInt(protocoloB[1]+protocoloB[0]);
                        return sA < sB?1:-1;
                    });

                    let fonteDeDados = [];
                    for(let x in dadoOriginal){
                        const d = dadoOriginal[x];
                        const lastFluxo = d.fluxoPath[d.fluxoPath.length-1]
                        if( lastFluxo !== 'validacaoGestaoUNATE') continue;
                        fonteDeDados.push(
                            {
                                id: d.id,
                                nProcesso: d.processo.nProcesso,
                                protocolo: d.protocolo,
                                dataCriacao: d.dataCriacao,
                                dataAnalise: d.dataAnalise,
                                status: showStatus(d),
                                programa: d.processo.programa,
                                responsavel: showResponsavel(d),
                                assunto: d.assunto
                            }
                        );
                    }
                    setFonteDeDados(fonteDeDados);
                }
            });        
    }

    function showResponsavel(row){
        if( row.responsaveis ){
            let ret = '';
            row.responsaveis.forEach(r => ret += r.nome+' ');
            return ret.trim();
        }
        return "";
    }
    
    const handleActionSolicitarClick = (index, acao, dado) => {
        // const dado = dadoOriginal.find(d => dado.id === requerimento.id);
        let assunto = dado.assunto.split('-')[0].trim();

        if (acao === "Visualizar") {
            history.replace("/gerenciaProcesso/unate/validarGestaoUNATEForm?id="+dado.id+"&editable=false&assunto="+assunto+"&return=/gerenciaProcesso/unate/validarGestaoUNATE");
        } else if (acao === "Validar") {
            history.replace("/gerenciaProcesso/unate/validarGestaoUNATEForm?id="+dado.id+"&assunto="+assunto+"&return=/gerenciaProcesso/unate/validarGestaoUNATE");
        } 
    }
    
    function formataData(dt) {
        if (!dt) return "";
        return FormataData(new Date(dt));
    }

    const desabilitaAcaoAtenderTable = (acao, dado) => {
        
        if (acao === "Atender" && dado) {
            return (dado.situacao === "Cancelado" || dado.situacao === "Concluído");
            
        }
        
        return false;
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={['Visualizar', 'Validar']}
                handleDisableAction={desabilitaAcaoAtenderTable}
                dados={fonteDeDados}
                readOnly={isReadOnly()}
                colunas={[
                    { nome: 'Protocolo', campo: 'protocolo' },
                    { nome: 'Processo', campo: 'nProcesso'},
                    { nome: 'Assunto', campo: 'assunto' },
                    { nome: 'Data Criacao', campo: 'dataCriacao', transformacao: formataData  },
                    { nome: 'Situação', campo: 'status' },
                    { nome: 'Programa', campo: 'programa' },
                    { nome: 'Responsável', campo: 'responsavel' },
                    { nome: 'Ação', campo: 'action' }
                ]} />

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
            </Grid>

        </Grid >


    );
}
