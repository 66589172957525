import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ImportaPlanilhaPanel from './ImportaPlanilhaPanel';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import GenericTable from '../template/GenericTable';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import { useStyles } from "../../constants";

export default function CargaLotePanel() {
    const classes = useStyles();

    const [wait, setWait] = React.useState(false);
    const [erro, setErro] = useState();
    const [lotes, setLotes] = useState();
    const [resumo, setResumo] = useState();
    const [resumoLote, setResumoLote] = useState();
    const [showResumo, setShowResumo] = useState(false);
    const [showResumoLote, setShowResumoLote] = useState(false);

    const [showAccordion, setShowAccordion] = useState({
        carga: true,
        resumo: false

    });
    const [filtro, setFiltro] = useState()
    useEffect(() => {
        setWait(true)
        fetch('/api/processoProdf/lote')
            .then(response => {
                setWait(false);
                if (response.ok) {
                    response.json().then((json) => {
                        setShowAccordion({...showAccordion, resumo: json.length > 0})
                        setWait(false);
                        setLotes(json);

                        buscaResumoCarga();
                        // 
                    });
                }
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }, [])
    function showQtdLoteSemCnpj() {
        if (lotes) {
            const arr = lotes.filter(l => !l.cnpj );
            return arr.length;
        }
        return 0;
    }
    function buscaResumoCarga(){
        setWait(true)
        fetch('/api/processoProdf/cargaLote')
            .then(response => {
                setWait(false);
                if (response.ok) {
                    response.json().then((json) => {
                        if( json && json.length > 0 ){
                            setResumo(json[0].resumo); 
                            setResumoLote(json[1].resumo); 
                        }
                    });
                }
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function showQtdLoteSemProcesso() {
        if (lotes) {
            const arr = lotes.filter(l => !l.processo || l.processo.length === 0);
            return arr.length;
        }
        return 0;
    }
    function showQtdLoteSemProposta(){
        if (lotes) {
            const arr = lotes.filter(l => !l.proposta || l.proposta.length === 0);
            return arr.length;
        }
        return 0;
    }
    function showQtdResumo(){
        if( resumo){
            return resumo.length;
        }
        return 0;
    }
    function showQtdResumoLote(){
        if( resumoLote){
            return resumoLote.length;
        }
        return 0;
    }
    function filtroResultadoCnpj() {
        const arr = lotes.filter(l => !l.cnpj || l.cnpj.length === 0);
        setShowResumo(false);
        setFiltro(arr);
    }
    function filtroResultadoProcesso() {
        const arr = lotes.filter(l => !l.processo || l.processo.length === 0);
        setShowResumo(false);
        setFiltro(arr);
    }
    function filtroResultadoProposta() {
        const arr = lotes.filter(l => !l.proposta || l.proposta.length === 0);
        setFiltro(arr);
        setShowResumo(false);
    }
    function filtroResumo(){
        setFiltro(null);
        setShowResumo(true);
    }
    function filtroResumoLote(){
        setFiltro(null);
        setShowResumo(false);
        setShowResumoLote(true);
    }
    const handleChange = (acao) => {
        if (acao === 'carga') {
            setShowAccordion({ ...showAccordion, carga: !showAccordion.carga });
        } else {
            setShowAccordion({ ...showAccordion, resumo: !showAccordion.resumo });
        }
    }
    return (
        <div className={classes.root}>
            {erro &&
                <Alert severity="error">{erro}</Alert>
            }
            <Accordion expanded={showAccordion.carga} onChange={() => handleChange('carga')} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>Importar Planilha Lote</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ImportaPlanilhaPanel isLote={true} />
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={showAccordion.resumo} onChange={() => handleChange('resumo')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo Importação</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer >
                        <Table  aria-label="custom pagination table">
                            <TableBody>
                            
                                <TableRow >
                                    <TableCell component="th" scope="row">
                                        Lotes sem CNPJ
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {showQtdLoteSemCnpj()} 
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="right">
                                        <a href='#void' onClick={filtroResultadoCnpj}> Ver resultado</a>
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell component="th" scope="row">
                                        Lotes sem Processo
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {showQtdLoteSemProcesso()} 
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="right">
                                        <a href='#void' onClick={filtroResultadoProcesso}> Ver resultado</a><br />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell component="th" scope="row">
                                        Lotes sem Proposta
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {showQtdLoteSemProposta()}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="right">
                                        <a href='#void' onClick={filtroResultadoProposta}> Ver resultado</a><br />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell component="th" scope="row">
                                        Inconsistências Processos
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {showQtdResumo()}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="right">
                                        <a href='#void' onClick={filtroResumo}> Ver resultado</a><br />
                                    </TableCell>
                                </TableRow>
                                <TableRow >
                                    <TableCell component="th" scope="row">
                                        Inconsistências Lotes
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {showQtdResumoLote()}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} align="right">
                                        <a href='#void' onClick={filtroResumoLote}>Ver resultado</a><br />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </TableContainer>
                </AccordionDetails>
            </Accordion>
            <Backdrop className={classes.backdrop} open={wait} >
                <CircularProgress color="inherit" />
            </Backdrop>
            {
                filtro &&
                <GenericTable dados={filtro} colunas={[
                    { nome: 'RA', campo: 'ra' },
                    { nome: 'ADE', campo: 'ade' },
                    { nome: 'Endereço', campo: 'endereco' },
                    { nome: 'Area', campo: 'area' },
                    { nome: 'Situação', campo: 'situacao' },
                ]} />
            }
            {
                showResumo &&
                <GenericTable dados={resumo} colunas={[
                    { nome: 'Processo', campo: 'nProcesso' },
                    { nome: 'CNPJ', campo: 'cnpj' },
                    { nome: 'Empresa', campo: 'razaoSocial' },
                    { nome: 'Proposta', campo: 'proposta' },
                    { nome: 'Situação', campo: 'msg' },
                ]} />
            }
            {
                showResumoLote &&
                <GenericTable dados={resumoLote} colunas={[
                    { nome: 'Processo', campo: 'nProcesso' },
                    { nome: 'CNPJ', campo: 'cnpj' },
                    { nome: 'ADE', campo: 'ade' },
                    { nome: 'Proposta', campo: 'proposta' },
                    { nome: 'Situação', campo: 'msg' },
                ]} />
            }
        </div>
    );
}
