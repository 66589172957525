import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Dialog, DialogActions} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L, { LatLng } from "leaflet";

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor:   [15, 40 ],
    popupAnchor:  [-6, -47]
});
L.Marker.prototype.options.icon = DefaultIcon;
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

function MapaDialog(props) {
  const classes = useStyles();
  const { onClose, open, empresa } = props;
  const inputEl = useRef(null);
  const [ponto, setPonto] = useState();
  const [markers, setMarkers] = useState([]);

const handleOnClick = (e) =>{
    const lat = e.latlng.lat;
    const lng = e.latlng.lng;

    setPonto({
        lat: lat,
        lng: lng,
    });
    let arr = [...markers];
    arr.push(e.latlng)
    setMarkers(arr)
}
  const handleClose = () => {
    onClose(ponto);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" 
        open={open}
        fullWidth={true}
        maxWidth='md'
    >
      <DialogTitle id="simple-dialog-title">Indique a localidade</DialogTitle>
      <Map ref={inputEl} style={{
        height: '60vh',
        position: 'center',
        borderRadius: '15px',
      }} center={[-15.7801, -47.9292]} zoom={13} onClick={handleOnClick}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        { ponto &&  
        <Marker title={empresa?empresa.razaoSocial:""} position={[ponto.lat, ponto.lng]} icon={DefaultIcon}>
          <Popup>
            <span>A pretty CSS3 popup. <br/> Easily customizable.</span>
          </Popup>
        </Marker>
        }
      </Map>
      <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            Ok
          </Button>
        </DialogActions>
    </Dialog>
  );
}
export default MapaDialog;