import React, { useEffect, useState } from 'react';
import {
    Grid, IconButton, Paper, Menu, MenuItem,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip
} from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CampoBusca from "../../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import {useStyles, FormataDataComHora, filtroDadoGenerico, fluxoDespacho} from "../../../constants";
import { useHistory, useLocation } from "react-router-dom";
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import {useFluxo, useFluxoDiversos} from "../../../server/UseFluxo";

const optionsGeral = [
    'Avaliar',
    'Vincular',
    'Desvincular',
    'Analisar',
    'Visualizar',
    'Encaminhar'
];

let options = [
];
let fonteDeDados = null;
let dadoOriginal = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;
let msgDialog = '';
let acao;
let requerimento;

export default function AvaliaRequerimentoTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait, encaminhar } = props;
    const [profile] = useCurrentUser();
    const [fluxo] = useFluxo();
    const [fluxoDiverso] = useFluxoDiversos();
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);
    const [value, setValue] = React.useState("Todos Requerimento");
    const history = useHistory();
    const location = useLocation();
    const [showConfirm, setShowConfirm] = useState(false);
    const [update, setUpdate]= useState(false);

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    useEffect(() => {
        if( profile && fluxo && fluxoDiverso){
            buscaTodosRequerimento();
            if( !options || options.length === 0 ){
                for(let x in optionsGeral){
                    const opt = optionsGeral[x];
                    if( location.pathname.includes("unate/avaliaRequerimento")){
                        if( opt === 'Analisar' || opt === 'Encaminhar') continue;
                    }
                    options.push(opt);
                }
            }
            
        }
    },[profile, fluxo, fluxoDiverso]);
    
    useEffect(() => {
        if( encaminhar ){
            setTitulo("Encaminhar solicitação e analise de requerimentos");
        }else{
            setTitulo("Avaliar Requerimentos - CheckList");
        }
        localStorage.removeItem("worginRequest");
        console.log(">>>.change location<<<<<", location)
    },[]);
    
    useEffect(() => {
        if( update){
            //buscaTodosRequerimento();
            filtraDado();
            setUpdate(false);
        }
    });


    useEffect(() => {
        filtraDado();
    }, [ordem, pagina, bloco, value]);

    function buscaTodosRequerimento(){
        setWait(true);
        fetch("/api/processoProdf/requerimento/todos",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    dadoOriginal = result;
                    
                    fonteDeDados = [];
                    for(let x in dadoOriginal){
                        const d = dadoOriginal[x];
                        if( d.status === 'RASCUNHO' || d.status === 'RASCUNHO_ACESSOR' || d.status === 'NAO_RESPONDIDO' ) continue; // || d.status === 'DESPACHO') continue;
                        
                        fonteDeDados.push(
                            {
                                id: d.id,
                                nProcesso: getNumeroProcesso(d),
                                protocolo: d.protocolo,
                                dataCriacao: d.dataCriacao,
                                dataAnalise: d.dataAnalise,
                                status: showStatus(d),
                                programa: d.processo? d.processo.programa:"",
                                responsavel: showResponsavel(d),
                                assunto: d.assunto
                            }
                        );
                    }
                    
                    fonteDeDados = fonteDeDados.sort((a, b) => {
                        if (!a.protocolo || !b.protocolo) return 0;
                        const protocoloA = a.protocolo.trim().split("-");
                        const protocoloB = b.protocolo.trim().split("-");
                        const sA = parseInt(protocoloA[1]+protocoloA[0]);
                        const sB = parseInt(protocoloB[1]+protocoloB[0]);
                        return sA < sB?1:-1;
                    });
                    filtraDado();
                }
            });        
    }
    
    const handleAcao = (e, index) => {
        indexProcesso = index;
        requerimento = dadosFiltrado[indexProcesso];
        setAnchorEl(e.currentTarget);
    }
    
    const handleActionClick = (action) => {
        requerimento = dadosFiltrado[indexProcesso];
        const dado = dadoOriginal.find(d => d.id === requerimento.id);

        let assunto = requerimento.assunto.split('-')[0].trim();
        acao = options[action];
        setAnchorEl(null);
        if( acao === 'Vincular'){
            msgDialog= "Confirma seu vinculo ao requerimento selecionado?"
            setShowConfirm(true);
        }else if( acao === 'Desvincular'){
            msgDialog= "Confirma descinular do requerimento selecionado?"
            setShowConfirm(true);
        }else if( acao === 'Avaliar'){

            if( dado.fluxoPath[dado.fluxoPath.length-1] === 'analiseUnate'){
                history.push("/gerenciaProcesso/semp/despachoRequerimentoForm?id="+requerimento.id+"&assunto="+assunto);
            }else{
                history.push("/gerenciaProcesso/unate/avaliarRequerimentoForm?id="+requerimento.id+"&assunto="+assunto);
            }
        }else if( acao === 'Analisar'){
            history.push("/gerenciaProcesso/unate/avaliarRequerimentoForm?id="+requerimento.id+"&assunto="+assunto);
        }else if( acao === 'Encaminhar'){
            history.push("/gerenciaProcesso/unate/encaminharEmpresarioForm?id="+requerimento.id+"&assunto="+assunto);
        }else{
            let ret = '';
            if( encaminhar ){
                ret = "/gerenciaProcesso/unate/encaminharEmpresario";
            }
            history.push("/gerenciaProcesso/unate/avaliarRequerimentoForm?id="+requerimento.id+"&editable=false&assunto="+assunto+"&return="+ret);
        }
      };
    function execAcao(){
        if( acao === 'Vincular'){
            vincularResponsavel();
        }else if( acao === 'Desvincular'){
            desvincularResponsavel();
            
        }
    }
    function disabledMenuItem(option){
        if( !profile) return;
        if( option === 'Visualizar'){
            return false;
        }
        if( !requerimento ) return true;

        if( requerimento.assunto === 'Complemento'){
            return disabledMenuItemComplemento(option);
        }
        return disabledMenuItemGenerico(option);
    }
    function disabledMenuItemComplemento(option){
        const dado = dadoOriginal.find(d => d.id === requerimento.id);
         if( dado.status !== "EM_ANALISE" && dado.status !== "DEVOLVIDO"){
            
            return true;
        }else if( option === 'Analisar'){
            return !isEnviarEmpresario(dado);
        }else if( option === "Vincular"){
            return (dado.responsaveis && dado.responsaveis.length > 0 )?true:false;
        }else if( option === "Desvincular"){
            return (dado.responsaveis && dado.responsaveis.find(r=> r.idUser === profile.id))?false:true;
        }else if( option === "Avaliar"){
            if( isEnviarEmpresario(dado)) return true;
            return (dado.responsaveis && dado.responsaveis.find(r=> r.idUser === profile.id))?false:true;
        }
        return false;

    }
    function disabledMenuItemGenerico(option){
        const dado = dadoOriginal.find(d => d.id === requerimento.id);
        if( !dado) return true;
        
        if( dado.status !== "EM_ANALISE" && dado.status !== "DEVOLVIDO"){
            return true;
        }else if( option === 'Analisar'){
            return !isEnviarEmpresario(dado);
        }else if( option === "Vincular"){
            return (dado.responsaveis && dado.responsaveis.length > 0 )?true:false;
        }else if( option === "Desvincular"){
            return (dado.responsaveis && dado.responsaveis.find(r=> r.idUser === profile.id))?false:true;
        }else if( option === "Avaliar"){
            if ( isEnviarEmpresario(dado) ){
                return true;
            }
            return (dado.responsaveis && dado.responsaveis.find(r=> r.idUser === profile.id))?false:true;
        }
        return false;
    }
    function isEnviarEmpresario(dado){
        return dado && dado.fluxoPath && dado.fluxoPath[dado.fluxoPath.length-1] === 'despachoUnate';
    }
    function desvincularResponsavel(){
        let assunto = requerimento.assunto.split('-')[0].trim();
        let dado = {
            id: requerimento.id,
            assunto:assunto,
        };
        dado.responsavel ={
            idUser: profile.id,
            nome: profile.name,
            email: profile.email
        };
        
        setWait(true);
        fetch('/api/processoProdf/requerimento/desvincular', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                response.json().then((json) => {
                    setUpdate(true);
                });
                buscaTodosRequerimento();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function vincularResponsavel(){
        let assunto = requerimento.assunto.split('-')[0].trim();
        let dado = {
            id: requerimento.id,
            assunto:assunto,
        };
        
        dado.responsavel ={
            idUser: profile.id,
            nome: profile.name,
            email: profile.email
        };
        setWait(true);
        fetch('/api/processoProdf/requerimento/vincular', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                response.json().then((json) => {
                    setUpdate(true);
                });
                buscaTodosRequerimento();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const handleClose=()=>{
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
          disparaSearch();
        }
        elapseTime = performance.now();
      }
    
      function disparaSearch() {
        setTimeout(function () {
          if (performance.now() - elapseTime > tempo) {
            setBloco(0);
            filtraDado(filtroCampo);
          } else {
            disparaSearch();
          }
        }, tempo);
      }

    function getTituloSituacao(row) {
        return "Visualizar";
    }

    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];
        let arrOrdenado = ordenar(fonteDeDados);
        filtroDadoGenerico(arrOrdenado, arr, filtroCampo);

        let x = 0;
        let index = 0;
        let arrPosFiltro = []
        for (x = 0; x < arr.length; x++) {
            let d = arr[x];
             if( value !== 'Todos Requerimento' && profile){
                 const dado = dadoOriginal.find(dOrig => dOrig.id === d.id);
                if ( dado.responsaveis.find(r => r.idUser === profile.id)) arrPosFiltro.push(d);
                else continue;
             }else{
                arrPosFiltro.push(d);
            }
           // arrPosFiltro.push(d);
        }
        qtd = arrPosFiltro.length;
        let arrBloco = [];
        x = 0;
        index = 0;
        for (x = (bloco * pagina); x < arrPosFiltro.length; x++) {
            let d = arrPosFiltro[x];
            arrBloco.push(d);
            if (++index >= pagina) break;
        }
        setDadosFiltrado(arrBloco);
    }
    
    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            if( ordem.campo === 'protocolo'){
                ordenado = ordenado.sort((a, b) => {
                    const protocoloA = a.protocolo.split("-");
                    const protocoloB = b.protocolo.split("-");
                    const sA = parseInt(protocoloA[1]+protocoloA[0]);
                    const sB = parseInt(protocoloB[1]+protocoloB[0]);
                    return ((sA < sB) ? 1 * asc : -1 * asc)
                });
            }else{
                ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
            }
        }
        return ordenado;
    }
    const handleChange = (event) => {
        setValue(event.target.value);
        setUpdate(true);
    };

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
        setBloco(0);
    }
    
    function getNumeroProcesso(row){
        if( typeof row.processo === 'string'){
            return row.processo;
        }else{
            return row.processo ? row.processo.nProcesso:"";
        }
    }
    function showResponsavel(row){
        if( row.responsaveis ){
            let ret = '';
            row.responsaveis.forEach(r => ret += r.nome+' ');
            return ret.trim();
        }
        return "";
    }
    function showStatus(row){
        return fluxoDespacho(row, fluxo, fluxoDiverso);
    }
    
    function isAcessor(){
        if( profile){
            return profile.regras.find(item => item === '013')
        }
        return false;
    }
    function dataValidacaoUnate(row){
        if( row.assunto === 'Notificacao'){
            return dataValidacaoUnateNotificacao(row);
        }
        return dataValidacaoUnateGenerico(row);
    }
    function dataValidacaoUnateNotificacao(row){
        const dado = dadoOriginal.find(d => d.id === row.id);
        const item = dado.historico.find(h => h.tipo === 'valGestaoUnate');
        if( item){
            const dt = item.data;
            if( dt ){
                return FormataDataComHora(new Date(dt) );
            }
        }
        return '';
    }
    function dataValidacaoUnateGenerico(row){
        let dt = null;
        const dado = dadoOriginal.find(d => d.id === row.id);
        dado.historico.forEach(h => {
            if( h.tipo === 'valGestaoUnate'){
                dt = new Date(h.data);
            }
        })
        if( dt ){
            return FormataDataComHora(dt);
        }
        return '';
    }
    
    return (
        <Grid container spacing={3} className={classes.root}>
            {
                isAcessor()&&
                <Grid item xs={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Avaliar Requerimento: </FormLabel>
                        <RadioGroup row value={value} onChange={handleChange}>
                            <FormControlLabel value="Todos Requerimento" control={<Radio />} label="Todos Requerimento" />
                            <FormControlLabel value="minhaUnidade" control={<Radio />} label="Requerimento dos processos da minha unidade" />
                            <FormControlLabel value="meu" control={<Radio />} label="Requerimento dos meus processos" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            }
            {
                !isAcessor()&&
                <Grid item xs={6}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Requerimento: </FormLabel>
                        <RadioGroup row aria-label="gender" name="gender1" value={value} onChange={handleChange}>
                            <FormControlLabel value="Todos Requerimento" control={<Radio />} label="Todos Requerimento" />
                            <FormControlLabel value="Meus Requerimento" control={<Radio />} label="Meus Requerimento" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            }
            <Grid item xs={6} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'protocolo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('protocolo')}
                                    >
                                        Protocolo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'processo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('processo')}
                                    >
                                        Processo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'assunto'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('assunto')}
                                    >
                                        Assunto
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'dataCriacao'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataCriacao')}
                                    >
                                        Data Criação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'dataAnalise'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataAnalise')}
                                    >
                                        Data validação UNATE
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'status'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('status')}
                                    >
                                        Situação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'programa'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('programa')}
                                    >
                                        Programa
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'responsavel'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('responsavel')}
                                    >
                                        Responsável UNATE
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell >{row.protocolo}</TableCell>
                                    <TableCell>{ row.nProcesso }</TableCell>
                                    <TableCell>{row.assunto}</TableCell>
                                    <TableCell>{ FormataDataComHora( new Date(row.dataCriacao)) }</TableCell>
                                    <TableCell>{dataValidacaoUnate(row)}</TableCell>
                                    <TableCell>{ row.status }</TableCell>
                                    <TableCell>{ row.programa }</TableCell>
                                    <TableCell>{row.responsavel}</TableCell>
                                    <TableCell width='100px' align="center">
                                            <Tooltip title={getTituloSituacao(row)}>
                                                <IconButton onClick={(e) => handleAcao(e, i)}>
                                                    <MoreVertIcon/>
                                                </IconButton>
                                            </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {
                                !dadosFiltrado || dadosFiltrado.length === 0 &&
                                <TableRow>
                                    <TableCell colSpan={8} width='100%' align="center">Nenhuma solicitação para avaliar</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    dadosFiltrado &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ qtd }
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                }}
            >
                {options.map((option, index) => (
                        <MenuItem disabled={ disabledMenuItem(option, index)}
                            key={option} 
                            selected={option === 'Pyxis'} 
                            onClick={()=>handleActionClick(index)}>
                            {option}
                        </MenuItem>
                ))}
            </Menu>
            <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg={msgDialog}
                        exec={execAcao}
                    />

        </Grid>


    );
}
