import React, { useState } from 'react';
import {
    Grid, Paper, TextField, MenuItem, Select, Tooltip, IconButton, Typography,
    Accordion, AccordionSummary, AccordionDetails, FormControl, InputLabel,

} from '@material-ui/core';
import { useStyles, FormataData } from "../../../constants";
import SearchIcon from '@material-ui/icons/Search';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BuscaDadoDialog from "../../../util/BuscaDadoDialog";
import { useAssuntoProjeto } from "../../../server/UseAssuntoProjeto";

export default function ProjetoCadastrarForm(props) {
    const { value, setValue, setWait, setMensagem, disable } = props;

    const classes = useStyles();
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [showBuscaLoteDialog, setShowBuscaLoteDialog] = useState(false);
    const [showBuscaRequerimentoDialog, setShowBuscaRequerimentoDialog] = useState(false);
    const [info, setInfo] = useState(null);
    const [doc, setDoc] = useState(null);
    const [assuntos] = useAssuntoProjeto();
    const [empresas, setEmpresas] = useState([]);
    const [lotes, setLotes] = useState([]);
    const [requerimentos, setRequerimentos] = useState([]);

    function buscaProcessoSemp() {
        setWait(true);
        fetch('/api/processoProdf/lote/byUser', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(response => {
            setWait(false);
            if (response.ok) {

                response.json().then(json => {
                    setLotes(json);
                    setShowBuscaLoteDialog(true);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function buscarProtocolo() {
        setWait(true);
        fetch('/api/processoProdf/requerimento/fromUser', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then(json => {
                    setShowBuscaRequerimentoDialog(true);
                    setRequerimentos(json)
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function buscarCnpj() {
        setWait(true);
        fetch('/api/acesso/cnpjsFromUser', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then(json => {

                    let arr = [];
                    for (let x in json) {
                        arr.push(json[x].empresa);
                    }
                    setEmpresas(arr);
                    setShowBuscaDadoDialog(true);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const handleEscolheRequerimento = (dado) => {
        const req = {
            id: dado.id,
            protocolo: dado.protocolo,
            assunto: dado.assunto,
            status: dado.status
        }
        if (value.requerimentoInicial) {
            req.observacao = value.requerimentoInicial.observacao;
        }
        setValue({ ...value, requerimentoInicial: req })
    }
    function getStatus() {
        if (value.requerimentoInicial && value.requerimentoInicial.status) {
            if (value.requerimentoInicial.status === 'EM_ANALISE') return "Em Analise";
            if (value.requerimentoInicial.status === 'DEFERIDO') return "Deferido";
            return value.requerimentoInicial.status;
        }
        return '';
    }
    const handleEscolheLote = (dado) => {
        delete dado.observacao_terracap;
        delete dado.historico;
        delete dado.vistoriaTerracap;
        delete dado.observacao;
        delete dado.dataCriacao;
        delete dado.outrasObservacoes;
        delete dado.ngb;
        delete dado.dataaprovacao;
        delete dado.dataIndicacao;
        delete dado.situacao;
        delete dado.situacaoSigafe;
        console.log(dado)
        setValue({
            ...value,
            lotes: {
                ...dado,
                dadosImovel: [{
                    ra: dado.ra,
                    ade: dado.ade,
                    endereco: dado.endereco,
                    area: dado.area
                }]
            }
        })
    }
    const handleEscolheEmpresa = (dado) => {
        setWait(true);
        const cnpj = dado.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (cnpj.length === 0) return;
        fetch('/api/v1/cnpj/' + cnpj, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then(json => {
                    delete json.historico;
                    json.cnpj = dado.cnpj;
                    setValue({ ...value, empresa: json })

                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const handleChangeObservacao = (event) => {
        let v = { ...value };
        v.requerimentoInicial = v.requerimentoInicial ? v.requerimentoInicial : {};
        v.requerimentoInicial.observacao = event.target.value;
        setValue(v);
    };

    const handleChangeAssunto = (event) => {
        setValue({ ...value, assunto: event.target.value });
        buscaInformacoes(event.target.value);
    };
    function formataDt(sDt) {
        if (!sDt || sDt.trim().length === 0) return "";
        return FormataData(new Date(sDt));

    }
    function isAssuntoMigracao() {
        if (value && value.assunto && value.assunto.toLowerCase().includes("migra")) {
            return true;
        }
        return false
    }
    function buscaInformacoes(assunto) {
        setWait(true);
        fetch('/api/projeto/getInfo/' + assunto, {
        }).then(response => {
            setWait(false);
            if (response.ok) {
                response.text().then(txt => {
                    setInfo(txt);
                });
            } else {
                setInfo(null);
                setMensagem("Assunto " + assunto + " não localizado no servidor.");
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
        if (assuntos) {
            const item = assuntos.find(a => a.assunto == assunto);
            if (item) {
                const arr = item.docs;
                let html = "<ul>";
                for (let i in arr) {
                    html += "<li>" + arr[i] + "</li>"
                }
                html += "</ul>"
                setDoc(html);
            }
        }
        // fetch('/api/projeto/getDocumentos/'+assunto, {
        // }).then(response => {
        //     setWait(false);
        //     if (response.ok) {
        //         response.text().then(txt => {                    
        //             setDoc(txt);
        //         });
        //     } else{
        //         doc(null);
        //         setMensagem("Assunto "+assunto+" não localizado no servidor.");
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });   
    }
    const handleChangeAccordion = (panel) => (event, newExpanded) => {
        if (info === null && newExpanded) {
            buscaInformacoes(value.assunto);
        }
    };
    return (
        <Paper>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>INFORMAÇÕES BÁSICAS</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={1} className={classes.root}>
                        <Grid item xs={disable ? 4 : 3}>
                            <TextField
                                value={value.empresa && value.empresa.cnpj || ''}
                                disabled
                                fullWidth
                                label="CNPJ"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        {!disable &&
                            <Grid item xs={1}>
                                <Tooltip title='Buscar Processo'>
                                    <IconButton onClick={buscarCnpj} >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item xs={8}>
                            <TextField
                                value={value.empresa && value.empresa.razaoSocial || ''}
                                fullWidth
                                label="Razão Social"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={value.empresa && value.empresa.nomeFantasia || ''}
                                fullWidth
                                label="Nome Fantasia"
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={formataDt(value.empresa && value.empresa.dataCriacao || '')}
                                fullWidth
                                label="Data"
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={value.protocolo || ''}
                                fullWidth
                                label="Protocolo"
                                InputProps={{
                                    readOnly: true,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>

            </Accordion>

            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>PROGRAMAS DE DESENVOLVIMENTO</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            {assuntos &&
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="tipo" >Assunto</InputLabel>
                                    <Select
                                        id="assunto"
                                        name='assunto'
                                        label="Assunto"
                                        placceholder="Assunto"
                                        onChange={handleChangeAssunto}
                                        value={value.assunto || ""}
                                        disabled={disable}
                                    >
                                        {assuntos && assuntos.map((tp, i) => (
                                            <MenuItem key={i} value={tp.assunto} >{tp.assunto}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                        </Grid>
                        {isAssuntoMigracao() &&
                            <>
                                <Grid item xs={12}>
                                    <TextField
                                        value={value.requerimentoInicial && value.requerimentoInicial.observacao || ''}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="Observaçao"
                                        id="campo2"
                                        name="campo2"
                                        onChange={handleChangeObservacao}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        disabled={disable}
                                    />
                                </Grid>
                                <Grid item xs={disable ? 6 : 5}>
                                    <TextField
                                        value={value.requerimentoInicial && value.requerimentoInicial.protocolo || ''}
                                        fullWidth
                                        label="Protocolo Inicial"
                                        id="campo3"
                                        name="campo3"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                    />
                                </Grid>
                                {!disable &&
                                    <Grid item xs={1}>
                                        <Tooltip title='Buscar Processo'>
                                            <IconButton onClick={buscarProtocolo} >
                                                <SearchIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                                <Grid item xs={6}>
                                    <TextField
                                        value={getStatus()}
                                        fullWidth
                                        label="Situação"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </>
                        }

                    </Grid>
                </AccordionDetails>

            </Accordion>

            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>INFORMAÇÕES PROCESSO LOTE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} className={classes.root}>
                        <Grid item xs={disable ? 6 : 5}>
                            <TextField
                                value={value.lotes && value.lotes.processo || ''}
                                fullWidth
                                label="Processo SEDET"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        {!disable &&
                            <Grid item xs={1}>
                                <Tooltip title='Processo SEDET'>
                                    <IconButton onClick={buscaProcessoSemp} >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item xs={6}>
                            {/* <TextField
                                value={value.lote && value.lote.processoterracap || ''}
                                fullWidth
                                label="Processo TERRACAP"
                                InputProps={{
                                    readOnly: true,
                                  }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            /> */}
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={value.lotes && value.lotes.dadosImovel && value.lotes.dadosImovel[0].ra || ''}
                                fullWidth
                                label="RA"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={value.lotes && value.lotes.dadosImovel && value.lotes.dadosImovel[0].ade || ''}
                                fullWidth
                                label="ADE"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={value.lotes && value.lotes.dadosImovel && value.lotes.dadosImovel[0].endereco || ''}
                                fullWidth
                                label="Endereço"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={false} onChange={handleChangeAccordion('info')} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>INFORMAÇÕES</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <span dangerouslySetInnerHTML={{
                            __html: info ? info : ''
                        }}></span>
                    </Grid>
                </AccordionDetails>

            </Accordion>

            <Accordion defaultExpanded={false} onChange={handleChangeAccordion('info')} >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>DOCUMENTOS</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={1}>
                        <span dangerouslySetInnerHTML={{
                            __html: doc ? doc : ''
                        }}></span>
                    </Grid>
                </AccordionDetails>

            </Accordion>
            <BuscaDadoDialog
                open={showBuscaDadoDialog}
                setOpen={setShowBuscaDadoDialog}
                acao={handleEscolheEmpresa}
                colunas={[
                    { nome: 'CNPJ', campo: 'cnpj' },
                    { nome: 'Nome', campo: 'nomeEmpresa' }
                ]}
                dados={empresas} />
            <BuscaDadoDialog
                open={showBuscaLoteDialog}
                setOpen={setShowBuscaLoteDialog}
                acao={handleEscolheLote}
                colunas={[
                    { nome: 'RA', campo: 'ra' },
                    { nome: 'ADE', campo: 'ade' },
                    { nome: 'endereco', campo: 'endereco' }
                ]}
                dados={lotes} />
            <BuscaDadoDialog
                open={showBuscaRequerimentoDialog}
                setOpen={setShowBuscaRequerimentoDialog}
                acao={handleEscolheRequerimento}
                colunas={[
                    { nome: 'Protocolo', campo: 'protocolo' },
                    { nome: 'Assunto', campo: 'assunto' },
                    { nome: 'Requerente', campo: 'requerente.nome' }
                ]}
                dados={requerimentos} />

        </Paper >
    );
}
