import React, { useState, useEffect } from 'react';

import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
} from '@material-ui/core';
import ConfirmDialog from "../../../util/ConfirmDialog";
import BuscaDadoDialog from "../../../util/BuscaDadoDialog";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles, getQueryParams, API_BASE_URL } from "../../../constants";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Download from "../../../util/Download";
import SearchIcon from '@material-ui/icons/Search';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaskedInput from 'react-text-mask';

export const useStylesLocal = makeStyles((theme) => ({
    btns: {
        marginTop: '10px',
        '& > *': {
            margin: '4px'
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    paper: {
        width: '100%',
        height: '180px',
        overflow: 'auto'
    }
}));

function TelMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TelMask2(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

const tipos = ['Contra Indeferimento', 'Contra Cancelamento', 'Outros'];

export default function RecursoForm(props) {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    const { setMensagem, setTitulo, setWait, disable,setSuccess } = props;
    let { id } = useParams();
    const [anexos, setAnexos] = useState([{ tipo: -1 }]);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [txt, setTxt] = useState('');
    const [error] = useState(resetErro());
    const [showTel1, setShowTel1] = useState(false);
    const [processos, setProcessos] = useState([]);
    const location = useLocation();
    
    const [value, setValue] = useState({
        programa: '',
        processo: {
            nProcesso: ''
        },
        descricao: '',
        declaracao: false,
        protocolo: '',
        tipo: '',
    });
    const history = useHistory();

    const param = getQueryParams(useLocation().search);

    let [profile] = useCurrentUser();

    useEffect(() => {

        if (profile) {
            const identificador = param.get('id');
            if (identificador) return;
            setValue({
                ...value,
                nome: profile.name,
                cpf: profile.CPF,
                email: profile.email,
                telefone: profile.telefone
            });
            if (param.get('IdProcesso')) {
                buscaProcessoPorId(param.get('IdProcesso'));
            }
        }
    }, [profile]);

    useEffect(() => {
        setTitulo(location.pathname.includes("solicitarRecursoView")?"Visualizar Recurso":"Solicitar Recurso");
        if (id) {
            setWait(true);
            fetch('/api/processoProdf/recurso/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        if( json.solicitante){
                            json.nome = json.solicitante.nome;
                            json.cpf = json.solicitante.cpf;
                            json.telefone = json.solicitante.telefone;
                            json.email = json.solicitante.email;
                        }
                        setValue(json);
                        
                        let arr = [];
                        if (json.documentos){
                            arr = json.documentos;
                        }
                        if( !disable){
                            arr.push({tipo:-1});
                        }
                        setAnexos(arr);
                        setTxt(json.descricao ? json.descricao : '');
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        }
    }, [disable, id])

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };
    const handleCheck = (e) => {
        setValue({
            ...value,
            declaracao: !value.declaracao
        });
    }
    function buscaProcessoPorId(idProcesso){
        setWait(true);
        fetch('/api/gerenciarProcesso/byIdProcesso/' + idProcesso, {
            method: 'GET'
        }).then(response => {
            if (response.ok ) {
                response.json().then((json) => {
                    setWait(false);

                    setValue({
                        ...value,
                        processo: {
                            id: json.idProcesso,
                            nProcesso: json.nProcesso,
                            cnpj: json.cnpj,
                            razaoSocial: json.razaoSocial,
                            programa: json.programa
                        },
                        nome: profile.name,
                        cpf: profile.CPF,
                        email: profile.email,
                        telefone: profile.telefone
                    });
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function relatorio() {
        var a = document.createElement("a");
        a.href = API_BASE_URL + "/api/processoProdf/prorrogacao/relatorio/" + id;
        a.setAttribute("target", "_blank");
        a.click();
    }

    function confirmaAprovacao() {
        let dado = { ...value };
        dado.solicitante = {
            nome: profile.name,
            email: profile.email,
            id: profile.id,
            cpf: profile.CPF,
            telefone: profile.telefone
        };
        if (value.status === 'PENDENTE') {
            dado.status = "EM_ANALISE";
            dado.historico = [{
                tipo: "Reenviado",
                data: new Date(),
                texto: "Solicitação reenviada pelo empresario",
                responsavel: {
                    id: profile.id,
                    nome: profile.name,
                    email: profile.email
                }
            }];
            
        } else {
            dado.status = "EM_ANALISE";
            dado.historico = [{
                tipo: "EM_ANALISE",
                data: new Date(),
                texto: "Solicitação do empresario",
                responsavel: {
                    id: profile.id,
                    nome: profile.name,
                    email: profile.email
                }
            }];
        }
        // dado.documentos = anexos;
        dado.documentos = [];//anexos;
        anexos.forEach(a => {
            if (a.tipo !== -1) dado.documentos.push(a);
        })
        
        dado.descricao = txt;

        submitForm(dado);

    }
    const enviarRascunho = () => {
        let dado = { ...value };

        dado.solicitante = {
            nome: profile.name,
            email: profile.email,
            id: profile.id,
            cpf: profile.CPF,
            telefone: profile.telefone
        };
        // dado.documentos = anexos;
        dado.documentos = [];//anexos;
        anexos.forEach(a => {
            if (a.tipo !== -1) dado.documentos.push(a);
        })

        if (value.status === 'PENDENTE') {
            dado.status = "PENDENTE";
        } else {
            dado.status = "RASCUNHO";
        }

        dado.descricao = txt;

        submitForm(dado);
    }

    function submitForm(dado) {
        setWait(true);

        fetch('/api/processoProdf/recurso', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                if (dado.status === "RASCUNHO") {
                    setSuccess("Rascunho salvo com sucesso.");
                    response.json().then(json => setValue(json));
                    history.replace("/gerenciaProcesso/solicitarRecursoTabela");
                } else {

                    response.json().then(json => {
                        if (json.historico.length === 1) {
                            setSuccess("Requerimento criado com sucesso. protocolo:" + json.protocolo);
                        } else {
                            setMensagem("Requerimento enviado com sucesso.");
                        }
                        history.replace("/gerenciaProcesso/solicitarRecursoTabela");


                    });

                }


            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: { erro: false, msg: '' },
            telefone: { erro: false, msg: '' },
            email: { erro: false, msg: '' },

            campo3: { erro: false, msg: '' },
        }
    }
    const handleEscolheFile = () => {
        let file = document.getElementById("file");
        file.click();
    }
    const handleTelLostFocus = () => {
        setShowTel1(value.telefone.trim().length !== 16);
    }
    const handleTelFocus = () => {
        setShowTel1(false);
    }

    function visivelEnviar() {
        if (disable) return false;
        if (param.get("reenviar") === 'true') return true;
        if (value && value.status && value.status === 'EM_ANALISE') return false;
        if (value && value.status && value.status === 'PENDENTE') return false;
        return true;
    }
    
    function desabilitado() {
        if (disable) return true;
        if (value && value.status && value.status === 'EM_ANALISE') return true;
        if (value && value.status && value.status === 'PENDENTE') return true;
        return false;
    }

    function desabilitar() {
        if (disable) return true;
        if (param.get("reenviar") === 'true') return false;
        if (desabilitado()) return true;
        return false;

    }
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    if (!result) return;
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();
                    // setArquivo({
                    //     nome: nm,
                    //     uuid: result.uuid
                    // });
                    // document.getElementById("arquivo").value= nm;
                    let arr = [];
                    arr.push({
                        tipo: '',
                        nome: nm,
                        uuid: result.uuid
                    });
                    anexos.forEach(a => {
                        if (a.tipo !== -1) arr.push(a)
                    });
                    arr.push({ tipo: -1 });
                    setAnexos(arr);
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }

    function podeEnviar() {
        if (value.processo && value.processo.nProcesso && value.processo.nProcesso.length === 0) return true;
        return false;
    }

    function setProcesso(processo) {
        setValue({
            ...value,
            processo: {
                id: processo.idProcesso,
                nProcesso: processo.nProcesso,
                cnpj: processo.cnpj,
                razaoSocial: processo.razaoSocial,
                programa: processo.programa
            }
        });
    }
    function removeArquivo(item) {
        let arr = [];
        anexos.forEach(it => {
            if (it.uuid !== item.uuid) {
                arr.push(it);
            }
        });
        setAnexos(arr);
    }
    function relatorio() {
        var a = document.createElement("a");
        a.href = API_BASE_URL + "/api/processoProdf/recurso/relatorio/" + value.id;
        a.setAttribute("target", "_blank");
        a.click();
    }
    function isShowReprovado(){
        return value && (value.status === 'REPROVADO');
    }
    function showReprovado(){
        if( value && value.historico){
            const h= value.historico.filter(h=> h.tipo === 'REPROVADO');
            return h && h.length>0?h[h.length-1].texto:''
        }
        return '';
    }

    function isShowPendencia(){
        return value && (value.status === 'PENDENTE');
    }
    function showPendencia(){
        if( value && value.historico){
            const h= value.historico.filter(h=> h.tipo === 'PENDENTE');
            return h && h.length>0?h[h.length-1].texto:''
        }
        return '';
    }
    function isShowParecerSolicitacao() {
        const lastFluxo = (value && value.fluxoPath)? value.fluxoPath[value.fluxoPath.length-1]:'';
        return lastFluxo === 'deferido' || lastFluxo=== 'indeferido';
    }
    function showParecer() {
        if (value && value.historico) {
            const h = value.historico.find(h => h.tipo === 'INDEFERIDO' || h.tipo === 'DEFERIDO');
            return h ? h.texto : ''
        }
        return '';
    }
    const buscaDado = () => {

        setWait(true);
        fetch("/api/gerenciarProcesso/filtro",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify({
                    cnpjs: profile.empresas
                }),
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setMensagem(result);
                } else {
                    setProcessos(result);

                    setShowBuscaDadoDialog(true);
                }
            });
    }
    const sair = ()=>{
        let urlRet  = "/gerenciaProcesso/solicitarRecursoTabela";
        if( param.get('back') ){
            urlRet = param.get('back');
            if( param.get("open")){
                urlRet += "?open="+param.get("open")
            }
        }
        history.replace(urlRet);    
    }
    return (
        <Container maxWidth='md'>
            <Paper >

                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={11}>
                        <TextField
                            value={value.processo.nProcesso || ''}
                            disabled
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Tooltip title='Buscar Processo'>
                            <IconButton onClick={buscaDado} >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            value={value.processo && value.processo.cnpj || ''} disabled
                            fullWidth
                            label="CNPJ"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField disabled
                            value={value.processo && value.processo.razaoSocial || ''}
                            fullWidth
                            label="Razão Social"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            value={value.processo.programa || ''}
                            disabled
                            fullWidth
                            label="Programa"
                            id="programa"
                            name="programa"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            value={value.protocolo} disabled
                            fullWidth
                            label="Protocolo"
                            id="protocolo"
                            name="protocolo"
                            // onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth disabled={desabilitado()}>
                            <InputLabel id="tipo" >Tipo recurso</InputLabel>
                            <Select id="tipo" name='tipo' label="Tipo recurso" placceholder="Tipo recurso" onChange={handleChangeInputForm} value={value.tipo} >
                                {tipos && tipos.map((tp, i) => (
                                    <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            disabled
                            label="CPF"
                            value={value.cpf || ''}
                            error={error.cpf.erro}
                            helperText={error.cpf.msg}
                            name="cpf"
                            id="cpf-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: CPFMask,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled
                            value={value.nome || ''}
                            fullWidth
                            label="Requerente"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            disabled
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleTelLostFocus}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'none' : 'block' }}
                            name="telefone"
                            id="tel-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask,
                            }}
                            variant="outlined"
                        />
                        <TextField
                            disabled
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'block' : 'none' }}
                            name="telefone"
                            id="tel-mask-input1"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask2,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            disabled
                            error={error.email.erro}
                            helperText={error.email.msg}
                            fullWidth
                            label="Email"
                            value={value.email || ''}
                            onChange={handleChangeInputForm}
                            name="email"
                            id="email-input"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <CKEditor
                            editor={ClassicEditor} disabled={desabilitar()}
                            config={{
                                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                    '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                            }}
                            data={txt}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.

                                editor.editing.view.change(writer => {
                                    writer.setStyle(
                                        "height",
                                        "250px",
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTxt(data);
                            }}
                            onBlur={(event, editor) => {

                            }}
                            onFocus={(event, editor) => {

                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TableContainer component={Paper} >
                            <Table aria-label="customized table" size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sortDirection={false} className={classes.tableHead}>
                                            Documento
                                        </TableCell>
                                        <TableCell width='100px' align="center" className={classes.tableHead}>
                                            Ação
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {anexos && anexos.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{row.nome}</TableCell>
                                            <TableCell>
                                                {row.tipo === -1 && visivelEnviar() &&
                                                    <Tooltip title="Incluir documento">
                                                        <IconButton onClick={(e) => handleEscolheFile()}>
                                                            <AttachFileIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {row.tipo !== -1 &&
                                                    <center>
                                                        <Download doc={row} />
                                                        {visivelEnviar() &&
                                                            <Tooltip title='Remover'>
                                                                <IconButton onClick={(e) => removeArquivo(row)} >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </center>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {
                                        (!anexos || anexos.length === 0) &&
                                        <TableRow>
                                            <TableCell>
                                                Nenhum registro encontrado!
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid item xs={12}>
                            {visivelEnviar() &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={value.declaracao}
                                            checked={value.declaracao}
                                            onChange={handleCheck}
                                            name="declaracao"
                                            color="primary"
                                        />
                                    }
                                    label="DECLARO A ENTREGA TOTAL DA DOCUMENTAÇÃO"
                                />
                            }
                        </Grid>
                        {isShowParecerSolicitacao() &&
                        <Grid item xs={12}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classesLocal.heading}>Parecer Técnico</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <span className={classesLocal.paper} dangerouslySetInnerHTML={{
                                        __html: showParecer()
                                    }}></span>
                                </AccordionDetails>
                            </Accordion>
                            </Grid> 
                        }
                        { isShowPendencia() &&
                        <Grid item xs={12}>
                            <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography className={classesLocal.heading}>Pendência</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                    <span className={classesLocal.paper} dangerouslySetInnerHTML={{
                                        __html: showPendencia()
                                    }}></span>
                            </AccordionDetails>
                            </Accordion>  
                        </Grid> 
                    }
                    { isShowReprovado() &&
                        <Grid item xs={12}>
                            <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                            <Typography className={classesLocal.heading}>Reprovado</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                    <span className={classesLocal.paper} dangerouslySetInnerHTML={{
                                        __html: showReprovado()
                                    }}></span>
                            </AccordionDetails>
                            </Accordion>  
                        </Grid> 
                    }
                    </Grid>

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                            Sair
                        </Button>
                        {
                            value.id &&
                            <Button variant="contained" color="primary" onClick={() => relatorio()}>
                                Visualizar
                            </Button>
                        }
                        {visivelEnviar() &&
                            <Button variant="contained" color="primary" onClick={enviarRascunho} disabled={podeEnviar()}>
                                Salvar rascunho
                            </Button>
                        }
                        {visivelEnviar() &&
                            <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} disabled={podeEnviar() || !value.declaracao || !value.tipo}>
                                Solicitar
                             </Button>
                        }
                    </Grid>

                    {/* <MotivoDialog open={showMotivoDialog} setOpen={setShowMotivoDialog} acao={revogar} /> */}

                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirma solicitação de requerimento?'
                        exec={confirmaAprovacao}
                    />
                    <BuscaDadoDialog
                        open={showBuscaDadoDialog}
                        setOpen={setShowBuscaDadoDialog}
                        acao={setProcesso}
                        colunas={[
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Nome', campo: 'razaoSocial' },
                            { nome: 'Processo', campo: 'nProcesso' },
                        ]}
                        dados={processos} />
                </Grid>

            </Paper>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />

        </Container>
    );
}
