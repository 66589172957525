import React, { useEffect } from 'react';
import { Grid, Button, TextField, Paper, Container } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SendIcon from '@material-ui/icons/Send';
import ReplyIcon from '@material-ui/icons/Reply';
import EditIcon from '@material-ui/icons/Edit';
import { useState } from 'react';
import AcaoTabelaComponent from '../../common/tabela/AcaoTabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import NotificacaoService from '../../../services/NotificacaoService';  
import { useParams, useHistory } from 'react-router-dom';
import { useStyles, FormataDataComHora, calculaDiaLimite, fluxoDespacho } from '../../../constants';
import Filtro from '../../../filters/Filtro';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import { useCurrentUser } from "../../../server/UseCurrentUser";
import {useFluxoNotificacaoComplementoComplexo} from "../../../server/UseFluxo";

export default function ListarComplementosInformacaoPanel(props) {
    const classes = useStyles();
    const history = useHistory();
    let { idProcesso } = useParams();
    const { setMensagem, setTitulo } = props;
    const [complementosInformacao, setComplementosInformacao] = useState([]);
    const [processo, setProcesso] = useState({ dadosEmpresa: [], complementosInformacao: [] });
    const [complementosInformacaoFiltrados, setComplementosInformacaoFiltrados] = useState([]);
    let [profile, carregandoProfile] = useCurrentUser();
    const [fluxoNotificacaoComplementoComplexo] = useFluxoNotificacaoComplementoComplexo();

    const isPerfilUnate = () => {
        return profile && profile.regras ? profile.regras.find(r => r === '011') !== undefined : false;
    }

    const isPerfilEmpresario = () => {
        return profile && profile.regras ? profile.regras.find(r => r === '012') !== undefined : false;
    }

    const isPerfilSemp = () => {
        return profile && profile.regras ? profile.regras.find(r => r === '013') !== undefined : false;
    }

    useEffect(() => {
        setTitulo("Complemento de informação");
        let ignore = false;
        async function fetchData() {
            await NotificacaoService.obterTodos().then((r) => {//obterByStatus("ENCAMINHADO,NAO_RESPONDIDO,DESPACHO").then((r) => {
                const arr = filtroInicial(r);
                const isEmpresario = isPerfilEmpresario();
                for (let i = 0; i < arr.length; i++) {                    
                    let notificacao = arr[i];
                    const dtNotificacao= getDataNotificacao(notificacao);
                    const dtResposta= getDataResposta(notificacao);
                    notificacao.dNotificacao = dtNotificacao?FormataDataComHora(dtNotificacao):'';
                    notificacao.dResposta = dtResposta?FormataDataComHora(dtResposta):'';
                    
                    notificacao.nProcesso = notificacao.processo.nProcesso;
                    notificacao.programa = notificacao.processo.programa;

                    if( isEmpresario ){
                        notificacao.nStatus = showStatusEmpresario(notificacao);
                    }else{
                        notificacao.nStatus = Filtro.fromNotificacaoStatus(notificacao, fluxoNotificacaoComplementoComplexo);
                    }

                    notificacao.diasLimiteResposta = calculaDiaLimite(dtNotificacao) ||  '';
                }
                if (!ignore) {
                    setComplementosInformacao(arr);
                    setComplementosInformacaoFiltrados(arr);
                }
            }).catch((e) => {
                setMensagem({ mensagem: e.message, tipo: 'error' });
            });
        }
        if( fluxoNotificacaoComplementoComplexo ){
            fetchData();
        }
        return () => { ignore = true; }
    }, [idProcesso, carregandoProfile, fluxoNotificacaoComplementoComplexo])

    function filtroInicial(r){
        if( isPerfilEmpresario() ){
            return r.filter(n => {
                if( n.assunto === 'Complemento' && n.status !== 'RASCUNHO_ACESSOR' && n.status !== 'DESPACHO' && n.status !== 'DEVOLVIDO' ){
                    if( n.status === 'EM_ANALISE' ){
                        const fluxo = n.fluxoPath[n.fluxoPath.length-1];
                        if( fluxo === 'despachoUnate' ) return false;
                    }
                    return profile.empresas.find( e => e === n.processo.cnpj)?true:false;
                }else if( n.assunto === 'Complemento' && n.status === 'DESPACHO' ){
                    const fluxo = n.fluxoPath.filter(f => f === 'naoRespondido' || f === 'emValidacao' );
                    if( fluxo ){
                        return profile.empresas.find( e => e === n.processo.cnpj)?true:false;
                    }
                }
                return false;
            });
        }
        return r.filter(n => n.assunto === 'Complemento');
    }
    function handleCadastrarComplementoInformacao() {
        history.replace(`/gerenciaProcesso/complementoInformacao/form`);
    }
    function showStatusEmpresario(row){
        const notificacaoStatus = row.status;
        if( notificacaoStatus === 'DEFERIDO'){
            const h = row.historico.find(i => i.tipo === '');
            return h?'Respondido': fluxoDespacho(row, "Em analise");
        }else if( notificacaoStatus === 'PENDENTE'){
            return "Pendente";
        }else if( notificacaoStatus === 'RASCUNHO'){
            return "Rascunho";
        }else{
            // return "fluxoDespacho(row, "Respondido");"
            return "Em analise"
        }
    }

    function handleAbrirDetalhamento(complementoInformacao) {
        if( isPerfilEmpresario() ){
            history.replace("/gerenciaProcesso/complementoInformacao/formView/"+complementoInformacao.id);
        }else{
            history.replace("/gerenciaProcesso/complementoInformacao/view?id="+complementoInformacao.id);
        }        
    };
    function getDataNotificacao(notificacao){
        const arr = notificacao.historico.filter(h => h.tipo === 'EM_ANALISE');
        if( arr && arr.length > 0){
            const dt = arr[arr.length-1].data;
            return new Date(dt);
        }
        return null;
    }
    function getDataResposta(notificacao){
        const arr = notificacao.historico.filter(h => h.tipo === 'responder');
        if( arr && arr.length > 0){
            const dt = arr[arr.length-1].data;
            return new Date(dt);
        }
        return null;
    }

    function handleEditar(complementoInformacao){
        history.replace(`/gerenciaProcesso/complementoInformacao/form?id=${complementoInformacao.id}`);
    }

    function handleReenviar(complementoInformacao) {
        // history.replace(`/gerenciaProcesso/complementoInformacao/${complementoInformacao.id}/reenviar`);
        history.replace(`/gerenciaProcesso/complementoinformacao/form?id=${complementoInformacao.id}&acao=view`);
    };

    function handleResponder(complementoInformacao) {
        // history.replace(`/gerenciaProcesso/complementoInformacao/${complementoInformacao.id}/responder`);
        history.replace(`/gerenciaProcesso/complementoinformacao/form?id=${complementoInformacao.id}&acao=view`);
    };

    function handleAnalisar(complementoInformacao) {
        history.replace(`/gerenciaProcesso/complementoInformacao/${complementoInformacao.id}/analisar`);
    };

    function handleSair() {
        history.push(`/home`);
    }

    function handlePodeReenviar(complementoInformacao) {
        return (isPerfilUnate() || isPerfilEmpresario()) && complementoInformacao && complementoInformacao.status === 'PENDENTE';
    }
    function handlePodeEditar(complementoInformacao) {
        const fluxo = complementoInformacao.fluxoPath[complementoInformacao.fluxoPath.length-1];
        return isPerfilSemp() && complementoInformacao && (complementoInformacao.status === 'RASCUNHO_ACESSOR' || fluxo === 'emRascunho');
    }

    function handlePodeResponder(complementoInformacao) {
        return (isPerfilUnate() || isPerfilEmpresario()) && complementoInformacao && (complementoInformacao.status === 'ENCAMINHADO' || complementoInformacao.status === 'RASCUNHO'|| complementoInformacao.status === 'NAO_RESPONDIDO');
    }

    function handlePodeAnalisar(complementoInformacao) {
        return isPerfilSemp() && complementoInformacao && complementoInformacao.status === 'RESPONDIDO';
    }

    function handleSearch(e) {
        let termo = e.target.value;
        setComplementosInformacaoFiltrados(complementosInformacao.filter((r) => {
            return (r.nProcesso && r.nProcesso.includes(termo)) ||
                (!!r.protocolo && r.protocolo.includes(termo)) ||
                (!!r.dCriacao && r.dCriacao.includes(termo)) ||
                (!!r.dAnalise && r.dAnalise.includes(termo)) ||
                (!!r.diasLimiteResposta && r.diasLimiteResposta.includes(termo)) ||
                (!!r.programa && r.programa.includes(termo)) ||
                (!!r.nStatus && r.nStatus.includes(termo))
        }));
    }

    return (
        <Grid item xs={12}>
            {!!idProcesso && idProcesso !== '0' && <Grid item xs={12} >
                <Container maxWidth='md'>
                    <Paper>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={6}>
                                <TextField
                                    value={(processo && processo.dadosEmpresa && processo.dadosEmpresa.nProcesso) || ''}
                                    disabled
                                    fullWidth
                                    label="Processo"
                                    id="processo"
                                    name="processo"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={(processo && processo.dadosEmpresa && processo.dadosEmpresa.cnpj) || ''}
                                    disabled
                                    fullWidth
                                    label="CNPJ"
                                    id="cnpj"
                                    name="cnpj"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={(processo && processo.dadosEmpresa && processo.dadosEmpresa.razaoSocial) || ''}
                                    disabled
                                    fullWidth
                                    label="Razão Social"
                                    id="razaoSocial"
                                    name="razaoSocial"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Grid>}
            <Grid item xs={12}>
                <TabelaComponent total={complementosInformacaoFiltrados.length} dados={complementosInformacaoFiltrados} onSearch={handleSearch}>
                    <CelulaCabecalhoTabelaComponent identificador='nProcesso' titulo="Número Processo" />
                    <CelulaCabecalhoTabelaComponent identificador='protocolo' titulo="Protocolo" />
                    <CelulaCabecalhoTabelaComponent identificador='dNotificacao' titulo="Data solicitação" />
                    <CelulaCabecalhoTabelaComponent identificador='dResposta' titulo="Data resposta" />
                    <CelulaCabecalhoTabelaComponent identificador='diasLimiteResposta' titulo="Data limite para resposta" />
                    <CelulaCabecalhoTabelaComponent identificador='nStatus' titulo="Situação" />
                    <AcaoTabelaComponent titulo="Visualizar Complemento de Informação" onClick={(e) => handleAbrirDetalhamento(e)}>
                        <VisibilityIcon />
                    </AcaoTabelaComponent>
                    <AcaoTabelaComponent titulo="Editar" onClick={(e) => handleEditar(e)} visibilidade={(e) => handlePodeEditar(e)}>
                        <EditIcon/>
                    </AcaoTabelaComponent>
                    <AcaoTabelaComponent titulo="Reenviar" onClick={(e) => handleReenviar(e)} visibilidade={(e) => handlePodeReenviar(e)}>
                        <SendIcon />
                    </AcaoTabelaComponent>
                    <AcaoTabelaComponent titulo="Responder" onClick={(e) => handleResponder(e)} visibilidade={(e) => handlePodeResponder(e)}>
                        <ReplyIcon />
                    </AcaoTabelaComponent>
                    <AcaoTabelaComponent titulo="Analisar" onClick={(e) => handleAnalisar(e)} visibilidade={(e) => handlePodeAnalisar(e)}>
                        <LibraryAddCheckIcon />
                    </AcaoTabelaComponent>
                </TabelaComponent>
            </Grid>
            { isPerfilSemp() && 
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" onClick={() => handleSair()} rel="noopener noreferrer">
                    Sair
                </Button>
                 <Button variant="contained" color="primary" onClick={() => handleCadastrarComplementoInformacao()}>
                    Solicitar
                </Button>
            </Grid>
            }
            { isPerfilEmpresario() && 
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" onClick={() => handleSair()} rel="noopener noreferrer">
                    Sair
                </Button>
            </Grid>
            }
        </Grid>
    );
}
