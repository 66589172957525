import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, TextField } from '@material-ui/core';
import { useStyles } from '../../../constants';
import { red } from '@material-ui/core/colors';

export default function DetalharDadosRequerimentoProcessoDialog(props) {
    const classes = useStyles();

    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <div>
            <Dialog open={props.open} aria-labelledby="form-dialog-title" maxWidth='lg' >
                <DialogTitle id="form-dialog-title">Visualizar Requerimento</DialogTitle>
                <DialogContent style={{ overflowY: 'hidden' }} >
                    <h3 style={{ color: red[500] }}>TELA NAO MAPEADA E DADOS HARDCODED?????</h3>
                    <Grid container spacing={3} className={classes.root}>
                        <Grid item xs={3}>
                            <TextField
                                value={props.detalhamentoRequerimento.protocolo || '?????'}
                                fullWidth
                                label="Protocolo"
                                id="protocolo"
                                name="protocolo"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                                value={props.detalhamentoRequerimento.processo || '?????'}
                                fullWidth
                                label="Processo"
                                id="processo"
                                name="processo"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                value={props.detalhamentoRequerimento.dataSolicitacao || '?????'}
                                fullWidth
                                label="Data solicitação"
                                id="dataSolicitacao"
                                name="dataSolicitacao"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={props.detalhamentoRequerimento.assunto || '?????'}
                                fullWidth
                                label="Assunto"
                                id="assunto"
                                name="assunto"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={props.detalhamentoRequerimento.dataAnalise || '?????'}
                                fullWidth
                                label="Data análise"
                                id="dataAnalise"
                                name="dataAnalise"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={props.detalhamentoRequerimento.situacao || '?????'}
                                fullWidth
                                label="Situação"
                                id="situacao"
                                name="situacao"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={props.detalhamentoRequerimento.programa || '?????'}
                                fullWidth
                                label="Programa"
                                id="programa"
                                name="programa"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Sair
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
