import React, { useEffect, } from 'react';
import {
    Button, Grid, Paper, Container
}
    from '@material-ui/core';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useStyles, getQueryParams } from "../../../constants"

export default function CadastroRecursoForm(props) {
    const classes = useStyles();
    const { setTitulo } = props;
    let { id } = useParams();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    useEffect(() => {
        setTitulo("Cadastrar Recurso...");
    }, []);

    function sair() {
        history.replace("/gerenciaProcesso/cadastrarProcesso?id=123456789abcd&panel=recurso");
    }

    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid>
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" onClick={() => sair()} color="primary">
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}