import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Paper, Menu, MenuItem,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CampoBusca from "../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import {useStyles} from "../../constants";

const options = [
    'AcaoA',
    'AcaoB'
  ];

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;

export default function TemplateTable(props) {
    const classes = useStyles();
    const { setErro, dados } = props;

    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    const handleAcao = (e, index) => {
        indexProcesso = index;
        setAnchorEl(e.currentTarget);
    }
    
    const handleActionClick = (action) => {
        let processo = fonteDeDados[indexProcesso];
        let acao = options[action];

        setAnchorEl(null);
      };
    const handleClose=()=>{
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
          disparaSearch();
        }
        elapseTime = performance.now();
      }
    
      function disparaSearch() {
        setTimeout(function () {
          if (performance.now() - elapseTime > tempo) {
            setBloco(0);
            filtraDado(filtroCampo);
          } else {
            disparaSearch();
          }
        }, tempo);
      }

    function getTituloSituacao(row) {

        return "Visualizar";
    }

    useEffect(() => {
        fonteDeDados = dados;
        filtraDado();
    }, [ordem, pagina, bloco]);

    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];

        let arrOrdenado = ordenar(fonteDeDados);
        

        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                let campos = filtroCampo.split(' ');
                for( let x in d ){
                    for (var y in campos) {
                        if( typeof d[x] === 'string'){
                            if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                arr.push(d);
                                break;
                            }    
                        }else if( d[x] == campos[y]){
                            arr.push(d);
                                break;
                        }
                    }
                }
            } else {
                arr.push(d);
            }
        });
        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (index++ > pagina) break;
        }
        
        setDadosFiltrado(arrBloco);
    }
    
    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
        
        setBloco(0);
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'primeiro'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('primeiro')}
                                    >
                                        Coluna a
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'segundo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('segundo')}
                                    >
                                        Coluna B
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'terceiro'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('terceiro')}
                                    >
                                        Coluna C
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell >{row.primeiro}</TableCell>
                                    <TableCell>{row.segundo}</TableCell>
                                    <TableCell>{row.terceiro}</TableCell>
                                    <TableCell>
                                        <Box >
                                            <Tooltip title="Visualizar">
                                                <IconButton href={"/template/templateForm/"+i} >
                                                <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={getTituloSituacao(row)}>
                                                <IconButton onClick={(e) => handleAcao(e, i)}>
                                                    <MoreVertIcon/>
                                                </IconButton>
                                            </Tooltip>

                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    dados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ qtd }
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                // style: {
                //     maxHeight: ITEM_HEIGHT * 4.5,
                //     width: '20ch',
                // },
                }}
            >
                {options.map((option, index) => (
                <MenuItem key={option} selected={option === 'Pyxis'} onClick={()=>handleActionClick(index)}>
                    {option}
                </MenuItem>
                ))}
            </Menu>
        </Grid>


    );
}
