import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Paper, Menu, MenuItem, Button,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CampoBusca from "../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import { useStyles, FormataDataComHora } from "../../constants";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../server/UseCurrentUser";
import Service from "../../services/Service";
import AssessmentIcon from '@material-ui/icons/Assessment';

let options = [];
let pageLoaded = false;
let dados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;

export default function RelatorioCadastroProcesso(props) {
    const classes = useStyles();
    const { setErro, setWait, setTitulo } = props;
    const history = useHistory();

    const [processos, setProcessos] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);
    let [profile, carregandoProfile] = useCurrentUser();

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    useEffect(() => {
        setTitulo("Relatorio Processo")
        if (!carregandoProfile) {
            buscaDados()
        }
    }, [carregandoProfile]);

    useEffect(() => {
        filtraDado();
    }, [ordem, pagina, bloco]);

    function buscaDados() {

        if (!pageLoaded) {
            if (!profile) return;
            setWait(true);

            Service.POST("/api/gerenciarProcesso/filtro", {
                cnpjs: profile.empresas
            }).then(result => {
                setWait(false);
                dados = result;
                filtraDado();
            }).catch(erro => {
                setWait(false);
                console.errror('error', erro)
                setErro(erro);
            })
            pageLoaded = true;
        }
    }

    const handleAcao = (e, index) => {
        indexProcesso = index;
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }

        elapseTime = performance.now();
    }

    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
            } else {
                disparaSearch();
            }
        }, tempo);
    }

    function getTituloSituacao(row) {
        return "Visualizar";
    }


    function filtraDado() {
        if (!dados) return;

        let arr = [];
        let arrOrdenado = ordenar(dados);
        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                let campos = [];
                if (filtroCampo.includes('"')) {
                    campos.push(filtroCampo.split('"')[1])
                } else {
                    campos = filtroCampo.split(' ');
                }
                for (var y in campos) {
                    if (d.cnpj && d.cnpj.toUpperCase().includes(campos[y].toUpperCase())) {
                        arr.push(d);
                        break;
                    }
                    if (d.razaoSocial && d.razaoSocial.toUpperCase().includes(campos[y].toUpperCase())) {
                        arr.push(d);
                        break;
                    }
                    if (d.nProcesso && d.nProcesso.includes(campos[y])) {
                        arr.push(d);
                        break;
                    }

                    let p = d.nProcesso;
                    let x = d.cnpj;

                    if (p && p.replace(/[^\w\s]/gi, '').includes(campos[y])) {
                        arr.push(d);
                        break;
                    }
                    if (x && x.replace(/[^\w\s]/gi, '').includes(campos[y])) {
                        arr.push(d);
                        break;
                    }

                    let val = FormataDataComHora(new Date(d.dataCriacao));
                    if (val && val.includes(campos[y])) {
                        arr.push(d);
                        break;
                    }
                }
            } else {
                arr.push(d);
            }
        });
        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (++index >= pagina) break;
        }

        setProcessos(arrBloco);
    }
    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => {
                return ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc)
            });
        }
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function createSortHandler(coluna) {

        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);

        setBloco(0);
    }


    function handleClickGerarRelatorio(a) {
        const dado = {
            id: a,
           
        }
        gerarRelatorio(dado);
    }

    function gerarRelatorio(dado) {


        Service.POSTReport('/api/projeto/relatorioCadastroProcesso.pdf', dado).then(body => {
            setWait(false);
            if (!body) setErro("Relatório sem conteúdo");
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        }).catch(error => {
            setWait(false);
            if (typeof error === 'object') {
                setErro(error ? error.message : 'Oops! Something went wrong. Please try again!');
            } else {
                setErro(error ? error.message : 'Oops! Something went wrong. Please try again!');
            }
        })

    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table className={classes.table} aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'nProcesso'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('nProcesso')}
                                    >
                                        Processo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'cnpj'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('cnpj')}
                                    >
                                        CNPJ
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={false} className={classes.tableHead}
                                >
                                    <TableSortLabel
                                        active={ordem.campo === 'razaoSocial'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('razaoSocial')}
                                    >
                                        Razão Social
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={false} className={classes.tableHead}
                                >
                                    <TableSortLabel
                                        active={ordem.campo === 'dataCriacao'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataCriacao')}
                                    >
                                        Data Criação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {processos && processos.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell >{row.nProcesso}</TableCell>
                                    <TableCell >{row.cnpj}</TableCell>  
                                    <TableCell>{row.razaoSocial}</TableCell>
                                    <TableCell>{FormataDataComHora(new Date(row.dataCriacao))}</TableCell>
                                    <TableCell>
                                        <Box >
                                            <Tooltip title="PDF">
                                                <IconButton onClick={() => handleClickGerarRelatorio(row.idProcesso)} >
                                                    <AssessmentIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    dados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={qtd}
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </Grid>

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                    // style: {
                    //     maxHeight: ITEM_HEIGHT * 4.5,
                    //     width: '20ch',
                    // },
                }}
            >
            </Menu>
        </Grid>
    );
}
