import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react';
import { useStyles, ValidarCNPJ } from "../../constants";
import {
    Container, Grid, Typography, Box, TextField, FormControl, FormLabel, FormControlLabel,
    RadioGroup, Radio, Button, Checkbox
}
    from '@material-ui/core';
import MaskedInput from 'react-text-mask'

function CNPJMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TelMask(props) {
    const { inputRef, ...other } = props;
    function test(a) {
        const num = a.replace(/\D/g, '');
        if (num.length === 10) return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        else return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={test}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TelMask2(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

const TxtFieldTEL = (props) => {
    const { field, setHasChange, value, disable } = props;
    const [showTel1, setShowTel1] = useState(false);
    useEffect(() => {
        document.getElementById(field.id).value = value ? value : '';
    }, [])

    const handleChange = (e) => {
        setHasChange(true);
        const val = e.target.value;
        document.getElementById(field.id).value = val;
    }
    const handleTelLostFocus = () => {
        const value = document.getElementById(field.id).value;
        setShowTel1(value.trim().length !== 16);
    }
    const handleTelFocus = () => {
        setShowTel1(false);
    }
    return (
        <Grid item xs={6}>
            <TextField
                fullWidth
                // id={field.id} 
                label={field.nome}
                onChange={handleChange}
                onBlur={handleTelLostFocus}
                disabled={disable}
                onFocus={handleTelFocus}
                defaultValue={value}
                InputProps={{
                    inputComponent: TelMask,
                    readOnly: (disable && disable === true)
                }}
                variant="outlined"
            />

            <input type="hidden" id={field.id} name={field.id} />
        </Grid>
    )
}
const TxtFieldCPF = (props) => {
    const { field, setHasChange, value, disable } = props;
    useEffect(() => {
        document.getElementById(field.id).value = value ? value : '';
    }, [])
    const handleChange = (e) => {
        setHasChange(true);
    }
    return (
        <Grid item xs={6}>
            <TextField
                fullWidth
                id={field.id}
                label={field.nome}
                onChange={handleChange}
                // value={value}
                disabled={disable}
                defaultValue={value}
                InputProps={{
                    inputComponent: CPFMask,
                    readOnly: (disable && disable === true)
                }}
                variant="outlined"
            />
        </Grid>
    )
}
const TxtField = (props) => {
    const { field, setHasChange, value, disable } = props;
    useEffect(() => {
        document.getElementById(field.id).value = value ? value : '';
    }, [])
    const handleChange = (e) => {
        setHasChange(true);
    }
    return (
        <Grid item xs={6}>
            <TextField
                fullWidth
                id={field.id}
                label={field && field.nome || ''}
                onChange={handleChange}
                disabled={disable}
                defaultValue={value}
                InputProps={{
                    readOnly: (disable && disable === true)
                }}
                variant="outlined"
            />
        </Grid>
    )
}

const CheckField = (props) => {
    const { field, setHasChange, value, disable } = props;
    useEffect(() => {
        document.getElementById(field.id).value = value ? value : '';
    }, [])
    const handleChange = (e) => {
        setHasChange(true);
    }
    return (
        <Grid item xs={6}>
            <FormControlLabel
                control={<Checkbox name={field.id} id={field.id} onChange={handleChange} defaultChecked={value || false} disabled={disable} />}
                label={field.nome}
            />
        </Grid>
    )
}
const SelectField = (props) => {
    const { field, setHasChange, value, disable } = props;
    const [valor, setValor] = useState(value);
    const [txt, setTxt] = useState();
    useEffect(() => {
        const item = field.options.find(o => o === value);
        if (!item) setTxt(value);
        document.getElementById(field.id).value = value ? value : '';
    }, [])
    const handleChange = (e) => {
        setHasChange(true);
        const val = e.target.value;
        document.getElementById(field.id).value = val;
        setValor(val);
        const item = field.options.find(o => o === val);
        if (item) {
            setTxt("");
        } else {
            setTxt(val);
        }

    }

    function desmarcar(event) {
        if (event.target.value === valor) {
            setHasChange(true);
            document.getElementById(field.id).value = "";
            setValor("")
        } else {
            setValor(event.target.value);
        }
    }


    return (
        <Grid item xs={6}>
            <input type="hidden" id={field.id} name={field.id} />
            <FormControl component="fieldset" >
                <FormLabel component="legend">{field.nome}</FormLabel>
                <RadioGroup row aria-label="position"
                    value={valor || ""}
                    onChange={handleChange}

                >
                    {field.options.map((opt, id) => {
                        if (opt === 'Outros') {
                            return (
                                <TextField key={field.id + "_" + id}
                                    id={"outros_" + field.id}
                                    label={opt}
                                    value={txt || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={disable}
                                />
                            )
                        } else {
                            return (
                                <FormControlLabel key={field.id + "_" + id}
                                    value={opt || false}
                                    control={<Radio disabled={disable} color="primary" onClick={desmarcar} />}

                                    label={opt} />
                            )
                        }

                    })}

                </RadioGroup>
            </FormControl>
        </Grid>

    )
}
const FornecidoCNPJ = (props) => {
    const { field, setHasChange, value, disable } = props;
    const [valor, setValor] = useState(value !== "Não" && value ? "Sim" : value);
    const [txt, setTxt] = useState();
    const [error, setError] = React.useState(resetErro());
    useEffect(() => {
        const item = field.options.find(o => o === value);
        if (!item) setTxt(value);
        document.getElementById(field.id).value = value ? value : '';
    }, [])
    const handleChange = (e) => {
        setHasChange(true);
        const val = e.target.value;
        document.getElementById(field.id).value = val;
        setValor(val);

    }
    const handleChangeCNPJ = (e) => {
        setHasChange(true);
        const val = e.target.value;
        document.getElementById(field.id).value = val;
        const item = field.options.find(o => o === val);
        if (item) {
            setTxt("");
        } else {
            setTxt(val);
        }

    }
    const handleLostFocusCNPJ = (e) => {
        setError({
            ...error,
            cnpj: {
                erro: false,
                msg: ''
            }
        });
        if (!txt) return;
        const cnpj = txt.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (cnpj.length === 0) return;
        if (!ValidarCNPJ(cnpj)) {
            setError({
                ...error,
                cnpj: {
                    erro: true,
                    msg: 'CNPJ inválido'
                }
            });

        }
    }
    function resetErro() {
        return {
            cnpj: {
                erro: false,
                msg: ''
            },
        }
    }
    function desmarcar(event) {
        if (event.target.value === valor) {
            setHasChange(true);
            document.getElementById(field.id).value = "";
            setValor("")
        } else {
            setValor(event.target.value);
        }
    }
    return (
        <Grid item xs={6}>
            <input type="hidden" id={field.id} name={field.id} />
            <FormControl component="fieldset" >
                <FormLabel component="legend">{field.nome}</FormLabel>
                <RadioGroup row aria-label="position"
                    value={valor || ""}
                    onChange={handleChange}

                >
                    {field.options.map((opt, id) => {
                        if (opt === 'Outros') {
                            if (valor !== "Não" && valor) {
                                return (
                                    <TextField key={field.id + "_" + id}
                                        id={"outros_" + field.id}
                                        label={"CNPJ"}
                                        error={error.cnpj.erro}
                                        helperText={error.cnpj.msg}
                                        value={txt || ''}
                                        onChange={handleChangeCNPJ}
                                        onBlur={handleLostFocusCNPJ}
                                        variant="outlined"
                                        disabled={disable}
                                        InputProps={{
                                            inputComponent: CNPJMask,
                                            readOnly: (disable && disable === true)
                                        }}
                                    />
                                )
                            }
                        } else {
                            return (
                                <FormControlLabel key={field.id + "_" + id}
                                    value={opt || false}
                                    control={<Radio disabled={disable} color="primary" onClick={desmarcar} />}

                                    label={opt} />
                            )
                        }
                    })}

                </RadioGroup>
            </FormControl>
        </Grid>

    )
}

const HoraAtendimento = (props) => {

    const { field, setHasChange, value, disable } = props;

    useEffect(() => {
        document.getElementById(field.id).value = value ? value : '';
    }, [])
    const handleChange = (e) => {
        setHasChange(true);
    }
    return (
        <Grid item xs={6}>
            <TextField
                fullWidth
                id={field.id}
                type="time"
                label={field && field.nome || ''}
                onChange={handleChange}
                disabled={disable}
                defaultValue={value}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    step: 300, // 5 min
                    readOnly: (disable && disable === true)
                }}
                variant="outlined"
            />
        </Grid>
    )
}

const TxtSimNaoRespostaField = (props) => {
    const { field, setHasChange, value, disable } = props;
    const [valor, setValor] = useState(value);
    const [txt, setTxt] = useState();
    useEffect(() => {
        if (value && value !== "Não") {
            setValor("Sim");
            setTxt(value);
        }
        document.getElementById(field.id).value = value ? value : "";
    }, [])
    const handleChange = (e) => {
        setHasChange(true);
        const val = e.target.value;
        document.getElementById(field.id).value = val;

        setValor(val);
        if (val) {
            setTxt("");
        } else {
            setTxt(val);
        }

    }
    const handleTxtChange = (e) => {
        const val = e.target.value;
        setTxt(val);
        document.getElementById(field.id).value = val;
    }

    function desmarcar(event) {
        if (event.target.value === valor) {
            setHasChange(true);
            document.getElementById(field.id).value = "";
            setValor("")
        } else {
            setValor(event.target.value);
        }
    }
    return (
        <Grid item xs={6}>
            <input type="hidden" id={field.id} name={field.id} />
            <FormControl component="fieldset" >
                <FormLabel component="legend">{field.nome}</FormLabel>
                <RadioGroup row aria-label="position"
                    value={valor || ""}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value={"Sim" || valor !== null}
                        control={<Radio disabled={disable} color="primary" onClick={desmarcar} />}
                        label="Sim" />

                    <FormControlLabel
                        value={"Não"}
                        control={<Radio disabled={disable} color="primary" onClick={desmarcar} />}
                        label="Não" />

                    {valor && valor !== 'Não' &&
                        <TextField
                            id={"resposta"}
                            label={field.txtOption}
                            disabled={disable}
                            value={txt || ''}
                            onChange={handleTxtChange}
                            variant="outlined"
                        />
                    }
                </RadioGroup>
            </FormControl>
        </Grid>
    )
}
const TxtOpcaoField = (props) => {
    const { field, setHasChange, value, disable } = props;
    const [valor, setValor] = useState(value);
    const [txt, setTxt] = useState();
    useEffect(() => {
        const item = field.options.find(o => o === value);
        if (!item) setTxt(value);
        document.getElementById(field.id).value = value ? value : '';
    }, [])
    const handleChange = (e) => {
        setHasChange(true);
        const val = e.target.value;
        document.getElementById(field.id).value = val;
        setValor(val);
        const item = field.options.find(o => o === val);
        if (item) {
            setTxt("");
        } else {
            setTxt(val);
        }

    }
    return (
        <Grid item xs={6}>
            <input type="hidden" id={field.id} name={field.id} />
            <FormControl component="fieldset" >
                <FormLabel component="legend">{field.nome}</FormLabel>
                <RadioGroup row aria-label="position"
                    value={valor || ""}
                    onChange={handleChange}
                >
                    {field.options.map((opt, id) => {
                        if (opt === 'Outros') {
                            return (
                                <TextField key={field.id + "_" + id}
                                    id={"outros_" + field.id}
                                    disable={disable}
                                    label={field.txtOption}
                                    value={txt || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                />
                            )
                        } else {
                            return (
                                <FormControlLabel key={field.id + "_" + id}
                                    value={opt || false}
                                    control={<Radio disable={disable} color="primary" />}
                                    label={opt} />
                            )
                        }

                    })}

                </RadioGroup>
            </FormControl>
        </Grid>
    )
}
const GrupoField = (props) => {
    const { field, setHasChange, dado, disable } = props
    function getValue(id) {
        if (!dado) return;
        const item = dado.find(d => {
            for (let x in d) {
                if (x === id) return true;
            }
            return false;
        })
        return (item ? item[id] : "");
    }
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>{field.nome}</Typography>
            </Grid>
            {field && field.grupo.map((f, i) => {
                if (f.tipo === 'grupo') {
                    return <GrupoField key={i} field={f} setHasChange={setHasChange} />
                } else if (f.tipo === 'txt') {
                    return <TxtField key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                } else if (f.tipo === 'txtCPF') {
                    return <TxtFieldCPF key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                } else if (f.tipo === 'txtTEL') {
                    return <TxtFieldTEL key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                } else if (f.tipo === 'select') {
                    return <SelectField key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                } else if (f.tipo === 'cpnjFornecido') {
                    return <FornecidoCNPJ key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                } else if (f.tipo === 'horaATendimento') {
                    return <HoraAtendimento key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                }

            })}
        </>
    )
}

// export default function DinamicForm(props) {

const DinamicForm = forwardRef((props, ref) => {
    const { form, onSaveClick, dado, disable, setChange } = props;
    const classes = useStyles();
    const [hasChange, setHasChange] = useState(false);

    useEffect(() => {

        if (hasChange) {
            setChange(hasChange);
        }
    }, [hasChange])

    useImperativeHandle(ref, () => ({

        getStatus: () => {
            return {
                hasChange: hasChange,
                salvar: salvar
            }
        }

    }));

    const salvar = () => {
        let arr = [];
        for (let x in form) {
            const f = form[x];
            if (f.tipo === "grupo") {
                for (let y in f.grupo) {
                    const g = f.grupo[y];
                    let val = document.getElementById(g.id);
                    arr.push(g.tipo === 'check' ? { [g.id]: val.checked } : { [g.id]: val.value });
                }
            } else {
                let val = document.getElementById(f.id);
                arr.push(f.tipo === 'check' ? { [f.id]: val.checked } : { [f.id]: val.value });
            }
        }
        setChange(false);
        if (onSaveClick) {
            return onSaveClick(arr);
        } else {
            return new Promise(function (resolve, reject) {
                resolve({});
            });
        }

    }
    function getValue(id) {
        if (!dado) return;
        const item = dado.find(d => {
            for (let x in d) {
                if (x === id) return true;
            }
            return false;
        })
        return (item ? item[id] : "");
    }
    return (

        <Container maxWidth="lg">
            <Grid container spacing={3}>
                {form && form.map((f, i) => {
                    if (f.tipo === 'grupo') {
                        return <GrupoField key={i} field={f} setHasChange={setHasChange} dado={dado} disable={disable} />
                    } else if (f.tipo === 'txt') {
                        return <TxtField key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                    } else if (f.tipo === 'txtCPF') {
                        return <TxtFieldCPF key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                    } else if (f.tipo === 'select') {
                        return <SelectField key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                    } else if (f.tipo === 'cpnjFornecido') {
                        return <FornecidoCNPJ key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                    } else if (f.tipo === 'check') {
                        return <CheckField key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                    } else if (f.tipo === 'txtOpcao') {
                        return <TxtOpcaoField key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                    } else if (f.tipo === 'txtSimNaoResposta') {
                        return <TxtSimNaoRespostaField key={i} field={f} setHasChange={setHasChange} value={getValue(f.id)} disable={disable} />
                    }
                })}
            </Grid>
            {/* <Grid container justify="flex-end" className={classes.btns}>
            <Button variant="contained" color="primary" onClick={salvar} rel="noopener noreferrer" disabled={!hasChange||disable}>
                Salvar
            </Button>
        </Grid> */}

        </Container>

    );
});

export default DinamicForm;
