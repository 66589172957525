import React, { useEffect, useState } from "react";
import { useStyles, FormataData } from "../../constants";
import {
    Button, Grid, Box,
    Typography, Accordion, AccordionSummary, AccordionDetails, makeStyles, TableBody, Tooltip, IconButton, FormControl, InputLabel, Select, MenuItem
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Pie, Bar } from 'react-chartjs-2';
import { MuiPickersUtilsProvider, usePickerState } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { max } from "date-fns";
import { ptBR } from "date-fns/locale";

const VerticalBar = (props) => {
    const { titulo, abertoAndamento } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12}>
                <Bar
                    data={abertoAndamento}
                    // width={100}
                    height={200}
                    options={options} />
            </Grid>
            <Grid item xs={12}>
                <h3 className={classes.paper}>{titulo}</h3>
            </Grid>
        </Grid>
    );
}
const VerticalBarPorDia = (props) => {
    const { titulo, dataPorDia } = props;
    const classes = useStyles();
    return (
        <Grid container spacing={1} className={classes.root}>
            <Grid item xs={12}>
                <Bar
                    data={dataPorDia}
                    // width={100}
                    height={200}
                    options={options} />
            </Grid>
            <Grid item xs={12}>
                <h3 className={classes.paper}>{titulo}</h3>
            </Grid>
        </Grid>
    );
}
function PieChart(props) {
    const { titulo, grafico } = props;
    const classes = useStyles();
    return (
        <>
            <Pie
                data={grafico}
                height={200}
                width={400}
                options={optionsPie}
            />
            <h3 >{titulo}</h3>
        </>)
}

// const data = {
//     labels: ['ABERTO', 'ANDAMENTO'],
//     datasets: [
//         {
//             label: '# of Votes',
//             data: [12, 80],
//             backgroundColor: [
//                 'rgba(255, 99, 132, 0.2)',
//                 'rgba(255, 159, 64, 0.2)',
//             ],
//             borderColor: [
//                 'rgba(255, 99, 132, 1)',
//                 'rgba(255, 159, 64, 1)',
//             ],
//             borderWidth: 1,
//         },
//     ],
// }
const optionsPie = {
    legend: {
        position: "right"
    },
    responsive: false,
    maintainAspectRatio: false
    // scales: {
    //   yAxes: [
    //     {
    //       ticks: {
    //         beginAtZero: true,
    //       },
    //     },
    //   ],
    // },
}

const options = {
    maintainAspectRatio: false
}

const labelSituacoes = ["Problema de acesso", "Erro no sistema",
    "Sugestão de Melhoria", "Dúvida negocial", "Dúvida de uso do sistema", "inconsitência de dados", "Outros"];

export default function ChamadosIndicadoresPanel(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait } = props;
    const [dtInicio, setDtInicio] = React.useState(null);
    const [dtFim, setDtFim] = React.useState(null);
    const [labelDias, setLabelDias] = useState([]);
    const [abertoPorDia, setAbertoPorDia] = useState([]);
    const [concluidoPorDia, setConcluidoPorDia] = useState([]);
    const [canceladoPorDia, setCanceladoPorDia] = useState([]);
    const [nomeChart, setNomeChart] = useState([]);
    const [numeroChart, setNumeroChart] = useState([]);
    const [tipoChart, setTipoChart] = useState([]);
    const [numTipoChart, setNumTipoChart] = useState([]);
    const [dataSetAndamento, setDataSetAndamento] = useState([]);
    const [dataSetAberto, setDataSetAberto] = useState([]);
    const [error, setError] = useState(resetErro());
    const dataPorDia = {
        labels: labelDias,
        datasets: [
            {
                label: "Aberto",
                backgroundColor: "green",
                data: abertoPorDia
            },
            {
                label: "Concluído",
                backgroundColor: "blue",
                data: concluidoPorDia
            },
            {
                label: "Cancelado",
                backgroundColor: "red",
                data: canceladoPorDia
            }
        ],
    }

    const chamadoAndamentoAberto = {
        labels: labelSituacoes,
        datasets: [
            {
                label: 'Em atendimento',
                data: dataSetAndamento,
                backgroundColor: "yellow",
            }, {
                label: 'Aberto',
                data: dataSetAberto,
                backgroundColor: "green",
            },
        ],
    }

    useEffect(() => {
        buscarSuporte();
        setTitulo("Análise de indicadores de atendimento");
    }, [])
    function resetErro() {
        return {
            data: { erro: false, msg: '' },
        }
    }
    function buscarSuporte() {
        let url = "/api/suporte/filtroData";
        let filtro = {
            dtInicio: dtInicio,
            dtFim: dtFim
        }
        if (dtInicio !== null || dtFim !== null) {
            let dataInicio = new Date(dtInicio);
            dataInicio.setHours(0);
            dataInicio.setMinutes(0);
            dataInicio.setSeconds(0)
            let dataFim = new Date(dtFim);
            dataFim.setHours(23);
            dataFim.setMinutes(59);
            dataFim.setSeconds(59);
            filtro = {
                dtInicio: dataInicio,
                dtFim: dataFim
            }
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(filtro),
        }).then(res => res.json()).then((result) => {

            // dados grafico por situacao
            const situacao = ['ABERTO', 'CONCLUIDO', 'CANCELADO', 'ANDAMENTO'];
            var situcaoNumero = [0, 0, 0, 0]

            // dados grafico dados por tipo
            const tipo1 = ['PROBLEMA_ACESSO', 'ERRO_SISTEMA', 'SUGESTAO_MELHORIA', 'DUVIDA_NEGOCIAL', 'DUVIDA_USO_SISTEMA', 'INCONSISTENCIA_DADO', 'OUTROS']
            var tipoNumero = [0, 0, 0, 0, 0, 0, 0]

            for (var x in result) {
                for (var y in situacao) {
                    if (result[x].situacao === situacao[y]) {
                        situcaoNumero[y]++
                    }

                }

                for (var y in tipo1) {
                    if (result[x].tipo === tipo1[y]) {
                        tipoNumero[y]++
                    }
                }
            }
            let arrSituacao = [];
            for (let x in situacao) {
                arrSituacao[x] = deParaSituacao(situacao[x]);
            }
            let arrTipo = [];
            for (let x in tipo1) {
                arrTipo[x] = deParaTipo(tipo1[x]);
            }

            setNomeChart(arrSituacao)
            setNumeroChart(situcaoNumero)

            setTipoChart(arrTipo)
            setNumTipoChart(tipoNumero);

            montaGraficoPorDia(result);
            montaGraficoAbertoAndamento(result);

        });
    }

    function montaGraficoPorDia(dado) {
        dado = dado.sort((a, b) => new Date(a.dataCriacao) > new Date(b.dataCriacao));
        if (dado.length === 0) {
            setAbertoPorDia([]);
            setConcluidoPorDia([]);
            setCanceladoPorDia([]);
            setLabelDias([]);
            return;
        }
        let dataInicio = new Date(dado[0] ? dado[0].dataCriacao : '');
        let dataFim = new Date(dado[dado.length - 1] ? dado[dado.length - 1].dataCriacao : '');
        dataInicio.setHours(0);
        dataFim.setHours(23);
        let arr = [];
        let arrAberto = new Map();
        let arrConcluido = new Map();
        let arrCancelado = new Map();
        while (dataInicio <= dataFim) {
            const dt = FormataData(dataInicio);
            arr.push(dt);
            arrAberto.set(dt, 0);
            arrConcluido.set(dt, 0);
            arrCancelado.set(dt, 0);
            for (let x in dado) {
                const item = dado[x];
                for (let h in item.historico) {
                    const historico = item.historico[h];
                    const data = FormataData(new Date(historico.data));
                    if (dt === data) {
                        if (historico.tipo === "ABERTO") {
                            arrAberto.set(dt, arrAberto.get(dt) + 1);
                        } else if (historico.tipo === "CONCLUIDO") {
                            arrConcluido.set(dt, arrConcluido.get(dt) + 1);
                        } else if (historico.tipo === "CANCELADO") {
                            arrCancelado.set(dt, arrCancelado.get(dt) + 1);
                        }
                    }
                }

            }
            dataInicio.setDate(dataInicio.getDate() + 1);
        }

        const ab = [...arrAberto].map(([name, value]) => (value));
        const cc = [...arrConcluido].map(([name, value]) => (value));
        const ca = [...arrCancelado].map(([name, value]) => (value));

        setAbertoPorDia(ab);
        setConcluidoPorDia(cc);
        setCanceladoPorDia(ca);
        setLabelDias(arr);
    }


    function montaGraficoAbertoAndamento(dado) {
        let arrAberto = [];
        let arrEmAndamento = [];

        for (let i in labelSituacoes) {
            arrAberto.push(0);
            arrEmAndamento.push(0);
        }
        for (let x in dado) {
            const item = dado[x];
            if (item.situacao === 'ABERTO') {
                if (item.tipo === 'SUGESTAO_MELHORIA') {
                    const index = labelSituacoes.indexOf("Sugestão de Melhoria");
                    arrAberto[index] += 1;
                } else if (item.tipo === 'PROBLEMA_ACESSO') {
                    const index = labelSituacoes.indexOf("Problema de acesso");
                    arrAberto[index] += 1;
                } else if (item.tipo === 'ERRO_SISTEMA') {
                    const index = labelSituacoes.indexOf("Erro no sistema");
                    arrAberto[index] += 1;
                } else if (item.tipo === 'DUVIDA_NEGOCIAL') {
                    const index = labelSituacoes.indexOf("Dúvida negocial")
                    arrAberto[index] += 1;
                } else if (item.tipo === 'DUVIDA_USO_SISTEMA') {
                    const index = labelSituacoes.indexOf("Dúvida de uso do sistema")
                    arrAberto[index] += 1;
                } else if (item.tipo === 'INCONSISTENCIA_DADO') {
                    const index = labelSituacoes.indexOf("inconsitência de dados")
                    arrAberto[index] += 1;
                } else {
                    const index = labelSituacoes.indexOf("Outros");
                    arrAberto[index] += 1;
                }
            }
            // else if( item.tipo === 'PROBLEMA_ACESSO'){
            //     const index = labelSituacoes.indexOf("Problema de acesso");
            //     arrAberto[ index] += 1; 
            // }
            else if (item.situacao === "ANDAMENTO") {
                if (item.tipo === 'SUGESTAO_MELHORIA') {
                    const index = labelSituacoes.indexOf("Sugestão de Melhoria");
                    arrEmAndamento[index] += 1;
                } else if (item.tipo === 'PROBLEMA_ACESSO') {
                    const index = labelSituacoes.indexOf("Problema de acesso");
                    arrEmAndamento[index] += 1;
                } else if (item.tipo === 'ERRO_SISTEMA') {
                    const index = labelSituacoes.indexOf("Erro no sistema");
                    arrEmAndamento[index] += 1;
                } else if (item.tipo === 'DUVIDA_NEGOCIAL') {
                    const index = labelSituacoes.indexOf("Dúvida negocial")
                    arrEmAndamento[index] += 1;
                } else if (item.tipo === 'DUVIDA_USO_SISTEMA') {
                    const index = labelSituacoes.indexOf("Dúvida de uso do sistema")
                    arrEmAndamento[index] += 1;
                } else if (item.tipo === 'INCONSISTENCIA_DADO') {
                    const index = labelSituacoes.indexOf("inconsitência de dados")
                    arrEmAndamento[index] += 1;
                } else {
                    const index = labelSituacoes.indexOf("Outros");
                    arrEmAndamento[index] += 1;
                }
            }


        }
        setDataSetAndamento(arrEmAndamento);
        setDataSetAberto(arrAberto);

    }
    const situacaoChamados = {
        labels: nomeChart,
        datasets: [
            {
                label: '# of Votes',
                data: numeroChart,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(1, 102, 255, 0.2)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(1, 102, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }


    const tipoChamados = {
        labels: tipoChart,
        datasets: [
            {
                label: '# of Votes',
                data: numTipoChart,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(1, 102, 255, 0.2)',
                    'rgba(1, 300, 255, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(1, 102, 255, 1)',
                    'rgba(1, 300, 255, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }


    const handleDateChangeInicio = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (date > dtFim && dtFim !== null) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        if (dtFim !== null && date == null) {
            setError({
                data: { erro: true, msg: 'Informe a data inicial' }
            });
        }
        setDtInicio(date);
    };
    const handleDateChangeFim = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (dtInicio == null && date !== null) {
            setError({
                data: { erro: true, msg: 'Informe a data inicial' }
            });
        }
        if (dtInicio > date) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        setDtFim(date);
    };

    function getMax(arr) {
        let maximo = 0;
        for (let x in arr) {
            if (maximo < arr[x]) maximo = arr[x];
        }
        return maximo;
    }
    const relatorio = () => {
        let arrLab = [], arrAnda = [], arrAberto = [];
        let max = getMax(dataSetAberto);
        let min = max, index = 0;
        let i, j = 0;

        let arr = dataSetAberto.slice(0, dataSetAberto.length);
        let arrL = labelSituacoes.slice(0, dataSetAberto.length);
        let arrAn = dataSetAndamento.slice(0, dataSetAberto.length);

        for (i = 0; i < dataSetAberto.length; i++) {
            min = max;
            let indexMenor = 0;
            for (j = arr.length; j >= 0; j--) {
                if (min >= arr[j - 1]) {
                    indexMenor = j - 1;
                    min = arr[j - 1]
                }

            }
            arrLab.push(arrL[indexMenor]);
            arrAberto.push(arr[indexMenor]);
            arrAnda.push(arrAn[indexMenor]);
            arr.splice(indexMenor, 1);
            arrL.splice(indexMenor, 1);
            arrAn.splice(indexMenor, 1);
            index++;
        }
        const dado = {
            filtro: {
                dtInicio: dtInicio,
                dtFim: dtFim
            },
            graficoDia: {
                labelDias: labelDias,
                abertoPorDia: abertoPorDia,
                concluidoPorDia: concluidoPorDia,
                canceladoPorDia: canceladoPorDia
            },
            totalChamado: {
                labels: nomeChart,
                data: numeroChart
            },
            tipoChamado: {
                labels: tipoChart,
                data: numTipoChart
            },
            chamadoAndamentoAberto: {
                labels: arrLab,
                andamento: arrAnda,
                aberto: arrAberto
            }
        }

        fetch("/api/suporte/analise.pdf", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }

    function deParaSituacao(de) {
        if (de === 'ABERTO') return "Aberto";
        if (de === 'FECHADO') return "Fechado";
        if (de === 'CONCLUIDO') return "Concluído";
        if (de === 'CANCELADO') return "Cancelado";
        if (de === 'INICIADO') return "Iniciado";
        if (de === 'ANDAMENTO') return "Em atendimento";
    }
    function deParaTipo(de) {
        if (de === 'PROBLEMA_ACESSO') return "Problema de acesso";
        if (de === 'ERRO_SISTEMA') return "Erro no sistema";
        if (de === 'SUGESTAO_MELHORIA') return "Sugestão de melhoria";
        if (de === 'DUVIDA_NEGOCIAL') return "Dúvida negocial";
        if (de === 'DUVIDA_USO_SISTEMA') return "Dúvida uso do sistema";
        if (de === 'INCONSISTENCIA_DADO') return "Inconsistencia de dado";
        if (de === 'OUTROS') return "Outros";

        return "?"
    }

    function disabledBuscar() {
        if (error.data.erro) return true;
        else if (dtFim === null && dtInicio === null) return false;
        else if (dtFim !== null && dtInicio !== null) return false;
        return true;
    }

    return (
        <Grid container spacing={1} className={classes.root} >
            <Grid item xs={12}>
                <form className={classes.root} noValidate autoComplete="off">
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                        <Box component="span" m={2}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                id="dataNascimento"
                                label="Data início análise"
                                format="dd/MM/yyyy"
                                value={dtInicio}
                                onChange={handleDateChangeInicio}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                inputVariant="outlined"
                                invalidDateMessage="Formato inválido"
                            />
                        </Box>
                        <Box component="span" m={2}>
                            <KeyboardDatePicker
                                error={error.data.erro}
                                helperText={error.data.msg}
                                disableToolbar
                                variant="inline"
                                id="dataNascimento"
                                label="Data fim análise"
                                format="dd/MM/yyyy"
                                value={dtFim}
                                onChange={handleDateChangeFim}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                inputVariant="outlined"
                                invalidDateMessage="Formato inválido"
                            />
                        </Box>
                        <Box component="span" m={2}>
                            <Button variant="contained" color="primary" disabled={disabledBuscar()} onClick={() => buscarSuporte()}>
                                Buscar
                            </Button>
                        </Box>

                    </MuiPickersUtilsProvider>
                </form>
            </Grid>
            <Grid item xs={12}>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Quantidade total de chamados</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid item xs={6}>
                            <PieChart titulo={"Total chamados por situação"} grafico={situacaoChamados} />
                        </Grid>
                        <Grid item xs={6}>
                            <PieChart titulo={"Total chamados por tipo solicitação"} grafico={tipoChamados} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Quantidade de chamados por dia</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <VerticalBarPorDia titulo={"Total de Chamados"} dataPorDia={dataPorDia} />

                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Backlog chamados abertos e em atendimento</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <VerticalBar titulo={"Backlog chamados abertos e em atendimento"} abertoAndamento={chamadoAndamentoAberto} />

                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>

                <Button variant="contained" color="primary" onClick={relatorio} rel="noopener noreferrer">
                    Imprimir
                </Button>
            </Grid>
        </Grid >


    );
}
