
const ProcessoService = {
    obterPorId: function (id) {
        return fetch(`/api/processoProdf/${id}`).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    },
    obterPorCnpjs: function (numerosCnpj) {
        return fetch(`/api/gerenciarProcesso/${!numerosCnpj ? '' : 'filtro'}`, {
            method: !numerosCnpj ? 'GET' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Bearer ${localStorage.accessToken}`
            },
            body: !numerosCnpj ? null : JSON.stringify({
                cnpjs: numerosCnpj
            }),
        }).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    }
};

export default ProcessoService;