import React, { useState, useEffect } from 'react';

import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
} from '@material-ui/core';
import ConfirmDialog from "../../../../util/ConfirmDialog";
import BuscaDadoDialog from "../../../../util/BuscaDadoDialog";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RemoveIcon from '@material-ui/icons/Remove';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles, getQueryParams, ValidarCPF } from "../../../../constants";
import { useCurrentUser } from "../../../../server/UseCurrentUser";
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Download from "../../../../util/Download";
import SearchIcon from '@material-ui/icons/Search';
import MaskedInput from 'react-text-mask'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useUnidades } from "../../../../server/UseUnidades";

function TelMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TelMask2(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
export const useStylesLocal = makeStyles((theme) => ({
    btns: {
        marginTop: '10px',
        '& > *': {
            margin: '4px'
        },
    },
}));

const tipos = ['Contra Cancelamento', 'Contra Indeferimento', 'Outros'];

export default function ManterRecursoPanel(props) {
    const classes = useStyles();
    const [processo, setProcesso] = useState();
    const location = useLocation();

    const { setMensagem, setTitulo, setWait, setErro, comCache, disable } = props;
    let { id } = useParams();
    const param = getQueryParams(useLocation().search);
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [arquivo, setArquivo] = useState();
    const [anexos, setAnexos] = useState([{ tipo: -1 }]);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [error, setError] = useState(resetErro());
    const [showConfirmConclusao, setShowConfirmConclusao] = useState(false);
    const [processos, setProcessos] = useState([]);
    const [showTel1, setShowTel1] = useState(false);
    const [podeEditarRequerente, setPodeEditarRequerente] = useState(!visivelEnviar());
    const [desabilitado, setDisabilitado] = useState((disable && disable === true) ? true : false);
    const [unidades] = useUnidades();

    const [value, setValue] = useState({
        programa: '',
        responsavel: '',
        processo: {
            nProcesso: ''
        },
        descricao: '',
        declaracao: false,
        protocolo: '',
        tipo: '',
    });
    const history = useHistory();

    let [profile] = useCurrentUser();

    useEffect(() => {
        if (profile) {
            if (comCache) {
                const process = JSON.parse(localStorage.getItem("workingProcess"));
                if (process) {
                    setProcesso(process);
                    setValue({
                        ...value,
                        processo: {
                            id: process.id,
                            nProcesso: process.dadosEmpresa.nProcesso,
                            cnpj: process.dadosEmpresa.cnpj,
                            razaoSocial: process.dadosEmpresa.razaoSocial,
                            programa: process.dadosEmpresa.programa
                        }
                    });
                }
            }
            buscaDados();
        }
    }, [profile]);

    function isView() {
        return location.pathname.includes("View");
    }
    function buscaDados() {
        id = param.get('id');
        if (comCache) {
            const process = localStorage.getItem("workingProcess");
            setProcesso(JSON.parse(process));
        }

        if (id) {
            setWait(true);
            fetch('/api/processoProdf/recurso/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        setValue({
                            ...json,
                            nome: json.solicitante.nome,
                            cpf: json.solicitante.cpf ? json.solicitante.cpf : '',
                            email: json.solicitante.email,
                            telefone: json.solicitante.telefone ? json.solicitante.telefone : ''
                        });
                        if (json.documentos) setAnexos(json.documentos);
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        } else {
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarRecursoForm");
            }
        }
    }

    useEffect(() => {
        setTitulo("Cadastrar Recurso");
    }, []);

    const handleLostFocusCPF = (e) => {
        setError({
            ...error,
            cpf: {
                erro: false,
                msg: ''
            }
        });
        if (!value.cpf) return;
        const cpf = value.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();

        if (cpf.length === 0) return;

        if (ValidarCPF(cpf)) {
            // TODO buscar ou não CPF
            setWait(true);
            fetch("/api/processoProdf/requerimento/buscaSolicitante/" + cpf)
                .then(res => {
                    if (res.status <= 300) return res.json()
                })
                .then(
                    (result) => {
                        setWait(false);
                        setError(resetErro);
                        if (!result) {
                            setPodeEditarRequerente(false);
                            setValue({
                                ...value, nome: ''
                            });
                        } else {
                            setValue({
                                ...value,
                                idUser: result.id,
                                nome: result.name,
                                email: result.email,
                                telefone: result.telefone
                            });
                            setPodeEditarRequerente(true);
                        }
                    },
                    (error) => {
                        setWait(false);
                    }
                )
        } else {
            setError({
                ...error,
                cpf: {
                    erro: true,
                    msg: 'CPF inválido'
                }
            });
        }
    }

    const handleTelLostFocus = () => {
        setShowTel1(value.telefone.trim().length !== 16);
    }
    const handleTelFocus = () => {
        setShowTel1(false);
    }
    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };
    const handleCheck = (e) => {
        setValue({
            ...value,
            declaracao: !value.declaracao
        });
    }

    function confirmaAprovacao() {
        setError(resetErro());
        let dado = { ...value };

        dado.solicitante = {
            id: value.idUser,
            nome: value.nome,
            email: value.email,
            telefone: value.telefone,
            cpf: value.cpf,
        };

        dado.historico = [{
            tipo: dado.status,
            data: new Date(),
            texto: "Cadastro via UNATE",
            responsavel: {
                id: profile.id,
                nome: profile.name,
                email: profile.email
            }
        }];

        dado.documentos = [];
        anexos.forEach(a => {
            if (a.tipo !== -1) dado.documentos.push(a);
        });
        dado.status = "EM_ANALISE";

        if (valida(dado)) {
            submitForm(dado);
        }
    }

    function submitForm(dado) {
        setWait(true);
        fetch('/api/processoProdf/recurso', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                if (dado.status === "RASCUNHO") {
                    setMensagem("Rascunho salvo com sucesso.")
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    response.json().then(json => setValue({ ...value, id: json.id }));
                } else {
                    response.json().then(json => {
                        setValue({
                            ...value,
                            id: json.id,
                            protocolo: json.protocolo
                        });
                        setShowConfirmConclusao(true);
                    }
                    );
                }
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function sair() {
        if (comCache) {
            setTimeout(function () {
                history.replace("/gerenciaProcesso/unate/recursos?id=" + value.processo.id + "&back=/gerenciaProcesso");
            }, 300);
        } else {
            history.replace("/home");
            // history.goBack();//TODO tem que pensar uma forma melhor de ficar isso
        }
    }
    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: { erro: false, msg: '' },
            telefone: { erro: false, msg: '' },
            email: { erro: false, msg: '' }
        }
    }
    function valida(dado) {
        if (!dado.documentos || dado.documentos.length === 0) {
            setMensagem("Obrigatório informar os documentos para analise");
            return false;
        }
        if (!dado.declaracao) {
            setMensagem("Para prosseguir a analise é necessario declararar a entrega total da documentação");
            return false
        }
        return true
    }

    const handleEscolheFile = () => {
        let file = document.getElementById("file");
        file.click();
    }
    const handleAnexar = (e) => {
        let arr = [...anexos];
        arr.push({
            tipo: tipoDocumento,
            nome: arquivo.nome,
            uuid: arquivo.uuid
        });
        setAnexos(arr);
        setArquivo();
        setTipoDocumento('');
    }
    function visivelEnviar() {
        if (!param.get("vizualizacao")) {
            return true;
        }

        return param.get("vizualizacao") === "true";
        // if( isView() ) return false;
        // return !(value && value.status && value.status === 'EM_ANALISE');
    }

    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    if (!result) {
                        return;
                    }
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();
                    let arr = [];
                    arr.push({
                        tipo: tipoDocumento,
                        nome: nm,
                        uuid: result.uuid
                    });
                    anexos.forEach(a => {
                        if (a.tipo !== -1) arr.push(a)
                    });
                    arr.push({ tipo: -1 });
                    setAnexos(arr);

                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }

    function podeEnviar() {
        //if (value.processo.nProcesso.length === 0) return true;
        if (!value.declaracao) return true;
        if (anexos.length === 1) return true;
        if (value.tipo.length === 0) return true;
        if (!value.nome || value.nome.length === 0) return true;
        if (value && value.cpf) {
            const cpf = value.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
            if (cpf.length === 0) return true;
        }
        if (value && value.telefone) {
            const telefone = value.telefone.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
            if (telefone.length === 0) return true;
        }
        if (!value.email) return true;

        return false;
    }

    function atualizaProcesso(pro) {

        setProcesso(pro);
        setValue({
            ...value,
            processo: {
                id: pro.idProcesso,
                nProcesso: pro.nProcesso,
                cnpj: pro.cnpj,
                razaoSocial: pro.razaoSocial,
                programa: pro.programa
            }
        });

    }

    function removeArquivo(item) {
        let arr = [];
        anexos.forEach(it => {
            if (it.uuid !== item.uuid) {
                arr.push(it);
            }
        });
        setAnexos(arr);
    }

    const buscaDado = () => {

        setWait(false);
        fetch("/api/gerenciarProcesso/filtro",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify({
                    cnpjs: profile.empresas
                }),
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setMensagem(result);
                } else {
                    let arr = result;

                    for (let x in arr) {
                        const item = unidades.find(u => u.id === arr[x].idUnidade);
                        if (item) {
                            arr[x].unidade = item.nome;
                        }
                    }
                    setProcessos(arr);
                    setShowBuscaDadoDialog(true);
                }
            });
    }

    return (
        <Container maxWidth='md'>
            <Paper >

                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={12}>
                        <TextField
                            value={profile && profile.name || ''}
                            disabled
                            fullWidth
                            label="Responsável pelo Preechimento"

                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            value={value.processo.programa || ''}
                            disabled
                            fullWidth
                            label="Programa"
                            id="programa"
                            name="programa"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={isView() ? 12 : 11}>
                        <TextField
                            value={value && value.processo && value.processo.nProcesso || ''}
                            disabled
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />  
                    </Grid>
                    {!isView() &&
                        <Grid item xs={1}>
                            <Tooltip title='Buscar Processo'>
                                <IconButton onClick={buscaDado} >
                                    <SearchIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>}
                    <Grid item xs={6}>
                        <TextField
                            value={value.protocolo} disabled
                            fullWidth
                            label="Protocolo"
                            id="protocolo"
                            name="protocolo"
                            // onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                 
                    <Grid item xs={6}>
                        <TextField
                            value={value.processo.razaoSocial || ''}
                            disabled
                            fullWidth
                            label="Razão social"
                            id="razaoSocial"
                            name="razaoSocial"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={value.processo.cnpj || ''}
                            disabled
                            fullWidth
                            label="Cnpj"
                            id="cnpj"
                            name="cnpj"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth disabled={desabilitado}>
                            <InputLabel id="tipo" >Tipo recurso</InputLabel>
                            <Select id="tipo" name='tipo' label="Tipo recurso" placceholder="Tipo recurso" onChange={handleChangeInputForm} value={value.tipo} >
                                {tipos && tipos.map((tp, i) => (
                                    <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            disabled={desabilitado}
                            label="CPF"
                            value={value.cpf || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleLostFocusCPF}
                            error={error.cpf.erro}
                            helperText={error.cpf.msg}
                            name="cpf"
                            id="cpf-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: CPFMask,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled={podeEditarRequerente}
                            value={value.nome || ''}
                            onChange={handleChangeInputForm}
                            name="nome"
                            fullWidth
                            label="Requerente"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleTelLostFocus}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'none' : 'block' }}
                            name="telefone"
                            id="tel-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask,
                            }}
                            variant="outlined"
                        />
                        <TextField
                            disabled={desabilitado}
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'block' : 'none' }}
                            name="telefone"
                            id="tel-mask-input1"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask2,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.email.erro}
                            helperText={error.email.msg}
                            fullWidth
                            label="Email"
                            value={value.email || ''}
                            onChange={handleChangeInputForm}
                            // InputProps={{
                            //     readOnly: !disable,
                            // }}
                            name="email"
                            id="email-input"
                            variant="outlined"
                        />
                    </Grid>
                    {
                        value.descricao &&
                        <Grid item xs={12}>
                            <CKEditor
                                editor={ClassicEditor} disabled={true}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={value.descricao}
                                onInit={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "200px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        </Grid>
                    }


                    <Grid item xs={12}>
                        <Grid container spacing={1} className={classes.root}>

                            <Grid item xs={12}>
                                <TableContainer component={Paper} className={classes.root}>
                                    <Table aria-label="customized table" size={'small'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Documento
                                                </TableCell>
                                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                                    Ação
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {anexos && anexos.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{row.nome}</TableCell>
                                                    <TableCell align="center">
                                                        {row.tipo === -1 && visivelEnviar() &&
                                                            <Tooltip title="Incluir documento">
                                                                <IconButton onClick={(e) => handleEscolheFile()}>
                                                                    <AttachFileIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {row.tipo !== -1 &&
                                                            <center>
                                                                <Download doc={row} />
                                                                {visivelEnviar() &&
                                                                    <Tooltip title='Remover'>
                                                                        <IconButton onClick={(e) => removeArquivo(row)} >
                                                                            <RemoveIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </center>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {visivelEnviar() &&
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={value.declaracao}
                                            checked={value.declaracao}
                                            onChange={handleCheck}
                                            name="declaracao"
                                            color="primary"
                                        />
                                    }
                                    label="DECLARO A ENTREGA TOTAL DA DOCUMENTAÇÃO"
                                />
                            }
                        </Grid>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                            Sair
                        </Button>

                        {visivelEnviar() &&
                            <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} disabled={podeEnviar()}>
                                Cadastrar
                             </Button>
                        }
                    </Grid>
                    <ConfirmDialog
                        open={showConfirmConclusao}
                        btn={'OK'}
                        disableCancelar={true}
                        setOpen={setShowConfirmConclusao}
                        titulo={'cadastramento de requerimento criado com sucesso'}
                        msg={'numero do protocolo gerado: ' + value.protocolo}
                        exec={sair}
                    />
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirma cadastro de requerimento?'
                        exec={confirmaAprovacao}
                    />
                    <BuscaDadoDialog
                        open={showBuscaDadoDialog}
                        setOpen={setShowBuscaDadoDialog}
                        acao={atualizaProcesso}
                        colunas={[
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Nome', campo: 'razaoSocial' },
                            { nome: 'Processo', campo: 'nProcesso' },
                            { nome: 'Unidade', campo: 'unidade' },
                        ]}
                        dados={processos} />
                </Grid>

            </Paper>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />

        </Container>
    );
}
