import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Container, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import VistoriaForm from './VistoriaForm';
import DadosAgendaForm from './DadosAgendaForm';
import DadosLaudoVistoraForm from './DadosLaudoVistoraForm';
import HistoricoVistoriaForm from './HistoricoVistoriaForm';
import FluxoPanel from "../../util/FluxoPanelBPM";
import { useParams } from "react-router-dom";
import { useStyles, getQueryParams } from "../../constants";
import Service from "../../services/Service";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

let laudoDescricao = "";

export default function AlteraVistoriaTab(props) {
    // codigo copiado do VistoriaTab, e alterado!
    const classes = useStyles();
    let { id } = useParams();
    const { setTitulo, setMensagem, setWait, setErro, agendar,  disable, laudo, devolvida } = props;

    const [value, setValue] = React.useState('cad');
    const [fluxo, setFluxo] = useState();
    const history = useHistory();
    const param = getQueryParams(useLocation().search);
    const [vistoria, setVistoria] = useState();

    const [fluxoPath, setFluxoPath] = useState();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [tituloDialog, setTituloDialog] = useState("motivo devolver");
   
    const inputEl = useRef(null);

    useEffect(() => {
        buscaFluxo();
        bucaVistoria();

    }, []);
    useEffect(() => {
        if (vistoria) {
            let arr = [];
            for (let x in vistoria.historico) {
                const h = vistoria.historico[x];
            }
            setFluxoPath(arr)
        }
    }, [vistoria]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function bucaVistoria() {
        fetch('/api/processoProdf/vistoria/' + param.get("id"))
            .then(response => response.json())
            .then(json => {
                setVistoria(json);
                if (showLaudo()) {
                    setValue('laudo');
                }
            })
            .catch(erro => setErro(erro));
    }
    function buscaFluxo() {
        fetch('/api/info/fluxoVistoria')
            .then(response => response.json())
            .then(json => setFluxo(json))
            .catch(erro => setErro(erro));
    }

    function devolver() {
        id = param.get('id')
        const descricao = document.getElementById("descricao").value;
        const dado = {
            id: id,
            descricao: descricao,
        }
        Service.POST('/api/processoProdf/vistoria/devolver', dado).then(r =>{
            handleCloseDialog();
            history.replace("/vistoria/agendar")
        }).catch(error =>{
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');            
        })
        /*fetch('/api/processoProdf/vistoria/devolver', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                handleCloseDialog();
                history.replace("/vistoria/agendar")
            } else {
                response.json().then(erro => {
                    setErro(erro.message);
                })
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });*/
    }
    function setLaudo(txt) {
        laudoDescricao = txt;
    }
    // const handleConcluirLaudo = () => {
    //     const json = {
    //         id: vistoria.id,
    //         laudo: laudoDescricao
    //     };

    //     fetch('/api/processoProdf/vistoria/concluirLaudo', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json',
    //             Authorization: `Bearer ${localStorage.accessToken}`
    //         },
    //         body: JSON.stringify(json),
    //         credentials: 'include'
    //     }).then(response => {
    //         if (response.ok) {
    //             setWait(false);
    //             setMensagem("Termo de vistoria atualizaddo");
    //             history.replace("/vistoria/executar");
    //             // response.json().then(ret => setVistoria(ret) );
    //         } else {
    //             response.json()
    //                 .then(error => {
    //                     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    //                 })
    //         }
    //     }).catch(error => {
    //         setWait(false);
    //         setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    //     });
    // }

    // function sair() {
    //     // history.replace("/vistoria/solicitar");
    //     history.goBack();

    // }
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    function showLaudo() {
        if (laudo && laudo === true) return true;
        if (vistoria && (vistoria.situacao === 'EM_EXECUCAO'
            || vistoria.situacao === 'EXECUTADO'
            || vistoria.situacao === 'VALIDADO_DIRETOR'
            || vistoria.situacao === 'VALIDACAO_GERENTE'
        )
        ) return true;
        return false;
    }


    return (
        <Container maxWidth='lg'>

            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                    <Tab
                        value="cad"
                        label="Dados solicitação"
                        wrapped
                        {...a11yProps('cad')}
                    />

                    {/* <Tab value="agenda" label="Dados agenda" {...a11yProps('agenda')} /> */}


                    {/* <Tab value="laudo" label="Dados Laudo vistoria" {...a11yProps('agenda')} /> */}

                    <Tab value="hist" label="Histórico" {...a11yProps('historico')} />
                    
                    {/* <Tab value="sit" label="Situação" {...a11yProps('sit')} /> */}
                </Tabs>
            </AppBar>

            <TabPanel value={value} index="cad">
                <VistoriaForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} vistoria={vistoria} setVistoria={setVistoria} alterar={true} disable={disable || agendar || laudo} devolvida={devolvida} />
            </TabPanel>

            <TabPanel value={value} index="agenda">
                <DadosAgendaForm setWait={setWait} setMensagem={setMensagem} vistoria={vistoria} disable={disable} />
            </TabPanel>

            <TabPanel value={value} index="laudo">
                <DadosLaudoVistoraForm setWait={setWait}
                    setMensagem={setMensagem} vistoria={vistoria}
                    setLaudo={setLaudo}
                    setErro={setErro}
                    disable={disable} />
            </TabPanel>

            <TabPanel value={value} index="hist">
                <HistoricoVistoriaForm setWait={setWait} setMensagem={setMensagem} vistoria={vistoria}  alterar={true}/>
            </TabPanel>

            <TabPanel value={value} index="sit">
                {fluxo &&
                    <FluxoPanel fluxo={fluxo} path={fluxoPath} />
                }
            </TabPanel>


            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth >
                <DialogTitle id="form-dialog-title">{tituloDialog}:</DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        id="descricao"
                        name="descricao"
                        //label={tituloDialog}
                        multiline
                        rows={6}
                        //value={''}
                        //onChange={handleChangeInputForm}
                        //inputProps={{ readOnly: (disable && disable === true) }}
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)} rel="noopener noreferrer">
                        Sair
                        </Button>
                    <Button variant="contained" color="primary" onClick={() => devolver()} >
                        Confirmar
                        </Button>
                </DialogActions>
            </Dialog>
         
        </Container>
    );
}
