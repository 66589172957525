import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography,
    Accordion, AccordionSummary, AccordionDetails, Divider
} from '@material-ui/core';
import { useStyles, getQueryParams, API_BASE_URL } from "../../../constants";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InformacaoBasicaForm from "./InformacaoBasicaForm";
import GenericTable from '../../template/GenericTable';

const fonteDeDados = [{ socio: "aa", rg: 'bb', cpf: '111.111.111-11' }, { socio: "aa", rg: 'bb', cpf: '111.111.111-11' }]

export default function InformacaoProgramaDesenvolvimento(props) {
    const classes = useStyles();
    
    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>DESENVOLVE-DF</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <p>A Lei distrital nº 6.468, de 2019 foi responsável pela completa reformulação do Programa de
                            Apoio ao Empreendimento Produtivo do Distrito Federal – PRÓ-DF II e pela criação de um novo
                            sistema de concessão de benefício econômico: o da Concessão de Direito Real de Uso – CDRU
                            (sem opção de compra) pelo Programa de Apoio ao Desenvolvimento do
                                Distrito Federal – Desenvolve-DF.</p>
                            <p>
                                Apesar das modificações, o objetivo do Programa continua o mesmo: ampliar a capacidade da
                                economia local na produção de bens e serviços e na efetiva geração de emprego, renda,
                                receita tributária, além de promover o desenvolvimento econômico social, sustentável e
                                integrado do Distrito Federal.
                                </p>
                            <p>
                                Mas, a partir de agora, o ingresso das empresas no Desenvolve-DF dependerá de procedimento
                                licitatório e da apresentação, análise e aprovação de Projeto de Viabilidade Simplificado – PVS.
                                </p>
                            <p>
                                Para levantamento sobre a possibilidade de inclusão de imóvel(is) em licitação pública de
                                CDRU do Desenvolve-DF, as interessadas deverão indicar o(s) imóvel(is) pretendido(s) por
                                meio do REQUERIMENTO INCLUSÃO LOTE EM LICITAÇÃO CDRU – DESENVOLVE-DF.
                                </p>
                            <p>
                                Realizada a licitação pública de CDRU – Desenvolve-DF, a empresa vencedora deverá apresentar
                                o REQUERIMENTO CONCESSÃO DESENVOLVE-DF junto ao PVS CONCESSÃO DESENVOLVE-DF (empresa vencedora).
                                </p>
                            <p>
                                ATENÇÃO:
                                </p>
                            <p>
                                Os requerimentos apresentados deverão estar acompanhados de TODA a documentação indicada.
                                Não será realizada a análise técnica acerca do pedido se a documentação apresentada não
                                estiver completa (art.84, parágrafo único, do Decreto distrital nº 41.015, de 2020).
                                </p>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>NOVAS POSSIBILIDADES – LEI Nº 6.468/2019</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <p>
                                Para aquelas empresas que já participaram ou que ainda participam do Pró-DF II ou de programas
                                anteriores, a Lei distrital nº 6.468/2019 trouxe algumas possibilidades, dentre as quais:

                            </p>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>1 – MIGRAÇÃO DE PROGRAMAS ANTERIORES</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} >
                            <Grid item xs={12}>
                                <p>
                                    É a possibilidade de regularização dos empreendimentos beneficiados pelos programas de
                                    desenvolvimento econômico PROIN-DF, PRODECON-DF, PADES-DF e PRÓ-DF pelo benefício econômico
                                    previsto no Programa de Apoio ao Empreendimento Produtivo do Distrito Federal – PRÓ-DF II,
                                    desde que a beneficiária tenha assinado o Contrato de Concessão de Direito Real de Uso com
                                    Opção de Compra – CDRU-C no âmbito de qualquer dos programas anteriores.
                                </p>
                                <p>     O prazo para solicitação de Migração para o PRÓ-DF II será até 04/02/2021, em todas as
                                    hipóteses (pedido de migração isolado ou concomitante com qualquer outro).</p>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>MIGRAÇÃO DE PROGRAMAS ANTERIORES</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        (Art. 11 da Lei nº 6.468/2019 e Arts. 29 a 31 do Decreto nº 41.015/2020)
                                        <br />
                                        É a possibilidade de regularização dos empreendimentos beneficiados pelos programas de desenvolvimento econômico PROIN-DF, PRODECON-DF, PADES-DF e PRÓ-DF pelo benefício econômico previsto no Programa de Apoio ao Empreendimento Produtivo do Distrito Federal – PRÓ-DF II, desde que a beneficiária tenha assinado o Contrato de Concessão de Direito Real de Uso com Opção de Compra – CDRU-C no âmbito de qualquer dos programas anteriores.
                                        O prazo para solicitação de Migração para o PRÓ-DF II será até 04/02/2021, em todas as hipóteses (pedido de migração isolado ou concomitante com qualquer outro).
                                        A sistemática desse pedido é um pouco diferente das demais, já que, segundo a Lei distrital nº 4.269/2008, a análise será feita em dois momentos distintos: primeiro, a interessada apresenta o REQUERIMENTO MIGRAÇÃO PRÓ-DF II (Para análise de admissibilidade), que será objeto de análise técnica de admissibilidade pela Secretaria de Empreendedorismo.
                                        Considerada admissível a migração, a interessada será notificada e será aberto o prazo de 2 meses para apresentação do PVS MIGRAÇÃO PRÓ-DF II, junto ao REQUERIMENTO MIGRAÇÃO PRÓ-DF II (Após deferida Admissibilidade).
                                </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>1.1 – MIGRAÇÃO + TRANSFERÊNCIA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        Juntamente à migração para o PRÓ-DF II, a beneficiária originária poderá requerer a
                                        transferência do incentivo dentro do mesmo prazo (até 04/02/2021), por meio do
                                        REQUERIMENTO MIGRAÇÃO + TRANSFERÊNCIA.
                                   </p>
                                    <p>
                                        Considerada admissível a migração, a interessada será notificada e será aberto o
                                        prazo de 2 meses para apresentação do PVS MIGRAÇÃO + TRANSFERÊNCIA, junto ao
                                        REQUERIMENTO MIGRAÇÃO + TRANSFERÊNCIA (Após deferida Admissibilidade).
                                   </p>
                                    <p>
                                        Essa opção também poderá ocorrer nos casos em que o pedido de migração pela
                                        incentivada originária seja considerado inadmissível, hipótese na qual, a
                                        beneficiária terá o prazo de 6 meses, contados da ciência da inadmissibilidade
                                        ou do indeferimento da migração, para requerer a transferência do incentivo
                                        concomitantemente à migração.
                                   </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>1.2 – MIGRAÇÃO + REVOGAÇÃO</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        Ainda é possível que incentivos cancelados se beneficiem da migração, desde que seja
                                        requerida, de forma concomitante, a revogação administrativa por meio do REQUERIMENTO
                                        MIGRAÇÃO + REVOGAÇÃO.
                                   </p>
                                    <p>
                                        Atendidos os requisitos legais para revogação do cancelamento e admitida a migração
                                        pela SEDET/DF, a interessada será notificada e será aberto o prazo de 2 meses para
                                        apresentação do PVS MIGRAÇÃO + REVOGAÇÃO, junto ao REQUERIMENTO MIGRAÇÃO
                                        (Após deferida Admissibilidade) + REVOGAÇÃO.
                                   </p>

                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>1.3 – MIGRAÇÃO + REVOGAÇÃO + TRANSFERÊNCIA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        O pedido de revogação administrativa do cancelamento cumulado ao de migração
                                        também poderá vir acompanhado do pedido de transferência de incentivo, devendo
                                        a interessada apresentar o REQUERIMENTO REVOGAÇÃO + MIGRAÇÃO + TRANSFERÊNCIA.
                                   </p>
                                    <p>
                                        A sistemática permanece: somente após a análise sobre o atendimento aos requisitos
                                        para a revogação administrativa do cancelamento e quanto à admissibilidade do pedido
                                        de migração é que será aberto o prazo de 2 meses para apresentação para apresentação
                                        do PVS MIGRAÇÃO + REVOGAÇÃO + TRANSFERÊNCIA, junto ao REQUERIMENTO MIGRAÇÃO +
                                        TRANSFERÊNCIA (Após deferida Admissibilidade).
                                   </p>
                                    <p>ATENÇÃO:</p>
                                    <p>
                                        Os requerimentos apresentados deverão estar acompanhados de TODA a documentação indicada.
                                        Não será realizada a análise técnica acerca do pedido se a documentação apresentada não
                                        estiver completa (art.84, parágrafo único, do Decreto distrital nº 41.015, de 2020).
                                          </p>

                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>2 – CONVALIDAÇÃO DO BENEFÍCIO ECONÔMICO</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} >
                            <Grid item xs={12}>
                                <p>
                                    Permite que as empresas enquadradas nas situações previstas nos arts. 1º ou 9° da Lei
                                    distrital nº 6.251/2018 assinem Contrato de Concessão de Direito Real de Uso com Opção
                                    de Compra – CDRU-C no âmbito do PRÓ-DF II.
                                </p>

                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>CONVALIDAÇÃO DO BENEFÍCIO ECONÔMICO</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        (Lei nº 6.251/2018 e Arts. 32 ao 45 do Decreto nº 41.015/2020)
                                    </p>
                                    <p>
                                        Permite que as empresas enquadradas nas situações previstas nos arts. 1º ou 9° da Lei
                                        distrital nº 6.251/2018 assinem Contrato de Concessão de Direito Real de Uso com Opção
                                        de Compra – CDRU-C no âmbito do PRÓ-DF II.
                                    </p>
                                    <p>
                                        O requerimento para a convalidação deverá ser efetuado por meio do REQUERIMENTO – CONVALIDAÇÃO
                                        PRÓDF II e estar acompanhado do PVS – CONVALIDAÇÃO PRÓ DF II.
                                    </p>
                                    <p>
                                        O prazo para solicitação de Convalidação será até 04/02/2021 em todas as hipóteses
                                        (pedido de convalidação isolado ou concomitante com qualquer outro).
                                    </p>


                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>2.1 – CONVALIDAÇÃO + REVOGAÇÃO</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        Se o incentivo inicialmente deferido tiver sido cancelado, há a possibilidade
                                        de se requerer a revogação administrativa do cancelamento de forma concomitante
                                        à convalidação por meio do REQUERIMENTO – CONVALIDAÇÃO + REVOGAÇÃO acompanhado
                                        do PVS CONVALIDAÇÃO + REVOGAÇÃO.
                                        </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>2.2 – CONVALIDAÇÃO + TRANSFERÊNCIA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        Para os incentivos que não estejam cancelados, é possível realizar a opção
                                        de transferência de forma concomitante à convalidação do incentivo, caso em
                                        que deverá ser apresentado o REQUERIMENTO CONVALIDAÇÃO + TRANSFERÊNCIA acompanhado
                                        do PVS CONVALIDAÇÃO + TRANSFERÊNCIA.
                                    </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>2.3 – CONVALIDAÇÃO + REVOGAÇÃO + TRANSFERÊNCIA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        O pedido acima poderá, ainda, ser acompanhado do pedido de transferência do incentivo,
                                        hipótese na qual deverá ser preenchido o REQUERIMENTO CONVALIDAÇÃO + REVOGAÇÃO +
                                        TRANSFERÊNCIA acompanhado do PVS CONVALIDAÇÃO + REVOGAÇÃO + TRANSFERÊNCIA.
                                    </p>
                                    <p>ATENÇÃO:</p>
                                    <p>
                                        Os requerimentos apresentados deverão estar acompanhados de TODA a documentação indicada.
                                        Não será realizada a análise técnica acerca do pedido se a documentação apresentada
                                        não estiver completa (art.84, parágrafo único, do Decreto distrital nº 41.015, de 2020).
                                    </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>3 – REVOGAÇÃO ADMINISTRATIVA DO CANCELAMENTO</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} >
                            <Grid item xs={12}>
                                <p>
                                    (Arts. 8º e 9º da Lei nº 6.468/2019 e Arts. 23 a 27 do Decreto nº 41.015/2020)
                                </p>
                                <p>
                                    A empresa que tenha tido seu benefício econômico cancelado poderá requerer a revogação
                                    administrativa do cancelamento, desde que esteja em funcionamento no endereço incentivado
                                    e gerando, no mínimo, 70% dos empregos previstos, observados os demais requisitos da
                                    legislação.
                                </p>
                                <p>
                                    Os requerimentos apresentados deverão estar acompanhados de TODA a documentação indicada.
                                    Não será realizada a análise técnica acerca do pedido se a documentação apresentada
                                    não estiver completa (art.84, parágrafo único, do Decreto distrital nº 41.015, de 2020).
                                </p>
                                <p>
                                    O pedido será objeto de análise técnica pela Secretaria de Empreendedorismo e,
                                    posteriormente, submetido ao Conselho de Gestão do Programa de Apoio ao Empreendimento
                                    Produtivo – COPEP.
                                </p>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>REVOGAÇÃO ADMINISTRATIVA DO CANCELAMENTO</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        (Arts. 8º e 9º da Lei nº 6.468/2019 e Arts. 23 a 27 do Decreto nº 41.015/2020)
                                </p>
                                    <p>
                                        A empresa que tenha tido seu benefício econômico cancelado poderá requerer a revogação
                                        administrativa do cancelamento, desde que esteja em funcionamento no endereço incentivado
                                        e gerando, no mínimo, 70% dos empregos previstos, observados os demais requisitos da
                                        legislação.
                                </p>
                                    <p>
                                        O pedido será objeto de análise técnica pela Secretaria de Empreendedorismo e, posteriormente,
                                        submetido ao Conselho de Gestão do Programa de Apoio ao Empreendimento Produtivo – COPEP.
                                </p>
                                    <p>
                                        O prazo para solicitação da Revogação Administrativa de Cancelamento será até 04/02/2021
                                        e o pedido deve ser feito por meio do REQUERIMENTO REVOGAÇÃO ADMINISTRATIVA DO
                                        CANCELAMENTO acompanhado de toda a documentação indicada.
                                </p>
                                    <p>
                                        Ainda é possível que o pedido de revogação administrativa do cancelamento seja feito de
                                        forma concomitante ao de transferência, ao de migração, ao de convalidação e ao de
                                        adesão direta ao sistema de CDRU, conforme a situação específica de cada empreendimento.
                                </p>
                                    <p>
                                        Para cada uma dessas situações deverá ser preenchido o requerimento específico:
                                </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>3.1 – REVOGAÇÃO + TRANSFERÊNCIA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        A interessada deverá apresentar o REQUERIMENTO REVOGAÇÃO + TRANSFERÊNCIA junto ao PVS REVOGAÇÃO
                                        + TRANSFERÊNCIA.
                                </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>3.2 – REVOGAÇÃO + MIGRAÇÃO</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        A interessada deverá apresentar o REQUERIMENTO MIGRAÇÃO + REVOGAÇÃO e, atendidos
                                        os requisitos legais para revogação do cancelamento e admitida a migração pela SEDET/DF,
                                        a interessada será notificada e será aberto o prazo de 2 meses para apresentação do PVS
                                        MIGRAÇÃO + REVOGAÇÃO, junto ao REQUERIMENTO MIGRAÇÃO (Após deferida Admissibilidade) +
                                        REVOGAÇÃO.
                                </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>3.3 – REVOGAÇÃO + CONVALIDAÇÃO</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        A interessada deverá apresentar o REQUERIMENTO – CONVALIDAÇÃO + REVOGAÇÃO junto ao PVS CONVALIDAÇÃO
                                        + REVOGAÇÃO.
                                    </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>3.4 – REVOGAÇÃO + ADESÃO DIRETA AO DESENVOLVE-DF</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        A interessada deverá apresentar o REQUERIMENTO REVOGAÇÃO + ADESÃO DIRETA DESENVOLVE DF
                                        junto ao PVS REVOGAÇÃO + ADESÃO-AO-DESENVOLVE-DF.
                                    </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>3.5 – REVOGAÇÃO + MIGRAÇÃO + TRANSFERÊNCIA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        A interessada deverá apresentar o REQUERIMENTO REVOGAÇÃO + MIGRAÇÃO + TRANSFERÊNCIA e,
                                        atendidos os requisitos legais para revogação do cancelamento e admitida a migração pela
                                        SEDET/DF, a interessada será notificada e será aberto o prazo de 2 meses para apresentação
                                        do PVS MIGRAÇÃO + REVOGAÇÃO + TRANSFERÊNCIA, junto ao REQUERIMENTO MIGRAÇÃO + TRANSFERÊNCIA
                                        (Após deferida Admissibilidade).
                                    </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>3.6 – REVOGAÇÃO + CONVALIDAÇÃO + TRANSFERÊNCIA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        A interessada deverá apresentar o REQUERIMENTO CONVALIDAÇÃO + REVOGAÇÃO + TRANSFERÊNCIA
                                        acompanhado do PVS CONVALIDAÇÃO + REVOGAÇÃO + TRANSFERÊNCIA.
                                    </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>3.7 – REVOGAÇÃO + ADESÃO DIRETA AO DESENVOLVE-DF + TRANSFERÊNCIA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        A interessada deverá apresentar o REQUERIMENTO REVOGAÇÃO + ADESÃO DIRETA DESENVOLVE DF +
                                        TRANSFERÊNCIA, junto ao PVS REVOGAÇÃO + ADESÃO AO DESENVOLVE-DF + TRANSFERÊNCIA.
                                    </p>
                                    <p>ATENÇÃO:</p>
                                    <p>
                                        Os requerimentos apresentados deverão estar acompanhados de TODA a documentação indicada.
                                        Não será realizada a análise técnica acerca do pedido se a documentação apresentada não
                                        estiver completa (art.84, parágrafo único, do Decreto distrital nº 41.015, de 2020).
                                     </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>4 – TRANSFERÊNCIA DE INCENTIVO</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} >
                            <Grid item xs={12}>
                                <p>
                                    (Art. 7º da Lei nº 6.468/2019 e Arts. 16 a 22 do Decreto nº 41.015/2020)
                                    </p>

                                <p>
                                    É a possibilidade de a empresa inicialmente incentivada transferir a titularidade do benefício
                                    econômico para outra empresa, desde que tenha decorrido, pelo menos, 5 anos da concessão
                                    originária, ou seja, a aprovação do PVTEF (para incentivos anteriores e Pró-DF II) ou
                                    do PVS (para o novo sistema).
                                    </p>

                                <p>
                                    A empresa que receberá o incentivo assumirá todos os direitos e obrigações do benefício
                                    econômico concedido àquela que o transferirá. Assim, se não houver mais direito ao desconto
                                    no momento do exercício da opção de compra, por exemplo, a beneficiária que recebeu a
                                    transferência somente terá direito ao desconto de 10% previsto no art. 29 da Lei distrital
                                    nº 6.468, de 2019.
                                     </p>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>TRANSFERÊNCIA DE INCENTIVO</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        (Art. 7º da Lei nº 6.468/2019 e Arts. 16 a 22 do Decreto nº 41.015/2020)
                                    </p>

                                    <p>
                                        É a possibilidade de a empresa inicialmente incentivada transferir a titularidade do benefício
                                        econômico para outra empresa, desde que tenha decorrido, pelo menos, 5 anos da concessão
                                        originária, ou seja, a aprovação do PVTEF (para incentivos anteriores e Pró-DF II) ou
                                        do PVS (para o novo sistema).
                                    </p>

                                    <p>
                                        A empresa que receberá o incentivo assumirá todos os direitos e obrigações do benefício
                                        econômico concedido àquela que o transferirá. Assim, se não houver mais direito ao desconto
                                        no momento do exercício da opção de compra, por exemplo, a beneficiária que recebeu a
                                        transferência somente terá direito ao desconto de 10% previsto no art. 29 da Lei distrital
                                        nº 6.468, de 2019.
                                     </p>

                                    <p>
                                        As beneficiárias de incentivo no âmbito do Pró-DF II, deverão preencher o REQUERIMENTO
                                        TRANFERÊNCIA DE INCENTIVO – PRÓ-DF-II, acompanhado do PVS TRANSFERÊNCIA DE INCENTIVO –
                                        PRÓ-DF-II, e anexar os documentos indicados. Lembramos que é necessário colher a
                                        assinatura da empresa inicialmente incentivada.
                                     </p>
                                    <p>
                                        Os beneficiários do Desenvolve-DF deverão formular o pedido por meio do REQUERIMENTO
                                        TRANSFERÊNCIA DE INCENTIVO – DESENVOLVE-DF, acompanhado do PVS TRANSFERÊNCIA DE
                                        INCENTIVO – DESENVOLVE-DF e anexar os documentos indicados. Lembramos que é necessário
                                        colher a assinatura da empresa inicialmente incentivada e da que receberá o incentivo.
                                     </p>
                                    <p>
                                        As empresas beneficiárias de programas anteriores (PRÓ-DF, PADES, PRODECON ou PROIN),
                                        que tenham assinado contrato de Concessão de Direito Real de Uso com Opção de Compra
                                        – CDRU-C com a Terracap, terão até 04/02/2021 para solicitarem a transferência, já
                                        que esse, por força do art. 7º, 1º, inciso II da Lei distrital nº 6.468, de 2019,
                                        terão, obrigatoriamente, que fazê-lo de forma concomitantemente ao pedido de Migração.
                                     </p>
                                    <p>
                                        Deverá ser seguido o modelo REQUERIMENTO MIGRAÇÃO + TRANSFERÊNCIA e, considerada
                                        admissível a migração, a interessada será notificada e será aberto o prazo de 2 meses
                                        para apresentação do PVS MIGRAÇÃO + TRANSFERÊNCIA, junto ao REQUERIMENTO MIGRAÇÃO +
                                        TRANSFERÊNCIA (Após deferida Admissibilidade).
                                        </p>
                                    <p>
                                        Por outro lado, aquelas empresas que foram beneficiadas, mas que não assinaram o contrato
                                        de Concessão de Direito Real de Uso com Opção de Compra – CDRU-C com a Terracap, nos
                                        termos da Lei distrital nº 6.251, de 2018, também terão até 04/02/2021 para realizarem
                                        a opção de transferência, mas, o pedido concomitante será o de convalidação do incentivo. Deverá ser seguido o modelo REQUERIMENTO CONVALIDAÇÃO + TRANSFERÊNCIA, acompanhado do PVS CONVALIDAÇÃO + TRANSFERÊNCIA.
                                        </p>
                                    <p>
                                        Importante ressaltar que, para a transferência de benefícios do Pró-DF e Pró-DF II
                                        nos quais já tenha sido emitido Atestado de Implantação Definitivo – AID, a interessada
                                        no recebimento da transferência não está obrigada à apresentação do PVS.

                                        </p>
                                    <p>
                                        Ainda é possível formular pedido de transferência concomitante ao de revogação
                                        administrativa do cancelamento, desde que esteja em funcionamento no endereço
                                        incentivado e gerando, no mínimo, 70% dos empregos previstos, observados os demais
                                        requisitos da legislação, seguindo o modelo de REQUERIMENTO REVOGAÇÃO + TRANSFERÊNCIA
                                        a ser apresentado junto ao PVS REVOGAÇÃO + TRANSFERÊNCIA.
                                        </p>
                                    <p>
                                        Esse pedido de revogação com transferência do incentivo também poderá ser acompanhado do
                                        pedido de migração, quando deverá ser apresentado o REQUERIMENTO REVOGAÇÃO + MIGRAÇÃO
                                        + TRANSFERÊNCIA e, após a análise sobre o atendimento aos requisitos para a revogação
                                        administrativa do cancelamento e quanto à admissibilidade do pedido de migração, será
                                        aberto o prazo de 2 meses para apresentação para apresentação do PVS MIGRAÇÃO +
                                        REVOGAÇÃO + TRANSFERÊNCIA, junto ao REQUERIMENTO MIGRAÇÃO + TRANSFERÊNCIA
                                        (Após deferida Admissibilidade), ou do pedido de convalidação, devendo ser
                                        apresentado o REQUERIMENTO CONVALIDAÇÃO + REVOGAÇÃO + TRANSFERÊNCIA, acompanhado do PVS
                                        CONVALIDAÇÃO + REVOGAÇÃO + TRANSFERÊNCIA.
                                        </p>
                                    <p>ATENÇÃO:</p>
                                    <p>
                                        Os requerimentos apresentados deverão estar acompanhados de TODA a documentação indicada.
                                        Não será realizada a análise técnica acerca do pedido se a documentação apresentada não
                                        estiver completa (art.84, parágrafo único, do Decreto distrital nº 41.015, de 2020).
                                        </p>

                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Accordion>
                <Accordion defaultExpanded={false}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>5 – ADESÃO AO DESENVOLVE-DF</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} >
                            <Grid item xs={12}>
                                <p>
                                    As empresas beneficiárias de programas anteriores ao PRÓ-DF II poderão optar pela adesão
                                    direta ao Desenvolve-DF até 04/02/2021, dispensado o procedimento licitatório.
                                    </p>

                                <p>
                                    Para as empresas beneficiárias do PRÓ-DF II, a opção poderá ser realizada a qualquer momento.
                                    </p>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>ADESÃO AO DESENVOLVE-DF</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <p>
                                        As empresas beneficiárias de programas anteriores ao PRÓ-DF II poderão optar pela adesão
                                        direta ao Desenvolve-DF até 04/02/2021, dispensado o procedimento licitatório.
                                    </p>

                                    <p>
                                        Para as empresas beneficiárias do PRÓ-DF II, a opção poderá ser realizada a qualquer momento.
                                    </p>
                                    <p>
                                        Em ambas hipóteses, é necessário preencher o REQUERIMENTO ADESÃO DIRETA DESENVOLVE-DF e
                                        apresentar o PVS ADESÃO DIRETA DESENVOLVE-DF.
                                    </p>
                                    <p>ATENÇÃO:</p>
                                    <p>
                                        Os requerimentos apresentados deverão estar acompanhados de TODA a documentação indicada.
                                        Não será realizada a análise técnica acerca do pedido se a documentação apresentada não estiver
                                        completa (art.84, parágrafo único, do Decreto distrital nº 41.015, de 2020).
                                    </p>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Accordion>
            </Accordion>
        </Paper>
    )

}