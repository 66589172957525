import React, { useEffect, useState, } from 'react';
import {
    Button, Grid, Container, TextField
}
    from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { useStyles, getQueryParams } from "../../../constants"
import Service from '../../../services/Service';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import EtapaProcessoComp from './EtapaProcessoComp';

export default function CadastroProrrogacaoContratoForm(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setMensagem } = props;
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [processo, setProcesso] = useState({});
    const [prorrogacao, setProrrogacao] = useState({});


    useEffect(() => {
        setTitulo("Cadastrar Prorrogação Contrato");
        if (param.get("id")) {
            Service.GET("/api/processoProdf/" + param.get("id")).then(result => {
                setWait(false);
                setProcesso(result);
            }).catch(error => {
                setWait(false);
                setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
            });
        }
        var data = new Date();
        processo.dataCriacao = data;
    }, []);

    function sair() {
        if (param.get("id")) history.replace("/gerenciaProcesso/processo/" + param.get("id") + "?panel=prorrogacaoContrato");
        else history.replace("/gerenciaProcesso/cadastrarProcesso/?panel=prorrogacaoContrato");
    }

    const handleChangeInputForm = (event) => {
        setProrrogacao({
            ...prorrogacao,
            [event.target.name]: event.target.value,
        });
    };
    const handleChangeInputDataResolucao = (dt) => {
        setProrrogacao({
            ...prorrogacao,
            dataResolucao: dt,
        });
    };
    const handleChangeInputDataDescontoMax = (dt) => {
        setProrrogacao({
            ...prorrogacao,
            dataDescontoMax: dt,
        });
    };
    const handleChangeInputDataDescontoMin = (dt) => {
        setProrrogacao({
            ...prorrogacao,
            dataDescontoMin: dt,
        });
    };
    const handleChangeInputDataVencimento = (dt) => {
        setProrrogacao({
            ...prorrogacao,
            dataVencimento: dt,
        });
    };

    function salvar() {
        let arr = [];
        if (processo && processo.prorrogacaoContrato) {
            arr = processo.prorrogacaoContrato;
        }
        arr.push(prorrogacao);
        const dados = {
            processo: {
                ...processo,
                prorrogacaoContrato: arr
            }
        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=prorrogacaoContrato");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='md'>
                <Grid container spacing={3} className={classes.root}>
                    <Grid item xs={8}>
                        <TextField
                            value={prorrogacao && prorrogacao.resolucao || ''}
                            onChange={handleChangeInputForm}
                            fullWidth
                            label="Resolução/Decisão:"
                            id="resolucao"
                            name="resolucao"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                                {processo && processo.dadosEmpresa && processo.dadosEmpresa.nProcesso &&
                                    <EtapaProcessoComp nProcesso={processo.dadosEmpresa.nProcesso} indice={8} />}
                            </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            id="dataResolucao"
                            label="Data Resolução"
                            format="dd/MM/yyyy"
                            name="dataResolucao"
                            value={prorrogacao && prorrogacao.dataResolucao || null}
                            onChange={handleChangeInputDataResolucao}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                        // disabled={(disable && disable === true)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            id="dataDescontoMax"
                            label="Data Desconto Máximo:"
                            format="dd/MM/yyyy"
                            name="dataDescontoMax"
                            value={prorrogacao && prorrogacao.dataDescontoMax || null}
                            onChange={handleChangeInputDataDescontoMax}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                        // disabled={(disable && disable === true)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            id="dataDescontoMin"
                            label="Data Desconto Mínimo:"
                            format="dd/MM/yyyy"
                            name="dataDescontoMin"
                            value={prorrogacao && prorrogacao.dataDescontoMin || null}
                            onChange={handleChangeInputDataDescontoMin}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                        // disabled={(disable && disable === true)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            id="dataVencimento"
                            label="Data Vencimento:"
                            format="dd/MM/yyyy"
                            name="dataVencimento"
                            value={prorrogacao && prorrogacao.dataVencimento || null}
                            onChange={handleChangeInputDataVencimento}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                        // disabled={(disable && disable === true)}
                        />
                    </Grid>
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" onClick={() => salvar()} color="primary">
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </Container>
        </MuiPickersUtilsProvider>
    );
}