import React, {useState, useEffect} from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {Container, Backdrop, CircularProgress} from '@material-ui/core';
import { useCurrentUser } from "../server/UseCurrentUser";

import SolicitarAcessoForm from "./user/SolicitarAcessoForm";
import SolicitarAcessoAcompanhar from "./user/SolicitarAcessoAcompanhar";
import RecuperarSenhaForm from "./user/RecuperarSenhaForm";
import { Redirect } from 'react-router-dom';
import PrimeiroAcessoForm from './user/home/PrimeiroAcessoForm';
import ConsultarChamadoForm from './suporte/ConsultarChamadoForm'

import {
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";
import PainelHome from './user/home/PainelHome';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      "margin-top": "15px"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }),
);

function Home() {
    const [user, loading] = useCurrentUser();
    const classes = useStyles();
    let [profile] = useCurrentUser();
    const [painelHome, setPainelHome] = useState(false)
    const [wait, setWait] = useState(false);
    const match = useRouteMatch();
    const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });
    function setMensagem(alerta) {
      let a;
      if (typeof alerta === 'object') {
        a = { ...alerta };
        if (!a.tipo) a.tipo = 'success';
        if (!a.duracao) a.duracao = 5000;
        a.visibilidade = true;
      } else {
        a = {
          tipo: 'info',
          duracao: 5000,
          visibilidade: true,
          mensagem: alerta
        }
      }
    
      setAlerta(a);
      if (!!a.duracao && a.duracao > 0) {
        window.setTimeout(() => {
          setAlerta({ tipo: a.tipo, visibilidade: false });
        }, a.duracao)
      }
    }

    // useEffect(() => {
    //   if (profile) {
    //     if (profile.regras.find((i) =>
    //       i === "032" ||
    //       i === "033" ||
    //       i === "022" ||
    //       i === "039" ||
    //       i === "030" ||
    //       i === "041"
    //     )) {
    //       setPainelHome(true)
    //     }
    //   }
    // }, [profile])

    function Redireciona(){
      if( !localStorage.accessToken){
        return (<Redirect to="/login" />);
      }
      // else if(painelHome ){
      //   return ( <Redirect to="/home/painel" /> );
      // }
      // if (user && user.perfis && user.perfis.find( f => f === "SUPORTE")){
      //   return (<Redirect to="/suporte/listaChamados" />);
      // }
      else{
        return (<h2>Seja bem vindo: {user && (user.name?user.name:user.login) }</h2>);
      }
    }
    return (
      <Container maxWidth='xl' className={classes.root}>
        <div>
        <Backdrop className={classes.backdrop} open={loading || wait}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Switch>

          <Route path={`${match.path}/solicitarAcesso/:id`}>
            <SolicitarAcessoAcompanhar/>
          </Route>

          <Route path={`${match.path}/SolicitarAcessoForm`}>
            <SolicitarAcessoForm />
          </Route>

          <Route path={`${match.path}/recuperarSenha`}>
            <RecuperarSenhaForm/>
          </Route>

          <Route path={`${match.path}/ConsultarChamadoForm`}>
              <ConsultarChamadoForm  setWait={setWait} setMensagem={setMensagem}/>
            </Route>

          <Route path={`${match.path}/primeiroAcesso`}>
            <PrimeiroAcessoForm/>
          </Route>
          {/* <Route path={`${match.path}/painel`}>
            <PainelHome/>
          </Route> */}
          <Route path={match.path}>
            {
              <Redireciona/>
              // !localStorage.accessToken ? <Redirect to="/login" />:<h2>Seja bem vindo: {user && user.name}</h2>
            }
          </Route>
        </Switch>
        </div>
      </Container>
    )
  }

export default Home;
