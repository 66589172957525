import React, {useEffect} from "react";

import {
    DiagramComponent,
    Inject,
    SnapConstraints,
    DiagramConstraints,
    BpmnDiagrams,
} from "@syncfusion/ej2-react-diagrams";

const tamanhoW = 80;
const tamanhoH = 60;
const tamanho=4;
const yInit = 35;
const dist = 80;

let connectors = [];
let diagramInstance;

export default function FluxoPanelBPM(props) {

    const {fluxo, path} = props;
    let nodes = [];
    
    useEffect(()=>{
        var larguraMaxima = document.getElementById("diagram").parentElement.offsetWidth;
        let x = 0;
        let y = yInit;
        let index=0;
        fluxo.nodes.forEach(no =>{

            var style= {
                fill: 'white',
                strokeColor: 'black'
            };
            if( path && path.find(item => item === no.node)){
                style= {
                    fill: '#1aff1a',
                    strokeColor: '#0066ff'
                };
            }
            
            nodes.push(
                {
                    id: no.node,
                    width: tamanhoW,
                    height: tamanhoH,
                    offsetX: no.x?no.x:x,
                    offsetY: no.y?no.y:y,
                    style: style,
                    annotations: [
                        {
                          content: no.nome
                        }
                      ]
                }
            )
            
            // x += dist*2;
            // if( x > larguraMaxima-dist){
            //     x = dist+tamanhoW;
            //     y += (tamanhoH*2);
            // }
        });
        
        drawFluxo(x, y, index, larguraMaxima);
          
    }, []);
    function drawFluxo(x, y, index, larguraMaxima){
        for(let ii in fluxo.fluxo){
            const fl = fluxo.fluxo[ii];
            // let noPai = nodes.find(no => no.id === ii);
            // if( noPai){
            //     noPai.offsetX = x;
            //     noPai.offsetY = y;
            //     x += dist*2;
            // }

            if( typeof fl === 'string'){
                let noFilho = nodes.find(no => no.id === fl);
                if( noFilho){
                    connectors.push({
                        id:"_"+(++index),
                        sourceID: ii,
                        targetID: fl,
                        type: 'Orthogonal'
                    });
                }
            }else{
                // y -= (tamanhoH * fl.length );
                // x += dist;
                let incremento=1;
                fl.forEach(item =>{
                    let noFilho = nodes.find(no => no.id === item);
                    // y += tamanhoH+(tamanhoH/2);
                    // y = noFilho.offsetY+tamanhoH;
                    // noFilho.offsetY = y;
                    // // x -= dist;
                    // noFilho.offsetX -= (dist * incremento++);
                    if( noFilho){
                        connectors.push({
                            id:"_"+(++index),
                            sourceID: ii,
                            targetID: item,
                            type: 'Orthogonal'
                        });
                    }
                });
            }
            // if( x > larguraMaxima-dist){
            //     x = dist+tamanhoW;
            //     y += (tamanhoH*2);
            // }
        }
    }
    return (
    <DiagramComponent     
        id="diagram"
        ref={diagram => (diagramInstance = diagram)}
        width = {
            '100%'
        }
        height = {
            '300px'
        }
        scrollSettings={{
            horizontalOffset: 100,
            verticalOffset: 50,
        }}
        layout = {
            {
                //Sets layout type
                type: 'OrganizationalChart',
                getLayoutInfo: (node, options) => {
                    if (!options.hasSubTree) {
                        options.type = 'Left';
                        options.orientation = 'Horizontal';
                    }
                }
            }
        }
        created = {
            () => {
                nodes.forEach(no =>{
                    diagramInstance.add(no);
                });
                diagramInstance.connectors = connectors;
            }
        }
        snapSettings= {{
            constraints: SnapConstraints.None,
        }}
        constraints = { DiagramConstraints.Default & ~DiagramConstraints.PageEditable }
        ><Inject services = {[BpmnDiagrams]}/>
        </DiagramComponent>
    )
}
