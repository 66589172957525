import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, TextField, Typography }
  from '@material-ui/core';
import { useStyles, ValidarCPF } from "../../../constants";
import MaskedInput from 'react-text-mask'
import GenericTable from "../../../components/template/GenericTable";

function TelMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}
// function TelMask2(props) {
//   const { inputRef, ...other } = props;
//   return (
//     <MaskedInput
//       {...other}
//       ref={(ref) => {
//         inputRef(ref ? ref.inputElement : null);
//       }}
//       mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
//       placeholderChar={'\u2000'}
//       showMask
//     />
//   );
// }
function CPFMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
    />
  );
}

export default function SocioDiretoresForm(props) {
  const classes = useStyles();
  const { value, setValue, setWait, disable } = props;
  const [showTel1, setShowTel1] = useState(false);
  const [error, setError] = React.useState(resetErro());
  const [values, setValues] = useState({});

  const handleChangeInputForm = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };
  const handleActionSolicitarClick = (index, acao, dado) => {
    let arr = [...value.sociosDiretores];
    arr.splice(index, 1);
    setValue({
      ...value,
      sociosDiretores: arr,
    });
  }
  const handleTelLostFocus = () => {
    if (!values || !values.telefone) return;
    setShowTel1(values.telefone.trim().length !== 16);
  }

  function resetErro() {
    return {
      cpf: {
        erro: false,
        msg: ''
      },
      email: {
        erro: false,
        msg: ''
      },
      telefone: {
        error: false,
        msg: ''
      }

    }
  }
  const handleTelFocus = () => {

    setShowTel1(false);
  }
  const handleLostFocusCPF = (e) => {
    setError({
      ...error,
      cpf: {
        erro: false,
        msg: ''
      }
    });

    if (!values || !values.cpf) return;

    const cpf = values.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();

    if (cpf.length === 0) return;

    if (ValidarCPF(cpf)) {
      // TODO buscar ou não CPF
      setWait(true);
      fetch("/api/v1/cpf/" + cpf)
        .then(res => {
          if (res.status <= 300) return res.json()
        })
        .then(
          (result) => {
            setWait(false);
            setError(resetErro);
            if (!result) {
              //   setEditavel(true);
              setValues({
                ...values, nome: ''
              });
            } else {
              setValues({
                ...values,
                nome: result.name,
                nomeMae: result.nomeMae,
                dataNascimento: new Date(result.dataNascimento),
                email: result.email,
                telefone: result.telefone
              });
              const el = document.getElementById("nome");
              el.focus();
              el.select();
            }
          },
          (error) => {
            setWait(false);
          }
        )
    } else {
      setError({
        ...error,
        cpf: {
          erro: true,
          msg: 'CPF inválido'
        }
      });
    }
  }

  function incluir() {
    let arr = [];
    let ok = true
    const dadosTab = {
      ...values
    }
    if (value.sociosDiretores) {
      for (let x in value.sociosDiretores) {
        const d = value.sociosDiretores[x]
        arr.push(d)
      }
    }
    if (ok) arr.push(dadosTab);
    setValue({
      ...value,
      sociosDiretores: arr
    })
    setValues({});
  }

  function disableIncluir() {
    if (values.nome && values.cpf && values.telefone && values.endereco && values.porcentagemSocietario) return false
    return true
  }

  const handleCheck = (e) => {
    setValues({
      ...values,
      socioMajoritario: e.target.checked
    });
  }
  function transformaSocio(valor) {
    if (valor) {
      return (valor == "true" || valor == true) ? "majoritário" : "";
    }
    return "";
  }

  return (
    <Grid container spacing={1} >
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>QUADRO SOCIETÁRIO DA EMPRESA OU QUADRO DE DIRETORES, SE S/A</Typography>
      </Grid>
      {!disable &&
        <>
          <Grid item xs={4}>
            <TextField
              required
              label="CPF"
              value={values.cpf || ''}
              onChange={handleChangeInputForm}
              onBlur={handleLostFocusCPF}
              error={error.cpf.erro}
              helperText={error.cpf.msg}
              name="cpf"
              id="cpf-mask-input"
              fullWidth
              InputProps={{
                inputComponent: CPFMask,
              }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={8}>
            <TextField
              required
              label="Nome"
              value={values.nome || ''}
              name="nome"
              id="nome"
              fullWidth
              onChange={handleChangeInputForm}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={values.endereco || ''}
              fullWidth
              label="Endereço"
              onChange={handleChangeInputForm}
              id="endereco"
              name="endereco"
              aria-describedby="nova-helper-text"
              // InputProps={{
              //   readOnly: true,
              // }}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={values.pontoReferencia}
              fullWidth
              label="Orientações e pontos de referencia para localizar"
              onChange={handleChangeInputForm}
              id="orientacao"
              name="orientacao"
              aria-describedby="nova-helper-text"
              // InputProps={{
              //   readOnly: true,
              // }}
              variant="outlined"

            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              label="Telefone Comercial"
              value={values.telefone || ''}
              error={error.telefone.erro}
              helperText={error.telefone.msg}
              onChange={handleChangeInputForm}
              onBlur={handleTelLostFocus}
              onFocus={handleTelFocus}
              // style={{ display: showTel1 ? 'none' : 'block' }}
              name="telefone"
              id="tel-mask-input"
              fullWidth
              InputProps={{
                inputComponent: TelMask,
              }}
              variant="outlined"
            />

            {/* <TextField
              required
              label="Telefone Comercial222"
              value={values.telefone || ''}
              error={error.telefone.erro}
              helperText={error.telefone.msg}
              onChange={handleChangeInputForm}
              onFocus={handleTelFocus}
              // style={{ display: showTel1 ? 'block' : 'none' }}
              name="telefone"
              id="tel-mask-input1"
              fullWidth
              InputProps={{
                inputComponent: TelMask2,
              }}
              variant="outlined"
            /> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              type="number"
              label="% Societário"
              value={values.porcentagemSocietario || ''}
              onChange={handleChangeInputForm}
              // style={{ display: showTel1 ? 'none' : 'block' }}
              name="porcentagemSocietario"
              id="porcentagemSocietario"
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.socioMajoritario || false}
                  onChange={handleCheck}
                  name="declaracao"
                  color="primary"
                />
              }
              label="Socio majoritário"
            />
          </Grid>
          <Grid container justify="flex-end" className={classes.btns}>
            <Button variant="contained" color="primary" onClick={incluir} disabled={disableIncluir()}>
              Incluir
            </Button>
          </Grid>
        </>}
      <Grid item xs={12}>
        <GenericTable
          actionClick={handleActionSolicitarClick}
          options={['Excluir']}
          dados={value ? (value.sociosDiretores || []) : []}
          colunas={[
            { nome: 'Nome', campo: 'nome' },
            { nome: 'CPF', campo: 'cpf' },
            { nome: '%Societario', campo: 'porcentagemSocietario' },
            { nome: 'Endereço', campo: 'endereco' },
            { nome: 'Telefone', campo: 'telefone' },
            { nome: 'Socio', campo: 'socioMajoritario', transformacao: transformaSocio },
            { nome: 'Ação', campo: 'action' }
          ]} />
      </Grid>

    </Grid>
  );
}
