import React from 'react';
import {
    Grid, Paper, TextField, Typography,
    Accordion, AccordionSummary, AccordionDetails, InputAdornment
    ,} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "../../../constants";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import NumberFormat from 'react-number-format';


const FormataCampo = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

export default function ProjetoForm(props) {
    const classes = useStyles();
    const { value, setValue, disable } = props;

    const handleChangeInputForm = (event) => {
        const pasaDado = {
            ...value.projeto,
            [event.target.name]: event.target.value,
        };
        setValue({
            ...value,
            projeto: pasaDado
        });
    };

    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>PROJETO</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>a) Descreva os produtos e serviços oferecidos pela empresa(de acordo  com classificação do CNAE).</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}

                                data={value.projeto ? (value.projeto.produtoServicos || '') : ''}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    const pasaDado = {
                                        ...value.projeto,
                                        produtoServicos: editor.getData()
                                    };
                                    setValue({
                                        ...value,
                                        projeto: pasaDado
                                    });
                                }}
                                disabled={disable}
                                onBlur={(event, editor) => {

                                }}
                                onFocus={(event, editor) => {

                                }}
                            />
                        </Grid>

                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>b) Quais são as tecnologias e aptidões operacionais do empreendimento?</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={value.projeto ? (value.projeto.tecnologiaAptidao || '') : ''}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    const pasaDado = {
                                        ...value.projeto,
                                        tecnologiaAptidao: editor.getData()
                                    };
                                    setValue({
                                        ...value,
                                        projeto: pasaDado
                                    });
                                }}
                                disabled={disable}
                                onBlur={(event, editor) => {

                                }}
                                onFocus={(event, editor) => {

                                }}
                            />
                        </Grid>
                        <Typography variant="h5" gutterBottom>Investimentos:</Typography>
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>a) Qual o valor do Investimento fixo a ser realizado pelo empreendimento?</Typography>
                            <Grid item xs={3}>
                                <TextField
                                    value={value.projeto ? (value.projeto.valInvestidoFixo || '') : ''}
                                    // disabled
                                    fullWidth
                                    label=""
                                    id="valInvestidoFixo"
                                    name="valInvestidoFixo"
                                    disabled={disable}
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">R$</InputAdornment>,
            
                                        inputComponent: FormataCampo
                                    }}
                                />
                            </Grid>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={value.projeto ? (value.projeto.investimentoFixo || '') : ''}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    const pasaDado = {
                                        ...value.projeto,
                                        investimentoFixo: editor.getData()
                                    };
                                    setValue({
                                        ...value,
                                        projeto: pasaDado
                                    });
                                }}
                                disabled={disable}
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>b) O projeto será executado com compromentimento de recurso próprios da empresa superior a 10% em relação ao investimento fixo? Descreva.</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={value.projeto ? (value.projeto.comprometimendoDiscricao || '') : ''}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    const pasaDado = {
                                        ...value.projeto,
                                        comprometimendoDiscricao: editor.getData()
                                    };
                                    setValue({
                                        ...value,
                                        projeto: pasaDado
                                    });
                                }}
                                disabled={disable}
                                
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
    );
}
