const mock = true;

const NotificacaoService = {
    criar: function (notificacao) {
        return fetch(`/api/processoProdf/notificacaoComplemento/`, {
            method: notificacao.id?"PUT":"POST",
            body: JSON.stringify(notificacao),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        }).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    },
    alterar: function (notificacao) {
        return fetch(`/api/processoProdf/notificacaoComplemento/`, {
            method: "PUT",
            body: JSON.stringify(notificacao),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
        }).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    },
    obterTodos: function () {
        return fetch(`/api/processoProdf/notificacaoComplemento/`).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    },
    obterByStatus: function (st) {
        return fetch(`/api/processoProdf/notificacaoComplemento/status/${st}`).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    },
    obterPorId: function (id) {
        
        return fetch(`/api/processoProdf/notificacao/${id}`).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    },
    obterPorIdProcesso: function (idProcesso) {
        
        return fetch(`/api/processoProdf/notificacaoComplemento/byIdProcesso/${idProcesso}`).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    },
    obterPorIdProcessoIdNotificacao: function (idProcesso, idNotificacao) {
        if (mock) {
            return new Promise((res, rej) => {
                res({});
            });
        }
        return fetch(`/api/processoProdf/notificacao/${idProcesso}/${idNotificacao}`).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        }).catch((e) => {
            console.erro('erro', e);
        });
    },
    remover: function (idProcesso, idNotificacao) {
        if (mock) {
            return new Promise((res, rej) => {

                res({});
            });
        }
        return fetch(`/api/processoProdf/notificacao/${idProcesso}/${idNotificacao}`, {
            method: "DELETE",
        }).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    }
};

export default NotificacaoService;
