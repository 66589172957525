import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, Backdrop, CircularProgress, Collapse, Button, IconButton } from '@material-ui/core';
import GerenciaProcessoTable from "./GerenciaProcessoTable";
import Alert from '@material-ui/lab/Alert';
import DetalharProcessoPanel from "./detalhamento/DetalharProcessoPanel";
import { useStyles } from "../../constants";
import RecursoTable from "./recurso/RecursoTable";
import RecursoForm from "./recurso/RecursoForm";
import ProrrogacaoForm from "./prorrogacao/ProrrogacaoForm";
import ProrrogacaoTable from "./prorrogacao/ProrrogacaoTable";
import CancelamentoForm from "./cancelamento/CancelamentoForm";
import CancelamentoTable from "./cancelamento/CancelamentoTable";
import RequerimentoTable from "./outros/RequerimentoTable";
import RequerimentoForm from "./outros/RequerimentoForm";
import CancelamentoUnateTable from "./unate/cancelamento/CancelamentoUnateTable";
import NotificacaoComplementoUnateTable from "./unate/notificacao/NotificacaoComplementoUnateTable";
import NotificacaoComplementoUnateForm from "./unate/notificacao/NotificacaoComplementoUnateForm";
import RequerimentoOutrosUnateTable from "./unate/RequerimentoOutrosUnateTable";
import RequerimentoOutrosUnateForm from './unate/RequerimentoOutrosUnateForm';
import CancelamentoUnateForm from "./unate/cancelamento/CancelamentoUnateForm";
import ProrrogacaoTableUnate from "./unate/prorrogacao/ProrrogacaoTableUnate";
import ProrrogacaoUnateForm from "./unate/prorrogacao/ProrrogacaoUnateForm";
import AvaliaRequerimentoTable from "./unate/AvaliaRequerimentoTable";
import AvaliarRequerimentoForm from "./unate/AvaliarRequerimentoForm";
import AvaliaRequerimentoCheckList from "./unate/AvaliaRequerimentoCheckList";
import DespachoRequerimentoTable from "./semp/DespachoRequerimentoTable";
import DespachoRequerimentoForm from "./semp/DespachoRequerimentoForm";
import SempAvaliarRequerimentoTable from "./semp/SempAvaliarRequerimentoTable";
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import ListarRecursosPanel from './unate/recurso/ListarRecursosPanel';
import ManterRecursoPanel from './unate/recurso/ManterRecursoPanel';
import RequerimentoUnateForm from "./unate/RequerimentoUnateForm";
import CloseIcon from '@material-ui/icons/Close';
import FluxoPanelBPM from '../../util/FluxoPanelBPM';
import ManterNotificacaoPanel from './notificacao/ManterNotificacaoPanel';
import ListarNotificacoesPanel from './notificacao/ListarNotificacoesPanel';
import ListarComplementosInformacaoPanel from './complementoInformacao/ListarComplementosInformacaoPanel';
import ManterComplementoInformacaoPanel from './complementoInformacao/ManterComplementoInformacaoPanel';
import ResponderForm from './notificacao/ResponderForm';
import EncaminhaRequerimentoTable from "./unate/EncaminhaRequerimentoTable"
import ValidarGestaoUNATETable from "./unate/ValidarGestaoUNATETable";
import ValidarGestaoUNATEForm from "./unate/ValidarGestaoUNATEForm";
import TextoPadraoForm from '../template/textoPadrao/TextoPadraoForm';
import TextoPadraoTable from '../template/textoPadrao/TextoPadraoTable';
import CadastroProcessoForm from './cadastrar/CadastroProcessoForm';
import CadastroImovelForm from './cadastrar/CadastroImovelForm';
import CadastroCartaConsultaForm from './cadastrar/CadastroCartaConsultaForm';
import CadastroIndicarAreaForm from './cadastrar/CadastroIndicarAreaForm';
import CadastroProjetoForm from './cadastrar/CadastroProjetoForm';
import CadastroContratoTerracapForm from './cadastrar/CadastroContratoTerracapForm';
import CadastroImplantacaoForm from './cadastrar/CadastroImplantacaoForm';
import CadastroProrrogacaoContratoForm from './cadastrar/CadastroProrrogacaoContratoForm';
import CadastroAtestadoForm from './cadastrar/CadastroAtestadoForm';
import CadastroEscrituracaoForm from './cadastrar/CadastroEscrituracaoForm';
import CadastroMetasForm from './cadastrar/CadastroMetasForm';
import CadastroRequerimentoForm from './cadastrar/CadastroRequerimentoForm';
import CadastroRecursoForm from './cadastrar/CadastroRecursoForm';
import CadastroNotificacaoForm from './cadastrar/CadastroNotificacaoForm';
import CadastroComplementoInformacaoForm from './cadastrar/CadastroComplementoInformacaoForm';
import ConsultarProcessoSei from './ConsultarProcessoSei';
import ParecerTecnicoPanel from './semp/ParecerTecnicoPanel';


export default function Processos() {
    const classes = useStyles();

    const [wait, setWait] = useState(false);
    const match = useRouteMatch();
    const [titulo, setTitulo] = useState("Cadastro de processo");
    const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });

    function setMensagem(alerta) {
        let a;
        if (typeof alerta === 'object') {
            a = { ...alerta };
            if (!a.tipo) a.tipo = 'success';
            if (!a.duracao) a.duracao = 5000;
            a.visibilidade = true;
        } else {
            a = {
                tipo: 'info',
                duracao: 5000,
                visibilidade: true,
                mensagem: alerta
            }
        }

        setAlerta(a);
        if (!!a.duracao && a.duracao > 0) {
            window.setTimeout(() => {
                setAlerta({ tipo: a.tipo, visibilidade: false });
            }, a.duracao)
        }
    }

    // @deprecated - usar setMensagem quando for possível
    function setErro(e) {
        setMensagem({
            tipo: 'error',
            mensagem: e.statusText || e.error || e
        });
    }

    function setSuccess(e){
        setMensagem({
            tipo: 'success',
            mensagem: e.statusText || e.error || e
        });
    }

    return (
        <Container maxWidth='xl' className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
                        <Collapse in={alerta.visibilidade}>
                            <Alert severity={alerta.tipo} action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlerta({ visibilidade: false });
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }>{alerta.mensagem}</Alert>
                        </Collapse>
                    </Paper>
                </Grid>
            </Grid>

            <Switch>
                <Route path={`${match.path}/processo/:id`}>
                    <DetalharProcessoPanel setTitulo={setTitulo} setWait={setWait} setErro={setErro} setMensagem={setMensagem} />
                </Route>
                <Route path={`${match.path}/processoConsultar/:id`}>
                    <DetalharProcessoPanel setTitulo={setTitulo} setWait={setWait} setErro={setErro} setMensagem={setMensagem} />
                </Route>
                <Route path={`${match.path}/processoImovel/:id`}>
                    <DetalharProcessoPanel setTitulo={setTitulo} setWait={setWait} setErro={setErro} />
                </Route>
                <Route path={`${match.path}/movimentarProcesso/:id`}>
                    <DetalharProcessoPanel setTitulo={setTitulo} setWait={setWait} setErro={setErro} />
                </Route>

                <Route path={`${match.path}/solicitarRecursoTabela`}>
                    <RecursoTable setTitulo={setTitulo} setErro={setErro} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/solicitarRecursoForm/:id`}>
                    <RecursoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setSuccess={setSuccess} />
                </Route>
                <Route path={`${match.path}/solicitarRecursoView/:id`}>
                    <RecursoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true} />
                </Route>
                <Route path={`${match.path}/solicitarRecursoForm`}>
                    <RecursoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setSuccess={setSuccess} />
                </Route>
                <Route path={`${match.path}/solicitarCancelamentoTabela`}>
                    <CancelamentoTable setTitulo={setTitulo} setErro={setErro} setWait={setWait} setSuccess={setSuccess}/>
                </Route>
                <Route path={`${match.path}/solicitarProrrogacaoTabela`}>
                    <ProrrogacaoTable setTitulo={setTitulo} setErro={setErro} setWait={setWait} />
                </Route>

                <Route path={`${match.path}/solicitarRequerimentoTabela`}>
                    <RequerimentoTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setSuccess={setSuccess} />
                </Route>

                <Route path={`${match.path}/solicitarProrrogacaoForm/:id`}>
                    <ProrrogacaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setSuccess={setSuccess} />
                </Route>
                <Route path={`${match.path}/solicitarProrrogacaoView/:id`}>
                    <ProrrogacaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true}/>
                </Route>

                <Route path={`${match.path}/solicitarProrrogacaoForm`}>
                    <ProrrogacaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setSuccess={setSuccess} />
                </Route>
                <Route path={`${match.path}/solicitarRequerimentoForm`}>
                    <RequerimentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setSuccess={setSuccess} />
                </Route>
                <Route path={`${match.path}/solicitarRequerimentoView/:id`}>
                    <RequerimentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true} />
                </Route>


                <Route path={`${match.path}/alterarProcesso`}>
                    <CadastroProcessoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setErro={setErro} />
                </Route>
                <Route path={`${match.path}/imovelProcesso`}>
                    <CadastroImovelForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/cartaConsultaProcesso`}>
                    <CadastroCartaConsultaForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/cartaIndicarAreaProcesso`}>
                    <CadastroIndicarAreaForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/projetoProcesso`}>
                    <CadastroProjetoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/contratoTerracapProcesso`}>
                    <CadastroContratoTerracapForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/implantacaoProcesso`}>
                    <CadastroImplantacaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/prorrogacaoContratoProcesso`}>
                    <CadastroProrrogacaoContratoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/atestadoProcesso`}>
                    <CadastroAtestadoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/escrituracaoProcesso`}>
                    <CadastroEscrituracaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/metasProcesso`}>
                    <CadastroMetasForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>

                <Route path={`${match.path}/requerimentoProcesso`}>
                    <CadastroRequerimentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/recursoProcesso`}>
                    <CadastroRecursoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/notificacaoProcesso`}>
                    <CadastroNotificacaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/complementoInformacaoProcesso`}>
                    <CadastroComplementoInformacaoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>

                
                <Route path={`${match.path}/solicitarCancelamentoForm/:id`}>
                    <CancelamentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setSuccess={setSuccess} />
                </Route>
                <Route path={`${match.path}/solicitarCancelamentoView/:id`}>
                    <CancelamentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true} />
                </Route>
                <Route path={`${match.path}/solicitarCancelamentoForm`}>
                    <CancelamentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} setSuccess={setSuccess} />
                </Route>
                <Route path={`${match.path}/unate/solicitarCancelamentoTable`}>
                    <CancelamentoUnateTable setTitulo={setTitulo} setErro={setErro} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/unate/solicitarRequerimentoOutrosTable`}>
                    <RequerimentoOutrosUnateTable setTitulo={setTitulo} setErro={setErro} setWait={setWait} setSuccess={setSuccess} />
                </Route>

                <Route path={`${match.path}/unate/solicitarNotificaoTable`}>
                    <NotificacaoComplementoUnateTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} titulo="Notificação" />
                </Route>
                <Route path={`${match.path}/unate/solicitarNotificaForm`}>
                    <NotificacaoComplementoUnateForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} titulo="Responder Notificação" />
                </Route>

                <Route path={`${match.path}/unate/solicitarComplementoTable`}>
                    <NotificacaoComplementoUnateTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} titulo="Complemento" isComplemento={true} />
                </Route>
                <Route path={`${match.path}/unate/solicitarComplementoForm`}>
                    <NotificacaoComplementoUnateForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/unate/solicitarComplementoView`}>
                    <NotificacaoComplementoUnateForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true} />
                </Route>

                <Route path={`${match.path}/unate/solicitarRequerimentoOutrosForm`}>
                    <RequerimentoOutrosUnateForm setTitulo={setTitulo} setErro={setErro} setWait={setWait} editable={true} setSuccess={setSuccess} />
                </Route>
                <Route path={`${match.path}/unate/solicitarCancelamentoForm`}>
                    <CancelamentoUnateForm setTitulo={setTitulo} setErro={setErro} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/unate/solicitarProrrogacaoTable`}>
                    <ProrrogacaoTableUnate setTitulo={setTitulo} setErro={setErro} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/unate/solicitarProrrogacaoForm`}>
                    <ProrrogacaoUnateForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/unate/avaliaRequerimento`}>
                    <AvaliaRequerimentoTable setTitulo={setTitulo} setErro={setErro} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/unate/encaminharEmpresario`}>
                    <EncaminhaRequerimentoTable setTitulo={setTitulo} setErro={setErro} setWait={setWait} encaminhar={true} />
                </Route>

                <Route path={`${match.path}/unate/validarGestaoUNATE`}>
                    <ValidarGestaoUNATETable setTitulo={setTitulo} setErro={setErro} setWait={setWait} encaminhar={true} />
                </Route>

                <Route path={`${match.path}/unate/validarGestaoUNATEForm`}>
                    <ValidarGestaoUNATEForm setTitulo={setTitulo} setErro={setErro} setWait={setWait} encaminhar={true} setMensagem={setMensagem} />
                </Route>
                

                


                <Route path={`${match.path}/unate/avaliarRequerimentoForm`}>
                    <AvaliarRequerimentoForm setTitulo={setTitulo} setErro={setErro} setWait={setWait} setMensagem={setMensagem} />
                </Route>
                <Route path={`${match.path}/unate/avaliaRequerimentoCheckList`}>
                    <AvaliaRequerimentoCheckList setTitulo={setTitulo} setErro={setErro} setWait={setWait} setMensagem={setMensagem} />
                </Route>
                <Route path={`${match.path}/unate/recursos`} exact>
                    <ListarRecursosPanel setTitulo={setTitulo} setErro={setErro} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/unate/recursos/:idProcesso/:idRecurso/:acao`} exact>
                    <ManterRecursoPanel setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/unate/solicitarRecursoForm`} exact>
                    <ManterRecursoPanel setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} comCache={true} setSuccess={setSuccess} />
                </Route>
                <Route path={`${match.path}/unate/solicitarRecursoView`} exact>
                    <ManterRecursoPanel setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} comCache={true} disable={true} />
                </Route>
                <Route path={`${match.path}/unate/encaminharEmpresarioForm`}>
                    <DespachoRequerimentoForm setTitulo={setTitulo} setErro={setErro} setWait={setWait} disableDespacho={true} disableParecerTecnico={true} showEncaminhar={true} />
                </Route>

                <Route path={`${match.path}/unate/solicitarRequerimentoForm`}>
                    <RequerimentoUnateForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>

                <Route path={`${match.path}/consultarProcessoSei`}>
                    <ConsultarProcessoSei setErro={setMensagem} setWait={setWait} setTitulo={setTitulo} setMensagem={setMensagem} />
                </Route> 

                <Route path={`${match.path}/unate`}>
                    <b>Falta implementar</b>
                </Route>

                <Route path={`${match.path}/semp/avaliaRequerimento`}>
                    <SempAvaliarRequerimentoTable setTitulo={setTitulo} setErro={setErro} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/semp/despacho`}>
                    <DespachoRequerimentoTable setTitulo={setTitulo} setErro={setErro} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/semp/despachoRequerimentoForm`}>
                    <DespachoRequerimentoForm setTitulo={setTitulo} setErro={setErro} setWait={setWait} disableParecerTecnico={true} />
                </Route>
                <Route path={`${match.path}/semp/parecerTecnicoRequerimentoForm`}>
                    <ParecerTecnicoPanel setTitulo={setTitulo} setErro={setErro} setWait={setWait} setMensagem={setMensagem} setSuccess={setSuccess} />
                    {/* <DespachoRequerimentoForm setTitulo={setTitulo} setErro={setErro} setWait={setWait} disableDespacho={true} setMensagem={setMensagem}/> */}
                </Route>
                <Route path={`${match.path}/semp/parecerTecnicoRequerimentoForm/requerimento`}>
                    <DespachoRequerimentoForm setTitulo={setTitulo} setErro={setErro} setWait={setWait} disableDespacho={true} setMensagem={setMensagem}/>
                </Route>

                <Route path={`${match.path}/semp/fluxo`}>
                    <FluxoPanelBPM />
                </Route>


                <Route path={`${match.path}/notificacao/form`} exact>
                    <ManterNotificacaoPanel setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/notificacao/formView/:id`} exact>
                    <ManterNotificacaoPanel setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/notificacao/view`} exact>
                    <DespachoRequerimentoForm setTitulo={setTitulo} setErro={setErro} setWait={setWait} disable={true} assunto={"Notificacao"} disableDespacho={true} />
                </Route>
                <Route path={`${match.path}/notificacao/respostaForm`} exact>
                    <ResponderForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>

                <Route path={`${match.path}/notificacao/lista`} exact>
                    <ListarNotificacoesPanel setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>

                <Route path={`${match.path}/complementoinformacao/form`} exact>
                    <ManterComplementoInformacaoPanel setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/complementoinformacao/formView/:id`} exact>
                    <ManterComplementoInformacaoPanel setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/complementoinformacao/view`} exact>
                    <DespachoRequerimentoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true} assunto={"Complemento"} disableDespacho={true} />
                </Route>

                <Route path={`${match.path}/complementoinformacao/lista`} exact>
                    <ListarComplementosInformacaoPanel setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/textoPadrao`} exact>
                    <TextoPadraoTable setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>

                <Route path={`${match.path}/textoPadraoForm`} exact>
                    <TextoPadraoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} />
                </Route>
                <Route path={`${match.path}/textoPadraoView`} exact>
                    <TextoPadraoForm setTitulo={setTitulo} setMensagem={setMensagem} setWait={setWait} disable={true} />
                </Route>

                
                <Route path={`${match.path}/cadastrarProcesso`}>
                    <DetalharProcessoPanel setTitulo={setTitulo} setWait={setWait} setErro={setErro} />
                </Route>

                <Route path={`${match.path}/semp`}>
                    <b>Falta implementar</b>
                </Route>

                <Route path={match.path}>
                    <GerenciaProcessoTable setErro={setErro} setWait={setWait} setTitulo={setTitulo} />
                </Route>
            </Switch>
            <Backdrop className={classes.backdrop} open={wait}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}

