import React, {useState, useEffect} from "react";
import * as ReactDOM from "react-dom";
import { Grid, Button, ButtonGroup } from '@material-ui/core';
import {
    Diagram,
    DiagramComponent,
    Inject,
    ConnectorModel,
    NodeModel,
    DiagramConstraints,
    HierarchicalTree,
    TextModel, MoveTool, cloneObject, randomId,
    SelectorConstraints,
    DataBinding
} from "@syncfusion/ej2-react-diagrams";
import {
    DataManager,
    Query
} from '@syncfusion/ej2-data';
import { useStyles } from "../../../constants";
import { useHistory, useLocation } from "react-router-dom";

export default function UnidadeDiagram(props) {
    const classes = useStyles();
    const history = useHistory();
    const {dado, setMensagem} = props;

    let diagramInstance;
    let noSelecionado;
    const [items, setItems] = useState();

    useEffect(()=>{
        let arr = []
        dado.forEach(d => {
            if( d.pai === 0 ){
                arr.push({
                    id: d.id,
                    Name: d.nome,

                })
            }else{
                let pai = dado.find(dd => dd.id === d.pai)
                arr.push({
                    id: d.id,
                    Name: d.nome,
                    ReportingPerson: pai.nome
                })
            }
        });
        setItems( new DataManager(arr, new Query().take(7)) );

    }, [])

    let handles = [{
        name: 'clone',
        pathData: 'M60.3,18H27.5c-3,0-5.5,2.4-5.5,5.5v38.2h5.5V23.5h32.7V18z M68.5,28.9h-30c-3, 0-5.5,2.4-5.5,5.5v38.2c0,3,2.4,5.5,5.5,5.5h30c3,0,5.5-2.4,5.5-5.5V34.4C73.9,31.4,71.5,28.9,68.5,28.9z M68.5,72.5h-30V34.4h30V72.5z',
        visible: true,
        offset: 0,
        side: 'Bottom',
        pathColor: "white",
        margin: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    }];
    function getTool(action) {
        let tool;
        if (action === 'clone' && diagramInstance) {
            tool = new CloneTool(diagramInstance.commandHandler);
            document.getElementById("tool").style.display = "block";
        }else{
            document.getElementById("tool").style.display = "none";
        }
        return tool;
    }
    
    class CloneTool extends MoveTool {
        mouseDown(args) {
            let newObject;
            if (diagramInstance.selectedItems.nodes.length > 0) {
                newObject = diagramInstance.selectedItems.nodes[0].data;
            } else {
                newObject = diagramInstance.selectedItems.connectors[0].data;
            }
            noSelecionado= newObject;
            this.inAction = true;
        }
    }

    const handleNovaUnidade = ()=>{
        history.replace("/unidade/novaUnidade?id="+noSelecionado.id);
     }
     const handleEditarUnidade = ()=>{
        history.replace("/unidade/editarUnidade?id="+noSelecionado.id);
     }
     const handleRemoverUnidade = ()=>{
        history.replace("/unidade/excluirUnidade?id="+noSelecionado.id);
     }
   
    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item sm={12} id="tool" style={{ display: 'none' }} >
              <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                <Button onClick={handleNovaUnidade}>Nova Unidade</Button>
                <Button onClick={handleEditarUnidade}>Editar</Button>
                <Button onClick={handleRemoverUnidade}>Remover</Button>
              </ButtonGroup>
            </Grid>
            {items && 
            <Grid item sm={12}>
                <DiagramComponent id = "diagram" ref={diagram => (diagramInstance = diagram)}
                width = {
                    '100%'
                }
                height = {
                    '550px'
                }
                //Uses layout to auto-arrange nodes on the diagram page
                layout = {
                    {
                        //Sets layout type
                        type: 'HierarchicalTree'
                    }
                }
                //Configures data source for diagram
                dataSourceSettings = {
                    {
                        id: 'Name',
                        parentId: 'ReportingPerson',
                        dataManager: items
                    }
                }
                //Sets the default properties for nodes
                getNodeDefaults = {
                    (obj) => {
                        obj.shape = {
                            type: 'Text',
                            content: obj.data.Name
                        };
                        obj.style = {
                            fill: 'None',
                            strokeColor: 'none',
                            strokeWidth: 2,
                            bold: true,
                            color: 'white'
                        };
                        obj.borderColor = 'white';
                        obj.width = 100;
                        obj.height = 40;
                        obj.backgroundColor = '#6BA5D7';
                        obj.borderWidth = 1;
                        (obj.shape).margin = {
                            left: 5,
                            right: 5,
                            top: 5,
                            bottom: 5
                        };
                        return obj;
                    }
                }
                //Sets the default properties for and connectors
                getConnectorDefaults = {
                    (connector, diagram) => {
                        connector.style = {
                            strokeColor: '#6BA5D7',
                            strokeWidth: 2
                        };
                        connector.targetDecorator.style.fill  =  '#6BA5D7';
                        connector.targetDecorator.style.strokeColor  =  '#6BA5D7';
                        connector.type = 'Orthogonal';
                        return connector;
                    }
                }
                // constraints = { DiagramConstraints.Default & ~DiagramConstraints.PageEditable }
                selectedItems = {
                    {
                        constraints: SelectorConstraints.UserHandle,
                        userHandles: handles
                    }
                }
                getCustomTool = {
                    getTool
                }
            
                ><Inject services = {[DataBinding, HierarchicalTree]}/>
                </DiagramComponent>
                </Grid>
            }
        </Grid>
    )
}