import React, { useEffect, useState } from 'react';
import {
    Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, TextField, Container
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Download from '../../util/Download';
import TablePagination from '@material-ui/core/TablePagination';
import { FormataData, useStyles } from '../../constants';
import CampoBusca from '../../util/CampoBusca';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

export function deParaSituacao(de) {
    if (de === 'ABERTO') return "Aberto";
    if (de === 'FECHADO') return "Fechado";
    if (de === 'CONCLUIDO') return "Concluído";
    if (de === 'CANCELADO') return "Cancelado";
    if (de === 'INICIADO') return "Iniciado";
    if (de === 'ANDAMENTO') return "Em andamento";
    return de;
}
export function deParaTipo(de) {
    if (de === 'PROBLEMA_ACESSO') return "Problema de acesso";
    if (de === 'ERRO_SISTEMA') return "Erro no sistema";
    if (de === 'SUGESTAO_MELHORIA') return "Sugestão de melhoria";
    if (de === 'DUVIDA_NEGOCIAL') return "Dúvida negocial";
    if (de === 'DUVIDA_USO_SISTEMA') return "Dúvida uso do sistema";
    if (de === 'INCONSISTENCIA_DADO') return "Inconsistencia de dado";
    if (de === 'OUTROS') return "Outros";
    return de;
}

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let qtd = 0;

export default function TabelaChamadosForm(props) {
    const colunas = [
        { nome: 'Número chamado', campo: 'numeroChamada' },
        { nome: 'Tipo', campo: 'tipoSolicitacao' },
        { nome: 'Situação', campo: 'situacao' },
        { nome: 'Solicitante', campo: 'nome' },
        { nome: 'Data solicitação', campo: 'dataSolicitacao', 'transformacao': formataData },
        { nome: 'Data inicio atendimento', campo: 'dataInicioAtend', 'transformacao': formataData },
        { nome: 'Data conclusão', campo: 'dataConclusao', 'transformacao': formataData },
        { nome: 'Responsável', campo: 'responsavel' },
    ];
    const classes = useStyles();
    const { dados,  anexo } = props;
    const [value, setValue] = useState([]);
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [selected, setSelected] = React.useState();
    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });
    useEffect(() => {
        if (dados && dados.length > 0) {
            fonteDeDados = dados;
            filtraDado();
        }
    }, [dados]);

    useEffect(() => {
        fonteDeDados = dados;
        filtraDado();
    }, [ordem, pagina, bloco]);


    const handleRowClick = (event, p, i) => {
        setSelected(i);
        setValue(p)
    };
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
    }

    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
            } else {
                disparaSearch();
            }
        }, tempo);
    }

    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];
        let arrOrdenado = ordenar(fonteDeDados);
        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                let campos = [];
                if (filtroCampo.includes('"')) {
                    campos.push(filtroCampo.split('"')[1])
                } else {
                    campos = filtroCampo.split(' ');
                }
                for (let x in d) {
                    for (var y in campos) {
                        if (typeof d[x] === 'string') {
                            if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                arr.push(d);
                                break;
                            }
                        } else if (d[x] == campos[y]) {
                            arr.push(d);
                            break;
                        }
                    }
                }
            } else {
                arr.push({
                    id: d.id,
                    numeroChamada: d.numeroChamada,
                    email: d.email,
                    tipoSolicitacao: deParaTipo(d.tipoSolicitacao),
                    situacao: deParaSituacao(d.situacao),
                    descricaoOcorrencia: d.descricaoOcorrencia,
                    dataSolicitacao: d.dataSolicitacao,
                    dataInicioAtend: d.dataInicioAtend,
                    dataConclusao: d.dataConclusao,
                    solucao: d.solucao,
                    responsavel: d.responsavel,
                    nome: d.nome,
                    anexo:d.anexo
                });
            }
        });
        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (index++ > pagina - 2) break;
        }

        setDadosFiltrado(arrBloco);
    }

    function ordenar(arr) {
        let ordenado = arr;
        for (let c in colunas) {
            let nCampo = colunas[c].nome;
            let campo = colunas[c].campo;
            if (ordem.campo === nCampo) {
                const asc = (ordem.ordem === 'asc') ? 1 : -1;
                ordenado = ordenado.sort((a, b) => ((a[campo] < b[campo]) ? 1 * asc : -1 * asc));
            }
        }

        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);

        setBloco(0);
    }

    function getValue(row, campo, transformacao) {
        let split = campo.split("\.");        
        if (split.length > 1) {
            try {
                let lstObjt = row;
                for (let c in split) {
                    lstObjt = transformacao ? transformacao(lstObjt[split[c]]) : lstObjt[split[c]];
                }
                return lstObjt;
            } catch (erro) {
                return ""
            }
        }
        return transformacao ? transformacao(row[split[0]]) : row[split[0]];
    }

    function formataData(dt) {
        if (!dt) return "";
        return FormataData(new Date(dt));
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='lg'>
                <Grid container spacing={3} className={classes.root}>
                    <Grid item xs={12} >

                        <Typography variant="h6" gutterBottom>Selecione o chamado</Typography>

                    </Grid>
                    {dados && dados.length > 5 &&
                        <Grid item xs={12} >
                            <CampoBusca handleSearch={handleSearch} />
                        </Grid>
                    }
                    <Grid item xs={12} >
                        <TableContainer component={Paper} className={classes.root}>
                            <Table aria-label="customized table" size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            colunas && colunas.map((item, i) => (
                                                <TableCell key={i} sortDirection={false} className={classes.tableHead}>
                                                    <TableSortLabel
                                                        active={ordem.campo === item.nome}
                                                        direction={ordem.ordem}
                                                        onClick={(e) => createSortHandler(item.nome)}
                                                    >
                                                        {item.nome}
                                                    </TableSortLabel>
                                                </TableCell>
                                            ))
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                        <TableRow key={i} onClick={(event) => handleRowClick(event, row, i)}
                                            selected={i === selected}
                                        >
                                            {
                                                colunas && colunas.map((item, ii) => (
                                                    <TableCell key={ii + "-" + i}>
                                                        {getValue(row, item.campo, item.transformacao)}

                                                        {/* <span dangerouslySetInnerHTML={{
                                                            __html: row[item.campo]
                                                        }}></span> */}


                                                    </TableCell>
                                                ))
                                            }

                                            {/* <TableCell>{row.segundo}</TableCell>
                                                <TableCell>{row.terceiro}</TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            dados &&
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={qtd}
                                rowsPerPage={pagina}
                                page={bloco}
                                labelRowsPerPage="Registros por página"
                                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        }

                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={value.numeroChamada || ''}
                            fullWidth
                            label="Número Chamado"
                            id="numeroChamado"
                            name="numeroChamado"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            //disabled={disable && disable === true}
                            value={value.tipoSolicitacao || ''}
                            fullWidth
                            label="Tipo Solicitação"
                            id="tipoSolicitacao"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            //disabled={disable && disable === true}
                            value={value.situacao || ''}
                            fullWidth
                            label="Situação"
                            id="situacao"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            rows={6}
                            value={value.descricaoOcorrencia || ''}
                            fullWidth
                            label="Descrição da Ocorrência"
                            id="descricaoOcorrencia"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            id="dataSolicitacao"
                            label="Data solicitação"
                            format="dd/MM/yyyy"
                            value={value.dataSolicitacao || null}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                            disabled
                        // InputProps={{
                        //     readOnly: disable && disable === true,
                        // }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            id="dataConclusao"
                            label="Data resolução"
                            format="dd/MM/yyyy"
                            value={value.dataConclusao || null}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                            disabled
                        // InputProps={{
                        //     readOnly: disable && disable === true,
                        // }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            rows={6}
                            //disabled={disable && disable === true}
                            value={value.solucao || ''}
                            fullWidth
                            label="Solução"
                            id="solucao"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Typography variant="h6" gutterBottom>Anexo Chamado</Typography>
                    <TableContainer component={Paper} >
                                            <Table aria-label="customized table" size={'small'}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sortDirection={false} className={classes.tableHead}>
                                                            Documento
                                                        </TableCell>
                                                        <TableCell width='100px' align="center" className={classes.tableHead}>
                                                            Ação
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {value.anexo && value.anexo.map((row, i) => (
                                                        <TableRow key={i}>
                                                            <TableCell>{row.nome}</TableCell>
                                                            <TableCell>
                                                                {row.tipo !== -1 &&
                                                                    <center>
                                                                        <Download doc={row} />
                                                                    </center>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {
                                                        (!value.anexo || value.anexo.length === 0) &&
                                                        <TableRow>
                                                            <TableCell>
                                                                Nenhum registro encontrado!
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                </Grid>
            </Container>
        </MuiPickersUtilsProvider>
    );
}
