import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { useStyles } from '../../constants';


const useStylesPagina = makeStyles((theme) => ({
    paper: {
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function GenericTransferList(props) {
    const { titulo, setLeft, left, right, setRight, tituloleft, tituloright, handleAll } = props;
    const classes = useStyles();
    const classesPagina = useStylesPagina();
    const [checked, setChecked] = React.useState([]);
    // const [left, setLeft] = React.useState([0, 1, 2, 3]);
    // const [right, setRight] = React.useState([4, 5, 6, 7]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(ordenarArrString(right.concat(left)));
        setLeft([]);
        if(handleAll) handleAll(right.concat(left));
    };

    const handleCheckedRight = () => {
        setRight(ordenarArrString(right.concat(leftChecked)));
        setLeft(ordenarArrString(not(left, leftChecked)));
        setChecked(not(checked, leftChecked));
        if(handleAll) handleAll(right.concat(leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(ordenarArrString(left.concat(rightChecked)));
        setRight(ordenarArrString(not(right, rightChecked)));
        setChecked(not(checked, rightChecked));
        if(handleAll) handleAll(not(right, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(ordenarArrString(left.concat(right)));
        setRight([]);
        if(handleAll) handleAll([]);
    };

    function ordenarArrString(arr) {
        return arr.sort((a, b) => (a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? -1 : a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? 1 : 0));
    }

    const customList = (items) => (
        <Paper className={classesPagina.paper}>
            <List dense component="div" role="list">
                {items && items.length > 0 && items.map((value) => {
                    const labelId = `transfer-list-item-${value}-label`;

                    return (
                        <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    return (
        // <Grid container spacing={3} className={classes.root}>
        //     <Grid item xs={12} >
        <Accordion defaultExpanded={true} >
            {/* {titulo && */}
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
            >

                <Typography className={classes.heading} gutterBottom>{titulo}</Typography>
            </AccordionSummary>
            {/* } */}
            <AccordionDetails>
                <Grid container spacing={2} className={classesPagina.root}>
                    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
                        <Grid item xs={5}>
                            <Typography variant="subtitle1">{tituloleft}</Typography>
                            {customList(left)}
                        </Grid>
                        <Grid item xs={2}>
                            <Grid container direction="column" alignItems="center">
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classesPagina.button}
                                    onClick={handleAllRight}
                                    disabled={left.length === 0}
                                    aria-label="move all right"
                                >≫</Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classesPagina.button}
                                    onClick={handleCheckedRight}
                                    disabled={leftChecked.length === 0}
                                    aria-label="move selected right"
                                >&gt;</Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classesPagina.button}
                                    onClick={handleCheckedLeft}
                                    disabled={rightChecked.length === 0}
                                    aria-label="move selected left"
                                >&lt;</Button>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classesPagina.button}
                                    onClick={handleAllLeft}
                                    disabled={right.length === 0}
                                    aria-label="move all left"
                                > ≪</Button>
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Typography variant="subtitle1">{tituloright}</Typography>
                            {customList(right)}
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
        //     </Grid>
        // </Grid>
    );
}
