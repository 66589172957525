import React, { useEffect, useState } from 'react';
import {
    Grid, Button, Paper, Accordion, AccordionSummary, Typography, AccordionDetails,
    MenuItem, Select, TextField
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, FormataMoeda } from '../../../constants';
import GenericTable from '../../template/GenericTable';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import NumberFormat from 'react-number-format';

let totalProdf = 0, totalIDEAS = 0, totalFIDE = 0;

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={4}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

export default function ConsolidadoPevisaoTable(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;

    const [resumoEmpresa, setResumoEmpresa] = useState([]);
    const [resumoEmpresaTotal, setResumoEmpresaTotal] = useState([]);
    const [beneficiadosLocalidade, setBeneficiadosLocalidade] = useState([]);
    const [beneficiadosSetor, setBeneficiadosSetor] = useState([]);
    const [beneficiadosSetorTotal, setBeneficiadosSetorTotal] = useState();
    const [beneficiadosLocalTotal, setBeneficiadosLocalTotal] = useState();
    const [planejamentoPRODF, setPlanejamentoPRODF] = useState([]);
    const [planejamentoPRODFTotal, setPlanejamentoPRODFTotal] = useState();

    const [planejamentoFIDE, setPlanejamentoFIDE] = useState([]);
    const [planejamentoFIDETotal, setPlanejamentoFIDETotal] = useState();
    const [planejamentoIDEAS, setPlanejamentoIDEAS] = useState([]);
    const [planejamentoIDEASTotal, setPlanejamentoIDEASTotal] = useState();

    const [planejamentoProgramas, setPlanejamentoProgramas] = useState([]);
    const [planejamentoProgramasTotal, setPlanejamentoProgramasTotal] = useState();

    const [previsoes, setPrevisoes] = useState();
    const [novoProjeto, setNovoProjeto] = useState();
    const [exercicio, setExcercicio] = useState();
    const [lstExercicio, setLstExercicio] = useState([]);

    const [custoOportunidade, setCustoOportunidade] = useState([]);
    const [custoOportunidadeTotal, setCustoOportunidadeTotal] = useState();

    const [dadoApoio, setDadoApoio] = useState();
    const [taxa, setTaxa] = useState();
    // const [dadoTables, setDadoTables] = useState(dt);

    useEffect(() => {
        setTitulo("Planejamento Orçamentário do FUNDEFE");
        buscaPlanejamentos();
        buscaFundefeNovoProjeto();
        totalProdf = 0; totalIDEAS = 0; totalFIDE = 0;
    }, []);
    useEffect(() => {
        if (exercicio) {
            recalcula();
        }
    }, [exercicio]);

    function getMaxCdi(dadoApio) {
        let max = 0;
        for (let x in dadoApio.dadosCDI) {
            const a = dadoApio.dadosCDI[x];
            if (max < a.cdi) max = a.cdi
        }
        return max;
    }

    useEffect(() => {
        if (dadoApoio) {
            if (dadoApoio.taxaJurosAnual) {
                setTaxa(dadoApoio.taxaJurosAnual);
            }
            recalculaDadoApoio();
        }
    }, [dadoApoio]);

    useEffect(() => {
        let arr = [];
        if (previsoes) {
            for (let x in previsoes) {
                const p = previsoes[x];
                if (!arr.find(e => e === p.exercicio)) {
                    arr.push(p.exercicio);
                }
            }
        }
        if (novoProjeto) {
            for (let x in novoProjeto) {
                const p = novoProjeto[x];
                if (!arr.find(e => e === p.exercicio)) {
                    arr.push(p.exercicio);
                }
            }
        }
        setLstExercicio(arr);
    }, [previsoes, novoProjeto]);

    function buscaFundefeNovoProjeto() {
        // setWait(true);
        fetch("/api/fundefeNovoProjeto/buscarParcelas")
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            setNovoProjeto(json);
                        })
                }
            }
            ).catch(error => {
                // setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }

    function buscaPlanejamentos() {
        fetch('/api/fundefePrevisao')
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            setPrevisoes(json);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function recalculaDadoApoio() {
        let arr = [];

        const juros = Math.pow((1 + dadoApoio.juros / 100), 12);

        const maxCdi = getMaxCdi(dadoApoio);

        let jr = (maxCdi * 0.95) / 100;
        let jurosMercado = ((Math.pow(1 + jr, 12)) - 1) * 100;

        if (dadoApoio.taxaJurosAnual) {
            jr = dadoApoio.taxaJurosAnual;
            jurosMercado = jr;
        }

        const jurosFUNDEFE = (juros - 1) * 100;

        let ctOportunidade = 1 + (jurosMercado - jurosFUNDEFE) / 100;

        //jurosMercado = jurosMercado.toFixed(2);
        arr.push({
            programa: "FIDE",
            valor: totalFIDE,
            txJurosFUNDEFE: jurosFUNDEFE,
            txJurosMercado: jurosMercado,
            ctOportunidade: ctOportunidade,
            jurosFUNDEFE: (totalFIDE * (1 + jurosFUNDEFE / 100)) - totalFIDE,
            jurosMercado: totalFIDE * (1 + jurosMercado / 100) - totalFIDE,
            renuncia: (totalFIDE * (1 + jurosMercado / 100) - totalFIDE) - ((totalFIDE * (1 + jurosFUNDEFE / 100)) - totalFIDE),
            totalBeneficio: (totalFIDE * ctOportunidade)
        });
        arr.push({
            programa: "PRODF",
            valor: totalProdf,
            txJurosFUNDEFE: jurosFUNDEFE,
            txJurosMercado: jurosMercado,
            ctOportunidade: ctOportunidade,
            jurosFUNDEFE: (totalProdf * (1 + jurosFUNDEFE / 100)) - totalProdf,
            jurosMercado: totalProdf * (1 + jurosMercado / 100) - totalProdf,
            renuncia: (totalProdf * (1 + jurosMercado / 100) - totalProdf) - ((totalProdf * (1 + jurosFUNDEFE / 100)) - totalProdf),
            totalBeneficio: (totalProdf * ctOportunidade)
        });

        arr.push({
            programa: "IDEAS",
            valor: totalIDEAS,
            txJurosFUNDEFE: jurosFUNDEFE,
            txJurosMercado: jurosMercado,
            ctOportunidade: ctOportunidade,
            jurosFUNDEFE: (totalIDEAS * (1 + jurosFUNDEFE / 100)) - totalIDEAS,
            jurosMercado: totalIDEAS * (1 + jurosMercado / 100) - totalIDEAS,
            renuncia: (totalIDEAS * (1 + jurosMercado / 100) - totalIDEAS) - ((totalIDEAS * (1 + jurosFUNDEFE / 100)) - totalIDEAS),
            totalBeneficio: (totalIDEAS * ctOportunidade)
        });
        setCustoOportunidade(arr);

        setCustoOportunidadeTotal([
            "Total",
            FormataMoeda(totalFIDE + totalProdf + totalIDEAS),
            formataTaxa(arr[0].txJurosFUNDEFE),
            formataTaxa(arr[0].txJurosMercado),
            formataTaxa(arr[0].ctOportunidade),
            FormataMoeda(arr[0].jurosFUNDEFE + arr[1].jurosFUNDEFE + arr[2].jurosFUNDEFE),
            FormataMoeda(arr[0].jurosMercado + arr[1].jurosMercado + arr[2].jurosMercado),
            FormataMoeda(arr[0].renuncia + arr[1].renuncia + arr[2].renuncia),
            FormataMoeda(arr[0].totalBeneficio + arr[1].totalBeneficio + arr[2].totalBeneficio),
        ])
    }
    function recalcula() {
        let arr = [];
        let arrSetor = [];
        let arrLocalicade = [];
        let arrResumoPlanejamentoProdf = [];
        let arrResumoPlanejamentoFIDE = [];
        let arrResumoPlanejamentoIDEAS = [];

        let total = 0;
        const parelaFiltrada = previsoes.filter(p => p.exercicio === exercicio);
        for (let x in parelaFiltrada) {
            const p = parelaFiltrada[x];
            arr.push({
                processo: p.processo,
                cnpj: p.cnpj,
                razaoSocial: p.razaoSocial,
                matrizFilial: p.isMatriz ? 'Matriz' : "Filial",
                ra: p.ra,
                setor: p.setor,
                programa: p.programa,
                valor: p.valor
            });
            if (p.programa === 'PRODF') {
                arrResumoPlanejamentoProdf.push({
                    processo: p.processo,
                    cnpj: p.cnpj,
                    razaoSocial: p.razaoSocial,
                    matrizFilial: p.isMatriz ? 'Matriz' : "Filial",
                    ra: p.ra,
                    setor: p.setor,
                    valor: p.valor
                });
                totalProdf += p.valor;
            } else if (p.programa === 'FIDE') {
                arrResumoPlanejamentoFIDE.push({
                    processo: p.processo,
                    cnpj: p.cnpj,
                    razaoSocial: p.razaoSocial,
                    matrizFilial: p.isMatriz ? 'Matriz' : "Filial",
                    ra: p.ra,
                    setor: p.setor,
                    valor: p.valor
                });
                totalFIDE += p.valor;
            } else if (p.programa === 'IDEAS') {
                arrResumoPlanejamentoIDEAS.push({
                    processo: p.processo,
                    cnpj: p.cnpj,
                    razaoSocial: p.razaoSocial,
                    matrizFilial: p.isMatriz ? 'Matriz' : "Filial",
                    ra: p.ra,
                    setor: p.setor,
                    valor: p.valor
                });
                totalIDEAS += p.valor;
            }
            recalculaSetor(arrSetor, p);
            recalculaLocalidade(arrLocalicade, p);
            // setPlanejamentoIDEASTotal(["TOTAL", "", "", "", "", "", FormataMoeda(totalIDEAS)]);
            // setPlanejamentoPRODFTotal(["TOTAL", "", "", "", "", "", FormataMoeda(totalProdf)]);
            // setPlanejamentoFIDETotal(["TOTAL", "", "", "", "", "", FormataMoeda(totalFIDE)]);

            total += p.valor;
        }
        const parelaFiltradaNP = novoProjeto.filter(p => p.exercicio === exercicio);
        for (let x in parelaFiltradaNP) {
            const p = parelaFiltradaNP[x];
            arr.push({
                processo: "",
                cnpj: "",
                razaoSocial: "Novo Projeto",
                matrizFilial: "",
                ra: "",
                setor: "",
                programa: p.programa,
                valor: p.valorOrcamento
            });
            if (p.programa === 'PRODF') {
                arrResumoPlanejamentoProdf.push({
                    processo: "",
                    cnpj: "",
                    razaoSocial: "Novo Projeto",
                    matrizFilial: "",
                    ra: "",
                    setor: "",
                    valor: p.valorOrcamento
                });
                totalProdf += p.valorOrcamento;
            }
            if (p.programa === 'FIDE') {
                arrResumoPlanejamentoFIDE.push({
                    processo: "",
                    cnpj: "",
                    razaoSocial: "Novo Projeto",
                    matrizFilial: "",
                    ra: "",
                    setor: "",
                    valor: p.valorOrcamento
                });
                totalFIDE += p.valorOrcamento;
            }
            if (p.programa === 'IDEAS') {
                arrResumoPlanejamentoIDEAS.push({
                    processo: "",
                    cnpj: "",
                    razaoSocial: "Novo Projeto",
                    matrizFilial: "",
                    ra: "",
                    setor: "",
                    valor: p.valorOrcamento
                });
                totalIDEAS += p.valorOrcamento;
            }
            total += p.valorOrcamento;
            let itemSetor = arrSetor.find(s => s.localidadeSetor === "Novo Projeto");
            if (itemSetor) {
                itemSetor.quantidade++;
                itemSetor.valor += p.valorOrcamento;
            } else {
                arrSetor.push({
                    localidadeSetor: "Novo Projeto",
                    quantidade: 1,
                    valor: p.valorOrcamento
                })
            }
            let itemLocalidade = arrLocalicade.find(s => s.ra === "Novo Projeto");
            if (itemLocalidade) {
                itemLocalidade.quantidade++;
                itemLocalidade.valor += p.valorOrcamento;
            } else {
                arrLocalicade.push({
                    ra: "Novo Projeto",
                    quantidade: 1,
                    valor: p.valorOrcamento
                })
            }
        }

        setResumoEmpresa(arr);
        setResumoEmpresaTotal(["TOTAL", "", "", "", "", "", "", FormataMoeda(total)]);
        setBeneficiadosSetor(arrSetor);
        setBeneficiadosLocalidade(arrLocalicade);
        setPlanejamentoPRODF(arrResumoPlanejamentoProdf);
        setPlanejamentoFIDE(arrResumoPlanejamentoFIDE);
        setPlanejamentoIDEAS(arrResumoPlanejamentoIDEAS);
        setBeneficiadosSetorTotal(calculoTotal(arrSetor));
        setBeneficiadosLocalTotal(calculoTotal(arrLocalicade));
        setPlanejamentoProgramas([
            { programa: "PRODF", valor: totalProdf },
            { programa: "FIDE", valor: totalFIDE },
            { programa: "IDEAS", valor: totalIDEAS }
        ]);
        setPlanejamentoProgramasTotal(["TOTAL", FormataMoeda(totalProdf + totalIDEAS + totalFIDE)]);
        setPlanejamentoIDEASTotal(["TOTAL", "", "", "", "", "", FormataMoeda(totalIDEAS)]);
        setPlanejamentoPRODFTotal(["TOTAL", "", "", "", "", "", FormataMoeda(totalProdf)]);
        setPlanejamentoFIDETotal(["TOTAL", "", "", "", "", "", FormataMoeda(totalFIDE)]);
    }
    function calculoTotal(setorLocal) {
        let qtd = 0;
        let total = 0;
        for (let x in setorLocal) {
            const sl = setorLocal[x];
            qtd += sl.quantidade;
            total += sl.valor;
        }
        return ["TOTAL", qtd, FormataMoeda(total)];
    }
    function recalculaLocalidade(arr, previsao) {
        let item = arr.find(s => s.ra === previsao.ra);
        if (item) {
            item.quantidade++;
            item.valor += previsao.valor
        } else {
            arr.push({
                ra: previsao.ra,
                quantidade: 1,
                valor: previsao.valor
            })
        }
    }
    function recalculaSetor(arr, previsao) {
        let item = arr.find(s => s.localidadeSetor === previsao.setor);
        if (item) {
            item.quantidade++;
            item.valor += previsao.valor
        } else {
            arr.push({
                localidadeSetor: previsao.setor,
                quantidade: 1,
                valor: previsao.valor
            })
        }
    }
    function imprimir() {
        const dado = {
            exercicio: exercicio,
            novoProjeto: false,
            resumoEmpresa: resumoEmpresa,
            resumoEmpresaTotal: resumoEmpresaTotal,
            beneficiadosLocalidade: beneficiadosLocalidade,
            beneficiadosSetor: beneficiadosSetor,
            planejamentoPRODF: planejamentoPRODF,
            planejamentoFIDE: planejamentoFIDE,
            planejamentoIDEAS: planejamentoIDEAS,
            planejamentoProgramas: planejamentoProgramas,
            custoOportunidade: custoOportunidade
        }
        fetch("/api/fundefePrevisao/previsaoOrcamentariaConsolidada?nm=Planejamento", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                // Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    const handleChangeExercicio = (event) => {
        totalProdf = 0;
        totalIDEAS = 0;
        totalFIDE = 0;
        const val = event.target.value;
        if (val != '') {
            setExcercicio(val);
            buscaDadoApoio(val);
        } else {
            setExcercicio("");
            setResumoEmpresa(null);
            setResumoEmpresaTotal(null);
            setBeneficiadosLocalidade(null);
            setBeneficiadosSetor(null);
        }
    };
    function buscaDadoApoio(exercicio) {
        fetch('/api/fundefeDadoApoio/byExercicio/' + exercicio)
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            setDadoApoio(json);
                        })
                } else {
                    setMensagem('Exercício sem Dado de Apoio cadastrado');
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function formataTaxa(val) {
        let s = val.toFixed(4);
        s = s.endsWith('000') ? s.substr(0, s.length - 3) : s;
        return s.replace(".", ",");
    }
    function handleAtualizaTaxa(e) {
        setTaxa(e.target.value)
    }
    function aplicar() {
        let juros = taxa;

        juros = juros.replace(/[.*+\-?^${}()|[\]\\]/g, '');
        juros = juros.replace(",", ".");


        fetch('/api/fundefeDadoApoio/taxaJuros', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify({ juros: parseFloat(juros), exercicio: exercicio }),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then(json => {
                    setDadoApoio(json);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem('exercício ja cadastrado posteriormente.');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    return (
        <Paper >
            <Grid container spacing={3} className={classes.root} >
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="lbExercicio">Excercício</InputLabel>
                        <Select
                            labelId="lbExercicio"
                            id="exercicio"
                            value={exercicio || ''}
                            onChange={handleChangeExercicio}
                            label="Exercício"
                        >
                            <MenuItem value="">Nenhum</MenuItem>
                            {lstExercicio && lstExercicio.map((row, i) => (
                                <MenuItem key={i} value={row}>{row}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo por empresa</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            // disableSearch
                            dados={resumoEmpresa}
                            rowTotal={resumoEmpresaTotal}
                            colunas={[
                                { nome: 'Processos', campo: 'processo' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Razão Social', campo: 'razaoSocial' },
                                { nome: 'Matriz/Filia', campo: 'matrizFilial' },
                                { nome: 'RA', campo: 'ra' },
                                { nome: 'Setor', campo: 'setor' },
                                { nome: 'Programa', campo: 'programa' },
                                { nome: 'Valor', campo: 'valor', transformacao: FormataMoeda, align: "right" }
                            ]} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Setores a serem beneficiados</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={6}>
                            <GenericTable
                                disableSearch
                                dados={beneficiadosLocalidade}
                                rowTotal={beneficiadosLocalTotal}
                                colunas={[
                                    { nome: 'Localidade (RA)', campo: 'ra' },
                                    { nome: 'Quantidade', campo: 'quantidade', align: "right" },
                                    { nome: 'Valor', campo: 'valor', transformacao: FormataMoeda, align: "right" }
                                ]} />
                        </Grid>
                        <Grid item xs={6}>
                            <GenericTable
                                disableSearch
                                dados={beneficiadosSetor}
                                rowTotal={beneficiadosSetorTotal}
                                colunas={[
                                    { nome: 'Setor', campo: 'localidadeSetor' },
                                    { nome: 'Quantidade', campo: 'quantidade', align: "right" },
                                    { nome: 'Valor', campo: 'valor', transformacao: FormataMoeda, align: "right" }
                                ]} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo planejamento PRODF</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={planejamentoPRODF}
                            rowTotal={planejamentoPRODFTotal}
                            colunas={[
                                { nome: 'Processos', campo: 'processo' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Razão Social', campo: 'razaoSocial' },
                                { nome: 'Matriz/Filia', campo: 'matrizFilial' },
                                { nome: 'Ra', campo: 'ra' },
                                { nome: 'Setor', campo: 'setor' },
                                { nome: 'Valor', campo: 'valor', transformacao: FormataMoeda, align: "right" }
                            ]} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo planejamento FIDE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={planejamentoFIDE}
                            rowTotal={planejamentoFIDETotal}
                            colunas={[
                                { nome: 'Processos', campo: 'processo' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Razão Social', campo: 'razaoSocial' },
                                { nome: 'Matriz/Filia', campo: 'matrizFilial' },
                                { nome: 'Ra', campo: 'ra' },
                                { nome: 'Setor', campo: 'setor' },
                                { nome: 'Valor', campo: 'valor', transformacao: FormataMoeda, align: "right" }
                            ]} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo planejamento IDEAS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={planejamentoIDEAS}
                            rowTotal={planejamentoIDEASTotal}
                            colunas={[
                                { nome: 'Processos', campo: 'processo' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Razão Social', campo: 'razaoSocial' },
                                { nome: 'Matriz/Filia', campo: 'matrizFilial' },
                                { nome: 'Ra', campo: 'ra' },
                                { nome: 'Setor', campo: 'setor' },
                                { nome: 'Valor', campo: 'valor', transformacao: FormataMoeda, align: "right" }
                            ]} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo planejamento programas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={planejamentoProgramas}
                            rowTotal={planejamentoProgramasTotal}
                            colunas={[
                                { nome: 'Programa', campo: 'programa' },
                                { nome: 'Valor Total', campo: 'valor', transformacao: FormataMoeda, align: "right" }
                            ]} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Custo de oportunidade</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                value={taxa || ''}
                                InputProps={{
                                    inputComponent: MonetarioField
                                }}
                                name="taxaJuros"
                                onChange={handleAtualizaTaxa}
                                label="Taxa Juros Mercado a.a"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                disabled={!dadoApoio}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary"
                                disabled={!dadoApoio}
                                onClick={aplicar}
                            >
                                Aplicar
                            </Button>
                        </Grid>
                        <GenericTable
                            disableSearch
                            dados={custoOportunidade}
                            rowTotal={custoOportunidadeTotal}
                            colunas={[
                                { nome: 'Programa', campo: 'programa' },
                                { nome: 'Principal', campo: 'valor', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Taxa Juros FUNDEFE(a.a)', campo: 'txJurosFUNDEFE', transformacao: formataTaxa, align: "right" },
                                { nome: 'Taxa Juros Mercado(a.a)', campo: 'txJurosMercado', transformacao: formataTaxa, align: "right" },
                                { nome: 'Custo de Oportunidade', campo: 'ctOportunidade', transformacao: formataTaxa, align: "right" },
                                { nome: 'Juros Fundefe', campo: 'jurosFUNDEFE', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Juros Mercado', campo: 'jurosMercado', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Renuncia Receita', campo: 'renuncia', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Total Benefício', campo: 'totalBeneficio', transformacao: FormataMoeda, align: "right" }
                            ]} />
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Grid container spacing={3} className={classes.root}>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                        Sair
            </Button>
                    <Button variant="contained" color="primary" onClick={() => imprimir()} >
                        Imprimir
            </Button>
                </Grid>
            </Grid >
        </Paper>

    );
}
