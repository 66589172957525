import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams, useLocation } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Container, Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useStyles } from "../../constants";
import ProjetoCadastrarForm from "./form/ProjetoCadastrarForm";
import DadosCadastraisEmpresaForm from './form/DadosCadastraisEmpresaForm';
import ProjetoForm from './form/ProjetoForm';
import InformacoesIniciaisForm from './form/InformacoesIniciaisForm';
import MetasEmprego from './form/MetasEmprego';
import ResponseSocioAmbientalForm from './form/ResponseSocioAmbientalForm';
import FaturamentoImpostoForm from './form/FaturamentoImpostoForm';
import FormalizacaoForm from './form/FormalizacaoForm';
import DeclaracaoForm from './form/DeclaracaoForm';
import InformacaoProgramaDesenvolvimento from './form/InformacaoProgramaDesenvolvimento';
import Requerimento from './form/Requerimento';
import { useCurrentUser } from "../../server/UseCurrentUser";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

let acaoSubmit = null;

export default function ProjetoTabPanel(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setMensagem, setErro,setSuccess } = props;
    const [error, setError] = useState(resetErro());
    const [anexos, setAnexos] = useState([{ tipo: -1 }]);
    const [value, setValue] = React.useState(0);
    const [txt, setTxt] = useState('');
    const [projeto, setProjeto] = React.useState({
        declaracao: false
    });
    const history = useHistory();
    const location = useLocation();
    let { id } = useParams();
    let [profile] = useCurrentUser();
    const [disable, setDisable] = useState(false);

    useEffect(() => {
        if (location.pathname.includes("view")) {
            setDisable(true);
        }
        if (id) {
            buscaProjeto();
        }
        return () => { }
    }, []);
    const handleChange = (event, newValue) => {
        if (newValue === 2) {
            setTitulo("Dados Cadastrais da Empresa")
        }
        
        setValue(newValue);
    };
    function sair() {
        history.replace("/projeto/consultarProjeto");
    }

    const salvarRascunho = () => {
        submitForm('/api/projeto/rascunho');
    }
    const proximaPage = () => {
        const x = value;
        if (!menuAssunto() && x == 4) {
            // console.log("exclusão zona")
            setValue(x + 2);
        } else {
            setValue(x + 1);
        }
        if (!disable) salvarRascunho();
    }
    const anteriorPage = () => {
        const x = value;
        if (!menuAssunto() && x == 6) {
            // console.log("exclusão zona")
            setValue(x - 2);
        } else {
            setValue(x - 1);
        }
    }
    const disableEnviar = () => {
        if (!projeto || !projeto.declaracao) return true;
        if (!projeto.descriacaoRequerimento) return true;
        return !projeto.declaracao;
    }

    const enviar = () => {
        submitForm('/api/projeto/enviar');
    }
    function buscaProjeto() {
        setWait(true);
        fetch('/api/projeto/' + id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then(json => {
                    json.declaracao = false;
                    setProjeto(json);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function submitForm(url) {
        setWait(true);
        projeto.requerente = {
            id: profile.id,
            cpf: profile.CPF,
            nome: profile.name,
            telefone: profile.telefone,
            email: profile.email
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(projeto),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then(json => {
                    if (json.status === 'RASCUNHO') {
                        setMensagem("Rascunho salvo com sucesso.");
                        setProjeto(json);
                        // history.replace("/projeto/consultarProjeto");
                    } else {
                        history.replace("/projeto/consultarProjeto");
                        setSuccess("Requerimento criado com sucesso. protocolo:" + json.protocolo);
                        confirmaRequerimento(json.protocolo);
                    }
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    topFunction();
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            topFunction();
        });
    }
    function topFunction() {
        setTimeout(function () {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }, 30);
    }

    function disableDadosCadastrais() {
        if (!projeto.assunto) return true;
        if (!projeto.empresa) return true;
    }
    function disableDeclaracao() {
        if (!projeto.sociosDiretores) return true;

    }
    const disableRequerimento = () => {
        if (!projeto.assunto) return true;
        if (!projeto.empresa) return true;
        if (!projeto.sede) return true;
    }
    const disableProximo = () => {
        if (!projeto.assunto) return true;
        if (!projeto.empresa) return true;
        if (value === 2) {
            if (!projeto.sede) return true;
        }
    }

    function menuAssunto() {
        if (projeto.assunto == "TRANSFERÊNCIA (DESENVOLVE-DF)") return false;
        else if (projeto.assunto == "ADESÃO DIRETA AO DESENVOLVE-DF") return false;
        else if (projeto.assunto == "ADESÃO DIRETA AO DESENVOLVE + TRANSFERÊNCIA") return false;
        else if (projeto.assunto == "ADESÃO DIRETA AO DESENVOLVE-DF + REVOGAÇÃO ADMINISTRATIVA DE CANCELAMENTO") return false;
        else if (projeto.assunto == "ADESÃO DIRETA AO DESENVOLVE-DF + REVOGAÇÃO ADMINISTRATIVA DE CANCELAMENTO + TRANSFERÊNCIA") return false;
        else if (projeto.assunto == "CONCESSÃO DESENVOLVE") return false;
        return true;
    }

    function confirmaRequerimento(a) {
        setError(resetErro());
        let dado = { ...value };
        dado.requerente = {
            nome: profile.name,
            email: profile.email,
            id: profile.id,
            cpf: profile.CPF,
            telefone: profile.telefone,
        };
        dado.assunto = projeto?.assunto
        dado.processo ={
            id:projeto?.empresa?.id,
            nProcesso:projeto?.lotes?.processo ,
            cnpj:projeto?.empresa?.cnpj ,
            razaoSocial:projeto?.empresa?.razaoSocial ,
            programa:" "
        }
        dado.protocolo = a;
        dado.dataCriacao = null;

        if (value.status === 'PENDENTE') {
            dado.status = "EM_ANALISE";
            dado.historico = [{
                tipo: "Reenviado",
                data: new Date(),
                texto: "Solicitação reenviada pelo empresario",
                responsavel: {
                    id: profile.id,
                    nome: profile.name,
                    email: profile.email
                }
            }];

        } else {
            dado.status = "EM_ANALISE";
            dado.historico = [{
                tipo: "EM_ANALISE",
                data: new Date(),
                texto: "Solicitação do empresario",
                responsavel: {
                    id: profile.id,
                    nome: profile.name,
                    email: profile.email
                }
            }];
        }

        // dado.documentos = anexos;
        dado.documentos = [];//anexos;
        projeto.documentos.forEach(a => {
            if (a.tipo !== -1) dado.documentos.push(a);
        })
        dado.descricao = txt;
        acaoSubmit = "tabela";
        // if (valida(dado)) {
        //     // criarRequerimento(dado);
        // } else {
        //     setTimeout(function () {
        //         document.body.scrollTop = 0;
        //         document.documentElement.scrollTop = 0;
        //     }, 300);
        // }
      
     
    }
    

    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: { erro: false, msg: '' },
            telefone: { erro: false, msg: '' },
            email: { erro: false, msg: '' }
        }
    }

    //ESTA ERRADO.....
    // function criarRequerimento(dado) {
    //     setWait(true);
      
    //     fetch('/api/processoProdf/requerimento', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json',
    //             Authorization: `Bearer ${localStorage.accessToken}`
    //         },
    //         body: JSON.stringify(dado),
    //         credentials: 'include'
    //     }).then(response => {
    //         if (response.ok && (response.status < 300)) {
    //             setWait(false);
               
    //         } else {
    //             response.json().then((error) => {
    //                 setWait(false);
    //                 setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    //             });
    //         }
    //     }).catch(error => {
    //         setWait(false);
    //         setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    //     });
    // }




    return (
        <Container maxWidth='lg'>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab value={0} label="Cadastrar Projeto" wrapped {...a11yProps(0)} />
                    <Tab value={1} label="PVS - Informações" {...a11yProps(1)} />
                    <Tab value={2} label="PVS - Dados Cadastrais" {...a11yProps(2)} disabled={disableDadosCadastrais()} />
                    <Tab value={3} label="PVS - Projeto" {...a11yProps(3)} disabled={disableRequerimento()} />
                    <Tab value={4} label="PVS - Metas de Emprego" {...a11yProps(4)} disabled={disableRequerimento()} />
                    {menuAssunto() && <Tab value={5} label="PVS - RESPONSABILIDADE SOCIAL/AMBIENTAL" {...a11yProps(5)} />}
                    <Tab value={6} label="PVS - FATURAMENTO/IMPOSTOS" {...a11yProps(6)} disabled={disableRequerimento()} />
                    <Tab value={7} label="PVS - DECLARAÇÃO" {...a11yProps(7)} disabled={disableDeclaracao()} />
                    <Tab value={8} label="PVS - FORMALIZAÇÃO" {...a11yProps(8)} disabled={disableRequerimento()} />
                    <Tab value={9} label="REQUERIMENTO" {...a11yProps(9)} disabled={disableRequerimento()} />
                    <Tab value={10} label="INFORMAÇÃO PROGRAMA DE DESENVOLVIMENTO" {...a11yProps(10)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <ProjetoCadastrarForm value={projeto} setValue={setProjeto} setWait={setWait} setMensagem={setMensagem} disable={disable} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <InformacoesIniciaisForm />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <DadosCadastraisEmpresaForm value={projeto} setValue={setProjeto} setWait={setWait} setMensagem={setMensagem} disable={disable} />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ProjetoForm value={projeto} setValue={setProjeto} disable={disable} />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <MetasEmprego value={projeto} setValue={setProjeto} disable={disable} />
            </TabPanel>
            <TabPanel value={value} index={5}>
                <ResponseSocioAmbientalForm value={projeto} setValue={setProjeto} disable={disable} />
            </TabPanel>
            <TabPanel value={value} index={6}>
                <FaturamentoImpostoForm value={projeto} setValue={setProjeto} disable={disable} />
            </TabPanel>
            <TabPanel value={value} index={7}>
                <DeclaracaoForm value={projeto} setValue={setProjeto} setWait={setWait} setMensagem={setMensagem}/>
            </TabPanel>
            <TabPanel value={value} index={8}>
                <FormalizacaoForm value={projeto} setValue={setProjeto} setWait={setWait} setMensagem={setMensagem} />
            </TabPanel>
            <TabPanel value={value} index={9}>
                <Requerimento value={projeto} setValue={setProjeto} setWait={setWait} setMensagem={setMensagem} disable={disable} />
            </TabPanel>

            <TabPanel value={value} index={10}>
                <InformacaoProgramaDesenvolvimento />
            </TabPanel>

            <Grid container justify="flex-end" className={classes.btns}>
                {(value !== 0) &&
                    <Button variant="contained" color="primary" onClick={anteriorPage} >
                        Anterior
                    </Button>
                }

                <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                    Sair
                </Button>
                {!disable && (value < 10) &&
                    <Button variant="contained" color="primary" onClick={salvarRascunho}>
                        Salvar Rascunho
                    </Button>
                }
                {(value < 9) &&
                    <Button variant="contained" color="primary" onClick={proximaPage} disabled={disableProximo()}>
                        Proximo
                    </Button>

                }
                {!disable && (value == 9) &&
                    <Button variant="contained" color="primary" onClick={enviar} disabled={disableEnviar()}>
                        Enviar
                    </Button>
                }
               
                
            </Grid>
        </Container>
    );
}
