import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography,
    Accordion, AccordionDetails, Divider, Dialog, DialogTitle, DialogContent, FormControl, InputLabel, Select, MenuItem, DialogActions
} from '@material-ui/core';
import { makeStyles, } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

import { useStyles, getQueryParams, FormataDataComHora } from "../../../constants";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Download from "../../../util/Download";
import  {CKEditor}  from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import ConfirmDialog from "../../../util/ConfirmDialog";
import HistoricoTable from "../unate/HistoricoTable";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FluxoPanel from "../../../util/FluxoPanelBPM";
import UnidadeDialog from "../../user/unidade/UnidadeDialog";
import { useUnidades } from "../../../server/UseUnidades";
import Service from '../../../services/Service';

const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);

const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '160px',
        overflow: 'auto'
    },
    descolcar: {
        // width: '100%',
        // height: 'auto',
        position: 'relative',
        // alignItems: 'center',
        display: 'flex',
        // background: 'green',
        marginLeft: 'auto',
        top: '37px',
        right: '10px',
        zIndex: 999
    }
}), { index: 1 });

let acao = '';
export default function ValidarGestaoUNATEForm(props) {
    const classes = useStyles();
    const classesLocal = useLocalStyle();
    const [processo, setProcesso] = useState();
    const { setTitulo, setErro, setWait, assunto, disable, setMensagem } = props;
    let { id } = useParams();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showUnidadeDialogProtocolo, setShowUnidadeDialogProtocolo] = useState(false);
    const [msgConfirmacao, setMsgConfirmacao] = useState('');
    const [fluxo, setFluxo] = useState();
    const [unidades] = useUnidades();
    const [tituloDialog, setTituloDialog] = useState('Selecione o Texto Padrão');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [assuntoDado, setAssuntoDado] = React.useState('');
    const [assuntoTexto, setAssuntoTexto] = useState([]);
    const [showUnidadeDialog, setShowUnidadeDialog] = useState(false);

    const [value, setValue] = useState({
        assunto: '',
        processo: '',
        cpf: '',
        nome: '',
        telefone: '',
        email: '',
        protocolo: '',
        tipo: '',
        fluxoPath: [],
        declaracao: false,
    });
    const [anexos, setAnexos] = useState([]);
    const history = useHistory();
    let [profile] = useCurrentUser();
    const param = getQueryParams(useLocation().search);
    const location = useLocation();
    const [txt, setTxt] = useState('');

    const [unidade, setUnidade] = React.useState();
    const [unidadeProtocolo, setUnidadeProtocolo] = React.useState();

    const [expanded, setExpanded] = useState(true);

    useEffect(() => {

        if (profile) {
            id = param.get('id');
            if (id) return;
            setValue({
                ...value,
                nome: profile.name,
                cpf: profile.CPF,
                email: profile.email
            });
        }
    }, [profile]);


    useEffect(() => {
        if (location.pathname.includes("view")) {
            setTitulo && setTitulo("Visualiar");
        } else {
            setTitulo && setTitulo("Validar Gestão UNATE");
        }
        const process = localStorage.getItem("workingProcess");
        setProcesso(JSON.parse(process));
        buscaDados();
        buscarResponseveis();
    }, [])
    
    useEffect(() => {
        if(value && value.protocolo && unidades){
            buscaProtocolo(value.protocolo);
        }
    }, [value, unidades])
    const handleOnChange = () => {
        setExpanded(!expanded);
    }
    function montaUrl() {
        let ass = assunto;
        if (!ass) {
            ass = (param.get("assunto")) ? param.get("assunto") : '';
        }

        if (ass === 'Recurso') {
            return '/api/processoProdf/recurso/'
        } else if (ass === 'Prorrogacao') {
            return '/api/processoProdf/prorrogacao/'
        } else if (ass === 'Cancelamento') {
            return '/api/processoProdf/cancelamento/'
        } else if (ass === 'Notificacao') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else if (ass === 'Complemento') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else {
            return "/api/processoProdf/requerimento/";
        }
    }
    const handleEscolheUnidade = () => {
        setShowUnidadeDialog(true);
    }
    function showVisualizar() {
        return !location.pathname.includes("view");
    }

    function unidadeSelecionada(un) {
        setUnidade(un);
    }
    
    function unidadeSelecionadaProtocolo(un) {
        setUnidadeProtocolo(un);
    }
    function buscaDados() {
        id = param.get('id');

        const process = JSON.parse(localStorage.getItem("workingProcess"));
        setProcesso(process);
        const url = montaUrl();

        if (id) {
            setWait(true);
            fetch(url + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        montaValor(json);
                        
                        if (location.pathname.includes("view")) {
                            if (json.assunto === 'Complemento') setTitulo && setTitulo("Visualiar Complemento");
                            if (json.assunto === 'Notificacao') setTitulo && setTitulo("Visualiar Notificação");
                        }
                        if (json.documentos) setAnexos(json.documentos);
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        } else {
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarrequerimentoForm");
            }
        }
    }
    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };
    function buscaProtocolo(protocolo){
        Service.GET("/api/processoProdf/protocolo/byNumProtocolo/" + protocolo).then(result =>{
            if( result.unidade){
                const item = unidades.find(u => u.id == result.unidade);
                setUnidadeProtocolo( item);
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function montaValor(json) {
        let valor = null;
        if (!json.assunto) json.assunto = assunto ? assunto : param.get("assunto");
        if (json.requerente) {
            valor = {
                ...json,
                nome: json.requerente.nome,
                cpf: json.requerente.cpf ? json.requerente.cpf : '',
                email: json.requerente.email,
                telefone: json.requerente.telefone ? json.requerente.telefone : '',
            };
            if (json.historico.length > 0 && json.historico[0].tipo === 'EM_ANALISE') {
                valor.respCadastro = json.historico[0].responsavel
            } else {
                valor.respCadastro = json.requerente;
            }


        } else if (json.solicitante) {
            if (typeof json.processo === 'string') { // para recurso, cancelamento e prorrogacao
                //TODO NECESSARIO REFATORAR
                if (processo) {
                    json.processo = {
                        id: processo.id,
                        nProcesso: processo.dadosEmpresa.nProcesso,
                        cnpj: processo.dadosEmpresa.cnpj,
                        razaoSocial: processo.dadosEmpresa.razaoSocial
                    }
                }

            }
            valor = {
                ...json,
                nome: json.solicitante.nome,
                cpf: json.solicitante.cpf ? json.solicitante.cpf : '',
                email: json.solicitante.email,
                telefone: json.solicitante.telefone ? json.solicitante.telefone : ''
            }
            if (json.historico.length > 0 && json.historico[0].tipo === 'EM_ANALISE') {
                valor.respCadastro = json.historico[0].responsavel
            } else {
                valor.respCadastro = json.solicitante;
            }
        }
        montaPath(valor);

        setValue(valor);
    }

    function vizualizarResposta() {
        const fluxo = value.fluxoPath[value.fluxoPath.length - 1];
        if (fluxo === 'responder' && value && value.assunto && value.assunto === 'Complemento') return true;
        if (fluxo === 'responder' && value && value.assunto && value.assunto === 'Notificacao') return true;
        return false;
    }

    function getResposta() {
        if (value && value.historico) {
            const h = value.historico.find(x => x.tipo === "responder");
            return (h && h.texto) ? h.texto : '';
        }
        return '';
    }

    function montaPath(solicitacao) {
        if (!solicitacao.fluxoPath) {
            if (solicitacao.status === 'RASCUNHO') {
                solicitacao.fluxoPath = ["emRascunho"];
            } else {
                solicitacao.fluxoPath = ["emRascunho", "valUnate"];
            }
        }
    }

    function confirmaRequerimento() {
        let dado = {
            id: value.id,
            assunto: value.assunto,
            protocolo: value.protocolo
        }
        if (acao === 'validar') {
            dado.status = "DESPACHO";
        } else if (acao === 'devolver') {
            dado.status = 'DEVOLVIDO';
        }

        dado.historico = [{
            tipo: "valGestaoUnate",
            data: new Date(),
            texto: txt,
            responsavel: {
                idUser: profile.id,
                nome: profile.name,
                email: profile.email
            }
        }];
        if (dado.status !== 'DEVOLVIDO' && isAssuntoDiversos()) {
            dado.historico.push({
                tipo: "unidade",
                data: new Date(),
                texto: unidade.id,
                responsavel: {
                    idUser: profile.id,
                    nome: profile.name,
                    email: profile.email
                }
            })
        }
        submitForm(dado);
        
    }
    function atualizaUnidadeProtocolo(dado){
        const item = {
            protocolo: dado.protocolo,
            unidade: unidadeProtocolo.id,
            tipo:"gestão",
            assunto: dado.assunto,
            responsavel: {
                idUser: profile.id,
                nome: profile.name,
                email: profile.email
            }
        }
        Service.POST('/api/processoProdf/requerimento/unidadeProtocolo', item).then(resp =>{
            setWait(false);

            if (acao === 'rascunho') {
                document.body.scrollTop = 0; // For Safari
                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            } else {
                if (param.get("return")) {
                    history.replace(param.get("return"));
                } else {
                    history.replace("/home");
                }
            }
        }).catch(error =>{
            setWait(false);
            setErro(error? error: 'Oops! Something went wrong. Please try again!');
        })
    }
    function submitForm(dado) {
        setWait(true);

        fetch('/api/processoProdf/requerimento/posCreate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                atualizaUnidadeProtocolo(dado);
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function desabilitado() {
        if (disable === true) {
            return true;
        }
        if (!param.get('editable') || param.get('editable') === 'true') {
            return false;
        }
        return true;
    }

    function isDisabled() {
        if (isAssuntoDiversos()) {
            return !unidade;
        }
        if( (txt.length === 0 )){
            return true;
        }
        if( !unidadeProtocolo){
            return true;
        }
        return false;
    }
    function handleAcao(cmd) {
        acao = cmd;
        if (cmd === 'rascunho') {

        } else if (acao === 'devolver') {
            setMsgConfirmacao("Confirma devolver a solicitação?");
            setShowConfirm(true);
        } else if (acao === 'validar') {
            setMsgConfirmacao("Confirma validação da solicitação?");
            setShowConfirm(true);
        }
    }
    function isAssuntoDiversos() {
        return value.assunto === 'Diversos';
    }
    function getassunt() {
        if (isAssuntoDiversos()) {
            return "Requerimento - " + value && value.assunto;
        } else {
            if (value.assunto === 'Cancelamento' || value.assunto === 'Complemento') {
                return value && value.assunto || '';
            } else {
                return value && value.assunto + " - " + value.tipo || '';
            }
        }
    }

    const relatorio = () => {
        let dado = { ...value };
        fetch("/api/processoProdf/requerimento/relatorio", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                return;
            }
        }).then(body => {
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    const handleFluxoChange = () => {
        if (!fluxo) {
            let url = '/api/info/fluxoComplexo';
            if (value.assunto === 'Diversos') {
                url = '/api/info/fluxoDiversos';
            } else if (value.assunto === 'Notificacao') {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
                // url = '/api/info/fluxoNotificacaoComplementoSimples';
                // if (value.tipo === 'Pendência de vistoria' || value.tipo === 'Prazo vencido') {
                //     url = '/api/info/fluxoNotificacaoComplementoComplexo';
                // }
            } else if (value.assunto && value.assunto === 'Complemento') {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
            }


            fetch(url)
                .then(response => response.json())
                .then(json => setFluxo(json)).catch(erro => setErro(erro));
        }
    }
    const sair = () => {
        if (param.get("return")) {
            history.replace(param.get("return"));
        } else {
            history.replace("/home");
        }
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangeAssunto = (event) => {
        setAssuntoDado(event.target.value);
    };

    function handleClickComfimarDialog() {
        setTxt(assuntoDado.txt)
        setOpenDialog(false)

    }

    function buscarResponseveis() {
        setWait(true);
        fetch('/api/textoPadrao/buscarAssunto', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then((json) => {
                    let arr = [];
                    for (let x in json) {
                        let v = json[x]
                        arr.push(v)
                    }
                    setAssuntoTexto(arr);

                })
            } else {
                response.json().then((error) => {
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const handleBuscaUnidade=()=>{
        setShowUnidadeDialogProtocolo(true);
    }


    return (
        <Container maxWidth='lg'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    {!isAssuntoDiversos() &&
                        <>
                            <Grid item xs={4}>
                                <TextField
                                    value={value.processo && value.processo.programa || ''}
                                    disabled
                                    fullWidth
                                    label="Programa"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    value={value.processo && value.processo.nProcesso || ''}
                                    disabled
                                    fullWidth
                                    label="Processo"
                                    id="processo"
                                    name="processo"
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    value={value && value.protocolo || ''}
                                    disabled
                                    fullWidth
                                    label="Protocolo"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    value={value.dataCriacao ? FormataDataComHora(new Date(value.dataCriacao)) : ''}
                                    disabled
                                    fullWidth
                                    label="Data Solicitação"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <TextField
                                    value={value.processo && value.processo.cnpj || ''} disabled
                                    fullWidth
                                    label="CNPJ"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={9}>
                                <TextField disabled
                                    value={value.processo && value.processo.razaoSocial || ''}
                                    fullWidth
                                    label="Razão Social"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                        </>
                    }
                    {isAssuntoDiversos() &&
                        <>
                            <Grid item xs={8}>
                                <TextField disabled
                                    value={value.requerente && value.requerente.nome || ''}
                                    fullWidth
                                    label="Requerente"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField disabled
                                    value={value.requerente && value.requerente.cpf || ''}
                                    fullWidth
                                    label="CPF"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField disabled
                                    value={value.requerente && value.requerente.email || ''}
                                    fullWidth
                                    label="Email"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    value={value && value.protocolo || ''}
                                    disabled
                                    fullWidth
                                    label="Protocolo"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={value.dataCriacao ? FormataDataComHora(new Date(value.dataCriacao)) : ''}
                                    disabled
                                    fullWidth
                                    label="Data Solicitação"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                        </>

                    }
                    <Grid item xs={12}>
                        <TextField
                            value={getassunt()}
                            disabled
                            fullWidth
                            label="Assunto"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    {value && value.descricao &&
                        <Grid item xs={12}>
                            <Paper className={classesLocal.paper}>
                                <span dangerouslySetInnerHTML={{
                                    __html: value.descricao ? value.descricao : ''
                                }}></span>
                            </Paper>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Grid container spacing={1} >
                            <Grid item xs={12}>
                                <TableContainer component={Paper} >
                                    <Table aria-label="customized table" size={'small'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Documento
                                                </TableCell>
                                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                                    Ação
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {anexos && anexos.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{row.tipo}</TableCell>
                                                    <TableCell>
                                                        <center>
                                                            <Box>
                                                                {row.nome && <Download doc={row} />}
                                                            </Box>
                                                        </center>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {!anexos || anexos.length === 0 &&
                                                <TableRow>
                                                    <TableCell colSpan={2} width='100%' align="center">Nenhum registro encontrado.</TableCell>
                                                </TableRow>

                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>


                        {
                            vizualizarResposta() &&
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography variant="h5" gutterBottom>Resposta</Typography>
                                </AccordionSummary>

                                <Paper className={classesLocal.paper}>
                                    <span dangerouslySetInnerHTML={{
                                        __html: getResposta()
                                    }}></span>
                                </Paper>
                            </Accordion>
                        }

                        <Accordion expanded={expanded} onChange={handleOnChange}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h5" gutterBottom>Validação</Typography>
                            </AccordionSummary>
                            <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom:'-30px', marginTop:'10px' }}>
                                <TextField
                                    value={ (unidadeProtocolo && unidadeProtocolo.nome) || ''}
                                    label="Enviar para a unidade"
                                    id="unidadeProtocolo"
                                    name="unidadeProtocolo"
                                    variant="outlined"
                                />
                                <Tooltip disableTouchListener={true} title='Buscar Processo'>
                                    <IconButton onClick={handleBuscaUnidade} >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <Button className={classesLocal.descolcar}
                                color="primary" onClick={() => { setOpenDialog(true); }}>
                                Texto Padrão
                            </Button>
                            <CKEditor
                                editor={ClassicEditor} disabled={desabilitado()}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={txt}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    setTxt(editor.getData());
                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />

                        </Accordion>


                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h5" gutterBottom>Histórico</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {value && value.historico &&
                                    <HistoricoTable dados={value.historico}  setWait={setWait} setMensagem={setMensagem} />
                                }
                            </AccordionDetails>
                        </Accordion>

                        <Accordion onChange={handleFluxoChange}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h5" gutterBottom>Fluxo</Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                {fluxo &&
                                    <FluxoPanel fluxo={fluxo} path={value.fluxoPath} />
                                }
                            </AccordionDetails>
                        </Accordion>
                    </Grid>

                    {isAssuntoDiversos() &&
                        <>
                            <Grid item xs={11}>
                                <TextField
                                    placeholder="Unidade"
                                    id="nomeUnidade"
                                    label="Unidade de destino"
                                    fullWidth
                                    value={unidade && unidade.nome || ''}
                                    inputProps={{ readOnly: true }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <Tooltip title="Buscar que esse perfil esta associado" aria-label="add">
                                    <IconButton type="submit"
                                        aria-label="search"
                                        onClick={handleEscolheUnidade}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </>
                    }
                    <Grid container justify="flex-end" className={classes.btns}>

                        <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                            Sair
                        </Button>

                        {showVisualizar() &&
                            <Button variant="contained" color="primary" onClick={relatorio} >
                                Visualizar
                        </Button>
                        }

                        {!desabilitado() &&
                            <Button variant="contained" color="primary" onClick={() => handleAcao('devolver')} disabled={txt.length === 0}>
                                Devolver
                            </Button>}


                        {!desabilitado() &&  
                            <Button variant="contained" color="primary" onClick={() => handleAcao('validar')} disabled={isDisabled() }>
                                Validar
                            </Button>
                        }
                    </Grid>



                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg={msgConfirmacao}
                        exec={confirmaRequerimento}
                    />

                    <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth >
                        <DialogTitle id="form-dialog-title">{tituloDialog}</DialogTitle>
                        <DialogContent>
                            <FormControl variant="outlined" fullWidth >
                                <InputLabel id="demo-simple-select-outlined-label">Título</InputLabel>
                                <Select
                                    labelId="lb-idresponsavel"
                                    value={assuntoDado}
                                    id="idresponsavel"
                                    onChange={handleChangeAssunto}
                                    label="Título"
                                >
                                    {assuntoTexto.map(v =>
                                        <MenuItem key={v.id} value={v}>
                                            <em>{v.assunto}</em>
                                        </MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained" color="secondary" onClick={() => { setOpenDialog(false); }} rel="noopener noreferrer">
                                Sair
                        </Button>
                            <Button variant="contained" color="primary" onClick={() => handleClickComfimarDialog(assuntoDado)}  >
                                Confirmar
                        </Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Paper>
            <UnidadeDialog open={showUnidadeDialogProtocolo} setOpen={setShowUnidadeDialogProtocolo} acao={unidadeSelecionadaProtocolo} unidades={unidades} />
            <UnidadeDialog open={showUnidadeDialog} setOpen={setShowUnidadeDialog} acao={unidadeSelecionada} unidades={unidades} />
        </Container>
    );
}
