import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField,
    Table, TableContainer, TableHead, TableRow, TableCell, Typography, Accordion, AccordionSummary, AccordionDetails, makeStyles, TableBody, Tooltip, IconButton, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import { useHistory, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, FormataDataComHora } from "../../constants";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Download from '../../util/Download';
import GenericTable from '../template/GenericTable';
import { useCurrentUser } from '../../server/UseCurrentUser';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { deParaSituacao, deParaTipo } from "./ChamadosTable";
import RemoveIcon from '@material-ui/icons/Remove';
import Service from '../../services/Service';
import { id, ptBR } from 'date-fns/locale';
import ConfirmDialog from '../../util/ConfirmDialog';

const useStylesPagina = makeStyles((theme) => ({
    paper: {
        width: 200,
        height: 230,
        overflow: 'auto',
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));
const solicitacao = ['Problema de acesso', 'Erro de sistema', 'Sugestão de melhoria', 'Dúvida negocial', 'Dúvida de uso do sistema', 'Inconsistência dos dados', 'Outros']
export default function ChamadosForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, view, setErro } = props;
    const [profile] = useCurrentUser();
    const classesPagina = useStylesPagina();
    const [dados, setDados] = useState([]);
    const [anexos, setAnexos] = useState([{ tipo: -1 }]);
    const [anexosChamado, setAnexosChamado] = useState([{ tipo: -1 }]);
    const param = getQueryParams(useLocation().search);
    const [values, setValues] = useState({});
    const history = useHistory();
    const [responsavel, setResponsavel] = useState([]);
    const [disabledAtendimento, setDisabledAtendimento] = useState(true);
    const [historico, setHistorico] = useState([]);
    const [error, setError] = useState(resetErro());
    const [showConfirmCancelar, setShowConfirmCancelar] = useState(false);
    const [showConfirmConcluir, setShowConfirmConcluir] = useState(false);

    useEffect(() => {
        if (view) {
            setTitulo("Suporte ao usuário - Visualizar chamado");
        } else {
            setTitulo("Suporte ao usuário - Atender chamado");
            buscarUsuario();
        }
        buscarSuporte();

    }, [])

    function resetErro() {
        return {
            obs: { erroT: false, msg: '' },
            responsavel: { erroT: false, msg: '' }
        }
    }

    const handleChangeInputForm = (event) => {
        setDados({
            ...dados,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeObsForm = (event) => {
        setError({
            obs: { erroT: false, msg: '' },
        });
        setDados({
            ...dados,
            [event.target.name]: event.target.value,
        });
    };
    function getTipoSolicitacao() {
        if (values.solicitacao === 'Problema de acesso') return "PROBLEMA_ACESSO";
        if (values.solicitacao === 'Erro de sistema') return "ERRO_SISTEMA";
        if (values.solicitacao === 'Sugestão de melhoria') return "SUGESTAO_MELHORIA";
        if (values.solicitacao === 'Dúvida negocial') return "DUVIDA_NEGOCIAL";
        if (values.solicitacao === 'Dúvida de uso do sistema') return "DUVIDA_USO_SISTEMA";
        if (values.solicitacao === 'Inconsistência dos dados') return "INCONSISTENCIA_DADO";
        if (values.solicitacao === 'Outros') return "OUTROS";
    }
    function handleClickConcluir() {
        setShowConfirmConcluir(true);
    }
    function confirmarConclir() {
        if (!dados.idResponsavel) {
            setMensagem("Selecione o responsável");
        } else if (!dados.observacao) {
            setError({
                obs: { erroT: true, msg: 'Campo obrigatorio' }
            });
        } else {
            setError({
                obs: { erroT: false, msg: '' },
                responsavel: { erroT: false, msg: '' }
            });
            const dado = values;
            dado.texto = dados.observacao;
            dado.anexos = [];//anexos;
            anexos.forEach(a => {
                if (a.tipo !== -1) dado.anexos.push(a);
            });
            dado.tipo = getTipoSolicitacao();
            dado.anexosChamado = [];//anexos;
            anexosChamado.forEach(a => {
                if (a.tipo !== -1) dado.anexosChamado.push(a);
            });
            Service.POST('/api/suporte/registrarConclusaoAtendimento', dado).then(resp => {
                setWait(false);
                buscarSuporte();
                //salvarAnexo();
                history.replace("/suporte/listaChamados");
            }).catch(error => {
                setWait(false);
                setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
            });
            // fetch('/api/suporte/registrarConclusaoAtendimento', {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Accept: 'application/json',
            //         Authorization: `Bearer ${localStorage.accessToken}`
            //     },
            //     body: JSON.stringify(dado),
            // }).then(response => {
            //     if (response.ok) {
            //         buscarSuporte();
            //         history.replace("/suporte/listaChamados");
            //     } else {
            //         response.json().then(erro => {
            //             setErro(erro.message);
            //         })
            //     }
            // }).catch(error => {
            //     setWait(false);
            //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            // });
        }

    }


    function handleClickCancelar() {
        setShowConfirmCancelar(true)
    }

    function confirmarCancelar() {

        setError({
            obs: { erroT: false, msg: '' },
            responsavel: { erroT: false, msg: '' }
        });
        const dado = {
            id: param.get("id"),
            texto: dados.observacao,
        }
        Service.POST('/api/suporte/registrarCancelamentoAtendimento', dado).then(resp => {
            setWait(false);
            buscarSuporte();
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
        // fetch('/api/suporte/registrarCancelamentoAtendimento', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        // }).then(response => {
        //     if (response.ok) {
        //         buscarSuporte();
        //     } else {
        //         response.json().then(erro => {
        //             setErro(erro.message);
        //         })
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });

    }



    function iniciarAtendimento() {
        const dado = {
            id: param.get("id"),
            nome: profile.name,
            email: profile.email,
            idUsuario: profile.id
        }
        Service.POST('/api/suporte/registrarInicioAtendimento', dado).then(resp => {
            setWait(false);
            buscarSuporte();
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
        // fetch('/api/suporte/registrarInicioAtendimento', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        // }).then(response => {
        //     if (response.ok) {
        //         buscarSuporte();
        //     } else {
        //         response.json().then(erro => {
        //             setErro(erro.message);
        //         })
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    function buscarUsuario() {
        setWait(true);
        Service.GET('/api/usuariosSistema').then(json => {
            setWait(false);
            json.findIndex(r => r.perfis === "ADM");
            setResponsavel(json);
            // buscarSuporte(json)
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
        // fetch('/api/usuariosSistema', {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok) {
        //         setWait(false);
        //         response.json().then((json) => {
        //             json.findIndex(r => r.perfis === "ADM");
        //             setResponsavel(json);
        //             // buscarSuporte(json)
        //         })
        //     } else {
        //         response.json().then((error) => {
        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    function buscarSuporte() {
        fetch("/api/suporte/" + param.get("id"))
            .then(res => res.json()).then((result) => {
                //setWait(false);
                let responsa = "";
                for (let x in result.historico) {
                    if (x > 0) responsa = result.historico[x].responsavel.nome;
                }
                setValues({
                    ...result,
                    dataAbertura: (result.historico[0].data ? new Date(result.historico[0].data) : null),
                    responsavel: responsa,
                    solicitacao: deParaSit(result.tipo)
                });
                setAnexos(result.anexos);

                if (result.anexosChamado && result.anexosChamado.length >= 1) {
                    setAnexosChamado(result.anexosChamado);
                }


                let arr = [];
                let responsavelUtimo = "Erro";
                let tempos = [];
                for (let x in result.historico) {
                    const r = result.historico[x];
                    arr.push({
                        data: r.data,
                        responsavel: r.responsavel.nome,
                        observacao: r.texto ? r.texto : ''
                    })
                    if (r.tipo === "ABERTO" || r.tipo === "INICIADO" || r.tipo === "CONCLUIDO") {
                        tempos.push(r.data);
                    }
                    if (r.tipo === "ABERTO") {
                        responsavelUtimo = "";
                    } else {
                        responsavelUtimo = r.responsavel.id
                    }
                }
                setHistorico(arr);
                setDisabledAtendimento(result.historico[1] ? true : false);
                if (tempos[3]) setErro("Erro Data");

                setDados({
                    idResponsavel: responsavelUtimo,
                    conclusaoAtendimento: diferencaData(tempos[0], tempos[1]),
                    inicioAtendimento: diferencaData(tempos[1], tempos[2])
                });
                setWait(false);
            });
    }

    function diferencaData(i, f) {
        if (i === undefined || f === undefined) return "-";
        const dataAberto = new Date(i);
        const dataIniciado = new Date(f);
        const milliseconds = dataIniciado.getTime() - dataAberto.getTime();
        const days = Math.floor(milliseconds / (60 * 60 * 24 * 1000))
        const hora = Math.floor((milliseconds % (60 * 60 * 24 * 1000)) / (60 * 60 * 1000))
        const min = Math.round((milliseconds % (60 * 60 * 1000)) / (60 * 1000))
        return days + " Dias, " + hora + " horas e " + min + " minutos";
    }

    function registroAndamento() {
        if (!dados.observacao) {
            setError({
                obs: { erroT: true, msg: 'Campo obrigatorio' }
            });
            return;
        }
        let respon = [];
        for (let x in responsavel) {
            if (responsavel[x].id === dados.idResponsavel) {
                respon = responsavel[x];
            }
        }
        const dado = values;

        dado.texto = dados.observacao;
        dado.anexos = [];//anexos;
        anexos.forEach(a => {
            if (a.tipo !== -1) dado.anexos.push(a);
        });

        dado.tipo = getTipoSolicitacao();
        console.log(">>>", dado)
        // <<<<<<_____________________________________________________>>>>>>
        Service.POST('/api/suporte/registrarAndamentoAtendimento', dado).then(resp => {
            setWait(false);
            buscarSuporte();
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
        // fetch('/api/suporte/registrarAndamentoAtendimento', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        // }).then(response => {
        //     if (response.ok) {
        //         buscarSuporte()
        //     } else {
        //         response.json().then(erro => {
        //             setErro(erro.message);
        //         })
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    function formatar(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }
    function getNumeroChamado() {
        if (values.numeroChamada) return " " + values.numeroChamada
    }

    const handleChangeInputCheck = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });

    };

    function deParaSit(value) {
        if (value === 'PROBLEMA_ACESSO') return "Problema de acesso";
        if (value === 'ERRO_SISTEMA') return "Erro de sistema";
        if (value === 'SUGESTAO_MELHORIA') return "Sugestão de melhoria";
        if (value === 'DUVIDA_NEGOCIAL') return "Dúvida negocial";
        if (value === 'DUVIDA_USO_SISTEMA') return "Dúvida de uso do sistema";
        if (value === 'INCONSISTENCIA_DADO') return "Inconsistência dos dados";
        if (value === 'OUTROS') return "Outros";

    }

    const handleEscolheFile = () => {
        // if (disable) return;
        let file = document.getElementById("file");
        file.click();
    }

    function removeArquivo(item) {
        // if (disable) return;
        let arr = [];
        anexosChamado.forEach(it => {
            if (it.uuid !== item.uuid) {
                arr.push(it);
            }
        });
        setAnexosChamado(arr);
    }
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    if (!result) return;
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();
                    let arr = [];
                    arr.push({
                        id: result.id,
                        nome: nm,
                        uuid: result.uuid
                    });
                    anexosChamado.forEach(a => {
                        if (a.tipo !== -1) arr.push(a)
                    });
                    arr.push({ tipo: -1 });
                    setAnexosChamado(arr);
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }

    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('jpeg') &&
            !anexo.name.toLocaleLowerCase().endsWith('jpg') &&
            !anexo.name.toLocaleLowerCase().endsWith('pdf') &&
            !anexo.name.toLocaleLowerCase().endsWith('png')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .jpeg ou jpg ou png");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Grid container spacing={1} className={classes.root}>
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography variant="h5" gutterBottom>Dados do chamado {getNumeroChamado()}</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Grid container spacing={3} className={classesPagina.root}>
                                <Grid item xs={6}>
                                    <TextField
                                        value={values.cpf || ''}
                                        fullWidth
                                        label="CPF"
                                        id="solicitante"
                                        name="solicitante"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={values.nome || ''}
                                        fullWidth
                                        label="Nome Completo"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={values.telefone || ''}
                                        fullWidth
                                        label="Telefone"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={values.email || ''}
                                        fullWidth
                                        label="E-mail"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={values.cnpj || ''}
                                        fullWidth
                                        label="CNPJ"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={values.razaoSocial || ''}
                                        fullWidth
                                        label="Razão Social"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={deParaTipo(values.tipo || '')}
                                        fullWidth
                                        label="Tipo solicitação"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        id="dataAbertura"
                                        label="Data abertura"
                                        format="dd/MM/yyyy"
                                        value={values.dataAbertura || null}
                                        fullWidth
                                        KeyboardButtonProps={true}
                                        inputVariant="outlined"
                                        disabled={true}
                                        invalidDateMessage="Formato inválido"
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        multiline
                                        rows={6}
                                        value={values.descricao || ''}
                                        fullWidth
                                        label="Descrição da Ocorrência"
                                        id="descricao"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography variant="h5" gutterBottom>Anexos</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <TableContainer component={Paper} >
                                <Table aria-label="customized table" size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                Documento
                                            </TableCell>
                                            <TableCell width='100px' align="center" className={classes.tableHead}>
                                                Ação
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {anexos && anexos.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell>{row.nome}</TableCell>
                                                <TableCell>
                                                    {row.tipo !== -1 &&
                                                        <center>
                                                            <Download doc={row} />
                                                        </center>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {
                                            (!anexos || anexos.length === 0) &&
                                            <TableRow>
                                                <TableCell>
                                                    Nenhum registro encontrado!
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography variant="h5" gutterBottom>Dados atendimento</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <Grid container spacing={3} className={classesPagina.root}>
                                <Grid item xs={6}>
                                    {!view &&
                                        <FormControl variant="outlined" fullWidth >
                                            <InputLabel id="tipo" >Atribuir responsável</InputLabel>
                                            <Select
                                                id="idResponsavel"
                                                name='idResponsavel'
                                                label="Atribuir Responsável"
                                                placceholder="idResponsavel"
                                                onChange={handleChangeInputForm}
                                                value={dados.idResponsavel || ''}
                                                disabled={!disabledAtendimento}
                                            >
                                                {responsavel.map(v =>
                                                    <MenuItem key={v.id} value={v.id}>
                                                        <em>{v.name}</em>
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    }
                                    {view &&
                                        <TextField
                                            value={values.responsavel || ''}
                                            fullWidth
                                            label="Responsável"
                                            id="responsavel"
                                            name="responsavel"
                                            aria-describedby="nova-helper-text"
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: true,
                                            }}

                                        />
                                    }

                                </Grid>

                                <Grid item xs={6}>
                                    {!view &&
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="tipo" >Tipo de Solicitação</InputLabel>
                                            <Select
                                                id="solicitacao"
                                                name='solicitacao'
                                                label="Tipo de Solicitação"
                                                placceholder="solicitacao" onChange={handleChangeInputCheck}
                                                value={values.solicitacao || ""}
                                                disabled={!disabledAtendimento} >

                                                {solicitacao && solicitacao.map((tp, i) => (
                                                    <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }

                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        value={deParaSituacao(values.situacao || '')}
                                        fullWidth
                                        id="situacao"
                                        name="situacao"
                                        label="Situação"
                                        aria-describedby="nova-helper-text"
                                        // onChange={handleChangeInputForm}
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                {!view &&
                                    <Grid item xs={12} >
                                        <TableContainer component={Paper} >
                                            <Typography variant="h5" gutterBottom>Anexos Chamado</Typography>
                                            <Table aria-label="customized table" size={'small'}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sortDirection={false} className={classes.tableHead}>
                                                            Documento
                                                        </TableCell>
                                                        <TableCell width='100px' align="center" className={classes.tableHead}>
                                                            Ação
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {anexosChamado && anexosChamado.map((row, i) => (
                                                        <TableRow key={i}>
                                                            <TableCell>{row.nome}</TableCell>
                                                            <TableCell>
                                                                {row.tipo === -1 &&
                                                                    <Tooltip title="Incluir documento">
                                                                        <IconButton onClick={(e) => handleEscolheFile()}>
                                                                            <AttachFileIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                                {row.tipo !== -1 &&
                                                                    <center>
                                                                        <Download doc={row} />

                                                                        <Tooltip title='Remover'>
                                                                            <IconButton onClick={(e) => removeArquivo(row)} >
                                                                                <RemoveIcon />
                                                                            </IconButton>
                                                                        </Tooltip>


                                                                    </center>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {
                                                        (!anexosChamado || anexosChamado.length === 0) &&
                                                        <TableRow>
                                                            <TableCell>
                                                                Nenhum registro encontrato!
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                }
                                {!view &&
                                    <>
                                        <Grid item xs={12} >
                                            <TextField
                                                value={dados.observacao || ''}
                                                error={error.obs.erroT}
                                                helperText={error.obs.msg}
                                                fullWidth
                                                id="observacao"
                                                name="observacao"
                                                label="Observação"
                                                aria-describedby="nova-helper-text"
                                                onChange={handleChangeObsForm}
                                                disabled={!disabledAtendimento}
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                            />
                                        </Grid>
                                        <Grid container justify="flex-end" className={classes.btns}>
                                            {!disabledAtendimento &&
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    rel="noopener noreferrer"
                                                    onClick={() => iniciarAtendimento()}
                                                >
                                                    Registrar inicio atendimento
                                                </Button>
                                            }
                                            {disabledAtendimento && values.situacao !== "CONCLUIDO" && values.situacao !== "CANCELADO" &&
                                                <>
                                                    <Button
                                                        variant="contained"
                                                        color="secondary"
                                                        onClick={() => handleClickCancelar()}
                                                        rel="noopener noreferrer">
                                                        Cancelar
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleClickConcluir()}
                                                        rel="noopener noreferrer">
                                                        Concluir Atendimento
                                                    </Button>
                                                    <Button variant="contained" color="primary" onClick={() => registroAndamento()} rel="noopener noreferrer">
                                                        Registrar Andamento
                                                    </Button>
                                                </>
                                            }
                                        </Grid>
                                    </>
                                }
                                {view &&
                                    <>
                                        <Grid item xs={6} >
                                            <TextField
                                                value={dados.inicioAtendimento || ''}
                                                fullWidth
                                                id="inicioAtendimento"
                                                name="inicioAtendimento"
                                                label="Tempo inicio atendimento"
                                                aria-describedby="nova-helper-text"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={6} >
                                            <TextField
                                                value={dados.conclusaoAtendimento || ''}
                                                fullWidth
                                                id="conclusaoAtendimento"
                                                name="conclusaoAtendimento"
                                                label="Tempo conclusão atendimento"
                                                aria-describedby="nova-helper-text"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <TableContainer component={Paper} >
                                            <Table aria-label="customized table" size={'small'}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sortDirection={false} className={classes.tableHead}>
                                                            Documento
                                                        </TableCell>
                                                        <TableCell width='100px' align="center" className={classes.tableHead}>
                                                            Ação
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {anexosChamado && anexosChamado.map((row, i) => (
                                                        <TableRow key={i}>
                                                            <TableCell>{row.nome}</TableCell>
                                                            <TableCell>
                                                                {row.tipo !== -1 &&
                                                                    <center>
                                                                        <Download doc={row} />
                                                                    </center>
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    {
                                                        (!anexosChamado || anexosChamado.length === 0) &&
                                                        <TableRow>
                                                            <TableCell>
                                                                Nenhum registro encontrado!
                                                            </TableCell>
                                                        </TableRow>
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </>

                                }
                            </Grid>

                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography variant="h5" gutterBottom>Histórico atendimento</Typography>
                        </AccordionSummary>
                        <AccordionDetails >
                            <GenericTable

                                dados={historico}
                                // readOnly={isReadOnly()}
                                colunas={[
                                    { nome: 'Data', campo: 'data', 'transformacao': formatar },
                                    { nome: 'Responsável', campo: 'responsavel' },
                                    { nome: 'Observação', campo: 'observacao' },

                                ]} />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" href={"/suporte/listaChamados"} rel="noopener noreferrer">
                        Sair
                    </Button>
                </Grid>
                <ConfirmDialog
                    open={showConfirmCancelar}
                    setOpen={setShowConfirmCancelar}
                    titulo={'Confirmação'}
                    msg='Deseja confirma o cancelamento do atendimento realizado?'
                    exec={confirmarCancelar}
                />
                <ConfirmDialog
                    open={showConfirmConcluir}
                    setOpen={setShowConfirmConcluir}
                    titulo={'Confirmação'}
                    msg='Deseja concluir o atendimento realizado?'
                    exec={confirmarConclir}
                />
            </Grid >
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf,image/gif, image/jpeg, image/png, image/jpg" />
        </MuiPickersUtilsProvider>

    );
}
