import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography, FormControlLabel, Checkbox
}
    from '@material-ui/core';
import ConfirmDialog from "../../../../util/ConfirmDialog";
import MaskedInput from 'react-text-mask'
import SearchIcon from '@material-ui/icons/Search';
import RemoveIcon from '@material-ui/icons/Remove';
import BuscaDadoDialog from "../../../../util/BuscaDadoDialog";
import { useCurrentUser } from "../../../../server/UseCurrentUser";
import { useUnidades } from "../../../../server/UseUnidades";
import { useHistory, useLocation } from "react-router-dom";
import Download from "../../../../util/Download";
import { useParams } from "react-router-dom";
import { useStyles, getQueryParams, ValidarCPF } from "../../../../constants";
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';

function TelMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TelMask2(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

export const useStylesLocal = makeStyles((theme) => ({
    btns: {
        marginTop: '10px',
        '& > *': {
            margin: '4px'
        },
    },
}));
let tipoAnexo=null;

export default function CancelamentoUnateForm(props) {
    const classes = useStyles();
    let { id } = useParams();
    const { setTitulo, setWait, setMensagem, setErro} = props;  
    const [showConfirm, setShowConfirm] = useState(false);
    const [showTel1, setShowTel1] = useState(false);
    const [disable, setDisable] = useState(false);
    const [value, setValue] = useState({
        processo: { 
            nProcesso:''
        },
        responsavel: '',
        programa: '',        
        descricao: '',
        declaracao: false,
        protocolo: '',
        tipo: '',
    });
    const [arquivo, setArquivo] = useState();
    const [anexos, setAnexos] = useState([{tipo:-1}]);
    const [processos, setProcessos] = useState([]);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    let [profile] = useCurrentUser();
    const [unidades] = useUnidades();
    const [processo, setProcesso] = useState(); 
    const [error, setError] = useState(resetErro());
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [assuntos, setAssunto] = useState(['']);
    const param = getQueryParams(useLocation().search);
    const [showConfirmConclusao, setShowConfirmConclusao] = useState(false);
    const [podeEditarRequerente, setPodeEditarRequerente] = useState( false );

    useEffect(() => {
        if( profile){
            const process = JSON.parse(localStorage.getItem("workingProcess"));
            if( param.get("editavel") === 'true'){
                if( process){
                    setProcesso( process );
                    setValue({...value, 
                        processo:{
                            id: process.id,
                            nProcesso: process.dadosEmpresa.nProcesso,
                            cnpj: process.dadosEmpresa.cnpj,
                            razaoSocial: process.dadosEmpresa.razaoSocial,
                            programa: process.dadosEmpresa.programa
                        }
                    });
                }
            }
            buscaTipoDocumento();

        }
    },[profile]);

    function buscaDados(){
        id = param.get('id');
        
        if( param.get("editavel") === 'true'){
            const process = localStorage.getItem("workingProcess");
            setProcesso( JSON.parse(process) );
        }

        if( id ){
            setWait(true);
            fetch('/api/processoProdf/cancelamento/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false); 
                        setValue({...json,
                            nome: json.requerente.nome,
                            cpf: json.requerente.cpf?json.requerente.cpf:'',
                            email:json.requerente.email,
                            telefone: json.requerente.telefone?json.requerente.telefone:''
                        });
                        if( json.documentos ){
                            let arr = [];
                            json.documentos.forEach(d => arr.push(d));
                            if( visivelEnviar() ) 
                                arr.push({tipo:-1});
                            setAnexos( arr );
                        }
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        }else{
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarCancelamentoForm");
            }
        }
    }    
    
    useEffect(() => {
        setTitulo("Cadastrar Cancelamento");
        setDisable( param.get("vizualizacao") === 'true' );
        setPodeEditarRequerente( param.get("vizualizacao") === 'true' );
    }, []);

    const handleLostFocusCPF = (e) => {
        setError({
          ...error,
          cpf: {
            erro: false,
            msg: ''
          }
        });
        if( !value.cpf ) return;
        const cpf = value.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
    
        if (cpf.length === 0) return;
    
        if (ValidarCPF(cpf)) {
          // TODO buscar ou não CPF
          setWait(true);
          fetch("/api/processoProdf/requerimento/buscaSolicitante/" + cpf)
            .then(res => {
              if (res.status <= 300) return res.json()
            })
            .then(
              (result) => {
                setWait(false);
                setError(resetErro);
                if (!result) {
                    setPodeEditarRequerente(false);
                  setValue({
                    ...value, nome: '', telefone:'', email:''
                  });
                } else {
                  setValue({
                    ...value,
                    idUser: result.id,
                    nome: result.name,
                    email: result.email,
                    telefone: result.telefone
                  });
                  setPodeEditarRequerente(true);
                }
              },
              (error) => {
                setWait(false);
              }
            )
        } else {
          setError({
            ...error,
            cpf: {
              erro: true,
              msg: 'CPF inválido'
            }
          });
        }
      }

    const handleCheck = (e) => {
        setValue({
            ...value,
            declaracao: e.target.checked
        });
    }

    const handleTelLostFocus = () => {
        setShowTel1(value.telefone.trim().length !== 16);
    }
    const handleTelFocus = () => {
        setShowTel1(false);
    }

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };

    function confirmaAprovacao() {
        setError(resetErro());

        let dado = { ...value };

        dado.solicitante = {
            id: value.idUser,
            nome: value.nome,
            email: value.email,
            telefone: value.telefone,
            cpf: value.cpf,
        };        
        dado.documentos = [];//anexos;
        anexos.forEach(a =>{
            if( a.tipo !== -1) dado.documentos.push(a);
        })
        dado.status = "EM_ANALISE";

        dado.historico=[{
            tipo: dado.status,
            data: new Date(),
            texto: "Cadastro via UNATE",
            responsavel:{
                id: profile.id,
                nome: profile.name,
                email: profile.email
            }
        }];
        if (valida(dado)) {
         submitForm(dado);
        }
    }
    function sair(){
        if (param.get("editavel")) {
            history.goBack();//TODO tem que pensar uma forma melhor de ficar isso
        } else {
            history.replace("/home");
        }
    }

    function atualizaProcesso(pro) {
        setProcesso(pro);
        setValue({
            ...value,
            processo: {
                id: pro.idProcesso,
                nProcesso: pro.nProcesso,
                cnpj: pro.cnpj,
                razaoSocial: pro.razaoSocial,
                programa: pro.programa,
            }
        });
    }

    function buscaTipoDocumento(){
        if( !tipoAnexo){
            fetch('/api/info/tipoDocumento')
            .then( response => response.json() )
            .then(json => {
                tipoAnexo = json;
                let arr = [''];
                json.forEach(item => arr.push( item.tipo ));
                setAssunto(arr);
                buscaDados();
            });
        }
    }
    
    function submitForm(dado) {
        setWait(true);
        fetch('/api/processoProdf/cancelamento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);

                response.json().then( json =>setValue( {...value,
                    id:json.id,
                    protocolo: json.protocolo
                } ));
                
                setShowConfirmConclusao(true);
                // history.replace("/gerenciaProcesso/unate/solicitarCancelamentoTable");
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: { erro: false, msg: '' },
            telefone: { erro: false, msg: '' },
            email: { erro: false, msg: '' }
        }
    }


    function valida(dado) {
        if (!dado.documentos || dado.documentos.length === 0) {
            setMensagem("Obrigatório informar os documentos para analise");
            return false;
        }
        return true
    }
    
    const handleEscolheFile = () => {
        let file = document.getElementById("file");
        file.click();
    }

    // const handleAnexar = (e) => {
    //     let arr = [...anexos];
    //     arr.push({
    //         tipo: tipoDocumento,
    //         nome: arquivo.nome,
    //         uuid: arquivo.uuid
    //     });
    //     setAnexos(arr);
    //     setArquivo();
    //     setTipoDocumento('');
    // }
    function visivelEnviar() {
        if (!param.get("vizualizacao")) {
            return true;
        }
        
        return !(param.get("vizualizacao") === "true");
    }
    
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            setOpen(true);
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);

                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();
                    let arr = [];
                    arr.push({
                        tipo: tipoDocumento,
                        nome: nm,
                        uuid: result.uuid
                    });
                    anexos.forEach(a =>{
                        if( a.tipo !== -1 )arr.push(a)
                    });
                    arr.push({tipo:-1});

                    
                    setAnexos(arr);
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }

    function desabilitado() {
        if (value.processo.nProcesso.length === 0) return true;
        if( value && value.cpf){        
            const cpf = value.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
            if( cpf.length === 0 ) return true;
        }
        if( value && value.telefone){        
            const telefone = value.telefone.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
            if( telefone.length === 0 ) return true;
        }

        if( anexos.length === 1) return true;
        if (!value.nome) return true;
        if (!value.email) return true;
        return false;
    }   

    function removeArquivo(item) {
        let arr = [];
        anexos.forEach(it => {
            if (it.uuid !== item.uuid) {
                arr.push(it);
            }
        });
        setAnexos(arr);
    }

    
    const buscaDadoProcesso = ()=>{
        setWait(true);
        fetch("/api/gerenciarProcesso/filtro",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify({
                    cnpjs: profile.empresas
                }),
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setMensagem(result);
                } else {
                    let arr = []
                    arr = result;
                    for(let x in arr){
                        const item = unidades.find(u => u.id === arr[x].idUnidade);
                        if( item ){
                            arr[x].unidade = item.nome;
                        }
                    }
                    setProcessos(arr);
                    setShowBuscaDadoDialog(true);
                }
            });
    }

    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={12}>
                        <TextField
                            value={profile && profile.name || ''}
                            disabled
                            fullWidth
                            label="Responável pelo preenchimento"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}> 
                        <TextField
                            value={value && value.processo && value.processo.nProcesso || ''}
                            disabled
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>   
                    <Grid item xs={1}>
                        <Tooltip title='Buscar Processo'>
                            <IconButton onClick={buscaDadoProcesso} >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid> 
                    <Grid item xs={8}>
                        <TextField
                            value={value.processo.razaoSocial || ''}
                            disabled
                            fullWidth
                            label="Razão social"
                            id="razaoSocial"
                            name="razaoSocial"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={value.processo.programa || ''}
                            disabled
                            fullWidth
                            label="Programa"
                            id="programa"
                            name="programa"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                   
                    <Grid item xs={4}>
                        <TextField
                            value={value.processo.cnpj || ''}
                            disabled
                            fullWidth
                            label="Cnpj"
                            id="cnpj"
                            name="cnpj"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={value.protocolo} disabled
                            fullWidth
                            label="Protocolo"
                            id="protocolo"
                            name="protocolo"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            disabled={disable}
                            label="CPF"
                            value={value.cpf || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleLostFocusCPF}
                            error={error.cpf.erro}
                            helperText={error.cpf.msg}
                            name="cpf"
                            id="cpf-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: CPFMask,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled={podeEditarRequerente}
                            value={value.nome || ''}
                            onChange={handleChangeInputForm}
                            name="nome"
                            fullWidth
                            label="Requerente"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleTelLostFocus}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'none' : 'block' }}
                            name="telefone"
                            id="tel-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask,
                            }}
                            variant="outlined"
                        />
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'block' : 'none' }}
                            name="telefone"
                            id="tel-mask-input1"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask2,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.email.erro}
                            helperText={error.email.msg}
                            fullWidth
                            label="Email"
                            value={value.email || ''}
                            onChange={handleChangeInputForm}
                            // InputProps={{
                            //     readOnly: !disable,
                            // }}
                            name="email"
                            id="email-input"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        
                                <TableContainer component={Paper} >
                                    <Table aria-label="customized table" size={'small'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    Documento
                                                </TableCell>
                                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                                    Ação
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {anexos && anexos.map((row, i) => (
                                                <TableRow key={i}>
                                                    <TableCell>{row.nome}</TableCell>
                                                    <TableCell align="center">
                                                        { row.tipo === -1 && visivelEnviar() && 
                                                            <Tooltip title="Incluir documento">
                                                                <IconButton onClick={ (e) => handleEscolheFile() }>
                                                                    <AttachFileIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                        {row.tipo !== -1 &&  
                                                            <center>
                                                                <Download doc={row}/>
                                                                { visivelEnviar() &&
                                                                    <Tooltip title='Remover'>
                                                                        <IconButton onClick={(e)=>removeArquivo(row)} >
                                                                            <RemoveIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                }
                                                            </center>
                                                        }
                                                        </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={value.declaracao}
                                                onChange={handleCheck}
                                                name="declaracao"
                                                color="primary"
                                            />
                                        }
                                        label="DECLARO A ENTREGA TOTAL DA DOCUMENTAÇÃO"
                                    />
                                </Grid>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                            Sair
                        </Button>
                        
                        {visivelEnviar() &&
                            <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} disabled={desabilitado() || !value.declaracao}>
                                Cadastrar
                        </Button>
                        }
                    </Grid>
                    <ConfirmDialog
                        open={showConfirmConclusao}
                        btn={'OK'} disableCancelar={true}
                        setOpen={setShowConfirmConclusao}
                        titulo={'cadastramento de requerimento criado com sucesso'}
                        msg={'numero do protocolo gerado: '+value.protocolo}
                        exec={sair}
                    />
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirma cadastro de requerimento?'
                        exec={confirmaAprovacao}
                    />

                    <BuscaDadoDialog 
                        open={showBuscaDadoDialog} 
                        setOpen={setShowBuscaDadoDialog} 
                        acao={atualizaProcesso}
                        colunas={[
                            {nome:'CNPJ', campo:'cnpj'},
                            {nome:'Nome', campo:'razaoSocial'},
                            {nome:'Processo', campo:'nProcesso'},
                            {nome:'Unidade', campo:'unidade'},
                        ]}
                        dados={processos}/>
                </Grid>

            </Paper>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />

        </Container>
    );
}
