import { useState, useEffect } from "react";
import { ACCESS_TOKEN } from '../constants';

function useUnidades() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function call() {
            if (!localStorage.getItem(ACCESS_TOKEN)) {
                setData(null);
                setLoading(false);
            } else {
                const unidades = localStorage.getItem(ACCESS_TOKEN+"_UNIDADES");
                if( !unidades || unidades.length === 0 ){
                    
                    fetch('/api/unidade')
                    .then(response => response.json())
                    .then(json => {
                        setData(json);
                        setLoading(false);
                        localStorage.setItem(ACCESS_TOKEN+"_UNIDADES", JSON.stringify(json) );
                    })
                    .catch(erro => console.error(erro));
                }else{
                    
                    return new Promise((resolve, reject) => {
                        resolve();
                    }).then(() => {
                        setData(JSON.parse(unidades));
                        setLoading(false);
                    })
                }
            }
        }
        call();
    }, []);
    return [data, loading];
}
export { useUnidades };
