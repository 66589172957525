import React, { useEffect, useState } from "react";
import { Container } from '@material-ui/core';
import { Backdrop, Grid, Paper, Typography, Collapse, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import LoteTable from "./LoteTable";
import CadastroEmpresaTable from "./empresa/CadastroEmpresaTable";
import ManutencaoEmpresaTable from "./empresa/ManutencaoEmpresaTable";
import SituacaoLoteTable from "./situacaoLote/SituacaoLoteTable";
import SituacaoLoteForm from "./situacaoLote/SituacaoLoteForm";
import EmpresaForm from "./empresa/EmpresaForm";
import LoteMapaView from "./LoteMapaView";
import LoteForm from "./LoteForm";
import CadastrarAlterar from "./CadastrarAlterar"
import { useStyles } from "../../constants";
import CloseIcon from '@material-ui/icons/Close';
import PdfView from '../../util/PdfView';
import RegioesTable from './regiaoAdiminstrativas/RegioesTable'
import RegioesForm from "./regiaoAdiminstrativas/RegioesForm";

import EmitirRelatorioForm from "./consultaRelatorio/EmitirRelatorioForm";
import LoteExcluidoTable from "./LoteExcluidoTable";

export default function OperacaoPanel() {
  const classes = useStyles();
  const match = useRouteMatch();
  const [erro, setErro] = useState();
  const [wait, setWait] = useState(false);
  const [titulo, setTitulo] = useState("OperaçãoPanel");
  const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });
  const [values, setValues] = useState({
    cnpj: '00.000.000/0000_00',
    razaoSocial: 'Razão texto',
    telefone: '(00) 0000-000',
    endereco: 'Endereço texto',
    descricaoAtividadePrincipal: 'Descrição Atividade Principal'
  });

  useEffect(() => {

  });

  function setMensagem(alerta) {
    let a;
    if (typeof alerta === 'object') {
      a = { ...alerta };
      if (!a.tipo) a.tipo = 'success';
      if (!a.duracao) a.duracao = 5000;
      a.visibilidade = true;
    } else {
      a = {
        tipo: 'info',
        duracao: 5000,
        visibilidade: true,
        mensagem: alerta
      }
    }

    setAlerta(a);
    if (!!a.duracao && a.duracao > 0) {
      window.setTimeout(() => {
        setAlerta({ tipo: a.tipo, visibilidade: false });
      }, a.duracao)
    }
  }

  return (
    <Container maxWidth="xl">

      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
            <Collapse in={alerta.visibilidade}>
              <Alert severity={alerta.tipo} action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlerta({ visibilidade: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>{alerta.mensagem}</Alert>
            </Collapse>
          </Paper>

          {
            erro && <Alert severity="error">{erro}</Alert>
          }
        </Grid>

        {/*<Grid item sm={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom>Titulo Template</Typography>
          </Paper>
    </Grid>*/}
        <Grid item sm={12}>
          <Switch>
            {/* <Route path={`${match.path}/processoForm`}>
              <b>processo</b>
            </Route> */}

            <Route path={`${match.path}/empresaTable`}>
              <CadastroEmpresaTable setTitulo={setTitulo} setErro={setErro} setMensagem={setMensagem} setValues={setValues} setWait={setWait} />
            </Route>

            <Route path={`${match.path}/manutencaoEmpresa`}>
              <ManutencaoEmpresaTable setTitulo={setTitulo} setErro={setErro} setMensagem={setMensagem} setValues={setValues} setWait={setWait} />
            </Route>


            <Route path={`${match.path}/situacaoLotesTable`}>
              <SituacaoLoteTable setTitulo={setTitulo} setErro={setErro} setValues={setValues} />
            </Route>

            <Route path={`${match.path}/situacaoLotesView`}>
              <SituacaoLoteForm setTitulo={setTitulo} setErro={setErro} setValues={setValues} setWait={setWait} setMensagem={setMensagem} disable={true} />
            </Route>

            <Route path={`${match.path}/situacaoLotesForm`}>
              <SituacaoLoteForm setTitulo={setTitulo} setErro={setErro} setValues={setValues} setWait={setWait} setMensagem={setMensagem} />
            </Route>
            <Route path={`${match.path}/empresaView`}>
              <EmpresaForm setTitulo={setTitulo} disable={true} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/empresaForm`}>
              <EmpresaForm setTitulo={setTitulo} disable={false} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/relatorioEstoqueFrom`}>
              <EmitirRelatorioForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
            </Route>
            <Route path={`${match.path}/relatorioListagemFrom`} >
              <EmitirRelatorioForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
            </Route>
            <Route path={`${match.path}/relatorioFichaFrom`}>
              <EmitirRelatorioForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
            </Route>

            <Route path={`${match.path}/lotes`}>
              <LoteTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/manutencaoLotes`}>
              <LoteExcluidoTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/estoqueLoteView`}>
              <LoteForm disable={false} setTitulo={setTitulo} setWait={setWait} setErro={setErro} setMensagem={setMensagem} disable={true} />
            </Route>

            <Route path={`${match.path}/estoqueLoteExcluidoView`}>
              <LoteForm disable={false} setTitulo={setTitulo} setWait={setWait} setErro={setErro} setMensagem={setMensagem} disable={true} />
            </Route>

            <Route path={`${match.path}/estoqueLoteForm`}>
              <LoteForm setTitulo={setTitulo} setWait={setWait} setErro={setErro} setMensagem={setMensagem} />
            </Route>

            <Route path={`${match.path}/estoqueLoteTable`}>
              <LoteForm setTitulo={setTitulo} disable={true} values={values} setValues={setValues} />
            </Route>

            <Route path={`${match.path}/cadastrarAlterarTabel`}>
              <CadastrarAlterar disable={true} values={values} setValues={setValues} />
            </Route>

            <Route path={`${match.path}/loteMapaView`}>
              <LoteMapaView setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/regioesAdministrativasTable`}>
              <RegioesTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/regioesAdministrativasForm`}>
              <RegioesForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/regioesAdministrativasView`}>
              <RegioesForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/resumoGeralSituacaoSigafe`}>
              <PdfView setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} titulo={"Relatório Resumo Geral Situação SIGAFE"}
                url={"/api/processoProdf/lote/resumoGeralSituacaoSigafe.pdf"}
              />
            </Route>
            <Route path={`${match.path}/resumoGeralSituacaoADE`}>
              <PdfView setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} titulo={"Relatório Resumo Geral Situação (por ADE)"}
                url={"/api/processoProdf/lote/resumoGeralSituacaoADE.pdf"}
              />
            </Route>
            <Route path={`${match.path}/resumoGeralSituacao`}>
              <PdfView setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} titulo={"Relatório Resumo Geral Situação"}
                url={"/api/processoProdf/lote/relatorioResumoGeralSituacao.pdf"}
              />
            </Route>
            <Route path={match.path}>
              <h2>nao implementado</h2>
            </Route>
          </Switch>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={wait}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Container>
  )
}