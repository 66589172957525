import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { List, Collapse, Drawer } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import StarBorder from '@material-ui/icons/StarBorder';
import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import { useCurrentUser } from "../server/UseCurrentUser";
import { ACCESS_TOKEN } from '../constants';
import {
  Link
} from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Service from '../services/Service';
import { DriveEta } from '@material-ui/icons';

const useStyles = makeStyles((theme) =>
  createStyles({
    list: {
      width: 250,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    listItemText: {
      fontSize: '0.85em',
    },
  }),
);

const LeftMenu = (props) => {
  const classes = useStyles();

  // const [menu, loading] = useFetch("/api/menu/");
  const [menu, setMenu] = useState(null);
  const [loading, setLoding] = useState(true);
  const [nestedMenu, setNestedMenu] = useState([]);
  let [profile] = useCurrentUser();


  useEffect(() => {
    if (!menu) {
      const mn = localStorage.getItem(ACCESS_TOKEN + "_menu");
      if (!profile || profile.length === 0) return;
      if (!mn) {


        Service.GET("/api/menu/" + profile.id).then(result =>{
          localStorage.setItem(ACCESS_TOKEN + "_menu", JSON.stringify(result));
              result.forEach(el => {
                let arr = [];
                if (!el.acao) {
                  arr.push(el.nome);
                }
                el.isOpen = false;
                setNestedMenu(arr);
              })
              setMenu(result);
              setLoding(false);
        })
      } else {
        setMenu(JSON.parse(mn));
        setLoding(false);
      }
    }
  }, [menu, profile]);

  const toggleDrawer = (open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    props.onchange(event);
  };
  const handleNestedClick = (e, mn) => {
    e.stopPropagation();
    mn.isOpen = !mn.isOpen;
    if (nestedMenu.includes(mn.nome)) {
      let arr = [];
      nestedMenu.forEach(el => {
        if (el !== mn.nome) {
          arr.push(el);
        }
      });
      setNestedMenu(arr);
    } else {
      let arr = [...nestedMenu];
      arr.push(mn.nome);
      setNestedMenu(arr);
    }
  }

  function listNestedSubmenu(mn, sub, index) {
    return (
      <List key={"sub_"+index} component="div" disablePadding>
        {mn.isOpen && <ListItem button to={"/" + sub.acao} component={Link} key={sub.nome} className={classes.nested}>
          <ListItemIcon>
            <Icon>{sub.icone}</Icon>
          </ListItemIcon>
          <ListItemText primary={sub.nome} classes={{ primary: classes.listItemText }} />
        </ListItem>}
      </List>
    );
  }

  function listNestedItem(mn, index) {
    if (mn.acao) {
      if (mn.nome === '-') {
        return <Divider key={index} />
      } else {
        return (
          <ListItem button to={"/" + mn.acao} component={Link} key={"item_"+index}>
            <ListItemIcon>
              <Icon>{mn.icone}</Icon>
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary={mn.nome} >
            </ListItemText>
          </ListItem>
        )
      }
    } else {
      return (
        <div key={mn.nome} key={"item_"+index}>
          <ListItem button onClick={(e) => handleNestedClick(e, mn)}>
            <ListItemIcon>
              <Icon>{mn.icone}</Icon>
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary={mn.nome} name={mn.nome}>
            </ListItemText>
            <ListItemIcon>
              {!mn.isOpen && <ExpandMoreIcon />}
              {!!mn.isOpen && mn.isOpen && <ExpandLessIcon />}
            </ListItemIcon>
          </ListItem>
          {mn.subMenu && mn.subMenu.map((sub, index2) => listNestedSubmenu(mn, sub, index+"_"+index2))}
        </div>
      )
    }
  }

  const sideList = (side, omenu) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      style={{ width: '350px' }}
    >
      <List>
        <ListItem button to="/home" component={Link} key="Inicio">
          <ListItemIcon>
            <Icon>home</Icon>
          </ListItemIcon>
          <ListItemText primary="Inicio" classes={{ primary: classes.listItemText }}>
          </ListItemText>
        </ListItem>

        {omenu.map && omenu.map((mn, index) => listNestedItem(mn, index))}
      </List>
    </div >
  );
  const sideListPublico = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button to="/home" component={Link} key="Inicio">
          <ListItemIcon>
            <Icon>home</Icon>
          </ListItemIcon>
          <ListItemText primary="Inicio" classes={{ primary: classes.listItemText }}>
          </ListItemText>
        </ListItem>
        <ListItem button to="/home/solicitarAcesso" component={Link} key="Solicitar Acesso">
          <ListItemIcon>
            <Icon>home</Icon>
          </ListItemIcon>
          <ListItemText primary="Solicitar Acesso" classes={{ primary: classes.listItemText }}>
          </ListItemText>
        </ListItem>
        <ListItem button to="/processos" component={Link} key="Processos">
          <ListItemIcon>
            <Icon>search</Icon>
          </ListItemIcon>
          <ListItemText primary="Processos" classes={{ primary: classes.listItemText }}>
          </ListItemText>
        </ListItem>
        <ListItem button to="/home/testeInfo" component={Link} key="Teste Backend">
          <ListItemIcon>
            <Icon>accessibility</Icon>
          </ListItemIcon>
          <ListItemText primary="Teste backend" classes={{ primary: classes.listItemText }}>
          </ListItemText>
        </ListItem>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Starred" classes={{ primary: classes.listItemText }} />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </div>
  );

  return (
    <div>
      <Drawer
        open={props.open}
        onClose={toggleDrawer(false)}
        // onOpen={toggleDrawer(true)}
        // ModalProps={{
        //   keepMounted: true, // Better open performance on mobile.
        // }}
      >
        {loading ? sideListPublico('left') : sideList('left', menu)}
      </Drawer>

    </div>
  );
}

export default LeftMenu;