import React, { useEffect, useState, useRef } from 'react';
import {
    Grid, Paper, Typography, Button, TextField,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell,
    Accordion, AccordionSummary, AccordionDetails,
    Box, Menu, MenuItem, IconButton, Divider, Tooltip,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaskedInput from 'react-text-mask'
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import { useStyles, getQueryParams, ValidarCNPJ, FormataDataComHora } from "../../../constants";
import { useLocation, useHistory } from "react-router-dom";
import ConfirmDialog from "../../../util/ConfirmDialog";
import GenericTable from '../../template/GenericTable';
import { ValidarCPF } from '../../../constants/';
import HistoricoDialog from "./HistoricoDialog";
import HistoricoView from "./HistoricoView";
import { fi, id } from 'date-fns/locale';
import Service from "../../../services/Service";

const optionsDados = [
    'Alterar',
    'Ativar/Inativar',
    'Excluir'

];

let labelSalvar = null;
let msgConfirmacao = null;
let indexResp = 0;
let fonteDeDadosAtividadesSec = null;
let filtroCampo = null;
let qtd = 0;
let workHistorico = null;

const dadosDaEmpresa = [
    { cnae: "11.01-1-00", descricao: "Fabricação produto" },
    { cnae: "11.01-1-00", descricao: "Fabricação produto" },
]

function CNPJMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}


function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

function TelMask(props) {
    const { inputRef, ...other } = props;
    function functMask() {
        let nume = props.value.replace(/\s/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, '');

        if (nume.length == 11) return ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
    }
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={functMask()}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

function CelMask(props) {
    const { inputRef, ...other } = props;
    function functMask() {
        let nume = props.value.replace(/\s/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, '');

        if (nume.length == 11) return ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
    }
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={functMask()}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}


export default function EmpresaForm(props) {
    const classes = useStyles();

    const { setErro, setTitulo, disable, setWait, setMensagem } = props;
    const [error, setError] = useState(resetErro());
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [showConfirm, setShowConfirm] = useState(false);
    const [anchorElDados, setAnchorElDados] = React.useState(null);
    const [showTel1, setShowTel1] = useState(false);
    const openActionDados = Boolean(anchorElDados);
    const location = useLocation();

    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const [responsaveis, setResponsaveis] = useState([]);

    const [dadosFiltradoAtividadesSec, setDadosFiltradoAtividadesSec] = React.useState([]);
    const [bloco, setBloco] = useState(0);
    const [pagina, setPagina] = useState(5);
    const [openHistorico, setOpenHistorico] = useState(false);
    const [openview, setOpenView] = useState(false);
    const [dadoHistorico, setDadoHistorico] = useState({});
    const [dadoView, setDadoView] = useState({});
    const [tipoInstrumento, setTipoInstrumento] = useState("cadastro");
    const [labelIncluir, setLabelIncluir] = useState("Incluir");
    const textInput = useRef(null);

    const [values, setValues] = useState({
        cnpj: '',
        usuario: '',
        razaoSocial: '',
        telefone: '',
        endereco: '',
        descricaoAtividadePrincipal: '',
        cpfRepr: '',
        rrgrepr: '',
        email: '',
        telcel: '',
        cfdf: ''
    });
    const [representante, setRepresentante] = useState({});
    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc',
    });

    useEffect(() => {
        const id = param.get("id");
        setTitulo((disable ? "Visualizar" : (id ? "Altera" : "Cadastro")) + " Empresa");

        if (id) {
            labelSalvar = "Alterar";
            msgConfirmacao = 'Confirma alteração de empresa?';
            buscaEmpresa(id)
        } else {
            labelSalvar = "Cadastrar";
            msgConfirmacao = 'Confirma cadastro de empresa?';
            textInput.current.focus();
            if (param.get('processo')) {
                setValues({
                    ...values,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarCancelamentoForm");
            }
        }
    }, []);

    useEffect(() => {
        filtraDadoAtividadesSec();
    }, [ordem, pagina, bloco]);

    function buscaEmpresa(id) {
        setWait(true);
        Service.GET("/api/empresa/" + id).then(json => {
            setWait(false);
            let podeBuscar = true;
            if ((!json.responsaveis || json.responsaveis.length === 0)) {
                if (json.representante && json.representante.length > 0) {
                    podeBuscar = false;
                    incluiResponsavelInicial(id, {
                        nome: json.representante,
                        rg: json.rgrepr,
                        email: json.email,
                        telefone: json.telefone,
                        ativo: true,
                        userSistema: false
                    })
                }
            }
            const mask = json.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
            if (podeBuscar) buscaSolicitarAcesso(mask, json);
            setValues(json);
            atividadePrincipal(json);
            if (json.atividade) fonteDeDadosAtividadesSec = json.atividade.atividadeSecundaria;
            filtraDadoAtividadesSec();
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });

        // fetch("/api/empresa/" + id, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok ) {
        //         response.json().then((json) => {
        //             setWait(false);
        //             let podeBuscar = true;
        //             if ((!json.responsaveis || json.responsaveis.length === 0)) {
        //                 if (json.representante && json.representante.length > 0) {
        //                     podeBuscar = false;
        //                     incluiResponsavelInicial(id, {
        //                         nome: json.representante,
        //                         rg: json.rgrepr,
        //                         email: json.email,
        //                         telefone: json.telefone,
        //                         ativo: true,
        //                         userSistema: false
        //                     })
        //                 }
        //             }

        //             const mask = json.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
        //             if (podeBuscar) buscaSolicitarAcesso(mask, json);
        //             setValues(json);
        //             // if( !json.nomeFantasia ){

        //             //     // buscaNomeFantasia(json);
        //             // }
        //             atividadePrincipal(json);
        //             if (json.atividade) fonteDeDadosAtividadesSec = json.atividade.atividadeSecundaria;

        //             filtraDadoAtividadesSec();

        //         });
        //     } else {
        //         response.json().then((json) => {
        //             // const { message, stackTrace } = json;
        //             throw json;
        //         });
        //         // response.json().then((error) => {
        //         //     setWait(false);
        //         //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         // });
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }
    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeInputRepreForm = (event) => {
        setRepresentante({
            ...representante,
            [event.target.name]: event.target.value,
        });
    };
    function alterarResponsavel(resp) {
        const dado = {
            id: values.id,
            responsavel: resp
        };
        setWait(true);
        Service.POST('/api/empresa/alteraResponsavel', dado).then(response => {
            setWait(false);
            let arr = [...responsaveis];
            // arr.push( representante);
            for (let i in arr) {
                let r = arr[i];
                if (r.nome === resp.nome) {
                    r.nome = resp.nome;
                    r.telefone = resp.telefone;
                    r.ativo = resp.ativo;
                }
            }
            setResponsaveis(arr);
            setRepresentante({});
            setLabelIncluir("Incluir");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });

        // fetch('/api/empresa/alteraResponsavel', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok ) {
        //         let arr = [...responsaveis];
        //         // arr.push( representante);
        //         for(let i in arr){
        //             let r = arr[i];
        //             if( r.nome === resp.nome){
        //                 r.nome = resp.nome;
        //                 r.telefone = resp.telefone;
        //                 r.ativo = resp.ativo;
        //             }
        //         }
        //         setResponsaveis( arr );
        //         setRepresentante({});
        //         setLabelIncluir("Incluir");
        //     } else {
        //         response.json().then((error) => {
        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }
    const mudarParaCadastro = () => {
        const id = param.get("id");
        history.replace("/operacao/empresaForm?id=" + id);
        setTitulo((id ? "Altera" : "Cadastro") + " Empresa");

        if (id) {
            labelSalvar = "Alterar";
            msgConfirmacao = 'Confirma alteração de empresa?';
            // buscaEmpresa(id)
        } else {
            labelSalvar = "Cadastrar";
            msgConfirmacao = 'Confirma cadastro de empresa?';
            textInput.current.focus();
        }
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    }
    const handleInlcuirClick = () => {
        representante.ativo = true;
        const dado = {
            id: values.id,
            responsavel: representante
        };
        setWait(true);
        Service.POST('/api/empresa/incluirResponsavel', dado).then(json => {
            setWait(false);
            setResponsaveis(json.responsaveis);
            setLabelIncluir("Incluir");
            setRepresentante({});
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
        // fetch('/api/empresa/incluirResponsavel', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok ) {
        //         response.json().then((json) => {
        //             setResponsaveis(json.responsaveis);
        //         });
        //         setLabelIncluir("Incluir");
        //         setRepresentante({});
        //     } else {
        //         response.json().then((error) => {
        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }
    function incluiResponsavelInicial(id, rep) {
        rep.ativo = true;
        const dado = {
            id: id,
            responsavel: rep
        };
        setWait(true);
        Service.POST('/api/empresa/incluirResponsavel', dado).then(json => {
            setWait(false);
            setResponsaveis(json.responsaveis);
            setLabelIncluir("Incluir");
            setRepresentante({});
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
        // fetch('/api/empresa/incluirResponsavel', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok ) {
        //         response.json().then((json) => {
        //             setResponsaveis(json.responsaveis);
        //         });
        //         setLabelIncluir("Incluir");
        //         setRepresentante({});
        //     } else {
        //         response.json().then((error) => {
        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    function confirmaExclusao() {
        let resp = values.responsaveis[indexResp];

        const dado = {
            id: values.id,
            responsavel: resp
        };
        setWait(true);
        Service.POST('/api/empresa/excluirResponsavel', dado).then(json => {
            setWait(false);
            let novoArr = [];
            let arr = [...responsaveis];
            for (let i in arr) {
                const r = arr[i];
                if (r.nome !== resp.nome) {
                    novoArr.push(r);
                }
            }
            setResponsaveis(novoArr);
            setValues(json);
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });

        // fetch('/api/empresa/excluirResponsavel', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok) {
        //         let novoArr = [];
        //         let arr = [...responsaveis];
        //         for (let i in arr) {
        //             const r = arr[i];
        //             if (r.nome !== resp.nome) {
        //                 novoArr.push(r);
        //             }
        //         }
        //         setResponsaveis(novoArr);
        //         response.json().then((json) => {
        //             setValues(json);
        //         });
        //     } else {
        //         response.json().then((error) => {
        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    function naoPodeSalvar() {
        const cnpj = values.cnpj ? values.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim() : '';
        if (cnpj.length === 0) return true;
        if (!responsaveis || responsaveis.length === 0) return true;
        if (!values.telefone || values.telefone.length === 0) return true;
        return false;
    }

    function atividadePrincipal(empresa) {
        if (!empresa.atividade) {
            setWait(true);
            fetch("/api/empresa/atualizaAtividade/" + empresa.id).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        setWait(false);
                        setValues(json);
                    });
                    setWait(false);

                } else {
                    response.json() // return the result of the inner promise, which is an error
                        .then((error) => {
                            setWait(false);
                            if (error.message.includes('Too Many Requests')) {
                                const item = {
                                    tipo: 'info',
                                    duracao: 15000,
                                    visibilidade: true,
                                    mensagem: "Vários acessos ao serviço da Receita Federal acima do limite permitido. Volte a esta funcionalidade mais tarde."
                                }
                                setMensagem(item);
                            } else setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                        });

                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });

        }
    }

    function buscaSolicitarAcesso(cnpj, empresa) {
        fetch("/api/acesso/cnpj", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json'
            },
            body: JSON.stringify({ cnpj: cnpj }),
        }
        ).then(res => res.json()).then((result) => {
            setWait(false);
            empresa.responsaveis = empresa.responsaveis ? empresa.responsaveis : [];
            for (let i in result) {
                const acesso = result[i];
                if (acesso.status === 'APROVADO') {
                    const item = empresa.responsaveis.find(resp => resp.nome && resp.nome.toUpperCase() === acesso.solicitante.nome.toUpperCase());
                    if (!item || item === null) {
                        empresa.responsaveis.push({
                            nome: acesso.solicitante.nome,
                            cpf: acesso.solicitante.cpf,
                            telefone: acesso.solicitante.telefone,
                            email: acesso.solicitante.email,
                            userSistema: true,
                            ativo: true
                        })
                    }
                }
            };
            setResponsaveis(empresa.responsaveis);
        });
    }
    function confirmaAprovacao() {
        setError(resetErro());
        let dado = { ...values, responsaveis: responsaveis };
        submitForm(dado);
    }
    function alteraInstrumentoRegistro(dado) {
        setWait(true);
        Service.POST('/api/empresa/alteraInstrumentoRegistro', dado).then(json => {
            setWait(false);
            setValues(json);
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });

        // fetch('/api/empresa/alteraInstrumentoRegistro', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     setWait(false);
        //     if (response.ok ) {
        //         response.json().then((json) => {
        //             setValues(json);
        //         });

        //     } else {
        //         response.json().then((error) => {

        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setWait(false);  
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }
    function submitForm(dado) {
        console.log(dado)
        fetch('/api/empresa', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {

                history.replace("/operacao/empresaTable");
            } else {
                response.json().then((error) => {

                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {

            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function resetErro() {
        return {

            cpf: { erro: false, msg: '' },

            cnpj: {
                erro: false,
                msg: ''
            },
            telefone: {
                erro: false,
                msg: ''
            },
            cpf: {
                erro: false,
                msg: ''
            },
        }
    }

    const handleLostFocusCNPJ = (e) => {
        if (disable) return; // para não pesquisar quando text do cnpj estiver disable
        setError({
            ...error,
            cnpj: {
                erro: false,
                msg: ''
            }
        });
        const cnpj = values.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (cnpj.length === 0) return;
        setWait(true);
        if (ValidarCNPJ(cnpj)) {
            fetch("/api/v1/cnpj/" + cnpj)
                .then(res => (res.status <= 300) ? res.json() : setErro(res))
                .then(
                    (result) => {
                        setWait(false);
                        if (!result) return;
                        // if (!result.razaoSocial) {
                        //     result.razaoSocial = result.nome
                        // }
                        // if (result.atividade_principal) {
                        //     setValues({
                        //         atividadePrincipal: result.atividade_principal,
                        //         atividadeSecundaria: result.atividades_secundarias
                        //     })
                        // }
                        // setValues(result);
                        buscaEmpresa(result.id);
                    },
                    (error) => {
                        setWait(false);
                    }
                )
        } else {
            setWait(false);
            setError({
                ...error,
                cnpj: {
                    erro: true,
                    msg: 'CNPJ inválido'
                }
            });
        }
    }

    const handleTelLostFocus = () => {
        if (!values || !values.telefone) return;
        setShowTel1(values.telefone.trim().length !== 16);
    }
    const handleTelFocus = () => {
        setShowTel1(false);
    }

    const handleLostFocusCPF = (e) => {
        setError({
            ...error,
            cpf: {
                erro: false,
                msg: ''
            }
        });
        if (!representante || !representante.cpf) return;
        const cpf = representante.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (ValidarCPF(cpf)) {
            if (isCpfJaCadastrado(representante.cpf)) return;
            setWait(true);
            fetch("/api/v1/cpf/" + cpf)
                .then(res => {
                    if (res.status <= 300) return res.json()
                })
                .then(
                    (result) => {
                        setWait(false);
                        setError(resetErro);
                        if (!result) {
                            setRepresentante({
                                ...representante, nome: '', telefone: '', email: ''

                            });
                        } else {
                            setRepresentante({
                                ...representante,
                                idUser: result.id,
                                nome: result.name,
                                email: result.email,
                                telefone: result.telefone
                            });
                        }
                    },
                    (error) => {
                        setWait(false);
                    }
                )
        } else {
            setError({
                ...error,
                cpf: {
                    erro: true,
                    msg: 'CPF inválido'
                }
            });
        }
    }
    function isCpfJaCadastrado(cpf) {
        if (responsaveis) {
            const item = responsaveis.find(r => r.cpf === cpf);
            if (item) {
                setRepresentante(item);
                setLabelIncluir("Alterar");
                return true;
            }
        }
        return false;
    }
    const handleCloseDados = () => {
        setAnchorElDados(null);
    }

    const handleAcaoDados = (e, index) => {
        indexResp = index;

        setAnchorElDados(e.currentTarget);
    }
    function topFunction() {
        setTimeout(function () {
            document.body.scrollTop = 0; // For Safari
            document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        }, 30);
    }
    function getNomeMenu(option) {
        if (option === 'Ativar/Inativar') {
            if (indexResp >= 0 && values && values.responsaveis) {
                try {
                    return (values.responsaveis[indexResp].ativo) ? 'Inativar' : 'Ativar'
                } catch (err) {
                    return "???";
                }
            } else {
                return "??"
            }
        } else {
            return option;
        }
    }
    function disabilitaMenu(option, index) {
        if (option === "Excluir") {
            if (!values || !values.responsaveis) return false;
            let resp = values.responsaveis[indexResp];
            return resp ? resp.userSistema : false;
        }
        return false;
    }
    const handleActionClickDados = (action) => {
        let resp = values.responsaveis[indexResp];
        let acao = optionsDados[action];
        if (acao == 'Excluir') {
            if (values.responsaveis.length == 1) {
                setMensagem({
                    tipo: "error",
                    mensagem: "Não pode excluir o ultimo responsável da empresa."
                });
                topFunction();
            } else {
                setShowConfirmExclusao(true);
            }
        }
        if (acao == 'Alterar') {
            setLabelIncluir("Alterar");
            setRepresentante(resp);
        }
        if (acao == 'Ativar/Inativar') {
            resp.ativo = !resp.ativo;//resp.userSistema && resp.userSistema === 'sim' ? 'sim' : 'sim'
            alterarResponsavel(resp)
        }
        handleCloseDados();
    };
    const handleChangePageAtividadesSec = (event, newPage) => {
        setBloco(newPage);
    };
    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function getTituloSituacao(row) {
        return "Visualizar";
    }
    const handleActionHistoricoClick = (index, acao, dado) => {

        workHistorico = { index: index, dado: dado };

        if (acao && acao === 'Visualizar') {
            setOpenView(true);
            setDadoView(dado);
        } else {
            setOpenHistorico(true);
            setDadoHistorico(dado);
        }
    }

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
        setBloco(0);
    }

    function naoPodeIncluirEmpresario() {
        if (!representante || !representante.nome || representante.nome.length == 0) return true;
        if (!representante.rg) return true;
        if (!representante.email) return true;
        if (!representante.telefone) return true;
        return false;
    }
    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }
        return ordenado;
    }

    function filtraDadoAtividadesSec() {
        if (!fonteDeDadosAtividadesSec) return;
        let arr = [];

        let arrOrdenado = ordenar(fonteDeDadosAtividadesSec);

        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                let campos = filtroCampo.split(' ');
                root: for (let x in d) {
                    for (var y in campos) {
                        if (typeof d[x] === 'string') {
                            if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                arr.push(d);
                                break root;
                            }
                        } else if (d[x] == campos[y]) {
                            arr.push(d);
                            break root;
                        }
                    }
                }
            } else {
                arr.push(d);
            }
        });
        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (index++ > (pagina - 2)) break;
        }
        setDadosFiltradoAtividadesSec(arrBloco);
    }
    const handleSaveClick = () => {
        setOpenHistorico(true);
        setTipoInstrumento("cadastro");
    }
    function instrumentoClick(instrumento) {
        if (workHistorico && workHistorico.index >= 0) {
            workHistorico.id = values.id;
            workHistorico.dado = instrumento;

            workHistorico.dado.dataPublicacao = instrumento.dataPublicacao ? instrumento.dataPublicacao.getTime() : null;


            alteraInstrumentoRegistro(workHistorico);
        } else {
            setValues({ ...values, historico: [instrumento] });
            setShowConfirm(true);
        }
    }
    function isReadOnly() {
        return (disable && disable === true) ? true : false;
    }
    function transformaDt(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
                <Accordion defaultExpanded={location.pathname.includes("empresaForm" + id) ? false : true} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h5" gutterBottom>Dados Empresa</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={4}>
                                <TextField ref={textInput}
                                    value={values.cnpj || ''}
                                    required
                                    error={error.cnpj.erro}
                                    helperText={error.cnpj.msg}
                                    label="CNPJ"
                                    onChange={handleChangeInputForm}
                                    onBlur={handleLostFocusCNPJ}
                                    name="cnpj"
                                    id="cnpj-mask-input"
                                    InputProps={{
                                        inputComponent: CNPJMask,
                                        readOnly: isReadOnly(),
                                        autoFocus: true
                                    }}
                                    variant="outlined"
                                    disabled={(values && values.id) ? true : false}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    label="Razão social"
                                    value={values.razaoSocial || ''}
                                    onChange={handleChangeInputForm}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    name="razaoSocial"
                                    id="nome-empresa-input"
                                    variant="outlined"

                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={values.cfdf || ''}
                                    fullWidth
                                    label="CF/DF"
                                    id="cfdf"
                                    name="cfdf"
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: isReadOnly(),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    fullWidth
                                    label="Nome Fantasia"
                                    value={values.nomeFantasia || ''}
                                    onChange={handleChangeInputForm}
                                    InputProps={{
                                        // readOnly: true,
                                        readOnly: isReadOnly(),
                                    }}
                                    name="nomeFantasia"
                                    id="nomeFantasia"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    error={error.telefone.erro}
                                    helperText={error.telefone.msg}
                                    label="Telefone para contato"
                                    value={values.telefone || ''}
                                    onChange={handleChangeInputForm}
                                    onBlur={handleTelLostFocus}
                                    onFocus={handleTelFocus}

                                    name="telefone"
                                    id="tel-mask-input"
                                    fullWidth
                                    InputProps={{
                                        inputComponent: TelMask,
                                        readOnly: isReadOnly(),
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={values.endereco || ''}
                                    fullWidth
                                    label="Endereço"
                                    id="endereco"
                                    name="endereco"

                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: isReadOnly(),
                                    }}
                                />
                            </Grid>


                            <Grid item xs={4}>
                                <TextField

                                    value={values.atividade && values.atividade.atividadePrincipal[0].code || ""}
                                    fullWidth
                                    label="CNAE Atividade principal"
                                    id="cneaAtividadePrincipal"
                                    name="cneaAtividadePrincipal"
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: isReadOnly(),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={values.atividade && values.atividade.atividadePrincipal[0].text || ""}
                                    fullWidth
                                    label="Descrição atividade principal"
                                    id="descricaoAtividadePrincipal"
                                    name="descricaoAtividadePrincipal"

                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Typography variant="body1" gutterBottom>CNAE Atividades secundarias:</Typography>
                            {values.atividade && values.atividade.atividadeSecundaria &&
                                <GenericTable disableSearch={true}
                                    dados={values.atividade.atividadeSecundaria} colunas={[
                                        { nome: 'CNAe', campo: 'code' },
                                        { nome: 'Descrição', campo: 'text' },
                                    ]} />
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12} >
                <Accordion defaultExpanded={location.pathname.includes("empresaForm" + id) ? true : false} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h5" gutterBottom>Dados do representante</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} className={classes.root}>
                            {
                                location.pathname.includes("empresaForm") &&
                                <Grid container spacing={3} className={classes.root}>
                                    <Grid item xs={4}>
                                        <TextField
                                            value={representante.cpf || ''}
                                            label="CPF"
                                            id="cpf"
                                            name="cpf"
                                            onChange={handleChangeInputRepreForm}
                                            onBlur={handleLostFocusCPF}
                                            error={error.cpf.erro}
                                            helperText={error.cpf.msg}
                                            fullWidth
                                            InputProps={{
                                                inputComponent: CPFMask,
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            value={representante.nome || ''}
                                            fullWidth
                                            label="Nome"
                                            id="nome"
                                            name="nome"
                                            onChange={handleChangeInputRepreForm}
                                            aria-describedby="nova-helper-text"
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: disable,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            value={representante.rg || ''}
                                            fullWidth
                                            label="RG/OAB/CNH"
                                            id="rg"
                                            name="rg"
                                            onChange={handleChangeInputRepreForm}
                                            aria-describedby="nova-helper-text"
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: disable,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={representante.email || ''}
                                            fullWidth
                                            label="e-mail"
                                            id="email"
                                            name="email"
                                            onChange={handleChangeInputRepreForm}
                                            aria-describedby="nova-helper-text"
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: disable,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            required
                                            error={error.telefone.erro}
                                            helperText={error.telefone.msg}
                                            label="Telefone para contato"
                                            value={representante.telefone || ''}
                                            onChange={handleChangeInputRepreForm}
                                            onBlur={handleTelLostFocus}
                                            onFocus={handleTelFocus}
                                            name="telefone"
                                            id="telefone"
                                            fullWidth
                                            InputProps={{
                                                inputComponent: CelMask,
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid container justify="flex-end" className={classes.btns}>
                                        <Button variant="contained" color="primary" onClick={handleInlcuirClick} rel="noopener noreferrer" disabled={naoPodeIncluirEmpresario()}>
                                            {labelIncluir}
                                        </Button>
                                    </Grid>
                                </Grid>
                            }
                            <TableContainer component={Paper} className={classes.root}>

                                <Table aria-label="customized table" size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                <TableSortLabel
                                                    active={ordem.campo === 'nome'}
                                                    direction={ordem.ordem}
                                                    onClick={(e) => createSortHandler('nome')}
                                                >Nome</TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                <TableSortLabel
                                                    active={ordem.campo === 'cpf'}
                                                    direction={ordem.ordem}
                                                    onClick={(e) => createSortHandler('cpf')}
                                                >CPF</TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                <TableSortLabel
                                                    active={ordem.campo === 'rg'}
                                                    direction={ordem.ordem}
                                                    onClick={(e) => createSortHandler('rg')}
                                                >RG/OAB/CNH</TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                <TableSortLabel
                                                    active={ordem.campo === 'email'}
                                                    direction={ordem.ordem}
                                                    onClick={(e) => createSortHandler('email')}
                                                >e-mail</TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                <TableSortLabel
                                                    active={ordem.campo === 'telefone'}
                                                    direction={ordem.ordem}
                                                    onClick={(e) => createSortHandler('telefone')}
                                                >Telefone</TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                <TableSortLabel
                                                    active={ordem.campo === 'usuarioSistema'}
                                                    direction={ordem.ordem}
                                                    onClick={(e) => createSortHandler('usuarioSistema')}
                                                >Usuário de sistema</TableSortLabel>
                                            </TableCell>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                <TableSortLabel
                                                    active={ordem.campo === 'ativo'}
                                                    direction={ordem.ordem}
                                                    onClick={(e) => createSortHandler('ativo')}
                                                >Ativo</TableSortLabel>
                                            </TableCell>
                                            {!isReadOnly() &&
                                                <TableCell sortDirection={false} className={classes.tableHead}>
                                                    <TableSortLabel
                                                        active={ordem.campo === 'ativo'}
                                                        direction={ordem.ordem}
                                                        onClick={(e) => createSortHandler('ativo')}
                                                    >Ação</TableSortLabel>
                                                </TableCell>
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {responsaveis && responsaveis.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell >{row.nome}</TableCell>
                                                <TableCell> {row.cpf}</TableCell>
                                                <TableCell> {row.rg}</TableCell>
                                                <TableCell> {row.email}</TableCell>
                                                <TableCell> {row.telefone}</TableCell>
                                                <TableCell> {(row.userSistema && row.userSistema === true) ? 'sim' : 'não'}</TableCell>
                                                <TableCell> {(row.ativo && row.ativo === true) ? 'sim' : 'não'}</TableCell>
                                                {!isReadOnly() &&
                                                    <TableCell align="center">
                                                        <Box >
                                                            <Tooltip title={getTituloSituacao()}>
                                                                <IconButton onClick={(e) => handleAcaoDados(e, i)}>
                                                                    <MoreVertIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </Box>
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                {
                                    dadosDaEmpresa &&
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={qtd}
                                        rowsPerPage={pagina}
                                        page={bloco}
                                        labelRowsPerPage="Registros por página"
                                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                }
                            </TableContainer>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12} >
                <Accordion defaultExpanded={location.pathname.includes("empresaForm" + id) ? true : false} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography variant="h5" gutterBottom>Histórico Alterações</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} className={classes.root}>
                            {values && values.historico &&
                                <GenericTable
                                    actionClick={handleActionHistoricoClick}
                                    options={['Registrar instrumento', 'Visualizar']}
                                    dados={values.historico}
                                    readOnly={isReadOnly()}
                                    colunas={[
                                        { nome: 'Data', campo: 'data', transformacao: transformaDt },
                                        { nome: 'Responsável', campo: 'responsavel.nome' },
                                        { nome: 'Observacao', campo: 'descricao' },
                                        { nome: 'Alteração', campo: 'texto' },
                                        { nome: 'Número DODF', campo: 'dodf' },
                                        { nome: 'Protocolo', campo: 'protocolo' },
                                        { nome: 'Ação', campo: 'action' },
                                    ]} />
                            }
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorElDados}
                keepMounted
                open={openActionDados}
                onClose={handleCloseDados}
            >
                {optionsDados.map((option, index) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleActionClickDados(index)} disabled={disabilitaMenu(option, index)}>
                        {getNomeMenu(option)}
                    </MenuItem>
                ))}
            </Menu>

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/operacao/empresaTable"} rel="noopener noreferrer">
                    Sair
                </Button>
                {location.pathname.includes("empresaView") &&
                    <Button variant="contained" color="primary"
                        onClick={mudarParaCadastro} rel="noopener noreferrer" >
                        Alterar
                    </Button>
                }
                {location.pathname.includes("empresaForm") &&
                    <Button variant="contained" color="primary" onClick={handleSaveClick} rel="noopener noreferrer" disabled={naoPodeSalvar()}>
                        {labelSalvar}
                    </Button>
                }
            </Grid>
            <HistoricoDialog
                open={openHistorico}
                setOpen={setOpenHistorico}
                historico={dadoHistorico}
                setWait={setWait}
                tipoInstrumento={tipoInstrumento}
                cnpj={values.cnpj}
                acao={instrumentoClick}
            />
            <HistoricoView
                open={openview}
                setOpen={setOpenView}
                historico={dadoView}
                setWait={setWait}
                tipoInstrumento={tipoInstrumento}
                cnpj={values.cnpj}
            />

            <ConfirmDialog
                open={showConfirm}
                setOpen={setShowConfirm}
                titulo={'Confirmação'}
                msg={msgConfirmacao}
                exec={confirmaAprovacao}
            />
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />

        </Grid>
    );
}
