import React, { useEffect, useState, Profiler } from 'react';
import { Accordion, Grid, Button, Dialog, DialogTitle, DialogContent, InputLabel, Select, MenuItem, DialogActions } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "../../../constants";
import AvaliarRequerimentoForm from "./AvaliarRequerimentoForm";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FluxoPanel from "../../../util/FluxoPanelBPM";
import HistoricoTable from "./HistoricoTable";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import { makeStyles, } from '@material-ui/core/styles';
import Download from "../../../util/Download";

export const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '160px',
        overflow: 'auto'
    },
    nextWeek: {
        position: 'relative',
        display: 'flex',
        marginLeft: 'auto',
        top: '37px',
        right: '10px',
        zIndex: 999
    }
}), { index: 1 });
let primeiro = true;
let tipoAnexo = null;
let acao = '';
let msgDialog = '';
let request;

export default function AvaliaRequerimentoCheckList(props) {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = React.useState(false);
    const { setTitulo, setErro, setWait, setMensagem } = props;
    const [fluxo, setFluxo] = useState();
    const history = useHistory();
    const [expandCheckList, setExpandCheckList] = React.useState(true);
    const [listaCheck, setListaCheck] = React.useState();
    const [txt, setTxt] = useState('');
    const [assuntoDado, setAssuntoDado] = React.useState('');
    const [assunto, setAssunto] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
    const [tituloDialog, setTituloDialog] = useState('Selecione o Texto Padrão');
    const classesLocal = useLocalStyle();
    let [profile] = useCurrentUser();
    const [value, setValue] = useState({});

    // const [state, setState] = React.useState({
    //     gilad: true,
    //     jason: false,
    //     antoine: false,
    // });

    const handleChange = (event) => {
        // setState({ ...state, [event.target.name]: event.target.checked });        

        let arr = [...listaCheck];
        let item = arr.find(item => item.nome === event.target.name);
        item.check = event.target.checked;
        
        setListaCheck(arr);
    };
    // const { gilad, jason, antoine } = state;
    // const error = [gilad, jason, antoine].filter((v) => v).length !== 2;

    useEffect(() => {
        if (primeiro) {
            setTitulo("Check-list: Avaliar Requerimentos");
            request = JSON.parse(localStorage.getItem("worginRequest"));
            setValue(request);
            buscaTipoDocumento();
            buscarResponseveis()
            primeiro = false;
        }
    });

    function buscaTipoDocumento() {
        if (!tipoAnexo) {
            console.log("aqui")
            fetch('/api/info/tipoDocumentoCompleto')
                .then(response => response.json())
                .then(json => {
                    tipoAnexo = json;
                    if(request.assunto === "MIGRAÇÃO + REVOGAÇÃO ADMINISTRATIVA DE CANCELAMENTO"){
                        console.log("ok",)
                    }
                    
                    console.log("aaa",json)
                    const itens = json.find(item => item.tipo === request.assunto);
                    console.log("aaa",itens)
                    if (itens) {
                        let arr = [];
                        itens.lst.forEach(item => arr.push({
                            nome: item,
                            check: false
                        }));
                        setListaCheck(arr);
                    } else {
                        setListaCheck(
                            [{
                                nome: 'Documentação apresentada em conformidade',
                                check: false
                            }]
                        );
                    }

                });
        }
    }
    const handleClickAprovar = (e) => {
        acao = 'aprovar';
        msgDialog = 'Confirma aprovação da solicitação?'
        setShowConfirm(true);
    }
    const handleClickReprovar = (e) => {
        acao = 'reprovar';
        msgDialog = 'Confirma reprovação da solicitação?'
        setShowConfirm(true);

    }
    const handleClickPendente = (e) => {
        acao = 'pendente';
        msgDialog = 'Confirma solicitação pendente?'
        setShowConfirm(true);
    }

    function execAcao() {
        let historico = {
            responsavel: {
                idUser: profile.id,
                nome: profile.name,
                email: profile.email
            },
            data: new Date(),
            texto: txt
        }
        if (acao === 'pendente') {
            request.status = 'PENDENTE';
        } else if (acao === 'aprovar') {
            request.status = 'DESPACHO';
        } else if (acao === 'reprovar') {
            request.status = 'REPROVADO';
        }
        historico.tipo = request.status;
        request.documentos = listaCheck;
        
        request.historico.push(historico);
        submitForm(request);
    }

    function montaUrl() {
        if (request.assunto === 'Recurso') {
            return '/api/processoProdf/recurso/'
        } else if (request.assunto === 'Prorrogacao') {
            return '/api/processoProdf/prorrogacao/'
        } else if (request.assunto === 'Cancelamento') {
            return '/api/processoProdf/cancelamento/'
        } else if (request.assunto === 'Notificacao') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else if (request.assunto === 'Complemento') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else {
            return "/api/processoProdf/requerimento/";
        }
    }
    function montaDownLoad(doc){
        const item = value.documentos.find( d => d.tipo === doc);
        if( !item || !item.id || !item.id === null){
            return (<></>)
        }
        return(
            <Download doc={item} />
        )
    }

    function submitForm(dado) {
        const url = montaUrl();
        setWait(true);

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                primeiro = true;
                setWait(false);
                history.replace("/gerenciaProcesso/unate/avaliaRequerimento");
                // history.goBack();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const disabilitaValidar = () => {
        let ativo = false;
        if (listaCheck) {
            for (let x in listaCheck) {
                const c = listaCheck[x];
                if (c.check) {
                    ativo = true;
                    break;
                }
            }
        }
        if( !txt || txt.length <= 1 )  return true;
        return !ativo;
    }


    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangeAssunto = (event) => {
        setAssuntoDado(event.target.value);
    };

    function buscarResponseveis() {
        setWait(true);
        fetch('/api/textoPadrao/buscarAssunto', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then((json) => {
                    let arr = [];
                    for (let x in json) {
                        let v = json[x]
                        arr.push(v)
                    }
                    setAssunto(arr);

                })
            } else {
                response.json().then((error) => {
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function handleClickComfimarDialog() {
        setTxt(assuntoDado.txt)
        setOpenDialog(false)

    }

    const handleFluxoChange = () => {
        if (!fluxo) {
            let url = '/api/info/fluxoComplexo';
            if (value.assunto === 'Diversos') {
                url = '/api/info/fluxoDiversos';
            } else if (value.assunto.includes('Notificacao')) {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
                // url = '/api/info/fluxoNotificacaoComplementoSimples';
                // if (value.tipo === 'Pendência de vistoria' || value.tipo === 'Prazo vencido') {
                //     url = '/api/info/fluxoNotificacaoComplementoComplexo';
                // }
            } else if (value.assunto && value.assunto.includes('Complemento')) {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
            }


            fetch(url)
                .then(response => response.json())
                .then(json => setFluxo(json)).catch(erro => setErro(erro));
        }
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Formulário</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        
                        <AvaliarRequerimentoForm setErro={setErro} setWait={setWait} 
                            disableBtn={true} disableHisotrico={true} />
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expandCheckList} >
                    <AccordionSummary
                        onClick={() => setExpandCheckList(!expandCheckList)}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Check-list</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControl component="fieldset" >
                            <FormGroup>
                                {
                                    listaCheck && listaCheck.map((check, index) => (
                                        <FormControlLabel key={index}
                                            control={
                                                <>
                                                    <Checkbox checked={check.check} onChange={handleChange} name={check.nome} />
                                                    {
                                                        montaDownLoad(check.nome)
                                                    }
                                                </>
                                        }
                                            label={check.nome}
                                        />
                                       
                                    ))
                                }
                            </FormGroup>
                            <FormHelperText>Checklist para validação dos documentos entregues</FormHelperText>
                        </FormControl>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Histórico</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {request &&
                            <HistoricoTable dados={request.historico} setWait={setWait} setMensagem={setMensagem} />
                        }
                    </AccordionDetails>
                </Accordion>
                <Accordion onChange={handleFluxoChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading} gutterBottom>Fluxo</Typography>
                    </AccordionSummary>
                    <AccordionDetails >
                        {fluxo &&
                            <FluxoPanel fluxo={fluxo} path={value.fluxoPath} />
                        }
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Button className={classesLocal.nextWeek}
                    color="primary" onClick={() => { setOpenDialog(true); }}>
                    Texto Padrão
                </Button>
                <CKEditor
                    editor={ClassicEditor}
                    config={{
                        toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                            '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                    }}
                    data={txt}
                    onReady={editor => {
                        editor.editing.view.change(writer => {
                            writer.setStyle(
                                "height",
                                "250px",
                                editor.editing.view.document.getRoot()
                            );
                        });
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setTxt(data);
                    }}
                    onBlur={(event, editor) => {

                    }}
                    onFocus={(event, editor) => {

                    }}
                />
            </Grid>
            <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth >
                <DialogTitle id="form-dialog-title">{tituloDialog}</DialogTitle>
                <DialogContent>
                    <FormControl variant="outlined" fullWidth >
                        <InputLabel id="demo-simple-select-outlined-label">Título</InputLabel>
                        <Select
                            labelId="lb-idresponsavel"
                            value={assuntoDado}
                            id="idresponsavel"
                            onChange={handleChangeAssunto}
                            label="Título"
                        >
                            {assunto.map(v =>
                                <MenuItem key={v.id} value={v}>
                                    <em>{v.assunto}</em>
                                </MenuItem>
                            )}

                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={() => { setOpenDialog(false); }} rel="noopener noreferrer">
                        Sair
                        </Button>
                    <Button variant="contained" color="primary" onClick={() => handleClickComfimarDialog(assuntoDado)}  >
                        Confirmar
                        </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={showConfirm}
                setOpen={setShowConfirm}
                titulo={'Confirmação'}
                msg={msgDialog}
                exec={execAcao}
            />

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/gerenciaProcesso/unate/avaliaRequerimento"} rel="noopener noreferrer">
                    Sair
                </Button>
                <Button variant="contained" color="primary" onClick={handleClickPendente} disabled={txt.length <= 1}>
                    Pendente
                </Button>
                <Button variant="contained" color="primary" onClick={handleClickReprovar} disabled={txt.length <= 1}>
                    Reprovar
                </Button>
                <Button variant="contained" color="primary" onClick={handleClickAprovar} disabled={disabilitaValidar()} >
                    Validar
                </Button>

            </Grid>
        </Grid>
    );
}
