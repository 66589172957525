import React, { useState } from 'react';
import { Container, Card, CardHeader, CardContent, Grid, TextField, Accordion, AccordionSummary, Typography, AccordionDetails, Button } from '@material-ui/core';
import { FormataDataSemHora, useStyles } from '../../../constants';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GenericTable from '../../template/GenericTable';
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";
import Service from '../../../services/Service';
import { useLocation } from 'react-router-dom';

export default function DetalharDadosProjetoProcessoPanel(props) {
    const classes = useStyles();
    const { processo, setProcesso, gestaoProcesso, profile, setWait, setMensagem } = props;
    const [alteracao, setAlteracao] = useState(false);
    const location = useLocation();


    function transformaDt(valor) {
        if (valor) {
            return FormataDataSemHora(new Date(valor));
        }
        return "";
    }

    function isEmpresario() {
        if (location.pathname.includes("cadastrarProcesso")) return true;
        if (profile) {
            return profile.regras.find(r => r === '012') && true;
        }
        return false;
    }

    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            setAlteracao(false);
            // history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeCartaProjetoViabilidadeEconomico = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            projetoViabilidadeTecnicaEconomicoFinanceiraPvtef: {
                ...processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        <Grid container spacing={1} className={classes.root}>


            <Accordion style={{ width: '100%' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="processo-content"
                    id="processo-header">
                    <Typography className={classes.heading}>
                        <span>PVTEF</span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Container maxWidth="lg">


                        <Card style={{ marginTop: '5px' }}>
                            <CardHeader title="Dados Projeto" />
                            <CardContent>
                                <Grid container spacing={3} className={classes.root}>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={processo && processo.tipoProjeto || ''}
                                            fullWidth
                                            label="Tipo Projeto"
                                            id="tipoProjeto"
                                            name="tipoProjeto"
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefResolucaoN || ''}
                                            fullWidth
                                            label="Resolução nº"
                                            id="pvtefResolucaoN"
                                            name="pvtefResolucaoN"
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefDataResolucao || ''}
                                            fullWidth
                                            label="Data Resolução"
                                            id="pvtefDataResolucao"
                                            name="pvtefDataResolucao"
                                            disabled
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefPublicacaoDodf || ''}
                                            fullWidth
                                            label="Publicação DODF"
                                            id="pvtefPublicacaoDodf"
                                            name="pvtefPublicacaoDodf"
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefDataDodf || ''}
                                            fullWidth
                                            label="Data Publicação"
                                            id="pvtefDataDodf"
                                            name="pvtefDataDodf"
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card style={{ marginTop: '5px' }}>
                            <CardHeader title="Dados Área" />
                            <CardContent>
                                <Grid container spacing={3} className={classes.root}>
                                    <Grid item xs={4}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento || ''}
                                            fullWidth
                                            label="Valor investimento"
                                            id="valorInvestimento"
                                            name="valorInvestimento"
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefAreaEdificar || ''}
                                            fullWidth
                                            label="Área a edificar (m²)"
                                            id="pvtefAreaEdificar"
                                            name="pvtefAreaEdificar"
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.areaEdificada || ''}
                                            fullWidth
                                            label="Área edificada (m²)"
                                            id="areaEdificada"
                                            name="areaEdificada"
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.atividade || ''}
                                            fullWidth
                                            label="Atividade"
                                            id="atividade"
                                            name="atividade"
                                            variant="outlined"
                                            disabled
                                            multiline={true}
                                            rows={5}
                                        />
                                    </Grid>
                                    {!isEmpresario() &&
                                        <Grid item xs={6}>
                                            <TextField
                                                value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.linkFolhaAtividade || ''}
                                                onChange={handleChangeCartaProjetoViabilidadeEconomico}
                                                fullWidth
                                                label="Dados Área (Link/ Folha)"
                                                id="linkFolhaAtividade"
                                                name="linkFolhaAtividade"
                                                variant="outlined"
                                            // disabled
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <h3>Alteração de Área</h3>
                                        <GenericTable
                                            dados={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.altercaoArea || []}
                                            disableSearch={true}
                                            readOnly={true}
                                            colunas={[
                                                { nome: 'Resolução', campo: 'resolucao' },
                                                { nome: 'Data Resolução', campo: 'areaDataResolucao', transformacao: transformaDt },
                                                { nome: 'Valor investido', campo: 'valorInvestido' },
                                                { nome: 'Área a edificar (m²)', campo: 'areaEdificar' },
                                                { nome: 'Área edificada (m²)', campo: 'areaEdificada' }
                                            ]} />
                                        {/* <TabelaComponent dados={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.altercaoArea}>
                                            <CelulaCabecalhoTabelaComponent identificador='resolucao' titulo="Resolução" />
                                            <CelulaCabecalhoTabelaComponent identificador='areaDataResolucao' titulo="Data Resolução" />
                                            <CelulaCabecalhoTabelaComponent identificador='valorInvestido' titulo="Valor investido" />
                                            <CelulaCabecalhoTabelaComponent identificador='areaEdificar' titulo="Área a edificar (m²)" />
                                            <CelulaCabecalhoTabelaComponent identificador='areaEdificada' titulo="Área edificada (m²)" />
                                        </TabelaComponent> */}
                                    </Grid>
                                    {!isEmpresario() &&
                                        <Grid item xs={6}>
                                            <TextField
                                                value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.linkFolhaArea || ''}
                                                onChange={handleChangeCartaProjetoViabilidadeEconomico}
                                                fullWidth
                                                label="Alteração de Área (Link/ Folha)"
                                                id="linkFolhaArea"
                                                name="linkFolhaArea"
                                                variant="outlined"
                                            // disabled
                                            />
                                        </Grid>
                                    }
                                </Grid>
                            </CardContent>
                        </Card>
                        <Card style={{ marginTop: '5px' }}>
                            <CardHeader title="Empregos" />
                            <CardContent>
                                <Grid container spacing={3} className={classes.root}>
                                    <Grid item xs={4}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosAtual || ''}
                                            fullWidth
                                            label="Empregos atuais"
                                            id="pvtefEmpregosAtual"
                                            name="pvtefEmpregosAtual"
                                            disabled
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosGerar || ''}
                                            fullWidth
                                            label="Empregos a gerar"
                                            id="pvtefEmpregosGerar"
                                            name="pvtefEmpregosGerar"
                                            disabled
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && parseInt(processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosAtual) + parseInt(processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosGerar) || ''}
                                            fullWidth
                                            label="Total"
                                            id="empregosTotal"
                                            name="empregosTotal"
                                            variant="outlined"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <h3>Alteração meta emprego</h3>
                                        {/* <TabelaComponent dados={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.alteracaoMeta}>
                                            <CelulaCabecalhoTabelaComponent identificador='resolucao' titulo="Resolução" />
                                            <CelulaCabecalhoTabelaComponent identificador='areaDataResolucao' titulo="Data Resolução" />
                                            <CelulaCabecalhoTabelaComponent identificador='pvtefEmpregosAtual' titulo="Empregos atuais" />
                                            <CelulaCabecalhoTabelaComponent identificador='pvtefEmpregosGerar' titulo="Empregos a gerar" />
                                            <CelulaCabecalhoTabelaComponent identificador='pvtefEmpregosTotal' titulo="Empregos total" />
                                        </TabelaComponent> */}

                                        <GenericTable
                                            disableSearch={true}
                                            dados={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.alteracaoMeta}
                                            colunas={[
                                                { nome: 'Resolução', campo: 'resolucao' },
                                                { nome: 'Data Resolução', campo: 'areaDataResolucao', transformacao: transformaDt },
                                                { nome: 'Empregos atuais', campo: 'pvtefEmpregosAtual' },
                                                { nome: 'Empregos a gerar', campo: 'pvtefEmpregosGerar' },
                                                { nome: 'Total empregos', campo: 'pvtefEmpregosTotal' },
                                            ]} />
                                    </Grid>
                                    {!isEmpresario() &&
                                        <Grid item xs={6}>
                                            <TextField
                                                value={processo &&  processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.linkFolhaEmprego || ''}
                                                onChange={handleChangeCartaProjetoViabilidadeEconomico}
                                                fullWidth
                                                label="Alteração meta emprego (Link/ Folha)"
                                                id="linkFolhaEmprego"
                                                name="linkFolhaEmprego"
                                                variant="outlined"
                                            // disabled
                                            />
                                        </Grid>
                                    }
                                </Grid>
                                {alteracao &&
                                    <Grid container justify="flex-end" className={classes.btns}>
                                        <Button variant="contained" onClick={() => salvar()} color="primary" >
                                            Salvar
                                        </Button>
                                    </Grid>
                                }
                            </CardContent>


                        </Card>

                    </Container>
                </AccordionDetails>
            </Accordion>


            <Accordion style={{ width: '100%' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="processo-content"
                    id="processo-header">
                    <Typography className={classes.heading}>
                        <span>Projeto PVS</span>
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Container maxWidth="lg">

                        <h1>...</h1>


                    </Container>
                </AccordionDetails>
            </Accordion>
            <p />
            {/* <Grid item xs={12}>
                <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="projeto" />
            </Grid> */}
        </Grid>
    );
}
