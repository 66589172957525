import React, { useEffect, useState, } from 'react';
import {
    Button, Grid, Paper, Container, Card, CardHeader, CardContent, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem,
    Dialog, DialogTitle, DialogContent, DialogActions
}
    from '@material-ui/core';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, FormataData, FormataDataSemHora } from "../../../constants"
import Service from '../../../services/Service';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import NumberFormat from 'react-number-format';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { PausePresentationTwoTone } from '@material-ui/icons';
import GenericTable from '../../template/GenericTable';
import { ptBR } from 'date-fns/locale';
import EtapaProcessoComp from './EtapaProcessoComp';

const FormataCampo = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}
const tipoProjetos = ['PVS', 'PVTEF'];

export default function CadastroProjetoForm(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setMensagem } = props;
    const [id, setId] = useState();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [processo, setProcesso] = useState({});
    const [pvtefDataResolucao, setPvtefDataResolucao] = useState();
    const [pvtefDataDodf, setPvtefDataDodf] = useState();
    const [campoDialog, setCampoDialog] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [disabledSalvar, setDisabledSalvar] = useState(true);

    const [dadosAlterados, setDadosAlterados] = useState();
    useEffect(() => {
        setTitulo("Cadastrar Projeto");
        if (param.get("id")) {
            setId(param.get("id"));
            fetch(`/api/processoProdf/${param.get("id")}`).then((r) => {
                setWait(false);
                if (!r.ok) {
                    r.json().then(j => setMensagem(j));

                } else {
                    r.json().then(result => {
                        setProcesso(result);
                        if (result.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef) {
                            setDadosAlterados({
                                valorInvestimento: result.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento,
                                pvtefAreaEdificar: result.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefAreaEdificar,
                                areaEdificada: result.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.areaEdificada,
                                pvtefEmpregosAtual: result.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosAtual,
                                pvtefEmpregosGerar: result.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosGerar

                            });
                        }
                        if (result.cartaConsulta !== null) {
                            setPvtefDataResolucao(formatarDataString(result.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefDataResolucao));
                            setPvtefDataDodf(formatarDataString(result.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefDataDodf));
                        }
                    });
                }
            });
        }
    }, []);

    function formatarDataString(d) {
        const data = d.split("/");
        if (data.length === 3) {
            return new Date(data[2], (data[1] - 1), data[0]);
        }
        return '';
    }

    function sair() {
        if (id) history.replace("/gerenciaProcesso/processo/" + id + "?panel=projeto");
        else history.replace("/gerenciaProcesso/cadastrarProcesso/?panel=projeto");
    }

    const handleChangeInputForm = (event) => {
        setProcesso({
            ...processo,
            projetoViabilidadeTecnicaEconomicoFinanceiraPvtef: {
                ...processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef,
                [event.target.name]: event.target.value,
            }
        });
        setDisabledSalvar(false);
    };

    const handleChangeInputFormDialog = (event) => {
        setCampoDialog({
            ...campoDialog,
            [event.target.name]: event.target.value,
        });
        setDisabledSalvar(false);
    };

    const handleChangeInputFormDadosAlterados = (event) => {
        setDadosAlterados({
            ...dadosAlterados,
            [event.target.name]: event.target.value,
        });
        setDisabledSalvar(false);
    };

    const handleChangeInputFormTipoProj = (event) => {
        setProcesso({ ...processo, [event.target.name]: event.target.value });
        setDisabledSalvar(false);
    };

    function handleSalvar() {
        if (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef) {
            if (
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.valorInvestimento !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento) ||
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.pvtefAreaEdificar !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefAreaEdificar) ||
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.areaEdificada !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.areaEdificada) ||
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.pvtefEmpregosAtual !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosAtual) ||
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.pvtefEmpregosGerar !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosGerar)
            ) {
                setOpenDialog(true);
            }
            else {
                salvar()
            }
        } else {
            salvar()
        }
    }

    function transformaDt(valor) {
        if (valor) {
            return FormataDataSemHora(new Date(valor));
        }
        return "";
    }

    function salvar() {
        let arrAlteArea = [];
        let alteraMetaEmprego = [];

        if (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef) {
            if (
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.valorInvestimento !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento) ||
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.pvtefAreaEdificar !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefAreaEdificar) ||
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.areaEdificada !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.areaEdificada) ||
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.pvtefEmpregosAtual !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosAtual) ||
                (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                    dadosAlterados.pvtefEmpregosGerar !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosGerar)
            ) {
                if (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.altercaoArea) {
                    arrAlteArea = processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.altercaoArea;
                }
                if (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.alteracaoMeta) {
                    alteraMetaEmprego = processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.alteracaoMeta;
                }
                if (
                    (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                        dadosAlterados.valorInvestimento !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento) ||
                    (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                        dadosAlterados.pvtefAreaEdificar !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefAreaEdificar) ||
                    (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                        dadosAlterados.areaEdificada !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.areaEdificada)
                ) {
                    arrAlteArea.push({
                        resolucao: campoDialog.resolucao,
                        areaDataResolucao: campoDialog.areaDataResolucao,
                        valorInvestido: processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento,
                        areaEdificar: processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefAreaEdificar,
                        areaEdificada: processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.areaEdificada,
                    });
                }
                if (
                    (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                        dadosAlterados.pvtefEmpregosAtual !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosAtual) ||
                    (processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.valorInvestimento &&
                        dadosAlterados.pvtefEmpregosGerar !== processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosGerar)
                ) {
                    alteraMetaEmprego.push({
                        resolucao: campoDialog.resolucao,
                        areaDataResolucao: campoDialog.areaDataResolucao,
                        pvtefEmpregosAtual: processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosAtual,
                        pvtefEmpregosGerar: processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosGerar,
                        pvtefEmpregosTotal: parseInt(processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosAtual) + parseInt(processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefEmpregosGerar)
                    })
                }
            }
        }
        setOpenDialog(false)

        const dados = {
            processo: {
                ...processo,
                projetoViabilidadeTecnicaEconomicoFinanceiraPvtef: {
                    ...processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef,
                    ...dadosAlterados,
                    pvtefDataResolucao: pvtefDataResolucao ? FormataData(pvtefDataResolucao) : "",
                    pvtefDataDodf: pvtefDataDodf ? FormataData(pvtefDataDodf) : "",
                    altercaoArea: arrAlteArea,
                    alteracaoMeta: alteraMetaEmprego
                }
            }
        };
        setDisabledSalvar(true);
        // return;
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=projeto");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })

    }

    const handleChangeInputFormPvtefDataResolucao = (date) => {
        setPvtefDataResolucao(date);
        setDisabledSalvar(false);
    }

    const handleChangeInputFormPvtefDataDodf = (date) => {
        setPvtefDataDodf(date);
        setDisabledSalvar(false);
    }

    const handleChangeInputFormAlteraArea = (date) => {
        // setAreaDataResolucao(date)
        setCampoDialog({
            ...campoDialog,
            areaDataResolucao: date
        });
        setDisabledSalvar(false);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='md'>
                <Card style={{ marginTop: '5px' }}>
                    <CardHeader title="Dados Projeto" />
                    <CardContent>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={8}>
                                <FormControl variant="outlined" fullWidth disabled={false}>
                                    <InputLabel id="tipoProjeto" >Tipo Projeto</InputLabel>
                                    <Select
                                        id="tipoProjeto"
                                        name='tipoProjeto'
                                        label="Tipo Projeto"
                                        placceholder="Tipo Projeto"
                                        onChange={handleChangeInputFormTipoProj}
                                        value={processo && processo.tipoProjeto || ''}
                                    >
                                        {
                                            tipoProjetos && tipoProjetos.map((tp, i) => (
                                                <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                {processo && processo.dadosEmpresa && processo.dadosEmpresa.nProcesso &&
                                    <EtapaProcessoComp nProcesso={processo.dadosEmpresa.nProcesso} indice={5} />}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefResolucaoN || ''}
                                    onChange={handleChangeInputForm}
                                    fullWidth
                                    label="Resolução Nº"
                                    id="pvtefResolucaoN"
                                    name="pvtefResolucaoN"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>

                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="pvtefDataResolucao"
                                    label="Data Resolução"
                                    format="dd/MM/yyyy"
                                    name="pvtefDataResolucao"
                                    value={pvtefDataResolucao || null}
                                    onChange={handleChangeInputFormPvtefDataResolucao}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.pvtefPublicacaoDodf || ''}
                                    onChange={handleChangeInputForm}
                                    fullWidth
                                    label="Publicação DODF"
                                    id="pvtefPublicacaoDodf"
                                    name="pvtefPublicacaoDodf"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="pvtefDataDodf"
                                    label="Data Publicação"
                                    format="dd/MM/yyyy"
                                    name="pvtefDataDodf"
                                    value={pvtefDataDodf || null}

                                    onChange={handleChangeInputFormPvtefDataDodf}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card style={{ marginTop: '5px' }}>
                    <CardHeader title="Dados Área" />
                    <CardContent>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={4}>
                                <TextField
                                    value={dadosAlterados && dadosAlterados.valorInvestimento || ''}
                                    onChange={handleChangeInputFormDadosAlterados}
                                    fullWidth
                                    label="Valor Investimento"
                                    id="valorInvestimento"
                                    name="valorInvestimento"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        inputComponent: FormataCampo
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={dadosAlterados && dadosAlterados.pvtefAreaEdificar || ''}
                                    onChange={handleChangeInputFormDadosAlterados}
                                    fullWidth
                                    label="Área a Edificar (m²)"
                                    id="pvtefAreaEdificar"
                                    name="pvtefAreaEdificar"
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">m²</InputAdornment>,
                                        inputComponent: FormataCampo
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={dadosAlterados && dadosAlterados.areaEdificada || ''}
                                    onChange={handleChangeInputFormDadosAlterados}
                                    fullWidth
                                    label="Área Edificada (m²)"
                                    id="areaEdificada"
                                    name="areaEdificada"
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="start">m²</InputAdornment>,
                                        inputComponent: FormataCampo
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.atividade || ''}
                                    onChange={handleChangeInputForm}
                                    fullWidth
                                    label="Atividade"
                                    id="atividade"
                                    name="atividade"
                                    variant="outlined"
                                    multiline={true}
                                    rows={5}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h3>Alteração de Área</h3>
                                <GenericTable
                                    disableSearch={true}
                                    dados={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.altercaoArea || []}
                                    colunas={[
                                        { nome: 'Resolução', campo: 'resolucao' },
                                        { nome: 'Data Resolução', campo: 'areaDataResolucao', transformacao: transformaDt },
                                        { nome: 'Valor Investido', campo: 'valorInvestido' },
                                        { nome: 'Área a Edificar (m²)', campo: 'areaEdificar' },
                                        { nome: 'Área Edificada (m²)', campo: 'areaEdificada' },
                                    ]} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card style={{ marginTop: '5px' }}>
                    <CardHeader title="Empregos" />
                    <CardContent>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={4}>
                                <TextField
                                    value={dadosAlterados && dadosAlterados.pvtefEmpregosAtual || ''}
                                    onChange={handleChangeInputFormDadosAlterados}
                                    fullWidth
                                    label="Empregos Atuais"
                                    id="pvtefEmpregosAtual"
                                    name="pvtefEmpregosAtual"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={dadosAlterados && dadosAlterados.pvtefEmpregosGerar || ''}
                                    onChange={handleChangeInputFormDadosAlterados}
                                    fullWidth
                                    label="Empregos a Gerar"
                                    id="pvtefEmpregosGerar"
                                    name="pvtefEmpregosGerar"
                                    variant="outlined"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={dadosAlterados && parseInt(dadosAlterados.pvtefEmpregosGerar) + parseInt(dadosAlterados.pvtefEmpregosAtual) || ''}
                                    onChange={handleChangeInputForm}
                                    fullWidth
                                    label="Total"
                                    id="pvtefEmpregosTotal"
                                    name="pvtefEmpregosTotal"
                                    variant="outlined"
                                    disabled={true}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <h3>Alteração meta emprego</h3>
                                <GenericTable
                                    disableSearch={true}
                                    dados={processo && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef && processo.projetoViabilidadeTecnicaEconomicoFinanceiraPvtef.alteracaoMeta || []}
                                    colunas={[
                                        { nome: 'Resolução', campo: 'resolucao' },
                                        { nome: 'Data Resolução', campo: 'areaDataResolucao', transformacao: transformaDt },
                                        { nome: 'Empregos Atuais', campo: 'pvtefEmpregosAtual' },
                                        { nome: 'Empregos a Gerar', campo: 'pvtefEmpregosGerar' },
                                        { nome: 'Total Empregos', campo: 'pvtefEmpregosTotal' },
                                    ]} />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                        Sair
                    </Button>
                    <Button variant="contained" onClick={() => handleSalvar()} color="primary" disabled={disabledSalvar} >
                        Salvar
                    </Button>
                </Grid>




                <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth >
                    <DialogTitle id="form-dialog-title">Preencha os campos</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1} className={classes.root}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="resolucao"
                                    name="resolucao"
                                    placeholder="Resolução"
                                    rows={6}
                                    value={campoDialog && campoDialog.resolucao || ''}
                                    onChange={handleChangeInputFormDialog}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="areaDataResolucao"
                                    label="Data Resolução"
                                    format="dd/MM/yyyy"
                                    name="areaDataResolucao"
                                    value={campoDialog && campoDialog.areaDataResolucao || null}
                                    onChange={handleChangeInputFormAlteraArea}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => salvar()} >
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </MuiPickersUtilsProvider>
    );
}