import React, { useState } from 'react';
import { Button, Grid, Paper, TextField }
    from '@material-ui/core';
import MotivoDialog from "../../util/MotivoDialog";
import ConfirmDialog from "../../util/ConfirmDialog";
import {useStyles} from "../../constants";

export default function TemplateForm() {
    const classes = useStyles();
    
    const [showMotivoDialog, setShowMotivoDialog] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [values, setValues] = useState({
        campo1: 'Valor campo1',
        campo2: 'Valor campo2',
        campo3: 'Valor campo3'
    });
    const [error, setError] = useState({
        campo1: { erro: false, msg: '' },
        campo2: { erro: false, msg: '' },
        campo3: { erro: false, msg: '' },
    })

    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };
    function confirmaAprovacao() {
        setError(resetErro());
    }
    function revogar() {
        
    }
    const invalidar = () => {
        let e = resetErro();

        e.campo2.erro = true;
        e.campo2.msg = "Campo esta inválido";
        setError(e);
    }
    function resetErro() {
        return {
            campo1: {
                erro: false,
                msg: ''
            },
            campo2: {
                erro: false,
                msg: ''
            },
            campo3: {
                erro: false,
                msg: ''
            }
        }
    }

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <TextField
                        value={values.campo1}
                        fullWidth
                        label="Campo1"
                        id="campo1"
                        name="campo1"
                        error={error.campo1.erro}
                        helperText={error.campo1.msg}
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={values.campo1}
                        fullWidth
                        label="Campo 2"
                        id="campo2"
                        name="campo2"
                        error={error.campo2.erro}
                        helperText={error.campo2.msg}
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        value={values.campo2}
                        fullWidth
                        label="Campo3"
                        id="campo3"
                        name="campo3"
                        error={error.campo3.erro}
                        helperText={error.campo3.msg}
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" href={"/template"} rel="noopener noreferrer">
                        Sair
                        </Button>

                    <Button variant="contained" color="primary" onClick={() => setShowMotivoDialog(true)}>
                        Reprovar
                    </Button>
                    <Button variant="contained" color="primary" onClick={invalidar}>
                        Invalidar
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)}>
                        Aprovar
                    </Button>

                </Grid>

                <MotivoDialog open={showMotivoDialog} setOpen={setShowMotivoDialog} acao={revogar} />
                <ConfirmDialog
                    open={showConfirm}
                    setOpen={setShowConfirm}
                    titulo={'Confirmação'}
                    msg='Após a confirmação da solicitação o cliente receberá um email com as informações com acessos para prosseguir a solicitação.'
                    exec={confirmaAprovacao}
                />
            </Grid>
        </Paper>
    );
}
