import React, { useState } from 'react';
import {
    Button, Grid, Paper, TextField, Typography, Accordion, AccordionSummary, AccordionDetails,
    FormLabel, RadioGroup, FormControl, FormControlLabel, Radio, InputAdornment, Checkbox, FormGroup
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormataMoeda, useStyles } from "../../../constants";
import GenericTable from '../../template/GenericTable';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getMonth, getYear } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import NumberFormat from 'react-number-format';

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}


export default function FaturamentoImpostoForm(props) {
    const classes = useStyles();
    const { value, setValue, disable } = props;
    const [values, setValues] = useState({});
    const [dataMA, setDataMA] = useState();
    const [error, setError] = useState(resetErro());

    const handleChangeRegime = (event) => {
        const pasaDado = {
            ...value.faturamentoImposto,
            regimeTributacao: event.target.value
        };
        setValue({
            ...value,
            faturamentoImposto: pasaDado
        });
    };
    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };
    const handleActionSolicitarClick = (index, acao, dado) => {
        let arr = [...value.faturamentoImposto.valoresTable];
        arr.splice(index, 1);
        const pasaDado = {
            ...value.faturamentoImposto,
            valoresTable: arr
        };
        setValue({
            ...value,
            faturamentoImposto: pasaDado,
        });
    }
    const incluirAltera = () => {
        let arr = [];
        const dadosTab = {
            ...values,
            data: dataAjusteMA(dataMA)
        };
        let ok = true
        if (value.faturamentoImposto) {
            for (let x in value.faturamentoImposto.valoresTable) {
                const d = value.faturamentoImposto.valoresTable[x];
                if (d.data === dataAjusteMA(dataMA)) {
                    arr[x] = dadosTab;
                    ok = false
                } else {
                    arr.push(d);
                }
            }
        }
        if (ok) arr.push(dadosTab);
        const pasaDado = {
            ...value.faturamentoImposto,
            valoresTable: arr
        };
        setValue({
            ...value,
            faturamentoImposto: pasaDado,
        });
        setValues({});
        setDataMA();
    }
    function dataAjusteMA(d) {
        if (d) {
            const mes = getMonth(d);
            const ano = getYear(d);
            return (mes + 1) + "/" + ano;
        }
        return '';
    }
    const handleDateChangedataMA = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        const d = new Date();
        if (date >= d) {
            setError({
                data: { erro: true, msg: 'A data deve ser inferior a data atual' }
            });
        }
        setDataMA(date);
    }
    function resetErro() {
        return {
            data: { erro: false, msg: '' },
        }
    }
    function podeInclirAlterar() {
        if (dataMA && values.faturamento && values.icms && values.iss) return false
        return true;
    }
    const handleCheck = (event) => {
        const pasaDado = {
            ...value.faturamentoImposto,
            [event.target.name]: event.target.checked
        };
        setValue({
            ...value,
            faturamentoImposto: pasaDado
        });
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Paper >
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>FATURAMENTO E IMPOSTOS DA EMPRESA (ULTIMOS 12 MESES)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3} >
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Tributação de acordo com a atividade económico:</FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={disable}
                                                    checked={value.faturamentoImposto && value.faturamentoImposto.icms}
                                                    onChange={handleCheck}
                                                    name="icms"
                                                    color="primary"
                                                />
                                            }
                                            label="ICMS"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={disable}
                                                    checked={value.faturamentoImposto && value.faturamentoImposto.iss}
                                                    onChange={handleCheck}
                                                    name="iss"
                                                    color="primary"
                                                />
                                            }
                                            label="ISS"
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Regime de Tributação:</FormLabel>
                                    <RadioGroup row value={value.faturamentoImposto ? (value.faturamentoImposto.regimeTributacao || '') : ''} onChange={handleChangeRegime}>
                                        <FormControlLabel value="sistematicoApuracao" control={<Radio disabled={disable} />} label="Sistemática Normal de Apuração" />
                                        <FormControlLabel value="simplesNacional" control={<Radio disabled={disable} />} label="Simples Nacional" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>VALORES</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={1} >
                            {!disable && <>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        views={["year", "month"]}
                                        disableToolbar
                                        error={error.data.erro}
                                        helperText={error.data.msg}
                                        variant="inline"
                                        id="dataMA"
                                        label="MÊS / ANO"
                                        format="MM/yyyy"
                                        value={dataMA || null}
                                        onChange={handleDateChangedataMA}
                                        fullWidth
                                        KeyboardButtonProps={true}
                                        inputVariant="outlined"
                                        invalidDateMessage="Formato inválido"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={values.faturamento || ''}
                                        fullWidth
                                        label="FATURAMENTO"
                                        id="faturamento"
                                        name="faturamento"
                                        InputProps={{
                                            readOnly: false,
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                            inputComponent: MonetarioField
                                        }}
                                        onChange={handleChangeInputForm}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid container className={classes.btns}>
                                    <Typography variant="h6" gutterBottom>TRIBUTOS</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={values.icms || ''}
                                        fullWidth
                                        label="ICMS ou Simples"
                                        id="icms"
                                        name="icms"
                                        InputProps={{
                                            readOnly: false,
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                            inputComponent: MonetarioField
                                        }}
                                        onChange={handleChangeInputForm}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        value={values.iss || ''}
                                        fullWidth
                                        label="ISS ou Simples"
                                        id="iss"
                                        name="iss"
                                        InputProps={{
                                            readOnly: false,
                                            endAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                            inputComponent: MonetarioField
                                        }}
                                        onChange={handleChangeInputForm}
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid container justify="flex-end" className={classes.btns}>
                                    <Button variant="contained" color="primary" onClick={incluirAltera} disabled={podeInclirAlterar()} >
                                        Incluir/ Alterar
                                    </Button>
                                </Grid>
                                <Grid container className={classes.btns}>
                                    <Typography variant="h6" gutterBottom>Valores em reais R$ 1,00</Typography>
                                </Grid>
                            </>}
                            <GenericTable
                                actionClick={handleActionSolicitarClick}
                                disableSearch={true}
                                dados={value.faturamentoImposto ? (value.faturamentoImposto.valoresTable || []) : []}
                                options={['Excluir']}
                                colunas={[
                                    { nome: 'MÊS/ANO', campo: 'data' },
                                    { nome: 'FATURAMENTO (R$)', campo: 'faturamento', align: "right" },
                                    { nome: 'TRIBUTOS ICMS ou Simples', campo: 'icms', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'TRIBUTOS ISS ou Simples', campo: 'iss', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Ação', campo: 'action' }
                                ]} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Paper >
        </MuiPickersUtilsProvider>
    );
}
