import React, { useEffect, useState } from 'react';
import {
    Button, Grid, Paper, Container, TextField
}
    from '@material-ui/core';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, FormataData } from "../../../constants"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Service from "../../../services/Service";
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import EtapaProcessoComp from './EtapaProcessoComp';

export default function CadastroIndicarAreaForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;
    const [id, setId] = useState();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [indicacaoAreaDataDodf, setIndicacaoAreaDataDodf] = useState();
    const [indicacaoAreaDataEdital, setIndicacaoAreaDataEdital] = useState();
    const [value, setValue] = useState({ indicacaoArea: {} });
    useEffect(() => {
        setTitulo("Cadastrar Indicação de Área");
        if (param.get("id")) {
            setId(param.get("id"));
            fetch(`/api/processoProdf/${param.get("id")}`).then((r) => {
                setWait(false);
                if (!r.ok) {
                    r.json().then(j => setErro(j));

                } else {
                    r.json().then(result => {
                        setValue(result);
                        if (result.indicacaoArea !== null) {
                            setIndicacaoAreaDataDodf(formatarDataString(result.indicacaoArea.indicacaoAreaDataDodf));
                            setIndicacaoAreaDataEdital(formatarDataString(result.indicacaoArea.indicacaoAreaDataEdital ));
                        }
                    });
                }
            });
        }
    }, []);
    function formatarDataString(d) {
        const data = d.split("/");
        if(data.length === 3){
            return new Date(data[2],(data[1]-1),data[0]);
        }
        return '';
    }

    function sair() {
        //history.replace("/gerenciaProcesso/cadastrarProcesso?id=123456789abcd&panel=cartaConsulta");
        if (id) history.replace("/gerenciaProcesso/processo/" + id + "?panel=indicacaoArea");
        else history.replace("/gerenciaProcesso/cadastrarProcesso/?panel=indicacaoArea");
    }

    function salvar() {
        let dado = {
            processo: {
                ...value,
                indicacaoArea:{
                    ...value.indicacaoArea,
                    indicacaoAreaDataEdital: indicacaoAreaDataEdital ? FormataData(indicacaoAreaDataEdital) : "",
                    indicacaoAreaDataDodf: indicacaoAreaDataDodf ? FormataData(indicacaoAreaDataDodf) : ""
                }
            }
        };
        submitForm(dado);
    }

    function submitForm(dado) {

        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dado).then(json => {
            setWait(false);
            history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=indicacaoArea");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            indicacaoArea: {
                ...value.indicacaoArea,
                [event.target.name]: event.target.value
            }
        });
    };

    const handleChangeInputFormAreaDataDodf = (date) => {
        setIndicacaoAreaDataDodf(date)
    }

    const handleChangeInputFormDataEdital = (date) => {
        setIndicacaoAreaDataEdital(date)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='md'>
                <Paper >
                    <Grid>

                        <Grid container spacing={1} className={classes.root}>
                            <Grid item xs={6}>
                                <TextField
                                    value={value && value.indicacaoArea && value.indicacaoArea.indicacaoAreaEditalN || ''}
                                    fullWidth
                                    label="Edital Nº"
                                    id="indicacaoAreaEditalN"
                                    name="indicacaoAreaEditalN"
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    InputProps={{
                                        readOnly: false,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="indicacaoAreaDataEdital"
                                    label="Data Edital"
                                    format="dd/MM/yyyy"
                                    name="indicacaoAreaDataEdital"
                                    value={indicacaoAreaDataEdital || null}
                                    onChange={handleChangeInputFormDataEdital}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={value && value.indicacaoArea && value.indicacaoArea.indicacaoAreaPublicacaoDodf || ''}
                                    fullWidth
                                    label="Publicação DODF"
                                    id="indicacaoAreaPublicacaoDodf"
                                    name="indicacaoAreaPublicacaoDodf"
                                    aria-describedby="nova-helper-text"
                                    onChange={handleChangeInputForm}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="indicacaoAreaDataDodf"
                                    label="Data Publicação DODF"
                                    format="dd/MM/yyyy"
                                    name="indicacaoAreaDataDodf"
                                    value={indicacaoAreaDataDodf || null}
                                    onChange={handleChangeInputFormAreaDataDodf}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {value && value.dadosEmpresa && value.dadosEmpresa.nProcesso &&
                                    <EtapaProcessoComp nProcesso={value.dadosEmpresa.nProcesso} indice={4} />}
                            </Grid>

                        </Grid>


                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                                Sair
                            </Button>
                            <Button variant="contained" onClick={() => salvar()} color="primary">
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </MuiPickersUtilsProvider>
    );
}