import moment from 'moment';
import {fluxoDespacho} from '../constants';
import {useFluxoNotificacaoComplementoComplexo} from "../server/UseFluxo";

const Filtro = {
    
    fromDateString: function (dataString, formato) {
        if (!formato) formato = 'DD/MM/YYYY HH:mm';
        if (!!dataString) return moment(new Date(dataString)).format(formato);
        return '';
    },
    fromRecursoStatus: function (recursoStatus) {
        switch (recursoStatus) {    
            case 'RASCUNHO':
                return "Rascunho";
            case 'EM_ANALISE':
                return "Em análise";
            case 'PENDENTE':
                return "Pendente";
            case 'REPROVADO':
                return "Reprovado";
            case 'DEFERIDO':
                return "Deferido";
            case 'INDEFERIDO':
                return "Indeferido";
            default:
                return recursoStatus;
        }
    },
    fromNotificacaoStatus: function (row, fluxo) {
        const lastFluxo = row.fluxoPath[row.fluxoPath.length -1];
        const item = fluxo.nodes.find(f => f.node === lastFluxo);
        if( item ){
            return item.nome
        }
        return "Em Analise";
    },
    fromComplementoInformacaoStatus: function (complementoInformacaoStatus) {
        switch (complementoInformacaoStatus) {
            case 'RASCUNHO':
                return "Rascunho";
            case 'EM_VALIDACAO':
                return "Em validação";
            case 'PENDENTE':
                return "Pendente";
            case 'RESPONDIDO':
                return "Respondido";
            case 'NAO_RESPONDIDO':
                return "Não Respondido";
            case 'PRAZO_VENCIDO':
                return "Prazo Vencido";
            default:
                return complementoInformacaoStatus;
        }
    }
};

export default Filtro;
