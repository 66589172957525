import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, Container,
    InputLabel, Typography, AccordionDetails
} from '@material-ui/core';
import { useCurrentUser } from "../../server/UseCurrentUser";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from "../../constants";
import { deParaTipo, deParaSituacao } from "./ChamadosTable";

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import RegistrarChamadaForm from './RegistrarChamadaForm';
import ReCAPTCHA from "react-google-recaptcha";
import MaskedInput from 'react-text-mask';
import { ptBR } from 'date-fns/locale';


function ProtocoloMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

export default function ConsultarChamadoForm(props) {
    const classes = useStyles();
    const { setMensagem, setWait, disable, setErro } = props;
    const [value, setValue] = useState({});
    const history = useHistory();
    const [error, setError] = useState(resetErro());
    const [profile, hasLoad] = useCurrentUser();
    const [captcha, setCaptcha] = useState(null);
    const [captchaKeySite, setCaptchaKeySite] = useState();

    useEffect(() => {
        if (hasLoad) {
            if (!profile) {
                buscaCaptchaKeySite();
            }
        }
    }, [])
    function buscaCaptchaKeySite() {
        fetch('/api/captcha')
            .then(ret => ret.text())
            .then(key => setCaptchaKeySite(key))
    }
    function resetErro() {
        return {
            numeroChamado: { erro: false, msg: '' },
            email: { erro: false, msg: '' },
        }
    }
    function isNumber(n) { return /^[0-9]*\-?[0-9]*$/.test(n); }

    const handleChangeInputForm = (event) => {
        const c = event.target.value.trim();
        if (event.target.name === 'numeroChamado') {
            if (!isNumber(c)) return;
        }
        setValue({
            ...value,
            [event.target.name]: c,
        });

    };

    function sair() {
        history.push('/home')
    }

    function buscarSuporte() {
        const numeroChamado = value.numeroChamado.trim();
        const email = value.email.trim();
        const dado = {
            numeroChamado: numeroChamado,
            email: email
        }
        fetch("/api/suporte/filtro", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(res => res.json()).then((result) => {
            setError({
                numeroChamado: { erro: false, msg: '' },
                email: { erro: false, msg: '' },
            });
            if (dado.numeroChamado != result.numeroChamada) {
                setError({
                    numeroChamado: { erro: true, msg: 'Numero de protocolo invalido ou ' },
                    email: { erro: true, msg: 'Email invalido' },
                });
                setWait(false);
                return;
            }
            let histor = [];
            for (let x in result.historico) {
                const hist = result.historico[x];
                if (hist.tipo === "CONCLUIDO") {
                    histor.soluca = hist.texto;
                    histor.data = hist.data;
                }
            }
            setWait(false);
            setValue({
                ...result,
                solucao: histor.soluca ? histor.soluca : "Não Concluído",
                dataSolicitacao: (result.historico[0].data ? new Date(result.historico[0].data) : null),
                dataResolucao: (histor.data ? new Date(histor.data) : null)
            })
        });
    }
    function onChange(value) {
        setCaptcha(value);
    }
    function disableButon() {
        if (!value || !value.email || !value.numeroChamado) return true;
        let disable = true;
        disable = captcha === null;
        return disable;
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='lg'>
                <Accordion defaultExpanded={true} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography variant="h5" gutterBottom>Registrar chamado</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <RegistrarChamadaForm setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
                    </AccordionDetails>

                </Accordion>
                <Accordion defaultExpanded={false} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography variant="h5" gutterBottom>Consultar Chamado</Typography>
                    </AccordionSummary>
                    <Paper >

                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={12} >


                                <Grid item xs={12} >

                                    <Typography variant="h6" gutterBottom>Dados da Busca</Typography>

                                </Grid>
                                <Grid container spacing={1} className={classes.root}>

                                    <Grid item xs={6}>
                                        <TextField
                                            value={value.numeroChamado || ''}
                                            error={error.numeroChamado.erro}
                                            helperText={error.numeroChamado.msg}
                                            fullWidth
                                            label="Número Chamado"
                                            onChange={handleChangeInputForm}
                                            id="numeroChamado"
                                            name="numeroChamado"
                                            aria-describedby="nova-helper-text"
                                            variant="outlined"
                                            InputProps={{
                                                inputComponent: ProtocoloMask

                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <TextField
                                            value={value.email || ''}
                                            error={error.email.erro}
                                            helperText={error.email.msg}
                                            fullWidth
                                            label="E-mail"
                                            onChange={handleChangeInputForm}
                                            id="email"
                                            name="email"
                                            aria-describedby="nova-helper-text"
                                            variant="outlined"
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        {captchaKeySite &&
                                            <ReCAPTCHA
                                                sitekey={captchaKeySite}
                                                onChange={onChange}
                                            />
                                        }
                                    </Grid>
                                    <Grid container justify="flex-end" className={classes.btns}>
                                        <Button variant="contained" color="primary"
                                            onClick={() => buscarSuporte()}
                                            rel="noopener noreferrer"
                                            disabled={disableButon()}
                                        >
                                            Pesquisar
                                        </Button>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Paper>
                    <br />
                    <Paper >

                        <Grid container spacing={1} className={classes.root}>

                            <Grid item xs={12} >
                                <Grid item xs={12}>
                                    <Typography variant="h6" gutterBottom>Resultado da Busca</Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={1} className={classes.root}>
                                <Grid item xs={4}>
                                    <TextField
                                        value={value.numeroChamada || ''}
                                        fullWidth
                                        label="Número Chamado"
                                        id="numeroChamado"
                                        name="numeroChamado"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        //disabled={disable && disable === true}
                                        value={deParaTipo(value.tipo || '')}
                                        fullWidth
                                        label="Tipo Solicitação"
                                        id="tipoSolicitacao"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: disable && disable === true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        //disabled={disable && disable === true}
                                        value={deParaSituacao(value.situacao || '')}
                                        fullWidth
                                        label="Situação"
                                        id="situacao"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: disable && disable === true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        multiline
                                        rows={6}
                                        value={ value.descricao || ''}
                                        fullWidth
                                        label="Descrição da Ocorrência"
                                        id="descricao"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        id="dataSolicitacao"
                                        label="Data solicitação"
                                        format="dd/MM/yyyy"
                                        value={value.dataSolicitacao || null}
                                        fullWidth
                                        KeyboardButtonProps={true}
                                        inputVariant="outlined"
                                        disabled
                                        invalidDateMessage="Formato inválido"
                                    // InputProps={{
                                    //     readOnly: disable && disable === true,
                                    // }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        id="dataResolucao"
                                        label="Data resolução"
                                        format="dd/MM/yyyy"
                                        value={value.dataResolucao || null}
                                        fullWidth
                                        KeyboardButtonProps={true}
                                        inputVariant="outlined"
                                        disabled
                                        invalidDateMessage="Formato inválido"
                                    // InputProps={{
                                    //     readOnly: disable && disable === true,
                                    // }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputLabel htmlFor="solucao">Solução</InputLabel>
                                    <Paper className={classes.paperNormal} variant="outlined">
                                        <Typography id='solucao' variant="body2" color="textSecondary" component="p">
                                            {
                                                value && <span dangerouslySetInnerHTML={{
                                                    __html: value.solucao
                                                }}></span>
                                            }
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>




                        </Grid>

                    </Paper>
                </Accordion>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary"
                        onClick={sair}
                        rel="noopener noreferrer"
                    >
                        Sair
                                </Button>
                </Grid>

            </Container>
        </MuiPickersUtilsProvider>
    );
}
