import React, { useEffect, useState } from 'react';
import {
    Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../../../../constants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import Download from '../../../../util/Download';
import CheckIcon from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import HistoricoTable from '../../unate/HistoricoTable';

export default function LevantamentoFatosForm(props) {
    const { value, anexos, setWait, setMensagem, setAbilitaSalvar, abilitaSalvar, setTxtHistorico,
        textoRelato, disabled, textoLegislacao, txtRelato, setTxtRelato,
        txtLegislacao, setTxtLegislacao, txtViabFin, setTxtViabFin, 
        txtGerarEmpreg, setTxtGerarEmpreg, txtConclusao, setTxtConclusao,txtDocApresentada, setDocApresentada } = props;
    const classes = useStyles();
    // const [txtConclusao, setTxtConclusao] = useState('');
    // const [legislacao, setLegislacao] = useState(textoLegislacao);


    // function setMensagemIntermedio(alerta) {
    //     let a;
    //     if (typeof alerta === 'object') {
    //         a = { ...alerta };
    //         if (!a.tipo) {
    //             console.log("buscaIntegracaoSei OK\n", alerta)
    //         }
    //     } else {
    //         setMensagem(alerta);
    //     }
    // }

    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>1.Breve Relato</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                disabled={disabled}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={txtRelato}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "200px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    setTxtRelato(editor.getData());
                                    // setTxtHistorico(editor.getData());
                                    if (abilitaSalvar) {
                                        let array = [...abilitaSalvar]
                                        if (array && array.length !== 0 && !(array.indexOf("breve_relato") > -1)) {
                                            array.push("breve_relato")
                                        }
                                        setAbilitaSalvar(array);
                                    } else {
                                        setAbilitaSalvar(["breve_relato"]);
                                    }

                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>2. Legislação</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                disabled={disabled}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={txtLegislacao}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "200px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    setTxtLegislacao(editor.getData());
                                    // setTxtHistorico(editor.getData());  txtLegislacao, setTxtLegislacao
                                    if (abilitaSalvar) {
                                        let array = [...abilitaSalvar]
                                        if (array && array.length !== 0 && !(array.indexOf("legislacao") > -1)) {
                                            array.push("legislacao")
                                        }
                                        setAbilitaSalvar(array);
                                    } else {
                                        setAbilitaSalvar(["legislacao"]);
                                    }

                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>3. Documentação Apresentada</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                        <CKEditor
                                editor={ClassicEditor}
                                disabled={disabled}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={txtDocApresentada}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "200px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    setDocApresentada(editor.getData());
                                    // setTxtHistorico(editor.getData());  txtLegislacao, setTxtLegislacao
                                    if (abilitaSalvar) {
                                        let array = [...abilitaSalvar]
                                        if (array && array.length !== 0 && !(array.indexOf("gerar_emprego") > -1)) {
                                            array.push("gerar_emprego")
                                        }
                                        setAbilitaSalvar(array);
                                    } else {
                                        setAbilitaSalvar(["gerar_emprego"]);
                                    }

                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>4. Geração de Emprego</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                disabled={disabled}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={txtGerarEmpreg}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "200px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    setTxtGerarEmpreg(editor.getData());
                                    // setTxtHistorico(editor.getData());  txtLegislacao, setTxtLegislacao
                                    if (abilitaSalvar) {
                                        let array = [...abilitaSalvar]
                                        if (array && array.length !== 0 && !(array.indexOf("gerar_emprego") > -1)) {
                                            array.push("gerar_emprego")
                                        }
                                        setAbilitaSalvar(array);
                                    } else {
                                        setAbilitaSalvar(["gerar_emprego"]);
                                    }

                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>5. Viabilidade Financeira e Economica da Empresa</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                disabled={disabled}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={txtViabFin}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "200px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    setTxtViabFin(editor.getData());
                                    // setTxtHistorico(editor.getData());  txtLegislacao, setTxtLegislacao
                                    if (abilitaSalvar) {
                                        let array = [...abilitaSalvar]
                                        if (array && array.length !== 0 && !(array.indexOf("viabilibade_finaceira") > -1)) {
                                            array.push("viabilibade_finaceira")
                                        }
                                        setAbilitaSalvar(array);
                                    } else {
                                        setAbilitaSalvar(["viabilibade_finaceira"]);
                                    }

                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>6. Conclusão</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <CKEditor
                                editor={ClassicEditor}
                                disabled={disabled}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}
                                data={txtConclusao}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "200px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    setTxtConclusao(editor.getData());
                                    // setTxtHistorico(editor.getData());  txtConclusao, setTxtConclusao
                                    if (abilitaSalvar) {
                                        let array = [...abilitaSalvar]
                                        if (array && array.length !== 0 && !(array.indexOf("conclusao") > -1)) {
                                            array.push("conclusao")
                                        }
                                        setAbilitaSalvar(array);
                                    } else {
                                        setAbilitaSalvar(["conclusao"]);
                                    }

                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {/* <Accordion defaultExpanded={false}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>6. Histórico</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {value && value.historico &&
                        <HistoricoTable dados={value.historico} protocolo={value.protocolo} setWait={setWait} setMensagem={setMensagemIntermedio} />
                    }
                </AccordionDetails>
            </Accordion> */}
        </Paper>
    )

}