import React, { useEffect, useState } from 'react';
import {
    Grid, Button, FormControlLabel, Typography, RadioGroup, Radio, Paper, makeStyles, FormControl, MenuItem, Select
} from '@material-ui/core';
import { useStyles, FormataData, FormataDataSemHora } from "../../constants";
import GenericTransferList from '../template/GenericTransferList';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import { ptBR } from 'date-fns/locale';

// const useStylesLocal = makeStyles((theme) => ({
//     heading: {
//         fontSize: theme.typography.pxToRem(18),
//         fontWeight: theme.typography.fontWeightBold, //.fontWeightRegular,
//         margin: theme.spacing(2, 0),
//     },
// }));
export default function RelatorioEmpregaDfDemontrativo(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setMensagem, setErro } = props;
    // const location = useLocation();
    const [anoAnalize, setAnoAnalize] = useState();
    const [lstAnoAnalize, setLstAnoAnalize] = useState([]);
    const [dadoEmprega, setDadoEmprega] = useState([]);

    const [empresasDisponiveis, setEmpresasDisponiveis] = React.useState([]);
    const [empresasSelecionadas, setEmpresasSelecionadas] = React.useState([]);

    const [beneficiosDisponiveis, setBeneficiosDisponiveis] = React.useState(["Geral", "PROIMP", "Relevante Interesse"]);
    const [beneficiosSelecionadas, setBeneficiosSelecionadas] = React.useState([]);


    useEffect(() => {
        setTitulo("Relatório Demontrativo Emprega DF");
        buscarDadoEmpregaDF();
    }, []);

    function buscarDadoEmpregaDF() {
        fetch("/api/empregaDF")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    let empre = [];
                    let ano = [];
                    for (let x in result) {
                        const item = result[x];
                        if (item.execucaoOperacaoFruicao) {
                            for (let y in item.execucaoOperacaoFruicao) {
                                const fruic = item.execucaoOperacaoFruicao[y];
                                if (!ano.find(e => e === fruic.ano)) {
                                    ano.push(fruic.ano);
                                }
                            }
                        }
                        if (!empre.find(e => e === item.razaoSocial)) {
                            empre.push(item.razaoSocial);
                        }
                    }
                    setEmpresasDisponiveis(ordenarArrString(empre));
                    setLstAnoAnalize(ano.sort(function (a, b) { return a - b }));
                    setDadoEmprega(result);
                }
            });
    }

    function transformaDt(valor) {
        if (valor) {
            return FormataDataSemHora(new Date(valor));
        }
        return "";
    }


    function ordenarArrString(arr) {
        return arr.sort((a, b) => (a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? -1 : a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? 1 : 0));
    }

    const handleCliciGerarRelatorio = () => { //  relatorioListagemFrom relatorioListagemFrom
        let arrId = []
        root: for (let x in dadoEmprega) {
            const item = dadoEmprega[x];
            if (empresasSelecionadas.length > 0 && !(empresasSelecionadas.find(e => e === item.razaoSocial))) continue root;
            if ((beneficiosSelecionadas.length > 0 || anoAnalize) && item.execucaoOperacaoFruicao) {
                // && !(beneficiosSelecionadas.find(b => b === "as"))
                let benefiOk = beneficiosSelecionadas.length > 0 ? true : false;
                let anoOk =  anoAnalize ? true : false;
                for (let y in item.execucaoOperacaoFruicao) {
                    const fruic = item.execucaoOperacaoFruicao[y];
                    if (fruic.beneficios) {
                        for (let bene in fruic.beneficios) {
                            if ((beneficiosSelecionadas.find(b => b === deParaBeneficiario(fruic.beneficios[bene])))) benefiOk = false;
                        }

                    }
                    if (fruic.ano === anoAnalize) anoOk = false;
                }
                if (benefiOk) {
                    continue root;
                }
                if( anoOk) continue root;
            }
            arrId.push(item.id)
        }
        const dado ={
            ids: arrId,
            anoAnalise: anoAnalize,
        }
        // setMensagem("pagina não desenvolvida");
        // relatorioDemontrativo
        fetch("/api/empregaDF/relatorioDemonstrativo.pdf", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    console.error(error.message)
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    function deParaBeneficiario(s) {
        if (s === "geral") return "Geral";
        else if (s === "especial") return "PROIMP"; // especial
        else if (s === "relevante") return "Relevante Interesse";
        setMensagem("Erro",s)
        return s;
    }

    const handleChangeExercicio = (event) => {
        const val = event.target.value;
        if (val != '') {
            setAnoAnalize(val);
            filtrarEmpresa(val);
        } else {
            setAnoAnalize("");
        }
    };

    function filtrarEmpresa(a) {
        let empre = [];
        for (let x in dadoEmprega) {
            const item = dadoEmprega[x];
            let anoOk = false;
            if (item.execucaoOperacaoFruicao) {
                for (let y in item.execucaoOperacaoFruicao) {
                    const fruic = item.execucaoOperacaoFruicao[y];
                    if (fruic.ano === a) anoOk = true;
                }
            }
            if (anoOk && !empre.find(e => e === item.razaoSocial)) {
                empre.push(item.razaoSocial);
            }
        }
        setEmpresasDisponiveis(ordenarArrString(empre));
        setEmpresasSelecionadas([]);
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Paper>
                <Grid container spacing={3} className={classes.root} >
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="lbAno">Ano de análise</InputLabel>
                            <Select
                                labelId="lbAno"
                                id="ano"
                                value={anoAnalize || ''}
                                onChange={handleChangeExercicio}
                                label="Ano de análise:"
                            >
                                <MenuItem value="">Nenhum</MenuItem>
                                {lstAnoAnalize && lstAnoAnalize.map((row, i) => (
                                    <MenuItem key={i} value={row}>{row}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.root} >
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Empresas"} tituloleft={"Empresas disponíveis:"} tituloright={"Empresas selecionadas:"}
                            left={empresasDisponiveis} setLeft={setEmpresasDisponiveis}
                            right={empresasSelecionadas} setRight={setEmpresasSelecionadas}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Benefícios"} tituloleft={"Benefícios disponíveis:"} tituloright={"Benefícios selecionadas:"}
                            left={beneficiosDisponiveis} setLeft={setBeneficiosDisponiveis}
                            right={beneficiosSelecionadas} setRight={setBeneficiosSelecionadas}
                        />
                    </Grid>
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={handleCliciGerarRelatorio}>
                            Gerar Relatório
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </MuiPickersUtilsProvider>
    );
}