import { useState, useEffect } from "react";
import { ACCESS_TOKEN } from '../constants';

function useFluxo() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function call() {
            if (!localStorage.getItem(ACCESS_TOKEN)) {
                setData(null);
                setLoading(false);
            } else {
                const fluxo = localStorage.getItem(ACCESS_TOKEN+"_FLUXO");
                if( !fluxo || fluxo.length === 0 ){
                    
                    fetch('/api/info/fluxoComplexo')
                    .then(response => response.json())
                    .then(json => {
                        setData(json);
                        setLoading(false);
                        localStorage.setItem(ACCESS_TOKEN+"_FLUXO", JSON.stringify(json) );
                    })
                    .catch(erro => console.error(erro));
                }else{
                    
                    return new Promise((resolve, reject) => {
                        resolve();
                    }).then(() => {
                        setData(JSON.parse(fluxo));
                        setLoading(false);
                    })
                }
            }
        }
        call();
    }, []);
    return [data, loading];
}

function useFluxoDiversos() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function call() {
            if (!localStorage.getItem(ACCESS_TOKEN)) {
                setData(null);
                setLoading(false);
            } else {
                const fluxo = localStorage.getItem(ACCESS_TOKEN+"_FLUXO_DIVERSOS");
                if( !fluxo || fluxo.length === 0 ){
                    
                    fetch('/api/info/fluxoDiversos')
                    .then(response => response.json())
                    .then(json => {
                        setData(json);
                        setLoading(false);
                        localStorage.setItem(ACCESS_TOKEN+"_FLUXO_DIVERSOS", JSON.stringify(json) );
                    })
                    .catch(erro => console.error(erro));
                }else{
                    
                    return new Promise((resolve, reject) => {
                        resolve();
                    }).then(() => {
                        setData(JSON.parse(fluxo));
                        setLoading(false);
                    })
                }
            }
        }
        call();
    }, []);
    return [data, loading];
}

function useFluxoNotificacaoComplementoComplexo() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function call() {
            if (!localStorage.getItem(ACCESS_TOKEN)) {
                setData(null);
                setLoading(false);
            } else {
                const fluxo = localStorage.getItem(ACCESS_TOKEN+"_FluxoNotificacaoComplementoComplexo");
                if( !fluxo || fluxo.length === 0 ){
                    
                    fetch('/api/info/fluxoNotificacaoComplementoComplexo')
                    .then(response => response.json())
                    .then(json => {
                        setData(json);
                        setLoading(false);
                        localStorage.setItem(ACCESS_TOKEN+"_FluxoNotificacaoComplementoComplexo", JSON.stringify(json) );
                    })
                    .catch(erro => console.error(erro));
                }else{
                    
                    return new Promise((resolve, reject) => {
                        resolve();
                    }).then(() => {
                        setData(JSON.parse(fluxo));
                        setLoading(false);
                    })
                }
            }
        }
        call();
    }, []);
    return [data, loading];
}
export { useFluxo, useFluxoDiversos, useFluxoNotificacaoComplementoComplexo };
