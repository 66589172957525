import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Paper, Menu, MenuItem, Button,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RemoveIcon from '@material-ui/icons/Remove';
import CampoBusca from "../../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import {useStyles} from "../../../constants";
import { FormataDataComHora, filtroDadoGenerico, showStatusEmpresario } from '../../../constants/';
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../../server/UseCurrentUser";

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let qtd = 0;
let primeiro = true;
let idProcesso = 0;
const dataAtual = new Date();

export default function RecursoTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait } = props;

    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [showConfirmExclusao, setShowConfirmExclusao]= useState(false);
    const history = useHistory();
    let [profile] = useCurrentUser();

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    useEffect(() =>{
        setTitulo("Requerimentos de Recurso");
        if( primeiro && profile){
            setWait(true);
            fetch("/api/processoProdf/recurso/filtro",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'Authorization': `Bearer ${localStorage.accessToken}`
                    },
                    body: JSON.stringify({
                        cnpjs: profile.empresas
                    })
                }).then(res => res.json()).then((result) => {
                    setWait(false);
                    if (result.status && result.status > 300) {
                        setErro(result);
                    } else {
                        result.sort((a, b) => ((a.protocolo < b.protoclo) ? 1 : -1));
                        fonteDeDados = [];
                        for(let x in result){
                            const d = result[x];
                            let dataValidacao = "";
                            for(let h in d.historico){  
                                const hDado = d.historico[h];
                                if(hDado.tipo === "valGestaoUnate"){
                                    dataValidacao = hDado.data; 
                                }
                            }
                            fonteDeDados.push(
                                {
                                    id: d.id,
                                    nProcesso: d.processo.nProcesso,
                                    protocolo: d.protocolo,
                                    dataCriacao: d.dataCriacao,
                                    dataValidacao: dataValidacao,
                                    dataAnalise: d.dataAnalise,
                                    status: d.status,
                                    programa: d.processo.programa,
                                    tipo: d.tipo,
                                    fluxoPath: d.fluxoPath
                                }
                            );
                        }
                        filtraDado();
                    }
                });
            primeiro =false;
        }
    });

    const handleAcao = (e, item, acao) => {
        if( acao=== 'editar'){
            history.replace("/gerenciaProcesso/solicitarRecursoForm/"+item.id+"?reenviar=true");
        }
    }
    function confirmaExclusao(){
        setWait(true);
        fetch("/api/processoProdf/recurso/"+idProcesso,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    let value = [];
                    if (fonteDeDados && (fonteDeDados.length > 0)) {
                        for (let i = 0; i < fonteDeDados.length; i++) {
                            if (fonteDeDados[i].id !== idProcesso) {
                            value.push(fonteDeDados[i]);
                            }
                        }
                    }
                    fonteDeDados = value
                    filtraDado();
                }
            });
    }
    function excluir(id){
        setShowConfirmExclusao(true);
        idProcesso = id;
    }

    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
          disparaSearch();
        }
        elapseTime = performance.now();
      }
    
      function disparaSearch() {
        setTimeout(function () {
          if (performance.now() - elapseTime > tempo) {
            setBloco(0);
            filtraDado(filtroCampo);
          } else {
            disparaSearch();
          }
        }, tempo);
      }

    useEffect(() => {
        filtraDado();
    }, [ordem, pagina, bloco]);

    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];

        let arrOrdenado = ordenar(fonteDeDados);
        filtroDadoGenerico(arrOrdenado, arr, filtroCampo);        

        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (++index >= pagina) break;
        }
        
        setDadosFiltrado(arrBloco);
    }
    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
        
        setBloco(0);
    }
    function calculaDiaLimite(recurso){
        if( recurso.status === 'PENDENTE'){
            let dt = new Date(recurso.dataAnalise);            
            const timeDiff  = (dataAtual) - (new Date(dt));
            const days      = timeDiff / (1000 * 60 * 60 * 24);
            if( days >= 31 )return "";
            return Math.round( 30 - days );
        }
        return "";
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'processo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('processo')}
                                    >
                                        Numero Processo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'protocolo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('protocolo')}
                                    >
                                        Protocolo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'dataCriacao'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataCriacao')}
                                    >
                                        Data Solicitação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'dataAnalise'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataAnalise')}
                                    >
                                        Data validação
                                    </TableSortLabel>
                                </TableCell>                                
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'limite'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('limite')}
                                    >
                                        Dias limite resposta
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'status'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('status')}
                                    >
                                        Situação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'programa'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('programa')}
                                    >
                                        Programa
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'tpRecurso'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('tpRecurso')}
                                    >
                                        Tipo recurso
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width='100px' align="center" className={classes.tableHead}>  
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell >{row.nProcesso}</TableCell>
                                    <TableCell>{row.protocolo}</TableCell>
                                    <TableCell>{ row.dataCriacao && FormataDataComHora( new Date(row.dataCriacao)) }</TableCell>
                                    <TableCell>{ (row.dataValidacao)?FormataDataComHora( new Date(row.dataValidacao)):'' }</TableCell>                                    
                                    <TableCell> {calculaDiaLimite(row)}</TableCell>
                                    <TableCell>{ showStatusEmpresario(row)}</TableCell>
                                    <TableCell>{row.programa}</TableCell>
                                    <TableCell>{ row.tipo }</TableCell>
                                    <TableCell>
                                        <Box >
                                            {
                                                    row.status !== 'RASCUNHO' && 
                                                <Tooltip title="Visualizar">
                                                    <IconButton href={"/gerenciaProcesso/solicitarRecursoView/"+row.id} >
                                                    <VisibilityIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                row.status === 'RASCUNHO' && 
                                                <Tooltip title="Editar">
                                                    <IconButton onClick={(e) => handleAcao(e, row, 'editar')}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                    row.status === 'RASCUNHO' && 
                                                <Tooltip title="Excluir">
                                                    <IconButton onClick={() => (excluir( row.id)) } >
                                                    <RemoveIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {
                                                row.status === 'PENDENTE' && 
                                                <Tooltip title="Reenviar">
                                                    <IconButton onClick={(e) => handleAcao(e, row, 'editar')}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            }

                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    fonteDeDados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ qtd }
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }
            </Grid>
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>

                <Button variant="contained" color="primary" href={"/gerenciaProcesso/solicitarRecursoForm"} >
                    Solicitar
                </Button>
            </Grid>
            <ConfirmDialog
                    open={showConfirmExclusao}
                    setOpen={setShowConfirmExclusao}
                    titulo={'Confirmação'}
                    msg='Confirma exclusão do item selecionado?.'
                    exec={confirmaExclusao}
                />
        </Grid>
    );
}
