import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Divider, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
} from '@material-ui/core';
import ConfirmDialog from "../../util/ConfirmDialog";
import SearchIcon from '@material-ui/icons/Search';
import RemoveIcon from '@material-ui/icons/Remove';
import BuscaDadoDialog from "../../util/BuscaDadoDialog";
import { useCurrentUser } from "../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import Download from "../../util/Download";
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles, getQueryParams } from "../../constants";
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import GenericTable from "../../components/template/GenericTable";
import Service from "../../services/Service";
import { ptBR } from 'date-fns/locale';

export const useStylesLocal = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '180px',
        overflow: 'auto'
    }
}));

const area = ['DIRAE', 'DAABE', 'SUPEC', 'GIAMP', 'GERAP', 'GEVIS', 'GECOA'];

const assunto = ['Vistoria Acompanhamento de implantação', 'Vistoria para analise de projeto de viabilidade economica',
    'Vistoria atestado de implantação provisoria/definitiva', 'Vistoria de situação atual de lote', 'Vistoria detalhada',
    'Vistoria inicio de implantação', 'Vistoria acompanhamento de metas após AID', 'Vistoria lote']

export default function VistoriaForm(props) {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    const [adeFiltro, setAdeFiltro] = useState();
    const [enderecoFiltro, setEnderecoFiltro] = useState();
    const { setTitulo, setMensagem, setWait, disable, vistoria, setVistoria, alterar, setErro, devolvida, executar, setSuccess } = props;
    const param = getQueryParams(useLocation().search);
    const location = useLocation();
    const [raAde, setRaAde] = useState();
    const [showConfirm, setShowConfirm] = useState(false);
    const [value, setValue] = useState({});
    const [dataSolicitacao, setDataSolicitacao] = useState(param.get("id") ? null : new Date());
    const [anexos, setAnexos] = useState([{ tipo: -1 }]);
    const [processos, setProcessos] = useState([]);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const history = useHistory();
    let [profile] = useCurrentUser();
    const [usuario] = useCurrentUser();
    const [error, setError] = useState(resetErro());
    const [txt, setTxt] = useState('');

    useEffect(() => {

        if (location.pathname.includes("gerenciarView")) {
            setTitulo("Gerenciar Vistoria");
        } else if (location.pathname.includes("elaborarLaudoVistoria")) {
            setTitulo("Executar Vistoria");
        } else if (location.pathname.includes("agendarVistoria")) {
            setTitulo("Agendar Vistoria");
        } else if (location.pathname.includes("solicitarForm")) {
            setTitulo("Solicitar Vistoria");
        } else {
            setTitulo((alterar && alterar === true) ? "Alterar Vistoria" : "Vistoria");

        }
        buscaRaAde();
    }, [])

    useEffect(() => {
        if (!param.get("id")) {
            if (usuario) {
                if (usuario.unidade) {
                    const item = area.find(f => f === usuario.unidade.nome.trim())
                    if (item) {
                        setValue({ ...value, area: item })
                    }
                }
            }
        }


    }, [usuario])


    useEffect(() => {
        if (vistoria) {
            let v = {
                id: vistoria.id,
                solicitante: vistoria.solicitante.nome,
                area: vistoria.area,
                assunto: vistoria.assunto,
                protocolo: vistoria.protocolo,
                observacao: vistoria.observacao,
            };
            if (vistoria.processo) {
                let arr = [];
                for (let x in vistoria.lote) {
                    let l = vistoria.lote[x];
                    arr.push({
                        ra: l.ra,
                        ade: l.ade,
                        endereco: l.endereco
                    });
                }
                v.processo = {
                    dadosEmpresa: {
                        razaoSocial: vistoria.processo.razaoSocial,
                        cnpj: vistoria.processo.cnpj,
                        programa: vistoria.processo.programa,
                        nProcesso: vistoria.processo.nProcesso
                    },
                    dadosImovel: arr
                };
            } else {
                v.ra = vistoria.lote[0].ra;
                v.ade = vistoria.lote[0].ade;
                v.endereco = vistoria.lote[0].endereco;
            }
            setValue(v);
            let arr = vistoria.anexos ? vistoria.anexos.filter(a => !a.tipo) : '';
            if (!disable || disable === false) {
                if (!arr) arr = [{ tipo: -1 }]
                else arr.push({ tipo: -1 });
            }
            setAnexos(arr);
            setDataSolicitacao(vistoria.dataSolicitacao);
            setTxt(vistoria.observacao);
        }
    }, [vistoria]);

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
        if (event.target.name === 'ra') {
            let arr = raAde.find(item => item.nome === event.target.value);
            arr = arr ? arr.ade : [];
            setAdeFiltro(arr);
            setEnderecoFiltro();
            if (arr && arr.length === 1) {
                setValue({
                    ...value,
                    'ade': arr[0],
                    [event.target.name]: event.target.value,
                });

                const dado = {
                    ra: event.target.value,
                    ade: arr[0]
                }
                buscarEndereco(dado);
            }
        }
        if (event.target.name === 'ade') {
            const dado = {
                ra: value.ra,
                ade: event.target.value
            }
            buscarEndereco(dado);
        }
    };

    function buscarEndereco(d) {
        setWait(true);
        Service.POST('/api/processoProdf/vistoria/buscaEnderecoPorRaAde', d).then(ret => {
            let arr = [];
            for (let x in ret) {
                //arr.push(ret[x].endereco);
                arr[x] = ret[x].endereco;
            }
            setEnderecoFiltro(arr);
            setWait(false);
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
        /*
                fetch('/api/processoProdf/vistoria/buscaEnderecoPorRaAde', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        Authorization: `Bearer ${localStorage.accessToken}`
                    },
                    body: JSON.stringify(d),
                    credentials: 'include'
                }).then(response => {
                    if (response.ok) {
                        response.json()
                            .then(ret => {
                                let arr = [];
                                for (let x in ret) {
                                    //arr.push(ret[x].endereco);
                                    arr[x] = ret[x].endereco;
                                }
                                setEnderecoFiltro(arr);
                                setWait(false);
                            })
                    } else {
                        response.json().then(erro => {
                            setErro(erro.message);
                        })
                    }
                }).catch(error => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                }); */
    }

    function confirmaAprovacao() {
        setError(resetErro());


        let dado = {
            area: value.area,
            assunto: value.assunto,
            dataSolicitacao: dataSolicitacao,
            observacao: txt
        };
        dado.anexos = [];//anexos;
        anexos.forEach(a => {
            if (a.tipo !== -1) dado.anexos.push(a);
        });

        if (value.id) dado.id = value.id;
        if (value.assunto === "Vistoria lote") {
            dado.lote = [{
                ra: value.ra,
                ade: value.ade,
                endereco: value.endereco
            }];
        } else {
            if (value.processo === undefined) {
                setMensagem("Para realizar a alteração e nescessario cadastrar as informações do processo");
                return;

            } else {


                dado.processo = {
                    nProcesso: value.processo.dadosEmpresa.nProcesso,
                    programa: value.processo.dadosEmpresa.programa,
                    cnpj: value.processo.dadosEmpresa.cnpj,
                    razaoSocial: value.processo.dadosEmpresa.razaoSocial,
                };
                let loteArr = [];

                for (let x in value.processo.dadosImovel) {
                    let l = value.processo.dadosImovel[x]
                    loteArr.push({
                        ra: l.ra,
                        ade: l.ade,
                        endereco: l.endereco
                    });
                }
                dado.lote = loteArr;
            }
        }


        if (dado.lote[0].ra === "") {
            setMensagem("Empresa esta sem endereço cadastrado, atualize o cadastro");
        } else {

            submitForm(dado);
        }

    }
    function submitForm(dado) {
        setWait(true);
        Service.POST('/api/processoProdf/vistoria', dado).then(r => {
            setWait(false);
            if (param.get("id")) {
                setSuccess("Vistoria alterada com sucesso");
            } else {
                setSuccess("Vistoria cadastrada com sucesso");
            }
            sair();
        }).catch(error => {
            topFunction();
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });

    }
    function topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    function resetErro() {
        return {
            processo: { erro: false, msg: '' },

            campo3: { erro: false, msg: '' },
        }
    }
    function setProcesso(processo) {

        buscaProcessoProdf(processo.idProcesso);
    }
    function buscaProcessoProdf(id) {
        setWait(true);
        fetch("/api/processoProdf/" + id)
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setMensagem(result);
                } else {
                    if (result.dadosImovel === null) {

                        setErro("Empresa não possui o endereço do lote cadastrado, favor realizar o cadastro.");
                    } else {
                        setValue({
                            ...value,
                            processo: result
                        });
                    }


                }
            });
    }
    const buscaDado = () => {
        if (disable) return;
        setWait(true);
        Service.POST("/api/gerenciarProcesso/filtro", {
            cnpjs: usuario.empresas
        }).then(result => {
            setWait(false);
            let arr = [];
            for (let x in result) {
                let item = result[x];
                if (item.nProcesso) item.processoSM = item.nProcesso.replace(/[^\d]+/g, '');
                if (item.cnpj) item.cnpjSM = item.cnpj.replace(/[^\d]+/g, '');
                arr.push(item);
            }

            setProcessos(arr);
            setShowBuscaDadoDialog(true);
        }).catch(error => {
            topFunction();
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
        // fetch("/api/gerenciarProcesso/filtro",
        //     {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             Accept: 'application/json',
        //             'Authorization': `Bearer ${localStorage.accessToken}`
        //         },
        //         body: JSON.stringify({
        //             cnpjs: usuario.empresas
        //         }),
        //     }).then(res => res.json()).then((result) => {
        //         setWait(false);
        //         if (result.status && result.status > 300) {
        //             setMensagem(result);
        //         } else {
        //             let arr = [];
        //             for (let x in result) {
        //                 let item = result[x];
        //                 item.processoSM = item.nProcesso.replace(/[^\d]+/g, '');
        //                 item.cnpjSM = item.cnpj.replace(/[^\d]+/g, '');
        //                 arr.push(item);
        //             }

        //             setProcessos(arr);
        //             setShowBuscaDadoDialog(true);
        //         }
        //     });

    }

    const handleEscolheFile = () => {
        if (disable) return;
        let file = document.getElementById("file");
        file.click();
    }

    function removeArquivo(item) {
        if (disable) return;
        let arr = [];
        anexos.forEach(it => {
            if (it.uuid !== item.uuid) {
                arr.push(it);
            }
        });
        setAnexos(arr);
    }
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    if (!result) return;
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();
                    // setArquivo({
                    //     nome: nm,
                    //     uuid: result.uuid
                    // });
                    // document.getElementById("arquivo").value= nm;
                    let arr = [];
                    arr.push({
                        id: result.id,
                        nome: nm,
                        uuid: result.uuid
                    });
                    anexos.forEach(a => {
                        if (a.tipo !== -1) arr.push(a)
                    });
                    arr.push({ tipo: -1 });
                    setAnexos(arr);
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }
    const handleDateChangeSolicitacao = (date) => {
        setDataSolicitacao(date);
    }
    function sair() {
        history.replace("/vistoria/solicitar");
    }
    function showLote() {
        return (
            <>
                <Grid item xs={6} >
                    <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">RA</InputLabel>
                        <Select
                            autoWidth={false}
                            fullWidth
                            labelId="demo-simple-select-outlined-label"
                            id="ra"
                            name="ra"
                            value={value.ra || ''}
                            onChange={handleChangeInputForm}
                            label="RA"
                            inputProps={{ readOnly: (disable && disable === true) }}
                        >
                            {
                                raAde && raAde.map((item, index) => (
                                    <MenuItem key={index} value={item.nome}>{item.nome}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>

                {/* trazer ade */}
                <Grid item xs={6} >
                    <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                        {/* <InputLabel id="demo-simple-select-outlined-label-ade">ADE</InputLabel> */}
                        {adeFiltro &&
                            <Select
                                labelId="demo-simple-select-outlined-label-ade"
                                id="ade"
                                name="ade"
                                value={value.ade || ''}
                                onChange={handleChangeInputForm}
                                label="ADE"
                                inputProps={{ readOnly: (disable && disable === true) }}
                            >
                                {
                                    adeFiltro && adeFiltro.map((item, index) => (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>
                                    ))
                                }
                            </Select>
                        }
                        {!adeFiltro &&
                            <TextField
                                //disabled={true}
                                value={value.ade || ''}
                                fullWidth
                                label="ADE"
                                id="loet_ade_txt"
                                variant="outlined"
                                inputProps={{ readOnly: (disable && disable === true) }}
                            />
                        }
                    </FormControl>
                </Grid>
                {/* trazer endereço */}
                <Grid item xs={12}>
                    <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                        {/* <InputLabel id="endereço">Endereço</InputLabel> */}
                        {enderecoFiltro &&
                            <Select
                                id="endereco"
                                name="endereco"
                                placeholder="Endereço"
                                label="Endereço"
                                value={value.endereco || ''}
                                onChange={handleChangeInputForm}
                                inputProps={{ readOnly: (disable && disable === true) }}
                            >
                                {
                                    enderecoFiltro && enderecoFiltro.map((item, index) => (
                                        <MenuItem key={index} value={item}>{item}</MenuItem>
                                    ))
                                }
                            </Select>
                        }
                        {!enderecoFiltro &&
                            <TextField
                                //disabled={true}
                                value={value.endereco || ''}
                                // fullWidth
                                label="Endereço"
                                id="lote_end_txt"
                                // aria-describedby="nova-helper-text"
                                variant="outlined"
                                inputProps={{ readOnly: (disable && disable === true) }}
                            />
                        }
                    </FormControl>
                </Grid>
            </>
        )
    }
    function showProcesso() {
        return (
            <>
                <Grid item xs={5}>
                    <TextField
                        value={(value.processo && value.processo.dadosEmpresa.nProcesso) || ''}
                        disabled
                        fullWidth
                        label="Processo"
                        id="processo"
                        name="processo"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={1}>
                    <Tooltip disableTouchListener={true} title='Buscar Processo'>
                        <IconButton onClick={buscaDado} >
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        // disabled={disable && disable === true}
                        value={(value.processo && value.processo.dadosEmpresa.programa) || ''}
                        fullWidth
                        label="Programa"
                        InputProps={{
                            readOnly: true,
                        }}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        //disabled={disable && disable === true}
                        value={(value.processo && value.processo.dadosEmpresa.cnpj) || ''}
                        fullWidth
                        label="CNPJ"
                        InputProps={{
                            readOnly: true,
                        }}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>

                <Grid item xs={8}>
                    <TextField
                        //disabled={disable && disable === true}
                        value={(value.processo && value.processo.dadosEmpresa && value.processo.dadosEmpresa.razaoSocial) || ''}
                        fullWidth
                        label="Razão Social"
                        InputProps={{
                            readOnly: true,
                        }}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                {value && value.processo &&
                    <GenericTable
                        dados={value.processo.dadosImovel}
                        disableSearch={true}
                        readOnly={true}
                        colunas={[
                            { nome: 'RA', campo: 'ra' },
                            { nome: 'ADE', campo: 'ade' },
                            { nome: 'Endereço', campo: 'endereco', align: 'right' }
                        ]} />}
            </>
        )
    }
    function podeConfirmar() {
        if (!value) return true;
        if (!value.assunto) return true;
        if (!value.area) return true;

        if ((!value.endereco) && (!(value.processo && value.processo.dadosImovel.length >= 1))) return true;
        return false;
    }


    function buscaRaAde() {
        fetch('/api/info/ra_ade').then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    setRaAde(json);

                });
            } else {
                response.json().then((error) => {
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }


    return (

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='lg'>
                <Paper >
                    <Grid container spacing={1} className={classes.root}>
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>Dados solicitação</Typography>
                        </Grid>
                        {!param.get("id") &&
                            <Grid item xs={12}>

                                <TextField
                                    value={usuario ? usuario.name : ''}
                                    fullWidth
                                    label="Solicitante"
                                    onChange={handleChangeInputForm}
                                    id="solicitante"
                                    name="solicitante"
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        }
                        {param.get("id") &&
                            <>
                                <Grid item xs={8}>

                                    <TextField
                                        value={value.solicitante || ''}
                                        fullWidth
                                        label="Solicitante"
                                        onChange={handleChangeInputForm}
                                        id="solicitante"
                                        name="solicitante"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        //disabled={disable && disable === true}
                                        value={value.protocolo || ''}
                                        fullWidth
                                        label="Protocolo"
                                        aria-describedby="nova-helper-text"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: disable && disable === true,
                                        }}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth
                                disabled={disable && disable === true}>
                                <InputLabel id="tipo" >Area Solicitante</InputLabel>
                                <Select id="area" name='area' label="Area Solicitante"
                                    disabled={executar}
                                    placceholder="Area Solicitante"
                                    onChange={handleChangeInputForm}
                                    value={value.area || ""} >
                                    {area && area.map((tp, i) => (
                                        <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth disabled={disable && disable === true}>
                                <InputLabel id="tipo" >Assunto</InputLabel>
                                <Select id="assunto" name='assunto' label="Assunto" placceholder="Assunto" onChange={handleChangeInputForm} value={value.assunto || ""} >
                                    {assunto && assunto.map((tp, i) => (
                                        <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {value.assunto === "Vistoria lote" &&
                            showLote()
                        }
                        {value.assunto !== "Vistoria lote" &&
                            showProcesso()
                        }
                        <Grid item xs={12}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                id="data"
                                label="Data Solicitação"
                                format="dd/MM/yyyy"
                                value={dataSolicitacao || null} onChange={handleDateChangeSolicitacao}
                                fullWidth
                                KeyboardButtonProps={true}
                                inputVariant="outlined"
                                disabled
                                invalidDateMessage="Formato inválido"
                            // InputProps={{
                            //     readOnly: disable && disable === true,
                            // }}
                            />
                        </Grid>
                        {
                            !(disable && disable === true) &&
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>Observação</Typography>
                            </Grid>
                        }
                        <Grid item xs={12} >
                            {(disable && disable === true) &&
                                <Paper className={classesLocal.paper}>
                                    <Typography>Observação: </Typography>
                                    <Typography color="textSecondary" gutterBottom>
                                        {
                                            txt && <span dangerouslySetInnerHTML={{
                                                __html: txt
                                            }}></span>
                                        }
                                    </Typography>
                                </Paper>
                            }
                            {
                                !(disable && disable === true) &&
                                <TextField
                                    fullWidth
                                    id="txt"
                                    name="txt"
                                    disabled={disable && disable === true}
                                    multiline
                                    rows={6}
                                    value={txt}
                                    onChange={(e) => {
                                        setTxt(e.target.value);
                                        if (setVistoria) {
                                            setVistoria({
                                                ...vistoria,
                                                observacao: e.target.value
                                            }
                                            )
                                        }
                                    }}
                                    //inputProps={{ readOnly: (disable && disable === true) }}
                                    variant="outlined"
                                />
                            }
                        </Grid>
                        {devolvida &&
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    id="descricao"
                                    name="descricao"
                                    label="Motivo da Devolução"
                                    multiline
                                    rows={6}
                                    value={devolvida}
                                    //onChange={handleChangeInputForm}
                                    //inputProps={{ readOnly: (disable && disable === true) }}
                                    variant="outlined"
                                />
                            </Grid>
                        }

                        <TableContainer component={Paper} >
                            <Table aria-label="customized table" size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sortDirection={false} className={classes.tableHead}>
                                            Documento
                                        </TableCell>
                                        <TableCell width='100px' align="center" className={classes.tableHead}>
                                            Ação
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {anexos && anexos.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{row.nome}</TableCell>
                                            <TableCell>
                                                {row.tipo === -1 &&
                                                    <Tooltip title="Incluir documento">
                                                        <IconButton onClick={(e) => handleEscolheFile()}>
                                                            <AttachFileIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {row.tipo !== -1 &&
                                                    <center>
                                                        <Download doc={row} />
                                                        {(!disable && disable !== true) &&
                                                            <Tooltip title='Remover'>
                                                                <IconButton onClick={(e) => removeArquivo(row)} >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }

                                                    </center>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {
                                        (!anexos || anexos.length === 0) &&
                                        <TableRow>
                                            <TableCell>
                                                Nenhum registro encontrado!
                                            </TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Grid item xs={12}>
                            <Box component="span" m={1} />
                        </Grid>

                        {location.pathname.includes("solicitarForm") &&
                            <Grid container justify="flex-end" className={classes.btns}>
                                <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                                    Sair
                                </Button>
                                <Button variant="contained" color="primary"
                                    onClick={() => setShowConfirm(true)}
                                    rel="noopener noreferrer"
                                    disabled={podeConfirmar()}
                                >
                                    Solicitar
                                </Button>
                            </Grid>
                        }
                        {alterar && alterar === true &&
                            <Grid container justify="flex-end" className={classes.btns}>
                                <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                                    Sair
                                </Button>
                                <Button variant="contained" color="primary"
                                    onClick={() => setShowConfirm(true)}
                                    rel="noopener noreferrer"
                                >
                                    {devolvida ? "Reenviar" : "Alterar"}
                                </Button>
                            </Grid>
                        }
                        <ConfirmDialog
                            open={showConfirm}
                            setOpen={setShowConfirm}
                            titulo={'Confirmação'}
                            msg={(value && value.id) ? "Confirma alteração da vistoria?" : "Confirma cadastro de vistoria?"}
                            exec={confirmaAprovacao}
                        />
                        <Grid item xs={12}>
                            <BuscaDadoDialog
                                open={showBuscaDadoDialog}
                                setOpen={setShowBuscaDadoDialog}
                                acao={setProcesso}
                                colunas={[
                                    { nome: 'CNPJ', campo: 'cnpj' },
                                    { nome: 'Nome', campo: 'razaoSocial' },
                                    { nome: 'Processo', campo: 'nProcesso' },
                                ]}
                                dados={processos} />
                        </Grid>
                    </Grid>

                </Paper>

                <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />
            </Container>
        </MuiPickersUtilsProvider>


    );
}
