import React, { useEffect, useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
  InputBase, Box, Button, Container, Collapse, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, MenuItem, Paper,
  Select, Typography, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CadastrarPerfilPanel from './CadastrarPerfilPanel';
import ConfirmDialog from '../../util/ConfirmDialog';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CampoBusca from "../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { useStyles } from "../../constants";

const StyledTableCell = withStyles((theme) => createStyles({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => createStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
  }
}))(TableRow);

let pageLoaded = false;
let desabilitaVerPerfil = false;
let filtroCampo = null;
let dados = null;
let elapseTime = 0;
let tempo = 600;

let perfilId = null;
export default function CriarPerfilPanel() {

  const classes = useStyles();
  const [perfis, setPerfil] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');
  // const [campo, setCampo] = React.useState('');
  const [editarPerfil, setEditarPerfil] = React.useState();
  const [showConfirm, setShowConfirm] = useState(false);
  const [pagina, setPagina] = useState(5);
  const [bloco, setBloco] = useState(0);
  const [ordem, setOrdem] = useState({
    campo: '',
    ordem: 'asc'
  });

  useEffect(() => {
    fetch("/api/perfil",
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.accessToken}`
        }
      }).then(res => res.json()).then((result) => {
        if (!pageLoaded) {
          const lst = result.sort((a, b) => ((a.name < b.name) ? -1 : 1));
          dados = [...lst];
          filtraDado();
          pageLoaded = true;
        }
      });
  }, [perfis]);


  useEffect(() => {
    
    filtraDado();
  }, [ordem, pagina, bloco]);

  const handleSearch = (e) => {
    filtroCampo = e.target.value;
    if (performance.now() - elapseTime > tempo) {
      disparaSearch();
    }
    elapseTime = performance.now();
  }
  function disparaSearch() {
    setTimeout(function () {
      if (performance.now() - elapseTime > tempo) {
        filtraDado(filtroCampo);
      } else {
        disparaSearch();
      }
    }, tempo);
  }
  function ordenar(arr) {
    let ordenado = arr;
    
    if (ordem.campo.length > 0) {
      const asc = (ordem.ordem === 'asc') ? 1 : -1;
      if (ordem.campo === 'regras') {
        ordenado = ordenado.sort((a, b) => ((a.regras.length < b.regras.length) ? 1 * asc : -1 * asc));
      } else {
        ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
      }

    }
    return ordenado;
  }

  function filtraDado() {
    if (!dados) return;
    let arr = [];
    let arrOrdenado = ordenar(dados);

    dados.forEach(d => {
      if (filtroCampo) {
        let campos = filtroCampo.split(' ');
        root: for (var x in d) {
          for (var y in campos) {
            if (typeof d[x] === 'string') {
              if ((d[x] && campos[y]) && (d[x].toUpperCase().includes(campos[y].toUpperCase()))) {
                arr.push(d);
                break root;
              }
            } else if (d[x] === campos[y]) {
              arr.push(d);
              break root;
            }
          }
        }
      } else {
        arr.push(d);
      }
    });
    setPerfil(arr);
  }

  const handleFiltrarSubmit = event => {
    event.preventDefault();
    let endpoint = null;
    const filtro = document.getElementById('filtro').value;
    // if (!filtro || (campo === '')) {
      endpoint = '/api/perfil';
    // } else {
    //   endpoint = '/api/perfil/filtrar/' + campo + '/' + filtro;
    // }

    fetch(endpoint, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      credentials: 'include'
    }).then(response => {
      if (response.ok && (response.status === 200)) {
        response.json().then((result) => {
          setPerfil(result);
        });
      } else {
        response.json().then((error) => {
          setOpen(true);
          setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
      }
    }).catch(error => {
      setOpen(true);
      setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  };

  const handleExcluirPerfil = (perfil) => {
    perfilId = perfil.id;
    setMsgErro(null);
    setOpen(false);
    setShowConfirm(true);
  }

  
  function confirmar() {
    fetch('/api/perfil/' + perfilId, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      credentials: 'include'
    }).then(response => {
      if (response.ok && (response.status === 202)) {
        const value = [];
        if (perfis && (perfis.length > 0)) {
          for (let i = 0; i < perfis.length; i++) {
            if (perfis[i].id !== perfilId) {
              value.push(perfis[i]);
            }
          }
        }
        setPerfil(value);
      } else {
        response.json().then((error) => {
          setOpen(true);
          setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
          window.scrollTo({
            top:0
            
          });
        });
      }
    }).catch(error => {
      setOpen(true);
      setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }
  function createSortHandler(coluna) {
    let or = { ...ordem };
    if (or.campo === coluna) {
      or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
    } else {
      or.campo = coluna;
      or.ordem = 'asc';
    }
    setOrdem(or);
    setBloco(0);
  }


  return (
    <Container className={classes.root} maxWidth="md">
      {!editarPerfil && <div>
        <div >
          <Collapse in={open}>
            <Alert severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {msgErro}
            </Alert>
          </Collapse>
        </div>

        <form noValidate autoComplete="off" action="/api/perfil/filtrar" method="get" onSubmit={handleFiltrarSubmit} >
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <Paper className={classes.paper}>
                <Typography component="h1" variant="h5" gutterBottom>Perfis do sistema</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <CampoBusca handleSearch={handleSearch} />
            </Grid>
          </Grid>
        </form>
        <Grid container spacing={3} className={classes.root}>
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={false} className={classes.tableHead}>
                    <TableSortLabel active={ordem.campo === 'nome'}
                      direction={ordem.ordem}
                      onClick={(e) => createSortHandler('nome')}>
                      Nome do Perfil
                  </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={false} className={classes.tableHead}>
                    <TableSortLabel active={ordem.campo === 'regrass'}
                      direction={ordem.ordem}
                      onClick={(e) => createSortHandler('regras')}>
                      Qtde de funcionalidades
                  </TableSortLabel>
                  </TableCell>
                  <TableCell width='160px' align="center" className={classes.tableHead}>
                    Ação
                  </TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {(perfis && (perfis.length > 0)) ? perfis.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="td" scope="col">{row.nome}</StyledTableCell>
                    <StyledTableCell component="td" scope="col">{(row.regras ? row.regras.length : 0)}</StyledTableCell>
                    <StyledTableCell component="td" scope="col">
                      <Box>
                        <Tooltip title="Remover Perfil">
                          <IconButton onClick={(event) => handleExcluirPerfil(row)}><DeleteIcon /></IconButton>
                        </Tooltip>
                        <Tooltip title="Atualizar Perfil">
                          <IconButton onClick={(event) => { desabilitaVerPerfil = false; setEditarPerfil(row) }}>
                            <AssignmentOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Visualizar Perfil">
                          <IconButton onClick={(event) => { desabilitaVerPerfil = true; setEditarPerfil(row) }}>
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )) : <StyledTableRow><TableCell colSpan={3}><center>Nenhum Perfil cadastrado</center></TableCell></StyledTableRow>}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid container item sm={12} justify="flex-end" className={classes.btns} >
              <Button variant="contained" color="primary" href="/CadastrarPerfil">
                Cadastrar Perfil
            </Button>
          </Grid>
        </Grid>

      </div>}
      <ConfirmDialog
        open={showConfirm}
        setOpen={setShowConfirm}
        titulo={'Confirmação'}
        msg={'Deseja realmente excluir o perfil?'}
        exec={confirmar}
      />
      {editarPerfil &&
        <Grid container spacing={3}>
          {/* <ThemeProvider theme={theme}> */}
            <CadastrarPerfilPanel disabled={desabilitaVerPerfil} perfil={editarPerfil} />
          {/* </ThemeProvider> */}

        </Grid>
      }
    </Container>
  )
}
