import React, { useState } from 'react';
import { Container, Card, CardContent, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { useStyles, FormataData } from '../../../constants';
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";
import Service from '../../../services/Service';
import { useLocation } from 'react-router-dom';

export default function DetalharDadosIndicacaoAreaProcessoPanel(props) {
    const classes = useStyles();
    const { processo, setProcesso, gestaoProcesso, profile, setWait, setMensagem } = props;
    const [alteracao, setAlteracao] = useState(false);
    const location = useLocation();


    function isEmpresario() {
        if (location.pathname.includes("cadastrarProcesso")) return true;
        if (profile) {
            return profile.regras.find(r => r === '012') && true;
        }
        return false;
    }

    const handleChangeArea = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            indicacaoArea: {
                ...processo.indicacaoArea,
                [e.target.name]: e.target.value
            }
        })
    }

    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            setAlteracao(false);
            // history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }
    const handleCheck = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            indicacaoArea: {
                ...processo.indicacaoArea,
                [e.target.name]: e.target.checked
            }
        })
    }

    return (
        <Container>
            <Card style={{ marginTop: '5px' }}>
                <CardContent>
                    <Grid container spacing={3} className={classes.root}>
                        {!isEmpresario() &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={processo && processo.indicacaoArea && processo.indicacaoArea.naoSeAplica || false}
                                            onChange={handleCheck}
                                            name="naoSeAplica"
                                            color="primary"
                                        />
                                    }
                                    label="Não se aplica"
                                />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                value={processo.indicacaoArea && processo.indicacaoArea.indicacaoAreaEditalN || ''}
                                fullWidth
                                label="Edital Nº"
                                id="indicacaoAreaEditalN"
                                name="indicacaoAreaEditalN"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo && processo.indicacaoArea && processo.indicacaoArea.indicacaoAreaDataEdital || ''}
                                fullWidth
                                label="Data Edital"
                                id="indicacaoAreaDataEdital"
                                name="indicacaoAreaDataEdital"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo.indicacaoArea && processo.indicacaoArea.indicacaoAreaPublicacaoDodf || ''}
                                fullWidth
                                label="Publicação DODF"
                                id="indicacaoAreaPublicacaoDodf"
                                name="indicacaoAreaPublicacaoDodf"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo && processo.indicacaoArea && processo.indicacaoArea.indicacaoAreaDataDodf || ''}
                                fullWidth
                                label="Data Publicação DODF"
                                id="indicacaoAreaDataDodf"
                                name="indicacaoAreaDataDodf"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        {!isEmpresario() &&
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.indicacaoArea && processo.indicacaoArea.linkFolha || ''}
                                    onChange={handleChangeArea}
                                    fullWidth
                                    label="Área (Link/Folha)"
                                    id="linkFolha"
                                    name="linkFolha"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                        {/* <Grid item xs={12}>
                        <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="area"/>

                        </Grid> */}
                    </Grid>
                    {alteracao &&
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" onClick={() => salvar()} color="primary" >
                                Salvar
                            </Button>
                        </Grid>
                    }
                </CardContent>
            </Card>
        </Container>
    );
}
