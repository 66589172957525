import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {List, Typography, Box, IconButton} from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { useHistory } from "react-router-dom";
import {theme, useStyles} from "../../../constants";
import { ThemeProvider } from '@material-ui/core/styles';
import CampoBusca from "../../../util/CampoBusca";
import ConfirmDialog from "../../../util/ConfirmDialog";
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

const useStylesPagina = makeStyles(() => ({
  paper: {
    width: 400,
    height: 500,
    overflow: 'auto',
  },
  margem: {
    marginLeft: '-40px'
  }
}));

function intersection(a, b) {
  return a.filter((value) => b.find(item => item.id === value.id));
}

let pageLoaded = false;
let fonteDisponiveis;
let fonteSelecioadas;
let elapseTime = 0;
let tempo = 600;
let filtroCampoDisponivel = null;
let filtroCampoSelecionado=null;

export default function Form(props) {
  const classes = useStyles();
  const classesPagina = useStylesPagina();
  const history = useHistory();
  const [checked, setChecked] = React.useState([]);

  const [showConfirm, setShowConfirm] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');

  const [painelDisponiveis, setPainelDisponiveis] = React.useState([]);
  const [painelSelecionadas, setPainelSelecionadas] = React.useState([]);

  const leftChecked = intersection(checked, painelDisponiveis);
  const rightChecked = intersection(checked, painelSelecionadas);

  useEffect(() => {
    if (!pageLoaded) {
      props.setTitulo("Associação de usuários ao perfil "+props.userProfile.perfil.nome);
      fetch("/api/usuariosSistema",
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Bearer ${localStorage.accessToken}`
          }
        }).then(res => res.json()).then((result) => {
          let lst = result;
          if (props && props.userProfile && props.userProfile.users) {
            lst = [];
            let perfilMap = [];
            props.userProfile.users.forEach(u => {
              perfilMap[u.id] = u;
            });
            result.forEach(u => {
              if (!perfilMap[u.id]) {
                if( u.situacao && u.situacao === 'ativo'){
                  lst.push(u);
                }
              }
            });
            const sel = props.userProfile.users.sort((a, b) => a.nome > b.nome);
            fonteSelecioadas = [...sel];
            setPainelSelecionadas(sel);
            const finalList = lst.sort((a, b) => a.nome > b.nome );
            fonteDisponiveis = [...finalList];
            setPainelDisponiveis(finalList);
          }
        });
      pageLoaded = true;
    }
  });

  const handleCancel = () => {
    window.location.reload();
  }

  const handleSubmit = (event) => {
    if( filtroCampoSelecionado && filtroCampoSelecionado.length > 0 ){
      setMsgErro("Retire o filtro dos usuários selecionados para poder aplicar a alteração.");
      return;
    }
    let lst = [];
    
    if (painelSelecionadas && (painelSelecionadas.length > 0)) {
      painelSelecionadas.forEach(user => {
        lst.push({ id: user.id });
      })
    }

    fetch('/api/perfil/perfilUsuario', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify({
        perfil: { nome: props.userProfile.perfil.nome },
        users: lst
      }),
      credentials: 'include'
    }).then(response => {
      if (response.ok && (response.status === 202)) {
        window.location.reload();
        // history.push('/associarPerfilLote');
      } else {
        response.json().then((error) => {
          setOpen(true);
          setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
      }
    }).catch(error => {
      setOpen(true);
      setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }

  const handleToggle = (value) => () => {
    let newChecked = [...checked];
    if( checked.find(item => value.id === item.id)){
      let arr=[];
      newChecked.forEach(item =>{
        if( item.id !== value.id){
          arr.push(item);
        }
      })
      newChecked = arr;
    }else{
      newChecked.push(value)
    }
    setChecked(newChecked);    
  };

  const handleAllRight = () => {
    let disponiveis = [...painelDisponiveis];
    let selecionadas = [...painelSelecionadas];
    disponiveis.forEach(item => selecionadas.push(item));

    selecionadas.sort((a,b) =>a.name > b.name );
    setPainelSelecionadas( selecionadas );
    setPainelDisponiveis([]);

    fonteSelecioadas = selecionadas;
  };

  const handleCheckedRight = () => {
    let disponiveis = [...painelDisponiveis];
    let selecionadas = [...painelSelecionadas];
    leftChecked.forEach(item => {  
      selecionadas.push(item)
    });
    let arr = [];
    for( let x in disponiveis){
      let item = disponiveis[x];
      if( selecionadas.find(i => i.id === item.id)){
        continue;
      }
      arr.push(item);
    }
    arr.sort((a,b) =>a.name > b.name );
    selecionadas.sort((a,b) =>a.name > b.name );
    setPainelDisponiveis( arr );
    setPainelSelecionadas( selecionadas );
    
    fonteSelecioadas = selecionadas;
  };

  const handleCheckedLeft = () => {
    let disponiveis = [...painelDisponiveis];
    let selecionadas = [...painelSelecionadas];
    rightChecked.forEach(item => {  
      disponiveis.push(item)
    });
    let arr = [];
    for( let x in selecionadas){
      let item = selecionadas[x];
      if( disponiveis.find(i => i.id === item.id)){
        continue;
      }
      arr.push(item);
    }
    arr.sort((a,b) =>a.name > b.name );
    setPainelSelecionadas( arr );
    setPainelDisponiveis( disponiveis );

  };

  const handleAllLeft = () => {
    let disponiveis = [...painelDisponiveis];
    let selecionadas = [...painelSelecionadas];
    selecionadas.forEach(item => disponiveis.push(item));

    selecionadas.sort((a,b) =>a.name > b.name );
    setPainelSelecionadas( [] );
    setPainelDisponiveis( disponiveis );
  };

  const handleSearchDisponivel=(e)=>{
    filtroCampoDisponivel = e.target.value;
    if (performance.now() - elapseTime > tempo) {
      disparaSearchDisponivel();
    }
    elapseTime = performance.now();
  }
  const handleSearchSelecionado=(e)=>{
    setMsgErro();
    filtroCampoSelecionado = e.target.value;
    if (performance.now() - elapseTime > tempo) {
      disparaSearchSelecionado();
    }
    elapseTime = performance.now();
  }
  function disparaSearchSelecionado() {
    setTimeout(function () {
      if (performance.now() - elapseTime > tempo) {
        filtraDadoSelecionado(filtroCampoSelecionado);
      } else {
        disparaSearchSelecionado();
      }
    }, tempo);
  }
  function disparaSearchDisponivel() {
    setTimeout(function () {
      if (performance.now() - elapseTime > tempo) {
        filtraDadoDisponivel(filtroCampoDisponivel);
      } else {
        disparaSearchDisponivel();
      }
    }, tempo);
  }
  function filtraDadoSelecionado() {
    if( !fonteSelecioadas) return;
    let arr=[];
    let arrOrdenado = ordenar(fonteSelecioadas);
    arrOrdenado.forEach(d => {
      if( filtroCampoSelecionado){
        let campos = filtroCampoSelecionado.split(' ');
        for (var y in campos) {
            if( d.name.toUpperCase().includes(campos[y].toUpperCase())) {
              arr.push(d);
              break;
            }
        }
      }else{
        arr.push(d);
      }
    });

    let arrSemDisponivel = [];
    for(let x in arr ){
      let item = arr[x]
      if( painelDisponiveis.find(sel => sel.id === item.id)){
        continue;
      }
      arrSemDisponivel.push( item );
    }

    setPainelSelecionadas(arrSemDisponivel);

  }
  function filtraDadoDisponivel() {
    if( !fonteDisponiveis) return;
    let arr=[];
    let arrOrdenado = ordenar(fonteDisponiveis);
    arrOrdenado.forEach(d => {
      if( filtroCampoDisponivel){
        let campos = filtroCampoDisponivel.split(' ');
        for (var y in campos) {
            if( d.name.toUpperCase().includes(campos[y].toUpperCase())) {
              arr.push(d);
              break;
            }
        }
      }else{
        arr.push(d);
      }

    });
    let arrSemSelecionado = [];
    for(let x in arr ){
      let item = arr[x]
      if( painelSelecionadas.find(sel => sel.id === item.id)){
        continue;
      }
      arrSemSelecionado.push( item );
    }
    setPainelDisponiveis(arrSemSelecionado);

  }
  function ordenar(arr) {
    const finalList = arr.sort((a, b) => ((a.nome < b.nome) ? -1 : 1));
    return finalList;
  }

  const customList = (items) => (
    <Paper className={classesPagina.paper}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value)}>
              {(!props.disabled) &&<ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>}
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      { msgErro &&
        <Grid item xs={12}>
          <Alert
            severity="warning"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setMsgErro();
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
          {msgErro}
        </Alert>
      </Grid>
      
      }
      <Grid item>
        <Typography variant="h6" gutterBottom>Usuários disponíveis:</Typography>
        <Box className={classesPagina.margem}>
          <CampoBusca handleSearch={handleSearchDisponivel} />
        </Box>
        {customList(painelDisponiveis)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={(!props.disabled) ? painelDisponiveis.length === 0 : true}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={(!props.disabled) ?leftChecked.length === 0 : true}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={(!props.disabled) ? rightChecked.length === 0 : true}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={(!props.disabled) ? painelSelecionadas.length === 0 : true}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant="h6" gutterBottom>Usuários selecionados:</Typography>
        <Box className={classesPagina.margem}>
          <CampoBusca handleSearch={handleSearchSelecionado} />
        </Box>
        {customList(painelSelecionadas)}
      </Grid>
        <ThemeProvider theme={theme}>
          <Grid container justify="flex-end" className={classes.btns} >
            <Button variant="contained" color="secondary" onClick={handleCancel}>
              Sair
            </Button>
            <Button variant="contained" color="primary" onClick={()=>setShowConfirm(true)} type="submit" disabled={props && props.disabled}>
              Associar
            </Button>
          </Grid>
        </ThemeProvider>
        <ConfirmDialog
                    open={showConfirm}
                    setOpen={setShowConfirm}
                    titulo={'Confirmação'}
                    msg={'Deseja confima alteração dos usuário selecionados ao perfil '+props.userProfile.perfil.nome+' ?'}
                    exec={handleSubmit}
                />

    </Grid>
  );
}
