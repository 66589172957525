import React from 'react';
import { useStyles } from '../../../constants';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export default function CelulaCabecalhoTabelaComponent(props) {
    const classes = useStyles();
    const { ordem, setOrdem, sortDirection, identificador, titulo } = props;
    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
    }
    return (
        <TableCell sortDirection={sortDirection} className={classes.tableHead}>
            <TableSortLabel
                active={ordem.campo === identificador}
                direction={ordem.ordem}
                onClick={(e) => createSortHandler(identificador)}
            >
                {titulo}
            </TableSortLabel>
        </TableCell>
    );
}
