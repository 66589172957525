import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, Container, Tooltip, Box,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography,
    Accordion, AccordionSummary, AccordionDetails,
    FormControl, InputLabel, Select, MenuItem, Card, CardHeader
} from '@material-ui/core';

import { useStyles, getQueryParams, FormataDataComHora } from "../../constants";
import { useCurrentUser } from "../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Download from "../../util/Download";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FluxoPanel from "../../util/FluxoPanelBPM";
import { makeStyles, } from '@material-ui/core/styles';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import HistoricoTable from "../processo/unate/HistoricoTable";
import Service from '../../services/Service';

export const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '160px',
        overflow: 'auto'
    }
}), { index: 1 });

export default function DistribuirRequerimentoForm(props) {
    const classes = useStyles();
    const classesLocal = useLocalStyle();
    const [processo, setProcesso] = useState();
    const [txt, setTxt] = useState('');
    const { setTitulo, setErro, setWait, disableDespacho, setMensagem, assunto } = props;
    let { id } = useParams();
    const [responsavel, setResponsavel] = useState([]);

    const [fluxo, setFluxo] = useState();

    const [value, setValue] = useState({
        assunto: '',
        processo: '',
        cpf: '',
        nome: '',
        telefone: '',
        email: '',
        protocolo: '',
        tipo: '',
        fluxoPath: [],
        declaracao: false,
        situacao: '',
        unidade: '',
    });
    const [anexos, setAnexos] = useState([]);
    const history = useHistory();
    let [profile] = useCurrentUser();
    const param = getQueryParams(useLocation().search);
    const location = useLocation();

    const [responsavelDado, setResponsavelDado] = React.useState(null);

    useEffect(() => {
        if (profile) {
            id = param.get('id');
            if (id) return;
            setValue({
                ...value,
                nome: profile.name,
                cpf: profile.CPF,
                email: profile.email
            });
        }
    }, [profile]);


    useEffect(() => {
        setTitulo && setTitulo("Distribuir Requerimento");

        const process = localStorage.getItem("workingProcess");
        setProcesso(JSON.parse(process));
        buscaDados();
        buscarResponseveis();

    }, [])
    function montaUrl() {
        let ass = assunto;
        if (!ass) {
            ass = (param.get("assunto")) ? param.get("assunto") : '';
        }

        if (ass === 'Recurso') {
            return '/api/processoProdf/recurso/'
        } else if (ass === 'Prorrogacao') {
            return '/api/processoProdf/prorrogacao/'
        } else if (ass === 'Cancelamento') {
            return '/api/processoProdf/cancelamento/'
        } else if (ass === 'Notificacao') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else if (ass === 'Complemento') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else {
            return "/api/processoProdf/requerimento/";
        }
    }

    function buscaDados() {
        id = param.get('id');

        const process = JSON.parse(localStorage.getItem("workingProcess"));
        setProcesso(process);
        const url = montaUrl();


        if (id) {
            setWait(true);
            Service.GET(url + id).then(json =>{
                setWait(false);
                montaValor(json);
                if (json.documentos) setAnexos(json.documentos);
            }).catch(error => {
                setWait(false);
                setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
            });
            // --------------------------
            // fetch(url + id, {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Accept: 'application/json',
            //         Authorization: `Bearer ${localStorage.accessToken}`
            //     },
            //     credentials: 'include'
            // }).then(response => {
            //     if (response.ok && (response.status < 300)) {
            //         response.json().then((json) => {
            //             setWait(false);
            //             montaValor(json);

            //             if (json.documentos) setAnexos(json.documentos);
            //         });
            //     } else {
            //         response.json().then((error) => {
            //             setWait(false);
            //             setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            //         });
            //     }
            // }).catch(error => {
            //     setWait(false);
            //     setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            // });
        } else {
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarrequerimentoForm");
            }
        }
    }
    function buscarResponseveis() {
        setWait(true);
        Service.GET('/api/usuariosDaUnidade/2').then(json =>{
            setWait(false);
            let arr = [];
            for (let x in json) {
                let v = json[x];
                if (v.perfis.find(p => p === 'Empresário')) continue;
                arr.push(v);
            }
            setResponsavel(arr);
        }).catch(error => {
            setWait(false);
            setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
        });
        // ---------------------
        // fetch('/api/usuariosDaUnidade/2', {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok) {
        //         setWait(false);
        //         response.json().then((json) => {
        //             let arr = [];
        //             for (let x in json) {
        //                 let v = json[x];
        //                 if (v.perfis.find(p => p === 'Empresário')) continue;
        //                 arr.push(v);
        //             }
        //             setResponsavel(arr);
        //         })
        //     } else {
        //         response.json().then((error) => {
        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }
    const handleDisbribuir = () => {
        let requerimento = value;
        let assunto = requerimento.assunto.split('-')[0].trim();
        let dado = {
            id: requerimento.id,
            assunto: assunto
        };
        dado.responsavel = {
            idUser: responsavelDado.id,
            nome: responsavelDado.name,
            email: responsavelDado.email
        };
        setWait(true);
        Service.POST('/api/processoProdf/requerimento/vincular', dado).then(resp =>{
            setWait(false);
            sair();
        }).catch(error =>{
            setWait(false);
            setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
        })
        // --------------------------------------------------------
        // fetch('/api/processoProdf/requerimento/vincular', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok && (response.status < 300)) {
        //         setWait(false);
        //         response.json().then((json) => {
        //             sair();
        //         });
        //     } else {
        //         response.json().then((error) => {
        //             setWait(false);
        //             setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    function montaValor(json) {
        let valor = null;
        if (!json.assunto) json.assunto = assunto ? assunto : param.get("assunto");
        if (json.requerente) {
            valor = {
                ...json,
                nome: json.requerente.nome,
                cpf: json.requerente.cpf ? json.requerente.cpf : '',
                email: json.requerente.email,
                telefone: json.requerente.telefone ? json.requerente.telefone : '',
            };
            if (json.historico.length > 0 && json.historico[0].tipo === 'EM_ANALISE') {
                valor.respCadastro = json.historico[0].responsavel
            } else {
                valor.respCadastro = json.requerente;
            }


        } else if (json.solicitante) {
            if (typeof json.processo === 'string') { // para recurso, cancelamento e prorrogacao
                //TODO NECESSARIO REFATORAR
                if (processo) {
                    json.processo = {
                        id: processo.id,
                        nProcesso: processo.dadosEmpresa.nProcesso,
                        cnpj: processo.dadosEmpresa.cnpj,
                        razaoSocial: processo.dadosEmpresa.razaoSocial
                    }
                }
            }
            valor = {
                ...json,
                nome: json.solicitante.nome,
                cpf: json.solicitante.cpf ? json.solicitante.cpf : '',
                email: json.solicitante.email,
                telefone: json.solicitante.telefone ? json.solicitante.telefone : ''
            }
            if (json.historico.length > 0 && json.historico[0].tipo === 'EM_ANALISE') {
                valor.respCadastro = json.historico[0].responsavel
            } else {
                valor.respCadastro = json.solicitante;
            }
        }

        montaPath(valor);

        setValue(valor);
    }

    function montaPath(solicitacao) {
        if (!solicitacao.fluxoPath) {
            if (solicitacao.status === 'RASCUNHO') {
                solicitacao.fluxoPath = ["emRascunho"];
            } else {
                solicitacao.fluxoPath = ["emRascunho", "valUnate"];
            }
        }
    }

    function podeEnviar() {
        if(location.pathname === "/gestao/distribuirRequerimentoView")return true;
        return responsavelDado === null;
        return false;
    

    }
    function disableSelect(){
        if(location.pathname === "/gestao/distribuirRequerimentoView")return true;
        return false;
    }

    function getassunt() {
        if (isAssuntoDiversos()) {
            return value.assunto;
        } if (value.assunto === 'Cancelamento' || value.assunto === 'Complemento') {
            return value && value.assunto || '';
        } else {
            return value && value.assunto + (value.tipo ? ' -' + value.tipo : '');
        }
    }
    function isAssuntoDiversos() {
        return value.assunto === 'Diversos';
    }
    const handleChangeResponsavel = (event) => {
        setResponsavelDado(event.target.value);
    };

    const relatorio = () => {
        let dado = { ...value };
        fetch("/api/processoProdf/requerimento/relatorio", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                return;
            }
        }).then(body => {
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    const handleFluxoChange = () => {
        if (!fluxo) {
            let url = '/api/info/fluxoComplexo';
            if (value.assunto === 'Diversos') {
                url = '/api/info/fluxoDiversos';
            } else if (value.assunto === 'Notificacao') {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
            //     url = '/api/info/fluxoNotificacaoComplementoSimples';
                // if (value.tipo === 'Pendência de vistoria' || value.tipo === 'Prazo vencido') {
                //     url = '/api/info/fluxoNotificacaoComplementoComplexo';
                // }
            } else if (value.assunto && value.assunto === 'Complemento') {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
            }

            fetch(url)
                .then(response => response.json())
                .then(json => setFluxo(json)).catch(erro => setErro(erro));
        }
    }
    const sair = () => {
        history.replace("/gestao/distribuirRequerimentoTable");
    }

    function showStatus(row) {
        const st = row.status;
        const lastFluxo = row.fluxoPath ? row.fluxoPath[row.fluxoPath.length - 1] : '';
        if (st === 'RASCUNHO') {
            return "Rascunho"
        } else if (st === 'PENDENTE') {
            return "Pendente";
        } else if (st === 'REPROVADO') {
            return "Reprovado";
        } else if (st === 'DEFERIDO') {
            if (lastFluxo === 'deferido') {
                return "Deferido";
            }
            return "Em análise"
        } else if (st === 'INDEFERIDO') {
            if (lastFluxo === 'indeferido') {
                return "Indeferido";
            }
            return "Em análise"
        } else {
            return "Em análise"
        }
    }

    function requerimentoOutro(){
        if(value.assunto === "Diversos") return false;
        return true;

    }

    return (
        <Container maxWidth='md'>

            <Accordion onChange={handleFluxoChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>Dados requerimento</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <Grid container spacing={1} className={classes.root} >
                        <Grid item xs={12}>
                            <TextField
                                value={value.processo && value.processo.nProcesso || ''}
                                disabled
                                fullWidth
                                label="Processo"
                                id="processo"
                                name="processo"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={value.dataCriacao ? FormataDataComHora(new Date(value.dataCriacao)) : ''}
                                disabled
                                fullWidth
                                label="Data Solicitação"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={value.processo && value.processo.programa || ''}
                                disabled
                                fullWidth
                                label="Programa"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                value={value && value.protocolo || ''}
                                disabled
                                fullWidth
                                label="Protocolo"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={showStatus(value && value.status || '')}
                                disabled
                                fullWidth
                                label="Situação"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        {!requerimentoOutro() &&
                        <Grid item xs={4}>
                        <TextField
                            value={value && value.unidade || ''}
                            disabled
                            fullWidth
                            label="Unidade"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>}
                        

                        <Grid item xs={12}>
                            <TextField
                                value={value.processo && value.processo.cnpj || ''}
                                disabled
                                fullWidth
                                label="CNPJ"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField disabled
                                value={value.processo && value.processo.razaoSocial || ''}
                                fullWidth
                                label="Razão Social"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={getassunt()}
                                disabled
                                fullWidth
                                label="Assunto"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>



                        {value && value.descricao &&
                            <Grid item xs={12}>
                                {/* <Paper className={classesLocal.paper}>
                                    <span dangerouslySetInnerHTML={{
                                        __html: value.descricao ? value.descricao : ''
                                    }}></span>
                                </Paper> */}

                                <CKEditor
                                    editor={ClassicEditor}
                                    disabled={true}
                                    config={{
                                        toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                            '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                    }}
                                    data={value.descricao ? value.descricao : ''}
                                    onReady={editor => {
                                        editor.editing.view.change(writer => {
                                            writer.setStyle(
                                                "height",
                                                "250px",
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    onChange={(event, editor) => {
                                    }}
                                    onBlur={(event, editor) => {
                                    }}
                                    onFocus={(event, editor) => {
                                    }}
                                />
                            </Grid>
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Accordion onChange={handleFluxoChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>Anexos</Typography>
                </AccordionSummary>
                <AccordionDetails >


                    <TableContainer component={Paper} >
                        <Table aria-label="customized table" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                        Documento
                                                </TableCell>
                                    <TableCell width='100px' align="center" className={classes.tableHead}>
                                        Ação
                                                </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {anexos && anexos.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{row.nome ? row.nome : row.tipo}</TableCell>
                                        <TableCell>
                                            <center>
                                                <Box>
                                                    {row.nome && <Download doc={row} />}
                                                </Box>
                                            </center>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {!anexos || anexos.length === 0 &&
                                    <TableRow>
                                        <TableCell colSpan={2} width='100%' align="center">Nenhum registro encontrado.</TableCell>
                                    </TableRow>

                                }
                            </TableBody>
                        </Table>
                    </TableContainer>


                </AccordionDetails>
            </Accordion>

            <Accordion onChange={handleFluxoChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>Fluxo</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    {fluxo &&
                        <FluxoPanel fluxo={fluxo} path={value.fluxoPath} />
                    }
                </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded={true} onChange={handleFluxoChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>Dados responsável</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth >
                            <InputLabel id="demo-simple-select-outlined-label">Responsável</InputLabel>
                            <Select
                                labelId="lb-idresponsavel"
                                id="idresponsavel"
                                value={responsavelDado || ''}
                                onChange={handleChangeResponsavel}
                                label="Responsável"
                                disabled={disableSelect()}
                            >
                                {responsavel.map(v =>
                                    <MenuItem key={v.id} value={v}>
                                        <em>{v.name}</em>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={false} onChange={handleFluxoChange}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>Historico</Typography>
                </AccordionSummary>
                <AccordionDetails >
                    <Grid item xs={12}>
                    {value && value.historico &&
                        <HistoricoTable dados={value.historico} setWait={setWait} setMensagem={setMensagem} />
                    }
                    </Grid>
                </AccordionDetails>
            </Accordion>



            <Grid container justify="flex-end" className={classes.btns}>

                <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                    Sair
                        </Button>

                <Button variant="contained" color="primary" onClick={relatorio} >
                    Visualizar
                </Button>

                <Button variant="contained" color="primary" onClick={handleDisbribuir} disabled={podeEnviar()}>
                    Distribuir
                </Button>

            </Grid>



        </Container>
    );
}
