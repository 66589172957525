import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Container, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, FormataDataComHora } from "../../../constants";
import GenericTable from "../../../components/template/GenericTable";
import NumberFormat from 'react-number-format';
import { useParams, useLocation, useHistory } from "react-router-dom";


const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

let novoProjeto = [];
const programa = ['FIDE', 'IDEAS', 'PRODF']

const TaxaField = (props) => {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={4}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}
export default function NovosProjetosForm(props) {
    const { setTitulo, setMensagem, setWait, disable } = props;
    const classes = useStyles();
    const history = useHistory();
    let { id } = useParams();
    const location = useLocation();
    const [values, setValues] = React.useState({
        exercicio: '',
        valorOrcamento: '',
    });
    const [liberacoes, setLiberacoes] = useState([]);
    useEffect(() => {
        buscarLiberacoes();
        
        buscaFundefeNovoProjetoList();
        if (location.pathname.includes("NovosProjetosView")) {
            setTitulo('Visualizar de Previsão Orçamentárea FUNDEFE - NOVOS PROJETOS')
        } else if (location.pathname.includes("NovosProjetosAlterar")) {
            setTitulo('Alterar de Previsão Orçamentárea FUNDEFE - NOVOS PROJETOS')
        } else {
            setTitulo('Cadastro de Previsão Orçamentárea FUNDEFE - NOVOS PROJETOS')
        }
    }, []);

    useEffect(() => {
        if( id) buscaFundefeNovoProjeto();
    }, [id]);

    useEffect(() => {
        if (values.exercicio && values.programa) alteradoExercPrograma();
    }, [values.exercicio, values.programa]);
    useEffect(() => {
        if (values.valorOrcamento && values.totalExecutado) {
            let orcameto = values.valorOrcamento;
            if (typeof values.valorOrcamento === 'string') {
                orcameto = values.valorOrcamento.replace(/[.]/g, '').replace(",", ".")
            }
            setValues({
                ...values,
                saldo:  values.totalExecutado - orcameto,
                percentualExecutado: (values.totalExecutado / orcameto).toFixed(4)
            })
        }
    }, [values.valorOrcamento]);

    function buscaFundefeNovoProjetoList() {
        setWait(true);
        fetch("/api/fundefeNovoProjeto")
            .then(resp=> {
                if(resp.ok){
                    resp.json()
                    .then(json => {
                        novoProjeto = json;
                    })
                }
            });
    }

    function buscarLiberacoes() {
        setWait(true);
        fetch("/api/fundefeParcelas")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    let arr = [];
                    for (let x in result) {
                        const item = result[x];
                        let dadoFiltrado = {};
                        dadoFiltrado.exercicio = item.previsao.exercicio;
                        dadoFiltrado.programa = item.previsao.programa;
                        const total = soma(item.parcelas);
                        dadoFiltrado.totalLiberado = total;
                        arr.push(dadoFiltrado);
                    }
                    setLiberacoes(arr);
                }
            });
    }
    function soma(parcelas) {
        let total = 0;
        for (let x in parcelas) {
            const p = parcelas[x];
            total += p.valorParcela;
        }
        return total;
    }

    function alteradoExercPrograma() {
        const totalExecutadoLib = liberacoes.find(a => a.exercicio == values.exercicio && a.programa == values.programa);
        let orcameto = values.valorOrcamento;
        if (typeof values.valorOrcamento === 'string') {
            orcameto = values.valorOrcamento.replace(/[.]/g, '').replace(",", ".")
        }
        const arrProjetos = novoProjeto.find(a => a.exercicio == values.exercicio && a.programa === values.programa);
        if (location.pathname.includes("NovosProjetosAlterar")) {
            if(arrProjetos){
                history.replace("/fundefe/NovosProjetosAlterar/" + arrProjetos.id);
            }
            setValues({
                ...values,
                id: arrProjetos ? arrProjetos.id : values.id,
                historico: arrProjetos ? arrProjetos.historico : values.historico,
                totalExecutado: totalExecutadoLib ? totalExecutadoLib.totalLiberado : '',
                saldo: (orcameto && totalExecutadoLib) ? (orcameto - totalExecutadoLib.totalLiberado) : 0,
                percentualExecutado: orcameto && totalExecutadoLib ? (totalExecutadoLib.totalLiberado / orcameto).toFixed(4) : 0
            })
        } else {
            setValues({
                ...values,
                valorOrcamento: arrProjetos ? arrProjetos.valorOrcamento : (values.valorOrcamento ? values.valorOrcamento : ''),
                historico: arrProjetos ? arrProjetos.historico : [],
                id: arrProjetos ? arrProjetos.id : null,
                totalExecutado: totalExecutadoLib ? totalExecutadoLib.totalLiberado : '',
                saldo: (orcameto && totalExecutadoLib) ? (orcameto - totalExecutadoLib.totalLiberado) : 0,
                percentualExecutado: orcameto && totalExecutadoLib ? (totalExecutadoLib.totalLiberado / orcameto).toFixed(4) : 0
            })
        }

    }

    const handleChangeExercicio = (event) => {
        const val = event.target.value;
        setValues({
            ...values,
            programa: val
        });
    };

    function transformaDt(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }

    function buscaFundefeNovoProjeto() {
        fetch("/api/fundefeNovoProjeto/" + id)
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    setValues(result);
                }
            });
    }

    function submitForm() {
        let val = values.valorOrcamento
        if (values.valorOrcamento && typeof values.valorOrcamento === "string") {
            val = values.valorOrcamento.replace(/[.*+\-?^${}()|[\]\\]/g, '').replace(",", ".");
        }
        let dado = {
            ...values,
            valorOrcamento: val
        }
        // return;
        setWait(true);
        fetch('/api/fundefeNovoProjeto', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                response.json().then(json => {
                });
                history.replace("/fundefe/previsaoNovoProjeto")
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });

    }

    const sair = () => {
        history.push("/fundefe/previsaoNovoProjeto");

    }
    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    }

    return (
        <Container maxWidth='xl'>
            <Paper>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Informações Prévisão Orçamentaria</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    disabled={disable}
                                    autoFocus={true}
                                    type={"number"}
                                    label="Exercícios"
                                    value={values.exercicio || ''}
                                    onChange={handleChangeInputForm}
                                    name="exercicio"
                                    id="exercicio"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="lbExercicio">Programa</InputLabel>
                                    <Select
                                        disabled={disable}
                                        labelId="programa"
                                        id="Programa"
                                        onChange={handleChangeExercicio}
                                        value={values && values.programa || ''}
                                        label="Programa"
                                    >
                                        <MenuItem value="">Nenhum</MenuItem>
                                        {programa && programa.map((row, i) => (
                                            <MenuItem key={i} value={row}>{row}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    disabled={disable}
                                    label="Valor Orçamento"
                                    value={values.valorOrcamento || ''}
                                    onChange={handleChangeInputForm}
                                    name="valorOrcamento"
                                    id="valorOrcamento"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        inputComponent: MonetarioField
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    value={values.totalExecutado || ''}
                                    id='totalExecutado'
                                    label="Total executado"
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        inputComponent: MonetarioField
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    value={values.saldo || 0}
                                    id='Saldo'
                                    label="saldo"
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        inputComponent: MonetarioField
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    
                                    fullWidth
                                    value={values.percentualExecutado / 100 || 0}
                                    id='percentualExecutado'
                                    label="Percentual executado"
                                    name="percentualExecutado"
                                    aria-describedby="nova-helper-text"
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        inputComponent: TaxaField,
                                        
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography className={classes.heading}>Histórico</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                        <GenericTable
                            disableSearch={true}
                            dados={values ? values.historico : []}
                            colunas={[
                                { nome: 'Data', campo: 'data', transformacao: transformaDt },
                                { nome: 'Responsavel', campo: 'responsavel.nome' },
                                { nome: 'Ação', campo: 'acao' },
                                { nome: 'Observação', campo: 'texto' }
                            ]} />
                    </AccordionDetails>
                </Accordion>

                <Grid container justify="flex-end" className={classes.btns}>

                    <Button
                        variant="contained"
                        color="secondary"
                        rel="noopener noreferrer"
                        onClick={sair}
                        
                    >
                        Sair
                    </Button>
                    {!disable &&
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submitForm}
                        >
                            {location.pathname.includes("NovosProjetosAlterar") ? "Alterar" : "Salvar"}
                        </Button>
                    }
                </Grid>
            </Paper>
        </Container>
    )
}