import React, { useEffect, useState } from 'react';
import {
    Grid, Button, Paper, TextField, Container,
} from '@material-ui/core';
import { useStyles, getQueryParams } from "../../../constants";
import { useHistory, useLocation } from "react-router-dom";
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useCurrentUser } from '../../../server/UseCurrentUser';
import Service from "../../../services/Service";

export default function TextoPadraoForm(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait, setMensagem,disable } = props;
    const history = useHistory();
    
    const [txt, setTxt] = useState('');
    const param = getQueryParams(useLocation().search);
    let [profile] = useCurrentUser();
    const [assunto, setAssunto] = useState('')

    useEffect(() => {
        setTitulo("Texto Padrão");
        buscarTextoPadrao()
    }, []);

    function buscarTextoPadrao(){
        const id = param.get("id");
        if (id) {
            setWait(true);

            Service.GET('/api/textoPadrao/' + id).then(json =>{
                setWait(false);
                setAssunto(json.assunto);
                setTxt(json.txt)
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
            // fetch('/api/textoPadrao/' + id, {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Accept: 'application/json',
            //         Authorization: `Bearer ${localStorage.accessToken}`
            //     },
            //     credentials: 'include'
            // }).then(response => {
            //     if (response.ok && (response.status < 300)) {
            //         response.json().then((json) => {
            //             setWait(false);
            //             setAssunto(json.assunto);
            //             setTxt(json.txt)
            //         });
            //     } else {
            //         response.json().then((error) => {
            //             setWait(false);
            //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            //         });
            //     }
            // }).catch(error => {
            //     setWait(false);
            //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            // });
        }
    }

    const handleChange = (event) => {
        setAssunto(event.target.value);
    };

    function salvarInformacoes() {
        let dado = {
            id: param.get("id"),
            assunto: assunto,
            txt: txt
        };
        dado.solicitante = {
            nome: profile.name,
            email: profile.email,
            id: profile.id,
            cpf: profile.CPF,
            telefone: profile.telefone
        };


        submitForm(dado)

    }
    function submitForm(dado) {

        Service.POST( '/api/textoPadrao', dado).then( resposnta =>{
            history.replace("/gerenciaProcesso/textoPadrao");
        }).catch(error => {
            if( error.includes("duplicate key error")){
                setMensagem("Titulo ja cadastrado");
            }else{
                setMensagem(error || 'Oops! Something went wrong. Please try again!');
            }
        });
        // fetch('/api/textoPadrao', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok && (response.status < 300)) {

        //          history.replace("/gerenciaProcesso/textoPadrao");
        //     } else {
        //         response.json().then((error) => {

        //             setMensagem("Titulo ja cadastrado");
        //         });
        //     }
        // }).catch(error => {
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }


    return (
        <Container maxWidth='md'>
            <Paper >

                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={12}>
                        <TextField
                            value={assunto || ''}
                            fullWidth
                            disabled={disable}
                            label="Título"
                            onChange={handleChange}
                            id="titulo"
                            name="titulo"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CKEditor
                            editor={ClassicEditor}
                            config={{
                                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                    '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                            }}
                            disabled={disable}
                            data={txt}
                            onReady={editor => {
                                editor.editing.view.change(writer => {
                                    writer.setStyle(
                                        "height",
                                        "250px",
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTxt(data);
                            }}
                            onBlur={(event, editor) => {
                            }}
                            onFocus={(event, editor) => {
                            }}
                        />
                    </Grid>
                    <Grid container justify="flex-end" className={classes.btns}>
                        {!disable &&
                        <Button variant="contained" color="primary" onClick={salvarInformacoes} rel="noopener noreferrer">
                        {(param.get("id")) ? "Alterar" : "Cadastrar"}
                            </Button>
                        }
                        
                        <Button variant="contained" color="secondary" href={"/gerenciaProcesso/textoPadrao"} rel="noopener noreferrer">
                            sair
                            </Button>


                    </Grid>

                </Grid>

            </Paper>

        </Container>


    );
}
