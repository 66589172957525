
import React, { useState, useEffect, } from 'react';
import {
    Tooltip, IconButton
}
    from '@material-ui/core';
import { API_BASE_URL } from '../constants';
import VisibilityIcon from '@material-ui/icons/Visibility';

export default function Download(props) {
    const { doc } = props;
    const handleUpload = (e) => {
        var a = document.createElement("a");
        if( doc.id ){
            a.href = API_BASE_URL + "/api/dowload/gridfs/" + doc.id;
        }else{
            a.href = API_BASE_URL + "/api/dowload/fileSystem/" + doc.uuid + "-" + doc.nome;
        }
        a.setAttribute("target", "_blank");
        a.click();

    }
    return (
        <Tooltip title="Visualizar">
            <IconButton onClick={(e) => handleUpload()}>
                <VisibilityIcon />
            </IconButton>
        </Tooltip>
    )
}
