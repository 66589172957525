import React, { useEffect, useState } from "react";
import { Container } from '@material-ui/core';
import { Backdrop, Grid, Paper, Typography, Collapse, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useStyles } from "../../constants";
import CloseIcon from '@material-ui/icons/Close';
import AgendaPanel from "./agenda/AgendaPanel";
import AgendarVistoriaPanel from "./agenda/AgendarVistoriaPanel";
import VistoriaTable from './VistoriaTable';
import VistoriaTab from './VistoriaTab';
import VistoriadorForm from "./vistoriador/VistoriadorForm";
import VistoriadorTable from "./vistoriador/VistoriadorTable";
import VistoriaForm from "./VistoriaForm";
import AlteraVistoriaForm from "./AlteraVistoriaForm";
import CadastrarCapacidadeForm from "./CadastrarCapacidadeForm";
import RelatorioVistoria from "./relatorio/RelatorioVistoria";
import PdfView from "../../util/PdfView";

export default function VistoriaPanel() {
  const classes = useStyles();
  const match = useRouteMatch();
  const [erro, setErro] = useState();
  const [wait, setWait] = useState(false);
  const [titulo, setTitulo] = useState("Vistoria Panel");
  const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });

  function setMensagem(alerta) {
    let a;
    if (typeof alerta === 'object') {
      a = { ...alerta };
      if (!a.tipo) a.tipo = 'success';
      if (!a.duracao) a.duracao = 5000;
      a.visibilidade = true;
    } else {
      a = {
        tipo: 'info',
        duracao: 5000,
        visibilidade: true,
        mensagem: alerta
      }
    }

    setAlerta(a);
    if (!!a.duracao && a.duracao > 0) {
      window.setTimeout(() => {
        setAlerta({ tipo: a.tipo, visibilidade: false });
      }, a.duracao)
    }


  }
  function setSuccess(e) {
    setMensagem({
      tipo: 'success',
      mensagem: e.statusText || e.error || e
    });
  }

  function setSuccess(e) {
    setMensagem({
      tipo: 'success',
      mensagem: e.statusText || e.error || e
    });
  }

  return (
    <Container maxWidth="xl">

      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
            <Collapse in={alerta.visibilidade}>
              <Alert severity={alerta.tipo} action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setAlerta({ visibilidade: false });
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>{alerta.mensagem}</Alert>
            </Collapse>
          </Paper>

          {
            erro && <Alert severity="error">{(typeof erro === 'string') ? erro : erro.message}</Alert>
          }
        </Grid>
        <Grid item sm={12}>
          <Switch>
            <Route path={`${match.path}/solicitar`}>
              <VistoriaTable setTitulo={setTitulo} setWait={setWait} setSuccess={setSuccess} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/agendar`}>
              <VistoriaTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/gerenciar`}>
              <VistoriaTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/executar`}>
              <VistoriaTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/executarVistoria`}>
              <VistoriaTab setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setSuccess={setSuccess} setErro={setErro} disable={true} executar={true}/>
            </Route>

            <Route path={`${match.path}/elaborarLaudoVistoria`}>
              <VistoriaTab setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setSuccess={setSuccess} setErro={setErro} laudo={true} />
            </Route>

            <Route path={`${match.path}/validarVistoriaGerente`}>
              <VistoriaTab setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} validarVistoria={true} setSuccess={setSuccess} />
            </Route>
            <Route path={`${match.path}/relatorioListVistoria`}>
              <RelatorioVistoria setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/relatorioVistoriaPorVistoriador`}>
              <RelatorioVistoria setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/relatorioVistoriaQuantidade`}>
              <RelatorioVistoria setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/relatorioVistoriaSituacao`}>
              <RelatorioVistoria setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/relatorioVistoriaTempo`}>
              <RelatorioVistoria setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/validarVistoriaDiretor`}>
              <VistoriaTab setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} validarVistoriaDiretor={true} disableLaudo={true} setSuccess={setSuccess} />
            </Route>

            <Route path={`${match.path}/validarGerente`}>
              <VistoriaTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/validarDiretor`}>
              <VistoriaTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/vistoriador`}>
              <VistoriadorTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/solicitarForm`}>
              <VistoriaForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setSuccess={setSuccess} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/alteraVistoria`}>
              <AlteraVistoriaForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/relatorioVistoriaSituacaoAtual`}>
              <PdfView setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} titulo={"Vistorias por Situação Atual"}
                url={"/api/processoProdf/vistoria/vistoriaSituacaoAtual.pdf"}
              />
            </Route>

            <Route path={`${match.path}/solicitarView`}>
              {/* <VistoriaForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} /> */}
              <VistoriaTab setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} disable={true} setSuccess={setSuccess} />
            </Route>

            <Route path={`${match.path}/gerenciarView`}>
              {/* <VistoriaForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} /> */}
              <VistoriaTab setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} disable={true} setSuccess={setSuccess} />
            </Route>

            <Route path={`${match.path}/capacidade`}>
              <CadastrarCapacidadeForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/VistoriadorForm`}>
              <VistoriadorForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/VistoriadorView`}>
              <VistoriadorForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} disable={true} />
            </Route>

            <Route path={`${match.path}/agenda`}>
              <AgendaPanel setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>

            <Route path={`${match.path}/agendarVistoria`}>
              <VistoriaTab setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} agendar={true} setSuccess={setSuccess} />
            </Route>
            <Route path={`${match.path}/agendarVistoriaId`}>
              <AgendarVistoriaPanel setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
            </Route>
            <Route path={`${match.path}/reagendarVistoriaId`}>
              <AgendarVistoriaPanel setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} reagendarVistoria={true} />
            </Route>
            <Route path={match.path}>
              <h2>nao implementado</h2>
            </Route>
          </Switch>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={wait}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Container>
  )
}