import React, { useEffect, useState } from "react";
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AnalisarSolicitacaoVinculoTabela from './Table';
import AnalisarSolicitacaoVinculoForm from './Form';
import {Backdrop, Grid, Paper, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center'
  },


}));

let primeiro = true;
let dados = null;
export default function AnalisarSolicitacaoVinculoPanel() {
  const [editarAnalisarSolicitacaoVinculo, setAnalisarSolicitacaoVinculo] = React.useState();
  const classes = useStyles();

  const [solicitacoes, setSolicitacoes] = useState();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [erro, setErro] = useState();

  useEffect(() => {
    if (primeiro) {
      setOpenBackdrop(true);

      fetch("/api/perfil/solicitar",
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Bearer ${localStorage.accessToken}`
          }
        }).then(res => {
          setOpenBackdrop(false);

          if (res.ok && (res.status === 200)) {
            res.json().then((result) => {
              const lst = result.sort((a, b) => {
                return (((new Date(a.dataSolicitacao)) < (new Date(b.dataSolicitacao))) ? -1 : 1)
              });
              setSolicitacoes(lst);
              primeiro = false;
            });
          } else {
            res.json().then(error => {
              // setOpen(true);
              setErro(error);
            }).catch(error => {
              // setOpen(true);
              setErro({
                message: "Erro de comunicação com o servidor."
              });
            });
          }
        });

    }
  }, [solicitacoes]);


  return (
    <Container className={classes.root} maxWidth="xl">
      {
        erro && <Alert severity="error">{erro.statusText || erro.error}</Alert>
      }
      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom>Analisar solicitação vínculo</Typography>
          </Paper>
        </Grid>
      </Grid>

      {
        editarAnalisarSolicitacaoVinculo &&
        <AnalisarSolicitacaoVinculoForm
          setEdit={setAnalisarSolicitacaoVinculo}
          disabled={editarAnalisarSolicitacaoVinculo.disabled}
          solicitacao={editarAnalisarSolicitacaoVinculo.solicitacao}
        />
      }
      {
        !editarAnalisarSolicitacaoVinculo && <AnalisarSolicitacaoVinculoTabela setEdit={setAnalisarSolicitacaoVinculo} />
      }
      <Backdrop className={classes.backdrop} open={openBackdrop} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}
