import React, { useState } from 'react';
import { Container, Card, CardHeader, CardContent, Grid, TextField, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { useStyles } from '../../../constants';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import GenericTable from '../../template/GenericTable';
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";
import Service from '../../../services/Service';
import { useLocation } from 'react-router-dom';

export default function DetalharDadosContratoTerracapProcessoPanel(props) {
    const classes = useStyles();
    const { processo, setProcesso, gestaoProcesso, profile, setWait, setMensagem } = props;
    const [alteracao, setAlteracao] = useState(false);
    const location = useLocation();

    function isEmpresario() {
        if (location.pathname.includes("cadastrarProcesso")) return true;
        if (profile) {
            return profile.regras.find(r => r === '012') && true;
        }
        return false;
    }

    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            setAlteracao(false);
            // history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeContratoTerracap = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            contratoTerracap: {
                ...processo.contratoTerracap,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleCheck = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            contratoTerracap: {
                ...processo.contratoTerracap,
                [e.target.name]: e.target.checked
            }
        })
    }

    return (
        <Container>
            <Card style={{ marginTop: '5px' }}>
                <CardHeader title="Dados do Contrato" />
                <CardContent>
                    <Grid container spacing={3} className={classes.root}>
                        <Grid item xs={4}>
                            <TextField
                                value={processo && processo.contratoTerracap && processo.contratoTerracap.migracaoSimOuNao || ''}
                                fullWidth
                                label="Migração"
                                id="migracaoSimOuNao"
                                name="migracaoSimOuNao"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={processo && processo.contratoTerracap && processo.contratoTerracap.programaProin || ''}
                                fullWidth
                                label="Programa"
                                id="programaProin"
                                name="programaProin"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={processo && processo.contratoTerracap && processo.contratoTerracap.nContrato || ''}
                                fullWidth
                                label="Nº Contrato"
                                id="nContrato"
                                name="nContrato"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo && processo.contratoTerracap && processo.contratoTerracap.dataAssinatura || ''}
                                fullWidth
                                label="Data de Assinatura"
                                id="dataAssinatura"
                                name="dataAssinatura"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo && processo.contratoTerracap && processo.contratoTerracap.valorImovel || ''}
                                fullWidth
                                label="Valor Imóvel"
                                id="valorImovel"
                                name="valorImovel"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Card style={{ marginTop: '5px' }}>
                <CardHeader title="Descontos" />
                <CardContent>
                    <Grid container spacing={3} className={classes.root}>
                        {!isEmpresario() &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={processo && processo.contratoTerracap && processo.contratoTerracap.naoSeAplica || false}
                                            onChange={handleCheck}
                                            name="naoSeAplica"
                                            color="primary"
                                        />
                                    }
                                    label="Não se aplica"
                                />
                            </Grid>
                        }
                        <Grid item xs={4}>
                            <TextField
                                value={processo && processo.contratoTerracap && processo.contratoTerracap.dataDescontoMaximo || ''}
                                fullWidth
                                label="Data Desconto Máximo"
                                id="dataDescontoMaximo"
                                name="dataDescontoMaximo"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={processo && processo.contratoTerracap && processo.contratoTerracap.dataDescontoMinimo || ''}
                                fullWidth
                                label="Data Desconto Mínimo"
                                id="dataDescontoMinimo"
                                name="dataDescontoMinimo"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={processo && processo.contratoTerracap && processo.contratoTerracap.dataVencimento || ''}
                                fullWidth
                                label="Data Vencimento"
                                id="dataVencimento"
                                name="dataVencimento"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <h3>Aditivos</h3>
                    {/* <TabelaComponent total={processo && processo.contratoTerracap && processo.contratoTerracap.aditivos} dados={processo && processo.contratoTerracap && processo.contratoTerracap.aditivos}>
                    <CelulaCabecalhoTabelaComponent identificador='numeroNotificacao' titulo="Número ratificação" />
                    <CelulaCabecalhoTabelaComponent identificador='dataNotificacao' titulo="Data ratificação" />
                </TabelaComponent> */}
                    <GenericTable
                        disableSearch
                        dados={processo && processo.contratoTerracap && processo.contratoTerracap.historico || []}
                        colunas={[
                            { nome: 'Resolução', campo: 'resolucao' },
                            { nome: 'Data Resolução', campo: 'dataResolucao' },
                            { nome: 'Migração', campo: 'migracaoSimOuNao' },
                            { nome: 'Programa', campo: 'programaProin' },
                            { nome: 'Nº Contrato', campo: 'nContrato' },
                            { nome: 'Data de Assinatura', campo: 'dataAssinatura' },
                            { nome: 'Valor Imóvel', campo: 'valorImovel' },
                            { nome: 'Data Desconto Máximo', campo: 'dataDescontoMaximo' },
                            { nome: 'Data Desconto Mínimo', campo: 'dataDescontoMinimo' },
                            { nome: 'Data Vencimento', campo: 'dataVencimento' },
                        ]}
                    />
                </Grid>
                {/* <Grid item xs={6}>
                    <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="terracap" />
                </Grid> */}
                {!isEmpresario() &&
                    <Grid item xs={6}>
                        <TextField
                            value={processo && processo.contratoTerracap && processo.contratoTerracap.linkFolha || ''}
                            onChange={handleChangeContratoTerracap}
                            fullWidth
                            label="Contrato (Link/ Folha)"
                            id="linkFolha"
                            name="linkFolha"
                            variant="outlined"
                        // disabled
                        />
                    </Grid>
                }
            </Grid>
            {alteracao &&
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" onClick={() => salvar()} color="primary" >
                        Salvar
                    </Button>
                </Grid>
            }
        </Container>
    );
}
