import React, { useEffect, useState } from "react";
import { Container } from '@material-ui/core';
import { Backdrop, Grid, Paper, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import TemplateTable from "./TemplateTable";
import TemplateForm from "./TemplateForm";
import {useStyles} from "../../constants";

const dados = [
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "teste", segundo: "outro", terceiro: "so para ver" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
  { primeiro: "aaaaa", segundo: "bbbbb", terceiro: "ccccc" },
]

export default function TemplatePanel() {
  const classes = useStyles();
  const match = useRouteMatch();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [erro, setErro] = useState();

  useEffect(() => {

  });

  return (
    <Container maxWidth="xl">
      {
        erro && <Alert severity="error">{erro}</Alert>
      }
      <Grid container spacing={3} className={classes.root}>
        <Grid item sm={12}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h5" gutterBottom>Titulo Template</Typography>
          </Paper>
        </Grid>
        <Grid item sm={12}>
          <Switch>
            <Route path={`${match.path}/processoForm`}>
              <b>processo</b>
            </Route>

            <Route path={`${match.path}/imovel`}>
              <h2>aaa</h2>
            </Route>

            <Route path={`${match.path}/templateForm`}>
              <TemplateForm />
            </Route>

            <Route path={match.path}>
              <TemplateTable dados={dados} />
            </Route>
          </Switch>
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={openBackdrop} >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  )
}
