import React, { useEffect, useState } from 'react';
import {withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Box, Grid, IconButton, Button, Paper, Menu, MenuItem,
  Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip, Typography, TextField,
  Accordion, AccordionSummary, AccordionDetails, FormControl, InputLabel, Select
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import VisibilityIcon from '@material-ui/icons/Visibility';
import CampoBusca from "../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import ConfirmDialog from "../../util/ConfirmDialog";
import { useHistory } from "react-router-dom";
import { useStyles } from "../../constants";
import { Height } from '@material-ui/icons';


const options = [
  'Atualizar',
];

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;

const dados = [
  { cnae: "1.09-1.100", descricao: "160.000.727/2002" },
  { cnae: "2.09-1.100", descricao: "160.000.727/2002" },
  { cnae: "3.09-1.100", descricao: "160.000.727/2002" },
]


export default function CadastrarAlterar(props) {
  const classes = useStyles();
  const [cor, setCor] = useState("");
  const { setErro } = props;
  const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
  const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
  const [pagina, setPagina] = useState(5);
  const [bloco, setBloco] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const history = useHistory();


  useEffect(() => {
    
  })

  const handleChange = (event) => {
    setCor(event.target.value);
  };

  const [ordem, setOrdem] = useState({
    campo: '',
    ordem: 'asc'
  });

  const handleAcao = (e, index) => {
    indexProcesso = index;
    setAnchorEl(e.currentTarget);
  }

  const handleActionClick = (action) => {
    let acao = options[action];
    setAnchorEl(null);
    if (action === 0) {
      history.push("/operacao/EmpresaLoteView")
    }
  };


  const handleClose = () => {
    setAnchorEl(null);
  }
  const handleSearch = (e) => {
    filtroCampo = e.target.value;
    if (performance.now() - elapseTime > tempo) {
      disparaSearch();
    }
    elapseTime = performance.now();
  }

  function disparaSearch() {
    setTimeout(function () {
      if (performance.now() - elapseTime > tempo) {
        setBloco(0);
        filtraDado(filtroCampo);
      } else {
        disparaSearch();
      }
    }, tempo);
  }

  useEffect(() => {
    fonteDeDados = dados;
    filtraDado();
  }, [ordem, pagina, bloco]);

  function filtraDado() {
    if (!fonteDeDados) return;
    let arr = [];

    let arrOrdenado = ordenar(fonteDeDados);


    arrOrdenado.forEach(d => {
      if (filtroCampo) {
        let campos = filtroCampo.split(' ');
        for (let x in d) {
          for (var y in campos) {
            if (typeof d[x] === 'string') {
              if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                arr.push(d);
                break;
              }
            } else if (d[x] == campos[y]) {
              arr.push(d);
              break;
            }
          }
        }
      } else {
        arr.push(d);
      }
    });
    let arrBloco = [];
    qtd = arr.length;

    let x = 0;
    let index = 0;
    for (x = (bloco * pagina); x < arr.length; x++) {
      let d = arr[x];
      arrBloco.push(d);
      if (index++ > pagina) break;
    }

    setDadosFiltrado(arrBloco);
  }

  function ordenar(arr) {
    let ordenado = arr;
    if (ordem.campo.length > 0) {
      const asc = (ordem.ordem === 'asc') ? 1 : -1;
      ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
    }
    return ordenado;
  }


  return (
    <Paper  className={classes.paper}>
      <Grid container spacing={3} className={classes.root}>
        <Grid item >
          <TextField
            id="situacao"
            label="Situação"
            defaultValue="000000-0"
            variant="outlined"
          />
        </Grid>
        <Grid item >
          <TextField
            id="descricao"
            name="descricao"
            label="Descrição"
            defaultValue="Lote na situação dispinível para o programa Desenvolve DF"
            variant="outlined"
          />
        </Grid>
        <Grid item >
          <FormControl variant="outlined" >
            <InputLabel id="demo-simple-select-outlined-label">Cor</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={cor}
              onChange={handleChange}
              label="Cor"
              style={{width: 100}}
            >
              <MenuItem value="none">
                None
                    </MenuItem>
              <MenuItem value={10}>Verde</MenuItem>
              <MenuItem value={20}>Azul</MenuItem>
              <MenuItem value={30}>Amarelo</MenuItem>
              <MenuItem value={30}>Laranja</MenuItem>
            </Select>
          </FormControl>
        </Grid>



        <Grid container justify="flex-end" className={classes.btns}>
          <Button variant="contained" color="primary" href={"/"} >
            Cadastrar/Alterar
        </Button>
        </Grid>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={openAction}
          onClose={handleClose}
          PaperProps={{
            // style: {
            //     maxHeight: ITEM_HEIGHT * 4.5,
            //     width: '20ch',
            // },
          }}
        >
          {options.map((option, index) => (
            <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleActionClick(index)}>
              {option}
            </MenuItem>
          ))}
        </Menu>
      </Grid >
    </Paper>
  );
}
