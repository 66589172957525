import React, { useEffect , useState} from 'react';
import { fade, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box, Button, Collapse, Container, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, MenuItem, Paper,
  Select, Typography, Table, TableBody, TableContainer, TableHead, TableRow, TableCell,
  Tooltip, InputBase
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {FormataDataComHora, useStyles} from "../../../constants";
import TablePagination from '@material-ui/core/TablePagination';



const StyledTableCell = withStyles((theme) => createStyles({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => createStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
  }
}))(TableRow);

const campos = [{ 'nome': '--', 'tipo': '' }, { 'nome': 'Data solicitação', 'tipo': 'dataSolicitacao' },
{ 'nome': 'Matrícula', 'tipo': 'matricula' }, { 'nome': 'Nome', 'tipo': 'name' }, { 'nome': 'Perfil inicial', 'tipo': 'perfilInicial' },
{ 'nome': 'Perfil Solicitado', 'tipo': 'perfil' }, { 'nome': 'Data análise', 'tipo': 'dataAnalise' },
{ 'nome': 'Situação', 'tipo': 'status' }];

let pageLoaded = false;

let qtd = 0;
let filtroCampo = null;
let dados = null;
let elapseTime = 0;
let tempo = 600;

export default function AnalisarSolicitacaoVinculoTabela(props) {
  const { setEdit } = props;
  const classes = useStyles();
  const [pagina, setPagina] = useState(5);
  const [solicitacoes, setSolicitacoes] = React.useState([]);
  const [bloco, setBloco] = useState(0);
  const [campo, setCampo] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');

  const [ordem, setOrdem] = useState({
    campo: '',
    ordem: 'asc'
});

  const handleChangeCampo = event => {
    setCampo(event.target.value);
  }

  const updateTable = (endpoint) => {
    fetch(endpoint,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Authorization': `Bearer ${localStorage.accessToken}`
        }
      }).then(res => {
        if (res.ok && (res.status === 200)) {
          res.json().then((result) => {
            const lst = result.sort((a, b) => {
              return (((new Date(a.dataSolicitacao)) < (new Date(b.dataSolicitacao))) ? -1 : 1)
            });
            dados = lst;
            filtraDado();

          });
        } else {
          res.json().then(error => {
            setOpen(true);
            setMsgErro(error.message);
          }).catch(error => {
            setOpen(true);
            setMsgErro("Erro de comunicação com o servidor.");

          });
        }
      });
  }

  useEffect(() => {
    if (!pageLoaded) {
      updateTable("/api/perfil/solicitar");
      pageLoaded = true;
    }
  }, [solicitacoes]);

  useEffect(() => {
    dados = dados;
    filtraDado();
}, [ordem, pagina, bloco]);

  function createSortHandler(coluna) {
    let or = { ...ordem };
    if (or.campo === coluna) {
        or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
    } else {
        or.campo = coluna;
        or.ordem = 'asc';
    }
    setOrdem(or);
    
    setBloco(0);
}

const handleChangePage = (event, newPage) => {
  setBloco(newPage);
};

const handleChangeRowsPerPage = (event) => {
  setPagina(event.target.value);

};

  const handleFiltrarSubmit = (event) => {
    event.preventDefault();

    let endpoint = '/api/perfil/solicitar';
    const filtro = document.getElementById('filtro').value;

    if (filtro && campo) {
      let valor = filtro;
      if ((campo === 'dataAnalise') || (campo === 'dataSolicitacao')) {
        valor = Date.parse(filtro);
      }
      endpoint = endpoint + '/filtrar/' + campo + '/' + valor;
    }

    updateTable(endpoint);
  }

  function ordenar(arr) {    
    let ordenado = arr;
    if( ordem.campo === 'nome'){
      const asc = (ordem.ordem === 'asc') ? 1 : -1;
      ordenado = ordenado.sort((a, b) => ((a.solicitante.name < b.solicitante.name) ? 1 * asc : -1 * asc));
    }else if( ordem.campo === 'dataSolicitacao'){      
      const asc = (ordem.ordem === 'asc') ? 1 : -1;
      ordenado = ordenado.sort((a, b) => ((a.dataSolicitacao < b.dataSolicitacao) ? 1 * asc : -1 * asc));
    }
    else if( ordem.campo === 'perfilS'){      
      const asc = (ordem.ordem === 'asc') ? 1 : -1;
      ordenado = ordenado.sort((a, b) => ((a.solicitante.perfis < b.solicitante.perfis) ? 1 * asc : -1 * asc));
    }
    else if( ordem.campo === 'perfilS'){      
      const asc = (ordem.ordem === 'asc') ? 1 : -1;
      ordenado = ordenado.sort((a, b) => ((a.solicitante.perfis < b.solicitante.perfis) ? 1 * asc : -1 * asc));
    }
      else {
      if (ordem.campo.length > 0) {       
        const asc = (ordem.ordem === 'asc') ? 1 : -1;
        ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
      }
    }
    return ordenado;
}

  function filtraDado() {
    if (!dados) return;
    let arr = [];
    let arrOrdenado = ordenar(dados);

    dados.forEach(d => {
      if (filtroCampo) {
        let campos = filtroCampo.split(' ');
        root: for (var x in d) {
          for (var y in campos) {
            if (typeof d[x] === 'string') {
              if ((d[x] && campos[y]) && (d[x].toUpperCase().includes(campos[y].toUpperCase()))) {
                arr.push(d);
                break root;
              }
            } else if (d[x] === campos[y]) {
              arr.push(d);
              break root;
            }
          }
        }
      } else {
        arr.push(d);
      }
    });
    setSolicitacoes(arr.slice(0).reverse());
  }
  const handleVisualizarSolicitacao = (solicitacao) => {
    setEdit({ disabled: true, solicitacao: solicitacao })
  }

  const handleAnalisarSolicitacao = (solicitacao) => {
    setEdit({ disabled: false, solicitacao: solicitacao })
  }
  function showStatus(st) {
    if (st === 'APROVADO') {
      return 'Aprovado';
    }
    if (st === 'USER_NOTIFICADO_APROVADO') {
      return 'Aprovado';
    }
    if (st === 'REPROVADO') {
      return 'Reprovado';
    }
    if (st === 'USER_NOTIFICADO_REPROVACAO') {
      return 'Reprovado';
    }
    return "Aguardando analise";
  }

  const handleSearch = (e) => {
    filtroCampo = e.target.value;

    if (performance.now() - elapseTime > tempo) {
      disparaSearch();
    }
    elapseTime = performance.now();
  }
  function disparaSearch() {
    setTimeout(function () {
      if (performance.now() - elapseTime > tempo) {
        filtraDado(filtroCampo);
      } else {
        disparaSearch();
      }
    }, tempo);
  }

  return (
    <form noValidate autoComplete="off" action="/api/perfil/solicitar/filtrar" method="get" onSubmit={handleFiltrarSubmit} >
      <div >
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {msgErro}
          </Alert>
        </Collapse>
      </div>

      <Grid container className={classes.root} spacing={3}>
        <Grid item xs={12}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar…"
              onChange={handleSearch}
              fullWidth
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sortDirection={false} className={classes.tableHead}>
                    <TableSortLabel
                      active={ordem.campo === 'dataSolicitacao'}
                      direction={ordem.ordem}
                      onClick={(e) => createSortHandler('dataSolicitacao')}
                    >
                      Data solicitação
                     </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={false} className={classes.tableHead}>
                    <TableSortLabel
                      active={ordem.campo === 'primeiro'}
                      direction={ordem.ordem}
                      onClick={(e) => createSortHandler('primeiro')}
                    >
                      Matrícula
                      </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={false} className={classes.tableHead}>
                    <TableSortLabel
                      active={ordem.campo === 'nome'}
                      direction={ordem.ordem}
                      onClick={(e) => createSortHandler('nome')}
                    >
                      Nome
                      </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={false} className={classes.tableHead}>
                    <TableSortLabel
                      active={ordem.campo === 'perfil'}
                      direction={ordem.ordem}
                      onClick={(e) => createSortHandler('perfil')}
                    >
                      Perfil inicial
                     </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={false} className={classes.tableHead}>
                    <TableSortLabel
                      active={ordem.campo === 'perfilS'}
                      direction={ordem.ordem}
                      onClick={(e) => createSortHandler('perfilS')}
                    >
                      Perfil solicitado
                      </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={false} className={classes.tableHead}>
                    <TableSortLabel
                      active={ordem.campo === 'dataAnalise '}
                      direction={ordem.ordem}
                      onClick={(e) => createSortHandler('dataAnalise ')}
                    >
                      Data análise
                      </TableSortLabel>
                  </TableCell>
                  <TableCell sortDirection={false} className={classes.tableHead}>
                    <TableSortLabel
                      active={ordem.campo === 'status'}
                      direction={ordem.ordem}
                      onClick={(e) => createSortHandler('status')}
                    >
                      Situação
                    </TableSortLabel>
                  </TableCell>                 
                  <TableCell width='100px' align="center" className={classes.tableHead}>
                    Ação
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(solicitacoes && (solicitacoes.length > 0)) ? solicitacoes.map((row, i) => (
                  <StyledTableRow key={i}>
                    <StyledTableCell component="td" scope="col">{FormataDataComHora(new Date(row.dataSolicitacao))}</StyledTableCell>
                    <StyledTableCell component="td" scope="col">{row.solicitante.id}</StyledTableCell>
                    <StyledTableCell component="td" scope="col">{row.solicitante.name?row.solicitante.name:row.solicitante.email}</StyledTableCell>
                    <StyledTableCell component="td" scope="col">{row.solicitante.perfis ? row.solicitante.perfis : ''}</StyledTableCell>
                    <StyledTableCell component="td" scope="col">{row.perfil}</StyledTableCell>
                    <StyledTableCell component="td" scope="col">{(row.dataAnalise ? FormataDataComHora(new Date(row.dataAnalise)) : '')}</StyledTableCell>
                    <StyledTableCell component="td" scope="col">{showStatus(row.status)}</StyledTableCell>
                    <StyledTableCell component="td" scope="col">
                      <Box>
                        {(row.status === 'ABERTO' || row.status === 'EM_ANALISE' || row.status === 'EM_ANALISE_com_erro') ? (
                          <Tooltip title="Analisar">
                            <IconButton onClick={() => handleAnalisarSolicitacao(row)}><AssignmentIndIcon /></IconButton>
                          </Tooltip>
                        ) : (
                            <Tooltip title="Visualizar">
                              <IconButton onClick={() => handleVisualizarSolicitacao(row)}><VisibilityIcon /></IconButton>
                            </Tooltip>
                          )}
                      </Box>
                    </StyledTableCell>
                  </StyledTableRow>
                )) : <StyledTableRow><TableCell colSpan={8}><center>Nenhuma solicitação cadastrada</center></TableCell></StyledTableRow>}
              </TableBody>
            </Table>
          </TableContainer>
          {
                    dados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ qtd }
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }
        </Grid>
      </Grid>
    </form>
  );
}
