import React, { useState } from "react";
import { Container } from '@material-ui/core';
import { Backdrop, Grid, Paper, Typography, Collapse, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { useStyles } from "../../constants";
import CloseIcon from '@material-ui/icons/Close';
import GenericGestapForm from './GenericGestapForm';
import DistribuirRequerimentoTable from "./DistribuirRequerimentoTable";
import DisbribuirRequerimentoForm from "./DistribuirRequerimentoForm";

export default function GestaoPanel() {
    const classes = useStyles();
    const match = useRouteMatch();
    const [erro, setErro] = useState();
    const [wait, setWait] = useState(false);
    const [titulo, setTitulo] = useState("Gestão indices e analise");
    const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });
    function setMensagem(alerta) {
        let a;
        if (typeof alerta === 'object') {
            a = { ...alerta };
            if (!a.tipo) a.tipo = 'success';
            if (!a.duracao) a.duracao = 5000;
            a.visibilidade = true;
        } else {
            a = {
                tipo: 'info',
                duracao: 5000,
                visibilidade: true,
                mensagem: alerta
            }
        }

        setAlerta(a);
        if (!!a.duracao && a.duracao > 0) {
            window.setTimeout(() => {
                setAlerta({ tipo: a.tipo, visibilidade: false });
            }, a.duracao)
        }
    }

    return (
        <Container maxWidth="xl">

            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
                        <Collapse in={alerta.visibilidade}>
                            <Alert severity={alerta.tipo} action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlerta({ visibilidade: false });
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }>{alerta.mensagem}</Alert>
                        </Collapse>
                    </Paper>
                    {
                        erro && <Alert severity="error">{erro}</Alert>
                    }
                </Grid>

                <Grid item sm={12}>
                    <Switch>

                        <Route path={`${match.path}/listagemAtendimentoForm`}>
                            <GenericGestapForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
                        </Route>

                        <Route path={`${match.path}/listagemAcoesForm`}>
                            <GenericGestapForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
                        </Route>

                        <Route path={`${match.path}/listagemRequerimentoForm`}>
                            <GenericGestapForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
                        </Route>

                        <Route path={`${match.path}/quantitativoRequerimentoForm`}>
                            <GenericGestapForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
                        </Route>

                        <Route path={`${match.path}/tiposRequerimentoForm`}>
                            <GenericGestapForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
                        </Route>

                        <Route path={`${match.path}/quantitativoAtendimentoForm`}>
                            <GenericGestapForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
                        </Route>

                        <Route path={`${match.path}/tempoMedioAtendimentoForm`}>
                            <GenericGestapForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} />
                        </Route>

                        <Route path={`${match.path}/distribuirRequerimentoTable`}>
                            <DistribuirRequerimentoTable setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
                        </Route>

                        <Route path={`${match.path}/distribuirRequerimentoForm`}>
                            <DisbribuirRequerimentoForm setTitulo={setTitulo} setErro={setErro} setWait={setWait} setMensagem={setMensagem}/>
                        </Route>

                        <Route path={`${match.path}/distribuirRequerimentoView`}>
                            <DisbribuirRequerimentoForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} setErro={setErro} />
                        </Route>

                        <Route path={match.path}>
                            <h2>ainda não implementado</h2>
                        </Route>
                    </Switch>
                </Grid>
            </Grid>
            <Backdrop className={classes.backdrop} open={wait}>
                <CircularProgress color="inherit" />
            </Backdrop>

        </Container>
    )
}