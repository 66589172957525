import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import DetalharDadosRequerimentoProcessoDialog from './DetalharDadosRequerimentoProcessoDialog';
import { StatusRequerimento } from '../../../util/StatusSistema';
import { useHistory, useParams } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import AcaoTabelaComponent from '../../common/tabela/AcaoTabelaComponent';

export default function DetalharDadosProrrogacaoPrazoProcessoPanel(props) {
    const { processo } = props;
    const [open, setOpen] = React.useState(false);
    const history = useHistory();
    const [detalhamentoRequerimento, setDetalhamentoRequerimento] = useState({});
    const { id } = useParams();


    const handleAbrirDetalhamento = (r) => {

        history.replace("/gerenciaProcesso/solicitarProrrogacaoView/" + r.id + "?back=/gerenciaProcesso/processo/" + id + "&open=prorrogacaoPrazo");
    };

    function getSolicitacoes() {
        if (processo && processo.requerimentos) {
            let arr = processo.requerimentos.filter(r => r.tipo === 'prorrogacao');
            arr.forEach(el => {
                el.statusNome = StatusRequerimento.find(st => st.status === el.status);
                el.statusNome = el.statusNome ? el.statusNome.nome : ''
            });
            return arr;
        }
        return;
    }
    return (
        // <div>
        <Grid item xs={12}>
            <DetalharDadosRequerimentoProcessoDialog open={open} setOpen={setOpen} detalhamentoRequerimento={detalhamentoRequerimento} />
            <TabelaComponent dados={getSolicitacoes()}>
                <CelulaCabecalhoTabelaComponent identificador='protocolo' titulo="Protocolo" />
                <CelulaCabecalhoTabelaComponent fixo={processo.dadosEmpresa && processo.dadosEmpresa.nProcesso || "nProcesso"} titulo="Número Processo" />
                <CelulaCabecalhoTabelaComponent identificador='tipoRecurso' titulo="Assunto" />
                <CelulaCabecalhoTabelaComponent identificador='dataCriacao' titulo="Data Solicitação" />
                <CelulaCabecalhoTabelaComponent identificador='dataAnalise' titulo="Data Análise" />
                <CelulaCabecalhoTabelaComponent identificador='statusNome' titulo="Situação" />
                <CelulaCabecalhoTabelaComponent fixo={processo.dadosEmpresa && processo.dadosEmpresa.programa || "programa"} titulo="Programa" />
                <AcaoTabelaComponent titulo="Visualizar" onClick={(e) => handleAbrirDetalhamento(e)}>
                    <VisibilityIcon />
                </AcaoTabelaComponent>
            </TabelaComponent>
        </Grid>
        // </div>
    );
}
