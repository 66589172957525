import React, { useState, useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import {
    Grid, Paper, MenuItem, Button, TextField, Typography, FormLabel, RadioGroup, FormControlLabel, Radio

} from '@material-ui/core';
import { useStyles, FormataDataComHora, getQueryParams } from "../../../constants";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useHistory, useLocation } from "react-router-dom";
import GenericTable from "../../../components/template/GenericTable";
import DateFnsUtils from '@date-io/date-fns';
import Service from "../../../services/Service";
import { ptBR } from 'date-fns/locale';

export default function VistoriadorForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, disable } = props;
    const [turno, setTurno] = React.useState('');
    const location = useLocation();
    const [vistoriador, setVistoriador] = React.useState('');
    const [dataInicio, setDataInicio] = useState();
    const history = useHistory();
    const [dataFim, setDataFim] = useState();
    const [situacao, setSituacao] = useState('sim');
    const [vistoriadores, setVistoriadores] = useState([]);
    const [indisponibilidade, setIndisponibilidade] = useState([]);
    const param = getQueryParams(useLocation().search);
    const [erro, setErro] = useState(false);
    const [error, setError] = useState(resetErro());

    useEffect(() => {
        if(location.pathname.includes("VistoriadorView")){
            setTitulo( "Visualizar Vistoriador");
        }else{
            setTitulo((param.get("id") ? "Alterar" : "Cadastrar") + " Vistoriador");
        }
        

        buscaVistoriadores()


    }, [])

    function resetErro() {
        return {
            data: { erro: false, msg: '' },
        }
    }

    function buscaVistoriador(dados) {
        const id = param.get("id");
        if (id) {
            setWait(true);
            Service.GET('/api/vistoriador/' + id).then(json=>{
                setWait(false);
                for (let x in dados) {
                    let v = dados[x];
                    if (json.user.idUser === v.id) {
                        setVistoriador(v)
                    }
                }
                setIndisponibilidade(json.indisponibilidades)
                setSituacao(json.situacao ? 'sim' : 'nao')
            }).catch(error => {
                setWait(false);
                setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
            });
            /*
            fetch('/api/vistoriador/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        for (let x in dados) {
                            let v = dados[x];
                            if (json.user.idUser === v.id) {
                                setVistoriador(v)
                            }
                        }
                        setIndisponibilidade(json.indisponibilidades)
                        setSituacao(json.situacao ? 'sim' : 'nao')
                        //setVistoriador

                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });*/
        }
    }
    const handleChange = (event) => {
        setTurno(event.target.value);
    };
    const handleChangeTurno = (e) => {
        setSituacao(e.target.value);
    }

    const handleChangeVistoriador = (event) => {
        setVistoriador(event.target.value);
    };

    const handleDateChangedataInicio = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (date > dataFim && dataFim !== null ) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        setDataInicio(date);
    }

    const handleDateChangedataFim = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (dataInicio == null) {
            setError({
                data: { erro: true, msg: 'Informe a data inicial' }
            });
        }
        if (dataInicio > date) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        setDataFim(date);
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        if(acao === "Excluir"){
            let arr = [...indisponibilidade];//.filter(i => i.)
            arr.splice(index, 1);
            setIndisponibilidade( arr );
        }
    }

    function buscaVistoriadores() {
        setWait(true);
        Service.GET('/api/usuariosSistema').then(json =>{
            setWait(false);
            let arr = [];
            for (let x in json) {
                let v = json[x]
                if (v.situacao!=="ativo") continue;
                if (v.perfis.find(p => p === 'Empresário')) continue;
                arr.push(v);
            }
            setVistoriadores(arr);
            buscaVistoriador(arr);
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
        /*
        fetch('/api/usuariosSistema', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then((json) => {
                    let arr = [];
                    for (let x in json) {
                        let v = json[x]
                        if (v.situacao!=="ativo") continue;
                        if (v.perfis.find(p => p === 'Empresário')) continue;
                        arr.push(v);
                    }
                    setVistoriadores(arr);
                    buscaVistoriador(arr);
                })
            } else {
                response.json().then((error) => {
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });*/

    }

    function isInvalido(dado){
        const item = indisponibilidade.filter(i =>{
            const dataInicio = new Date( i.dataInicio);
            const dataFim = new Date( i.dataFim);
            return dado.dataInicio >= dataInicio && dado.dataInicio <= dataFim && i.turno === dado.turno;
        })
        return (item && item.length > 0 )?true:false;
    }
    function incluirClick(dado) {
        
        if (turno === 'indisponivel') {
            const dado = [{
                dataInicio: dataInicio,
                dataFim: dataFim,
                turno: 'manha'
            }, {
                dataInicio: dataInicio,
                dataFim: dataFim,
                turno: 'tarde'
            }]
            let arr = [...indisponibilidade];
            dado[0].dataInicio.setHours(0);
            dado[0].dataFim.setHours(23);
            dado[1].dataInicio.setHours(0);
            dado[1].dataFim.setHours(23);

            if( isInvalido(dado[0])){
                setMensagem("Já existe uma indisponibilidade cadastrada para esse turno");
            }else{
                arr.push(dado[0]);
            }

            if( isInvalido(dado[1])){
                setMensagem("Já existe uma indisponibilidade cadastrada para esse turno");
            }else{
                arr.push(dado[1]);
            }
            // arr.push(dado[1]);
            let dia = dataInicio.getDate();
            var d = new Date();
            var n = d.getDate();
            if (dataInicio < d) {
                setMensagem("Data de inicio tem que ser maio que a data de hoje")
                return setErro(false)
            }
            setIndisponibilidade(arr);
            setDataInicio(null);
            setDataFim(null);
            setTurno('');
        } else {
            const dado = {
                dataInicio: dataInicio,
                dataFim: dataFim,
                turno: turno
            }
            dado.dataInicio.setHours(0);
            dado.dataFim.setHours(23);
            
            let arr = [...indisponibilidade];
            if( isInvalido(dado)){
                setMensagem("Já existe uma indisponibilidade cadastrada para esse turno");
                return;
            }
            arr.push(dado);
            let dia = dataInicio.getDate();
            var d = new Date();
            var n = d.getDate();
            if (dia < n) {
                setMensagem("Data de inicio tem que ser maio que a data de hoje")
                return setErro(false)
            }
            setIndisponibilidade(arr);
            setDataInicio(null);
            setDataFim(null);
            setTurno('');
        }
    }

    function formatar(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }

    function salvarInformacoes() {
        let dado = {
            id: param.get("id"),
            user: {
                idUser: vistoriador.id,
                nome: vistoriador.name,
                email: vistoriador.email
            },
            situacao: situacao === 'sim',
            indisponibilidades: indisponibilidade
        };

        if(vistoriador.id === undefined || vistoriador.id === null){
            setMensagem("Selecione o vistoriador")
        }else{
            submitForm(dado);
        }
    }

    function submitForm(dado) {

        fetch('/api/vistoriador', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {

                history.replace("/vistoria/vistoriador");
            } else {
                response.json().then((error) => {

                    setMensagem("Vistoriador ja cadastrado");
                });
            }
        }).catch(error => {

            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function checkVistoriador() {
        if (disable) return true;
        // if (param.get("id")) return true;

    }

    const desabilitaAcaoGenericTable = (acao, dado) => {
        if (location.pathname.includes("VistoriadorView"))return true;
        return false;
    }



    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Paper>
                <Grid container spacing={3} className={classes.root}>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth disabled={checkVistoriador()} >
                            <InputLabel id="demo-simple-select-outlined-label">Vistoriador</InputLabel>
                            <Select
                                labelId="lb-idVistoriador"
                                id="idVistoriador"
                                value={vistoriador}
                                onChange={handleChangeVistoriador}
                                label="vistoriador"
                            >
                                {vistoriadores.map(v =>
                                    <MenuItem key={v.id} value={v}>
                                        <em>{v.name}</em>
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            // value={vistoriador.email ||''}
                            disabled
                            fullWidth
                            label="Matricula"
                            id="matricula"
                            name="matricula"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Ativo</FormLabel>
                            <RadioGroup aria-label="situacao" name="situacao" value={situacao} onChange={handleChangeTurno}>
                                <FormControlLabel value="sim" disabled={disable} control={<Radio />} label="Sim" />
                                <FormControlLabel value="nao" disabled={disable} control={<Radio />} label="Não" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Indisponibilidades</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            disabled={disable}
                            disableToolbar
                            variant="inline"
                            id="dataInicio"
                            label="data Inicio"
                            format="dd/MM/yyyy"
                            value={dataInicio || null} onChange={handleDateChangedataInicio}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                            invalidDateMessage="Formato inválido"
                        />
                    </Grid>


                    <Grid item xs={4}>
                        <KeyboardDatePicker
                            error={error.data.erro}
                            helperText={error.data.msg}
                            disableToolbar
                            variant="inline"
                            id="dataFim"
                            disabled={disable}
                            label="data Fim"
                            format="dd/MM/yyyy"
                            value={dataFim || null}
                            onChange={handleDateChangedataFim}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                            invalidDateMessage="Formato inválido"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl variant="outlined" fullWidth >
                            <InputLabel id="demo-simple-select-outlined-label">Turno</InputLabel>
                            <Select
                                labelId="lbidTurno"
                                id="idTurno"
                                disabled={disable}
                                value={turno}
                                onChange={handleChange}
                                label="Turno"
                            >
                                <MenuItem value="">
                                    <em></em>
                                </MenuItem>
                                <MenuItem value={'manha'}>Manhã</MenuItem>
                                <MenuItem value={'tarde'}>Tarde</MenuItem>
                                <MenuItem value={'indisponivel'}>Indisponivel</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.btns}>
                        {!checkVistoriador() &&
                            <Button variant="contained" color="primary" onClick={incluirClick} rel="noopener noreferrer" disabled={erro || error.data.erro || !turno || !dataInicio || !dataFim}>
                                Incluir
                                </Button>
                        }

                    </Grid>

                    <GenericTable
                        actionClick={handleActionSolicitarClick}
                        handleDisableAction={desabilitaAcaoGenericTable}
                        options={['Excluir']}
                        dados={indisponibilidade}
                        colunas={[
                            { nome: 'Data Inicio', campo: 'dataInicio', 'transformacao': formatar },
                            { nome: 'Data Fim', campo: 'dataFim', 'transformacao': formatar },
                            { nome: 'Turno', campo: 'turno' },
                            { nome: 'Ação', campo: 'action' }
                        ]} />


                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" href={"/vistoria/vistoriador"} rel="noopener noreferrer">
                            Sair
                     </Button>
                        {!checkVistoriador() &&
                            <Button variant="contained" color="primary" onClick={salvarInformacoes} rel="noopener noreferrer">
                                {(param.get("id")) ? "Alterar" : "Cadastrar"}
                            </Button>
                        }


                    </Grid>


                </Grid>
            </Paper>

        </MuiPickersUtilsProvider>
    );
}
