import React from 'react';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
    rodape: {
        width: '100%',
        display: 'none',
        paddingTop: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'block',
            position: 'fixed',
            bottom: 0,
        },
        backgroundColor: '#45b97c',
        borderTop: '5px solid #346399'
    },
    rodapeMobile: {
        width: '100%',
        display: 'flex',
        paddingTop: '10px',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        backgroundColor: '#45b97c',
        borderTop: '5px solid #346399'
    },
    link: {
        color: 'white',
        fontFamily: '"Montserrat", sans-serif',
        paddingTop: '10px',
        paddingBottom: '10px',
        // fontWeight: 'inherit',
        // fontStyle: 'inherit',
        fontSize: '16px',
        textDecoration: 'none'
    },
    ul: {
        listStyleType: 'none',
    },
    acessoAInform: {
        width: '100px'
    }
}));

export default function FooterGDF(props) {
    const classes = useStyles();

    function logo() {
        if (window.location.hostname === "sga.sde.df.gov.br") {
            return true
        }
    }

    function Conteudo() {
        return (
            <Container maxWidth="lg">
                <Grid container justify="center" spacing={3}>
                    <Grid item xs={2}>
                        <ul className={classes.ul}>
                            <li>
                                {!logo() &&
                                    <a href="http://www.empreender.df.gov.br/" target="_blank" rel="external" className={classes.link}>Secretaria</a>
                                }
                                {logo() &&
                                    <a href="http://www.sde.df.gov.br/" target="_blank" rel="external" className={classes.link}>Secretaria</a>
                                }

                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={3}>
                        <ul className={classes.ul}>
                            <li>
                                {!logo() &&
                                    <a href="http://www.empreender.df.gov.br/fale-com-a-secretaria/" target="_blank" rel="external" className={classes.link}>Fale com a Secretaria</a>
                                }
                                {logo() &&
                                    <a href="http://www.sde.df.gov.br/fale-com-a-secretaria/" target="_blank" rel="external" className={classes.link}>Fale com a Secretaria</a>
                                }
                            </li>

                        </ul>
                    </Grid>
                    <Grid item xs={window.location.hostname === "sga.sde.df.gov.br" ? 2 : 4}>
                        <ul className={classes.ul}>
                            <li>
                                {logo() &&
                                    <a href="https://ouvidoria.df.gov.br/" target="_blank" rel="external" className={classes.link}>Ouvidoria </a>
                                }
                                {!logo() &&
                                    <a className={classes.link}>Atendimento ao Empresário: 2141-5555 </a>
                                }
                            </li>
                            <br />
                        </ul>
                    </Grid>
                    <Grid item xs={3}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center">
                            {logo() &&
                                <a href="http://www.sde.df.gov.br/category/acesso-a-informacao/" target="_blank" rel="external">

                                    <img src="http://www.empreender.df.gov.br/wp-conteudo/themes/site-secretarias/img/ico-acesso-info.svg" alt=""
                                        className={classes.acessoAInform}
                                    />
                                </a>
                            }
                            {!logo() &&
                                <a href="http://www.empreender.df.gov.br/index.php/category/acesso-a-informacao/" target="_blank" rel="external">

                                    <img src="http://www.empreender.df.gov.br/wp-conteudo/themes/site-secretarias/img/ico-acesso-info.svg" alt=""
                                        className={classes.acessoAInform}
                                    />
                                </a>
                            }
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        )
    }
    return (
        <div>
            <div className={classes.rodape}>
                <Conteudo />
            </div>
            <div className={classes.rodapeMobile}>
                <Conteudo />
            </div>
        </div>
    );
}