import React, { useState, useEffect } from 'react';
import { Grid, Paper } from '@material-ui/core'
import { FormataMoeda } from "../../../constants";
import GraficoBarra from './GraficoBarra'
import GraficoPizza from './GraficoPizza'

let anoReferencia;
export default function FruicaoGraficos(props) {
    const { dado, total, operacao, graficoPiz } = props;
    const [arrecadacao, setArrecadacao] = useState();
    const [operacoesEmpreendimentos, setOperacoesEmpreendimentos] = useState();
    const [operacoes, setOperacoes] = useState({});

    useEffect(() => {
        // anoReferencia = dado.ano;
        function calculo() {
            let totprojecao = 0;
            let totarrecadacao = 0;
            // for (let x in total) {
            //     const f = total[x];
            //     totprojecao += f.creditoPresumidoICMS ? f.creditoPresumidoICMS : 0
            //     totarrecadacao += f.ICMSProprioEmpreendidoC ? f.ICMSProprioEmpreendidoC : 0
            // }
            totprojecao = total[0] / 1000;
            totarrecadacao = total[1] / 1000

            setArrecadacao({
                labels: ["Projeção \nArrecadação(R$ MIL)", "Arrecadação \nICMS(R$ MIL)"],
                datasets: [
                    {
                        backgroundColor: "green",
                        data: [totprojecao, totarrecadacao, 0]
                    }
                ],
            });
            
            if (dado) {
                let totvenda = 0;
                let tottrans = 0;
                let totremes = 0;


                for (let x in dado.operacoes) {
                    const op = dado.operacoes[x];
                    totvenda += op.operacaoVenda ? op.operacaoVenda : 0;
                    tottrans += op.transferencia ? op.transferencia : 0;
                    totremes += op.remessa ? op.remessa : 0;
                }

                let total = totvenda + tottrans + totremes;
                let pVenda = (totvenda * 100) / total;
                let pTrans = (tottrans * 100) / total;
                let pRemes = (totremes * 100) / total;
                setOperacoesEmpreendimentos({
                    labels: [
                        "Operações de venda (" + FormataMoeda(totvenda) + ") " + FormataPerc(pVenda ) + "%",
                        "Transferências(" + FormataMoeda(tottrans) + ") " +      FormataPerc(pTrans ) + "%",
                        "Remessas(" + FormataMoeda(totremes) + ") " +            FormataPerc(pRemes ) + "%"],
                    datasets: [
                        {
                            label: 'Empreendimentos',
                            data: [pVenda, pTrans, pRemes],
                            backgroundColor: [
                                'rgba(75, 192, 192, 0.2)',
                                'rgba(54, 162, 235, 0.2)',
                                'rgba(255, 99, 132, 0.2)',
                                'rgba(255, 206, 86, 0.2)',
                                'rgba(153, 102, 255, 0.2)',
                                'rgba(1, 102, 255, 0.2)',
                            ],
                            borderColor: [
                                'rgba(75, 192, 192, 1)',
                                'rgba(54, 162, 235, 1)',
                                'rgba(255, 99, 132, 1)',
                                'rgba(255, 206, 86, 1)',
                                'rgba(153, 102, 255, 1)',
                                'rgba(1, 102, 255, 1)',
                            ],
                            borderWidth: 1,
                        },
                    ],
                });
                if( operacao ){
                    setOperacoes({
                        labels: ["Faturamento \nPrevisto(R$ Mil)", "Operações \nRealizadas(R$ Mil)"],
                        datasets: [
                            {
                                label: "Faturamento previsto(R$ MIL)",
                                backgroundColor: "green",
                                data: [operacao[0] / 1000, operacao[1] / 1000]
                            }
                        ],
                    })
                }
            }

        }

        calculo();

    }, [dado, total])

    function FormataPerc(val){
        const opt = {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        };
        if( val === 0 || val === '0')return val.toLocaleString('pt-br', opt);
        if( !val) return '';
        var f = val.toLocaleString('pt-br',opt);
        return f;
    }
    return (
         
        <Grid container spacing={6}>
            {!graficoPiz &&
                <Grid item xs={6} >
                    <Paper>
                    {arrecadacao &&
                        <GraficoBarra titulo={"Arrecadação ICMS(R$ MIL)"} data={arrecadacao} />}
                     </Paper>
                </Grid>
            }
            {!graficoPiz &&
                <Grid item xs={6}>
                    <Paper>
                        {operacoes &&
                            <GraficoBarra titulo={"Operações em (R$ MIL)"} data={operacoes} />
                        }
                    </Paper>
                </Grid>
            }
            { graficoPiz &&
                <Grid item xs={12} >
                    {operacoesEmpreendimentos &&
                        <GraficoPizza titulo={"Operações em empreendimentos " + anoReferencia} grafico={operacoesEmpreendimentos} />}
                </Grid>
            }
        </Grid> 
    )
}