import React, { useState, useEffect } from 'react';
import {
    Grid, Paper, TextField, Tooltip, Box, IconButton, FormControlLabel,
    Accordion, AccordionSummary, AccordionDetails, FormControl, InputLabel,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, API_BASE_URL } from "../../../constants";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import Download from "../../../util/Download";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useAssuntoProjeto } from "../../../server/UseAssuntoProjeto";

let currentRow = null;

export default function Requerimento(props) {
    const classes = useStyles();
    const { value, setValue, setMensagem, setWait, disable } = props;
    const [txt, setTxt] = useState(value.descriacaoRequerimento);
    const [anexos, setAnexos] = useState([
        { tipo: 'pvs', nome: "PVS.pdf" },
        { tipo: -1 }]);
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [assuntos] = useAssuntoProjeto();

    useEffect(() => {

        if (!value.declaracao) {
            setValue({ ...value, declaracao: false })
        }
        if (value.documentos) {
            let arr = [];
            let addAsinaturas = true;
            for (let x in value.documentos) {
                if (addAsinaturas && value.documentos[x].tipo !== "Projeto de Viabilidade Simplificado;") {
                    if (value.declaracaoPVS) {
                        arr.push(value.declaracaoPVS)
                    }
                    if (value.formalizacaoPVS) {
                        arr.push(value.formalizacaoPVS)
                    }
                    addAsinaturas = false;
                }
                if (!(value.documentos[x].tipo === "Declaração de Sócios" || value.documentos[x].tipo === "Formalização do pedido")) {
                    arr.push(value.documentos[x])
                }

            }
            // arr.push({ tipo: -1 });
            setAnexos(arr);
        }
    }, []);
    useEffect(() => {
        if (assuntos && anexos.length === 2) {
            const ass = assuntos.find(a => a.assunto === value.assunto);
            if (ass) {
                let arr = [];
                // arr.push({ tipo: 'pvs', nome:"PVS.pdf" });
                for (let x in ass.docs) {
                    if (ass.docs[x] == "Projeto de Viabilidade Simplificado;") {
                        arr.push({
                            tipo: ass.docs[x],
                            nome: "PVS.pdf"
                        })
                        if (value.declaracaoPVS) {
                            arr.push(value.declaracaoPVS)
                        }
                        if (value.formalizacaoPVS) {
                            arr.push(value.formalizacaoPVS)
                        }
                    } else if (ass.docs[x]) {
                        arr.push({
                            tipo: ass.docs[x]
                        })
                    }
                }
                setAnexos(arr);
            }
        }
    }, [assuntos]);
    useEffect(() => {

        let arr = [];
        for (let x in anexos) {
            if (anexos[x].tipo !== -1) {
                arr.push(anexos[x])
            }
        }
        setValue({ ...value, documentos: arr })
    }, [anexos]);
    useEffect(() => {
        setValue({ ...value, descriacaoRequerimento: txt })
    }, [txt]);

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === 'Visualizar') {
            if (dado.tipo === "Projeto de Viabilidade Simplificado;") {
                var a = document.createElement("a");

                a.href = API_BASE_URL + "/api/projeto/relatorioPVS/" + value.id;

                a.setAttribute("target", "_blank");
                a.click();
            }
        }
    }
    const handleCheck = (e) => {
        setValue({
            ...value,
            declaracao: !value.declaracao
        });
    }
    const handleAnexo = (row) => {
        currentRow = row;

        fileClick();
    }
    function fileClick() {
        let file = document.getElementById("file");
        file.click();
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;
        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();
        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);
        dataArray.append('file', file.files[0]);
        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();

                    if (currentRow) {
                        let arr = [...anexos];
                        arr.forEach(i => {
                            if (i === currentRow) {
                                i.nome = nm;
                                i.uuid = result.uuid;
                            }
                        });
                        setAnexos(arr);
                        currentRow = null;
                    } else {
                        let arr = [];
                        arr.push({
                            tipo: tipoDocumento,
                            nome: nm,
                            uuid: result.uuid
                        });
                        anexos.forEach(a => {
                            if (a.tipo !== -1) arr.push(a)
                        });
                        arr.push({ tipo: -1 });
                        setAnexos(arr);
                    }
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function removeArquivo(item, index) {
        if (item.tipo && item.tipo.length > 0) {
            let itens = [...anexos];
            itens[index].nome = null;
            itens[index].uuid = null;

            setAnexos(itens);
        } else {
            let arr = [];
            anexos.forEach(it => {
                if (it.uuid !== item.uuid) {
                    arr.push(it);
                }
            });

            setAnexos(arr);
        }
    }
    function desabilitado(tipo) {
        if (tipo == "Projeto de Viabilidade Simplificado;" )return false;
        if (tipo == "Declaração de Sócios" )return false;
        if (tipo == "Formalização do pedido" )return false;
        if (!disable) return true;
        if (value && value.status && value.status === 'EM_ANALISE') return true;
        return false;
    }
    return (
        <Paper className={classes.paper} >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography variant="h5" gutterBottom>Requerimento</Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={1} className={classes.root}>
                        <Grid item xs={8}>
                            <TextField
                                value={value.assunto || ''}
                                fullWidth
                                label="Assunto"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                value={value.lotes && value.lotes.processo || ''}
                                fullWidth
                                label="Processo"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={value.empresa.razaoSocial || ''}
                                fullWidth
                                label="INTERASSADO(Razão Social)"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={value.empresa.cnpj || ''}
                                fullWidth
                                label="CNPJ"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={value.requerente ? (value.requerente.cpf || '') : ''}
                                fullWidth
                                label="CPF"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={value.requerente ? (value.requerente.nome || '') : ''}
                                fullWidth
                                label="Requerente"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={value.sede.telefone || ''}
                                fullWidth
                                label="Telefone"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={value.sede.email || ''}
                                fullWidth
                                label="E-mail"
                                InputProps={{
                                    readOnly: true,
                                }}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom align='left'>Requerimento</Typography>
                            <CKEditor
                                editor={ClassicEditor}
                                config={{
                                    toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                        '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                }}

                                data={txt}
                                onReady={editor => {
                                    editor.editing.view.change(writer => {
                                        writer.setStyle(
                                            "height",
                                            "100px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                                onChange={(event, editor) => {
                                    setTxt(editor.getData());
                                }}
                                disabled={disable}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TableContainer component={Paper} >
                                <Table aria-label="customized table" size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                Tipo Documento
                                            </TableCell>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                Documento
                                            </TableCell>
                                            <TableCell width='100px' align="center" className={classes.tableHead}>
                                                Ação
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {anexos && anexos.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell>{row.tipo === -1 ? "" : row.tipo}</TableCell>
                                                <TableCell>{row.nome}</TableCell>
                                                <TableCell>
                                                    {row.tipo === -1 &&
                                                        <Tooltip title="Incluir documento">
                                                            <IconButton onClick={(e) => fileClick()}>
                                                                <AttachFileIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                    {row.tipo !== -1 &&
                                                        <center>
                                                            {
                                                                (desabilitado(row.tipo)) ? (
                                                                    (!row.nome) ?
                                                                        <Box>
                                                                            {

                                                                                (row.arquivo || row.doc) ?
                                                                                    <Tooltip title="Remover documento">
                                                                                        <IconButton onClick={(e) => removeArquivo(row, i)}>
                                                                                            <DeleteIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip title="Incluir documento">
                                                                                        <IconButton onClick={(e) => handleAnexo(row)}>
                                                                                            <AttachFileIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>

                                                                            }
                                                                        </Box> :
                                                                        (<Box>
                                                                            <Tooltip title="Remover documento">
                                                                                <IconButton onClick={(e) => removeArquivo(row, i)}>
                                                                                    <DeleteIcon />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                            {row.nome && <Download doc={row} />}
                                                                        </Box>)

                                                                )
                                                                    : (
                                                                        <Box>
                                                                            {row.tipo === "Projeto de Viabilidade Simplificado;" &&
                                                                                <Tooltip title="Visualizar">
                                                                                    <IconButton onClick={(e) => handleActionSolicitarClick(i, 'Visualizar', row)}>
                                                                                        <VisibilityIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            }
                                                                            {(row.tipo !== "Projeto de Viabilidade Simplificado;" && row.nome) && <Download doc={row} />}
                                                                        </Box>)

                                                            }

                                                        </center>
                                                    }
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" gutterBottom align='left'>
                                Decreto nº 41.015, de 22 de julho de 2020:111
                            </Typography>
                            <br />
                            <Typography variant="body2" gutterBottom align='left'>
                                Art. 84. Nos casos de documentação incompleta, ainda que apresentada na data limite, será concedido à empresa o prazo de 30 dias corridos para saneamento, após o que, caso permaneça a omissão, o requerimento será inadmitido pela SEDET, e arquivado sem análise ou encaminhado para abertura de procedimento de cancelamento, conforme a situação.
                                Parágrafo único. Não será realizada a análise técnica acerca do pedido enquanto não finalizada a juntada da documentação faltante.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={value.declaracao}
                                        checked={value.declaracao}
                                        onChange={handleCheck}
                                        name="declaracao"
                                        color="primary"
                                    />
                                }
                                label="DECLARO A ENTREGA DA DOCUMENTAÇÃO"
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />
            </Accordion>
        </Paper>
    );
}
