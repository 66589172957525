import React, { useEffect, useState } from 'react';
import {
    Button, Grid, Paper, Container, TextField
}
    from '@material-ui/core';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, FormataData } from "../../../constants"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Service from "../../../services/Service";
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import EtapaProcessoComp from './EtapaProcessoComp';

export default function CadastroEscrituracaoForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;
    const [id, setId] = useState();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [promessa, setPromessa] = useState();
    const [definitiva, setDefinitiva] = useState();
    const [desenvolveDf, setDesenvolveDf] = useState();
    const [value, setValue] = useState({ escritura: {} });
    useEffect(() => {
        setTitulo("Cadastrar Escrituração");
        if (param.get("id")) {
            setId(param.get("id"));
            fetch(`/api/processoProdf/${param.get("id")}`).then((r) => {
                setWait(false);
                if (!r.ok) {
                    r.json().then(j => setErro(j));

                } else {
                    r.json().then(result => {
                        setValue(result);
                        if (result.escritura !== null) {
                            setPromessa(formatarDataString(result.escritura.escrituraPromessaData));
                            setDefinitiva(formatarDataString(result.escritura.escrituraDefinitivaData));
                            setDesenvolveDf(formatarDataString(result.escritura.escrituraDataDesenvolveDf));
                        }
                    });
                }
            });
        }
    }, []);
    function formatarDataString(d) {
        if(!d) return '';
        const data = d.split("/");
        if (data.length === 3) {
            return new Date(data[2], (data[1] - 1), data[0]);
        }
        return '';
    }
    function sair() {
        //history.replace("/gerenciaProcesso/cadastrarProcesso?id=123456789abcd&panel=cartaConsulta");
        if (id) history.replace("/gerenciaProcesso/processo/" + id + "?panel=escrituracao");
        else history.replace("/gerenciaProcesso/cadastrarProcesso/?panel=escrituracao");
    }

    function salvar() {
        let dado = {
            processo: {
                ...value,
                escritura: {
                    ...value.escritura,
                    escrituraPromessaData: promessa ? FormataData(promessa) : "",
                    escrituraDefinitivaData: definitiva ? FormataData(definitiva) : "",
                    escrituraDataDesenvolveDf: desenvolveDf ? FormataData(desenvolveDf) : ""
                }
            }
        };

        submitForm(dado);
    }

    function submitForm(dado) {

        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dado).then(json => {
            setWait(false);
            history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=escrituracao");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }



    const handleChangeInputFormPromessa = (date) => {
        setPromessa(date)
    }

    const handleChangeInputFormDefinitiva = (date) => {
        setDefinitiva(date)
    }

    const handleChangeInputFormDesenvolveDf = (date) => {
        setDesenvolveDf(date)
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='md'>
                <Paper >
                    <Grid>

                        <Grid container spacing={1} className={classes.root}>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="escrituraPromessaData"
                                    label="Data da Escritura Promessa"
                                    format="dd/MM/yyyy"
                                    name="escrituraPromessaData"
                                    value={promessa || null}
                                    onChange={handleChangeInputFormPromessa}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="escrituraDefinitivaData"
                                    label="Data da Escritura Definitiva"
                                    format="dd/MM/yyyy"
                                    name="escrituraDefinitivaData"
                                    value={definitiva || null}
                                    onChange={handleChangeInputFormDefinitiva}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="escrituraDataDesenvolveDf"
                                    label="Data da Escritura Desenvolve-DF"
                                    format="dd/MM/yyyy"
                                    name="escrituraDataDesenvolveDf"
                                    value={desenvolveDf || null}
                                    onChange={handleChangeInputFormDesenvolveDf}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {value && value.dadosEmpresa && value.dadosEmpresa.nProcesso &&
                                    <EtapaProcessoComp nProcesso={value.dadosEmpresa.nProcesso} indice={10} />}
                            </Grid>     
                        </Grid>


                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                                Sair
                            </Button>
                            <Button variant="contained" onClick={() => salvar()} color="primary">
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </MuiPickersUtilsProvider>
    );
}