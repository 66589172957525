
import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import GenericTable from "../../../components/template/GenericTable";
import { useStyles } from "../../../constants";
import ConfirmDialog from '../../../util/ConfirmDialog';



let idParcela = null;
export default function RegioesTable(props) {
    const history = useHistory();
    const classes = useStyles();
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const [showConfirmExclusao, setShowConfirmExclusao]= useState(false);

    const { setTitulo, setWait, setMensagem, setErro } = props;

    useEffect(() => {
        setTitulo("Gerenciar Regioes Administrativas")
        buscaDados()
    }, []);

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao && acao === 'Visualizar') {
            history.push("/operacao/regioesAdministrativasView?id=" + dado.id)
        }else if (acao && acao === 'Alterar') {
            history.push("/operacao/regioesAdministrativasForm?id=" + dado.id)
        } else if (acao && acao === 'Excluir'){
            setShowConfirmExclusao(true);
            idParcela = dado.id;
        }
    }

    function isReadOnly() {
        return false;
    }

    function buscaDados(){
        fetch("/api/regioesAdm")
        .then(res => res.json()).then((result) => {
            setWait(false);
            if (result.status && result.status > 300) {
            } else {
                setFonteDeDados(result)
                // filialMatriz(result)
            }
        });
    }

    function confirmaExclusao(){
        setWait(true);
        fetch("/api/regioesAdm/"+idParcela,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    let value = [];
                    if (fonteDeDados && (fonteDeDados.length > 0)) {
                        for (let i = 0; i < fonteDeDados.length; i++) {
                            if (fonteDeDados[i].id !== idParcela) {
                            value.push(fonteDeDados[i]);
                            }
                        }
                    }
                    setFonteDeDados(value);
                }
            });
    }


    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12}>
                <GenericTable
                    actionClick={handleActionSolicitarClick}
                    options={['Visualizar', 'Alterar' , 'Excluir']}
                    dados={fonteDeDados}
                    readOnly={isReadOnly()}
                    colunas={[
                        { nome: 'Ra', campo: 'ra' },
                        { nome: 'Ade', campo: 'ade' },
                        { nome: 'Endereço', campo: 'endereco' },
                        { nome: 'Ação', campo: 'action' }
                    ]} />
            </Grid>

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>

                <Button variant="contained" color="primary" href={"/operacao/regioesAdministrativasForm"} >
                    Cadastrar
                </Button>
            </Grid>
            <ConfirmDialog
                    open={showConfirmExclusao}
                    setOpen={setShowConfirmExclusao}
                    titulo={'Confirmação'}
                    msg='Confirma exclusão do item selecionado?.'
                    exec={confirmaExclusao}
                />

        </Grid>
    )
}