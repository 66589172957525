import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Container, Grid, Button } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useStyles } from "../../constants";
import RegistrarChamadaForm from './RegistrarChamadaForm';
import { useCurrentUser } from '../../server/UseCurrentUser';
import TabelaChamadosForm from './TabelaChamadosForm';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

export default function SuporteTab(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;
    // const location = useLocation();
    const [value, setValue] = React.useState('regi');
    const history = useHistory();
    const [dadosCamados, setDadosCamados] = useState([]);
    const [profile] = useCurrentUser();

    useEffect(() => {
        setTitulo("Suporte ao Usuário");
        if (profile) buscarSuporte();
    }, [profile]);

    function buscarSuporte() {
        fetch("/api/suporte")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    result = result.sort((a, b) => ((a.dataSolicitacao < b.dataSolicitacao) ? 1 : -1));
                    filtrarDados(result);

                }
            });
    }
    function filtrarDados(d) {        
        let arr = [];
        for (let x in d) {
            const suporte = d[x];            
            if (suporte.historico[0].responsavel.id === profile.id) {
                let dataConclu = '';
                let dataInicioAtend = '';
                let solucao = '';
                let responsavelUtimo = '';
                for (let x in suporte.historico) {
                    const his = suporte.historico[x];
                    if (his.tipo === "CONCLUIDO") {
                        dataConclu = his.data;
                        solucao = his.texto;
                    }
                    if (his.tipo === "ANDAMENTO" && dataInicioAtend === '') {
                        dataInicioAtend = his.data;
                    }
                    if (his.tipo === "ABERTO") {
                        responsavelUtimo = " ";
                    } else {
                        responsavelUtimo = his.responsavel.nome
                    }
                }
                arr.push({
                    id: suporte.id,
                    numeroChamada: suporte.numeroChamada,
                    email: suporte.email,
                    tipoSolicitacao: suporte.tipo,
                    situacao: suporte.situacao,
                    descricaoOcorrencia: suporte.descricao,
                    dataSolicitacao: suporte.dataCriacao,
                    dataInicioAtend: dataInicioAtend,
                    dataConclusao: dataConclu,
                    solucao: solucao,
                    responsavel: responsavelUtimo,
                    nome: suporte.nome,
                    anexo:suporte.anexosChamado
                })
            }

        }

        setDadosCamados(arr);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function sair() {
        // history.replace("/vistoria/solicitar");

        history.replace("/home");

    }

    return (
        <Container maxWidth='lg'>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                    <Tab
                        value="regi"
                        label="Registrar Chamado"
                        wrapped
                        {...a11yProps('registrarChamado')}
                    />

                    <Tab value="cons" label="Consultar Chamado" {...a11yProps('consultarChamado')} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index="regi">
                <RegistrarChamadaForm setWait={setWait} setMensagem={setMensagem} setErro={setErro} profile={profile} usuarioInterno={true} />
            </TabPanel>
            <TabPanel value={value} index="cons">
                <TabelaChamadosForm dados={dadosCamados} />
            </TabPanel>

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                    Sair
                </Button>
            </Grid>
        </Container>
    );
}
