import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Container, Box, AppBar, Tabs, Tab, Paper, TextField, makeStyles
} from '@material-ui/core';
import { useStyles, getQueryParams, FormataDataComHora } from "../../../constants";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import EmpresaTrasferenteForm from './formDespacho/EmpresaTrasferenteForm';
import EmpresaRecebenteForm from './formDespacho/EmpresaRecebenteForm';
import LevantamentoFatosForm from './formDespacho/LevantamentoFatosForm';
// import ConclusaoForm from './formDespacho/ConclusaoForm'; não existe mais, commit (Relatório Técnico nova etapa Visualizar documento)
import ConfirmDialog from '../../../util/ConfirmDialog';
import MaskedInput from 'react-text-mask';
import PdfView from '../../../util/PdfView';
import HistoricoTable from '../unate/HistoricoTable';
import FluxoPanel from "../../../util/FluxoPanelBPM";
import EmpresaDocumentosTable from './formDespacho/EmpresaDocumentosTable';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`wrapped-tabpanel-${index}`}
            aria-labelledby={`wrapped-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

let acao = '';
let levantamentoTxt = '';
let conclusaoTxt = '';

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '160px',
        overflow: 'auto'
    },
    deslocar: {
        position: 'relative',
        display: 'flex',
        marginLeft: 'auto',
        top: '37px',
        right: '10px',
        zIndex: 999
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        marginLeft: "80px"
    },
}), { index: 1 });

export default function ParecerTecnicoPanel(props) {
    const classes = useStyles();
    const classesLocal = useLocalStyle();
    const [processo, setProcesso] = useState();
    const { setTitulo, setErro, setWait, assunto, setMensagem, setSuccess } = props;
    let { id } = useParams();
    const [anexos, setAnexos] = useState([]);
    const [valueIndex, setValueIndex] = useState(0);
    const [abilitaSalvar, setAbilitaSalvar] = useState(false);
    const [txtRelato, setTxtRelato] = useState('');
    const [txtLegislacao, setTxtLegislacao] = useState('');
    const [txtGerarEmpreg, setTxtGerarEmpreg] = useState('');
    const [txtDocApresentada, setDocApresentada] = useState('');
    const [txtViabFin, setTxtViabFin] = useState('');
    const [txtConclusao, setTxtConclusao] = useState('');
    const [msgConfirmacao, setMsgConfirmacao] = useState('');
    const [fluxo, setFluxo] = useState();
    const [showConfirm, setShowConfirm] = useState(false);

    const [value, setValue] = useState({
        assunto: '',
        processo: '',
        cpf: '',
        nome: '',
        telefone: '',
        email: '',
        protocolo: '',
        tipo: '',
        fluxoPath: [],
        historico: [],
        declaracao: false,
    });

    const history = useHistory();
    let [profile] = useCurrentUser();
    const param = getQueryParams(useLocation().search);
    const location = useLocation();

    useEffect(() => {
        if (profile) {
            id = param.get('id');
            if (id) return;
            setValue({
                ...value,
                nome: profile.name,
                cpf: profile.CPF,
                email: profile.email
            });
        }
    }, [profile]);


    useEffect(() => {
        setTitulo("Parecer Técnico");
        const process = localStorage.getItem("workingProcess");
        setProcesso(JSON.parse(process));
        buscaDados();
        handleFluxoChange();
    }, [profile])

    function montaUrl() {
        let ass = assunto;
        if (!ass) {
            ass = (param.get("assunto")) ? param.get("assunto") : '';
        }
        // console.log("ass ", ass)
        if (ass === 'Recurso' || ass === 'Recurso - Contra Cancelamento' || ass === 'Recurso - Contra Indeferimento' || ass === 'Recurso - outros' || ass === 'Recurso - Outros') {
            return '/api/processoProdf/recurso/'
        } else if (ass === 'Prorrogacao' || ass === 'Prorrogacao - Contra Recurso' || ass === 'Prorrogacao - Processo de Implantação' || ass === 'Prorrogacao - Análise de Documento' || ass === 'Prorrogacao - Outros') {
            return '/api/processoProdf/prorrogacao/'
        } else if (ass === 'Cancelamento') {
            return '/api/processoProdf/cancelamento/'
        } else if (ass === 'Notificacao') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else if (ass === 'Complemento') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else {
            return "/api/processoProdf/requerimento/";
        }
    }

    function buscaDados() {
        id = param.get('id');

        const process = JSON.parse(localStorage.getItem("workingProcess"));
        setProcesso(process);
        const url = montaUrl();
        // console.log("url", url)

        if (id) {
            setWait(true);
            fetch(url + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        montaValor(json);
                        console.log(json)
                        if (location.pathname.includes("view")) {
                            if (json.assunto === 'Complemento') setTitulo && setTitulo("Visualizar Complemento de Informação");
                            if (json.assunto === 'Notificacao') setTitulo && setTitulo("Visualiar Notificação");
                        } else {
                            if (json.assunto === "Diversos") {
                                setTitulo("Analisar Requerimento Diversos");
                            }
                        }
                        // buscaProtocolo(json.protocolo)
                        if (json.documentos) setAnexos(json.documentos);
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        } else {
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarrequerimentoForm");
            }
        }
    }

    const handleChangeTecnico = (event) => {
        let arr = [...anexos];
        let item = arr.find(item => (item.nome ? item.nome : item.tipo) === event.target.name);
        item.checkTecnico = event.target.checked;
        setAnexos(arr);
    }

    function montaValor(json) {
        let valor = null;
        if (!json.assunto) json.assunto = assunto ? assunto : param.get("assunto");
        if (json.requerente) {
            valor = {
                ...json,
                nome: json.requerente.nome,
                cpf: json.requerente.cpf ? json.requerente.cpf : '',
                email: json.requerente.email,
                telefone: json.requerente.telefone ? json.requerente.telefone : '',
            };
            if (json.historico.length > 0 && json.historico[0].tipo === 'EM_ANALISE') {
                valor.respCadastro = json.historico[0].responsavel
            } else {
                valor.respCadastro = json.requerente;
            }


        } else if (json.solicitante) {
            if (typeof json.processo === 'string') { // para recurso, cancelamento e prorrogacao
                //TODO NECESSARIO REFATORAR
                if (processo) {
                    json.processo = {
                        id: processo.id,
                        nProcesso: processo.dadosEmpresa.nProcesso,
                        cnpj: processo.dadosEmpresa.cnpj,
                        razaoSocial: processo.dadosEmpresa.razaoSocial
                    }
                }

            }
            valor = {
                ...json,
                nome: json.solicitante.nome,
                cpf: json.solicitante.cpf ? json.solicitante.cpf : '',
                email: json.solicitante.email,
                telefone: json.solicitante.telefone ? json.solicitante.telefone : ''
            }
            if (json.historico.length > 0 && json.historico[0].tipo === 'EM_ANALISE') {
                valor.respCadastro = json.historico[0].responsavel
            } else {
                valor.respCadastro = json.solicitante;
            }
        }
        // if (valor.assunto === 'Diversos' && valor.status !== 'PARECER_TECNICO') {
        //     const itens = valor.historico.filter(h => h.tipo === "DESPACHO");
        //     if (itens && itens.length > 0) {
        //         setTxtParecer(itens[itens.length - 1].texto);
        //     }
        // } else {
        //     valor.historico.forEach(h => {
        //         if (h.tipo === 'DEFERIDO' || h.tipo === 'INDEFERIDO' || h.tipo === 'RASCUNHO_ACESSOR') {
        //             setTxtParecer(h.texto ? h.texto : '');
        //         }
        //     })
        // }
        montaPath(valor);

        if (valor && valor.historico.length > 0) {
            let itens = valor.historico.filter(h => h.tipo === 'legislacao');
            if (itens.length > 0) setTxtLegislacao(itens[itens.length - 1].texto);
            itens = valor.historico.filter(h => h.tipo === 'breve_relato');
            if (itens.length > 0) setTxtRelato(itens[itens.length - 1].texto);
            itens = valor.historico.filter(h => h.tipo === 'doc_apresentada');
            if (itens.length > 0) setDocApresentada(itens[itens.length - 1].texto)
            itens = valor.historico.filter(h => h.tipo === 'gerar_emprego');
            if (itens.length > 0) setTxtGerarEmpreg(itens[itens.length - 1].texto);
            itens = valor.historico.filter(h => h.tipo === 'viabilibade_finaceira');
            if (itens.length > 0) setTxtViabFin(itens[itens.length - 1].texto);
            itens = valor.historico.filter(h => h.tipo === 'conclusao');
            if (itens.length > 0) setTxtConclusao(itens[itens.length - 1].texto);
        }

        setValue(valor);
        console.log(valor);
    }

    function montaPath(solicitacao) {
        if (!solicitacao.fluxoPath) {
            if (solicitacao.status === 'RASCUNHO') {
                solicitacao.fluxoPath = ["emRascunho"];
            } else {
                solicitacao.fluxoPath = ["emRascunho", "valUnate"];
            }
        }
    }


    function desabilitado() {
        if (!param.get('editable') || param.get('editable') === 'true') {
            return false;
        }
        return true;
    }



    const relatorio = () => {
        let dado = { ...value };
        fetch("/api/processoProdf/requerimento/relatorio", {
            method: "POST",
            body: JSON.stringify(dado),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                return;
            }
        }).then(body => {
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }


    const handleChange = (event, newValue) => {
        setValueIndex(newValue);
    };

    function menuAssunto() {
        // console.log(value.assunto);
        if (value.assunto == "MIGRAÇÃO + TRANSFERÊNCIA") return true;
        else if (value.assunto == "CONVALIDAÇÃO + TRANSFERÊNCIA") return true;
        else if (value.assunto == "TRANSFERÊNCIA (DESENVOLVE-DF)") return true;
        else if (value.assunto == "TRANSFERÊNCIA (PRÓ-DF II)") return true;
        else if (value.assunto == "ADESÃO DIRETA AO DESENVOLVE + TRANSFERÊNCIA") return true;
        // else if (value.assunto == "") return false;
        return false;
    }

    const proximaPage = () => {
        const x = valueIndex;
        if (!menuAssunto() && x == 0) {
            setValueIndex(x + 2);
        } else {
            setValueIndex(x + 1);
        }
        if (abilitaSalvar) {
            handleAcaoSalvarRascunho();
        }
    }
    const anteriorPage = () => {
        const x = valueIndex;
        if (!menuAssunto() && x == 2) {
            setValueIndex(x - 2);
        } else {
            setValueIndex(x - 1);
        }
        if (abilitaSalvar) {
            handleAcaoSalvarRascunho();
        }
    }

    function handleAcaoSalvarRascunho() {
        acao = 'rascunho';
        confirmaRequerimento()
    }

    const handleFluxoChange = () => {
        console.log("aquii")
        if (!fluxo) {
            let url = '/api/info/fluxoComplexo';
            if (value.assunto === 'Diversos') {
                url = '/api/info/fluxoDiversos';
            } else if (value.assunto === 'Notificacao') {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
                //     url = '/api/info/fluxoNotificacaoComplementoSimples';
                // if (value.tipo === 'Pendência de vistoria' || value.tipo === 'Prazo vencido') {
                //     url = '/api/info/fluxoNotificacaoComplementoComplexo';
                // }
            } else if (value.assunto && value.assunto === 'Complemento') {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
            }
            fetch(url)
                .then(response => response.json())
                .then(json => setFluxo(json)).catch(erro => setErro(erro));
        }
    }

    function confirmaRequerimento() {

        let dado = {
            id: value.id,
            assunto: value.assunto,
            protocolo: value.protocolo,
            documentos: anexos,
            processo: value.processo
        }
        let txt = "Salvar Rascunho alteração Site/Email";
        dado.documentos = anexos;
        let histipo = 'RASCUNHO';

        console.log("abilitaSalvar", abilitaSalvar.typography)
        if (abilitaSalvar && abilitaSalvar.length) {
            let hist = [];
            for (let x in abilitaSalvar) {
                switch (abilitaSalvar[x]) {
                    case "breve_relato":
                        txt = txtRelato;
                        break;
                    case "legislacao":
                        txt = txtLegislacao;
                        break;
                    case "gerar_emprego":
                        txt = txtDocApresentada;
                        break;
                    case "viabilibade_finaceira":
                        txt = txtViabFin;
                        break;
                    case "gerar_emprego":
                        txt = txtGerarEmpreg;
                        break;
                    case "conclusao":
                        txt = txtConclusao;
                        break;
                    default:
                        break;
                }
                hist.push({
                    tipo: abilitaSalvar[x],
                    data: new Date(),
                    texto: txt,
                    responsavel: {
                        idUser: profile.id,
                        nome: profile.name,
                        email: profile.email
                    }
                });
            }
            dado.historico = hist;
        } else {
            dado.historico = [{
                tipo: histipo,
                data: new Date(),
                texto: txt,
                responsavel: {
                    idUser: profile.id,
                    nome: profile.name,
                    email: profile.email
                }
            }];
        }


        dado.status = "PARECER_TECNICO";
        if (acao === 'deferir') {
            dado.status = 'DEFERIDO';
        }
        // console.log(dado)
        submitForm(dado);
    }

    function submitForm(dado) {
        setWait(true);
        let url = '/api/processoProdf/requerimento/posCreate';
        if (dado.assunto === 'Complemento' || dado.assunto === 'Notificacao') {
            url = '/api/processoProdf/notificacaoComplemento/posCreate';
        }
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                if (acao === 'rascunho') {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    setSuccess("Rascunho salvo com sucesso.")
                    setAbilitaSalvar(false);
                } else {
                    history.push("/gerenciaProcesso/semp/avaliaRequerimento");
                }
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function handleAcao(cmd) {

        acao = cmd;
        if (acao === 'deferir') {
            setMsgConfirmacao("Confirma deferir a solicitação?");
            setShowConfirm(true);
        } else if (acao === 'indeferir') {
            setMsgConfirmacao("Confirma indeferir a solicitação?");
            setShowConfirm(true);
        }

    }


    function showParecerTecnico() {
        if (value.assunto === 'Notificacao') return false;
        if (value.assunto === 'Complemento') return false;
        return true;
    }

    function getassunt() {
        if (value.assunto === 'Complemento') {
            return 'Complemento de Informação  '
        }
        if (isAssuntoDiversos()) {
            return value.assunto;
        } if (value.assunto === 'Cancelamento' || value.assunto === 'Complemento') {
            return value && value.assunto || '';
        }
        else {
            return value && value.assunto + (value.tipo ? (" - " + value.tipo) : '');
        }
    }
    function isAssuntoDiversos() {
        return value.assunto === 'Diversos';
    }

    return (
        <Container maxWidth='lg'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={4}>
                        <TextField
                            value={value.processo && value.processo.programa || ''}
                            disabled
                            fullWidth
                            label="Programa"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={value.processo && value.processo.nProcesso || ''}
                            disabled
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            // onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={value && value.protocolo || ''}
                            disabled
                            fullWidth
                            label="Protocolo"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={value.dataCriacao ? FormataDataComHora(new Date(value.dataCriacao)) : ''}
                            disabled
                            fullWidth
                            label="Data Solicitação"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            value={value.processo && value.processo.cnpj || ''} disabled
                            fullWidth
                            label="CNPJ"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField disabled
                            value={value.processo && value.processo.razaoSocial || ''}
                            fullWidth
                            label="Razão Social"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    {showParecerTecnico() &&
                        <Grid item xs={4}>
                            <TextField
                                disabled
                                label="CPF"
                                value={value.cpf}
                                // error={error.cpf.erro}
                                // helperText={error.cpf.msg}
                                name="cpf"
                                id="cpf-mask-input"
                                fullWidth
                                InputProps={{
                                    inputComponent: CPFMask,
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    }
                    {showParecerTecnico() &&
                        <Grid item xs={8}>
                            <TextField disabled
                                value={value.nome}
                                fullWidth
                                label="Requerente"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            value={getassunt()}
                            disabled
                            fullWidth
                            label="Assunto"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    {value && value.descricao &&
                        <Grid item xs={12}>
                            <Paper className={classesLocal.paper}>
                                <span dangerouslySetInnerHTML={{
                                    __html: value.descricao ? value.descricao : ''
                                }}></span>
                            </Paper>
                        </Grid>
                    }
                </Grid>
            </Paper>
            <AppBar position="static">
                <Tabs
                    value={valueIndex}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab value={0} label="Empresa Trasferente" wrapped {...a11yProps(0)} />
                    {menuAssunto() && <Tab value={1} label="Empresa Recebente" wrapped {...a11yProps(1)} />}
                    <Tab value={2} label="Levantamento dos Fatos" wrapped {...a11yProps(2)} />
                    <Tab value={3} label="Documentação" wrapped {...a11yProps(3)} />
                    <Tab value={4} label="Visualizar documento" wrapped {...a11yProps(4)} />
                    <Tab value={5} label="Histórico" wrapped {...a11yProps(5)} />
                    <Tab value={6} label="Fluxo" wrapped {...a11yProps(6)} />
                    {/* <Tab value={7} label="Documentação" wrapped {...a11yProps(6)} /> */}
                </Tabs>
            </AppBar>
            <TabPanel value={valueIndex} index={0}>
                <EmpresaTrasferenteForm value={value} setValue={setValue} setMensagem={setMensagem} setWait={setWait} setAbilitaSalvar={setAbilitaSalvar} disabled={desabilitado()} />
            </TabPanel>
            <TabPanel value={valueIndex} index={1}>
                <EmpresaRecebenteForm value={value} setValue={setValue} setMensagem={setMensagem} setWait={setWait} setAbilitaSalvar={setAbilitaSalvar} disabled={desabilitado()} />
            </TabPanel>
            <TabPanel value={valueIndex} index={2}>
                <LevantamentoFatosForm
                    textoRelato={levantamentoTxt} value={value} disabled={desabilitado()}
                    handleChangeTecnico={handleChangeTecnico}
                    setWait={setWait} setMensagem={setMensagem}
                    setAbilitaSalvar={setAbilitaSalvar} abilitaSalvar={abilitaSalvar}
                    txtRelato={txtRelato} setTxtRelato={setTxtRelato}
                    txtLegislacao={txtLegislacao} setTxtLegislacao={setTxtLegislacao}
                    txtGerarEmpreg={txtGerarEmpreg} setTxtGerarEmpreg={setTxtGerarEmpreg}
                    txtViabFin={txtViabFin} setTxtViabFin={setTxtViabFin}
                    txtConclusao={txtConclusao} setTxtConclusao={setTxtConclusao}
                    txtDocApresentada={txtDocApresentada} setDocApresentada={setTxtConclusao}
                />
            </TabPanel>
            <TabPanel value={valueIndex} index={3}>
                <EmpresaDocumentosTable anexos={anexos} disabled={desabilitado()} />
                {/* <ConclusaoForm textoRelato={conclusaoTxt} setAbilitaSalvar={setAbilitaSalvar} setTxtHistorico={setTxtHistorico} disabled={desabilitado()} /> */}
            </TabPanel>
            <TabPanel value={valueIndex} index={4}>
                <PdfView setWait={setWait} setMensagem={setMensagem} setErro={setErro}
                    url={"/api/processoProdf/requerimento/relatorio/" + value.id}
                />
                {/* <ConclusaoForm textoRelato={conclusaoTxt} setAbilitaSalvar={setAbilitaSalvar} setTxtHistorico={setTxtHistorico} disabled={desabilitado()} /> */}
            </TabPanel>
            <TabPanel value={valueIndex} index={5}>
                <HistoricoTable dados={value.historico} protocolo={value.protocolo} setWait={setWait} setMensagem={setMensagem} />
                {/* <ConclusaoForm textoRelato={conclusaoTxt} setAbilitaSalvar={setAbilitaSalvar} setTxtHistorico={setTxtHistorico} disabled={desabilitado()} /> */}
            </TabPanel>
            <TabPanel value={valueIndex} index={6}>
                <FluxoPanel fluxo={fluxo} path={value.fluxoPath} />
                {/* <ConclusaoForm textoRelato={conclusaoTxt} setAbilitaSalvar={setAbilitaSalvar} setTxtHistorico={setTxtHistorico} disabled={desabilitado()} /> */}
            </TabPanel>


            <Grid container spacing={1} className={classes.root}>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" onClick={() => history.push("/gerenciaProcesso/semp/avaliaRequerimento")} rel="noopener noreferrer">
                        Sair
                    </Button>
                    {/* <Button variant="contained" color="primary" onClick={relatorio} >
                        Imprimir
                    </Button> */}
                    {(!desabilitado() && valueIndex != 4) && <Button variant="contained" color="primary" onClick={() => handleAcaoSalvarRascunho()} disabled={!abilitaSalvar} >
                        Salvar Rascunho
                    </Button>}
                    {valueIndex != 0 && <Button variant="contained" color="primary" onClick={anteriorPage} >
                        Voltar
                    </Button>}
                    {valueIndex < 4 && <Button variant="contained" color="primary" onClick={proximaPage} >
                        Proximo
                    </Button>}
                    {(valueIndex == 4 && !desabilitado()) &&
                        <Button variant="contained" color="primary" onClick={() => handleAcao('indeferir')} >
                            Invalidar
                        </Button>
                    }
                    {(valueIndex == 4 && !desabilitado()) && <Button variant="contained" color="primary" onClick={() => handleAcao('deferir')} >
                        Validar
                    </Button>}

                </Grid>
            </Grid>
            <ConfirmDialog
                open={showConfirm}
                setOpen={setShowConfirm}
                titulo={'Confirmação'}
                msg={msgConfirmacao}
                exec={confirmaRequerimento}
            />
        </Container>
    );
}
