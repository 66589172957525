import React, { useEffect, useState, } from 'react';
import {
    Button, Grid, Paper, Container, Card, CardHeader, CardContent, TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Typography, Dialog, DialogTitle, DialogContent, DialogActions
}
    from '@material-ui/core';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, FormataData } from "../../../constants"
import Service from '../../../services/Service';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import NumberFormat from 'react-number-format';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import GenericTable from '../../template/GenericTable';
import EtapaProcessoComp from './EtapaProcessoComp';

import { ptBR } from 'date-fns/locale';
const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

const programa = ['PROIN', 'PRODECON', 'PRO-DF', 'PRO-DFII', 'DESENVOLVEDF'];

export default function CadastroContratoTerracapForm(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setErro, setMensagem } = props;
    const [id, setId] = useState();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [processo, setProcesso] = useState({});
    const [dataAssinatura, setDataAssinatura] = useState();
    const [openDialog, setOpenDialog] = useState(false);
    const [dataDescontoMaximo, setDataDescontoMaximo] = useState();
    const [dataDescontoMinimo, setDataDescontoMinimo] = useState();
    const [programaDisponiveis, setProgramaDisponiveis] = React.useState([]);
    const [dataVencimento, setDataVencimento] = useState();
    const [dataResolucao, setDataResolucao] = useState();
    const [aditivo, setAditivo] = useState();
    const [dadosAlterados, setDadosAlterados] = useState();

    useEffect(() => {
        setTitulo("Cadastrar Contrato Terracap");
        buscarPrograma();
        if (param.get("id")) {
            setId(param.get("id"));
            Service.GET("/api/processoProdf/" + param.get("id")).then(result => {
                setWait(false);
                setProcesso(result);
                
                if (result.contratoTerracap) {
                    setDadosAlterados({
                        resolucao: result.contratoTerracap.resolucao,
                        migracaoSimOuNao: result.contratoTerracap.migracaoSimOuNao,
                        programaProin: result.contratoTerracap.programaProin,
                        nContrato: result.contratoTerracap.nContrato,
                        valorImovel: result.contratoTerracap.valorImovel,
                        dataAssinatura: result.contratoTerracap.dataAssinatura,
                        dataResolucao: result.contratoTerracap.dataResolucao,
                        dataDescontoMaximo: result.contratoTerracap.dataDescontoMaximo,
                        dataDescontoMinimo: result.contratoTerracap.dataDescontoMinimo,
                        dataVencimento: result.contratoTerracap.dataVencimento,

                    });
                }
                if (result.contratoTerracap) {
                    setDataAssinatura(formatarDataString(result.contratoTerracap.dataAssinatura));
                    setDataDescontoMaximo(formatarDataString(result.contratoTerracap.dataDescontoMaximo));
                    setDataDescontoMinimo(formatarDataString(result.contratoTerracap.dataDescontoMinimo));
                    setDataVencimento(formatarDataString(result.contratoTerracap.dataVencimento));
                }
            }).catch(error => {
                setWait(false);
                setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
            });
        }
    }, []);
    function formatarDataString(d) {
        const data = d.split("/");
        if (data.length === 3) {
            return new Date(data[2], (data[1] - 1), data[0]);
        }
        return '';
    }


    function buscarPrograma() {

        Service.GET('/api/info/programa').then(result => {
            setWait(false);
            setProgramaDisponiveis(result);
            
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
        

    }
    function sair() {
        if (id) history.replace("/gerenciaProcesso/processo/" + id + "?panel=contratoTerracap");
        else history.replace("/gerenciaProcesso/cadastrarProcesso/?panel=contratoTerracap");
    }
    function handleSalvar() {
        if (processo.contratoTerracap) {
            if (
                (processo.contratoTerracap.migracaoSimOuNao &&
                    dadosAlterados.migracaoSimOuNao !== processo.contratoTerracap.migracaoSimOuNao) ||
                (processo.contratoTerracap.migracaoSimOuNao &&
                    dadosAlterados.programaProin !== processo.contratoTerracap.programaProin) ||
                (processo.contratoTerracap.migracaoSimOuNao &&
                    dadosAlterados.nContrato !== processo.contratoTerracap.nContrato) ||
                (processo.contratoTerracap.migracaoSimOuNao &&
                    dadosAlterados.valorImovel !== processo.contratoTerracap.valorImovel) ||
                (processo.contratoTerracap.migracaoSimOuNao &&
                    dadosAlterados.dataAssinatura !== dataAssinatura) ||
                (processo.contratoTerracap.migracaoSimOuNao &&
                    dadosAlterados.dataResolucao !== dataResolucao) ||
                (processo.contratoTerracap.migracaoSimOuNao  &&
                    dadosAlterados.dataDescontoMaximo !== dataDescontoMaximo) ||
                (processo.contratoTerracap.migracaoSimOuNao &&
                    dadosAlterados.dataDescontoMinimo !== dataDescontoMinimo) ||
                (processo.contratoTerracap.migracaoSimOuNao &&
                    dadosAlterados.dataVencimento !== dataVencimento)

            ) {
                setOpenDialog(true);
            }
            else {
                salvar()
            }
        } else {
            salvar()
        }
    }

    function salvar() {
        let arrHisAlte = [];
        if (processo.contratoTerracap) {
            if (processo.contratoTerracap.historico) arrHisAlte = processo.contratoTerracap.historico;
            if (
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.resolucao !== processo.contratoTerracap.resolucao) ||
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.migracaoSimOuNao !== processo.contratoTerracap.migracaoSimOuNao) ||
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.programaProin !== processo.contratoTerracap.programaProin) ||
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.nContrato !== processo.contratoTerracap.nContrato) ||
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.valorImovel !== processo.contratoTerracap.valorImovel) ||
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.dataAssinatura !== processo.contratoTerracap.dataAssinatura) ||
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.dataResolucao !== processo.contratoTerracap.dataResolucao) ||
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.dataDescontoMaximo !== processo.contratoTerracap.dataDescontoMaximo) ||
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.dataDescontoMinimo !== processo.contratoTerracap.dataDescontoMinimo) ||
                (processo.contratoTerracap.resolucao &&
                    dadosAlterados.dataVencimento !== processo.contratoTerracap.dataVencimento)

            ){                              
                arrHisAlte.push({
                    resolucao: processo.contratoTerracap.resolucao,
                    migracaoSimOuNao: processo.contratoTerracap.migracaoSimOuNao,
                    programaProin: processo.contratoTerracap.programaProin,
                    nContrato: processo.contratoTerracap.nContrato,
                    dataAssinatura: processo.contratoTerracap.dataAssinatura,
                    dataResolucao: dataResolucao? FormataData(dataResolucao) : "",
                    valorImovel: processo.contratoTerracap.valorImovel,
                    dataDescontoMaximo: processo.contratoTerracap.dataDescontoMaximo,
                    dataDescontoMinimo: processo.contratoTerracap.dataDescontoMinimo,
                    dataVencimento: processo.contratoTerracap.dataVencimento
                });
            }
                
        }
        setOpenDialog(false)
        const dados = {
            processo: {
                ...processo,
                contratoTerracap: {
                    ...processo.contratoTerracap,
                    ...dadosAlterados,
                    dataAssinatura: dataAssinatura ? FormataData(dataAssinatura) : "",
                    dataDescontoMaximo: dataDescontoMaximo ? FormataData(dataDescontoMaximo) : "",
                    dataDescontoMinimo: dataDescontoMinimo ? FormataData(dataDescontoMinimo) : "",
                    dataVencimento: dataVencimento ? FormataData(dataVencimento) : "",
                    historico: arrHisAlte
                }
            }
        }
        // return;
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=contratoTerracap");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }
    const handleChangeInputForm = (event) => {
        setProcesso({
            ...processo,
            contratoTerracap: {
                ...processo.contratoTerracap,
                [event.target.name]: event.target.value,
            }
        });
    };
    const handleChangeInputFormDataAssinatura = (date) => {
        setDataAssinatura(date)
    }
    const handleChangeInputFormDataDescontoMaximo = (date) => {
        setDataDescontoMaximo(date)
    }
    const handleChangeInputFormDataDescontoMinimo = (date) => {
        setDataDescontoMinimo(date)
    }
    const handleChangeInputFormDataVencimento = (date) => {
        setDataVencimento(date)
    }
    const handleChangeInputFormAditivo = (date) => {
        setDataResolucao(date)
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleChangeInputFormDadosAlterados = (event) => {
        setDadosAlterados({
            ...dadosAlterados,
            [event.target.name]: event.target.value,
        });
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='md'>
                <Card style={{ marginTop: '5px' }}>
                    <CardHeader title="Dados do Contrato" />
                    <CardContent>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={4}>
                                <FormControl component="fieldset">
                                    <Typography className={classes.heading} gutterBottom>Migração:</Typography>
                                    <RadioGroup row value={dadosAlterados && dadosAlterados.migracaoSimOuNao || ''} aria-label="migracaoSimOuNao" onChange={handleChangeInputFormDadosAlterados} name="migracaoSimOuNao" defaultValue="top">
                                        <FormControlLabel
                                            value="Sim"
                                            control={<Radio color="primary" />}
                                            label="Sim"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Não"
                                            control={<Radio color="primary" />}
                                            label="Não"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <FormControl variant="outlined" fullWidth disabled={false}>
                                    <InputLabel id="programaProin" >Programa</InputLabel>
                                    <Select
                                        id="programaProin"
                                        name='programaProin'
                                        label="Programa"
                                        placceholder="programaProin"
                                        onChange={handleChangeInputFormDadosAlterados}
                                        value={dadosAlterados && dadosAlterados.programaProin || ''}
                                    >
                                        {
                                            programaDisponiveis && programaDisponiveis.map((tp, i) => (
                                                <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={dadosAlterados && dadosAlterados.nContrato || ''}
                                    onChange={handleChangeInputFormDadosAlterados}
                                    fullWidth
                                    label="Nº Contrato"
                                    id="nContrato"
                                    name="nContrato"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataAssinatura"
                                    label="Data de Assinatura"
                                    format="dd/MM/yyyy"
                                    name="dataAssinatura"
                                    value={dataAssinatura || null}
                                    onChange={handleChangeInputFormDataAssinatura}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={dadosAlterados && dadosAlterados.valorImovel || ''}
                                    onChange={handleChangeInputFormDadosAlterados}
                                    fullWidth
                                    label="Valor Imóvel"
                                    id="valorImovel"
                                    name="valorImovel"
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        inputComponent: MonetarioField
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {processo && processo.dadosEmpresa && processo.dadosEmpresa.nProcesso &&
                                    <EtapaProcessoComp nProcesso={processo.dadosEmpresa.nProcesso} indice={6} />}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card style={{ marginTop: '5px' }}>
                    <CardHeader title="Descontos" />
                    <CardContent>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataDescontoMaximo"
                                    label="Data Desconto Máximo"
                                    format="dd/MM/yyyy"
                                    name="dataDescontoMaximo"
                                    value={dataDescontoMaximo || null}
                                    onChange={handleChangeInputFormDataDescontoMaximo}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataDescontoMinimo"
                                    label="Data Desconto Mínimo"
                                    format="dd/MM/yyyy"
                                    name="dataDescontoMinimo"
                                    value={dataDescontoMinimo || null}
                                    onChange={handleChangeInputFormDataDescontoMinimo}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataVencimento"
                                    label="Data Vencimento"
                                    format="dd/MM/yyyy"
                                    name="dataVencimento"
                                    value={dataVencimento || null}
                                    onChange={handleChangeInputFormDataVencimento}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid item xs={12}>
                    <h3>Aditivos</h3>
                    {/* <TabelaComponent dados={processo && processo.contratoTerracap && processo.contratoTerracap.historico || []}>
                        <CelulaCabecalhoTabelaComponent identificador='resolucao' titulo="Resolução" />
                        <CelulaCabecalhoTabelaComponent identificador='dataResolucao' titulo="dataResolucao" />
                        <CelulaCabecalhoTabelaComponent identificador='migracaoSimOuNao' titulo="Migração" />
                        <CelulaCabecalhoTabelaComponent identificador='programaProin' titulo="Programa" />
                        <CelulaCabecalhoTabelaComponent identificador='nContrato' titulo="Nº Contrato" />
                        <CelulaCabecalhoTabelaComponent identificador='dataAssinatura' titulo="Data de Assinatura" />
                        <CelulaCabecalhoTabelaComponent identificador='valorImovel' titulo="Valor Imóvel" />
                        <CelulaCabecalhoTabelaComponent identificador='dataDescontoMaximo' titulo="Data desconto máximo" />
                        <CelulaCabecalhoTabelaComponent identificador='dataDescontoMinimo ' titulo="Data desconto mínimo" />
                        <CelulaCabecalhoTabelaComponent identificador='dataVencimento' titulo="Data vencimento" />                  
                    </TabelaComponent> */}
                    <GenericTable
                        disableSearch
                        dados={processo && processo.contratoTerracap && processo.contratoTerracap.historico || []}
                        colunas={[
                            { nome: 'Resolução', campo: 'resolucao' },
                            { nome: 'Data Resolução', campo: 'dataResolucao' },
                            { nome: 'Migração', campo: 'migracaoSimOuNao' },
                            { nome: 'Programa', campo: 'programaProin' },
                            { nome: 'Nº Contrato', campo: 'nContrato' },
                            { nome: 'Data de Assinatura', campo: 'dataAssinatura' },
                            { nome: 'Valor Imóvel', campo: 'valorImovel' },
                            { nome: 'Data Desconto Máximo', campo: 'dataDescontoMaximo' },
                            { nome: 'Data Desconto Mínimo', campo: 'dataDescontoMinimo' },
                            { nome: 'Data Vencimento', campo: 'dataVencimento' },
                        ]}
                    />
                </Grid>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                        Sair
                    </Button>
                    <Button variant="contained" onClick={() => handleSalvar()} color="primary">
                        Salvar
                    </Button>
                </Grid>

            </Container>
            <Grid>
                <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth >
                    <DialogTitle id="form-dialog-title">Preencha os campos</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={1} className={classes.root}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="resolucao"
                                    name="resolucao"
                                    placeholder="Resolução"
                                    rows={6}
                                    value={processo && processo.contratoTerracap && processo.contratoTerracap.resolucao || ''}
                                    onChange={handleChangeInputForm}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataResolucao"
                                    label="Data Resolução"
                                    format="dd/MM/yyyy"
                                    name="dataResolucao"
                                    value={dataResolucao || null}
                                    onChange={handleChangeInputFormAditivo}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => salvar()} >
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </MuiPickersUtilsProvider>


    );
}