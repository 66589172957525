import React, { useEffect, useState } from 'react';
import {
    Grid, Button, Paper, Accordion, AccordionSummary, Typography, AccordionDetails,
    MenuItem, Select,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, FormataMoeda } from '../../../constants';
import GenericTable from '../../template/GenericTable';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

let totalProdf = 0, totalProdfNP = 0, totalIDEAS = 0, totalIDEASNP = 0, totalFIDE = 0, totalFIDENP = 0,
    totalExecIDEAS = 0, totalExecIDEASNP = 0, totalExecPRODF = 0, totalExecPRODFNP = 0, totalExecFIDE = 0,
    totalExecFIDENP = 0, totalQtdIDEASNP = 0, totalQtdIDEAS = 0, totalQtdPRODF = 0, totalQtdPRODFNP = 0, totalQtdFIDE = 0, totalQtdFIDENP = 0;

export default function ConsolidadoLiberacaoTable(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait } = props;
    const [todosNovosPrjetos, setTodosNovosPrjetos] = useState();
    const [resumoEmpresa, setResumoEmpresa] = useState([]);
    const [resumoEmpresaTotal, setResumoEmpresaTotal] = useState([]);

    const [resumoEmpresaNP, setResumoEmpresaNP] = useState([]);
    const [resumoEmpresaTotalNP, setResumoEmpresaTotalNP] = useState([]);

    const [beneficiadosLocalidade, setBeneficiadosLocalidade] = useState([]);
    const [beneficiadosSetor, setBeneficiadosSetor] = useState([]);
    const [beneficiadosSetorTotal, setBeneficiadosSetorTotal] = useState();

    const [beneficiadosSetorNP, setBeneficiadosSetorNP] = useState([]);
    const [beneficiadosSetorTotalNP, setBeneficiadosSetorTotalNP] = useState();

    const [beneficiadosLocalTotal, setBeneficiadosLocalTotal] = useState();

    const [beneficiadosLocalidadeNP, setBeneficiadosLocalidadeNP] = useState([]);
    const [beneficiadosLocalTotalNP, setBeneficiadosLocalTotalNP] = useState();

    const [planejamentoPRODF, setPlanejamentoPRODF] = useState([]);
    const [planejamentoPRODFTotal, setPlanejamentoPRODFTotal] = useState();

    const [planejamentoPRODFNP, setPlanejamentoPRODFNP] = useState([]);
    const [planejamentoPRODFTotalNP, setPlanejamentoPRODFTotalNP] = useState();

    // const [resumoLibaracaoTotalNP, setResumoLibaracaoTotalNP] = useState([]);
    const [planejamentoFIDE, setPlanejamentoFIDE] = useState([]);
    const [planejamentoFIDETotal, setPlanejamentoFIDETotal] = useState();

    const [planejamentoFIDENP, setPlanejamentoFIDENP] = useState([]);
    const [planejamentoFIDETotalNP, setPlanejamentoFIDETotalNP] = useState();

    const [planejamentoIDEAS, setPlanejamentoIDEAS] = useState([]);
    const [planejamentoIDEASTotal, setPlanejamentoIDEASTotal] = useState();

    const [planejamentoIDEASNP, setPlanejamentoIDEASNP] = useState([]);
    const [planejamentoIDEASTotalNP, setPlanejamentoIDEASTotalNP] = useState();

    const [planejamentoProgramas, setPlanejamentoProgramas] = useState([]);
    const [planejamentoProgramasTotal, setPlanejamentoProgramasTotal] = useState();

    const [planejamentoProgramasNP, setPlanejamentoProgramasNP] = useState([]);
    const [planejamentoProgramasTotalNP, setPlanejamentoProgramasTotalNP] = useState();

    const [parcelas, setParcelas] = useState();
    const [exercicio, setExcercicio] = useState();
    const [lstExercicio, setLstExercicio] = useState([]);

    useEffect(() => {
        setTitulo("Resumo Consolidado Execução Orçamentária FUNDEFE (Liberações)");
        buscaTodasParcelas();
        buscarTodosNovosProjetos();
        totalProdf = 0;
        totalIDEAS = 0;
        totalFIDE = 0;
    }, []);
    useEffect(() => {
        if (exercicio) {
            recalcula();
        }
    }, [exercicio]);

    function buscarTodosNovosProjetos() {
        fetch('/api/fundefeNovoProjeto')
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            setTodosNovosPrjetos(json);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function buscaTodasParcelas() {
        fetch('/api/fundefeParcelas')
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            setParcelas(json);
                            let arr = [];
                            for (let x in json) {
                                const p = json[x].previsao;
                                if (!arr.find(e => e === p.exercicio)) {
                                    arr.push(p.exercicio);
                                }
                            }
                            setLstExercicio(arr);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }

   
    function getTotalPrevisaoNP(programa) {
        let val = 0;
        const item = todosNovosPrjetos.find(np => np.programa === programa && np.exercicio === exercicio);
        if (item) {
            val = item.valorOrcamento;
        }
        return val;
    }

    function somaValorExecutado(parcelas) {
        let total = 0;
        for (let x in parcelas) {
            const p = parcelas[x];
            total += p.valorParcela;
        }
        return total;
    }
    function recalcula() {
        totalProdf = 0; totalProdfNP = 0; totalIDEAS = 0; totalIDEASNP = 0; totalFIDE = 0; totalFIDENP = 0;
        totalExecIDEAS = 0; totalExecIDEASNP = 0; totalExecPRODF = 0; totalExecPRODFNP = 0; totalExecFIDE = 0;
        totalExecFIDENP = 0; totalQtdIDEASNP = 0; totalQtdIDEAS = 0; totalQtdPRODF = 0; totalQtdPRODFNP = 0; totalQtdFIDE = 0; totalQtdFIDENP = 0;

        let arr = [];
        let arrNP = [];
        let arrSetor = [];
        let arrSetorNP = [];
        let arrLocalicade = [];
        let arrLocalicadeNP = [];

        let arrResumoPlanejamentoProdf = [];
        let arrResumoPlanejamentoProdfNP = [];
        let arrResumoPlanejamentoFIDE = [];
        let arrResumoPlanejamentoFIDENP = [];
        let arrResumoPlanejamentoIDEAS = [];
        let arrResumoPlanejamentoIDEASNP = [];

        let total = 0;
        let totalNP = 0;
        let valorExecutadoTotal = 0;
        let valorExecutadoTotalNP = 0;
        let qtdTotal = 0;
        let qtdTotalNP = 0;
        const parelaFiltrada = parcelas.filter(p => p.previsao.exercicio === exercicio);
        for (let x in parelaFiltrada) {
            const p = parelaFiltrada[x];
            const valorExecutado = somaValorExecutado(p.parcelas);
            
                addArr(arr, p, valorExecutado);
                if (p.previsao.programa === 'PRODF') {
                    addArr(arrResumoPlanejamentoProdf, p, valorExecutado);
                    totalProdf += p.previsao.valor;
                    totalExecPRODF += valorExecutado;
                    totalQtdPRODF += p.parcelas.length;
                } else if (p.previsao.programa === 'FIDE') {
                    addArr(arrResumoPlanejamentoFIDE, p, valorExecutado);
                    totalFIDE += p.previsao.valor;
                    totalExecFIDE += valorExecutado;
                    totalQtdFIDE += p.parcelas.length;
                } else if (p.previsao.programa === 'IDEAS') {
                    addArr(arrResumoPlanejamentoIDEAS, p, valorExecutado);
                    totalIDEAS += p.previsao.valor;
                    totalExecIDEAS += valorExecutado;
                    totalQtdIDEAS += p.parcelas.length;
                }
                recalculaSetor(arrSetor, p);
                recalculaLocalidade(arrLocalicade, p);
                setPlanejamentoIDEASTotal(["TOTAL", "", "", "", "", "", "", FormataMoeda(totalIDEAS), FormataMoeda(totalExecIDEAS), totalIDEAS > 0 && formataPorcentagem((totalExecIDEAS * 100) / totalIDEAS), totalQtdIDEAS]);
                setPlanejamentoPRODFTotal(["TOTAL", "", "", "", "", "", "", FormataMoeda(totalProdf), FormataMoeda(totalExecPRODF), totalProdf > 0 && formataPorcentagem((totalExecPRODF * 100) / totalProdf), totalQtdPRODF]);
                setPlanejamentoFIDETotal(["TOTAL", "", "", "", "", "", "", FormataMoeda(totalFIDE), FormataMoeda(totalExecFIDE), totalFIDE > 0 && formataPorcentagem((totalExecFIDE * 100) / totalFIDE), totalQtdFIDE]);
                valorExecutadoTotal += valorExecutado;
                total += p.previsao.valor;
                qtdTotal += p.parcelas.length;
            
                recalculaSetor(arrSetorNP, p);
                recalculaLocalidade(arrLocalicadeNP, p);
                setPlanejamentoPRODFTotalNP(["TOTAL", "", "", "", "", "", FormataMoeda(totalProdfNP), FormataMoeda(totalExecPRODFNP), totalProdfNP > 0 && formataPorcentagem((totalExecPRODFNP * 100) / totalProdfNP), totalQtdPRODFNP]);
                setPlanejamentoFIDETotalNP(["TOTAL", "", "", "", "", "", FormataMoeda(totalFIDENP), FormataMoeda(totalExecFIDENP), totalFIDENP > 0 && formataPorcentagem((totalExecFIDENP * 100) / totalFIDENP), totalQtdFIDENP]);
                setPlanejamentoIDEASTotalNP(["TOTAL", "", "", "", "", "", FormataMoeda(totalIDEASNP), FormataMoeda(totalExecIDEASNP), totalIDEASNP > 0 && formataPorcentagem((totalExecIDEASNP * 100) / totalIDEASNP), totalQtdIDEASNP]);
                valorExecutadoTotalNP += valorExecutado;
                totalNP += p.previsao.valor;
                qtdTotalNP += p.parcelas.length;
            
        }

        setResumoEmpresa(arr);
        setResumoEmpresaTotal(["TOTAL", "", "", "", "", "", "", "", FormataMoeda(total), FormataMoeda(valorExecutadoTotal),
            formataPorcentagem((valorExecutadoTotal * 100) / total), qtdTotal]
        );

        setResumoEmpresaNP(arrNP);
        setResumoEmpresaTotalNP(["TOTAL", "", "", "", "", "", "", FormataMoeda(totalNP), FormataMoeda(valorExecutadoTotalNP),
            formataPorcentagem((valorExecutadoTotalNP * 100) / totalNP), qtdTotalNP]
        );

        setBeneficiadosSetor(arrSetor);
        setBeneficiadosSetorNP(arrSetorNP);
        setBeneficiadosLocalidade(arrLocalicade);
        setBeneficiadosLocalidadeNP(arrLocalicadeNP);
        setPlanejamentoPRODF(arrResumoPlanejamentoProdf);
        setPlanejamentoPRODFNP(arrResumoPlanejamentoProdfNP);
        setPlanejamentoFIDE(arrResumoPlanejamentoFIDE);
        setPlanejamentoFIDENP(arrResumoPlanejamentoFIDENP);
        setPlanejamentoIDEAS(arrResumoPlanejamentoIDEAS);
        setPlanejamentoIDEASNP(arrResumoPlanejamentoIDEASNP);
        // setNovoProjeto(arrNovosProjetos);
        setBeneficiadosLocalTotal(calculoTotal(arrLocalicade));
        setBeneficiadosLocalTotalNP(calculoTotal(arrLocalicadeNP));
        setBeneficiadosSetorTotal(calculoTotal(arrSetor));
        setBeneficiadosSetorTotalNP(calculoTotal(arrSetorNP));
        setPlanejamentoProgramas([
            { programa: "PRODF", valorPrevisto: totalProdf, valor: totalExecPRODF, perExecucao: totalExecPRODF > 0 ? (totalExecPRODF * 100) / totalProdf : 0 },
            { programa: "FIDE", valorPrevisto: totalFIDE, valor: totalExecFIDE, perExecucao: totalExecFIDE > 0 ? (totalExecFIDE * 100) / totalFIDE : 0 },
            { programa: "IDEAS", valorPrevisto: totalIDEAS, valor: totalExecIDEAS, perExecucao: totalExecIDEAS > 0 ? (totalExecIDEAS * 100) / totalIDEAS : 0 }
        ]);
        setPlanejamentoProgramasNP([
            { programa: "PRODF", valorPrevisto: totalProdfNP, valor: totalExecPRODFNP, perExecucao: totalExecPRODFNP > 0 ? (totalExecPRODFNP * 100) / totalProdfNP : 0 },
            { programa: "FIDE", valorPrevisto: totalFIDENP, valor: totalExecFIDENP, perExecucao: totalExecFIDENP > 0 ? (totalExecFIDENP * 100) / totalFIDENP : 0 },
            { programa: "IDEAS", valorPrevisto: totalIDEASNP, valor: totalExecIDEASNP, perExecucao: totalExecIDEASNP > 0 ? (totalExecIDEASNP * 100) / totalIDEASNP : 0 }
        ]);
        const tt = totalProdf + totalIDEAS + totalFIDE;
        const te = totalExecPRODF + totalExecIDEAS + totalExecFIDE;
        const ttNP = totalProdfNP + totalIDEASNP + totalFIDENP;
        const teNP = totalExecPRODFNP + totalExecIDEASNP + totalExecFIDENP;
        setPlanejamentoProgramasTotal(["Total", FormataMoeda(tt), FormataMoeda(te), formataPorcentagem((te * 100) / tt)]);
        setPlanejamentoProgramasTotalNP(["Total", FormataMoeda(ttNP), FormataMoeda(teNP), formataPorcentagem((teNP * 100) / ttNP)]);
    }
    function calculoTotal(setorLocal) {
        let qtd = 0;
        let total = 0;
        let totalExec = 0;
        let per = 0;
        let qtdTot = 0;
        for (let x in setorLocal) {
            const sl = setorLocal[x];
            qtd += sl.quantidade;
            total += sl.valorPrevisto;
            totalExec += sl.valor;
            // per += sl.perExecutado;
            qtdTot += sl.parcelas;
        }
        per = (totalExec * 100) / total;
        return ["TOTAL", qtd, FormataMoeda(total), FormataMoeda(totalExec), formataPorcentagem(per), qtdTot];
    }
    function recalculaLocalidade(arr, parcela) {
        const valorPrevisto = parcela.previsao.valor ? parcela.previsao.valor : 0;
        const valor = somaValorExecutado(parcela.parcelas);
        let perExecutado = 0;
        if (parcela.novosProjetos) {
            const item = todosNovosPrjetos.find(np => np.programa === parcela.previsao.programa && np.exercicio === parcela.previsao.exercicio);
            if (item) {
                perExecutado = parcela.demaisEmpresa ? 0 : (100 * valor) / item.valorOrcamento;
            }
        } else {
            perExecutado = parcela.demaisEmpresa ? 0 : ((valor * 100) / valorPrevisto);
        }
        const parcelas = parcela.parcelas.length;
        let item = arr.find(s => s.ra === parcela.previsao.ra);
        if (item) {
            item.quantidade++;
            item.valorPrevisto += valorPrevisto;
            item.valor += valor;
            item.perExecutado = perExecutado;
            item.parcelas += parcelas;
        } else {
            arr.push({
                ra: parcela.previsao.ra,
                quantidade: 1,
                valorPrevisto: valorPrevisto,
                valor: valor,
                perExecutado: perExecutado,
                parcelas: parcelas
            })
        }
    }
    function recalculaSetor(arr, parcela) {
        const valorPrevisto = parcela.previsao.valor ? parcela.previsao.valor : 0;
        const valor = somaValorExecutado(parcela.parcelas);
        let perExecutado = 0;
        if (parcela.novosProjetos) {
            const item = todosNovosPrjetos.find(np => np.programa === parcela.previsao.programa && np.exercicio === parcela.previsao.exercicio);
            if (item) {
                perExecutado = parcela.demaisEmpresa ? 0 : (100 * valor) / item.valorOrcamento;
            }
        } else {
            perExecutado = parcela.demaisEmpresa ? 0 : ((valor * 100) / valorPrevisto);
        }
        const parcelas = parcela.parcelas.length;
        let item = arr.find(s => s.localidadeSetor === parcela.previsao.setor);
        if (item) {
            item.quantidade++;
            item.valorPrevisto += valorPrevisto;
            item.valor += valor;
            item.perExecutado = perExecutado;
            item.parcelas += parcelas;
        } else {
            arr.push({
                localidadeSetor: parcela.previsao.setor ? parcela.previsao.setor : "Demais empresas",
                quantidade: 1,
                valorPrevisto: valorPrevisto,
                valor: valor,
                perExecutado: perExecutado,
                parcelas: parcelas
            })
        }
    }
    function imprimirNovoProjeto() {
        const dado = {
            exercicio: exercicio,
            novoProjeto: true,
            resumoEmpresa: resumoEmpresaNP,
            resumoEmpresaTotal: resumoEmpresaTotalNP,
            beneficiadosLocalidade: beneficiadosLocalidadeNP,
            beneficiadosSetor: beneficiadosSetorNP,
            planejamentoPRODF: planejamentoPRODFNP,
            planejamentoFIDE: planejamentoFIDENP,
            planejamentoIDEAS: planejamentoIDEASNP,
            planejamentoProgramas: planejamentoProgramasNP
        }
        fetch("/api/fundefePrevisao/previsaoOrcamentariaConsolidada", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                // Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    function imprimir() {
        const dado = {
            exercicio: exercicio,
            novoProjeto: false,
            resumoEmpresa: resumoEmpresa,
            resumoEmpresaTotal: resumoEmpresaTotal,
            beneficiadosLocalidade: beneficiadosLocalidade,
            beneficiadosSetor: beneficiadosSetor,
            planejamentoPRODF: planejamentoPRODF,
            planejamentoFIDE: planejamentoFIDE,
            planejamentoIDEAS: planejamentoIDEAS,
            planejamentoProgramas: planejamentoProgramas
        }
        fetch("/api/fundefePrevisao/previsaoOrcamentariaConsolidada?nm=Execucao", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                // Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    const handleChangeExercicio = (event) => {
        totalProdf = 0;
        totalIDEAS = 0;
        totalFIDE = 0;
        totalExecIDEAS = 0;
        totalExecPRODF = 0;
        totalExecFIDE = 0;
        totalQtdIDEAS = 0;
        totalQtdPRODF = 0;
        totalQtdFIDE = 0;
        const val = event.target.value;
        if (val != '') {
            setExcercicio(val);
            setPlanejamentoIDEASTotal([]);
            setPlanejamentoPRODFTotal([]);
            setPlanejamentoFIDETotal([]);
            setPlanejamentoPRODFTotalNP([]);
            setPlanejamentoFIDETotalNP([]);
            setPlanejamentoIDEASTotalNP([]);
        } else {
            setExcercicio("");
            setResumoEmpresa(null);
            setResumoEmpresaTotal(null);
            setBeneficiadosLocalidade(null);
            setBeneficiadosSetor(null);
        }
    };

    function formataPorcentagem(val) {
        if (val == Number.POSITIVE_INFINITY || val == Number.NEGATIVE_INFINITY) {
            return "";
        }
        // if( val || isFinite(val)) return "";
        // if( val || isFinite(val)) return "";
        let x = (!val || val === 0) ? "" : val.toFixed(4) + "%";
        return x.replace(".", ",");
    }
    function addArr(arr, p, valorExecutado) {
        let perExecucao = 0;
        if (p.novosProjetos) {
            //   - No campo % execução, fazer o calculo considerando o valor executado/total orçamento novos projetos. Lembrar de multiplicar por 100 e formatar com 4 casas decimais. A forma de calculo é diferente da formula de calculo quando não é novo projeto.
            const item = todosNovosPrjetos.find(np => np.programa === p.previsao.programa && np.exercicio === p.previsao.exercicio);
            if (item) {
                perExecucao = p.demaisEmpresa ? 0 : (100 * valorExecutado) / item.valorOrcamento;
            }
        } else {
            perExecucao = p.demaisEmpresa ? 0 : (100 * valorExecutado) / p.previsao.valor
        }
        arr.push({
            processo: p.previsao.processo,
            cnpj: p.previsao.cnpj,
            razaoSocial: p.previsao.razaoSocial ? p.previsao.razaoSocial : "Demais empresas",
            matrizFilial: p.previsao.isMatriz === true ? 'Matriz' : (!p.previsao.isMatriz ? "" : "Filial"),
            novosProjetos:p.novosProjetos === true ? 'Sim' :'Não',   
            ra: p.previsao.ra,
            setor: p.previsao.setor,
            programa: p.previsao.programa,
            valorPrevisto: p.previsao.valor ? p.previsao.valor : 0,
            valor: valorExecutado,
            perExecucao: perExecucao,
            parcelas: p.parcelas.length
        });
    }
    return (
        <Paper >
            <Grid container spacing={3} className={classes.root} >
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="lbExercicio">Excercício</InputLabel>
                        <Select
                            labelId="lbExercicio"
                            id="exercicio"
                            value={exercicio || ''}
                            onChange={handleChangeExercicio}
                            label="Exercício"
                        >
                            <MenuItem value="">Nenhum</MenuItem>
                            {lstExercicio && lstExercicio.map((row, i) => (
                                <MenuItem key={i} value={row}>{row}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo por empresa</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={resumoEmpresa}
                            rowTotal={resumoEmpresaTotal}
                            colunas={[
                                { nome: 'Processos', campo: 'processo' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Razão Social', campo: 'razaoSocial' },
                                { nome: 'Matriz/Filia', campo: 'matrizFilial' },
                                { nome: 'Novos Projetos', campo: 'novosProjetos' },
                                { nome: 'RA', campo: 'ra' },
                                { nome: 'Setor', campo: 'setor' },
                                { nome: 'Programa', campo: 'programa' },
                                { nome: 'Valor Previsto', campo: 'valorPrevisto', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Executado', campo: 'valor', transformacao: FormataMoeda, align: "right" },
                                { nome: '% Execução', campo: 'perExecucao', transformacao: formataPorcentagem, align: "right" },
                                { nome: 'N° execução', campo: 'parcelas', align: "right" }
                            ]} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Setores a serem beneficiados</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <GenericTable
                                disableSearch
                                dados={beneficiadosLocalidade}
                                rowTotal={beneficiadosLocalTotal}
                                colunas={[
                                    { nome: 'Localidade (RA)', campo: 'ra' },
                                    { nome: 'Quantidade', campo: 'quantidade', align: "right" },
                                    { nome: 'Valor Previsto', campo: 'valorPrevisto', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Executado', campo: 'valor', transformacao: FormataMoeda, align: "right" },
                                    { nome: '% Executado', campo: 'perExecutado', transformacao: formataPorcentagem, align: "right" },
                                    { nome: 'N° Parcelas', campo: 'parcelas', align: "right" }
                                ]} />
                       
                        </Grid>
                        <Grid item xs={12}>
                            <GenericTable
                                disableSearch
                                dados={beneficiadosSetor}
                                rowTotal={beneficiadosSetorTotal}
                                colunas={[
                                    { nome: 'Setor', campo: 'localidadeSetor', transformacao: function (ra) { return ra ? ra : "Demais empresas" } },
                                    { nome: 'Quantidade', campo: 'quantidade', align: "right" },
                                    { nome: 'Valor Previsto', campo: 'valorPrevisto', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Executado', campo: 'valor', transformacao: FormataMoeda, align: "right" },
                                    { nome: '% Executado', campo: 'perExecutado', transformacao: formataPorcentagem, align: "right" },
                                    { nome: 'N° Parcelas', campo: 'parcelas', align: "right" }
                                ]} />
                          
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo execução PRODF</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={planejamentoPRODF}
                            rowTotal={planejamentoPRODFTotal}
                            colunas={[
                                { nome: 'Processos', campo: 'processo' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Razão Social', campo: 'razaoSocial' },
                                { nome: 'Matriz/Filia', campo: 'matrizFilial' },
                                { nome: 'Novos Projetos', campo: 'novosProjetos' },
                                { nome: 'RA', campo: 'ra' },
                                { nome: 'Setor', campo: 'setor' },
                                { nome: 'Valor', campo: 'valorPrevisto', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Executado', campo: 'valor', transformacao: FormataMoeda, align: "right" },
                                { nome: '% Executado', campo: 'perExecucao', transformacao: formataPorcentagem, align: "right" },
                                { nome: 'N° Parcelas', campo: 'parcelas', align: "right" }
                            ]} />
                       
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo execução FIDE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={planejamentoFIDE}
                            rowTotal={planejamentoFIDETotal}
                            colunas={[
                                { nome: 'Processos', campo: 'processo' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Razão Social', campo: 'razaoSocial' },
                                { nome: 'Matriz/Filia', campo: 'matrizFilial' },
                                { nome: 'Novos Projetos', campo: 'novosProjetos' },
                                { nome: 'RA', campo: 'ra' },
                                { nome: 'Setor', campo: 'setor' },
                                { nome: 'Valor Pervisto', campo: 'valorPrevisto', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Executado', campo: 'valor', transformacao: FormataMoeda, align: "right" },
                                { nome: '% Executado', campo: 'perExecucao', transformacao: formataPorcentagem, align: "right" },
                                { nome: 'N° Parcelas', campo: 'parcelas', align: "right" }
                            ]} />
                       

                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo execução IDEAS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={planejamentoIDEAS}
                            rowTotal={planejamentoIDEASTotal}
                            colunas={[
                                { nome: 'Processos', campo: 'processo' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Razão Social', campo: 'razaoSocial' },
                                { nome: 'Matriz/Filia', campo: 'matrizFilial' },
                                { nome: 'Novos Projetos', campo: 'novosProjetos' },
                                { nome: 'Ra', campo: 'ra' },
                                { nome: 'Setor', campo: 'setor' },
                                { nome: 'Valor', campo: 'valorPrevisto', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Executado', campo: 'valor', transformacao: FormataMoeda, align: "right" },
                                { nome: '% Executado', campo: 'perExecucao', transformacao: formataPorcentagem, align: "right" },
                                { nome: 'N° Parcelas', campo: 'parcelas', align: "right" }
                            ]} />
                        

                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo execução programas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={planejamentoProgramas}
                            rowTotal={planejamentoProgramasTotal}
                            colunas={[
                                { nome: 'Programa', campo: 'programa' },
                                { nome: 'Valor Previsao Total', campo: 'valorPrevisto', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Liberado Total', campo: 'valor', transformacao: FormataMoeda, align: "right" },
                                { nome: '% Total Executado', campo: 'perExecucao', transformacao: formataPorcentagem, align: "right" }
                            ]} />
                       
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid container spacing={3} className={classes.root}>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                        Sair
                </Button>
                    <Button variant="contained" color="primary" onClick={() => imprimir()} >
                        Imprimir
                </Button>
                    {/* <Button variant="contained" color="primary" onClick={() => imprimirNovoProjeto()} >
                        Imprimir Novo Projeto
                </Button> */}
                </Grid>
            </Grid >
        </Paper>
    );
}