import React, { useEffect, useState } from 'react';
import {
    Grid, Button,
} from '@material-ui/core';
import GenericTable from "../../components/template/GenericTable";
import { useStyles, FormataMoeda } from "../../constants";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../util/ConfirmDialog";
import { useCurrentUser } from "../../server/UseCurrentUser";

let idParcela = null;
export default function LiberacoesTable(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setErro } = props;
    const history = useHistory();
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const [options, setOptions] = useState(['Visualizar', 'Alterar', 'Excluir'])
    let [profile] = useCurrentUser();

    useEffect(() => {
        setTitulo("Liberação de parcelas");
        buscarLiberacoes();

    }, []);
    useEffect(() => {
        if (profile){
            if( profile.regras.find(r => r === "047")){
                setOptions(['Visualizar']);
            }
        }
    }, [profile]);

    function buscarLiberacoes() {
        fetch("/api/fundefeParcelas")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    let arr = [];
                    for (let x in result) {
                        let item = result[x];
                        item.valor = formatar(item.previsao.valor);
                        item.parcelasLiberadas = item.parcelas.length;
                        const total = soma(item.parcelas);
                        item.totalLiberado = formatar(total);
                        if(!item.demaisEmpresa){
                            item.saldo = formatar(item.previsao.valor - total);
                        }
                        
                        item.matrizFilial = item.previsao.isMatriz ? "Matriz" : "Filial";
                        item.totalPrevisto = formatar(item.previsao.valor);
                        if( item.demaisEmpresa){
                            item.previsao.razaoSocial = "Demais empresas";
                            item.matrizFilial = "";
                        }
                        item.novoProjeto = item.novosProjetos ? "Sim":"Não"
                        arr.push(item);
                    }
                    setFonteDeDados(arr);
                }
            });
    }

    function soma(parcelas) {
        let total = 0;
        for (let x in parcelas) {
            const p = parcelas[x];
            total += p.valorParcela;
        }
        return total;
    }
    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === 'Alterar') {
            history.replace("/fundefe/liberacoesAlterar/" + dado.id);
        } else if (acao === 'Visualizar') {
            history.replace("/fundefe/liberacoesView/" + dado.id);
        } else if (acao === 'Excluir') {
            setShowConfirmExclusao(true);
            idParcela = dado.id;
        }
    }
    function formatar(val) {
        if (val) {
            return FormataMoeda(val);
        }
        return '';
    }
    function confirmaExclusao() {
        setWait(true);
        fetch("/api/fundefeParcelas/" + idParcela, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Bearer ${localStorage.accessToken}`
            }
        }).then((result) => {
            setWait(false);
            if (result.status && result.status > 300) {
                setErro(result);
            } else {
                let value = [];
                if (fonteDeDados && (fonteDeDados.length > 0)) {
                    for (let i = 0; i < fonteDeDados.length; i++) {
                        if (fonteDeDados[i].id !== idParcela) {
                            value.push(fonteDeDados[i]);
                        }
                    }
                }
                setFonteDeDados(value);
            }
        });
    }
    function isSomenteConsulta(){
        if( profile && profile.regras.find(r => r === "047")){
            return true;
        }
        return false;
    }
    
    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={options}
                dados={fonteDeDados}
                colunas={[
                    { nome: 'Exercício', campo: 'previsao.exercicio' },
                    { nome: 'Novo Projeto', campo: 'novoProjeto'},
                    { nome: 'CNPJ', campo: 'previsao.cnpj' },
                    { nome: 'Beneficiário', campo: 'previsao.razaoSocial' },
                    { nome: 'Matriz/Filial', campo: 'matrizFilial' },
                    { nome: 'Programa', campo: 'previsao.programa' },
                    { nome: 'Valor total Previsto', campo: 'totalPrevisto', align: "right" },
                    { nome: 'Valor total Liberado', campo: 'totalLiberado',  align: "right" },
                    { nome: 'Saldo', campo: 'saldo',  align: "right" },
                    { nome: 'Número parcelas liberadas', campo: 'parcelasLiberadas',  align: "right" },
                    { nome: 'Ação', campo: 'action' }
                ]} />

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
                {!isSomenteConsulta() &&
                <Button variant="contained" color="primary" href={"/fundefe/liberacoesNovo"} >
                    Cadastrar
                </Button>}
            </Grid>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />
        </Grid >


    );
}
