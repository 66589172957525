import React, { useState } from 'react';
import { Container, Card, CardContent, Grid, TextField, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { useStyles, FormataData } from '../../../constants';
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";
import Service from '../../../services/Service';
import { useLocation } from 'react-router-dom';

export default function DetalharDadosImplantacaoProcessoPanel(props) {
    const classes = useStyles();
    const { processo, setProcesso, gestaoProcesso, profile, setWait, setMensagem } = props;
    const [alteracao, setAlteracao] = useState(false);
    const location = useLocation();

    function isEmpresario() {
        if (location.pathname.includes("cadastrarProcesso")) return true;
        if (profile) {
            return profile.regras.find(r => r === '012') && true;
        }
        return false;
    }

    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            setAlteracao(false);
            // history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeInicioImplantacao = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            inicioImplantacao: {
                ...processo.inicioImplantacao,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleCheck = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            inicioImplantacao: {
                ...processo.inicioImplantacao,
                [e.target.name]: e.target.checked
            }
        })
    }

    return (
        <Container>
            <Card style={{ marginTop: '5px' }}>
                <CardContent>
                    <Grid container spacing={3} className={classes.root}>
                        {!isEmpresario() &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={processo && processo.inicioImplantacao && processo.inicioImplantacao.naoSeAplica || false}
                                            onChange={handleCheck}
                                            name="naoSeAplica"
                                            color="primary"
                                        />
                                    }
                                    label="Não se aplica"
                                />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                value={processo.inicioImplantacao && processo.inicioImplantacao.atestadoInicioImplantacao || ''}
                                fullWidth
                                label="Atestado de Início de Implantação"
                                id="atestadoInicioImplantacao"
                                name="atestadoInicioImplantacao"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo.inicioImplantacao && processo.inicioImplantacao.inicioAtividadeDataVistoria || ''}
                                fullWidth
                                label="Início da Atividade (Data da Vistoria)"
                                id="inicioAtividadeDataVistoria"
                                name="inicioAtividadeDataVistoria"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="implantacao" />
                        </Grid> */}
                        {!isEmpresario() &&
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.inicioImplantacao && processo.inicioImplantacao.linkFolha || ''}
                                    onChange={handleChangeInicioImplantacao}
                                    fullWidth
                                    label="Inicio de implantação (Link/Folha)"
                                    id="linkFolha"
                                    name="linkFolha"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                    </Grid>
                    {alteracao &&
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" onClick={() => salvar()} color="primary" >
                                Salvar
                            </Button>
                        </Grid>
                    }
                </CardContent>

            </Card>
        </Container>
    );
}
