
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import { Button, Container, Dialog, Grid, Typography } from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, API_BASE_URL } from "../../constants";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import DinamicForm from "../../util/form/DinamicForm";
import Service from "../../services/Service";
import LaudoAnexoGrid from './LaudoAnexoGrid';

let tipoAnexo = "foto";

const DadosLaudoVistoraForm = forwardRef((props, ref) =>  {
    const classes = useStyles();
    const { setMensagem, setWait, disable, vistoria, setVistoria, setErro, setLaudo, laudoDescricao, setDisabledConcluir, disabledConcluir, setHasChange } = props;
    const [txt, setTxt] = useState(laudoDescricao || vistoria.laudo.laudo);
    const [form, setForm] = useState();
    const [anexo, setAnexo] = useState();
    const [lstAnexo, setLstAnexo] = useState();
    const childRef = useRef();


    useEffect(() => {
        buscaForm();
        if (vistoria.anexos) {
            let arr = []
            for (let x in vistoria.anexos) {
                const a = vistoria.anexos[x];
                if (a.tipo) arr.push(a);
            }
            setLstAnexo(arr);
        }
    }, [])

    useEffect(() => {
        if (setDisabledConcluir && txt && disabledConcluir) {
            setDisabledConcluir(false);
        } else if (!txt) {
            setDisabledConcluir(true);
        }
    }, [txt]);
    useImperativeHandle(ref, () => ({
        getStatus: ()=> {
          return childRef.current.getStatus();
        }
      }));

    function isReadOnly() {
        return false;
    }
    function buscaForm() {
        if (vistoria && (!form || form.length === 0)) {
            let uri = '';
            let arr = vistoria.assunto.split(" ");
            for (let x in arr) {
                if (uri.length > 0) uri += "_"
                uri += arr[x];

            }

            uri = uri.toLowerCase();
            uri = uri.replace('ç', '');
            uri = uri.replace('ã', '');
            uri = uri.replace('ó', 'o');

            uri = uri.replace('/', '_');
            fetch('/api/info/form/' + uri)
                .then(response => response.json())
                .then(json => setForm(json))
                .catch(erro => setErro(erro));
        }
    }

    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('jpeg') &&
            !anexo.name.toLocaleLowerCase().endsWith('jpg') &&
            !anexo.name.toLocaleLowerCase().endsWith('pdf')) {
             setErro({
                 message: "Extensão inválida. Por favor informar uma anexo com extensão .pdf, jpg ou jpeg"
             })
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 5) {
            setErro({
                message: "Inválido. Por favor informar anexo menor do que 5 mb"
            })
            return false;
        }
        return true;
    }
    const salvaForm = (dado) => {
        const json = {
            id: vistoria.id,
            formData: dado
        }

        return Service.POST('/api/processoProdf/vistoria/formTermo', json).then(r=>{
            setWait(false);
            return r;
        }).catch(error => {
            setWait(false);
            setMensagem(error?error:'Oops! Something went wrong. Please try again!');
            throw error;                   
        });
        /*
        return fetch('/api/processoProdf/vistoria/formTermo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(json),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                return response.json();
                setWait(false);
                // setMensagem("Termo de vistoria atualizaddo");
                // response.json().then(json => {
                //     setVistoria(json);
                // })
            } else {
                return response.json() // return the result of the inner promise, which is an error
                    .then((json) => {
                        // const { message, stackTrace } = json;
                        throw json;
                    });
                // response.json()
                //     .then(error => {
                //         setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                //     })
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });*/
    }
    function trocaOrdem(target, source){
        let arr = [];
        for(let x in lstAnexo){
            const anexo = lstAnexo[x];
            if( anexo.uuid === target){
                arr.push( lstAnexo.find(a => a.uuid === source))
            }else if( anexo.uuid === source){
                arr.push( lstAnexo.find(a => a.uuid === target))
            }else{
                arr.push(anexo);
            }
        }
        setLstAnexo(arr);
        let vis = {...vistoria};
        vis.anexos = arr;
        setVistoria(vis);
        setHasChange(true);

    }
    function removeAnexo(anexo) {
        const dado = {
            idAnexo: anexo.id,
            idVistoria: vistoria.id
        }
        fetch('/api/processoProdf/vistoria/removerDocumento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                let arr = [];
                for (let x in lstAnexo) {
                    const a = lstAnexo[x];
                    if (a.id !== anexo.id) {
                        arr.push(a)
                    }
                }
                const vist = {...vistoria};
                setLstAnexo(arr);
                vistoria.anexos = arr;
            } else {
                response.json().then(erro => {
                    setErro(erro.message);
                })
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const onClickAnexo = (item) =>{
        setHasChange(true);
        if( item.acao === 'add'){
            fileClick();
        }else if( item.acao === 'save'){
            enviaAnexoVistoria(item.legenda);
        }else if( item.acao === 'remove'){
            removeAnexo(item.anexo);
        }else if( item.acao === 'update'){
            atualizaLegendaDocumento(item);
        }
    }
    const atualizaLegendaDocumento =(item)=>{
        const json = {
            id: vistoria.id,
            uuid: item.uuid,
            legenda: item.legenda
        };
        Service.POST('/api/processoProdf/vistoria/atualizaLegendaDocumento', json).then(ret =>{
            setWait(false);             
            ret.laudo.laudo = txt;
            setVistoria(ret);
        }).catch(error => {
            setWait(false);
            setMensagem(error?error:'Oops! Something went wrong. Please try again!');
        });
        /*
        fetch('/api/processoProdf/vistoria/atualizaLegendaDocumento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(json),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                // setMensagem("Termo de vistoria atualizaddo");
                response.json().then(ret => {
                    ret.laudo.laudo = txt;
                    setVistoria(ret);
                    // if (ret.anexos) {
                    //     let arr = []
                    //     for (let x in ret.anexos) {
                    //         const a = ret.anexos[x];
                    //         if (a.tipo) arr.push(a);
                    //     }
                    //     setLstAnexo(arr);
                    // }
                });
                // document.getElementById('nomeArquivo').value = "";
                // document.getElementById('legendaAnexo').value = "";
                // setAnexo();
            } else {
                response.json()
                    .then(error => {
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    })
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });*/
    }
    const enviaAnexoVistoria = (legenda) => {
        const json = {
            id: vistoria.id,
            uuid: anexo.uuid,
            nome: anexo.doc,
            tipo: tipoAnexo,
            legenda: legenda//?legenda:document.getElementById("legendaAnexo").value
        };
        Service.POST('/api/processoProdf/vistoria/anexarDocumento', json).then(ret =>{
            ret.laudo.laudo = txt;
            setVistoria(ret);
            if (ret.anexos) {
                let arr = []
                for (let x in ret.anexos) {
                    const a = ret.anexos[x];
                    if (a.tipo) arr.push(a);
                }
                setLstAnexo(arr);
                setAnexo();
            }
        }).catch(error => {
            setWait(false);
            setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
        });
        /*
        fetch('/api/processoProdf/vistoria/anexarDocumento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(json),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                setMensagem("Termo de vistoria atualizaddo");
                response.json().then(ret => {
                    ret.laudo.laudo = txt;
                    setVistoria(ret);
                    if (ret.anexos) {
                        let arr = []
                        for (let x in ret.anexos) {
                            const a = ret.anexos[x];
                            if (a.tipo) arr.push(a);
                        }
                        setLstAnexo(arr);
                    }
                });
                // document.getElementById('nomeArquivo').value = "";
                // document.getElementById('legendaAnexo').value = "";
                setAnexo();
            } else {
                response.json()
                    .then(error => {
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    })
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });*/
    }
    const handleTipoAnexo = (e) => {
        tipoAnexo = e.target.value;
    }
    const ocultar = (col, index)=>{   
        let arr = [...lstAnexo];
        arr[index].ocultar = arr[index].ocultar?false:true;
        setLstAnexo(arr);
    }
    function fileClick() {
        let file = document.getElementById("file");
        file.click();
    }
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();
        if (!validaAnexo(file.files[0])) {
            return;
        }
        dataArray.append('file', file.files[0]);
        setWait(true);
        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res =>{
                setWait(false);
                if (res.ok) {
                    res.json()
                    .then( result =>{                        
                        if (!result) return;
                        var nm = file.value;
                        nm = nm.split(/(\\|\/)/g).pop();
                        // document.getElementById('nomeArquivo').value = nm;
                        setAnexo({
                            doc: nm,
                            uuid: result.uuid
                        });
                    })
                }else{
                    console.error("------>", res );
                    setMensagem("Erro inesperado: "+res.statusText+". Aguarde alguns instantes e proceda a operação, caso o erro persista contacte os responsáveis.");
                }
            }).catch(function(e) {
                console.error("error", e);
                setMensagem("Erro não esperado");
            });

    }
    function relatorio() {
        setWait(true);
        fetch("/api/processoProdf/vistoria/relatorioInicioImplantacao", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(vistoria),
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }

    const handleActionClick = (index, acao, dado) => {
        if (acao === "Remover") {
            removeAnexo(dado);
        } else if (acao === "Visualizar") {
            var a = document.createElement("a");
            if (dado.id) {
                a.href = API_BASE_URL + "/api/dowload/gridfs/" + dado.id;
            } else {
                a.href = API_BASE_URL + "/api/dowload/fileSystem/" + dado.uuid + "-" + dado.nome;
            }
            a.setAttribute("target", "_blank");
            a.click();
        }
    }


    // const handleChangeAccordion=(event)=>{
    //     setAnchorEl(anchorEl ? null : event.currentTarget);
    // }


    // const handleClickOpen = (col) => {
    //     let url = col.id ? (API_BASE_URL + "/api/dowload/gridfs/" + col.id): (API_BASE_URL + "/api/dowload/fileSystem/" + col.uuid + "-" + col.nome);
    //     nome = col.nome
    //     leg= col.legenda
    //     setUrlImagem(url);
    //     setAbrir(true);
    // };

    // const handleClose = () => {
    //     setAbrir(false);
    //   };
    return (
        <Container maxWidth='lg'>
            <Typography variant="h5" gutterBottom>Dados Laudo vistoria</Typography>

            <Grid item xs={12}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography variant="h5" gutterBottom>Termo de vistoria - {vistoria ? vistoria.assunto : ''}</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        {vistoria &&
                            <DinamicForm 
                                form={form} 
                                onSaveClick={salvaForm} 
                                dado={vistoria.laudo.formulario} 
                                disable={disable} 
                                ref={childRef}
                                setChange={setHasChange}
                            />
                        }
                    </AccordionDetails>
                </Accordion>
            </Grid>


            <Grid item xs={12} >

                <Typography variant="h5" gutterBottom>Laudo De Vistoria</Typography>
                <CKEditor
                    editor={ClassicEditor} disabled={disable && disable === true}
                    config={{
                        toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                            '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                    }}
                    data={txt}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.

                        editor.editing.view.change(writer => {
                            writer.setStyle(
                                "height",
                                "150px",
                                editor.editing.view.document.getRoot()
                            );
                        });
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setTxt(data);
                        setLaudo(data);

                    }}
                    onBlur={(event, editor) => {

                    }}
                    onFocus={(event, editor) => {

                    }}
                />
            </Grid>
            
            {/* {!(disable && disable === true) &&
                <Grid item xs={12}>
                    <Paper className={classesLocal.root}>
                        <InputBase
                            className={classesLocal.input}
                            placeholder="Anexo"
                            id="nomeArquivo"
                            inputProps={{ readOnly: true }}
                            inputProps={{ 'aria-label': 'Anexo' }}
                        />
                        <Tooltip title="Buscar anexo do sistema de arquivo" aria-label="add">
                            <IconButton type="submit" className={classesLocal.iconButton}
                                aria-label="search"
                                onClick={() => fileClick()}
                            >
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                        <Divider className={classesLocal.divider} orientation="vertical" />
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Tipo</FormLabel>
                            <RadioGroup row aria-label="position" name="tipoAnexo" defaultValue="foto" onChange={handleTipoAnexo}>
                                <FormControlLabel value="foto" control={<Radio color="primary" />} label="Foto" />
                                <FormControlLabel value="outros" control={<Radio color="primary" />} label="Outros" />
                            </RadioGroup>
                        </FormControl>
                        <TextField id="legendaAnexo"
                            label="Legenda" variant="outlined"
                            className={classesLocal.txt}
                        />
                        {anexo &&
                            <Tooltip title="Enviar anexos do laudo" aria-label="add">
                                <IconButton color="primary" className={classesLocal.iconButton}
                                    aria-label="directions"
                                    onClick={enviaAnexoVistoria}
                                >
                                    <DirectionsIcon />
                                </IconButton>
                            </Tooltip>
                        }
                    </Paper>
                </Grid>
            } */}

            <Grid item xs={12}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography variant="h5" gutterBottom>Anexo Laudo vistoria</Typography>

                    </AccordionSummary>
                    <AccordionDetails>
                        <LaudoAnexoGrid 
                            anexos={lstAnexo} 
                            onClick={onClickAnexo} 
                            anexoTarget={anexo}
                            trocaOrdem={trocaOrdem}
                            disabled={disable}/>

                    </AccordionDetails>
                </Accordion>

            </Grid>
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="primary"
                    onClick={relatorio}
                    rel="noopener noreferrer">
                    Imprimir ficha
                </Button>
            </Grid>
            {/* </Container> */}
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf, image/jpeg, image/jpg"/>
            
        </Container>
    )
});


export default DadosLaudoVistoraForm;