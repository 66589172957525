import React, { useEffect, useState } from 'react';
import {
    Grid, Button
} from '@material-ui/core';
import GenericTable from "../../template/GenericTable";
import { FormataMoeda, useStyles } from "../../../constants";
import ConfirmDialog from '../../../util/ConfirmDialog';
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../../server/UseCurrentUser";

let idParcela = null;
export default function PrevisaoNovoProjetoTable(props) {

    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const history = useHistory();
    const [options, setOptions] = useState(['Visualizar', 'Alterar', 'Excluir', 'Replicar'])
    let [profile] = useCurrentUser();


    const [fonteDeDados, setFonteDeDados] = useState([]);
    useEffect(() => {
        setTitulo("Previsão Orçamentaria - FUNDEFE - Novos Projetos");
        buscaFundefeNovoProjeto();
    }, []);
    useEffect(() => {
        if (profile){
            if( profile.regras.find(r => r === "047")){
                setOptions(['Visualizar']);
            }
        }
    }, [profile]);

    function buscaFundefeNovoProjeto() {
        // setWait(true);
        fetch("/api/fundefeNovoProjeto/buscarParcelas")
        .then(resp => {
            if (resp.ok) {
                resp.json()
                    .then(json => {
                        setFonteDeDados(json);
                        // setWait(false);
                    })
            }
        }
        ).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === "Visualizar") {
            history.replace("/fundefe/NovosProjetosView/" + dado.id);
        } else if (acao === "Alterar") {
            history.replace("/fundefe/NovosProjetosAlterar/" + dado.id);
        } else if (acao === "Excluir") {
            setShowConfirmExclusao(true);
            idParcela = dado.id;
        }
    }
    function confirmaExclusao() {
        setWait(true);
        fetch("/api/fundefeNovoProjeto/" + idParcela, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Bearer ${localStorage.accessToken}`
            }
        }).then((result) => {
            setWait(false);
            if (result.status && result.status > 300) {
                setErro(result);
            } else {
                let value = [];
                if (fonteDeDados && (fonteDeDados.length > 0)) {
                    for (let i = 0; i < fonteDeDados.length; i++) {
                        if (fonteDeDados[i].id !== idParcela) {
                            value.push(fonteDeDados[i]);
                        }
                    }
                }
                setFonteDeDados(value);
            }
        });
    }

    function formataNumeroComPorcento(val) {
        let x = Number(val / 100).toFixed(4) + '%';
        return x.replace(".", ",");
    }
    function isSomenteConsulta(){
        if( profile && profile.regras.find(r => r === "047")){
            return true;
        }
        return false;
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={['Visualizar', 'Alterar', 'Excluir']}
                dados={fonteDeDados}
                colunas={[
                    { nome: 'Exercício', campo: 'exercicio' },
                    { nome: 'Programa', campo: 'programa' },
                    { nome: 'Total Orçamento', campo: 'valorOrcamento', transformacao: FormataMoeda, align: "right" },
                    { nome: 'Ação', campo: 'action', align: "center" }
                ]} />
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
                {!isSomenteConsulta() &&
                <Button variant="contained" color="primary" href={"/fundefe/NovosProjetosForm/"}  >
                    Novo
                </Button>}
            </Grid>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />

        </Grid >
    );
}