import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Typography, Backdrop, CircularProgress, Collapse, Button, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useStyles } from "../../constants";

import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import TableRequerimentos from './requerimento/TableRequerimentos';

export default function VisualizarRequerimento() {
    const classes = useStyles();

    const [wait, setWait] = useState(false);
    const match = useRouteMatch();
    const [titulo, setTitulo] = useState("Processos");
    const [alerta, setAlerta] = useState({ tipo: 'info', mensagem: '', visibilidade: false, duracao: 5000 });

    function setMensagem(alerta) {
        let a;
        if( typeof alerta === 'object'){
            a = { ...alerta };
            if (!a.tipo) a.tipo = 'success';
            if (!a.duracao) a.duracao = 5000;
            a.visibilidade = true;
        }else{
            a = {
                tipo: 'info',
                duracao:5000,
                visibilidade: true,
                mensagem: alerta
            }
        }
        
        setAlerta(a);
        if (!!a.duracao && a.duracao > 0) {
            window.setTimeout(() => {
                setAlerta({ tipo: a.tipo, visibilidade: false });
            }, a.duracao)
        }
    }

    // @deprecated - usar setMensagem quando for possível
    function setErro(e) {
        setMensagem({
            tipo: 'error',
            mensagem: e.statusText || e.error || e
        });
    }

    return (
        <Container maxWidth='xl' className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h5" gutterBottom><center>{titulo}</center></Typography>
                        <Collapse in={alerta.visibilidade}>
                            <Alert severity={alerta.tipo} action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlerta({ visibilidade: false });
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }>{alerta.mensagem}</Alert>
                        </Collapse>
                    </Paper>
                </Grid>
                
            </Grid>

            <Backdrop className={classes.backdrop} open={wait}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Switch>
                <Route path={`${match.path}/empresario`}>
                    <b>Falta implementar</b>
                </Route>

                <Route path={match.path}>
                    <TableRequerimentos setErro={setErro} setWait={setWait} setTitulo={setTitulo}/>
                </Route>
            </Switch>
        </Container>
    )
}

    
