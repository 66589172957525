import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    table: {
        minWidth: 650,
      },
  }));
  
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];

export default function AgendaDetalheDiaDialog(props) {
  const {open, setOpen, dadoDia} = props;
  const classes = useStyles();
  const history = useHistory();
  const [scroll] = React.useState('paper');
  const [titulo, setTitulo] = React.useState('');
  const [lstManha, setLstManha] = React.useState([]);
  const [lstTarde, setLstTarde] = React.useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  function irPara(id){
      history.replace("/vistoria/solicitarView?id="+id+"&goTo=Agenda");
  }
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
    setTitulo( montaTitulo() );
    montaPeriodo();
  }, [open]);

  function montaPeriodo(){
      if( ! dadoDia) return;
      let arrManha = [];
      let arrTarde = []
      dadoDia.atividades.forEach(a => {
          if( a.agenda.turno === 'MANHA'){
              arrManha.push( a );
          }else{
            arrTarde.push( a );
          }
      });
      setLstManha(arrManha);
      setLstTarde(arrTarde);
  }
  function montaTitulo(){
      if( !dadoDia)return "Sem atividade";
      const sDia = (dadoDia.dia < 10)?"0"+dadoDia.dia:""+dadoDia.dia;
      const sMes = (dadoDia.mes < 10)?"0"+dadoDia.mes:""+dadoDia.mes;
    return (dadoDia.atividades.length ==0 ?"Sem atividade":dadoDia.atividades.length+" atividades") + " na data: "+sDia+"/"+(dadoDia.mes+1)+"/"+dadoDia.ano;
  }

  function AtividadeTable(props){
      const {dados}= props;
      function showVistoriadores(agenda){
          let ret = "";
          agenda.vistoriadores.forEach(v =>{
              ret += ret.length > 0 ?", "+v.user.nome:v.user.nome;
          })
          return ret;
      }
    return (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width='400px'>Nome</TableCell>
                <TableCell>Vistoriadores</TableCell>
                <TableCell width='100px' align="center">Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dados && dados.map((row, id) => (
                <TableRow key={id}>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{showVistoriadores(row.agenda)}</TableCell>
                  <TableCell align="center">
    
                    <Tooltip title="Ver vistoria">
                        <IconButton onClick={ (e) => irPara(row.id) }>
                            <ArrowRightAltIcon />
                        </IconButton>
                    </Tooltip>
                        
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
  }
  function Conteudo(){
      return(
        <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Manhã</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AtividadeTable dados={lstManha}/>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Tarde</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AtividadeTable dados={lstTarde} />
          </AccordionDetails>
        </Accordion>
      </div>
      )
  }

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{titulo}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          {/* <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            
          </DialogContentText> */}
          <Conteudo/>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
  );
}
