import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Paper, Menu, MenuItem, Typography, Collapse,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip, Button
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CampoBusca from "../../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import { useStyles, filtroDadoGenerico, FormataCnpj } from "../../../constants";
import MotivoDialog from "../../../util/MotivoDialog";
import { useHistory } from "react-router-dom";

const options = [
    'Alterar',
    'Excluir',
    'Visualizar'
];

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;

export default function CadastroEmpresaTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait, setMensagem } = props;
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const history = useHistory();
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);
    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    useEffect(() => {
        filtroCampo = null;
        setWait(true);
        fetch("/api/empresa"
        ).then(res => res.json()).then((result) => {
            setWait(false);
            if (result.status && result.status > 300) {
                setErro((setErro && setErro.statusText) || 'Oops! Something went wrong. Please try again!');
            } else {
                result.sort((a, b) => ((a.razaoSocial < b.razaoSocial) ? 1 : -1));
                let arr = [];
                for (let x in result) {
                    const r = result[x];
                    arr.push({
                        id: r.id,
                        cnpj: r.cnpj,
                        razaoSocial: r.razaoSocial,
                        nomeFantasia: r.nomeFantasia,
                        representante: r.representante,
                        responsaveis: r.responsaveis,
                        rgrepr: r.rgrepr,
                        email: r.email,
                        telcel: r.telcel
                    })
                }
                fonteDeDados = arr;
                filtraDado();
            }
        });
    }, []);

    useEffect(() => {
        setTitulo("Gerenciar Cadastro de Empresas");
        // fonteDeDados = dados;
        filtraDado();
    }, [ordem, pagina, bloco]);

    function confirmaExclusao(txt) {
        const id = dadosFiltrado[indexProcesso].id;
        setWait(true);
        const dado = {
            id: id,
            motivo: txt
        }
        fetch("/api/empresa/" + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then((result) => {
            setWait(false);

            setShowConfirmExclusao(false);
            if (!result.ok) {
                setMensagem("Esta empresa não pode ser excluída pois está associada a um processo ou requerimento");
            } else {
                let value = [];
                if (fonteDeDados && (fonteDeDados.length > 0)) {
                    for (let i = 0; i < fonteDeDados.length; i++) {
                        if (fonteDeDados[i].id !== id) {
                            value.push(fonteDeDados[i]);
                        }
                    }
                }

                fonteDeDados = value;
                filtraDado();
                setMensagem("Registro excluido!")
            }
        }).catch(error => {
            setWait(false);
            setErro("Esta empresa não pode ser excluída pois está associada a um processo ou requerimento.");
        });
    }
    const handleAcao = (e, index) => {
        indexProcesso = index;
        setAnchorEl(e.currentTarget);
    }

    const handleActionClick = (action) => {
        // let empresa = fonteDeDados[indexProcesso];
        let acao = options[action];
        const id = dadosFiltrado[indexProcesso].id;
        if (acao == 'Excluir') {
            setShowConfirmExclusao(true);
        }
        if (acao == 'Alterar') {


            history.push("/operacao/empresaForm?id=" + id);
        }
        if (acao == 'Visualizar') {

            history.push("/operacao/empresaView?id=" + id);
        }

        setAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
    }

    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
            } else {
                disparaSearch();
            }
        }, tempo);
    }

    function getTituloSituacao(row) {

        return "Visualizar";
    }

    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];

        let arrOrdenado = ordenar(fonteDeDados);

        root:
        for (let xx in arrOrdenado) {
            const d = arrOrdenado[xx];
            if (filtroCampo) {

                let campos = [];
                if (filtroCampo.includes('"')) {
                    campos.push(filtroCampo.split('"')[1])
                } else {
                    campos = filtroCampo.split(' ');
                }
                filho:
                for (let x in d) {
                    for (var y in campos) {
                        if (typeof d[x] === 'string') {
                            if (x === 'cnpj') {
                                let cnpj1 = d[x].replace(/[^\d]+/g, '');
                                let cnpj2 = campos[y].replace(/[^\d]+/g, '');
                                if (cnpj2.length > 0) {
                                    if (cnpj1.includes(cnpj2)) {
                                        arr.push(d);
                                        continue root;
                                    }
                                }
                            } else if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                arr.push(d);
                                continue root;
                            }
                        } else if (d[x] == campos[y]) {
                            arr.push(d);
                            continue root;
                        }
                    }
                }
            } else {
                arr.push(d);
            }
        }

        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (index++ > (pagina - 2)) break;
        }
        setDadosFiltrado(arrBloco);
    }

    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
        setBloco(0);
    }
    function showRepresentante(row) {
        if (row.responsaveis && row.responsaveis.length > 0) {
            for (let a in row.responsaveis) {
                if (row.responsaveis[a].ativo === true) {
                    return row.responsaveis[a].nome;
                }
            }
        }
        if (row.representante === '0') return "";
        if (row.representante) return row.representante;
        return "";
    }
    function showRg(row) { // row.rgrepr == "0") ? '' : row.rgrepr
        if (row.responsaveis && row.responsaveis.length > 0) {
            for (let a in row.responsaveis) {
                if (row.responsaveis[a].ativo === true) {
                    return row.responsaveis[a].rg;
                }
            }
        }
        if (row.rgrepr === '0') return "";
        if (row.rgrepr) return row.rgrepr;
        return "";
    }
    function showTelefone(row) { // (row.telcel == "0") ? '' :row.telcel
        if (row.responsaveis && row.responsaveis.length > 0) {
            for (let a in row.responsaveis) {
                if (row.responsaveis[a].ativo === true) {
                    return row.responsaveis[a].telefone;
                }
            }
        }
        if (row.telcel === '0') return "";
        if (row.telcel) return row.telcel;
        return "";
    }
    function showEmail(row) {
        if (row.responsaveis && row.responsaveis.length > 0) {
            for (let a in row.responsaveis) {
                if (row.responsaveis[a].ativo === true) {
                    return row.responsaveis[a].email;
                }
            }

        }
        if (row.email === '0') return "";
        if (row.email) return row.email;
        return "";
    }

    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'cnpj'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('cnpj')}
                                    >
                                        CNPJ
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'razao'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('razao')}
                                    >
                                        Razão Social
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'nomeFantasia'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('nomeFantasia')}
                                    >
                                        Nome Fantasia
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'representante'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('representante')}
                                    >
                                        Representante
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'rg'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('rg')}
                                    >
                                        RG
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'email'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('email')}
                                    >
                                        Email
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'celular'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('celular')}
                                    >
                                        celular
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{FormataCnpj(row.cnpj)}</TableCell>
                                    <TableCell>{row.razaoSocial}</TableCell>
                                    <TableCell>{row.nomeFantasia}</TableCell>
                                    <TableCell>{showRepresentante(row)}</TableCell>
                                    <TableCell>{showRg(row)}</TableCell>
                                    <TableCell>{showEmail(row)}</TableCell>
                                    <TableCell>{showTelefone(row)}</TableCell>
                                    <TableCell align="center">
                                        <Box >
                                            <Tooltip title={getTituloSituacao(row)}>
                                                <IconButton onClick={(e) => handleAcao(e, i)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    fonteDeDados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={qtd}
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </Grid>
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>

                <Button variant="contained" color="primary" href={"/operacao/empresaForm"} >
                    Cadastrar
                </Button>
            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                    // style: {
                    //     maxHeight: ITEM_HEIGHT * 4.5,
                    //     width: '20ch',
                    // },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleActionClick(index)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
            <Grid>
                <MotivoDialog
                    open={showConfirmExclusao}
                    setOpen={setShowConfirmExclusao}
                    titulo={'Confirmação'}
                    motivo='Motivo de exclusão'
                    msg='Confirma exclusão do item selecionado?.'
                    acao={confirmaExclusao}
                />
            </Grid>

        </Grid>


    );
}
