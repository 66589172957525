import React, { useEffect, useState } from 'react';
import {
    Grid, Button
} from '@material-ui/core';
import ConfirmDialog from "../../util/ConfirmDialog";
import { useHistory } from "react-router-dom";
import { FormataDataComHora, useStyles } from "../../constants";
import MotivoDialog from '../../util/MotivoDialog';
import GenericTable from '../template/GenericTable';
import Service from "../../services/Service";

const options = [
    'Visualizar',
    'Restaurar'
];

let indexProcesso = 0;

export default function LoteExcluidoTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait, setMensagem } = props;
    const [showConfirmReativacao, setShowConfirmReativacao] = useState(false);
    const [showNaoPodeExclusao, setShowNaoPodeExclusao] = useState(false);
    const [dados, setDados] = React.useState([]);
    const [id, setID] = useState(-1);
    const history = useHistory();


    useEffect(() => {
        setTitulo("Gerenciar Estoque de Lotes Excluidos")
        setWait(true);
        fetch("/api/processoProdf/lote/excluido"
        ).then(res => res.json()).then((result) => {
            setWait(false);

            if (result.status && result.status > 300) {
                setErro(result);
            } else {
                let arr = result;
                for (let a in result){
                    const lote = result[a];
                    if (lote.historico){
                        for (let x in lote.historico){
                            const h = lote.historico[x];
                            if(h.texto === "Exclusão Lote") {
                                arr[a] = {
                                    ...arr[a],
                                    dtExclusao: transformaDt(h.data),
                                    responsvel: h.responsavel.nome
                                };
                            }
                        }
                    }
                }
                setDados(arr);
            }
        });
    }, []);

    function transformaDt(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }

    function confirmaReativacao(txt) {
        // const id = dados[indexID].id;
        setWait(true);
        const dado = {
            id: id,
            motivo: txt
        }
        // return;
        Service.POST("/api/processoProdf/lote/ativar/", dado).then(r => {
            setWait(false);
            let value = [];
            if (dados && (dados.length > 0)) {
                for (let i = 0; i < dados.length; i++) {
                    if (dados[i].id !== id) {
                        value.push(dados[i]);
                    }
                }
            }
            setDados(value);
            setMensagem("Registro Ativado!")

        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : "Este lote não pode ser reativado, pois já existe outro ativo com a mesma identificação: (proposta, RA, ADE, Endereço)");
        });
        // fetch("/api/processoProdf/lote/ativar/", {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then((result) => {
        //     setWait(false);

        //     setShowConfirmReativacao(false);
        //     if (!result.ok) {
        //         setMensagem("Este lote não pode ser reativado, pois já existe outro ativo com a mesma identificação: (proposta, RA, ADE, Endereço)");
        //     } else {
        //         let value = [];
        //         if (dados && (dados.length > 0)) {
        //             for (let i = 0; i < dados.length; i++) {
        //                 if (dados[i].id !== id) {
        //                     value.push(dados[i]);
        //                 }
        //             }
        //         }
        //         setDados(value);
        //         setMensagem("Registro Ativado!")
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setErro("Este lote não pode ser reativado, pois já existe outro ativo com a mesma identificação: (proposta, RA, ADE, Endereço)");
        // });
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === 'Visualizar') {
            history.push("/operacao/estoqueLoteExcluidoView?id=" + dado.id);
        }
        if (acao === 'Restaurar') {
            setShowConfirmReativacao(true);
            setID(dado.id)
            // history.push("/operacao/estoqueLoteForm?id=" + dado.id);
        }
    };

    function formataDigito(valor){
        if( valor == null ) return '';
        return valor.toLocaleString('pt-br',{minimumFractionDigits: 2});
    }

    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={['Visualizar', 'Restaurar']}
                dados={dados || []}
                colunas={[
                    { nome: 'Proposta', campo: 'proposta' },
                    { nome: 'RA', campo: 'ra' },
                    { nome: 'ADE', campo: 'ade' },
                    { nome: 'Endereço', campo: 'endereco' },
                    { nome: 'Área (m²)', campo: 'area', transformacao:formataDigito },
                    { nome: 'Data da Exclusão', campo: 'dtExclusao' },
                    { nome: 'Responsavel', campo: 'responsvel' },
                    { nome: 'Ação', campo: 'action' }
                ]} />
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>


                <Button variant="contained" color="primary" href={"/operacao/estoqueLoteForm"} >
                    Cadastrar
                </Button>
            </Grid>
            <Grid>
                <MotivoDialog
                    open={showConfirmReativacao}
                    setOpen={setShowConfirmReativacao}
                    titulo={'Confirmação'}
                    motivo='Motivo da reativação'
                    msg='Confirma exclusão do item selecionado?.'
                    acao={confirmaReativacao}
                />
            </Grid>
        </Grid>


    );
}
