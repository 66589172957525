import React, {useState, useEffect} from 'react';
import { createStyles, withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {Dialog, Grid} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import GenericTable from "../../../components/template/GenericTable";
import { useStyles } from "../../../constants";

const styles = (theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function UnidadeDialog(props) {
  const classes = useStyles();
  const {open, setOpen, acao, titulo, unidades} = props;
  const handleClose = () => {
    setOpen(false);
  };

  const handleActionClick = (index, opt, dado) =>{
    acao( dado );
    setOpen(false);
  }
  
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth='md'>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {titulo?titulo:'Escolha a unidade'}
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={3} className={classes.root}>
                  <GenericTable 
                    actionClick={handleActionClick}
                    options={['Selecionar']}
                    dados={unidades} colunas={[
                    { nome: 'Nome', campo: 'nome' },
                    { nome: 'Descricao', campo: 'descricao' },
                    { nome: 'Ação', campo: 'action' },
                  ]} />
                </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
