import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { Backdrop, Button, CircularProgress, Container, Grid } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useHistory, useLocation } from "react-router-dom";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import { red } from '@material-ui/core/colors';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import Alert from '@material-ui/lab/Alert';
import { FormataDataComHora } from "../../../constants";
import Service from '../../../services/Service';
import { useCurrentUser } from '../../../server/UseCurrentUser';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            'margin-left': '10%',
            'margin-top': '1%',
            width: "80%",
            height: "45%",
        },
        media: {
            //   height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        btns:{
            '& > *': {
                margin: theme.spacing(1)
            },
            display: 'flex'
        }
    }),
);

export default function VerPost(props) {
    const classes = useStyles();
    const { setWait } = props;
    const [expanded, setExpanded] = useState(false);
    const [postLst, setPostLst] = useState(false);
    const [error, setError] = useState(false);
    const spanRef = useRef(null);
    const history = useHistory();
    let [profile] = useCurrentUser();

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {

        if (props.match.params.id === undefined) {
            history.push("/home");
        } else {
            if (Object.entries(props.match.params.id).length > 0) {
                if (!postLst && !error) {
                    fetch('/api/post/lst/' + props.match.params.id)
                        .then(response => response.json())
                        .then(data => {
                            setPostLst(data.sort((a, b) => new Date(b.data) - new Date(a.data)));
                            // spanRef.current.innerHTML = data.conteudo;   
                        })
                        .catch(error => setError(error));
                }
            }


        }


    }, [postLst, error, props]);


   

    function formataData(dt) {
        if (!dt) return "";
        return FormataDataComHora(new Date(dt));
    }


    const handleAcao = (e) => {
        setWait(true)
        let dado;
        console.log("profile", profile)
        dado = {
            idPost: e.id,
            idUser: profile.id,
        }
        console.log(dado)
        Service.POST("/api/processoProdf/userPost/marcarComoLido", dado).then(result => {

            atualizarPagina(e.id);
            setWait(false)

        }).catch(erro => {

            console.log(erro)
        })

    }

    function atualizarPagina(dado) {

        let str = props.match.params.id;

        console.log(str.length)

        let itemRemovido = dado + ",";

        let novaStr = str.replace(itemRemovido, "")
        if (str.length !== 0) {
            history.push("/post/" + novaStr);
            window.location.reload();

        } else {
            history.push("/home");
        }




    }

    function marcarTodosComoVisto() {

        let dado;
        dado = {
            idPost: props.match.params.id,
            idUser: profile.id,
        }

        Service.POST("/api/processoProdf/userPost/marcarTodosComoLido", dado).then(result => {
            //atualizarPagina(e.id);
            history.push("/home");
            window.location.reload();

        }).catch(erro => {

            console.log(erro)
        })




    }



    return (
        <Container maxWidth="xl">
            <Grid container justify="flex-end" className={classes.btns}>
                <Grid>
                    <Button variant="contained" color="primary" onClick={() => marcarTodosComoVisto()}>
                        Marcar todos Como lido
                    </Button>
                </Grid>

            </Grid>

            {postLst && postLst.map((post, index) => (

                <Card className={classes.root} key={index}>

                    <CardHeader
                        avatar={
                            <Avatar aria-label="recipe" className={classes.avatar}>
                                Sys
                            </Avatar>
                        }
                        action={
                            <IconButton aria-label="settings " onClick={(e) => handleAcao(post)}>
                                <CloseIcon />
                            </IconButton>
                        }
                        title={post && post.titulo}
                        subheader={post && post.assunto + " - " + formataData(post.executeDate)}
                    />
                    {/* <CardMedia
              className={classes.media}
              image="/logo192.png"
              title="Paella dish"
            /> */}
                    <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                            {
                                post && <span dangerouslySetInnerHTML={{
                                    __html: post.conteudo
                                }}></span>
                            }
                        </Typography>
                    </CardContent>
                    {/* <CardActions disableSpacing> */}
                    {/* <IconButton aria-label="add to favorites">
                            <FavoriteIcon />
                        </IconButton>
                        <IconButton aria-label="share">
                            <ShareIcon />
                        </IconButton>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                        </IconButton> */}
                    {/* </CardActions> */}

                    <Backdrop className={classes.backdrop} open={!post} >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    {
                        error && <Alert severity="error">{error}</Alert>
                    }


                </Card>
            ))}

            

        </Container>

    );
}
