

import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
}
    from '@material-ui/core';
import ConfirmDialog from "../../../util/ConfirmDialog";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import RemoveIcon from '@material-ui/icons/Remove';
import BuscaDadoDialog from "../../../util/BuscaDadoDialog";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import Download from "../../../util/Download";
import { useStyles, getQueryParams, API_BASE_URL, FormataDataComHora } from "../../../constants";
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';

export const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '100px',
        overflow: 'auto'
    }
}), { index: 1 });

export default function ResponderForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait } = props;
    
    const classesLocal = useLocalStyle();
    const [showConfirm, setShowConfirm] = useState(false);
    const [value, setValue] = useState({
        processo: {
            nProcesso:''
        },
        descricao: '',
        declaracao: false,
        protocolo: '',
        tipo: '',
    });
    const [anexos, setAnexos] = useState([{tipo:-1}]);
    const [processos, setProcessos] = useState([]);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const [descricao, setDescricao] = useState('');
    let [profile] = useCurrentUser();
    const [complemento, setComplemento] = useState({});
    const [desabilitado, setDesabilitado] = useState(false);
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [txt, setTxt] = useState('');
    const param = getQueryParams(useLocation().search);

    useEffect(() => {   
        const id = param.get("id");
        setTitulo("Responder");
        if( id ){
            setWait(true);
            fetch('/api/processoProdf/notificacaoComplemento/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        setValue(json);
                        if( json.documentos ){
                            let arr = [];
                            json.documentos.forEach(d => arr.push(d));
                            
                            arr.push({tipo:-1});
                            setAnexos( arr );
                            const h = json.historico.filter(i => i.tipo === 'responder');
                            if( h && h.length > 0 ){
                                setTxt( h[h.length-1].texto);
                            }
                        }
                        //setDescricao(json);
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);                            
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);  
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        }else{
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
              //  window.history.pushState({}, document.title, "./solicitarCancelamentoForm");
            }
        }
    }, [])

    useEffect(() => {
        
        const id = param.get("id");
        async function fetchData() {
            setWait(true);
            buscaComplemento(id);
        }
        fetchData();
        return () => { }
    }, []);

    useEffect(() => {
        if( complemento.assunto === 'Notificacao'){
            setTitulo('Responder Notificação');
        }else{
            setTitulo('Responder Complemento de Informação');
        }
        
    }, [complemento && complemento.status])

    
    function buscaComplemento(id) {
        
        setTitulo("Responder");
        if( id ){
            setWait(true);
            fetch('/api/processoProdf/notificacaoComplemento/' + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        setComplemento(json);
                        setDescricao(json.descricao?json.descricao:'');
                        setValue(json);
                        if( json.documentos ){
                            let arr = [];
                            json.documentos.forEach(d => arr.push(d));
                            
                            arr.push({tipo:-1});
                            setAnexos( arr );
                        }
                        //setDescricao(json);
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);                            
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);  
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        }else{
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
              //  window.history.pushState({}, document.title, "./solicitarCancelamentoForm");
            }
        }
    }

    function relatorio(){
        var a = document.createElement("a");
        a.href = API_BASE_URL + "/api/processoProdf/cancelamento/relatorio/" + value.id;
        a.setAttribute("target", "_blank");
        a.click();
    }
  
    
    function confirmaAprovacao() {
        let dado = { ...value };
        dado.historico = [{
            responsavel:{
                nome: profile.name,
                email: profile.email,
                id: profile.id,
            },
            texto: txt,
            data: new Date(),
            tipo: 'responder'
        }];
        dado.documentos = [];//anexos;
        anexos.forEach(a =>{
            if( a.tipo !== -1) dado.documentos.push(a);
        })
        dado.status = "EM_ANALISE";  
        
        submitForm(dado);
        
    }
    const enviarRascunho = () => {
        let dado = { ...value };
        
        dado.historico = [{
            responsavel:{
                nome: profile.name,
                email: profile.email,
                id: profile.id,
            },
            texto: txt,
            data: new Date(),
            tipo: 'Rascunho'
        }];

        dado.documentos = [];//anexos;
        anexos.forEach(a =>{
            if( a.tipo !== -1) dado.documentos.push(a);
        })
        dado.status = "RASCUNHO";  
        
        submitForm(dado);
    }

    function submitForm(dado) {
      
        setWait(true);
        
        fetch('/api/processoProdf/notificacaoComplemento/posCreate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                   // setMensagem("Rascunho salvo com sucesso.")
                   if( complemento.assunto === 'Notificacao'){
                     history.replace("/gerenciaProcesso/notificacao/lista");
                   }else{
                    history.replace("/gerenciaProcesso/complementoinformacao/lista");
                   }
                
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function resetErro() {
        return {
            processo: { erro: false, msg: '' },

            campo3: { erro: false, msg: '' },
        }
    }
    function setProcesso(processo){
        setValue({...value,
            processo:{
                id: processo.id,
                nProcesso: processo.nProcesso,
                cnpj: processo.cnpj,
                razaoSocial: processo.razaoSocial,
                programa: processo.programa
            }
        });
    }
    
    const handleEscolheFile = () => {
        let file = document.getElementById("file");
        file.click();
    }
    
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            setOpen(true);
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();
                    
                    let arr = [];
                    arr.push({
                        tipo: tipoDocumento,
                        nome: nm,
                        uuid: result.uuid
                    });
                    anexos.forEach(a =>{
                        if( a.tipo !== -1 )arr.push(a)
                    });
                    arr.push({tipo:-1});
                    setAnexos(arr);
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }

    function podeEnviar() {
        return txt && txt.length > 0;
    }
    
    function removeArquivo(item){
        let arr=[];
        anexos.forEach(it =>{
            const idItem = (item.uuid )?item.uuid:item.id ;
            const idIt = (it.uuid)?it.uuid:it.id;
            if( idItem !== idIt){
                arr.push(it);
            }
        });
        setAnexos( arr );
    }
    const buscaDado = ()=>{
        
        setWait(true);
        fetch("/api/gerenciarProcesso/filtro",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify({
                    cnpjs: profile.empresas
                }),
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setMensagem(result);
                } else {
                    setProcessos(result);
                    
                    setShowBuscaDadoDialog(true);
                }
            });
        
    }

    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>                
                <Grid item xs={4}>
                        <TextField disabled={true}
                            value={complemento.processo && complemento.processo.nProcesso || ''}
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            variant="outlined"
                        />
                    </Grid>
                   

                    <Grid item xs={2}>
                        <TextField disabled={true}
                            value={complemento.processo && complemento.processo.programa || ''}
                            fullWidth
                            label="Programa"
                            id="programa"
                            name="programa"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField disabled={desabilitado}
                            value={complemento.protocolo || ''}
                            fullWidth
                            label="Protocolo"
                            id="protocolo"
                            name="protocolo"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField disabled={true}
                            value={complemento.dataCriacao && FormataDataComHora( new Date(complemento.dataCriacao)) || ''}
                            fullWidth
                            label="Data solicitação"
                            id="dataSolicitacao"
                            name="dataSolicitacao"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField disabled={true}
                            value={complemento.processo && complemento.processo.cnpj || ''}
                            fullWidth
                            label="CNPJ"
                            id="cnpj"
                            name="cnpj"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled={true}
                            value={complemento.processo && complemento.processo.razaoSocial || ''}
                            fullWidth
                            label="Razão Social"
                            id="razaoSocial"
                            name="razaoSocial"
                            variant="outlined"
                        />
                    </Grid>
                    
                    <Grid item xs={12}>
                            <Paper className={classesLocal.paper}>
                                <span dangerouslySetInnerHTML={{
                                    __html: descricao ? descricao : ''
                                }}></span>
                            </Paper>
                        </Grid>                   
                    
                        
                    <Grid item xs={12}>
                        <CKEditor       
                            editor={ClassicEditor} 
                            config={{
                                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                    '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                            }}
                            config={{
                                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                    '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                            }}
                            data={txt}  
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                               
                                editor.editing.view.change(writer => {
                                    writer.setStyle(
                                      "height",
                                      "300px",
                                      editor.editing.view.document.getRoot()
                                    );
                                  });
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTxt(data);
                            }}
                            onBlur={(event, editor) => {
                             
                            }}
                            onFocus={(event, editor) => {
                               
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>                        
                        <TableContainer component={Paper} >
                            <Table aria-label="customized table" size={'small'}>
                                <TableHead>
                                    <TableRow>                                                
                                        <TableCell sortDirection={false} className={classes.tableHead}>
                                            Documento
                                        </TableCell>
                                        <TableCell width='100px' align="center" className={classes.tableHead}>
                                            Ação
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {anexos && anexos.map((row, i) => (
                                        <TableRow key={i}>                                                    
                                            <TableCell>{row.nome}</TableCell>
                                            <TableCell align="center">
                                                
                                            { row.tipo === -1 && 
                                                <Tooltip title="Incluir documento">
                                                    <IconButton onClick={ (e) => handleEscolheFile() }>
                                                        <AttachFileIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {row.tipo !== -1 &&  
                                                <center>
                                                    <Download doc={row}/>
                                                        <Tooltip title='Remover'>
                                                            <IconButton onClick={(e)=>removeArquivo(row)} >
                                                                <RemoveIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                </center>
                                            }
                                            
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" href={(param.get('back')) ? param.get('back') : "/home"} rel="noopener noreferrer">
                            Sair
                        </Button>
                        {
                            value.id &&
                            <Button variant="contained" color="primary" onClick={() => relatorio()}>
                                    Visualizar
                            </Button>
                        }
                    <Button variant="contained" color="primary" onClick={enviarRascunho} disabled={!podeEnviar()}>
                            Salvar rascunho
                    </Button>
                
                    <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} disabled={!podeEnviar() }>
                            Enviar
                    </Button>

                    </Grid>
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirma envio da resposta a notificação?'
                        exec={confirmaAprovacao}
                    />

                    <BuscaDadoDialog 
                        open={showBuscaDadoDialog} 
                        setOpen={setShowBuscaDadoDialog} 
                        acao={setProcesso}
                        colunas={[
                            {nome:'CNPJ', campo:'cnpj'},
                            {nome:'Nome', campo:'razaoSocial'},
                            {nome:'Processo', campo:'nProcesso'},
                        ]}
                        dados={processos}/>
                </Grid>

            </Paper>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />

        </Container>
    );
}
