import React, { useState } from 'react';
import { Container, Card, CardContent, Grid, TextField, Checkbox, FormControlLabel, Button } from '@material-ui/core';
import { useStyles, FormataData } from '../../../constants';
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";
import Service from '../../../services/Service';
import { useLocation } from 'react-router-dom';

export default function DetalharDadosCartaConsultaProcessoPanel(props) {
    const classes = useStyles();
    const { processo, setProcesso, gestaoProcesso, profile, setWait, setMensagem } = props;
    const [alteracao, setAlteracao] = useState(false);
    const location = useLocation();

    function isEmpresario() {
        if (location.pathname.includes("cadastrarProcesso")) return true;
        if (profile) {
            return profile.regras.find(r => r === '012') && true;
        }
        return false;
    }

    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            setAlteracao(false);
            // history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeCartaConsultaResolucao = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            cartaConsulta: {
                ...processo.cartaConsulta,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleCheck = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            cartaConsulta: {
                ...processo.cartaConsulta,
                [e.target.name]: e.target.checked
            }
        })
    }
    return (
        <Container>
            <Card style={{ marginTop: '5px' }}>
                <CardContent>
                    <Grid container spacing={3} className={classes.root}>
                        {!isEmpresario() &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={processo && processo.cartaConsulta && processo.cartaConsulta.naoSeAplica || false}
                                            onChange={handleCheck}
                                            name="naoSeAplica"
                                            color="primary"
                                        />
                                    }
                                    label="Não se aplica"
                                />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                value={processo.cartaConsulta.cartaConsultaResolucao || ''}
                                fullWidth
                                label="Resolução/Edital nº"
                                id="cartaConsultaResolucao"
                                name="cartaConsultaResolucao"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo && processo.cartaConsulta && processo.cartaConsulta.cartaConsultaDataResolucao || ''}
                                fullWidth
                                label="Data da Resolução"
                                id="cartaConsultaDataResolucao"
                                name="cartaConsultaDataResolucao"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo.cartaConsulta.cartaConsultaPublicacaoDodf || ''}
                                fullWidth
                                label="Publicação DODF"
                                id="cartaConsultaPublicacaoDodf"
                                name="cartaConsultaPublicacaoDodf"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo && processo.cartaConsulta && processo.cartaConsulta.cartaConsultaDataDodf || ''}
                                fullWidth
                                label="Data de Publicação"
                                id="cartaConsultaDataDodf"
                                name="cartaConsultaDataDodf"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                value={processo.cartaConsulta.cartaConsultaNatureza || ''}
                                fullWidth
                                label="Natureza"
                                id="cartaConsultaNatureza"
                                name="cartaConsultaNatureza"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="carta" />
                        </Grid> */}
                        {!isEmpresario() &&
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.cartaConsulta && processo.cartaConsulta.linkFolha || ''}
                                    onChange={handleChangeCartaConsultaResolucao}
                                    fullWidth
                                    label="Carta Consulta (Link Folha)"
                                    id="linkFolha"
                                    name="linkFolha"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                    </Grid>
                    {alteracao &&
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" onClick={() => salvar()} color="primary" >
                                Salvar
                            </Button>
                        </Grid>
                    }

                </CardContent>
            </Card>
        </Container>
    );
}
