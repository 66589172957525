import React, { useState, useEffect } from 'react';
import { Grid, TextField }
    from '@material-ui/core';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

export default function InformacaoBasicaForm(props) {
    const {value, setValue, disable} = props;
    const [txt, setTxt] = useState(value.objetivo?value.objetivo:'');
    useEffect(()=>{
        setValue({
            ...value,
            objetivo:txt
        })
    },[txt])

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };

    return (
        // <Paper >
            <Grid container spacing={1} >
                <Grid item xs={3}>
                    <TextField
                        required
                        label="CNPJ"
                        value={value.empresa && value.empresa.cnpj || ''}
                        name="cnpj"
                        id="cnpj-mask-input"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        value={value.empresa && value.empresa.razaoSocial || ''}
                        fullWidth
                        label="Razão Social"
                        id="razaoSocial"
                        name="razaoSocial"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                    />
                </Grid>
                <Grid item xs={3}>
                <TextField
                        required
                        label="CF/DF"
                        value={value && value.empresa.cfdf || ''}
                        name="CFDF"
                        id="cfdf"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                        />
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        value={value && value.empresa.nomeFantasia||''}
                        fullWidth
                        label="Nome Fantasia"
                        id="nomeFantasia"
                        name="nomeFantasia"
                        onChange={handleChangeInputForm}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                        InputProps={{
                            readOnly: true,
                          }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                        }}
                        data={txt}
                        onReady={editor => {
                            editor.editing.view.change(writer => {
                                writer.setStyle(
                                    "height",
                                    "150px",
                                    editor.editing.view.document.getRoot()
                                );
                            });
                        }}
                        disabled={disable}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setTxt(data);
                        }}
                        onBlur={(event, editor) => {

                        }}
                        onFocus={(event, editor) => {

                        }}
                    />
                </Grid>

            </Grid>
        // </Paper>
    );
}
