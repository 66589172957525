import React, { useState, useEffect } from 'react';
import { Container, Card, CardHeader, CardContent, Grid, TextField, Tooltip, IconButton, Button } from '@material-ui/core';
import { useStyles, FormataData } from '../../../constants';
import SearchIcon from '@material-ui/icons/Search';
import { useLocation, useHistory } from "react-router-dom";
import UnidadeDialog from "../../user/unidade/UnidadeDialog";
import ConfirmDialog from "../../../util/ConfirmDialog";
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import Service from '../../../services/Service';

let unSelecionada = null;
export default function DetalharDadosGeraisProcessoPanel(props) {
    const classes = useStyles();
    const { processo, setProcesso, profile, unidades, setWait, setErro, gestaoProcesso, setMensagem } = props;
    const location = useLocation();
    const [showUnidadeDialog, setShowUnidadeDialog] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [unidade, setUnidade] = useState();
    const [unidadesFolha, setUnidadesFolha] = useState();
    const [alteracao, setAlteracao] = useState(false);

    useEffect(() => {
      
        if (gestaoProcesso) {
            console.log(gestaoProcesso)
            const item = unidades.find(u => u.id === gestaoProcesso.idUnidade);
            setUnidade(item);
            montaUnidadesFolha();
        }
    }, [gestaoProcesso])


    const buscaDado = () => {
        setShowUnidadeDialog(true);
    }
    function unidadeSelecionada(un) {
        setUnidade(un);
        unSelecionada = un;
        setShowConfirm(true);
    }
    function montaUnidadesFolha() {
        let arr = [];
        for (let x in unidades) {
            const u = unidades[x];
            if (u.id === 2) continue; //ignora a folha 2 (motivos negociais)
            const item = unidades.find(un => un.pai === u.id);
            if (!item) {
                arr.push(u)
            }
        };
        setUnidadesFolha(arr);
    }
    const hasRegra037 = () => {
        return profile && profile.regras.find(r => r === '037');
    }
    const isMovimentarProcesso = () => {
        return (location.pathname.includes("movimentarProcesso")) ? true : false
    }
    const mudaUnidadeDoProcesso = () => {
        setWait(true);
        const dado = {
            idProcesso: processo.id,
            idUnidade: unSelecionada.id
        }
        fetch('/api/gerenciarProcesso/mudarUnidade', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                response.json()
                    .then(ret => {
                        setWait(false);
                        // forceUpdate();
                        window.location.reload();
                    })
            } else {
                response.json().then(erro => {
                    setErro(erro.message);
                })
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function montaSituacao() {
        let ret = '';
        if (processo && processo.situacao) {
            if (typeof processo.situacao === 'string') {
                ret = processo.situacao;
            } else {
                ret = processo.situacao.status;
            }
        }
        return ret;
    }

    function isEmpresario() {
        if (location.pathname.includes("cadastrarProcesso")) return true;
        if (profile) {
            return profile.regras.find(r => r === '012') && true;
        }
        return false;
    }

    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            setAlteracao(false);
            // history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeDadoEmpresa = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            dadosEmpresa: {
                ...processo.dadosEmpresa,
                [e.target.name]: e.target.value
            }
        })
    }
    const handleChangeDadosEmpresaDt = (e) => {
        setAlteracao(true);
        console.log(e)
        setProcesso({
            ...processo,
            dadosEmpresa: {
                ...processo.dadosEmpresa,
                dataPreenchimento: e
            }
        })
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container>
                <Card style={{ marginTop: '5px' }}>
                    <CardHeader title="Dados processo" />
                    <CardContent>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.nProcesso || ''}
                                    fullWidth
                                    label="Processo"
                                    id="processo"
                                    name="processo"
                                    variant="outlined"
                                    disabled

                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.dataCriacao && FormataData(new Date(processo.dataCriacao)) || ''}
                                    fullWidth
                                    label="Data criação"
                                    id="dataCriacao"
                                    name="dataCriacao"
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={montaSituacao()}
                                    // value={processo && processo.situacaoStatus || ''}
                                    fullWidth
                                    label="Situação Status"
                                    id="situacaoStatus"
                                    name="situacaoStatus"
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <div className={classes.btnInLine}>
                                    <TextField
                                        value={(unidade && unidade.nome) || ''}
                                        fullWidth
                                        label="Unidade"
                                        id="unidade"
                                        disabled
                                        name="unidade"
                                        variant="outlined"
                                    />
                                    {isMovimentarProcesso() && hasRegra037() &&
                                        <Tooltip disableTouchListener={true} title='Buscar Processo'>
                                            <IconButton onClick={buscaDado} >
                                                <SearchIcon />
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    // value={montaSituacao()}
                                    value={processo && processo.programa || ''}
                                    fullWidth
                                    label="Programa"
                                    id="programa"
                                    name="programa"
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card style={{ marginTop: '5px' }}>
                    <CardHeader title="Dados empresa" />
                    <CardContent>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={4}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.cnpj || ''}
                                    fullWidth
                                    label="CNPJ"
                                    id="cnpj"
                                    name="cnpj"
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.razaoSocial || ''}
                                    fullWidth
                                    label="Razão Social"
                                    id="razaoSocial"
                                    name="razaoSocial"
                                    variant="outlined"
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.cpfResponsavel || ''}
                                    fullWidth
                                    label="CPF Responsável"
                                    id="cpf"
                                    name="cpf"
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.nomeResponsavel || ''}
                                    fullWidth
                                    label="Nome Responsável"
                                    id="nomeResp"
                                    name="nomeResp"
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    value={processo && processo.dadosEmpresa && processo.dadosEmpresa.cfdf || ''}
                                    fullWidth
                                    label="CF/DF"
                                    id="cfdf"
                                    name="cfdf"
                                    disabled
                                    variant="outlined"
                                />
                            </Grid>
                            {!isEmpresario() && <>
                                <Grid item xs={4}>
                                    <TextField
                                        value={processo && processo.dadosEmpresa && processo.dadosEmpresa.contratoSocial || ''}
                                        fullWidth
                                        label="Contrato Social (Link/ Folha)"
                                        id="contratoSocial"
                                        name="contratoSocial"
                                        // disabled
                                        variant="outlined"
                                        onChange={handleChangeDadoEmpresa}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        value={processo && processo.dadosEmpresa && processo.dadosEmpresa.ultimaAlteracaoContratual || ''}
                                        fullWidth
                                        label="Ultima Alteração Contratual (Link/ Folha)"
                                        id="ultimaAlteracaoContratual"
                                        name="ultimaAlteracaoContratual"
                                        // disabled
                                        variant="outlined"
                                        onChange={handleChangeDadoEmpresa}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        id="dataPreenchimento"
                                        // disabled
                                        label="Data do Preenchimento"
                                        format="dd/MM/yyyy"
                                        value={processo && processo.dadosEmpresa && processo.dadosEmpresa.dataPreenchimento || null}
                                        onChange={handleChangeDadosEmpresaDt}
                                        fullWidth
                                        KeyboardButtonProps={true}
                                        inputVariant="outlined"
                                        invalidDateMessage="Formato inválido"
                                    />
                                </Grid>
                            </>}
                            {/* <Grid item xs={6}>
                                <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="processo" />
                            </Grid> */}

                        </Grid>
                        {alteracao &&
                            <Grid container justify="flex-end" className={classes.btns}>
                                <Button variant="contained" onClick={() => salvar()} color="primary" >
                                    Salvar
                                </Button>
                            </Grid>
                        }
                    </CardContent>
                </Card>
                {
                    unidadesFolha &&
                    <UnidadeDialog open={showUnidadeDialog} setOpen={setShowUnidadeDialog} acao={unidadeSelecionada} unidades={unidadesFolha} />
                }
                <ConfirmDialog
                    open={showConfirm}
                    setOpen={setShowConfirm}
                    titulo={'Confirmação'}
                    msg='Confirma troca da unidade?'
                    exec={mudaUnidadeDoProcesso}
                />

            </Container>
        </MuiPickersUtilsProvider>
    );
}
