import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';

export default function CelulaCabecalhoTabelaComponent(props) {

    const StyledTableCell = withStyles((theme) =>
        createStyles({
            head: {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
            },
            body: {
                fontSize: 14,
            },
        }),
    )(TableCell);

    return (
        <StyledTableCell>{props.children}</StyledTableCell>
    );
}
