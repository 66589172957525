import React, { useState, useEffect, useRef } from 'react';
import { Accordion, AccordionDetails, 
    AccordionSummary, Button, 
    FormControl, Grid, 
    InputLabel, MenuItem, 
    Paper, Select, 
    TextField, Typography }
    from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../../../constants';
import GenericTable from '../../template/GenericTable';
import { useHistory, useParams } from 'react-router';
import NumberFormat from 'react-number-format';

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;
    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={4}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
let ex = 0;

export default function DadosApoioForm(props) {
    const { setTitulo, setMensagem, setWait, setErro, disable } = props;
    const [value, setValue] = useState({
        txIcms:1.5,
        perDescontoBeneficioEsp:60,
        perDescontoBeneficioGeral:75,
        perDescontoBeneficioInte:83,
        indiceAnaliseExec:400.1
    });
    const classes = useStyles();
    let { id } = useParams();
    const history = useHistory();
    const [alterar, setAlterar] = useState(false);


    useEffect(() => {
        setTitulo("Cadastro de Dados de apoio");
        buscarDadoApoio();
    }, []);

    function buscarDadoApoio() {
        fetch('/api/fundefeDadoApoio/' + id)
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            let arr = [];
                            for(let x in json.dadosCDI){
                                let a  = json.dadosCDI[x];
                                arr.push({...a, 
                                    m: meses.indexOf(a.mes)
                                })
                            }
                            
                            json.dadosCDI = arr;
                            setValue({
                                ...json,
                            });
                            ex = json.exercicio
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }

    const handleChangeInputForm = (event) => {
        setValue({ ...value, [event.target.name]: event.target.value });
    };

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === 'Alterar') {
            setValue({
                ...value,
                mes: dado.mes,
                cdi: dado.cdi
            })
            setAlterar(true);
        } else if (acao === 'Excluir') {
            const dado = { 
                ...value.dadosCDI
            };
            let arr = [];
            for(let x in dado){
                if(x != index){
                    arr.push(dado[x]);
                }
            }
            setValue({
                ...value,
                dadosCDI: arr
            });
        }
    }


    const handleChangeDadosCdi = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
    }
    function incluir() {
        let arr = [];
        const dadosTab = {
            m: meses.indexOf(value.mes),
            mes: value.mes,
            cdi: value.cdi
        };
        if (alterar) {
            if (value.dadosCDI) {
                for (let x in value.dadosCDI) {
                    const d = value.dadosCDI[x]
                    if (d.mes === value.mes){
                        arr.push(dadosTab);
                    }else{
                        arr.push(d)
                    }
                }
            }
        } else {
            if (value.dadosCDI) {
                for (let x in value.dadosCDI) {
                    const d = value.dadosCDI[x]
                    if (d.mes === value.mes) return setMensagem("Mês ja cadastrado")
                    arr.push(d)
                }
            }
            arr.push(dadosTab);
        }
        arr = arr.sort( (a, b) =>{
            let indexA = meses.indexOf(a.mes);
            let indexB = meses.indexOf(b.mes);
            return indexA - indexB;
        });

        setAlterar(false);
        setValue({
            ...value,
            mes: '',
            cdi: '',
            dadosCDI: arr
        })
    }
    const cancelar = () => {
        setAlterar(false);
    }
    function confirmaAprovacao() {
        let arr = []
        for (let x in value.dadosCDI) {
            let val = value.dadosCDI[x].cdi;
            if (val && typeof val ==="string") {
            val = val.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            val = val.replace(",", ".");
        }
            arr.push({
                mes: value.dadosCDI[x].mes,
                cdi: val
            });
        }
        let dado = {...value};
        dado.dadosCDI= arr;
        
        if (dado.juros && typeof dado.juros === "string") {
            let val = dado.juros.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            dado.juros = val.replace(",", "."); 
         }

        if (dado.txIcms && typeof dado.txIcms === "string") {
            let val = dado.txIcms.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            dado.txIcms = val.replace(",", "."); 
        }
        if (dado.perDescontoBeneficioEsp && typeof dado.perDescontoBeneficioEsp === "string") {
            let val = dado.perDescontoBeneficioEsp.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            dado.perDescontoBeneficioEsp = val.replace(",", "."); 
        }
        if (dado.perDescontoBeneficioGeral && typeof dado.perDescontoBeneficioGeral === "string") {
            let val = dado.perDescontoBeneficioGeral.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            dado.perDescontoBeneficioGeral = val.replace(",", "."); 
        }
        if (dado.perDescontoBeneficioInte && typeof dado.perDescontoBeneficioInte === "string") {
            let val = dado.perDescontoBeneficioInte.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            dado.perDescontoBeneficioInte = val.replace(",", "."); 
        }
        if (dado.indiceAnaliseExec && typeof dado.indiceAnaliseExec === "string") {
            let val = dado.indiceAnaliseExec.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            dado.indiceAnaliseExec = val.replace(",", "."); 
        }
        if (dado.descontoICMS && typeof dado.descontoICMS === "string") {
            let val = dado.descontoICMS.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            dado.descontoICMS = val.replace(",", "."); 
        }
        
        dado.id = id;
        submit(dado);
    }

    function submit(dado) {
        setWait(true);
        fetch('/api/fundefeDadoApoio', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then(json => {
                    history.replace("/fundefe/dadosApoioTable/");
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem('exercício ja cadastrado posteriormente.');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function formata(dado) {
        let num = 0;
        if (dado && typeof dado === "string") {
            dado = dado.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            num = parseFloat(dado.replace(",", "."));
        }else{
            num = parseFloat(dado);
        }
        var cont = 0.95;
        var calc = num * cont
        return calc.toFixed(4);
    }

    function podeEnviar() {
        if ((value && value.exercicio || '').length === 0) return true
        if ((value && value.juros || '').length === 0) return true
        return false
    }

    function disableIncluir() {
        if ((value && value.mes || '').length === 0) return true
        if ((value && value.cdi || '').length === 0) return true
        return false
    }


    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Informações</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} >

                        <Grid item xs={3}>
                            <TextField
                                disabled={disable}
                                value={value && value.exercicio || ''}
                                fullWidth
                                type="number"
                                label="Exercício"
                                id="exercicio"
                                name="exercicio"
                                onChange={handleChangeInputForm}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Taxa Juros Mercado</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} >
                        <Grid item xs={3}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="tipo" >Mês</InputLabel>
                                <Select
                                    disabled={disable}
                                    id="mes"
                                    name='mes'
                                    label="MÊs"
                                    placceholder="Mês"
                                    onChange={handleChangeDadosCdi}
                                    value={value && value.mes || ''} >
                                    {
                                        meses && meses.map((tp, i) => (
                                            <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                disabled={disable}
                                value={value && value.cdi || ''}
                                fullWidth
                                InputProps={{
                                    inputComponent: MonetarioField
                                }}
                                label="CDI"
                                id="cdi"
                                name="cdi"
                                onChange={handleChangeInputForm}
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                        {!disable &&
                            <Grid className={classes.btns}>
                                <Button variant="contained" color="primary" onClick={incluir} disabled={disableIncluir()}>
                                    {alterar ? "Alterar" : "Incluir"}
                                </Button>
                                {alterar &&
                                    <Button variant="contained" color="secondary" rel="noopener noreferrer"  onClick={cancelar} >
                                        Cancelar
                                    </Button>
                                }
                            </Grid>
                        }

                    </Grid>
                </AccordionDetails>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        {!disable &&
                            <GenericTable
                                actionClick={handleActionSolicitarClick}
                                disableSearch
                                options={['Alterar', 'Excluir']}
                                dados={value ? (value.dadosCDI) : []}
                                colunas={[
                                    { nome: 'Mês', campo: 'm', transformacao: (m)=>{ return meses[m]} },
                                    { nome: 'CDI', campo: 'cdi' },
                                    { nome: '95% CDI', campo: 'cdi', transformacao: formata },
                                    { nome: 'Ação', campo: 'action' }
                                ]} />
                        }
                        {disable &&
                            <GenericTable
                                actionClick={handleActionSolicitarClick}
                                disableSearch
                                dados={value ? (value.dadosCDI || []) : []}
                                colunas={[
                                    { nome: 'Mês', campo: 'mes' },
                                    { nome: 'CDI', campo: 'cdi' },
                                    { nome: '95% CDI', campo: 'cdi', transformacao: formata },
                                ]} />
                        }
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Juros Contrato</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} >
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                disabled={disable}
                                label="Taxa Juros(a.m):"
                                name="juros"
                                onChange={handleChangeInputForm}
                                value={value && value.juros || ''}
                                InputProps={{
                                    inputComponent: MonetarioField
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Emprega DF</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} >
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                disabled={disable}
                                label="TAXA ICMS PRÓPRIO EMPREENDIMENTO SEM DESCONTO(%):"
                                name="txIcms"
                                onChange={handleChangeInputForm}
                                value={value && value.txIcms || ''}
                                InputProps={{
                                    inputComponent: MonetarioField
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                disabled={disable}
                                label="INDICE ANÁLISE E EXECUÇÃO (baseado do INPC)(%):"
                                name="indiceAnaliseExec"
                                onChange={handleChangeInputForm}
                                value={value && value.indiceAnaliseExec || ''}
                                InputProps={{
                                    inputComponent: MonetarioField
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        {/* <Grid item xs={4}>
                            <TextField
                                fullWidth
                                disabled={disable}
                                label="DESCONTO ICMS EMPREGA DF:"
                                name="descontoICMS"
                                onChange={handleChangeInputForm}
                                value={value && value.descontoICMS || ''}
                                InputProps={{
                                    inputComponent: MonetarioField
                                }}
                                variant="outlined"
                            />
                        </Grid> */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Grid container spacing={1} >
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" href={"/fundefe/dadosApoioTable"} rel="noopener noreferrer">
                        Sair
                    </Button>
                    {!disable &&
                        <Button variant="contained" color="primary" onClick={() => confirmaAprovacao()} disabled={podeEnviar()}>
                            Salvar
                        </Button>
                    }
                </Grid>
            </Grid>
        </Paper>
    );
}
