import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory, useParams } from "react-router-dom";
import GenericTable from '../../template/GenericTable';
import { FormataDataComHora, useStyles } from '../../../constants';

let fonteDeDados = null;
let dadoOriginal = null;
export default function TableRequerimentos(props) {
    const classes = useStyles();
    const { setErro, setWait, setTitulo } = props;
    const [teste, setTeste] = useState([]);
    const history = useHistory();

    useEffect(() => {
        
        setTitulo("Visualizar Requerimento")
        buscaTodosRequerimento()
    }, []);


    function buscaTodosRequerimento() {
        setWait(true);
        fetch("/api/processoProdf/requerimento/todos",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    dadoOriginal = result;
                    fonteDeDados = [];
                    for (let x in dadoOriginal) {
                        const d = dadoOriginal[x];
                        if (d.status === 'RASCUNHO' || d.status === 'RASCUNHO_ACESSOR' || d.status === 'NAO_RESPONDIDO') continue; // || d.status === 'DESPACHO') continue;

                        fonteDeDados.push(
                            {
                                id: d.id,
                                nProcesso: getNumeroProcesso(d),
                                protocolo: d.protocolo,
                                dataCriacao: d.dataCriacao,
                                dataAnalise: getDataAnalise(d),
                                status: showStatus(d),
                                programa: d.processo ? d.processo.programa : "",
                                responsavel: showResponsavel(d),
                                assunto: d.assunto
                            }
                        );

                    }

                    fonteDeDados = fonteDeDados.sort((a, b) => {
                        if (!a.protocolo || !b.protocolo) return 0;
                        const protocoloA = a.protocolo.trim().split("-");
                        const protocoloB = b.protocolo.trim().split("-");
                        const sA = parseInt(protocoloA[1] + protocoloA[0]);
                        const sB = parseInt(protocoloB[1] + protocoloB[0]);
                        return sA < sB ? 1 : -1;
                    });
                    setTeste(fonteDeDados);
                }
            });
    }

    function getNumeroProcesso(row) {
        if (typeof row.processo === 'string') {
            return row.processo;
        } else {
            return row.processo ? row.processo.nProcesso : "";
        }
    }

    function getDataAnalise(row) {
        let teste = ''
        row.historico.forEach(r => teste = r.data)
        return teste;
    }

    function showResponsavel(row) {
        if (row.responsaveis) {
            let ret = '';
            row.responsaveis.forEach(r => ret += r.nome + ' ');
            return ret.trim();
        }
        return "";
    }


    function showStatus(row) {
        const st = row.status

        if (st === 'RASCUNHO') {
            return "Rascunho"
        } else if (st === 'EM_ANALISE') {
            return "Em análise";
        } else if (st === 'PENDENTE') {
            return "Pendente";
        } else if (st === 'REPROVADO') {
            return "Reprovado";
        } else if (st === 'DEFERIDO') {
            return "Deferido";
        } else if (st === 'INDEFERIDO') {
            return "Indeferido";
        } else if (st === 'DESPACHO') {
            const fluxo = row.fluxoPath[row.fluxoPath.length - 1];
            if (fluxo === 'despachoSubSecretaria') {
                return "Aguardando despacho Subsecretaria"
            } else if (fluxo === 'despachoDiretoria') {
                return "Aguardando despacho Diretoria"
            } else if (fluxo === 'despachoGerencia') {
                return "Aguardando despacho Gerencia"
            } else if (fluxo === 'analiseGerencia') {
                return "Aguardando despacho analise Gerencia";
            } else if (fluxo === 'analiseDiretoria') {
                return "Aguardando despacho analise Diretoria";
            } else if (fluxo === 'analiseSubSecretaria') {
                return "Aguardando despacho analise Subsecretaria";
            }
            return "Aguardando validação gestão UNATE";

        } else if (st === 'DEVOLVIDO') {
            return "Devolvido";
        }
        else {
            return "Em Analise"
        }
    }

    function transformaDt(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }


    const handleActionSolicitarClick = (index, acao, dado) => {
        history.replace("/gerenciaProcesso/semp/parecerTecnicoRequerimentoForm/requerimento?id=" + dado.id + "&editable=false&assunto=" + dado.assunto);

    };

    return (
        <Grid container spacing={3} className={classes.root}>
            

            <GenericTable
                dados={teste || []}
                actionClick={handleActionSolicitarClick}
                options={['Visualizar']}
                colunas={[
                    { nome: 'protocolo', campo: 'protocolo' },
                    { nome: 'nProcesso', campo: 'nProcesso' },
                    { nome: 'Assunto', campo: 'assunto' },
                    { nome: 'Data análise UNATE', campo: 'dataAnalise', transformacao: transformaDt},
                    { nome: 'Situação', campo: 'status' },
                    { nome: 'Ação', campo: 'action' }
                ]} />
        </Grid>
    );
}


