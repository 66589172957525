import React, { useEffect, useState } from 'react';
import {
    Button, Grid, Paper, Container, TextField
}
    from '@material-ui/core';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, FormataData } from "../../../constants"
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Service from "../../../services/Service";
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import EtapaProcessoComp from './EtapaProcessoComp';

export default function CadastroMetasForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;
    const [id, setId] = useState();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [inicioDataVistoria, setInicioDataVistoria] = useState();
    const [value, setValue] = useState({ dcm: {} });
    useEffect(() => {
        setTitulo("Cadastro de Cumprimento de Metas");
        if (param.get("id")) {
            setId(param.get("id"));
            fetch(`/api/processoProdf/${param.get("id")}`).then((r) => {
                setWait(false);
                if (!r.ok) {
                    r.json().then(j => setErro(j));

                } else {
                    r.json().then(result => {
                        setValue(result);
                        if (result.dcm !== null) {
                            setInicioDataVistoria(formatarDataString(result.dcm.dataDcm));
                        }
                    });
                }
            });
        }
    }, []);
    function formatarDataString(d) {
        const data = d.split("/");
        if (data.length === 3) {
            return new Date(data[2], (data[1] - 1), data[0]);
        }
        return '';
    }
    function sair() {
        //history.replace("/gerenciaProcesso/cadastrarProcesso?id=123456789abcd&panel=cartaConsulta");
        if (id) history.replace("/gerenciaProcesso/processo/" + id + "?panel=metas");
        else history.replace("/gerenciaProcesso/cadastrarProcesso/?panel=metas");
    }

    function salvar() {
        let dado = {
            processo: {
                ...value,
                dcm: {
                    ...value.dcm,
                    dataDcm: inicioDataVistoria ? FormataData(inicioDataVistoria) : ""
                }
            }
        };
        submitForm(dado);
    }

    function submitForm(dado) {

        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dado).then(json => {
            setWait(false);
            history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=metas");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeInputFormDataVistoria = (date) => {
        setInicioDataVistoria(date)
    }

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            dcm: {
                ...value.dcm,
                [event.target.name]: event.target.value
            }
        });
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Container maxWidth='md'>
                <Paper >
                    <Grid>

                        <Grid container spacing={1} className={classes.root}>
                            <Grid item xs={6}>
                                <TextField
                                    value={value && value.dcm && value.dcm.declaracaoCumprimentoMetas || ''}
                                    fullWidth
                                    label="Declaração Cumprimento de Metas"
                                    id="declaracaoCumprimentoMetas"
                                    name="declaracaoCumprimentoMetas"
                                    onChange={handleChangeInputForm}
                                    aria-describedby="nova-helper-text"
                                    InputProps={{
                                        readOnly: false,
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    id="dataCumprimentoMetas"
                                    label="Data Declaração Cumprimento Metas"
                                    format="dd/MM/yyyy"
                                    name="dataCumprimentoMetas"
                                    value={inicioDataVistoria || null}
                                    onChange={handleChangeInputFormDataVistoria}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                // disabled={(disable && disable === true)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                {value && value.dadosEmpresa && value.dadosEmpresa.nProcesso &&
                                    <EtapaProcessoComp nProcesso={value.dadosEmpresa.nProcesso} indice={11} />}
                            </Grid>  
                        </Grid>
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                                Sair
                            </Button>
                            <Button variant="contained" onClick={() => salvar()} color="primary">
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </MuiPickersUtilsProvider>
    );
}