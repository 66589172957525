import React, {useEffect, useState} from 'react';
import { Grid } from '@material-ui/core';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AcaoTabelaComponent from '../../common/tabela/AcaoTabelaComponent';
import { useHistory } from "react-router-dom";

//NAO_INFORMADO(0), CANCELADO(1), SUSPENSO(2), AGENDADO(3), AGUARDANDO_AJUSTE(4), EM_EXECUCAO(5), EXECUTADO(6), AJUSTE_VISTORIADOR(7), VALIDACAO_GERENTE(8), AGUARDANDO_AJUSTE_GERENTE(9), VALIDADO_DIRETOR(10);
const map = {
    NAO_INFORMADO: '', 
    CANCELADO: 'Cancelado', 
    SUSPENSO: 'Suspenso', 
    AGENDADO: 'Agendado', 
    AGUARDANDO_AJUSTE: 'Aguardando Ajuste', 
    EM_EXECUCAO: 'Em execução', 
    EXECUTADO: 'Execução concuida', 
    AJUSTE_VISTORIADOR: 'Ajuste vistoriador', 
    VALIDACAO_GERENTE: 'Validada gerente', 
    AGUARDANDO_AJUSTE_GERENTE: 'Aguardando ajuste gerente', 
    VALIDADO_DIRETOR: 'Validado Diretor'
}
export default function DetalharDadosVistoriaProcessoPanel(props) {
    const { vistoria } = props;
    const [dados, setDados] = useState([]);
    const history = useHistory();

    useEffect(()=>{
        let arr = []
        for(let x in vistoria){
            arr.push({
                id: vistoria[x].id,
                protocolo: vistoria[x].protocolo,
                dataSolicitacao: vistoria[x].dataSolicitacao,
                assunto: vistoria[x].assunto,
                situacao: map[vistoria[x].situacao]
            });
        }
        setDados(arr);
    }, [])
    
    const handleAbrirDetalhamento = (r) => {
        history.replace("/vistoria/solicitarView?id=" + r.id );
    };
    return (
        <Grid item xs={12}>
            <TabelaComponent total={dados} dados={dados}>
                <CelulaCabecalhoTabelaComponent identificador='protocolo' titulo="Protocolo" />
                <CelulaCabecalhoTabelaComponent identificador='dataSolicitacao' titulo="Data solicitação" />
                <CelulaCabecalhoTabelaComponent identificador='assunto' titulo="Assunto" />
                <CelulaCabecalhoTabelaComponent identificador='situacao' titulo="Situação" />
                <AcaoTabelaComponent titulo="Visualizar" onClick={(e) => handleAbrirDetalhamento(e)}>
                    <VisibilityIcon />
                </AcaoTabelaComponent>
            </TabelaComponent>
        </Grid>
    );
}
