import React, { useEffect, useState } from 'react';
import {
    Grid, Button, FormControlLabel, Typography, RadioGroup, Radio, Paper, makeStyles,
} from '@material-ui/core';
import { useStyles, FormataData } from "../../constants";
import { useLocation } from "react-router-dom";
// import Switch from '@material-ui/core/Switch';
// import GenericTransferList from '../../template/GenericTransferList';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import GenericTable from '../template/GenericTable';
import Service from '../../services/Service';
import { ptBR } from 'date-fns/locale';

const useStylesLocal = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold, //.fontWeightRegular,
        margin: theme.spacing(2, 0),
    },
}));

export function deParaSituacao(de) {
    if (de === 'ABERTO') return "Aberto";
    if (de === 'FECHADO') return "Fechado";
    if (de === 'CONCLUIDO') return "Concluído";
    if (de === 'CANCELADO') return "Cancelado";
    if (de === 'INICIADO') return "Iniciado";
    if (de === 'ANDAMENTO') return "Em andamento";

    return de;
}
export function deParaTipo(de) {
    if (de === 'PROBLEMA_ACESSO') return "Problema de acesso";
    if (de === 'ERRO_SISTEMA') return "Erro no sistema";
    if (de === 'SUGESTAO_MELHORIA') return "Sugestão de melhoria";
    if (de === 'DUVIDA_NEGOCIAL') return "Dúvida negocial";
    if (de === 'DUVIDA_USO_SISTEMA') return "Dúvida uso do sistema";
    if (de === 'INCONSISTENCIA_DADO') return "Inconsistencia de dado";
    if (de === 'OUTROS') return "Outros";

    return de;
}

export default function FiltroRelatorioChamado(props) {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    const { setTitulo, setWait, setMensagem, setErro } = props;
    const location = useLocation();
    const [dataInicio, setDataInicio] = useState();
    const [dataFim, setDataFim] = useState();
    const [error, setError] = useState(resetErro());
    const [suportes, setSuportes] = useState([]);
    const [fonteDeDados, setFonteDeDados] = useState([]);

    function resetErro() {
        return {
            data: { erro: false, msg: '' },
        }
    }

    useEffect(() => {
        setTitulo("Relatorio Chamado Suporte");
        buscarSuporte();
    }, []);

    function buscarSuporte() {
        setWait(true);
        Service.GET('/api/suporte/').then(result => {
            setWait(false);
            result = result.sort((a, b) => ((a.dataSolicitacao < b.dataSolicitacao) ? 1 : -1));
            filtrarDados(result);

        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
    }

    function filtrarDados(d) {
        let arr = [];
        for (let x in d) {
            const suporte = d[x];
            let dataConclu = '';
            let dataInicioAtend = '';
            let solucao = '';
            let responsavelUtimo = '';
            for (let x in suporte.historico) {
                const his = suporte.historico[x];
                if (his.tipo === "CONCLUIDO") {
                    dataConclu = his.data;
                    solucao = his.texto;
                }
                if (his.tipo === "ANDAMENTO" && dataInicioAtend === '') {
                    dataInicioAtend = his.data;
                }
                if (his.tipo === "ABERTO") {
                    responsavelUtimo = " ";
                } else {
                    responsavelUtimo = his.responsavel.nome
                }

            }
            arr.push({
                id: suporte.id,
                numeroChamada: suporte.numeroChamada,
                email: suporte.email,
                tipoSolicitacao: deParaTipo(suporte.tipo),
                situacao: deParaSituacao(suporte.situacao),
                descricaoOcorrencia: suporte.descricao,
                dataSolicitacao: suporte.dataCriacao,
                dataConclusao: dataConclu,
                solucao: solucao,
                responsavel: responsavelUtimo,
                nome: suporte.nome,
                descricao: suporte.descricao,
                dataCriacao: suporte.dataCriacao
            })
        }
        setSuportes(arr);
        setFonteDeDados(arr);
    }

    function formataData(dt) {
        if (!dt) return "";
        return FormataData(new Date(dt));
    }



    const handleDateChangedataInicio = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (date > dataFim && dataFim !== null) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        setDataInicio(date);
        const dt = formataData(dataInicio);
        if (date && dataFim && date <= dataFim && date !== null && dataFim !== null) filtroPorPeriodo(date, dataFim);
    }

    const handleDateChangedataFim = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (date < dataInicio && dataInicio !== null) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        setDataFim(date);
        if (date && dataInicio && date >= dataInicio && date !== null && dataInicio !== null) filtroPorPeriodo(dataInicio, date);
    }

    function filtroPorPeriodo(dataI, dataF) {
        dataI = new Date(dataI.setHours(0, 0, 0, 0));
        dataF = new Date(dataF.setHours(23, 59, 59, 999));
        let arr = [];
        for (let x in fonteDeDados) {
            const sup = fonteDeDados[x];
            if (new Date(sup.dataCriacao) < dataI || new Date(sup.dataCriacao) > dataF) {
                continue;
            }
            arr.push(sup);
        }
        // console.log(arr);
        setSuportes(arr);

    }

    const handleCliciGerarRelatorio = () => { //  relatorioListagemFrom relatorioListagemFrom
        let listId = []
        for (let x in suportes) {
            listId.push(suportes[x].id);
        }
        console.log(listId);

        fetch("/api/suporte/relatorio.pdf", {
            method: "POST",
            body: JSON.stringify(listId),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Paper>
                <Grid container spacing={2} className={classes.root} >

                    <Grid item >
                        <Typography className={classesLocal.heading} gutterBottom>Período:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <KeyboardDatePicker
                            // disabled={disable}
                            disableToolbar
                            variant="inline"
                            id="dataInicio"
                            // label="Período"
                            format="dd/MM/yyyy"
                            value={dataInicio || null} onChange={handleDateChangedataInicio}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                            invalidDateMessage="Formato inválido"
                        />
                    </Grid>
                    <Grid item >
                        <Typography className={classesLocal.heading} gutterBottom>a</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <KeyboardDatePicker
                            error={error.data.erro}
                            helperText={error.data.msg}
                            disableToolbar
                            variant="inline"
                            id="dataFim"
                            format="dd/MM/yyyy"
                            value={dataFim || null} onChange={handleDateChangedataFim}
                            fullWidth
                            KeyboardButtonProps={true}
                            inputVariant="outlined"
                            invalidDateMessage="Formato inválido"
                        />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <GenericTable
                        // actionClick={handleActionSolicitarClick}
                        // options={['Visualizar', 'Atender']}
                        // handleDisableAction={desabilitaAcaoAtenderTable}
                        dados={suportes}
                        // readOnly={isReadOnly()}
                        colunas={[
                            { nome: 'Número chamado', campo: 'numeroChamada' },
                            { nome: 'Nome', campo: 'nome' },
                            { nome: 'Tipo solicitação', campo: 'tipoSolicitacao' },
                            { nome: 'Descrição', campo: 'descricao' },
                            { nome: 'Data criação', campo: 'dataCriacao', transformacao: formataData },
                            { nome: 'Data de resolução', campo: 'dataConclusao', transformacao: formataData },
                            { nome: 'Resumo Conslusão', campo: 'solucao' }
                        ]} />

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" href={"/suporte/listaChamados"} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={handleCliciGerarRelatorio}>
                            Gerar Relatório
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </MuiPickersUtilsProvider>
    );
}