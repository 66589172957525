import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Button, Paper, Menu, MenuItem,
} from '@material-ui/core';
import { useStyles, FormataData } from "../../constants";
import GenericTable from "../template/GenericTable";
import { useHistory } from "react-router-dom";


let situacaoVistoria;

export function deParaSituacao(de) {
    if (de === 'ABERTO') return "Aberto";
    if (de === 'FECHADO') return "Fechado";
    if (de === 'CONCLUIDO') return "Concluído";
    if (de === 'CANCELADO') return "Cancelado";
    if (de === 'INICIADO') return "Iniciado";
    if (de === 'ANDAMENTO') return "Em andamento";

    return de;
}
export function deParaTipo(de) {
    if (de === 'PROBLEMA_ACESSO') return "Problema de acesso";
    if (de === 'ERRO_SISTEMA') return "Erro no sistema";
    if (de === 'SUGESTAO_MELHORIA') return "Sugestão de melhoria";
    if (de === 'DUVIDA_NEGOCIAL') return "Dúvida negocial";
    if (de === 'DUVIDA_USO_SISTEMA') return "Dúvida uso do sistema";
    if (de === 'INCONSISTENCIA_DADO') return "Inconsistencia de dado";
    if (de === 'OUTROS') return "Outros";

    return de;
}

export default function ChamadosTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait, setMensagem } = props;
    const [dados, setDados] = useState([]);
    const history = useHistory();
    const [fonteDeDados, setFonteDeDados] = useState([]);

    useEffect(() => {
        setTitulo("Listagem Chamado");
        buscarSuporte();

        return () => {
            setFonteDeDados({});
        };
    }, []);

    function isReadOnly() {
        return false;
    }

    function buscarSuporte() {
        fetch("/api/suporte")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    let arr = [];
                    result = result.sort((a, b) => ((a.dataSolicitacao < b.dataSolicitacao) ? 1 : -1));
                    filtrarDados(result);

                }
            });
    }

    function filtrarDados(d) {
        let arr = [];
        for (let x in d) {
            const suporte = d[x];
            let dataConclu = '';
            let dataInicioAtend = '';
            let solucao = '';
            let responsavelUtimo = '';
            for (let x in suporte.historico) {
                const his = suporte.historico[x];
                if (his.tipo === "CONCLUIDO") {
                    dataConclu = his.data;
                    solucao = his.texto;
                }
                if (his.tipo === "ANDAMENTO" && dataInicioAtend === '') {
                    dataInicioAtend = his.data;
                }
                if(his.tipo=== "ABERTO"){
                  responsavelUtimo =" ";
                }else{
                    responsavelUtimo = his.responsavel.nome
                }
              
            }
            arr.push({
                id: suporte.id,
                numeroChamada: suporte.numeroChamada,
                email: suporte.email,
                tipoSolicitacao: deParaTipo(suporte.tipo),
                situacao: deParaSituacao(suporte.situacao),
                descricaoOcorrencia: suporte.descricao,
                dataSolicitacao: suporte.dataCriacao,
                inicioAtend: diferencaData(suporte.dataCriacao, dataInicioAtend),
                conclusaoAtend: diferencaData(suporte.dataCriacao, dataConclu),
                dataConclusao: dataConclu,
                solucao: solucao,
                responsavel: responsavelUtimo,
                nome: suporte.nome
            })
        }
        setFonteDeDados(arr);
    }

    function diferencaData(i, f) {
        if (i === undefined || f === undefined || !i || !f) return "";
        const dataAberto = new Date(i);
        const dataIniciado = new Date(f);
        const milliseconds = dataIniciado.getTime() - dataAberto.getTime();
        const days = Math.floor(milliseconds / (60 * 60 * 24 * 1000))
        const hora = Math.floor((milliseconds % (60 * 60 * 24 * 1000)) / (60 * 60 * 1000))
        const min = Math.round((milliseconds % (60 * 60 * 1000)) / (60 * 1000))
        return (days !== 0 ? (days + " dia ") : "") + (hora !== 0 ? (hora + "h e ") : "") + min + "min";
    }
    const handleActionSolicitarClick = (index, acao, dado) => {
        situacaoVistoria = dado.situacao;
        if (acao === "Visualizar") {
            history.push("/suporte/chamadoSuporteView?id=" + dado.id);
        } else if (acao === "Atender") {
            history.push("/suporte/chamadoSuporteForm?id=" + dado.id);
        } else {
            console.error("Ação não desenvolvida :\n", acao)
        }
    }
    function formataData(dt) {
        if (!dt) return "";
        return FormataData(new Date(dt));
    }

    const desabilitaAcaoAtenderTable = (acao, dado) => {
        
        if (acao === "Atender" && dado) {
            return (dado.situacao === "Cancelado" || dado.situacao === "Concluído");
            
        }
        
        return false;
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={['Visualizar', 'Atender']}
                handleDisableAction={desabilitaAcaoAtenderTable}
                dados={fonteDeDados}
                readOnly={isReadOnly()}
                colunas={[
                    { nome: 'Número chamado', campo: 'numeroChamada' },
                    { nome: 'Data abertura', campo: 'dataSolicitacao', transformacao: formataData },
                    { nome: 'Tipo solicitação', campo: 'tipoSolicitacao' },
                    { nome: 'e-mail', campo: 'email' },
                    { nome: 'Nome', campo: 'nome' },
                    { nome: 'Situação', campo: 'situacao' },
                    { nome: 'Data de resolução', campo: 'dataConclusao', transformacao: formataData },
                    { nome: 'Tempo inicio atendimento', campo: 'inicioAtend' },
                    { nome: 'Tempo conclusão', campo: 'conclusaoAtend' },
                    { nome: 'Responsável', campo: 'responsavel' },
                    { nome: 'Ação', campo: 'action' }
                ]} />

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="primary" href={"/suporte/gerarRelatorio"} >
                    Gerar relatorio 
                </Button>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
            </Grid>

        </Grid >


    );
}
