import React, { useState } from 'react';
import { Container, Card, CardContent, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { useStyles } from '../../../constants';
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";
import Service from '../../../services/Service';
import { useLocation } from 'react-router-dom';

export default function DetalharDadosMetasProcessoPanel(props) {
    const classes = useStyles();
    const { processo, setProcesso, gestaoProcesso, profile, setWait, setMensagem } = props;
    const [alteracao, setAlteracao] = useState(false);
    const location = useLocation();

    function isEmpresario() {
        if (location.pathname.includes("cadastrarProcesso")) return true;
        if (profile) {
            return profile.regras.find(r => r === '012') && true;
        }
        return false;
    }

    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            setAlteracao(false);
            // history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeDcm = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            dcm: {
                ...processo.dcm,
                [e.target.name]: e.target.value
            }
        })
    }

    const handleCheck = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            dcm: {
                ...processo.dcm,
                [e.target.name]: e.target.checked
            }
        })
    }
    return (
        <Container>
            <Card style={{ marginTop: '5px' }}>
                <CardContent>
                    <Grid container spacing={3} className={classes.root}>
                        {!isEmpresario() &&
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={processo && processo.dcm && processo.dcm.naoSeAplica || false}
                                            onChange={handleCheck}
                                            name="naoSeAplica"
                                            color="primary"
                                        />
                                    }
                                    label="Não se aplica"
                                />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                value={processo.dcm.declaracaoCumprimentoMetas || ''}
                                fullWidth
                                label="Declaração Cumprimento Metas"
                                id="declaracaoCumprimentoMetas"
                                name="declaracaoCumprimentoMetas"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={processo.dcm.dataDcm || ''}
                                fullWidth
                                label="Data declaração Cumprimento Metas"
                                id="dataDcm"
                                name="dataDcm"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="metas" />
                        </Grid> */}
                        {!isEmpresario() &&
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.dcm && processo.dcm.linkFolha || ''}
                                    onChange={handleChangeDcm}
                                    fullWidth
                                    label="Link/ Folha"
                                    id="linkFolha"
                                    name="linkFolha"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                    </Grid>
                    {alteracao &&
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" onClick={() => salvar()} color="primary" >
                                Salvar
                            </Button>
                        </Grid>
                    }
                </CardContent>
            </Card>
        </Container>
    );
}
