import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Paper, Menu, MenuItem,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Button
} from '@material-ui/core';
import CampoBusca from "../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useStyles } from "../../constants";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { withStyles, makeStyles } from '@material-ui/core/styles';

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;

let index = 0;

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    body: {
        // backgroundColor: theme.palette.common.black,
        //   color: theme.palette.common.white,
        fontSize: 14,
        fontWeight: 700

    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export default function GenericTable(props) {
    let filtroCampo = null;
    const classes = useStyles();
    const { dados, colunas, options, actionClick, readOnly, disableSearch, handleDisableAction, rowTotal, rowMedia} = props;

    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [qtd, setQtd] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    const handleAcao = (e, id) => {
        index = id;
        setAnchorEl(e.currentTarget);
    }

    const handleActionClick = (action) => {
        let dado = dadosFiltrado[index];
        let acao = options[action];
        if (actionClick) {
            actionClick(index, acao, dado);
            filtroCampo = null;
        }
        setAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
    }

    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
            } else {
                disparaSearch();
            }
        }, tempo);
    }

    useEffect(() => {
        if (dados) {
            if (qtd > 0 || dados.length !== qtd) {
                fonteDeDados = [...dados];
                filtraDado();
            }
        }
    }, [dados]);

    useEffect(() => {
        fonteDeDados = dados ? [...dados] : [];
        filtraDado();
    }, [ordem, pagina, bloco]);
    function buscaRecursivo(objeto, valor) {
        for (let x in objeto) {
            if (typeof objeto[x] === 'string') {
                if (objeto[x].toUpperCase().includes(valor.toUpperCase())) {
                    return true
                }
            } else if (typeof objeto[x] === 'object') {
                if (buscaRecursivo(objeto[x], valor)) {
                    return true;
                }
            } else if (objeto[x] == valor) {
                return true;
            }
        }
        return false;
    }
    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];
        let arrOrdenado = ordenar(fonteDeDados);

        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                let pula = false;

                let campos = [filtroCampo];
                if (filtroCampo.split("\"").length === 2) pula = true;


                if (!pula) {
                    campos = filtroCampo.split("\"").length > 2 ? [filtroCampo.split("\"")[1]] : filtroCampo.split(' ');
                    root: for (let x in d) {
                        for (var y in campos) {
                            if (typeof d[x] === 'string') {
                                if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                    arr.push(d);
                                    break root;
                                }
                            } else if (typeof d[x] === 'object') {
                                if (buscaRecursivo(d[x], campos[y].toUpperCase())) {
                                    arr.push(d);
                                    break root;
                                }
                            } else if (d[x] == campos[y]) {
                                arr.push(d);
                                break root;
                            }
                        }
                    }
                } else {
                    arr.push(d);
                }
            } else {
                arr.push(d);
            }
        });
        let arrBloco = [];
        setQtd(arr.length)

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (++index >= pagina) break;
        }
        setDadosFiltrado(arrBloco);
    }

    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);

        setBloco(0);
    }
    function getValue(row, campo, transformacao) {
        let split = campo.split("\.");
        if (split.length > 1) {
            try {
                let lstObjt = row;
                for (let c in split) {
                    lstObjt = transformacao ? transformacao(lstObjt[split[c]]) : lstObjt[split[c]];
                }
                return lstObjt;
            } catch (erro) {
                return ""
            }
        }
        return transformacao ? transformacao(row[split[0]]) : row[split[0]];
    }
    function getTip(row) {
        return "Ação";
    }
    function acaoDisabilitar(acao) {
        if (readOnly && readOnly === true) return true;
        if (handleDisableAction) return handleDisableAction(acao, dadosFiltrado[index]);
        else return false;
    }

    return (
        <Grid container spacing={3} className={classes.root}>

            {(!disableSearch || disableSearch === false) &&
                <Grid item xs={12} >
                    <CampoBusca handleSearch={handleSearch} />
                </Grid>
            }

            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                {colunas && colunas.map((col, index) => (
                                    <TableCell key={index} sortDirection={false} className={classes.tableHead} align={col.align}>
                                        <TableSortLabel
                                            active={ordem.campo === col.campo}
                                            direction={ordem.ordem}
                                            onClick={(e) => createSortHandler(col.campo)}
                                        >
                                            {col.nome}
                                        </TableSortLabel>
                                    </TableCell>
                                ))
                                }

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={"row" + i}>
                                    {colunas && colunas.map((col, ii) => {
                                        // <TableCell key={"col"+i+"_"+ii}>{row[col.campo]}</TableCell>
                                        // { col.campo === 'action' &&
                                        if (col.campo === 'action') {
                                            return (
                                                <TableCell key={"col" + i + "_" + ii} align="center">
                                                    <Box >

                                                        {(options[0] === 'Visualizar' && options.length == 1) &&
                                                            < IconButton
                                                                onClick={() => {
                                                                    index = i;
                                                                    handleActionClick(0)
                                                                }}
                                                            >
                                                                <VisibilityIcon />
                                                            </IconButton>
                                                        }

                                                        {options.length > 1 &&
                                                            <IconButton
                                                                onClick={(e) => handleAcao(e, i)}
                                                                disabled={(readOnly && readOnly === true) ? true : false}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        }
                                                        {
                                                            (options[0] !== 'Visualizar' && options.length == 1 ) &&
                                                            <Button
                                                                onClick={() => {
                                                                    index = i;
                                                                    handleActionClick(0)
                                                                }}
                                                            >
                                                                {options[0]}
                                                            </Button>
                                                        }


                                                    </Box>
                                                </TableCell>
                                            )

                                        } else {
                                            if (col.align) {
                                                return <TableCell align={col.align} key={"col" + i + "_" + ii}>{getValue(row, col.campo, col.transformacao)}</TableCell>
                                            } else {
                                                return <TableCell key={"col" + i + "_" + ii}>{getValue(row, col.campo, col.transformacao)}</TableCell>
                                            }
                                        }
                                        // }

                                    })}

                                </TableRow>
                            ))}
                            {rowTotal && <StyledTableRow>
                                {rowTotal.map((row, i) => (
                                    // ----------
                                    <StyledTableCell align={i > 0 ? "right" : "left"} key={i}>{row}</StyledTableCell>
                                ))}
                            </StyledTableRow>}
                            { rowMedia && <StyledTableRow>
                                {rowMedia.map((row,i) =>(
                                    // ----------
                                    <StyledTableCell align={i > 0 ?"right": "left"} key={i}>{row}</StyledTableCell>      
                                ))}   
                            </StyledTableRow>}


                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    dados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={qtd}
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </Grid>
            {
                options &&
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={openAction}
                    onClose={handleClose}
                    PaperProps={{
                        // style: {
                        //     maxHeight: ITEM_HEIGHT * 4.5,
                        //     width: '20ch',
                        // },
                    }}
                >
                    {options.map((option, index) => (
                        <MenuItem
                            key={option}
                            selected={option === 'Pyxis'}
                            onClick={() => handleActionClick(index)}
                            disabled={acaoDisabilitar(option)}
                        >
                            {option}
                        </MenuItem>
                    ))}
                </Menu>
            }
        </Grid >


    );
}
