const ArquivoService = {
    upload: function(arquivo) {
        return fetch(`/api/upload/file`, {
            method: "POST",
            body: arquivo,
          }).then(async (r) => {
            if (!r.ok) {
                throw await r.json();
            }
            return r.json();
        });
    },
};

export default ArquivoService;
