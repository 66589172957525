import React, { useEffect, useState } from 'react';
import {
    Grid, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import GenericTable from "../../components/template/GenericTable";
import { FormataMoeda, useStyles } from "../../constants";
import { useHistory, useParams } from "react-router-dom";
import ConfirmDialog from '../../util/ConfirmDialog';
import { useCurrentUser } from "../../server/UseCurrentUser";

let idParcela = null;
export default function PrevisaoOrcamentariaTable(props) {

    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;
    const history = useHistory();
    const [showConfirmExclusao, setShowConfirmExclusao]= useState(false);
    let { projeto } = useParams();
    let [profile] = useCurrentUser();
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = useState(['Visualizar', 'Alterar', 'Excluir', 'Replicar']);
    
    const [fonteDeDados, setFonteDeDados] = useState();
    useEffect(() => {
        setTitulo("Previsão Orçamentaria - FUNDEFE - " + projeto);
        buscaFundefePrevisao();
    }, [projeto]);

    useEffect(() => {
        if (profile){
            if( profile.regras.find(r => r === "047")){
                setOptions(['Visualizar']);
            }
        }
    }, [profile]);
    
    function buscaFundefePrevisao() {
        fetch("/api/fundefePrevisao")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    let arr = [];
                    const itens = result.filter(f => f.programa === projeto);
                    for(let x in itens){
                        arr.push({
                            ...itens[x],
                            matriz: formata(itens[x].isMatriz)
                        })
                    }                    
                    setFonteDeDados(arr);
                }
            });
    }
    function formata(valor) {
        return valor ? "Matriz" : "Filial";
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === "Visualizar") {
            history.replace("/fundefe/PlanejamentoView/"+dado.id);
        } else if (acao === "Alterar") {
            history.replace("/fundefe/PlanejamentoAlter/"+dado.id);
        } else if (acao === "Excluir") {
            setShowConfirmExclusao(true);
            idParcela = dado.id;
        }else if( acao === "Replicar"){
            idParcela = dado.id;
            setOpen(true);
        }
    }
    function confirmaExclusao(){
        setWait(true);
        fetch("/api/fundefePrevisao/"+idParcela,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    let value = [];
                    if (fonteDeDados && (fonteDeDados.length > 0)) {
                        for (let i = 0; i < fonteDeDados.length; i++) {
                            if (fonteDeDados[i].id !== idParcela) {
                            value.push(fonteDeDados[i]);
                            }
                        }
                    }
                    setFonteDeDados(value);
                }
            });
    }
    function replicar(){
        let val = document.getElementById("replicaExercicio").value;
        
        fetch("/api/fundefePrevisao/replicar/"+idParcela+"/"+val,{
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            })
            .then(res => res.json()).then((result) => {
                setWait(false);
                // if (result.ok) 
                {
                    setOpen(false)
                    let arr = [...fonteDeDados];                    
                    arr.push({
                        ...result,
                        matriz: formata(result.isMatriz)
                    });
                    setFonteDeDados(arr);
                }
            });
    }

    function isSomenteConsulta(){
        if( profile && profile.regras.find(r => r === "047")){
            return true;
        }
        return false;
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={options}
                dados={fonteDeDados}
                colunas={[
                    { nome: 'Exercício', campo: 'exercicio' },
                    { nome: 'Processo', campo: 'processo' },
                    { nome: 'CNPJ', campo: 'cnpj' },
                    { nome: 'Razão Social', campo: 'razaoSocial' },
                    { nome: 'Matriz/Filial', campo: 'isMatriz', transformacao: formata },
                    { nome: 'RA', campo: 'ra' },
                    { nome: 'Setor', campo: 'setor'},
                    { nome: 'Valor', campo: 'valor',transformacao: FormataMoeda, align: "right" },
                    { nome: 'Ação', campo: 'action' }
                ]} />
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
                {!isSomenteConsulta() &&
                <Button variant="contained" color="primary" href={"/fundefe/PlanejamentoForm/" + projeto}  >
                    Novo
                </Button>}
            </Grid>
            <ConfirmDialog
                    open={showConfirmExclusao}
                    setOpen={setShowConfirmExclusao}
                    titulo={'Confirmação'}
                    msg='Confirma exclusão do item selecionado?.'
                    exec={confirmaExclusao}
                />

                <FormDialog open={open} setOpen={setOpen} exec={replicar}/>
        </Grid >
    );
}

function FormDialog(props) {
    const {open, setOpen, exec} = props;

    const handleKeyPress = (e)=>{
        if (e.keyCode === 13) {
            e.preventDefault();
            exec();
        }
    }
  
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Replicar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Informa o novo exercicio para os quais os dados serão replicados
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="replicaExercicio"
              label="Novo Exercicio"
              onKeyDown={handleKeyPress}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={exec} color="primary">
              Replicar
            </Button>
          </DialogActions>
        </Dialog>
    );
  }