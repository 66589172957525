import React from 'react';
import Avatar from '@material-ui/core/Avatar';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useLocation } from "react-router-dom";
import { login } from '../util/APIUtils';
import { ACCESS_TOKEN } from '../constants';
import HeaderGDF from '../components/common/HeaderGDF';
import FooterGDF from '../components/common/FooterGDF';
import { logDOM } from '@testing-library/dom';

function BemVindo1() {

  /*
  Bem-vindo ao Sistema de Gerenciamento da Secretaria de Desenvolvimento Econômico Trabalho e Renda - SEDET2023.
  */

  return (

    <Typography variant="body2" color="textSecondary" align="center">
      {'Bem-vindo ao  '}

      Sistema de Gerenciamento da Secretaria de Desenvolvimento Econômico Trabalho e Renda - SEDET

      {new Date().getFullYear()}
      {'.'}
    </Typography>


  );
}

function BemVindo2() {

  return (

    <Typography variant="body2" color="textSecondary" align="center">
      {'Bem-vindo ao  '}

      Sistema de Gerenciamento da Secretaria de Desenvolvimento Econômico Trabalho e Renda - SEDET

      {new Date().getFullYear()}
      {'.'}
    </Typography>


  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  suporte: {
    marginLeft: '140px',
    left: 50,
  }
}));

export default function SignIn(props) {
  const location = useLocation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');

  const [user, setUser] = React.useState({
    login: '',
    password: ''
  });

  const handleInputChange = event => {

    const target = event.target;
    const value = target.value;
    const name = target.id;
    user[name] = value;

    setUser(user);
    setOpen(false);
  }


  const handleSubmit = (event) => {
    event.preventDefault();

    if (!document.getElementById("login").value || !document.getElementById("password").value) {
      setMsgErro("Os campos Usuário e Senha são obrigatórios.");
      setOpen(true);
      return;
    }

    localStorage.removeItem(ACCESS_TOKEN + "_menu");
    login(user)
      .then(response => {
        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        // setOpen(false);
        if (props.onChange) props.onChange(false);
        // window.location.reload();
        window.location.href = "/home";


      }).catch(error => {
        setOpen(true);
        if (error.status === 401) {
          setMsgErro("Usuário ou senha inválidos!");
        } else {
          setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        }
        document.getElementById("login").value = "";
        document.getElementById("password").value = "";
        document.getElementById("login").focus();
      });
  }

  function logo() {
    if (window.location.hostname === "sga.sde.df.gov.br") {
      return true
    }
  }


  return (
    <div >
      <HeaderGDF />
      <Container maxWidth="xs">
        <div >
          <Collapse in={open}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {msgErro}
            </Alert>
          </Collapse>
        </div>

        <CssBaseline />
        <div className={classes.paper}>

          {!logo() &&
            <img src="/images/logoSEMP.png" />
          }
          {logo() &&
            <img src="/images/logoSDE.png" />
          }

          <form className={classes.form} noValidate method="post" onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="login"
              label="Usuário (e-mail)"
              name="login"
              autoComplete="login"
              autoFocus onChange={handleInputChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password" onChange={handleInputChange}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Entrar
          </Button>
            <Grid container>
              <Grid item xs={4}>
              <Link href="/home/recuperarSenha" variant="body2">
                Esqueci a senha
              </Link>
            </Grid>

              <Grid item xs={4} >
                <Link href="/home/ConsultarChamadoForm" variant="body2">
                  Suporte ao usuário
              </Link>
              </Grid>

              <Grid item xs={4}>
              <Link href="/home/SolicitarAcessoForm" variant="body2">
                Solicitar Acesso
              </Link>
            </Grid>
            </Grid>
          </form>
        </div>
        {logo() &&
          <Box mt={2}>
            <BemVindo1 />
          </Box>
        }
        {!logo() &&
          <Box mt={2}>
            <BemVindo2 />
          </Box>
        }
      </Container>
      <footer>
        <div>
          <FooterGDF />
        </div>
      </footer>

    </div>
  );
}
