import React, { useEffect, useState } from 'react';
import {
    Grid, Button,
} from '@material-ui/core';
import GenericTable from "../../template/GenericTable";
import { useStyles, FormataMoeda, FormataDataSemHora } from "../../../constants";
import { useHistory } from "react-router-dom";
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useCurrentUser } from "../../../server/UseCurrentUser";

let idParcela = null;
export default function BeneficiariosTable(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setErro } = props;
    const history = useHistory();
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const [options, setOptions] = useState(['Visualizar', 'Alterar', 'Excluir']);
    let [profile] = useCurrentUser();

    useEffect(() => {
        setTitulo("Benefíciarios Emprega DF");
        buscarDadoEmpregaDF();
    }, []);
    useEffect(() => {
        if (profile){
            if( profile.regras.find(r => r === "049")){
                setOptions(['Visualizar']);
            }
        }
    }, [profile]);

    function buscarDadoEmpregaDF() {
        fetch("/api/empregaDF")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    let arr = [];
                    for (let x in result) {
                        let item = result[x];
                        item.matrizFilial = result[x].isMatriz ? "Matriz" : "Filial";
                        item.dataPublicacaoFormatada = transformaDt(result[x].dataPublicacao);
                        arr.push(item);
                    }
                    setFonteDeDados(arr);
                }
            });
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === "Visualizar") {
            history.replace("/fundefe/DadosFinanceirosView/"+dado.id);
        } else if (acao === "Alterar") {
            history.replace("/fundefe/DadosFinanceirosForm/" + dado.id);
        } else if (acao === "Excluir") {
            setShowConfirmExclusao(true);
            idParcela = dado.id;
        }
    }

    function transformaDt(valor) {
        if (valor) {
            return FormataDataSemHora(new Date(valor));
        }
        return "";
    }

    function confirmaExclusao(){
        setWait(true);
        fetch("/api/empregaDF/"+idParcela,{
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    let value = [];
                    if (fonteDeDados && (fonteDeDados.length > 0)) {
                        for (let i = 0; i < fonteDeDados.length; i++) {
                            if (fonteDeDados[i].id !== idParcela) {
                            value.push(fonteDeDados[i]);
                            }
                        }
                    }
                    setFonteDeDados(value);
                }
            });
    }
    function isSomenteConsulta(){
        if( profile && profile.regras.find(r => r === "049")){
            return true;
        }
        return false;
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={options}
                dados={fonteDeDados}
                colunas={[
                    { nome: 'Processo', campo: 'processoSEI' },
                    { nome: 'CNPJ', campo: 'cnpj' },
                    { nome: 'Razão social', campo: 'razaoSocial' },
                    { nome: 'Matriz/Filial', campo: 'matrizFilial' },
                    { nome: 'DODF', campo: 'diarioOficial' },
                    { nome: 'Data Publicação', campo: 'dataPublicacaoFormatada'},
                    { nome: 'Ação', campo: 'action' }
                ]} />

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
                {!isSomenteConsulta() &&
                <Button variant="contained" color="primary" href={"/fundefe/DadosFinanceirosForm"} >
                    Cadastrar
                </Button>}
            </Grid>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />
        </Grid >


    );
}