import React, { useState, useEffect } from 'react';
import { Button, Typography, Grid, Container, Paper, FormControl, 
    Collapse, FormHelperText, IconButton } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';    
import CloseIcon from '@material-ui/icons/Close';
import { useStyles, getQueryParams } from "../../../constants";
import TextField from '@material-ui/core/TextField';
import { useHistory, useLocation } from "react-router-dom";
import ConfirmDialog from "../../../util/ConfirmDialog";

export default function PrimeiroAcessoForm() {
    const classes = useStyles();
    const param = getQueryParams(useLocation().search);
    const [hashVerificado, setHashVerificado] = useState(false);
    const [showConfirmConclusao, setShowConfirmConclusao] = useState(false);
    const [erro, setErro] = useState();
    const history = useHistory();
    const [values, setValues] = useState({
        novaSenha: '',
        confirmaSenha: ''
    });
    const [error, setError] = useState({
        hasErro: false,
        msg: ''
    })
    useEffect(() => {
        localStorage.clear();
        const hash = param.get("hash");
        fetch('/api/acesso/primeiroAcesso/' + hash, {
            method: 'GET'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                response.json().then((json) => {
                    setHashVerificado(true);
                });
            } else {
                response.json().then((error) => {
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }, [])

    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };
    const handleBtnClick = (e) => {
        let er = {
            hasErro: false,
            msg: ''
        }
        if (values.novaSenha !== values.confirmaSenha) {
            er.hasErro = true;
            er.msg = "Senha não confere. Por fafor confirme a senha"
        }
        setError(er);
        if (!er.hasErro) {
            const dado = {
                hash: param.get('hash'),
                pass: values.novaSenha
            }
            submitForm(dado);
        }
    }
    function submitForm(dado) {
        fetch('/api/acesso/primeiroAcesso', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(dado)
        }).then(response => {
            if (response.ok ) {
                
                setShowConfirmConclusao(true)
            } else {
                response.json().then((error) => {
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    const Conteudo = () => {
        return (
            <Paper className={classes.paperNormal}>
                <form noValidate autoComplete="off">
                    <Grid container spacing={3} className={classes.root}>
                        <Grid item xs={12}>
                            <Typography component="h5" variant="h5" >
                                Para ter acesso completo ao sistema é necessario cadastrar uma senha
                </Typography>
                        </Grid>
                        <Grid item xs={12}>

                            <FormControl>
                                <TextField name="senha"
                                    type="password"
                                    label="Senha"
                                    name='novaSenha'
                                    value={values.novaSenha}
                                    onChange={handleChangeInputForm}
                                    variant="outlined"
                                    fullWidth
                                />
                                <FormHelperText id="my-helper-text">Informe a senha nova</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl>
                                <TextField name="confirSenha"
                                    type="password"
                                    label="Confirmar a senha"
                                    name='confirmaSenha'
                                    value={values.confirmaSenha}
                                    error={error.hasErro}
                                    helperText={error.msg}
                                    onChange={handleChangeInputForm}
                                    variant="outlined"
                                    fullWidth
                                />
                                <FormHelperText id="my-helper-text">Confirme sua nova senha</FormHelperText>
                            </FormControl>
                        </Grid>

                    </Grid>
                </form>
            </Paper>
        )
    }
    const ConteudoNaoVerificado = () => {
        return (
            <div>
                <Typography component="h5" variant="h5" >
                    Validando hash....
                </Typography>
            </div>
        )
    }
    return (
        <Container maxWidth="sm" >

            {
                erro && 
                <Paper className={classes.paper}>
                <Collapse in={erro && erro.length > 0}>
                    <Alert severity='error' action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setErro('');
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }>{erro}</Alert>
                </Collapse>
            </Paper>
            }
            {hashVerificado && Conteudo()}
            {!hashVerificado && ConteudoNaoVerificado()}

            <Grid container justify="flex-end" className={classes.btns}>
                <Button href="/home"
                    variant="contained"
                    color="secondary">Voltar</Button>
                {hashVerificado &&
                    <Button variant="contained"
                        color="primary" disabled={values.novaSenha.length === 0 || values.confirmaSenha.length === 0}
                        onClick={handleBtnClick}>Cadastrar</Button>
                }
            </Grid>
            <ConfirmDialog
                        open={showConfirmConclusao}
                        btn={'OK'}
                        disableCancelar={true}
                        setOpen={setShowConfirmConclusao}
                        titulo={'Acesso ao sistema criado com sucesso'}
                        msg={'A seguir será redirecionado para a tela de login onde poderá entrar com a senha cadastrada'}
                        exec={()=>history.replace("/login")}
                    />

        </Container>
    );
}
