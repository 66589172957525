import React from 'react';
import Avatar from '@material-ui/core/Avatar';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CardMedia from '@material-ui/core/CardMedia';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
 
  head: {
    backgroundColor: '#515151',
    color: theme.palette.common.white,
    // maxHeight: 35
  },
  link: {
    color: '#ccc',
    backgroundColor: 'transparent',
    lineHeight:'30px',
    marginRight:'20px',
    transition: 'all linear 0.2s',
    cursor: 'pointer',
    textDecoration: 'none',
    fontFamily: '"Montserrat", sans-serif',
    fontSize: '12px'
  },
  linkA: {
    color: '#ccc',
    backgroundColor: 'transparent',
    transition: 'all linear 0.2s',
    cursor: 'pointer',
    textDecoration: 'none'
  },
  right: {
    position: 'absolute',
    right: '0px',
  },
  separaAncoras: {
    margin: '0 10px',
    color: '#ccc'
  }
}));

export default function HeaderGDF(props) {
  const classes = useStyles();

  return (
    
    <AppBar position="static" className={classes.head} height="15%">
      <Toolbar variant="dense">
        <a href="http://www.transparencia.df.gov.br/" target="_blank" className={classes.link}>Transparência</a>
        <a href="http://www.ouvidoria.df.gov.br/" target="_blank" className={classes.link}>Ouvidoria</a>
        <a href="http://www.df.gov.br/category/acesso-a-informacao/" className={classes.link}>Acesso à Informação</a>
        <a href="http://www.dodf.df.gov.br/" target="_blank" className={classes.link}>Diário Oficial</a>
        <a href="https://www.agenciabrasilia.df.gov.br/" target="_blank" className={classes.link}>Agência Brasília</a>
        <a href="http://www.brasilia.df.gov.br" target="_blank" className={classes.link}> Portal do Governo de Brasília</a>
        <a href="http://www.dados.df.gov.br/" target="_blank" className={classes.link}>Dados Abertos</a>

        <div edge="end" className={classes.right}>
          <a href="http://brasilia.df.gov.br/index.php/acessibilidade/" className={classes.link}>Acessibilidade</a>
          <span className={classes.separaAncoras}>|</span>
          <a className={classes.linkA}>A
            <span>a</span>
          </a>
          <span className={classes.separaAncoras}>|</span>
          <a href="http://www.vlibras.gov.br/" target="_blank" className={classes.link}>
            <img src="http://www.empreender.df.gov.br/wp-conteudo/themes/site-secretarias/img/ico-vlibras.svg" alt="Vlibras" />
          </a>

        </div>
      </Toolbar>
    </AppBar>
  );
}