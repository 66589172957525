import React, { useEffect, useState } from 'react';
import { Button, Grid, InputBase, Paper, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useStyles, getQueryParams } from '../../constants';
import SearchIcon from '@material-ui/icons/Search';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FolderIcon from '@material-ui/icons/Folder';
import DescriptionIcon from '@material-ui/icons/Description';
import GenericTable from "../../components/template/GenericTable";
import { useLocation } from "react-router-dom";

import PdfView from '../../util/PdfView';
import Service from '../../services/Service';
import { blue } from '@material-ui/core/colors';

const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        '&:hover > $content': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:focus > $content, &$selected > $content': {
            backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
            color: 'var(--tree-view-color)',
        },
        '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
            backgroundColor: 'transparent',
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '$expanded > &': {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    group: {
        marginLeft: 0,
    },
    label: {
        fontWeight: 'inherit',
        // color: 'inherit',
        backgroundColor: "green",
        color: "red"
    },
    labelRoot: {
        display: 'flex',
        color: 'primary',
        // padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: 'inherit',
        // flexGrow: 1,
    },
}));

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other } = props;
    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <LabelIcon style={{ color: blue[200] }} className={classes.labelIcon} />
                    <Typography variant="body2" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                '--tree-view-color': color,
                '--tree-view-bg-color': bgColor,
            }}
            {...other}
        />
    );
}

export default function ConsultarProcessoSei(props) {
    const { setErro, setWait, setTitulo, setMensagem } = props;
    const classes = useStyles();
    const [processoSei, setProcessoSei] = useState();
    const [documentos, setDocumentos] = useState();
    const [documento, setDocumento] = useState();
    const [historico, setHistorico] = useState();
    const [procedimentoSemFormato, setProcedimentoSemFormato] = useState();

    const [values, setValues] = useState();
    const [andamento, setAndamento] = useState();
    const location = useLocation();
    const param = getQueryParams(useLocation().search);
    useEffect(() => {
        setTitulo("Consultar Processos SEI")
        if (location.search !== "") {
            consultarPrecedimentoNProcesso();
        };
        setProcessoSei(false);
        setDocumento(false);
        setAndamento(false);
    }, [location]);

    function consultarPrecedimento() {
        let processo = values.processo;
        if( processo.length > 10){
            processo = processo.replace(/[^0-9]/g, "");
            if (processo.length < 14) processo = '0' + processo;
        }
        setAndamento();
        fetch('/api/gerenciarProcesso/cosultaProcedimento/' + processo, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        })
            .then(resp => resp.json())
            .then(json => {
                setWait(false);
                if (json && json.status >= 400) {
                    setErro({
                        tipo: "error",
                        mensagem: json.message
                    });
                } else {
                    if( processo.length > 8){
                        setProcedimentoSemFormato(processo);
                    }
                    setProcessoSei(json.procedimento);
                    setDocumentos(json.documentos);
                    setHistorico(json.historicos);
                }

            }).catch(error => {
                setWait(false);
                setErro(error ? error : 'Oops! Something went wrong. Please try again!');
            });
    }

    function consultarPrecedimentoNProcesso() {
        const processo = param.get('nProcesso');
        let p = processo.replace(/[^0-9]/g, "");
        if (p.length < 14) p = '0' + p;
        setAndamento();
        fetch('/api/gerenciarProcesso/cosultaProcedimento/' + p, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        })
            .then(resp => resp.json())
            .then(json => {
                setWait(false);
                if (json && json.status >= 400) {
                    setErro({
                        tipo: "error",
                        mensagem: json.message
                    });
                } else {
                    setProcedimentoSemFormato(p);
                    setProcessoSei(json.procedimento);
                    setDocumentos(json.documentos);
                    setHistorico(json.historicos);
                }

            }).catch(error => {
                setWait(false);
                setErro(error ? error : 'Oops! Something went wrong. Please try again!');
            });
    }
    function buscaDocumentosProcesso(processo) {
        fetch('/api/gerenciarProcesso/consultaDocumentosSei/' + processo, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        })
            .then(resp => resp.json())
            .then(json => {
                setWait(false);
                setAndamento(false)
                if (json && json.status >= 400) {
                    setErro({
                        tipo: "error",
                        mensagem: json.message
                    });
                } else {
                    setDocumento(json);
                }
            })
            .catch(error => {
                setWait(false);
                setErro(error)
            });
    }

    const handleDocClick = (doc) => {
        setWait(true);
        setDocumento(null);
        buscaDocumentosProcesso(doc.doc);
    }

    const handleChangeInputForm = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    function detalharAndamento() {
        setDocumento();
        // setWait(true);
        setAndamento(true);
        // buscaDetalhamentoProcesso();
    }

    return (
        <Grid container spacing={3} className={classes.root} >


            {location.search === "" &&
                <Grid container spacing={3} className={classes.root}>
                    <Grid item xs={4}>

                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                autoFocus={true}
                                name="processo"
                                id="processo"
                                placeholder="Buscar…"
                                onChange={handleChangeInputForm}
                                fullWidth
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>

                    </Grid>
                    <Grid item xs={2}>

                        <Button variant="contained" color="primary" onClick={consultarPrecedimento}>
                            Consultar
                        </Button>

                    </Grid>
                </Grid>
            }

            {processoSei &&
                <Grid item xs={4}>
                    <Paper elevation={0} >
                        {/* <Typography className={classes.title} variant="h6" noWrap align="inherit">
                            Processo {processoSei.procedimentoFormatado}
                        </Typography> */}
                        <TreeView
                            className={classes.rootTree}
                            defaultExpanded={['3']}
                            defaultCollapseIcon={<ArrowDropDownIcon />}
                            defaultExpandIcon={<ArrowRightIcon />}
                            defaultEndIcon={<div style={{ width: 24 }} />}
                        >
                            <StyledTreeItem nodeId="3" labelText={`Processos ${processoSei.procedimentoFormatado}`}
                                labelIcon={FolderIcon}
                            >
                                {
                                    documentos && documentos.map((doc) =>
                                        <StyledTreeItem key={doc.doc}
                                            nodeId={doc.doc}
                                            labelText={doc.tipo + " (" + doc.doc + ")"}
                                            labelIcon={DescriptionIcon}
                                            color="#1a73e8"
                                            bgColor="#e8f0fe"
                                            onClick={() => handleDocClick(doc)}
                                        />
                                    )
                                }
                            </StyledTreeItem>
                        </TreeView>
                        <Divider />
                        {/* <Grid container justifyContent="flex-start" className={classes.btns}> */}
                        <div style={{ marginLeft:"5px", marginTop: '20px' }} >
                            
                            <Button size="small" variant="contained" color="primary" onClick={detalharAndamento} startIcon={<SearchIcon />}>
                                Detalhar Andamento
                            </Button>
                        </div>
                        {/* </Grid> */}
                    </Paper>
                </Grid>
            }

            {!processoSei &&
                <Grid item xs={4} >
                    <Paper elevation={0} >
                        buscando...
                    </Paper>
                </Grid>
            }

            <Grid item xs={8} >
                <Paper elevation={0} >
                    {documento &&
                        // <iframe src={documento.linkAcesso}></iframe>
                        <PdfView setTitulo={setTitulo} setWait={setWait} setErro={setErro} titulo={"Relatório"}
                            url={"/api/gerenciarProcesso/montaLinkSei/" + documento.documentoFormatado}
                        />
                    }
                    {andamento &&
                        <>
                            <Typography className={classes.title} variant="h6" noWrap align="inherit">
                                Histórico do Processo {processoSei.procedimentoFormatado}
                            </Typography>
                            <Grid item xs={12} >
                                <GenericTable
                                    options={['Visualizar', 'Alterar', 'Excluir']}
                                    dados={historico}
                                    colunas={[
                                        { nome: 'Data/hora', campo: 'data' },
                                        { nome: 'Unidade', campo: 'unidade' },
                                        { nome: 'Descrição', campo: 'desc' },
                                    ]}
                                />
                            </Grid >
                        </>
                    }
                </Paper>
            </Grid>

            {/* <Grid item xs={8} style={{ height: 50 }}>
                <Paper elevation={4}>

                    {documento &&
                        // <iframe src={documento.linkAcesso}></iframe>
                        <PdfView setTitulo={setTitulo} setWait={setWait} setErro={setErro} titulo={"Relatório"}
                            url={"/api/gerenciarProcesso/montaLinkSei/" + documento.documentoFormatado}
                        />
                    }
                    {andamento &&
                        <>
                            <Typography variant="body2" color="textSecondary" align="inherit">
                                Histórico do Processo {processoSei.procedimentoFormatado}
                            </Typography>
                            <Grid item xs={12} >
                                <GenericTable
                                    options={['Visualizar', 'Alterar', 'Excluir']}
                                    dados={historico}
                                    colunas={[
                                        { nome: 'Data/hora', campo: 'data' },
                                        { nome: 'Unidade', campo: 'unidade' },
                                        { nome: 'Descrição', campo: 'desc' },
                                    ]}
                                />
                            </Grid >
                        </>
                    }
                </Paper>
            </Grid> */}

        </Grid>
    )
}