import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useStyles } from "../../constants";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

let turno = "";
let vistoriadores = "";
let obs=""
export default function DadosAgendaForm(props) {
    const classes = useStyles();
    const { disable, vistoria } = props;

    const [dataAgenda, setDataAgenda] = useState( null );

    useEffect(() => {
        vistoriadores = "";
        if( vistoria ){
            setDataAgenda( new Date( vistoria.agenda ? vistoria.agenda.data : '') );
            turno = vistoria.agenda ? vistoria.agenda.turno : '';
            if( vistoria.agenda ? vistoria.agenda.vistoriadores : false ){
                for(let x in vistoria.agenda.vistoriadores){
                    const user = vistoria.agenda.vistoriadores[x];
                    vistoriadores +=(vistoriadores.length> 1)?",\n":"";
                    vistoriadores +=user.user.nome;
                }
                obs = vistoria.agenda.observacao;
            }
        }
    }, [])

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Typography variant="h5" gutterBottom>Dados Agenda</Typography>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={4}>
                    <KeyboardDateTimePicker
                        disableToolbar
                        variant="inline"
                        id="data"
                        label="Data"
                        format="dd/MM/yyyy"
                        value={dataAgenda || null}
                        fullWidth
                        KeyboardButtonProps={true}
                        inputVariant="outlined"
                        disabled={disable}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextField
                        value={turno}
                        fullWidth
                        label="Turno:"
                        id="turno"
                        name="turno"
                        InputProps={{readOnly: true}}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        value={vistoriadores || ''}
                        fullWidth
                        label="Vistoriadores:"
                        id="vistoriadores"
                        name="vistoriadores"
                        InputProps={{readOnly: true}}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                        multiline
                        rows={3}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={obs}
                        multiline
                        rows={4}
                        fullWidth
                        label="Observações:"
                        id="observacao"
                        name="observacao"
                        InputProps={{readOnly: true}}
                        aria-describedby="nova-helper-text"
                        variant="outlined"
                    >
                    </TextField>
                </Grid>
            </Grid>
            
        </MuiPickersUtilsProvider>
    )
}