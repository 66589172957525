import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid, TextField, Container, Radio, RadioGroup, FormControlLabel, FormControl,
    FormLabel, Select, Button, Typography, InputLabel, MenuItem,
    Table, TableBody, TableHead, TableRow, TableCell, TableContainer, Tooltip, IconButton
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import GenericTable from "../../../components/template/GenericTable";
import Service from "../../../services/Service";
import { ptBR } from 'date-fns/locale';

const useStylesLocal = makeStyles(() => ({
    dialog: {
        height: 900,
    },
}));

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
const days = ["DOMINGO", "SEGUNDA", "TERÇA", "QUARTA", "QUINTA", "SEXTA", "SABADO"];

export default function AgendarVistoriaDialog(props) {
    const { open, setOpen, vistoria, data, setWait, actionFeito, setErro, lstVistoria, alteraVistoriador, reagendarVistoria } = props;
    const classesLocal = useStylesLocal();
    const [mensagem, setMensagem] = useState();

    const [dataAgenda, setDataAgenda] = useState(data);
    const [user, setUser] = useState();
    const [rows, setRows] = useState([]);
    const [vistoriadores, setVistoriadores] = useState([]);
    const [vistoriadorDisponivel, setVistoriadorDisponivel] = useState([]);
    const [turno, setTurno] = useState("");
    const [capacidade, setCapacidade] = useState();

    useEffect(() => {
        if ((alteraVistoriador || reagendarVistoria) && vistoria) {
            setDataAgenda(vistoria.agenda.data);
            if (!reagendarVistoria) {
                setTurno(vistoria.agenda.turno);
                setRows(vistoria.agenda.vistoriadores);
            }
            // vistoria.agenda.observacao
        }
        buscarCapacidade();
        setDataAgenda(data);
        buscaVistoriador(data);
        setMensagem();
    }, [data]);

    useEffect(() => {
        informaVistoriadoresDisponiveis(rows);
    }, [turno, vistoriadores]);

    const handleChange = (e) => {
        setUser(e.target.value)
    }
    const handleClose = () => {
        setOpen(false);
        setTurno();
        setVistoriadores([]);
        setRows([]);
        if (alteraVistoriador) actionFeito();
    };

    function informaVistoriadoresDisponiveis(jaIncluidos) {
        if (!dataAgenda || !vistoriadores || !turno) return;
        let arr = [];

        vistoriadores.forEach(v => {
            if (v.indisponibilidades) {
                let ok = true;
                for (let x in v.indisponibilidades) {
                    const disp = v.indisponibilidades[x];
                    let dtInicio = new Date(disp.dataInicio);
                    dtInicio.setDate(dtInicio.getDate() - 1);
                    const dtFim = new Date(disp.dataFim);
                    let entre = (dtInicio <= dataAgenda && dtFim > dataAgenda);
                    if (entre) {
                        if (disp.turno === turno.toLowerCase()) {
                            ok = false;
                            break;
                        }
                    }
                }
                if (ok && !naoIncluido(v, jaIncluidos)) {
                    arr.push(v);
                }
            } else {
                if (!naoIncluido(v, jaIncluidos)) {
                    arr.push(v);
                }
            }
        });

        setVistoriadorDisponivel(arr);
        setUser();
    }

    function validarAgendamento() {
        let qtd = 0;
        let dia = dataAgenda.getDate();
        let mes = dataAgenda.getMonth();
        let ano = dataAgenda.getFullYear();
        let diaSemana = dataAgenda.getDay();
        let sDiaSemana = days[diaSemana];

        //const turnoVistoria = vistoria.agenda.turno.toLowerCase();

        for (let i in lstVistoria) {
            const v = lstVistoria[i];
            if (!v.turno) continue;
            const dt = new Date(v.data);
            const turnoVistoria = v.turno.toLowerCase();
            if (dt.getDate() === dia && dt.getMonth() === mes
                && dt.getFullYear() === ano
                && turnoVistoria === turno.toLowerCase()) { // && v.turno === turno
                qtd++;
            }
        }
        const cap = capacidade.find(c => c.dia === sDiaSemana && c.turno === turno);

        if (!cap) return false;

        if (qtd >= parseInt(cap.quantidade)) {
            return false;
        }
        return true;
    }
    const handleConfirma = () => {
        setMensagem();

        if (validarAgendamento() || alteraVistoriador) {
            //setOpen(false);
            const dado = {
                id: vistoria.id,
                data: dataAgenda,
                observacao: document.getElementById("observacao_agenda").value,
                turno: turno,
                vistoriadores: rows
            }
            setOpen(false);
            submitVistoria(dado);

        } else {
            setMensagem("Ultrapassou o limite de vistoria do dia / turno");
        }
    }


    function buscarCapacidade() {
        setWait(true);
        fetch("/api/processoProdf/capacidade")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result.error ? result.error : "Problema inesperado!");
                } else {
                    setCapacidade(result);
                }

            });
    }
    function submitVistoria(dado) {
        if (alteraVistoriador) {
            Service.POST('/api/processoProdf/vistoria/alterarVistoriador', dado).then(r =>{
                setWait(false);
                setOpen(false);
                actionFeito();
            }).catch(error => {
                setWait(false);
                setMensagem(error?error:'Oops! Something went wrong. Please try again!');
            });
/*
            fetch('/api/processoProdf/vistoria/alterarVistoriador', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify(dado),
                credentials: 'include'
            }).then(response => {
                if (response.ok) {
                    setWait(false);
                    setOpen(false);
                    actionFeito();
                } else {
                    response.json().then(error => {
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });*/
        }else if(reagendarVistoria){
            Service.POST('/api/processoProdf/vistoria/reAgendar', dado).then(r =>{
                setWait(false);
                    setOpen(false);
                    actionFeito();
            }).catch(error => {
                setWait(false);
                setMensagem(error?error:'Oops! Something went wrong. Please try again!');
            });
            /*
            fetch('/api/processoProdf/vistoria/reAgendar', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify(dado),
                credentials: 'include'
            }).then(response => {
                if (response.ok) {
                    setWait(false);
                    setOpen(false);
                    actionFeito();
                } else {
                    response.json().then(error => {
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });*/
        } else {
            Service.POST('/api/processoProdf/vistoria/agendar', dado).then(r=>{
                setWait(false);
                    setOpen(false);
                    actionFeito();
            }).catch(error => {
                setWait(false);
                setMensagem(error?error:'Oops! Something went wrong. Please try again!');
            });
            /*
            fetch('/api/processoProdf/vistoria/agendar', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                body: JSON.stringify(dado),
                credentials: 'include'
            }).then(response => {
                if (response.ok) {
                    setWait(false);
                    setOpen(false);
                    actionFeito();
                } else {
                    response.json().then(error => {
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });*/
        }
    }

    const handleTurnoChange = (event) => {
        setTurno(event.target.value);
        if (!reagendarVistoria) {
            setVistoriadorDisponivel([]);
            setRows([]);
        }
    }

    const handleIncluirVistoriador = () => {
        let arr = [...rows];
        arr.push(user);
        setRows(arr);
        setUser();
        informaVistoriadoresDisponiveis(arr);
    }
    function buscaVistoriador(data) {
        if (!data) return;
        setWait(true);
        Service.GET('/api/vistoriador').then(json =>{
            setWait(false);
            setVistoriadores(json);
        }).catch(error => {
            setWait(false);
            setMensagem(error?error:'Oops! Something went wrong. Please try again!');
        });
        /*
        fetch('/api/vistoriador', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                setWait(false);
                response.json().then((json) => {
                    setVistoriadores(json);
                })
            } else {
                response.json().then((error) => {
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });*/
    }
    const handleRemoveVistoriador = (e, index) => {
        let itens = [...rows];
        // if( itens[index].arquivo) rows[index].arquivo = null;
        // if( itens[index].doc) rows[index].doc = null;
        itens.splice(index, 1);



        setRows(itens);

        informaVistoriadoresDisponiveis(itens);
    }
    function naoIncluido(vistoriador, jaIncluidos) {
        for (let x in jaIncluidos) {
            if (jaIncluidos[x].user.idUser === vistoriador.user.idUser) {
                return true;
            }
        }
        return false;
    }
    function desabilitaConfirmar() {
        if (!turno) return true;
        if (!rows || rows.length === 0) return true;

        return false;
    }
    return (
        <Dialog maxWidth={'lg'}
            open={open}
            onClose={handleClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            classes={{
                paper: classesLocal.dialog,
            }}
        >
            {mensagem &&
                <Alert severity="info">{mensagem}</Alert>
            }

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>

                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                    {alteraVistoriador ? "Alterar vistoriador" : reagendarVistoria ? "Reagendar Vistoria" : "Agendar Vistoria"}
                </DialogTitle>
                <DialogContent >
                    <Container maxWidth='lg' >
                        <Grid container spacing={3} >
                            <Grid item xs={8}>
                                <TextField
                                    value={vistoria ? (vistoria.assunto) || '' : ''}
                                    fullWidth
                                    label="Assunto"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextField
                                    value={vistoria ? (vistoria.processo && vistoria.processo.nProcesso) || '' : ''}
                                    fullWidth
                                    label="Processo"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={vistoria ? (vistoria.processo && vistoria.processo.razaoSocial) || '' : ''}
                                    fullWidth
                                    label="Razão Social"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    aria-describedby="nova-helper-text"
                                    variant="outlined"
                                />
                            </Grid>
                            {vistoria && vistoria.lote &&
                                <GenericTable
                                    dados={vistoria.lote}
                                    disableSearch={true}
                                    readOnly={true}
                                    colunas={[
                                        { nome: 'RA', campo: 'ra' },
                                        { nome: 'ADE', campo: 'ade' },
                                        { nome: 'Endereço', campo: 'endereco' }
                                    ]} /> }

                            <Grid item xs={4}>
                                <TextField
                                    variant="outlined"
                                    id="data"
                                    label="Data Agendamento"
                                    defaultValue={dataAgenda ? dataAgenda.getDate() + "/" + (dataAgenda.getMonth() + 1) + "/" + dataAgenda.getFullYear() : "" || null}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl component="fieldset" >
                                    <FormLabel component="legend">Turno</FormLabel>
                                    {!alteraVistoriador &&
                                        <RadioGroup row aria-label="position"
                                            name="turno"
                                            id="turno"
                                            onChange={handleTurnoChange}
                                            defaultValue='aaa' variant="outlined">
                                            <FormControlLabel
                                                value="MANHA"
                                                control={<Radio color="primary" />}
                                                label="MANHÃ"
                                            // labelPlacement="top"
                                            />
                                            <FormControlLabel
                                                value="TARDE"
                                                control={<Radio color="primary" />}
                                                label="TARDE"
                                            // labelPlacement="start"
                                            />
                                        </RadioGroup>
                                    }
                                    {alteraVistoriador &&
                                        <RadioGroup row aria-label="position"
                                            name="turno"
                                            id="turno"
                                            onChange={handleTurnoChange}
                                            defaultValue={turno} variant="outlined">
                                            <FormControlLabel
                                                value="MANHA"
                                                control={<Radio color="primary" />}
                                                label="MANHÃ"
                                                disabled
                                            // labelPlacement="top"
                                            />
                                            <FormControlLabel
                                                value="TARDE"
                                                control={<Radio color="primary" />}
                                                label="TARDE"
                                                disabled
                                            // labelPlacement="start"
                                            />
                                        </RadioGroup>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                {!(alteraVistoriador || reagendarVistoria) &&
                                    <TextField fullWidth
                                        id="observacao_agenda"
                                        label="Observação"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                    />
                                }
                                {(alteraVistoriador || reagendarVistoria) &&
                                    <TextField
                                        value={vistoria ? (vistoria.agenda && vistoria.agenda.observacao) || '' : ''}
                                        fullWidth
                                        id="observacao_agenda"
                                        label="Observação"
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <Paper>
                            <Grid container spacing={3} >
                                <Grid item xs={12}>
                                    <Typography component="h1" variant="h5" gutterBottom>{reagendarVistoria ? "Vistoriadores" : "Selecionar vistoriador"}</Typography>
                                </Grid>
                                
                                    <>
                                        <Grid item xs={8}>
                                            <FormControl variant="outlined" fullWidth>
                                                <InputLabel id="demo-simple-select-outlined-label">Vistoriador</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={user || ''}
                                                    onChange={handleChange}
                                                    label="Vistoriador"
                                                >
                                                    {vistoriadorDisponivel.map(v =>
                                                        <MenuItem key={v.id} value={v}>
                                                            <em>{v.user.nome}</em>
                                                        </MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Button autoFocus onClick={handleIncluirVistoriador} variant="contained" color="secondary" disabled={!user}>
                                                Incluir
                                        </Button>
                                        </Grid>
                                    </>
                                
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="grid vistoriadores">
                                            <colgroup>
                                                <col style={{ width: '10%' }} />
                                                <col style={{ width: '80%' }} />
                                                <col style={{ width: '10%' }} />
                                            </colgroup>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>id</TableCell>
                                                    <TableCell>Nome</TableCell>
                                                    <TableCell>Ação</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{index + 1}</TableCell>
                                                        <TableCell>{row.user.nome}</TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Remover documento">
                                                                <IconButton onClick={(e) => handleRemoveVistoriador(e, index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>

                        </Paper>
                    </Container>
                </DialogContent>
                <DialogActions>
                    {alteraVistoriador &&
                        <>
                            <Button autoFocus onClick={handleClose} variant="contained" color="secondary">
                                Sair
                        </Button>
                            <Button onClick={handleConfirma} disabled={desabilitaConfirmar()} variant="contained" color="primary">
                                {'Salvar'}
                            </Button>
                        </>
                    }
                    {!alteraVistoriador &&
                        <>
                            <Button autoFocus onClick={handleClose} variant="contained" color="secondary">
                                Cancela
                        </Button>
                            <Button onClick={handleConfirma} disabled={desabilitaConfirmar()} variant="contained" color="primary">
                                {'confirma'}
                            </Button>
                        </>
                    }
                </DialogActions>
            </MuiPickersUtilsProvider>
        </Dialog>
    );
}
