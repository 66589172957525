import React, { useState } from 'react';
import {
    Paper, Typography,
    Accordion, AccordionSummary, AccordionDetails, Grid, TextField, Tooltip, IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../../../constants';
import CreateIcon from '@material-ui/icons/Create';
import BuscaDadoDialog from '../../../util/BuscaDadoDialog';
import ConfirmDialog from '../../../util/ConfirmDialog';

const dados = [
    { id: 8999, sigla: "SDE/GAB/SUPEC/DAABE/GATE", descricao: "Gerência de Atendimento ao Empresário" },
    { id: 8998, sigla: "GAB/SUPEC/DAABE/GATE", descricao: "Gerência de Atendimento" },
    { id: 8997, sigla: "SDE/SUPEC/DAABE/GATE", descricao: "Gerência de Empresário" }
];

export default function LaudoVistoriaForm(props) {
    const classes = useStyles();
    const { setWait, setMensagem, parametro, setParametro, setSalvarDisable } = props;
    const [showBuscaUnidadeDialog, setShowBuscaUnidadeDialog] = useState(false);
    const [showConfirmAlteracao, setShowConfirmAlteracao] = useState(false);

    function buscarUnidadeSei() {
        console.log("buscarUnidadeSei");
        setShowBuscaUnidadeDialog(true)
    }

    function handleEscolheUnidade() {
        console.log("handleEscolheUnidade");
        setShowConfirmAlteracao(true);
    }

    function confirmaAlteracao() {
        console.log("confirmaAlteracao")
    }

    const handleChange = (e) => {
        if (parametro && parametro.laudoVistoria) {
            setParametro({
                ...parametro,
                laudoVistoria: {
                    ...parametro.laudoVistoria,
                    [e.target.name]: e.target.value
                }
            });
        }else{
            setParametro({
                ...parametro,
                laudoVistoria: {
                    [e.target.name]: e.target.value
                }
            });
        }

        setSalvarDisable(true)
    }

    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Laudo de Vistoria</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={4}>
                            <TextField
                                value={parametro && parametro.laudoVistoria && parametro.laudoVistoria.id || ''}
                                onChange={handleChange}
                                // disabled
                                // InputProps={{
                                //     readOnly: true,
                                // }}
                                fullWidth
                                label="ID"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="id"
                                name="id"
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                value={parametro && parametro.laudoVistoria && parametro.laudoVistoria.nome || ''}
                                onChange={handleChange}
                                // disabled
                                // InputProps={{
                                //     readOnly: true,
                                // }}
                                fullWidth
                                label="Tipo de Documento"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="nome"
                                name="nome"
                            />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <BuscaDadoDialog
                open={showBuscaUnidadeDialog}
                setOpen={setShowBuscaUnidadeDialog}
                acao={handleEscolheUnidade}
                colunas={[
                    { nome: 'ID', campo: 'id' },
                    { nome: 'Sigla da Unidade', campo: 'sigla' },
                    { nome: 'Descrição da Unidade', campo: 'descricao' }
                ]}
                dados={dados} />
            <ConfirmDialog
                open={showConfirmAlteracao}
                setOpen={setShowConfirmAlteracao}
                titulo={'Confirmação'}
                msg='Deseja alterar a unidade?'
                exec={confirmaAlteracao}
            />
        </Paper>
    )

}