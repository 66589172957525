import React, { useState } from 'react';
import { TablePagination, Paper, Table, Grid, TableContainer, TableBody, TableHead, TableRow, withStyles, createStyles, TableCell, Box } from '@material-ui/core';
import { useStyles, FormataDataComHora } from '../../../constants';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import CelulaLinhaTabelaComponent from '../../common/tabela/CelulaLinhaTabelaComponent';
import AcaoTabelaComponent from './AcaoTabelaComponent';
import CampoBusca from '../../../util/CampoBusca';

export default function TabelaComponent(props) {

    const classes = useStyles();
    const { dados, onSearch } = props;
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [ordem, setOrdem] = useState({
        campo: 'id',
        ordem: 'asc'
    });
    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
    };
    const obterHead = (e) => {
        const resultado = [];
        for (let i = 0; i < e.length; i++) {
            const filho = e[i];
            if (filho.type === CelulaCabecalhoTabelaComponent) {
                var novasPropriedades = {
                    bloco: bloco, setBloco: setBloco,
                    pagina: pagina, setPagina: setPagina,
                    ordem: ordem, setOrdem: setOrdem,
                    key: i
                };
                resultado.push(React.cloneElement(filho, novasPropriedades));
            }
        }
        return resultado;
    }
    const obterAcoes = (e, o) => {
        const resultado = [];
        for (let i = 0; i < e.length; i++) {
            const filho = e[i];
            if (filho.type === AcaoTabelaComponent) {
                resultado.push(React.cloneElement(filho, { objeto: o, key: i }));
            }
        }
        return resultado;
    }
    const contemAcoes = (e) => {
        for (let i = 0; i < e.length; i++) {
            if (e[i].type === AcaoTabelaComponent) {
                return true;
            }
        }
        return false;
    }
    const obterAtributos = (e) => {
        const resultado = [];
        for (let i = 0; i < e.length; i++) {
            const filho = e[i];
            if (filho.type === CelulaCabecalhoTabelaComponent && !!filho.props.identificador) {
                resultado.push(filho.props.identificador);
            }else if( filho.type === CelulaCabecalhoTabelaComponent && !!!!filho.props.fixo) {
                resultado.push({fixo: filho.props.fixo});
            }
        }
        return resultado;
    }
    const obterDados = (dados) => {
        if( ! dados) return [];
        var resultado = dados;
        if (!resultado) resultado = [];
        if (dados && dados.length > 0 && ordem.campo === '') {
            for (var key in dados[0]) {
                setOrdem({
                    ordem: ordem.ordem,
                    campo: key
                })
                break;
            }
        }
        const asc = (ordem.ordem === 'asc') ? 1 : -1;
        resultado = resultado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        if (resultado.length === dados.length) {
            resultado = dados.slice(pagina * bloco, (pagina * bloco) + pagina);
        }
        return resultado;
    }
    const StyledTableRow = withStyles((theme) =>
        createStyles({
            root: {
                '&:nth-of-type(odd)': {
                    backgroundColor: theme.palette.action.hover,
                },
            },
        }),
    )(TableRow);

    function getValor(row, atributo){
        if(typeof atributo==='string') {
            if( atributo.includes("data")){
                return row[atributo]?FormataDataComHora( new Date(row[atributo])):'';
            }
            return row[atributo];
        }else{
            return atributo.fixo;
        }
    }
    // if( !dados){
    //     return <div/>
    // }else 
    return (
        <Grid container spacing={3} className={classes.root}>
            {!!onSearch && 
            <Grid item xs={12} >
                <CampoBusca handleSearch={onSearch} />
            </Grid>}
        <Grid item xs={12}>
            
            <TableContainer component={Paper}>
                <Table aria-label="customized table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            {obterHead(props.children)}
                            {contemAcoes(props.children) &&
                                <CelulaCabecalhoTabelaComponent
                                    bloco={bloco} setBloco={setBloco}
                                    pagina={pagina} setPagina={setPagina}
                                    ordem={ordem} setOrdem={setOrdem}
                                    titulo="Ação"
                                />
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {obterDados(props.dados).map((row, i) => (
                            <StyledTableRow key={i}>
                                {obterAtributos(props.children).map((atributo, j) => (
                                    <CelulaLinhaTabelaComponent key={i + '-' + j} styled={true}>{ getValor(row, atributo)}</CelulaLinhaTabelaComponent>
                                ))}
                                {contemAcoes(props.children) && <TableCell>
                                    <Box >
                                        {obterAcoes(props.children, row)}
                                    </Box>
                                </TableCell>}
                            </StyledTableRow>
                        ))}
                        {obterDados(props.dados).length === 0 && <StyledTableRow>
                            <TableCell colSpan={9}>
                                <center><b>Nenhum registro encontrado!</b></center>
                            </TableCell>
                        </StyledTableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={dados?dados.length:0}
                rowsPerPage={pagina}
                page={bloco}
                labelRowsPerPage="Registros por página"
                labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </Grid>
        </Grid>
    );
}
