
import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, Container, Box,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography,
    Accordion, AccordionSummary, AccordionDetails, Divider
} from '@material-ui/core';
import MaskedInput from 'react-text-mask'
import ConfirmDialog from "../../../util/ConfirmDialog";
import { useStyles, getQueryParams, API_BASE_URL } from "../../../constants";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Download from "../../../util/Download";
import FluxoPanel from "../../../util/FluxoPanelBPM";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

import HistoricoTable from "./HistoricoTable";
import { makeStyles, } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


function TelMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TelMask2(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
export const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '100px',
        overflow: 'auto'
    }
}), { index: 1 });

let acao = '';

export default function AvaliarRequerimentoForm(props) {
    const classes = useStyles();
    const classesLocal = useLocalStyle();
    const [processo, setProcesso] = useState();
    const { setTitulo, setErro, setWait, disableBtn, disableHisotrico, setMensagem } = props;
    const [fluxo, setFluxo] = useState();
    let { id } = useParams();
    const [showConfirm, setShowConfirm] = useState(false);
    const [showTel1, setShowTel1] = useState(false);
    const [msgConfirmacao, setMsgConfirmacao] = useState('');

    const [value, setValue] = useState({
        assunto: '',
        processo: '',
        cpf: '',
        nome: '',
        telefone: '',
        email: '',
        protocolo: '',
        tipo: '',
        declaracao: false,
    });
    const [anexos, setAnexos] = useState([]);
    const history = useHistory();
    let [profile] = useCurrentUser();
    const param = getQueryParams(useLocation().search);
    const [relatorioTecnico, setRelatorioTecnico] = useState('');

    const [error, setError] = useState(resetErro());
    const [tipoDocumento, setTipoDocumento] = useState('');

    useEffect(() => {

        if (profile) {
            id = param.get('id');
            if (id) return;
            setValue({
                ...value,
                nome: profile.name,
                cpf: profile.CPF,
                email: profile.email
            });
        }
    }, [profile]);


    useEffect(() => {

        if (param.get('editable') && param.get('editable') === 'false') {
            setTitulo && setTitulo("Visualizar Requerimento");
        } else {
            setTitulo && setTitulo("Avaliar Requerimento");
        }
        const process = localStorage.getItem("workingProcess");
        setProcesso(JSON.parse(process));

        const request = localStorage.getItem("worginRequest");
        if (!request) {
            buscaDados();
        } else {
            const json = JSON.parse(request);
            montaValor(json);
            if (json.documentos) setAnexos(json.documentos);
        }
    }, [])
    function montaUrl() {
        const assunto = (param.get("assunto")) ? param.get("assunto") : '';

        if (assunto === 'Recurso') {
            return '/api/processoProdf/recurso/'
        } else if (assunto === 'Prorrogacao') {
            return '/api/processoProdf/prorrogacao/'
        } else if (assunto === 'Cancelamento') {
            return '/api/processoProdf/cancelamento/'
        } else if (assunto === 'Notificacao') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else if (assunto === 'Complemento') {
            return '/api/processoProdf/notificacaoComplemento/'
        } else {
            return "/api/processoProdf/requerimento/";
        }
    }
    function buscaDados() {
        id = param.get('id');

        const process = JSON.parse(localStorage.getItem("workingProcess"));
        setProcesso(process);
        const url = montaUrl();

        if (id) {
            setWait(true);
            fetch(url + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok && (response.status < 300)) {
                    response.json().then((json) => {
                        setWait(false);
                        montaValor(json);
                        if (json.documentos) setAnexos(json.documentos);
                        localStorage.setItem("worginRequest", JSON.stringify(json));
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        } else {
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarrequerimentoForm");
            }
        }
    }
    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };

    function montaValor(json) {
        let valor = null;
        if (!json.assunto) json.assunto = param.get("assunto");
        if (json.requerente) {
            valor = {
                ...json,
                nome: json.requerente.nome,
                cpf: json.requerente.cpf ? json.requerente.cpf : '',
                email: json.requerente.email,
                telefone: json.requerente.telefone ? json.requerente.telefone : '',
            };
        } else if (json.solicitante) {
            if (typeof json.processo === 'string') { // para recurso, cancelamento e prorrogacao
                //TODO NECESSARIO REFATORAR
                if (processo) {
                    json.processo = {
                        id: processo.id,
                        nProcesso: processo.dadosEmpresa.nProcesso,
                        cnpj: processo.dadosEmpresa.cnpj,
                        razaoSocial: processo.dadosEmpresa.razaoSocial
                    }
                }

            }
            valor = {
                ...json,
                nome: json.solicitante.nome,
                cpf: json.solicitante.cpf ? json.solicitante.cpf : '',
                email: json.solicitante.email,
                telefone: json.solicitante.telefone ? json.solicitante.telefone : ''
            }
        }
        // if( valor.responsaveis && valor.responsaveis.length > 0 ){
        //     valor.respCadastro = valor.responsaveis[0];
        // }
        const h = valor.historico.find(item => item.texto === 'Cadastro via UNATE');
        if (h) {
            valor.respCadastro = h.responsavel;
        }
        if (valor.tipo) {
            valor.assunto += " - " + valor.tipo;
        }
        setValue(valor);

    }

    function confirmaRequerimento() {
        setError(resetErro());
        let dado = { ...value };

        dado.documentos = anexos;
        if (acao === 'rascunho') {
            dado.status = "EM_ANALISE";
        } else if (acao === 'deferir') {
            dado.status = 'DEFERIDO';
        } else if (acao === 'indeferir') {
            dado.status = 'INDEFERIDO';
        }

        dado.historico = [{
            tipo: dado.status,
            data: new Date(),
            texto: "Cadastro via SEDET-ACESSOR",
            responsavel: {
                idUser: profile.id,
                nome: profile.name,
                email: profile.email
            }
        }];

        if (valida(dado)) {
            submitForm(dado);
        }
    }

    function submitForm(dado) {
        setWait(true);
        fetch('/api/processoProdf/requerimento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                history.goBack();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: { erro: false, msg: '' },
            telefone: { erro: false, msg: '' },
            email: { erro: false, msg: '' }
        }
    }
    function valida(dado) {
        if (!dado.documentos || dado.documentos.length === 0) {
            setErro("Obrigatório informar os documentos para analise");
            return false;
        }
        return true
    }

    function fileClick() {
        let file = document.getElementById("file");
        file.click();
    }

    function visivelCheckList() {
        if (param.get('editable') && param.get('editable') === 'false') {
            return false;
        }
        return true;
    }

    function disable() {
        if (!param.get('editable') || param.get('editable') === 'true') {
            return false;
        }
        // return (value && value.status && value.status === 'EM_ANALISE');
        return true;
    }

    function podeEnviar() {
        return false;
    }
    function viewCheckList() {
        if (value.assunto.includes('Notificacao') || value.assunto.includes('Complemento')) {
            let fluxo = value.fluxoPath[value.fluxoPath.length - 1];
            return fluxo === 'emValidacao';
        } else if (value && value.fluxoPath) {
            let fluxo = value.fluxoPath[value.fluxoPath.length - 1];
            return fluxo === 'valUnate';
        }
        return false;
    }
    const handleTelLostFocus = () => {
        setShowTel1(value.telefone.trim().length !== 16);
    }
    const handleTelFocus = () => {
        setShowTel1(false);
    }

    function montaUrlCheckList() {
        return "/gerenciaProcesso/unate/avaliaRequerimentoCheckList?id=" + param.get("id");
    }
    function relatorio() {
        var a = document.createElement("a");
        a.href = API_BASE_URL + "/api/processoProdf/prorrogacao/relatorio/" + id;
        a.setAttribute("target", "_blank");
        a.click();
    }
    function handleAcao(acao) {

        if (acao === 'rascunho') {

        } else if (acao === 'indeferir') {
            setMsgConfirmacao("Confirma indeferir a solicitação?");
            setShowConfirm(true);
        } else if (acao === 'deferir') {
            setMsgConfirmacao("Confirma deferir a solicitação?");
            setShowConfirm(true);
        }
    }
    function isFaseDeferirIndeferir() {
        if (value && value.status && value.status !== 'EM_ANALISE' && value.status !== '') {
            return true;
        }
        return false;
    }

    function vizualizarResposta() {
        if (value && value.historico) {
            const h = value.historico.find(h => h.tipo === 'responder');
            return h ? true : false;
        }
        return false;
    }

    function getResposta() {
        if (value && value.historico) {
            const x = value.historico.find(x => x.tipo === "responder").texto;
            return x;
        }

        return '';
    }

    const sair = () => {
        let sair = param.get("return")
        if (sair && sair.length > 0) {
            history.replace(sair);
        } else {
            history.replace("/gerenciaProcesso/unate/avaliaRequerimento");
        }
    }

    const handleFluxoChange = () => {
        if (!fluxo) {
            let url = '/api/info/fluxoComplexo';
            if (value.assunto === 'Diversos') {
                url = '/api/info/fluxoDiversos';
            } else if (value.assunto.includes('Notificacao')) {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
                // url = '/api/info/fluxoNotificacaoComplementoSimples';
                // if (value.tipo === 'Pendência de vistoria' || value.tipo === 'Prazo vencido') {
                //     url = '/api/info/fluxoNotificacaoComplementoComplexo';
                // }
            } else if (value.assunto && value.assunto.includes('Complemento')) {
                url = '/api/info/fluxoNotificacaoComplementoComplexo';
            }


            fetch(url)
                .then(response => response.json())
                .then(json => setFluxo(json)).catch(erro => setErro(erro));
        }
    }
    return (
        <Container maxWidth='md' className={classes.root}>
            <Paper >
                <Grid container spacing={1} >
                    {
                        (value && value.respCadastro && value.respCadastro.nome) &&
                        <Grid item xs={12}>
                            <TextField
                                value={value && value.respCadastro && value.respCadastro.nome || ''}
                                disabled
                                fullWidth
                                label="Responável pelo preenchimento"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                            />
                        </Grid>
                    }


                    <Grid item xs={8}>
                        <TextField
                            value={value && value.assunto || ''}
                            disabled
                            fullWidth
                            label="Assunto"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={value.processo && value.processo.programa || ''}
                            disabled
                            fullWidth
                            label="Programa"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            value={value && value.protocolo || ''}
                            disabled
                            fullWidth
                            label="Protocolo"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={value.processo && value.processo.nProcesso || ''}
                            disabled
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            value={value.processo && value.processo.cnpj || ''} disabled
                            fullWidth
                            label="CNPJ"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField disabled
                            value={value.processo && value.processo.razaoSocial || ''}
                            fullWidth
                            label="Interessado"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            disabled
                            label="CPF"
                            value={value.cpf}
                            // onChange={handleChangeInputForm}
                            // onBlur={handleLostFocusCPF}
                            error={error.cpf.erro}
                            helperText={error.cpf.msg}
                            name="cpf"
                            id="cpf-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: CPFMask,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled
                            value={value.nome}
                            fullWidth
                            label="Requerente"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            disabled
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone}
                            onChange={handleChangeInputForm}
                            onBlur={handleTelLostFocus}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'none' : 'block' }}
                            name="telefone"
                            id="tel-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask,
                            }}
                            variant="outlined"
                        />
                        <TextField
                            disabled
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone}
                            onChange={handleChangeInputForm}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'block' : 'none' }}
                            name="telefone"
                            id="tel-mask-input1"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask2,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            disabled
                            error={error.email.erro}
                            helperText={error.email.msg}
                            fullWidth
                            label="Email"
                            value={value.email}
                            onChange={handleChangeInputForm}
                            name="email"
                            id="email-input"
                            variant="outlined"
                        />
                    </Grid>
                    {
                        value.descricao &&
                        <Grid item xs={12}>
                            <Paper className={classesLocal.paper}>
                                <span dangerouslySetInnerHTML={{
                                    __html: value.descricao ? value.descricao : ''
                                }}></span>
                            </Paper>
                        </Grid>
                    }

                    {
                        vizualizarResposta() &&
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>Resposta</Typography>
                            <Paper className={classesLocal.paper}>
                                <span dangerouslySetInnerHTML={{
                                    __html: getResposta()
                                }}></span>
                            </Paper>
                        </Grid>
                    }

                    <Grid item xs={12}>


                        <TableContainer component={Paper} >
                            <Table aria-label="customized table" size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sortDirection={false} className={classes.tableHead}>
                                            Tipo Documento
                                        </TableCell>
                                        <TableCell sortDirection={false} className={classes.tableHead}>
                                            Documento
                                        </TableCell>
                                        <TableCell width='100px' align="center" className={classes.tableHead}>
                                            Ação
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {anexos && anexos.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{row.tipo}</TableCell>
                                            <TableCell>{row.nome}</TableCell>
                                            <TableCell>
                                                <center>
                                                    {
                                                        <Box>
                                                            {row.nome && <Download doc={row} />}
                                                        </Box>
                                                    }
                                                </center>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {
                                        (!anexos || anexos.length === 0) &&
                                        <TableRow >
                                            <TableCell>Nenhum registro</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {
                            isFaseDeferirIndeferir() &&
                            <div>
                                <Box component="span" m={1}></Box>
                                <Divider />
                                <Typography variant="subtitle1" gutterBottom>Relatório Técnico</Typography>
                                <Box component="span" m={1}></Box>
                                <CKEditor
                                    editor={ClassicEditor} disabled={disable()}
                                    config={{
                                        toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                            '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                                    }}
                                    data={relatorioTecnico}
                                    onReady={editor => {
                                        editor.editing.view.change(writer => {
                                            writer.setStyle(
                                                "height",
                                                "200px",
                                                editor.editing.view.document.getRoot()
                                            );
                                        });
                                    }}
                                    onChange={(event, editor) => {
                                        setRelatorioTecnico(editor.getData());

                                    }}
                                    onBlur={(event, editor) => {
                                    }}
                                    onFocus={(event, editor) => {
                                    }}
                                />
                            </div>
                        }
                        {/* esta com falha  de JS    */}                   
                        {disableHisotrico || disableHisotrico !== true &&
                            <>

                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography className={classes.heading}>Histórico</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {value && value.historico &&
                                            <HistoricoTable dados={value.historico} setWait={setWait} setMensagem={setMensagem} />
                                        }

                                    </AccordionDetails>
                                </Accordion>

 
                                <Accordion onChange={handleFluxoChange}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <Typography className={classes.heading}>Fluxo</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails >
                                        {fluxo &&
                                            <FluxoPanel fluxo={fluxo} path={value.fluxoPath} />
                                        }
                                    </AccordionDetails>
                                </Accordion> 
                            </>
                        }


                    </Grid>

                    {isFaseDeferirIndeferir() &&
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" color="secondary" href={"/gerenciaProcesso/unate/avaliaRequerimento"} rel="noopener noreferrer">
                                Sair
                            </Button>
                            {!disable() &&
                                <Button variant="contained" color="primary" onClick={() => relatorio()} disabled={podeEnviar()}>
                                    Visualizar
                                </Button>}
                            {!disable() &&
                                <Button variant="contained" color="primary" onClick={() => handleAcao('rascunho')} disabled={podeEnviar() || relatorioTecnico.length === 0}>
                                    Salvar Racunho
                                </Button>}
                            {!disable() &&
                                <Button variant="contained" color="primary" onClick={() => handleAcao('indeferir')} disabled={podeEnviar() || relatorioTecnico.length === 0}>
                                    Indeferir
                                </Button>}
                            {!disable() &&
                                <Button variant="contained" color="primary" onClick={() => handleAcao('deferir')} disabled={podeEnviar() || relatorioTecnico.length === 0}>
                                    Deferir
                                </Button>}
                        </Grid>
                    }
                    {!disableBtn && !isFaseDeferirIndeferir() &&
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" color="secondary" href={"/gerenciaProcesso/unate/avaliaRequerimento"} rel="noopener noreferrer">
                                Sair
                            </Button>
                            {
                                visivelCheckList() &&
                                <Button variant="contained" color="primary" href={montaUrlCheckList()} disabled={!viewCheckList()}>
                                    Check-list
                                </Button>
                            }
                        </Grid>
                    }
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg={msgConfirmacao}
                        exec={confirmaRequerimento}
                    />
                </Grid>

            </Paper>

        </Container>
    );
}
