import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Button, Paper, Menu, MenuItem,
} from '@material-ui/core';
import { useStyles, FormataData } from "../../../constants";
import GenericTable from "../../template/GenericTable";
import { useHistory } from "react-router-dom";
import ConfirmDialog from '../../../util/ConfirmDialog';


let idVistoria;

export default function TextoPadraoTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait, setMensagem } = props;
    const [dados, setDados] = useState([]);
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const history = useHistory();
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const [fonteDeDados, setFonteDeDados] = useState([]);

    useEffect(() => {
        setTitulo("Cadastro de textos padrão");
        buscarSuporte();

       
    }, []);

    function isReadOnly() {
        return false;
    }

    function buscarSuporte() {
        fetch("/api/textoPadrao")        
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    result = result.sort((a, b) => ((a.dataSolicitacao < b.dataSolicitacao) ? 1 : -1));
                    filtrarDados(result);
                    

                }
            });
    }

    function filtrarDados(d) {
        let arr = [];
        for (let x in d) {
            const suporte = d[x];            
            arr.push({
                id:suporte.id,
                assunto: suporte.assunto,
                nome: suporte.solicitante.nome,                
            })
        }
        setFonteDeDados(arr);
    }

    
    const handleActionSolicitarClick = (index, acao, dado) => {
        idVistoria = dado.id;
        if (acao === "Visualizar") {
            history.push("/gerenciaProcesso/textoPadraoView?id=" + dado.id);
        } else if (acao === "Alterar") {
            history.push("/gerenciaProcesso/textoPadraoForm?id=" + dado.id);
        }else if(acao === "Excluir"){
            setShowConfirmExclusao(true);
        }
    }

    function confirmaExclusao() {
        const dado = {
            id: idVistoria,
        }
        setWait(true);
        fetch("/api/textoPadrao/" + dado.id, {
            method: 'DELETE'
        }).then((result) => {
            setWait(false);

            if (!result.ok) {
                setErro(result && result.message ? result.message : result.statusText);
            } else {

                buscarSuporte();
                setMensagem("Registro excluido!")
            }
        }).catch(error => {
            setWait(false);
            setErro((setErro && setErro.statusText) || 'Oops! Something went wrong. Please try again!');
        });
    }
    

    const desabilitaAcaoAtenderTable = (acao, dado) => {
        
        if (acao === "Atender" && dado) {
            return (dado.situacao === "Cancelado" || dado.situacao === "Concluído");
            
        }
        
        return false;
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                options={['Visualizar', 'Alterar', 'Excluir']}
                handleDisableAction={desabilitaAcaoAtenderTable}
                dados={fonteDeDados}
                readOnly={isReadOnly()}
                colunas={[
                    { nome: 'Assunto', campo: 'assunto' },
                    { nome: 'Responsavel', campo: 'nome' },
                    { nome: 'Ação', campo: 'action' }
                ]} />

            <Grid container justify="flex-end" className={classes.btns}>
            <Button variant="contained" color="primary" href={"/gerenciaProcesso/textoPadraoForm"} rel="noopener noreferrer">
                    Cadastrar
                </Button>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
            </Grid>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />
        </Grid >


    );
}
