import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Container, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import VistoriaForm from './VistoriaForm';
import DadosAgendaForm from './DadosAgendaForm';
import DadosLaudoVistoraForm from './DadosLaudoVistoraForm';
import HistoricoVistoriaForm from './HistoricoVistoriaForm';
import FluxoPanelBPM from "../../util/FluxoPanelBPM";
import { useParams } from "react-router-dom";
import { useStyles, getQueryParams } from "../../constants";
import { Map, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import Service from "../../services/Service";

let DefaultIcon = L.icon({
  iconUrl: icon,
  iconAnchor: [19, 46],
  popupAnchor: [-6, -47]

});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

let laudoDescricao = null;

export default function VistoriaTab(props) {
  const classes = useStyles();
  let { id } = useParams();
  const { setTitulo, setMensagem, setWait, setErro, agendar,setSuccess,
    executar, validarVistoria, disable, disableLaudo, laudo, validarVistoriaDiretor } = props;
  const location = useLocation();
  const [value, setValue] = React.useState('cad');
  const [fluxo, setFluxo] = useState();
  const history = useHistory();
  const param = getQueryParams(useLocation().search);
  const [vistoria, setVistoria] = useState();
  const [txt, setTxt] = useState('');
  const [hasChange, setHasChange] = useState(false);
  
  const [fluxoPath, setFluxoPath] = useState();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [tituloDialog, setTituloDialog] = useState("motivo devolver");
  const [showMapa, setShowMapa] = useState(false);
  const [disabledConcluir, setDisabledConcluir] = useState(false);
  const laudoRef = useRef();

  
  const [coordenada, setCoordenada] = useState({});
  const [mapCentralizar, setMapCentralizar] = useState({
    lat: -15.7801,
    lng: -47.9292
  });
  const inputEl = useRef(null);

  useEffect(() => {
    buscaFluxo();
    bucaVistoria();
    laudoDescricao = null;
  }, []);
  
  useEffect(() => {
    if (vistoria) {
      let arr = [];
      for (let x in vistoria.historico) {
        const h = vistoria.historico[x];
        if (h.tipo === "SOLICITACAO") {
          arr.push("solicitada");
        } else if (h.tipo === "AGENDA") {
          arr.push("agendada");
        } else if (h.tipo === "EM_EXECUCAO") {
          arr.push("execucao");
        } else if (h.tipo === "EXECUTADO") {
          arr.push("executada");
        } else if (h.tipo === "SUSPENCAO") {
          arr.push("suspensa");
        } else if (h.tipo === "DEVOLVER") {
          arr.push("ajusteSolicitante");
        } else if (h.tipo === "AJUSTE_VISTORIADOR") {
          arr.push("ajusteVistoriador");
        } else if (h.tipo === "VALIDACAO_GERENTE") {
          arr.push("validadaGerente");
        } else if (h.tipo === "AGUARDANDO_AJUSTE_GERENTE") {
          arr.push("ajusteGerete");
        } else if (h.tipo === "VALIDADO_DIRETOR") {
          arr.push("validadaDiretor");
        } else if (h.tipo === "CANCELADO") {
          arr.push("cancelada");
        }
      }
      setFluxoPath(arr)
    }
  }, [vistoria]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function bucaVistoria() {
    fetch('/api/processoProdf/vistoria/' + param.get("id"))
      .then(response => response.json())
      .then(json => {
        setVistoria(json);
        if (laudo && laudo === true) {
          setValue('laudo');
        }
      })
      .catch(erro => setErro(erro));
  }
  function buscaFluxo() {
    fetch('/api/info/fluxoVistoria')
      .then(response => response.json())
      .then(json => setFluxo(json))
      .catch(erro => setErro(erro));
  }
  
  function agendarVistoria() {
    history.replace("/vistoria/agendarVistoriaId?id=" + param.get("id"));
  }
  function handleEmExecucao() {
    id = param.get('id')
    const dado = {
      id: id
    }
    Service.POST('/api/processoProdf/vistoria/emExecucao', dado).then(r =>{
      handleCloseDialog();
        history.replace("/vistoria/executar")
    }).catch(error => {
      setWait(false);
      setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
    });
    /*
    fetch('/api/processoProdf/vistoria/emExecucao', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(dado),
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        handleCloseDialog();
        history.replace("/vistoria/executar")
      } else {
        response.json().then(erro => {
          setErro(erro.message);
        })
      }
    }).catch(error => {
      setWait(false);
      setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
    */
  }
  
  
  function devolver() {
    id = param.get('id')
    const descricao = document.getElementById("descricao").value;
    const dado = {
      id: id,
      descricao: descricao,
    }

    Service.POST('/api/processoProdf/vistoria/devolver', dado).then(resp =>{
      handleCloseDialog();
      handleSalvarVistoria().then( response => {
        history.replace("/vistoria/agendar")
      });
    }).catch(error => {
      setWait(false);
      setMensagem(error? error.message: 'Oops! Something went wrong. Please try again!');
    });
    /*
    fetch('/api/processoProdf/vistoria/devolver', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(dado),
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        handleCloseDialog();
        history.replace("/vistoria/agendar")
      } else {
        response.json().then(erro => {
          setErro(erro.message);
        })
      }
    }).catch(error => {
      setWait(false);
      setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });*/
  }
  function setLaudo(txt) {
    laudoDescricao = txt;
    setHasChange(true);
  }
  const handleSalvarVistoria = () => {

    setWait(true);
    const st = laudoRef.current && laudoRef.current.getStatus();
    if( st && laudoRef.current.getStatus().hasChange ){
      return laudoRef.current.getStatus().salvar().then( json =>{
        if( json && json.laudo){
          vistoria.laudo = json.laudo;
          return salvaSemMudartatus(vistoria);
        }else{
          topFunction();
          return new Promise(function(resolve, reject) {
              resolve({});
          });
        }
      });
    }else{
      return salvaSemMudartatus(vistoria)
    }
  }
  function salvaSemMudartatus(vistoria){
    if( laudoDescricao ){
      if( !vistoria.laudo ) vistoria.laudo={};
      vistoria.laudo.laudo = laudoDescricao;
    }

    return fetch('/api/processoProdf/vistoria', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${localStorage.accessToken}`
          },
          body: JSON.stringify(vistoria),
          credentials: 'include'
      }).then(response => {
        setWait(false);
        //response;
          if (response.ok) {
              setWait(false);
              setHasChange(false);
              return response.json();
          } else {
            return response.json() // return the result of the inner promise, which is an error
              .then((json) => {
                // const { message, stackTrace } = json;
                throw json;
              });
              // console.error.log(">>>erro<<<", error )
              // setMensagem('Erro inesperado');
          }
      }).catch(error => {
        topFunction();
          console.error(">>>erro<<<", error )
          setWait(false);
          setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
      });
  }
  function topFunction() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}
  const handleConcluirLaudo = () => {
    const json = {
      id: vistoria.id,
      laudo: laudoDescricao
    };

    fetch('/api/processoProdf/vistoria/concluirLaudo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(json),
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        handleSalvarVistoria().then( res => {
            setWait(false);
            setSuccess("Termo de vistoria atualizado");
            history.replace("/vistoria/executar");
        })
      } else {        
        response.json()
          .then(error => {
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
          });
          topFunction();
      }
    }).catch(error => {
      setWait(false);
      setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
      topFunction();
    });
  }

  function devolverVistoriaDiretor() {
    id = param.get('id')
    const descricao = txt;
    const dado = {
      id: id,
      descricao: descricao,
    }
    fetch('/api/processoProdf/vistoria/devolverLaudoGerente', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(dado),
      credentials: 'include'
    }).then(ret => {
      handleSalvarVistoria().then( response => {
          history.replace("/vistoria/validarDiretor");
      })
    }).catch(error => {
      console.error(">>>erro<<<", error )
      setWait(false);
      setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }
  function devolverValidarLaudo() {
    id = param.get('id')
    const descricao = txt;
    const dado = {
      id: id,
      descricao: descricao,
    }

    Service.POST('/api/processoProdf/vistoria/devolverValidarLaudo', dado).then(resp =>{
      handleSalvarVistoria().then( response => {
        history.replace("/vistoria/validarGerente");
      });
    }).catch(error =>{
      setWait(false);
      setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
    })
    /*
    fetch('/api/processoProdf/vistoria/devolverValidarLaudo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(dado),
      credentials: 'include'
    }).then(resp => {
      if( resp.ok ){
        handleSalvarVistoria().then( json => {
            history.replace("/vistoria/validarGerente")
        })
      }else{
        resp.json().then((json) => {
          throw json;
        });
      }
    }).catch(error => {
      setWait(false);
      setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });*/
  }

  function validarLaudoDiretor() {
    id = param.get('id')
    const descricao = txt;
    const dado = {
      id: id,
      descricao: descricao,
    }
    Service.POST('/api/processoProdf/vistoria/validarLaudoDiretor', dado).then(r=>{
      history.replace("/vistoria/validarDiretor")
    }).catch(error => {
      setWait(false);
      setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
    });
/*
    fetch('/api/processoProdf/vistoria/validarLaudoDiretor', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(dado),
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        history.replace("/vistoria/validarDiretor")
      } else {
        response.json().then(erro => {
          setErro(erro.message);
        })
      }
    }).catch(error => {
      setWait(false);
      setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
    */
  }
  function validarLaudo() {

    id = param.get('id')
    const descricao = txt;
    const dado = {
      id: id,
      descricao: descricao,
    }
    Service.POST('/api/processoProdf/vistoria/validarLaudo', dado).then(r =>{
      setWait(false);
      handleSalvarVistoria().then( res => {
        setWait(false);
        setSuccess("Termo de vistoria atualizado");
        history.replace("/vistoria/validarGerente")
      });
      
    }).catch(error => {
      setWait(false);
      setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
    });
/*
    fetch('/api/processoProdf/vistoria/validarLaudo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(dado),
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        handleSalvarVistoria().then( res => {
          setWait(false);
          setMensagem("Termo de vistoria atualizaddo");
          history.replace("/vistoria/validarGerente")
        });
      } else {
        response.json().then(erro => {
          setErro(erro.message);
        })
      }
    }).catch(error => {
      setWait(false);
      setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
    */
  }

  const handleActionSolicitarClick = (index, acao, dado) => {
    setOpenDialog(true);
    setTituloDialog("Motivo Devolução");
  }

  function fechaMapa(ponto) {

    setShowMapa(false);
  }

  function agendado() {
    if (vistoria && vistoria.situacao === 'AGENDADO') return false;
    return true;
  }

  function sair() {
    // history.replace("/vistoria/solicitar");
    if (param.get("goTo") === "Agenda") {
      history.replace("/vistoria/agenda");
    } else {
      history.goBack();
    }
  }
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function showLaudo() {
    if( vistoria ){
      if (laudo && laudo === true) return true;
        if (vistoria && (vistoria.situacao === 'EM_EXECUCAO'
          || vistoria.situacao === 'EXECUTADO'
          || vistoria.situacao === 'VALIDADO_DIRETOR'
          || vistoria.situacao === 'VALIDACAO_GERENTE'
          || vistoria.situacao === 'AGUARDANDO_AJUSTE_GERENTE'
          || vistoria.situacao === 'AJUSTE_VISTORIADOR'
          || vistoria.situacao === 'AGUARDANDO_AJUSTE'
        )
        ){
          return true;
        } 
    }
    return false;
  }
  function showAgenda() {
    if (agendar) return false;
    return true;
  }

  
  function relatorio() {

    setWait(true);
    fetch("/api/processoProdf/vistoria/relatorioInicioImplantacao", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(vistoria),
    }).then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        response.json().then((error) => {
          setWait(false);
          setMensagem("Problema ao gerar PDF");
        });
        return;
      }
    }).then(body => {
      setWait(false);
      if (!body) return;
      var file = window.URL.createObjectURL(body);
      let tab = window.open();
      tab.location.assign(file);

    });
  }

  const imprimirVistoria = () => {
    const dado = vistoria;

    //fetch("/api/processoProdf/requerimento/relatorio", {
    fetch("/api/processoProdf/vistoria/fichaVistoria.pdf", {
      method: "POST",
      body: JSON.stringify(dado),
      headers: { "Content-Type": "application/json; charset=utf-8" }
    }).then(response => {
      if (response.ok) {
        return response.blob();
      } else {
        response.json().then((error) => {
          setWait(false);
          setMensagem("Problema ao gerar PDF");
        });
        return;
      }
    }).then(body => {
      if (!body) return;
      var file = window.URL.createObjectURL(body);
      let tab = window.open();
      tab.location.assign(file);
    });
  }
  const handleShowMapa = () => {
    const dado = {
      ra: vistoria.lote.ra,
      ade: vistoria.lote.ade,
      endereco: vistoria.lote.endereco
    }
    fetch('/api/processoProdf/vistoria/buscaLotePorEndereco', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(dado),
      credentials: 'include'
    }).then(response => {
      if (response.ok) {
        response.json()
          .then(ret => {
            setCoordenada({
              lat: ret[0].latitude,
              lng: ret[0].longitude
            })
            if (ret[0].latitude) {
              setMapCentralizar({
                lat: ret[0].latitude,
                lng: ret[0].longitude
              })
            } else {
              setMapCentralizar({
                lat: -15.7801,
                lng: -47.9292
              })
            }
            setShowMapa(true);
          })
      } else {
        response.json().then(erro => {
          setErro(erro.message);
        })
      }
    }).catch(error => {
      setWait(false);
      setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }

  return (
    <Container maxWidth='lg'>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
          <Tab
            value="cad"
            label="Dados solicitação"
            wrapped
            disabled={hasChange}
            {...a11yProps('cad')}
          />
          {showAgenda() &&
            <Tab value="agenda" label="Dados agenda" {...a11yProps('agenda')} disabled={hasChange}/>
          }
          {showLaudo() &&
            <Tab value="laudo" label="Dados Laudo vistoria" {...a11yProps('agenda')} disabled={hasChange}/>
          }
          <Tab value="hist" label="Histórico" {...a11yProps('historico')} disabled={hasChange}/>
          <Tab value="sit" label="Situação" {...a11yProps('sit')} disabled={hasChange}/>
        </Tabs>
      </AppBar>
      <TabPanel value={value} index="cad" >
        <VistoriaForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} vistoria={vistoria} disable={disable || agendar || laudo || validarVistoria || validarVistoriaDiretor} />
      </TabPanel>
      <TabPanel value={value} index="agenda">
        <DadosAgendaForm setWait={setWait} setMensagem={setMensagem} vistoria={vistoria} disable={disable || validarVistoria || validarVistoriaDiretor} />
      </TabPanel>
      <TabPanel value={value} index="laudo">
        <DadosLaudoVistoraForm setWait={setWait}
          setMensagem={setMensagem}
          vistoria={vistoria}
          setVistoria={setVistoria}
          setLaudo={setLaudo}
          laudoDescricao={laudoDescricao}
          setErro={setErro}
          setDisabledConcluir={setDisabledConcluir}
          disabledConcluir={disabledConcluir}
          disable={disable || disableLaudo || validarVistoriaDiretor} 
          setHasChange={setHasChange}
          ref={laudoRef}
          />
      </TabPanel>
      <TabPanel value={value} index="hist">
        <HistoricoVistoriaForm setWait={setWait} setMensagem={setMensagem} vistoria={vistoria} />
      </TabPanel>
      <TabPanel value={value} index="sit">
        {fluxo &&
          <FluxoPanelBPM fluxo={fluxo} path={fluxoPath} />
        }
      </TabPanel>
      {agendar && agendar === true &&
        <Grid container justify="flex-end" className={classes.btns}>
          <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
            Sair
          </Button>
          <Button variant="contained" color="primary" onClick={handleActionSolicitarClick} rel="noopener noreferrer">
            Devolver
          </Button>
          <Button variant="contained" color="primary" onClick={agendarVistoria} rel="noopener noreferrer">
            Agendar
            </Button>
        </Grid>
      }

      {executar && executar === true &&

        <Grid container justify="flex-end" className={classes.btns}>
          <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
            Sair
          </Button>
          <Button variant="contained" color="primary" onClick={handleShowMapa} rel="noopener noreferrer">
            Ver Mapa
          </Button>
          <Button variant="contained" color="primary" onClick={relatorio} rel="noopener noreferrer">
            Imprimir Ficha
          </Button>
          <Button variant="contained" color="primary" onClick={handleEmExecucao} rel="noopener noreferrer">
            Executar
          </Button>
        </Grid>

      }
      {validarVistoriaDiretor && validarVistoriaDiretor === true &&
        <Grid justify="center" container spacing={1} className={classes.root}>
          <Grid item xs={11} >
            <Typography>Validar diretor</Typography>
            <CKEditor
              editor={ClassicEditor} disabled={disable && disable === true}
              config={{
                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                    '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
            }}
              data={txt}
              id="descricao"
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                editor.editing.view.change(writer => {
                  writer.setStyle(
                    "height",
                    "200px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setTxt(data);
              }}
              onBlur={(event, editor) => { }}
              onFocus={(event, editor) => { }}
            />
          </Grid>
          <Grid container justify="flex-end" className={classes.btns} >
            <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
              Sair
              </Button>
            <Button variant="contained" color="primary" onClick={imprimirVistoria} rel="noopener noreferrer">
              Imprimir
          </Button>
            <Button variant="contained" color="primary" onClick={() => validarLaudoDiretor()} rel="noopener noreferrer">
              Validar
            </Button>
            <Button variant="contained" color="primary" onClick={() => devolverVistoriaDiretor()} rel="noopener noreferrer">
              Devolver
              </Button>
          </Grid>
        </Grid>
      }

      {/* campo da tela de validarVistoria */}
      {validarVistoria && validarVistoria === true &&
        <Grid justify="center" container spacing={1} className={classes.root}>
          <Grid item xs={11} >
            <Typography>Validar gerente</Typography>
            <CKEditor
              editor={ClassicEditor} 
              data={txt}
              id="descricao"
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                editor.editing.view.change(writer => {
                  writer.setStyle(
                    "height",
                    "200px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setTxt(data);
              }}
              onBlur={(event, editor) => { }}
              onFocus={(event, editor) => { }}
            />
          </Grid>
          <Grid container justify="flex-end" className={classes.btns}>
            <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
              Sair
            </Button>
            <Button variant="contained" color="primary" onClick={imprimirVistoria} rel="noopener noreferrer" disabled={hasChange} >
              Imprimir 
          </Button>
         
            <Button variant="contained" color="primary" onClick={() => validarLaudo()} rel="noopener noreferrer">
              {vistoria && vistoria.situacao === 'AGUARDANDO_AJUSTE_GERENTE' ? 'Reenviar' : 'Validar'}
            </Button>
            {hasChange && 
            <Button variant="contained" color="primary" onClick={handleSalvarVistoria} rel="noopener noreferrer">
              Salvar
            </Button>}
            <Button variant="contained" color="primary" onClick={() => devolverValidarLaudo()} rel="noopener noreferrer">
              Devolver
            </Button>
          </Grid>
        </Grid>
      }


      {laudo && laudo === true &&
        <Grid container justify="flex-end" className={classes.btns}>
          <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
            Sair
          </Button>
          <Button variant="contained" color="primary" onClick={imprimirVistoria} rel="noopener noreferrer">
            Imprimir
          </Button>
          {hasChange && 
          <Button variant="contained" color="primary" onClick={handleSalvarVistoria} rel="noopener noreferrer">
            Salvar
          </Button>}
          <Button variant="contained" color="primary" onClick={handleConcluirLaudo} rel="noopener noreferrer" disabled={disabledConcluir} >
            {vistoria && vistoria.situacao === 'AJUSTE_VISTORIADOR' ? 'Reenviar' : 'Concluir'}
          </Button>
        </Grid>
      }

      {(location.pathname.includes("solicitarView") || location.pathname.includes("gerenciarView")) && 
        <Grid container justify="flex-end" className={classes.btns}>
          <Button variant="contained" color="primary" onClick={imprimirVistoria} rel="noopener noreferrer">
            Imprimir
          </Button>
          
          
          {!location.pathname.includes("solicitarView") && vistoria && vistoria.situacao === 'NAO_INFORMADO' &&
            <Button variant="contained" color="primary" href={"/vistoria/alteraVistoria?id=" + param.get("id")} rel="noopener noreferrer">
              Alterar
            </Button>
            
          }
          <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
            Sair
          </Button>
        </Grid>
      }

      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth >
        <DialogTitle id="form-dialog-title">{tituloDialog}:</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            id="descricao"
            name="descricao"
            //label={tituloDialog}
            multiline
            rows={6}
            //value={''}
            //onChange={handleChangeInputForm}
            //inputProps={{ readOnly: (disable && disable === true) }}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={() => setOpenDialog(false)} rel="noopener noreferrer">
            Sair
          </Button>
          <Button variant="contained" color="primary" onClick={() => devolver()} >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog onClose={fechaMapa} aria-labelledby="simple-dialog-title" open={showMapa} fullWidth={true} maxWidth='md'>
        <DialogTitle id="simple-dialog-title">Localidade</DialogTitle>
        <Map ref={inputEl} style={{
          height: '60vh',
          position: 'center',
          borderRadius: '15px',
        }} center={mapCentralizar} zoom={13}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={coordenada} icon={DefaultIcon}>
          </Marker>
        </Map>
        <DialogActions>
          <Button onClick={fechaMapa} variant="contained" color="secondary">
            Sair
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
