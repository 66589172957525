import React, { useState } from 'react';
import { Container, Card, CardContent, Grid, TextField, Button } from '@material-ui/core';
import { useStyles } from '../../../constants';
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";
import Service from '../../../services/Service';
import { useLocation } from 'react-router-dom';

export default function DetalharDadosEscrituracaoProcessoPanel(props) {
    const classes = useStyles();
    const { processo, setProcesso, gestaoProcesso, profile, setWait, setMensagem } = props;
    const [alteracao, setAlteracao] = useState(false);
    const location = useLocation();

    function isEmpresario() {
        if (location.pathname.includes("cadastrarProcesso")) return true;
        if (profile) {
            return profile.regras.find(r => r === '012') && true;
        }
        return false;
    }

    function salvar() {
        const dados = {
            processo: processo,
            IdUnidade: processo.unidade

        }
        setWait(true);
        Service.POST('/api/gerenciarProcesso/salvarProcessoGerenciar', dados).then(json => {
            setWait(false);
            setAlteracao(false);
            // history.replace("/gerenciaProcesso/processo/" + json.id + "?panel=processo");
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }

    const handleChangeEscritura = (e) => {
        setAlteracao(true);
        setProcesso({
            ...processo,
            escritura: {
                ...processo.escritura,
                [e.target.name]: e.target.value
            }
        })
    }

    return (
        <Container>
            <Card style={{ marginTop: '5px' }}>
                <CardContent>
                    <Grid container spacing={3} className={classes.root}>
                        <Grid item xs={6}>
                            <TextField
                                value={processo.escritura && processo.escritura.escrituraPromessaData || ''}
                                fullWidth
                                label="Data da Escritura Promessa"
                                id="escrituraPromessaData"
                                name="escrituraPromessaData"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        {!isEmpresario() &&
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.escritura && processo.escritura.linkFolhaPromessa || ''}
                                    onChange={handleChangeEscritura}
                                    fullWidth
                                    label="Data da Escritura Promessa (Link/Folha)"
                                    id="linkFolhaPromessa"
                                    name="linkFolhaPromessa"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                value={processo.escritura && processo.escritura.escrituraDefinitivaData || ''}
                                fullWidth
                                label="Data da Escritura Definitiva"
                                id="escrituraDefinitivaData"
                                name="escrituraDefinitivaData"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        {!isEmpresario() &&
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.escritura && processo.escritura.linkFolhaDefinitiva || ''}
                                    onChange={handleChangeEscritura}
                                    fullWidth
                                    label="Data da Escritura Definitiva (Link/Folha)"
                                    id="linkFolhaDefinitiva"
                                    name="linkFolhaDefinitiva"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                        <Grid item xs={6}>
                            <TextField
                                value={processo.escritura && processo.escritura.escrituraDataDesenvolveDf || ''}
                                fullWidth
                                label="Data da Escritura Desenvolve-DF"
                                id="escrituraDataDesenvolveDf"
                                name="escrituraDataDesenvolveDf"
                                variant="outlined"
                                disabled
                            />
                        </Grid>
                        {!isEmpresario() &&
                            <Grid item xs={6}>
                                <TextField
                                    value={processo && processo.escritura && processo.escritura.linkFolhaDesenvolveDf || ''}
                                    onChange={handleChangeEscritura}
                                    fullWidth
                                    label="Data da Escritura Desenvolve-DF (Link/Folha)"
                                    id="linkFolhaDesenvolveDf"
                                    name="linkFolhaDesenvolveDf"
                                    variant="outlined"
                                // disabled
                                />
                            </Grid>
                        }
                        {/* <Grid item xs={12}>
                            <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="escrituracao" />
                        </Grid> */}
                    </Grid>
                    {alteracao &&
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Button variant="contained" onClick={() => salvar()} color="primary" >
                                Salvar
                            </Button>
                        </Grid>
                    }
                </CardContent>
            </Card>
        </Container >
    );
}
