

const Service ={

    POSTReport: function(url, dado){
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(dado),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                return response.json().then(erro =>{
                    console.error("---->",erro);
                    if( erro.status === 500){
                        if( erro.message.includes("signature does not match") ||
                            erro.message.includes("JWT expired") 
                          ){
                            Service.gotoLogin();
                            throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
                        }else{
                            throw (erro && erro.message)?erro.message:"";
                        }
                    }else{
                        throw erro;
                    }
                });
            }
        });
    },
    POST: function (url, dado, headers){
        const val = {
            url: url,
            dado: dado
        }
        return fetch(url, {
            method: "POST",
            body: JSON.stringify(dado),
            headers: headers?headers:{
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            credentials: 'include'
          }).then( r => {
            if (r.ok) {
                return r.json();
            }else{
                return r.json().then(erro =>{
                    console.error("---->",erro);
                    if( erro.status === 500){
                        if( erro.message.includes("signature does not match") ||
                            erro.message.includes("JWT expired") 
                          ){
                            Service.gotoLogin();
                            throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
                        }else{
                            throw (erro && erro.message)?erro.message:"";
                        }
                    }else{
                        throw erro;
                    }
                });
            }
        });
    },

    GET: function (url,){
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
          }).then( r => {
            if (r.ok) {
                return r.json();
            }else{
                return r.json().then(erro =>{
                    console.error("Erro da requisicao: ",erro);
                    if( erro.status === 500){
                        if( erro.message.includes("signature does not match") ||
                            erro.message.includes("JWT expired") 
                          ){
                            Service.gotoLogin();
                            throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
                        }else{
                            throw (erro && erro.message)?erro.message:"";
                        }
                    }else{
                        throw erro;
                    }
                });
            }
        });
    },


    gotoLogin: function(){
        setTimeout(function(){ 
            localStorage.clear();
            window.location.href = "login";
         }, 3000);
        
    }
};


export default Service;