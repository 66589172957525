import React, { useEffect, useRef, useState } from 'react';
import {
    Grid, Button, Dialog, DialogTitle, TextField, DialogContent, DialogActions,
    FormGroup, FormControlLabel, Checkbox
} from '@material-ui/core';
import ConfirmDialog from "../../util/ConfirmDialog";
import { useStyles } from "../../constants";
import { useLocation, useHistory } from "react-router-dom";
import { FormataDataComHora, FormataDataSemHora } from "../../constants";
import GenericTable from "../../components/template/GenericTable";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import AgendarVistoriaDialog from './agenda/AgendarVistoriaDialog';
import { useCurrentUser } from "../../server/UseCurrentUser";
import Service from '../../services/Service';

let idVistoria;
let situacaoVistoria;
let DefaultIcon = L.icon({
    iconUrl: icon,
    iconAnchor: [19, 46],
    popupAnchor: [-6, -47]
});
export default function VistoriaTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait, setMensagem,setSuccess } = props;
    const history = useHistory();
    const location = useLocation();
    const [valueRadioGroup, setValueRadioGroup] = React.useState('minhasVistorias');
    const [openDialog, setOpenDialog] = React.useState(false);
    const [tituloDialog, setTituloDialog] = useState("Erro");
    const [txtDialog, setTxtDialog] = useState();
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const [localidade, setLocalidade] = useState();
    const [coordenada, setCoordenada] = useState({});
    const [showMapa, setShowMapa] = useState(false);
    const [showAgendar, setShowAgendar] = useState(false);
    const [dataIndicada, setDataIndicada] = useState();
    const [dadosDialog, setDadosDialog] = useState();
    const [vistoriaFinalizada, setVistoriaFinalizada] = useState(false);
    let [profile] = useCurrentUser();
    //const [dado, setDado] = useState();
    const [mapCentralizar, setMapCentralizar] = useState({
        lat: -15.7801,
        lng: -47.9292
    });
    const inputEl = useRef(null);
    useEffect(() => {
        if (location.pathname.includes("agendar")) {
            setTitulo("Agendar Vistorias");
        } else if (location.pathname.includes("solicitar")) {
            setTitulo("Vistorias Solicitadas");
        } else if (location.pathname.includes("gerenciar")) {
            setTitulo("Gerenciar Vistorias");
        } else if (location.pathname.includes("executar")) {
            setTitulo("Executar Vistorias");
        } else if (location.pathname.includes("validarGerente")) {
            setTitulo("Validar Vistorias (Gerente)");
        } else if (location.pathname.includes("validarDiretor")) {
            setTitulo("Validar Vistorias (Diretor)");
        } else if (location.pathname.includes("vistoriador")) {
            setTitulo("Vistoriador Cadastrado");
        } else {
            setTitulo("Aa-aA")
        }
    }, [location.pathname]);

    useEffect(()=>{
        if( fonteDeDados && fonteDeDados.length > 0 ){
            console.log(">>>>vistoriaFinalizada<<<<");
            buscaVistoria();
        }
        
    }, [vistoriaFinalizada])

    useEffect(() => {
        if(profile) buscaVistoria();
    }, [profile, location.pathname]);

    function buscaVistoria() {
        setWait(true);
        fetch("/api/processoProdf/vistoria")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    const isGerenciar = location.pathname.includes("gerenciar")
                    let arr = [];
                    result = result.sort((a, b) => ((a.dataSolicitacao < b.dataSolicitacao) ? 1 : -1));
                    let dataValidadaDiretor
                    for (let x in result) {
                        const vistoria = result[x];
                        if( isGerenciar){
                            if( !vistoriaFinalizada ){
                                if( vistoria.situacao === 'VALIDADO_DIRETOR') continue;
                            }
                        }
                        const dataAtual = new Date();
                        const date2 = new Date(vistoria.dataSolicitacao);
                        const diffTime = Math.abs(date2 - dataAtual);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        let dataDif 
                        let vistoriadores = vistoria.agenda ? (vistoria.agenda.vistoriadores[0] ? vistoria.agenda.vistoriadores[0].user.nome : '') : '';
                        for(let y in vistoria.historico){
                            const hist = vistoria.historico[y]
                            if(hist.tipo === 'VALIDADO_DIRETOR'){
                                dataValidadaDiretor = new Date(hist.data)
                                const data1 = Math.abs(dataValidadaDiretor - date2)
                                const dtCalc = Math.ceil(data1 / (1000 * 60 * 60 * 24) )
                                dataDif = dtCalc
                            }
                        }
                        vistoriadores += vistoriadores && vistoriadores.length > 0 ?"\n":"";
                        vistoriadores +=vistoria.agenda ? (vistoria.agenda.vistoriadores[1] ? vistoria.agenda.vistoriadores[1].user.nome : '') : '';
                        arr.push({
                            id: vistoria.id,
                            protocolo: vistoria.protocolo,
                            protocoloRepl: vistoria.protocolo.replace(/[^\w\s]/gi, ''),
                            processo: vistoria.processo ? vistoria.processo.nProcesso : '',
                            processoRepl: vistoria.processo ? vistoria.processo.nProcesso.replace(/[^\w\s]/gi, '') : '',
                            cnpj: vistoria.processo ? vistoria.processo.cnpj : "",
                            cnpjRepl: vistoria.processo ? vistoria.processo.cnpj.replace(/[^\w\s]/gi, '') : "",
                            razaoSocial: vistoria.processo ? vistoria.processo.razaoSocial : "",
                            assunto: vistoria.assunto,
                            dataSolicitacao: (vistoria.dataSolicitacao ? FormataDataComHora(new Date(vistoria.dataSolicitacao)) : ""),
                            dataSolicitacaoRepl: (vistoria.dataSolicitacao ? FormataDataComHora(new Date(vistoria.dataSolicitacao)) : "").replace(/[^\w\s]/gi, ''),
                            calcTempo: (vistoria.situacao === "VALIDADO_DIRETOR" ? dataDif : diffDays + ' (dias)'),
                            situacao: vistoria.situacao,
                            situacaoForm: deParaSituacao(vistoria.situacao),
                            ra: vistoria.lote[0].ra,
                            ade: vistoria.lote[0].ade,
                            endereco: vistoria.lote[0].endereco,
                            solicitante: vistoria.solicitante.nome,
                            solicitanteID: vistoria.solicitante.id,
                            dataVistoria: vistoria.agenda ? FormataDataSemHora(new Date(vistoria.agenda.data)) : null,
                            dataVistoriaTurno: vistoria.agenda ? (FormataDataSemHora(new Date(vistoria.agenda.data))+vistoria.agenda.turno) : null,
                            dataVistoriaRepl: vistoria.agenda ? FormataDataSemHora(new Date(vistoria.agenda.data)).replace(/[^\w\s]/gi, '') : null,
                            turno: vistoria.agenda ? vistoria.agenda.turno : null,
                            vistoriadores: vistoriadores,
                            agenda: vistoria.agenda,
                        })
                    }
                    if (location.pathname.includes("executar")) {
                        
                        filtraDadoExecutar(arr);
                    } else if (location.pathname.includes("agendar")) {
                        filtraDadoAgendar(arr);
                    } else if (location.pathname.includes("validarGerente")) {
                        filtraDadoGerente(arr);
                    } else if (location.pathname.includes("validarDiretor")) {
                        filtraDadoDiretor(arr);
                    } else {
                        setFonteDeDados(arr);
                    }

                }
            });
    }
    function fechaMapa() {
        setShowMapa(false);
    }

    function filtraDadoExecutar(arr) {
        let fitrado = [];
        const dataAtual = FormataDataSemHora(new Date());
        for (let x in arr) {
            if (arr[x].agenda && arr[x].agenda.vistoriadores)//(arr[x].dataVistoria <= dataAtual) 
            {
                const item = (arr[x].agenda.vistoriadores.find(v => 
                    v.user.idUser === profile.id
                ));
                if ( item) {
                    if (arr[x].situacao === "AGENDADO"
                        || arr[x].situacao === "EM_EXECUCAO"
                        || arr[x].situacao === "EXECUTADO"
                        || arr[x].situacao === "VALIDACAO_GERENTE"
                        || arr[x].situacao === "VALIDADO_DIRETOR"
                        || arr[x].situacao === "AGUARDANDO_AJUSTE_GERENTE"
                        || arr[x].situacao === "AJUSTE_VISTORIADOR"
                    ) {
                        fitrado.push(arr[x]);
                    }

                }
            }
        }
        setFonteDeDados(fitrado);
    }

    function filtraDadoAgendar(arr) {
        let fitrado = [];
        for (let x in arr) {
            if (arr[x].situacao === "Solicitado"
                || arr[x].situacao === "NAO_INFORMADO"
                || arr[x].situacao === "SOLICITADO"
            ) {
                fitrado.push(arr[x]);
            }
        }
        setFonteDeDados(fitrado);
    }

    function filtraDadoGerente(arr) {
        let fitrado = [];
        for (let x in arr) {
            if (arr[x].situacao === "EXECUTADO"
                || arr[x].situacao === "AGUARDANDO_AJUSTE_GERENTE"

            ) {
                fitrado.push(arr[x]);
            }
        }
        setFonteDeDados(fitrado);
    }

    function filtraDadoDiretor(arr) {
        let fitrado = [];
        for (let x in arr) {
            if (arr[x].situacao === "VALIDACAO_GERENTE") {
                fitrado.push(arr[x]);
            }
        }
        setFonteDeDados(fitrado);
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        idVistoria = dado.id;
        situacaoVistoria = dado.situacao;
        if (acao && acao === 'Visualizar') {
            if (location.pathname.includes("gerenciar")) {
                history.push("/vistoria/gerenciarView?id=" + dado.id)

            } else {
                history.push("/vistoria/solicitarView?id=" + dado.id)
            }
        } else if (acao && acao === 'Suspender') {
            if (dado.situacao === 'SUSPENSO') {
                setMensagem("Vitoria já suspensa");
            } else {
                setOpenDialog(true);
                setTituloDialog("Motivo suspensão");
            }
        } else if (acao && acao === 'Cancelar') {
            if (dado.situacao === "CANCELADO") {
                setMensagem("Vistoria já cancelada")
            } else {
                setOpenDialog(true);
                setTituloDialog("Motivo Cancelamento");
            }
        } else if (acao && acao === 'Alterar') {
            history.push("/vistoria/alteraVistoria?id=" + dado.id);
        } else if (acao && acao === 'Agendar') {
            history.push("/vistoria/agendarVistoria?id=" + dado.id);
        } else if (acao && acao === 'Reagendar') {
            history.push("/vistoria/reagendarVistoriaId?id=" + dado.id);
        } else if (acao && acao === 'Executar') {
            history.push("/vistoria/executarVistoria?id=" + dado.id);
        } else if (acao && acao === 'Elaborar laudo de vistoria') {
            history.push("/vistoria/elaborarLaudoVistoria?id=" + dado.id);
        } else if (acao && acao === 'Validar laudo') {
            history.push("/vistoria/validarVistoriaGerente?id=" + dado.id);
        } else if (acao && acao === 'Validar laudo Diretor') {
            history.push("/vistoria/validarVistoriaDiretor?id=" + dado.id);
        } else if (acao && acao === 'Excluir') {
            if (situacaoVistoria === "NAO_INFORMADO") {
                setShowConfirmExclusao(true);
            }
        } else if (acao && acao === 'Alterar vistoriadores') {
            buscarVistoria(dado.id);
        } else if (acao && acao === "Ver Mapa") {
            handleShowMapa(dado)
        } else if (acao && acao === 'Novo agendamento') {
            handleNewAge(dado.id);
        }
    }

    function buscarVistoria(id) {
        setWait(true);
        fetch("/api/processoProdf/vistoria/" + id)
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result.error ? result.error : "Problema inesperado!");
                } else {
                    setDataIndicada(new Date(result.agenda.data));
                    setDadosDialog(result);
                    setShowAgendar(true);
                }
            });
    }

    /*  function formataData(dt) {
         if (!dt) return "";
         return FormataDataComHora(new Date(dt));
     } */

    function deParaSituacao(st) {
        if (st === "AGENDADO") {
            return "Agendado"
        } else if (st === "EM_EXECUCAO") {
            return "Em execução"
        } else if (st === "EXECUTADO") {
            return "Executada"
        } else if (st === "VALIDADA_GERENTE") {
            return "Validada Gerente"
        } else if (st === "VALIDADO_DIRETOR") {
            return "Validada Diretor"
        } else if (st === "CANCELADO") {
            return "Cancelada"
        } else if (st === "SUSPENSO") {
            return "Suspensa"
        } else if (st === "AGUARDANDO_AJUSTE") {
            return "Aguardando ajuste solicitante"
        } else if (st === "AGUARDANDO_AJUSTE_GERENTE") {
            return "Aguardando ajuste gerente"
        } else if (st === "AGUARDANDO_AJUSTE_VISTORIADOR") {
            return "Aguardando ajuste vistoriador"
        } else if (st === "NAO_INFORMADO") {
            return "Solicitada"
        } else if (st === "AJUSTE_VISTORIADOR") {
            return "Aguardando ajuste vistoriador"
        } else if (st === "VALIDACAO_GERENTE") {
            return "Validada Gerente"
        }
        return "?";
    }


    const handleCloseDialog = () => {
        setTxtDialog();
        setOpenDialog(false);
    };

    function isReadOnly() {
        return false;
    }
    function handleClickComfimarDialog() {
        if (tituloDialog === "Motivo suspensão") {
            submitSuspender();
        } else if (tituloDialog === "Motivo Cancelamento") {
            submitCancelar()
        }
        setOpenDialog(false);
        setTituloDialog("Erro");
    }

    function submitSuspender() {
        // const descricao = document.getElementById("descricao").value;
        const dado = {
            id: idVistoria,
            descricao: txtDialog,
        }
        Service.POST('/api/processoProdf/vistoria/suspender', dado).then(r =>{
            buscaVistoria();
        }).catch(error => {
            setWait(false);
            setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
        });
        /*
        fetch('/api/processoProdf/vistoria/suspender', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                buscaVistoria();
            } else {
                response.json().then(erro => {
                    setErro(erro.message);
                })
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
        */
    }

    function submitCancelar() {
        // const descricao = document.getElementById("descricao").value;
        const dado = {
            id: idVistoria,
            descricao: txtDialog,
        }
        Service.POST('/api/processoProdf/vistoria/cancelar', dado).then(r =>{
            buscaVistoria();
        }).catch(error => {
            setWait(false);
            setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
        });
        /*
        fetch('/api/processoProdf/vistoria/cancelar', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                buscaVistoria();
            } else {
                response.json().then(erro => {
                    setErro(erro.message);
                })
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });*/
    }
    function confirmaExclusao() {
        const dado = {
            id: idVistoria,
        }
        setWait(true);
        fetch("/api/processoProdf/vistoria/" + dado.id, {
            method: 'DELETE'
        }).then((result) => {
            setWait(false);

            if (!result.ok) {
                setErro(result && result.message ? result.message : result.statusText);
            } else {

                buscaVistoria();
                setMensagem("Registro excluido!")
            }
        }).catch(error => {
            setWait(false);
            setErro((setErro && setErro.statusText) || 'Oops! Something went wrong. Please try again!');
        });
    }
    // parte da funcionalidade do mapa
    function handleShowMapa(d) {
        const dado = {
            ade: d.ade,
            ra: d.ra,
            endereco: d.endereco,
            assunto: d.assunto,
            processo: d.processo,
            dataSolicitacao: d.dataSolicitacao,
            turno: d.turno
        }
        setLocalidade(dado);
        fetch('/api/processoProdf/vistoria/buscaLotePorEndereco', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                response.json()
                    .then(ret => {
                        setCoordenada({
                            lat: ret[0].latitude,
                            lng: ret[0].longitude
                        })
                        if (ret[0].latitude) {
                            setMapCentralizar({
                                lat: ret[0].latitude,
                                lng: ret[0].longitude
                            })
                        } else {
                            setCoordenada({
                                lat: -15.789073,
                                lng: -47.899635
                            })
                        }
                        setShowMapa(true);
                    })
            } else {
                response.json().then(erro => {
                    setErro(erro.message);
                })
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    function handleNewAge(val) {
       let id = val
        const dado = {
            id: id
        }

        fetch('/api/processoProdf/vistoria/novoAgendamento', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok) {
                handleCloseDialog();
                window.location.reload();
            } else {
                response.json().then(erro => {
                    setErro(erro.message);
                })
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }



    const desabilitaAcaoGenericTable = (acao, dado) => {
        if (location.pathname.includes("gerenciar") && dado) {
            if ((dado.situacao !== "AGENDADO" && dado.situacao !== "SUSPENSO") && acao === "Cancelar") return true;
            if (dado.situacao !== "AGENDADO" && dado.situacao !== "SUSPENSO" && acao === "Reagendar") return true;
            if (dado.situacao !== "AGENDADO" && acao === "Suspender") return true;
            if (dado.situacao !== "AGENDADO" && acao === 'Alterar vistoriadores') return true;
            //if (dado.situacao === "NAO_INFORMADO" && acao === "Alterar vistoriadores") return true;
            //if (dado.situacao !== "SUSPENSO" && acao === "Reagendar") return false;
        }
        if (location.pathname.includes("validarDiretor") && dado) {

            if (dado.situacao !== "VALIDACAO_GERENTE" && acao === 'Validar laudo Diretor') return true;

        } else {
            if (acao === "Executar" && dado) {
                return dado.situacao !== "AGENDADO";
            }
            if (acao === "Elaborar laudo de vistoria" && dado) {
                return (dado.situacao !== "EM_EXECUCAO" && dado.situacao !== "AJUSTE_VISTORIADOR");
            }
            if (acao === "Novo agendamento" && dado) {
                return (dado.situacao !== "EM_EXECUCAO" );
            }
        }
        return false;
    }
    const desabilitaAcaoExcluirTable = (acao, dado) => {

        if (acao === "Cancelar" && dado && (dado.solicitanteID !== profile.id || (dado.situacao !== "NAO_INFORMADO") && (dado.situacao !== "AGUARDANDO_AJUSTE") )){
            return true;
        }
        if (dado && dado.situacao === "AGUARDANDO_AJUSTE") return false;
        if (acao === "Excluir" && dado) {
            return dado.situacao !== "NAO_INFORMADO";
        }
        if (acao === "Alterar" && dado) {
            return dado.situacao !== "NAO_INFORMADO";
        }
        return false;
    }
    function feito() {
        buscaVistoria();
        setDadosDialog();
    }
    function verificarTexto() {
        if(!txtDialog){
            return true;
        }
        return false;
    }
    // const handleChangeInputForm = (event) => {
    //     setValue(event.target.value);
    // };
    return (
        <Grid container spacing={3} className={classes.root}>
            {location.pathname.includes("solicitar") &&
                <Grid item xs={12}>
                    <GenericTable
                        actionClick={handleActionSolicitarClick}
                        handleDisableAction={desabilitaAcaoExcluirTable}
                        options={['Visualizar', 'Alterar','Cancelar', 'Excluir']}
                        dados={fonteDeDados}
                        readOnly={isReadOnly()}
                        colunas={[
                            { nome: 'Protocolo', campo: 'protocolo' },
                            { nome: 'Processo', campo: 'processo' },
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Razão Social', campo: 'razaoSocial' },
                            { nome: 'Assunto', campo: 'assunto' },
                            { nome: 'Data Solicitação', campo: 'dataSolicitacao' },
                            { nome: 'Tempo (dias)', campo: 'calcTempo' },
                            { nome: 'Situação', campo: 'situacaoForm' },
                            { nome: 'Solicitante', campo: 'solicitante' },
                            { nome: 'Ação', campo: 'action' }
                        ]} />
                </Grid>
            }
            {location.pathname.includes("agendar") &&
                <Grid item xs={12}>
                    <GenericTable
                        actionClick={handleActionSolicitarClick}
                        options={['Visualizar', 'Agendar']}
                        dados={fonteDeDados}
                        readOnly={isReadOnly()}
                        colunas={[
                            { nome: 'Protocolo', campo: 'protocolo' },
                            { nome: 'Processo', campo: 'processo' },
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Razão Social', campo: 'razaoSocial' },
                            { nome: 'RA', campo: 'ra' },
                            { nome: 'ADE', campo: 'ade' },
                            { nome: 'Endereço', campo: 'endereco' },
                            { nome: 'Assunto', campo: 'assunto' },
                            { nome: 'Data Solicitação', campo: 'dataSolicitacao' },
                            { nome: 'Tempo (dias)', campo: 'calcTempo' },
                            { nome: 'Situação', campo: 'situacaoForm' },
                            { nome: 'Ação', campo: 'action' }
                        ]} />
                </Grid>
            }
            {location.pathname.includes("gerenciar") &&
            <>
                <div style={{position:"absolute", top:"200px", right:"100px",  }}>
                    <FormControlLabel control={<Checkbox checked={vistoriaFinalizada} onChange={(e) => setVistoriaFinalizada(e.target.checked)} />} label="Apresentar Vistoria Finalizadas" />
                </div>
                <Grid item xs={12}>
                    
                    <GenericTable 
                        actionClick={handleActionSolicitarClick}
                        options={['Visualizar', 'Suspender', 'Reagendar', 'Alterar vistoriadores', 'Ver Mapa']}
                        dados={fonteDeDados}
                        readOnly={isReadOnly()}
                        handleDisableAction={desabilitaAcaoGenericTable}
                        colunas={[
                            { nome: 'Protocolo', campo: 'protocolo' },
                            { nome: 'Processo', campo: 'processo' },
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Razão Social', campo: 'razaoSocial' },
                            { nome: 'Assunto', campo: 'assunto' },
                            { nome: 'Data Solicitação', campo: 'dataSolicitacao' },
                            { nome: 'Data Vistoria', campo: 'dataVistoria' },
                            { nome: 'Tempo (dias)', campo: 'calcTempo' },
                            { nome: 'Situação', campo: 'situacaoForm' },
                            { nome: 'Vistoriadores', campo: 'vistoriadores' },
                            { nome: 'Ação', campo: 'action' }
                        ]} />
                </Grid>
                </>
            }
            {location.pathname.includes("executar") &&
                <Grid item xs={12}>
                    <GenericTable
                        actionClick={handleActionSolicitarClick}
                        options={['Visualizar', 'Executar', 'Elaborar laudo de vistoria', 'Novo agendamento']}
                        dados={fonteDeDados}
                        readOnly={isReadOnly()}
                        handleDisableAction={desabilitaAcaoGenericTable}
                        colunas={[
                            { nome: 'Protocolo', campo: 'protocolo' },
                            { nome: 'Processo', campo: 'processo' },
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Razão Social', campo: 'razaoSocial' },
                            { nome: 'RA', campo: 'ra' },
                            { nome: 'ADE', campo: 'ade' },
                            { nome: 'Endereço', campo: 'endereco' },
                            { nome: 'Assunto', campo: 'assunto' },
                            { nome: 'Situação', campo: 'situacaoForm' },
                            { nome: 'Data Vistoria', campo: 'dataVistoriaTurno'},
                            // { nome: 'Turno', campo: 'turno' },
                            { nome: 'Vistoriadores', campo: 'vistoriadores'},
                            { nome: 'Ação', campo: 'action' }
                        ]} />
                </Grid>
            }
            {/* estou trabalhando aqui */}
            {location.pathname.includes("validarGerente") &&
                <Grid item xs={12}>
                    <GenericTable
                        actionClick={handleActionSolicitarClick}
                        options={['Visualizar', 'Validar laudo']}
                        dados={fonteDeDados}
                        readOnly={isReadOnly()}
                        colunas={[
                            { nome: 'Protocolo', campo: 'protocolo' },
                            { nome: 'Processo', campo: 'processo' },
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Razão Social', campo: 'razaoSocial' },
                            { nome: 'RA', campo: 'ra' },
                            { nome: 'ADE', campo: 'ade' },
                            { nome: 'Endereço', campo: 'endereco' },
                            { nome: 'Assunto', campo: 'assunto' },
                            { nome: 'Situação', campo: 'situacaoForm' },
                            { nome: 'Data Vistoria', campo: 'dataVistoria' },
                            { nome: 'Ação', campo: 'action' }
                        ]} />
                </Grid>
            }
            {location.pathname.includes("validarDiretor") &&
                <Grid item xs={12}>
                    <GenericTable
                        actionClick={handleActionSolicitarClick}
                        options={['Visualizar', 'Validar laudo Diretor']}
                        dados={fonteDeDados}
                        handleDisableAction={desabilitaAcaoGenericTable}
                        readOnly={isReadOnly()}
                        colunas={[
                            { nome: 'Protocolo', campo: 'protocolo' },
                            { nome: 'Processo', campo: 'processo' },
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Razão Social', campo: 'razaoSocial' },
                            { nome: 'RA', campo: 'ra' },
                            { nome: 'ADE', campo: 'ade' },
                            { nome: 'Endereço', campo: 'endereco' },
                            { nome: 'Assunto', campo: 'assunto' },
                            { nome: 'Situação', campo: 'situacaoForm' },
                            { nome: 'Data Vistoria', campo: 'dataVistoria' },
                            { nome: 'Ação', campo: 'action' }
                        ]} />
                </Grid>
            }
            {location.pathname.includes("vistoriador") &&
                <Grid item xs={12}>
                    <GenericTable
                        actionClick={handleActionSolicitarClick}
                        options={fonteDeDados}
                        dados={["Aaasas"]}
                        readOnly={isReadOnly()}
                        colunas={[
                            { nome: 'Vistoriador', campo: 'protocolo' },
                            { nome: 'Matricula', campo: 'processo' },
                            { nome: 'Ativo', campo: 'cnpj' },
                            { nome: 'Ação', campo: 'action' }
                        ]} />
                </Grid>
            }
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
                {location.pathname.includes("solicitar") &&
                    <Button variant="contained" color="primary" href={"/vistoria/solicitarForm"} >
                        Solicitar
                    </Button>
                }
                {location.pathname.includes("vistoriador") &&
                    <Button variant="contained" color="primary" href={"/vistoria/VistoriadorForm"} >
                        Cadastrar
                </Button>
                }
            </Grid>
            <div>
                <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title" fullWidth >
                    <DialogTitle id="form-dialog-title">{tituloDialog}:</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            id="descricao"
                            name="descricao"
                            //label={tituloDialog}
                            multiline
                            rows={6}
                            value={txtDialog}
                            onChange={e => setTxtDialog(e.target.value)}
                            //inputProps={{ readOnly: (disable && disable === true) }}
                            variant="outlined"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="secondary" onClick={handleCloseDialog} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" color="primary" onClick={() => handleClickComfimarDialog()} disabled={verificarTexto()} >
                            Confirmar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />
            {localidade &&
                <Dialog onClose={fechaMapa} aria-labelledby="simple-dialog-title" open={showMapa} fullWidth={true} maxWidth='md'>
                    <DialogTitle id="simple-dialog-title">Localidade</DialogTitle>

                    <Map ref={inputEl} style={{
                        height: '60vh',
                        position: 'center',
                        borderRadius: '15px',
                    }} center={mapCentralizar} zoom={13}>
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        />
                        <Marker position={coordenada} icon={DefaultIcon}>
                            <Popup>
                                <p>
                                    <b>Assunto : </b>{localidade.assunto}<br />
                                    <b>Processo:</b>{localidade.processo}<br />
                                    <b>Razão Social:</b>{localidade.razaoSocial}<br />
                                    <b>RA:</b>{localidade.ra}<br />
                                    <b>ADE:</b>{localidade.ade}<br />
                                    <b>Endereço:</b>{localidade.endereco}<br />
                                    <b>Data:</b>{localidade.dataSolicitacao}<br />
                                    <b>Turno:</b>{localidade.turno}<br />
                                </p>
                            </Popup>
                        </Marker>
                    </Map>
                    <DialogActions>
                        <Button onClick={fechaMapa} variant="contained" color="secondary">
                            Sair
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            {dadosDialog &&
                <AgendarVistoriaDialog
                    open={showAgendar}
                    setOpen={setShowAgendar}
                    data={dataIndicada}
                    vistoria={dadosDialog}
                    //lstVistoria={dado}
                    setWait={setWait}
                    setMensagem={setMensagem}
                    actionFeito={feito}
                    alteraVistoriador={true}
                />
            }
        </Grid >


    );
}
