import { useState, useEffect } from "react";
import { ACCESS_TOKEN } from '../constants';

function useAssuntoProjeto() {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function call() {
            console.log("aqui")
            if (!localStorage.getItem(ACCESS_TOKEN)) {
                setData(null);
                setLoading(false);
            } else {
                const unidades = localStorage.getItem(ACCESS_TOKEN+"_ASSUNTO_PROJETO");
                if( !unidades || unidades.length === 0 ){
                    
                    fetch('/api/projeto/getAssuntos')
                    .then(response => response.json())
                    .then(json => {
                        setData(json);
                        setLoading(false);
                        localStorage.setItem(ACCESS_TOKEN+"_ASSUNTO_PROJETO", JSON.stringify(json) );
                    })
                    .catch(erro => console.error(erro));
                }else{
                    
                    return new Promise((resolve, reject) => {
                        resolve();
                    }).then(() => {
                        ordenAlfabetica(JSON.parse(unidades));
                        setLoading(false);
                    })
                }
            }
        }
        call();
    }, []);


    function ordenAlfabetica(unidades){
        
        unidades.sort(function(x,y){
            if(x.assunto > y.assunto){
                return 1
            }

            if(x.assunto < y.assunto){
                return -1
            }
            return 0;
        })
        setData(unidades);
    }
    return [data, loading];
}
export { useAssuntoProjeto };
