import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import { Meses, FormataMoeda } from "../../../constants";
import TableMoedaCell from "./TableMoedaCell";

const useStylesLocal = makeStyles(theme => ({
    input: {
        width: 80,
        height: 30
    },
    table: {
        minWidth: 700
    },
    tablecell: {
        fontSize: 14,
        fontWeight: 700
    }
}));

export default function FruicaoExecusaoTable(props) {
    const classes = useStylesLocal();

    const { dados, setDados, calcula, disabled, dadosTerceirizados, dadosRenda } = props;
    const [total, setTotal] = useState({});
    const [componenteAtual, setComponenteAtual] = useState();

    useEffect(() => {
        if (dados && dados.length > 0) {
            let totValorContabilICMSSIGES = 0;
            let totICMSRecolherSIGES = 0;
            let totVendaSEF = 0;
            let totICMSREF = 0;
            let totRendaEmpregado = 0;
            let totInvestimentoProducao = 0;
            let totEmpregadosDireto = 0;
            let totEmpregadosIndireto = 0;
            let totMediaDireto = 1;

            for (let x in dados) {
                totMediaDireto = parseInt(x) + 1
                if (!dados[x]) continue;
                totValorContabilICMSSIGES += dados[x].valorContabilICMSSIGES ? dados[x].valorContabilICMSSIGES : 0;
                totICMSRecolherSIGES += dados[x].ICMSRecolherSIGES ? dados[x].ICMSRecolherSIGES : 0;
                totVendaSEF += dados[x].vendaSEF ? dados[x].vendaSEF : 0;
                totICMSREF += dados[x].ICMSREF ? dados[x].ICMSREF : 0;
                totRendaEmpregado += dados[x].rendaEmpregado ? dados[x].rendaEmpregado : 0;
                totInvestimentoProducao += dados[x].investimentoProducao ? dados[x].investimentoProducao : 0;
                totEmpregadosDireto += dados[x].empregadosDireto ? dados[x].empregadosDireto : 0;
                totEmpregadosIndireto += dados[x].empregadosIndireto ? dados[x].empregadosIndireto : 0;
            }
            setTotal({
                valorContabilICMSSIGES: totValorContabilICMSSIGES,
                ICMSRecolherSIGES: totICMSRecolherSIGES,
                vendaSEF: totVendaSEF,
                ICMSREF: totICMSREF,
                rendaEmpregado: totRendaEmpregado,
                investimentoProducao: totInvestimentoProducao,
                empregadosDireto: Math.round(totEmpregadosDireto / totMediaDireto),
                empregadosIndireto: Math.round(totEmpregadosIndireto / totMediaDireto),
            });
        }
    }, [dados])

    useEffect(() => {
        if (dadosTerceirizados) {
            let arr = [...dados];
            for (let x in dadosTerceirizados) {
                const terce = dadosTerceirizados[x];
                if (terce) {
                    arr[x] = {
                        ...arr[x],
                        empregadosIndireto: (terce.totalEmpregoIndiretos || terce.totalEmpregoIndiretos === 0 || terce.totalEmpregoIndiretos === '0') ? terce.totalEmpregoIndiretos : null
                    }
                }

            }
            if (dadosRenda) {
                for (let x in dadosRenda) {
                    const dadoR = dadosRenda[x];
                    if (dadoR && (dadoR.totalEmpregoDiretos || dadoR.totalEmpregoDiretos === 0 || dadoR.totalEmpregoDiretos === "0") || (dadoR.totalSalario || dadoR.totalSalario === 0 || dadoR.totalSalario === "0")) {
                        arr[x] = {
                            ...arr[x],
                            empregadosDireto: dadoR.totalEmpregoDiretos,
                            rendaEmpregado: dadoR.totalSalario
                        };
                    }
                }
            }
            setDados(arr);
        }
    }, [dadosTerceirizados, dadosRenda])

    const onChange = (e, row, index) => {
        if (!/^[0-9]*\,?[0-9]*$/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^0-9]*\,?[0-9]*$/g, "");
            return;
        }
        let arr = [...dados];
        let val = { ...arr[index] };
        let valor = e.target.value;
        valor = valor.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '');
        valor = valor.replace(",", ".");
        val[e.target.name] = parseFloat(valor);

        if (!val.editado) val.editado = [];
        if (!val.editado.find(v => v === e.target.name)) val.editado.push(e.target.name);

        arr[index] = val;
        setDados(arr);
    };
    function apresentaValor(index, nome) {
        if (dados && dados.length > 0) {
            if (dados[index] || dados[index] === 0 || dados[index] === '0') {
                return FormataMoeda(dados[index][nome]);
            }
            return '';
        }
        return '';
    }
    function apresentaValorEmprego(index, nome) {
        if (dados && dados.length > 0) {
            if (dados[index] || dados[index] === 0 || dados[index] === '0') {
                return FormataMilhar(dados[index][nome]);
            }
            return '';
        }
        return '';
    }
    function proximo(nome){
        let arr = nome.split("nm_");
        let col = parseInt(arr[0]);
        let lin = parseInt(arr[1]);
        if( col >= 5){
            lin++;
            col=1;
        }else{
            col++;
        }
        setComponenteAtual(col+"nm_"+lin);
    }
    function FormataMilhar(val){
        if( val === 0 || val === '0')return val.toLocaleString('pt-br');
        if( !val) return '';
        var f = val.toLocaleString('pt-br');
        return f;
    }
    return (
        <TableContainer component={Paper} className={classes.root}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead} >Mês Referência</TableCell>
                        <TableCell className={classes.tableHead} align="right">VALOR CONTÁBIL ICMS(R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">ICMS RECOLHER(R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">VENDA SEF</TableCell>
                        <TableCell className={classes.tableHead} align="right">ICMS SEF</TableCell>
                        <TableCell className={classes.tableHead} align="right">INVESTIMENTO PRODUÇÃO</TableCell>
                        <TableCell className={classes.tableHead} align="right">RENDA EMPREGADOS</TableCell>
                        <TableCell className={classes.tableHead} align="right">EMPREGOS DIRETOS</TableCell>
                        <TableCell className={classes.tableHead} align="right">EMPREGOS INDIRETOS</TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {Meses.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{row}</TableCell>
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"1nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="valorContabilICMSSIGES" onChange={onChange} valor={apresentaValor(index, 'valorContabilICMSSIGES')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"2nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="ICMSRecolherSIGES" onChange={onChange} valor={apresentaValor(index, 'ICMSRecolherSIGES')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"3nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="vendaSEF" onChange={onChange} valor={apresentaValor(index, 'vendaSEF')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"4nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="ICMSREF" onChange={onChange} valor={apresentaValor(index, 'ICMSREF')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"5nm_"+index} index={index} disabled={disabled} row={row} calcula={calcula} name="investimentoProducao" onChange={onChange} valor={apresentaValor(index, 'investimentoProducao')} />
                            <TableMoedaCell index={index} disabled={true} row={row} calcula={calcula} name="rendaEmpregado" onChange={onChange} valor={apresentaValor(index, 'rendaEmpregado')} />
                            <TableMoedaCell index={index} disabled={true} row={row} calcula={calcula} name="empregadosDireto" onChange={onChange} valor={apresentaValorEmprego(index, 'empregadosDireto')} />
                            <TableMoedaCell index={index} disabled={true} row={row} calcula={calcula} name="empregadosIndireto" onChange={onChange} valor={apresentaValorEmprego(index, 'empregadosIndireto')} />
                        </TableRow>
                    ))}
                    <TableRow style={{ background: '#fafafa' }} className={classes.tablecell}
                    >
                        <TableCell className={classes.tablecell} style={{ color: 'black' }} component="th" scope="row" >Total/Média</TableCell>
                        <TableCell className={classes.tablecell} align="right" >{FormataMoeda(total.valorContabilICMSSIGES)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.ICMSRecolherSIGES)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.vendaSEF)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.ICMSREF)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.investimentoProducao)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.rendaEmpregado)}</TableCell>
                        
                        <TableCell className={classes.tablecell} align="right">{FormataMilhar(total.empregadosDireto)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMilhar(total.empregadosIndireto)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
