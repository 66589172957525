import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InputBase, Box, Button, Container, Collapse, FormControl, FormHelperText, Grid, IconButton, Input, InputLabel, MenuItem, Paper, 
    Select, Typography, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ConfirmDialog from '../../util/ConfirmDialog';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: 200,
    },
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  btns: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

export default function ImportaPlanilhaPanel(props) {  
  const classes = useStyles();
  const {isLote, isEmpresa}=props;

  const [espera, setEspera] = React.useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [erro, setErro] = useState();
  const [planilha, setPlanilha] = useState();
  const [sucesso, setSucesso] = useState(false);

  const upload=(e) =>{
    let file = document.getElementById("file");
    let dataArray = new FormData();
    
    dataArray.append('file', file.files[0]);
    setEspera(true);
    
    fetch('/api/upload/file', {method: "POST", body: dataArray})
    .then(res =>  res.status === 200 ?res.json():setErro(res ) )
    .then(
        (result) => {
            setEspera(false);
            if( !result) return;
          var nm = file.value;
          nm = nm.split(/(\\|\/)/g).pop();

          let pla= {
            nome: nm,
            uuid: result.uuid
        }
          
          setPlanilha(pla);
          
        },
        (error) => {
          setEspera(false);
        }
      );
}
function registraCarga(arquivo){
    setEspera(true);
    let url = '/api/processoProdf';
    if( isLote ){
      url = '/api/processoProdf/cargaLote';
    }else if( isEmpresa ){
      url = '/api/processoProdf/cargaEmpresa';
    }
    fetch(url, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(arquivo),
        credentials: 'include'
      }).then(response => {
        setEspera(false);
  
        if (response.status <= 300) {
            setSucesso(true);
        } else {
          response.json().then(erro => {
            setErro(erro);
          }).catch(() => {
            setErro(response);
          })
        }
      }).catch(error => {
            setErro(error);
            setEspera(false);
  
        });
}

  return (
    <Container className={classes.root} maxWidth="xl">      
        {
            erro && <Alert severity="error">{erro.statusText || erro.error}</Alert>
        }
        
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {isLote && <Typography component="h1" variant="h5" gutterBottom>Carga Lote</Typography>}
                {isEmpresa && <Typography component="h1" variant="h5" gutterBottom>Carga Empresa</Typography>}
                {
                  (!isEmpresa && !isLote) &&
                  <Typography component="h1" variant="h5" gutterBottom>Carga planilha DAABE</Typography>
                }
                
              </Paper>              
            </Grid>
            {!sucesso &&
                <Grid item xs={12}>
                  {isLote && 
                    <Typography variant="h6" gutterBottom>
                        Carga dos Lotes cadastrados na terracap disponiveis ao programa PRODF
                    </Typography>
                  }
                  {isEmpresa && 
                    <Typography variant="h6" gutterBottom>
                        Carga das Empreas que fazem parte do programa PRODF
                    </Typography>
                  }
                  { (!isLote && !isEmpresa) && 
                    <Typography variant="h6" gutterBottom>
                        Carga dos processos em andamento do programa PRODF  
                    </Typography>
                  }
                </Grid>
            }
            <Grid item xs={12}>
                {
                    sucesso &&
                    <Typography variant="h6" gutterBottom>
                        Planilha registrada com sucesso. Aguarde alguns instantes para processamento das informações.
                    </Typography> 
                }
                {!planilha && !sucesso &&
                    <Button variant="contained" component="label">
                        Escolha arquivo
                        <input type="file" style={{ display: "none" }} accept=".csv" onChange={upload} id="file"/>
                    </Button>
                }
                {planilha && !sucesso &&
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            {planilha.nome}
                        </Typography>
                        <center>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={() => registraCarga(planilha)}
                                
                                >
                                Confirmar carga dos dados
                            </Button>
                        </center>
                    </Box>
                }
            </Grid>
          </Grid>
                <Backdrop className={classes.backdrop} open={espera} >
            <CircularProgress color="inherit" />
        </Backdrop>

    </Container>
  )
}
