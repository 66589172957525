import React, { useEffect, useState, } from 'react';
import {
    Button, Grid, Paper, Container, FormControl, MenuItem, Select, TextField, isWidthUp
}
    from '@material-ui/core';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, FormataNumeroInteiro, FormataMoeda } from "../../../constants"
import Service from '../../../services/Service';
import GenericTable from '../../../components/template/GenericTable';
import { useStylesLocal } from '../../vistoria/VistoriaForm';
import InputLabel from '@material-ui/core/InputLabel';
import { id } from 'date-fns/locale';
import { ConnectorFixedUserHandle } from '@syncfusion/ej2-react-diagrams';
import ConfirmDialog from '../../../util/ConfirmDialog';
import EtapaProcessoComp from './EtapaProcessoComp';

export default function CadastroImovelForm(props) {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    const { setTitulo, setWait, setMensagem } = props;
    // let { id } = useParams();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [processo, setProcesso] = useState();
    const [value, setValue] = useState({});
    const [raAde, setRaAde] = useState();
    const [adeFiltro, setAdeFiltro] = useState();
    const [enderecoFiltro, setEnderecoFiltro] = useState();
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    const [showConfirmInclusao, setShowConfirmInclusao] = useState(false);
    const [loteExcluido, setLoteExcluido] = useState();

    useEffect(() => {
        setTitulo("Cadastrar Imovel");
        buscaRaAde();
        if (param.get("id")) {
            // setId(param.get("id"));
            Service.GET("/api/processoProdf/" + param.get("id")).then(result => {
                setWait(false);
                setProcesso(result);
                verificarProcessoEmpresa(result)
            }).catch(error => {
                setWait(false);
                setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
            });
        } else {
            setMensagem("Cadastre previamente os dados processo e empresa");
        }

    }, []);

    function verificarProcessoEmpresa(dado) {
        if (dado && dado.dadosEmpresa) {
            let txt = "";
            if (!dado.dadosEmpresa.cnpj || dado.dadosEmpresa.cnpj === "") {
                txt += "CNPJ não encontrado. "
            }
            if (!dado.dadosEmpresa.nProcesso || dado.dadosEmpresa.nProcesso === "") {
                txt += "Porcesso não encontrado. "
            }
            if (txt !== "") {
                setMensagem(txt + "Cadastre os compos previamente");
            }
        } else {
            setMensagem("verificando processo e empresa...");
        }

    }



    function sair() {
        // history.replace("/gerenciaProcesso/cadastrarProcesso?id=123456789abcd&panel=imovel");
        if (param.get("id")) history.replace("/gerenciaProcesso/processo/" + param.get("id") + "?panel=imovel");
        else history.replace("/gerenciaProcesso/cadastrarProcesso/?panel=imovel");
    }
    const handleActionSolicitarClick = (index, acao, row) => {
        if (acao === "Excluir") {
            setShowConfirmExclusao(true);
            setLoteExcluido(row)
            // excluirLoteProcesso(row);
        }
    }
    function buscaRaAde() {
        Service.GET("/api/info/ra_ade").then(json => {
            setWait(false);
            setRaAde(json);
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
    }

    const handleChangeInputForm = (event) => {

        if (event.target.name === 'ra') {
            let arr = raAde.find(item => item.nome === event.target.value);
            arr = arr ? arr.ade : [];
            setAdeFiltro(arr);
            setEnderecoFiltro();
            if (arr && arr.length === 1) {
                setValue({
                    'ade': arr[0],
                    [event.target.name]: event.target.value,
                });

                const dado = {
                    ra: event.target.value,
                    ade: arr[0]
                }
                buscarEndereco(dado);
            } else {
                setValue({
                    [event.target.name]: event.target.value,
                });
            }
        } else if (event.target.name === 'ade') {
            const dado = {
                ra: value.ra,
                ade: event.target.value
            }
            buscarEndereco(dado);
            setValue({
                ra: value.ra,
                [event.target.name]: event.target.value,
            });
        } else {
            setValue({
                ...value,
                [event.target.name]: event.target.value,
            });
        }
    };
    function buscarEndereco(d) {
        setWait(true);
        Service.POST('/api/processoProdf/vistoria/buscaEnderecoPorRaAde', d).then(ret => {
            let arr = [];
            for (let x in ret) {
                if (ret[x].processo === "") {
                    arr[x] = ret[x].endereco;
                } else if (ret[x].processo === null) {
                    arr[x] = ret[x].endereco;
                }
                //arr.push(ret[x].endereco);

            }
            setEnderecoFiltro(arr);
            setWait(false);
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
    }

    // function isInvalido(ret) {
    //     if (processo && processo.dadosImovel) {
    //         let propostaUm;
    //         let propostaDois;
    //         for (let x in ret) {
    //             propostaUm = ret[x].proposta
    //         }
    //         for (let y in processo.dadosImovel) {
    //             propostaDois = processo.dadosImovel[y].proposta;
    //         }
    //         if (propostaUm === propostaDois) return true;
    //     }
    // }
    function verificarIncluir() {
        if (!raAde || !adeFiltro || !enderecoFiltro) {
            setMensagem("Preencha todos os campos.");
        } else if (!param.get("id") || !processo || !processo.dadosEmpresa || !processo.dadosEmpresa.cnpj || !processo.dadosEmpresa.nProcesso) {
            setMensagem("Para incluir imóvel os campos Processos e CNPJ devem ser preenchidos previamente.")
        } else {
            setShowConfirmInclusao(true)
        }
    }
    function incluir() {
        const dado = {
            idprocessoProdf: param.get("id"),
            ra: value.ra,
            ade: value.ade,
            endereco: value.endereco
        }
        setWait(true);
        Service.POST('/api/processoProdf/incluirLote', dado).then(result => {
            setProcesso(result);
            setValue({});
            setWait(false);
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
    }

    function excluirLoteProcesso(d) {
        // const dado ={
        //     dadosImovel: d,
        //     idprocessoProdf :param.get("id")

        // }
        d.idprocessoProdf = param.get("id");
        Service.POST('/api/processoProdf/excluirLoteProcesso', d).then(result => {
            setProcesso(result);
            setWait(false);
            setLoteExcluido();
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });

        // const dado = {
        //     id: txt.idLote,

        // }
        // setWait(true);
        //return;
        // Service.POST("/api/processoProdf/lote/excluirProcessoLote/", dado).then(r => {
        //     setWait(false);


        // }).catch(error => {
        //     setWait(false);
        //     setMensagem(error);
        // });
    }

    function confirmaExclusao() {
        excluirLoteProcesso(loteExcluido);
    }
    function confirmaInclusao() {
        incluir();
    }

    function formataDigito(valor) {

        if (valor.substring(1, 2) == '.') {
            valor.replace(".", "");
            return valor;

        }
        valor = parseInt(valor)

        if (valor == null) return '';
        return valor.toLocaleString('pt-br', { minimumFractionDigits: 2 });
    }

    return (
        <Container maxWidth='md'>
            <Grid container spacing={1} >
                <Grid item xs={6} >
                    <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                        <InputLabel id="demo-simple-select-outlined-label">RA</InputLabel>
                        <Select
                            autoWidth={false}
                            fullWidth
                            labelId="demo-simple-select-outlined-label"
                            id="ra"
                            name="ra"
                            value={value.ra || ''}
                            onChange={handleChangeInputForm}
                            label="RA"
                        // inputProps={{ readOnly: (disable && disable === true) }}
                        >
                            {
                                raAde && raAde.map((item, index) => (
                                    <MenuItem key={index} value={item.nome}>{item.nome}</MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>

                {/* trazer ade */}
                <Grid item xs={6} >
                    <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                        {adeFiltro &&
                            <>
                                <InputLabel id="demo-simple-select-outlined-label">ADE</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label-ade"
                                    id="ade"
                                    name="ade"
                                    label="ADE"
                                    value={value.ade || ''}
                                    onChange={handleChangeInputForm}
                                // inputProps={{ readOnly: (disable && disable === true) }}
                                >
                                    {
                                        adeFiltro && adeFiltro.map((item, index) => (
                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </>
                        }
                        {!adeFiltro &&
                            <TextField
                                //disabled={true}
                                value={value.ade || ''}
                                fullWidth
                                label="ADE"
                                id="loet_ade_txt"
                                variant="outlined"
                            // inputProps={{ readOnly: (disable && disable === true) }}
                            />
                        }
                    </FormControl>
                </Grid>
                {/* trazer endereço */}
                <Grid item xs={8}>
                    <FormControl variant="outlined" className={classesLocal.formControl} fullWidth>
                        {enderecoFiltro &&
                            <>
                                <InputLabel id="demo-simple-select-outlined-label">Endereço</InputLabel>
                                <Select
                                    id="endereco"
                                    name="endereco"
                                    placeholder="Endereço"
                                    label="Endereço"
                                    value={value.endereco || ''}
                                    onChange={handleChangeInputForm}
                                // inputProps={{ readOnly: (disable && disable === true) }}
                                >
                                    {
                                        enderecoFiltro && enderecoFiltro.map((item, index) => (
                                            <MenuItem key={index} value={item}>{item}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </>
                        }
                        {!enderecoFiltro &&
                            <TextField
                                //disabled={true}
                                value={value.endereco || ''}
                                // fullWidth
                                label="Endereço"
                                id="lote_end_txt"
                                // aria-describedby="nova-helper-text"
                                variant="outlined"
                            // inputProps={{ readOnly: (disable && disable === true) }}
                            />
                        }
                        
                    </FormControl>
                    
                </Grid>
                <Grid item xs={4}>
                {processo && processo.dadosEmpresa.nProcesso && 
                        <EtapaProcessoComp nProcesso={processo.dadosEmpresa.nProcesso} indice={2}/> }
                   </Grid>     
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" onClick={() => verificarIncluir()} color="primary" >
                        Incluir
                    </Button>
                </Grid>
                <GenericTable
                    actionClick={handleActionSolicitarClick}
                    // handleDisableAction={desabilitaAcaoExcluirTable}
                    options={['Excluir']}
                    dados={processo && processo.dadosImovel || []}
                    disableSearch={true}
                    readOnly={true}
                    colunas={[
                        { nome: 'Número da Proposta', campo: 'proposta' },
                        { nome: 'RA', campo: 'ra' },
                        { nome: 'ADE', campo: 'ade' },
                        { nome: 'Endereço', campo: 'endereco' },
                        { nome: 'Área', campo: 'area', transformacao: formataDigito, align: "right" },
                        { nome: 'Ação', campo: 'action' }
                    ]} />
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                        Sair
                    </Button>
                    {/* <Button variant="contained" onClick={() => salvar()} color="primary" disabled={true} >
                        Salvar
                    </Button> */}
                </Grid>
            </Grid>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma excluir o endereço selecionado? Essa ação excluir automaticamente o endereço selecionado'
                exec={confirmaExclusao}
            />
            <ConfirmDialog
                open={showConfirmInclusao}
                setOpen={setShowConfirmInclusao}
                titulo={'Confirmação'}
                msg='Confirma incluir o endereço selecionado? Essa ação salva automaticamente o endereço selecionado'
                exec={confirmaInclusao}
            />
        </Container>
    );
}