import React, { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';

import MaskedInput from 'react-text-mask'
import Button from '@material-ui/core/Button';
import SolicitarAcessoDocTable from './SolicitarAcessoDocTable';
import { ValidarCNPJ } from '../../constants/';
import { ValidarCPF } from '../../constants/';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Container from '@material-ui/core/Container';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {KeyboardDatePicker} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    btns: {
      '& > *': {
          margin: theme.spacing(1)
      },
      display: 'flex'
  },
    paper: {
      padding: theme.spacing(2),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

  }),
);

function TelMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function TelMask2(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function CPFMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function CNPJMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

export default function SolicitarAcessoForm() {
  const classes = useStyles();
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [erro, setErro] = useState();
  const [editavel, setEditavel] = useState(true);
  const [solicitacao, setSolicitacao] = useState();
  const [showTel1, setShowTel1] = useState(false);
  const [values, setValues] = React.useState({
    cpf: '',
    nome: '',
    telefone: '',
    nomeMae: '',
    email: '',
    cnpj: '',
    nomeEmpresa: '',
  });
  
  React.useEffect(() => {
    if (values.telefone.trim().length > 10 && values.telefone.trim().length !== 16) {
      const el = document.getElementById("tel-mask-input");
      el.focus();
      el.select();
    }
  }, [showTel1])

  const [documentos, setDocumentos] = useState(
    [
      { nome: 'Contrato Social', doc: '' },
      { nome: 'Cartão CNPJ', doc: '' },
      { nome: 'Procuração', doc: '' },
      { nome: 'Documento de Identificação', doc: '' },
    ]
  );

  const [selectedDate, setSelectedDate] = React.useState(null);

  const [expanded, setExpanded] = React.useState({
    pn1: true,
    pn2: true
  });
  
  const [error, setError] = React.useState(resetErro());

  const handleChange = (panel) => (event, isExpanded) => {
    let pn = { ...expanded };
    pn[panel] = isExpanded;
    setExpanded(pn);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setValues({
      ...values,
      'dataNascimento': date,
    });
    let e = resetErro();
        if( date >= new Date() ){
            e.dt = {
                erro: true,
                msg: "Data de nascimento não pode ser maior do que a atual"
            }
        }
        setError(e);
  };

  const handleTelLostFocus = () => {
    setShowTel1(values.telefone.trim().length !== 16);
  }
  const handleTelFocus = () => {
    setShowTel1(false);
  }

  const handleChangeInputForm = (event) => {

    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleLostFocusCPF = (e) => {
    setError({
      ...error,
      cpf: {
        erro: false,
        msg: ''
      }
    });
    const cpf = values.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();

    if (cpf.length === 0) return;

    if (ValidarCPF(cpf)) {
      // TODO buscar ou não CPF
      setOpenBackdrop(true);
      fetch("/api/v1/cpf/" + cpf)
        .then(res => {
          if (res.status <= 300) return res.json()
        })
        .then(
          (result) => {
            setOpenBackdrop(false);
            setError(resetErro);
            if (!result) {
              setEditavel(true);
              setValues({
                ...values, nome: ''
              });
            } else {
              setValues({
                ...values,
                nome: result.name,
                nomeMae: result.nomeMae,
                dataNascimento: new Date(result.dataNascimento),
                email: result.email,
                telefone: result.telefone
              });
              setSelectedDate(new Date(result.dataNascimento));
              setEditavel(false);
              const el = document.getElementById("cnpj-mask-input");
              el.focus();
              el.select();

            }
          },
          (error) => {

            setOpenBackdrop(false);
          }
        )
    } else {
      setError({
        ...error,
        cpf: {
          erro: true,
          msg: 'CPF inválido'
        }
      });
    }
  }
  const handleLostFocusCNPJ = (e) => {

    setError({
      ...error,
      cnpj: {
        erro: false,
        msg: ''
      }
    });
    const cnpj = values.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
    if (cnpj.length === 0) return;

    if (ValidarCNPJ(cnpj)) {
      setOpenBackdrop(true);

      // fetch("https://receitaws.com.br/v1/cnpj/"+cnpj)
      fetch("/api/v1/cnpj/" + cnpj)
        .then(res => (res.status <= 300) ? res.json() : setErro(res))
        .then(
          (result) => {
            setOpenBackdrop(false);
            if (!result) return;
            setValues({
              ...values, nomeEmpresa: result.razaoSocial
            });
          },
          (e) => {
            setOpenBackdrop(false);
            if( e.message.includes("not in cache")){
              // console.log(">>>>>>error error error:", error.message);
              setErro({message:"Favor informar um CNPJ cadastrado na Receita Federal"})
              setError({
                ...error,
                cnpj: {
                  erro: true,
                  msg: 'CNPJ inválido'
                }
              });
            }
            
          }
        )
    } else {
      setError({
        ...error,
        cnpj: {
          erro: true,
          msg: 'CNPJ inválido'
        }
      });
    }
  }

  function resetErro() {
    return {
      nome: {
        erro: false,
        msg: ''
      },
      cnpj: {
        erro: false,
        msg: ''
      },
      cpf: {
        erro: false,
        msg: ''
      },
      telefone: {
        erro: false,
        msg: ''
      },
      email: {
        erro: false,
        msg: ''
      },
      dt: {
        erro: false,
        msg: ''
      },
      nomeMae: {
        erro: false,
        msg: ''
      },
    }
  }
  function validaAnexos(){
    let ok= true;
    let txt = '';
    if(documentos[0].doc.length === 0 ){
      ok =false;
      txt = "O anexo "+documentos[0].nome+" é obrigatório!";
    }
    if(documentos[1].doc.length === 0 ){
      ok =false;
      txt += !ok?"\n":"";
      txt += "O anexo "+documentos[1].nome+" é obrigatório!";
    }
    if(documentos[3].doc.length === 0 ){
      ok =false;
      txt += !ok?"\n":"";
      txt += "O anexo "+documentos[3].nome+" é obrigatório!";
    }
    if( !ok ){
      setErro({
        statusText:txt
      })
    }else{
      setErro(null);
    }
    return ok;
  }
  const handleLostFocusDate=(ev)=>{
    validaData(selectedDate);
  }
  function validaData(data){
    let ok = true;
    let e = resetErro();
    if( data){
      if( data.toString() === 'Invalid Date'){
          ok = false;
          e.dt = {
              erro: true,
              msg: "Data inválida"
          }
          setSelectedDate(null);
      }else if( data >= new Date() ){
          e.dt = {
              erro: true,
              msg: "Data de nascimento não pode ser maior do que a atual"
          }
          ok = false;
      }
    }
    setError(e);
    return ok;
}
function validateEmail(mail) {
 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return true;
  }
  return false;
}
  function validarSubmit(dados) {
    let ok = true;

    let e = resetErro();
    const cpf = values.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
    if( !validaAnexos() ){
      ok = false;
    }

    if (!ValidarCPF(cpf)) {
      e.cpf = {
        erro: true,
        msg: "CPF inválido"
      }
      ok = false;
    }
    if (!dados.solicitante.nome) {
      e.nome = {
        erro: true,
        msg: "Nome é obritatório"
      }
      ok = false;
    }
    if (!dados.solicitante.telefone) {
      e.telefone = {
        erro: true,
        msg: "Telefone é obritatório"
      }
      ok = false;
    }
    if (!dados.solicitante.email) {
      e.email = {
        erro: true,
        msg: "Email é obritatório"
      }
      ok = false;
    }else if (!validateEmail(dados.solicitante.email) ) {
      e.email = {
        erro: true,
        msg: "Email inválido"
      }
      ok = false;
    }
    if (!dados.solicitante.dataNascimento) {
      e.dt = {
        erro: true,
        msg: "Data de nascimento é obritatório"
      }
      ok = false;
    }
    if (!dados.empresa.cnpj) {
      e.cnpj = {
        erro: true,
        msg: "CNPJ é obritatório"
      }
      ok = false;
    }
    if (!dados.empresa.nomeEmpresa) {
      e.cnpj = {
        erro: true,
        msg: "CNPJ é obritatório"
      }
      ok = false;
    }
    if (!dados.solicitante.nomeMae) {
      e.nomeMae = {
        erro: true,
        msg: "Nome da Mãe é obritatório"
      }
      ok = false;
    }
    console.log(dados)

    setError({...error, ...e});

    return ok;
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    let docs = [];
    documentos.map(d => docs.push({
      nome: d.nome,
      arquivo: d.doc,
      uuid: d.uuid
    }));

    const dados = {
      solicitante: {
        nome: values.nome,
        cpf: values.cpf,
        email: values.email,
        dataNascimento: values.dataNascimento ? values.dataNascimento.getTime() : null,
        nomeMae: values.nomeMae,
        telefone: values.telefone
      },
      empresa: {
        nomeEmpresa: values.nomeEmpresa,
        cnpj: values.cnpj
      },
      documentos: docs
    }

    if (!validarSubmit(dados)) {
      return
    }

    setOpenBackdrop(true);
    fetch('/api/acesso/solicitar', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(dados),
      credentials: 'include'
    }).then(response => {
      setOpenBackdrop(false);

      if (response.status <= 300) {
        return response.json();
      } else {
        response.json().then(erro => {
          
          setErro(erro);
        }).catch(() => {
          setErro(response);
        })
      }
    }).then(res => setSolicitacao(res))
      .catch(error => {
        setOpenBackdrop(false);

      });
  }

  function SoclcitacaoOkInfo(props) {
    const { info } = props;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>
              Solicitação realizada com sucesso.
          </Typography>
            <Typography variant="body1" gutterBottom>
            Será encaminhado um email com demais informações!! <a href={"/login/"}>Voltar para tela de login</a>,
          </Typography>
          </Paper>
        </Grid>
      </Grid>
    )
  }

  return (
    <Container maxWidth="md">
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>

        {
          solicitacao ? <SoclcitacaoOkInfo info={solicitacao} /> :
            <form className={classes.root} noValidate autoComplete="off" onSubmit={handleSubmit}>
              <div className={classes.root}>
                {
                  erro && <Alert severity="error">{erro.statusText ? erro.statusText: erro.message}</Alert>
                }
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Paper className={classes.paper}> 
                    <Typography component="h1" variant="h5" gutterBottom><center>Solicitação de acesso</center></Typography>                     
                      <Accordion expanded={expanded.pn1} onChange={handleChange('pn1')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography className={classes.heading}>Dados pessoais</Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <TextField
                                required
                                autoFocus
                                label="CPF"
                                value={values.cpf}
                                onChange={handleChangeInputForm}
                                onBlur={handleLostFocusCPF}
                                error={error.cpf.erro}
                                helperText={error.cpf.msg}
                                name="cpf"
                                id="cpf-mask-input"
                                fullWidth
                                InputProps={{
                                  inputComponent: CPFMask,
                                }}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                required
                                fullWidth
                                label="Nome"
                                value={values.nome}
                                onChange={handleChangeInputForm}
                                error={error.nome.erro}
                                helperText={error.nome.msg}
                                name="nome"
                                id="nome-input"
                                InputProps={{
                                  readOnly: !editavel,
                                }}
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <KeyboardDatePicker
                                required
                                disableToolbar
                                error={error.dt.erro}
                                helperText={error.dt.msg}
                                variant="inline"
                                id="dataNascimento"
                                label="Data Nascimento"
                                format="dd/MM/yyyy"
                                fullWidth
                                onBlur={handleLostFocusDate}
                                InputProps={{
                                  readOnly: !editavel,
                                }}
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                inputVariant="outlined"
                                invalidDateMessage="Formato inválido"
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                required
                                fullWidth
                                label="Nome da Mãe"
                                value={values.nomeMae}
                                onChange={handleChangeInputForm}
                                error={error.nomeMae.erro}
                                helperText={error.nomeMae.msg}
                                InputProps={{
                                  readOnly: !editavel,
                                }}
                                name="nomeMae"
                                id="nome-Mae"
                                variant="outlined"
                              />
                            </Grid>

                            <Grid item xs={4}>
                              <TextField
                                required
                                error={error.telefone.erro}
                                helperText={error.telefone.msg}
                                label="Telefone para contato"
                                value={values.telefone}
                                onChange={handleChangeInputForm}
                                onBlur={handleTelLostFocus}
                                onFocus={handleTelFocus}
                                style={{ display: showTel1 ? 'none' : 'block' }}
                                name="telefone"
                                id="tel-mask-input"
                                fullWidth
                                InputProps={{
                                  inputComponent: TelMask,
                                }}
                                variant="outlined"
                              />
                              <TextField
                                required
                                error={error.telefone.erro}
                                helperText={error.telefone.msg}
                                label="Telefone para contato"
                                value={values.telefone}
                                onChange={handleChangeInputForm}
                                onFocus={handleTelFocus}
                                style={{ display: showTel1 ? 'block' : 'none' }}
                                name="telefone"
                                id="tel-mask-input1"
                                fullWidth
                                InputProps={{
                                  inputComponent: TelMask2,
                                }}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                required
                                error={error.email.erro}
                                helperText={error.email.msg}
                                fullWidth
                                label="Email para contato"
                                value={values.email}
                                onChange={handleChangeInputForm}
                                InputProps={{
                                  readOnly: !editavel,
                                }}
                                name="email"
                                id="email-input"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>


                        </AccordionDetails>
                      </Accordion>

                      <Accordion expanded={expanded.pn2} onChange={handleChange('pn2')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2bh-content"
                          id="panel2bh-header"
                        >
                          <Typography className={classes.heading}>Dados da empresa</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <TextField
                                required
                                error={error.cnpj.erro}
                                helperText={error.cnpj.msg}
                                label="CNPJ"
                                value={values.textmask}
                                onChange={handleChangeInputForm}
                                onBlur={handleLostFocusCNPJ}
                                name="cnpj"
                                id="cnpj-mask-input"
                                InputProps={{
                                  inputComponent: CNPJMask
                                }}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                fullWidth
                                label="Razão social"
                                value={values.nomeEmpresa || ''}
                                onChange={handleChangeInputForm}
                                InputProps={{
                                  readOnly: true,
                                }}
                                name="nomeEmpresa"
                                id="nome-empresa-input"
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>

                      <SolicitarAcessoDocTable rows={documentos} setRows={setDocumentos} erro={erro} setErro={setErro} editavel={true} setEspera={setOpenBackdrop}/>


                    </Paper>
                  </Grid>

                </Grid>
              </div>
              <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/login"} rel="noopener noreferrer">
                    Sair
                </Button>
                  <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Solicitar
                </Button>
              </Grid>
              
              <Paper />
              <Backdrop className={classes.backdrop} open={openBackdrop} >
                <CircularProgress color="inherit" />
              </Backdrop>
            </form>
        }
      </MuiPickersUtilsProvider>
    </Container>
  );
}
