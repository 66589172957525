import React from 'react';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Tooltip from '@material-ui/core/Tooltip';
import { API_BASE_URL } from '../../constants';
import {useStyles} from "../../constants";

const StyledTableCell = withStyles((theme) =>
    createStyles({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.blue,
        },
        body: {
            fontSize: 14,
        },
    }),
)(TableCell);
const StyledTableRow = withStyles((theme) =>
    createStyles({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }),
)(TableRow);

// const useStyles = makeStyles({
//     table: {
//         minWidth: 700,
//     },
//     boxMargin: {
//         marginLeft: 30
//     }
// });
let currentRow=-1;

export default function SolicitarAcessoDocTable(props) {
    const classes = useStyles();
    const {rows, setRows, setErro, editavel, setEspera} = props;

    function fileClick(){
        let file = document.getElementById("file");
        file.click();
    }
    const handleAnexo=(e, index) =>{
        
        currentRow=index;

        fileClick();
    }
    const handleRemoveAnexo=(e, index) =>{
        let itens = [...rows];
        if( itens[index].arquivo) rows[index].arquivo = null;
        if( itens[index].doc) rows[index].doc = null;

        setRows(itens);
    }
    function validaAnexo(anexo){
        if( !anexo.name.toLocaleLowerCase().endsWith('pdf')){
            setErro({
                message:"Extensão inválida. Por favor informar uma anexo com extensão .pdf"
            })
            return false;
        }
        if( (anexo.size / 1048576).toFixed(2) > 5 ){
            setErro({
                message:"Inválido. Por favor informar anexo menor do que 5 mb"
            })
            return false;
        }
        return true;
    }
    const upload=(e) =>{
        let file = document.getElementById("file");
        let dataArray = new FormData();
        if( !validaAnexo(file.files[0] )){
            return;
        }

        dataArray.append('file', file.files[0]);
        setEspera(true);
        
        fetch('/api/upload/file', {method: "POST", body: dataArray})
        .then(res =>  res.status === 200 ?res.json():setErro(res ) )
        .then(
            (result) => {
                
                setEspera(false);
                if( !result) return;
              var nm = file.value;
              nm = nm.split(/(\\|\/)/g).pop();

              let itens = [...rows];
              itens[ currentRow ].doc = nm;
              itens[ currentRow ].uuid = result.uuid;
              setRows(itens);

              
            },
            (error) => {
                setErro( error )
              setEspera(false);
            }
          );
    }
    function Download(prods){
        const {doc} = prods;
        const handleUpload = (e)=>{

            var a = document.createElement("a");
            a.href = API_BASE_URL+"/api/dowload/fileSystem/"+doc.uuid+"-"+doc.arquivo;
            // a.setAttribute("download", doc.arquivo);
            a.setAttribute("target", "_blank");
            a.click();

        }
        return(
            <Box className={classes.boxMargin}>    
            {
                doc.arquivo.length > 0 &&
                    <IconButton onClick={ (e) => handleUpload() }>
                        <CloudDownloadIcon />
                    </IconButton>
            }
            </Box>
        )
    }
    return (
        <div>
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell width='200px'>Tipo documento</TableCell>
                        <TableCell>Documento</TableCell>
                        <TableCell width='100px' align="center">Ação</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, i) => (
                        <StyledTableRow key={i}>
                            <StyledTableCell component="th" scope="row">
                                {row.nome}
                            </StyledTableCell>
                            <StyledTableCell >{row.doc?row.doc:row.arquivo}</StyledTableCell>
                            <TableCell align="center">

                            {
                                        editavel && editavel=== true?
                                    <Box>    
                                        {
                                            (row.arquivo || row.doc) ?
                                            <Tooltip title="Remover documento">
                                                <IconButton onClick={ (e) => handleRemoveAnexo(e,i) }>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Incluir documento">
                                                <IconButton onClick={ (e) => handleAnexo(e,i) }>
                                                    <AttachFileIcon />
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </Box>
                                        :
                                        <Download doc={row}/>
                            }
                                

                            </TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
            
        </TableContainer>
        <input type="file" id="file" style={{display:'none'}} onChange={upload} accept="application/pdf"/>
        
        </div>
    );
}
