import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import MaskedInput from 'react-text-mask'
import BuscaDadoDialog from "./BuscaDadoDialog";
import {
    TextField
  } from '@material-ui/core';
  
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
function protocoloMask(props) {
    const { inputRef, ...other } = props;
    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        placeholderChar={'\u2000'}
        showMask
      />
    );
  }
  
export default function ProtocoloField(props) {
  const classes = useStyles();
  const {handleChangeInputForm, valor, nome, acao, cnpj, setWait, fieldReadOnly} = props
  const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
  const [processos, setProcessos] = useState([]);
  
  function handleSelecionarProtocolo(protocolo){
    if( acao ){
        acao(protocolo);
    }
  }
  function handleLocalizaProtocolo() {
    setWait(true);
    const mask = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")

    fetch("/api/processoProdf/requerimento/todosByCnpj",
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify({
                cnpjs: [mask]
            }),
        }).then(res => res.json()).then((result) => {
            setWait(false);
            if (result.status && result.status > 300) {
                // setMensagem(result);
            } else {
              const arr = result.filter(r => r.status === 'DEFERIDO');
                setProcessos(arr);

                setShowBuscaDadoDialog(true);
            }
        });
  }
  return (
    <div className={classes.root}>
      <TextField
                    label={nome?nome:"Protocolo"}
                    value={valor}
                    onChange={handleChangeInputForm}
                    name="protocolo"
                    id="processo-mask-input"
                    fullWidth
                    InputProps={{
                      inputComponent: protocoloMask,
                      readOnly: (fieldReadOnly && fieldReadOnly === true)
                    }}
                    variant="outlined"
                  />
      <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleLocalizaProtocolo}>
        <SearchIcon />
      </IconButton>
      <BuscaDadoDialog
            open={showBuscaDadoDialog}
            setOpen={setShowBuscaDadoDialog}
            titulo={"Protocolo"}
            acao={(p) => handleSelecionarProtocolo(p)}
            colunas={[
                { nome: 'Protocolo', campo: 'protocolo' },
                { nome: 'Tipo', campo: 'assunto' },
                { nome: 'Descricao', campo: 'descricao' },
            ]}
            dados={processos} />
 
    </div>
  );
}
