import React, { useEffect, useState } from 'react';
import {
    Grid, Button, FormControlLabel, Typography, RadioGroup, Radio, Paper, makeStyles,
} from '@material-ui/core';
import { useStyles, FormataData } from "../../../constants";
import { useLocation } from "react-router-dom";
import Switch from '@material-ui/core/Switch';
import GenericTransferList from '../../template/GenericTransferList';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

const useStylesLocal = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontWeight: theme.typography.fontWeightBold, //.fontWeightRegular,
        margin: theme.spacing(2, 0),
    },
}));
export default function RelatorioVistoria(props) {
    const classes = useStyles();
    const classesLocal = useStylesLocal();
    const { setTitulo, setWait, setMensagem, setErro } = props;
    const location = useLocation();
    // const [raAde, setRaAde] = useState();
    const [agrupador, setAgrupador] = React.useState(null);
    const [dataInicio, setDataInicio] = useState();
    const [dataFim, setDataFim] = useState();
    const [error, setError] = useState(resetErro());
    const [vistoria, setVistoria] = useState();

    const [raDisponiveis, setRaDisponiveis] = React.useState([]);
    const [raSelecionadas, setRaSelecionadas] = React.useState([]);

    const [adeDisponiveis, setAdeDisponiveis] = React.useState([]);
    const [adeSelecionadas, setAdeSelecionadas] = React.useState([]);

    const [assuntoDisponiveis, setAssuntoDisponiveis] = React.useState([]);
    const [assuntoSelecionadas, setAssuntoSelecionadas] = React.useState([]);

    const [situacaoDisponiveis, setSituacaoDisponiveis] = React.useState([]);
    const [situacaoSelecionadas, setSituacaoSelecionadas] = React.useState([]);

    const [vistoriadorDisponiveis, setVistoriadorDisponiveis] = React.useState([]);
    const [vistoriadorSelecionadas, setVistoriadorSelecionadas] = React.useState([]);

    const [areaDisponiveis, setAreaDisponiveis] = React.useState([]);
    const [areaSelecionadas, setAreaSelecionadas] = React.useState([]);

    useEffect(() => {
        atualizaStatus();
        if (location.pathname.includes("relatorioListVistoria")) {
            setTitulo("Listagem de Vistorias");
        } else if (location.pathname.includes("relatorioVistoriaPorVistoriador")) {
            setTitulo("Vistorias por vistoriadores - Relatório");
        } else if (location.pathname.includes("relatorioVistoriaQuantidade")) {
            setTitulo("Quantidades de vistorias - Relatório");
        } else if (location.pathname.includes("relatorioVistoriaSituacaoAtual")) {
            setTitulo("Vistorias por situação Atual - Relatório");
        } else if (location.pathname.includes("relatorioVistoriaSituacao")) {
            setTitulo("Vistorias por situação - Relatório");
        } else if (location.pathname.includes("relatorioVistoriaTempo")) {
            setTitulo("Tempo médio - Relatório");
        } else {
            setTitulo("Titulo Não implementado");
        }

    }, [location.pathname]);

    function resetErro() {
        return {
            data: { erro: false, msg: '' },
        }
    }
    function atualizaStatus() {
        setDataInicio();
        setDataFim();
        setRaDisponiveis([]);
        setRaSelecionadas([]);
        setAdeSelecionadas([]);
        setAdeDisponiveis([]);
        setAssuntoDisponiveis([]);
        setAssuntoSelecionadas([]);
        setSituacaoDisponiveis([]);
        setSituacaoSelecionadas([]);
        setVistoriadorDisponiveis([]);
        setVistoriadorSelecionadas([]);
        setAreaDisponiveis([]);
        setAreaSelecionadas([]);
        buscaVistoria();
    }

    function formataData(dt) {
        if (!dt) return "";
        return FormataData(new Date(dt));
    }

    function ordenarArrString(arr) {
        return arr.sort((a, b) => (a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? -1 : a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? 1 : 0));
    }

    function buscaVistoria() {
        setWait(true);
        fetch("/api/processoProdf/vistoria/completo").then(response => {
            if (response.ok) {
                response.json().then((json) => {
                    let arrAssontos = [];
                    let arrSituacao = [];
                    let arrArea = [];
                    // let arrRAeADE = [];
                    let arrRA = [];
                    let arrADE = [];
                    let arrVistoriador = [];
                    for (let x in json) {
                        const v = json[x];
                        for (let y in v.lote) {
                            const l = v.lote[y];
                            // if (!arrRAeADE.find(e => e.ra === l.ra && e.ade === l.ade)) {
                            //     arrRAeADE.push({
                            //         ra: l.ra,
                            //         ade: l.ade
                            //     });
                            // }
                            if (!arrRA.find(e => e === l.ra)) {
                                if(l.ra=== "")continue;
                                arrRA.push(l.ra);
                            }
                            if (!arrADE.find(e => e === l.ade)) {
                                arrADE.push(l.ade);
                            }
                        }
                        if (v.agenda && v.agenda.vistoriadores) {
                            for (let vsr in v.agenda.vistoriadores) {
                                const vistoriador = v.agenda.vistoriadores[vsr]
                                if (!arrVistoriador.find(e => e === vistoriador.user.nome)) arrVistoriador.push(vistoriador.user.nome);
                            }
                        }

                        if (!arrAssontos.find(e => e === v.assunto)) {
                            arrAssontos.push(v.assunto)
                        }
                        if (!arrSituacao.find(e => e === v.situacao)) {
                            arrSituacao.push(v.situacao)
                        }
                        if (!arrArea.find(e => e === v.area)) {
                            arrArea.push(v.area)
                        }
                    }
                    // arrRA.sort((a, b) => (a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? -1 : a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? 1 : 0));
                    // arrADE.sort((a, b) => (a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? -1 : a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? 1 : 0));
                    setVistoria(json);
                    setRaDisponiveis(ordenarArrString(arrRA));
                    setAdeDisponiveis(ordenarArrString(arrADE));
                    // setRaAde(arrRAeADE);
                    setAssuntoDisponiveis(ordenarArrString(arrAssontos));
                    setSituacaoDisponiveis(ordenarArrString(formatarSituacaoArr(arrSituacao)));
                    
                    setAreaDisponiveis(ordenarArrString(arrArea));
                    setVistoriadorDisponiveis(ordenarArrString(arrVistoriador));
                    setWait(false);
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    const handleDateChangedataInicio = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (date > dataFim && dataFim !== null) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        setDataInicio(date);
        const dt = formataData(dataInicio);
        if (date && dataFim && date <= dataFim && date !== null && dataFim !== null) filtroPorPeriodo(date, dataFim);
    }

    const handleDateChangedataFim = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (date < dataInicio && dataInicio !== null) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        setDataFim(date);
        if (date && dataInicio && date >= dataInicio && date !== null && dataInicio !== null) filtroPorPeriodo(dataInicio, date);
    }

    function filtroPorPeriodo(dataI, dataF) {
        dataI = new Date(dataI.setHours(0, 0, 0, 0));
        dataF = new Date(dataF.setHours(23, 59, 59, 999));
        let arrAssontos = [];
        let arrSituacao = [];
        let arrArea = [];
        // let arrRAeADE = [];
        let arrRA = [];
        let arrADE = [];
        let arrVistoriador = [];
        for (let x in vistoria) {
            const v = vistoria[x];
            if ((location.pathname.includes("relatorioVistoriaSituacao") || location.pathname.includes("relatorioVistoriaTempo"))) {
                if (v.historico && v.historico.find(h => new Date(h.data) > dataI && new Date(h.data) < dataF)) {
                    for (let y in v.lote) {
                        const l = v.lote[y];
                        // if (!arrRAeADE.find(e => e.ra === l.ra && e.ade === l.ade)) {
                        //     arrRAeADE.push({
                        //         ra: l.ra,
                        //         ade: l.ade
                        //     });
                        // }
                        if (!arrRA.find(e => e === l.ra)) {
                            arrRA.push(l.ra);
                        }
                        if (!arrADE.find(e => e === l.ade)) {
                            arrADE.push(l.ade);
                        }
                    }
                    if (!arrAssontos.find(e => e === v.assunto)) {
                        arrAssontos.push(v.assunto)
                    }
                    if (!arrSituacao.find(e => e === v.situacao)) {
                        arrSituacao.push(v.situacao)
                    }
                    if (!arrArea.find(e => e === v.area)) {
                        arrArea.push(v.area)
                    }
                }
            } else if (v.agenda && new Date(v.agenda.data) > dataI && new Date(v.agenda.data) < dataF) {
                const dataAgenda = new Date(v.agenda.data);
                if (dataAgenda > dataI && dataAgenda < dataF) {
                    for (let y in v.lote) {
                        const l = v.lote[y];
                        // if (!arrRAeADE.find(e => e.ra === l.ra && e.ade === l.ade)) {
                        //     arrRAeADE.push({
                        //         ra: l.ra,
                        //         ade: l.ade
                        //     });
                        // }
                        if (!arrRA.find(e => e === l.ra)) {
                            arrRA.push(l.ra);
                        }
                        if (!arrADE.find(e => e === l.ade)) {
                            arrADE.push(l.ade);
                        }
                    }
                    if (v.agenda && v.agenda.vistoriadores) {
                        for (let vsr in v.agenda.vistoriadores) {
                            const vistoriador = v.agenda.vistoriadores[vsr]
                            if (!arrVistoriador.find(e => e === vistoriador.user.nome)) arrVistoriador.push(vistoriador.user.nome);
                        }
                    }
                    if (!arrAssontos.find(e => e === v.assunto)) {
                        arrAssontos.push(v.assunto)
                    }
                    if (!arrSituacao.find(e => e === v.situacao)) {
                        arrSituacao.push(v.situacao)
                    }
                    if (!arrArea.find(e => e === v.area)) {
                        arrArea.push(v.area)
                    }
                }
            }
        }
        // arrRA.sort((a, b) => (a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? -1 : a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? 1 : 0));
        // arrADE.sort((a, b) => (a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") < b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? -1 : a.normalize('NFD').replace(/[\u0300-\u036f]/g, "") > b.normalize('NFD').replace(/[\u0300-\u036f]/g, "") ? 1 : 0));
        setRaDisponiveis(ordenarArrString(arrRA));
        setAdeDisponiveis(ordenarArrString(arrADE));
        // setRaAde(arrRAeADE);
        setAssuntoDisponiveis(ordenarArrString(arrAssontos));
        setSituacaoDisponiveis(ordenarArrString(formatarSituacaoArr(arrSituacao)));
        setAreaDisponiveis(ordenarArrString(arrArea));
        setVistoriadorDisponiveis(ordenarArrString(arrVistoriador));
        setRaSelecionadas([]);
        setAdeSelecionadas([]);
        setAssuntoSelecionadas([]);
        setSituacaoSelecionadas([]);
        setVistoriadorSelecionadas([])
    }

    const handleCliciGerarRelatorio = () => { //  relatorioListagemFrom relatorioListagemFrom
        if (location.pathname.includes("relatorioListVistoria")) {
            relatorioListVistoria();
        } else if (location.pathname.includes("relatorioVistoriaPorVistoriador")) {
            relatorioVistoriaPorVistoriador();
        } else if (location.pathname.includes("relatorioVistoriaQuantidade")) {
            relatorioVistoriaQuantidade();
        } else if (location.pathname.includes("relatorioVistoriaSituacaoAtual")) {
            relatorioVistoriaSituacaoAtual();
        } else if (location.pathname.includes("relatorioVistoriaSituacao")) {
            relatorioVistoriaSituacao();
        } else if (location.pathname.includes("relatorioVistoriaTempo")) {
            relatorioVistoriaTempo();
        } else {
            setMensagem("pagina não desenvolvida")
        }
    }

    function relatorioListVistoria() {
        const dados = {
            id: gerarIdVistoriaSelecionada(),
            agrupador: agrupador,
            raSelecionadas: raSelecionadas.length > 0 ? raSelecionadas : raDisponiveis,
            adeSelecionadas: adeSelecionadas.length > 0 ? adeSelecionadas : adeDisponiveis,
            assuntoSelecionadas: assuntoSelecionadas.length > 0 ? assuntoSelecionadas : assuntoDisponiveis,
            situacaoSelecionadas: situacaoSelecionadas.length > 0 ? situacaoSelecionadas : situacaoDisponiveis,
            vistoriadorSelecionadas: vistoriadorSelecionadas.length > 0 ? vistoriadorSelecionadas : vistoriadorDisponiveis,
            areaSelecionadas: areaSelecionadas.length > 0 ? areaSelecionadas : areaDisponiveis,
            dataInicio: formataData(dataInicio),
            dataFim: formataData(dataFim)
        }
        fetch("/api/processoProdf/vistoria/listagemVistoria.pdf", {
            method: "POST",
            body: JSON.stringify(dados),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    function relatorioVistoriaPorVistoriador() {
        const dados = {
            id: gerarIdVistoriaSelecionada(),
            agrupador: agrupador,
            raSelecionadas: raSelecionadas.length > 0 ? raSelecionadas : raDisponiveis,
            adeSelecionadas: adeSelecionadas.length > 0 ? adeSelecionadas : adeDisponiveis,
            assuntoSelecionadas: assuntoSelecionadas.length > 0 ? assuntoSelecionadas : assuntoDisponiveis,
            situacaoSelecionadas: situacaoSelecionadas.length > 0 ? situacaoSelecionadas : situacaoDisponiveis,
            vistoriadorSelecionadas: vistoriadorSelecionadas.length > 0 ? vistoriadorSelecionadas : vistoriadorDisponiveis,
            areaSelecionadas: areaSelecionadas.length > 0 ? areaSelecionadas : areaDisponiveis,
            dataInicio: formataData(dataInicio),
            dataFim: formataData(dataFim)
        }


        fetch("/api/processoProdf/vistoria/vistoriaPorVistoriador.pdf", {
            method: "POST",
            body: JSON.stringify(dados),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    function relatorioVistoriaQuantidade() {
        const dados = {
            id: gerarIdVistoriaSelecionada(),
            agrupador: agrupador,
            raSelecionadas: raSelecionadas.length > 0 ? raSelecionadas : raDisponiveis,
            adeSelecionadas: adeSelecionadas.length > 0 ? adeSelecionadas : adeDisponiveis,
            assuntoSelecionadas: assuntoSelecionadas.length > 0 ? assuntoSelecionadas : assuntoDisponiveis,
            situacaoSelecionadas: situacaoSelecionadas.length > 0 ? situacaoSelecionadas : situacaoDisponiveis,
            vistoriadorSelecionadas: vistoriadorSelecionadas.length > 0 ? vistoriadorSelecionadas : vistoriadorDisponiveis,
            areaSelecionadas: areaSelecionadas.length > 0 ? areaSelecionadas : areaDisponiveis,
            dataInicio: formataData(dataInicio),
            dataFim: formataData(dataFim)
        }

        fetch("/api/processoProdf/vistoria/quantidadeVistoria.pdf", {
            method: "POST",
            body: JSON.stringify(dados),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    function relatorioVistoriaSituacaoAtual() {
        const dados = {
            id: gerarIdVistoriaSelecionada(),
            agrupador: agrupador,
            raSelecionadas: raSelecionadas,
            adeSelecionadas: adeSelecionadas,
            assuntoSelecionadas: assuntoSelecionadas,
            situacaoSelecionadas: situacaoSelecionadas,
            vistoriadorSelecionadas: vistoriadorSelecionadas,
            areaSelecionadas: areaSelecionadas
        }
    }


    function relatorioVistoriaSituacao() {
        const dados = {
            id: gerarIdVistoriaSelecionada(),
            agrupador: "grupoADE",
            raSelecionadas: raSelecionadas.length > 0 ? raSelecionadas : raDisponiveis,
            adeSelecionadas: adeSelecionadas.length > 0 ? adeSelecionadas : adeDisponiveis,
            assuntoSelecionadas: assuntoSelecionadas.length > 0 ? assuntoSelecionadas : assuntoDisponiveis,
            situacaoSelecionadas: situacaoSelecionadas.length > 0 ? situacaoSelecionadas : situacaoDisponiveis,
            vistoriadorSelecionadas: vistoriadorSelecionadas.length > 0 ? vistoriadorSelecionadas : vistoriadorDisponiveis,
            areaSelecionadas: areaSelecionadas.length > 0 ? areaSelecionadas : areaDisponiveis,
            dataInicio: formataData(dataInicio),
            dataFim: formataData(dataFim)
        }

        fetch("/api/processoProdf/vistoria/vistoriaSituacao.pdf", {
            method: "POST",
            body: JSON.stringify(dados),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    function relatorioVistoriaTempo() {
        const dados = {
            id: gerarIdVistoriaSelecionada(),
            raSelecionadas: raSelecionadas.length > 0 ? raSelecionadas : raDisponiveis,
            adeSelecionadas: adeSelecionadas.length > 0 ? adeSelecionadas : adeDisponiveis,
            assuntoSelecionadas: assuntoSelecionadas.length > 0 ? assuntoSelecionadas : assuntoDisponiveis,
            situacaoSelecionadas: situacaoSelecionadas.length > 0 ? situacaoSelecionadas : situacaoDisponiveis,
            vistoriadorSelecionadas: vistoriadorSelecionadas.length > 0 ? vistoriadorSelecionadas : vistoriadorDisponiveis,
            areaSelecionadas: areaSelecionadas.length > 0 ? areaSelecionadas : areaDisponiveis,
            dataInicio: formataData(dataInicio),
            dataFim: formataData(dataFim)
        }
        fetch("/api/processoProdf/vistoria/relatorioVistoriaTempoMedio.pdf", {
            method: "POST",
            body: JSON.stringify(dados),
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }

    function gerarIdVistoriaSelecionada() {
        let arr = [];
        let dtI = dataInicio ? new Date(dataInicio.setHours(0, 0, 0, 0)) : false;
        let dtF = dataFim ? new Date(dataFim.setHours(23, 59, 59, 999)) : false;
        root: for (let x in vistoria) {
            const v = vistoria[x];
            if ((location.pathname.includes("relatorioVistoriaSituacao") || location.pathname.includes("relatorioVistoriaTempo"))) {
                if ((dtI || dtF) && !(v.historico && v.historico.find(h => new Date(h.data) > dtI && new Date(h.data) < dtF))) {
                    continue root;
                }
            } else if ((dtI || dtF) && !(v.agenda && new Date(v.agenda.data) >= dtI && new Date(v.agenda.data) <= dtF)) {
                continue root;
            }
            for (let i in v.lote) {
                if (raSelecionadas.length > 0 && !raSelecionadas.find(r => r === v.lote[i].ra)) continue root;
                if (adeSelecionadas.length > 0 && !adeSelecionadas.find(r => r === v.lote[i].ade)) continue root;
            }
            if (assuntoSelecionadas.length > 0 && !assuntoSelecionadas.find(a => a === v.assunto)) continue root;
            if (situacaoSelecionadas.length > 0 && !situacaoSelecionadas.find(a => a === deParaSituacao(v.situacao))) continue root;
            if (vistoriadorSelecionadas.length > 0 && v.agenda && v.agenda.vistoriadores) {
                let notOK = true;
                for (let vsr in v.agenda.vistoriadores) {
                    const vistoriador = v.agenda.vistoriadores[vsr]
                    if (vistoriadorSelecionadas.find(e => e === vistoriador.user.nome)) notOK = false;

                }
                if (notOK) continue root;
            }
            if (areaSelecionadas.length > 0 && !areaSelecionadas.find(a => a === v.area)) continue root;
            arr.push(v.id);
        }
        return arr
    }

    const handleChange = (event) => {
        setAgrupador(event.target.value);
    };

    const handleChangeDesmacar = (e) => {
        if (e.target.value === agrupador) setAgrupador(null);
    }
    function formatarSituacaoArr(arr) {
        let arrN = [];
        for (let x in arr) {
            arrN.push(deParaSituacao(arr[x]));
        }
        return arrN;
    }
    function deParaSituacao(st) {
        if (st === "AGENDADO") {
            return "Agendado"
        } else if (st === "EM_EXECUCAO") {
            return "Em execução"
        } else if (st === "EXECUTADO") {
            return "Executada"
        } else if (st === "VALIDADA_GERENTE") {
            return "Validada Gerente"
        } else if (st === "VALIDADO_DIRETOR") {
            return "Validada Diretor"
        } else if (st === "CANCELADO") {
            return "Cancelada"
        } else if (st === "SUSPENSO") {
            return "Suspensa"
        } else if (st === "AGUARDANDO_AJUSTE") {
            return "Aguardando ajuste solicitante"
        } else if (st === "AGUARDANDO_AJUSTE_GERENTE") {
            return "Aguardando ajuste gerente"
        } else if (st === "AGUARDANDO_AJUSTE_VISTORIADOR") {
            return "Aguardando ajuste vistoriador"
        } else if (st === "NAO_INFORMADO") {
            return "Solicitada"
        } else if (st === "AJUSTE_VISTORIADOR") {
            return "Aguardando ajuste vistoriador"
        } else if (st === "VALIDACAO_GERENTE") {
            return "Validada Gerente"
        }
        return "?";
    }

    function mudaFiltroRaSelecionada(ra) {
        let dtI = dataInicio ? new Date(dataInicio.setHours(0, 0, 0, 0)) : false;
        let dtF = dataFim ? new Date(dataFim.setHours(23, 59, 59, 999)) : false;
        let adeD = [];
        let adeSele = [];
        let assuntoDis = [];
        let assuntoSele = [];
        let situD = [];
        let situS = [];
        let visdorD = [];
        let visdorS = [];
        let areaD = [];
        let areaS = [];
        root: for (let x in vistoria) {
            const v = vistoria[x];
            if ((location.pathname.includes("relatorioVistoriaSituacao") || location.pathname.includes("relatorioVistoriaTempo"))) {
                if ((dtI || dtF) && !(v.historico && v.historico.find(h => new Date(h.data) > dtI && new Date(h.data) < dtF))) {
                    continue root;
                }
            } else if ((dtI || dtF) && !(v.agenda && new Date(v.agenda.data) >= dtI && new Date(v.agenda.data) <= dtF)) {
                continue root;
            }
            let raTest = false;
            for (let y in v.lote) {
                const l = v.lote[y];
                if (ra.length === 0 && !adeD.find(e => e === l.ade)) {
                    adeD.push(l.ade);
                } else if ((!adeD.find(e => e === l.ade) && !adeSele.find(e => e === l.ade)) && ra.find(a => a === l.ra)) {
                    if (adeSelecionadas.find(e => e === l.ade)) adeSele.push(l.ade);
                    else adeD.push(l.ade);
                }
                if (ra.find(a => a === l.ra)) {
                    raTest = true;
                }
            }
            if (ra.length === 0) {
                if (!assuntoDis.find(a => a === v.assunto) && !assuntoSele.find(a => a === v.assunto)) {
                    if (assuntoSelecionadas.find(e => e === v.assunto)) assuntoSele.push(v.assunto);
                    else assuntoDis.push(v.assunto);
                }
                if (!situD.find(s => s === deParaSituacao(v.situacao)) && !situS.find(s => s === deParaSituacao(v.situacao))) {
                    if (situacaoSelecionadas.find(s => s === deParaSituacao(v.situacao))) situS.push(deParaSituacao(v.situacao));
                    else situD.push(deParaSituacao(v.situacao));
                }
                if (v.agenda && v.agenda.vistoriadores) {
                    for (let vsr in v.agenda.vistoriadores) {
                        const vistoriador = v.agenda.vistoriadores[vsr]
                        // if (!arrVistoriador.find(e => e === vistoriador.user.nome)) arrVistoriador.push(vistoriador.user.nome);
                        if (!visdorD.find(d => d === vistoriador.user.nome) && !visdorS.find(s => s === vistoriador.user.nome)) {
                            if (vistoriadorSelecionadas.find(v => v === vistoriador.user.nome)) visdorS.push(vistoriador.user.nome);
                            else visdorD.push(vistoriador.user.nome);
                        }
                    }
                }
                if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                    if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                    else areaD.push(v.area);
                }
            } else if (raTest) {
                if ((!assuntoDis.find(a => a === v.assunto) && !assuntoSele.find(a => a === v.assunto))) {
                    if (assuntoSelecionadas.find(e => e === v.assunto)) assuntoSele.push(v.assunto);
                    else assuntoDis.push(v.assunto);
                }
                if (!situD.find(s => s === deParaSituacao(v.situacao)) && !situS.find(s => s === deParaSituacao(v.situacao))) {
                    if (situacaoSelecionadas.find(s => s === deParaSituacao(v.situacao))) situS.push(deParaSituacao(v.situacao));
                    else situD.push(deParaSituacao(v.situacao));
                }
                if (v.agenda && v.agenda.vistoriadores) {
                    for (let vsr in v.agenda.vistoriadores) {
                        const vistoriador = v.agenda.vistoriadores[vsr]
                        // if (!arrVistoriador.find(e => e === vistoriador.user.nome)) arrVistoriador.push(vistoriador.user.nome);
                        if (!visdorD.find(d => d === vistoriador.user.nome) && !visdorS.find(s => s === vistoriador.user.nome)) {
                            if (vistoriadorSelecionadas.find(v => v === vistoriador.user.nome)) visdorS.push(vistoriador.user.nome);
                            else visdorD.push(vistoriador.user.nome);
                        }
                    }
                }
                if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                    if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                    else areaD.push(v.area);
                }
            }
        }
        setAdeDisponiveis(ordenarArrString(adeD));
        setAdeSelecionadas(ordenarArrString(adeSele));
        setAssuntoDisponiveis(ordenarArrString(assuntoDis));
        setAssuntoSelecionadas(ordenarArrString(assuntoSele));
        setSituacaoDisponiveis(ordenarArrString(situD));
        setVistoriadorSelecionadas(ordenarArrString(visdorS));
        setAreaDisponiveis(ordenarArrString(areaD));
        setAreaSelecionadas(ordenarArrString(areaS));
    }
    function mudaFiltroAdeSelecionado(ade) {
        let dtI = dataInicio ? new Date(dataInicio.setHours(0, 0, 0, 0)) : false;
        let dtF = dataFim ? new Date(dataFim.setHours(23, 59, 59, 999)) : false;
        let assuntoDis = [];
        let assuntoSele = [];
        let situD = [];
        let situS = [];
        let visdorD = [];
        let visdorS = [];
        let areaD = [];
        let areaS = [];
        root: for (let x in vistoria) {
            const v = vistoria[x];
            if ((location.pathname.includes("relatorioVistoriaSituacao") || location.pathname.includes("relatorioVistoriaTempo"))) {
                if ((dtI || dtF) && !(v.historico && v.historico.find(h => new Date(h.data) > dtI && new Date(h.data) < dtF))) {
                    continue root;
                }
            } else if ((dtI || dtF) && !(v.agenda && new Date(v.agenda.data) >= dtI && new Date(v.agenda.data) <= dtF)) {
                continue root;
            }
            for (let y in v.lote) {
                if (raSelecionadas.length > 0 && !raSelecionadas.find(r => r === v.lote[y].ra)) continue root;
                const l = v.lote[y];
                if (ade.find(a => a === l.ade)) {
                    if ((!assuntoDis.find(a => a === v.assunto) && !assuntoSele.find(a => a === v.assunto))) {
                        if (assuntoSelecionadas.find(e => e === v.assunto)) assuntoSele.push(v.assunto);
                        else assuntoDis.push(v.assunto);
                    }
                    if (!situD.find(s => s === deParaSituacao(v.situacao)) && !situS.find(s => s === deParaSituacao(v.situacao))) {
                        if (situacaoSelecionadas.find(s => s === deParaSituacao(v.situacao))) situS.push(deParaSituacao(v.situacao));
                        else situD.push(deParaSituacao(v.situacao));
                    }
                    if (v.agenda && v.agenda.vistoriadores) {
                        for (let vsr in v.agenda.vistoriadores) {
                            const vistoriador = v.agenda.vistoriadores[vsr]
                            // if (!arrVistoriador.find(e => e === vistoriador.user.nome)) arrVistoriador.push(vistoriador.user.nome);
                            if (!visdorD.find(d => d === vistoriador.user.nome) && !visdorS.find(s => s === vistoriador.user.nome)) {
                                if (vistoriadorSelecionadas.find(v => v === vistoriador.user.nome)) visdorS.push(vistoriador.user.nome);
                                else visdorD.push(vistoriador.user.nome);
                            }
                        }
                    }
                    if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                        if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                        else areaD.push(v.area);
                    }
                }
            }
            // ------------------------------------
            if (ade.length === 0) {
                if ((!assuntoDis.find(a => a === v.assunto) && !assuntoSele.find(a => a === v.assunto))) {
                    if (assuntoSelecionadas.find(e => e === v.assunto)) assuntoSele.push(v.assunto);
                    else assuntoDis.push(v.assunto);
                }
                if (!situD.find(s => s === deParaSituacao(v.situacao)) && !situS.find(s => s === deParaSituacao(v.situacao))) {
                    if (situacaoSelecionadas.find(s => s === deParaSituacao(v.situacao))) situS.push(deParaSituacao(v.situacao));
                    else situD.push(deParaSituacao(v.situacao));
                }
                if (v.agenda && v.agenda.vistoriadores) {
                    for (let vsr in v.agenda.vistoriadores) {
                        const vistoriador = v.agenda.vistoriadores[vsr]
                        // if (!arrVistoriador.find(e => e === vistoriador.user.nome)) arrVistoriador.push(vistoriador.user.nome);
                        if (!visdorD.find(d => d === vistoriador.user.nome) && !visdorS.find(s => s === vistoriador.user.nome)) {
                            if (vistoriadorSelecionadas.find(v => v === vistoriador.user.nome)) visdorS.push(vistoriador.user.nome);
                            else visdorD.push(vistoriador.user.nome);
                        }
                    }
                }
                if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                    if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                    else areaD.push(v.area);
                }
            }

        }
        setAssuntoDisponiveis(ordenarArrString(assuntoDis));
        setAssuntoSelecionadas(ordenarArrString(assuntoSele));
        setSituacaoDisponiveis(ordenarArrString(situD));
        setSituacaoSelecionadas(ordenarArrString(situS));
        setVistoriadorDisponiveis(ordenarArrString(visdorD));
        setVistoriadorSelecionadas(ordenarArrString(visdorS));
        setAreaDisponiveis(ordenarArrString(areaD));
        setAreaSelecionadas(ordenarArrString(areaS));
    }
    function mudaFiltroAssuntoSeleciodado(assun) {
        let dtI = dataInicio ? new Date(dataInicio.setHours(0, 0, 0, 0)) : false;
        let dtF = dataFim ? new Date(dataFim.setHours(23, 59, 59, 999)) : false;
        let situD = [];
        let situS = [];
        let visdorD = [];
        let visdorS = [];
        let areaD = [];
        let areaS = [];
        root: for (let x in vistoria) {
            const v = vistoria[x];
            if ((location.pathname.includes("relatorioVistoriaSituacao") || location.pathname.includes("relatorioVistoriaTempo"))) {
                if ((dtI || dtF) && !(v.historico && v.historico.find(h => new Date(h.data) > dtI && new Date(h.data) < dtF))) {
                    continue root;
                }
            } else if ((dtI || dtF) && !(v.agenda && new Date(v.agenda.data) >= dtI && new Date(v.agenda.data) <= dtF)) {
                continue root;
            }
            for (let i in v.lote) {
                if (raSelecionadas.length > 0 && !raSelecionadas.find(r => r === v.lote[i].ra)) continue root;
                if (adeSelecionadas.length > 0 && !adeSelecionadas.find(r => r === v.lote[i].ade)) continue root;
            }
            // ------------------------------------
            if (assun.length === 0) {
                if (!situD.find(s => s === deParaSituacao(v.situacao)) && !situS.find(s => s === deParaSituacao(v.situacao))) {
                    if (situacaoSelecionadas.find(s => s === deParaSituacao(v.situacao))) situS.push(deParaSituacao(v.situacao));
                    else situD.push(deParaSituacao(v.situacao));
                }
                if (v.agenda && v.agenda.vistoriadores) {
                    for (let vsr in v.agenda.vistoriadores) {
                        const vistoriador = v.agenda.vistoriadores[vsr]
                        // if (!arrVistoriador.find(e => e === vistoriador.user.nome)) arrVistoriador.push(vistoriador.user.nome);
                        if (!visdorD.find(d => d === vistoriador.user.nome) && !visdorS.find(s => s === vistoriador.user.nome)) {
                            if (vistoriadorSelecionadas.find(v => v === vistoriador.user.nome)) visdorS.push(vistoriador.user.nome);
                            else visdorD.push(vistoriador.user.nome);
                        }
                    }
                }
                if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                    if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                    else areaD.push(v.area);
                }
            } else if (assun.find(a => a === v.assunto)) {
                if (!situD.find(s => s === deParaSituacao(v.situacao)) && !situS.find(s => s === deParaSituacao(v.situacao))) {
                    if (situacaoSelecionadas.find(s => s === deParaSituacao(v.situacao))) situS.push(deParaSituacao(v.situacao));
                    else situD.push(deParaSituacao(v.situacao));
                }
                if (v.agenda && v.agenda.vistoriadores) {
                    for (let vsr in v.agenda.vistoriadores) {
                        const vistoriador = v.agenda.vistoriadores[vsr]
                        // if (!arrVistoriador.find(e => e === vistoriador.user.nome)) arrVistoriador.push(vistoriador.user.nome);
                        if (!visdorD.find(d => d === vistoriador.user.nome) && !visdorS.find(s => s === vistoriador.user.nome)) {
                            if (vistoriadorSelecionadas.find(v => v === vistoriador.user.nome)) visdorS.push(vistoriador.user.nome);
                            else visdorD.push(vistoriador.user.nome);
                        }
                    }
                }
                if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                    if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                    else areaD.push(v.area);
                }
            }
        }
        setSituacaoDisponiveis(ordenarArrString(situD));
        setSituacaoSelecionadas(ordenarArrString(situS));
        setVistoriadorDisponiveis(ordenarArrString(visdorD));
        setVistoriadorSelecionadas(ordenarArrString(visdorS));
        setAreaDisponiveis(ordenarArrString(areaD));
        setAreaSelecionadas(ordenarArrString(areaS));
    }
    function mudaFiltroSituacaoSeleciodado(situS) {
        let dtI = dataInicio ? new Date(dataInicio.setHours(0, 0, 0, 0)) : false;
        let dtF = dataFim ? new Date(dataFim.setHours(23, 59, 59, 999)) : false;
        let visdorD = [];
        let visdorS = [];
        let areaD = [];
        let areaS = [];
        root: for (let x in vistoria) {
            const v = vistoria[x];
            if ((location.pathname.includes("relatorioVistoriaSituacao") || location.pathname.includes("relatorioVistoriaTempo"))) {
                if ((dtI || dtF) && !(v.historico && v.historico.find(h => new Date(h.data) > dtI && new Date(h.data) < dtF))) {
                    continue root;
                }
            } else if ((dtI || dtF) && !(v.agenda && new Date(v.agenda.data) >= dtI && new Date(v.agenda.data) <= dtF)) {
                continue root;
            }
            for (let i in v.lote) {
                if (raSelecionadas.length > 0 && !raSelecionadas.find(r => r === v.lote[i].ra)) continue root;
                if (adeSelecionadas.length > 0 && !adeSelecionadas.find(r => r === v.lote[i].ade)) continue root;
            }
            if (assuntoSelecionadas.length > 0 && !assuntoSelecionadas.find(a => a === v.assunto)) continue root;
            // ------------------------------------
            if (situS.length === 0) {
                if (v.agenda && v.agenda.vistoriadores) {
                    for (let vsr in v.agenda.vistoriadores) {
                        const vistoriador = v.agenda.vistoriadores[vsr]
                        if (!visdorD.find(d => d === vistoriador.user.nome) && !visdorS.find(s => s === vistoriador.user.nome)) {
                            if (vistoriadorSelecionadas.find(v => v === vistoriador.user.nome)) visdorS.push(vistoriador.user.nome);
                            else visdorD.push(vistoriador.user.nome);
                        }
                    }
                }
                if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                    if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                    else areaD.push(v.area);
                }
            } else if (situS.find(a => a === deParaSituacao(v.situacao))) {
                if (v.agenda && v.agenda.vistoriadores) {
                    for (let vsr in v.agenda.vistoriadores) {
                        const vistoriador = v.agenda.vistoriadores[vsr]
                        if (!visdorD.find(d => d === vistoriador.user.nome) && !visdorS.find(s => s === vistoriador.user.nome)) {
                            if (vistoriadorSelecionadas.find(v => v === vistoriador.user.nome)) visdorS.push(vistoriador.user.nome);
                            else visdorD.push(vistoriador.user.nome);
                        }
                    }
                }
                if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                    if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                    else areaD.push(v.area);
                }
            }
        }
        setVistoriadorDisponiveis(ordenarArrString(visdorD));
        setVistoriadorSelecionadas(ordenarArrString(visdorS));
        setAreaDisponiveis(ordenarArrString(areaD));
        setAreaSelecionadas(ordenarArrString(areaS));
    }
    function mudaFiltroVistoriadorSeleciodado(visdorS) {
        let dtI = dataInicio ? new Date(dataInicio.setHours(0, 0, 0, 0)) : false;
        let dtF = dataFim ? new Date(dataFim.setHours(23, 59, 59, 999)) : false;
        let areaD = [];
        let areaS = [];
        root: for (let x in vistoria) {
            const v = vistoria[x];
            if ((location.pathname.includes("relatorioVistoriaSituacao") || location.pathname.includes("relatorioVistoriaTempo"))) {
                if ((dtI || dtF) && !(v.historico && v.historico.find(h => new Date(h.data) > dtI && new Date(h.data) < dtF))) {
                    continue root;
                }
            } else if ((dtI || dtF) && !(v.agenda && new Date(v.agenda.data) >= dtI && new Date(v.agenda.data) <= dtF)) {
                continue root;
            }
            for (let i in v.lote) {
                if (raSelecionadas.length > 0 && !raSelecionadas.find(r => r === v.lote[i].ra)) continue root;
                if (adeSelecionadas.length > 0 && !adeSelecionadas.find(r => r === v.lote[i].ade)) continue root;
            }
            if (assuntoSelecionadas.length > 0 && !assuntoSelecionadas.find(a => a === v.assunto)) continue root;
            if (situacaoSelecionadas.length > 0 && !situacaoSelecionadas.find(a => a === deParaSituacao(v.situacao))) continue root;
            // ------------------------------------
            if (visdorS.length === 0) {
                if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                    if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                    else areaD.push(v.area);
                }
            } else if (v.agenda && v.agenda.vistoriadores) {
                for (let vsr in v.agenda.vistoriadores) {
                    const vistdor = v.agenda.vistoriadores[vsr];
                    if (visdorS.find(e => e === vistdor.user.nome)) {
                        if (!areaD.find(d => d === v.area) && !areaS.find(s => s === v.area)) {
                            if (areaSelecionadas.find(a => a === v.area)) areaS.push(v.area);
                            else areaD.push(v.area);
                        }
                    }
                }
            }
        }
        setAreaDisponiveis(ordenarArrString(areaD));
        setAreaSelecionadas(ordenarArrString(areaS));
    }
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Paper>
                <Grid container spacing={2} className={classes.root} >
                    {!location.pathname.includes("relatorioVistoriaSituacaoAtual") &&
                        <>
                            <Grid item >
                                <Typography className={classesLocal.heading} gutterBottom>Período:</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <KeyboardDatePicker
                                    // disabled={disable}
                                    disableToolbar
                                    variant="inline"
                                    id="dataInicio"
                                    // label="Período"
                                    format="dd/MM/yyyy"
                                    value={dataInicio || null} onChange={handleDateChangedataInicio}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                />
                            </Grid>
                            <Grid item >
                                <Typography className={classesLocal.heading} gutterBottom>a</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <KeyboardDatePicker
                                    error={error.data.erro}
                                    helperText={error.data.msg}
                                    // disabled={disable}
                                    disableToolbar
                                    variant="inline"
                                    id="dataFim"
                                    // label="a"
                                    format="dd/MM/yyyy"
                                    value={dataFim || null} onChange={handleDateChangedataFim}
                                    fullWidth
                                    KeyboardButtonProps={true}
                                    inputVariant="outlined"
                                    invalidDateMessage="Formato inválido"
                                />
                            </Grid>
                            <Grid item xs={6}></Grid>
                        </>
                    }
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Filtro RA"} tituloleft={"RAs disponíveis:"} tituloright={"RAs selecionadas:"}
                            left={raDisponiveis} setLeft={setRaDisponiveis}
                            right={raSelecionadas} setRight={setRaSelecionadas}
                            handleAll={mudaFiltroRaSelecionada}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Filtro ADE"} tituloleft={"ADEs disponíveis:"} tituloright={"ADEs selecionadas:"}
                            left={adeDisponiveis} setLeft={setAdeDisponiveis}
                            right={adeSelecionadas} setRight={setAdeSelecionadas}
                            handleAll={mudaFiltroAdeSelecionado}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Assunto"} tituloleft={"Disponíveis:"} tituloright={"Selecionados:"}
                            left={assuntoDisponiveis} setLeft={setAssuntoDisponiveis}
                            right={assuntoSelecionadas} setRight={setAssuntoSelecionadas}
                            handleAll={mudaFiltroAssuntoSeleciodado}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Situação"} tituloleft={"Disponíveis:"} tituloright={"Selecionados:"}
                            left={situacaoDisponiveis} setLeft={setSituacaoDisponiveis}
                            right={situacaoSelecionadas} setRight={setSituacaoSelecionadas}
                            handleAll={mudaFiltroSituacaoSeleciodado}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Vistoriador"} tituloleft={"Disponíveis:"} tituloright={"Selecionados:"}
                            left={vistoriadorDisponiveis} setLeft={setVistoriadorDisponiveis}
                            right={vistoriadorSelecionadas} setRight={setVistoriadorSelecionadas}
                            handleAll={mudaFiltroVistoriadorSeleciodado}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Área solicitante"} tituloleft={"Disponíveis:"} tituloright={"Selecionados:"}
                            left={areaDisponiveis} setLeft={setAreaDisponiveis}
                            right={areaSelecionadas} setRight={setAreaSelecionadas}
                        />
                    </Grid>
                    {location.pathname.includes("relatorioListVistoria") &&
                        <Grid item xs={6}>
                            <Typography className={classes.heading} gutterBottom>Agrupadores:</Typography>
                            <RadioGroup aria-label="gender" name="gender1" value={agrupador} onChange={handleChange}>
                                <FormControlLabel
                                    value="ra" control={<Radio />} label="RA" onClick={handleChangeDesmacar}
                                />
                                <FormControlLabel
                                    value="assunto" control={<Radio />} label="Assunto" onClick={handleChangeDesmacar}
                                />
                                <FormControlLabel
                                    value="situacao" control={<Radio />} label="Situação" onClick={handleChangeDesmacar}
                                />
                                <FormControlLabel
                                    value="areaSolicitante" control={<Radio />} label="Área Solicitante" onClick={handleChangeDesmacar}
                                />
                            </RadioGroup>
                        </Grid>
                    }
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                            Sair
                        </Button>
                        <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={handleCliciGerarRelatorio}>
                            Gerar Relatório
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </MuiPickersUtilsProvider>
    );
}