
import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { Typography, Paper, Grid, Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { useCurrentUser } from "../../../server/UseCurrentUser";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import ConfirmDialog from '../../../util/ConfirmDialog';
import { theme, useStyles } from "../../../constants";
import { useHistory } from "react-router-dom";

function AnaliseVinculo() {
    return (
        <div>
            <Typography variant="h6" gutterBottom>
                Sua solicitação de vinculo foi encaminhada para analise. Assim que a solicitação de vinculo for autorizada, receberá um e-mail e suas permissões serão liberados.
            </Typography>
        </div>
    )
}
export default function SolicitarVinculoPanel() {
    const classes = useStyles();
    const [erro, setErro] = useState();
    const [user, loading] = useCurrentUser();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [perfis, setPerfis] = useState();
    const [value, setValue] = React.useState('');
    const [showConfirm, setShowConfirm] = useState(false);
    const [showResultado, setShowResultado] = useState(false);
    const history = useHistory();


    const handleChange = (event) => {
        setValue((event.target).value);
    };

    React.useEffect(() => {
        async function fetchData() {
            setOpenBackdrop(true);
            const response = await fetch('/api/perfil');
            const dado = await response.json();
            if (dado.status > 300) {
                setErro(dado);
                return;
            } else {
                setPerfis(dado);
            }
        }
        if (!perfis) {
            fetchData();
        }
        setOpenBackdrop(false);
    });

    React.useEffect(() => {
        if (user && user.perfis) {
            setValue(user.perfis[0]);
        }
    }, [user]);

    function showInforPerfilUser() {
        if (user && user.perfis) {
            return "Seu perfil atual: " + user.perfis;
        } else {
            return "Ainda não possue perfil associado";
        }
    }

    function showPerfil(perfil) {
        return perfil.nome + ((perfil.descricao) ? " - " + perfil.descricao : "");
    }
    function confirmaSolicitacao() {
        setOpenBackdrop(true);
        const body = {
            solicitante: user,
            perfil: value
        }
        fetch('/api/perfil/solicitar', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(body),
            credentials: 'include'
        }).then(response => {
            setOpenBackdrop(false);
            if (response.status <= 300) {
                //history.replace(`/home`)
                setShowResultado(true);
            } else {
                setErro(response);
            }
        })
            .catch(error => {
                setOpenBackdrop(false);
            });
    }
    function jaTemPerfil(perfil) {
        let tem = false;
        user.perfis && user.perfis.forEach(p => {
            if (p === perfil.nome) {
                tem = true;
                return true;
            }
        });
        return tem;
    }
    return (
        <Container maxWidth="md" className={classes.root}>
            <ThemeProvider theme={theme}>

                {
                    showResultado && <AnaliseVinculo />
                }
                {
                    !showResultado &&



                    <Grid container spacing={3}>
                        {
                            erro && <Alert severity="error">{erro.statusText || erro.error}</Alert>
                        }
                        <Grid item xs={12}>
                            <Paper className={classes.paper}>
                                <Typography variant="h6" gutterBottom>
                                    Solicitação De Vínculo
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>
                                {showInforPerfilUser()}
                            </Typography>
                        </Grid>


                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Escolha um perfil</FormLabel>
                                <RadioGroup aria-label="nome" name="nome1" value={value} onChange={handleChange}>
                                    {
                                        perfis && perfis.map((perfil, index) =>
                                            <FormControlLabel key={index} value={perfil.nome} control={<Radio />} label={showPerfil(perfil)} disabled={jaTemPerfil(perfil)} />
                                        )
                                    }
                                </RadioGroup>
                            </FormControl>

                        </Grid>
                    </Grid>
                }


                {
                   showResultado || (value && value.length > 0) &&
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} style={{ marginLeft: "rigth" }}>
                            Solicitar vinculo {value.nome}
                        </Button>
                    </Grid>
                }
                <ConfirmDialog
                    open={showConfirm}
                    setOpen={setShowConfirm}
                    titulo={'Confirmação'}
                    msg='Confirma solicitação de vinculo ao perfil selecionado?'
                    exec={confirmaSolicitacao}
                />
                <Backdrop className={classes.backdrop} open={openBackdrop || loading} >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </ThemeProvider>
        </Container >
    );
}
