import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, Container
} from '@material-ui/core';
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useUnidades } from "../../../server/UseUnidades";
import { useHistory, useLocation } from "react-router-dom";
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles, getQueryParams, ACCESS_TOKEN } from "../../../constants";
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Service from '../../../services/Service';

export const useStylesLocal = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '180px',
        overflow: 'auto'
    }
}));

export default function UnidadeForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, disable } = props;

    const location = useLocation();
    const param = getQueryParams(useLocation().search);
    const history = useHistory();
    const [usuario] = useCurrentUser();
    const [unidades] = useUnidades();
    const [unidade, setUnidade] = useState();
    
    const [unidadePai, setUnidadePai]= useState(null);

    useEffect(() => {
        const id = param.get("id");
        if (location.pathname.includes("novaUnidade")) {
            setTitulo("Cadastro de unidade")
        }else if (location.pathname.includes("excluirUnidade")) {
            setTitulo("Excluir de unidade");
        }else if (location.pathname.includes("editarUnidade")) {
            setTitulo("Alterar de unidade")
        }
        buscaUnidade(id);
    }, []);

    useEffect(() => {       
        if( unidade && unidades && unidadePai === null){
            if (location.pathname.includes("editarUnidade")) {
                unidades.forEach(u => {
                    if( u.id === unidade.pai){
                        setUnidadePai(u);
                        return;
                    }
                });
            }else if (location.pathname.includes("novaUnidade")) {
                const item = unidades.find( u => u.id === unidade.id);
                setUnidadePai(item);
            }

            if (location.pathname.includes("excluirUnidade")) {
                let temFilho = false;
                unidades.forEach(u=>{
                    if( u.id !== unidade.id){
                        if( unidade.id === u.pai){
                            temFilho = true;
                            return;
                        }
                    }
                });
                if( temFilho){
                    setMensagem({
                        mensagem: "Ao confirmar toda sub unidade será excluida",
                        tipo:'warning'
                    });
    
                }
            }
        }
    }, [unidade]);

    function buscaUnidade(id){
        fetch('/api/unidade/'+id)
        .then(response => response.json())
        .then(json => {
            console.log( json )
            if (location.pathname.includes("novaUnidade")) {
                json.nome= '';
            }
            setUnidade(json)
        })
        .catch(erro => setMensagem(erro));
    }
    
    function submitForm(dado) {
        setWait(true);
        Service.POST('/api/unidade', dado).then(resp =>{
            if (param.get("id")) {
                setMensagem("Unidade alterada com sucesso");
            } else {
                setMensagem("Unidade cadastrada com sucesso");
            }
            atualizaCacheUnidade();
        }).catch(error =>{
            setWait(false);
            setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
        });   
    }
    function atualizaCacheUnidade(){
        fetch('/api/unidade')
        .then(response => response.json())
        .then(json => {
            setWait(false);
            localStorage.setItem(ACCESS_TOKEN+"_UNIDADES", JSON.stringify(json) );
            sair();
            window.location.reload(false);
        })
        .catch(erro => console.error(erro));
    }

    function sair() {
        history.replace("/unidade/view?reload=true");
    }
    function salvar(){
        if (location.pathname.includes("novaUnidade")) {
            unidade.id= null;
            unidade.pai = unidadePai.id;
        }
        if( !unidade.id || unidade.id === null) unidade.id = unidades.length+1;
        submitForm( unidade );
    }
    function excluir(){
        fetch('/api/unidade/'+unidade.id, {
            method: 'DELETE'
        })
        .then(response => {
            if (response.ok) {
                setWait(false);
                setMensagem("Unidade removida com sucesso");
                sair();
                window.location.reload(false);
            } else {
                setMensagem('Oops! Something went wrong. Please try again!');
            }
        })
        
        .catch(erro => setMensagem(erro));
    }

    const handleChangeInputForm = (event) => {
        setUnidade({
            ...unidade,
            [event.target.name]: event.target.value,
          });
    }

    return (
        <Container maxWidth='lg'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={12}>
                    <TextField
                        label="Unidade superior"
                        value={(unidadePai && unidadePai.nome) || ''}
                        name="pai"
                        id="pai"
                        fullWidth
                        disabled={true}
                        variant="outlined"
                    />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            autoFocus
                            label="Nome"
                            value={(unidade && unidade.nome)|| ''}
                            onChange={handleChangeInputForm}
                            name="nome"
                            id="nome"
                            fullWidth
                            InputProps={{
                                readOnly: (disable && disable === true)
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Descrição"
                            value={(unidade && unidade.descricao)|| ''}
                            onChange={handleChangeInputForm}
                            name="descricao"
                            id="descricao"
                            fullWidth
                            multiline
                            rows={6}
                            InputProps={{
                                readOnly: (disable && disable === true)
                            }}
                            variant="outlined"
                        />
                    </Grid>
                </Grid>
                { !location.pathname.includes("excluirUnidade") && 
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" href={"/unidade/view"} rel="noopener noreferrer">
                        Sair
                    </Button>
                    {!disable &&
                        <Button variant="contained" color="primary" onClick={() => salvar()} >
                            Salvar
                        </Button>
                    }
                </Grid>
                }
                { location.pathname.includes("excluirUnidade") && 
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" href={"/unidade/view"} rel="noopener noreferrer">
                        Sair
                    </Button>    
                    <Button variant="contained" color="primary" onClick={() => excluir()} >
                        Confirma exclusão
                    </Button>
                </Grid>
                }
            </Paper>

        </Container>
    );
}
