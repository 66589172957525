import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import { Meses, FormataMoeda } from "../../../constants";
import TableMoedaCell from "./TableMoedaCell";

const useStylesLocal = makeStyles(theme => ({
    input: {
        width: 80,
        height: 30
    },
    table: {
        minWidth: 700
    },
    tablecell: {
        fontSize: 14,
        fontWeight: 700
    }
}));


export default function FruicaoTable(props) {
    const classes = useStylesLocal();
    const [componenteAtual, setComponenteAtual] = useState();
    const { dados, setDados, calcula, disabled, dadoApoio, perDescontoBeneficio } = props;
    const [total, setTotal] = useState({});

    useEffect(() => {
        let teste = dadoApoio ? (dadoApoio.txIcms/100) : 0;
        
        if (dados) {
            let arr = [...dados];
            for (let x in dados) {
                if (!dados[x]) continue;
                if (dadoApoio && teste) {
                    if (arr[x].recolhimentoFundoProd) {
                        arr[x].ICMSProprioEmpreendidoS = arr[x].recolhimentoFundoProd / teste;
                    }
                }
                if (perDescontoBeneficio) {
                    if (!dados[x]) continue;
                    if (arr[x].ICMSProprioEmpreendidoS) {
                        arr[x].ICMSProprioEmpreendidoC = arr[x].ICMSProprioEmpreendidoS * (1 - (perDescontoBeneficio/100));
                        arr[x].creditoPresumidoICMS = arr[x].ICMSProprioEmpreendidoS * (perDescontoBeneficio/100);
                    }
                }
                arr[x].recolhimentoTotalFundos = arr[x].recolhimentoFundoProd + arr[x].recolhimentoFundoDesenv;
            }
            setDados(arr);
        }
    }, [perDescontoBeneficio])

    useEffect(() => {
        if (dados && dados.length > 0) {

            let totrecolhimentoFundoProd = 0;
            let totrecolhimentoFundoDesenv = 0;
            let totICMSProprioEmpreendidoS = 0;
            let totcreditoPresumidoICMS = 0;
            let totICMSProprioEmpreendidoC = 0;
            let totRecolhimentoTotalFundos = 0;
            for (let x in dados) {
                if (!dados[x]) continue;
                totrecolhimentoFundoProd += dados[x].recolhimentoFundoProd ? dados[x].recolhimentoFundoProd : 0;
                totrecolhimentoFundoDesenv += dados[x].recolhimentoFundoDesenv ? dados[x].recolhimentoFundoDesenv : 0;
                totICMSProprioEmpreendidoS += dados[x].ICMSProprioEmpreendidoS ? dados[x].ICMSProprioEmpreendidoS : 0;
                totcreditoPresumidoICMS += dados[x].creditoPresumidoICMS ? dados[x].creditoPresumidoICMS : 0;
                totICMSProprioEmpreendidoC += dados[x].ICMSProprioEmpreendidoC ? dados[x].ICMSProprioEmpreendidoC : 0;
                totRecolhimentoTotalFundos += dados[x].recolhimentoTotalFundos ? dados[x].recolhimentoTotalFundos : 0;
            }
            // let saldoInicial = (dados[11].saldoInicial?dados[11].saldoInicial:0) + (dados[11].parcelaLiberacao?dados[11].parcelaLiberacao:0) - (dados[11].parcelaLeiloada?dados[11].parcelaLeiloada:0) 
            //     + (dados[11].rendimentoContrato?dados[11].rendimentoContrato:0) - (dados[11].jurosPago?dados[11].jurosPago:0);

            setTotal({
                recolhimentoFundoProd: totrecolhimentoFundoProd,
                recolhimentoFundoDesenv: totrecolhimentoFundoDesenv,
                ICMSProprioEmpreendidoS: totICMSProprioEmpreendidoS,
                creditoPresumidoICMS: totcreditoPresumidoICMS,
                ICMSProprioEmpreendidoC: totICMSProprioEmpreendidoC,
                recolhimentoTotalFundos: totRecolhimentoTotalFundos
            });
        }
    }, [dados])

    const onChange = (e, row, index) => {
        if (!/^[0-9]*\,?[0-9]*$/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^0-9]*\,?[0-9]*$/g, "");
            return;
        }
        let teste = dadoApoio ? (dadoApoio.txIcms/100) : 0;
        let arr = [...dados];
        let val = { ...arr[index] };
        let valor = e.target.value;
        valor = valor.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '');
        valor = valor.replace(",", ".");
        val[e.target.name] = parseFloat(valor);

        // if( !val.editado)val.editado = [];
        // if( !val.editado.find(v => v === e.target.name)) val.editado.push(e.target.name);
        if (e.target.name === "recolhimentoFundoProd" && dadoApoio && teste) {
            val["ICMSProprioEmpreendidoS"] = val["recolhimentoFundoProd"] / teste;
            val["recolhimentoTotalFundos"] = val["recolhimentoFundoProd"] + val["recolhimentoFundoDesenv"];
        }
        if (e.target.name === "recolhimentoFundoProd" && perDescontoBeneficio) {
            val["ICMSProprioEmpreendidoC"] = val["ICMSProprioEmpreendidoS"] * (1 - (perDescontoBeneficio/100));
            val["creditoPresumidoICMS"] = val["ICMSProprioEmpreendidoS"] * (perDescontoBeneficio/100);
            val["recolhimentoTotalFundos"] = val["recolhimentoFundoProd"] + val["recolhimentoFundoDesenv"];
        }

        if (e.target.name === "recolhimentoFundoProd" || e.target.name === "recolhimentoFundoDesenv") val["recolhimentoTotalFundos"] = val["recolhimentoFundoProd"] + val["recolhimentoFundoDesenv"];

        arr[index] = val;
        setDados(arr);
    };

    function proximo(nome) {
        let arr = nome.split("nm_");
        let col = parseInt(arr[0]);
        let lin = parseInt(arr[1]);
        if (col >= 2) {
            lin++;
            col = 1;
        } else {
            col++;
        }
        setComponenteAtual(col + "nm_" + lin);
    }

    function apresentaValor(index, nome) {
        if (dados && dados.length > 0) {
            if (dados[index]) {
                return FormataMoeda(dados[index][nome]);
            }
            return '';
        }
        return '';
    }
    return (
        <TableContainer component={Paper} className={classes.root}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead} >REFERÊNCIA</TableCell>
                        <TableCell className={classes.tableHead} align="right">RECOLHIMENTO FUNDO PRÓ-RECEITA (R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">RECOLHIMENTO FUNDO DESENVOLVIMENTO DF (R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">RECOLHIMENTO TOTAL FUNDOS (R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">ICMS PRÓPRIO EMPREENDIMENTO SEM DESCONTO (R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">CRÉDITO PRESUMIDO DE ICMS EMPREENDIMENTO (R$)</TableCell>
                        <TableCell className={classes.tableHead} align="right">ICMS PRÓPRIO EMPREENDIMENTO COM DESCONTO (R$)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Meses.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{row}</TableCell>
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"1nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="recolhimentoFundoProd" onChange={onChange} valor={apresentaValor(index, 'recolhimentoFundoProd')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"2nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="recolhimentoFundoDesenv" onChange={onChange} valor={apresentaValor(index, 'recolhimentoFundoDesenv')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"3nm_" + index} index={index} disabled={true} row={row} calcula={calcula} name="recolhimentoTotalFundos" valor={apresentaValor(index, 'recolhimentoTotalFundos')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"4nm_" + index} index={index} disabled={true} row={row} calcula={calcula} name="ICMSProprioEmpreendidoS" valor={apresentaValor(index, 'ICMSProprioEmpreendidoS')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"5nm_" + index} index={index} disabled={true} row={row} calcula={calcula} name="creditoPresumidoICMS" valor={apresentaValor(index, 'creditoPresumidoICMS')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"6nm_" + index} index={index} disabled={true} row={row} calcula={calcula} name="ICMSProprioEmpreendidoC" valor={apresentaValor(index, 'ICMSProprioEmpreendidoC')} />
                        </TableRow>
                    ))}
                    <TableRow style={{ background: '#fafafa' }} className={classes.tablecell}
                    >
                        <TableCell className={classes.tablecell} component="th" scope="row" >Total</TableCell>
                        <TableCell className={classes.tablecell} align="right" style={{ color: (total.recolhimentoFundoProd < 0) ? '#ff5c33' : 'black' }} >{FormataMoeda(total.recolhimentoFundoProd)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.recolhimentoFundoDesenv)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.recolhimentoTotalFundos)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.ICMSProprioEmpreendidoS)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.creditoPresumidoICMS)}</TableCell>
                        <TableCell className={classes.tablecell} align="right">{FormataMoeda(total.ICMSProprioEmpreendidoC)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
