import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Container, Collapse, IconButton, Box, Grid, Paper, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import MaskedInput from 'react-text-mask'
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { useCurrentUser } from "../../server/UseCurrentUser";
import { ACCESS_TOKEN, API_BASE_URL } from '../../constants';
import DadosAgendaForm from '../vistoria/DadosAgendaForm';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      padding: theme.spacing(2),
    },
    btns: {
      '& > *': {
        margin: theme.spacing(1)
      },
      marginTop: '20px',
      display: 'flex'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
    },
    gridControl: {
      marginLeft: '5px',
      marginRight: '5px'
    },
    foto: {
      width: '100%'
    },
    botaoFoto: {
      padding: '5px',
      width: '100%'
    }
  })
);

function TelMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
function TelMask2(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}
let primeiro = false;
export default function FormMinhaConta() {
  const classes = useStyles();
  const [usuario, setUsuario] = React.useState({
    CPF: '',
    email: '',
    name: '',
    login: '',
    matricula: ''
  });
  const [profile] = useCurrentUser();

  const [open, setOpen] = React.useState(false);
  const [msgErro, setMsgErro] = React.useState('');
  const [fotoPreview, setFotoPreview] = React.useState();
  const [erro, setErro] = useState();
  const [errorTextField, setErrorTextField] = useState({
    telefone: {
        erro: false,
        msg: ''
    },
    telefoneFixo: {
      erro: false,
      msg: ''
  },
  });


  const [perfis, setPerfis] = React.useState();
  const history = useHistory();
  const [telefone, setTelefone] = React.useState('');
  const [telefoneFixo, setTelefoneFixo] = React.useState('');
  const [showTel1, setShowTel1] = useState(false);
  const [showCel1, setShowCel1] = useState(false);

  useEffect(() => {
    if (primeiro === false && profile) {
      let user = { ...profile };

      //user.matricula = ;//(user.matricula)?user.matricula:user.login;
      user.CPF = (user.CPF) ? user.CPF : '';
      user.email = (user.email) ? user.email : user.login;
      setUsuario(user);
      setTelefone(profile.telefone ? profile.telefone : '');
      setTelefoneFixo(profile.telefoneFixo ? profile.telefoneFixo : '');
      primeiro = true;
    }

    if (profile && profile.foto && !fotoPreview) {

      if (profile.foto.id) {
        setFotoPreview(API_BASE_URL + "/api/dowload/gridfs/" + profile.foto.id); //'/api/dowload/fileSystem/' + profile.foto.uuid + "-" + profile.foto.nome);
      } else {
        setFotoPreview('/api/dowload/fileSystem/' + profile.foto.uuid + "-" + profile.foto.nome);
      }


    }

    if (!perfis) {
      fetch("api/perfil",
        {
          headers: {
            Accept: 'application/json',
            'Authorization': `Bearer ${localStorage.accessToken}`
          }
        }).then(res => {
          if (res.ok && res.status === 200) {
            res.json().then((result) => {
              let todosPerfis = [];
              (result && result.forEach(perfil => {
                todosPerfis[perfil.id] = perfil;
              }));
              setPerfis(todosPerfis);
            });
          } else {
            res.json().then((error) => {
              setOpen(true);
              setMsgErro(error && error.message);
            }).catch(error => {
              setOpen(true);
              setMsgErro("Erro de comunicação com o servidor.");
            });
          }
        }).catch(error => {
          setOpen(true);
          setMsgErro(error);
        });
    }
  }, [usuario, perfis]);

  const handleEscolheFoto = () => {
    let file = document.getElementById("file2");
    file.click();
  }
  function validaAnexo(anexo) {
    if (!anexo) {
      setOpen(true);
      return false;
    }

    if (!anexo.name.toLocaleLowerCase().endsWith('jpeg') &&
      !anexo.name.toLocaleLowerCase().endsWith('jpg') &&
      !anexo.name.toLocaleLowerCase().endsWith('png')) {
      setMsgErro("Extensão inválida. Por favor informar uma anexo com extensão .jpeg ou jpg ou png");
      setOpen(true);
      return false;
    }
    var sizeInMB = (anexo.size / (1024*1024)).toFixed(2);
    console.log("size MB:", sizeInMB, (sizeInMB > 3));
    if (sizeInMB > 3) { //((anexo.size / 1000).toFixed(2) > 50) {
      setErro("Inválido. Por favor informar imagem menor do que 3MB");
      return false;
    }
    return true;
  }
  const upload = (e) => {
    let file = document.getElementById("file2");
    let dataArray = new FormData();

    if (!validaAnexo(file.files[0])) {
      // setOpen(true);
      return;
    }

    dataArray.append('file', file.files[0]);

    fetch('/api/upload/file', { method: "POST", body: dataArray })
      .then(res => res.status === 200 ? res.json() : setMsgErro(res))
      .then(
        (result) => {
          var nm = file.value;
          nm = nm.split(/(\\|\/)/g).pop();
          setUsuario({
            ...usuario,
            foto: {
              id: result.id,
              nome: nm,
              uuid: result.uuid
            }
          })
          setFotoPreview('/api/dowload/fileSystem/' + result.uuid + "-" + nm);
        },
        (error) => {
          setMsgErro(error);
        }
      );
  }

  function  preencheDadosUsuario(dado) {
    fetch('/api/user/preencher/dados', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.accessToken}`
      },
      body: JSON.stringify(dado),
      credentials: 'include'
    }).then(response => {
      if (response.ok && (response.status === 202)) {

        if (response.ok) {
          response.json().then(j => {

            const regras = profile.regras;
            j.regras = regras;
            localStorage.setItem(ACCESS_TOKEN + "_PROFILE", JSON.stringify(j));
            history.push('/home');
            window.location.reload();
          })
        }


      } else {
        response.json().then((error) => {
          setOpen(true);
          setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
        }).catch(error => {
          setOpen(true);
          setMsgErro("Erro de comunicação com o servidor.");
        });
      }
    }).catch(error => {
      setOpen(true);
      setMsgErro((error && error.message) || 'Oops! Something went wrong. Please try again!');
    });
  }

  const handleSubmit = () => {
    usuario.telefone = document.getElementById('telCelular').value || document.getElementById('telCelular1').value;
    usuario.telefoneFixo = document.getElementById('telFixo').value || document.getElementById('telFixo1').value;
    let dado ={...usuario}
    dado.matricula = usuario.matricula;
    let ret = false;
    let erroTele = {...errorTextField};
    if(!usuario.telefone || usuario.telefone == "(   )      -    "){
      erroTele.telefone = {
        erro: true,
        msg: 'Campo obrigatorio'
      }
      ret = true;
    }else if( (usuario.telefone.replaceAll(/[^0-9]+/g, '').length) < 10){
      erroTele.telefone =  {
          erro: true,
          msg: 'Número inválido'
      }
      ret = true;
    }else{
      erroTele.telefone =  {
        erro: false,
        msg: ''
      }
    }
    if(!usuario.telefoneFixo || usuario.telefoneFixo == "(   )      -    "){
      console.log("a")
    }else if( (usuario.telefoneFixo.replaceAll(/[^0-9]+/g, '').length) < 10){
      console.log("3")
      erroTele.telefoneFixo = {
          erro: true,
          msg: 'Número inválido'
      }
      ret = true;
    }else{
      erroTele.telefoneFixo =  {
        erro: false,
        msg: ''
      }
    }
    setErrorTextField(erroTele)
    if(ret) return;
    preencheDadosUsuario(dado);

  }

  const handleChangeInputForm = (event) => {

    if (event.target.name === 'telCelular') {
      setTelefone(event.target.value);
    }
    if (event.target.name === 'telFixo') {
      setTelefoneFixo(event.target.value);
    }
  };

  const handleChangeInputName = (event) => {
    setUsuario({
      ...usuario,
      [event.target.name]: event.target.value,
    });
  }

  const handleCelLostFocus = () => {
    setShowCel1(telefone.trim().length !== 16);
  }
  const handleCelFocus = () => {
    setShowCel1(false);
  }
  const handleTelLostFocus = () => {
    setShowTel1(telefoneFixo.trim().length !== 16);
  }
  const handleTelFocus = () => {
    setShowTel1(false);
  }
  const showPerfis = () => {
    return (usuario && usuario.perfis) ? usuario.perfis : '';
  }



  return (
    <Container maxWidth="md">

      <div className={classes.root}>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpen(false); }} >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {msgErro}
          </Alert>
        </Collapse>
          {
            erro && <Alert severity="error">{erro}</Alert>
          }
      </div>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5" gutterBottom><center>Dados pessoais</center></Typography>
      </Paper>
      <Box />
      <Paper>
        <Grid className={classes.gridControl} container direction="row" spacing={2}>

          <Grid item sm={2}>
            <br /><img alt="foto" src={(fotoPreview ? fotoPreview : "/images/pessoa.jpg")} className={classes.foto} />
            {/* <Button className={classes.botaoFoto} variant="outlined" color="primary" onClick={() => fotoRef.current.click()}>Carregar foto</Button> */}
            <Button className={classes.botaoFoto} variant="outlined" color="primary" onClick={handleEscolheFoto}>Carregar foto</Button>
            {/* <input type="file" id="file" ref={fotoRef} onChange={handleChangeFoto} hidden /> */}
          </Grid>
          <Grid item sm={10}>
            <Grid className={classes.gridControl} container direction="row" spacing={2}>
              <Grid item sm={12}>
                <TextField
                  value={usuario && usuario.name || ""}
                  fullWidth
                  label="Nome:"
                  id="name"
                  name="name"
                  // onChange={handleChangeInputName}
                  InputProps={{
                    readOnly: true,
                  }}
                  aria-describedby="nova-helper-text"
                  variant="outlined"
                />


              </Grid>
              <Grid item sm={12}>
                <TextField
                  value={usuario && usuario.login  || ''}
                  disabled
                  fullWidth
                  label="Usuário:"
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={12}>
                <TextField
                  value={usuario && usuario.email  || ''}
                  disabled
                  fullWidth
                  label="E-mail:"
                  InputProps={{
                    readOnly: true,
                  }}
                  variant="outlined"
                />

              </Grid>
              {usuario && !usuario.usuarioExterno &&
                <Grid item sm={12}>
                  <TextField
                    value={usuario && usuario.matricula || ''}
                    fullWidth
                    id="matricula"
                    name="matricula"
                    onChange={handleChangeInputName}
                    label="Matrícula"
                    variant="outlined"
                  />

                </Grid>
              }
              <Grid item sm={12}>
                <TextField
                  value={usuario && usuario.CPF  || ''}
                  disabled
                  fullWidth
                  label="CPF"
                  variant="outlined"
                />
              </Grid>

              <Grid item sm={12}>
                <TextField
                  value={showPerfis()}
                  disabled
                  fullWidth
                  label="Perfil"
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={12}>
                {/* <Input id="telCelular" name="telCelular" value={telefone} variant="outlined" onChange={ handleChangeInputForm } InputProps={{ inputComponent: TelMask}} /> */}
                <TextField
                  value={telefone}
                  error={errorTextField.telefone.erro}
                  helperText={errorTextField.telefone.msg}
                  label="Telefone celular"
                  fullWidth
                  onChange={handleChangeInputForm}
                  onFocus={handleCelFocus}
                  onBlur={handleCelLostFocus}
                  style={{ display: showCel1 ? 'none' : 'block' }}
                  name="telCelular"
                  id="telCelular"
                  InputProps={{
                    inputComponent: TelMask,
                  }}
                  variant="outlined"
                />
                <TextField
                  value={telefone}
                  error={errorTextField.telefone.erro}
                  helperText={errorTextField.telefone.msg}
                  label="Telefone celular"
                  fullWidth
                  onChange={handleChangeInputForm}
                  onFocus={handleCelFocus}
                  style={{ display: showCel1 ? 'block' : 'none' }}
                  name="telCelular"
                  id="telCelular1"
                  InputProps={{
                    inputComponent: TelMask2,
                  }}
                  variant="outlined"
                />

              </Grid>
              <Grid item sm={12}>
                <TextField
                  value={telefoneFixo}
                  error={errorTextField.telefoneFixo.erro}
                  helperText={errorTextField.telefoneFixo.msg}
                  label="Telefone fixo"
                  fullWidth
                  onChange={handleChangeInputForm}
                  onFocus={handleTelFocus}
                  onBlur={handleTelLostFocus}
                  style={{ display: showTel1 ? 'none' : 'block' }}
                  name="telFixo"
                  id="telFixo"
                  InputProps={{
                    inputComponent: TelMask,
                  }}
                  variant="outlined"
                />
                <TextField
                  value={telefoneFixo}
                  error={errorTextField.telefoneFixo.erro}
                  helperText={errorTextField.telefoneFixo.msg}
                  fullWidth
                  onChange={handleChangeInputForm}
                  onFocus={handleTelFocus}
                  style={{ display: showTel1 ? 'block' : 'none' }}
                  name="telFixo"
                  id="telFixo1"
                  InputProps={{
                    inputComponent: TelMask2,
                  }}
                  variant="outlined"
                />

              </Grid>
            </Grid>

          </Grid>

        </Grid>
      </Paper>
      <Grid container justify="flex-end" className={classes.btns} >
        <Button variant="contained" color="secondary" href="/home">
          Sair
        </Button>
        <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
          Salvar
        </Button>
      </Grid>
      <input type="file" id="file2" style={{ display: 'none' }} onChange={upload} accept="image/gif, image/jpeg, image/png, image/jpg" />
    </Container>
  );
}
