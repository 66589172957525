import React from 'react';
import { Grid, Button } from '@material-ui/core';
import ArquivoService from '../../../services/ArquivoService';
import PublishIcon from '@material-ui/icons/Publish';

export default function FileUploadComponent(props) {

    const { setMensagem, anexos, setAnexos, setWait, tipoDocumento, type } = props;

    const upload = () => {
        let file = document.getElementById("file");
        let dados = new FormData();
        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);
        dados.append('file', file.files[0]);
        ArquivoService.upload(dados).then((result) => {
            setWait(false);
            var nm = file.value.split(/(\\|\/)/g).pop();
            let arr = [...anexos];
            arr.push({
                tipo: tipoDocumento,
                nome: nm,
                uuid: result.uuid
            });
            setAnexos(arr);
            file.value = null;
        }).catch((e) => {
            setWait(false);
            setMensagem({ tipo: 'error', mensagem: e.message });
        });
    }

    function validaAnexo(anexo) {
        if (!anexo) return false;
        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem({ tipo: 'error', mensagem: "Extensão inválida. Por favor informar uma anexo com extensão .pdf" });
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem({ tipo: 'error', mensagem: "Arquivo inválido. Por favor informar imagem menor do que 20 mb" });
            return false;
        }
        return true;
    }

    const handleEscolherArquivo = () => {
        let file = document.getElementById("file");
        file.click();
    }
    if( type && type === "simple"){
        return <Button>Anexar</Button>;
    }else

    return (
        
        <Grid item xs={12} style={{ textAlign: '-webkit-right' }}>
            <Grid item xs={8}></Grid>
            <Grid item xs={4}>
                <Button
                    icon="publish"
                    variant="contained"
                    color="primary"
                    onClick={handleEscolherArquivo}
                >
                    <PublishIcon />&nbsp;Adicionar documento
                </Button>
            </Grid>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />
        </Grid>
    );
}
