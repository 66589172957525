import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography,
    Accordion, AccordionSummary, AccordionDetails, Divider
} from '@material-ui/core';
import { useStyles, getQueryParams, API_BASE_URL } from "../../../constants";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InformacaoBasicaForm from "./InformacaoBasicaForm";

export default function InformacoesIniciaisForm(props) {
    const classes = useStyles();
    const { value, setValue } = props;

    return (
        // <Container maxWidth='md'>
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>INFORMAÇÕES</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <h2>Informações à Empresa Proponente</h2>
                            <p>1. A Secretaria de Desenvolvimento Econômico do Distrito Federal - SEDET/DF receberá somente os modelos de Projeto de Viabilidade Simplificado - PVS, devidamente preenchidos sem rasuras e acompanhados da documentação exigida.</p>
                            <p>2. As informações constantes nos documentos apresentados são de inteira responsabilidade da proponente.</p>
                            <p>3. A proponente poderá acrescentar ao modelo PVS outras informações ou tópicos que considere relevantes.</p>
                            <p>4. O ato de recebimento do PVS e dos documentos que o acompanham não implica no seu deferimento.</p>
                            <p>5. Todas as informações apresentadas pela proponente estarão sujeitas à comprovação.</p>
                            <p>6. Os documentos que acompanham o PVS poderão ser apresentados separadamente (por tipo de documento) em formato digital (arquivo .pdf com reconhecimento ótico de caracteres - ocr) e não serão aceitos quando encadernados.</p>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Paper>
        // </Container>
    )

}