import React, { useEffect, useState } from 'react';
import {
    Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../../../../constants';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';
import Download from '../../../../util/Download';
import CheckIcon from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import HistoricoTable from '../../unate/HistoricoTable';

export default function EmpresaDocumentosTable(props) {
    const { value, anexos, setWait, setMensagem, setAbilitaSalvar, abilitaSalvar, setTxtHistorico,
        textoRelato, disabled, textoLegislacao, txtRelato, setTxtRelato,
        txtLegislacao, setTxtLegislacao, txtViabFin, setTxtViabFin, 
        txtGerarEmpreg, setTxtGerarEmpreg, txtConclusao, setTxtConclusao } = props;
    const classes = useStyles();
    // const [txtConclusao, setTxtConclusao] = useState('');
    // const [legislacao, setLegislacao] = useState(textoLegislacao);


    // function setMensagemIntermedio(alerta) {
    //     let a;
    //     if (typeof alerta === 'object') {
    //         a = { ...alerta };
    //         if (!a.tipo) {
    //             console.log("buscaIntegracaoSei OK\n", alerta)
    //         }
    //     } else {
    //         setMensagem(alerta);
    //     }
    // }

    return (
        <Paper >
            
        
        
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <TableContainer component={Paper} >
                                <Table aria-label="customized table" size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sortDirection={false} className={classes.tableHead}>
                                                Documento
                                            </TableCell>
                                            <TableCell width='100px' align="center" className={classes.tableHead}>
                                                Ação
                                            </TableCell>
                                            {/* <TableCell width='100px' align="center" className={classes.tableHead}>
                                                Ação Técnico
                                            </TableCell> */}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {anexos && anexos.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell>{row.tipo}</TableCell>
                                                <TableCell>
                                                    <center>
                                                        <Box>
                                                            {row.nome && <Download doc={row} />}
                                                            {row.check && <CheckIcon color="primary" />}

                                                        </Box>
                                                    </center>
                                                </TableCell>
                                                {/* <TableCell>
                                                    <center>
                                                        <Checkbox
                                                            disabled={desabilitado()}
                                                            checked={row.checkTecnico}
                                                            onChange={handleChangeTecnico}
                                                            name={row.nome ? row.nome : row.tipo}
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </center>
                                                </TableCell> */}
                                            </TableRow>
                                        ))}
                                        {!anexos || anexos.length === 0 &&
                                            <TableRow>
                                                <TableCell colSpan={2} width='100%' align="center">Nenhum registro encontrado.</TableCell>
                                            </TableRow>

                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
               
                 </Paper>
    )

}