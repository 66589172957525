import React, { useEffect, useState } from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid, IconButton, Paper, 
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import {useStyles} from "../constants";
import Typography from '@material-ui/core/Typography';
import CampoBusca from "./CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';

const styles = (theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
    });

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const options = [
    'AcaoA',
    'AcaoB'
];
let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let qtd = 0;

export default function BuscaDadoDialog(props) {
    const classes = useStyles();
    const { open, setOpen, dados, titulo, colunas, acao } = props;
    
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    
    const [selected, setSelected] = React.useState();
    const [processo, setProcesso] = React.useState();
    
    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });
    useEffect(() => {
        if( dados && dados.length > 0 ){
            fonteDeDados = dados;
            filtroCampo = null;
            filtraDado();
        }
    }, [dados]);

    useEffect(() => {
        fonteDeDados = dados;
        filtraDado();
    }, [ordem, pagina, bloco]);


    const handleRowClick = (event, p, i) => {
        setProcesso(p);
        
        setSelected(i);
      };
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
    }

    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
            } else {
                disparaSearch();
            }
        }, tempo);
    }
    
    function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];
        let arrOrdenado = ordenar(fonteDeDados);

        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                let campos = [];
                if( filtroCampo.includes('"')){
                    campos.push( filtroCampo.split('"')[1])
                }else {
                    campos = filtroCampo.split(' ');
                }
                root: for (let x in d) {
                    for (var y in campos) {
                        if (typeof d[x] === 'string') {
                            if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                arr.push(d);
                                break root;
                            }
                        } else if (d[x] == campos[y]) {
                            arr.push(d);
                            break root;
                        }
                    }
                }
            } else {
                arr.push(d);
            }
        });
        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (index++ > pagina - 2) break;
        }

        setDadosFiltrado(arrBloco);
    }

    function ordenar(arr) {
        let ordenado = arr;
        for(let c in colunas){
            let  nCampo = colunas[c].nome;
            let  campo = colunas[c].campo;
            if( ordem.campo === nCampo ){
                const asc = (ordem.ordem === 'asc') ? 1 : -1;
                ordenado = ordenado.sort((a, b) => ((a[campo]< b[campo]) ? 1 * asc : -1 * asc));
            }
        }
        
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);

        setBloco(0);
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleClick = () => {
        filtroCampo=null;
        acao(processo);
        setOpen(false);
    };

    function montaTitulo(){
        let ret = titulo ? titulo : 'Busca';
        return ret;
    }

    return (
        <div>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} maxWidth='md' fullWidth={true}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    { montaTitulo() }
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container spacing={3} className={classes.root}>
                        {dados && dados.length > 5 &&
                            <Grid item xs={12} >
                                <CampoBusca handleSearch={handleSearch} />
                            </Grid>
                        }
                        <Grid item xs={12} >
                            <TableContainer component={Paper} className={classes.root}>
                                <Table aria-label="customized table" size={'small'}>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                colunas && colunas.map((item, i) =>(
                                                    <TableCell key={i} sortDirection={false} className={classes.tableHead}>
                                                        <TableSortLabel
                                                            active={ordem.campo === item.nome}
                                                            direction={ordem.ordem}
                                                            onClick={(e) => createSortHandler(item.nome)}
                                                        >
                                                            {item.nome}
                                                        </TableSortLabel>
                                                    </TableCell>
                                                ))
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                            <TableRow key={i} onClick={(event) => handleRowClick(event, row, i)}
                                                selected={i === selected}
                                            >
                                                {
                                                    colunas && colunas.map((item, ii) =>(
                                                        <TableCell key={ii+"-"+i}>

                                                        <span dangerouslySetInnerHTML={{
                                                            __html: row[item.campo]
                                                        }}></span>
                                                            
                                                            
                                                        </TableCell>
                                                    ))
                                                }
                                                
                                                {/* <TableCell>{row.segundo}</TableCell>
                                                <TableCell>{row.terceiro}</TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {
                                dados &&
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={qtd}
                                    rowsPerPage={pagina}
                                    page={bloco}
                                    labelRowsPerPage="Registros por página"
                                    labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            }

                        </Grid>
                        
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" disabled={processo ? false : true} onClick={handleClick} color="primary" >
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
