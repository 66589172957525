import React, {useEffect} from 'react'

export default function PdfView(props){
    const { url, titulo, setTitulo, setWait, setErro } = props;

    // const { setErro, setTitulo, setWait, setMensagem } = props;

    useEffect(()=>{
        if (setTitulo) setTitulo( titulo );
        montaPdf();
    }, [titulo])
    function montaPdf(){
        setWait(true);
        fetch(url).then(response => {
            setWait(false);
            if (response.ok ) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    console.error( error );
                });
                return;
            }
        }).then(body => {
            if( !body) return;
            // var file = window.URL.createObjectURL(body);
            const frame = document.getElementById("frame");
            frame.src = URL.createObjectURL(body);
        });
    }
    return (
        // <PDFViewer
        //     document={{
        //         url: 'https://arxiv.org/pdf/quant-ph/0410100.pdf',
        //     }}
        // />

        // <embed src={API_BASE_URL+url} width= "100%" height= "600px"/>

        // <iframe src={API_BASE_URL+url} width="100%" height={"600"} frameBorder={'no'} scrolling={'no'} ></iframe>
        <iframe id="frame"  width= "100%" height= "600px" />
        // <embed id="frame" width= "100%" height= "600px"/>

    )
}