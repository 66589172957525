import React, { useState, useEffect } from 'react';
import { Grid, TextField }
  from '@material-ui/core';
import { validarEmail } from "../../../constants";
import MaskedInput from 'react-text-mask'
import { FormataNumeroInteiro } from "../../../constants";

function TelMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}
// function TelMask2(props) {
//   const { inputRef, ...other } = props;
//   return (
//     <MaskedInput
//       {...other}
//       ref={(ref) => {
//         inputRef(ref ? ref.inputElement : null);
//       }}
//       mask={['(', /\d/, /\d/,  ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
//       placeholderChar={'\u2000'}
//       showMask
//     />
//   );
// }

function CEPMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      // showMask
    />
  );
}
export default function SedeForm(props) {
  const { value, setValue, setWait, setMensagem, disable } = props;
  // const [showTel1, setShowTel1] = useState(false);
  const [error, setError] = React.useState(resetErro());

  useEffect(() => {
    document.getElementById('cep').value = value.sede ? value.sede.cep : "";
  }, [])
  const handleChangeInputForm = (event) => {
    //let end = value.sede;
    //end[event.target.name] = event.target.value;
    setValue({
      ...value,
      sede: {
        ...value.sede,
        [event.target.name]: event.target.value
      }
    });
  };
  const handleChangeOrientacao = (event) => {
    let end = value.sede;
    end.orientacao = event.target.value;
    setValue({
      ...value,
      sede: end
    });
  }

  // const handleTelLostFocus = (event) => {
  //   const tel = event.target.value;
  //   setShowTel1(tel.trim().length !== 16);
  // }
  const handleEmailFocus = () => {
    setError({
      email: {
        erro: false,
        msg: ""
      }
    });
  }
  const handleBlur = () => {
    if (value.sede.email) {
      if (!validarEmail(value.sede.email)) {
        setError({
          email: {
            erro: true,
            msg: "Email invalido"
          }
        });
      }
    }
  }
  // const handleTelFocus = () => {
  //   setShowTel1(false);
  // }
  function resetErro() {
    return {
      email: {
        erro: false,
        msg: ''
      }
    }
  }
  const handleLostFocus = () => {
    setWait(true);
    let cep = document.getElementById("cep").value;

    cep = cep.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();

    fetch("https://viacep.com.br/ws/" + cep + "/json/")
      .then(res => (res.status <= 300) ? res.json() : setMensagem(res))
      .then(
        (result) => {
          setWait(false);
          // if (!result) return;
          setValue({
            ...value, sede: result
          });
        },
        (error) => {
          setWait(false);
        }
      )

  }
  return (
    <Grid container spacing={1} >
      <Grid item xs={3}>
        <TextField
          required
          fullWidth
          label="CEP"
          // value={value.sede && value.sede.cep || ''}
          name="cep"
          id="cep"
          onChange={handleChangeInputForm}
          onBlur={handleLostFocus}
          InputProps={{
            inputComponent: CEPMask,
          }}
          disabled={disable}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={9}>
        <TextField
          value={value.sede && value.sede.logradouro || ''}
          fullWidth
          label="Endereço"
          id="sede"
          name="sede"
          aria-describedby="nova-helper-text"
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          label="Complemento"
          fullWidth
          value={value.sede && value.sede.complemento || ''}
          name="complemento"
          id="complemento"
          onChange={handleChangeInputForm}
          disabled={disable}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={value.sede && value.sede.bairro || ''}
          fullWidth
          label="Bairro"
          id="bairro"
          name="bairro"
          InputProps={{
            readOnly: true,
          }}
          aria-describedby="nova-helper-text"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={value.sede && value.sede.localidade || ''}
          fullWidth
          label="Cidade"
          id="cidade"
          name="cidade"
          InputProps={{
            readOnly: true,
          }}
          aria-describedby="nova-helper-text"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={value.sede && value.sede.uf || ''}
          fullWidth
          label="UF"
          id="uf"
          name="uf"
          InputProps={{
            readOnly: true,
          }}
          aria-describedby="nova-helper-text"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          label="Orientações e pontos de referencia para localizar"
          value={value.sede && value.sede.orientacao || ''}
          name="orientacao"
          id="orientacao"
          fullWidth
          onChange={handleChangeOrientacao}
          disabled={disable}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          label="Telefone Comercial"
          value={value.sede && value.sede.telefone || ''}
          onChange={handleChangeInputForm}
          // onBlur={handleTelLostFocus}
          // onFocus={handleTelFocus}
          // style={{ display: showTel1 ? 'none' : 'block' }}
          name="telefone"
          id="tel-mask-input"
          fullWidth
          InputProps={{
            inputComponent: TelMask,
          }}
          disabled={disable}
          variant="outlined"
        />
        {/* <TextField
                required
                label="Telefone Comercial"
                value={value.telefone}
                onChange={handleChangeInputForm}
                onFocus={handleTelFocus}
                style={{ display: showTel1 ? 'block' : 'none' }}
                name="telefone"
                id="tel-mask-input1"
                fullWidth
                InputProps={{
                    inputComponent: TelMask2,
                }}
                disabled={disable}
                variant="outlined"
              /> */}
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          label="Celular"
          value={value.sede && value.sede.celular || ''}
          onChange={handleChangeInputForm}
          // style={{ display: showTel1 ? 'none' : 'block' }}
          name="celular"
          id="celular"
          fullWidth
          InputProps={{
            inputComponent: TelMask,
          }}
          disabled={disable}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          label="Site"
          value={value.sede && value.sede.site || ''}
          onChange={handleChangeInputForm}
          // style={{ display: showTel1 ? 'none' : 'block' }}
          name="site"
          id="site"
          fullWidth
          disabled={disable}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          error={error.email.erro}
          helperText={error.email.msg}
          fullWidth
          label="Email para contato"
          value={value.sede && value.sede.email || ''}
          onFocus={handleEmailFocus}
          onBlur={handleBlur}
          onChange={handleChangeInputForm}
          name="email"
          id="email-input"
          disabled={disable}
          variant="outlined"
        />
      </Grid>

    </Grid>
  );
}
