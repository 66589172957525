import React, { useEffect, useState } from 'react';
import {
    Grid, Button, Paper, Accordion, AccordionSummary, Typography, AccordionDetails,
    MenuItem, Select
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, FormataMoeda } from '../../../constants';
import GenericTable from '../../template/GenericTable';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

export default function ConsolidadoRenunciaTable(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;

    const [renuncias, serRenuncias] = useState();
    const [exercicio, setExcercicio] = useState();
    const [lstExercicio, setLstExercicio] = useState([]);

    const [resumoProgramas, setResumoProgramas] = useState([]);
    const [resumoProgramasTotal, setResumoProgramasTotal] = useState([]);

    // const [resumoProgramasNP, setResumoProgramasNP] = useState([]);
    // const [resumoProgramasTotalNP, setResumoProgramasTotalNP] = useState([]);

    const [resumoSetor, setResumoSetor] = useState([]);
    const [resumoSetorTotal, setResumoSetorTotal] = useState([]);

    // const [resumoSetorNP, setResumoSetorNP] = useState([]);
    // const [resumoSetorTotalNP, setResumoSetorTotalNP] = useState([]);

    const [resumoLibaracao, setResumoLibaracao] = useState([]);
    const [resumoLibaracaoTotal, setResumoLibaracaoTotal] = useState([]);

    // const [resumoLibaracaoNP, setResumoLibaracaoNP] = useState([]);
    // const [resumoLibaracaoTotalNP, setResumoLibaracaoTotalNP] = useState([]);

    const [resumoPRODF, setResumoPRODF] = useState([]);
    const [resumoPRODFTotal, setResumoPRODFTotal] = useState([]);

    // const [resumoPRODFNP, setResumoPRODFNP] = useState([]);
    // const [resumoPRODFTotalNP, setResumoPRODFTotalNP] = useState([]);

    const [resumoFIDE, setResumoFIDE] = useState([]);
    const [resumoFIDETotal, setResumoFIDETotal] = useState([]);

    // const [resumoFIDENP, setResumoFIDENP] = useState([]);
    // const [resumoFIDETotalNP, setResumoFIDETotalNP] = useState([]);

    const [resumoIDEAS, setResumoIDEAS] = useState([]);
    const [resumoIDEASTotal, setResumoIDEASTotal] = useState([]);

    // const [resumoIDEASNP, setResumoIDEASNP] = useState([]);
    // const [resumoIDEASTotalNP, setResumoIDEASTotalNP] = useState([]);

    const [localidadeBeneficiada, setLocalidadeBeneficiada] = useState([]);
    const [localidadeBeneficiadaTotal, setLocalidadeBeneficiadaTotal] = useState([]);

    // const [localidadeBeneficiadaNP, setLocalidadeBeneficiadaNP] = useState([]);
    // const [localidadeBeneficiadaTotalNP, setLocalidadeBeneficiadaTotalNP] = useState([]);

    useEffect(() => {
        setTitulo("Resumo Consolidado Renuncia FUNDEFE");
        buscaTodasRenuncias();
    }, []);
    useEffect(() => {
        if (exercicio) {
            recalcula();
        }
    }, [exercicio]);

    function buscaTodasRenuncias() {
        fetch('/api/fundefeRenuncia')
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            serRenuncias(json);
                            let arr = [];
                            for (let x in json) {
                                const p = json[x].previsao;
                                if (!arr.find(e => e === p.exercicio)) {
                                    arr.push(p.exercicio);
                                }
                            }
                            setLstExercicio(arr);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }

    function recalcula() {
        let arrProgramas = [];
        let totalProgramas = [0, 0, 0, 0, 0, 0, 0, 0];

        // let arrProgramasNP = [];
        // let totalProgramasNP = [0, 0, 0, 0, 0, 0, 0, 0];

        let arrLibaracao = [];
        // let arrLibaracaoNP = [];
        let totalLibaracao = [0, 0, 0, 0, 0, 0, 0, 0];
        // let totalLibaracaoNP = [0, 0, 0, 0, 0, 0, 0, 0];
        let arrResumoPRODF = [];
        let totalResumoPRODF = [0, 0, 0, 0, 0, 0, 0, 0];

        // let arrResumoPRODFNP = [];
        // let totalResumoPRODFNP = [0, 0, 0, 0, 0, 0, 0, 0];

        let arrResumoFIDE = [];
        let totalResumoFIDE = [0, 0, 0, 0, 0, 0, 0, 0];

        // let arrResumoFIDENP = [];
        // let totalResumoFIDENP = [0, 0, 0, 0, 0, 0, 0, 0];

        let arrResumoIDEAS = [];
        let totalResumoIDEAS = [0, 0, 0, 0, 0, 0, 0, 0];

        // let arrResumoIDEASNP = [];
        // let totalResumoIDEASNP = [0, 0, 0, 0, 0, 0, 0, 0];

        let arrLocalicade = [];
        let totalLocalicade = [0, 0, 0, 0, 0, 0, 0, 0, 0];

        // let arrLocalicadeNP = [];
        // let totalLocalicadeNP = [0, 0, 0, 0, 0, 0, 0, 0, 0];


        let arrSetor = [];
        let totalSetor = [0, 0, 0, 0, 0, 0, 0, 0];

        // let arrSetorNP = [];
        // let totalSetorNP = [0, 0, 0, 0, 0, 0, 0, 0];

        const renunciasFiltradas = renuncias.filter(p => p.previsao.exercicio === exercicio);
        for (let x in renunciasFiltradas) {
            const r = renunciasFiltradas[x];
            const novoProj = r.novoProjeto ? "sim" : "não";

            if (r.previsao.programa === "PRODF") racalcularResumoArr(arrResumoPRODF, totalResumoPRODF, r, novoProj);
            if (r.previsao.programa === "FIDE") racalcularResumoArr(arrResumoFIDE, totalResumoFIDE, r, novoProj);
            if (r.previsao.programa === "IDEAS") racalcularResumoArr(arrResumoIDEAS, totalResumoIDEAS, r, novoProj);

            racalcularResumoArr(arrLibaracao, totalLibaracao, r, novoProj);
            racalcularProgramasArr(arrProgramas, totalProgramas, r);
            racalcularLocalidadeArr(arrLocalicade, totalLocalicade, r);

            racalcularSetorArr(arrSetor, totalSetor, r);

            // if( r.novoProjeto){
            //     if (r.previsao.programa === "PRODF") racalcularResumoArr(arrResumoPRODFNP, totalResumoPRODFNP, r);
            //     if (r.previsao.programa === "FIDE") racalcularResumoArr(arrResumoFIDENP, totalResumoFIDENP, r);
            //     if (r.previsao.programa === "IDEAS") racalcularResumoArr(arrResumoIDEASNP, totalResumoIDEASNP, r);

            //     racalcularResumoArr(arrLibaracaoNP, totalLibaracaoNP, r);
            //     racalcularProgramasArr(arrProgramasNP, totalProgramasNP, r);
            //     racalcularLocalidadeArr(arrLocalicadeNP, totalLocalicadeNP, r);

            //     racalcularSetorArr(arrSetorNP, totalSetorNP, r);
            // }



        }
        setResumoProgramas(arrProgramas);
        setResumoProgramasTotal(["Total", FormataMoeda(totalProgramas[0]), FormataMoeda(totalProgramas[1]), FormataMoeda(totalProgramas[2]), FormataMoeda(totalProgramas[3]), FormataMoeda(totalProgramas[4]), FormataMoeda(totalProgramas[5]), formataPorcentagem(totalProgramas[6])]);
        // setResumoProgramasNP(arrProgramasNP);
        // setResumoProgramasTotalNP(["Total", FormataMoeda(totalProgramasNP[0]), FormataMoeda(totalProgramasNP[1]), FormataMoeda(totalProgramasNP[2]), FormataMoeda(totalProgramasNP[3]), FormataMoeda(totalProgramasNP[4]), FormataMoeda(totalProgramasNP[5]), formataPorcentagem(totalProgramasNP[6])]);

        setLocalidadeBeneficiada(arrLocalicade);
        setLocalidadeBeneficiadaTotal(["Total", totalLocalicade[0], FormataMoeda(totalLocalicade[1]), FormataMoeda(totalLocalicade[2]), FormataMoeda(totalLocalicade[3]), FormataMoeda(totalLocalicade[4]), FormataMoeda(totalLocalicade[5]), FormataMoeda(totalLocalicade[6]), formataPorcentagem(totalLocalicade[7])]);
        // setLocalidadeBeneficiadaNP(arrLocalicadeNP);
        // setLocalidadeBeneficiadaTotalNP(["Total", totalLocalicadeNP[0], FormataMoeda(totalLocalicadeNP[1]), FormataMoeda(totalLocalicadeNP[2]), FormataMoeda(totalLocalicadeNP[3]), FormataMoeda(totalLocalicadeNP[4]), FormataMoeda(totalLocalicadeNP[5]), FormataMoeda(totalLocalicadeNP[6]), formataPorcentagem(totalLocalicadeNP[7])]);

        setResumoSetor(arrSetor);
        setResumoSetorTotal(["Total", totalSetor[0], FormataMoeda(totalSetor[1]), FormataMoeda(totalSetor[2]), FormataMoeda(totalSetor[3]), FormataMoeda(totalSetor[4]), FormataMoeda(totalSetor[5]), FormataMoeda(totalSetor[6]), formataPorcentagem(totalSetor[7])]);
        // setResumoSetorNP(arrSetorNP);
        // setResumoSetorTotalNP(["Total", totalSetorNP[0], FormataMoeda(totalSetorNP[1]), FormataMoeda(totalSetorNP[2]), FormataMoeda(totalSetorNP[3]),  FormataMoeda(totalSetorNP[4]), FormataMoeda(totalSetorNP[5]), FormataMoeda(totalSetorNP[6]), formataPorcentagem(totalSetorNP[7])]);

        setResumoLibaracao(arrLibaracao);
        setResumoLibaracaoTotal(["Total", '', '', '', FormataMoeda(totalLibaracao[0]), FormataMoeda(totalLibaracao[1]), FormataMoeda(totalLibaracao[2]), FormataMoeda(totalLibaracao[3]), FormataMoeda(totalLibaracao[4]), FormataMoeda(totalLibaracao[5]), formataPorcentagem(totalLibaracao[6])]);
        // setResumoLibaracaoNP(arrLibaracaoNP);
        // setResumoLibaracaoTotalNP(["Total", '', '', FormataMoeda(totalLibaracaoNP[0]), FormataMoeda(totalLibaracaoNP[1]), FormataMoeda(totalLibaracaoNP[2]),  FormataMoeda(totalLibaracaoNP[3]), FormataMoeda(totalLibaracaoNP[4]), FormataMoeda(totalLibaracaoNP[5]), formataPorcentagem(totalLibaracaoNP[6])]);

        setResumoPRODF(arrResumoPRODF);
        setResumoPRODFTotal(["Total", '', '', FormataMoeda(totalResumoPRODF[0]), FormataMoeda(totalResumoPRODF[1]), FormataMoeda(totalResumoPRODF[2]), FormataMoeda(totalResumoPRODF[3]), FormataMoeda(totalResumoPRODF[4]), FormataMoeda(totalResumoPRODF[5]), formataPorcentagem(totalResumoPRODF[6])]);
        // setResumoPRODFNP(arrResumoPRODFNP);
        // setResumoPRODFTotalNP(["Total", '', FormataMoeda(totalResumoPRODFNP[0]), FormataMoeda(totalResumoPRODFNP[1]), FormataMoeda(totalResumoPRODFNP[2]),  FormataMoeda(totalResumoPRODFNP[3]), FormataMoeda(totalResumoPRODFNP[4]), FormataMoeda(totalResumoPRODFNP[5]), formataPorcentagem(totalResumoPRODFNP[6])]);

        setResumoFIDE(arrResumoFIDE);
        setResumoFIDETotal(["Total", '', '', FormataMoeda(totalResumoFIDE[0]), FormataMoeda(totalResumoFIDE[1]), FormataMoeda(totalResumoFIDE[2]), FormataMoeda(totalResumoFIDE[3]), FormataMoeda(totalResumoFIDE[4]), FormataMoeda(totalResumoFIDE[5]), formataPorcentagem(totalResumoFIDE[6])]);
        // setResumoFIDENP(arrResumoFIDENP);
        // setResumoFIDETotalNP(["Total", '', FormataMoeda(totalResumoFIDENP[0]), FormataMoeda(totalResumoFIDENP[1]), FormataMoeda(totalResumoFIDENP[2]),  FormataMoeda(totalResumoFIDENP[3]), FormataMoeda(totalResumoFIDENP[4]), FormataMoeda(totalResumoFIDENP[5]), formataPorcentagem(totalResumoFIDENP[6])]);

        setResumoIDEAS(arrResumoIDEAS);
        setResumoIDEASTotal(["Total", '', '', FormataMoeda(totalResumoIDEAS[0]), FormataMoeda(totalResumoIDEAS[1]), FormataMoeda(totalResumoIDEAS[2]), FormataMoeda(totalResumoIDEAS[3]), FormataMoeda(totalResumoIDEAS[4]), FormataMoeda(totalResumoIDEAS[5]), formataPorcentagem(totalResumoIDEAS[6])]);
        // setResumoIDEASNP(arrResumoIDEASNP);
        // setResumoIDEASTotalNP(["Total", '', FormataMoeda(totalResumoIDEASNP[0]), FormataMoeda(totalResumoIDEASNP[1]), FormataMoeda(totalResumoIDEASNP[2]), FormataMoeda( totalResumoIDEASNP[3]), FormataMoeda(totalResumoIDEASNP[4]), FormataMoeda(totalResumoIDEASNP[5]), formataPorcentagem(totalResumoIDEASNP[6])]);

    }

    function racalcularProgramasArr(arr, total, r) {
        let totalFinacia = r.financiamento[0].saldoInicial;
        let somaRendimen = 0;
        let somaJuros = 0;
        let somaEmolumento = 0;
        let somaRenuncia = 0;
        for (let y in r.financiamento) {
            const f = r.financiamento[y];
            const a = r.aplicacao[y];
            totalFinacia += f.parcelaLiberacao;
            somaRendimen += a.rendimentoAplicacao;
            somaJuros += f.jurosPago;
            somaEmolumento += f.emolumentos;
            somaRenuncia += a.renunciaMensal;
        }
        total[0] += totalFinacia;
        total[1] += somaRendimen;
        total[2] += somaJuros;
        total[3] += somaEmolumento;
        total[4] += (totalFinacia + somaRendimen - somaJuros);
        total[5] += somaRenuncia;
        total[6] = (total[5] / total[0]) * 100;
        if (arr && arr.find(e => e.programa === r.previsao.programa)) {
            for (let x in arr) {
                if (r.previsao.programa == arr[x].programa) {
                    arr[x].valorTotalFinacia += totalFinacia;
                    arr[x].redimentoApli += somaRendimen;
                    arr[x].juroContato += somaJuros;
                    arr[x].emolumento += somaEmolumento;
                    arr[x].valorAplicacao += (totalFinacia + somaRendimen - somaJuros);
                    arr[x].valorRenuncia += somaRenuncia;
                    arr[x].porcenRenuncia = (arr[x].valorRenuncia / arr[x].valorTotalFinacia) * 100
                }
            }
        } else {
            arr.push({
                programa: r.previsao.programa,
                valorTotalFinacia: totalFinacia,
                redimentoApli: somaRendimen,
                juroContato: somaJuros,
                emolumento: somaEmolumento,
                valorAplicacao: (totalFinacia + somaRendimen - somaJuros),
                valorRenuncia: somaRenuncia,
                porcenRenuncia: (somaRenuncia / totalFinacia) * 100
            })
        }
    }
    function racalcularLocalidadeArr(arr, total, r) {
        let totalFinacia = r.financiamento[0].saldoInicial;
        let somaRendimen = 0;
        let somaJuros = 0;
        let somaEmolumento = 0;
        let somaRenuncia = 0;
        for (let y in r.financiamento) {
            const f = r.financiamento[y];
            const a = r.aplicacao[y];
            totalFinacia += f.parcelaLiberacao;
            somaRendimen += a.rendimentoAplicacao;
            somaJuros += f.jurosPago;
            somaEmolumento += f.emolumentos;
            somaRenuncia += a.renunciaMensal;
        }
        total[0]++;
        total[1] += totalFinacia;
        total[2] += somaRendimen;
        total[3] += somaJuros;
        total[4] += somaEmolumento;
        total[5] += (totalFinacia + somaRendimen - somaJuros);
        total[6] += somaRenuncia;
        total[7] = (total[6] / total[1]) * 100;
        if (arr && arr.find(e => e.ra === r.previsao.ra || e.ra === "Demais Regiões")) {
            for (let x in arr) {
                if (r.previsao.ra == arr[x].ra) {
                    arr[x].quantidade++;
                    arr[x].valorTotalFinacia += totalFinacia;
                    arr[x].redimentoApli += somaRendimen;
                    arr[x].juroContato += somaJuros;
                    arr[x].emolumento += somaEmolumento;
                    arr[x].valorAplicacao += (totalFinacia + somaRendimen - somaJuros);
                    arr[x].valorRenuncia += somaRenuncia;
                    arr[x].porcenRenuncia = (arr[x].valorRenuncia / arr[x].valorTotalFinacia) * 100;
                } else if (!r.previsao.ra && arr[x].ra === "Demais Regiões") {
                    arr[x].quantidade++;
                    arr[x].valorTotalFinacia += totalFinacia;
                    arr[x].redimentoApli += somaRendimen;
                    arr[x].juroContato += somaJuros;
                    arr[x].emolumento += somaEmolumento;
                    arr[x].valorAplicacao += (totalFinacia + somaRendimen - somaJuros);
                    arr[x].valorRenuncia += somaRenuncia;
                    arr[x].porcenRenuncia = (arr[x].valorRenuncia / arr[x].valorTotalFinacia) * 100;
                }
            }
        } else {
            arr.push({
                ra: r.previsao.ra ? r.previsao.ra : "Demais Regiões",
                quantidade: 1,
                valorTotalFinacia: totalFinacia,
                redimentoApli: somaRendimen,
                juroContato: somaJuros,
                emolumento: somaEmolumento,
                valorAplicacao: (totalFinacia + somaRendimen - somaJuros),
                valorRenuncia: somaRenuncia,
                porcenRenuncia: (somaRenuncia / totalFinacia) * 100
            })
        }
    }
    function racalcularSetorArr(arr, total, r) {
        let totalFinacia = r.financiamento[0].saldoInicial;
        let somaRendimen = 0;
        let somaJuros = 0;
        let somaEmolumento = 0;
        let somaRenuncia = 0;
        for (let y in r.financiamento) {
            const f = r.financiamento[y];
            const a = r.aplicacao[y];
            totalFinacia += f.parcelaLiberacao;
            somaRendimen += a.rendimentoAplicacao;
            somaJuros += f.jurosPago;
            somaEmolumento += f.emolumentos;
            somaRenuncia += a.renunciaMensal;
        }
        total[0]++;
        total[1] += totalFinacia;
        total[2] += somaRendimen;
        total[3] += somaJuros;
        total[4] += somaEmolumento;
        total[5] += (totalFinacia + somaRendimen - somaJuros);
        total[6] += somaRenuncia;
        total[7] = (total[6] / total[1]) * 100;
        if (arr && arr.find(d => d.setor === r.previsao.setor)) {
            for (let x in arr) {
                if (r.previsao.setor == arr[x].setor) {
                    arr[x].quantidade++;
                    arr[x].valorTotalFinacia += totalFinacia;
                    arr[x].redimentoApli += somaRendimen;
                    arr[x].juroContato += somaJuros;
                    arr[x].emolumento += somaEmolumento;
                    arr[x].valorAplicacao += (totalFinacia + somaRendimen - somaJuros);
                    arr[x].valorRenuncia += somaRenuncia;
                    arr[x].porcenRenuncia = (arr[x].valorRenuncia / arr[x].valorTotalFinacia) * 100;
                }
            }
        } else {
            arr.push({
                setor: r.previsao.setor,
                quantidade: 1,
                valorTotalFinacia: totalFinacia,
                redimentoApli: somaRendimen,
                juroContato: somaJuros,
                emolumento: somaEmolumento,
                valorAplicacao: (totalFinacia + somaRendimen - somaJuros),
                valorRenuncia: somaRenuncia,
                porcenRenuncia: (somaRenuncia / totalFinacia) * 100
            })
        }
    }

    function racalcularResumoArr(arr, total, r, np) {
        let totalFinacia = r.financiamento[0].saldoInicial;
        let somaRendimen = 0;
        let somaJuros = 0;
        let somaEmolumento = 0;
        let somaRenuncia = 0;
        for (let y in r.financiamento) {
            const f = r.financiamento[y];
            const a = r.aplicacao[y];
            totalFinacia += f.parcelaLiberacao;
            somaRendimen += a.rendimentoAplicacao;
            somaJuros += f.jurosPago;
            somaEmolumento += f.emolumentos;
            somaRenuncia += a.renunciaMensal;
        }
        total[0] += totalFinacia;
        total[1] += somaRendimen;
        total[2] += somaJuros;
        total[3] += somaEmolumento;
        total[4] += (totalFinacia + somaRendimen - somaJuros);
        total[5] += somaRenuncia;
        total[6] = (total[5] / total[0]) * 100;
        arr.push({
            empresa: r.previsao.razaoSocial ? r.previsao.razaoSocial : "Demais empresas",
            cnpj: r.previsao.cnpj,
            programa: r.previsao.programa,
            valorTotalFinacia: totalFinacia,
            redimentoApli: somaRendimen,
            juroContato: somaJuros,
            emolumento: somaEmolumento,
            valorAplicacao: (totalFinacia + somaRendimen - somaJuros),
            valorRenuncia: somaRenuncia,
            porcenRenuncia: (somaRenuncia / totalFinacia) * 100,
            novoProjeto: np
        })//é Valor total renuncia/Valor financiamento
    }
    // function imprimirNovosProjetos(){
    //     let dado = {
    //         exercicio: exercicio,
    //         novoProjeto: true,
    //         resumoProgramas: resumoProgramasNP,
    //         resumoProgramasTotal: resumoProgramasTotalNP,

    //         resumoSetor: resumoSetorNP,
    //         resumoSetorTotal: resumoSetorTotalNP,

    //         resumoLibaracao: resumoLibaracaoNP,
    //         resumoLibaracaoTotal: resumoLibaracaoTotalNP,

    //         resumoPRODF: resumoPRODFNP,
    //         resumoPRODFTotal: resumoPRODFTotalNP,

    //         resumoFIDE: resumoFIDENP,
    //         resumoFIDETotal: resumoFIDETotalNP,

    //         resumoIDEAS: resumoIDEASNP,
    //         resumoIDEASTotal: resumoIDEASTotalNP,

    //         localidadeBeneficiada: localidadeBeneficiadaNP,
    //         localidadeBeneficiadaTotal: localidadeBeneficiadaTotalNP
    //     }
    //     return;
    //     fetch("/api/fundefeRenuncia/renunciaConsolidada", {
    //         method: "POST",
    //         headers: {
    //             'Content-Type': 'application/json; charset=utf-8',
    //             Authorization: `Bearer ${localStorage.accessToken}`
    //         },
    //         body: JSON.stringify(dado),
    //     }).then(response => {
    //         if (response.ok) {
    //             return response.blob();
    //         } else {
    //             response.json().then((error) => {
    //                 setWait(false);
    //                 setMensagem("Problema ao gerar PDF");
    //             });
    //             return;
    //         }
    //     }).then(body => {
    //         setWait(false);
    //         if (!body) return;
    //         var file = window.URL.createObjectURL(body);
    //         let tab = window.open();
    //         tab.location.assign(file);
    //     });
    // }
    function imprimir() {
        let dado = {
            exercicio: exercicio,
            novoProjeto: false,
            resumoProgramas: resumoProgramas,
            resumoProgramasTotal: resumoProgramasTotal,

            resumoSetor: resumoSetor,
            resumoSetorTotal: resumoSetorTotal,

            resumoLibaracao: resumoLibaracao,
            resumoLibaracaoTotal: resumoLibaracaoTotal,

            resumoPRODF: resumoPRODF,
            resumoPRODFTotal: resumoPRODFTotal,

            resumoFIDE: resumoFIDE,
            resumoFIDETotal: resumoFIDETotal,

            resumoIDEAS: resumoIDEAS,
            resumoIDEASTotal: resumoIDEASTotal,

            localidadeBeneficiada: localidadeBeneficiada,
            localidadeBeneficiadaTotal: localidadeBeneficiadaTotal,
        }
        // return;
        fetch("/api/fundefeRenuncia/renunciaConsolidada", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                // Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
        }).then(response => {
            if (response.ok) {
                return response.blob();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem("Problema ao gerar PDF");
                });
                return;
            }
        }).then(body => {
            setWait(false);
            if (!body) return;
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        });
    }
    const handleChangeExercicio = (event) => {
        const val = event.target.value;
        if (val != '') {
            setExcercicio(val);
        } else {
            setExcercicio("");
        }
    };

    function formataPorcentagem(val) {
        let x = Number(val).toFixed(4) + '%';
        return x.replace(".", ",");
    }
    return (
        <Paper >
            <Grid container spacing={3} className={classes.root} >
                <Grid item xs={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel id="lbExercicio">Excercício</InputLabel>
                        <Select
                            labelId="lbExercicio"
                            id="exercicio"
                            value={exercicio || ''}
                            onChange={handleChangeExercicio}
                            label="Exercício"
                        >
                            <MenuItem value="">Nenhum</MenuItem>
                            {lstExercicio && lstExercicio.map((row, i) => (
                                <MenuItem key={i} value={row}>{row}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo renúncia FUNDEFE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={resumoLibaracao}
                            rowTotal={resumoLibaracaoTotal}
                            colunas={[
                                { nome: 'Empresa', campo: 'empresa' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Programa', campo: 'programa' },
                                { nome: 'Novo Projeto', campo: 'novoProjeto' },
                                { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                            ]} />
                        {/* { resumoLibaracaoNP && resumoLibaracaoNP.length > 0 && <>
                            <Typography className={classes.subHeading}>Novo Projeto</Typography>
                            <GenericTable
                                disableSearch
                                dados={resumoLibaracaoNP}
                                rowTotal={resumoLibaracaoTotalNP}
                                colunas={[
                                    { nome: 'Empresa', campo: 'empresa', transformacao:function(ra){ return ra?ra:"Demais empresas"}},
                                    { nome: 'CNPJ', campo: 'cnpj' },
                                    { nome: 'Programa', campo: 'programa' },
                                    { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                    { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                                ]} />
                            </>} */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Localidades Beneficiados</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={localidadeBeneficiada}
                            rowTotal={localidadeBeneficiadaTotal}
                            colunas={[
                                { nome: 'Localidade (RA)', campo: 'ra' },
                                { nome: 'Quantidade', campo: 'quantidade', align: "right" },
                                { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                            ]} />

                        {/* { localidadeBeneficiadaNP && localidadeBeneficiadaNP.length > 0 &&
                                <>
                                 <Typography className={classes.subHeading}>Novo Projeto</Typography>
                                <GenericTable
                                    disableSearch
                                    dados={localidadeBeneficiadaNP}
                                    rowTotal={localidadeBeneficiadaTotalNP}
                                    colunas={[
                                        { nome: 'Localidade (RA)', campo: 'ra', transformacao:function(ra){ return ra?ra:"Demais empresas"} },
                                        { nome: 'Quantidade', campo: 'quantidade', align: "right" },
                                        { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                        { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                                    ]} />
                                </>
                            } */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Setores Beneficiados</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={resumoSetor}
                            rowTotal={resumoSetorTotal}
                            colunas={[
                                { nome: 'Setores', campo: 'setor' },
                                { nome: 'Quantidade', campo: 'quantidade', align: "right" },
                                { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                            ]} />


                        {/* {resumoSetorNP && resumoSetorNP.length > 0 &&
                        <>
                            <Typography className={classes.subHeading}>Novo Projeto</Typography>
                            <GenericTable
                                disableSearch
                                dados={resumoSetorNP}
                                rowTotal={resumoSetorTotalNP}
                                colunas={[
                                    { nome: 'Setores', campo: 'setor' },
                                    { nome: 'Quantidade', campo: 'quantidade', align: "right" },
                                    { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                    { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                                ]} />
                        
                        </>
                        } */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo Renuncia PRODF</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={resumoPRODF}
                            rowTotal={resumoPRODFTotal}
                            colunas={[
                                { nome: 'Empresa', campo: 'empresa' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Novo Projeto', campo: 'novoProjeto' },
                                { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                            ]} />
                        {/* { resumoPRODFNP && resumoPRODFNP.length > 0 && <>
                            <Typography className={classes.subHeading}>Novo Projeto</Typography>
                                <GenericTable
                                    disableSearch
                                    dados={resumoPRODFNP}
                                    rowTotal={resumoPRODFTotalNP}
                                    colunas={[
                                        { nome: 'Empresa', campo: 'empresa' },
                                        { nome: 'CNPJ', campo: 'cnpj' },
                                        { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                        { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                        { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                                    ]} />
                            </>} */}

                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo Renuncia FIDE</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={resumoFIDE}
                            rowTotal={resumoFIDETotal}
                            colunas={[
                                { nome: 'Empresa', campo: 'empresa' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Novo Projeto', campo: 'novoProjeto' },
                                { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                            ]} />

                        {/* { resumoFIDENP && resumoFIDENP.length > 0 && <>
                            <Typography className={classes.subHeading}>Novo Projeto</Typography>
                            <GenericTable
                                disableSearch
                                dados={resumoFIDENP}
                                rowTotal={resumoFIDETotalNP}
                                colunas={[
                                    { nome: 'Empresa', campo: 'empresa' },
                                    { nome: 'CNPJ', campo: 'cnpj' },
                                    { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                    { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                                ]} />
                            </>} */}

                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo Renuncia IDEAS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={resumoIDEAS}
                            rowTotal={resumoIDEASTotal}
                            colunas={[
                                { nome: 'Empresa', campo: 'empresa' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Novo Projeto', campo: 'novoProjeto' },
                                { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                            ]} />

                        {/* { resumoIDEASNP && resumoIDEASNP.length > 0 && <>
                            <Typography className={classes.subHeading}>Novo Projeto</Typography>
                        <GenericTable
                            disableSearch
                            dados={resumoIDEASNP}
                            rowTotal={resumoIDEASTotalNP}
                            colunas={[
                                { nome: 'Empresa', campo: 'empresa' },
                                { nome: 'CNPJ', campo: 'cnpj' },
                                { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                            ]} />
                            </>} */}

                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Resumo programas</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <GenericTable
                            disableSearch
                            dados={resumoProgramas}
                            rowTotal={resumoProgramasTotal}
                            colunas={[
                                { nome: 'Programa', campo: 'programa' },
                                { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                            ]} />
                        {/* { resumoProgramasNP && resumoProgramasNP.length > 0 && <>
                            <Typography className={classes.subHeading}>Novo Projeto</Typography>
                            <GenericTable
                                disableSearch
                                dados={resumoProgramasNP}
                                rowTotal={resumoProgramasTotalNP}
                                colunas={[
                                    { nome: 'Programa', campo: 'programa' },
                                    { nome: 'Valor Total Financiamento', campo: 'valorTotalFinacia', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Redimento Aplicado', campo: 'redimentoApli', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Juros Contrato', campo: 'juroContato', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Emolumentos FUNDEFE', campo: 'emolumento', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Total Aplicação', campo: 'valorAplicacao', transformacao: FormataMoeda, align: "right" },
                                    { nome: 'Valor Total Renuncia', campo: 'valorRenuncia', transformacao: FormataMoeda, align: "right" },
                                    { nome: '% de Renuncia', campo: 'porcenRenuncia', transformacao: formataPorcentagem, align: "right" }
                                ]} />
                                </>} */}

                    </Grid>
                </AccordionDetails>
            </Accordion>

            <Grid container spacing={3} className={classes.root}>
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                        Sair
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => imprimir()} >
                        Imprimir
                    </Button>
                    {/* <Button variant="contained" color="primary" onClick={() => imprimirNovosProjetos()} >
                        Imprimir Novos Projetos
                </Button> */}
                </Grid>
            </Grid >
        </Paper>

    );
}
