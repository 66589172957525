import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Container, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography } from '@material-ui/core';

import { useParams } from "react-router-dom";
import { useStyles, getQueryParams } from "../../constants";
import AlteraVistoriaTab from './AlteraVistoriaTab';

export default function AlteraVistoriaForm(props) {
  const classes = useStyles();
  let { id } = useParams();
  const { setTitulo, setMensagem, setWait, setErro } = props;

  const [value, setValue] = React.useState('cad');
  const [fluxo, setFluxo] = useState();
  const history = useHistory();
  const param = getQueryParams(useLocation().search);
  const [vistoria, setVistoria] = useState();
  const [devolvida, setDevolvida] = useState();
 
 
  useEffect(() => {
    bucaVistoria();
    
  }, []);
  function sair() {
    history.replace("/vistoria/solicitar");
  }
  function bucaVistoria() {
    fetch('/api/processoProdf/vistoria/' + param.get("id"))
      .then(response => response.json())
      .then(json => {
        setVistoria(json,"\nSituação", json.situacao);
        if (json.situacao == "AGUARDANDO_AJUSTE") {
          for (let x in json.historico) { 
            if (json.historico[x].tipo === "DEVOLVER"){
              setDevolvida(json.historico[x].texto);
            }
          }
        }
      })
      .catch(erro => setErro(erro));
  }
  
  return (
    <Container maxWidth='lg'>
        <AlteraVistoriaTab setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} vistoria={vistoria} setVistoria={setVistoria} alterar={true} devolvida={devolvida}/>
          {/* <VistoriaForm setTitulo={setTitulo} setWait={setWait} setMensagem={setMensagem} vistoria={vistoria} alterar={true} devolvida={devolvida} /> */}
       
    </Container>
  );
}
