import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RemoveIcon from '@material-ui/icons/Remove';
import { API_BASE_URL } from "../../../constants";
import Service from "../../../services/Service";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 260,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function AnexoField(props) {
    const { protocolo, setWait, setProtocolo, setMensagem, anexo, disabled } = props;
    const classes = useStyles();
    const handleSeeAnexo = () => {
        var a = document.createElement("a");
            
        if (anexo.id != null) {
            a.href = API_BASE_URL + "/api/dowload/gridfs/" + anexo.id;
        }else{
            a.href = anexo.nomeArquivo;
        }
        a.setAttribute("target", "_blank");
            
        a.click();
    }
    const handleRemoveAnexo = () => {
        let url = '/api/processoProdf/requerimento/anexoParecerTecnicoRemove';
        const dado = {
            anexo: anexo,
            protocolo: protocolo.protocolo
        }
        Service.POST(url, dado).then(resp => {
            setWait(false);
            setProtocolo(resp);

        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        })
    }
    return (
        <Paper component="form" className={classes.root}>
            <InputBase
                className={classes.input}
                value={anexo.nomeDocumento}
                inputProps={{ 'aria-label': anexo.nomeDocumento }}
                disabled
            />
            <IconButton color="primary" className={classes.iconButton} aria-label="search" onClick={handleSeeAnexo}>
                <VisibilityIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
            <IconButton color="secondary" disabled={disabled} className={classes.iconButton} aria-label="directions" onClick={handleRemoveAnexo}>
                <RemoveIcon />
            </IconButton>
        </Paper>
    );
}
