import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Button, Paper, Menu, MenuItem,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip, Dialog, DialogTitle, TextField, DialogContent, DialogActions
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CampoBusca from "../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import ConfirmDialog from "../../util/ConfirmDialog";
import { useHistory } from "react-router-dom";
import { useStyles } from "../../constants";
import MotivoDialog from '../../util/MotivoDialog';


const options = [
    'Visualizar',
    'Atualizar',
    'Excluir',
    'Mapa'
];

let fonteDeDados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;

export default function LoteTable(props) {
    const classes = useStyles();
    const { setErro, setTitulo, setWait, setMensagem } = props;
    const [showConfirmExclusao, setShowConfirmExclusao]= useState(false);
    const [showNaoPodeExclusao, setShowNaoPodeExclusao]= useState(false);
    const [dadosFiltrado, setDadosFiltrado] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);
    const history = useHistory();
    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });
    
    useEffect(() => {
        fonteDeDados = null;
        setTitulo("Gerenciar Estoque de Lotes")
        setWait(true);
        fetch("/api/processoProdf/lote"
        ).then(res => res.json()).then((result) => {
            setWait(false);
            
            if (result.status && result.status > 300) {
                setErro(result);
            } else {
                fonteDeDados = result;
                filtraDado();
            }
        });
    }, []);

    useEffect(() => {
        
        // fonteDeDados = dados;
        filtraDado();
    }, [ordem, pagina, bloco]);

    function buscarLoteEscluido(e){
        if(e.historico){
            for(let h in e.historico){
                if(h.texto === "Exclusão Lote") return true;
            }
        }
        return false
    }

    function confirmaExclusao(txt) {
        const id = dadosFiltrado[indexProcesso].id;
        setWait(true);
        const dado ={
            id: id,
            motivo: txt
        }
        fetch("/api/processoProdf/lote/" + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then((result) => {
            setWait(false);
            setShowConfirmExclusao(false);
            if (!result.ok) {
                setMensagem("Este Lote não pode ser excluído.");
            } else {
                let value = [];
                if (fonteDeDados && (fonteDeDados.length > 0)) {
                    for (let i = 0; i < fonteDeDados.length; i++) {
                        if (fonteDeDados[i].id !== id) {
                            value.push(fonteDeDados[i]);
                        }
                    }
                }
                
                fonteDeDados = value;
                filtraDado();
                setMensagem("Registro excluido!")
            }
        }).catch(error => {
            setWait(false);
            setErro("Este Lote não pode ser excluído.");
        });
    }

    const handleAcao = (e, index) => {
        indexProcesso = index;
        setAnchorEl(e.currentTarget);
    }

    const handleActionClick = (action) => {
        let lote = dadosFiltrado[indexProcesso];
        let acao = options[action];
        setAnchorEl(null);

        if(action === 0){
            history.push("/operacao/estoqueLoteView?id="+lote.id)

        }
        if(acao === 'Atualizar'){
            history.push("/operacao/estoqueLoteForm?id="+lote.id);
        }
        if(acao === 'Excluir'){
            if( lote.processo && lote.processo.length > 0 ){
                setShowNaoPodeExclusao( true );
            }else{
                setShowConfirmExclusao(true);
            }
        }
        if(action === 3){
            history.push("/operacao/loteMapaView?id="+lote.id);
        }
        setAnchorEl(null);
    };

  
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }
        elapseTime = performance.now();
    }

    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
            } else {
                disparaSearch();
            }
        }, tempo);
    }

    function getTituloSituacao(row) {

        return "Visualizar";
    }

   

  function filtraDado() {
        if (!fonteDeDados) return;
        let arr = [];

        let arrOrdenado = ordenar(fonteDeDados);
        
        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                
                let campos = [];
                if( filtroCampo.includes('"')){
                    campos.push( filtroCampo.split('"')[1])
                }else {
                    campos = filtroCampo.split(' ');
                }
                root: for (let x in d) {
                    for (var y in campos) {
                        if( x === 'processo'){
                            let string1 = d[x];
                            string1 = string1?string1.replace(/[\W_]/g, ""):'';
                            let string2 = campos[y];
                            string2 = string2?string2.replace(/[\W_]/g, ""):'';
                            
                            if (string1.includes(string2)) {
                                arr.push(d);
                                break root;
                            }
                        }else if (typeof d[x] === 'string') {
                            if (d[x].toUpperCase().includes(campos[y].toUpperCase())) {
                                arr.push(d);
                                break root;
                            }
                        } else if (d[x] == campos[y]) {
                            arr.push(d);
                            break root;
                        }
                    }
                }
            } else {
                arr.push(d);
            }
        });
        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (index++ > (pagina - 2)) break;
        }
        setDadosFiltrado(arrBloco);
    }

    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };

    function createSortHandler(coluna) {
        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);
        setBloco(0);
    }
    function formataDigito(valor){
        if( valor == null ) return '';
        console.log(valor)

        return valor.toLocaleString('pt-br',{minimumFractionDigits: 2});
    }

    return (
        <Grid container spacing={3} className={classes.root}>
            
            <Grid item xs={12} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'proposta'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('proposta')}
                                    >
                                        Proposta
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'processo'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('processo')}
                                    >
                                        Processo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'ra'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('ra')}
                                    >
                                        RA
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'ade'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('ade')}
                                    >
                                        ADE
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'endereco'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('endereco')}
                                    >
                                        Endereço
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'area'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('area')}
                                    >
                                        Área (m2)
                                    </TableSortLabel>
                                </TableCell>

                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'situacao'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('situacao')}
                                    >
                                        Situação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dadosFiltrado && dadosFiltrado.map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell >{row.proposta}</TableCell>
                                    <TableCell>{row.processo}</TableCell>
                                    <TableCell>{row.ra}</TableCell>
                                    <TableCell>{row.ade}</TableCell>
                                    <TableCell>{row.endereco}</TableCell>
                                    <TableCell>{ formataDigito(row.area) }</TableCell>
                                    <TableCell>{row.situacao}</TableCell>
                                    <TableCell align='center'>
                                       <Box>
                                            {/* <Tooltip title="Visualizar">
                                                <IconButton href={"/template/templateForm/"+i} >
                                                <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip> */}
                                            <Tooltip title={getTituloSituacao(row)}>
                                                <IconButton  onClick={(e) => handleAcao(e, i)}>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {
                    fonteDeDados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={qtd}
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </Grid>
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>

                <Button variant="contained" color="primary" href={"/operacao/estoqueLoteForm"} >
                    Cadastrar
                </Button>
            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                    // style: {
                    //     maxHeight: ITEM_HEIGHT * 4.5,
                    //     width: '20ch',
                    // },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleActionClick(index)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
            <Grid>
                <MotivoDialog
                    open={showConfirmExclusao}
                    setOpen={setShowConfirmExclusao}
                    titulo={'Confirmação'}
                    motivo='Motivo de exclusão'
                    msg='Confirma exclusão do item selecionado?.'
                    acao={confirmaExclusao}
                />
            </Grid>
            {/* <ConfirmDialog
                     open={showConfirmExclusao}
                     setOpen={setShowConfirmExclusao}
                     titulo={'Confirmação'}
                     msg='Confirma exclusão do item selecionado?.'
                     exec={confirmaExclusao}
                /> */}
            <ConfirmDialog
                     open={showNaoPodeExclusao} disableCancelar={true} btn={'Ok'}
                     setOpen={setShowNaoPodeExclusao}
                     titulo={'Exclusão'}
                     msg='Este lote não pode ser excluído pois está associado a um processo ou vistoria.'
                />
        </Grid>


    );
}
