import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
}
    from '@material-ui/core';
import ConfirmDialog from "../../../../util/ConfirmDialog";
import MaskedInput from 'react-text-mask'
import RemoveIcon from '@material-ui/icons/Remove';
import { useCurrentUser } from "../../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import Download from "../../../../util/Download";
import { useParams } from "react-router-dom";
import { useStyles, getQueryParams, ValidarCPF } from "../../../../constants";
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor';

function TelMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function TelMask2(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
export const useLocalStyle = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '160px',
        overflow: 'auto'
    }
}), { index: 1 });

export default function NotificacaoComplementoUnateForm(props) {
    const classes = useStyles();
    const classesLocal = useLocalStyle();
    let { id } = useParams();
    const { setTitulo, setWait, setMensagem, disable } = props;
    const [showConfirm, setShowConfirm] = useState(false);
    const [showTel1, setShowTel1] = useState(false);
    const [txt, setTxt] = useState('');
    const [desabilitado, setDesabilitado] = useState(disable ? true : false);
    const [value, setValue] = useState({
        processo: {
            nProcesso: ''
        },
        responsavel: '',
        programa: '',
        descricao: '',
        declaracao: false,
        protocolo: '',
        tipo: '',
    });
    const [anexos, setAnexos] = useState([{ tipo: -1 }]);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const location = useLocation();
    let [profile] = useCurrentUser();
    const [error, setError] = useState(resetErro());
    const param = getQueryParams(useLocation().search);
    const [podeEditarRequerente, setPodeEditarRequerente] = useState(disable ? true : false);

    useEffect(() => {
        const process = JSON.parse(localStorage.getItem("workingProcess"));

        if (process) {
            setValue({
                ...value,
                processo: {
                    id: process.id,
                    nProcesso: process.dadosEmpresa.nProcesso,
                    cnpj: process.dadosEmpresa.cnpj,
                    razaoSocial: process.dadosEmpresa.razaoSocial,
                    programa: process.dadosEmpresa.programa
                }
            });
        }
        buscaDados();
        
        if( location.pathname.toLocaleLowerCase().includes("view") ){
            setDesabilitado(true);
        }
    }, []);

    function buscaDados() {
        id = param.get('id');

        if (param.get("editavel") === 'true') {
            const process = localStorage.getItem("workingProcess");
            // setProcesso(JSON.parse(process));
        }

        if (id) {
            setWait(true);
            fetch(`/api/processoProdf/notificacaoComplemento/` + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.accessToken}`
                },
                credentials: 'include'
            }).then(response => {
                if (response.ok) {
                    response.json().then((json) => {
                        setWait(false);
                        setValue(json);
                        if (json.assunto === 'Complemento') {
                            setTitulo("Resposta Complemento");
                        } else {
                            setTitulo("Resposta Notificação");
                        }
                        if (json.documentos) {
                            let arr = [];
                            json.documentos.forEach(d => arr.push(d));
                            if (visivelEnviar())
                                arr.push({ tipo: -1 });
                            setAnexos(arr);
                        }
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        } else {
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarCancelamentoForm");
            }
        }
    }

    useEffect(() => {
        setTitulo("Resposta");
    }, []);

    const handleLostFocusCPF = (e) => {
        setError({
            ...error,
            cpf: {
                erro: false,
                msg: ''
            }
        });
        if (!value.cpf) return;
        const cpf = value.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();

        if (cpf.length === 0) return;

        if (ValidarCPF(cpf)) {
            // TODO buscar ou não CPF
            setWait(true);
            fetch("/api/v1/cpf/" + cpf)
                .then(res => {
                    if (res.status <= 300) return res.json()
                })
                .then(
                    (result) => {
                        setWait(false);
                        setError(resetErro);
                        if (!result) {
                            setPodeEditarRequerente(false);
                            setValue({
                                ...value, nome: '', telefone: '', email: ''
                            });
                        } else {
                            setValue({
                                ...value,
                                idUser: result.id,
                                nome: result.name,
                                email: result.email,
                                telefone: result.telefone
                            });
                            setPodeEditarRequerente(true);
                        }
                    },
                    (error) => {
                        setWait(false);
                    }
                )
        } else {
            setError({
                ...error,
                cpf: {
                    erro: true,
                    msg: 'CPF inválido'
                }
            });
        }
    }

    const handleTelLostFocus = () => {
        setShowTel1(value.telefone.trim().length !== 16);
    }
    const handleTelFocus = () => {
        setShowTel1(false);
    }

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };

    function confirmaResposta() {
        let dado = {};//{ ...value };
        dado.historico = [{
            responsavel: {
                nome: profile.name,
                email: profile.email,
                id: profile.id,
            },
            texto: txt,
            data: new Date(),
            tipo: 'responder'
        }];
        dado.documentos = [];//anexos;
        anexos.forEach(a => {
            if (a.tipo !== -1) dado.documentos.push(a);
        })
        dado.status = "EM_ANALISE";
        dado.id = value.id;
        dado.assunto = value.assunto;

        if (valida(dado)) {
            submitForm(dado);
        }
    }
    function submitForm(dado) {
        setWait(true);

        fetch('/api/processoProdf/notificacaoComplemento/posCreate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);

                response.json().then(json => setValue({
                    ...value,
                    id: json.id,
                    protocolo: json.protocolo
                }));
                
                setMensagem({ mensagem: 'Resposta encaminhada.', duracao: 5000 })
                sair();
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    function sair() {

        if (param.get('back')) {// param.get("editavel")) {
            // history.goBack();//TODO tem que pensar uma forma melhor de ficar isso
            setTimeout(function(){ 
                history.replace(param.get('back'));
             }, 300);
        } else {
            setTimeout(function(){ 
                history.replace("/gerenciaProcesso/unate/solicitarNotificaoTable?id="+value.processo.id+"&back=/gerenciaProcesso");
             }, 300);
        }
    }

    function resetErro() {
        return {
            programa: { erro: false, msg: '' },
            processo: { erro: false, msg: '' },
            cpf: { erro: false, msg: '' },
            telefone: { erro: false, msg: '' },
            email: { erro: false, msg: '' }
        }
    }

    function valida(dado) {
        // if (!dado.documentos || dado.documentos.length === 0) {
        //     setMensagem("Obrigatório informar os documentos para analise");
        //     return false;
        // }
        return true
    }
    const handleChangeCampo = (event) => {

        setValue({
            ...value,
            programa: event.target.value
        }
        );
    }
    const handleEscolheFile = () => {
        let file = document.getElementById("file");
        file.click();
    }

    function visivelEnviar() {
        if (disable) {
            return false;
        }
        if (!param.get("vizualizacao")) {
            return true;
        }

        return !(param.get("vizualizacao") === "true");
    }

    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            setOpen(true);
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);

                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();
                    let arr = [];
                    arr.push({
                        // tipo: tipoDocumento,
                        nome: nm,
                        uuid: result.uuid
                    });
                    anexos.forEach(a => {
                        if (a.tipo !== -1) arr.push(a)
                    });
                    arr.push({ tipo: -1 });


                    setAnexos(arr);
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }

    function removeArquivo(item) {

        let arr = [];
        anexos.forEach(it => {
            if (it.uuid !== item.uuid) {
                arr.push(it);
            }
        });
        setAnexos(arr);
    }

    return (
        <Container maxWidth='md'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={12}>
                        <TextField
                            value={profile && profile.name || ''}
                            disabled
                            fullWidth
                            label="Responável pelo preenchimento"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={value && value.processo && value.processo.nProcesso || ''}
                            disabled
                            fullWidth
                            label="Processo"
                            id="processo"
                            name="processo"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            value={value.processo.programa || ''}
                            disabled
                            fullWidth
                            label="Programa"
                            id="programa"
                            name="programa"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={value.protocolo} disabled
                            fullWidth
                            label="Protocolo"
                            id="protocolo"
                            name="protocolo"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            disabled={desabilitado}
                            label="CPF"
                            value={value.cpf || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleLostFocusCPF}
                            error={error.cpf.erro}
                            helperText={error.cpf.msg}
                            name="cpf"
                            id="cpf-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: CPFMask,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField disabled={podeEditarRequerente}
                            value={value.nome || ''}
                            onChange={handleChangeInputForm}
                            name="nome"
                            fullWidth
                            label="Requerente"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onBlur={handleTelLostFocus}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'none' : 'block' }}
                            name="telefone"
                            id="tel-mask-input"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask,
                            }}
                            variant="outlined"
                        />
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.telefone.erro}
                            helperText={error.telefone.msg}
                            label="Telefone"
                            value={value.telefone || ''}
                            onChange={handleChangeInputForm}
                            onFocus={handleTelFocus}
                            style={{ display: showTel1 ? 'block' : 'none' }}
                            name="telefone"
                            id="tel-mask-input1"
                            fullWidth
                            InputProps={{
                                inputComponent: TelMask2,
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            disabled={podeEditarRequerente}
                            error={error.email.erro}
                            helperText={error.email.msg}
                            fullWidth
                            label="Email"
                            value={value.email || ''}
                            onChange={handleChangeInputForm}
                            // InputProps={{
                            //     readOnly: !disable,
                            // }}
                            name="email"
                            id="email-input"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Solicitação</Typography>
                        <Paper className={classesLocal.paper}>
                            <span dangerouslySetInnerHTML={{
                                __html: value.descricao ? value.descricao : ''
                            }}></span>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <CKEditor
                            editor={ClassicEditor} disabled={desabilitado}
                            config={{
                                toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'underline', '|', 'fontSize', 'fontColor', 'fontBackGroundColor',
                                    '|', 'bulletedList', 'numberedList', '|', 'alignment', 'indent', 'outdent', '|', 'insertTable']
                            }}
                            data={txt}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.

                                editor.editing.view.change(writer => {
                                    writer.setStyle(
                                        "height",
                                        "300px",
                                        editor.editing.view.document.getRoot()
                                    );
                                });
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setTxt(data);
                            }}
                            onBlur={(event, editor) => {

                            }}
                            onFocus={(event, editor) => {

                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <TableContainer component={Paper} >
                            <Table aria-label="customized table" size={'small'}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sortDirection={false} className={classes.tableHead}>
                                            Documento
                                                </TableCell>
                                        <TableCell width='100px' align="center" className={classes.tableHead}>
                                            Ação
                                                </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {anexos && anexos.map((row, i) => (
                                        <TableRow key={i}>
                                            <TableCell>{row.nome}</TableCell>
                                            <TableCell align="center">
                                                {row.tipo === -1 && visivelEnviar() &&
                                                    <Tooltip title="Incluir documento">
                                                        <IconButton onClick={(e) => handleEscolheFile()}>
                                                            <AttachFileIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {row.tipo !== -1 &&
                                                    <center>
                                                        <Download doc={row} />
                                                        {visivelEnviar() &&
                                                            <Tooltip title='Remover'>
                                                                <IconButton onClick={(e) => removeArquivo(row)} >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </center>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={() => sair()} rel="noopener noreferrer">
                            Sair
                        </Button>

                        {visivelEnviar() &&
                            <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)}>
                                Responder
                        </Button>
                        }
                    </Grid>
                    
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg='Confirma envio da resposta?'
                        exec={confirmaResposta}
                    />
                </Grid>

            </Paper>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />

        </Container>
    );
}
