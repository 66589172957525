
import React, { useEffect, useState } from 'react'
import { Pie, Chart } from 'react-chartjs-2';

export default function GraficoPizza(props) {
    const { titulo, grafico } = props;

    useEffect(()=>{
        Chart.pluginService.register({
            afterDraw: function (chart, easing) {
                chart.data.labels.forEach(function (label, index, labelsArr) {
                    if (/\n/.test(label)) {
                        labelsArr[index] = label.split(/\n/)
                    }
                })
            }
          });
    }, [])

    const optionsPie = {
        legend: {
            position: "bottom",
            align: "start"
        },
        responsive: false,
        maintainAspectRatio: false
    }
    return (
        <>
            <Pie
                data={grafico}
                height={200}
                width={460}
                options={optionsPie}
            />
            <h4 >{titulo}</h4>
        </>)
}
