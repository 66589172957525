import React, { useState } from 'react';
import {
    Paper, Typography,
    Accordion, AccordionSummary, AccordionDetails, Grid, TextField, Tooltip, IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../../../constants';
import CreateIcon from '@material-ui/icons/Create';
import BuscaDadoDialog from '../../../util/BuscaDadoDialog';
import ConfirmDialog from '../../../util/ConfirmDialog';
import Service from '../../../services/Service';

export default function UnidadeSeiForm(props) {
    const classes = useStyles();
    const { setWait, setMensagem, parametro, setParametro, salvar } = props;
    const [showBuscaUnidadeDialog, setShowBuscaUnidadeDialog] = useState(false);
    const [showConfirmAlteracao, setShowConfirmAlteracao] = useState(false);
    const [unidadesSei, setUnidadesSei] = useState([]);

    function buscarUnidadeSei() {
        setWait(true);
        Service.GET('/api/sei/parametro/listarUnidades').then(json => {
            setWait(false);
            // console.log("listarUnidadesSei", json);
            setUnidadesSei(json);
            setShowBuscaUnidadeDialog(true)
        }).catch(error => {
            setWait(false);
            setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
        });
    }

    function handleEscolheUnidade(a) {
        setParametro({
            ...parametro,
            unidade: a
        })
        setShowConfirmAlteracao(true);
    }

    function confirmaAlteracao() {
        salvar();
    }

    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Unidade</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={3}>
                            <TextField
                                value={parametro && parametro.unidade && parametro.unidade.idUnidade || ''}
                                // disabled
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Unidade"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="idUnidade"
                                name="idUnidade"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={parametro && parametro.unidade && parametro.unidade.sigla || ''}
                                // disabled
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Sigla da Unidade"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="sigla"
                                name="sigla"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                value={parametro && parametro.unidade && parametro.unidade.descricao || ''}
                                // disabled
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Descrição da Unidade"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="descricao"
                                name="descricao"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title='Editar'>
                                <IconButton onClick={buscarUnidadeSei} >
                                    <CreateIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>

                </AccordionDetails>
            </Accordion>
            <BuscaDadoDialog
                open={showBuscaUnidadeDialog}
                setOpen={setShowBuscaUnidadeDialog}
                acao={handleEscolheUnidade}
                colunas={[
                    { nome: 'ID', campo: 'idUnidade' },
                    { nome: 'Sigla da Unidade', campo: 'sigla' },
                    { nome: 'Descrição da Unidade', campo: 'descricao' }
                ]}
                dados={unidadesSei} />
            <ConfirmDialog
                open={showConfirmAlteracao}
                setOpen={setShowConfirmAlteracao}
                titulo={'Confirmação'}
                msg='Deseja alterar a unidade?'
                exec={confirmaAlteracao}
            />
        </Paper>
    )

}