import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { grey, blue, red, yellow, green } from '@material-ui/core/colors';

import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import AtividadeModal from '../fazenda/AtividadeModal';
// import VerAtividadesModal from './VerAtividadesModal';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 300,
        // width: "100%"
        // width:'100%',   
        // height:'100%'
        // display: 'block',
        // width: '30vw',
        // transitionDuration: '0.3s',
        height: '150px'
    },
    removeBottomPadding: {
        paddingBottom: "0px",
        paddingTop: "0px"
    },
    hoje: {
        maxWidth: 300,
        height: '150px',
        border: 1, borderColor: 'blue', borderStyle: 'solid',
        background: "#D3D3D3",
    },
    mesNaoCorrete: {
        backgroundColor: grey[500],
    },
    mesCorrete: {
        backgroundColor: blue[500],
    },
    cellRed: {
        backgroundColor: red[500],
    },
    cellYellow: {
        backgroundColor: yellow[500],
    },
    cellGreen: {
        backgroundColor: green[500],
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    }
}));
export default function AtividadeCard(props) {
    const { dia, mes, ano, atividades, today, month, ultimoDia, disableAction, cellColorAction, menuAcoes, diaClick } = props;
    const classes = useStyles();
    const [descricao, setDescricao] = useState('');
    const [qtdAtividade, setQtdAtividade] = useState(0);
    
    
    const [menu, setMenu] = useState();

    const [anchorEl, setAnchorEl] = useState();

    useEffect(() => {
        let txt = '';
        let qtd = 0;
        // for (let item in atividades) {
            // let lst = atividades[item].dados.atividades;
            for (let a in atividades) {
                let at = atividades[a];
                let d = at.data.getDate();
                let m = at.data.getMonth();
                let aa = at.data.getFullYear();
                if(dia === d && mes === m && ano === aa){
                    txt += txt.length > 0 ? ", " + at.nome : at.nome;
                    qtd++;
                }
            }
        // }
        if ( menuAcoes ){
            let arrMenu=[];
            for( let x in menuAcoes){
                const ac = menuAcoes[x];
                txt = txt.length > 100 ? txt.substr(0, 100) + "..." : txt;
                if( (ac.showOnlyHasItem && ac.showOnlyHasItem === true) ){
                    if( qtd > 0) arrMenu.push([ac.id, ac.nome]);
                    // arrMenu.push(['verAtiv', 'Ver Atividade'] );
                }else{
                    arrMenu.push([ac.id, ac.nome]);
                }
            }
            setMenu(arrMenu);
        } else {
            txt = txt.length > 100 ? txt.substr(0, 100) + "..." : txt;
        }
        setDescricao(txt);
        setQtdAtividade(qtd);
    }, [atividades, dia, mes, ultimoDia])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAction = (e, acao) => {
        setAnchorEl(null);

        const ac = menuAcoes.find( ma => ma.id === acao[0])
        
        if( ac && ac.handleClick ){
            ac.handleClick(ac, dia, mes, ano);
        }
    };

    function getCor() {
        if (cellColorAction === 'red') {
            return classes.cellRed;
        } else if (cellColorAction === 'yellow') {
            return classes.cellYellow;
        } else if (cellColorAction === 'green') {
            return classes.cellGreen;
        } else {
            return classes.mesCorrete;
        }
    }
    const handleAvatarClick = (e)=>{
        e.stopPropagation();
        
        if( diaClick ){
            diaClick(dia, mes, ano, atividades);
        }

    }
    return (
        <Card 
            className={dia === today && mes === month ? classes.hoje : classes.root}
            variant="outlined" >
                { disableAction && 
                    <CardHeader 
                    avatar={
                        <div onClick={handleAvatarClick}>
                            <Avatar aria-label="recipe" className={mes === month ? getCor() : classes.mesNaoCorrete}>
                                {dia}
                            </Avatar>
                        </div>
                    }
                    subheader={qtdAtividade > 0 ? qtdAtividade + " atividades" : ""}
                  />    
                }
            { !disableAction && menu &&
                <CardHeader 
                    avatar={
                        <div onClick={handleAvatarClick}>
                        <Avatar aria-label="recipe" className={mes === month ? classes.mesCorrete : classes.mesNaoCorrete}>
                            {dia}
                        </Avatar>
                        </div>
                    }
                    action={
                        <IconButton aria-label="settings" onClick={handleClick}>
                            <MoreVertIcon />
                        </IconButton>
                    }
                    // title='Sem atividade'
                    subheader={qtdAtividade > 0 ? qtdAtividade + " atividades" : ""}
                />
            }
            { !disableAction && !menu && 
                <CardHeader 
                    avatar={
                        <div onClick={handleAvatarClick}>
                        <Avatar aria-label="recipe" className={mes === month ? classes.mesCorrete : classes.mesNaoCorrete}>
                            {dia}
                        </Avatar>
                        </div>
                    }
                    subheader={qtdAtividade > 0 ? qtdAtividade + " atividades" : ""}
                />
            }
            <CardContent className={classes.removeBottomPadding}>
                <Typography variant="caption" display="block" gutterBottom>
                    {descricao}
                </Typography>
            </CardContent>
            {menu &&
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleAction}
            >
                {
                    menu.map((m, index) =>(
                        <MenuItem onClick={(e)=>handleAction(e, m)} key={ index }>{m[1]}</MenuItem>
                    ))
                }
            </Menu>
            }
            
        </Card>
    );
}
