import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
} from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { useHistory } from "react-router-dom";
import Download from "../../util/Download";
import InputLabel from '@material-ui/core/InputLabel';
import { useStyles, ValidarCPF, ValidarCNPJ } from "../../constants";
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import MaskedInput from 'react-text-mask';
import ReCAPTCHA from "react-google-recaptcha";
import { useCurrentUser } from "../../server/UseCurrentUser";
import ConfirmDialog from '../../util/ConfirmDialog';
import Service from '../../services/Service';

export const useStylesLocal = makeStyles((theme) => ({
    paper: {
        width: '100%',
        height: '180px',
        overflow: 'auto'
    }
}));

const solicitacao = ['Problema de acesso', 'Erro de sistema', 'Sugestão de melhoria', 'Dúvida negocial', 'Dúvida de uso do sistema', 'Inconsistência dos dados', 'Outros']

function CPFMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CelMask(props) {
    const { inputRef, ...other } = props;
    function functMask() {
        let nume = props.value.replace(/\s/g, '').replace(/\(/g, '').replace(/\)/g, '').replace(/\-/g, '');

        if (nume.length == 11) return ['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/];
    }
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={functMask()}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
function CNPJMask(props) {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
let roboDisable = true;

export default function RegistrarChamadaForm(props) {
    const classes = useStyles();
    const { setMensagem, setWait, setErro, usuarioInterno } = props;
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmChamado, setShowConfirmChamado] = useState(false);
    const [numeroChamada, setNumeroChamada] = useState();
    const [error, setError] = useState(resetErro());
    const [value, setValue] = useState({});
    const [anexos, setAnexos] = useState([{ tipo: -1 }]);
    const history = useHistory();
    const [txt, setTxt] = useState('');
    const [captcha, setCaptcha] = useState(null);
    const [captchaKeySite, setCaptchaKeySite] = useState();
    const [profile, hasLoad] = useCurrentUser();

    useEffect(() => {
        if (hasLoad) {
            if (profile) {
                setValue({
                    cpf: profile.CPF,
                    nome: profile.name,
                    email: profile.email,
                    telefone: profile.telefone,
                });
                roboDisable = false;
            } else {
                buscaCaptchaKeySite();
            }
        }

    }, [profile])

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });

    };
    const handleChangeTxtForm = (event) => {
        setTxt(event.target.value,);

    };
    function getTipoSolicitacao() {
        if (value.solicitacao === 'Problema de acesso') return "PROBLEMA_ACESSO";
        if (value.solicitacao === 'Erro de sistema') return "ERRO_SISTEMA";
        if (value.solicitacao === 'Sugestão de melhoria') return "SUGESTAO_MELHORIA";
        if (value.solicitacao === 'Dúvida negocial') return "DUVIDA_NEGOCIAL";
        if (value.solicitacao === 'Dúvida de uso do sistema') return "DUVIDA_USO_SISTEMA";
        if (value.solicitacao === 'Inconsistência dos dados') return "INCONSISTENCIA_DADO";
        if (value.solicitacao === 'Outros') return "OUTROS";
    }
    function confirmaAprovacao() {
        let dado = value
        dado.descricao = txt;
        dado.tipo = getTipoSolicitacao();
        dado.anexos = [];//anexos;
        anexos.forEach(a => {
            if (a.tipo !== -1) dado.anexos.push(a);
        });
        submitForm(dado);
    }

    function confirmaNumeroChamado() {
        history.replace("/home")
    }

    function submitForm(dado) {
        let url = '/api/suporte';
        if (roboDisable) {
            url += '/captcha?g-recaptcha-response=' + captcha;
        }
        setWait(true);
        if (usuarioInterno) {
            Service.POST(url, dado).then(json =>{
                setWait(false);
                setShowConfirmChamado(true)
                setNumeroChamada(json.numeroChamada);
            }).catch(error =>{
                setWait(false);
                setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
            })
            // fetch(url, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Accept: 'application/json',
            //         Authorization: `Bearer ${localStorage.accessToken}`
            //     },
            //     body: JSON.stringify(dado),
            // }).then(response => {
            //     if (response.ok) {
            //         setWait(false);
            //         response.json().then(json => {
            //             setShowConfirmChamado(true)
            //             setNumeroChamada(json.numeroChamada);
            //         });
            //     } else {
            //         response.json().then((error) => {
            //             setWait(false);
            //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            //         });
            //     }
            // }).catch(error => {
            //     setWait(false);
            //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            // });
        } else {
            fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json'
                },
                body: JSON.stringify(dado),
            }).then(response => {
                if (response.ok) {
                    setWait(false);
                    response.json().then(json => {
                        setShowConfirmChamado(true)
                        setNumeroChamada(json.numeroChamada);
                    });
                } else {
                    response.json().then((error) => {
                        setWait(false);
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        }
    }

    function resetErro() {
        return {
            cpf: { erro: false, msg: '' },
            cnpj: { erro: false, msg: '' },
        }
    }

    function buscaCaptchaKeySite() {
        fetch('/api/captcha')
            .then(ret => ret.text())
            .then(key => setCaptchaKeySite(key))
    }

    const handleLostFocusCPF = (e) => {
        if (usuarioInterno) return;
        setError({
            ...error,
            cpf: {
                erro: false,
                msg: ''
            }
        });
        if (!value || !value.cpf) return;
        const cpf = value.cpf.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (ValidarCPF(cpf)) {
            setWait(true);
            fetch("/api/v1/cpf/" + cpf)
                .then(res => {
                    if (res.status <= 300) return res.json()
                })
                .then(
                    (result) => {
                        setWait(false);
                        setError(resetErro);
                        if (!result) {
                            setValue({
                                ...value, nome: '', telefone: '', email: ''
                            });
                        } else {
                            setValue({
                                ...value,
                                idUser: result.id,
                                nome: result.name,
                                email: result.email,
                                telefone: result.telefone
                            });
                        }
                    },
                    (error) => {
                        setWait(false);
                    }
                )
        } else {
            setError({
                ...error,
                cpf: {
                    erro: true,
                    msg: 'CPF inválido'
                }
            });
        }
    }

    function disableButon() {
        let disable = true;
        if (usuarioInterno &&
            txt &&
            value.solicitacao) {
            disable = false;
        }
        if (roboDisable && value.solicitacao && txt) {
            disable = captcha === null;
        }
        return disable;
    }

    const handleLostFocusCNPJ = (e) => {
        // if (disable) return; // para não pesquisar quando text do cnpj estiver disable
        if (value.cnpj === undefined || value.cnpj === null) return; // para não pesquisar quando text do cnpj estiver disable

        setError({
            ...error,
            cnpj: {
                erro: false,
                msg: ''
            }
        });
        const cnpj = value.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (cnpj.length === 0) return;
        setWait(true);
        if (ValidarCNPJ(cnpj)) {
            fetch("/api/v1/cnpj/" + cnpj)
                .then(res => (res.status <= 300) ? res.json() : setErro(res))
                .then(
                    (result) => {
                        setWait(false);
                        if (!result) return;
                        setValue({
                            ...value,
                            razaoSocial: result.razaoSocial,
                        });
                        //buscaEmpresa(result.id);
                    },
                    (error) => {
                        setWait(false);
                    }
                )
        } else {
            setWait(false);
            setError({
                ...error,
                cnpj: {
                    erro: true,
                    msg: 'CNPJ inválido'
                }
            });
        }
    }
    // function buscaEmpresa(id) {
    //     setWait(true);
    //     fetch("/api/empresa/" + id, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Accept: 'application/json',
    //             Authorization: `Bearer ${localStorage.accessToken}`
    //         },
    //         credentials: 'include'
    //     }).then(response => {
    //         if (response.ok && (response.status < 300)) {
    //             response.json().then((json) => {
    //                 setWait(false);
    //                 setValue({
    //                     ...value,
    //                     cnpj: json.cnpj,
    //                     razaoSocial: json.razaoSocial,
    //                     email: value.email ? value.email : json.email,
    //                     telefone: value.telefone ? value.telefone : json.telefone,
    //                 });
    //             });
    //         } else {
    //             response.json().then((error) => {
    //                 setWait(false);
    //                 setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    //             });
    //         }
    //     }).catch(error => {
    //         setWait(false);
    //         setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
    //     });
    // }

    const handleEscolheFile = () => {
        // if (disable) return;
        let file = document.getElementById("file");
        file.click();
    }
    function removeArquivo(item) {
        // if (disable) return;
        let arr = [];
        anexos.forEach(it => {
            if (it.uuid !== item.uuid) {
                arr.push(it);
            }
        });
        setAnexos(arr);
    }
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();

        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);

        dataArray.append('file', file.files[0]);

        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    if (!result) return;
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();
                    let arr = [];
                    arr.push({
                        id: result.id,
                        nome: nm,
                        uuid: result.uuid
                    });
                    anexos.forEach(a => {
                        if (a.tipo !== -1) arr.push(a)
                    });
                    arr.push({ tipo: -1 });
                    setAnexos(arr);
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }
    function validaAnexo(anexo) {
        if (!anexo) return false;

        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }
    function onChange(value) {
        setCaptcha(value);
    }
    return (

        <Container maxWidth='lg'>
            <Paper >
                <Grid container spacing={1} className={classes.root}>
                    <Grid item xs={6}>
                        <TextField
                            value={value.cpf || ''}
                            fullWidth
                            label="CPF"
                            id="cpf"
                            name="cpf"
                            onChange={handleChangeInputForm}
                            onBlur={handleLostFocusCPF}
                            error={error.cpf.erro}
                            helperText={error.cpf.msg}
                            aria-describedby="nova-helper-text"
                            autoFocus
                            variant="outlined"
                            InputProps={{
                                inputComponent: CPFMask,
                                readOnly: usuarioInterno && usuarioInterno === true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            //disabled={disable && disable === true}
                            value={value.nome || ''}
                            onChange={handleChangeInputForm}
                            fullWidth
                            label="Nome Completo"
                            id="nome" name="nome"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                            InputProps={{
                                readOnly: usuarioInterno && usuarioInterno === true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            //disabled={disable && disable === true}
                            value={value.telefone || ''}
                            fullWidth
                            label="Telefone"
                            id="telefone"
                            name="telefone"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                            InputProps={{
                                inputComponent: CelMask,
                                readOnly: usuarioInterno && usuarioInterno === true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            //disabled={disable && disable === true}
                            value={value.email || ''}
                            fullWidth
                            label="E-mail"
                            id="email" name="email"
                            onChange={handleChangeInputForm}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                            InputProps={{
                                readOnly: usuarioInterno && usuarioInterno === true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            value={value.cnpj || ''}
                            fullWidth
                            id="cnpj-mask-input"
                            name="cnpj"
                            label="CNPJ"
                            onChange={handleChangeInputForm}
                            onBlur={handleLostFocusCNPJ}
                            error={error.cnpj.erro}
                            helperText={error.cnpj.msg}
                            InputProps={{
                                inputComponent: CNPJMask,
                            }}
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={(value.razaoSocial) || ''}
                            fullWidth
                            id="razaoSocial" name="razaoSocial"
                            onChange={handleChangeInputForm}
                            label="Razão Social"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="tipo" >Tipo de Solicitação</InputLabel>
                            <Select
                                id="solicitacao"
                                name='solicitacao'
                                label="Tipo de Solicitação"
                                placceholder="solicitacao" onChange={handleChangeInputForm}
                                value={value.solicitacao || ""} >
                                {solicitacao && solicitacao.map((tp, i) => (
                                    <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            multiline
                            rows={6}
                            value={txt || ''}
                            fullWidth
                            id="txt"
                            name="txt"
                            onChange={handleChangeTxtForm}
                            label="Descrição da Ocorrência"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Anexos</Typography>
                    </Grid> */}
                    <TableContainer component={Paper} >
                        <Typography variant="h5" gutterBottom>Anexos</Typography>
                        <Table aria-label="customized table" size={'small'}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sortDirection={false} className={classes.tableHead}>
                                        Documento
                                    </TableCell>
                                    <TableCell width='100px' align="center" className={classes.tableHead}>
                                        Ação
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {anexos && anexos.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell>{row.nome}</TableCell>
                                        <TableCell>
                                            {row.tipo === -1 &&
                                                <Tooltip title="Incluir documento">
                                                    <IconButton onClick={(e) => handleEscolheFile()}>
                                                        <AttachFileIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {row.tipo !== -1 &&
                                                <center>
                                                    <Download doc={row} />

                                                    <Tooltip title='Remover'>
                                                        <IconButton onClick={(e) => removeArquivo(row)} >
                                                            <RemoveIcon />
                                                        </IconButton>
                                                    </Tooltip>


                                                </center>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {
                                    (!anexos || anexos.length === 0) &&
                                    <TableRow>
                                        <TableCell>
                                            Nenhum registro encontrato!
                                        </TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid item xs={12}>
                        <Box component="span" m={1} />
                    </Grid>
                    {roboDisable && captchaKeySite &&
                        <Grid item xs={12}>
                            <ReCAPTCHA
                                sitekey={captchaKeySite}
                                onChange={onChange}
                            />
                        </Grid>
                    }
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="primary"
                            onClick={() => setShowConfirm(true)}
                            rel="noopener noreferrer"
                            disabled={disableButon()}
                        >
                            Registrar
                            </Button>
                    </Grid>
                </Grid>
                <ConfirmDialog
                    open={showConfirm}
                    setOpen={setShowConfirm}
                    titulo={'Confirmação'}
                    msg='Confirma registro de chamado?'
                    exec={confirmaAprovacao}
                />
                <ConfirmDialog
                    open={showConfirmChamado}
                    setOpen={setShowConfirmChamado}
                    disableCancelar={true}
                    titulo={'Número chamado'}
                    msg={'Número do chamado: ' + numeroChamada}
                    exec={confirmaNumeroChamado}
                />
            </Paper>
            <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />
        </Container>
    );
}
