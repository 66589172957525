import React, { useState } from 'react';
import {
    Button, Grid, Paper, Typography,
    Accordion, AccordionSummary, AccordionDetails, IconButton, Tooltip
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, API_BASE_URL } from "../../../constants";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Download from '../../../util/Download';

let name = 'Banco do Brasil'
let currentRow = null;

export default function FormalizacaoForm(props) {
    const classes = useStyles();
    const { value, setValue, setMensagem, setWait } = props;
    const [txt, setTxt] = useState('');


    function imprimi() {

        var a = document.createElement("a");

        a.href = API_BASE_URL + "/api/projeto/formalizacao/" + value.id;

        a.setAttribute("target", "_blank");
        a.click();

    }

    const handleFormalizacao = () => {
        currentRow = {
            check: false,
            checkTecnico: false,
            tipo: "Certidão Negativa ou Positiva com efeito de Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União - RFB aos sócios-administradores;",
        };

        fileClick();
    }
    function fileClick() {
        let file = document.getElementById("file");
        file.click();
    }

    function validaAnexo(anexo) {
        if (!anexo) return false;
        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();
        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);
        dataArray.append('file', file.files[0]);
        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();

                    if (currentRow) {
                        console.log("currentRow", currentRow);
                        console.log("nm", nm);
                        console.log("currentRow", result.uuid);
                        console.log("value", value);
                        setValue({
                            ...value,
                            formalizacaoPVS: {
                                tipo: "Formalização do pedido",
                                check: false,
                                checkTecnico: false,
                                nome: nm,
                                uuid: result.uuid
                            }
                        })

                        // let arr = [...anexos];
                        // arr.forEach(i => {
                        //     if (i === currentRow) {
                        //         i.nome = nm;
                        //         i.uuid = result.uuid;
                        //     }
                        // });
                        // setAnexos(arr);
                        currentRow = null;
                    } else {
                        console.log("False>>>>>>>>>>\nnm", nm);
                        console.log("currentRow", result.uuid);
                        // let arr = [];
                        // arr.push({
                        //     tipo: tipoDocumento,
                        //     nome: nm,
                        //     uuid: result.uuid
                        // });
                        // anexos.forEach(a => {
                        //     if (a.tipo !== -1) arr.push(a)
                        // });
                        // arr.push({ tipo: -1 });
                        // setAnexos(arr);
                    }
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }


    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Formalização</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12} >
                            <Typography variant="h6" gutterBottom>Formalização do pedido</Typography>

                            <p>Brasilia(DF) - 00/00/0000</p>
                            <p>Exma. Senhora</p>
                            <p>Secratária de Estado de Empreendedorismo do Distrito Federal,</p>
                            <br />
                            <br />
                            <p>Nome da Empresa: {name}</p>
                            <p>Cnpj: {'aaaaaa'}</p>
                            <br />
                            <p>
                                vem, com base na legislação em vigor, requerer a V.Exa. que seja analizado o Projeto de Viabilidade Simplificada - PVS para afins
                                de concessão do Programa de Apoio ao Desenvolvimento do Distrito Federal - Desenvolve-DF,em anexo, acompanhada de toda documentação
                                exigida por lei.
                            </p>
                            <br />
                            <p>
                                Assuto total responsabilidade pela veracidade das informações apresentadas e declaro conchecer os objetivos e as exigências do programa,
                                ciente de que,em caso de notificação para apresentação de documentação ou saneamento de irregularidades, o não atendimento no prazo determinado, implicará
                                no indeferimento da análise do pleito.
                            </p>
                            <br />
                            <p>
                                Declaro ter ciência de que todas as intimações serão realizadas por meio do envio de e-mail, bem como por SMS e/ou aplicativo de mensagens intantâneas e que devo manter
                                esses dados atualizados nos cadastros da SEDET e Terracap, considerando-se válidas e eficazes as intimações enviados aos endereços abaixo indicados.
                            </p>

                            <br />
                            <p>
                                Informo, nesta oportunidade
                            </p>

                            <br />
                            <p>
                                e-mail(para recebimento de intimações): {'aaa@gmail.com'}
                            </p>
                            <p>
                                Telefone celular:{'(61) 00000-0000'}
                            </p>

                            <br />
                            <p>
                                Atenciosamente,
                            </p>
                            <br />
                            <p>
                                ________________________________<br />
                                (Nome e cargo na empresa)
                            </p>



                        </Grid>
                        <Grid container justify="flex-end" className={classes.btns}>
                            <Tooltip title="Incluir Formalização">
                                <IconButton onClick={() => handleFormalizacao()}>
                                    <AttachFileIcon />
                                </IconButton>
                            </Tooltip>
                            {value.formalizacaoPVS && <Download doc={value.formalizacaoPVS} />}
                            {/* <Tooltip title="Visualizar">
                                <IconButton onClick={() => handleFormalizacaoVisualizar()}>
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip> */}
                            <Button variant="contained" color="primary" onClick={imprimi} >
                                Imprimir Formalização
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />
            </Accordion>
        </Paper>
    );
}
