import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import MaskedInput from 'react-text-mask'
import BuscaDadoDialog from "./BuscaDadoDialog";
import {
    TextField
  } from '@material-ui/core';
  
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    // width: 400,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
function processoMask(props) {
  const { inputRef, ...other } = props;
  function functMask(a) {
      let nume = a.replace(/\D/g, '');
      if (nume.length <= 13) return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
      return [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]; // 01234-000 000 01/2021-24.
  }
  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
              inputRef(ref ? ref.inputElement : null);
          }}
          mask={functMask}
          placeholderChar={'\u2000'}
          showMask
      />
  );
}
export default function ProcessoField(props) {
  const classes = useStyles();
  const {handleChangeInputForm, valor, nome, acao, profile, setWait, fieldReadOnly} = props
  const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
  const [processos, setProcessos] = useState([]);
  
  function handleSelecionarProcesso(processo){
    if( acao ){
        acao(processo);
    }
  }
  function handleLocalizarProcesso() {
    setWait(true);
    fetch("/api/gerenciarProcesso/filtro",
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Authorization': `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify({
                cnpjs: profile.empresas
            }),
        }).then(res => res.json()).then((result) => {
            setWait(false);
            if (result.status && result.status > 300) {
                // setMensagem(result);
            } else {
                setProcessos(result);

                setShowBuscaDadoDialog(true);
            }
        });
  }
  return (
    <div className={classes.root}>
      <TextField
                    label={nome?nome:"Processo"}
                    value={valor}
                    onChange={handleChangeInputForm}
                    name="processo"
                    id="processo-mask-input"
                    fullWidth
                    InputProps={{
                      inputComponent: processoMask,
                      readOnly: (fieldReadOnly && fieldReadOnly === true)
                    }}
                    variant="outlined"
                  />
        { fieldReadOnly !== true &&
        <IconButton type="submit" className={classes.iconButton} aria-label="search" onClick={handleLocalizarProcesso}>
          <SearchIcon />
        </IconButton>
        }
      
      <BuscaDadoDialog
            open={showBuscaDadoDialog}
            setOpen={setShowBuscaDadoDialog}
            acao={(p) => handleSelecionarProcesso(p)}
            colunas={[
                { nome: 'CNPJ', campo: 'cnpj' },
                { nome: 'Nome', campo: 'razaoSocial' },
                { nome: 'Processo', campo: 'nProcesso' },
            ]}
            dados={processos} />
        
    </div>
  );
}
