import React from 'react';
import {Button, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function TextoPadaoDialog(props) {
  const {open, setOpen, exec, assuntoTexto} = props;

  const [assuntoDado, setAssuntoDado] = React.useState('');

  const handleChangeAssunto = (event) => {
    setAssuntoDado(event.target.value);
    };

  const handleConfirma=()=>{
    setOpen(false);
    if( exec ) exec(assuntoDado);
  }
  return (
    <div>
      <Dialog open={open} onClose={()=> setOpen(false)} aria-labelledby="form-dialog-title" fullWidth >
            <DialogTitle id="form-dialog-title">Selecione o Texto Padrão</DialogTitle>
            <DialogContent>
                <FormControl variant="outlined" fullWidth >
                    <InputLabel id="demo-simple-select-outlined-label">Título</InputLabel>
                    <Select
                        labelId="lb-idresponsavel"
                        value={assuntoDado}
                        id="idresponsavel"
                        onChange={handleChangeAssunto}
                        label="Título"
                    >
                        {assuntoTexto.map(v =>
                            <MenuItem key={v.id} value={v}>
                                <em>{v.assunto}</em>
                            </MenuItem>
                        )}

                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={() => { setOpen(false); }} rel="noopener noreferrer">
                    Sair
            </Button>
                <Button variant="contained" color="primary" onClick={handleConfirma}  >
                    Confirmar
            </Button>
            </DialogActions>
        </Dialog>
    </div>
  );
}
