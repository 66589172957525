import React, { useEffect, useState } from 'react';
import {
    Grid, Button,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { FormataMoeda, useStyles } from '../../../constants';

import ConfirmDialog from '../../../util/ConfirmDialog';
import GenericTable from "../../../components/template/GenericTable";
import { useCurrentUser } from "../../../server/UseCurrentUser";

let idLeilao = 0;
let dadoRenuncia;
export default function LeiloesTable(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait, setErro } = props;
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const history = useHistory();
    const [showConfirmExclusao, setShowConfirmExclusao] = useState(false);
    let [profile] = useCurrentUser();
    const [options, setOptions] = useState(['Visualizar', 'Alterar', 'Excluir']);


    useEffect(() => {
        setTitulo("Resultado consolidado de leilões");
        buscarDadosApoio();
        
    }, []);
    useEffect(() => {
        if (profile){
            if( profile.regras.find(r => r === "047")){
                setOptions(['Visualizar']);
            }
        }
    }, [profile]);

    async function buscarLeilao(apoio) {
        fetch("/api/fundefeLeilao")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    let arr = [];
                    for (let x in result) {
                        const apoioDado = apoio && apoio.filter(l => l.exercicio === result[x].previsao.exercicio);
                        arr.push({
                            ...result[x],
                            desagio: ((1-(result[x].valorArrematado / result[x].totalLiberado)) * 100),
                            totalDesagio: (result[x].totalLiberado - result[x].valorArrematado),
                            capAnual: apoioDado.length > 0 ? ((Math.pow((1 + apoioDado[0].juros), 12)) - 1) : '',
                            cdiAtual: (result[x].cdiAtual !== undefined ? (result[x].cdiAtual) : "")
                        })
                    }
                    setFonteDeDados(arr)
                }
            });
    }
    async function buscarDadosApoio() {

        let result = await fetch("/api/fundefeDadoApoio");
        let json = await result.json()
        // setDadoApoio( json );
        buscarRenuncia(json);
        buscarLeilao(json);

    }

    function buscarRenuncia() {
        fetch("/api/fundefeRenuncia")
            .then(res => res.json()).then((result) => {
                setWait(false);
                if (result.status && result.status > 300) {
                } else {
                    dadoRenuncia = result;
                }
            });
    }

    const handleActionSolicitarClick = (index, acao, dado) => {
        if (acao === "Visualizar") {
            history.replace("/fundefe/viewLeilao/" + dado.id);
        } else if (acao === "Alterar") {
            history.replace("/fundefe/editarLeilao/" + dado.id);
        } else if (acao === "Excluir") {
            excluir(dado.id);
        }
    }
    function excluir(id) {
        setShowConfirmExclusao(true);
        idLeilao = id;
    }

    function confirmaExclusao() {
        setWait(true);
        fetch('/api/fundefeLeilao/' + idLeilao, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            }
        }).then(response => {
            if (response.ok) {
                setWait(false);

                let value = [];
                if (fonteDeDados && (fonteDeDados.length > 0)) {
                    for (let i = 0; i < fonteDeDados.length; i++) {
                        if (fonteDeDados[i].id !== idLeilao) {
                            value.push(fonteDeDados[i]);
                        }
                    }
                }
                setFonteDeDados(value);

            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }
    
    function formataNumeroComPorcento(val) {
        let x = Number(val).toFixed(4) + '%'; 
        return x.replace(".", ",");
    }
    function formatar(val) {
        if (val) {
            return FormataMoeda(val);
        }
        return '';
    }
    const desabilitaAcaoAtenderTable = (acao, dado) => {

        if (acao === "Excluir" && dado) {
            for (let x in dadoRenuncia) {
                const dadoRe = dadoRenuncia[x];
                if (dadoRe.previsao.cnpj === dado.previsao.cnpj &&
                    dadoRe.previsao.exercicio === dado.previsao.exercicio &&
                    dadoRe.previsao.programa === dado.previsao.programa) return true;
            }
        }
        return false;
    }
    function isSomenteConsulta(){
        if( profile && profile.regras.find(r => r === "047")){
            return true;
        }
        return false;
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <GenericTable
                actionClick={handleActionSolicitarClick}
                handleDisableAction={desabilitaAcaoAtenderTable}
                options={['Visualizar', 'Alterar', 'Excluir']}
                dados={fonteDeDados}
                colunas={[
                    { nome: 'Exercicio', campo: 'previsao.exercicio' },
                    { nome: 'CNPJ', campo: 'previsao.cnpj' },
                    { nome: 'Beneficiário', campo: 'previsao.razaoSocial' },
                    { nome: 'Leilão', campo: 'leilao' },
                    { nome: 'Taxa Cap anual', campo: 'capAnual', transformacao: formataNumeroComPorcento,  align: "right" },
                    { nome: 'Taxa CDI Dt atual', campo: 'cdiAtual', transformacao: formataNumeroComPorcento,  align: "right" },
                    { nome: 'Total liberado', campo: 'totalLiberado', transformacao: formatar,  align: "right" },
                    { nome: 'Valor arrematado', campo: 'valorArrematado', transformacao: formatar,  align: "right" },
                    { nome: 'Deságio', campo: 'desagio',  align: "right", transformacao: formataNumeroComPorcento },
                    { nome: 'Total Deságio', campo: 'totalDesagio', transformacao: formatar,  align: "right" },
                    { nome: 'Ação', campo: 'action' }
                ]} />

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                    Sair
                </Button>
                {!isSomenteConsulta() &&
                <Button variant="contained" color="primary" href={"/fundefe/cadastrarResultadoLeilao"} >
                    Novo
                </Button>}
            </Grid>
            <ConfirmDialog
                open={showConfirmExclusao}
                setOpen={setShowConfirmExclusao}
                titulo={'Confirmação'}
                msg='Confirma exclusão do item selecionado?.'
                exec={confirmaExclusao}
            />
        </Grid >


    );
}
