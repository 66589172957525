import React, { useEffect, useState } from 'react';
import {
    Box, Grid, IconButton, Paper, Menu, MenuItem,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip, Button
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CampoBusca from "../../util/CampoBusca";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';
import { useStyles, FormataDataComHora } from "../../constants";
import { useHistory, useLocation } from "react-router-dom";
import { useCurrentUser } from "../../server/UseCurrentUser";
import { useUnidades } from "../../server/UseUnidades";

// const optionsGeral = [
//     'Adesão DESENVOVE DF',
//     'Atestado',
//     'Cancelamento',
//     'Concessão DESENVOVE DF',
//     'Convalidação',
//     'Declaração Cumprimento de meta',
//     'PVS (projeto Viabilidade Sinmplificado)',
//     'Prorrogação prazo',
//     'Recurso',
//     'Revogação',
//     'Outros'
// ];
// const optionsUnate = [
//     'Recurso',
//     'Cancelamento',
//     'Prorrogação prazo',
//     'Reposta Complemento Informação',
//     'Reposta notificação',
//     'Requerimento outros'
// ];
const optionsGeral = [
    'Cancelamento',
    'Prorrogação prazo',
    'Recurso',
    'Outros'
];
const optionsUnate = [
    'Recurso',
    'Cancelamento',
    'Prorrogação prazo',
    'Resposta Complemento Informação',
    'Resposta notificação',
    'Requerimento outros'
];
let options = [];
let pageLoaded = false;
let dados = null;
let elapseTime = 0;
let tempo = 600;
let filtroCampo = null;
let indexProcesso = 0;
let qtd = 0;

export default function GerenciaProcessoTable(props) {
    const classes = useStyles();
    const { setErro, setWait, setTitulo } = props;
    const location = useLocation();
    const history = useHistory();
    const [unidades] = useUnidades();
    const [processos, setProcessos] = React.useState([]);
    const [pagina, setPagina] = useState(5);
    const [bloco, setBloco] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openAction = Boolean(anchorEl);
    let [profile, carregandoProfile] = useCurrentUser();

    const [ordem, setOrdem] = useState({
        campo: '',
        ordem: 'asc'
    });

    useEffect(() => {
        setTitulo("Gerenciar Processos")
        if (!carregandoProfile) {
            if (isUnate()) {
                options = optionsUnate;
            } else {
                options = optionsGeral;
            }
            if (hasRegra037()) {
                if (!options.find(o => o === 'Movimentar Processo')) {
                    options.push('Movimentar Processo');
                }
            }
        }
    }, [carregandoProfile]);

    const handleAcao = (e, index) => {
        indexProcesso = index;
        setAnchorEl(e.currentTarget);
    }

    const isUnate = () => {
        return profile.regras.find(r => r === '011');
    }
    const hasRegra037 = () => {
        return profile.regras.find(r => r === '037');
    }

    const handleActionClick = (action) => {
        let acao = options[action];
        filtroCampo = null;
        if (acao === 'Movimentar Processo') {
            let processo = processos[indexProcesso];
            history.replace("/gerenciaProcesso/movimentarProcesso/" + processo.idProcesso);
        } else if(acao === 'Visualizar SEI'){
            handlerVisualizarSei();
        } else if (isUnate()) {
            handleAcaoUnate(acao);
        } else {
            handleAcaoGeral(acao);
        }
        setAnchorEl(null);
    };
    function handlerVisualizarSei(acao){
        let processo = processos[indexProcesso];
        console.log('acao', processo)
        history.replace("/gerenciaProcesso/consultarProcessoSei/?nProcesso=" + processo.nProcesso);
    }
    function handleAcaoUnate(acao) {
        let processo = processos[indexProcesso];

        if (acao === 'Recurso') {
            history.replace("/gerenciaProcesso/unate/recursos?id=" + processo.idProcesso + "&back=/gerenciaProcesso");
        } else if (acao === 'Cancelamento') {
            history.replace("/gerenciaProcesso/unate/solicitarCancelamentoTable?id=" + processo.idProcesso + "&back=/gerenciaProcesso");
        } else if (acao.includes("Prorroga")) {
            history.replace("/gerenciaProcesso/unate/solicitarProrrogacaoTable?id=" + processo.idProcesso + "&back=/gerenciaProcesso");
        } else if (acao === 'Requerimento outros') {
            history.replace("/gerenciaProcesso/unate/solicitarRequerimentoOutrosTable?id=" + processo.idProcesso + "&back=/gerenciaProcesso");
        } else if (acao.includes('notificação')) {
            history.replace("/gerenciaProcesso/unate/solicitarNotificaoTable?id=" + processo.idProcesso + "&back=/gerenciaProcesso");
        } else if (acao.includes('Complemento')) {
            history.replace("/gerenciaProcesso/unate/solicitarComplementoTable?id=" + processo.idProcesso + "&back=/gerenciaProcesso");
        }
    }
    function handleAcaoGeral(acao) {
        let processo = processos[indexProcesso];
        if (acao === 'Recurso') {
            history.replace("/gerenciaProcesso/solicitarRecursoForm?IdProcesso=" + processo.idProcesso + "&back=/gerenciaProcesso");
        } else if (acao === 'Cancelamento') {
            history.replace("/gerenciaProcesso/solicitarCancelamentoForm?IdProcesso=" + processo.idProcesso + "&back=/gerenciaProcesso");
        } else if (acao.includes("Prorroga")) {
            history.replace("/gerenciaProcesso/solicitarProrrogacaoForm?IdProcesso=" + processo.idProcesso + "&back=/gerenciaProcesso");
        } else if (acao.includes("Outros")) {
            history.replace("/gerenciaProcesso/solicitarRequerimentoForm?IdProcesso=" + processo.idProcesso + "&back=/gerenciaProcesso");
        }
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleSearch = (e) => {
        filtroCampo = e.target.value;
        if (performance.now() - elapseTime > tempo) {
            disparaSearch();
        }

        elapseTime = performance.now();
    }

    function disparaSearch() {
        setTimeout(function () {
            if (performance.now() - elapseTime > tempo) {
                setBloco(0);
                filtraDado(filtroCampo);
            } else {
                disparaSearch();
            }
        }, tempo);
    }

    function getTituloSituacao(row) {
        return "Visualizar";
    }
    useEffect(() => {
        filtraDado();
    }, [ordem, pagina, bloco]);

    useEffect(() => {
        if (!pageLoaded) {
            setWait(true);
            fetch("/api/gerenciarProcesso",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                        'Authorization': `Bearer ${localStorage.accessToken}`
                    }
                }).then(res => res.json()).then((result) => {
                    //   const lst = result.sort((a, b) => ((a.perfil.name < b.perfil.name) ? -1 : 1));
                    setWait(false);
                    if (result.status && result.status > 300) {
                        setErro(result);
                    } else {
                        dados = result;
                        filtraDado();
                    }
                });
            pageLoaded = true;
        }
    }, [processos]);

    function filtraDado() {
        if (!dados) return;

        let arr = [];
        let arrOrdenado = ordenar(dados);
        arrOrdenado.forEach(d => {
            if (filtroCampo) {
                let campos = [];
                if (filtroCampo.includes('"')) {
                    campos.push(filtroCampo.split('"')[1])
                } else {
                    campos = filtroCampo.split(' ');
                }
                for (var y in campos) {
                    if (d.cnpj && d.cnpj.toUpperCase().includes(campos[y].toUpperCase())) {
                        arr.push(d);
                        break;
                    }
                    if (d.razaoSocial && d.razaoSocial.toUpperCase().includes(campos[y].toUpperCase())) {
                        arr.push(d);
                        break;
                    }
                    if (d.nProcesso && d.nProcesso.includes(campos[y])) {
                        arr.push(d);
                        break;
                    }
                    if (d.idUnidade !== 0 && unidades && (unidades.find(u => u.id === d.idUnidade).nome).toUpperCase().includes(campos[y].toUpperCase())) {
                        arr.push(d);
                        break;
                    }
                    let p = d.nProcesso;
                    let x = d.cnpj;

                    if (p && p.replace(/[^\w\s]/gi, '').includes(campos[y])) {
                        arr.push(d);
                        break;
                    }
                    if ( x && x.replace(/[^\w\s]/gi, '').includes(campos[y])) {
                        arr.push(d);
                        break;
                    }

                    let val = FormataDataComHora(new Date(d.dataCriacao));
                    if (val && val.includes(campos[y])) {
                        arr.push(d);
                        break;
                    }
                }
            } else {
                arr.push(d);
            }
        });
        let arrBloco = [];
        qtd = arr.length;

        let x = 0;
        let index = 0;
        for (x = (bloco * pagina); x < arr.length; x++) {
            let d = arr[x];
            arrBloco.push(d);
            if (++index >= pagina) break;
        }

        setProcessos(arrBloco);
    }
    function ordenar(arr) {
        let ordenado = arr;
        if (ordem.campo.length > 0) {
            const asc = (ordem.ordem === 'asc') ? 1 : -1;
            ordenado = ordenado.sort((a, b) => ((a[ordem.campo] < b[ordem.campo]) ? 1 * asc : -1 * asc));
        }
        return ordenado;
    }

    const handleChangePage = (event, newPage) => {
        setBloco(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPagina(event.target.value);
        // setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);
    };
    const hasRegraCadastrarProcesso = () =>{
        if( profile ){
            return profile.regras.filter(r => r === "051").length > 0 ;
        }
        return false;
    };

    function createSortHandler(coluna) {

        let or = { ...ordem };
        if (or.campo === coluna) {
            or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
        } else {
            or.campo = coluna;
            or.ordem = 'asc';
        }
        setOrdem(or);

        setBloco(0);
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <Grid item xs={12} >
                <CampoBusca handleSearch={handleSearch} />
            </Grid>
            <Grid item xs={12} >
                <TableContainer component={Paper} className={classes.root}>
                    <Table className={classes.table} aria-label="customized table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'nProcesso'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('nProcesso')}
                                    >
                                        Processo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection={false} className={classes.tableHead}>
                                    <TableSortLabel
                                        active={ordem.campo === 'cnpj'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('cnpj')}
                                    >
                                        CNPJ
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={false} className={classes.tableHead}
                                >
                                    <TableSortLabel
                                        active={ordem.campo === 'razaoSocial'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('razaoSocial')}
                                    >
                                        Razão Social
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={false} className={classes.tableHead}
                                >
                                    <TableSortLabel
                                        active={ordem.campo === 'idUnidade'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('idUnidade')}
                                    >
                                        Unidade do processo
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell
                                    sortDirection={false} className={classes.tableHead}
                                >
                                    <TableSortLabel
                                        active={ordem.campo === 'dataCriacao'}
                                        direction={ordem.ordem}
                                        onClick={(e) => createSortHandler('dataCriacao')}
                                    >
                                        Data Criação
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width='100px' align="center" className={classes.tableHead}>
                                    Ação
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        {unidades &&
                            <TableBody>
                                {processos && processos.map((row, i) => (
                                    <TableRow key={i}>
                                        <TableCell >{row.nProcesso}</TableCell>
                                        <TableCell >{row.cnpj}</TableCell>
                                        <TableCell>{row.razaoSocial}</TableCell>
                                        <TableCell>{row.idUnidade !== 0 ? (unidades.find(u => u.id === row.idUnidade).nome) : ''}</TableCell>
                                        <TableCell>{FormataDataComHora(new Date(row.dataCriacao))}</TableCell>
                                        <TableCell>
                                            <Box >
                                                <Tooltip title="Visualizar">
                                                    <IconButton href={"/gerenciaProcesso/processo/" + row.idProcesso} >
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={getTituloSituacao(row)}>
                                                    <IconButton onClick={(e) => handleAcao(e, i)}>
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </Tooltip>

                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                {
                    dados &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={qtd}
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }

            </Grid>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openAction}
                onClose={handleClose}
                PaperProps={{
                    // style: {
                    //     maxHeight: ITEM_HEIGHT * 4.5,
                    //     width: '20ch',
                    // },
                }}
            >
                {options.map((option, index) => (
                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleActionClick(index)}>
                        {option}
                    </MenuItem>
                ))}
            </Menu>
            {location.pathname.includes("gerenciaProcesso") &&
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                        Sair
                    </Button>
                    { hasRegraCadastrarProcesso() &&
                    <Button variant="contained" color="primary" href={"/gerenciaProcesso/cadastrarProcesso"} >
                        Cadastrar
                    </Button>}
                </Grid>
            }

        </Grid>
    );
}
