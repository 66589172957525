import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography,
    Accordion, AccordionSummary, AccordionDetails, Divider
} from '@material-ui/core';
import { useStyles, getQueryParams, API_BASE_URL } from "../../../constants";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GenericTable from '../../template/GenericTable';
import Download from '../../../util/Download';

let currentRow = null;
export default function DeclaracaoForm(props) {
    const classes = useStyles();
    const { value, setValue, setMensagem, setWait } = props;

    const imprimirDeclaracao = () => {
        var a = document.createElement("a");

        a.href = API_BASE_URL + "/api/projeto/declaracao/" + value.id;

        a.setAttribute("target", "_blank");
        a.click();
    }
    function fileClick() {
        let file = document.getElementById("file");
        file.click();
    }

    const handleFormalizacao = () => {
        currentRow = {
            check: false,
            checkTecnico: false,
            tipo: "Certidão Negativa ou Positiva com efeito de Negativa de Débitos Relativos aos Tributos Federais e à Dívida Ativa da União - RFB aos sócios-administradores;",
        };

        fileClick();
    }

    function validaAnexo(anexo) {
        if (!anexo) return false;
        if (!anexo.name.toLocaleLowerCase().endsWith('pdf')) {
            setMensagem("Extensão inválida. Por favor informar uma anexo com extensão .pdf");
            return false;
        }
        if ((anexo.size / 1048576).toFixed(2) > 20) {
            setMensagem("Inválido. Por favor informar imagem menor do que 20 mb");
            return false;
        }
        return true;
    }
    const upload = (e) => {
        let file = document.getElementById("file");
        let dataArray = new FormData();
        if (!validaAnexo(file.files[0])) {
            return;
        }
        setWait(true);
        dataArray.append('file', file.files[0]);
        fetch('/api/upload/file', { method: "POST", body: dataArray })
            .then(res => res.status === 200 ? res.json() : setMensagem(res))
            .then(
                (result) => {
                    setWait(false);
                    var nm = file.value;
                    nm = nm.split(/(\\|\/)/g).pop();

                    if (currentRow) {
                        console.log("currentRow", currentRow);
                        console.log("nm", nm);
                        console.log("currentRow", result.uuid);
                        console.log("value", value);
                        setValue({
                            ...value,
                            declaracaoPVS: {
                                tipo: "Declaração de Sócios",
                                check: false,
                                checkTecnico: false,
                                nome: nm,
                                uuid: result.uuid
                            }
                        })

                        // let arr = [...anexos];
                        // arr.forEach(i => {
                        //     if (i === currentRow) {
                        //         i.nome = nm;
                        //         i.uuid = result.uuid;
                        //     }
                        // });
                        // setAnexos(arr);
                        currentRow = null;
                    } else {
                        console.log("False>>>>>>>>>>\nnm", nm);
                        console.log("currentRow", result.uuid);
                        // let arr = [];
                        // arr.push({
                        //     tipo: tipoDocumento,
                        //     nome: nm,
                        //     uuid: result.uuid
                        // });
                        // anexos.forEach(a => {
                        //     if (a.tipo !== -1) arr.push(a)
                        // });
                        // arr.push({ tipo: -1 });
                        // setAnexos(arr);
                    }
                },
                (error) => {
                    setWait(false);
                    setMensagem(error);
                }
            );
    }

    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>INFORMAÇÕES</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={12}>
                            <h3>Declaração</h3>
                            <p> Declaro à Secretaria de Desenvolvimento Econômico do Distrito Federal - SEDET/DF que os sócios
                                caracterizados abaixo não estão respondendo por crimes previsto nas Leis nº. 1.521 de 26 de
                                dezembro de 1951, 7.492 de 16 de junho de 1986, 8.137 de 27 de dezembro de 1990, 9.605 de 12 de
                                fevereiro de 1988 e 9.613 de 02 de março de 1998.</p>
                            <p>Sob pena de incorrer nas cominações legais, declaro, ainda, que as informações prestadas são verdeiras.</p>
                            <p>Brasilia(DF)</p>
                        </Grid>
                        <GenericTable
                            //actionClick={handleActionSolicitarClick}
                            options={['Visualizar', 'Alterar', 'Excluir', 'Reenviar']}
                            dados={value ? (value.sociosDiretores || []) : []}
                            colunas={[
                                { nome: 'Sócio', campo: 'nome' },
                                { nome: 'RG', campo: 'rg' },
                                { nome: 'CPF', campo: 'cpf' },
                                { nome: 'Assinatura', campo: 'assim' }
                            ]} />

                        <Grid item xs={12}>
                            <h3>Intruções</h3>
                            <p>1.Esta declaração deverá ser preenchida por todos os Sócios/Titulares da empresa constantes do Contrato Social.</p>
                        </Grid>
                        <Grid container justify="flex-end" className={classes.btns}>

                            <Tooltip title="Incluir Formalização">
                                <IconButton onClick={() => handleFormalizacao()}>
                                    <AttachFileIcon />
                                </IconButton>
                            </Tooltip>
                            {value.declaracaoPVS && <Download doc={value.declaracaoPVS} />}
                            <Button variant="contained" color="primary" onClick={imprimirDeclaracao}>
                                Imprimir Declaração
                            </Button>
                        </Grid>
                    </Grid>
                </AccordionDetails>
                <input type="file" id="file" style={{ display: 'none' }} onChange={upload} accept="application/pdf" />
            </Accordion>
        </Paper>
    )

}
