import React from 'react';
import { Container,  } from '@material-ui/core';
import GenericTable from '../../template/GenericTable';
import { FormataDataSemHora } from '../../../constants';
import EtapaProcessoComboBox from "./EtapaProcessoComboBox";

export default function DetalharDadosProrrogacaoContratoProcessoPanel(props) {
    const { processo, gestaoProcesso } = props;

    function transformaDt(valor) {
        if (valor) {
            return FormataDataSemHora(new Date(valor));
        }
        return "";
    }

    return (
        <Container>
            <GenericTable
                dados={processo.prorrogacaoContrato || []}
                disableSearch={true}
                readOnly={true}
                colunas={[
                    { nome: 'Resolução', campo: 'resolucao' },
                    { nome: 'Data Resolução', campo: 'dataResolucao', transformacao: transformaDt },
                    { nome: 'Data Desconto Máximo', campo: 'dataDescontoMax', transformacao: transformaDt },
                    { nome: 'Data Desconto Mínimo', campo: 'dataDescontoMin', transformacao: transformaDt },
                    { nome: 'Data Vencimento', campo: 'dataVencimento', transformacao: transformaDt }
                ]} />
                {/* <EtapaProcessoComboBox gestaoProcesso={gestaoProcesso} etapa="prorrogacao"/> */}
        </Container>
    );
}
