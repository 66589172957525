import React, { useEffect, useState } from 'react';

import {
    Grid, Button, FormControlLabel, Typography, RadioGroup, Radio, Paper, makeStyles, FormControl, MenuItem, Select, Accordion, AccordionSummary, AccordionDetails
} from '@material-ui/core';
import { useStyles, FormataData, FormataDataSemHora, FormataDataComHora } from "../../constants";
import GenericTransferList from '../template/GenericTransferList';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { useLocation } from "react-router-dom";
import Service from '../../services/Service';
import { ptBR } from 'date-fns/locale';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExcelFile from 'react-export-excel/dist/ExcelPlugin/components/ExcelFile';
import ExcelSheet from 'react-export-excel/dist/ExcelPlugin/elements/ExcelSheet';
import ExcelColumn from 'react-export-excel/dist/ExcelPlugin/elements/ExcelColumn';


// let dados = null;
export default function RelatorioDaabeCadastroProcesso(props) {
    const classes = useStyles();
    const { setTitulo, setWait, setMensagem, setErro } = props;
    const [error, setError] = useState(resetErro());
    const location = useLocation();
    const [dataInicio, setDataInicio] = useState();
    const [dataFim, setDataFim] = useState();
    const [processo, setProcessos] = useState([]);
    const [processoFiltrado, setProcessosFiltrado] = useState([]);

    const [programas, setProgramas] = React.useState([]);
    const [programasSelecionado, setProgramasSelecionado] = React.useState([]);

    const [situacoes, setSituacoes] = React.useState([]);
    const [situacoesSelecionados, setSituacoesSelecionados] = React.useState([]);

    useEffect(() => {

        setWait(true);
        fetch("/api/gerenciarProcesso/comSituacaoStatus",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then(res => res.json()).then((result) => {
                let programa = []; //['PROIN', 'PRODECON', 'PRO-DF', 'PRO-DFII', 'DESENVOLVEDF'];
                for (let x in result) {
                    const item = result[x]
                    if (item.programa) {
                        if (!programa.find(e => e === item.programa)) {
                            programa.push(item.programa)
                        }
                    }
                }
                setProgramas(programa);
                setProcessos(result);
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    // dados = result;
                }
            });
        montaSituacao();
        buscaDados();
    }, []);

    useEffect(() => {
        // if(programasSelecionado.length > 0){
        buscaDados()

        // }

    }, [programasSelecionado,situacoesSelecionados]);

    function montaSituacao() {
        // Service.GET("/api/processoProdf/todaSituacao").then(result => {
        Service.GET("/api/processoProdf/todaSituacaoStatus").then(result => {
            setSituacoes(result);
        })
    }
    function resetErro() {
        return {
            data: { erro: false, msg: '' },
        }
    }

    function formataData(dt) {
        if (!dt) return "";
        return FormataData(new Date(dt));
    }

    const handleDateChangedataInicio = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (date > dataFim && dataFim !== null) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        setDataInicio(date);
        const dt = formataData(dataInicio);
    }

    const handleDateChangedataFim = (date) => {
        setError({
            data: { erro: false, msg: '' }
        });
        if (date < dataInicio && dataInicio !== null) {
            setError({
                data: { erro: true, msg: 'A data inicial não pode ser maior que a final' }
            });
        }
        setDataFim(date);
    }

    function handleClickGerarRelatorio() {
        setWait(true);

        var p1 = new Promise(
            function (resolve, reject) {
                setTimeout(() => {
                    let arrId = [];
                    let dtI = dataInicio ? new Date(dataInicio.setHours(0, 0, 0, 0)) : false;
                    let dtF = dataFim ? new Date(dataFim.setHours(23, 59, 59, 999)) : false;
                    let teste = [];
                    for (let x in processo) {
                        const item = processo[x];


                        // if (programasSelecionado.length > 0 && !(programasSelecionado.find(e => e === item.programa))) continue;
                        if (situacoesSelecionados.length > 0 && !(situacoesSelecionados.find(e => item.situacao && e === item.situacao))) continue;
                        if ((dtI || dtF) && !(new Date(item.dataCriacao) > dtI && new Date(item.dataCriacao) < dtF)) continue;
                        arrId.push(item.idProcesso)
                        teste.push(item)
                    }

                    if (arrId.length === 0) {
                        setErro("Nenhum dado atende ao critério especificado");
                        window.scrollTo(0, 0);
                        setWait(false);
                        return;
                    }

                    const dado = {
                        ids: arrId,
                        dataInicio: dataInicio,
                        dataFim: dataFim,
                        filtro: {
                            programas: programasSelecionado.length > 0 ? programasSelecionado : programas
                        }
                    }
                    if (location.pathname.includes("relatorioDaabeGeracaoEmpregos")) {
                        resolve(true);
                        relatorioDaabeGeracaoEmpregos(dado, resolve);
                    } else {
                        resolve(true);
                        relatorioDaabeCadastroProcesso(dado, resolve);
                    }
                }, 30);
            }
        );
        // p1.then(() => setWait(false))

    }

    function relatorioDaabeGeracaoEmpregos(dado, resolve) {
        Service.POSTReport('/api/projeto/relatorioDaabeGeracaoEmpregos.pdf', dado).then(body => {
            setWait(false);
            resolve(true);
            if (!body) setErro("Relatório sem conteúdo");
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        }).catch(error => {
            setWait(false);
            if (typeof error === 'object') {
                setErro(error ? error.message : 'Oops! Something went wrong. Please try again!');
            } else {
                setErro(error ? error.message : 'Oops! Something went wrong. Please try again!');
            }
            resolve(true);
        })
    }

    function relatorioDaabeCadastroProcesso(dado, resolve) {
        Service.POSTReport('/api/projeto/relatorioDaabeCadastroProcesso.pdf', dado).then(body => {
            setWait(false);
            resolve(true);
            if (!body) setErro("Relatório sem conteúdo");
            var file = window.URL.createObjectURL(body);
            let tab = window.open();
            tab.location.assign(file);
        }).catch(error => {
            setWait(false);
            if (typeof error === 'object') {
                setErro(error ? error.message : 'Oops! Something went wrong. Please try again!');
            } else {
                setErro(error ? error.message : 'Oops! Something went wrong. Please try again!');
            }
            resolve(true);
        })
    }

    function buscaDados() {

        fetch("/api/gerenciarProcesso/comSituacaoStatus",
            {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    'Authorization': `Bearer ${localStorage.accessToken}`
                }
            }).then(res => res.json()).then((result) => {
                tratarDados(result)
                setWait(false);
                if (result.status && result.status > 300) {
                    setErro(result);
                } else {
                    // dados = result;
                }
            });
    }

    function tratarDados(dado) {
        let dados = [];

        for (let x in dado) {

            const d = dado[x]
            if (filtraprograma(d)) {
                continue;
            }
            if (filtroSituacao(d)) {
                continue;
            }
            dados.push({
                programa: d.programa,
                nProcesso: d.nProcesso,
                dataCriacao: formataData(d.dataCriacao),
                cnpj: d.cnpj,
                razaoSocial: d.razaoSocial,
                situacao: d.situacao,
            })
        }
        setProcessosFiltrado(dados)
    }

    function formataData(dt) {
        if (!dt) return "";
        return FormataDataComHora(new Date(dt));
    }



    function filtraprograma(i) {
        if (programasSelecionado.length > 0 && !(programasSelecionado.find(e => i.programa && e === i.programa))) {
            return true;
        }
        return false;
    }

    function filtroSituacao(i){
        if (situacoesSelecionados.length > 0 && !(situacoesSelecionados.find(e => i.situacao && e === i.situacao))) {
            return true;
        }
        return false;
        

    }

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
            <Paper>
                <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                        <Accordion defaultExpanded={true} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography variant="h6" gutterBottom>
                                    Período (Data de Cadastro)
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3} className={classes.root}>
                                    <Grid item xs={6}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            id="dataLicitacao"
                                            label="De:"
                                            format="dd/MM/yyyy"
                                            fullWidth
                                            value={dataInicio || null}
                                            onChange={handleDateChangedataInicio}
                                            KeyboardButtonProps={true}
                                            inputVariant="outlined"
                                            invalidDateMessage="Formato inválido"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <KeyboardDatePicker
                                            error={error.data.erro}
                                            helperText={error.data.msg}
                                            disableToolbar
                                            variant="inline"
                                            id="dataLicitacao"
                                            label="Até:"
                                            format="dd/MM/yyyy"
                                            fullWidth
                                            value={dataFim || null}
                                            onChange={handleDateChangedataFim}
                                            KeyboardButtonProps={true}
                                            inputVariant="outlined"
                                            invalidDateMessage="Formato inválido"
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid container spacing={2} className={classes.root} >
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Programas"} tituloleft={"Programas disponíveis:"} tituloright={"Programas selecionados:"}
                            left={programas} setLeft={setProgramas}
                            right={programasSelecionado} setRight={setProgramasSelecionado}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <GenericTransferList
                            titulo={"Situação Status"} tituloleft={"Situação disponíveis:"} tituloright={"Situação selecionadas:"}
                            left={situacoes} setLeft={setSituacoes}
                            right={situacoesSelecionados} setRight={setSituacoesSelecionados}
                        />
                    </Grid>
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" href={"/home"} rel="noopener noreferrer">
                            Sair
                        </Button>
                        {/* <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={handleClickTest} >
                            Test
                        </Button> */}
                        <ExcelFile element={<Button variant="contained" color="primary"  >gerar Excel</Button>}>
                            <ExcelSheet data={processoFiltrado} name="People"     >
                                <ExcelColumn label="Programa" value="programa" title="teste" />
                                <ExcelColumn label="Processo" value="nProcesso" />
                                <ExcelColumn label="Dt. Criação" value="dataCriacao" />
                                <ExcelColumn label="CNPJ" value="cnpj" />
                                <ExcelColumn label="Razão Socia" value="razaoSocial" />
                                <ExcelColumn label="Situação Status" value="situacao" />

                            </ExcelSheet>

                        </ExcelFile>

                        <Button variant="contained" color="primary" rel="noopener noreferrer" onClick={handleClickGerarRelatorio} >
                            Gerar Relatório
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </MuiPickersUtilsProvider>
    );

}
