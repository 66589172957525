import React from 'react';
import { Grid } from '@material-ui/core';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AcaoTabelaComponent from '../../common/tabela/AcaoTabelaComponent';
import {StatusRequerimento} from '../../../util/StatusSistema';
import { useHistory, useParams } from "react-router-dom";

export default function DetalharDadosComplementoInformacaoProcessoPanel(props) {
    const { processo } = props;
    const history = useHistory();
    const { id } = useParams();

    const handleAbrirDetalhamento = (r) => {
        history.replace("/gerenciaProcesso/complementoInformacao/formView/" + r.id+"?back=/gerenciaProcesso/processo/"+id+"&open=complementoInformacao"  );
    };
    function getSolicitacoes(){
        if( processo && processo.requerimentos){
            let arr= processo.requerimentos.filter(r => r.tipo === 'complemento/notificacao' && r.tipoRecurso === 'Complemento');
            arr.forEach(el => {
                el.statusNome = StatusRequerimento.find(st => st.status === el.status);
                el.statusNome = el.statusNome?el.statusNome.nome:''
            });
            return arr;
        }
        return;
    }

    return (
        <Grid item xs={12}>
            <TabelaComponent dados={getSolicitacoes()}>
                <CelulaCabecalhoTabelaComponent fixo={processo.dadosEmpresa && processo.dadosEmpresa.nProcesso || ''} titulo="Número Processo" />
                <CelulaCabecalhoTabelaComponent identificador='protocolo' titulo="Protocolo" />
                <CelulaCabecalhoTabelaComponent identificador='tipo' titulo="Notificação" />
                <CelulaCabecalhoTabelaComponent identificador='dataNotificacao' titulo="Data Notificação" />
                <CelulaCabecalhoTabelaComponent identificador='dataResposta' titulo="Data da Resposta" />
                <CelulaCabecalhoTabelaComponent identificador='diasLimiteResposta' titulo="Dias Limite Resposta" />
                <CelulaCabecalhoTabelaComponent identificador='situacao' titulo="Situação" />
                <AcaoTabelaComponent titulo="Visualizar" onClick={(e)=> handleAbrirDetalhamento(e)}>
                    <VisibilityIcon />
                </AcaoTabelaComponent>
            </TabelaComponent>
        </Grid>
    );
}

