import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import CalendarioPanel from "../../../util/calendario/CalendarioPanel";
import { useStyles } from "../../../constants";
import AgendaDetalheDiaDialog from "./AgendaDetalheDiaDialog";
import { useHistory, useLocation } from "react-router-dom";

export default function AgendaPanel(props) {
    const classes = useStyles();
    const { setTitulo } = props;
    const [dado, setDado] = useState();
    const [open, setOpen] = useState(false);
    const [dadoDia, setDadoDia] = useState();
    const history = useHistory();

    useEffect(() => {
        setTitulo("Agenda de Vistorias");
        buscaVistoriasAgendadas();
    }, []);

    function buscaVistoriasAgendadas() {
        
        fetch('/api/processoProdf/vistoria/agendadas')
            .then(response => {
                if (response.ok) {
                    response.json()
                        .then(json => {
                            let arr = [];
                            for (let x in json) {
                                if (json[x].situacao !== "SUSPENSO") {
                                    arr.push({
                                        id: json[x].id,
                                        nome: json[x].lote[0].ra,
                                        data: new Date(json[x].agenda.data),
                                        agenda: json[x].agenda,
                                    })
                                }
                            }
                            setDado(arr);
                        })
                }
            })
    }
    function sair() {
        history.replace("/home");
    }

    function diaClick(dia, mes, ano, atividades) {
        let arr = [];
        for (let a in atividades) {
            let at = atividades[a];
            let d = at.data.getDate();
            let m = at.data.getMonth();
            let aa= at.data.getFullYear();
            if (dia === d && mes === m && ano == aa) {
                arr.push(at);
            }
        }
        setDadoDia({
            dia: dia,
            mes: mes,
            ano: ano,
            atividades: arr
        });
        setOpen(true);
    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <CalendarioPanel
                atividades={dado}
                // menuAcoes={[
                //     { id: '1', nome: "Menu 1", handleClick: menuClick, showOnlyHasItem: true },
                //     { id: '2', nome: "Menu 2", handleClick: menuClick },
                //     { id: '3', nome: "Menu 3", handleClick: menuClick }
                // ]}
                diaClick={diaClick}
            />
            <AgendaDetalheDiaDialog open={open} setOpen={setOpen} dadoDia={dadoDia} />

            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                    Sair
                </Button>
            </Grid>
        </Grid>
    );
}
