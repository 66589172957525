

import React, { useState, useEffect, } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Tooltip, Box, IconButton,
    Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Typography
}
    from '@material-ui/core';
import ConfirmDialog from "../../../util/ConfirmDialog";

import BuscaDadoDialog from "../../../util/BuscaDadoDialog";
import { useCurrentUser } from "../../../server/UseCurrentUser";
import { useHistory, useLocation } from "react-router-dom";
import { useStyles, getQueryParams, API_BASE_URL } from "../../../constants";
import { makeStyles } from '@material-ui/core/styles';
import Service from "../../../services/Service";

export const useStylesLocal = makeStyles((theme) => ({
    btns: {
        marginTop: '10px',
        '& > *': {
            margin: '4px'
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightBold,
    },
    paper: {
        width: '100%',
        height: '180px',
        overflow: 'auto'
    }
}));



export default function SituacaoLoteForm(props) {
    const classes = useStyles();
    const location = useLocation();
    const classesLocal = useStylesLocal();
    const { setTitulo, setMensagem, setWait, disable } = props;
    
    const [showConfirm, setShowConfirm] = useState(false);
    const [value, setValue] = useState({
        descricao: '',
        situacao: '',
    });
    const [anexos, setAnexos] = useState([{ tipo: -1 }]);
    const [processos, setProcessos] = useState([]);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    let [profile] = useCurrentUser();
    const [error, setError] = useState(resetErro());
    const [tipoDocumento, setTipoDocumento] = useState('');
    const [txt, setTxt] = useState('');
    const param = getQueryParams(useLocation().search);

    useEffect(() => {
        const id = param.get("id");
        if(location.pathname.includes("situacaoLotesForm")){
            setTitulo((id?"Altera":"Cadastro") + " Situação do Lote");
        }else{
            setTitulo("Visualizar situação do lote");
        };
        
        if (id) {
            setWait(true);
            Service.GET('/api/processoProdf/situacaoLote/' + id).then(json =>{
                setWait(false);
                setValue(json);
            }).catch(error => {
                setWait(false);
                setMensagem(error?error: 'Oops! Something went wrong. Please try again!');
            });
            // fetch('/api/processoProdf/situacaoLote/' + id, {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         Accept: 'application/json',
            //         Authorization: `Bearer ${localStorage.accessToken}`
            //     },
            //     credentials: 'include'
            // }).then(response => {
            //     if (response.ok && (response.status < 300)) {
            //         response.json().then((json) => {
            //             setWait(false);
            //             // json.declaracao = (json.declaracao === 'true');
            //             setValue(json);
                      
                      
            //         });
            //     } else {
            //         response.json().then((error) => {
            //             setWait(false);
            //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            //         });
            //     }
            // }).catch(error => {
            //     setWait(false);
            //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            // });
        } else {
            if (param.get('processo')) {
                setValue({
                    ...value,
                    processo: param.get('processo').replace(/\'/g, '')
                }
                );
                window.history.pushState({}, document.title, "./solicitarCancelamentoForm");
            }
        }
    }, [])

    const handleChangeInputForm = (event) => {
        setValue({
            ...value,
            [event.target.name]: event.target.value,
        });
    };
    const handleCheck = (e) => {
        setValue({
            ...value,
            declaracao: e.target.checked
        });
    }

    


    function confirmaAprovacao() {
        setError(resetErro());

        let dado = { ...value };       

        // dado.documentos = anexos;

       // dado.descricao = txt;

        submitForm(dado);
    }


    function submitForm(dado) {
        setWait(true);
        Service.POST('/api/processoProdf/situacaoLote', dado).then(resp =>{
            setWait(false);
            history.replace("/operacao/situacaoLotesTable");
        }).catch(error =>{
            setWait(false);
            setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
        })

        // fetch('/api/processoProdf/situacaoLote', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok && (response.status < 300)) {
                           
        //         history.replace("/operacao/situacaoLotesTable");
        //     } else {
        //         response.json().then((error) => {
                  
        //             setMensagem("Não é possível cadastrar : situação repetida.");
        //         });
        //     }
        // }).catch(error => {
        
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    function resetErro() {
        return {
            processo: { erro: false, msg: '' },

            campo3: { erro: false, msg: '' },
        }
    }
    function setProcesso(processo) {
        setValue({
            ...value,
            processo: {
                id: processo.id,
                nProcesso: processo.nProcesso,
                cnpj: processo.cnpj,
                razaoSocial: processo.razaoSocial,
                programa: processo.programa
            }
        });
    }

   




    return (
        <Container maxWidth='md'>
            <Paper >

                <Grid container spacing={1} className={classes.root}>
                     
                    <Grid item xs={6}>
                        <TextField
                            disabled={disable && disable===true}
                            value={value.situacao || ''}
                            fullWidth
                            label="situacao"
                            onChange={handleChangeInputForm}
                            id="situacao"
                            name="situacao"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                           disabled={disable && disable===true}
                            value={value.descricao || ''}
                            fullWidth
                            label="descricao"
                            onChange={handleChangeInputForm}
                            id="descricao"
                            name="descricao"
                            aria-describedby="nova-helper-text"
                            variant="outlined"
                        />
                    </Grid>               
                  

                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" href={"/operacao/situacaoLotesTable"} rel="noopener noreferrer">
                            Sair
                        </Button>

                    
                    
                        
                        {location.pathname.includes("situacaoLotesForm") &&
                    <Button variant="contained" color="primary" onClick={() => setShowConfirm(true)} rel="noopener noreferrer">
                        { (value && value.id)?"Alterar":"Cadastrar"}
                    </Button>
                }
                        
                        


                    </Grid>
                    <ConfirmDialog
                        open={showConfirm}
                        setOpen={setShowConfirm}
                        titulo={'Confirmação'}
                        msg={(value && value.id)?"Confirma alteração da situação de lote?":"Confirma cadastro de situação de lote?"}
                        exec={confirmaAprovacao}
                    />

                    <BuscaDadoDialog
                        open={showBuscaDadoDialog}
                        setOpen={setShowBuscaDadoDialog}
                        acao={setProcesso}
                        colunas={[
                            { nome: 'CNPJ', campo: 'cnpj' },
                            { nome: 'Nome', campo: 'razaoSocial' },
                            { nome: 'Processo', campo: 'nProcesso' },
                        ]}
                        dados={processos} />
                </Grid>

            </Paper>


        </Container>
    );
}
