import { Button, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles, getQueryParams, FormataDataComHora } from '../../constants';
import GenericTable from '../template/GenericTable';

export default function HistoricoVistoriaForm(props) {
    const classes = useStyles();
    const { setMensagem, setWait, disable, vistoria, alterar } = props;
    const param = getQueryParams(useLocation().search);
    const [txt, setTxt] = useState('');
    const [value, setValue] = useState({});
    const [dataSolicitacao, setDataSolicitacao] = useState(param.get("id") ? null : new Date());
    const [fonteDeDados, setFonteDeDados] = useState([]);
    const history = useHistory();



    function isReadOnly() {
        return false;
    }

    function sair() {
        history.replace("/vistoria/solicitar");
    }

    function formataData(dt) {
        if (!dt) return "";
        return FormataDataComHora(new Date(dt));
    }

    function formateHtml(html) {
        return (
            <span dangerouslySetInnerHTML={{
                __html: html
            }}></span>
        )
    }


    function deParaAcao(st) {
        if (st === "CANCELADO") {
            return "Cancelado"
        } else if (st === "SUSPENSO") {
            return "Suspenso";
        } else if (st === "AGENDADO") {
            return "Agendado"
        } else if (st === "AGUARDANDO_AJUSTE") {
            return "Devolvido para ajuste do solicitante"
        } else if (st === "EM_EXECUCAO") {
            return "Iniciada execução"
        } else if (st === "EXECUTADO") {
            return "Finalizada execução"
        } else if (st === "AJUSTE_VISTORIADOR") {
            return "Devolvido para ajuste do vistoriador"
        } else if (st === "VALIDACAO_GERENTE") {
            return "Validado gerente"
        } else if (st === "AGUARDANDO_AJUSTE_GERENTE") {
            return "Devolvido para ajuste do gerente"
        } else if (st === "VALIDADO_DIRETOR") {
            return "Validado diretor"
        } else if (st === "SOLICITACAO") {
            return "Solicitado"
        } else if (st === "AGENDA") {
            return "Agendado"
        } else if (st === "MUDAR_VISTORIADOR") {
            return "Alteração vistoriador"
        } else if (st === "TERMO_VISTORIA") {
            return "Alteração termo vistoria";
        } else if (st === "ALTERACAO") {
            return "Alteração";
        }
        return st;

    }
    return (
        <Grid container spacing={3} className={classes.root}>
            <Typography variant="h5" gutterBottom>Histórico</Typography>

            <Grid item xs={12}>
                <GenericTable
                    //actionClick={handleActionSolicitarClick}
                    options={['Visualizar']}
                    dados={vistoria.historico}
                    readOnly={isReadOnly()}
                    colunas={[
                        { nome: 'Data', campo: 'data', transformacao: formataData },
                        { nome: 'Responsável Validação', campo: 'responsavel.nome' },
                        { nome: 'Ação', campo: 'tipo', transformacao: deParaAcao },
                        { nome: 'Observação', campo: 'texto', transformacao: formateHtml }
                    ]} />
            </Grid>

            { alterar && alterar === true &&
                <Grid container justify="flex-end" className={classes.btns}>
                    <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                        Sair
                    </Button>

                </Grid>
            }

        </Grid>
    )
}