import React, { useState, useEffect, useRef } from 'react';
import {
    Button, Grid, Paper, TextField, MenuItem, Select, Container, Typography,
    Accordion, AccordionDetails, AccordionSummary, Tooltip, IconButton,
    RadioGroup, Radio, FormControlLabel, InputAdornment, FormGroup, Checkbox
} from '@material-ui/core';
import { useStyles, FormataMoeda, FormataDataComHora, ValidarCNPJ } from "../../constants";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { useParams, useLocation, useHistory } from "react-router-dom";
import GenericTable from "../../components/template/GenericTable";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import { useCurrentUser } from "../../server/UseCurrentUser";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BuscaDadoDialog from "../../util/BuscaDadoDialog";
import SearchIcon from '@material-ui/icons/Search';
import MaskedInput from 'react-text-mask';
import { ptBR } from 'date-fns/locale';

const MonetarioField = (props) => {
    const { inputRef, onChange, ...other } = props;

    return <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        onChange={onChange}
    />
}

function CNPJMask(props) {
    const { inputRef, ...other } = props;


    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

let indexAlteracao = null;
const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
let disable = false;
let atualizar = true;
export default function LiberacoesForm(props) {
    const classes = useStyles();
    const { setTitulo, setMensagem, setWait } = props;
    let { id } = useParams();
    const [value, setValue] = useState();
    const [parcelas, setParcelas] = useState([]);
    const [parcela, setParcela] = useState();
    const [lstExercicio, setLstExercicio] = useState([]);
    const [lstFatoGerador, setLstFatoGerador] = useState([]);
    const [lstPrevisao, setLstPrevisao] = useState([]);
    const [escolhaPrevisao, setEscolhaPrevisao] = useState([]);
    const [raAde, setRaAde] = useState();
    const location = useLocation();
    const [previsao, setPrevisao] = useState();
    const [alterar, setAlterar] = useState(false);
    const [valorParcela, setValorParcela] = useState(0)
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [showBuscaDadoDialog, setShowBuscaDadoDialog] = useState(false);
    const [demaisEmpresa, setDemaisEmpresa] = useState(false);
    const [novosProjetos, setNovosProjetos] = useState(false);
    const [error, setError] = React.useState(resetErro());
    const textInput = useRef(null);
    const history = useHistory();
    let [profile] = useCurrentUser();

    useEffect(() => {
        setTitulo("Cadastro de liberação de parcelas");
        buscaPrevisao();
        indexAlteracao = null;
        disable = location.pathname.includes("liberacoesView" || "liberacoesAlterar");
        atualizar = location.pathname.includes("liberacoesAlterar");
    }, []);
    useEffect(() => {
        if (lstExercicio && lstExercicio.length > 0) {
            if (id) {
                buscaParcela();
            }
        }
    }, [lstExercicio])

    useEffect(() => {
        if (value) {
            populaFatoGerador();
        }
    }, [value])
    useEffect(() => {
        function buscaRaAde() {
            fetch('/api/info/ra_ade').then(response => {
                if (response.ok) {
                    response.json().then((json) => {
                        setRaAde(json);
                    });
                } else {
                    response.json().then((error) => {
                        setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    });
                }
            }).catch(error => {
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
        }
        if (novosProjetos) {
            setLstPrevisao([
                "PRODF", "IDEAS", "FIDE"
            ])

            if (!raAde) {
                buscaRaAde();
            }
        }
    }, [novosProjetos])

    function buscaParcela() {
        fetch('/api/fundefeParcelas/' + id)
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            caculaParcela(json);
                            if (json.previsao.isMatriz) {
                                json.previsao.matriz = (json.previsao.isMatriz === true) ? 'matriz' : 'filial'
                            }
                            if (json.previsao.exercicio) {
                                const itens = previsao.filter(l => l.exercicio === json.previsao.exercicio);
                                if (itens) {
                                    let arr = []
                                    for (let x in itens) {
                                        arr.push(itens[x].programa)
                                    }
                                    const arrfiltrado = arr.filter((este, i) => arr.indexOf(este) === i);
                                    setLstPrevisao(arrfiltrado);
                                }
                            }
                            if (json.novosProjetos) {
                                json.previsao.isMatriz = json.previsao.isMatriz ? 'matriz' : 'filial'
                            }
                            setValue(json);
                            atualizaParcelas(json);
                            setDemaisEmpresa(json.demaisEmpresa === true);
                            setNovosProjetos(json.novosProjetos === true);

                            // caculaParcela(json);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function getExercicio(row) {
        return value.previsao.exercicio;
    }
    function populaFatoGerador() {
        let arr = [];


        if (value && value.previsao.programa === 'PRODF') {
            arr.push(2013)
            arr.push(value.previsao.exercicio);
        } else if (value && value.previsao.programa === 'FIDE') {
            arr = [2013, 2014, 2015, 2016, 2017, 2018, 2019];
            arr.push(value.previsao.exercicio);
        } else if (value && value.previsao.programa === 'IDEAS') {
            arr = [2019, 2020];
            if (!arr.find(a => a === value.previsao.exercicio)) {
                arr.push(value.previsao.exercicio);
            }
        }
        setLstFatoGerador(arr)
    }
    const handleChangedemaisEmpresa = (e) => {
        setDemaisEmpresa(e.target.checked);
    }

    const handleChangeNovosProjetos = (e) => {
        setNovosProjetos(e.target.checked);
        setValue();
    }


    const handleActionSolicitarClick = (index, acao, row) => {
        if (disable) return;
        if (acao === "Alterar") {
            setAlterar(true);
            setSelectedDate(new Date(row.dtLiberacao));
            setParcela(row);
            indexAlteracao = index;
        } else if (acao === 'Excluir') {
            setWait(true);
            let dado = { ...value };
            delete dado.previsao.historico;
            if (!dado.parcelas) dado.parcelas = [];
            const parc = [...parcelas];
            let arr = [];
            for (let i in parc) {
                if (i != index) {
                    arr.push(parc[i]);
                }
            }
            if (novosProjetos) {
                dado.previsao.isMatriz = dado.previsao.isMatriz === 'matriz';
            }

            dado.parcelas = arr;
            if (!dado.historico) dado.historico = [];
            dado.historico.push({
                tipo: "Exclusão ",
                data: new Date(),
                texto: "Parcelas de " + row.parcela + " excluida",
                responsavel: {
                    id: profile.id,
                    nome: profile.name,
                    email: profile.email
                }
            });
            submitForm(dado);
        }
    }
    const cancelar = () => {
        setAlterar(false);
        setParcela();
        setSelectedDate(null);
        indexAlteracao = null;
    }
    function buscaPrevisao() {
        fetch('/api/fundefePrevisao')
            .then(resp => {
                if (resp.ok) {
                    resp.json()
                        .then(json => {
                            setPrevisao(json);
                            let arr = [];
                            for (let x in json) {
                                const item = arr.find(l => l.exercicio === json[x].exercicio)
                                if (!item) arr.push(json[x]);
                            }
                            setLstExercicio(arr);
                        })
                }
            }
            ).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function desabilitarBusca() {
        if (!value || !value.previsao) return true;

        return false;
    }
    function submitForm(dado) {
        setWait(true);
        fetch('/api/fundefeParcelas', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.accessToken}`
            },
            body: JSON.stringify(dado),
            credentials: 'include'
        }).then(response => {
            if (response.ok && (response.status < 300)) {
                setWait(false);
                response.json().then((json) => {
                    if (json.previsao.isMatriz) {
                        json.previsao.matriz = (json.previsao.isMatriz === true) ? 'matriz' : 'filial'
                    }
                    if (json.novosProjetos) {
                        json.previsao.isMatriz = json.previsao.isMatriz ? 'matriz' : 'filial'
                    }
                    setValue(json);
                    atualizaParcelas(json);
                    caculaParcela(json);
                    setParcela();
                    setSelectedDate();
                    cancelar();
                });
            } else {
                response.json().then((error) => {
                    setWait(false);
                    setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                });
            }
        }).catch(error => {
            setWait(false);
            setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        });
    }

    const handleChangeExercicio = (event) => {
        if (novosProjetos) return;
        const val = event.target.value;
        const itens = previsao.filter(l => l.exercicio === val);
        if (itens) {
            let arr = []
            for (let x in itens) {
                if (!arr.find(p => p === itens[x].programa)) {
                    arr.push(itens[x].programa);
                }
            }
            setLstPrevisao(arr);
        }
        setValue({
            ...value, previsao: {
                exercicio: val
            }
        });
    };
    const handleChange = (event) => {
        const val = event.target.value;
        const exercicio = value.previsao.exercicio;

        if (novosProjetos) {
            setValue({
                ...value, previsao: {
                    exercicio: exercicio,
                    programa: val
                }
            });
            return;
        }

        const item = previsao.find(l => {
            return l.exercicio === exercicio && l.programa === val;
        })
        if (item) setValue({
            ...value, previsao: {
                exercicio: exercicio,
                programa: val
            }
        });
    };

    function caculaParcela(dado) {
        let arr = [];
        var total = 0;
        for (let x in dado.parcelas) {
            const d = dado.parcelas[x].valorParcela
            arr = [d]
            for (var i = 0; i < arr.length; i++) {
                total += arr[i];
            }
        }
        setValorParcela(total)
    }
    const handleChangeParcela = (e) => {
        setParcela({ ...parcela, [e.target.name]: e.target.value });
    }
    function notValido() {
        //TODO discutir com os analistas a validação
        // const mes = selectedDate.getMonth();
        // const ano = selectedDate.getFullYear();
        // for( let x in parcelas){
        //     let p = parcelas[x];
        //     let dt = new Date( p.dtLiberacao );
        //     let m = dt.getMonth();
        //     let a = dt.getFullYear();

        //     if( mes === m && ano === a){
        //         setMensagem("Já exite parcela cadastrada nessa data");
        //         topFunction();
        //         return true;
        //     }
        // }
        // setMensagem("lalalalla")
        // topFunction();
        return false;
    }

    const incluir = (e) => {
        if (notValido()) return;
        let dado = { ...value };
        delete dado.previsao.historico;
        if (!dado.parcelas) dado.parcelas = [];
        let parc = { ...parcela };

        if (parc.valorParcela && typeof parc.valorParcela === 'string') {
            let val = parc.valorParcela.replace(/[.*+\-?^${}()|[\]\\]/g, '');
            parc.valorParcela = val.replace(",", ".");
        }
        parc.dtLiberacao = selectedDate;
        if (!dado.historico) dado.historico = [];
        let txt = "Inclusão de parcelas"
        if (indexAlteracao !== null && indexAlteracao >= 0) {
            dado.parcelas[indexAlteracao] = parc;
            txt = 'Alteração da parcela'
        } else {
            dado.parcelas.push(parc);
        }
        dado.demaisEmpresa = demaisEmpresa;
        dado.novosProjetos = novosProjetos;

        if (novosProjetos) {
            dado.previsao.setor = dado.previsao.programa === 'FIDE' ? 'Atacadista' : 'Industrial'
        }
        if (demaisEmpresa) {
            dado.previsao.setor = dado.previsao.programa === 'FIDE' ? 'Atacadista' : 'Industrial'
        }

        // if( novosProjetos){
        dado.previsao.isMatriz = dado.previsao.isMatriz === 'matriz';
        // }
        dado.historico.push({
            tipo: "Cadastramento",
            data: new Date(),
            texto: txt,
            responsavel: {
                id: profile.id,
                nome: profile.name,
                email: profile.email
            }
        });
        submitForm(dado);
    }
    const alterer = () => {
        if (notValido()) return;
        let dado = { ...value };
        delete dado.previsao.historico;
        if (!dado.previsao.processo) dado.previsao.processo = "";

        dado.previsao.isMatriz = dado.previsao.isMatriz === 'matriz';

        submitForm(dado);
        // history.replace('/fundefe/cadLiberacoes')

    }
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    function disableIncluir() {
        if (!value) return true;
        if (!parcela) return true;
        if (!selectedDate) return true;
        if (!parcela.valorParcela) return true;
        if (!parcela.parcela) return true;
        return false;
    }
    function atualizaParcelas(parcela) {
        let arr = [];
        for (let x in parcela.parcelas) {
            let p = parcela.parcelas[x];
            p.exercicio = parcela.previsao.exercicio;
            p.numeroParcelaMes = meses.indexOf(p.parcela)
            arr.push(p);
            arr = arr.sort((a, b) => {
                let indexA = meses.indexOf(a.parcela);
                let indexB = meses.indexOf(b.parcela);
                return indexA - indexB;
            });
        }
        setParcelas(arr);
    }
    function transformaDt(valor) {
        if (valor) {
            return FormataDataComHora(new Date(valor));
        }
        return "";
    }
    function formateValorAliberar() {
        if (demaisEmpresa) return "";
        if (!novosProjetos) {
            if (value) {
                return FormataMoeda(value.previsao.valor - valorParcela);
            }
        }

        return '';
    }
    function buscaDado() {
        const programa = value.previsao.programa;
        const exercicio = value.previsao.exercicio;
        const item = previsao.filter(l => {
            return l.exercicio === exercicio && l.programa === programa;
        })
        if (item) {
            setEscolhaPrevisao([...item]);
        }

        setShowBuscaDadoDialog(true);
    }
    function handleEscolhaPrevisao(dado) {
        if (dado) {
            dado.isMatriz = (dado.isMatriz === true) ? 'matriz' : 'filial'
        }
        setParcelas([]);
        let valores = {
            ...value,
            previsao: dado
        };
        valores.historico = null;
        setValue(valores);
        buscaParcelaSeJaExixte(dado);
    }
    function buscaParcelaSeJaExixte(dado) {
        setWait(true);
        fetch('/api/fundefeParcelas/byIdPrevisao/' + dado.id)
            .then(response => {
                if (response.ok && (response.status < 300)) {
                    setWait(false);
                    response.json().then((json) => {
                        setMensagem("Previsão orçamentaria já cadastrada, abrindo para edição");
                        if (json.previsao) {
                            json.previsao.matriz = (json.previsao.isMatriz === true) ? 'matriz' : 'filial'
                        }
                        setValue(json);
                        atualizaParcelas(json);
                        caculaParcela(json);
                        setParcela();
                    });
                } else {
                    setWait(false);
                    // response.json().then((error) => {
                    //     setWait(false);
                    //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
                    // });
                }
            }).catch(error => {
                setWait(false);
                setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
            });
    }
    function verifiqueSinal() {
        if (value && value.previsao && (value.previsao.valor - valorParcela < 0)) {
            return classes.multilineColor;
        }
        return;
    }

    const handleLostFocusCNPJ = (e) => {
        setError({
            ...error,
            cnpj: {
                erro: false,
                msg: ''
            }
        });
        if (!value || !value.previsao || !value.previsao.cnpj) return;
        const cnpj = value.previsao.cnpj.replace(/[&\/\\#,+()$~%.\-'":*?<>{}]/g, '').trim();
        if (cnpj.length === 0) return;
        if (ValidarCNPJ(cnpj)) {
            fetch("/api/v1/cnpj/" + cnpj)
                .then(res => (res.status <= 300) ? res.json() : setMensagem(res))
                .then(
                    (result) => {
                        if (!result) return;
                        let dado = { ...value };
                        dado.previsao.razaoSocial = result.razaoSocial
                        setValue(dado);
                    },
                )
        } else {
            setError({
                ...error,
                cnpj: {
                    erro: true,
                    msg: 'CNPJ inválido'
                }
            });
        }
    }

    const handleChangeInputForm = (e) => {
        let dado = { ...value };
        if (!dado.previsao) dado.previsao = {}
        dado.previsao[e.target.name] = e.target.value;
        setValue(dado);
    }
    function resetErro() {
        return {
            cnpj: {
                erro: false,
                msg: ''
            },
        }
    }

    const sair = () => {
        history.push("/fundefe/cadLiberacoes");

    }
    return (
        <Container maxWidth='lg'>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                <Paper >
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Informações</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <FormGroup row>
                                        <FormControlLabel
                                            control={<Checkbox checked={demaisEmpresa || false} disabled={disable || atualizar} onChange={handleChangedemaisEmpresa} />}
                                            label="Demais empresas"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={novosProjetos || false}
                                                    disabled={disable || atualizar}
                                                    onChange={handleChangeNovosProjetos}
                                                />
                                            }
                                            label="Novos Projetos"
                                        />
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={4}>
                                    {!novosProjetos &&
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="lbExercicio">Excercício</InputLabel>
                                            <Select
                                                labelId="lbExercicio"
                                                id="exercicio"
                                                value={value && value.previsao.exercicio || ''}
                                                onChange={handleChangeExercicio}
                                                disabled={disable || atualizar}
                                                label="Exercício"
                                            >
                                                <MenuItem value="">Nenhum</MenuItem>
                                                {lstExercicio && lstExercicio.map((row, i) => (
                                                    <MenuItem key={i} value={row.exercicio}>{row.exercicio}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    }
                                    {novosProjetos &&
                                        <TextField
                                            fullWidth
                                            label="Excercício"
                                            disabled={disable}
                                            value={value && value.previsao.exercicio || ''}
                                            onChange={handleChangeInputForm}
                                            name="exercicio"
                                            variant="outlined"
                                            type="number"
                                            InputProps={{
                                                readOnly: disable,
                                            }}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="lbPrevisao">Programa</InputLabel>
                                        <Select
                                            labelId="lbPrevisao"
                                            id="previsao"
                                            value={value && value.previsao.programa || ''}
                                            onChange={handleChange}
                                            label="Exercício"
                                            disabled={disable || atualizar}
                                        >
                                            <MenuItem value="">
                                                <em>Nenhum</em>
                                            </MenuItem>
                                            {
                                                lstPrevisao && lstPrevisao.map((row, i) => (
                                                    <MenuItem key={i} value={row}>{row}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {!demaisEmpresa && <>

                                    {!novosProjetos && <>
                                        <Grid item xs={disable ? 4 : 3}>
                                            <TextField
                                                fullWidth
                                                label="CNPJ"
                                                value={value && value.previsao.cnpj || ''}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>


                                        {!disable &&
                                            <Grid item xs={1}>
                                                <Tooltip title='Buscar Previsão Orçamentárea' >
                                                    <div>
                                                        <IconButton onClick={buscaDado} disabled={desabilitarBusca()}>
                                                            <SearchIcon />
                                                        </IconButton>
                                                    </div>
                                                </Tooltip>
                                            </Grid>}


                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Beneficiário"
                                                value={value && value.previsao.razaoSocial || ''}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl component="fieldset">
                                                <RadioGroup row value={value && value.previsao.isMatriz || ''} aria-label="isMatriz"
                                                    name="isMatriz" defaultValue="top">
                                                    <FormControlLabel
                                                        value="matriz"
                                                        control={<Radio disabled={disable} color="primary" />}
                                                        label="Matriz"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="filial"
                                                        control={<Radio disabled={disable} color="primary" />}
                                                        label="Filial"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </>}

                                    {novosProjetos && <>
                                        <Grid item xs={4}>
                                            <TextField
                                                fullWidth
                                                label="CNPJ"
                                                // disabled={disable}
                                                error={error.cnpj.erro}
                                                helperText={error.cnpj.msg}
                                                value={value && value.previsao && value.previsao.cnpj || ''}
                                                onChange={handleChangeInputForm}
                                                onBlur={handleLostFocusCNPJ}
                                                name="cnpj"
                                                variant="outlined"
                                                InputProps={{
                                                    inputComponent: CNPJMask,
                                                    readOnly: disable,
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Beneficiário"
                                                value={value && value.previsao.razaoSocial || ''}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <FormControl component="fieldset">
                                                <RadioGroup row value={value && value.previsao.isMatriz || ''} aria-label="isMatriz"
                                                    onChange={handleChangeInputForm} name="isMatriz" defaultValue="top">
                                                    <FormControlLabel
                                                        value="matriz"
                                                        control={<Radio disabled={disable} color="primary" />}
                                                        label="Matriz"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="filial"
                                                        control={<Radio disabled={disable} color="primary" />}
                                                        label="Filial"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </>}

                                </>}

                                <Grid item xs={3}>
                                    {!novosProjetos &&
                                        <TextField
                                            fullWidth
                                            label="Processo"
                                            value={value && value.previsao.processo || ''}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            disabled={demaisEmpresa}
                                            variant="outlined"
                                        />}
                                    {novosProjetos &&
                                        <TextField
                                            fullWidth
                                            label="Processo"
                                            value={value && value.previsao.processo || ''}
                                            onChange={handleChangeInputForm}
                                            name="processo"
                                            variant="outlined"
                                            InputProps={{
                                                readOnly: disable,
                                            }}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Valor Arrematado"
                                        value={FormataMoeda(value && value.previsao.valor || '')}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label="Valor parcelas liberadas"
                                        value={FormataMoeda(valorParcela)}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    {!novosProjetos &&
                                        <TextField
                                            fullWidth
                                            label="Saldo a liberar"
                                            value={formateValorAliberar()}
                                            InputProps={{
                                                readOnly: true,
                                                className: verifiqueSinal()
                                            }}
                                            variant="outlined"
                                        />}
                                    {novosProjetos &&
                                        <FormControl variant="outlined" className={classes.formControl} fullWidth>
                                            <InputLabel id="demo-simple-select-outlined-label">RA</InputLabel>
                                            <Select
                                                autoWidth={false}
                                                fullWidth
                                                labelId="demo-simple-select-outlined-label"
                                                name="ra"
                                                value={value && value.previsao.ra || ''}
                                                onChange={handleChangeInputForm}
                                                label="RA"
                                                disabled={disable}
                                                inputProps={{ readOnly: (disable && disable === true) }}
                                            >
                                                {
                                                    raAde && raAde.map((item, index) => (
                                                        <MenuItem key={index} value={item.nome}>{item.nome}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>}
                                </Grid>
                            </Grid>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>Referência</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                {!disable && <>

                                    <Grid item xs={2}>
                                        {/* <TextField
                                            fullWidth
                                            id="exercicioGerador"
                                            name="exercicioGerador"
                                            label="Exercício Fato Gerador"
                                            value={parcela && parcela.exercicioGerador || ''}
                                            onChange={handleChangeParcela}
                                            variant="outlined"
                                            type="number"
                                        /> */}

                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="lbexercicioGerador">Exercício Fato Gerador</InputLabel>
                                            <Select
                                                labelId="lbexercicioGerador"
                                                name="exercicioGerador"
                                                value={parcela && parcela.exercicioGerador || ''}
                                                onChange={handleChangeParcela}
                                                disabled={disable}
                                                label="Exercício Fato Gerado"
                                            >
                                                <MenuItem value="">Nenhum</MenuItem>
                                                {lstFatoGerador && lstFatoGerador.map((row, i) => (
                                                    <MenuItem key={i} value={row}>{row}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="lbParcela">Referência</InputLabel>
                                            <Select ref={textInput}
                                                labelId="lbParcela"
                                                id="parcela"
                                                name="parcela"
                                                value={parcela && parcela.parcela || ''}
                                                onChange={handleChangeParcela}
                                                label="Referência"
                                            >
                                                {
                                                    meses && meses.map((tp, i) => (
                                                        <MenuItem key={i} value={tp} >{tp}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xl={3}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            label="Data liberação"
                                            format="dd/MM/yyyy"
                                            fullWidth
                                            value={selectedDate}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            inputVariant="outlined"
                                            invalidDateMessage="Formato inválido"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            id="valorParcela"
                                            name="valorParcela"
                                            label="Valor parcela:"
                                            value={parcela && parcela.valorParcela || ''}
                                            onChange={handleChangeParcela}
                                            variant="outlined"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                                inputComponent: MonetarioField
                                            }}
                                        />
                                    </Grid>
                                </>}
                                {!disable &&
                                    <Grid item xs={1}>
                                        <Button variant="contained" color="primary"
                                            onClick={incluir} rel="noopener noreferrer" disabled={disableIncluir()}>
                                            {alterar ? "Alterar" : "Incluir"}
                                        </Button>
                                        {alterar &&
                                            <Button variant="contained" color="secondary"
                                                onClick={cancelar} rel="noopener noreferrer" >
                                                Cancelar
                                            </Button>
                                        }
                                    </Grid>}
                                {parcelas && !disable &&

                                    <GenericTable disableSearch={true}
                                        actionClick={handleActionSolicitarClick}
                                        options={['Alterar', 'Excluir']}
                                        dados={parcelas}
                                        colunas={[
                                            { nome: 'Exercício Fato Gerador', campo: 'exercicioGerador' },
                                            { nome: 'Referência', campo: 'numeroParcelaMes', transformacao: (m) => { return meses[m] } },
                                            { nome: 'Data liberação', campo: 'dtLiberacao', transformacao: transformaDt },
                                            { nome: 'Valor', campo: 'valorParcela', transformacao: FormataMoeda, align: "right" },
                                            { nome: 'Ação', campo: 'action' }
                                        ]} />
                                }
                                {parcelas && disable &&
                                    <GenericTable disableSearch={true}
                                        actionClick={handleActionSolicitarClick}
                                        options={['Alterar', 'Excluir']}
                                        dados={parcelas}
                                        colunas={[
                                            { nome: 'Excercício', campo: 'exercicio', transformacao: getExercicio },
                                            { nome: 'Parcela', campo: 'parcela' },
                                            { nome: 'Data liberação', campo: 'dtLiberacao', transformacao: transformaDt },
                                            { nome: 'Valor', campo: 'valorParcela', transformacao: FormataMoeda, align: "right" }
                                        ]} />
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    {value && value.historico &&
                        <Accordion defaultExpanded={true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography className={classes.heading}>Histórico</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={1}>
                                    <GenericTable
                                        dados={value ? value.historico : []}
                                        colunas={[
                                            { nome: 'Data', campo: 'data', transformacao: transformaDt },
                                            { nome: 'Responsavel', campo: 'responsavel.nome' },
                                            { nome: 'Ação', campo: 'tipo' },
                                            { nome: 'Observação', campo: 'texto' }
                                        ]} />
                                </Grid>
                            </AccordionDetails>
                        </Accordion>}
                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" onClick={sair} rel="noopener noreferrer">
                            Sair
                        </Button>
                        {atualizar &&
                            <Button variant="contained" color="primary" onClick={alterer} rel="noopener noreferrer">
                                Alterar
                            </Button>
                        }
                    </Grid>
                </Paper>
            </MuiPickersUtilsProvider>
            <BuscaDadoDialog
                open={showBuscaDadoDialog}
                setOpen={setShowBuscaDadoDialog}
                acao={handleEscolhaPrevisao}
                colunas={[
                    { nome: 'CNPJ', campo: 'cnpj' },
                    { nome: 'Nome', campo: 'razaoSocial' },
                    { nome: 'Programa', campo: 'programa' },
                ]}
                dados={escolhaPrevisao} />
        </Container>
    );
}
