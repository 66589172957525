import React, { useState } from 'react';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import UpdateIcon from '@material-ui/icons/Update';
import { useHistory } from "react-router-dom";
import MotivoDialog from '../../util/MotivoDialog';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import ConfirmDialog from '../../util/ConfirmDialog';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {useStyles} from "../../constants";
import TablePagination from '@material-ui/core/TablePagination';

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);
const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

let qtd = 0;



var idUserAtivo = null;

export default function UsersTable(props) {
  const classes = useStyles();
  const { rows, setRows, setErro, setTrocarPerfil,setOrdem, ordem, setPagina, pagina, setBloco, bloco } = props;
  const [showMotivoDialog, setShowMotivoDialog] = useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false); 
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmRevogar, setShowConfirmRevogar] = useState(false);
  const [motivoRevogacao, setMotivoRevogacao] = useState(false);
  const [usuario, setUsuario] = useState();
  const [msg, setMsg] = useState();
  

  const handleSituacaoAcao = (e, index) => {

    idUserAtivo = index;
    if (rows[index].situacao === 'ativo' || rows[index].situacao === 'ativo_nt_pendente') {
      //history.replace("/revogarAcesso/"+ rows[index].id);
      setShowMotivoDialog(true);
      setUsuario(rows[index].name);
    } else {
      setMsg(rows[index].motivo);
      setShowConfirm(true);
    }
  }

  const handleTrocarPerfil = (row) => {
    setTrocarPerfil(row);
  }

  const handleChangePage = (event, newPage) => {
    setBloco(newPage);
};

const handleChangeRowsPerPage = (event) => {
    setPagina(event.target.value);       
};

  function getTituloSituacao(row) {
    return (row.situacao === 'ativo' || row.situacao === 'ativo_nt_pendente') ? "Revogar" : "Ativar";
  }

  function formataData(data) {
    var dia = data.getDate().toString(),
      diaF = (dia.length === 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length === 1) ? '0' + mes : mes,
      anoF = data.getFullYear();
    return diaF + "/" + mesF + "/" + anoF;
  }

  const motivoRevogar = (txt) => {
    setMotivoRevogacao(txt);
    setShowConfirmRevogar(true);
    console.log(rows[idUserAtivo])
  }

  const revogar = () => {
    setShowMotivoDialog(false);
    setOpenBackdrop(true);
    setShowConfirmRevogar(false);
    const body = {
      id: rows[idUserAtivo].id,
      motivo: motivoRevogacao
    }
    fetch('/api/acesso/revogarAcesso', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      credentials: 'include'
    }).then(response => {
      setOpenBackdrop(false);
      if (response.status <= 300) {
        return response.json();
      } else {
        setErro(response);
      }
    }).then(dado => atualizaRow(dado))
      .catch(error => {
        setOpenBackdrop(false);
      });
  }

  function atualizaRow(u) {
    if (!u) return;
    let arr = [];
    rows.forEach(el => {
      if (el.id === u.id) {
        arr.push(u);
      } else {
        arr.push(el);
      }
    });
    setRows(arr);
  }

  function confirmaAtivarAcesso() {
    setOpenBackdrop(true);
    const body = {
      id: rows[idUserAtivo].id
    }

    fetch('/api/acesso/ativaAcesso', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body),
      credentials: 'include'
    }).then(response => {
      setOpenBackdrop(false);
      if (response.status <= 300) {
        return response.json()

      } else {
        setErro(response);
      }
    }).then(dado => atualizaRow(dado))
      .catch(error => {
        setOpenBackdrop(false);
      });
  }
  function tipoUsuario(provedor) {
    if (provedor === 'ldap') {
      return "Interno";
    }
    return "Externo";
  }

  function createSortHandler(coluna) {
    let or = { ...ordem };
    if (or.campo === coluna) {
        or.ordem = (or.ordem === 'asc') ? 'desc' : 'asc';
    } else {
        or.campo = coluna;
        or.ordem = 'asc';
    }
    setOrdem(or);
    
    setBloco(0);
}

  function nomeSituacao(st) {
    if (st === 'inativo_nt_pendente') return "inativo";
    if (st === 'ativo_nt_pendente') return "ativo";
    return st;
  }

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell sortDirection={false} className={classes.tableHead}>
              <TableSortLabel
                active={ordem.campo === 'name'}
                direction={ordem.ordem}
                onClick={(e) => createSortHandler('name')}
              >
                Nome
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={false} className={classes.tableHead}>
              <TableSortLabel
                active={ordem.campo === 'login'}
                direction={ordem.ordem}
                onClick={(e) => createSortHandler('login')}
              >
                Usuário
              </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={false} className={classes.tableHead}>
              <TableSortLabel
                active={ordem.campo === 'provedor'}
                direction={ordem.ordem}
                onClick={(e) => createSortHandler('provedor')}
              >
                Tipo
               </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={false} className={classes.tableHead}>
              <TableSortLabel
                active={ordem.campo === 'dataAtualizacao'}
                direction={ordem.ordem}
                onClick={(e) => createSortHandler('dataAtualizacao')}
              >
                Data da ultima atualização
                </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={false} className={classes.tableHead}>
              <TableSortLabel
                active={ordem.campo === 'perfis'}
                direction={ordem.ordem}
                onClick={(e) => createSortHandler('perfis')}
              >
                Perfil
                </TableSortLabel>
            </TableCell>
            <TableCell sortDirection={false} className={classes.tableHead}>
              <TableSortLabel
                active={ordem.campo === 'situacao'}
                direction={ordem.ordem}
                onClick={(e) => createSortHandler('situacao')}
              >
                Situação do acesso
               </TableSortLabel>
            </TableCell>
            <TableCell width='100px' align="center" className={classes.tableHead}>
              Ação
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <StyledTableRow key={i}>  
              <StyledTableCell component="th" scope="row">
                {row.name}
              </StyledTableCell>
              <StyledTableCell >{row.login}</StyledTableCell>
              <StyledTableCell >{tipoUsuario(row.provedor)}</StyledTableCell>
              <StyledTableCell >{formataData(new Date(row.dataAtualizacao))}</StyledTableCell>
              <StyledTableCell >{row.perfis}</StyledTableCell>
              <StyledTableCell >{nomeSituacao(row.situacao)}</StyledTableCell>
              <TableCell>
                <Tooltip title={getTituloSituacao(row)}>
                  <IconButton onClick={(e) => handleSituacaoAcao(e, i)}>
                    {
                      (row.situacao === 'ativo' || row.situacao === 'ativo_nt_pendente') ? <PersonIcon /> : <PersonIcon color="disabled" />
                    }
                  </IconButton>
                </Tooltip>
                <Tooltip title="Trocar perfil">
                  <IconButton onClick={() => handleTrocarPerfil(row)}><UpdateIcon /></IconButton>
                </Tooltip>
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      {
                    rows &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={ qtd }
                        rowsPerPage={pagina}
                        page={bloco}
                        labelRowsPerPage="Registros por página"
                        labelDisplayedRows={({ from, to, count }) => (from + "-" + to + ' de ' + count)}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                }
      
      <MotivoDialog open={showMotivoDialog} setOpen={setShowMotivoDialog} acao={motivoRevogar} />
      <Backdrop className={classes.backdrop} open={openBackdrop} >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        open={showConfirm}
        setOpen={setShowConfirm}
        titulo={'Confirmação'}
        msg={'O usuario foi desativado pelo seguinte motivo: ' + msg + '.\nConfirma ativação do usuário?.'}
        exec={confirmaAtivarAcesso}
      />
      <ConfirmDialog
        open={showConfirmRevogar}
        setOpen={setShowConfirmRevogar}
        titulo={'Confirmação'}
        msg={'Deseja realmente revogar o usuário '+ usuario + '.'}
        exec={revogar}
      />

    </TableContainer>
  );
}
