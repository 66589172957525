import React, { useEffect } from 'react';
import { Grid, Button, TextField, Paper, Container } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SendIcon from '@material-ui/icons/Send';
import ReplyIcon from '@material-ui/icons/Reply';
import { useState } from 'react';
import AcaoTabelaComponent from '../../common/tabela/AcaoTabelaComponent';
import CelulaCabecalhoTabelaComponent from '../../common/tabela/CelulaCabecalhoTabelaComponent';
import TabelaComponent from '../../common/tabela/TabelaComponent';
import NotificacaoService from '../../../services/NotificacaoService';
import { useParams, useHistory } from 'react-router-dom';
import { useStyles, FormataDataComHora, calculaDiaLimite, fluxoDespacho } from '../../../constants';
import Filtro from '../../../filters/Filtro';
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck';
import { useCurrentUser } from "../../../server/UseCurrentUser";
import {useFluxoNotificacaoComplementoComplexo} from "../../../server/UseFluxo";
import CreateIcon from '@material-ui/icons/Create';

export default function ListarNotificacoesPanel(props) {
    const classes = useStyles();
    const history = useHistory();
    let { idProcesso } = useParams();
    const { setMensagem, setTitulo } = props;
    const [notificacoes, setNotificacoes] = useState([]);
    const [processo, setProcesso] = useState({ dadosEmpresa: [], notificacoes: [] });
    const [notificacoesFiltrados, setNotificacoesFiltrados] = useState([]);
    let [profile, carregandoProfile] = useCurrentUser();
    const [fluxoNotificacaoComplementoComplexo] = useFluxoNotificacaoComplementoComplexo();

    const isPerfilUnate = () => {
        const resposta = profile && profile.regras ? profile.regras.find(r => r === '011') !== undefined : false;
        return resposta;
    }

    const isPerfilSemp = () => {
        const resposta = profile && profile.regras ? profile.regras.find(r => r === '013') !== undefined : false;
        return resposta;
    }

    const isPerfilEmpresario = () => {
        const resposta = profile && profile.regras ? profile.regras.find(r => r === '012') !== undefined : false;
        return resposta;
    }

    useEffect(() => {
        setTitulo("Notificações");
        if( carregandoProfile )return;
        
        // let ignore = false;
        async function fetchData() {
            await NotificacaoService.obterTodos().then((r) => {
                // r = r.sort((a, b) => ((a.dataCriacao < b.dataCriacao) ? 1 : -1));
                const isEmpresario = isPerfilEmpresario();
                const arr = filtroInicial(r);
                for (let i = 0; i < arr.length; i++) {
                    let notificacao = arr[i];
                    
                    const dtNotificacao= getDataNotificacao(notificacao);
                    const dtResposta= getDataResposta(notificacao);
                    notificacao.dNotificacao = dtNotificacao?FormataDataComHora(dtNotificacao):'';
                    notificacao.dResposta = dtResposta?FormataDataComHora(dtResposta):'';
                    notificacao.nProcesso = notificacao.processo.nProcesso;
                    notificacao.programa = notificacao.processo.programa;
                    if( isEmpresario ){
                        notificacao.nStatus = showStatusEmpresario(notificacao);
                    }else{
                        notificacao.nStatus = Filtro.fromNotificacaoStatus(notificacao, fluxoNotificacaoComplementoComplexo);
                    }
                    notificacao.diasLimiteResposta = (notificacao.status === 'NAO_RESPONDIDO' || notificacao.status === 'RASCUNHO' || notificacao.status === "DESPACHO") ? (calculaDiaLimite(dtNotificacao)) : '';
                }
                // if (!ignore) {
                    setNotificacoes(arr);
                    setNotificacoesFiltrados(arr);
                // }
            }).catch((e) => {
                setMensagem({ mensagem: e.message, tipo: 'error' });
            });
        }
        if( fluxoNotificacaoComplementoComplexo ){
            fetchData();
        }
        // return () => { ignore = true; }
    }, [idProcesso, carregandoProfile, fluxoNotificacaoComplementoComplexo])

    function showStatusEmpresario(row){
        const notificacaoStatus = row.status;
        
        if( notificacaoStatus === 'DEFERIDO'){
            const h = row.historico.find(i => i.tipo === '');
            return h?'Respondido': fluxoDespacho(row, "Em analise");
        }else if( notificacaoStatus === 'PENDENTE'){
            return "Pendente";
        }else if( notificacaoStatus === 'RASCUNHO'){
            return "Rascunho";
        }else{
            return "Em analise";
            // return fluxoDespacho(row, "Respondido");
        }
    }
  
    function getDataResposta(notificacao){
        if( notificacao.tipo && notificacao.tipo === 'Pendência de vistoria' ||'Prazo vencido' ||'Outros'){
            const arr = notificacao.historico.filter(h => h.tipo === 'responder');
            if( arr && arr.length > 0){
                const dt = arr[arr.length-1].data;
                return new Date(dt);
            }
        }else{
            const arr = notificacao.historico.filter(h => h.tipo === 'emValidacao');
            if( arr && arr.length > 0){
                const dt = arr[arr.length-1].data;
                return new Date(dt);
            }
        }
        return null;
    }

    function filtroInicial(r){
        if( isPerfilEmpresario() ){
            return r.filter(n => {
                
                if( n.assunto === 'Notificacao' && n.status !== 'RASCUNHO_ACESSOR' && n.status !== 'DESPACHO' && n.status !== 'DEVOLVIDO'){

                    if( n.status === 'EM_ANALISE' ){
                        const fluxo = n.fluxoPath[n.fluxoPath.length-1];
                        if( fluxo === 'despachoUnate' ) return false;
                    }
                    return profile.empresas.find( e => e === n.processo.cnpj)?true:false;
                }else if( n.assunto === 'Notificacao' && n.status === 'DESPACHO' ){
                    const fluxo = n.fluxoPath.filter(f => f === 'naoRespondido' || f === 'emValidacao' );
                    if( fluxo ){
                        return profile.empresas.find( e => e === n.processo.cnpj)?true:false;
                    }
                }
                return false;
            });
        }
        return r.filter(n => n.assunto === 'Notificacao');
    }
    function getDataNotificacao(notificacao){
        const arr = notificacao.historico.filter(h => h.tipo === 'EM_ANALISE');
        if( arr && arr.length > 0){
            const dt = arr[arr.length-1].data;
            return new Date(dt);
        }
        return null;
    }
    function handleCadastrarNotificacao() {
        history.replace(`/gerenciaProcesso/notificacao/form`);
    }

    function handleAbrirDetalhamento(notificacao) {
        if( isPerfilEmpresario() ){
            history.replace(`/gerenciaProcesso/notificacao/formView/${notificacao.id}`);
        }else{
            history.replace(`/gerenciaProcesso/notificacao/view?id=${notificacao.id}`);
        }
    };

    function handleReenviar(notificacao) {
        history.replace(`/gerenciaProcesso/notificacao/form?id=${notificacao.id}`);
    };

    function handleResponder(notificacao) {
        history.replace(`/gerenciaProcesso/notificacao/respostaForm?id=${notificacao.id}`);
    };

    function handleAnalisar(notificacao) {
        history.replace(`/gerenciaProcesso/notificacao/${notificacao.id}/analisar`);
    };
    function handleEditar(notificacao){
        history.replace(`/gerenciaProcesso/notificacao/form?id=${notificacao.id}`);
    }

    function handleSair() {
        history.replace(`/home`);
    }

    function handlePodeReenviar(notificacao) {
        return (isPerfilUnate() || isPerfilEmpresario()) && notificacao && (notificacao.status === 'PENDENTE' );
    }

    function handlePodeResponder(notificacao) {
        return (isPerfilUnate() || isPerfilEmpresario()) && notificacao && (notificacao.status === 'NAO_RESPONDIDO' || notificacao.status === 'RASCUNHO');
    }

    function handlePodeAnalisar(notificacao) {
        return isPerfilSemp() && notificacao && notificacao.status === 'RESPONDIDO';
    }
    function handlePodeEditar(notificacao){
        const fluxo = notificacao.fluxoPath[notificacao.fluxoPath.length-1];
        return isPerfilSemp() && notificacao && (notificacao.status === 'RASCUNHO_ACESSOR' || fluxo === 'emRascunho');
    }

    function handleSearch(e) {
        let termo = e.target.value;
        setNotificacoesFiltrados(notificacoes.filter((r) => {
            return (r.nProcesso && r.nProcesso.includes(termo)) ||
                (!!r.protocolo && r.protocolo.includes(termo)) ||
                (!!r.dCriacao && r.dCriacao.includes(termo)) ||
                (!!r.dAnalise && r.dAnalise.includes(termo)) ||
                (!!r.diasLimiteResposta && r.diasLimiteResposta.includes(termo)) ||
                (!!r.programa && r.programa.includes(termo)) ||
                (!!r.nStatus && r.nStatus.includes(termo)) ||
                (!!r.tipo && r.tipo.includes(termo))
        }));
    }

    return (
        <Grid item xs={12}>
            {!!idProcesso && idProcesso !== '0' && <Grid item xs={12} >
                <Container maxWidth='md'>
                    <Paper>
                        <Grid container spacing={3} className={classes.root}>
                            <Grid item xs={6}>
                                <TextField
                                    value={(processo && processo.dadosEmpresa && processo.dadosEmpresa.nProcesso) || ''}
                                    disabled
                                    fullWidth
                                    label="Processo"
                                    id="processo"
                                    name="processo"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={(processo && processo.dadosEmpresa && processo.dadosEmpresa.cnpj) || ''}
                                    disabled
                                    fullWidth
                                    label="CNPJ"
                                    id="cnpj"
                                    name="cnpj"
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={(processo && processo.dadosEmpresa && processo.dadosEmpresa.razaoSocial) || ''}
                                    disabled
                                    fullWidth
                                    label="Razão Social"
                                    id="razaoSocial"
                                    name="razaoSocial"
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Grid>}
            <Grid item xs={12}>
                <TabelaComponent total={notificacoesFiltrados.length} dados={notificacoesFiltrados} onSearch={handleSearch}>
                    <CelulaCabecalhoTabelaComponent identificador='nProcesso' titulo="Número Processo" />
                    <CelulaCabecalhoTabelaComponent identificador='protocolo' titulo="Protocolo" />
                    <CelulaCabecalhoTabelaComponent identificador='tipo' titulo="Notificação" />
                    <CelulaCabecalhoTabelaComponent identificador='dNotificacao' titulo="Data notificação" />
                    <CelulaCabecalhoTabelaComponent identificador='dResposta' titulo="Data resposta" />
                    <CelulaCabecalhoTabelaComponent identificador='diasLimiteResposta' titulo="Dias limite para resposta" />
                    <CelulaCabecalhoTabelaComponent identificador='nStatus' titulo="Situação" />
                    
                    <AcaoTabelaComponent titulo="Visualizar Notificação" onClick={(e) => handleAbrirDetalhamento(e)}>
                        <VisibilityIcon />
                    </AcaoTabelaComponent>
                    
                    {(isPerfilUnate() || isPerfilEmpresario()) && <AcaoTabelaComponent titulo="Reenviar" onClick={(e) => handleReenviar(e)} visibilidade={(e) => handlePodeReenviar(e)}>
                        <SendIcon />
                    </AcaoTabelaComponent>}
                    {(isPerfilUnate() || isPerfilEmpresario()) && <AcaoTabelaComponent titulo="Responder" onClick={(e) => handleResponder(e)} visibilidade={(e) => handlePodeResponder(e)}>
                        <ReplyIcon />
                    </AcaoTabelaComponent>}
                    {isPerfilSemp() && <AcaoTabelaComponent titulo="Analisar" onClick={(e) => handleAnalisar(e)} visibilidade={(e) => handlePodeAnalisar(e)}>
                        <LibraryAddCheckIcon />
                    </AcaoTabelaComponent>}
                    <AcaoTabelaComponent titulo="Editar" onClick={(e) => handleEditar(e)} visibilidade={(e) => handlePodeEditar(e)}>
                        <CreateIcon />
                    </AcaoTabelaComponent>
                </TabelaComponent>
            </Grid>
            { isPerfilSemp() && 
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" onClick={() => handleSair()} rel="noopener noreferrer">
                    Sair
                </Button>
                {isPerfilSemp() && <Button variant="contained" color="primary" onClick={() => handleCadastrarNotificacao()}>
                    Notificar
                </Button>}
            </Grid>
            }
            { isPerfilEmpresario() && 
            <Grid container justify="flex-end" className={classes.btns}>
                <Button variant="contained" color="secondary" onClick={() => handleSair()} rel="noopener noreferrer">
                    Sair
                </Button>
            </Grid>
            }
        </Grid>
    );
}
