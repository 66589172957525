import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Paper } from '@material-ui/core';
import { Meses, FormataMoeda } from "../../../constants";
import TableMoedaCell from "./TableMoedaCell";

const useStylesLocal = makeStyles(theme => ({
    input: {
        width: 80,
        height: 30
    },
    table: {
        minWidth: 700
    },
    tablecell: {
        fontSize: 14,
        fontWeight: 700
    }
}));
let media = 1

export default function FruicaoTerceirizados(props) {
    const classes = useStylesLocal();
    const { dados, setDados, calcula, disabled, dadosRenda } = props;
    const [total, setTotal] = useState({});
    const [componenteAtual, setComponenteAtual] = useState();

    useEffect(() => {
        if (dados && dados.length > 0) {

            let totalEmpDiretos = 0;
            let totalEmpIndiretos = 0;
            let mediaTrabGerado = 0;
            for (let x in dados) {
                if (!dados[x]) continue;
                media = parseInt(x)+1;
                totalEmpDiretos += dados[x].empregosDiretos ? dados[x].empregosDiretos : 0;
                totalEmpIndiretos += dados[x].totalEmpregoIndiretos ? dados[x].totalEmpregoIndiretos : 0;
                mediaTrabGerado += dados[x].totalPostoTrabalhoGerado ? dados[x].totalPostoTrabalhoGerado : 0;

            }
            setTotal({

                empregosDiretos: Math.round(totalEmpDiretos/media),
                totalEmpregoIndiretos: Math.round(totalEmpIndiretos/media),
                trabtotalPostoTrabalhoGeradoGerado: Math.round(mediaTrabGerado/media)
            });
        }
    }, [dados])


    
    useEffect(() => {
        if (dadosRenda) {
            let arr = [...dados];
            for (let x in dadosRenda) {
                const dadoR = dadosRenda[x];
                if (dadoR && (dadoR.totalEmpregoDiretos || dadoR.totalEmpregoDiretos === 0 || dadoR.totalEmpregoDiretos === "0")) {
                    arr[x] = {
                        ...arr[x],
                        empregosDiretos: dadoR.totalEmpregoDiretos
                    };
                }

            }
            for (let x in dados){
                if (!dados[x]) continue;
                if (arr[x].totalEmpregoIndiretos || arr[x].totalEmpregoIndiretos === 0) arr[x].totalPostoTrabalhoGerado = arr[x].totalEmpregoIndiretos + (arr[x].empregosDiretos? arr[x].empregosDiretos : 0)
                // val["totalPostoTrabalhoGerado"] = val["totalEmpregoIndiretos"] + val["empregosDiretos"];
            }
            setDados(arr);
        }
    }, [dadosRenda])

    function FormataMilhar(val){
        if( val === 0 || val === '0')return val.toLocaleString('pt-br');
        if( !val) return '';
        var f = val.toLocaleString('pt-br');
        return f;
    }
    
    const onChange = (e, row, index) => {
        if (!/^[0-9]*\,?[0-9]*$/.test(e.target.value)) {
            e.target.value = e.target.value.replace(/[^0-9]*\,?[0-9]*$/g, "");
            return;
        }
        let arr = [...dados];
        let val = { ...arr[index] };
        let valor = e.target.value;
        valor = valor.replace(/[.*+\R\$\-?^${}()|[\]\\]/g, '');
        valor = valor.replace(",", ".");
        val[e.target.name] = parseFloat(valor);

        if (!val.editado) val.editado = [];
        if (!val.editado.find(v => v === e.target.name)) val.editado.push(e.target.name);
        if (e.target.name === "totalEmpregoIndiretos") {
            if (!val["empregosDiretos"] && val["empregosDiretos"] != 0 && val["totalEmpregoIndiretos"]) val["empregosDiretos"] = null;
            val["totalPostoTrabalhoGerado"] = val["totalEmpregoIndiretos"] + val["empregosDiretos"];
        }

        arr[index] = val;
        setDados(arr);
    };
    function apresentaValor(index, nome) {
        if (dados && dados.length > 0) {
            if (dados[index]) {
                return FormataMoeda(dados[index][nome]);
            }
            return '';
        }
        return '';
    }
    
    
    function apresentaValorEmprego(index, nome) {
        if (dados && dados.length > 0) {
            if (dados[index] && (dados[index][nome] || dados[index][nome] === 0 || dados[index][nome] === "0") ) {
                return FormataMilhar(dados[index][nome]);
            }
            return '';
        }
        return '';
    }
    function proximo(nome) {
        let arr = nome.split("nm_");
        let col = parseInt(arr[0]);
        let lin = parseInt(arr[1]);
        lin++;
        col = 1;
        setComponenteAtual(col + "nm_" + lin);
    }
    return (
        <TableContainer component={Paper} className={classes.root}>
            <Table aria-label="simple table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead} >Mês Referencia</TableCell>
                        <TableCell className={classes.tableHead} align="right">EMPREGOS DIRETOS GERADOS PELO EMPREENDIMENTO</TableCell>
                        <TableCell className={classes.tableHead} align="right">EMPREGOS GERADOS PELAS TERCEIRIZADAS</TableCell>
                        <TableCell className={classes.tableHead} align="right">POSTOS DE TRABALHO GERADOS</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Meses.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">{row}</TableCell>
                            <TableMoedaCell index={index} disabled={true} row={row} calcula={calcula} name="empregosDiretos" valor={apresentaValorEmprego(index, 'empregosDiretos')} />
                            <TableMoedaCell componenteAtual={componenteAtual} proximo={proximo} nome={"1nm_" + index} index={index} disabled={disabled} row={row} calcula={calcula} name="totalEmpregoIndiretos" onChange={onChange} valor={apresentaValorEmprego(index, 'totalEmpregoIndiretos')} />
                            <TableMoedaCell componenteAtual={componenteAtual} index={index} disabled={true} row={row} calcula={calcula} name="totalPostoTrabalhoGerado" valor={apresentaValorEmprego(index, 'totalPostoTrabalhoGerado')} />
                        </TableRow>
                    ))}
                    <TableRow style={{ background: '#fafafa' }} className={classes.tablecell}
                    >
                        <TableCell className={classes.tablecell} component="th" scope="row" >Média</TableCell>
                        <TableCell className={classes.tablecell} align="right" >{FormataMilhar(total.empregosDiretos)}</TableCell>
                        <TableCell className={classes.tablecell} align="right"> {FormataMilhar(total.totalEmpregoIndiretos)}</TableCell>
                        <TableCell className={classes.tablecell} align="right"> {FormataMilhar(total.trabtotalPostoTrabalhoGeradoGerado)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}