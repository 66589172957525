import { Button, Container, Grid, Paper, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import GenericTable from "../../../components/template/GenericTable";
import { getQueryParams, useStyles } from "../../../constants";
import Service from '../../../services/Service';


export default function RegioesForm(props) {

    const { setErro, setTitulo, setWait, setMensagem } = props;
    const classes = useStyles();
    const [dado, setDado] = useState();
    const param = getQueryParams(useLocation().search);
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {

        if (param.get("id")) {
            buscarRegaoAdimins(param.get("id"));
            setTitulo(location.pathname.includes("regioesAdministrativasView") ? "Visualizar Regioes administrativas" : "Alterar Regioes administrativas");
        } else {
            setTitulo("Cadastrar Regioes administrativas");
        }
    }, []);

    function buscarRegaoAdimins(id) {
        setWait(true);
        Service.GET('/api/regioesAdm/' + id).then(json =>{
            setWait(false);
            setDado(json);
        }).catch(error =>{
            setWait(false);
            setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
        });
        // fetch('/api/regioesAdm/' + id, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     }
        // }).then(response => {
        //     if (response.ok) {
        //         setWait(false);
        //         response.json().then(json => {
        //             setDado(json);
        //         });
        //     } else {
        //         response.json().then((error) => {
        //             setWait(false);
        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    const handleChangeInputForm = (event) => {
        setDado({ ...dado, [event.target.name]: event.target.value });
    };

    function submitForm() {
        setWait(true);
        Service.POST('/api/regioesAdm', dado).then(json =>{
            setWait(false);
            history.replace("/operacao/regioesAdministrativasTable");
        }).catch(error =>{
            setWait(false);
            setMensagem(error? error: 'Oops! Something went wrong. Please try again!');
        });
        // fetch('/api/regioesAdm', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         Accept: 'application/json',
        //         Authorization: `Bearer ${localStorage.accessToken}`
        //     },
        //     body: JSON.stringify(dado),
        //     credentials: 'include'
        // }).then(response => {
        //     if (response.ok) {
        //         setWait(false);
        //         history.replace("/operacao/regioesAdministrativasTable");
        //     } else {
        //         response.json().then((error) => {
        //             setWait(false);
        //             setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        //         });
        //     }
        // }).catch(error => {
        //     setWait(false);
        //     setMensagem((error && error.message) || 'Oops! Something went wrong. Please try again!');
        // });
    }

    return (
        <Container maxWidth='lg'>
            <Paper>
                <Grid container spacing={3} className={classes.root}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Dados Região</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            autoFocus={location.pathname.includes("regioesAdministrativasView") ? false : true }
                            value={dado && dado.ra || ''}
                            onChange={handleChangeInputForm}
                            InputProps={{
                                readOnly: location.pathname.includes("regioesAdministrativasView") ? true : false
                            }}
                            label="Ra"
                            name="ra"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={dado && dado.ade || ''}
                            onChange={handleChangeInputForm}
                            InputProps={{
                                readOnly: location.pathname.includes("regioesAdministrativasView") ? true : false
                            }}
                            label="Ade"
                            name="ade"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            value={dado && dado.endereco || ''}
                            onChange={handleChangeInputForm}
                            InputProps={{
                                readOnly: location.pathname.includes("regioesAdministrativasView") ? true : false
                            }}
                            label="Endereço"
                            name="endereco"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>


                    <Grid container justify="flex-end" className={classes.btns}>
                        <Button variant="contained" color="secondary" href={"/operacao/regioesAdministrativasTable"} rel="noopener noreferrer">
                            Sair
                        </Button>
                        {!location.pathname.includes("regioesAdministrativasView")&&
                            <Button variant="contained" color="primary" onClick={submitForm} >
                                {param.get("id")? "Alterar":"Salvar"}
                            </Button>
                        }
                         {location.pathname.includes("regioesAdministrativasView")&&
                            <Button variant="contained" color="primary"  href={"/operacao/regioesAdministrativasForm?id="+param.get("id")} >
                                Editar
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    )
}