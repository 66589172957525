import React, { useState, useEffect } from "react";
import Home from "./components/Home";
import Header from "./components/Header";
import NotFound from "./components/common/NotFound";
import OAuth2RedirectHandler from "./components/oauth2/OAuth2RedirectHandler";
import FormMinhaConta from "./components/user/FormMinhaConta";
import VerPost from "./components/user/home/VerPost";
import Perfil from "./components/user/Perfil";
import Login from "./components/user/home/Login";
import CriarPerfilPanel from "./components/user/CriarPerfilPanel";
import UnidadePanel from "./components/user/unidade/UnidadePanel";
import CriarFuncionalidadePanel from "./components/user/CriarFuncionalidadePanel";
import CadastrarPerfilPanel from './components/user/CadastrarPerfilPanel';
import UsersPanel from './components/user/UsersPanel';
import TrocarSenhaPanel from './components/user/TrocarSenhaPanel';
import CadastrarFuncionalidadePanel from './components/user/CadastrarFuncionalidadePanel';
import AutorizarAcessoPanel from './components/user/AutorizarAcessoPanel';
import AssociarPerfilLotePanel from './components/user/associarPerfilLote/Panel';
import SolicitarVinculoPanel from './components/user/perfil/SolicitarVinculoPanel';
import AnalisarSolicitacaoVinculoPanel from './components/user/analisarSolicitacaoVinculo/Panel';
import ImportaPlanilhaPanel from './components/processoProdf/ImportaPlanilhaPanel';
import ImportaPlanilhaVistoriasPanel from './components/processoProdf/ImportaPlanilhaVistoriasPanel';
import CargaLotePanel from './components/processoProdf/CargaLotePanel';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Processos from "./components/processo/Processos";
import ProcessosEmpresario from "./components/processo/ProcessosEmpresario";
import VisualizarRequerimento from "./components/processo/VisualizarRequerimento";
import TemplatePanel from "./components/template/TemplatePanel";
import { ThemeProvider } from '@material-ui/core/styles';
import OperacaoPanel from './components/operacao/OperacaoPanel'
import GestaoPanel from './components/gestao/GestaoPanel'
import VistoriaPanel from './components/vistoria/VistoriaPanel'
import SuportePanel from './components/suporte/SuportePanel'
import Projeto from "./components/projeto/Projeto";
import Fundefe from "./components/fundefe/Fundefe";
import { useCurrentUser } from "./server/UseCurrentUser";
import { theme, useStyles } from "./constants";
import { ACCESS_TOKEN, API_BASE_URL } from './constants';
import Service from "./services/Service";
import Daabe from "./components/daabe/Daabe";
import ParametroSeiPanel from "./components/parametroSei/ParametroSeiPanel";
import { Backdrop, CircularProgress } from "@material-ui/core";
import ManualPanel from "./components/ManualPanel";

function App() {

  const [build, setBuild] = useState();
  const [marginBottom, setMarginBottom] = useState('0px');
  const [wait, setWait] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (window.location.pathname === '/login') {
      setMarginBottom('85px')
    }

    fetch('/api/info/v')
      .then(response => {
        if (response.ok) {
          response.json()
            .then(json => {
              setBuild(json);
            })
        }
      })
    function runForever() {
      setTimeout(() => {
        const profile = localStorage.getItem(ACCESS_TOKEN + "_PROFILE");
        if (profile) {
          const user = JSON.parse(profile)
          let endTime = new Date();
          var timeDiff = endTime - new Date(user.startTime); //in ms
          // strip the ms
          timeDiff /= 1000;

          // get seconds 
          var seconds = Math.round(timeDiff);
          if (seconds > 500) {
            localStorage.removeItem(ACCESS_TOKEN + "_PROFILE");//.clear();
            localStorage.removeItem(ACCESS_TOKEN + "_menu");
            Service.GET(API_BASE_URL + "/api/user/me").then(response => {
              response.startTime = new Date();
              localStorage.setItem(ACCESS_TOKEN + "_PROFILE", JSON.stringify(response));
            }).catch(error => {
              console.error(error);
              if (error.includes("signature does not match") ||
                error.includes("JWT expired")
              ) {
                setTimeout(function () {
                  localStorage.clear();
                  window.location.href = "login";
                }, 1000);
                throw "Problema ao verificar credenciais do usuário. Efetue novo login.";
              }
            });
          }
        }

        runForever();
      }, 2000);
    }
    runForever();
  }, []);

  const isUserLogado = () => {
    if (window.location.pathname != '/login' &&
      window.location.pathname != "/home/SolicitarAcessoForm" && window.location.pathname != "/home/recuperarSenha" &&
      window.location.pathname != "/home/ConsultarChamadoForm" &&
      window.location.pathname != "/home/primeiroAcesso") {
      if (!localStorage.getItem(ACCESS_TOKEN)) {
        return false;
      }
    }
    return true
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        {isUserLogado() &&
          <div className="App">
            <Header />

            <Switch>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/topics">
                <Topics />
              </Route>

              <Route path="/home">
                <Home />
              </Route>

              <Route path="/consultaProcesso">
                <ProcessosEmpresario />
              </Route>

              <Route path="/visualizarRequerimento">
                <VisualizarRequerimento />
              </Route>

              <Route path="/gerenciaProcesso">
                <Processos />
              </Route>

              <Route path="/projeto">
                <Projeto />
              </Route>

              <Route
                path="/oauth2/redirect"
                component={OAuth2RedirectHandler}
              ></Route>
              <Route path="/minhaConta">
                <FormMinhaConta />
              </Route>
              <Route path="/Perfil">
                <Perfil />
              </Route>
              <Route path="/usuariosSistema">
                <UsersPanel />
              </Route>
              <Route path="/criarPerfil">
                <CriarPerfilPanel />
              </Route>
              <Route path="/unidade">
                <UnidadePanel />
              </Route>
              <Route path="/CadastrarPerfil">
                <CadastrarPerfilPanel />
              </Route>
              <Route path="/trocarSenha">
                <TrocarSenhaPanel />
              </Route>

              <Route path="/CadastrarFuncionalidade">
                <CadastrarFuncionalidadePanel />
              </Route>

              <Route path="/criarFuncionalidade">
                <CriarFuncionalidadePanel />
              </Route>

              <Route path="/autorizaAcesso">
                <AutorizarAcessoPanel />
              </Route>

              <Route path="/associarPerfilLote">
                <AssociarPerfilLotePanel />
              </Route>

              <Route path="/solicitarVinculoPerfis">
                <SolicitarVinculoPanel />
              </Route>

              <Route path="/analisarSolicitacaoVinculo">
                <AnalisarSolicitacaoVinculoPanel />
              </Route>
              <Route path="/cargaDAADE">
                <ImportaPlanilhaPanel />
              </Route>
              <Route path="/cargaVistorias">
                <ImportaPlanilhaVistoriasPanel />
              </Route>
              <Route path="/cargaLote">
                {/* <ImportaPlanilhaPanel isLote={true}/> */}
                <CargaLotePanel />
              </Route>
              <Route path="/cargaEmpresa">
                <ImportaPlanilhaPanel isEmpresa={true} />
              </Route>
              <Route path="/login">
                <Login />
              </Route>

              <Route path="/template">
                <TemplatePanel />
              </Route>

              <Route path="/operacao">
                <OperacaoPanel />
              </Route>

              <Route path="/gestao">
                <GestaoPanel />
              </Route>
              <Route path="/vistoria">
                <VistoriaPanel />
              </Route>
              <Route path="/suporte">
                <SuportePanel />
              </Route>
              <Route path="/fundefe">
                <Fundefe />
              </Route>
              <Route path="/Daabe">
                <Daabe />
              </Route>
              <Route path="/parametroSei">
                <ParametroSeiPanel />
              </Route>
              <Route path="/manual">
                <ManualPanel />
              </Route>

              <Route path="/post/:id"  render={(props) => <VerPost {...props} setWait={setWait} />} />

              <Route path="/post/" render={(props) => <VerPost {...props} setWait={setWait} />} />

              <Route component={NotFound}></Route>
            </Switch>
            <Backdrop className={classes.backdrop} open={wait}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        }
        {!isUserLogado() && <Login />}
      </ThemeProvider>

      <div style={{
        float: "right",
        position: 'fixed',
        bottom: 0,

        marginRight: 50,
        // marginLeft: 25,
        marginBottom: marginBottom,
        // background:'green',
        width: '99%',
        // height:20,
        // justifyItems:"flex-end",
        textAlign: "right",
        // alignItems:"flex-end",
        // display:'table-cell',
        // verticalAlign: 'bottom'
        // display: 'flex',
        flexDirection: 'row',
        // webkit-box-align: center;
        alignItems: 'flex-end',
        // -webkit-box-pack: center;
        justifyContent: 'center',
        // margin: 0px 10px;
        opacity: 0.5,
        fontFamily: 'Garamond, serif',
        fontSize: 12,
        // color: rgba(0, 0, 0, 0.5);
      }}>
        {(build ? "BE: " + build.version : "") + " - FE:" + (build ? build.versionfront : "") + "- BPM: " + (build ? build.versionBPM : "")}
      </div>



    </Router>
  );
}

function About() {
  return <h2>Sobre...</h2>;
}

function Topics() {
  return <h2>lalalalalal</h2>;
}

// const styles = StyleSheet.create({
//   backgroundImage: {
//       resizeMode: 'cover',
//       flex: 1,
//       justifyContent: 'center',
//       alignItems: 'center',
//       position: 'absolute',
//       width: '100%',
//       height: '100%',  
//   },
//   titulo: {
//       fontWeight: 'bold',
//       fontSize: 26,
//   },fotoDePerfil: {
//       width: 120,
//       height: 120,
//       marginTop: 50,
//       marginBottom: 20,
//       borderRadius: 5,
//   },
// });

export default App;
