import React, { useState } from 'react';
import {
    Paper, Typography,
    Accordion, AccordionSummary, AccordionDetails, Grid, TextField, Tooltip, IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from '../../../constants';
import BuscaDadoDialog from '../../../util/BuscaDadoDialog';
import ConfirmDialog from '../../../util/ConfirmDialog';
import CreateIcon from '@material-ui/icons/Create';
import Service from '../../../services/Service';

const dados = [
    { id: 8999, sigla: "SDE/GAB/SUPEC/DAABE/GATE", descricao: "Gerência de Atendimento ao Empresário" },
    { id: 8998, sigla: "GAB/SUPEC/DAABE/GATE", descricao: "Gerência de Atendimento" },
    { id: 8997, sigla: "SDE/SUPEC/DAABE/GATE", descricao: "Gerência de Empresário" }
];

export default function TiposProcessoForm(props) {
    const classes = useStyles();
    const { setWait, setMensagem, parametro, setParametro, salvar } = props;
    const [showBuscaUnidadeDialog, setShowBuscaUnidadeDialog] = useState(false);
    const [showConfirmAlteracao, setShowConfirmAlteracao] = useState(false);
    const [processos, setProcessos] = useState([]);

    function buscarTipoProcessoSei() {
        console.log("buscarUnidadeSei listarTipoProcesso");
        if (parametro && parametro.unidade && parametro.unidade.idUnidade) {
            const id = parametro.unidade.idUnidade;
            setWait(true);
            Service.GET('/api/sei/parametro/listarTipoProcesso/' + id).then(json => {
                setWait(false);
                // console.log("listarTipoProcesso", json);
                setProcessos(json);
                setShowBuscaUnidadeDialog(true);
            }).catch(error => {
                setWait(false);
                setMensagem(error ? error : 'Oops! Something went wrong. Please try again!');
            });
        } else {
            setMensagem("Erro: Defina a unidade previamente");
        }


    }

    function handleEscolhe(a) {
        console.log("handleEscolhe", a);
        setParametro({
            ...parametro,
            tipoProcesso: a
        });
        setShowConfirmAlteracao(true);
    }

    function confirmaAlteracao() {
        // console.log("confirmaAlteracao")
        salvar();
    }

    return (
        <Paper >
            <Accordion defaultExpanded={true}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Tipos de Processo</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={3} >
                        <Grid item xs={4}>
                            <TextField
                                value={parametro && parametro.tipoProcesso && parametro.tipoProcesso.idTipoProcedimento || ''}
                                // disabled
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Tipos de Processo"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="tipoProcesso"
                                name="tipoProcesso"
                            />
                        </Grid>
                        <Grid item xs={7}>
                            <TextField
                               value={parametro && parametro.tipoProcesso && parametro.tipoProcesso.nome || ''}
                                // disabled
                                InputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                label="Sigla da Unidade"
                                aria-describedby="nova-helper-text"
                                variant="outlined"
                                id="cartaConsulta"
                                name="cartaConsulta"
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title='Editar'>
                                <IconButton onClick={buscarTipoProcessoSei} >
                                    <CreateIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <BuscaDadoDialog
                open={showBuscaUnidadeDialog}
                setOpen={setShowBuscaUnidadeDialog}
                acao={handleEscolhe}
                colunas={[
                    { nome: 'ID', campo: 'idTipoProcedimento' },
                    { nome: 'Nome', campo: 'nome' }
                ]}
                dados={processos} />
            <ConfirmDialog
                open={showConfirmAlteracao}
                setOpen={setShowConfirmAlteracao}
                titulo={'Confirmação'}
                msg='Deseja alterar a unidade?'
                exec={confirmaAlteracao}
            />
        </Paper>
    )

}